<app-ts-popup [dateFrom]="dateFrom" [dateTo]="dateTo"></app-ts-popup>

<div class="gep-table-wrapper">
  <div class="table-wrapper">
    <table class="standard-table gep-table">
      <thead>
        <th class="checkbox-td"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" 
            (change)="checkUncheckAll()"></th>
        <th class="large-td"><span title="33KV Feeder Name">
          TS NAME
        </span></th>
        <th ><span title="Energy Received (MWH)">
          ENERGY RECEIVED(MWH)
        </span></th>
        <th><span title="Total Consumption at DT (MWH)">
          TOTAL CONSUMPTION AT DT(MWH) 
        </span></th>
        <th><span title="Losses (MWH)">
          LOSSES(MWH)
        </span></th>
        <th><span title="Losses (%)">
          LOSSES(%)
        </span></th>
        <th><span title="Losses (NGN)">
          LOSSES(NGN)
        </span></th> 
        <th class="large-td"><span title="Number of Connected Feeders 33">
          CONNECTED FEEDERS 33
        </span></th>
        <th></th>
      </thead>

      <tbody>
        <tr *ngFor="let item of data" class="clickable actions-click" (click)="viewMore(item)">
          <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox" 
            class="input-checkmark" value="{{item.tsId}}" 
            [(ngModel)]="item.checked" (change)="checkForDownload(item.tsId)" ></td>
          <td class="large-td">
            <span *ngIf="item.tsName !== '/'" title="{{item.tsName}}">{{item.tsName | hasValue}}</span>
            <span *ngIf="item.tsName === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.energyReceived !== 0" title="{{item.energyReceived}}">{{item.energyReceived | hasValue | number: '.2' }}</span>
            <span *ngIf="item.energyReceived === 0" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.energyConsumptionAtDts  !== 0" title="{{item.energyConsumptionAtDts}}">{{item.energyConsumptionAtDts | hasValue  | number: '.2' }}</span>
            <span *ngIf="item.energyConsumptionAtDts === 0" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.losses !== 0" title="{{item.losses}}">{{item.losses | hasValue | number: '.2' }}</span>
            <span *ngIf="item.losses === 0" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.lossesInPercentage !== 0" title="{{item.lossesInPercentage}}">{{item.lossesInPercentage | hasValue }}</span>
            <span *ngIf="item.lossesInPercentage === 0" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.lossesInNGN !== 0" title="{{item.lossesInNGN}}">{{item.lossesInNGN | hasValue | number: '.2' }}</span>
            <span *ngIf="item.lossesInNGN === 0" class="NaN">N/A</span>
          </td>
          <td>
            <span title="{{item.numberOfConnectedFeeders33}}">{{item.numberOfConnectedFeeders33}}</span>
          </td>
          <td>
            <button class="see-more-btn">See More</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="pagination-wrapper">
  <app-pagination [pageSize]="pageSize" [currentPage]="currentPage" [count]="count" (pageChange)="pageChange($event)">
  </app-pagination>
</div>
