import { DictionaryItem } from "./dictionary-item.model"

export class PredictiveDtAssetAnalytics{
    dtName: string = ''
    dtAddress: string = ''
    dtNo: string = ''
    buName: string = ''
    status: DictionaryItem = new DictionaryItem()
    loadDeviation: number = 0
    currentDeviation: number = 0
    voltageDeviation: number = 0
    factorDeviation: number = 0
    ambTempDeviation: number = 0
    dtId : number = 0
    lastReadSync: Date = new Date()
    ambTemp: number = 0
    voltage: number = 0
    current: number = 0
    factor: number = 0
    ambTempTs: Date = new Date() 
    voltageTs: Date = new Date() 
    currentTs: Date = new Date() 
    factorTs: Date = new Date() 
    maxCurrent: number = 0
    startTime: Date = new Date() 
    endTime: Date = new Date() 
    description: string = ""
    msn: string = ""
    phaseAVoltageImbalance: number = 0
    phaseACurrentImbalance: number = 0
    phaseBVoltageImbalance: number = 0
    phaseBCurrentImbalance: number = 0
    phaseCVoltageImbalance: number = 0
    phaseCCurrentImbalance: number = 0
    phaseVoltageImbalanceEdge: number = 0
    phaseCurrentImbalanceEdge: number = 0
    voltageImbalanceTs : Date | null = null;
    currentImbalanceTs : Date | null = null;
}

export class PredictiveAssetAnalyticsGraphData{
    voltage : number = 0
    current : number = 0
    powerFactor : number = 0
    ts : Date = new Date()

}
