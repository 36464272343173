<div class="text-center">
  <h2>Width: {{scrWidth}}</h2>
  <h2>Height: {{scrHeight}}</h2>
  <h2>Pixel ratio: {{pixelRatio}}</h2>
  <h2>Screen:</h2>
  <h3>availHeight {{screen.availHeight}}</h3>
  <h3>availWidth {{screen.availWidth}}</h3>
  <h3>availLeft {{screen.availLeft}}</h3>
  <h3>availTop {{screen.availTop}}</h3>
  <h3>colorDepth {{screen.colorDepth}}</h3>
  <h3>height {{screen.height}}</h3>
  <h3>pixelDepth {{screen.pixelDepth}}</h3>
  <h3>width {{screen.width}}</h3>
</div>