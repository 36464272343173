<div class="gep-table-wrapper">
  <div class="table-wrapper">
    <table class="standard-table gep-table">
    <thead>
      <th class="checkbox-td"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" (change)="checkUncheckAll()"></th>
      <th class="large-td"><span title="{{utName}}">{{utName}}</span></th>
      <th><span title="{{buName}}">{{buName}}</span></th>
      <th class="large-td"><span title="DT Name">DT NAME</span></th>
      <th><span title="Meter Number">METER NUMBER</span></th>
      <th class="large-td"><span title="Energy Received">ENERGY RECEIVED(MWH)</span></th>
      <th class="large-td"><span title="Total Consumption - Customers(MWH)">TOTAL CONSUMPTION(MWH)</span></th>      
      <th><span title="Losses(MWH)">LOSSES(MWH)</span></th>
      <th><span title="Losses(%)">LOSSES(%)</span></th>
      <th><span title="Losses(NGN)">LOSSES(NGN)</span></th>
      <th>
        <div class="dropdown">
          <div tabindex="3" class="dropdown-btn" (click)="commStatusClicked(1)" (blur)="commStatusClicked(0)">
            <span title="Comm Status(last 24hrs)">COMM STATUS(LAST 24hrs)</span>
            <svg *ngIf="commStatusOpened" width="24" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z" fill="#8F92A1" fill-opacity="0.4"/>
            </svg> 
            <svg *ngIf="!commStatusOpened" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z" fill="#8F92A1" fill-opacity="0.4"/>
          </svg>
          </div>
          <div class="dropdown-expanded" *ngIf="commStatusOpened">
            <span (mousedown)="commStatusSelected(0)">Deselect</span>
            <span (mousedown)="commStatusSelected(1)">Yes</span>
            <span (mousedown)="commStatusSelected(2)">No</span>
          </div>
        </div>
      </th>
      <th>
        <div class="dropdown">
          <div tabindex="4" class="dropdown-btn" (click)="readTypeClicked(1)" (blur)="readTypeClicked(0)">
            <span title="Read Type">READ TYPE</span>
            <svg *ngIf="readTypeOpened" width="24" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z" fill="#8F92A1" fill-opacity="0.4"/>
            </svg> 
            <svg *ngIf="!readTypeOpened" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z" fill="#8F92A1" fill-opacity="0.4"/>
          </svg>
          </div>
          <div class="dropdown-expanded" *ngIf="readTypeOpened">
            <span (mousedown)="readTypeSelected(0)">Deselect</span>
            <span (mousedown)="readTypeSelected(1)">Actual</span>
            <span (mousedown)="readTypeSelected(2)">Estimated</span>
          </div>
        </div>
      </th>
      <th></th>
    </thead>
  
    <tbody>
      <tr *ngFor="let item of data" class="clickable actions-click" (click)="alert()">
        <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)"></td>
        <td class="large-td">
          <span *ngIf="item.utName!== '/'" title="{{item.utName}}">{{item.utName | hasValue}}</span>
          <span *ngIf="item.utName === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.buName !== '/'" title="{{item.buName}}">{{item.buName | hasValue}}</span>
          <span *ngIf="item.buName === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.dtName !== '/'" title="{{item.dtName}}">{{item.dtName | hasValue}}</span>
          <span *ngIf="item.dtName === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.meterNumber !== '/'" title="{{item.meterNumber}}">{{item.meterNumber | hasValue}}</span>
          <span *ngIf="item.meterNumber === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.energyReceived !== '/'" title="{{item.energyReceived}}">{{item.energyReceived | hasValue | twoDecimals}}</span>
          <span *ngIf="item.energyReceived === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.energyConsumptionAtCustomers !== '/'" title="{{item.energyConsumptionAtCustomers}}">{{item.energyConsumptionAtCustomers | hasValue | twoDecimals}}</span>
          <span *ngIf="item.energyConsumptionAtCustomers === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.lossesMwh !== '/'" title="{{item.lossesMwh}}">{{item.lossesMwh | hasValue | twoDecimals}}</span>
          <span *ngIf="item.lossesMwh === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.lossesPercentage !== '/'" title="{{item.lossesPercentage}}">{{item.lossesPercentage | hasValue}}</span>
          <span *ngIf="item.lossesPercentage === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.lossesN !== '/'" title="{{item.lossesN}}">{{item.lossesN | hasValue | twoDecimals}}</span>
          <span *ngIf="item.lossesN === '/'" class="NaN">N/A</span>
        </td>        
        <td class="sync-div">
          <span title="{{item.lastReadSync}}">{{item.lastReadSync | hasValue}}</span>
          <div *ngIf="item.lastReadSyncDate !== '/'" title="{{item.lastReadSyncDate}}">
            <svg class="info-circle" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" xmlns="http://www.w3.org/2000/svg">
              <path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 6.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" fill-rule="nonzero"/>
            </svg>
          </div>
          
        </td>
        <td class="center">
          <span *ngIf="item.typeOfConsumption !== '/'" title="{{item.typeOfConsumption}}">{{item.typeOfConsumption | hasValue}}</span>
          <span *ngIf="item.typeOfConsumption === '/'" class="NaN">N/A</span>
        </td>
        <td><button type="button" id="openPopup" class="see-more-btn">See More</button></td>
      </tr>
    </tbody>
  </table>
  </div>
</div>

<div class="pagination-wrapper">
  <app-pagination
  [pageSize]="pageSize"
  [currentPage]="currentPage"
  [count]="count"
  (pageChange)="pageChange($event)"
  >
  </app-pagination>

</div>
