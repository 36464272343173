import { DtService } from './../../../../services/dt.service';
import { Feeder33Service } from './../../../../services/feeder33.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UploadPlannedOutagePopupComponent } from '../upload-planned-outage-popup/upload-planned-outage-popup.component';
import { ToastrService } from 'ngx-toastr';
import { PlannedOutageService } from 'src/app/services/planned-outage.service';
import { PlannedOutageCreateNewComponent } from '../planned-outage-create-new/planned-outage-create-new.component';
import { PlannedOutageTableComponent } from '../planned-outage-table/planned-outage-table.component';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { cloneDeep } from 'lodash';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-planned-outage',
  templateUrl: './planned-outage.component.html',
  styleUrl: './planned-outage.component.scss',
})
export class PlannedOutageComponent implements OnInit {
  @ViewChild(UploadPlannedOutagePopupComponent)
  importRef!: UploadPlannedOutagePopupComponent;
  @ViewChild(PlannedOutageCreateNewComponent)
  popupComponentRef!: PlannedOutageCreateNewComponent;
  @ViewChild(PlannedOutageTableComponent)
  plannedOutageTable! : PlannedOutageTableComponent;
  public PermissionsEnum = PermissionsEnum;

  ngOnInit(): void {
    this.setMainDate();
  }

  constructor(
    private toastr: ToastrService,
    private plannedOutageService: PlannedOutageService,
    public authService : AuthService,
    public feeder11Service: Feeder11Service,
    public Feeder33Service: Feeder33Service,
    public dtService : DtService
  ) {
    this.options = new Array();
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1)
    this.maxDate = currentDate;
  }

  public fileChosen: any = null;
  public file: any = null;
  openUploadTab() {
    this.importRef.changeState();
  }
  isFileChosen() {
    if (this.fileChosen) return true;

    return false;
  }
  uploadFile() {
    this.file = this.importRef.fileChosen;

    if (!this.isFileChosen) {
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append('File', this.file);
    formData.append('LocalMinutesOffset', new Date().getTimezoneOffset().toString());

    this.plannedOutageService.uploadFile(formData).subscribe({
      next: (response) => {
        if (response.status == 200) {
          this.toastr.success(response.message);
          this.plannedOutageTable.pageChange(1);
          if (response.data && response.data.length > 0)
            window.location.href = response.data;
        } else {
          this.toastr.error(response.message);
          if (response.data && response.data.length > 0)
            window.location.href = response.data;
        }
      },
      error: (err) => {
        this.toastr.error('An error occurred.');
      },
    });
  }

  getTemplate() {
    this.plannedOutageService.getTemplate().subscribe({
      next: (response) => {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'AdoraPlannedOutageTemplate.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
      },
      error: (err) => {
        this.toastr.error('An error occurred.');
      },
    });
  }

  openTab(id?: number) {
      this.popupComponentRef.changeState(1);
  }

  addedNewRefresh(added : boolean){
    if(added){
      this.plannedOutageTable.pageChange(1);
    }
  }

  // Search Autocomplete
  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public options;
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public searchPlaceholder: string = "Search by Asset name, Asset Number or MSN"
 // Search Autocomplete
 searchChanged(searchText: SearchAutocomplete) {
  this.searchText = searchText
  this.searchText = cloneDeep(this.searchText)
 }
   // Search Autocomplete
   getOptions(searchText: any) {
    this.options = new Array();
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    if (searchText.name.length > 2) {
      this.Feeder33Service.getDataForAutocomplete(obj)
        .subscribe({
          next : (response) => this.options = this.options.concat(response.data),
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
      this.feeder11Service.getDataForAutocomplete(obj)
        .subscribe({
          next : (response) => this.options = this.options.concat(response.data),
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
      this.dtService.getDataForAutocomplete(obj)
        .subscribe({
          next : (response) => this.options = this.options.concat(response.data),
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
    } else {
      this.searchChild.resetOptions()
    }
  }

  //Date Range Picker
  public dateFrom: any = ''
  public dateTo: any = ''
  public maxDate: Date;
  dateChange(data: any) {
    this.dateFrom = data.dateFrom;
    this.dateTo = data.dateTo;
  }
  setMainDate(){
    let dteFrom = new Date()
    let dte = this.maxDate;

    dteFrom.setHours(0, 0, 0);
    dte.setHours(0, 0, 0);

    dteFrom.setMonth(dteFrom.getMonth() - 1);

    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate()};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate()};
  }
}
