import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/models/config';
import { ConfigFieldOperationService } from 'src/app/services/config-field-operation.service';
import { Feeder11FieldSettingsEditComponent } from './feeder11-field-settings-edit/feeder11-field-settings-edit.component';

@Component({
  selector: 'app-feeder11-field-settings',
  templateUrl: './feeder11-field-settings.component.html',
  styleUrls: ['./feeder11-field-settings.component.scss']
})
export class Feeder11FieldSettingsComponent implements OnInit {

  public configs: Config[] = []

  @ViewChild(Feeder11FieldSettingsEditComponent) popupComponentRef : Feeder11FieldSettingsEditComponent | any;

  constructor(private configService: ConfigFieldOperationService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.configService.getAllF11().subscribe(response => {
      if (response.status == 200) {
        this.configs = response.data
      }
      else 
        this.toastr.error(response.message)
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  reloadTable(){
    this.getAll();
  }

  
  openTab() {  
    this.popupComponentRef.openTab(1);
  }
}
