<main class="">
  <div class="user-profile">

    <div class="user-settings-content">

      <div class="first">
        <div class="title-container">
          <div class="title-u">
            Customer Consumption
          </div>
          <div class="subtitle">
            Rread customer consumption
          </div>
        </div>
      </div>
      <div class="header">
        <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
          <app-multi-filter *ngIf="filterApplied" [data]="allFilters" (dataChange)="setFilters($event)"
            [(showFilter)]="showFilter" (dropdownChange)="businessUnitSelected($event)">
          </app-multi-filter>
          <app-range-datepicker [fromDate]="dateFrom" [toDate]="dateTo" [isMonthly]="true" [name]="'Period'"
            (dateRangeSelected)="dateChange($event)">
          </app-range-datepicker>
          <app-search-autocomplete #search [placeholder]="'Search by Meter Number, Account Number & SPN'" [searchText]="searchText" [options]="options"
            [width]="20" (searchChange)="inputChanged($event)"
            (searchAutocompleteChange)="getOptions($event)"></app-search-autocomplete>
        </div>
      </div>
      <table class="standard-table gep-table">
        <thead>
          <tr>
            <th><span>Meter S/N</span></th>
            <th><span>ACCOUNT NUMBER</span></th>
            <th><span>CUSTOMER NAME</span></th>
            <th><span>{{buName}}</span></th>
            <th><span>{{utName}}</span></th>
            <th><span>LAR (MWH)</span></th>
            <th><span>PAR (MWH)</span></th>
            <th><span>CONSUMPTION (MONTHLY)</span></th>
            <th><span>AVERAGE DAILY CONSUMPTION</span></th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let item of data" class="bodyRow clickable actions-click">
            <td class="large-td">
              <span *ngIf="item.msn !== '/'" title="{{item.msn}}">{{item.msn |
                hasValue}}<img alt="Copy" class="icons" src="assets/images-new-design/copy.png"
                  (click)="copyToClipboard(item.msn)"></span>
              <span *ngIf="item.msn === '/'" class="NaN">N/A</span>
            </td>
            <td class="large-td">
              <span *ngIf="item.accountNumber !== '/'" title="{{item.accountNumber}}">{{item.accountNumber |
                hasValue}} <img alt="Copy" class="icons" src="assets/images-new-design/copy.png"
                  (click)="copyToClipboard(item.accountNumber)"></span>
              <span *ngIf="item.accountNumber === '/'" class="NaN">N/A</span>
            </td>
            <td class="large-td">
              <span *ngIf="item.customerName !== '/'" title="{{item.customerName}}">{{item.customerName +
                item.LastName |
                hasValue}}</span>
              <span *ngIf="item.customerName === '/'" class="NaN">N/A</span>
            </td>
            <td class="large-td">
              <span *ngIf="item.buName !== '/'" title="{{item.buName}}">{{item.buName |
                hasValue}}</span>
              <span *ngIf="item.buName === '/'" class="NaN">N/A</span>
            </td>
            <td class="large-td">
              <span *ngIf="item.utName !== '/'" title="{{item.utName}}">{{item.utName |
                hasValue}}</span>
              <span *ngIf="item.utName === '/'" class="NaN">N/A</span>
            </td>
            <td class="large-td">
              <span *ngIf="item.lar !== '/'" title="{{item.lar}}">{{item.lar |
                hasValue}}</span>
              <span *ngIf="item.lar === '/'" class="NaN">N/A</span>
            </td>
            <td class="large-td">
              <span *ngIf="item.par !== '/'" title="{{item.par}}">{{item.par |
                hasValue}}</span>
              <span *ngIf="item.par === '/'" class="NaN">N/A</span>
            </td>

            <td class="large-td">
              <span *ngIf="item.monthlyConsumption !== 0" title="{{item.monthlyConsumption}}">{{item.monthlyConsumption|
                hasValue}}</span>
              <span *ngIf="item.monthlyConsumption === 0" class="NaN">N/A</span>
            </td>
            <td class="large-td">
              <span *ngIf="item.dailyAvg !== 0" title="{{item.dailyAvg}}">{{item.dailyAvg |
                hasValue}}</span>
              <span *ngIf="item.dailyAvg === 0" class="NaN">N/A</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination-wrapper">
        <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
          [currentPage]="currentPage" (pageChange)="pageChange($event)">
        </app-fo-pagination-new>
      </div>
    </div>
  </div>

</main>
