import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from 'src/app/components/common-new-design/pagination/pagination.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { DtService } from 'src/app/services/dt.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-non-communicating-dt',
  templateUrl: './non-communicating-dt.component.html',
  styleUrl: './non-communicating-dt.component.scss'
})
export class NonCommunicatingDTComponent implements OnInit {
  @ViewChild(PaginationComponent) paginationComponentRef!: PaginationComponent;

  @Input() buId: number = 0
  @Input() f11Ids: number[] = []
  @Input() categoryId: number = -1
  @Input() utIds: number[] = []

  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''

  public pageSize: number = 10
  @Input() currentPage: number = 1
  public lastPageSize: number = 1;
  public selectedDt: any = null;

  public data: any[] = []
  count: number = 0
  public checkedList: any[] = []

  public masterSelected: boolean = false;

  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase()
  public utName: string = this.translationService.getByKeyFromCache('UT').toUpperCase()

  @Input() tabMenu!: number;
  @Output() redirect: EventEmitter<any> = new EventEmitter()
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()

  constructor(
    private dtService: DtService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private detectionChange: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    // this.GetData()
  }

  ngOnChanges(changes: SimpleChanges) {

    let reloadTable = false
     if (
      changes.buId !== undefined ||
      changes.buIds !== undefined ||
      changes.utIds !== undefined ||
      changes.categoryId !== undefined ||
      changes.searchText !== undefined || changes.currentPage !== undefined
    ) {
      reloadTable = true;
    }
    if (this.searchText != null) {
      reloadTable = true
    }

    if (changes.menu !== undefined || changes.tabMenu !== undefined) {
      reloadTable = true;
      this.resetFilters();
    }

    if (reloadTable) {
      this.pageChange(1)
    }

  }
  resetFilters() {
    this.categoryId = 0;
    this.buId = 0
    this.utIds = []

    this.detectionChange.detectChanges()
  }

  public GetData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        BuId: this.buId,
        UtIds: this.utIds,
        Search: this.searchText ? this.searchText.name : this.searchText
      }
    };
    this.GetNonCommunicatingDt(obj);
  }

  download() {

    let obj = {
      pageInfo: {
        page: 0,
        pageSize: this.pageSize
      },
      filterParams: {
        OutageCategory: this.categoryId,
        UtIds: this.utIds,
        Feeder11Ids: this.f11Ids,
        outageIds: this.checkedList,
        Search: this.searchText ? this.searchText.name : this.searchText
      }
    };
    this.dtService.DownloadNonCommunicatingReport(obj).subscribe(response => {
      if(response.status === 200){
        window.location.href = response.data;
      }else if(response.data===null){
        this.toastr.info(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].checked)
        this.checkedList.push(this.data[i]);
    }
  }

  isAllSelected() {
    this.masterSelected = this.data.every(function (item: any) {
      return item.checked == true;
    })
    this.getCheckedItemList();
  }
  public pageChange(page: number) {
    this.currentPage = page;
    this.GetData()
  }


  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
      this.checkForDownload(this.data[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.data.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item:any) {
      return item.checked == true;
    })
  }
  private GetNonCommunicatingDt(obj: any) {
    this.dtService.GetNonCommunicatingDt(obj).subscribe(response => {
      if (response.status == 200) {
        this.data = response.data.data
        this.isAlreadyChecked()
        if (obj.pageInfo.page == 1)
          this.count = response.data.count
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An error occurred")
    });
  }
}
