export class DashboardData{
    ssName: string = '';
    a: number = 0;
    b: number = 0;
    c: number = 0;
    d: number = 0;
    ssId: number = 0;
}

export class DashboardCardDataNM {
  totalEnergyDistributed: number = 0;
  totalEnergyConsumptionDt:number= 0;
  totalEnergyRecivedF11: number = 0;
  totalEnergyRecivedF33: number = 0;
  totalEnergyRecived: number = 2000.50;
  totalGridToDtLosses: number = 200.50;
  totalActiveOutage: number = 3000.50;
  customersImpacted: number = 300.50;
  totalActiveFeeders: number = 4000.50;
  totalActiveFeedersPreviousMonth: number = 400.50;

}
