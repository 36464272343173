import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AccountService } from 'src/app/services/account.service';
import { VendingsService } from 'src/app/services/vendings.service';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';

@Component({
  selector: 'app-customer-vends', 
  templateUrl: './customer-vends.component.html',
  styleUrl: './customer-vends.component.scss'
})
export class CustomerVendsComponent implements OnInit{
  public vends: any[] = [];

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public searchText:SearchAutocomplete = new SearchAutocomplete()
  options = ['']
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  //datepicker
  public dateFrom: any = ''
  public dateTo: any = ''
  constructor (
    private toastr: ToastrService,
    private vendingService: VendingsService,
    private accountService:AccountService) { }

  ngOnInit(): void {
      this.setMainDate()
      this.reloadTable()
  }
  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    this.pageChange(1)
  }
  setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()
    
    dteFrom.setHours(2,0,0);
    dte.setHours(2,0,0);
    dteFrom.setDate(dteFrom.getDate() - 1)
    
    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() , day: 1};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
  }
  inputChanged(searchText: SearchAutocomplete){
    this.currentPage = 1
    this.searchText = searchText
    this.pageChange(1)
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null) {
    if (page)
    this.currentPage = page;

  let obj = {
    pageInfo: {
      page: this.currentPage,
      pageSize: this.pageSize
    },
    filterParams: { 
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo), 
      Search: this.searchText.name
    }
  };

  this.vendingService.GetCustomerVends(obj).subscribe(response => {
    this.vends = response.data.data;
    if (obj.pageInfo.page == 1) {
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    };
  }, error => {
    this.toastr.error('An error occurred.');
  });
  }
  
  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name, 
      msnIncluded: false,
      startDate: transformToString(this.dateFrom),
      endDate: transformToString(this.dateTo)
    }
    if(searchText.name.length > 2){
      this.vendingService.getDataForAutocomplete(obj)
      .subscribe({ 
        next : (response) => this.options = response.data,
        error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
      })
    } else
      this.searchComponentRef.resetOptions();
  }

}
