<div class="gep-table-wrapper">
  <div class="table-wrapper">
    <table class="standard-table gep-table">
    <thead>
      <th class="checkbox-td"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" (change)="checkUncheckAll()"></th>
      <th class="large-td"><span title="{{buName}} Name">{{buName}} NAME</span></th>
      <th><span title="11KV Feeder Name">11KV FEEDER NAME</span></th>
      <th class="large-td"><span title="Feeder Number">FEEDER NUMBER</span></th>
      <th><span title="Meter Number">METER NUMBER</span></th>
      <th class="large-td"><span title="Energy Received(MWH)">ENERGY RECEIVED(MWH)</span></th>
      <th class="large-td"><span title="Total Consumption - Customers(MWH)">TOTAL CONSUMPTION(MWH)</span></th>
      <th><span title="Losses(MWH)">LOSSES(MWH)</span></th>
      <th><span title="Losses(%)">LOSSES(%)</span></th>
      <th><span title="Losses(NGN)">LOSSES(NGN)</span></th>
      <th>
        <span title="Comm Status(last 24hrs)">COMM STATUS(LAST 24hrs)</span>
      </th>
      <th>
        <span title="Read Type">READ TYPE</span>
      </th>
      <th></th>
    </thead>
  
    <tbody>
      <tr *ngFor="let item of data" class="clickable actions-click" (click)="alert()">
        <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" ></td>
        <td class="large-td">
          <span *ngIf="item.buName !== '/'" title="{{item.buName}}">{{item.buName | hasValue}}</span>
          <span *ngIf="item.buName === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.feederName !== '/'" title="{{item.feederName}}">{{item.feederName | hasValue}}</span>
          <span *ngIf="item.feederName === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.feederNumber !== '/'" title="{{item.feederNumber}}">{{item.feederNumber | hasValue}}</span>
          <span *ngIf="item.feederNumber === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.meterNumber !== '/'" title="{{item.meterNumber}}">{{item.meterNumber | hasValue}}</span>
          <span *ngIf="item.meterNumber === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.energyReceived !== '/'" title="{{item.energyReceived}}">{{item.energyReceived | hasValue | twoDecimals}}</span>
          <span *ngIf="item.energyReceived === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.energyConsumptionAtDts !== '/'" title="{{item.energyConsumptionAtDts}}">{{item.energyConsumptionAtDts | hasValue| twoDecimals }}</span>
          <span *ngIf="item.energyConsumptionAtDts === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.lossesMwh !== '/'" title="{{item.lossesMwh}}">{{item.lossesMwh | hasValue| twoDecimals }}</span>
          <span *ngIf="item.lossesMwh === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.lossesPercentage !== '/'" title="{{item.lossesPercentage}}">{{item.lossesPercentage | hasValue}}</span>
          <span *ngIf="item.lossesPercentage === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.lossesN !== '/'" title="{{item.lossesN}}">{{item.lossesN | hasValue | twoDecimals }}</span>
          <span *ngIf="item.lossesN === '/'" class="NaN">N/A</span>
        </td>        
        <td class="sync-div">
          <span title="{{item.lastReadSync}}">{{item.lastReadSync | hasValue}}</span>
          <div *ngIf="item.lastReadSyncDate !== '/'" title="{{item.lastReadSyncDate}}">
            <svg class="info-circle" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" xmlns="http://www.w3.org/2000/svg">
              <path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 6.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" fill-rule="nonzero"/>
            </svg>
          </div>
          
        </td>
        <td class="center">
          <span *ngIf="item.typeOfConsumption !== '/'" title="{{item.typeOfConsumption}}">{{item.typeOfConsumption | hasValue}}</span>
          <span *ngIf="item.typeOfConsumption === '/'" class="NaN">N/A</span>
        </td>
        <td>
          <button type="button" id="openPopup" class="see-more-btn">See More</button>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</div>

<div class="pagination-wrapper">
  <app-pagination
  [pageSize]="pageSize"
  [currentPage]="currentPage"
  [count]="count"
  (pageChange)="pageChange($event)"
  >
  </app-pagination>

</div>
