import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsRevenueComponent {
  public PermissionsEnum = PermissionsEnum;
  constructor(private toastr: ToastrService, public authService: AuthService){ }

  alert(){
    this.toastr.warning("Not yet implemented");
  }
}
