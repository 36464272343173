import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class AllPaymentsService {

  private pathApi = this.config.setting['pathApi'] + "AllPayments/";

  constructor(private http: HttpClient,
    private config: AppConfig) {  }
    
  getAllWithPagination(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllWithPagination`, obj);
  }
  getPaymentsCount(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getPaymentsCount`, obj);
  } 
  getAllPaymentsAmount(obj: any):Observable<any>{
    return this.http.post(`${this.pathApi}getAllPaymentsAmount`, obj);
  }
  getAllPaymentsCount(obj: any):Observable<any>{
    return this.http.post(`${this.pathApi}getAllPaymentsCount`, obj);
  }
  getAllPaymentsAmountPrevMonth(obj: any):Observable<any>{
    return this.http.post(`${this.pathApi}getAllPaymentsAmountPrevMonth`, obj);
  }
  getAllPaymentsCountPrevMonth(obj: any):Observable<any>{
    return this.http.post(`${this.pathApi}getAllPaymentsCountPrevMonth`, obj);
  }
  downloadPayments(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadPayments`, obj);
  }
  getAllBusForSelect() : Observable<any>{
    return this.http.get(`${this.pathApi}getBusForSelect`);
  }
  getAllUtsForSelect() : Observable<any>{
    return this.http.get(`${this.pathApi}getAllUtsForSelect`);
  }

  getDataForAutocomplete(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
  }
}
