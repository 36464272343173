<main class="container-fluid" [ngClass]="{'table-hidden': showDetails}">
  <div class="container-wrapper">
    <div class="network-management-title">
      <span>Meters</span>
    </div>

    <div class="menu-tab-filter">
      <app-tab-default [firstText]="'Prepaid'" [firstTabNum]="prepaidCount" [secondText]="'Postpaid'" [secondTabNum]="postpaidCount"
      (tabChanged)="menuChanged($event)" [wom]="true"
        [tabSelected]="tabMenu"></app-tab-default>
    </div>
    <div class="nm-filter-wrapper">


    <app-tab-default *ngIf="tabMenu==1 && !isMap" [firstText]="'Active'" [firstTabNum]="prepaidActiveCount"
    [secondText]="'Inactive'" [secondTabNum]="prepaidInactiveCount"  [thirdText]="'Finalised'" [thirdTabNum]="prepaidFinalizedCount"
    [forthText]="'Undefined'" [fourthTabNum]="prepaidUndefinedCount" [fifthText]="'Account Without Meter'" [fifthTabNum]="prepaidWithoutMeterCount" [paCss]="true" [womCss]="true" (tabChanged)="tabChanged($event)" [wom]="true"
    [tabSelected]="tabSubMenu"></app-tab-default>

    <app-tab-default *ngIf="tabMenu==1 && isMap" [firstText]="'Active'" [firstTabNum]="prepaidActiveCount"
    [secondText]="'Inactive'" [secondTabNum]="prepaidInactiveCount"  [thirdText]="'Finalised'" [thirdTabNum]="prepaidFinalizedCount"
    [forthText]="'Undefined'" [fourthTabNum]="prepaidUndefinedCount"
    [firstImg]="'../../../../assets/map-new/ic_acc_act.png'"
    [secondImg]="'../../../../assets/map-new/ic_acc_ina.png'"
    [thirdImg]="'../../../../assets/map-new/ic_acc_fin.png'"
    [fourthImg]="'../../../../assets/map-new/ic_acc_und.png'"
    [paCss]="true" [womCss]="true" (tabChanged)="tabChanged($event)" [wom]="true" [meterMapCss]="true"
    [tabSelected]="tabSubMenu"></app-tab-default>

  <app-tab-default *ngIf="tabMenu==2  && !isMap" [firstText]="'Active'" [firstTabNum]="postpaidActiveCount" [firstTabNum]="postpaidActiveCount"
    [secondText]="'Inactive'" [secondTabNum]="postpaidInactiveCount"  [thirdText]="'Disconnected'" [thirdTabNum]="postpaidDisconnectedCount"
   [forthText]="'Finalised'" [fourthTabNum]="postpaidFinalizedCount"
   [sixthText]="'Account Without Meter'" [sixthTabNum]="postpaidWithoutMeterCount"
     [paCss]="true" [womCss]="true"  [fifthText]="'Undefined'" [fifthTabNum]="postpaidUndefinedCount"
    (tabChanged)="tabChanged($event)" [wom]="true" [tabSelected]="tabSubMenu"></app-tab-default>

    <app-tab-default *ngIf="tabMenu==2 && isMap" [firstText]="'Active'" [firstTabNum]="postpaidActiveCount" [firstTabNum]="postpaidActiveCount"
    [secondText]="'Inactive'" [secondTabNum]="postpaidInactiveCount"  [thirdText]="'Disconnected'"
    [forthText]="'Finalised'" [fourthTabNum]="postpaidFinalizedCount"  [fifthText]="'Undefined'" [fifthTabNum]="postpaidUndefinedCount"
    [firstImg]="'../../../../assets/map-new/ic_acc_act.png'"
    [secondImg]="'../../../../assets/map-new/ic_acc_ina.png'"
    [thirdImg]="'../../../../assets/map-new/ic_acc_dis.png'"
    [fourthImg]="'../../../../assets/map-new/ic_acc_fin.png'"
    [fifthImg]="'../../../../assets/map-new/ic_acc_und.png'"
    [paCss]="true" [womCss]="true" [meterMapCss]="true"
    (tabChanged)="tabChanged($event)" [wom]="true" [tabSelected]="tabSubMenu"></app-tab-default>
</div>
    <div class="filters-container" *ngIf="!(tabMenu == 1 && tabSubMenu == 5) && !(tabMenu == 2 && tabSubMenu == 6)">
      <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
        <app-multi-filter *ngIf="filterApplied" [data]="allFilters" (dataChange)="applyFilter($event)"
          [(showFilter)]="showFilter" (dropdownChange)="dropdownSelected($event)">
        </app-multi-filter>

        <app-search-autocomplete #search [placeholder]="searchPlaceholder" [searchText]="searchText" [options]="options"
          (searchChange)="searchChange($event)" [width]="33"
          (searchAutocompleteChange)="getOptions($event)"></app-search-autocomplete>
      </div>

      <div class="download-button">
        <div *ngIf="!isMap && authService.isAuthorized(PermissionsEnum.metermanagement_meters_changestatus)" class="button-wrapper">
          <div class="button">
            <dropdown-meter-details class="dropdown-status" [data]="meterStatuses" [header]="'Change Status'"
               (selectItemChange)="changeStatus($event)"></dropdown-meter-details>
          </div>
        </div>
        <app-map-table-button-new [isMap]="isMap"
        (filterClicked)="mapChanged($event)">
        </app-map-table-button-new>

      </div>
    </div>
    <app-mm-meter-grid *ngIf="!isMap && !(tabMenu == 1 && tabSubMenu == 5) && !(tabMenu == 2 && tabSubMenu == 6) " #child [meterItemType]="meterItemType" [meterItemSubType]="subMenu" [meterIds]="meterIds"
      [search]="searchText" [buIds]="buIds" [customerTypeValue]="customerTypeValue" [utIds]="utIds" [dtIds]="dtIds" [amiIds]="amiIds"
      (showDetails)="openDetails($event)"></app-mm-meter-grid>
      <div *ngIf="(tabMenu == 1 && tabSubMenu == 5)||(tabMenu == 2 && tabSubMenu == 6)">
        <app-unmetered
        [businessUnitsNew]="businessUnitsNew"
        [dtsMap]="dtsMap"
        [undertakingsNew]="undertakingsNew"
        [customerPrepaidFlag]="customerPrepaidFlagUnmetered"
        [metersFilterFunction]="applyFilter.bind(this)"
        ></app-unmetered>
        </div>
      <div class="map-wrapper" *ngIf="isMap">
      <adora-map-clusterized
       [searchValue]="searchText.name"
        [customersMap]="true"
        [buIds]="buIds" [utIds]="utIds"
        [meterItemType]="meterItemType"
        [zoom]="11"></adora-map-clusterized>
        </div>
  </div>

</main>

<app-meter-details
  *ngIf="showDetails"
  [meterDetails]="detailsData"
  [currentMeterStatus]="detailsData.meterStatus"
  [currentAccStatus]="detailsData.accStatus"
  (backClicked)="showDetails = false;"
  (parentComp)="GetCardsCount()"
  ></app-meter-details>
