<div class="gep-table-wrapper">
    <div class="table-wrapper">
        <table class="standard-table gep-table">
            <thead>
                <th><span title="Account number">{{'Account number' | uppercase}}</span></th>
                <th><span title="Account name">{{'Account name' | uppercase}}</span></th>
                <th><span title="Customer type">{{'Customer type' | uppercase}}</span></th>
                <th><span title="Meter number">{{'Meter number' | uppercase}}</span></th>
                <th><span title="Meter make">{{'Meter make' | uppercase}}</span></th>
                <th><span title="Connection type">{{'Connection type' | uppercase}}</span></th>
                <th><span title="Meter voltage">{{'Meter voltage' | uppercase}}</span></th>
                <th><span title="Dt name">{{'Dt name' | uppercase}}</span></th>
                <th><span title="Dt number">{{'Dt number' | uppercase}}</span></th>
                <th><span title="{{buName}}">{{buName | uppercase}}</span></th>
                <th><span title="{{utName}}">{{utName | uppercase}}</span></th>
                <th><span title="Feeder">{{'Feeder' | uppercase}}</span></th>
                <th><span title="Dcu Sn">{{'Dcu Sn' | uppercase}}</span></th>
                <th><span title="Tariff">{{'Tariff' | uppercase}}</span></th>
                <th><span title="Meter Max Digit">{{'Meter Max Digit' | uppercase}}</span></th>
                <th><span title="Installation Date">{{'Installation Date' | uppercase}}</span></th>
                <th><span title="Remote Disconnection Date">{{'Remote Disconnection Date' | uppercase}}</span></th>
                <th>
                    <div class="dropdown">
                        <div tabindex="3" class="dropdown-btn" (click)="commStatusClicked(1)" (blur)="commStatusClicked(0)">
                          <span title="Communication Status">COMMUNICATION STATUS</span>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 4.66667H2.5M9.16667 4.66667C9.16667 3.46958 10.1363 2.5 11.3333 2.5C12.5304 2.5 13.5 3.46958 13.5 4.66667C13.5 5.86375 12.5304 6.83333 11.3333 6.83333C10.1363 6.83333 9.16667 5.86375 9.16667 4.66667ZM13.5 11.3333H8.16667M8.16667 11.3333C8.16667 12.5304 7.19707 13.5 6 13.5C4.80292 13.5 3.83333 12.5304 3.83333 11.3333M8.16667 11.3333C8.16667 10.1363 7.19707 9.16667 6 9.16667C4.80292 9.16667 3.83333 10.1363 3.83333 11.3333M3.83333 11.3333H2.5" stroke="#858585" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="dropdown-expanded" *ngIf="commStatusOpened">
                          <span (mousedown)="commStatusSelected(0)">All</span>
                          <span (mousedown)="commStatusSelected(1)">Yes</span>
                          <span (mousedown)="commStatusSelected(2)">No</span>
                        </div>
                      </div>
                </th>
                <th><span></span></th>
            </thead>
            <tbody>
                <tr *ngFor="let item of data; let i = index">
                    <td>
                        <span class="span-copy" *ngIf="item.accountNumber !== '/'" title="{{item.accountNumber}}">{{item.accountNumber | hasValue}}
                            <img alt="Copy" class="icons" src="assets/images-new-design/copy.png"(click)="copyToClipboard(item.accountNumber)">
                        </span>
                        <span *ngIf="item.accountNumber === '/'" class="NaN">N/A</span>
                    </td>
                    <td class="large-td">
                        <span *ngIf="item.accountName !== '/'" title="{{item.accountName}}">{{item.accountName | hasValue}}</span>
                        <span *ngIf="item.accountName === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.customerType !== '/'" title="{{item.customerType}}">{{item.customerType | hasValue}}</span>
                        <span *ngIf="item.customerType === '/'" class="NaN">N/A</span>
                    </td>
                    <td >
                        <span class="span-copy" *ngIf="item.meterNumber !== '/'" title="{{item.meterNumber}}">{{item.meterNumber | hasValue}}
                            <img alt="Copy" class="icons" src="assets/images-new-design/copy.png"(click)="copyToClipboard(item.meterNumber)">
                        </span>
                        <span *ngIf="item.meterNumber === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.meterMake !== '/'" title="{{item.meterMake}}">{{item.meterMake | hasValue}}</span>
                        <span *ngIf="item.meterMake === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.connectionType !== '/' && item.connectionType" title="{{item.connectionType}}">{{item.connectionType | hasValue}}</span>
                        <span *ngIf="item.connectionType === '/' || !item.connectionType" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.meterVoltage !== '/'" title="{{item.meterVoltage}}">{{item.meterVoltage | hasValue}}</span>
                        <span *ngIf="item.meterVoltage === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.dtName !== '/'" title="{{item.dtName}}">{{item.dtName | hasValue}}</span>
                        <span *ngIf="item.dtName === '/'" class="NaN">N/A</span>
                    </td>
                    <td class="large-td">
                        <span *ngIf="item.dtNo !== '/'" title="{{item.dtNo}}">{{item.dtNo | hasValue}}</span>
                        <span *ngIf="item.dtNo === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.buName !== '/'" title="{{item.buName}}">{{item.buName | hasValue}}</span>
                        <span *ngIf="item.buName === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.utName !== '/'" title="{{item.utName}}">{{item.utName | hasValue}}</span>
                        <span *ngIf="item.utName === '/'" class="NaN">N/A</span>
                    </td>
                    <td class="large-td">
                        <span *ngIf="item.feederName !== '/'" title="{{item.feederName}}">{{item.feederName | hasValue}}</span>
                        <span *ngIf="item.feederName === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.dcuSn !== '/' && item.dcuSn" title="{{item.dcuSn}}">{{item.dcuSn | hasValue}}</span>
                        <span *ngIf="item.dcuSn === '/' || !item.dcuSn" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.tariff !== '/'" title="{{item.tariff}}">{{item.tariff | hasValue}}</span>
                        <span *ngIf="item.tariff === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.meterMaxDigit !== '/' && item.meterMaxDigit" title="{{item.meterMaxDigit}}">{{item.meterMaxDigit | hasValue}}</span>
                        <span *ngIf="item.meterMaxDigit === '/' || !item.meterMaxDigit" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.installationDate !== '/' && item.installationDate" title="{{item.installationDate}}">{{item.installationDate | dateTimeFormat}}</span>
                        <span *ngIf="item.installationDate === '/' || !item.installationDate" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.disconnectedDate !== '/' && item.disconnectedDate" title="{{item.disconnectedDate}}">{{item.disconnectedDate | dateTimeFormat}}</span>
                        <span *ngIf="item.disconnectedDate === '/' || !item.disconnectedDate" class="NaN">N/A</span>
                    </td>
                    <td *ngIf="item.communicationStatus !== '/' && item.communicationStatus == 'Yes'">
                        <span class="commStatus commStatusYes">
                            Yes
                            <ng-container>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.32499 11.6H8.67499V7.09999H7.32499V11.6ZM7.99603 5.74999C8.18617 5.74999 8.34686 5.68568 8.47811 5.55707C8.60936 5.42844 8.67499 5.26907 8.67499 5.07894C8.67499 4.88881 8.61068 4.72811 8.48207 4.59686C8.35344 4.46561 8.19407 4.39999 8.00394 4.39999C7.81381 4.39999 7.65311 4.46429 7.52186 4.59291C7.39061 4.72153 7.32499 4.88091 7.32499 5.07103C7.32499 5.26117 7.38929 5.42186 7.51791 5.55311C7.64653 5.68436 7.80591 5.74999 7.99603 5.74999ZM8.00516 15.2C7.01421 15.2 6.08124 15.0125 5.20624 14.6375C4.33124 14.2625 3.56561 13.7469 2.90936 13.0906C2.25311 12.4344 1.73749 11.669 1.36249 10.7945C0.987488 9.92004 0.799988 8.98566 0.799988 7.9914C0.799988 6.99713 0.987488 6.06561 1.36249 5.19686C1.73749 4.32811 2.25311 3.56561 2.90936 2.90936C3.56561 2.25311 4.33098 1.73749 5.20545 1.36249C6.07994 0.987488 7.01431 0.799988 8.00858 0.799988C9.00285 0.799988 9.93436 0.987488 10.8031 1.36249C11.6719 1.73749 12.4344 2.25311 13.0906 2.90936C13.7469 3.56561 14.2625 4.32951 14.6375 5.20106C15.0125 6.07263 15.2 7.00388 15.2 7.99481C15.2 8.98576 15.0125 9.91874 14.6375 10.7937C14.2625 11.6687 13.7469 12.4344 13.0906 13.0906C12.4344 13.7469 11.6705 14.2625 10.7989 14.6375C9.92735 15.0125 8.9961 15.2 8.00516 15.2ZM7.99999 13.85C9.62499 13.85 11.0062 13.2812 12.1437 12.1437C13.2812 11.0062 13.85 9.62499 13.85 7.99999C13.85 6.37499 13.2812 4.99374 12.1437 3.85624C11.0062 2.71874 9.62499 2.14999 7.99999 2.14999C6.37499 2.14999 4.99374 2.71874 3.85624 3.85624C2.71874 4.99374 2.14999 6.37499 2.14999 7.99999C2.14999 9.62499 2.71874 11.0062 3.85624 12.1437C4.99374 13.2812 6.37499 13.85 7.99999 13.85Z" fill="#188D52"/>
                                    <title>{{item.communicationDate | dateTimeFormat}}</title>
                                </svg>
                            </ng-container>
                        </span>
                    </td>
                    <td *ngIf="item.communicationStatus !== '/' && item.communicationStatus == 'No'">
                        <span class="commStatus">
                            No
                            <ng-container *ngIf="item.communicationDate && item.communicationDate !== '/'">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.32499 11.6H8.67499V7.09999H7.32499V11.6ZM7.99603 5.74999C8.18617 5.74999 8.34686 5.68568 8.47811 5.55707C8.60936 5.42844 8.67499 5.26907 8.67499 5.07894C8.67499 4.88881 8.61068 4.72811 8.48207 4.59686C8.35344 4.46561 8.19407 4.39999 8.00394 4.39999C7.81381 4.39999 7.65311 4.46429 7.52186 4.59291C7.39061 4.72153 7.32499 4.88091 7.32499 5.07103C7.32499 5.26117 7.38929 5.42186 7.51791 5.55311C7.64653 5.68436 7.80591 5.74999 7.99603 5.74999ZM8.00516 15.2C7.01421 15.2 6.08124 15.0125 5.20624 14.6375C4.33124 14.2625 3.56561 13.7469 2.90936 13.0906C2.25311 12.4344 1.73749 11.669 1.36249 10.7945C0.987488 9.92004 0.799988 8.98566 0.799988 7.9914C0.799988 6.99713 0.987488 6.06561 1.36249 5.19686C1.73749 4.32811 2.25311 3.56561 2.90936 2.90936C3.56561 2.25311 4.33098 1.73749 5.20545 1.36249C6.07994 0.987488 7.01431 0.799988 8.00858 0.799988C9.00285 0.799988 9.93436 0.987488 10.8031 1.36249C11.6719 1.73749 12.4344 2.25311 13.0906 2.90936C13.7469 3.56561 14.2625 4.32951 14.6375 5.20106C15.0125 6.07263 15.2 7.00388 15.2 7.99481C15.2 8.98576 15.0125 9.91874 14.6375 10.7937C14.2625 11.6687 13.7469 12.4344 13.0906 13.0906C12.4344 13.7469 11.6705 14.2625 10.7989 14.6375C9.92735 15.0125 8.9961 15.2 8.00516 15.2ZM7.99999 13.85C9.62499 13.85 11.0062 13.2812 12.1437 12.1437C13.2812 11.0062 13.85 9.62499 13.85 7.99999C13.85 6.37499 13.2812 4.99374 12.1437 3.85624C11.0062 2.71874 9.62499 2.14999 7.99999 2.14999C6.37499 2.14999 4.99374 2.71874 3.85624 3.85624C2.71874 4.99374 2.14999 6.37499 2.14999 7.99999C2.14999 9.62499 2.71874 11.0062 3.85624 12.1437C4.99374 13.2812 6.37499 13.85 7.99999 13.85Z" fill="#C03438"/>
                                    <title>{{item.communicationDate | date}}</title>
                                </svg>
                            </ng-container>
                        </span>
                    </td>
                    <td><button type="button" id="openPopup" class="btn btn-more" (click)="reconnectionMeter(item.id)">Reconnect</button>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="pagination-wrapper">
  <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
</div>
