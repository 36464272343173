import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Concentrator } from 'src/app/models/concentrator.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConcentratorService } from 'src/app/services/concentrator.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-dcu-create',
  templateUrl: './dcu-create.component.html',
  styleUrls: ['./dcu-create.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})

export class DcuCreateComponent {
  public selectedConcentrator: Concentrator = new Concentrator()
  showShadow = false;
  // public amis: any = [];
  public amiName: string = this.translationService.getByKeyFromCache('AMI');
  @Output() parentComp = new EventEmitter();
  @Input() amis: any = [];

  @Input() animationState = 'out';
  @ViewChild(DcuCreateComponent) popupComponentRef!: DcuCreateComponent;
  constructor(private toastr: ToastrService,
    private ref: ElementRef,
    private authService: AuthService,
    private concentratorService: ConcentratorService,
    private amiService: AmiService,
    private translationService: TranslationService) { }


  // getAllForSelect() {
  //   this.amiService.getAllForSelect().subscribe(response => {
  //     this.amis = response.data;
  //   }, error => {
  //     this.toastr.error('An error occurred.');
  //   });
  // }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }
  closeTab() {
    this.popupComponentRef.changeState(2);
  }
  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.dcu-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }

  changeState(number: Number, data?: any) {
    if (data)
      this.selectedConcentrator = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
  }
  save() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate)) {
      this.toastr.error("You don't have permission.");
      return;
    }

    if (Number.isNaN(Number(this.selectedConcentrator?.latitude))) {
      this.toastr.error('Latitude must be numeric value between -90 and 90.');
      return;
    }

    if (Number(this.selectedConcentrator?.latitude) < -90 || Number(this.selectedConcentrator?.latitude) > 90) {
      this.toastr.error('Latitude must be in -90 to 90 range.');
      return;
    }

    if (Number.isNaN(Number(this.selectedConcentrator?.longitude))) {
      this.toastr.error('Longitude must be numeric value between -180 and 180.');
      return;
    }

    if (Number(this.selectedConcentrator?.longitude) < -180 || Number(this.selectedConcentrator?.longitude) > 180) {
      this.toastr.error('Longitude must be in -180 to 180 range.');
      return;
    }

    if(this.selectedConcentrator.serialNumber != '' && this.selectedConcentrator.amiId != 0){
      this.concentratorService.save(this.selectedConcentrator).subscribe(response => {
        if (response.status === 200) {
          this.toastr.success(response.message);
          this.changeState(2);
          this.parentComp.emit();
          this.resetSelectedConcentrator();
        } else
          this.toastr.error(response.message);
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }

  }

  resetSelectedConcentrator(){
    this.selectedConcentrator = new Concentrator();
  }
}
