import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsDelta } from 'src/app/models/permissionsDelta.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Role } from 'src/app/models/role.model';
import { AuthService } from 'src/app/services/auth.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-role-slider',
  templateUrl: './role-slider.component.html',
  styleUrls: ['./role-slider.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class RoleSliderComponent implements OnInit {

  @Output() reloadTable: EventEmitter<any> = new EventEmitter()
  @Input() animationState = 'out';
  showShadow = false;
  public PermissionsEnum = PermissionsEnum;
  @ViewChild("f") f: any;

  public activeTabs: number[] = []
  public permissionSettings: any = []
  public selectedRole: Role = new Role();
  public permissionsSnapshot: number[] = []
  public permissionsDelta: PermissionsDelta = new PermissionsDelta()

  public availableModules: string[] = [];

  public numberOfSections: number = 5

  constructor(
    private ref: ElementRef,
    private roleService: RoleService,
    private toastr: ToastrService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getAvailableModules();
    //this.changeState(1)
  }

  getAvailableModules() {
    this.availableModules = this.authService.getCurrentUser().availableModules;
    this.getAllPermissionSettings();
  }

  changeState(number: Number, data?: Role) {
    if (number === 1) {
      this.toggleShowDiv("open");
      if(data == null){
        this.selectedRole = new Role()
      }
      else{
        this.selectedRole = new Role()
        this.selectedRole.id = data.id
        this.selectedRole.name = data.name
        this.selectedRole.permissions = data.permissions
        for(let i = 0; i < this.numberOfSections; i++){
          if(this.tabHavePermissions(i)){
            this.activeTabs.push(i)
          }
        }
      }

      this.permissionsDelta = new PermissionsDelta()
      this.permissionsSnapshot = []
      this.permissionsSnapshot = [...this.selectedRole.permissions]
      this.showShadow = true;
    }
    if (number === 2) {
      this.resetVariables()
      this.toggleShowDiv("close");
      this.animationState = 'out';
      if(this.f)
        this.f.submitted = false;

      this.showShadow = false;

    }
  }

  private tabHavePermissions(tab: number){
    if(this.selectedRole.permissions == undefined){
      return false;
    }
    switch(tab){
      case 0:
        if(this.selectedRole.permissions.filter(( x: PermissionsEnum) => PermissionsEnum[x]?.includes('global_')).length > 0)
          return true
        return false
      case 1:
      if(this.selectedRole.permissions.filter(( x: PermissionsEnum) => PermissionsEnum[x]?.includes('networkmanagement_')).length > 0)
          return true
        return false
      case 2:
      if(this.selectedRole.permissions.filter(( x: PermissionsEnum) => PermissionsEnum[x]?.includes('revenueprotection_')).length > 0)
          return true
        return false
      case 3:
        if(this.selectedRole.permissions.filter(( x: PermissionsEnum) => PermissionsEnum[x]?.includes('metermanagement_')).length > 0)
          return true
        return false
      case 4:
        if(this.selectedRole.permissions.filter(( x: PermissionsEnum) => PermissionsEnum[x]?.includes('fieldoperations_')).length > 0)
          return true
        return false
      default:
        return false
    }
  }

  resetVariables(){
    this.activeTabs = []
    this.permissionsDelta = new PermissionsDelta()
    this.selectedRole = new Role()
    this.permissionsSnapshot = []
    this.selectedRole.permissions = []
    this.permissionsSnapshot = []
  }

  save(){
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_roles_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    if (this.selectedRole.name == '') {
      this.toastr.error("Name is required.");
      return;
    }

    console.log(this.permissionsSnapshot)
    this.selectedRole.permissions.forEach((element: any) => {
      let id = this.permissionsSnapshot.indexOf(element)
      if(id == -1){
        this.permissionsDelta.addPermissions.push(element)
      }
    });

    this.permissionsSnapshot.forEach((element: any) => {
      let id = this.selectedRole.permissions.indexOf(element)
      if(id == -1){
        this.permissionsDelta.removePermissions.push(element)
      }
    });

    this.permissionsDelta.id = this.selectedRole.id
    this.permissionsDelta.name = this.selectedRole.name
    console.log(this.permissionsDelta)

    this.roleService.save(this.permissionsDelta).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.reloadTable.emit();
        if(response.data !== "" && response.data !== null){
          localStorage.setItem('permissions', response.data);
        }
        //this.reloadTable();
        this.changeState(2)
      }else{
        this.toastr.error(response.message);
      }

    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.role-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isSliderClicked) {
          this.changeState(2);
        }
      }
  }

  public activate(tab: number, tabName: string){
    if(this.availableModules.includes(tabName) || tabName === 'Global Settings'){
      if(!this.isActive(tab)){
        this.activeTabs.push(tab)
      }
      else{
        const index = this.activeTabs.indexOf(tab);
        this.activeTabs.splice(index, 1);
      }
    }
  }

  isActive(tab: number){
    return this.activeTabs.includes(tab)
  }

  isAnyActive(){
    return this.activeTabs.length != 0
  }

  getAllPermissionSettings(){
    let obj = {
      modules: this.availableModules
    }
    this.roleService.getAllPermissionSettings(obj).subscribe(response => {
      this.permissionSettings = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  isChecked(listPermissions:any){
    let checked = true;
    if(!this.selectedRole.permissions.includes(listPermissions))
      checked = false;
    return checked;
  }

  isCheckedGroup(itemSettings: any){
    let checked = true

    itemSettings.permissions.forEach((element: any) => {
      if(!this.selectedRole.permissions.includes(element.parmission)){
        checked = false
      }
    });

    return checked
  }

  private addPermission(permission: number){
    const tempId = this.selectedRole.permissions.indexOf(permission)
    if(tempId == -1){
      this.selectedRole.permissions.push(permission)
    }
  }

  private removePermission(permission: number){
    const idSelected = this.selectedRole.permissions.indexOf(permission)
    if(idSelected != -1){
      this.selectedRole.permissions.splice(idSelected, 1)
    }
  }

  onChangePermission(permission:any, event:any = null){
    if(!this.isChecked(permission)){
      this.addPermission(permission)
    }
    else if(this.isChecked(permission)){
      this.removePermission(permission)
    }
  }

  onGroupChangePermission(header: any, event: any = null){
    if(event === true){
      header.permissions.forEach((element: any) => {
        if(!this.isChecked(element.parmission)){
          this.addPermission(element.parmission)
        }
      });
    }
    else{
      header.permissions.forEach((element: any) => {
        if(this.isChecked(element.parmission)){
          this.removePermission(element.parmission)
        }
      });
    }
  }
}
