import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class MeterManagementDashboardService {

  private pathApi = this.config.setting['pathApi'] + "MeterManagementDashboard/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    GetAccMeters(): Observable<any>{
        return this.http.get(`${this.pathApi}GetAccMeters`);
    }

    getAccMeterMakes(): Observable<any> {
        return this.http.get(`${this.pathApi}getAccMeterMakes`);
    }

      getMeterTriggersCardData(): Observable<any> {
        return this.http.get(`${this.pathApi}getMeterTriggersCardData`);
    }
    
      getCustomerStatsCardData(): Observable<any> {
        return this.http.get(`${this.pathApi}getCustomerStatsCardData`);
    }

    getOnlineAndOfflineNumber(): Observable<any> {
      return this.http.get(`${this.pathApi}getOnlineAndOfflineNumber`);
    }

    refreshData(): Observable<any> {
      return this.http.get(`${this.pathApi}refreshData`);
    }

  }