<app-deactivate-popup (parentComp)="activate()" [count]="count"></app-deactivate-popup>

<div class="details-task-wrapper" *ngIf="showTaskDetails == viewsEnum.ShowDetails || showTaskDetails == viewsEnum.Edit">
    <app-wom-details *ngIf="showTaskDetails == viewsEnum.ShowDetails"
    (changeView)="changeTaskView($event)"
    [taskId]="selectedTask.id"
    ></app-wom-details>
    <app-wom-create-task-page *ngIf="showTaskDetails == viewsEnum.Edit"
        (changeView)="changeTaskView($event)"
        [taskId]="selectedTask.id"
    ></app-wom-create-task-page>
</div>



<app-reassign-task-popup  (parentComp)="reloadTable()" (confirmReassign)="reassignTask()" [checkedList]="checkedList" *ngIf="showAgentPopup===true" [teamId]="teamId"></app-reassign-task-popup>

<main class="container-fluid" *ngIf="showTaskDetails == viewsEnum.View">
    <div class="gep-container">
        <div class="details">
            <div class="close-content">
                <div class="header-back" (click)="back()">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.52116 7.83342H12.8337C13.0698 7.83342 13.2677 7.75356 13.4274 7.59384C13.5871 7.43412 13.667 7.2362 13.667 7.00009C13.667 6.76398 13.5871 6.56606 13.4274 6.40634C13.2677 6.24662 13.0698 6.16676 12.8337 6.16676H3.52116L7.60449 2.08342C7.77116 1.91676 7.85102 1.72231 7.84408 1.50009C7.83713 1.27787 7.75033 1.08342 7.58366 0.916758C7.41699 0.76398 7.22255 0.684119 7.00033 0.677174C6.7781 0.67023 6.58366 0.750091 6.41699 0.916758L0.916992 6.41676C0.833659 6.50009 0.774632 6.59037 0.739909 6.68759C0.705187 6.78481 0.687826 6.88898 0.687826 7.00009C0.687826 7.1112 0.705187 7.21537 0.739909 7.31259C0.774632 7.40981 0.833659 7.50009 0.916992 7.58342L6.41699 13.0834C6.56977 13.2362 6.76074 13.3126 6.98991 13.3126C7.21908 13.3126 7.41699 13.2362 7.58366 13.0834C7.75033 12.9168 7.83366 12.7188 7.83366 12.4897C7.83366 12.2605 7.75033 12.0626 7.58366 11.8959L3.52116 7.83342Z" fill="#1C1B1F"/>
                      </svg>
                  </div>
                <div class="editAgent">
                    <button class="editbtn" (click)="sendObjectToAnotherComponent()">Edit Agent <img alt=""
                            src="assets/images-new-design/stylus.png"></button>
                </div>
            </div>
            <div class="header">
                <ng-container *ngIf="selectedfieldAgent.image; else defaultImage">
                    <div class="profile">
                        <img class="profile" [src]="selectedfieldAgent.image" alt="Agent">
                    </div>
                </ng-container>

                <ng-template #defaultImage>
                    <div class="profile">
                        <img class="profile" src="assets/images-new-design/defaultImg.png"
                            alt="Default">
                    </div>
                </ng-template>



                <div class="text-header">
                    {{ selectedfieldAgent.userName | hasValue  }} <div class="design">
                        {{ selectedfieldAgent.usersDesignation }}</div>
                </div>
            </div>

            <div class="download-content">

                <div class="form">
                    <label class="label-opt">Status</label>
                    <div class="status" [ngClass]="{'blue-background': selectedfieldAgent.status === 'Engaged', 'green-background': selectedfieldAgent.status === 'Unengaged', 'disabled' :
                    selectedfieldAgent.status === 'Inactive'}">{{selectedfieldAgent.status}}</div>
                </div>
                <div class="form">
                    <label class="label-opt">Agent ID</label>
                    <div class="formEmail">{{selectedfieldAgent.agentId | hasValue }}</div>
                    <div ><img alt="Copy" class="icons" src="assets/images-new-design/copy.png"
                        (click)="copyToClipboard(selectedfieldAgent.agentId)"></div>
                </div>
                <div class="form">
                    <label class="label-opt">Email</label>

                        <div class="formEmail">
                            <div>{{selectedfieldAgent.email | hasValue }}</div>
                        </div>
                        <div class="mail"><img alt="Copy" class="icons" src="assets/images-new-design/copy.png"
                                (click)="copyToClipboard(selectedfieldAgent.email)"></div>
                </div>

                <div class="form">
                    <label class="label-opt">Phone</label>

                        <div class="formEmail">
                            <div>{{selectedfieldAgent.phoneNumber | hasValue }}</div>
                        </div>
                        <div><img alt="Copy" class="icons" src="assets/images-new-design/copy.png"
                                (click)="copyToClipboard(selectedfieldAgent.phoneNumber)"></div>

                </div>
                <div class="form">
                    <label class="label-opt">Designation</label>
                    <div class="formEmail">{{selectedfieldAgent.usersDesignation | hasValue }}</div>
                </div>
                <div class="form">
                    <label class="label-opt">{{buName}}</label>
                    <div class="formEmail">{{selectedfieldAgent.buName | hasValue }}</div>
                </div>
                <div class="form">
                    <label class="label-opt">{{utName}}</label>
                    <div class="formEmail">{{selectedfieldAgent.utName | hasValue }}</div>
                </div>
                <div class="form">
                    <label class="label-opt">Date Created</label>
                    <div class="formEmail">{{date | hasValue }}</div>
                </div>

            </div>
            <div class="horizontal-line hr2"></div>

            <div class="bottom">
                <div class="editDlt">

                    <div class="editbtn" (click)="openPopup()">
                        {{ isActive(1) ? 'Deactivate Agent' : 'Activate Agent' }}
                        <div class="switch-wrapper" [ngClass]="{'selected': isActive(1)}">
                            <div class="switch"></div>
                        </div>
                    </div>


                </div>
            </div>
        </div>


        <div class="task">
            <div class="task-header">
                <div class="header t">Tasks
                </div>
                <div class="editAgent">
                    <button class="editbtn"  (click)="open()">Reassign</button>
                </div>
            </div>
            <div class="filters-container">
                <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

                    <app-multi-filter *ngIf="filterApplied"
                        [data]="allFilters"
                        [(showFilter)]="showFilter"
                        [search]="false"
                        [height] = "height"
                        (dropdownChange)="dropdownSelected($event)"
                    >
                    </app-multi-filter>

                    <app-range-datepicker
                        [fromDate]="dateFrom"
                        [toDate]="dateTo"
                        [name]="'Period'"
                        (dateRangeSelected)="dateChange($event)">
                    </app-range-datepicker>


                    <app-search-autocomplete
                    #search
                    [placeholder]="searchPlaceholder"
                    [searchText]="searchText"
                    [options]="options"
                    (searchChange)="searchChange($event)"
                    (searchAutocompleteChange)="getOptions($event)"
                >
                </app-search-autocomplete>
                </div>
            </div>
                <div class="taskItems">
                        <div class="item" *ngFor="let item of tasks">
                            <div class="first">
                                <div class="itemStatus">
                                    <div [ngClass]="{'black-bg': item.status === 'Canceled',  'red-bg': item.status ==='Overdue', 'yellow-bg': item.status === 'Ongoing', 'green-bg' :
                                    item.status === 'Completed' || item.status==='Unassigned'}">

                                        <img alt="Canceled" *ngIf="item.status === 'Canceled'" src="assets/images-new-design/block.png">
                                        <img alt="Overdue" *ngIf="item.status === 'Overdue'" src="assets/images-new-design/error.png">
                                        <img alt="Ongoing" *ngIf="item.status === 'Ongoing'"
                                            src="assets/images-new-design/clock_loader_40.png">
                                        <img alt="Completed" *ngIf="item.status === 'Completed' || item.status === 'Unassigned'"
                                            src="assets/images-new-design/check_circle.png" />

                                        {{item.status}}
                                    </div>
                                    <div class="itemDetail">{{item.id}}<img alt="Copy" class="icons" src="assets/images-new-design/content_copy.svg"
                                    (click)="copyToClipboard(item.id)"> </div>
                                    {{date2 }}
                                </div>

                            <div class="checkboxItem" *ngIf="item.status != 'Canceled' && item.status != 'Completed'">
                                <input class="input-checkmark" type="checkbox" value="{{item.id}}"
                                    [(ngModel)]="item.checked"  (change)="isAllSelected()" /></div>
                            </div>
                            <div class="desc"  (click)="showTask(item)">
                                {{item.description}}
                            </div>

                        </div>
                </div>
        </div>
    </div>

    </main>
