import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/models/config';
import { ConfigFieldOperationService } from 'src/app/services/config-field-operation.service';
import { DtFieldSettingsEditComponent } from './dt-field-settings-edit/dt-field-settings-edit.component';

@Component({
  selector: 'app-dt-field-settings',
  templateUrl: './dt-field-settings.component.html',
  styleUrls: ['./dt-field-settings.component.scss']
})
export class DtFieldSettingsComponent implements OnInit {

  @ViewChild(DtFieldSettingsEditComponent) popupComponentRef!: DtFieldSettingsEditComponent;

  public configs: Config[] = []

  constructor(private configService: ConfigFieldOperationService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.configService.getAll().subscribe(response => {
      if (response.status == 200) {
        this.configs = response.data
      }
      else 
        this.toastr.error(response.message)
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  openTab() {  
    this.popupComponentRef.openTab();
  }

  reloadTable(){
    this.getAll();
  }
}
