import { Component, OnInit } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { transformToString } from 'src/app/helpers/dateUtils';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { Vendings } from 'src/app/models/vendings.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommandRequestService } from 'src/app/services/command-request.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { VendingsService } from 'src/app/services/vendings.service';

@Component({
  selector: 'app-vendings',
  templateUrl: './vendings.component.html',
  styleUrls: ['./vendings.component.scss']
})
export class VendingsComponent implements OnInit {
  public showPaging: boolean = false;

  public currentPage: number = 1
  public pageSize: number = 10
  public count: number = 0;
  public lastPageSize: number = 1

  public countForDownload: number = 0;

  public searchText: string = ''

  private today = new Date()
  public dateTo: any = new NgbDate(
    this.today.getFullYear(),
    this.today.getMonth() + 1,
    this.today.getDate()
  )
  public dateFrom: any = new NgbDate(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate()
  )

  public vendings: Vendings[] = []

  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')

  public businessUnits: any = []
  public undertakings: any = []

  public buSelected: DictionaryItem = new DictionaryItem(0, this.buName)
  public utSelected: DictionaryItem = new DictionaryItem(0, this.utName)

  constructor(
    private vendingsService: VendingsService,
    private commandRequestService: CommandRequestService,
    public businessUnitService: BusinessUnitService,
    public undertakingService: UndertakingService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private location: Location
    ) { }

  ngOnInit(): void {
    this.reloadTable()
    this.getBusForSelect()
    this.getUtsForSelect()
    this.showPaging=true;

  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page

    let obj = this.generateObject()

    this.vendingsService.getAll(obj).subscribe(
      (response) => {
        this.vendings = response.data.data
        if(this.currentPage == 1){
          this.vendingsService
            .GetVendingsCount(obj)
            .subscribe((response) => {
              this.count = response.data
            })
            this.lastPageSize = Math.ceil(this.count/this.pageSize)
          }
        this.showPaging=false;
      },
      (error) => {
        this.toastr.error('An error occurred.')
      }
    )
    this.showPaging=true;

  }

  generateObject() {
    let obj = {
      pageInfo: {
        pageSize: this.pageSize,
        page: this.currentPage,
      },
      filterParams: {
        from: transformToString(this.dateFrom),
        to: transformToString(this.dateTo),
        buName: this.buSelected.value === 0 ? '' : this.buSelected.name,
        utName: this.utSelected.value === 0 ? '' : this.utSelected.name,
        search: this.searchText
      },
      guid: crypto.randomUUID()
    }

    return obj
  }

  pageChange(value: any) {
    this.currentPage = value
    this.reloadTable()
  }

  getBusForSelect() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = response.data
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getUtsForSelect() {
    this.undertakingService.getAllForSelect().subscribe(response => {
      this.undertakings = response.data
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  businessUnitSelected(bu: any) {
    this.buSelected = bu
    this.pageChange(1)
  }

  undertakingSelected(data: any) {
    this.utSelected = data
    this.pageChange(1)
  }

  updateDate(date: any) {
    this.dateFrom = date.dateFrom
    this.dateTo = date.dateTo
    this.reloadTable(1)
  }

  inputChanged(searchText: string) {
    this.searchText = searchText
    this.pageChange(1)
  }

  download() {
    let errors = this.validateDownload();

    if(errors.length > 0){
        errors.forEach(error => {
          this.toastr.error(error);
        });

      return;
    }

    let obj = this.generateObject()
    obj.pageInfo.pageSize = this.countForDownload;
    this.vendingsService.DownloadVendingsReport(obj).subscribe(
      (response) => {
        if(response.status == 201){
          this.toastr.success(response.message)
        }
        if(response.status == 200){
          if(!response.data.includes("http")){
            this.toastr.error("An error occured.");
          }
          else{
            window.location.href = response.data;
          }
        }
      },
      (_) => {
        this.toastr.error('An error occured')
      }
    )
  }

  checkCommandRequest(guid: string) {
    // initial check
    this.commandRequestService.checkCommandRequest(guid).subscribe(response => {
      if (response.data) {
        window.location.href = response.message
      }
    })

    // check every 5s
    let intervalRef = setInterval(() => {
      this.commandRequestService.checkCommandRequest(guid).subscribe(response => {
        if (response.data) {
          window.location.href = response.message
          clearInterval(intervalRef);
        }
      })
    }, 5000)
  }

  validateDownload(): string[]{
    let messages: string[] = [];

    console.log(this.buSelected);

    if(this.buSelected && this.buSelected.value === 0){
      messages.push("Please select region for download.");
    }

    const from = new Date(this.dateFrom.year, this.dateFrom.month - 1, this.dateFrom.day);
    const to = new Date(this.dateTo.year, this.dateTo.month - 1, this.dateTo.day);

    let diff = Math.abs(to.getTime() - from.getTime());
    diff = Math.ceil(diff / (1000 * 3600 * 24));

    if(diff > 31){
      messages.push("Gap between selected dates can't be greater than 31 days for download.");
    }

    return messages;
  }

  back() {
    this.location.back();
  }
}
