import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { TsViewMore } from 'src/app/models/tsViewMore';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';

@Component({
  selector: 'app-ts-popup',
  templateUrl: './ts-popup.component.html',
  styleUrl: './ts-popup.component.scss'
})
export class TsPopupComponent {

  isPopupOpen: boolean = false

  public selectedTs: TsViewMore | null = new TsViewMore()

  isDailyPeriod: boolean = false

  public feeders33: any = []
  public feeders33Count: number = 0

  public currentPage: number = 1
  public pageSize: number = 8
  public lastPageSize: number = 1
  public count: number = 0

  public loadingFlag : boolean = true

  public tabMenu: number = 1
  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''

  constructor(private tsService: TransmissionStationService,
    private toastr: ToastrService) { }

  changeState(daily: boolean, data?: any) {
    if (data)
     this.selectedTs = data

    this.isDailyPeriod = daily

    if (daily === true)
      this.selectTsDaily(this.selectedTs?.tsName)
    else
      this.selectTsMonthly(this.selectedTs?.tsName)

    this.isPopupOpen = true
    this.tabMenu = 1
  }

  selectTs() {
    if (this.isDailyPeriod == true) {
      this.selectTsDaily(this.selectedTs?.tsName)
    } else
      this.selectTsMonthly(this.selectedTs?.tsName)
  }

  tabChanged(value: number) {
    this.tabMenu = value
  }

  selectTsMonthly(name: any) {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        isMonthlySelected: !this.isDailyPeriod
      },
      filterParams: {
        tsId: this.selectedTs?.tsId,
        from: transformToString(this.dateFrom),
        to: transformToString(this.dateTo)
      }
    }
    this.loadingFlag = true
    this.tsService.getTsViewMore(obj).subscribe(response => {
      if (response.status === 200) {
        this.feeders33 = response.data.data
        this.count = response.data.count
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
        this.loadingFlag = false

      } else {
        this.toastr.error(response.message)
        this.loadingFlag = false
      }
    }, _ => {
      this.toastr.error('An error occurred.')
      this.loadingFlag = false
    })
  }

  selectTsDaily(name: string = '') {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        isMonthlySelected: !this.isDailyPeriod
      },
      filterParams: {
        tsId: this.selectedTs?.tsId,
        from: transformToString(this.dateFrom),
        to: transformToString(this.dateTo)
      }
    }
    this.tsService.getTsViewMore(obj).subscribe(response => {
      if (response.status === 200) {
        this.feeders33 = response.data.data
        this.count = response.data.count
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      } else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  pageChange(value: any) {
    this.currentPage = value
    this.selectTs()
  }

  close() {
    this.feeders33 = []
    this.selectedTs = null
    this.isPopupOpen = false
    this.currentPage = 1
  }

}
