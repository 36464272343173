<div class="ami-container">
    <app-account-create-status-tab (parentComp)="reloadTable($event)"></app-account-create-status-tab>
    <div class="ami-content">

        <div class="dropdown-filter">
            <div class="search-wrapper">
            </div>
        </div>
        <table class="standard-table">
            <thead>
                <tr>
                    <th><span>Account Status</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of accounts" class="bodyRow clickable actions-click"
                    [ngClass]="{'selected': selectedAccountStatus.id===item.id}">

                    <td><span>{{item.status | hasValue}}</span></td>

                    <th>
                        <span><img alt="Select Status" class="" src="assets/images-new-design/edit.jpg" data-toggle="modal"
                                data-target="#createModal"
                                 (click)="selectAccStatus(item.id)" /> </span>
                        <span><img alt="Delete" class="" src="assets/images-new-design/bin.png" data-toggle="modal"
                                data-target="#deleteModal"
                                 (click)="openTabDelete(item.id)" /> </span>
                    </th>
            </tbody>
        </table>
        <div class="pagination-wrapper">
        </div>
    </div>
</div>

