import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryISO, NgxIntlTelInputComponent, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { ToastrService } from 'ngx-toastr';
import { SupportMail } from 'src/app/models/supportMail.model';
import { CommonService } from 'src/app/services/common-service.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('out', style({
        transform: 'translateX(-100%)',
      })),
      state('in', style({
        transform: 'translateX(0)',
      })),
      transition('in => out', animate('1000ms ease-in-out')),
      transition('out => in', animate('1000ms ease-in-out'))
    ]),
  ],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class SupportFormComponent {
  @Input() animationState = 'out';
  showShadow = false;
  tabNumber = 1;
  isFormSubmitted = false;
  phoneNumberForm = FormGroup;

  @Input('openModal') openModal: number = 0;

  mail: SupportMail = new SupportMail();
  phoneNumberObject = {
    countryCode: '',
    dialCode: 0,
    e164Number: 0,
    internationalNumber: 0,
    nationalNumber: 0,
    number: ''
  };
  @ViewChild(NgxIntlTelInputComponent) intlTelInput!: NgxIntlTelInputComponent;
  public emailValid: boolean = true;
  public nameValid: boolean = true;

  public phoneNumberValid: boolean = true;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  @ViewChild('f') f!: ElementRef;
  isOpen = false;

  @ViewChild(SupportFormComponent) popupComponentRef!: SupportFormComponent;
  @ViewChild('slider')
  parentDiv!: ElementRef;
  isDropdownOpen: boolean = false;
  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private _eref: ElementRef
  ) { }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  onChildClick() {
    this.isDropdownOpen = false;

  }
  changeAnimationStateToOut() {
    this.animationState = 'out';
    // this.showShadow=false
  }

  changeState(number: Number) {
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
      this.reset();
    }
  }
  closeTab() {
    this.popupComponentRef.changeState(2);
  }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.animationState === 'in')
      if (this._eref.nativeElement.contains(event.target) || event.target === this._eref.nativeElement) {
        if (!isSliderClicked)
          this.changeState(2);
      }
  }
  // @HostListener('document:click', ['$event'])
  // onClickOutside(event: any): void {
  //   if (this.animationState==='in' ) {
  //     const isClickInsideSlider = this._eref.nativeElement.contains(event.target);
  //     if (!isClickInsideSlider) {
  //       this.changeState(2);
  //     }
  //   }
  // }
  reset() {
    this.mail.email = '';
    this.phoneNumberObject = {
      countryCode: '',
      dialCode: 0,
      e164Number: 0,
      internationalNumber: 0,
      nationalNumber: 0,
      number: ''
    };
    this.mail.fullName = '';
    this.mail.organisation = '';
    this.mail.message = '';
    this.mail.subject = '';
  }
  sendMail() {
    if (this.commonService.isEmail(this.mail.email))
      this.emailValid = true;
    else
      this.emailValid = false;
    if (this.emailValid == false)
      return;
    if (this.nameValid == false)
      return;

    //this.mail.phoneNumber =this.phoneNumberObject?.dialCode+ '' +this.phoneNumberObject?.number;
    this.mail.phoneNumber = this.phoneNumberObject?.number;
    if (this.mail.phoneNumber?.length < 8) {
      this.toastr.error("Phone Number must be at least 8 characters");
      return;
    }

    if (!/^[a-zA-Z\s]+$/.test(this.mail.fullName)) {
      this.toastr.error("Full name can only contain letters and spaces.");
      return;
    }
    this.userService.sendMail(this.mail).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.changeState(2);
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
