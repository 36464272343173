<div *ngIf="isPopupOpen" id="isViewMore" class="popup-overlay" (click)="close()">
  <div class="popup-content" click-stop-propagation>
    <div class="close-content">
      <svg (click)="close()" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.75 12.9436L17.0782 17.2718C17.203 17.3966 17.356 17.4632 17.5372 17.4718C17.7184 17.4804 17.8799 17.4137 18.0218 17.2718C18.1637 17.1299 18.2346 16.9726 18.2346 16.8C18.2346 16.6274 18.1637 16.4701 18.0218 16.3282L13.6936 12L18.0218 7.6718C18.1466 7.54702 18.2132 7.39403 18.2218 7.21283C18.2304 7.03163 18.1637 6.87009 18.0218 6.7282C17.8799 6.58631 17.7226 6.51537 17.55 6.51537C17.3774 6.51537 17.2201 6.58631 17.0782 6.7282L12.75 11.0564L8.4218 6.7282C8.29702 6.60342 8.14403 6.53676 7.96283 6.5282C7.78163 6.51964 7.62009 6.58631 7.4782 6.7282C7.33631 6.87009 7.26537 7.02736 7.26537 7.2C7.26537 7.37264 7.33631 7.52991 7.4782 7.6718L11.8064 12L7.4782 16.3282C7.35342 16.453 7.28676 16.606 7.2782 16.7872C7.26964 16.9684 7.33631 17.1299 7.4782 17.2718C7.62009 17.4137 7.77736 17.4846 7.95 17.4846C8.12264 17.4846 8.27991 17.4137 8.4218 17.2718L12.75 12.9436ZM12.7545 24C11.0951 24 9.53496 23.6851 8.07413 23.0554C6.61331 22.4256 5.34259 21.5709 4.26197 20.4913C3.18137 19.4117 2.32589 18.1422 1.69553 16.6827C1.06518 15.2233 0.75 13.6638 0.75 12.0045C0.75 10.3451 1.06488 8.78496 1.69463 7.32413C2.32441 5.86331 3.1791 4.59259 4.2587 3.51197C5.3383 2.43137 6.60782 1.57589 8.06727 0.945534C9.52673 0.315179 11.0862 0 12.7455 0C14.4049 0 15.965 0.314877 17.4259 0.944633C18.8867 1.57441 20.1574 2.4291 21.238 3.5087C22.3186 4.5883 23.1741 5.85782 23.8045 7.31727C24.4348 8.77673 24.75 10.3362 24.75 11.9955C24.75 13.6549 24.4351 15.215 23.8054 16.6759C23.1756 18.1367 22.3209 19.4074 21.2413 20.488C20.1617 21.5686 18.8922 22.4241 17.4327 23.0545C15.9733 23.6848 14.4138 24 12.7545 24ZM12.75 22.6667C15.7278 22.6667 18.25 21.6333 20.3167 19.5667C22.3833 17.5 23.4167 14.9778 23.4167 12C23.4167 9.02222 22.3833 6.5 20.3167 4.43333C18.25 2.36667 15.7278 1.33333 12.75 1.33333C9.77222 1.33333 7.25 2.36667 5.18333 4.43333C3.11667 6.5 2.08333 9.02222 2.08333 12C2.08333 14.9778 3.11667 17.5 5.18333 19.5667C7.25 21.6333 9.77222 22.6667 12.75 22.6667Z"
          fill="#7F7D82" />
      </svg>
    </div>
    <div class="tabs">
      <div class="formTitle">{{selectedF11.name | hasValue }}</div>
      <app-tab-default [firstText]="'Overview'" [secondText]=
      "'DT Downstream'" (tabChanged)="tabChanged($event)"
        [tabSelected]="tabMenu"
        [firstImg]="'../../../../assets/images-new-design/sort.png'"
        [secondImg]="'../../../../assets/images-new-design/commit.png'"

        ></app-tab-default>
    </div>
    <div class="horizontal-line hr"></div>
    <div class="download-content" *ngIf="tabMenu===1">

      <div class="form">
        <label class="label-opt">11KV Feeder Name</label>
        <div class="assetForm">{{selectedF11.name}}</div>
      </div>
      
      <div class="form">
        <label class="label-opt">Feeder Number</label>
        <div class="assetForm">{{selectedF11.feederNumber}}</div>
      </div>
      <div class="form">
        <label class="label-opt">Connected SS</label>
        <div class="assetForm">{{selectedF11.ssName}}</div>
        
      </div>
      <div class="form">
        <label class="label-opt">Meter Number</label> 
        <div *ngIf="selectedF11.meterNumber !== '/'" class="assetForm">{{selectedF11.meterNumber}}</div> 
        <div *ngIf="selectedF11.meterNumber === '/'" class="NaN2">N/A</div> 
      </div>
    
      <div class="form">
        <label class="label-opt">LAR (MWH)</label> 
        <div *ngIf="selectedF11.lar !== '/'" class="assetForm">{{selectedF11.lar}}</div> 
        <div *ngIf="selectedF11.lar === '/'" class="NaN2">N/A</div> 
      </div>
      <div class="form">
        <label class="label-opt">PAR(MWH)</label> 
        <div *ngIf="selectedF11.par !== '/'" class="assetForm">{{selectedF11.par}}</div> 
        <div *ngIf="selectedF11.par === '/'" class="NaN2">N/A</div> 
      </div>
      <div class="form">
        <label class="label-opt">Energy Received (MWH)</label> 
        <div *ngIf="selectedF11.energyReceived !== '/'" class="assetForm">{{selectedF11.energyReceived}}</div> 
        <div *ngIf="selectedF11.energyReceived === '/'" class="NaN2">N/A</div> 
      </div>
      <div class="form">
        <label class="label-opt">Total Consumption At DT (MWh)</label>
        <div *ngIf="selectedF11.energyConsumptionAtDts !== '/'" class="assetForm">{{selectedF11.energyConsumptionAtDts}}</div> 
        <div *ngIf="selectedF11.energyConsumptionAtDts === '/'" class="NaN2">N/A</div> 
      </div>
      <div class="form">
        <label class="label-opt">Energy transmitted downstream (MWH)</label>
        <div *ngIf="selectedF11.energyTransmittedAtDts !== 0" class="assetForm">{{selectedF11.energyTransmittedAtDts}}</div> 
        <span *ngIf="selectedF11.energyTransmittedAtDts===0" class="NaN2" class="NaN2">N/A</span>
      </div>
      <div class="form">
        <label class="label-opt">MF</label> 
        <div *ngIf="selectedF11.mf !== '/'" class="assetForm">{{selectedF11.mf}}</div> 
        <div *ngIf="selectedF11.mf === '/'" class="NaN2">N/A</div> 
      </div>
      <!-- <div class="form" *ngIf="selectedF11.availabilityHours">
        <label class="label-opt">Availability (Hrs)</label>
        <div class="assetForm">{{selectedF11.availabilityHours}}</div>
      </div> -->
      <div class="form">
        <label class="label-opt">Communication Status</label> 
        
        <div *ngIf="selectedF11.lastReadSync !== '/'" class="assetForm">{{selectedF11.lastReadSync}}</div> 
        <div *ngIf="selectedF11.lastReadSync === '/'" class="NaN2">N/A</div>  
      </div>
      <div class="form">
        <label class="label-opt">Read Type</label>   
        <div *ngIf="selectedF11.typeOfConsumption !== '/'" class="assetForm">{{selectedF11.typeOfConsumption}}</div> 
        <div *ngIf="selectedF11.typeOfConsumption === '/'" class="NaN2">N/A</div>  
      </div>
      <div class="form">
        <label class="label-opt">Total Losses(MWH)</label> 
        <div *ngIf="selectedF11.lossesMwh !== '/'" class="assetForm">{{selectedF11.lossesMwh}}</div> 
        <div *ngIf="selectedF11.lossesMwh === '/'" class="NaN2">N/A</div> 
      </div>
      <div class="form">
        <label class="label-opt">Total Losses(%)</label>  
        <div *ngIf="selectedF11.lossesPercentage !== '/'" class="assetForm">{{selectedF11.lossesPercentage}}</div> 
        <div *ngIf="selectedF11.lossesPercentage === '/'" class="NaN2">N/A</div>
      </div>
      <div class="form">
        <label class="label-opt">Total Losses(NGN)</label>  
        <div *ngIf="selectedF11.lossesN !== '/'" class="assetForm">{{selectedF11.lossesN}}</div> 
        <div *ngIf="selectedF11.lossesN === '/'" class="NaN2">N/A</div>
      </div>
    </div>

    <div class="download-content" *ngIf="tabMenu===2">

      <div class="popup-header sec-page">
        <span class="popup-title">All Dt's downstream of {{dataFeeder?.info?.name}}</span>
      </div>
      <table class="menu-header">
        <thead>
          <tr class="header-columns">

            <th class="textBg value">Description</th>

            <th class="textBg value">Total</th>
            <th class="textBg value">Communicating</th>
            <th class="textBg value">Not Communicating</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="boldColumn value">Private DTs Connected on 11KV feeder</td>
            <td class="value">{{dataFeeder?.totalCountOfPrivateDTs}}</td>
            <td class="value">{{dataFeeder?.privateCommunicatingCount}}</td>
            <td class="value">{{dataFeeder?.privateNotCommunicatingCount}}</td>

          </tr>
          <tr>
            <td class="boldColumn value">Public DTs Connected on 11KV feeder</td>
            <td class="value">{{dataFeeder?.totalCountOfPublicDTs}}</td>
            <td class="value">{{dataFeeder?.publicCommunicatingCount}}</td>
            <td class="value">{{dataFeeder?.publicNotCommunicatingCount}}</td>

          </tr> 
        </tbody>
      </table>
    </div>





  </div>