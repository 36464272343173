<table class="standard-table" *ngIf="tab===1">
    <thead>
        <tr>
            <th><input class="input-checkmark" type="checkbox"
                        [(ngModel)]="masterSelected" (change)="checkUncheckAll(unengagedFieldAgents)" /></th>

            <th><span>AGENT ID</span></th>
            <th><span>AGENT NAME</span></th>
            <th><span>DESIGNATION</span></th>
            <th><span>{{buName | uppercase}}</span></th>
            <th><span>{{utName | uppercase}}</span></th>
            <th><span>PHONE NUMBER</span></th>
            <th><span>EMAIL ADDRESS</span></th>
            <th class="small-td clickable actions-click"><span></span></th>
        </tr>
    </thead>
    <tbody class="table-body">
        <tr *ngFor="let item of unengagedFieldAgents; let i = index" (click)="openDetails(item.id)" class="clickable actions-click">
            <td (click)="false; $event.stopPropagation();">
                <input class="input-checkmark" type="checkbox" value="{{item.id}}" [(ngModel)]="item.checked"
                    (change)="checkForDownload(unengagedFieldAgents, item.id)" />
            </td>
            <td><span>{{item.agentId | hasValue}}</span></td>

            <td><span class="name">
                    <div *ngIf="item.image; else defaultImage"> <img alt="Agent" class="image" src="{{ item.image }}"></div>
                    <ng-template #defaultImage>
                        <div class="image"><img alt="Default" class="image" src="/assets/images-new-design/defaultImg.png">
                        </div>
                    </ng-template> <span class="username">{{ item.userName | hasValue}}</span>
                    <div class="assignTask" *ngIf="item.noOfAssignTask !==0">{{item.noOfAssignTask}}</div>

                </span></td>
            <td><span>{{item.usersDesignation | hasValue}}</span></td>
            <td><span>{{item.buName | hasValue}}</span></td>
            <td><span>{{item.utName | hasValue}}</span></td>
            <td><span>{{item.phoneNumber | hasValue}}</span></td>
            <td><span>{{item.email | hasValue}}</span></td>
            <td class="small-td clickable actions-click" ><span><img alt="..."
                        src="assets/images/3-dot.svg"></span>
            </td>
        </tr>
    </tbody>
</table>

<table class="standard-table" *ngIf="tab===2">
    <thead>
        <tr>
            <th><input class="input-checkmark" type="checkbox" name="list_name" value="h1"
                        [(ngModel)]="masterSelected" (change)="checkUncheckAll(engagedFieldAgents)" /></th>
            <th><span>AGENT ID</span></th>
            <th><span>AGENT NAME</span></th>
            <th><span>DESIGNATION</span></th>
            <th><span>{{buName | uppercase}}</span></th>
            <th><span>{{utName | uppercase}}</span></th>
            <th><span>PHONE NUMBER</span></th>
            <th><span>EMAIL ADDRESS</span></th>
            <th class="small-td clickable actions-click"><span></span></th>
        </tr>
    </thead>
    <tbody class="table-body">
        <tr *ngFor="let item of engagedFieldAgents; let i = index" (click)="openDetails(item.id)" class="clickable-actions-click">
            <td (click)="false; $event.stopPropagation();">
                <input type="checkbox" value="{{item.id}}" class="input-checkmark" name="list_name"
                    [(ngModel)]="item.checked" (change)="checkForDownload(engagedFieldAgents, item.id)" />

            </td>
            <td><span class="agentRow">{{item.agentId | hasValue}}
                </span></td>
            <td><span class="name">
                    <div *ngIf="item.image; else defaultImage"> <img alt="Agent" class="image" src="{{ item.image }}">
                    </div>
                    <ng-template #defaultImage>
                        <div class="image"><img alt="Default" class="image" src="/assets/images-new-design/defaultImg.png">
                        </div>
                    </ng-template> <span class="username">{{ item.userName | hasValue}}</span>
                    <div class="assignTask">{{item.noOfAssignTask}}</div>
                </span></td>
            <td><span>{{item.usersDesignation | hasValue}}</span></td>
            <td><span>{{item.buName | hasValue}}</span></td>
            <td><span>{{item.utName | hasValue}}</span></td>
            <td><span>{{item.phoneNumber | hasValue}}</span></td>
            <td><span>{{item.email | hasValue}}</span></td>
            <td class="small-td clickable actions-click"><span><img alt="..."
                        src="assets/images/3-dot.svg"></span>
            </td>
        </tr>
    </tbody>
</table>
<table class="standard-table" *ngIf="tab===3">
    <thead>
        <tr>
            <th><input type="checkbox" class="input-checkmark" name="list_name" value="h1"
                        [(ngModel)]="masterSelected" (change)="checkUncheckAll(inactiveFieldAgents)" />
            </th>
            <th><span>AGENT ID</span></th>
            <th><span>AGENT NAME</span></th>
            <th><span>DESIGNATION</span></th>
            <th><span>{{buName | uppercase}}</span></th>
            <th><span>{{utName | uppercase}}</span></th>
            <th><span>PHONE NUMBER</span></th>
            <th><span>EMAIL ADDRESS</span></th>
            <th class="small-td clickable actions-click"><span></span></th>
        </tr>
    </thead>
    <tbody class="table-body">
        <tr *ngFor="let item of inactiveFieldAgents; let i = index" (click)="openDetails(item.id)" class="clickable actions-click">
            <td (click)="false; $event.stopPropagation();">
                <input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked"
                    (change)="checkForDownload(inactiveFieldAgents, item.id)" />
            </td>
            <td><span>{{item.agentId | hasValue}}</span></td>
            <td><span class="name">
                    <div *ngIf="item.image; else defaultImage"> <img alt="Agent" class="image" src="{{ item.image }}">
                    </div>
                    <ng-template #defaultImage>
                        <div class="image"><img alt="Default" class="image" src="/assets/images-new-design/defaultImg.png">
                        </div>
                    </ng-template> <span class="username">{{ item.userName | hasValue}}</span>
                </span></td>
            <td><span>{{item.usersDesignation | hasValue}}</span></td>
            <td><span>{{item.buName | hasValue}}</span></td>
            <td><span>{{item.utName | hasValue}}</span></td>
            <td><span>{{item.phoneNumber | hasValue}}</span></td>
            <td><span>{{item.email | hasValue}}</span></td>
            <td class="small-td clickable actions-click"><span><img alt="..."
                        src="assets/images/3-dot.svg"></span>
            </td>
        </tr>
    </tbody>
</table>
