import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { ToastrService } from 'ngx-toastr';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommonService } from 'src/app/services/common-service.service';
import { FieldAgentsService } from 'src/app/services/field-agents.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';

@Component({
  selector: 'app-create-agent',
  templateUrl: './create-agent.component.html',
  styleUrls: ['./create-agent.component.scss']
})
export class CreateAgentComponent implements OnInit {

  @Input() isPopupOpen: boolean = false;
  @Output() isPopupOpenChange: EventEmitter<any> = new EventEmitter()

  @Output() closePopup = new EventEmitter()
  @Input() agent: User = new User()
  @Input() title: string = ''
  @Input() button: string = ''

  @Input() btnTitle: string = 'Create'
  @Output() parentComp = new EventEmitter();

  public preview: string = ''
  private uploadFile!: File | null;
  defaultImage: string = '../../../../../assets/images-new-design/defaultImg.png'

  public designations: DictionaryItem[] = []
  public bussinesUnits: DictionaryItem[] = []
  public uts: DictionaryItem[] = []

  public searchText: string = ''
  public selectedDesignation: DictionaryItem = new DictionaryItem()
  public selectedRegion: DictionaryItem = new DictionaryItem()
  public selectedUt: DictionaryItem = new DictionaryItem()

  userGuid: string = ''
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode = true;
  phoneNumberObject = {
    countryCode: '',
    dialCode: 0,
    e164Number: 0,
    internationalNumber: 0,
    nationalNumber: 0,
    number: ''
  };

  
  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')
  constructor(
    private buService: BusinessUnitService,
    private utService: UndertakingService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private location: Location,
    private route: ActivatedRoute,
    private agentService: FieldAgentsService,
    private authService:AuthService,
    private translationService:TranslationService
  ) { }

  ngOnInit(): void { 
    this.userGuid = this.authService.getCurrentUser()?.userGuid;
    this.setInitial(); 
    this.preview = this.defaultImage
    this.getDesignation()
    this.getAllBUsForSelect();  
  }
  selectFieldAgent(id: any) {
    this.agentService.get(this.agent.id).subscribe(response => {
      this.agent = response.data; 
      if(this.agent.image != null){
        this.preview = this.agent.image; 
      }
      this.selectRegion(new DictionaryItem(response?.data?.buId, response?.data?.buName))
      this.selectUt(new DictionaryItem(response?.data?.utId, response?.data?.utName))
      this.selectDesignation(new DictionaryItem(response?.data?.usersDesignationId, response?.data?.usersDesignation))
      this.agent.phoneNumber = this.agent.phoneNumber.substring(5)
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }
  setInitial() {
    this.route.queryParams.subscribe(params => {
      if (params['data'] !== undefined) {
        this.agent.id = JSON.parse(params['data']); 
      } else {
        this.agent.id = 0
      }
    });
    if (this.agent.id === 0) {
      this.title = "Create Agent"
      this.agent.buName=this.buName
      this.agent.utName=this.utName
      this.button = "Create Agent"
      this.agent.designationName="Designation"

    } else{
      this.title = "Edit Agent"
      this.button = "Save Changes"

      this.selectFieldAgent(this.agent.id)
    }
  }
  back() {
    this.location.back();
  }
  public createAgent(state: number) {
    if (state == 1) {
      this.isPopupOpen = true;
    }
    else {
      this.closePopup.emit(1)
    }
  }

  public create() {
    if(!this.validate())
      return
    let formData: FormData = new FormData();
    if (this.uploadFile) {
      formData.append('image', this.uploadFile, this.uploadFile.name);
    }
 
    formData.append('buId', this.selectedRegion.value.toString());
    formData.append('email', this.agent.email);
    formData.append('userName', this.agent.userName);
    formData.append('designationId', this.selectedDesignation.value.toString());
    formData.append('utId', this.selectedUt.value.toString());
    formData.append('phoneNumber', this.agent.phoneNumber == null ? '' : this.agent.phoneNumber?.internationalNumber
    );
    formData.append('id', this.agent.id.toString());  
    this.agentService.createOrEditAgent(formData).subscribe(response => {
      if (response.status == 200) {
        this.closePopup.emit()
        this.toastr.success(response.message)
        this.location.back();
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred')
    })
   }

   public validate(){
    let isValid = true
    if(this.selectedDesignation.value <= 0)
      isValid = false;
    if(this.selectedRegion.value <= 0)
      isValid = false
    if(this.selectedRegion.value <= 0)
      isValid = false
    if(this.selectedUt.value <= 0)
      isValid = false

    return isValid
  }

  onFileChanged(event: any) {
    this.uploadFile = event.target.files[0]
    if (this.uploadFile) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        this.preview = e.target.result
        this.agent.image=this.preview 
      }

      reader.readAsDataURL(this.uploadFile);
    }
  }
  public getDesignation() {
    this.commonService.getAllDesignations().subscribe(response => {
      if (response.status == 200) {
        this.designations = response.data
      }
      else (
        this.toastr.error(response.message)
      )
    }, _ => {
      this.toastr.error("An error occurred")
    })
  }

  selectDesignation(item: DictionaryItem) {
    this.selectedDesignation = item
    this.agent.designationName=item.name
  }

  getAllBUsForSelect() {
    this.buService.GetByUserGuid(this.userGuid).subscribe(response => {
      this.bussinesUnits = response.data; 
    }, _ => {
      this.toastr.error('An error occured');
    });
  }
  getAllUTsForSelect() {
    this.utService.getAllByBuId(this.selectedRegion.value).subscribe(response => {
      this.uts = response.data;
    }, _ => {
      this.toastr.error('An error occured');
    });
  }

  selectRegion(item: DictionaryItem) {
    this.selectedRegion = item
    this.agent.buName =item.name
    this.getAllUTsForSelect()
  }

  selectUt(item: DictionaryItem) {
    this.selectedUt = item
  }
}
