import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { WorkOrderManagement } from 'src/app/services/work-order-management.service';

@Component({
  selector: 'app-change-date-popup',
  templateUrl: './change-date-popup.component.html',
  styleUrl: './change-date-popup.component.scss',
})
export class ChangeDatePopupComponent {

  public isPopupOpen: boolean = false
  public task: any
  public estimatedEndDateOld: any = ''
  public estimatedEndDate: any = ''
  public sameDate: boolean = false
  public formValid: boolean = true;
  public notSameDate: boolean = true;

  @Output() dateChanged: EventEmitter<any> = new EventEmitter();
  @Output() cancelReassign: EventEmitter<any> = new EventEmitter();

  @ViewChild('f') f: any;

  constructor(
    private workOrderService: WorkOrderManagement,
    private toastr: ToastrService) {}

  close() {
    this.isPopupOpen = false
    this.notSameDate = true
    this.formValid = true
    this.cancelReassign.emit()
  }

  changeState(showTask: any = null) {
    if(showTask){
      this.task = showTask
      this.estimatedEndDateOld = this.task.estimatedEndDate
      let date = new Date(this.task.estimatedEndDate)
      this.estimatedEndDate = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}
      this.sameDate = false
    }

    this.isPopupOpen= !this.isPopupOpen
  }

  save() {
    let date = new Date(this.estimatedEndDateOld)
    let old = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    if(!this.sameDate && transformToString(this.estimatedEndDate) == transformToString(old)){
      this.formValid = false
      this.notSameDate = true
      return
    }
    if(this.sameDate && transformToString(this.estimatedEndDate) != transformToString(old)){
      this.formValid = true
      this.notSameDate = false
      return
    }
    this.task.estimatedEndDate = transformToString(this.estimatedEndDate)
    this.updateTask()
    this.changeState()
  }

  updateTask(){
    let obj = {
      WorkOrderId: this.task.id,
      AssignedUserId: this.task.assignedUserId,
      EstimatedEndDate: transformToString(this.estimatedEndDate)
    }

    this.workOrderService.assignWorkOrder(obj).subscribe(response => {
      if (response.status == 200) {
        this.toastr.success('Successfuly assigned user')
        this.dateChanged.emit()
      }
      else {
        this.toastr.error(response.message)
        this.cancelReassign.emit()
      }
    }, _ => {
      this.toastr.error("An error occurred")
    })
  }

  dateChange(date: any) {
    this.estimatedEndDate = date
  }
}
