
export class User {
    firstName: string = '';
    lastName: string = '';
    userName: string = '';
    email: string = '';
    phoneNumber: any;
    password: string = '';
    roleId: number = 0;
    image: any = '';
    imageAgent:string='';
    oldPassword: string = '';
    roleName: string = '';
    organisation: string = '';
    designationId: number = 0;
    designationName: string = '';
    buName: string = '';
    utName: string = '';
    checked: boolean = false;
    dateCreated : Date | null = null;
    createdBy: string = '';

    userGuid: string = '00000000-0000-0000-0000-000000000000';
    buId: number = 0;
    id:number=0;
    status: string = '';
}
