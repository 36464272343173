import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { DtViewMore } from 'src/app/models/dtViewMore.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { DtService } from 'src/app/services/dt.service';
import { TimePeriod } from '../../consumption/model/model';
import { DtPopupComponent } from './dt-popup/dt-popup.component';

@Component({
  selector: 'app-gep-dt',
  templateUrl: './gep-dt.component.html',
  styleUrls: ['./gep-dt.component.scss', '../grid-energy-profile-new.component.scss']
})
export class GepDtComponentNew implements OnChanges {

  public count: number = 0
  public pageSize: number = 8
  public currentPage: number = 1

  public currentDtName = "";
  public currentDt: any = null;

  @Input() ssIds: number[] = []
  @Input() f11Ids: number[] = []
  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''
  @Input() search: SearchAutocomplete = new SearchAutocomplete()

  public currentPageAccounts: number = 1;
  public accountsCount: number = 0;
  public accountsPageSize: number = 8;
  public lastPageSize: number = 1;

  public allFeedersType: string = ''
  daily:boolean=true
  public dtType: number = 0
  public dtTypeOpened: boolean = false
  public feederType: number = 0
  public feederTypeOpened: boolean = false
  public commStatus: number = 0
  public commStatusOpened: boolean = false
  public readType: number = 0
  public readTypeOpened: boolean = false
  public searchViewMore: any = null;
  public dataAccounts: any = [];

  @Input() timePeriod: TimePeriod = TimePeriod.Monthly;

  public masterSelected: boolean = false;

  public selectedDt: any = [];
  public checkedList: number[] = [];
  public dtIds: any = [];
  public data: DtViewMore[] = [];
  public isViewMore: boolean = false;

  public allFeedersId: number = 0
  @ViewChild(DtPopupComponent) popupRef!: DtPopupComponent;

  public bandNumber: number = 1;

  allDts: number = 0;
  bandACount: number = 0;
  bandBCount: number = 0;
  bandCCount: number = 0;
  bandDCount: number = 0;
  bandECount: number = 0;

  constructor(
    private _dtService: DtService,
    private toastr: ToastrService,
  ) { }

  close() {
    this.isViewMore = false;
  }

  private resetPagination() {
    this.currentPage = 1
  }

  reloadAccounts(page: any = null) {
    if (page)
      this.currentPageAccounts = page;
    let obj = {
      pageInfo: {
        page: this.currentPageAccounts,
        pageSize: this.accountsPageSize,
        isMonthlySelected: this.timePeriod == TimePeriod.Monthly
      },
      filterParams: {
        DtName: this.currentDtName,
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        Search: this.searchViewMore
      }
    };
    this.dataAccounts = [];
    this.selectDt();
  }
  viewMore(dt: any) {
    this.currentDt = dt;
    this.currentDtName = dt.dtName;
    this.searchViewMore = '';
    this.isViewMore = true;
    this.selectedDt = this.data.find(x => x.dtNumber == dt.dtNumber)
    this.selectDt();

  }

  pageChange(value: number) {
    this.currentPage = value
    this.GetData()
  }

  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false
    if (changes.ssIds != null) {
      reloadTable = true
    }
    if (changes.f11Ids != null) {
      reloadTable = true
    }
    if (changes.dateFrom != null || changes.dateTo != null) {
      reloadTable = true
    }
    if (this.search?.name == '')
      this.search = changes.search?.previousValue

    if (changes.search != null) {
      reloadTable = true
    }

    if (reloadTable) {
      if (this.dateFrom == '' || this.dateTo == '')
        this.setMainDate()

      this.checkedList = [];
      this.resetPagination()
      this.GetData()
    }

  }

  public dtTypeClicked(open: number) {
    if (open == 0)
      this.dtTypeOpened = false
    else {
      if (this.dtTypeOpened) {
        this.dtTypeOpened = false
      }
      else {
        this.dtTypeOpened = true
      }

    }
  }

  public commStatusClicked(open: number) {
    if (open == 0)
      this.commStatusOpened = false
    else {
      if (this.commStatusOpened) {
        this.commStatusOpened = false
      }
      else {
        this.commStatusOpened = true
      }

    }
  }

  public readTypeClicked(open: number) {
    if (open == 0)
      this.readTypeOpened = false
    else {
      if (this.readTypeOpened) {
        this.readTypeOpened = false
      }
      else {
        this.readTypeOpened = true
      }

    }
  }

  public feederTypeClicked(open: number) {
    if (open == 0) {
      this.feederTypeOpened = false
    }
    else {
      if (this.feederTypeOpened) {
        this.feederTypeOpened = false
      }
      else {
        this.feederTypeOpened = true
      }
    }

  }

  public dtTypeSelected(value: number) {
    this.dtType = value
    this.GetData()
  }

  public commStatusSelected(value: number) {
    this.currentPage = 1
    this.commStatus = value
    this.GetData()
  }

  public readTypeSelected(value: number) {
    this.currentPage = 1
    this.readType = value
    this.GetData()
  }

  public feederTypeSelected(value: number) {
    this.feederType = value
    this.GetData()
  }
  pageChangeAccounts(value: any) {
    this.currentPageAccounts = value;
    // this.reloadAccounts();
    this.selectDt();
  }

  private setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()

    dteFrom.setHours(2,0,0);
    dte.setHours(2,0,0);
    dteFrom.setMonth(dteFrom.getMonth() - 1)

    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: 1};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
  }

  public GetData() {
    let obj = this.getSearchParameters();

    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.getMonthlyBandsCount()
      this.fetchMonthlyDts(obj)
    }
    else {
      this.getDailyBandsCount()
      this.fetchDailyDts(obj)
    }
    this.getCommunicatingAssetsCounts()
  }
  selectDt() {
    this.isViewMore = true
    if (this.isViewMore) {

      if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
        this.daily=false;
      }
      else {
        this.daily=true;
      }
      this.openTab(this.selectedDt,this.daily)
    }
  }
  closeViewMore() {
    this.searchViewMore = null;
    this.isViewMore = false;
  }
  openTab(selectedDt:any,daily:any){
    this.popupRef.changeState(daily, selectedDt,1);
  }
  closeTab(){
    this.popupRef.changeState(this.daily, this.selectedDt,2);
  }
  // selectDtDaily(name: string) {
  //   console.log(name)
  //   let obj = {
  //     pageInfo: {
  //       page: this.currentPageAccounts,
  //       pageSize: this.accountsPageSize,
  //       isMonthlySelected: this.timePeriod == TimePeriod.Daily
  //     },
  //     filterParams: {
  //       DtName: name,
  //       From: transformToString(this.dateFrom),
  //       To: transformToString(this.dateTo),
  //       Search: this.searchViewMore
  //     }
  //   };
  //   this._dtService.getDtViewMore(obj).subscribe(response => {
  //     if (response.status === 200) {
  //       this.dataAccounts = response.data.data;
  //       this.accountsCount = response.data.count;
  //       this.lastPageSize = Math.ceil(this.accountsCount / this.accountsPageSize)
  //       this.openTab(this.selectedDt,this.dataAccounts);

  //     } else {
  //       this.toastr.error(response.message);
  //     }
  //   }, _ => {
  //     this.toastr.error('An error occurred.');
  //   })
  // }


  getSearchParameters() {
    return {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        SubstationIds: this.ssIds,
        Feeder11Ids: this.f11Ids,
        FeederType: this.feederType,
        DtType: this.dtType,
        CommStatus: this.commStatus,
        ReadType: this.readType,
        Search: this.search ? this.search.name : this.search,
        Band: this.bandNumber
      }
    };
  }

  fetchDailyDts(obj: any) {
    this._dtService.getAllDtEnergyProfilesDaily(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data;
        this.isAlreadyChecked();
        if(obj.pageInfo.page == 1) {
          this.count = response.data.count;
          this.lastPageSize = Math.ceil(this.count / this.pageSize)
        }
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getDailyBandsCount() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      SubstationIds: this.ssIds,
      Feeder11Ids: this.f11Ids,
      FeederType: this.feederType,
      DtType: this.dtType,
      CommStatus: this.commStatus,
      ReadType: this.readType,
      Search: this.search ? this.search.name : this.search,
      Band: this.bandNumber
    };
    this._dtService.getDailyBandsCount(obj).subscribe(response => {
      if(response.status === 200) {
        this.bandACount = response.data.bandACount;
        this.bandBCount = response.data.bandBCount;
        this.bandCCount = response.data.bandCCount;
        this.bandDCount = response.data.bandDCount;
        this.bandECount = response.data.bandECount;
        this.allDts = this.bandACount + this.bandBCount + this.bandCCount + this.bandDCount + this.bandECount
      } else {
        this.toastr.error(response.message);
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

  getMonthlyBandsCount() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      SubstationIds: this.ssIds,
      Feeder11Ids: this.f11Ids,
      FeederType: this.feederType,
      DtType: this.dtType,
      CommStatus: this.commStatus,
      ReadType: this.readType,
      Search: this.search ? this.search.name : this.search,
      Band: this.bandNumber
    };
    this._dtService.getMonthlyBandsCount(obj).subscribe(response => {
      if(response.status === 200) {
        this.bandACount = response.data.bandACount;
        this.bandBCount = response.data.bandBCount;
        this.bandCCount = response.data.bandCCount;
        this.bandDCount = response.data.bandDCount;
        this.bandECount = response.data.bandECount;
        this.allDts = this.bandACount + this.bandBCount + this.bandCCount + this.bandDCount + this.bandECount
      } else {
        this.toastr.error(response.message);
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

  fetchMonthlyDts(obj: any) {
    this._dtService.getAllDtEnergyProfilesMonthly(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data;
        this.isAlreadyChecked();
        if(obj.pageInfo.page == 1) {
          this.count = response.data.count;
          this.lastPageSize = Math.ceil(this.count / this.pageSize)
        }
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  public download() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      SubstationIds: this.ssIds,
      Feeder11Ids: this.f11Ids,
      FeederType: this.feederType,
      DtType: this.dtType,
      CommStatus: this.commStatus,
      ReadType: this.readType,
      CheckedList: this.checkedList,
      Search: this.search ? this.search.name : this.search,
      Band: this.bandNumber
    };

    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.downloadMonthlyReport(obj)
    }
    else {
      this.downloadDailyReport(obj)
    }
  }
  private downloadMonthlyReport(obj: any) {
    this._dtService.downloadMonthlyDTReport(obj).subscribe(response => {
      window.location.href = response.data;
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }
  private downloadDailyReport(obj: any) {
    this._dtService.downloadDailyDTReport(obj).subscribe(response => {
      window.location.href = response.data;
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
      this.checkForDownload(this.data[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.data.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item:any) {
      return item.checked == true;
    })
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }

  bandChanged(value: number) {
    this.bandNumber = value;
    this.currentPage = 1
    this.GetData();
  }
  public communicatingAssetsCounter : number = 0;
  public nonCommunicatingAssetsCounter : number = 0;

  getCommunicatingAssetsCounts(){
    let obj = {
      SubstationIds: this.ssIds,
      Feeder11Ids : this.f11Ids,
      Search: this.search ? this.search.name : this.search,
      FeederType: this.feederType,
      DtType: this.dtType,
      CommStatus: this.commStatus,
      ReadType: this.readType,
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
    };
    this._dtService.getCommunicatingAssetsCounts(obj).subscribe({
      next: (response) =>{
        if(response && response.status == 200){
          this.communicatingAssetsCounter = response.data?.comunicatingAssetsCount ?? 0;
          this.nonCommunicatingAssetsCounter = response.data?.nonComunicatingAssetsCount ?? 0;
        }
      },
      error: (err) =>{
        this.toastr.error('An error occurred.');
      },
    });
  }
}
