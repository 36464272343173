export class AssetHierarchy{
  id: number = 0;
  parentId: number = 0;
  name: string = '';
  type: AssetsHierarchyType = AssetsHierarchyType.Undefined;
  children: AssetHierarchy[] = [];
  parentType: AssetsHierarchyType = AssetsHierarchyType.Undefined
}

export enum AssetsHierarchyType{
  Undefined = 0,
  TS = 1,
  Feeder33KV = 2,
  SS = 3,
  Feeder11KV = 4,
  DTs = 5,
  ACC = 6
}