import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FieldAgentsService } from 'src/app/services/field-agents.service';
import { WorkOrderManagement } from 'src/app/services/work-order-management.service';

@Component({
  selector: 'app-reassign-task-popup',
  templateUrl: './reassign-task-popup.component.html',
  styleUrls: ['./reassign-task-popup.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class ReassignTaskPopupComponent implements OnInit {
  isPopupOpen: boolean = false;
  fieldAgents: any[] = []
  fieldAgentsNew: any[] = []
  selectedItems: any[] = []

  @Input() checkedList: any = []
  agent: any = []
  @Input() teamId: number[] = [];
  public search: string = ''
  @Output() parentComp = new EventEmitter();

  constructor(
    private fieldAgentService: FieldAgentsService,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private workOrderService: WorkOrderManagement) { }

  ngOnInit(): void {
    this.getAllFieldAgents()
  }
  close() {
    this.isPopupOpen = false;
  }
  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.isPopupOpen) {
      if (this.elementRef.nativeElement.contains(event.target) || event.target === this.elementRef.nativeElement) {
        if (!isSliderClicked) {
          this.close();
        }
      }
    }
  }
  reassign(item: any) {
    this.isPopupOpen = false;
    const idList: number[] = this.checkedList.map((obj: { id: number }) => obj.id);

    this.agent = item
    let obj = {
      WorkOrderId: idList,
      AssignedUserId: item.id
    }
    if(idList.length === 0){
      this.toastr.info('Please choose at least one task!')
      return;
    } 
    this.workOrderService.reassignTask(obj).subscribe(response => {
      this.toastr.success('Successfuly assigned user')
      this.parentComp.emit()
    }, _ => {
      this.toastr.error("An error occurred")
    })
  }

  searchChanged() {
    this.fieldAgentsNew = this.fieldAgents.filter(x => x.userName.includes(this.search.toLowerCase()))
  }
  open() {
    this.selectedItems = this.fieldAgentsNew.filter((item2: { checked: any; }) => item2.checked);

    this.isPopupOpen = true;
  }

  getAllFieldAgents(page: any = null) {
    let obj = {
      pageInfo: {
        page: 1,
        pageSize: 10
      },
      filterParams: {
        Id: 0,
        TeamId: this.teamId,
        SearchContent: this.search
      }
    };
    this.fieldAgentService.getAllFieldAgents(obj).subscribe(response => {
      this.fieldAgents = response.data;
      this.fieldAgentsNew = this.fieldAgents

    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
} 