<div class="popup-wrapper">
    <div class="header">
      <div class="header-back" (click)="back()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.52116 7.83342H12.8337C13.0698 7.83342 13.2677 7.75356 13.4274 7.59384C13.5871 7.43412 13.667 7.2362 13.667 7.00009C13.667 6.76398 13.5871 6.56606 13.4274 6.40634C13.2677 6.24662 13.0698 6.16676 12.8337 6.16676H3.52116L7.60449 2.08342C7.77116 1.91676 7.85102 1.72231 7.84408 1.50009C7.83713 1.27787 7.75033 1.08342 7.58366 0.916758C7.41699 0.76398 7.22255 0.684119 7.00033 0.677174C6.7781 0.67023 6.58366 0.750091 6.41699 0.916758L0.916992 6.41676C0.833659 6.50009 0.774632 6.59037 0.739909 6.68759C0.705187 6.78481 0.687826 6.88898 0.687826 7.00009C0.687826 7.1112 0.705187 7.21537 0.739909 7.31259C0.774632 7.40981 0.833659 7.50009 0.916992 7.58342L6.41699 13.0834C6.56977 13.2362 6.76074 13.3126 6.98991 13.3126C7.21908 13.3126 7.41699 13.2362 7.58366 13.0834C7.75033 12.9168 7.83366 12.7188 7.83366 12.4897C7.83366 12.2605 7.75033 12.0626 7.58366 11.8959L3.52116 7.83342Z" fill="#1C1B1F"/>
          </svg>          
      </div>
      <div class="header-title">
        <span *ngIf="taskId == 0">Create task</span>
        <span *ngIf="taskId != 0">Edit task</span>
      </div>
      <div class="add-wrapper">
        <label *ngIf="taskId == 0" for="submitForm" class="add-agent">Create Task</label>
        <label *ngIf="taskId != 0" for="submitForm" class="add-agent">Save Task</label>
      </div>
      
    </div>

  <div class="popup-content">

    <form class="form" (submit)="f.form.valid && create()" #f="ngForm">

      <div class="col-sm-12 crud-input-container">
        <label class="labelPopup" >Task Name</label>
        <input class="form-control" name="name" [(ngModel)]="workOrder.name" #name="ngModel"
            required autocomplete="off" placeholder="Enter task name" />
          <div *ngIf="f.submitted && name.invalid" class="form-control-error">Name is required</div>
      </div>

      <div class="col-sm-12 input textarea crud-input-container">
        <div class="optional">
          <label class="labelPopup" >Task Description</label>
          <label class="label-optional">Optional</label>
        </div>
        <textarea class="form-control" name="description" [(ngModel)]="workOrder.description" #description="ngModel"
            autocomplete="off" placeholder="Enter task description"></textarea>
      </div>

      <div class="col-sm-12 input group crud-input-container">
        <div class="dropdowns ">
          <label class="labelPopup" >Category</label>
          <app-simple-dropdown
            [header]="selectedCategory.name"
            [dropdownHeader]="'Category'"
            [data]="categories"
            (selectItemChange)="selectCategory($event)"
            [selectedItemId]="selectedCategory.value"
          ></app-simple-dropdown>
          <div *ngIf="f.submitted && selectedCategory.value <= 0" class="form-control-error">Category is required</div>
        </div>
        <div class="dropdowns">
          <label class="labelPopup" >Asset Type</label>
          <app-simple-dropdown
            [header]="selectedAssetType.name"
            [dropdownHeader]="'Asset Type'"
            [data]="assetTypes"
            (selectItemChange)="selectAssetType($event)"
            [selectedItemId]="selectedAssetType.value"
          ></app-simple-dropdown>
          <div *ngIf="f.submitted && selectedAssetType.value <= 0" class="form-control-error">Asset type is required</div>
        </div>
      </div>

      <div class="col-sm-12 input group crud-input-container">
        <div class="dropdowns">
          <label class="labelPopup" >{{buName}}</label>
          <app-simple-dropdown
            [header]="selectedRegion.name"
            [dropdownHeader]="buName"
            [data]="bussinesUnits"
            (selectItemChange)="selectRegion($event)"
            [selectedItemId]="selectedRegion.value"
          ></app-simple-dropdown>
          <div *ngIf="f.submitted && selectedRegion.value <= 0" class="form-control-error">{{buName}} is required</div>
        </div>
        <div class="dropdowns">
          <label class="labelPopup" >{{utName}}</label>
          <app-simple-dropdown
            [header]="selectedArea.name"
            [dropdownHeader]="utName"
            [data]="undertakings"
            (selectItemChange)="selectArea($event)"
            [selectedItemId]="selectedArea.value"
          ></app-simple-dropdown>
          <div *ngIf="f.submitted && selectedArea.value <= 0" class="form-control-error">{{utName}} is required</div>
        </div>
      </div>
      
      <div class="col-sm-12 input group crud-input-container">
        <div class="dropdowns">
          <label class="labelPopup" >Estimated End Date</label>
          <app-multi-filter-datepicker
          [name]="'Estimated'"
          [date]="estimatedEndDate"
          (dateChange)="dateChange($event)"
        ></app-multi-filter-datepicker>
        </div>
        <div class="dropdowns">
          <label class="labelPopup" >Priority</label>
          <app-simple-dropdown
            [header]="selectedPriority.name"
            [dropdownHeader]="'Priority'"
            [data]="priorities"
            (selectItemChange)="selectPriorities($event)"
            [selectedItemId]="selectedPriority.value"
          ></app-simple-dropdown>
          <div *ngIf="f.submitted && selectedPriority.value < 0" class="form-control-error">Priority is required</div>
        </div>
      </div>

      <div class="col-sm-12 input group crud-input-container">
        <div class="dropdowns">
          <label class="labelPopup" >Assigned Team</label>
          <app-dropdown-team-agents
            [header]="selectedTeam.name"
            [dropdownHeader]="'Team'"
            [data]="teams"
            (selectItemChange)="selectTeam($event)"
            [selectedTeamId]="selectedTeam.id"
          ></app-dropdown-team-agents>
          <div *ngIf="f.submitted && selectedTeam.id <= 0" class="form-control-error">Team is required</div>
        </div>
        <div class="dropdowns">
          <label class="labelPopup" >Assigned Agent</label>
          <app-dropdown-team-agents
            [header]="selectedAgent.userName"
            [dropdownHeader]="'Agent'"
            [data]="agents"
            (selectItemChange)="selectAgent($event)"
            [isAgents]="true"
            [selectedAgentId]="selectedAgent.id"
          ></app-dropdown-team-agents>
          <div *ngIf="f.submitted && selectedAgent.id <= 0" class="form-control-error">Agent is required</div>
        </div>
      </div>
      

      <div *ngIf="taskId == 0" class="col-sm-12 crud-input-container">
        <label class="labelPopup" >Attachments</label>
        <div class="upload-files-container">
          <div class="file-area">
            <span style="color:red;" *ngIf="message">{{message}}</span>
            <div class="file-input-container">
              <div class="label">
                  <span class="select-button">Select File</span>
                  <input placeholder="Select File" #file name="image" type="file" accept='image/*' multiple (change)="onFileChanged($event)" />
              </div>
              <div class="selected-file" *ngFor="let item of attachments">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0_1917_110325" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="21">
                  <rect x="2" y="2.5" width="20" height="20" fill="#D9D9D9"/>
                  </mask>
                  <g mask="url(#mask0_1917_110325)">
                  <path d="M9.95802 14.9556L16.9309 7.98279C17.1208 7.79287 17.3461 7.69792 17.6068 7.69792C17.8675 7.69792 18.0928 7.79287 18.2828 7.98279C18.4727 8.17271 18.5676 8.39803 18.5676 8.65875C18.5676 8.91946 18.4727 9.14477 18.2828 9.33469L10.626 16.9914C10.4361 17.1813 10.2135 17.2763 9.95802 17.2763C9.70259 17.2763 9.47992 17.1813 9.29 16.9914L5.71663 13.418C5.52671 13.2281 5.43439 13.0028 5.43967 12.7421C5.44496 12.4814 5.54256 12.256 5.73248 12.0661C5.9224 11.8762 6.14772 11.7812 6.40844 11.7812C6.66915 11.7812 6.89446 11.8762 7.08438 12.0661L9.95802 14.9556Z" fill="#439745"/>
                  </g>
                </svg>
                <span title="{{item}}">{{item}}</span>
              </div>
              <span class="selected-file-name"></span>
            </div>
          </div>
         </div>
      </div>

      <div class="labels-wrapper">
        <span class="label-optional">Jpeg, Png, Pdf, DOC, DOCX 24mb max file</span>
      </div>
      <button type="submit" id="submitForm" [hidden]="true"></button>
    </form>

  </div>
</div>