<button class="btn-red" (click)="changeState()" [style.width]="width">
    <span class="label-click clickable" type="button">{{onlyDownloadText}}</span>
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 15.2L12.1 14.3C11.9167 14.1167 11.6833 14.025 11.4 14.025C11.1167 14.025 10.8833 14.1167 10.7 14.3C10.5167 14.4833 10.425 14.7167 10.425 15C10.425 15.2833 10.5167 15.5167 10.7 15.7L13.3 18.3C13.5 18.5 13.7333 18.6 14 18.6C14.2667 18.6 14.5 18.5 14.7 18.3L17.3 15.7C17.4833 15.5167 17.575 15.2833 17.575 15C17.575 14.7167 17.4833 14.4833 17.3 14.3C17.1167 14.1167 16.8833 14.025 16.6 14.025C16.3167 14.025 16.0833 14.1167 15.9 14.3L15 15.2V12.025C15 11.7417 14.9042 11.5042 14.7125 11.3125C14.5208 11.1208 14.2833 11.025 14 11.025C13.7167 11.025 13.4792 11.1208 13.2875 11.3125C13.0958 11.5042 13 11.7417 13 12.025V15.2ZM11 20H17C17.2833 20 17.5208 20.0958 17.7125 20.2875C17.9042 20.4792 18 20.7167 18 21C18 21.2833 17.9042 21.5208 17.7125 21.7125C17.5208 21.9042 17.2833 22 17 22H11C10.7167 22 10.4792 21.9042 10.2875 21.7125C10.0958 21.5208 10 21.2833 10 21C10 20.7167 10.0958 20.4792 10.2875 20.2875C10.4792 20.0958 10.7167 20 11 20ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H8.175C8.44167 0 8.69583 0.05 8.9375 0.15C9.17917 0.25 9.39167 0.391667 9.575 0.575L14.425 5.425C14.6083 5.60833 14.75 5.82083 14.85 6.0625C14.95 6.30417 15 6.55833 15 6.825V8.025C15 8.30833 14.9042 8.54583 14.7125 8.7375C14.5208 8.92917 14.2833 9.025 14 9.025C13.7167 9.025 13.4792 8.92917 13.2875 8.7375C13.0958 8.54583 13 8.30833 13 8.025V7H9.5C9.08333 7 8.72917 6.85417 8.4375 6.5625C8.14583 6.27083 8 5.91667 8 5.5V2H2V16H7C7.28333 16 7.52083 16.0958 7.7125 16.2875C7.90417 16.4792 8 16.7167 8 17C8 17.2833 7.90417 17.5208 7.7125 17.7125C7.52083 17.9042 7.28333 18 7 18H2Z" fill="#2E3336"/>
    </svg>

</button>

<div *ngIf="isPopupOpen" class="popup-overlay" (click)="changeState()" >
    <div class="popup-content" click-stop-propagation>
        <div class="close-content">
        <div class="close-btn" (click)="changeState()">
            <img alt="Close" src="assets/images-new-design/Close.svg">
        </div>
        </div>
        <div class="download-content">
            <div class="download-header-wrapper">
                <span class="download-header">Download File</span>
                <span class="download-body">Import multiple customers data (Supported format XLSX, CSV).</span>
            </div>

            <div class="download-wrapper-button">
                <div class="download-button" (click)="downloadReport()">
                    <img alt="Download" src="assets/images-new-design/download file.svg">
                    <span>Download File</span>
                </div>
                <div class="download-button" (click)="downloadAndSendReport()">
                    <img alt="" src="assets/images-new-design/popup-upload-red.svg">
                    <span>Send</span>
                    <div class="email-wrapper" click-stop-propagation>
                        <span>Email: </span>
                        <input type="text" [(ngModel)]="email" value="{{user.email}}"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="close-wrapper">
            <button class="close-btn" (click)="changeState()"><span>Cancel</span></button>
        </div>
    </div>
</div>
