import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: "https://13dd3c6ff914544a9e1d427261e6d457@o4505148698394624.ingest.sentry.io/4505680717348864",
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      new Sentry.Replay(),
    ],
    attachStacktrace: true,
    environment: environment.environmentName,
    // Session Replay
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    autoSessionTracking : true,
    enableTracing : true,
        
  });
} 
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
