<div>
  <app-payment-channel-create
    (parentComp)="reloadTable($event)"
  ></app-payment-channel-create>
</div>
<main class="">
  <div class="user-profile">
    <div class="user-settings-content">
      <div class="title-and-desc">
        <div class="first">
          <div class="title-u">Payment Channel</div>
          <div class="channel-description">
            Create, read, update or delete Payment Channels
          </div>
        </div>
        <div class="second">
          <div class="btn">
            <button class="payment-btn" (click)="openTab()">
              Add Payment Channel
            </button>
          </div>
        </div>
      </div>
      <div class="channel-data">
        <div
          *ngFor="let item of paymentChannels"
          class="channel-item clickable actions-click"
          [ngClass]="{ selected: selectedChannel.id === item.id }"
        >
          <div class="channel-first-part">
            <div class="channel-info">
              <div class="channel-id">
                <span>Channel ID: {{ item.channelId | hasValue }}</span>
              </div>
              <div class="channel-name">
                <span>{{ item.name | hasValue }}</span>
              </div>
            </div>
            <div class="edit-channel payment-channel-light">
              <span
                class="edit-span"
                *ngIf="
                  authService.isAuthorized(
                    PermissionsEnum.global_settings_createupdate
                  )
                "
                (click)="selectChannel(item.id)"
                ><svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.09618 16.9638C0.778248 17.0343 0.504532 16.9548 0.275032 16.7253C0.0455486 16.4958 -0.0339349 16.2221 0.0365817 15.9042L0.873107 11.8889L5.11151 16.1273L1.09618 16.9638ZM5.11151 16.1273L0.873107 11.8889L12.0923 0.669643C12.4372 0.324776 12.8641 0.152344 13.3731 0.152344C13.882 0.152344 14.309 0.324776 14.6538 0.669643L16.3307 2.34654C16.6756 2.69141 16.848 3.11833 16.848 3.62729C16.848 4.13626 16.6756 4.56318 16.3307 4.90804L5.11151 16.1273ZM13.1615 1.72347L2.93653 11.9388L5.06153 14.0638L15.2769 3.83884C15.3346 3.78114 15.3635 3.70903 15.3635 3.62249C15.3635 3.53594 15.3346 3.46382 15.2769 3.40612L13.5943 1.72347C13.5366 1.66577 13.4644 1.63692 13.3779 1.63692C13.2913 1.63692 13.2192 1.66577 13.1615 1.72347Z"
                    fill="#1C1B1F"
                  />
                </svg>
                Edit</span
              >
            </div>
          </div>
          <div class="channel-second-part">
            <div class="delete">
              <span
                *ngIf="
                  authService.isAuthorized(
                    PermissionsEnum.global_settings_delete
                  )
                "
                (click)="openTabDelete(item.id)"
                ><svg
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.30078 16.9016C2.80578 16.9016 2.38203 16.7253 2.02953 16.3728C1.67703 16.0203 1.50078 15.5966 1.50078 15.1016V3.10156H0.300781V1.30156H5.10078V0.101562H9.90078V1.30156H14.7008V3.10156H13.5008V15.0907C13.5008 15.5979 13.3245 16.0266 12.972 16.3766C12.6195 16.7266 12.1958 16.9016 11.7008 16.9016H3.30078ZM11.7008 3.10156H3.30078V15.1016H11.7008V3.10156ZM5.10078 13.3016H6.90078V4.90156H5.10078V13.3016ZM8.10078 13.3016H9.90078V4.90156H8.10078V13.3016Z"
                    fill="#1C1B1F"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="create-new-channel" (click)="openTab()">
          <span class="new-channel-span"
            ><svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_9006_20744"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="25"
                height="25"
              >
                <rect x="0.25" y="0.5" width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_9006_20744)">
                <path
                  d="M11.5 13.25H6.5C6.2875 13.25 6.10938 13.1781 5.96563 13.0343C5.82188 12.8904 5.75 12.7122 5.75 12.4997C5.75 12.2871 5.82188 12.109 5.96563 11.9654C6.10938 11.8218 6.2875 11.75 6.5 11.75H11.5V6.75C11.5 6.5375 11.5719 6.35938 11.7157 6.21563C11.8595 6.07188 12.0377 6 12.2503 6C12.4629 6 12.641 6.07188 12.7846 6.21563C12.9282 6.35938 12.9999 6.5375 12.9999 6.75V11.75H18C18.2125 11.75 18.3906 11.8219 18.5343 11.9657C18.6781 12.1095 18.75 12.2877 18.75 12.5003C18.75 12.7129 18.6781 12.891 18.5343 13.0346C18.3906 13.1782 18.2125 13.25 18 13.25H12.9999V18.25C12.9999 18.4625 12.928 18.6406 12.7842 18.7843C12.6404 18.9281 12.4622 19 12.2497 19C12.0371 19 11.859 18.9281 11.7154 18.7843C11.5718 18.6406 11.5 18.4625 11.5 18.25V13.25Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
            <span class="payment-channel-light"
              >Create New Payment Channel</span
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</main>

<app-crud-delete-modal
  [selectedItem]="selectedChannel"
  (confirmDelete)="onDeleteConfirmed()"
  (cancelDelete)="resetSelectedChannel()"
>
</app-crud-delete-modal>
