import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Womteam } from 'src/app/models/womteam.model';
import { FieldAgentsService } from 'src/app/services/field-agents.service';
import { AgentDetailsComponent } from '../../agents/agent-details/agent-details.component';

@Component({
  selector: 'app-add-agent-popup',
  templateUrl: './add-agent-popup.component.html',
  styleUrls: ['./add-agent-popup.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class AddAgentPopupComponent {
  fieldAgentsNew: any[] = []
  fieldAgents: any[] = []
  selectedItems: any[] = []
  alreadySelectedNew: any[] = []
  isAgentPopupOpen: boolean = false;

  maxVisibleItems = 17;
  hiddenItemsCount: number = 0;
  showHiddenNames: boolean = false;
  teamLeads: any[] = [];
  @Input() teamBu: number = 0;
  @Input() teamAgents: Womteam= new Womteam()

  @Input() teamId: number[] = [];
  @Input() popup: number = 0;

  public search: string = ''
  @Output() dataEmitter = new EventEmitter<any>();
  @ViewChild('confirmReassign') confirmReassign: AgentDetailsComponent | any;

  constructor(
    private fieldAgentService: FieldAgentsService,
    private elementRef: ElementRef,
    private toastr: ToastrService) { }


  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.isAgentPopupOpen) {
      if (this.elementRef.nativeElement.contains(event.target) || event.target === this.elementRef.nativeElement) {
        if (!isSliderClicked) {
          this.close();
        }
      }
    }
  }
  searchChanged() {
    this.fieldAgentsNew = this.fieldAgents.filter(x => x.userName.toLowerCase().includes(this.search.toLowerCase()))
  }

  addAgent() {
    if(this.popup!=2){
      this.selectedItems = []
      this.selectedItems = this.fieldAgentsNew.filter((item2: { checked: any; }) => item2.checked);
      this.hiddenItemsCount = Math.max(this.selectedItems.length - this.maxVisibleItems, 0);

      this.teamLeads = this.selectedItems.map(selectedItem => selectedItem);
      let obj = {
        teamLead: this.teamLeads,
        selectedItems: this.selectedItems,
        hiddenItemsCount: this.hiddenItemsCount
      }
      this.dataEmitter.emit(obj);
      this.isAgentPopupOpen = false;
    }else{
       this.confirmReassign.emit()
    }
  }

  open(agents: any) {
    this.alreadySelectedNew = agents
    this.getAllFieldAgents();

    this.selectedItems = this.fieldAgentsNew.filter((item2: { checked: any; }) => item2.checked);
    
    this.isAgentPopupOpen = true;
  }

  close() {
    this.isAgentPopupOpen = false;
  }
  getAllFieldAgents(page: any = null) {
    if(this.teamBu === 0){
      this.toastr.warning("You must choose BU first!")
      return;
    }
    let obj = {
      pageInfo: {
        page: 1,
        pageSize: 10
      },
      filterParams: {
        Id: 0,
        BuId: this.teamBu,
        TeamId: this.teamId,
        SearchContent: this.search
      }
    };
    this.fieldAgentService.getAllFieldAgents(obj).subscribe(response => {
      this.fieldAgents = response.data;
      this.fieldAgentsNew = this.fieldAgents
      for (const item1 of this.fieldAgentsNew) {
        for (const item2 of this.teamAgents.agentsIds) {
          if (item1.id === item2) {
            item1.checked=true;
          }
        }
      }
        this.fieldAgentsNew.forEach(agent => {
          var item = this.alreadySelectedNew.find(x => x.id == agent.id)
          if(item)
            agent.checked = true
          else{
            agent.checked = false;
          }
        })
      }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
