import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AdditionalInfo } from 'src/app/models/additionalInfo.model';
import { MapFilter } from 'src/app/models/mapFilter.model';
import { MapFilterDataOut } from 'src/app/models/mapFilterDataOut.model';
import { MapService } from 'src/app/services/map.service';


@Component({
  selector: 'app-clusterized-map',
  templateUrl: './clusterized-map.component.html',
  styleUrls: ['./clusterized-map.component.scss']
})
export class ClusterizedMapComponent implements OnInit  {

  @ViewChild('AgmMap') public agmMap: google.maps.Map;
  mapData: MapFilterDataOut[] = [];
  @Input() reloadMapTrigger : boolean = true;



  searchValue: string = "";

  mapLoader: boolean = false;

  northEastLat: number = 0;
  northEastLng: number = 0;
  southWestLat: number = 0;
  southWestLng: number = 0;
  zoom: number = 6;

  lat: number = 9.072264;
  lng: number = 7.491302;

  lastSentLongitude: number = 0;
  lastSentLatitude: number = 0;

  drag = false;
  mapStep = 0.00005;

  isMenuOpen = false;
  isMenuOpenRegion = false;
  buName : string = "";

  additionalInfo: any;
  name: any;


  buildingCustomers: [] = [];
  tooltipPositionX: number = 0;
  tooltipPositionY: number = 0;
  public tooltipHidden: boolean = true;

  constructor(private mapService: MapService) { }

  ngOnInit(): void {
    // this.getMapData();
  }

  getMapData(){
    let mapFilter = new MapFilter();


    mapFilter.SearchValue = this.searchValue;
    mapFilter.NorthEastLat = this.northEastLat;
    mapFilter.NorthEastLng = this.northEastLng;

    mapFilter.SouthWestLat = this.southWestLat;
    mapFilter.SouthWestLng = this.southWestLng;
    mapFilter.Zoom = this.zoom;
    this.lastSentLongitude = this.northEastLng;
    this.lastSentLatitude = this.northEastLat;
    this.mapService.getAccMarkers(mapFilter).subscribe({
      next: (data) => {
        this.mapData = data.body.data;
      },
      error: (error) => console.log(error.statusText)
    });
  }

  onZoomChange(event: any) {
    this.zoom = event;
    this.mapLoader = true;
  }

  mapBoundsChange(event: any) {
    this.northEastLat = event.getNorthEast().lat();
    this.northEastLng = event.getNorthEast().lng();
    this.southWestLat = event.getSouthWest().lat();
    this.southWestLng = event.getSouthWest().lng();

    this.hideTooltip();
  }

  onMouseMove(event: any) {
    let zoom = ( window.outerWidth - 10 ) / window.innerWidth;

    this.tooltipPositionX = event.pageX * zoom;
    this.tooltipPositionY = event.pageY * zoom;
  }

  mapReady(map: any) {
    map.addListener("dragend", () => {
      if(Math.abs(this.northEastLng - this.lastSentLongitude) > this.mapStep ||
          Math.abs(this.northEastLat - this.lastSentLatitude) > this.mapStep) {
          this.getMapData();
          this.drag = true;
        }
    });
  }

  loaded()
  {
    if(!this.drag) {
        this.getMapData();
        this.mapLoader = false;
    } else {
      this.drag = false;
    }
  }

  zoomPoint(event: any, data: any) {
    this.showTooltip(event, data.additionalInfo)
  }

  showTooltip(event: any, additionalInfo: AdditionalInfo) {
    this.tooltipHidden = false;

    this.placeTooltip(event, additionalInfo);
   }

   placeTooltip(event : any, additionalInfo: AdditionalInfo) {

    this.additionalInfo = additionalInfo;
    this.name = additionalInfo.name
    let d2 = document.getElementById('tooltip-div')!;
    if(d2 != undefined) {
      d2.style.display = 'flex';
      // if(this.tooltipPositionY -0.1*innerHeight)
      d2.style.left = this.tooltipPositionX - 95 +'px';

      if(this.tooltipPositionY -window.outerHeight*0.1  + d2.clientHeight>680){
        d2.style.top =  this.tooltipPositionY - 250 + 'px';
      }else{
        d2.style.top =  this.tooltipPositionY -window.outerHeight*0.1 + 'px';

      }
    }
  }

  hideTooltip() {
    this.tooltipHidden = true;
  }

  zoomCluster(data: any)
  {
    if(this.zoom + 4 > 22) {
      this.zoom = 22;
    } else {
      this.zoom += 4;
    }

    this.lat = data.latitude;
    this.lng = data.longitude;
  }

  ngOnChanges(changes: SimpleChanges) {
        this.getMapData();
  }
}
