export class CustomComment{
  id: number = 0
  text: string = ''
  authorGuid: string = ''
  authorFirstName: string = ''
  authorLastName: string = ''
  authorImage: string = ''
  isEdited: boolean = false
  lastUpdateTime: Date = new Date 
  attachments: string[] = []
}