<div class="slider" [ngClass]="{'shadow' : showShadow === true}" [@slideInOut]="animationState">
  <div class="tab-header">
    <div class="btn-close-position">
      <div class="btn-close" (click)="changeState('closeModal', selectedWorkOrder)">
        <img alt="Close" src="../../../../../../assets/images/ExitButton.svg">
      </div>
    </div>


    <!-- Show more info modal -->
    <div *ngIf="showMoreInfo" class="modalContent showMoreInfo">
      <div class="tab-header-info">
        <div class="header-blue">

          <div class="tab-field-name">
            <span class="tab-field-header">
              Work Order Category
            </span>
            <span class="tab-field-body">{{selectedWorkOrder.category || '/'}}</span>
          </div>

          <div class="tab-field-name">
            <span class="tab-field-header">
              Asset Type
            </span>
            <span class="tab-field-body">{{selectedWorkOrder.assetType || '/'}}</span>
          </div>
        </div>

        <div class="header-green">
          <div>
            <span class="label">
              Date Created
            </span>
            <span>{{(selectedWorkOrder.dateCreated | date:'dd.MM.YYY') || '/'}}</span>
          </div>
          <div class="status">
            {{selectedWorkOrder.status || '/'}}
          </div>
        </div>
      </div>
      <div class="tab-body-info">
        <div class="tab-body-row">
          <div class="tab-body-row-header">{{buName}}</div>
          <div class="tab-body-row-body">
            {{selectedWorkOrder.buName || '/'}}
          </div>
        </div>
        <div class="tab-body-row">
          <div class="tab-body-row-header">Work Order description</div>
          <div class="tab-body-row-body">
            {{selectedWorkOrder.description || '/'}}
          </div>
        </div>
        <div class="tab-body-row">
          <div class="tab-body-row-header">Reporter</div>
          <div class="tab-body-row-body">
            {{selectedWorkOrder.reporter || '/'}}
          </div>
        </div>
        <div class="tab-body-row">
          <div class="tab-body-row-header">Assigned Staff</div>
          <div class="tab-body-row-body">
            {{selectedWorkOrder.assignedUser || '/'}}
          </div>
        </div>
        <div class="tab-body-row">
          <div class="tab-body-row-header">Assigned Team</div>
          <div class="tab-body-row-body">
            {{selectedWorkOrder.assignedTeam || '/'}}
          </div>
        </div>
        <div class="tab-body-row">
          <div class="tab-body-row-header">Date Assigned</div>
          <div class="tab-body-row-body">
            {{(selectedWorkOrder.dateAssigned | date:'dd.MM.YYY') || '/'}}
          </div>
        </div>
        <div class="tab-body-row">
          <div class="tab-body-row-header">Date Removed</div>
          <div class="tab-body-row-body">
            {{(selectedWorkOrder.dateRemoved | date:'dd.MM.YYY') || '/'}}
          </div>
        </div>
        <div class="tab-body-row">
          <div class="tab-body-row-header">Incident Aging</div>
          <div class="tab-body-row-body">
            {{incidentAgingCalculation(selectedWorkOrder.dateAssigned, selectedWorkOrder.dateRemoved, selectedWorkOrder.status) | number: '1.0-2' }} hours
          </div>
        </div>
        <div class="tab-body-row">
          <div class="tab-body-row-header">Status</div>
          <div class="tab-body-row-body">
            {{selectedWorkOrder.status || '/'}}
          </div>
        </div>
        <div class="tab-body-row">
          <div class="tab-body-row-header">Summary</div>
          <div class="tab-body-row-body">
            {{selectedWorkOrder.summary || '/'}}
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="btn blue-btn" (click)="handleButtonClick('editWorkOrder')" click-stop-propagation>
          <span>Edit Work Order </span>
          <img src="../../../../../assets/images/pencil-line.svg" alt="Edit icon" />
        </button>
        <button class="btn green-btn" (click)="handleButtonClick('assignWorkOrder')" click-stop-propagation>Assign Work Order</button>
      </div>
    </div>


    <!-- Show Edit Work Order modal -->
    <div *ngIf="editWorkOrder" class="modalContent editWorkOrder">
      <form>
        <h2>Edit Work Order</h2>

        <div class="input">
          <input type="text" placeholder="Summary" name="summary" [(ngModel)]="summary" />
        </div>
        <div class="input textarea">
          <textarea placeholder="Description" row="4" name="description" [(ngModel)]="description"></textarea>
        </div>

        <div class="input group">
          <div class="dropdown">
            <app-dropdown-menu
              label = "Asset type"
              [options] = "assetTypesForOptions"
              [dropdownName] = "'assetType'"
              [selectedOption]="selectedAssetType"
              (optionSelected)="optionSelected($event, 'assetType')"
              (clearSelected)="selectedAssetType = ''" >
            </app-dropdown-menu >
          </div>

          <div class="dropdown">
            <app-dropdown-menu
              label = "Business Unit"
              [options] = "buForOptions"
              [dropdownName] = "'businessUnit'"
              [selectedOption]="selectedBusinessUnit"
              (optionSelected)="optionSelected($event, 'businessUnit')"
              (clearSelected)="selectedBusinessUnit = ''" >
            </app-dropdown-menu >
          </div>
        </div>
        <!-- <div class="input">
          <input type="text" placeholder="Reporter" name="reporter" [(ngModel)]="reporter" />
        </div> -->

        <div class="input group">
          <div class="dropdown">
            <app-dropdown-menu
              label = "Assigned team"
              [options] = "womTeams"
              [dropdownName] = "'assignedTeam'"
              [selectedOption]="selectedAssignedTeam"
              (optionSelected)="optionSelected($event, 'assignedTeam')"
              (clearSelected)="selectedAssignedTeam = ''" >
            </app-dropdown-menu >
          </div>

          <div class="dropdown">
            <app-dropdown-menu
              label = "Assigned staff"
              [options] = "womTeamAgents"
              [dropdownName] = "'assignedStaff'"
              [selectedOption]="selectedAssignedStaff"
              (optionSelected)="optionSelected($event, 'assignedStaff')"
              (clearSelected)="selectedAssignedStaff = ''" >
            </app-dropdown-menu >
          </div>
        </div>

        <div class="upload-files-container">
          <div class="file-area">
            <span style="color:red;" *ngIf="message">{{message}}</span>
            <label class="label">
              <div class="files-wrapper">
                <div *ngFor="let img of readyFiles" class="img-wrapper">
                  <img alt="" [src]="img.imgURL" class="img" height="200" *ngIf="img.imgURL">
                  <img alt="Remove image" src="../../../../../assets/images/remove-img-icon.svg" (click)="handleRemove($event, img.id)" class="removeIcon" alt="Remove" title="Remove image">
                </div>
              </div>
              <span class="browse-files">
                <input #file type="file" accept='image/*' multiple (change)="preview(file.files)" />
                <span class="text">Click or Drag to Add Attachments</span>
              </span>
            </label>
          </div>
         </div>

        <div class="buttons">
          <button type="submit" class="btn green-btn" (click)="handleCreateWorkOrder(selectedWorkOrder.id)">Save</button>
        </div>
      </form>


    </div>

    <!-- Show Assign Work Order modal -->
    <div *ngIf="assignWorkOrder" class="modalContent assignWorkOrder">
      <form>
        <h2>Assign Work Order</h2>
        <div class="dropdown">
          <app-dropdown-menu
            label = "Assigned team"
            [options] = "womTeams"
            [dropdownName] = "'assignedTeam'"
            [selectedOption]="selectedAssignedTeam"
            (optionSelected)="optionSelected($event, 'assignedTeam')"
            (clearSelected)="selectedAssignedTeam = ''" >
          </app-dropdown-menu >
        </div>

        <div class="dropdown">
          <app-dropdown-menu
            label = "Assigned staff"
            [options] = "womTeamAgents"
            [dropdownName] = "'assignedStaff'"
            [selectedOption]="selectedAssignedStaff"
            (optionSelected)="optionSelected($event, 'assignedStaff')"
            (clearSelected)="selectedAssignedStaff = ''" >
          </app-dropdown-menu >
        </div>

        <div class="buttons">
          <button type="submit" class="btn green-btn" (click)="handleAssignWorkOrder(selectedWorkOrder.id)">Save</button>
        </div>
      </form>
    </div>

    <!-- Show Create Work Order modal -->
    <div *ngIf="createWorkOrder" class="modalContent createWorkOrder">
      <form>
        <h2>Create Work Order</h2>
        <div class="dropdown">
          <app-dropdown-menu
            label = "Work Order Category"
            [options] = "workOrderCategoriesForOptions"
            [dropdownName] = "'workOrderCategory'"
            [selectedOption]="selectedworkOrderCategory"
            (optionSelected)="optionSelected($event, 'workOrderCategory')"
            (clearSelected)="selectedworkOrderCategory = ''" >
          </app-dropdown-menu >
        </div>
        <div class="input">
          <input type="text" placeholder="Summary" name="summary" [(ngModel)]="summary" />
        </div>
        <div class="input textarea">
          <textarea placeholder="Description" row="4" name="description" [(ngModel)]="description"></textarea>
        </div>

        <div class="input group">
          <div class="dropdown">
            <app-dropdown-menu
              label = "Asset type"
              [options] = "assetTypesForOptions"
              [dropdownName] = "'assetType'"
              [selectedOption]="selectedAssetType"
              (optionSelected)="optionSelected($event, 'assetType')"
              (clearSelected)="selectedAssetType = ''" >
            </app-dropdown-menu >
          </div>

          <div class="dropdown">
            <app-dropdown-menu
              label = "Business Unit"
              [options] = "buForOptions"
              [dropdownName] = "'businessUnit'"
              [selectedOption]="selectedBusinessUnit"
              (optionSelected)="optionSelected($event, 'businessUnit')"
              (clearSelected)="selectedBusinessUnit = ''" >
            </app-dropdown-menu >
          </div>
        </div>

        <div class="input group">
          <div class="dropdown">
            <app-dropdown-menu
              label = "Assigned team"
              [options] = "womTeams"
              [dropdownName] = "'assignedTeam'"
              [selectedOption]="selectedAssignedTeam"
              (optionSelected)="optionSelected($event, 'assignedTeam')"
              (clearSelected)="selectedAssignedTeam = ''" >
            </app-dropdown-menu >
          </div>

          <div class="dropdown">
            <app-dropdown-menu
              label = "Assigned staff"
              [options] = "womTeamAgents"
              [dropdownName] = "'assignedStaff'"
              [selectedOption]="selectedAssignedStaff"
              (optionSelected)="optionSelected($event, 'assignedStaff')"
              (clearSelected)="selectedAssignedStaff = ''" >
            </app-dropdown-menu >
          </div>
        </div>

        <div class="upload-files-container">
          <div class="file-area">
            <span style="color:red;" *ngIf="message">{{message}}</span>
            <label class="label">
              <div class="files-wrapper">
                <div *ngFor="let img of readyFiles" class="img-wrapper">
                  <img alt="" [src]="img.imgURL" class="img" height="200" *ngIf="img.imgURL">
                  <img alt="Remove image" src="../../../../../assets/images/remove-img-icon.svg" (click)="handleRemove($event, img.id)" class="removeIcon" alt="Remove" title="Remove image">
                </div>
              </div>
              <span class="browse-files">
                <input #file type="file" accept='image/*' multiple (change)="preview(file.files)" />
                <span class="text">Click or Drag to Add Attachments</span>
              </span>
            </label>
          </div>
         </div>

        <div class="buttons">
          <button (click)="handleCreateWorkOrder()" type="submit" class="btn green-btn">Save</button>
        </div>
      </form>
    </div>

  </div>
</div>
