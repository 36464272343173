import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { GraphItem } from 'src/app/models/graph-item.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { MenuItem } from '../../consumption/model/model';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-org-watch-new',
  templateUrl: './org-watch-new.component.html',
  styleUrls: ['./org-watch-new.component.scss']
})
export class OrgWatchNewComponent implements OnInit {

  
  //multi filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public businessUnits: any = { name: this.buName, data: [], dropdown: true };
  public buSelected: any = {value: 0, name: '', checked: false}

  // Search Autocomplete
  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public options = ['']
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public searchPlaceholder: string = "Search by " + this.buName + " name"
  
  //Tab
  @Input() firstText: string = 'Region'
  @Input() secondText: string = 'Area'
  @Output() tabChanged = new EventEmitter();

  //Datepicker
  @Input() dateFrom: any = '' 
  @Output() dateFromChange = new EventEmitter();
  @Input() dateTo: any = ''
  @Output() dateToChange = new EventEmitter();
  public isMonthly: boolean = true

  @Input() menu!: MenuItem;
  @Output() redirect: EventEmitter<any> = new EventEmitter()
  public mode: number = 1

  //Graph
  public barChartType: ChartType = 'bar'
  private graphData: GraphItem[] = []
  public CapitalLabels: string[] = [];
  public EnergyLabels: string[] = [];
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        grid: {
          display: false
        },
        border:{
          display: false
        },
      },
      y: {
        grid: {
          color: '#E8E8E8',
        },
        border:{
          display: false
        },
        ticks: {
          padding: 30,
        }
      }   
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
  
            const data = context.parsed.y !== null
              ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(context.parsed.y)
              : '';
            return [label, data];
          },
          afterLabel: function () {
            return '';
          }
        },
        backgroundColor: '#2E3336', 
        borderColor: 'rgba(0, 0, 0, 0.5)',
        borderWidth: 1,
        padding: 20, 
        cornerRadius: 10,
        displayColors: false,
        xAlign: 'center', 
        yAlign: 'bottom'
      } 
    } 
  };

  public CapitalLoss: ChartDataset[] = [
    {
      data: [], label: 'TOTAL ENERGY LOSSES',
      backgroundColor: 'rgb(192, 52, 56)',
      borderRadius: 45,     
      barThickness: 12
    },
  ];

  public EnergyLoss: ChartDataset[] = [
    {
      data: [], label: 'TOTAL ENERGY LOSSES',
      backgroundColor: 'rgb(192, 52, 56)',
      borderRadius: 45,     
      barThickness: 12
    }
  ]

  constructor(
    private buService: BusinessUnitService,
    private utService: UndertakingService,
    private toastr: ToastrService,
    private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.setMainDate()
    this.fetchData()
  }

  setMainDate() {
    let date = new Date()

    date.setHours(2, 0, 0)
    date.setDate(1)

    this.dateTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    date.setMonth(date.getMonth() - 1);
    this.dateFrom = { year: date.getFullYear(), month: date.getMonth() + 1, day: 1};
  }


  changeTab(value: number) {
    this.mode = value
    if (this.mode == 1){
      this.searchPlaceholder = "Search by " + this.buName + " name";
      this.resetFilters()
    }
    if (this.mode == 2){
      this.searchPlaceholder = "Search by " + this.utName + " name";
      this.resetFilters()
      if(this.businessUnits.data.length == 0)
        this.getBuForSelect();
    }

    this.tabChanged.emit(this.mode)
    this.fetchData()

  }

  public fetchData() {
    if (new Date(this.dateFrom) >= new Date(this.dateTo)) {
      this.toastr.error("End date can\'t be before or equal with start date")
      return;
    } 
    let objEnergy = {
      Start: transformToString(this.dateFrom),
      End: transformToString(this.dateTo),
      buId: this.buSelected.value,
      Search: this.searchText.name,
      GetAll: false,
      GetCapitalLoss: 0
    }
    switch (this.mode) {
      case 1:
        this.getBuLoss(objEnergy);
        break;
      case 2: 
        this.getUtsLoss(objEnergy);
        break;
    }

  }

  private getBuLoss(obj: any) {
    this.buService.getBuWorstEnergyLosses(obj).subscribe(response => {
      this.handleEnergyLoss(response);
      this.setValuesForCapitalLoss(response);
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  private getUtsLoss(obj: any) {
    this.utService.getUtsWorstEnergyLosses(obj).subscribe(response => {
      this.handleEnergyLoss(response);
      this.setValuesForCapitalLoss(response);
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  public download() {
    let obj = {
      Start: transformToString(this.dateFrom),
      End: transformToString(this.dateTo),
      buId: this.buSelected.value,
      GetAll: false,
    }
    switch (this.mode) {
      case 1:
        this.downloadBuWorstRecordedAssets(obj);
        break;
      case 2:
        this.downloadUtsWorstRecordedAssets(obj);
        break;
    }
  }

  private downloadUtsWorstRecordedAssets(obj: any) {
    if (this.graphData.length == 0) {
      this.toastr.info('No recorded assets for selected time period.')
    }
    else{
      this.utService.downloadWorstRecordedAssets(obj).subscribe(response => {
        window.location.href = response.data;
      }, _ => {
        this.toastr.error('An error occurred.');
      })
    }
  }

  private downloadBuWorstRecordedAssets(obj: any) { 
    if (this.graphData.length == 0) {
      this.toastr.info('No recorded assets for selected time period.')
    }
    else {
      this.buService.downloadBuWorstRecordedAssets(obj).subscribe(response => {
        window.location.href = response.data;
      }, _ => {
        this.toastr.error('An error occurred.');
      })
    }
  }

  public SelectBuFilter(bu: any) { 
    this.buSelected = bu;
    this.fetchData()
  }

  private getBuForSelect() {
    this.buService.getAllForSelect().subscribe(response => {
      if (response.status === 200) {
        this.businessUnits = { name: this.buName, data: response.data, dropdown: true }
        this.checkFilters()
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  private handleEnergyLoss(response: any) {
    this.EnergyLoss[0].data = []
    this.EnergyLabels = []
    let labels = ['']
    labels = []
    if (response.data.dataWithoutCapitalLoss != null && response.data.dataWithoutCapitalLoss.length > 0) {
      this.graphData = response.data.dataWithoutCapitalLoss;
      this.graphData.forEach(element => {
        this.EnergyLoss[0].data?.push(Math.round(element.energyLoss * 100) / 100)
        labels.push(this.sliceLabel(element.name))
      })

      this.EnergyLabels = labels
    }
  }
  
  private sliceLabel(label: string) {
    if (label.length > 20)
      return label.slice(0, 20) + '...'
    return label
  }
  private setValuesForCapitalLoss(response: any) {
    this.CapitalLoss[0].data = []
    this.CapitalLabels = []
    let labels = ['']
    labels = []
    if (response.data.dataWithCapitalLoss != null && response.data.dataWithCapitalLoss.length > 0) {
      this.graphData = response.data.dataWithCapitalLoss;
      this.graphData.forEach(element => {
        this.CapitalLoss[0].data?.push(Math.round(element.energyLoss * 1000) / 1000)
        labels.push(this.sliceLabel(element.name))
      })

    }
    this.CapitalLabels = labels
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom;
    this.dateTo = data.dateTo;
    this.fetchData();
  }

  //Multi Filter
  dropdownSelected(data: any){
    if(!data)
      return
    if(data.data.name === this.buName)
      this.SelectBuFilter(data.item)
  }
  
  checkFilters() {
    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);
    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;
  
    if (this.mode == 2 && buIndex === -1) {
      this.allFilters.push(this.businessUnits)
    }

    if(this.allFilters.length != 0)
      this.filterApplied = true

    this.allFilters = [...this.allFilters]
  }

  resetFilters() {
    this.searchText = new SearchAutocomplete()
    if(this.searchChild){
      this.searchChild.searchText = new SearchAutocomplete()
      this.searchChild.resetOptions()
    }
    this.showFilter = false
    this.filterApplied = false
    this.buSelected = {value: 0, name: '', checked: false}
    this.allFilters = []
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.setMainDate()
    this.checkFilters()
  }

  
  // Search Autocomplete
  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name, 
      msnIncluded: false
    }
    if (searchText.name.length > 2) {
      if (this.mode == 1) {
        this.buService.getDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
      else if (this.mode == 2) {
        this.utService.getDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
    } else {
      this.searchChild.resetOptions()
    }
  }
  
  // Search Autocomplete
  searchChanged(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.fetchData()
  }
}
