export const environment = {
  production: true,
  pathApi: '/api/',
  environmentName: 'AEDCProd',
  mapCenterLat: 9.0893692,
  mapCenterLong: 7.440879,
  mapZoom: 15,
  useOpenIdDict: false,
  homeUrl: 'https://adora-home.azurewebsites.net/',
};
