<div class="popup-wrapper">
  <div class="header">
    <div class="header-back" (click)="back()">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.52116 7.83342H12.8337C13.0698 7.83342 13.2677 7.75356 13.4274 7.59384C13.5871 7.43412 13.667 7.2362 13.667 7.00009C13.667 6.76398 13.5871 6.56606 13.4274 6.40634C13.2677 6.24662 13.0698 6.16676 12.8337 6.16676H3.52116L7.60449 2.08342C7.77116 1.91676 7.85102 1.72231 7.84408 1.50009C7.83713 1.27787 7.75033 1.08342 7.58366 0.916758C7.41699 0.76398 7.22255 0.684119 7.00033 0.677174C6.7781 0.67023 6.58366 0.750091 6.41699 0.916758L0.916992 6.41676C0.833659 6.50009 0.774632 6.59037 0.739909 6.68759C0.705187 6.78481 0.687826 6.88898 0.687826 7.00009C0.687826 7.1112 0.705187 7.21537 0.739909 7.31259C0.774632 7.40981 0.833659 7.50009 0.916992 7.58342L6.41699 13.0834C6.56977 13.2362 6.76074 13.3126 6.98991 13.3126C7.21908 13.3126 7.41699 13.2362 7.58366 13.0834C7.75033 12.9168 7.83366 12.7188 7.83366 12.4897C7.83366 12.2605 7.75033 12.0626 7.58366 11.8959L3.52116 7.83342Z"
          fill="#1C1B1F" />
      </svg>
    </div>
    <div class="header-title">
      <span *ngIf="stockItem.id == 0">Create inventory</span>
      <span *ngIf="stockItem.id != 0">Edit inventory</span>
    </div>
    <div class="add-wrapper">
      <label *ngIf="stockItem.id == 0" for="submitForm" tabindex="0" class="add-agent">Create inventory</label>
      <label *ngIf="stockItem.id != 0" for="submitForm" tabindex="0" class="add-agent">Edit inventory</label>
    </div>
  
  </div>
  
  <div class="popup-content">
  
    <div class="left-menu">
      <div class="menu-elements">
        <span>
          <input type="radio" name="radio1" id="radio1">
          <label class="radio-label" for="radio1">Title & Description</label>
        </span>
  
        <span>
          <input type="radio" name="radio1" id="radio1">
          <label class="radio-label" for="radio1">Category</label>
        </span>
  
        <span>
          <input type="radio" name="radio1" id="radio1">
          <label class="radio-label" for="radio1">Quantity & Condition</label>
        </span>
  
        <span>
          <input type="radio" name="radio1" id="radio1">
          <label class="radio-label" for="radio1">Stock threshold</label>
        </span>
  
      </div>
  
    </div>
    <div class="create-form">
      <form class="form" (submit)="f.form.valid && create()" #f="ngForm">
        <div class="col-sm-12 crud-input-container">
          <label class="labelPopup">Item Name</label>
          <input class="form-control" name="name" [(ngModel)]="stockItem.name" #name="ngModel"
            required autocomplete="off" placeholder="Enter item name" />
          <div *ngIf="f.submitted && name.invalid" class="form-control-error">Name is required</div>
        </div>
  
        <div class="col-sm-12 input textarea crud-input-container">
          <div class="optional">
            <label class="labelPopup" >Description</label>
            <label class="label-optional">Optional</label>
          </div>
          <textarea class="form-control" name="description" [(ngModel)]="stockItem.description" #description="ngModel"
              autocomplete="off" placeholder="Enter description"></textarea>
        </div>  
        <div class="col-sm-12 input crud-input-container">
          <div class="dropdowns ">
            <label class="labelPopup">Category</label>
            <app-simple-dropdown 
              [header]="selectedCategory.name"
              [dropdownHeader]="'Category'"
              [data]="categories"
              (selectItemChange)="selectCategories($event)"
              [selectedItemId]="selectedCategory.value"
            ></app-simple-dropdown>
            <div *ngIf="f.submitted && selectedCategory.value < 0" class="form-control-error">Category is required</div>
          </div>
        </div>
  
        <div class="col-sm-12 input group crud-input-container">
          <div class="dropdowns">
            <label class="labelPopup">Total Quantity</label>
            <input type="number" class="form-control" name="totalQuantity" [(ngModel)]="stockItem.totalQuantity" #totalQuantity="ngModel"
              required autocomplete="off" />
  
            <div *ngIf="f.submitted && totalQuantity.value < 0" class="form-control-error">Total quantity is required and must be positive value</div>
          </div>
          <div class="dropdowns">
            <label class="labelPopup">Qty - Fault Condition</label>
            <input type="number" class="form-control" name="faultQuantity" [(ngModel)]="stockItem.faultQuantity"  #faultQuantity="ngModel"
              required autocomplete="off" />
  
            <div *ngIf="f.submitted && faultQuantity.value < 0" class="form-control-error">Fault quantity is required and must be positive value</div>
          </div>
        </div>
  
        <div class="col-sm-12 input crud-input-container">
          <label class="labelPopup">Low Stock threshold</label>
          <input type="number" class="form-control" name="threshold" [(ngModel)]="stockItem.threshold"  #threshold="ngModel"
            required autocomplete="off" />
          <span class="label-optional">Define a threshold that determines when the stock level for that item is
            considered low.</span>
          <div *ngIf="f.submitted && threshold.value < 0" class="form-control-error">Threshold is required and must be positive value</div>
        </div>
  
        <div class="col-sm-12 crud-input-container">
          <label class="labelPopup">Attachments</label>
          <div class="upload-files-container">
            <div class="file-area">
              <span style="color:red;" *ngIf="message">{{message}}</span>
              <div class="file-input-container">
                <div class="label">
                  <span class="select-button">Select File</span>
                  <input placeholder="Select File" #file name="image" type="file" accept='image/*' multiple (change)="onFileChanged($event)"/>
                </div>
                <div class="selected-file" *ngFor="let item of attachments">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_1917_110325" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="2"
                      width="20" height="21">
                      <rect x="2" y="2.5" width="20" height="20" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_1917_110325)">
                      <path
                        d="M9.95802 14.9556L16.9309 7.98279C17.1208 7.79287 17.3461 7.69792 17.6068 7.69792C17.8675 7.69792 18.0928 7.79287 18.2828 7.98279C18.4727 8.17271 18.5676 8.39803 18.5676 8.65875C18.5676 8.91946 18.4727 9.14477 18.2828 9.33469L10.626 16.9914C10.4361 17.1813 10.2135 17.2763 9.95802 17.2763C9.70259 17.2763 9.47992 17.1813 9.29 16.9914L5.71663 13.418C5.52671 13.2281 5.43439 13.0028 5.43967 12.7421C5.44496 12.4814 5.54256 12.256 5.73248 12.0661C5.9224 11.8762 6.14772 11.7812 6.40844 11.7812C6.66915 11.7812 6.89446 11.8762 7.08438 12.0661L9.95802 14.9556Z"
                        fill="#439745" />
                    </g>
                  </svg>
                  <span title="{{item}}">{{item}}</span>
                </div>
                <span class="selected-file-name"></span>
              </div>
            </div>
          </div>
        </div>
  
        <div class="labels-wrapper">
          <span class="label-optional">Jpeg, Png, Pdf,24mb max file</span>
        </div>
        <button type="submit" id="submitForm" [hidden]="true"></button>
      </form>
    </div>
  
    <div class="preview-menu">
      <div class="preview-header">
        <span>Preview</span>
      </div>
  
      <div class="preview-items">
        <span>Item Name</span>
        <span>Description</span>
        <span>Category</span>
        <span>Quantity</span>
        <span>Condition</span>
        <span>Stock threshold</span>
        <span>Attachments</span>
      </div>
    </div>
  </div>
</div>