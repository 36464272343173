import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TimePeriod } from 'src/app/components/consumption/model/model';
import { MeterViewMore } from 'src/app/models/meter-management-meter.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-mm-meter-grid',
  templateUrl: './mm-meter-grid.component.html',
  styleUrls: ['./mm-meter-grid.component.scss', '../grid.component.scss']
})
export class MeterGridComponent implements OnChanges {
  public isViewMore: boolean = false;

  public count: number = 0
  public pageSize: number = 10
  public currentPage: number = 1
  public currentMeterId: number = 1
  public lastPageSize: number = 1;
  // @ViewChild(F33PopupComponent) popupRef!: F33PopupComponent;

  @Input() meterIds: number[] = []
  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''
  @Input() search: SearchAutocomplete = new SearchAutocomplete()
  @Input() timePeriod: TimePeriod = TimePeriod.Monthly;
  @Input() meterItemType: string = '';

  @Input() meterItemSubType: string = '';
  @Input() buIds: any = [];
  @Input() utIds: any = [];
  @Input() dtIds: any = [];
  @Input() amiIds: any = [];
  @Input() customerTypeValue :number = 0;

  @Output() showDetails = new EventEmitter<any>();

  public masterSelected: boolean = false;

  public metersId: any = [];
  public checkedList: any = [];
  public data: MeterViewMore[] = [];

  public amiName: string = this.translationService.getByKeyFromCache('AMI');

  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase();
  public utName: string = this.translationService.getByKeyFromCache('UT').toUpperCase();
  constructor(
    private meterService: MeterService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private router: Router,
  ) { }

  copyToClipboard(text: any) {
    const textToCopy = text;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    this.toastr.success('Copied to clipboard')

  }
  ngOnChanges(changes: SimpleChanges) {

    let reloadTable = false

    if (changes.meterIds != null) {
      reloadTable = true
    }
    if (changes.buIds != null) {
      reloadTable = true
    }
    if (changes.dtIds != null) {
      reloadTable = true
    }
    if (changes.utIds != null) {
      reloadTable = true
    }
    if (changes.customerTypeValue != null) {
      reloadTable = true
    }
    if(changes.search !=null){
      reloadTable = true
    }
    if (this.search?.name == '')
      this.search = changes.search?.previousValue

    if (changes.search != null) {
      reloadTable = true
    }
    if (changes.meterItemType != null) {
      this.search = new SearchAutocomplete()
      reloadTable = true
    }
    if (changes.meterItemSubType != null) {
      this.search = new SearchAutocomplete()

      reloadTable = true
    }

    if (reloadTable) {
      this.pageChange(1)
    }
  }


  pageChange(value: number) {
    this.currentPage = value
    this.GetData()
  }

  public GetData() {

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        Search: this.search ? this.search.name : this.search,
        MeterItemType: this.meterItemType,
        CheckedList: this.metersId,
        UtIds: this.utIds,
        CustomerTypeValue: this.customerTypeValue,
        BuIds: this.buIds,
        DtIds: this.dtIds,
        AmiIds: this.amiIds,
        MeterItemSubType: this.meterItemSubType,
        CommStatus: this.commStatus,
      }
    };
    this.fetchAllMeters(obj)
  }

  fetchAllMeters(obj: any) {
    this.meterService.getAllMeters(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data;
        if (this.currentPage == 1)
          this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize);
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  public download() {
    this.metersId = [];
    if (this.checkedList.length != 0) {
      this.metersId = this.checkedList.map((item: any) => item.id)
    }

    let obj = {
      Search: this.search ? this.search.name : this.search,
      CheckedList: this.metersId,
      BuIds: this.buIds,
      DownloadAll: this.meterIds,
      MeterItemType: this.meterItemType,
      MeterItemSubType: this.meterItemSubType,
      CommStatus: this.commStatus,

    };

    this.downloadMetersReport(obj);

  }

  private downloadMetersReport(obj: any) {
    this.meterService.downloadMetersReport(obj).subscribe(response => {
      if (response.status == 200)
        window.location.href = response.data;
      else
        this.toastr.info(response.message)

    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].checked)
        this.checkedList.push(this.data[i]);
    }
    return this.checkedList;
  }

  isAllSelected() {
    this.masterSelected = this.data.every(function (item: any) {
      return item.checked == true;
    })
    this.getCheckedItemList();
  }

  openTab(item: any) {
    this.showDetails.emit(item);
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }
  public commStatus: number = 0
  public commStatusOpened: boolean = false
  public commStatusClicked(open: number) {
    if (open == 0)
      this.commStatusOpened = false
    else {
      if (this.commStatusOpened) {
        this.commStatusOpened = false
      }
      else {
        this.commStatusOpened = true
      }

    }
  }

  public commStatusSelected(value: number) {
    this.currentPage = 1
    this.commStatus = value
    this.GetData()
  }

}
