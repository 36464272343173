<main class="main-bg">
<div class="container">
    <div class="back-container"><img alt="Back" src="assets/images/ic_arrow_left.svg" />
        <div class="back-button" (click)="back()">Back</div>
    </div>
    <div class="data-table">
        <div class="row">

            <div class="crud-title">
                User's Settings
                <div class="create-wrapper">
                    <div class="create-button-wrapper">
                        <button class="btn btn-green" data-toggle="modal" data-target="#createModal" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_user_createupdate)">Create User</button>
                    </div>
                </div>
             </div>

            <div class=" col-sm-12 table-details">
                <br>
                <table>
                    <thead>
                        <tr class="header-columns">
                            <td></td>
                            <td><span>First Name</span></td>
                            <td><span>Last Name</span></td>
                            <td><span>Email</span></td>
                            <td><span>Phone Number</span></td>
                            <td><span>Actions</span></td>
                        </tr>
                    </thead>
                    <!-- Put <thead>, <tbody>, and <tr>'s here! -->
                    <tbody>
                        <tr *ngFor="let item of users" class="bodyRow clickable actions-click" [ngClass]="{'selected': selectedUser.userGuid===item.userGuid}" (click)="selectUser(item.userGuid)">
                            <td></td>
                            <td><span>{{item.firstName | hasValue}} </span></td>
                            <td><span>{{item.lastName | hasValue}} </span></td>
                            <td><span>{{item.email | hasValue}} </span></td>
                            <td><span>{{item.phoneNumber | hasValue}} </span></td>
                            <td>
                                <span><img alt="Edit" class="" src="assets/images/crud-edit.png" data-toggle="modal" data-target="#createModal" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_user_createupdate)" (click)="selectUser(item.userGuid)"/> </span>
                                <span><img alt="Delete" class="" src="assets/images/crud-delete.png"  data-toggle="modal" data-target="#deleteModal" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_user_delete)" /> </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div class="col-sm-12">
                        <app-adora-pagination [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-adora-pagination>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>
</main>

<div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="create" aria-hidden="true" appClickOutside (clickOutside)="resetSelectedUser()">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content create-modal crud-card">
            <div class="modal-header">
                <div class="modal-title"><img src="/assets/pencil.svg"> Create/Edit Users</div>
            </div>
            <div class="modal-body">
                <form (submit)="f.form.valid" #f="ngForm">
                    <!-- && save() -->
                    <div class="row modal-form">
                        <div class="col-sm-6">
                          <label>First Name</label>
                          <input class="form-control" name="firstName" [(ngModel)]="selectedUser.firstName" #firstName="ngModel" required autocomplete="off" placeholder="First Name" />
                          <div *ngIf="f.submitted && firstName.invalid" class="form-control-error">First name is required</div>
                        </div>


                        <div class="col-sm-6">
                            <label>Last Name</label>
                            <input class="form-control" name="lastName" [(ngModel)]="selectedUser.lastName" #lastName="ngModel" required autocomplete="off" placeholder="Last Name" />
                            <div *ngIf="f.submitted && lastName.invalid" class="form-control-error">Last name is required</div>
                          </div>

                          <div class="col-sm-6">
                            <label>Email</label>
                            <input type="email" class="form-control" name="email" [(ngModel)]="selectedUser.email" #email="ngModel" required autocomplete="off" placeholder="Email" />
                            <div *ngIf="f.submitted && email.errors?.['required']" class="form-control-error">Email is required</div>
                            <div *ngIf="f.submitted && !emailValid && !email.errors?.['required'];" class="form-control-error">Please enter valid email</div>
                          </div>


                          <div class="col-sm-6">
                            <label>Phone Number</label>
                            <input class="form-control" name="phoneNumber" [(ngModel)]="selectedUser.phoneNumber" #phoneNumber="ngModel" required autocomplete="off" placeholder="Phone Number" />
                            <div *ngIf="f.submitted && phoneNumber.errors?.['required']" class="form-control-error">Phone number is required</div>
                            <div *ngIf="f.submitted && !phoneNumberValid && !phoneNumber.errors?.['required']" class="form-control-error">Please enter valid phone number</div>
                          </div>
                          <div style="height: 6.6rem !important;" class="col-sm-6">
                            <label>Role</label>
                            <select  style="height: 6.6rem !important;" class="form-control" style="cursor: pointer;" name="role" [(ngModel)]="selectedUser.roleId" #role="ngModel" autocomplete="off">
                                <option selected disabled [ngValue]='0'>Select Role</option>
                                <option *ngFor="let item of roles" [value]="item.id" >{{item.name}}</option>
                            </select>
                            <div *ngIf="f.submitted && selectedUser.roleId==0" class="form-control-error">Role is required</div>
                        </div>
                        <div class="col-sm-12 form-buttons">
                            <button type="submit" class="btn green float-right" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_user_createupdate)"
                                 data-toggle="modal" data-target="#saveChangesModal" [disabled]="!f.valid || selectedUser.roleId==0">Save</button>
                            <div class="btn float-right clear-btn" (click)="resetSelectedUser()" data-dismiss="modal">Cancel</div>
                        </div>
                        <button #closeModal [hidden]="true" data-toggle="modal" data-target="#myModal" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="saveChangesModal" tabindex="-1" role="dialog" aria-labelledby="saveChanges" aria-hidden="true" click-stop-propagation>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content save-changes-modal">
            <div class="modal-header">
                <div class="title-wrapper">
                    <span class="modal-title upload-modal-title" id="exampleModalLabel">Are you sure you want to save changes?</span>
                    <div class="modal-button-wrapper">
                        <button type="button" class="btn send-btn-green modal-button-wrapper" (click)="save()" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn send-btn-red modal-button-wrapper" data-dismiss="modal">No</button>
                    </div>
                    <button #closeModal [hidden]="true" data-toggle="modal" data-target="#myModal" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="delete" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content delete-modal">
            <div class="modal-header">
                <div class="title-wrapper" *ngIf="selectedUser">
                    <span class="modal-title upload-modal-title" id="exampleModalLabel">Are you sure you want to delete?</span>
                    <div class="modal-button-wrapper">
                        <button type="button" class="btn send-btn-green modal-button-wrapper" data-dismiss="modal" (click)="deleteUser($event, selectedUser.userGuid)">Yes</button>
                        <button type="button" class="btn send-btn-red modal-button-wrapper" data-dismiss="modal">No</button>
                    </div>
                    <button #closeDeleteModal [hidden]="true" data-toggle="modal" data-target="#myModal" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
