import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { PermissionsDelta } from '../models/permissionsDelta.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private pathApi = this.config.setting['pathApi'] + "role/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    } 

    getDataForAutocomplete(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
    }

    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    } 

    save(obj: PermissionsDelta): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }

    getAll(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, obj);
    } 

    getAllWithAuditLog(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAllWithAuditLog`, obj);
    } 

    getAllAvailablePermissions(): Observable<any> {
        return this.http.get(`${this.pathApi}getAllAvailablePermissions`);
    } 

    getAllPermissionSettings(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAllPermissionSettings`, obj);
    } 

    getUserPermissions(email: string): Observable<any> {
      return this.http.get(`${this.pathApi}getUserPermissions/${email}`);
    }
}
