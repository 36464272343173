import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDatetime'
})
export class ShortDatetimePipe implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    let result = '';

    if (hours > 0) {
      result += `${hours}h `;
    }

    if (minutes > 0) {
      result += `${minutes}min`;
    }

    return result.trim();
  }

}