<div class="main-wrapper" tabindex="1" appClickOutside (clickOutside)="isSelected = false">
  <div class="simple-wrapper" (click)="isSelected = !isSelected">
    <div class="simple-header-closed">
      <span title="{{header}}">{{header}}</span>
      <svg width="12" height="8" 
      style="margin-top: 0.3rem" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.99953 7.03844L0.345703 1.38464L1.39953 0.330811L5.99953 4.93081L10.5995 0.330811L11.6534 1.38464L5.99953 7.03844Z" fill="#2E3336"/>
      </svg>      
    </div>
  </div>
  <div class="simple-wrapper selected-wrapper" *ngIf="isSelected">
    <div class="simple-dropdown">
      <div class="simple-item" *ngFor="let item of showData" (click)="select(item)" [ngClass]="{'selected': item == selectedItemId}">
        <span>{{item}}</span>
      </div>
    </div>
  </div>
  
</div>