<div class="revenue-protection-header">
  <div class="work-order-header">
      <span>Work Order Management</span>
      <div class="button">
          <button *ngIf="authService.isAuthorized(PermissionsEnum.fieldoperations_workordermanagement_createupdate)" class="red-btn" (click)="openPopup()" click-stop-propagation>
              Create Task <img alt="" src="assets/images-new-design/plus.png">
          </button>
      </div>
  </div>
</div>
<div class="filter-wrapper">
  <app-tab-default
      [firstText]="'Ongoing '"
      [firstTabNum]="taskCounts.ongoing"
      [secondText]="'Completed '"
      [secondTabNum]="taskCounts.completed"
      [thirdText]="'Cancelled'"
      [thirdTabNum]='taskCounts.canceled'
      [wom]="true"
      [womCss]="false"
      [tabSelected]="tabMenu"
      (tabChanged)="onStatusChange($event)"
  ></app-tab-default>
</div>
<div class="filters-container">

  <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

    <app-multi-filter *ngIf="filterApplied"
      [data]="allFilters"
      (dataChange)="applyFilter($event)"
      [(showFilter)]="showFilter">
    </app-multi-filter>

    <app-range-datepicker
      [fromDate]="dateCreatedFrom"
      [toDate]="dateCreatedTo"
      [name]="'Created'"
      (dateRangeSelected)="dateCreatedChange($event)">
    </app-range-datepicker>

    <app-range-datepicker *ngIf="showResolvedFilter"
      [fromDate]="dateResolvedFrom"
      [toDate]="dateResolvedTo"
      [name]="'Resolved'"
      (dateRangeSelected)="dateResolvedChange($event)">
    </app-range-datepicker>

  <app-search-autocomplete
    #search
    [placeholder]="searchPlaceholder"
    [width]="25"
    [backgroundColor]="'transparent'"
    [borderColor]="'#E8E8E8'"
    [(searchText)]="searchText"
    [options]="options"
    (searchChange)="searchChange($event)"
    (searchAutocompleteChange)="getOptions($event)"

  >
  </app-search-autocomplete>

  </div>
</div>

<table class="standard-table">
  <thead>
      <tr>
          <th><span>TASK ID</span></th>
          <th><span>TASK NAME</span></th>
          <th><span>ASSIGNED TO</span></th>
          <th><span>CATEGORY</span></th>
          <th><span>{{buName | uppercase}}</span></th>
          <th><span>DATE CREATED</span></th>
          <th class="small-td"><span></span></th>
      </tr>
  </thead>
  <tbody class="table-body">
      <tr *ngFor="let item of tableData" class="bodyRow clickable actions-click" (click)="showDetails(item)">
          <td><span>{{item.taskId}}</span></td>
          <td id="task-name-column"><span>{{item.taskName}}</span></td>
          <td class="assigned-column" *ngIf="item.assignedAgent != null">
            <div class="images-container">
                <img alt="Portrait" [src]="item.attachments[0] != null ? item.attachments[0] : defaultImage"/>
            </div>
            <span>{{item.agentUserName != null ? item.agentUserName : ''}}</span>
            <div class="overdue" *ngIf="isOverdue(item.estimatedEndDate)">
              <img alt="" src="assets/images/error.svg"/>
              <span>Overdue</span>
            </div>
          </td>
          <td *ngIf="item.assignedAgent == null">
            <span>Unassigned</span>
          </td>
          <td><span>{{item.categoryName}}</span></td>
          <td>
            <span *ngIf="item.buName != null && item.buName != ''">{{item.buName}}</span>
            <span *ngIf="item.buName == null" class="NaN">N/A</span>
          </td>
          <td><span>{{item.dateCreated | date:'dd.MM.YYY'}}</span></td>
          <td><span><img alt="..." src="../../../../../assets/images/3-dot.svg"></span></td>
        </tr>
  </tbody>
</table>
<div class="pagination-wrapper">
  <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
      [currentPage]="currentPage" (pageChange)="pageChange($event)">
  </app-fo-pagination-new>
</div>
