import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  private pathApi = this.config.setting['pathApi'] + "channel/";

  private pathApi1 = this.config.setting['pathApi'] + "account/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    GetById(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    } 
 
    getAll(): Observable<any> {
      return this.http.get(`${this.pathApi}getAll`);
    }  
    
    GetForReads(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi1}getReadsForAccount`, obj);
    }

}
