import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DtService } from 'src/app/services/dt.service';

@Component({
  selector: 'app-f33-popup',
  templateUrl: './f33-popup.component.html',
  styleUrls: ['./f33-popup.component.scss']
})
export class F33PopupComponent {
  public isPopupOpen: boolean = false;
  public selectedF33: any = {};
  public tabMenu: number = 1;
  public dataFeeder: any = {};

  constructor(
    private dtService: DtService,
    private toastr: ToastrService
  ) { }

  close() {
    this.isPopupOpen = false;
  }
  tabChanged(value: number) {
    this.tabMenu = value
  }
  getPopupInfo() {
    this.dtService.countOfDTsConnectedToF33(this.selectedF33.id).subscribe(response => {
      this.dataFeeder = response.data;
      this.dataFeeder.info = this.selectedF33;

    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }
  changeState(menu?: any, data?: any) {
    if (data)
      this.selectedF33 = data;
    this.getPopupInfo();
    if (menu === 1){
      this.isPopupOpen=true;
      this.tabMenu=1;
     }
    else
      this.isPopupOpen = false;

  }
}
