import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { TransmissionStation } from 'src/app/models/transmission-station.model';
import { AuthService } from 'src/app/services/auth.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';

@Component({
  selector: 'app-transmission-station-create',
  templateUrl: './transmission-station-create.component.html',
  styleUrls: ['./transmission-station-create.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class TransmissionStationCreateComponent {
  @ViewChild(TransmissionStationCreateComponent) popupComponentRef!: TransmissionStationCreateComponent;
  @Input() animationState = 'out';
  showShadow = false;
  selectedTs: TransmissionStation = new TransmissionStation();
  @Output() parentComp = new EventEmitter();
  @ViewChild("f") f: any;

  constructor(private toastr: ToastrService,
    private ref: ElementRef,
    private tsService: TransmissionStationService,
    private authService: AuthService) { }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  closeTab() {
    this.popupComponentRef.changeState(2);
  }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.transmission-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }
  changeState(number: Number, data?: any) {
    if (data)
      this.selectedTs = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      if(this.f)
        this.f.submitted = false;
      this.showShadow = false;
    }
  }

  save(){
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    if(Number.isNaN(Number(this.selectedTs?.longitude))){
      this.toastr.error('Longitude must be numeric value between -180 and 180.');
      return;
    }

    if(Number(this.selectedTs?.longitude) < -180 || Number(this.selectedTs?.longitude) > 180){
      this.toastr.error('Longitude must be in -180 to 180 range.');
      return;
    }

    if(Number.isNaN(Number(this.selectedTs?.latitude))){
      this.toastr.error('Latitude must be numeric value between -90 and 90.');
      return;
    }

    if(Number(this.selectedTs?.latitude) < -90 || Number(this.selectedTs?.latitude) > 90){
      this.toastr.error('Latitude must be in -90 to 90 range.');
      return;
    }

    if((this.selectedTs.name && this.selectedTs.longitude && this.selectedTs.latitude)  !== undefined){
      this.tsService.save(this.selectedTs).subscribe(response => {
        if(response.status === 200){
          this.toastr.success(response.message);
          this.changeState(2);
          this.resetSelectedTs();
          this.parentComp.emit();
         }else
          this.toastr.error(response.message);
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }

  }

  resetSelectedTs(){
    this.selectedTs = new TransmissionStation();
  }
}
