import { Component } from '@angular/core';
import { OrganizationNetworkTabs } from 'src/app/models/revenue-protection-enums.model';
import { HeaderService } from 'src/app/services/header.service';
import { MenuItem } from '../../consumption/model/model';

@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.scss'],
})
export class WatchlistComponent {
  public mainTab: OrganizationNetworkTabs = OrganizationNetworkTabs.Network;
  public tabsEnum = OrganizationNetworkTabs;

  public MenuItemEnum = MenuItem;
  menu: MenuItem = MenuItem.Network;

  constructor(private headerService: HeaderService) {}

  public switchMenu(item: MenuItem): void {
    this.menu = item;
    this.headerService.cleanGlobalSearch();
  }

  mainTabChange(value: number) {
    if (value == 1) {
      this.mainTab = this.tabsEnum.Network;
      this.switchMenu(this.MenuItemEnum.Network);
    } else {
      this.mainTab = this.tabsEnum.Organization;
      this.switchMenu(this.MenuItemEnum.Organisation);
    }
  }
}
