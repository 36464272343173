 <div class="login-container">
    <div class="row login-card">

        <div class="col-sm-12 logo-img"><img src="assets/images-new-design/BPS-logo-green.svg" alt="">
        </div>
        <div class="col-sm-12 login-form">
            <div class="login-title">Set new password</div>
            <div class="login-text">

            </div>
            <div   id="myForm" class="col-sm-12"  >
                <div class="form-group index-search-form">
                    <fieldset class="field-input">
                        <legend class="legend-input">New password</legend>
                        <input class="form-control password-icon" name="newPassword" [type]="inputType"
                            [(ngModel)]="newPassword" required>
                        <button class="input-button" type="button"><img alt="Show Password" [src]="eyeImage" (click)="changeEye()"></button>
                    </fieldset>
                </div>
                <div class="form-group index-search-form">
                    <fieldset class="field-input">
                        <legend class="legend-input">Confirm password</legend>
                        <input class="form-control password-icon" name="confirmNewPassword" [type]="inputType2"
                            [(ngModel)]="confirmNewPassword" required>
                        <button  style="margin-top:0.9rem !important"  class="input-button" type="button"><img alt="Show Confirm" [src]="eyeImage2"
                                (click)="changeEye2()"></button>
                    </fieldset>
                </div>

                <div class="form-group">
                    <button (click)="savePassword()" type="submit" [ngClass]="{'red-button': isFormValid()}"class="btn btn-primary regular-btn">
                        Save New Password
                    </button>
                </div>
            </div>
            <div class="password-notes-container">
                *Password must be at least 8 characters long.
            </div>
            <div class="password-notes-container">
                *Password must contain at least 1 uppercase letter.
            </div>
            <div class="password-notes-container">
                *Password must contain at least 1 number.
            </div>
            <div class="password-notes-container">
                *Password must contain at least 1 special character.
            </div>
        </div>
    </div>
    <div style="width: 50%; float: right;">
        <app-login-home-details></app-login-home-details>
    </div>
</div>
