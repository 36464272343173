import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MultiFilterComponent } from 'src/app/components/common-new-design/multi-filter/multi-filter.component';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';
import { CommonService } from 'src/app/services/common-service.service';
import { InventoryManagementService } from 'src/app/services/inventory-management.service';

@Component({
  selector: 'app-stock-management-main',
  templateUrl: './stock-management-main.component.html',
  styleUrls: ['./stock-management-main.component.scss']
})
export class StockManagementMainComponent implements OnInit {

  @Output() changeView: EventEmitter<any> = new EventEmitter()
  @Output() setItemId: EventEmitter<any> = new EventEmitter()
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;

  count: number = 0;
  currentPage: number = 1;
  pageSize: number = 9;
  lastPageSize: number = 1;

  public count1: number = 0;

  public dateFrom: any = "";
  public dateTo: any = "";
  public searchText: string = "";
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;

  public dateCreatedFrom : any = '';
  public dateCreatedTo : any = '';
  public dateIssuedFrom: any = '';
  public dateIssuedTo: any = '';
  options = ['']

  public masterSelected: boolean = false;
  public inventoryData: any[] = [];

  public stockType: string = '';
  public categoryType: string = '';
  public categories: DictionaryItem[] = []
  public categoryIds: any = []

  public checkedList: any = []
  public itemIds: number[] = []

  constructor(private toastr: ToastrService,
    private inventoryManagementService: InventoryManagementService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    this.getItemCategories();
    this.reloadTable();
    this.setInitalDates();
  }

  getItemCategories(){
    this.commonService.getAllItemCategories().subscribe(response => {
      this.categories = response.data
      this.allFilters.push(this.categories)
      // this.filterApplied = true
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchText: this.searchText,
        stockType: this.stockType,
        categoryType: this.categoryType,
      }
    };

    this.checkedList = [];
    this.masterSelected = false;
    this.count1 = 0;

    this.inventoryManagementService.getStockItems(obj).subscribe(response => {
      this.inventoryData = response.data.data;
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  setInitalDates() {
    let date = new Date()
    date.setHours(2,0,0)
    if (date.getMonth() == 0)
    {
      this.dateFrom = {year: date.getFullYear() - 1, month: 12, day: date.getDate()}
    }
    else {
      this.dateFrom = {year: date.getFullYear(), month: date.getMonth(), day: date.getDate()}
      if(date.getDate() == 31){
        this.dateFrom.day = -1
      }
      // february
      if (date.getMonth() == 2) {
        this.dateFrom.day -= 2
      }
    }
    this.dateTo = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}
  }

  applyFilter(data: any) {
    for (const item of data)
    {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)
      if (item.name === 'Category' && filteredValues.length > 0) {
        this.categoryIds = filteredValues
      } else if (item.name === 'Region' && filteredValues.length == 0) {
        this.categoryIds = []
      }
    }
    this.reloadTable(1)
  }

  isAllSelected() {
    this.masterSelected = this.inventoryData.every(function (item: any) {
      return item.checked == true;
    })
    this.changed();
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.inventoryData.length; i++) {
      if (this.inventoryData[i].checked)
        this.checkedList.push(this.inventoryData[i]);
    }
  }

  checkUncheckAll() {
    for (let i = 0; i < this.inventoryData.length; i++) {
      this.inventoryData[i].checked = this.masterSelected;
    }
    this.changed();
    this.getCheckedItemList();
  }

  changed() {
    this.count1 = 0;
    this.inventoryData.forEach((item: { [x: string]: any; }) => {
    if (item['checked']) {
      this.count1 = this.count1 + 1;
    }
  })
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  deleteItems() {
    this.itemIds = []
    if(this.checkedList.length != 0)
    {
     this.itemIds = this.checkedList.map((item: any) => item.id)
    }
    if(this.itemIds.length > 0) {
      let obj = {
        ItemIds: this.itemIds
      };
      this.inventoryManagementService.deleteStockItems(obj).subscribe(response => {
        if (response.status === 200) {
          this.toastr.success(response.message)
          this.reloadTable();
         } else {
          this.toastr.error(response.message);
         }
      })
    }
    else
      this.toastr.warning("Please select items you want to delete.");
  }

  dateCreatedChange(date: any){
    this.dateCreatedFrom = date.dateFrom;
    this.dateCreatedTo = date.dateTo;
    this.reloadTable();
  }
  dateIssuedChange(date: any){
    this.dateIssuedFrom = date.dateFrom;
    this.dateIssuedTo = date.dateTo;
    this.reloadTable();
  }

  searchChange(search:string) {
    this.searchText = search;
    this.reloadTable();
  }

  openPopup(){
    this.changeView.emit(ViewsEnum.Create)
  }

  issueItem(item: any){
    this.setItemId.emit(item.id)
    this.changeView.emit(ViewsEnum.IssueItem);
  }

  showDetails(item: any) {
    this.setItemId.emit(item.id)
    this.changeView.emit(ViewsEnum.ShowDetails)
  }

  alert(){
    this.toastr.warning("Not yet implemented.");
  }
}
