
<div>
    <app-meter-bypass-popup (parentComp)="reloadTable($event)"></app-meter-bypass-popup>
</div>
<main class="container-fluid" [ngClass]="{'table-hidden': showDetails}">
    <div class="container-wrapper">
        <div class="commercial-title">
            <span class="commercial-module-title">Customer Watchlist</span>
            <img alt=">" src="assets\images-new-design\arrow_forward_ios.svg">
            <span class="commercial-page-title">Prepaid Meter Bypass</span>
        </div>
        <div class="revenue-customers-download" >
            <div class="total-count-wrapper">
                <field-count-box-new class="card-item" label="Total Customers Flagged in the Network"
                    [data]="totalCount" [borderRadius]="'8px'" [width]="'100% !important'" [isBlack]="true" [lastMonthBg]="false" [lastMonth]="'' + previousTotalCount + ' last month'"></field-count-box-new>

                <div class="scroll-card-wrapper" [ngClass]="{'scroll-cards': areasCount > 3}">
                    <field-count-box-new class="scroll-card-item" *ngFor="let item of areas; index as i" [borderRadius]="'8px'"
                    [isYellow]="i === 0 ? true : false" [isPurple]="i === 1 ? true : false" [isGreen]="i === 2 ? true : false" [isBlue]="i === 3 ? true : false" [isRed]="i === 4 ? true : false"
                    [label] = "item.undertakingName | uppercase" [lastMonthBg]="false"  [data] = "item.numberPrepaidCustomers"
                    [lastMonth]="'' + item.previousMonthNumberPrepaidCustomers + ' last month'"></field-count-box-new>
                </div>

            </div>
        </div>
        <div class="filter-wrapper">
            <app-tab-default
                class="wide-tab"
                [firstText]="'Organization'"
                [secondText]="'Network'"
                (tabChanged)="mainTabChange($event)"
            ></app-tab-default>
        </div>
        <div class="filters-container">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                <app-multi-filter  *ngIf="filterApplied"
                        [data]="allFilters"
                        (dataChange)="applyFilter($event)"
                        [(showFilter)]="showFilter"
                        (dropdownChange)="dropdownSelected($event)">
                </app-multi-filter>
                <app-search-autocomplete
                [placeholder]="'Search by Meter Number, Account Number & SPN'"
                [width]="33"
                [backgroundColor]="'transparent'"
                [borderColor]="'#E8E8E8'"
                [(searchText)]="searchText"
                [options]="options"
                (searchChange)="inputChanged($event)"
                (searchAutocompleteChange)="getOptions($event)"></app-search-autocomplete>
            </div>
            <app-download-button-new
            [isOnlyDownload]="false"
            (downloadButtonClickedChange)="downloadReport()"
            (downloadAndSendButtonClickedChange)="downloadAndSendReport($event)"
            ></app-download-button-new>
        </div>
        <div class="scroll-table-wrapper">
            <div class="table-wrapper">
                <table class="standard-table scroll-table">
                    <thead>
                        <tr>
                            <th class="checkbox-td"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" (change)="checkUncheckAll()"></th>
                            <th class="medium-td"><span title="ACCOUNT NUMBER">ACCOUNT NUMBER</span></th>
                            <th class="large-td"><span title="ACCOUNT NAME">ACCOUNT NAME</span></th>
                            <th class="medium-td"><span>METER NUMBER</span></th>
                            <th class="medium-td"><span>CUSTOMER TYPE</span></th>
                            <th class="large-td"><span>DT NAME</span></th>
                            <th class="medium-td"><span>VENDING DEVIATION (%)</span></th>
                            <th class="medium-td"><span>AVAILABILITY DEVIATION (%)</span></th>
                            <th class="alarm-td">
                              <div class="dropdown">
                                <div tabindex="1" class="dropdown-btn" (click)="alarmConditionClicked(1)" (blur)="alarmConditionClicked(0)">
                                  <span>ALARM CONDITION</span>
                                  <svg *ngIf="alarmConditionOpened" width="24" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z"
                                      fill="#8F92A1" fill-opacity="0.4" />
                                  </svg>
                                  <svg *ngIf="!alarmConditionOpened" width="24" height="16" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z"
                                      fill="#8F92A1" fill-opacity="0.4" />
                                  </svg>
                                </div>
                                <div class="dropdown-expanded" *ngIf="alarmConditionOpened">
                                  <span (mousedown)="alarmConditionSelected(null)">Deselect</span>
                                  <span (mousedown)="alarmConditionSelected(1)">Undefined</span>
                                  <span (mousedown)="alarmConditionSelected(2)">Constant Vend</span>
                                  <span (mousedown)="alarmConditionSelected(3)">Decreased Vend</span>
                                  <span (mousedown)="alarmConditionSelected(4)">No Vending</span>

                                </div>
                              </div>
                            </th>
                            <th><span>PERIOD</span></th>
                            <th class="small-td">
                              <div class="dropdown">
                                <div tabindex="1" class="dropdown-btn" (click)="bypassStatusClicked(1)" (blur)="bypassStatusClicked(0)">
                                  <span>STATUS</span>
                                  <svg *ngIf="bypassStatusOpened" width="24" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z"
                                      fill="#8F92A1" fill-opacity="0.4" />
                                  </svg>
                                  <svg *ngIf="!bypassStatusOpened" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z"
                                      fill="#8F92A1" fill-opacity="0.4" />
                                  </svg>
                                </div>
                                <div class="dropdown-expanded" *ngIf="bypassStatusOpened">
                                  <span (mousedown)="bypassStatusSelected(null)">Deselect</span>
                                  <span (mousedown)="bypassStatusSelected(1)">Confirmed</span>
                                  <span (mousedown)="bypassStatusSelected(2)">No Bypass</span>
                                  <span (mousedown)="bypassStatusSelected(3)">Pending</span>
                                </div>
                              </div>
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr *ngFor="let item of meterBypasses; let i = index" class="clickable actions-click relative" (click)="selectPrepaidCustomer(item.accountId, item.month)">
                            <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" ></td>
                            <td title="{{item.accountNo}}" class="medium-td acc-no-td">
                                <span>
                                    {{item.accountNo | hasValue }}
                                </span>
                                <svg (click)="copyAccountNumber(item.accountNo)" click-stop-propagation width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="content_copy">
                                    <mask id="mask0_7446_16312" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <rect id="Bounding box" width="24" height="24" fill="#D9D9D9"/>
                                    </mask>
                                    <g mask="url(#mask0_7446_16312)">
                                    <path id="content_copy_2" d="M9.05771 17.5C8.55258 17.5 8.12502 17.325 7.77504 16.975C7.42504 16.625 7.25004 16.1974 7.25004 15.6923V4.3077C7.25004 3.80257 7.42504 3.375 7.77504 3.025C8.12502 2.675 8.55258 2.5 9.05771 2.5H17.4423C17.9474 2.5 18.375 2.675 18.725 3.025C19.075 3.375 19.25 3.80257 19.25 4.3077V15.6923C19.25 16.1974 19.075 16.625 18.725 16.975C18.375 17.325 17.9474 17.5 17.4423 17.5H9.05771ZM9.05771 16H17.4423C17.5192 16 17.5897 15.9679 17.6538 15.9038C17.7179 15.8397 17.75 15.7692 17.75 15.6923V4.3077C17.75 4.23077 17.7179 4.16024 17.6538 4.09613C17.5897 4.03203 17.5192 3.99998 17.4423 3.99998H9.05771C8.98078 3.99998 8.91026 4.03203 8.84616 4.09613C8.78204 4.16024 8.74999 4.23077 8.74999 4.3077V15.6923C8.74999 15.7692 8.78204 15.8397 8.84616 15.9038C8.91026 15.9679 8.98078 16 9.05771 16ZM5.55774 20.9999C5.05262 20.9999 4.62506 20.8249 4.27506 20.4749C3.92506 20.1249 3.75006 19.6973 3.75006 19.1922V6.3077H5.25004V19.1922C5.25004 19.2692 5.28209 19.3397 5.34619 19.4038C5.4103 19.4679 5.48082 19.5 5.55774 19.5H15.4423V20.9999H5.55774Z" fill="#1C1B1F"/>
                                    </g>
                                    </g>
                                </svg>
                            </td>
                            <td class="medium-td" title="{{item.accountName}}"><span>{{item.accountName | hasValue}}</span></td>
                            <td class="medium-td" title="{{item.msn}}"><span>{{item.msn | hasValue}}</span></td>
                            <td class="medium-td" *ngIf="item.accountType == 0" title="Undefined">
                                <span>Undefined</span>
                            </td>
                            <td class="medium-td" *ngIf="item.accountType == 2" title="MD">
                                <span>MD</span>
                            </td>
                            <td class="medium-td" *ngIf="item.accountType == 3"  title="NMD">
                                <span>NMD</span>
                            </td>

                            <td class="medium-td" title="{{item.dtName}}"><span>{{item.dtName | hasValue}}</span></td>
                            <td class="medium-td" title="{{item.vendsDeviation}}"><span>{{item.vendsDeviation | twoDecimals}}</span></td>
                            <td class="medium-td" title="{{item.dtAvailabilityDeviation}}"><span>{{item.dtAvailabilityDeviation | twoDecimals}}</span></td>
                            <td class="medium-td" *ngIf="item.status == 1" class="blue-table-pill" title="Undefined">
                                <span>Undefined</span>
                            </td>
                            <td class="medium-td" *ngIf="item.status == 2" class="red-table-pill" title="Constant Vend">
                                <span>Constant Vend</span>
                            </td>
                            <td class="medium-td" *ngIf="item.status == 3" class="yellow-table-pill" title="Decreased Vend">
                                <span>Decreased Vend</span>
                            </td>
                            <td class="medium-td" *ngIf="item.status == 4" class="green-table-pill" title="No Vending">
                                <span>No Vending</span>
                            </td>
                            <td title="{{item.month}}"><span>{{item.month | date}}</span></td>
                            <td *ngIf="item.bypassStatus == 1" class="red-table-pill" title="Confirmed">
                                <span>Confirmed</span>
                            </td>
                            <td *ngIf="item.bypassStatus == 2" class="green-table-pill" title="No Bypass">
                                <span>No Bypass</span>
                            </td>
                            <td *ngIf="item.bypassStatus == 3" class="yellow-table-pill" title="Pending">
                                <span>Pending</span>
                            </td>
                            <td *ngIf="item.bypassStatus == 0" class="blue-table-pill" title="Undefined">
                                <span>Unknown</span>
                            </td>
                            <td tabindex="1" class="small-td" (click)="bypassPopup = i" click-stop-propagation (blur)="closeBypassPopup()">
                                <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.99968 3.83333C2.32931 3.83333 2.65154 3.73559 2.92563 3.55245C3.19971 3.36931 3.41333 3.10902 3.53948 2.80447C3.66562 2.49993 3.69863 2.16482 3.63432 1.84152C3.57001 1.51822 3.41127 1.22124 3.17819 0.988156C2.9451 0.755068 2.64813 0.596334 2.32483 0.532025C2.00152 0.467717 1.66641 0.500722 1.36187 0.626868C1.05733 0.753014 0.797029 0.966635 0.613893 1.24072C0.430757 1.5148 0.333009 1.83703 0.333009 2.16667C0.333009 2.60869 0.508604 3.03262 0.821164 3.34518C1.13373 3.65774 1.55765 3.83333 1.99968 3.83333ZM1.99968 12.1667C1.67004 12.1667 1.34781 12.2644 1.07373 12.4476C0.799643 12.6307 0.586022 12.891 0.459876 13.1955C0.33373 13.5001 0.300724 13.8352 0.365033 14.1585C0.429342 14.4818 0.588077 14.7788 0.821164 15.0118C1.05425 15.2449 1.35122 15.4037 1.67453 15.468C1.99783 15.5323 2.33294 15.4993 2.63748 15.3731C2.94202 15.247 3.20232 15.0334 3.38546 14.7593C3.56859 14.4852 3.66634 14.163 3.66634 13.8333C3.66634 13.3913 3.49075 12.9674 3.17819 12.6548C2.86563 12.3423 2.4417 12.1667 1.99968 12.1667ZM1.99968 6.33333C1.67004 6.33333 1.34781 6.43108 1.07373 6.61422C0.799643 6.79735 0.586022 7.05765 0.459876 7.3622C0.33373 7.66674 0.300724 8.00185 0.365033 8.32515C0.429342 8.64845 0.588077 8.94542 0.821164 9.17851C1.05425 9.4116 1.35122 9.57033 1.67453 9.63464C1.99783 9.69895 2.33294 9.66595 2.63748 9.5398C2.94202 9.41365 3.20232 9.20003 3.38546 8.92595C3.56859 8.65187 3.66634 8.32964 3.66634 8C3.66634 7.55797 3.49075 7.13405 3.17819 6.82149C2.86563 6.50893 2.4417 6.33333 1.99968 6.33333Z" fill="#474747"/>
                                </svg>
                                <div *ngIf="bypassPopup == i" class="bypass-popup" click-stop-propagation>
                                    <div class="bypass-row">
                                        <span>Bypass</span>
                                    </div>
                                    <div class="bypass-row">
                                        <span>Confirmed</span>
                                        <input type="checkbox"  name="bypass" [checked]="item.bypassStatus == 1" (mousedown)="updateBypass(item, 1)">
                                    </div>
                                    <div class="bypass-row">
                                        <span>No Bypass</span>
                                        <input type="checkbox" name="bypass" [checked]="item.bypassStatus == 2" (mousedown)="updateBypass(item, 2)">
                                    </div>
                                    <div class="bypass-row">
                                        <span>Pending</span>
                                        <input type="checkbox" name="bypass" [checked]="item.bypassStatus == 3" (mousedown)="updateBypass(item, 3)">
                                    </div>
                                    <div class="bypass-btn" (click)="bypassPopup = -1" click-stop-propagation>
                                        <span>Undo</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="see-more-btn"> See More </div>
                                <div *ngIf="bypassPopup == i" class="bypass-popup" click-stop-propagation>
                                    <div class="bypass-row">
                                        <span>Bypass</span>
                                    </div>
                                    <div class="bypass-row">
                                        <span>Confirmed</span>
                                        <input type="checkbox"  name="bypass" [checked]="item.bypassStatus == 1" (mousedown)="updateBypass(item, 1)">
                                    </div>
                                    <div class="bypass-row">
                                        <span>No Bypass</span>
                                        <input type="checkbox" name="bypass" [checked]="item.bypassStatus == 2" (mousedown)="updateBypass(item, 2)">
                                    </div>
                                    <div class="bypass-row">
                                        <span>Pending</span>
                                        <input type="checkbox" name="bypass" [checked]="item.bypassStatus == 3" (mousedown)="updateBypass(item, 3)">
                                    </div>
                                    <div class="bypass-btn" (click)="bypassPopup = -1" click-stop-propagation>
                                        <span>Undo</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-sm-12 pagination-wrapper">
            <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
        </div>
    </div>
</main>

<app-meter-bypass-details
    *ngIf="showDetails"
    [data]="detailsData"
    (backClicked)="showDetails = false;"
    ></app-meter-bypass-details>
