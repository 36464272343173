import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, EMPTY, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from "../services/auth.service";
import { LogoutHubService } from "../services/logout-hub.service";
import { NotificationHubService } from "../services/notification-hub.service";

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  public static methodsToProcess = ['GET', 'POST', 'PUT', 'DELETE'];
  public static showToastMomentaneously = true;
  public static successfulResponses = [201, 200];
  public static errorResponses = [400, 401, 403, 404, 405];
  public static serverErrorResponses = [500, 504];

  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    private notificationHub: NotificationHubService,
    private logoutHub: LogoutHubService
    ) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    req = this.addHeaders(req);

    return next.handle(req).pipe<any>(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if(!req.url.includes("auth/refresh") 
              && err.status === 401){
            return this.handle401Error(req, next);
          }
          else if(err.status === 0 || err.statusText.includes('HTTP Error 500.30 - ASP.NET Core app failed to start')) {
            this.toastr.error('Server is down because of deploy.');
            return EMPTY;
          }
        }
        return throwError(err);
      }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = localStorage.getItem('refreshToken');

      if (token)
        return this.authService.refreshToken(token).pipe(
          switchMap((data: any) => {
            localStorage.setItem('token', data.token);
            localStorage.setItem('permissions', data.permissions); 
            localStorage.setItem('refreshToken', data.refreshToken);

            this.notificationHub.close();
            this.isRefreshing = false;
            this.refreshTokenSubject.next(data.token);
            this.notificationHub.init();
            
            return next.handle(this.addHeaders(request));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            
            this.toastr.error('Session timed out. Please log in.');
            this.logOut();
            return EMPTY;
          }),
        );
      else {
        this.isRefreshing = false;
        this.authService.logoutNoApi();
        return EMPTY;
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addHeaders(request)))
    );
  }

  private addHeaders(request: HttpRequest<any>) {
    const idToken = localStorage.getItem('token');

    if (idToken) {
      request = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${idToken}`) });
    }

    return request;
  }

  logOut(){
    this.notificationHub.close();
    this.logoutHub.close();
    this.authService.logout()
   }
}
