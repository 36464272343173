import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-adora-pagination',
  templateUrl: './adora-pagination.component.html',
  styleUrls: ['./adora-pagination.component.scss']
})
export class AdoraPaginationComponent {
  @Input() currentPage: number = 1;
  @Input() pageSize: number = 0;
  @Input() count: number = 0;
  public visiblePages: any;
  @Output() pageChange = new EventEmitter();

  public isLastPage : boolean = false;

  changePage(pagenum: number) {
    let lastPageNum = Math.ceil(this.count / this.pageSize);
    if (lastPageNum === 0)
      lastPageNum = 1;
    if (pagenum == -1)
      pagenum = lastPageNum;
    if (pagenum == 0)
      pagenum = 1;
    if (pagenum > lastPageNum)
      pagenum = lastPageNum;
    this.currentPage = pagenum;
    this.getVisiblePages();
    this.pageChange.emit(this.currentPage);
  }

  getVisiblePages() {
    let lastPageNum = Math.ceil(this.count / this.pageSize);

    this.visiblePages = [];

    if (this.currentPage == 1) {
      this.visiblePages.push(1);
      if (lastPageNum > 1)
        this.visiblePages.push(2);
      if (lastPageNum > 2)
        this.visiblePages.push(3);
    }
    else if (this.currentPage >= lastPageNum) {
      if (lastPageNum > 2)
        this.visiblePages.push(lastPageNum - 2);
      this.visiblePages.push(lastPageNum - 1);
      this.visiblePages.push(lastPageNum);
    }
    else {
      this.visiblePages.push(this.currentPage - 1);
      this.visiblePages.push(this.currentPage);
      this.visiblePages.push(this.currentPage + 1);
    }

    if(lastPageNum <= this.currentPage){
      this.isLastPage = true;
    }else{
      this.isLastPage = false;
    }

    return this.visiblePages;
  }

}
