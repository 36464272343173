import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { Feeder11 } from '../models/feeder11.model';
@Injectable({
  providedIn: 'root'
})
export class Feeder11Service {

  private pathApi = this.config.setting['pathApi'] + "feeder11/";
  private pathApiMediumFeeders = this.config.setting['pathApi'] + "AngularChartData/GetMediumFeeders";

  constructor(private http: HttpClient,
    private config: AppConfig,) {  }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    }

    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    }

    save(obj: Feeder11): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }

    getAll(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, obj);
    }

    getAllForSelect(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSelect`);
    }

    getAllFeedersDictionaryItem(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllFeedersDictionaryItem`);
    }

    getAllFeedersAllFeedersList(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllFeedersAllFeedersList`);
    }

    getAllMonthly11KvFeeders(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}GetAllMonthly11KvFeeders`,obj);
    }

    uploadFile(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}uploadFile`, obj);
    }

    getAllDaily11KvFeeders(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}GetAllDaily11KvFeeders`,obj);
    }

    getFeeder11ReceivedData(obj: any): Observable<any[]> {
      return this.http.post<any[]>(`${this.pathApi}Received`, obj)
    }

    getFeedersWorstEnergyLosses(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}GetWorst10EnergyLosses`, obj)
    }

    downloadWorstRecordedAssets(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadWorstAssets`, obj);
    }

    downloadAssetEnergy(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadAssetEnergy`, obj);
    }

    GetAllConnectedToF33(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}GetAllConnectedToF33/${id}`);
    }
    getAllConnectedToSubstation(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}getAllConnectedToSubstation/${id}`);
    }

    downloadMonthly11KvFeedersReport(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}DownloadMonthly11KvFeedersReport`,obj);
    }

    downloadDaily11KvFeedersReport(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}DownloadDaily11KvFeedersReport`,obj);
    }

    getTemplate(): Observable<any>{
      return this.http.get(`${this.pathApi}getTemplate`);
    }

    getFeedersOutages(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}GetOutages`, obj)
    }

    getCurrentActiveF11(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getCurrentActiveF11`, obj)
    }
    getNonCommunicatingF11(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getNonCommunicatingF11`, obj)
    }
    downloadOutages(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadOutagesReport`,obj)
    }
    DownloadCurrentActiveReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadCurrentActiveReport`,obj)
    }

    downloadNonCommunicatingReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadNonCommunicatingReport`,obj)
    }
    getFeederViewMore(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}GetFeeder11ViewMore`,obj);
    }

    getEnergyDistributionAndLosses(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getEnergyDistributionAndLosses`, obj);
    }

    getOutageDurationAndFrequency(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getOutageDurationAndFrequency`, obj);
    }

    getMonthlyAvailability(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getAvailability`, obj);
    }

    getAllForSelectByParams(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getAllForSelectByParams`, obj);
    }

    getDataForAutocomplete(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
    }
    getDataForAutocompleteNameExclusive(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForAutocompleteNameExclusive`, obj)
    }
    getDataForTopologyPopup(name: string, feeder:string): Observable<any> {
      return this.http.get(`${this.pathApi}getDataForTopologyPopup/${name}/${feeder}`);
    }
    downloadCrud(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}downloadCrud`, obj);
    }
    getF11Reads(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}GetF11Reads`, obj);
    }
    getSystemHierarchy(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getSystemHierarchy`, obj)
    }
    getDailyBandsCount(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getDailyBandsCount`, obj);
    }
    getMonthlyBandsCount(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getMonthlyBandsCount`, obj);
    }
    getCommunicatingAssetsCounts(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getCommunicatingAssetsCounts`, obj);
    }
}
