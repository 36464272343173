<div class="gep-table-wrapper">
  <div class="table-wrapper">
    <table class="standard-table gep-table">
      <thead>
        <th><span title="Meter number">METER NUMBER</span></th>
        <th><span title="Feeder name">FEEDER NAME</span></th>
        <th><span title="Transmission Station">TRANSMISSION STATION</span></th>
        <th><span title="Meter voltage">METER VOLTAGE</span></th>
        <th><span title="Meter make">METER MAKE</span></th>
        <th><span title="Meter model">METER MODEL</span></th>
        <th><span title="Energy">ENERGY</span></th>
        <th><span title="Meter max digit">METER MAX. DIGIT</span></th>
        <th><span title="Multiplier factor">MULTIPLIER FACTOR</span></th>
        <th>
          <div class="dropdown">
            <div tabindex="3" class="dropdown-btn" (click)="commStatusClicked(1)" (blur)="commStatusClicked(0)">
                <span title="Comm Status(last 24hrs)">COMMUNICATION STATUS</span>
                <svg *ngIf="commStatusOpened" width="24" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z"
                    fill="#8F92A1" fill-opacity="0.4" />
                </svg>
                <svg *ngIf="!commStatusOpened" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z"
                    fill="#8F92A1" fill-opacity="0.4" />
                </svg>
            </div>
              <div class="dropdown-expanded" *ngIf="commStatusOpened">
                <span (mousedown)="commStatusSelected(0)">Deselect</span>
                <span (mousedown)="commStatusSelected(1)">Yes</span>
                <span (mousedown)="commStatusSelected(2)">No</span>
              </div>
          </div>
        </th>
        <th><span title="Multiplier factor">VOLTAGE</span></th>
        <th><span title="Multiplier factor">CURRENT</span></th>
        <th><span title="Multiplier factor">POWER FACTOR</span></th>
        <th><span title="Multiplier factor">TARIFF BAND</span></th>
        <th><span title="Multiplier factor">FREQUENCY</span></th>
        <th><span title="Multiplier factor">APPARENT CURRENT</span></th>
      </thead>
      <tbody>
        <tr *ngFor="let item of data">
          <td>
            <span class="meter-number-copy" *ngIf="item.meterNumber !== '/'" title="{{item.meterNumber}}">{{item.meterNumber | hasValue}}
              <img alt="copy"
                class="icons" src="assets/images-new-design/copy.png"
                (click)="copyToClipboard(item.meterNumber)">
            </span>
          </td>
          <td>
            <span *ngIf="item.feederName !== '/'" title="{{item.feederName}}">{{item.feederName | hasValue}}</span>
            <span *ngIf="item.feederName === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.tsName !== '/'" title="{{item.tsName}}">{{item.tsName | hasValue}}</span>
            <span *ngIf="item.tsName === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.meterVol !== '/'" title="{{item.meterVol}}">{{item.meterVol | hasValue}}</span>
            <span *ngIf="item.meterVol === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.meterMake !== '/'" title="{{item.meterMake}}">{{item.meterMake | hasValue}}</span>
            <span *ngIf="item.meterMake === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.meterModel !== null" title="{{item.meterModel}}">{{item.meterModel | hasValue}}</span>
            <span *ngIf="item.meterModel === null" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.energy !== 0" title="{{item.energy}}">{{item.energy | hasValue}}</span>
            <span *ngIf="item.energy === 0" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.meterMaxDigit !== 0" title="{{item.meterMaxDigit}}">{{item.meterMaxDigit |
              hasValue}}</span>
            <span *ngIf="item.meterMaxDigit === 0" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.multiplierFactor !== 0" title="{{item.multiplierFactor}}" style="display: flex; justify-content: center;">{{item.multiplierFactor |
              hasValue}}</span>
            <span *ngIf="item.multiplierFactor === 0" class="NaN">N/A</span>
          </td>
          <td>
            <div *ngIf="item.lastReadSync !== null" class="assetForm" style="display: flex; justify-content: center;">{{item.lastReadSync}}</div>
            <div *ngIf="item.lastReadSync === null" class="NaN">N/A</div>
          </td>
          <td>
            <div *ngIf="item.voltage !== null" class="assetForm">{{item.voltage}}</div>
            <div *ngIf="item.voltage === null" class="NaN">N/A</div>
          </td>
          <td>
            <div *ngIf="item.current !== null" class="assetForm">{{item.current}}</div>
            <div *ngIf="item.current === null" class="NaN">N/A</div>
          </td>
          <td>
            <div *ngIf="item.powerFactor !== null" class="assetForm">{{item.powerFactor}}</div>
            <div *ngIf="item.powerFactor === null" class="NaN">N/A</div>
          </td>
          <td>
            <div *ngIf="item.tariffBand !== null" class="assetForm">{{item.tariffBand}}</div>
            <div *ngIf="item.tariffBand === null" class="NaN">N/A</div>
          </td>
          <td>
            <div *ngIf="item.frequency !== null" class="assetForm">{{item.frequency | number}}</div>
            <div *ngIf="item.frequency === null" class="NaN">N/A</div>
          </td>
          <td>
            <div *ngIf="item.apparentCurrent !== null" class="assetForm">{{item.apparentCurrent | twoDecimals}}</div>
            <div *ngIf="item.apparentCurrent === null" class="NaN">N/A</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="pagination-wrapper">
  <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
    (pageChange)="pageChange($event)">
  </app-fo-pagination-new>
</div>
