<main class="outage-wrapper">
    <table class="standard-table gep-table">
        <thead>
            <tr>
                <th><span><input class="input-checkmark" type="checkbox" name="list_name" value="h1" [(ngModel)]="masterSelected" (change)="checkUncheckAll()" /></span></th>
                <th title="DT Name"><span>DT NAME</span></th>
                <th title="DT Number"><span>DT NUMBER</span></th>
                <th title="Meter Number"><span>METER NUMBER</span></th>
                <th title="Connected Feeder"><span>CONNECTED FEEDER</span></th>
                <th title="Feeder Type"><span>FEEDER TYPE</span></th>
                <th title="{{buName}}"><span>{{buName}}</span></th>
                <th title="{{utName}}"><span>{{utName}}</span></th>
                <th title="Customers Downstream" class="large-td"><span>CUSTOMERS DOWNSTREAM</span></th>              
            </tr>
        </thead>
        
        <tbody>
            <tr *ngFor="let item of data">
                <td (click)="false; $event.stopPropagation();"><span> <input class="input-checkmark" type="checkbox" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" /></span> </td>

                <td title="{{item.name}}">
                    <span *ngIf="item.name && item.name != '/'">{{item.name}}</span>
                    <span *ngIf="!item.name || item.name == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.number}}">
                    <span *ngIf="item.number && item.number != '/'">{{item.number}}</span>
                    <span *ngIf="!item.number || item.number == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.msn}}">
                    <span *ngIf="item.msn && item.msn != '/'">{{item.msn}}</span>
                    <span *ngIf="!item.msn || item.msn == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.feederName}}">
                    <span *ngIf="item.feederName && item.feederName != '/'">{{item.feederName}}</span>
                    <span *ngIf="!item.feederName || item.feederName == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.feederType}}">
                   <span>{{item.feederType}}</span>
                </td>
                <td title="{{item.parentName}}">
                    <span *ngIf="item.parentName && item.parentName != '/'">{{item.parentName}}</span>
                    <span *ngIf="!item.parentName || item.parentName == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.utName}}">
                    <span *ngIf="item.utName && item.utName != '/'">{{item.utName}}</span>
                    <span *ngIf="!item.utName || item.utName == '/'" class="NaN">N/A</span>
                </td>
                <td class="center" title="{{item.noOfCustomersImpacted}}">
                    <span *ngIf="item.noOfCustomersImpacted && item.noOfCustomersImpacted != '/'">{{item.noOfCustomersImpacted | number}}</span>
                    <span *ngIf="!item.noOfCustomersImpacted || item.noOfCustomersImpacted == '/'" class="NaN">N/A</span>
                </td>
                 
            </tr>
        </tbody>
    </table>
</main>
<div class="pagination-wrapper">
    <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
        [currentPage]="currentPage" (pageChange)="pageChange($event)">
    </app-fo-pagination-new>
</div>

