import { Component, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { DeviceManagementRemoteDisconnectedComponent } from './device-management-remote-disconnected/device-management-remote-disconnected.component';

@Component({
  selector: 'app-device-management',
  templateUrl: './device-management.component.html',
  styleUrl: './device-management.component.scss'
})
export class DeviceManagementComponent {
  public tabMenu: number = 1;
  public remoteDisconnectedType: string = 'Remote Disconnected';
  public alarmEventMgtType: string = 'Alarm & Event Mgt';
  public deviceParametersType: string = 'Device Parameters';
  public RemoteTokenLoadType: string = 'Remote Token Load';
  public loadProfileType: string = 'Load Profile';
  public remoteDisconnectionAction: string = 'Remote Disconnection Action';
  public deviceManagementType: string = this.remoteDisconnectedType;

  remoteDisconnectedCount: number = 0;
  alarmEventMgtCount: number = 0;

  public eventName: string = "Event Category"
  public allEvents: any = { name: this.eventName, data: [{ value: 1, name: 'Meter Cover tamper' },{ value: 2, name: 'Open Terminal' }, { value: 3, name: 'Magnetic tampers' }
  , { value: 4, name: 'Low credit' },], dropdown: false }
  public eventIds: any = [];
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');

  //multiFilter comp
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  public businessUnitsNew: any = { name: this.buName, data: [] };
  public undertakingsNew: any = { name: this.utName, data: [] };
  public buIds: number[] = [];
  public utIds: any = [];

  //search component
  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public searchPlaceholder: string = "Search by Meter Number, Account Number & SPN"
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []
  public searchLength: number = 1


  //pagination
  public count: number = 0
  public pageSize: number = 10
  public currentPage: number = 1
  public lastPageSize: number = 1;

  public dateFrom: any = ''
  public dateTo: any = ''

  //details
  public showDetails: boolean = false;
  public detailsData: any;


  @ViewChild(DeviceManagementRemoteDisconnectedComponent) remoteDisconnectedRef!: DeviceManagementRemoteDisconnectedComponent;


  constructor(private toastr: ToastrService,
    private meterService: MeterService,
    private translationService: TranslationService,
    private buService: BusinessUnitService,
    private utService: UndertakingService,

  ) { }

  ngOnInit(): void {
    this.setMainDate()
    this.getAllBUs()
    this.getAllUTs()
  }

  setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()

    dte.setHours(2, 0, 0);

    if (dteFrom.getMonth() == 0) {
      this.dateFrom = { year: dteFrom.getFullYear() - 1, month: 12, day: 1 };
    }
    else {
      this.dateFrom = { year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: 1 };
    }

    this.dateTo = { year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate() };
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
  }

  menuChanged(value: number) {
    this.tabMenu = value;
    if (value == 1) {
      this.deviceManagementType = this.remoteDisconnectedType
      this.tabChanged(1)
    }
    else if (value == 2) {
      this.deviceManagementType = this.alarmEventMgtType
      this.tabChanged(2)
    }
    else if (value == 3) {
      this.deviceManagementType = this.deviceParametersType
      this.tabChanged(3)
    }
    else if (value == 4) {
      this.deviceManagementType = this.RemoteTokenLoadType
      this.tabChanged(4)
    }
    else if (value == 5) {
      this.deviceManagementType = this.loadProfileType
      this.tabChanged(5)
    }
    else if(value == 6) {
      this.deviceManagementType = this.remoteDisconnectionAction
      this.tabChanged(6)
    }
  }

  tabChanged(menu: number) { 
    this.tabMenu = menu;
    this.resetFilters();
  }

  resetFilters(){
    this.searchText = new SearchAutocomplete()
    if (this.searchChild) {
      this.searchChild.searchText = new SearchAutocomplete()
      this.searchChild.resetOptions()
    }
    this.eventIds = []
    this.allFilters = []
    this.showFilter = false
    this.filterApplied = false
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.checkFilters()
  }

  searchChange(text: SearchAutocomplete) {
    this.searchText = text
    this.searchText = cloneDeep(this.searchText)
  }

  getOptions(searchText: any) {//proveiti
    let obj = {
      searchText: searchText.name,
      msnIncluded: true,
      MeterItemType: this.deviceManagementType,
    }
    this.searchChild.searchLength = this.searchLength;
    if (searchText.name.length > 1) {
      this.meterService.getDataForAutocomplete(obj)
        .subscribe(response => {
          this.options = response.data
        })
    } else {
      this.searchChild.resetOptions()
    }
  }

  getAllBUs() {
    this.buService.getAllForSelect().subscribe(response => {
      this.businessUnitsNew = { name: this.buName, data: response.data }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllUTs() {
    this.utService.getAllForSelect().subscribe(response => {
      this.undertakingsNew = { name: this.utName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }


  checkFilters() {
    this.filterApplied = true;
    if (this.tabMenu == 1) {

      let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnitsNew.name);
      let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnitsNew.name);
      if (buIndex !== -1) {
        this.allFilters[buIndex].data = this.businessUnitsNew.data;
      }

      let utData = this.allFilters.find((item: { name: any; }) => item.name === this.undertakingsNew.name);
      let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakingsNew.name);
      if (utIndex !== -1) {
        this.allFilters[utIndex].data = this.undertakingsNew.data;
      }

      if (!buData)
        this.allFilters.push(this.businessUnitsNew)
      if (!utData)
        this.allFilters.push(this.undertakingsNew)
    }
    else if (this.tabMenu == 2) {
      let eventData = this.allFilters.find((item: { name: any; }) => item.name === this.allEvents.name);
      let eventIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.allEvents.name);

      if (eventIndex !== -1)
        this.allFilters[eventIndex].data = this.allEvents.data;
      if(!eventData)
        this.allFilters.push(this.allEvents)
    }

    this.allFilters = [...this.allFilters]
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)

      if (item.name === this.undertakingsNew.name) {
        this.utIds = filteredValues
      }
      else if (item.name === this.businessUnitsNew.name) {
        this.buIds = filteredValues
      }
      else if (item.name === this.allEvents.name) {
        this.eventIds = filteredValues
      }
    }
  }

  handleCountChange(count: any) {
    this.remoteDisconnectedCount = count;
  }

}
