import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { GraphItem } from 'src/app/models/graph-item.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { MenuItem, SubMenuItem } from '../../consumption/model/model';

@Component({
  selector: 'app-aeg-feeder11-new',
  templateUrl: './aeg-feeder11-new.component.html',
  styleUrls: ['./aeg-feeder11-new.component.scss']
})
export class AegFeeder11NewComponent {

  @Input() showTable = false;

  public SubMenuItemEnum = SubMenuItem;
  public Submenu: SubMenuItem = SubMenuItem.Feeders11;
  public menuItemEnum = MenuItem;

  //multiFilter
  @Input() f11Ids: number[] = []
  @Input() dateFrom: any = '';
  @Input() dateTo: any = '';

  //data
  public feeders11: [] = []

  avgReceived: number = 0;
  avgLosses: number = 0;
  avgEnergyConsumption: number = 0;

  private graphData: GraphItem[] = []
  public receivedTableData: any = []
  public lossesTableData: any = []

  public LostLabels: string[] = [];
  public receivedLabels: string[] = [];

  public receivedAndLossesTableData: any[] = [];

  public tableData: any[] = [];

  public receivedData: ChartDataset[] = [
    { data: [], label: 'Received',
      backgroundColor: '#29C87B',
      borderRadius: 45,
      barThickness: 12,
    },
    { data: [], label: 'Losses',
      backgroundColor: '#C03438',
      borderRadius: 45,
      barThickness: 12,
    },
  ];
  public barChartLegend = false;
  public barChartPlugins = [];

  @Input() menu!: MenuItem;
  @Output() redirect: EventEmitter<any> = new EventEmitter()
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()

  constructor(private toastr: ToastrService,
              private feeder11Service: Feeder11Service) { }


  ngOnChanges(changes: SimpleChanges): void {
    let reloadTable = false
    if(changes.f11Ids != null){
      reloadTable = true
    }
    if(changes.dateFrom != null || changes.dateTo != null){
      reloadTable = true
    }
    if(changes.searchText != null){
      reloadTable = true
    }

    if(reloadTable){

      this.GetData()
    }
  }


  public ChangeSubmenu(submenu: any) {
    this.redirect.emit(submenu)
  }
  public download(){
    let obj = {
        Start: transformToString(this.dateFrom),
        End: transformToString(this.dateTo),
        Feeder11Id: this.f11Ids,
        GetAll : false,
        Search: this.searchText.name
    };
    this.downloadReport(obj);
  }


  public GetData(){
    let obj = {
      Start: transformToString(this.dateFrom),
      End: transformToString(this.dateTo),
      Feeder11Id: this.f11Ids,
      GetAll: false,
      Search: this.searchText.name
    }
    this.getFeeder11EnergyReceivedAndLosses(obj)
    // this.getFeeder11Losses(obj)
  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        grid: {
          display: false
        },
        border:{
          display: false
        },
        ticks: {
          font: {
            size: 14
          },
        },
      },
      y: {
        grid: {
          color: '#E8E8E8',
        },
        border:{
          display: false
        },
        ticks: {
          padding: 30,
        }
      },
    },
    plugins:{
      tooltip:{
        callbacks: {
          label: function(context) {
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(context.parsed.y);
              }
              return label;
          }
      }
    }
  }
  }

  private getFeeder11EnergyReceivedAndLosses(obj: any){
    this.feeder11Service.getFeeder11ReceivedData(obj).subscribe((response: any) => {
      this.feeders11 = response.data
      this.setValuesForFeeder11Losses(response)
      this.setValuesForFeeder11Received(response)
      this.setValuesForTable(response.data)
    }, _ =>{
      this.toastr.error('An error ocured');
    })
  }

  private setValuesForTable(data: any){
    this.avgReceived = data.avgReceived;
    this.avgEnergyConsumption = data.avgEnergyConsumption;
    this.avgLosses = data.avgLosses;

    this.tableData = [];
    this.graphData = data.graphItems;
    this.graphData.forEach((element: any) => {
      this.tableData.push(element);
    })
  }

  // private getFeeder11Received(obj:any){
  //   this.feeder11Service.getFeeder11ReceivedData(obj).subscribe((response: GraphItem[]) => {
  //     this.setValuesForFeeder11Received(response)
  //   }, _ =>{
  //     this.toastr.error('An error ocured');
  //   })
  // }

  private setValuesForFeeder11Received(response: any){
    this.receivedData[0].data = []
    this.receivedLabels = []
    let labels = ['']
    labels = []
    this.receivedTableData = []
    if(response.data.graphItems != null && response.data.graphItems.length > 0){
      this.graphData = response.data.graphItems
      this.graphData.forEach(element =>{
      this.receivedData[0].data?.push(element.received)
      labels.push(this.sliceLabel(element.name))
      this.receivedTableData.push(element)
      })
      this.receivedAndLossesTableData[0] = this.receivedTableData;
    }
    else
      this.receivedAndLossesTableData[0] = [];
    this.receivedLabels = labels
  }

  private setValuesForFeeder11Losses(response: any){
    this.receivedData[1].data = []
    this.lossesTableData = []
    if(response.data.graphItems != null && response.data.graphItems.length > 0){
      this.graphData = response.data.graphItems
      this.graphData.forEach(element =>{
        this.receivedData[1].data?.push(element.lost)
        this.lossesTableData.push(element)
      })
      this.receivedAndLossesTableData[1] = this.lossesTableData;
    }
    else
      this.receivedAndLossesTableData[1] = [];
  }

  private sliceLabel(label: string){
    if(label.length > 30)
      return label.slice(0, 30) + '...'
    return label
  }

  downloadReport(obj: any){
    if (this.feeders11.length == 0) {
      this.toastr.warning('Please select F11 for download report.');
      return;
    }
    else{
      this.feeder11Service.downloadAssetEnergy(obj).subscribe(response =>{
        if(response.status == 200){
          // window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
          window.location.href = response.data;
        }
        else{
          this.toastr.error(response.message)
        }
      }, _ =>{
        this.toastr.error('An error occurred.');
      })
    }
  }

}
