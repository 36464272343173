<div class="slider-back" [hidden]="!showShadow">

</div>

<div [hidden]="!showShadow">
    <div class="slider" [ngClass]="{'shadow' : showShadow === true}" [@slideInOut]="animationState">
        <div class="tab-header">
            <span>Create Category</span>
            <div class="btn-close-position" (click)="changeState(2, selectedCategory)">
                <div class="btn-close">
                    <img alt="Close" src="../../../../../../assets/images-new-design/Close.png">
                </div>
            </div>

        </div>
        <form (submit)="f.valid" class="form col-sm-12" #f="ngForm">
            <div class="col-sm-12 crud-input-container">
                <label class="labelPopup" >Category name *</label>
                <input class="form-control" name="text" [(ngModel)]="selectedCategory.name" #name="ngModel" required
                    autocomplete="off" placeholder="Category name" pattern="^[a-zA-Z0-9]+(?:[a-zA-Z0-9 ]*[a-zA-Z0-9]+)*$" />
                <div *ngIf="f.submitted && name.invalid" class="form-control-error">Category name is required</div>
            </div>
            <div class="user-btn">

                <button class="submit col-xs-6" [disabled]="!f.valid" (click)="save()" >Save</button>
                <button class="cancel-btn col-xs-6" (click)="closeTab()">Cancel</button>
            </div>
        </form>

    </div>
</div>
