import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class OverdueBillArearsService {
  
  private pathApi = this.config.setting['pathApi'] + "overdueBillArears/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    getAll(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAll`, obj);
    }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    } 

    getCurrentTotalDebt(obj:any): Observable<any> {
      return this.http.post(`${this.pathApi}getCurrentTotalDebt`,obj);
    }
    
    downloadOverdueBillArearsReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadOverdueBillArearsReport`, obj);
    }

    downloadAndSendOverdueBillArearsReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadAndSendOverdueBillArearsReport`, obj);
    }

    uploadFile(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}uploadFile`, obj);
    }

    getTemplate(): Observable<any> {
      return this.http.get(`${this.pathApi}getTemplate`);
    }
    sendSms(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}sendSms`, obj);
    }

    getCurrentCountOfDebtors(): Observable<any>{
      return this.http.get(`${this.pathApi}getCurrentCountOfDebtors`);
    }

    sendMailOverdueBillArears(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}sendMailOverdueBillArears`, obj);
    }

    getOverduesForAutocomplete(searchText:string): Observable<any> {
      return this.http.get(`${this.pathApi}getOverduesForAutocomplete/${searchText}`)
    }
}
