import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { TransmissionStation } from 'src/app/models/transmission-station.model';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { CrudService } from 'src/app/services/crudService.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { CrudDeleteModalComponent } from '../crud-delete-modal/crud-delete-modal.component';
import { TransmissionStationCreateComponent } from './transmission-station-create/transmission-station-create.component';

@Component({
  selector: 'app-transmission-station-tab',
  templateUrl: './transmission-station-tab.component.html',
  styleUrls: ['./transmission-station-tab.component.scss']
})
export class TransmissionStationTabComponent implements OnInit {

  @ViewChild(TransmissionStationCreateComponent) popupComponentRef!: TransmissionStationCreateComponent;
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  public PermissionsEnum = PermissionsEnum;
  public selectedId: number = 0;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;

  public fileChosen: any = null;
  public file: any = null;

  public searchByName: string = '';
  public searchTS: SearchAutocomplete[] = [];

  private ngUnsubscribe = new Subject<void>();
  private searchText: string = "";

  public transmissionStations: TransmissionStation[] = [];
  public selectedTs: TransmissionStation = new TransmissionStation();
  public lastPageSize: number = 1;

  public masterSelected: boolean = false;
  public checkedList: any = [];

  @ViewChild("f") f: any;
  @Output() parentComp = new EventEmitter();
  @Output() deleteComp = new EventEmitter();

  constructor(private tsService: TransmissionStationService,
    private crudService: CrudService,
    private toastr: ToastrService,
    public authService: AuthService,
    private commonService: CommonService ) { }

  ngOnInit(): void {
    this.reloadTable();
    this.subscribeToSearch();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null){
    if(page)
      this.currentPage = page;

    this.resetSelectedTs();

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };
    if (!obj.filterParams.SearchContent){
      this.tsService.getAllForSelect().subscribe(response => {
        if (!obj.filterParams.SearchContent){
          this.searchTS = []
          response.data.forEach((x : TransmissionStation) =>
          {
            let item = new SearchAutocomplete();
            item.value = x.id;
            item.name = x.name;
            this.searchTS.push(item)
          })
        }
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
    this.tsService.getAll(obj).subscribe(response => {
      this.transmissionStations = response.data.data;
      if(obj.pageInfo.page == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      this.isAlreadyChecked();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  onFileChosen(event: any){
    this.file = event.target.files[0];
  }

  isFileChosen(){
    if(this.fileChosen)
      return true;

    return false;
  }

  selectTs(id: number){
    this.tsService.get(id).subscribe(response => {
      if(response.status === 200){
        this.selectedTs = response.data;
        this.parentComp.emit(this.selectedTs);
      }else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  openTab() {
    // this.popupComponentRef.changeState(1, this.selectedTs);
   }
  resetSelectedTs(){
    this.selectedTs = new TransmissionStation();

    if(this.f)
      this.f.submitted = false;
  }

  openTabDelete(id:number) {
    this.tsService.get(id).subscribe(response => {
     if(response.status === 200){
       this.selectedTs = response.data;
       this.deleteComp.emit(this.selectedTs);
     }
     else
       this.toastr.error(response.message);
   }, error => {
     this.toastr.error('An error occurred.');
   });
   this.selectedId = id;
 }

 onDeleteConfirmed(){
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_delete))
    {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.tsService.delete(this.selectedId).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.currentPage = 1;
        this.reloadTable();
        this.resetSelectedTs();
       }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getTemplate(){
    this.tsService.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch() {
    this.crudService.globalSearch$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(searchValue => {
      if(searchValue.clicked && searchValue.componentName === ComponentNamesNew.TransmissionStationComponent) {
        this.searchText = searchValue.text;
        this.currentPage = 1;
        this.checkedList = [];
        this.reloadTable();
        return;
      }
      this.searchText = '';
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkUncheckAll() {
    for (let i = 0; i < this.transmissionStations.length; i++) {
      this.transmissionStations[i].checked = this.masterSelected;
      this.checkForDownload(this.transmissionStations[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.transmissionStations.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.transmissionStations.every(function(item:any) {
      return item.checked == true;
    })
  }

  downloadItems(){
    let obj = {
      Ids: this.checkedList,
      activate: false
    };
    this.tsService.download(obj).subscribe(response => {
      if (response.status === 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.")
        }
        else {
          window.location.href = response.data
          this.toastr.success("Successfully downloaded Transmission Stations.");
        }
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
