<div *ngIf="isDialogOpen" class="popup2-overlay" (click)="changeState(popupEnum.Close)">
    <div class="popup2-content" *ngIf="!optionSelected" click-stop-propagation>
        <div class="close-content">
            <span class="popupTitle">Attachments</span>
            <div class="close-content-btn" (click)="changeState(popupEnum.Close)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 12.9436L16.3282 17.2718C16.453 17.3966 16.606 17.4632 16.7872 17.4718C16.9684 17.4804 17.1299 17.4137 17.2718 17.2718C17.4137 17.1299 17.4846 16.9726 17.4846 16.8C17.4846 16.6274 17.4137 16.4701 17.2718 16.3282L12.9436 12L17.2718 7.6718C17.3966 7.54702 17.4632 7.39403 17.4718 7.21283C17.4804 7.03163 17.4137 6.87009 17.2718 6.7282C17.1299 6.58631 16.9726 6.51537 16.8 6.51537C16.6274 6.51537 16.4701 6.58631 16.3282 6.7282L12 11.0564L7.6718 6.7282C7.54702 6.60342 7.39403 6.53676 7.21283 6.5282C7.03163 6.51964 6.87009 6.58631 6.7282 6.7282C6.58631 6.87009 6.51537 7.02736 6.51537 7.2C6.51537 7.37264 6.58631 7.52991 6.7282 7.6718L11.0564 12L6.7282 16.3282C6.60342 16.453 6.53676 16.606 6.5282 16.7872C6.51964 16.9684 6.58631 17.1299 6.7282 17.2718C6.87009 17.4137 7.02736 17.4846 7.2 17.4846C7.37264 17.4846 7.52991 17.4137 7.6718 17.2718L12 12.9436ZM12.0045 24C10.3451 24 8.78496 23.6851 7.32413 23.0554C5.86331 22.4256 4.59259 21.5709 3.51197 20.4913C2.43137 19.4117 1.57589 18.1422 0.945533 16.6827C0.315178 15.2233 0 13.6638 0 12.0045C0 10.3451 0.314878 8.78496 0.944633 7.32413C1.57441 5.86331 2.4291 4.59259 3.5087 3.51197C4.5883 2.43137 5.85782 1.57589 7.31727 0.945534C8.77673 0.315179 10.3362 0 11.9955 0C13.6549 0 15.215 0.314877 16.6759 0.944633C18.1367 1.57441 19.4074 2.4291 20.488 3.5087C21.5686 4.5883 22.4241 5.85782 23.0545 7.31727C23.6848 8.77673 24 10.3362 24 11.9955C24 13.6549 23.6851 15.215 23.0554 16.6759C22.4256 18.1367 21.5709 19.4074 20.4913 20.488C19.4117 21.5686 18.1422 22.4241 16.6827 23.0545C15.2233 23.6848 13.6638 24 12.0045 24ZM12 22.6667C14.9778 22.6667 17.5 21.6333 19.5667 19.5667C21.6333 17.5 22.6667 14.9778 22.6667 12C22.6667 9.02222 21.6333 6.5 19.5667 4.43333C17.5 2.36667 14.9778 1.33333 12 1.33333C9.02222 1.33333 6.5 2.36667 4.43333 4.43333C2.36667 6.5 1.33333 9.02222 1.33333 12C1.33333 14.9778 2.36667 17.5 4.43333 19.5667C6.5 21.6333 9.02222 22.6667 12 22.6667Z"
                        fill="#7F7D82" />
                </svg>
            </div>
        </div>
        <div class="content" [ngClass]="{'single-grid': dataNew.length === 1}">
            <div class="content-item"  >
                <div class="pdf-item">
                    <div class="content-item" *ngFor="let item of dataNew">
                        <div *ngIf="!isImageExtension(fileExtension)" >
                            <div class="block">
                                <div class="fileName"> <img alt="File" class="icon" [src]="'assets/images-new-design/file_present.png'" />
                                    {{item.fileName }}</div>
                                <img alt="Download" class="icon" [src]="'assets/images-new-design/downloadIcon.png'"
                                    (click)="download(item.url)" />
                            </div>
                        </div>
                        <img alt="Error" *ngIf="isImageExtension(fileExtension)" [src]="item.url" (error)="onImgError($event)" />
                        <div *ngIf="isImageExtension(fileExtension)" (click)="download(item.url)" class="download-icon">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.5 11.575C8.36667 11.575 8.24167 11.5542 8.125 11.5125C8.00833 11.4708 7.9 11.4 7.8 11.3L4.2 7.7C4 7.5 3.90417 7.26667 3.9125 7C3.92083 6.73333 4.01667 6.5 4.2 6.3C4.4 6.1 4.6375 5.99583 4.9125 5.9875C5.1875 5.97917 5.425 6.075 5.625 6.275L7.5 8.15V1C7.5 0.716667 7.59583 0.479167 7.7875 0.2875C7.97917 0.0958333 8.21667 0 8.5 0C8.78333 0 9.02083 0.0958333 9.2125 0.2875C9.40417 0.479167 9.5 0.716667 9.5 1V8.15L11.375 6.275C11.575 6.075 11.8125 5.97917 12.0875 5.9875C12.3625 5.99583 12.6 6.1 12.8 6.3C12.9833 6.5 13.0792 6.73333 13.0875 7C13.0958 7.26667 13 7.5 12.8 7.7L9.2 11.3C9.1 11.4 8.99167 11.4708 8.875 11.5125C8.75833 11.5542 8.63333 11.575 8.5 11.575ZM2.5 16C1.95 16 1.47917 15.8042 1.0875 15.4125C0.695833 15.0208 0.5 14.55 0.5 14V12C0.5 11.7167 0.595833 11.4792 0.7875 11.2875C0.979167 11.0958 1.21667 11 1.5 11C1.78333 11 2.02083 11.0958 2.2125 11.2875C2.40417 11.4792 2.5 11.7167 2.5 12V14H14.5V12C14.5 11.7167 14.5958 11.4792 14.7875 11.2875C14.9792 11.0958 15.2167 11 15.5 11C15.7833 11 16.0208 11.0958 16.2125 11.2875C16.4042 11.4792 16.5 11.7167 16.5 12V14C16.5 14.55 16.3042 15.0208 15.9125 15.4125C15.5208 15.8042 15.05 16 14.5 16H2.5Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
