<app-planned-outage-update (updated) = "refreshPage($event)"></app-planned-outage-update>
<main class="outage-wrapper">
  <table class="standard-table outage-table">
    <thead>
      <tr>
        <th title="Creation Date Time"><span>CREATION DATE TIME</span></th>
        <th title="Asset Type"><span>ASSET TYPE</span></th>
        <th title="Asset Name"><span>ASSET NAME</span></th>
        <th title="Asset Number"><span>ASSET NUMBER</span></th>
        <th title="Meter Number"><span>METER NUMBER</span></th>
        <th title="Substation"><span>SUBSTATION</span></th>
        <th title="Transmission Station"><span>TRANSMISSION STATION</span></th>
        <th title="Asset Location"><span>ASSET LOCATION</span></th>
        <th title="Outage Start Time"><span>OUTAGE START TIME</span></th>
        <th title="Outage End Time"><span>ACTUAL OUTAGE END TIME</span></th>
        <th title="Estimated Outage End Time"><span>ESTIMATED OUTAGE END TIME</span></th>
        <th title="Status"><span>STATUS</span></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of plannedOutages">
        <td title="{{item.creationDateTime}}">
          <span *ngIf="item.creationDateTime && item.creationDateTime != '/'">{{(item.creationDateTime | date:'MMM
            dd,yyyy, HH:mm:ss')}}</span>
          <span *ngIf="!item.creationDateTime" class="NaN">N/A</span>
        </td>
        <td title="{{item.assetType}}">
          <span *ngIf="item.assetType && item.assetType != '/' && item.assetType == 'f11' ">Feeder 11</span>
          <span *ngIf="item.assetType && item.assetType != '/' && item.assetType == 'f33' ">Feeder 33</span>
          <span *ngIf="item.assetType && item.assetType != '/' && item.assetType == 'dt' ">Dt</span>
          <span *ngIf="!item.assetType || item.assetType == '/' || (item.assetType != 'f11' && item.assetType != 'f33' && item.assetType != 'dt')" class="NaN">N/A</span>
        </td>
        <td title="{{item.assetName}}">
          <span *ngIf="item.assetName && item.assetName != '/'">{{item.assetName}}</span>
          <span *ngIf="!item.assetName || item.assetName == '/'" class="NaN">N/A</span>
        </td>
        <td title="{{item.assetNumber}}">
          <span *ngIf="item.assetNumber && item.assetNumber != '/'">{{item.assetNumber}}</span>
          <span *ngIf="!item.assetNumber || item.assetNumber == '/'" class="NaN">N/A</span>
        </td>
        <td title="{{item.meterNumber}}">
          <span *ngIf="item.meterNumber && item.meterNumber != '/'">{{item.meterNumber}}</span>
          <span *ngIf="!item.meterNumber || item.meterNumber == '/'" class="NaN">N/A</span>
        </td>
        <td title="{{item.substationName}}">
          <span *ngIf="item.substationName && item.substationName != '/'">{{item.substationName}}</span>
          <span *ngIf="!item.substationName || item.substationName == '/'" class="NaN">N/A</span>
        </td>
        <td title="{{item.transmissionStationName}}">
          <span *ngIf="item.transmissionStationName && item.transmissionStationName != '/'">{{item.transmissionStationName}}</span>
          <span *ngIf="!item.transmissionStationName || item.transmissionStationName == '/'" class="NaN">N/A</span>
        </td>
        <td title="{{item.location}}">
          <span *ngIf="item.location && item.location != '/'">{{item.location}}</span>
          <span *ngIf="!item.location || item.location == '/'" class="NaN">N/A</span>
        </td>
        <td title="{{item.outageStart}}">
          <span *ngIf="item.outageStart && item.outageStart != '/'">{{(item.outageStart | date:'MMM
            dd,yyyy, HH:mm:ss')}}</span>
          <span *ngIf="!item.outageStart" class="NaN">N/A</span>
        </td>
        <td class="minWidth" title="{{item.actualOutageEnd}}">
          <span *ngIf="item.actualOutageEnd && item.actualOutageEnd != '/'">{{(item.actualOutageEnd | date:'MMM
            dd,yyyy, HH:mm:ss')}}</span>
          <span *ngIf="!item.actualOutageEnd" class="NaN">N/A</span>
        </td>
        <td title="{{item.outageEnd}}">
          <span *ngIf="item.outageEnd && item.outageEnd != '/'">{{(item.outageEnd | date:'MMM
            dd,yyyy, HH:mm:ss')}}</span>
          <span *ngIf="!item.outageEnd" class="NaN">N/A</span>
        </td>
        <td>
          <span *ngIf="item.status && item.status != '/' && item.status == 1">Upcoming</span>
          <span *ngIf="item.status && item.status != '/' && item.status == 2 ">In Progress</span>
          <span *ngIf="item.status && item.status != '/' && item.status == 3 ">Completed</span>
          <span *ngIf="!item.status || item.number == '/' || item.status == 0" class="NaN">N/A</span>
        </td>
        <td>
          <button class="editButton" [disabled] = "item.status == 3 || item.status == 0" placement="top" [ngbTooltip]="item.status == 3 || item.status == 0 ? 'Updating Completed Outages is not allowed' : ''" [openDelay]="250" (click)="openTab(item.id, 1)"><svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white"/>
            <rect x="0.5" y="0.5" width="43" height="43" rx="7.5" stroke="#E8E8E8"/>
            <mask id="mask0_8505_132517" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="12" y="12" width="20" height="20">
            <rect x="12" y="12" width="20" height="20" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_8505_132517)">
            <path d="M15.8334 29.0525C15.5685 29.1113 15.3404 29.045 15.1491 28.8538C14.9579 28.6626 14.8916 28.4345 14.9504 28.1695L15.6475 24.8234L19.1795 28.3554L15.8334 29.0525ZM19.1795 28.3554L15.6475 24.8234L24.9969 15.4741C25.2843 15.1867 25.64 15.043 26.0642 15.043C26.4883 15.043 26.8441 15.1867 27.1314 15.4741L28.5289 16.8715C28.8163 17.1589 28.9599 17.5146 28.9599 17.9388C28.9599 18.3629 28.8163 18.7187 28.5289 19.0061L19.1795 28.3554ZM25.8879 16.3522L17.367 24.8651L19.1379 26.6359L27.6507 18.1151C27.6988 18.067 27.7228 18.0069 27.7228 17.9348C27.7228 17.8626 27.6988 17.8025 27.6507 17.7544L26.2485 16.3522C26.2004 16.3042 26.1403 16.2801 26.0682 16.2801C25.996 16.2801 25.9359 16.3042 25.8879 16.3522Z" fill="#1C1B1F"/>
            </g>
        </svg></button>
        </td>
      </tr>
    </tbody>
  </table>
</main>
<div class="pagination-wrapper">
  <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
    (pageChange)="pageChange($event)">
  </app-fo-pagination-new>
</div>
