<main class="container-fluid">
    <div class="container-wrapper">
        <span class="network-management-title">Outage</span>
        
        <div class="menu-tab-filter">
            <app-tab-default
            [firstText]="'CurrentOutage'"
            [secondText]="'HistoricalOutage'"
            (tabChanged)="menuChanged($event)"
            ></app-tab-default>
        </div>
        <div class="nm-filter-wrapper">
            <app-tab-default
            [firstText]="'33KV Feeders'"
            [secondText]="'11KV Feeders'"
            [thirdText]="'Distribution Transformer'"
            [paCss]="true"
            [womCss]="true"
            (tabChanged)="tabChanged($event)"
            [tabSelected]="tabMenu"            >
        </app-tab-default>
        </div>

        <div class="filters-container">
                <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

                    <app-multi-filter  *ngIf="filterApplied" 
                        [data]="allFilters"
                        (dataChange)="applyFilter($event)"
                        [(showFilter)]="showFilter"
                        (dropdownChange)="dropdownSelect($event)"
                        >
                    </app-multi-filter>


                    <app-outage-duration-filter
                    [durationFrom]="durationFrom" 
                    [durationTo]="durationTo" 
                    (durationChange)="durationChange($event)">    
                    </app-outage-duration-filter>

                    <app-range-datepicker
                    *ngIf="this.menu!=1"
                    [fromDate]="dateFrom" 
                    [toDate]="dateTo"
                    [name]="'Period'"
                    [maxDate]="maxDate"
                    (dateRangeSelected)="dateChange($event)">
                    </app-range-datepicker>

                    <app-search-autocomplete
                    #search
                    [placeholder]="searchPlaceholder"
                    [searchText]="searchText"
                    [options]="options"        
                    (searchChange)="searchChanged($event)"
                    (searchAutocompleteChange)="getOptions($event)"
                  ></app-search-autocomplete>
                </div>
                <div class="download-button">
                    <app-download-button-new
                    [isOnlyDownload]="true"
                    (downloadButtonClickedChange)="download()">
                    </app-download-button-new>   
                </div>
        </div>

        <app-outage-dt-new
        #child
        *ngIf="tabMenu == 3"
        [buIds]="buIds"
        [f11Ids]="f11Ids"
        [categoryId]="categorySelected.value"
        [searchText]="searchText"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        [durationFrom]="durationFrom"
        [durationTo]="durationTo"
        [menu]="menu"
        [tabMenu]="tabMenu"
        ></app-outage-dt-new>

        <app-outage-feeder11-new
        #child
        *ngIf="tabMenu == 2"
        [ssIds]="ssIds"
        [categoryId]="categorySelected.value"
        [searchText]="searchText"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        [durationFrom]="durationFrom"
        [durationTo]="durationTo"
        [menu]="menu"
        [tabMenu]="tabMenu"
        ></app-outage-feeder11-new>

        <app-outage-feeder33-new
        #child
        *ngIf="tabMenu == 1"
        [tsIds]="tsIds"
        [categoryId]="categorySelected.value"
        [searchText]="searchText"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        [durationFrom]="durationFrom"
        [durationTo]="durationTo"
        [menu]="menu"
        [tabMenu]="tabMenu"
        ></app-outage-feeder33-new>
    </div>
</main>
