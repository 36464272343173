import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Bill } from 'src/app/models/bill.model';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { BillsService } from 'src/app/services/bills.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommandRequestService } from 'src/app/services/command-request.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.scss']
})
export class BillsComponent implements OnInit {
  public showPaging: boolean = false;

  public currentPage: number = 1
  public pageSize: number = 10
  public count: number = 0;
  public lastPageSize: number = 1

  //public countForDownload: number = 100_000

  public searchText: string = ''

  public dateFrom: any = ''
  public date: any = ''

  public bills: Bill[] = []

  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')

  public businessUnits: any = []
  public undertakings: any = []

  public buSelected: DictionaryItem = new DictionaryItem(0, this.buName)
  public utSelected: DictionaryItem = new DictionaryItem(0, this.utName)

  constructor(
    private billsService: BillsService,
    private commandRequestService: CommandRequestService,
    public businessUnitService: BusinessUnitService,
    public undertakingService: UndertakingService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private location: Location
    ) { }

  ngOnInit(): void {
    this.setInitalDate()
    this.reloadTable()
    this.getBusForSelect()
    this.getUtsForSelect()
    this.showPaging=true;

  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page

    let obj = this.generateObject()

    this.billsService.getAll(obj).subscribe(
      (response) => {
        this.bills = response.data.data
        if(this.currentPage == 1)
        {
          this.billsService
            .getBillsCount(obj)
            .subscribe((response) => {
              this.count = response.data
            })
            this.lastPageSize = Math.ceil(this.count/this.pageSize)
        }
        this.showPaging=false;
      },
      (error) => {
        this.toastr.error('An error occurred.')
      }
    )
    this.showPaging=true;

  }

  generateObject() {
    let obj = {
      pageInfo: {
        pageSize: this.pageSize,
        page: this.currentPage,
      },
      filterParams: {
        from: this.date.from,
        to: this.date.to,
        buName: this.buSelected.value === 0 ? '' : this.buSelected.name,
        utName: this.utSelected.value === 0 ? '' : this.utSelected.name,
        search: this.searchText
      },
      guid: crypto.randomUUID()
    }

    return obj
  }

  setInitalDate() {
    const date = new Date()
    date.setMonth(date.getMonth() - 1)
    let dateFrom = new Date(date)
    const month = ("0" + (dateFrom.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    this.dateFrom = `${year}-${month}`;
    console.log(this.dateFrom)
    this.updateDate(this.dateFrom)
  }

  pageChange(value: any) {
    this.currentPage = value
    this.reloadTable()
  }

  getBusForSelect() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = response.data
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getUtsForSelect() {
    this.undertakingService.getAllForSelect().subscribe(response => {
      this.undertakings = response.data
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  businessUnitSelected(bu: any) {
    this.buSelected = bu
    this.pageChange(1)
  }

  undertakingSelected(data: any) {
    this.utSelected = data
    this.pageChange(1)
  }

  updateDate(date: any) {
    console.log(date)
    if (!date) {
      this.setInitalDate()
      this.date = {
        from: '',
        to: ''
      }
    } else {
      let month = parseInt(date.split('-')[1])
      let year = parseInt(date.split('-')[0])

      if (month == 12){
        month = 1
        year += 1
      }
      else {
        month += 1
      }

      let datefrom = `${year}-${month}`

      this.date = {
        from: date,
        to: datefrom
      }
    }

    this.reloadTable(1)
  }

  inputChanged(searchText: string) {
    this.searchText = searchText
    this.pageChange(1)
  }

  download() {
    let error = this.validateDownload();

    if(error){
      this.toastr.error(error);
      return;
    }

    let obj = this.generateObject()
    obj.pageInfo.pageSize = 0;
    this.billsService.DownloadBillsReport(obj).subscribe(
      (response) => {
        if(response.status == 201){
          this.toastr.success(response.message)
        }
        if(response.status == 200){
          if(!response.data.includes("http")){
            this.toastr.error("An error occured.");
          }
          else{
            window.location.href = response.data;
          }
        }
      },
      (_) => {
        this.toastr.error('An error occured')
      }
    )

  }

  downloadFile(url: string) {
    console.log(url);
    const link = document.createElement('a');
    link.href = url;
    let s = url.split('/');
    let filename = s[s.length - 1];
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

  checkCommandRequest(guid: string) {
    // initial check
    this.commandRequestService.checkCommandRequest(guid).subscribe(response => {
      if (response.data) {
        window.location.href = response.message
      }
    })

    // check every 5s
    let intervalRef = setInterval(() => {
      this.commandRequestService.checkCommandRequest(guid).subscribe(response => {
        if (response.data) {
          window.location.href = response.message
          clearInterval(intervalRef);
        }
      })
    }, 5000)
  }

  validateDownload(): string{
    let message: string = "";

    if(this.buSelected && this.buSelected.value === 0){
      message = "Please select region for download.";
    }

    return message;
  }

  back() {
    this.location.back();
  }
}
