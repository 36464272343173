import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Config } from 'src/app/models/config';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { ConfigRevenueProtectionService } from 'src/app/services/config-revenue-protection.service';

@Component({
  selector: 'app-configuration-settings-create',
  templateUrl: './configuration-settings-create.component.html',
  styleUrls: ['./configuration-settings-create.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(143%, 0, 0)'
      })),
      transition('in => out', animate('900ms ease-in-out')),
      transition('out => in', animate('900ms ease-in-out'))
    ]),
  ], host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class ConfigurationSettingsCreateComponent implements OnInit {
  @ViewChild(ConfigurationSettingsCreateComponent) popupComponentRef!: ConfigurationSettingsCreateComponent; 
  showShadow = false;
  @Input() animationState = 'out';
  public PermissionsEnum = PermissionsEnum;
  public configBills: Config[] = []

  public flags: Config[] = []
  public flag1: Config = new Config()
  public flag2: Config = new Config()
  public flag3: Config = new Config()
  constructor(private ref: ElementRef,
    private toastr: ToastrService,
    public authService: AuthService,
    private configService: ConfigRevenueProtectionService,
    ) { }

  ngOnInit(): void {
    this.getBillsConfiguration();
  }
  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isSliderClicked){
          this.changeState(2);
        }
      }
  }
  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }
  changeState(number: Number) {
   
   if (number === 1) {
     this.toggleShowDiv("open");
     this.showShadow = true;
   }
   if (number === 2) {
     this.toggleShowDiv("close");
      this.showShadow = false; 
   }
 }

 getBillsConfiguration(){
  this.configService.getAll().subscribe(response =>{
    if(response.status == 200){
      this.configBills = response.data
      this.flag1.key = this.configBills[0].key
      this.flag2.key = this.configBills[1].key
      this.flag3.key = this.configBills[2].key

      this.flag1.value = this.configBills[0].value
      this.flag2.value = this.configBills[1].value
      this.flag3.value = this.configBills[2].value

      this.flag1.name = this.configBills[0].name
      this.flag2.name = this.configBills[1].name
      this.flag3.name = this.configBills[2].name
    }
    else{
      this.toastr.error(response.message)
    }
  }, _=>{
    this.toastr.error("An error occurred")
  })
}
validateFlag1(){
  if(this.flag1.value == null){
    return false
  }
  else{
    return true
  }
}
updateSMSFlag(value: any){
   this.flag3.value = value.target.value
}

 validateFlag2(){
  if(this.flag2.value == null){
    return false
  }
  else{
    return true
  }
}
save(){
  if(!this.authService.isAuthorized(PermissionsEnum.revenueprotection_settings_createupdate))
  {
    this.toastr.error("You don't have permission.");
    return;
  }

  this.flags = [this.flag1, this.flag2, this.flag3] 

  this.configService.saveConfiguration(this.flags).subscribe(response =>{
    if(response.status == 200){
      this.getBillsConfiguration();
      window.location.reload();
     }
    else{
      this.toastr.error(response.message)
    }
  }, _=>{
    this.toastr.error("An error occurred")
  })
}

}
