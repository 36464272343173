import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Feeder33AssetPerfomance } from 'src/app/models/f33-asset-performance.model';
import { PopupEnum } from 'src/app/models/popupEnum';
import { AssetPerformanceF33PopupComponent } from './asset-performance-f33-popup/asset-performance-f33-popup.component';

@Component({
  selector: 'app-asset-performance-f33-new',
  templateUrl: './asset-performance-f33-new.component.html',
  styleUrls: ['./asset-performance-f33-new.component.scss']
})
export class AssetPerformanceF33NewComponent implements OnInit {

  @ViewChild(AssetPerformanceF33PopupComponent) popupComponentRef!: AssetPerformanceF33PopupComponent;

  @Input() assetPerformanceF33: Feeder33AssetPerfomance[] = []


  selectedAssetPerfomanceF33: any = {};
  @Input() checkedList: number[] = [];
  public masterSelected: boolean = false;

  ngOnInit(): void {
    this.isAlreadyChecked()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isAlreadyChecked()
  }

  openTab() {
    this.popupComponentRef.changeState(PopupEnum.Open, this.selectedAssetPerfomanceF33);
  }
  closeTab() {
    this.popupComponentRef.changeState(PopupEnum.Close, this.selectedAssetPerfomanceF33);
  }

  selectAssetPerformanceF33(feederName: string) {
    this.selectedAssetPerfomanceF33 = this.assetPerformanceF33.find(x => x.feederName == feederName)
    this.openTab()
  }

  checkUncheckAll() {
    for (let i = 0; i < this.assetPerformanceF33.length; i++) {
      this.assetPerformanceF33[i].checked = this.masterSelected;
      this.checkForDownload(this.assetPerformanceF33[i].id, true);
    }
  }


  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.assetPerformanceF33.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.assetPerformanceF33.every(function(item:any) {
      return item.checked == true;
    })
  }

}
