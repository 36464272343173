import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { Tariff } from 'src/app/models/tariff.model';
import { AuthService } from 'src/app/services/auth.service';
import { TariffService } from 'src/app/services/tariff.service';
import { ImportDetailsPopupComponent } from '../common-new-design/import-details-popup/import-details-popup.component';
import { SearchAutocompleteComponent } from '../common-new-design/search-autocomplete/search-autocomplete.component';
import { CrudDeleteModalComponent } from '../crud-tabs/crud-delete-modal/crud-delete-modal.component';
import { CreateTariffComponent } from './create-tariff/create-tarrif.component';


@Component({
  selector: 'app-tarrif-settings',
  templateUrl: './tarrif-settings.component.html',
  styleUrls: ['./tarrif-settings.component.scss']
})
export class TariffSettingsComponent implements OnInit {
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;
  @ViewChild(ImportDetailsPopupComponent) importRef!: ImportDetailsPopupComponent;

  public PermissionsEnum = PermissionsEnum;
  @ViewChild(CreateTariffComponent) popupComponentRef!: CreateTariffComponent;
  public selectedId: number = 0;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public searchByName: string = '';
  public searchByRate: string = '';

  public ngUnsubscribe = new Subject<void>();
  public tariffs: Tariff[] = [];
  public selectedTariff: Tariff = new Tariff();

  public fileChosen: any = null;
  public file: any = null;
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []
  public checkedList: any = [];
  tariffIds: any = [];
  public masterSelected: boolean = false;

  @ViewChild('search') searchChild!: SearchAutocompleteComponent;

  @ViewChild("f") f: any;
  constructor(private tariffService: TariffService,
    private toastr: ToastrService,
    public authService: AuthService,
    private location: Location) { }

    checkUncheckAll() {
      for (let i = 0; i < this.tariffs.length; i++) {
        this.tariffs[i].checked = this.masterSelected;
          this.checkForDownload(this.tariffs[i].id, this.masterSelected);
      }
      console.log(this.tariffs)
      console.log(this.masterSelected)
      console.log(this.checkedList)
    }

    checkForDownload(id: number, isMasterClicked: boolean = false) {
      if(!isMasterClicked)
        this.isMasterChecked();

      let index = this.checkedList.indexOf(id);
      if(index !== -1){
        if(!isMasterClicked)
          this.checkedList.splice(index, 1);
      }
      else {
        this.checkedList.push(id);
      }
    }

    isAlreadyChecked() {
      this.tariffs.forEach(x => {
        let index = this.checkedList.indexOf(x.id)
        if(index !== -1) {
          x.checked = true;
        }
      })

      this.isMasterChecked();
    }

    isMasterChecked() {
      this.masterSelected = this.tariffs.every(function(item:any) {
        return item.checked == true;
      })
    }

    public download() {
      let obj = {
          searchContent: this.searchText.name,
          tariffIds: this.checkedList
      };

      this.tariffService.download(obj).subscribe(response => {
        window.location.href = response.data;
      }, _ => {
        this.toastr.error('An error occurred.');
      })
    }

    getOptions(searchText: any) {
      let obj = {
        searchText: searchText.name,
        msnIncluded: false
      }
      if (searchText.name.length > 1) {
        this.tariffService.getDataForAutocomplete(obj)
              .subscribe({
                next : (response) => this.options = response.data,
                error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
              })
      }else{
        this.searchChild.resetOptions()
      }
    }

  ngOnInit(): void {
    this.reloadTable();
    this.subscribeToSearch();
  }
  openUploadTab() {
    this.importRef.changeState();
  }
  pageChange(value: any) {
    this.currentPage = value;
    this.masterSelected = false;
    this.reloadTable();
  }
  back() {
    this.location.back();
  }
  inputChanged(text: SearchAutocomplete){
    this.searchText = text
    this.searchText = cloneDeep(this.searchText)
    this.reloadTable();
    this.checkedList = [];
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;

    this.resetSelectedTariff();

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchContent: this.searchText.name
      }
    };

    this.tariffService.getAll(obj).subscribe(response => {
      this.tariffs = response.data.data;
      if(obj.pageInfo.page == 1){
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      this.isAlreadyChecked();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  closeTab() {
    this.popupComponentRef.changeState(2, this.selectedTariff);
  }

  openTab(id?:number) {
    console.log("id",id)
    if(id===0){
      this.popupComponentRef.changeState(1, 0);
    }else{
    this.popupComponentRef.changeState(1, this.selectedTariff);
   }
  }

  selectTariff(id: number) {
    this.tariffService.get(id).subscribe(response => {
      if (response.status === 200){
        this.selectedTariff = response.data;
        this.openTab()
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedTariff() {
    this.selectedTariff = new Tariff();

    if (this.f)
      this.f.submitted = false;
  }

  openTabDelete(id:number) {
    this.tariffService.get(id).subscribe(response => {
     if(response.status === 200){
       this.selectedTariff = response.data;
     }
     else
       this.toastr.error(response.message);
   }, error => {
     this.toastr.error('An error occurred.');
   });
   this.selectedId = id;
   this.crudDeleteRef.changeState();
 }
 onDeleteConfirmed() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_delete)) {
      this.toastr.error("You don't have permission.");
      return;
    }

    this.tariffService.delete(this.selectedId).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.reloadTable();
        this.resetSelectedTariff();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  onFileChosen(event: any) {
    this.file = event.target.files[0];
  }

  isFileChosen() {
    if (this.fileChosen)
      return true;

    return false;
  }

  uploadFile() {
    this.file = this.importRef.fileChosen;

    if (!this.isFileChosen) {
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());

    this.tariffService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.reloadTable();
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.reloadTable();
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  getTemplate() {
    this.tariffService.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch() {
    // this.headerService.globalSearch$
    // .pipe(takeUntil(this.ngUnsubscribe))
    // .subscribe(searchValue => {
    //   if(searchValue.clicked && searchValue.componentName === ComponentNamesNew.TariffComponent) {
    //     this.searchText = searchValue.text;
    //     this.currentPage = 1;
    //     this.reloadTable();
    //     return;
    //   }
    //   this.searchText = '';
    // });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
