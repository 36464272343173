import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';

export enum SettingsItems{
  UserProfileManagamentComponent = 1,
  UserSettingsComponent = 2,
  RoleManagementNewComponent = 3, 
  AuditLogNewComponent=5,
  AccountTabComponent=14,
  CrudTabsComponent=16,
  TarrifSettingsComponent=17,
  JobStatusHistoryNewComponent=6,
  TranslationPageComponent = 8
}
@Component({
  selector: 'app-settings-sidebar',
  templateUrl: './settings-sidebar.component.html',
  styleUrls: ['./settings-sidebar.component.scss']
})
export class SettingsSidebarComponent implements OnInit {
  public SelectedItem: any = 16;
  public permissionEnum = PermissionsEnum 

  constructor(
    private toastr: ToastrService, 
    private router: Router,
    public authService: AuthService) { 
    this.router.events
      .subscribe(
        (event) => {
          if(event instanceof ActivationEnd && event.snapshot.children.length == 0) {
            this.SelectedItem = event.snapshot.data['sidebarItem'];
           } 
        });
      }
  ngOnInit(): void { 
    this.SelectedItem = 16;
  }

  alert(){
    this.toastr.warning("Not yet implemented");
  }

  tabSelected(tabSelected: number){
    this.SelectedItem = tabSelected;
  }
  
  underRedesign(){
    this.toastr.warning("Under redesign.");
  }
}
