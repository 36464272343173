import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  private pathApi = this.config.setting['pathApi'] + "auditLog/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    getAll(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, obj);
    }

    download(path: string): Observable<any>{
      return this.http.get(`${this.pathApi}download/${path}`);
    }
    GetAllNicknamesForSelect(): Observable<any> {
      return this.http.get(`${this.pathApi}GetAllNicknamesForSelect`);
    }

    getDataForAutocomplete(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj);
    }

    downloadReport(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}downloadReport`,obj);
    }
}
