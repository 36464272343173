import { Injectable } from '@angular/core';
import { StaffOperator } from '../models/staff-operator.model';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StaffOperatorService {

  private pathApi = this.config.setting['pathApi'] + "staffOperator/";

  constructor(private http: HttpClient, 
    private config: AppConfig
  ) {  }

  get(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}${id}`);
  } 

  getAll(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, obj)
  }

  uploadFile(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile`, obj)
  }

  getTemplate(): Observable<any>{
    return this.http.get(`${this.pathApi}getTemplate`);
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}download`, obj);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.pathApi}delete/${id}`);
  } 

  save(obj: StaffOperator): Observable<any> {
    return this.http.post(`${this.pathApi}save`, obj);
  }
  
  getDataForAutocomplete(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
  }

  getUnassignedAssetsForAutocomplete(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getUnassignedAssetsForAutocomplete`, obj)
  }
  
  updateAssignedAssetsForStaffOperator(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}updateAssignedAssetsForStaffOperator`, obj)
  }

  getAssignedAssetsForSO(staffOperatorId: number): Observable<any> {
    return this.http.get(`${this.pathApi}getAssignedAssetsForSO/${staffOperatorId}`);
  } 
}
