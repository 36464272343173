import { Component } from '@angular/core';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';

@Component({
  selector: 'app-inventory-management',
  templateUrl: './inventory-management.component.html',
  styleUrls: ['./inventory-management.component.scss']
})
export class InventoryManagementComponent {

  public viewsEnum = ViewsEnum
  public activeView: ViewsEnum = ViewsEnum.View
  public itemId: number = 0

  public changeView(value: ViewsEnum){
    this.activeView = value
  }

  setItemId(id: number){
    this.itemId = id
  }

}
