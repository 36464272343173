import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MapService } from 'src/app/services/map.service';
import { ClusterizedMapComponent } from '../../common/clusterized-map/clusterized-map.component';
import { MenuItem } from '../../consumption/model/model';

@Component({
  selector: 'app-customers-map',
  templateUrl: './customers-map.component.html',
  styleUrls: ['./customers-map.component.scss']
})
export class CustomersMapComponent implements AfterViewInit {
  public Data: any = []
  @Input() reloadMapTrigger : boolean = true;
  @ViewChild(ClusterizedMapComponent) public clusterizedMap: ClusterizedMapComponent;
  public searchText: string = '';
  public searchWidth: string ="412px";


  public isMap: boolean = true;
  public menu: MenuItem = MenuItem.Network
  mapLoaded: boolean = false;

  constructor(private mapService : MapService,  private cdr: ChangeDetectorRef) { }

  public markers: any[] = [];

  searchChange(text: string) {
    this.searchText = text
  }
  public toggleMap(map: boolean){
    this.isMap = map;
    if(!map)
      this.menu = MenuItem.Network;
  }

  ngAfterViewInit(): void {
    if(this.clusterizedMap) {
        this.mapLoaded = true;
        this.cdr.detectChanges();
      }
  }

}
