<main class="container-fluid">
        <div class="first" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_createupdate)">
            <input #chooseFile type="file" accept=".xlsx" style="display: none;"
                (change)="onFileChosen($event);uploadFile();" [(ngModel)]="fileChosen">
        </div>
        <div class="row-item">
            <div class="item"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" (change)="checkUncheckAll()"> Select All</div>
        </div>

        <div class *ngFor="let item of amis">
            <div class="row-item">
                <div class="item" (click)="false; $event.stopPropagation();" (change)="checkForDownload(item.id)"><input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked"> {{item.name}}</div>
                <div class="item-btn">
                    <div class="edit-item" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_createupdate)"
                    (click)="selectAmi(item.id)" ><img alt="Edit" class="edit" src="assets/images-new-design/stylus.svg" data-toggle="modal"
                        data-target="#createModal"> Edit</div>
                     <img alt="Delete" class="delete" src="assets/images-new-design/delete.svg" data-toggle="modal"
                            data-target="#deleteModal" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_delete)"
                            (click)="openTabDelete(item.id)">
                </div>
            </div>
        </div>
        <div class="pagination-wrapper">
            <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
        </div>
</main>
