import { Component } from '@angular/core';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';

@Component({
  selector: 'app-work-order-management-new',
  templateUrl: './work-order-management-new.component.html',
  styleUrls: ['./work-order-management-new.component.scss']
})

export class WorkOrderManagementNewComponent {

  public viewsEnum = ViewsEnum
  public activeView: ViewsEnum = ViewsEnum.View
  public taskId: number = 0

  public changeView(value: ViewsEnum){
    this.activeView = value
  }

  setTaskId(id: number){
    this.taskId = id
  }
}
