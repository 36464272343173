import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MeterAsset } from 'src/app/models/meter-asset.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-asset-dt-meters',
  templateUrl: './asset-dt-meters.component.html',
  styleUrls: ['./asset-dt-meters.component.scss', '../grid.component.scss']
})
export class AssetDtMetersComponent implements OnChanges{
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  constructor(
    private toastr: ToastrService,
    private meterService : MeterService,
    private translationService: TranslationService,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    let reloadTable = false;
    if(changes.buIds){
      reloadTable = true;
    }
    if(changes.search){
      reloadTable = true;
    }
    if(changes.amiIds){
      reloadTable = true;
    }
    if(changes.utIds){
      reloadTable = true;
    }
    if(reloadTable){
      this.currentPage = 1
      this.getData();
    }
  }
  public data: MeterAsset[] = [];
  public count: number = 0
  public pageSize: number = 10
  public currentPage: number = 1
  public currentMeterId: number = 1
  public lastPageSize: number = 1;
  @Input() buIds : any = [];
  @Input() utIds : any = [];
  @Input() amiIds : any = [];
  @Input() search : SearchAutocomplete = new SearchAutocomplete();

  public commStatus: number = 0
  public commStatusOpened: boolean = false
  public commStatusClicked(open: number) {
    if (open == 0)
      this.commStatusOpened = false
    else {
      if (this.commStatusOpened) {
        this.commStatusOpened = false
      }
      else {
        this.commStatusOpened = true
      }

    }
  }

  public commStatusSelected(value: number) {
    this.currentPage = 1
    this.commStatus = value
    this.getData()
  }
  copyToClipboard(text: any) {
    const textToCopy = text;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    this.toastr.success('Copied to clipboard')

  }
  getData(){
    let now = new Date();
    let thisMonth = new Date();
    thisMonth.setDate(1);
    now.setDate(1);
    let lastMonth = now;
    lastMonth.setMonth(now.getMonth() - 1);

    let getObj ={
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        Search: this.search.name,
        BuIds : this.buIds,
        AmiIds: this.amiIds,
        UtIds : this.utIds,
        CommStatus : this.commStatus,
        From: lastMonth,
        To: thisMonth,
      }
    }
    this.GetDtMeters(getObj)
  }
  GetDtMeters(fetchObj : any){
    this.meterService.GetDtMeters(fetchObj).subscribe({
      next: (response) => {
        if(response && response.status == 200){
          this.data = response.data?.data
          if (this.currentPage == 1){
            this.count = response.data.count;
            this.lastPageSize = Math.ceil(this.count / this.pageSize);
          }
        }
      },
      error: (err) => {
        this.toastr.error('An error occurred.');
      },
    });
  }
  pageChange(value: number) {
    this.currentPage = value
    this.getData()
  }
}
