<div>
    <div class="slider" [ngClass]="{'shadow' : showShadow === true}" [@slideInOut]="animationState">
        <div class="tab-header">

            <div class="overdue-bills-header">
                <div class="overdue-bills-row">
                    <div class="overdue-bills-page-header">
                        <span>Customer Billing Information</span>
                    </div>

                </div>
                <div class="back-button" >
                    <img alt="Close" src="assets/images-new-design/Close.svg" (click)="changeState(2, overdueBillArears)">
                </div>
            </div>
        </div>
            <div class="tabs-wrapper">
                <div class="tabs-button" [ngClass]="{'selected': tabNumber == 1}" (click)="tabNumber = 1"><span>Customer
                        Details</span>
                </div>
                <div class="tabs-button" [ngClass]="{'selected': tabNumber == 2}" (click)="tabNumber = 2"><span>Service
                        Details</span>
                </div>
            </div>

            <div class="customer-address-header">
                <div class="customer-address-row">
                    <div class="customer-address-page-header">
                        <span>Customer Address</span>
                    </div>
                </div>
            </div>
            <div class="horizontal-line">

            </div>
            <div class="customer-address-row">
                <div class="customer-address-page-label">
                    <span>{{overdueBillArears.customerAddress | hasValue}}</span>
                </div>
            </div>

            <div class="tab-header-info">
                <div class="row-fluid">
                    <div class="header-red">
                        <div class="col-md-7">
                            <div class="tab-field-name">
                                <span class="tab-field-header">
                                    Account Name
                                </span>
                                <span class="tab-field-body" title="{{overdueBillArears.accountName}}">
                                    {{overdueBillArears.accountName}}
                                </span>
                            </div>
                        </div>

                        <div class="vertical-line"></div>

                        <div class="col-md-3">
                            <div class="tab-field-name">
                                <span class="tab-field-header">
                                    Account Number
                                </span>
                                <span class="tab-field-body" title="{{overdueBillArears.accountNumber}}">
                                    {{overdueBillArears.accountNumber}}
                                </span>
                            </div>
                        </div>

                        <div class="vertical-line"></div>

                        <div class="col-md-3">
                            <div class="tab-field-name">
                                <span class="tab-field-header serial-no">
                                    Meter Serial No
                                </span>
                                <span class="tab-field-body" title="{{overdueBillArears.customerType}}">
                                    {{overdueBillArears.customerType}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-body-info" *ngIf="tabNumber==1">
                <table class="overdue-bills-popup-table">
                    <tr>
                        <td>Current Total debt</td>
                        <td>{{overdueBillArears.currentDebtAmount | twoDecimals | hasValue}}</td>
                    </tr>
                    <tr>
                        <td>Initial debt amount</td>
                        <td>{{overdueBillArears.lastPaymentAmount | twoDecimals | hasValue}}</td>
                    </tr>
                    <tr>
                        <td>Last payment date</td>
                        <td>{{overdueBillArears.lastPaymentDate | date: 'dd/MM/yyyy' | hasValue}}</td>
                    </tr>
                </table>
                <table class="overdue-bills-popup-table">
                    <tr>
                        <td>Longitude</td>
                        <td>{{overdueBillArears.longitude | hasValue}}</td>
                    </tr>
                    <tr>
                        <td>Latitude</td>
                        <td>{{overdueBillArears.latitude | hasValue}}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{overdueBillArears.email | hasValue}}</td>
                    </tr>
                </table>
                <table class="overdue-bills-popup-table">
                    <tr>
                        <td>Phone number</td>
                        <td>{{overdueBillArears.phone | hasValue}}</td>
                    </tr>
                    <tr>
                        <td>Flagged on</td>
                        <td>{{overdueBillArears.flaggedDate | date:'dd/MM/yyyy' | hasValue}}</td>
                    </tr>
                </table>
            </div>


            <div class="tab-body-info" *ngIf="tabNumber==2">
                <table class="overdue-bills-popup-table">
                    <tr>
                        <td>DT Name</td>
                        <td>{{overdueBillArears.dtName  | hasValue}}</td>
                    </tr>
                    <tr>
                        <td>Feeder Name</td>
                        <td>{{overdueBillArears.feederName | hasValue}}</td>
                    </tr>
                    <tr>
                        <td>Original Debt Owed</td>
                        <td>{{overdueBillArears.initialDebtAmount | twoDecimals | hasValue}}</td>
                    </tr>
                </table>
                <table class="overdue-bills-popup-table">
                    <tr>
                        <td>{{utName}}</td>
                        <td>{{overdueBillArears.undertaking | hasValue}}</td>
                    </tr>
                    <tr>
                        <td>Tariff</td>
                        <td>{{overdueBillArears.tariff | hasValue}}</td>
                    </tr>
                    <tr>
                        <td>{{buName}}</td>
                        <td>{{overdueBillArears.businessUnit | hasValue}}</td>
                    </tr>
                </table>
                <table class="overdue-bills-popup-table">
                    <tr>
                        <td>Source</td>
                        <td>{{overdueBillArears.debtSource | hasValue}}</td>
                    </tr>
                </table>

        </div>

    </div>
</div>
