import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { MeterBypassService } from 'src/app/services/meter-bypass.service';

@Component({
  selector: 'app-download-button-new',
  templateUrl: './download-button-new.component.html',
  styleUrls: ['./download-button-new.component.scss']
})
export class DownloadButtonNewComponent {

  @Input() onlyDownloadText: string = 'Download Report'
  @Input() isOnlyDownload: boolean = false
  @Input() width: string = '';

  @Output() downloadButtonClickedChange = new EventEmitter();
  @Output() downloadAndSendButtonClickedChange = new EventEmitter();

  public user: any = this.authService.getCurrentUser();
  email:string = this.user.email ?? '';

  public isPopupOpen: boolean = false;

  constructor(public meterBypassService: MeterBypassService, public authService: AuthService, private commonService: CommonService, private toastr: ToastrService) { }

  downloadReport(){
    this.changeState()
    this.downloadButtonClickedChange.emit();

  }

  downloadAndSendReport(){
    this.changeState()
    this.downloadAndSendButtonClickedChange.emit(this.email);
  }

  changeState(){
    if(this.isOnlyDownload){
      this.downloadButtonClickedChange.emit();
    }
    else{
      this.isPopupOpen = !this.isPopupOpen
    }
  }

}

