import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../../environments/environment';
import { SupportFormComponent } from './support-form/support-form.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public email: any;
  public pass: any= null;
  openModal = 0;

  public resetPasswordForm: boolean = false;  
  public eyeImage : any = "assets/images-new-design/eye-closed.svg";
  public inputType: any = "password";
  public isLogClicked: boolean = false; 
 
  private token : any;  
  private url : any;
  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild(SupportFormComponent) popupComponentRef!: SupportFormComponent;
  show:boolean = false;
  constructor(private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private translationService: TranslationService,
    private toastr: ToastrService,
    private route : ActivatedRoute,    
    private _eref: ElementRef,
    private dialogRef: MatDialog) { 
      this.token = this.route.snapshot.queryParams.token;

      if(this.token){
        localStorage.setItem('token',this.token); 
      }
    }

  ngOnInit(): void {
    if(this.authService.isLoggedIn())
      this.router.navigate(['global-landing-page']);
  }
  
  openTab(){
    this.popupComponentRef.changeState(1);
    this.show=true;
  }
  closeTab(){
    this.popupComponentRef.changeState(2);
    this.show=false;
  }
 
  login() {
    this.isLogClicked = true;

    this.authService.login(this.email, this.pass).subscribe(x => { 
      if (x.status === 200 || x.status==='OK'){
        if (environment.production) {
          Sentry.setUser({
            email: this.email,
          });
        }
        localStorage.setItem('token', x.data.token); 
        localStorage.setItem('refreshToken', x.data.refreshToken); 
        localStorage.setItem('translationsBilling', JSON.stringify(x.data.translations)); 
        // localStorage.setItem('rolePermissionAndSystemComponents', x.data.permissions); 
        localStorage.setItem('permissions', x.data.permissions); 
        if(x.data.isPasswordTemp){
          localStorage.setItem('tempPassword', x.data.isPasswordTemp);
        }

        this.translationService.getAll().subscribe(x => {
          if (x.status === 200){
            this.isLogClicked = false;
            localStorage.setItem('translations',JSON.stringify(x.data));
            this.translationService.getByKeyFromCache('UT');

            if (this.authService.isPasswordTemp() && !environment.useOpenIdDict) {
              this.router.navigate(['password-new-user'])
            }
            else{
              this.router.navigate(['global-landing-page']);
            }
          }      
          else 
          {
            this.toastr.error(x.message);  
            this.isLogClicked = false;
          }    
        }, error => {
          this.toastr.error('An error ocurred.');
          this.isLogClicked = false;

        });
      }      
      else 
      {
        this.toastr.error(x.message);      
        this.isLogClicked = false;
      }
    }, _ => {
      this.toastr.error('Server is not avaiable now! Please try again later.');
      this.isLogClicked = false;
    });
    
  }
 
  forgotPaswordRequest(){
    this.isLogClicked = true;

    this.userService.forgotPasswordRequest(this.email).subscribe(x => { 
      if (x.status === 200) {
        setTimeout(() => {  
          this.toastr.success("A password reset email has been sent to " + this.email + ".");
          this.resetPasswordForm = false;
          this.isLogClicked = false;
          this.router.navigate(['login']);
        }, 2000);
    }
    else{
      this.toastr.error(x.message);      
    } 
    }, error => {
      this.toastr.error('An error occurred.');
    });    
  }

  changeEye(){
    if(this.eyeImage === "assets/images-new-design/eye-open.svg") {
      this.eyeImage = "assets/images-new-design/eye-closed.svg";
      this.inputType = "password";
    }
    else {
      this.eyeImage = "assets/images-new-design/eye-open.svg";
      this.inputType = "text"
    }
  }
  
}
