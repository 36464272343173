import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resolution-test',
  templateUrl: './resolution-test.component.html',
  styleUrls: ['./resolution-test.component.scss']
})
export class ResolutionTestComponent implements OnInit {
  scrHeight:number = 0;
  scrWidth:number = 0;
  pixelRatio:number = 0;
  screen:any = {};

  ngOnInit(): void {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.pixelRatio = window.devicePixelRatio;
    this.screen = window.screen;
  }

}
