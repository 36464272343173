import { Component, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/app/config/config';
import { NotificationAdora } from 'src/app/models/notification.model';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationHubService } from 'src/app/services/notification-hub.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationStorage } from 'src/share-storage/notification-storage';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() show: boolean = false;

  @Output() unreadCount : number = 0;

  public notifications:NotificationAdora[] = this.notificationStorage.notifications;

  currentPage = this.notificationStorage.totalPages;
  itemsPerPage=5;
  notificationCount: any
  infiniteScrollStatus : boolean = false;

  private pathHub = this.config.setting['pathApi'].slice(0, -5);

  token: string | null = null

  open: number = 1

   loadData= ()=>{
    this.getItems(this.currentPage,this.itemsPerPage);
    this.notificationStorage.totalPages -= 1;
    }

   onScroll= ()=>{
    this.currentPage++;
    if(this.currentPage >= this.notificationStorage.totalPages){
      this.getItems(this.currentPage,this.itemsPerPage)
    }
   }

   constructor(
    private notificationService:NotificationService, 
    private authService: AuthService,
    private config: AppConfig,
    private notificationStorage: NotificationStorage,
    private notificationHub: NotificationHubService,
    private toastr: ToastrService) {
   }

   getItems(page: any,itemsPerPage : any):any{
   let obj = {
        PageInfo: {
        Page: page,
        PageSize: itemsPerPage,
        IsMonthlySelected: false
        },
        UserGuid: this.authService.getCurrentUser()?.userGuid
    }
    this.notificationService.getAll(obj).subscribe(response =>{
          if(response.status == 200){
              console.log(this.notificationCount)
              this.notificationCount = response.data.count;
              console.log(this.notificationCount)
              this.notificationStorage.totalPages++;
              response.data.data.forEach((element: any) => {
                this.notificationStorage.notifications.push(element);
              });
              if(page >= 2){
                this.readNotification()
              }
          }
          else if (response.status == 204){
            this.infiniteScrollStatus = true;
          }
          // else {
          //   this.toastr.error(response.message);
          // }
      })
  }

  getUnreadCount(){
    this.notificationService.getUnreadCount(this.authService.getCurrentUser()?.userGuid).subscribe(response =>{
      this.unreadCount = response.data;
    })
  }

  ngOnInit(): void {
    // let guid = this.authService.getCurrentUser()?.userGuid;
    // if(guid != undefined)
    // {
    //   if(this.notificationStorage.firstLoad){
    //     this.loadData();
    //     this.getUnreadCount();
    //     this.notificationStorage.firstLoad = false;
    //   }
  
    //   this.notificationHub.init((count : any) => this.unreadCount = count);
    // }
  }

  ngOnChanges(){
    // if(this.notificationCount == 0){
    //   this.show = false;
    // }

    // if(this.show && this.unreadCount != 0) {
    //   this.readNotification();
    // }
  }

  readNotification() {
    let endIndex = this.notificationStorage.notifications.length
    let notificationIds : any[] = []
    for(let i = 0; i <= endIndex - 1; i++){
        if(this.notificationStorage.notifications[i].read == null) {
          notificationIds.push(this.notificationStorage.notifications[i].id);
          this.notificationStorage.notifications[i].read = new Date();
        }
    }
    let obj = {
      Read: new Date(),
      NotificationIds: notificationIds
    }
    this.notificationService.readNotifications(obj).subscribe(response => {
      this.getUnreadCount()
    })
  }
}
