    <div class="date-inner-container" id="date-inner" (click)="filterClick()" >
        <div class="left-date">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.3077 20C1.80257 20 1.375 19.825 1.025 19.475C0.675 19.125 0.5 18.6974 0.5 18.1923V4.80772C0.5 4.30259 0.675 3.87502 1.025 3.52502C1.375 3.17502 1.80257 3.00002 2.3077 3.00002H3.69233V1.65384C3.69233 1.43463 3.76573 1.25162 3.91253 1.10482C4.05931 0.958035 4.24232 0.884644 4.46155 0.884644C4.68077 0.884644 4.86378 0.958035 5.01058 1.10482C5.15736 1.25162 5.23075 1.43463 5.23075 1.65384V3.00002H12.8077V1.63462C12.8077 1.4218 12.8794 1.2436 13.023 1.10002C13.1666 0.956435 13.3448 0.884644 13.5576 0.884644C13.7704 0.884644 13.9486 0.956435 14.0922 1.10002C14.2358 1.2436 14.3076 1.4218 14.3076 1.63462V3.00002H15.6923C16.1974 3.00002 16.625 3.17502 16.975 3.52502C17.325 3.87502 17.5 4.30259 17.5 4.80772V18.1923C17.5 18.6974 17.325 19.125 16.975 19.475C16.625 19.825 16.1974 20 15.6923 20H2.3077ZM2.3077 18.5H15.6923C15.7692 18.5 15.8397 18.4679 15.9038 18.4038C15.9679 18.3397 16 18.2692 16 18.1923V8.80772H1.99997V18.1923C1.99997 18.2692 2.03202 18.3397 2.09612 18.4038C2.16024 18.4679 2.23077 18.5 2.3077 18.5ZM1.99997 7.30774H16V4.80772C16 4.73079 15.9679 4.66026 15.9038 4.59614C15.8397 4.53204 15.7692 4.49999 15.6923 4.49999H2.3077C2.23077 4.49999 2.16024 4.53204 2.09612 4.59614C2.03202 4.66026 1.99997 4.73079 1.99997 4.80772V7.30774Z" fill="#2E3336"/>
            </svg>   
            <span class="text">{{name}}</span>
        </div>
        <div class="right-date">
            <!-- <span>                 -->
                <input 
                class="input-datepicker"
                id="date" 
                [(ngModel)]="date" 
                (dateSelect)="changeDate($event); d.close()"
                name="dp"
                [markDisabled]="isDisabled"
                ngbDatepicker
                [placeholder]="placeholder"
                #d="ngbDatepicker"
                [placement]="placementPosition"
                autocomplete="off"
                navigation="navigation"
                (click)="d.toggle()"
                (mousedown)="handleMouseDown($event)"

                
            />
            <svg (click)="d.toggle()" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99497 6.24996C4.89497 6.24996 4.80122 6.2326 4.71372 6.19788C4.62622 6.16315 4.54775 6.11107 4.4783 6.04163L0.527013 2.09033C0.369541 1.93286 0.294277 1.75343 0.301221 1.55204C0.308166 1.35065 0.388027 1.17357 0.540805 1.02079C0.693582 0.868015 0.870666 0.791626 1.07205 0.791626C1.27344 0.791626 1.45053 0.868015 1.6033 1.02079L4.99914 4.43746L8.4158 1.02079C8.56858 0.868015 8.74567 0.795098 8.94705 0.802043C9.14844 0.808987 9.32553 0.888848 9.4783 1.04163C9.63108 1.1944 9.70747 1.37149 9.70747 1.57288C9.70747 1.77426 9.62874 1.95331 9.47126 2.11L5.51997 6.04163C5.44497 6.11107 5.36372 6.16315 5.27622 6.19788C5.18872 6.2326 5.09497 6.24996 4.99497 6.24996Z" fill="#2E3336"/>
            </svg>  
        </div>
    </div>

<!-- </div> -->



<ng-template #c let-dp>
	<div class="p-2">
		<div class="btn-group d-flex justify-content-end mb-2" role="group">
		</div>
		<div class="custom-month-grid">
			<div *ngFor="let month of dp.state.months">
				<div class="text-primary p-1 fw-bold">{{ dp.i18n.getMonthShortName(month.month) }} {{ month.year }}</div>
				<ngb-datepicker-month class="border rounded" [month]="month"></ngb-datepicker-month>
			</div>
		</div>
	</div>
</ng-template>
