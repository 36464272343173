import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Womteam } from 'src/app/models/womteam.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { TranslationService } from 'src/app/services/translation.service';
import { WomteamService } from 'src/app/services/womteam.service';
import { CreateTeamManagementComponent } from '../create-team-management/create-team-management.component';
import { TeamDetailsComponent } from '../team-details/team-details.component';

@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
  styleUrls: ['./team-management.component.scss']
})
export class TeamManagementComponent implements OnInit, OnChanges {
   @Input() womTeamData: any[] = [];

  womTeamEngagedData: any[] = [];
  menuTab: number = 1;

  allTeams: Womteam[] = [];
  public selectedWomTeam: any[] = [];
  public tabMenu: number = 1;
  public isPopupOpen: boolean = false

  @Output() dataEmitter = new EventEmitter<number>();

  currentPage: number = 1;
  pageSize: number = 10;
  lastPageSize: number = 1;
  count: number = 0;
  engagedCount: number = 0;
  @Output() parentComp = new EventEmitter();

  engaggedLastPageSize: number = 1;
  isFirstBlockVisible = false;
  isSecondBlockVisible = false;
  isThirdBlockVisible = false;
  public masterSelected: boolean = false;
  public checkedList: any = []


  teamsCount = 0;
  unengagedTeamsCount = 0;
  engagedTeamsCount = 0;
  activeTab: string = 'Agents';

  ids: any[] = [];
  checkedIds: any[] = [];
  email: string = '';
  @ViewChild(CreateTeamManagementComponent) popupComponentRef!: CreateTeamManagementComponent;
  @ViewChild(TeamDetailsComponent) detailsPopupComponentRef!: TeamDetailsComponent;

  public selectedTeam: string = "";
  public buId: number = 0;
  public searchText: string = "";

  public menu: any = 'Agents'


  //multi filter
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  @Input() businessUnits: any;
  public allFilters: any = [];
  public filterApplied: boolean = false;
  public showFilter: any = false;
  public dateFrom: any = '';
  public dateTo: any = '';
  public buIds: any = [];


  constructor(private womTeamService: WomteamService,
    private toastr: ToastrService,
    public businessUnitService: BusinessUnitService,
    public translationService: TranslationService,
    private router: Router) { }

  ngOnInit(): void {
     this.setMainDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isAlreadyChecked();
  }

  checkFilters() {
    if (this.buId == null) {
      if (this.businessUnits) {
        this.allFilters.push(this.businessUnits)
        this.filterApplied = true
      }
    }
  }

  openDetails(id:number){
    this.router.navigate(['/field-operations/team-details',id]);
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2, 0, 0);
    if (dte.getMonth() == 0) {
      this.dateFrom = { year: dte.getFullYear() - 1, month: 12, day: dte.getDate() };
    }
    else {
      this.dateFrom = { year: dte.getFullYear(), month: dte.getMonth(), day: dte.getDate() };
    }

    this.dateTo = { year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate() };
  }

  checkUncheckAll() {
    for (let item of this.womTeamData) {
      item.checked = this.masterSelected;
      this.checkForDownload(item.id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.womTeamData.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.womTeamData.every(function(item:any) {
      return item.checked == true;
    })
  }

  deleteTeam() {
    let obj = {
      Ids: this.checkedList
    };
    this.womTeamService.deleteTeam(obj).subscribe(response => {
      this.parentComp.emit()
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  isCheckedGroup(itemSettings: any) {
    let checked = true

    itemSettings.ids.forEach((element: any) => {
      if (!this.ids.includes(element.id)) {
        checked = false
      }
    });

    return checked
  }

  public filterByTeamSelected(team: any) {
    if (team === 'All Teams') {
      this.selectedTeam = ''
    } else
      this.selectedTeam = team
    this.parentComp.emit()
  }

  mainTabChange(value: number) {
    if (value === 1)
      this.activeTab = 'Agents';
    else
      this.activeTab = 'Teams';
  }

  onStatusChange(value: number) {
    if (value === 1) {
      this.currentPage = 1;
      this.menuTab = 1;
      this.masterSelected = false;
      this.checkedList = []
    } else if (value === 2) {
      this.currentPage = 1;
      this.menuTab = 2;
      this.masterSelected = false;
      this.checkedList = []
    }
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }

  getBusForSelect() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data };
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  inputChanged(searchText: string) {
    this.searchText = searchText
    this.parentComp.emit()
  }

  setFilters(data:any){
    for(const item of data)
    {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);
      const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
      if (item.name === this.businessUnits.name) {
        this.buIds = filteredValues
      }
    }
    this.parentComp.emit()
  }
}
