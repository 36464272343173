import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MapInfoWindow } from '@angular/google-maps';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { transformToString } from 'src/app/helpers/dateUtils';
import { MapFilterDataOut } from 'src/app/models/mapFilterDataOut.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommonService } from 'src/app/services/common-service.service';
import { DtService } from 'src/app/services/dt.service';
import { MapService } from 'src/app/services/map.service';
import { SubstationService } from 'src/app/services/substation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { environment } from 'src/environments/environment';
import { styles as mapStyle } from './model/styles';

@Component({
  selector: 'adora-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  @ViewChild('map', { static: true }) mapElement!: google.maps.Map;
  @ViewChild('markerModal') markerModal!: ElementRef;
  @ViewChild('markerDialog') markerDialog!: ElementRef;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow
  ts_icon: any = '/assets/map-new/ic_ts.png';
  ss_icon: any = "/assets/map-new/ic_ss.png";
  bu_icon = "/assets/map-new/ic_bu.png";
  ut_icon = "/assets/map-new/ic_ut.png";
  dt_icon = "/assets/map-new/ic_dt.png";
  ac_icon = "/assets/map-new/ic_acc.png"
  ac_act_icon = "/assets/map-new/ic_acc_act.png"
  ac_fin_icon = "/assets/map-new/ic_acc_fin.png"
  ac_dis_icon = "/assets/map-new/ic_acc_dis.png"
  ac_ina_icon = "/assets/map-new/ic_acc_ina.png"
  ac_unm_icon = "/assets/map-new/ic_acc_unm.png"



  content: any = '';

  @Input() lat = environment.mapCenterLat;
  @Input() lng = environment.mapCenterLong;
  @Input() zoom = environment.mapZoom;
  center: any;
  @Input() markers: any[] = [];
  markersTransformed: any[] = [];
  @Input() xOffsetModal: number = 0;
  @Input() yOffsetModal: number = 0;
  @Input() hasDialog: boolean = false;
  @Input() clickable: boolean = false;
  @Input() searchValue:string="";
  @Input() nmDashboard:boolean = false;
  @Input() organizationTab:boolean = false;
  @Input() customersMap: boolean = false;

  modalType: string = '';
  modalTitle: string = '';
  modalConsumption: number = 0;
  modalConsumptionPercentage: number = 0;
  modalData:any[]=[];
  showModal:boolean=false;
  //zoomTriggered:boolean = false;
  zoomTimeTriggered:number=0;
  zoomTimeDelay:number=500;
  zoomTimeout:any;

  energyReceivedPreviousMonth?: number = 0;
  losses?: number = 0;
  lossesMVH?: number = 0;

  filterByDateClicked: boolean = false;
  public dateFrom: any = '';
  public dateTo: any = '';
  clickObservable: Observable<Event> = fromEvent(document, 'click');

  northEastLat: number = 0;
  northEastLng: number = 0;
  southWestLat: number = 0;
  southWestLng: number = 0;
  mapData: MapFilterDataOut[] = [];




  private ngUnsubscribe = new Subject<void>();

  selectedMarker: any = {};

  public ssData: any = {};
  public dtData: any = {};
  public buData: any = {};
  public utData: any = {};

  shouldCloseDialog: boolean = true;
  public monthBefore: any = '';

  //ICON CLICKS
  @Output() tsClick = new EventEmitter();
  @Output() ssClick = new EventEmitter();
  @Output() buClick = new EventEmitter();
  @Output() utClick = new EventEmitter();
  @Output() dtClick = new EventEmitter();



  ngOnChanges(changes: SimpleChanges) {
    this.closeDialog();

    if(changes?.markers && this.markers.length > 0){
      //this.recenterForNewMarkers();
      this.markers.forEach(function (value) {
        if(!value.icon?.scaledSize){
          value.icon = {
            url: value.icon,
            scaledSize:{
              width: 50 / window.devicePixelRatio,
              height: 50 / window.devicePixelRatio,
              widthUnit: 'px',
              heightUnit: 'px'
          }}
        }
      });
    }
  }



  mapProperties = {
    center: new google.maps.LatLng(this.lat, this.lng),
    // zoom: this.zoom,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: mapStyle,
    disableDefaultUI: true
  };

  markerProperties: google.maps.MarkerOptions = {
    //zIndex: 100
  };


  constructor(private commonService: CommonService, private ssService: SubstationService,
              private dtService: DtService, private toastr: ToastrService, private buService: BusinessUnitService,
              private utService: UndertakingService, private mapService:MapService) {}

  ngOnInit(): void {
    this.center = new google.maps.LatLng(this.lat, this.lng);
    this.subscribeToClickEvent();
    this.setDate();

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  private setDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    if(dte.getMonth() == 0)
    {
      this.dateFrom = {year: dte.getFullYear() - 1, month: 12, day: 1};
    }
    else {
      this.dateFrom = {year: dte.getFullYear(), month: dte.getMonth(), day: 1};
    }
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
    this.monthBefore = new Date(dte.getFullYear(), dte.getMonth() - 1, 1);
  }

  recenterForNewMarkers(){

    if(this.markers.length == 1){
      if(this.nmDashboard){
        this.center = new google.maps.LatLng(this.markers[0].latitude, this.markers[0].longitude);
      }
      else{
        this.center = new google.maps.LatLng(this.markers[0].position.lat, this.markers[0].position.lng);
      }

    }

    if(this.markers.length <= 1)
      return;

    let bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < this.markers.length; i++) {
      //extend the bounds to include each marker's position
      if(this.nmDashboard){
        bounds.extend(new google.maps.LatLng(this.markers[i].latitude, this.markers[i].longitude));

      }
      else{
        bounds.extend(new google.maps.LatLng(this.markers[i].position.lat, this.markers[i].position.lng));
      }
    }

    //now fit the map to the newly inclusive bounds
    if(this.mapElement)
      this.mapElement.fitBounds(bounds);
  }

  markerHover(marker: any, data: any, event: any){
    this.modalTitle = data.title;
    this.modalConsumption = data.consumption;
    this.modalConsumptionPercentage = data.losses;

    this.energyReceivedPreviousMonth = data.consumptionLastMonth;
    this.losses = data.losses;
    this.lossesMVH = data.lossesMVH;

    this.checkModal(data.icon.url)

    let pixelOffset = this.getMarkerPixelPosition(marker, event.domEvent.target.width ?? 58);
    this.markerModal.nativeElement.style.left = (pixelOffset.x - this.markerModal.nativeElement.clientWidth / 2 - this.xOffsetModal) + 'px';
    this.markerModal.nativeElement.style.top = (pixelOffset.y - this.yOffsetModal) + 'px';

    this.markerModal.nativeElement.style.opacity = '1';

  }


  checkModal(icon:string){
    if(icon.includes('ic_ts'))
       this.modalType = 'TS';
    else if(icon.includes('ic_ss'))
       this.modalType = 'SS';
    else if(icon.includes('ic_dt'))
      this.modalType = 'DT';
    else if(icon.includes('ic_bu'))
      this.modalType = 'BU';
    else if(icon.includes('ic_ut'))
      this.modalType = 'UT';
    else if(icon.includes('ic_acc'))
      this.modalType = 'ACC';
 }

  getMarkerPixelPosition(marker: any, offset: any){
    let scale = Math.pow(2, this.mapElement?.getZoom()!);                /// Bilo je bez ||0.
    let tempLat = this.mapElement?.getBounds()?.getNorthEast().lat() ?? 0;
      let tempLng = this.mapElement?.getBounds()?.getSouthWest().lng() ?? 0;
      let nw = new google.maps.LatLng(
        tempLat,
        tempLng
    );
    let worldCoordinateNW = this.mapElement?.getProjection()?.fromLatLngToPoint(nw);
    let worldCoordinate = this.mapElement?.getProjection()?.fromLatLngToPoint(marker.getPosition());
    if(worldCoordinate && worldCoordinateNW){
      let pixelOffset = new google.maps.Point(
        Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
        Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
      );

      pixelOffset.y -= (offset / 4);
      pixelOffset.x += (offset / 3)
      return pixelOffset;
    }

    return new google.maps.Point(0, 0);
  }

  getMarkerLabel(marker:any){
    return {text: marker.count.toString(), color:'white' , fontSize:'8px' }
  }

  getIconData(ic:any){
      const icon ={
        url: ic, // url
        scaledSize: new google.maps.Size(50, 50)

      }
      return icon

  }


  onMarkerClick(marker: any){
    if(!this.clickable)
      return;

    this.shouldCloseDialog = true;

    this.markerDialog.nativeElement.style.transform = 'translate(100%)';
    this.selectedMarker = marker;
    if(marker.icon.url.includes('ic_ts'))
      this.tsClick.emit(marker);
    else if(marker.icon.url.includes('ic_ss')){
      this.getSSInfo();
      this.ssClick.emit(marker);
      this.shouldCloseDialog = false;
      this.markerDialog.nativeElement.style.transform = 'translate(0)';
    }
    else if(marker.icon.url.includes('ic_dt')){
      this.getDtInfo();
      this.dtClick.emit(marker);
      this.shouldCloseDialog = false;
      this.markerDialog.nativeElement.style.transform = 'translate(0)';
    }
    else if(marker.icon.url.includes('ic_bu')){
      this.getBuInfo();
      this.buClick.emit(marker);
      this.shouldCloseDialog = false;
      this.markerDialog.nativeElement.style.transform = 'translate(0)';
    }
    else if(marker.icon.url.includes('ic_ut')){
      this.getUtInfo();
      this.utClick.emit(marker);
      this.shouldCloseDialog = false;
      this.markerDialog.nativeElement.style.transform = 'translate(0)';
    }

    setTimeout(() => {this.shouldCloseDialog = true;}, 1000);
  }

  markerHoverFinish(){
    this.markerModal.nativeElement.style.opacity = '0';
    this.markerModal.nativeElement.style.top = '0';
  }

  closeDialog(){
    // this.markerDialog.nativeElement.style.opacity = '0';
    if(this.markerDialog && this.shouldCloseDialog)
      this.markerDialog.nativeElement.style.transform = 'translate(100%)';

    this.shouldCloseDialog = true;
  }

  getPosition(longitude:number, latitude:number){

    return {lat: latitude, lng:longitude }
  }

  private subscribeToClickEvent() {
    this.clickObservable
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((e: any) => {
      this.filterByDateClicked = false;
    })
  }

  download() {
    const obj = {
      id: this.selectedMarker.id,
      from: transformToString(this.dateFrom),
      to: transformToString(this.dateTo)
    }

    if(this.selectedMarker.icon.url.includes('ic_ss')){
      this.ssService.getConsumptionReport(obj).subscribe(response => {
        window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
      }, _ => this.toastr.error('An error occured'))
    }
    else if (this.selectedMarker.url.icon.includes('ic_dt')){
      this.dtService.getConsumptionReport(obj).subscribe(response => {
        window.location.href = response.result.data;
      }, _ => this.toastr.error('An error occured'))
    }
    else if (this.selectedMarker.url.icon.includes('ic_bu')){
      this.buService.getConsumptionReport(obj).subscribe(response => {
        window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
      }, _ => this.toastr.error('An error occured'))
    }
    else if (this.selectedMarker.url.icon.includes('ic_ut')){
      this.utService.getConsumptionReport(obj).subscribe(response => {
        window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
      }, _ => this.toastr.error('An error occured'))
    }
  }

  private getSSInfo(): void {
    this.ssService.getInfoForMapDialog(this.selectedMarker.id).subscribe(response => {
      this.ssData = response.data;
    });
  }

  private getDtInfo(): void {
    this.dtService.getInfoForMapDialog(this.selectedMarker.id).subscribe(response => {
      this.dtData = response.data;
    }, _ => this.toastr.error('An error occured'));
  }

  private getBuInfo(): void {
    this.buService.getInfoForMapDialog(this.selectedMarker.id).subscribe(response => {
      this.buData = response.data;
    }, _ => this.toastr.error('An error occured'));
  }

  private getUtInfo(): void {
    this.utService.getInfoForMapDialog(this.selectedMarker.id).subscribe(response => {
      this.utData = response.data;
    }, _ => this.toastr.error('An error occured'));
  }
}
