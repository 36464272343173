import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Subunit } from 'src/app/models/subunit.model';
import { AuthService } from 'src/app/services/auth.service';
import { SubunitService } from 'src/app/services/subunit.service';
import { UnitService } from 'src/app/services/unit.service';

@Component({
  selector: 'app-create-subunit',
  templateUrl: './create-subunit.component.html',
  styleUrl: '../.././create-crud.scss',  
  host: {
    '(document:click)': 'handleClick($event)',
  }
})
export class CreateSubunitComponent {

  public selectedEntity: Subunit = new Subunit()
  showShadow = false;
  
  @Output() saveEvent = new EventEmitter();

  @ViewChild('f') f: any;

  public units: DictionaryItem[] = [];

  @Input() animationState = 'out';
  @ViewChild(CreateSubunitComponent) popupComponentRef!: CreateSubunitComponent;
  
  constructor(private toastr: ToastrService,
    private ref: ElementRef,
    private authService: AuthService,
    private subunitService: SubunitService,
    private unitService: UnitService) { }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  closeTab() {
    this.popupComponentRef.changeState(2);
  }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.popup-wrapper');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }

  changeState(number: Number, data?: any) {
    if (data)
      this.selectedEntity = data;
    if (number === 1) {
      this.toggleShowDiv('open');
      this.showShadow = true;
      console.log(this.showShadow)
    }
    if (number === 2) {
      this.toggleShowDiv('close');
      this.showShadow = false;
    }
  }

  getAllUnitsForSelect() {
    this.unitService.getAllForSelect(0).subscribe(response => {
      this.units = response.data
    }, _ => {
      this.toastr.error('An error occured');
    })
  }
  
  save() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate)) {
      this.toastr.error("You don't have permission.");
      return;
    }

    if (!this.selectedEntity.name || this.selectedEntity.unitId == 0) {
      return
    }

    this.subunitService.save(this.selectedEntity).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.changeState(2);
        this.saveEvent.emit();
        this.resetSelectedEntity();
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedEntity() {
    this.selectedEntity = new Subunit();

    if (this.f)
      this.f.submitted = false;
  }

}
