import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { MeterModel } from 'src/app/models/meterModel.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { CrudService } from 'src/app/services/crudService.service';
import { MeterModelService } from 'src/app/services/meterModel.service';

@Component({
  selector: 'app-meter-model-tab',
  templateUrl: './meter-model-tab.component.html',
  styleUrl: './meter-model-tab.component.scss'
})
export class MeterModelTabComponent {

  public selectedMeterModel: MeterModel = new MeterModel()
  public meterModels: MeterModel[] = []

  @Output() deleteEvent = new EventEmitter()
  @Output() editEvent = new EventEmitter()
  @Output() amisFetchedEvent = new EventEmitter()

  public amis: DictionaryItem[] = []

  public currentPage: number = 1
  public pageSize: number = 10
  public count: number = 0
  public lastPageSize: number = 1

  @ViewChild("form") form: any

  public PermissionsEnum = PermissionsEnum

  file: any = null

  public searchText: string = ''
  private ngUnsubscribe = new Subject<void>()
  public filteredMeterModels: SearchAutocomplete[] = []

  public checkedList: number[] = []
  public masterSelected: boolean = false

  constructor(private meterModelService: MeterModelService,
    private amiService: AmiService,
    private crudService: CrudService,
    public authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.reloadTable()
    this.subscribeToSearch()
  }

  public subscribeToSearch() {
    this.crudService.globalSearch$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(searchValue => {
      if (searchValue.clicked && searchValue.componentName === ComponentNamesNew.MeterModelTabComponent) {
        this.searchText = searchValue.text
        this.currentPage = 1
        this.checkedList = []
        this.reloadTable()
        return
      }
      this.searchText = ''
    })
  }

  pageChange(value: any) {
    this.currentPage = value
    this.reloadTable()
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchContent: this.searchText
      }
    }

    if (!obj.filterParams.searchContent) {
      this.meterModelService.getAllForSearch().subscribe(response => {
          this.filteredMeterModels = []
          response.data.forEach((x: MeterModel) =>
          {
            let item = new SearchAutocomplete()
            item.value = x.id
            item.name = x.name
            this.filteredMeterModels.push(item)
          })
      }, error => {
        this.toastr.error('An error occurred.')
      })
    }

    this.meterModelService.getAll(obj).subscribe(response => {
      this.meterModels = response.data.data
      if (obj.pageInfo.page == 1) {
        this.count = response.data.count
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      this.isAlreadyChecked()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  isAlreadyChecked() {
    this.meterModels.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if (index !== -1) {
        x.checked = true
      }
    })
    this.isMasterChecked()
  }

  isMasterChecked() {
    this.masterSelected = this.meterModels.every(function(item: any) {
      return item.checked == true
    })
  }

  checkUncheckAll() {
    for (let i = 0; i < this.meterModels.length; i++) {
      this.meterModels[i].checked = this.masterSelected
      this.checkForDownload(this.meterModels[i].id, true)
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if (!isMasterClicked)
      this.isMasterChecked()
    let index = this.checkedList.indexOf(id)
    if (index !== -1) {
      if (!isMasterClicked)
        this.checkedList.splice(index, 1)
      else if (isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1)
    }
    else {
      this.checkedList.push(id)
    }
  }

  public uploadFile(file: any, isUpdate: any) {
    const formData = new FormData()
    formData.append("uploadFile", file)
    formData.append("update", isUpdate)

    this.meterModelService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message)
          this.reloadTable()
      } else {
        this.toastr.error(response.message)
        if (response.data && response.data.length > 0)
          window.location.href = response.data
      }
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  openTabDelete(id: number) {
    this.meterModelService.get(id).subscribe(response => {
     if (response.status === 200) {
       this.selectedMeterModel = response.data
       this.deleteEvent.emit()
     }
     else
       this.toastr.error(response.message)
   }, error => {
     this.toastr.error('An error occurred.')
   })

   if (this.selectedMeterModel) {
    this.selectedMeterModel.id = id
   }
 }

 onDeleteConfirmed() {
    this.meterModelService.delete(this.selectedMeterModel ? this.selectedMeterModel.id : 0).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message)
        this.currentPage = 1
        this.reloadTable()
      }
      else
        this.toastr.error(response.message)
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  select(id: number) {
    this.meterModelService.get(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedMeterModel = response.data
        this.editEvent.emit(this.selectedMeterModel)
      } else
        this.toastr.error(response.message)
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getTemplate() {
    this.meterModelService.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data
      } else {
        this.toastr.error(response.message)
      }
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  downloadItems() {
    let obj = {
      ids: this.checkedList,
      activate: false
    }

    this.meterModelService.download(obj).subscribe(response => {
      if (response.status === 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.")
        }
        else {
          window.location.href = response.data
          this.toastr.success("Successfully downloaded Meter Models.")
        }
      }
      else
        this.toastr.error(response.message)
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
