import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { Account } from 'src/app/models/account.model';
import { FilterReads } from 'src/app/models/FilterReads';
import { ChannelService } from 'src/app/services/channels.service';
import { FilterReadsService } from 'src/app/services/filter-reads.service';
import { TimePeriod } from '../../consumption/model/model';

@Component({
  selector: 'app-account-reads-new',
  templateUrl: './account-reads.component.html',
  styleUrls: ['./account-reads.component.scss']
})
export class AccountReadsNewComponent implements OnInit {

  @Output() dateToChange = new EventEmitter();
  @Input() dateTo: any = ''
  @Output() dateFromChange = new EventEmitter();
  @Input() dateFrom: any = ''
  @Output() filterChange = new EventEmitter();


  @ViewChild('menu') menu: ElementRef | any;
  @ViewChild('dropDown') dropDown: ElementRef | any;


  @Output() clickOutside = new EventEmitter<MouseEvent>();

  @Input() timePeriod: TimePeriod = TimePeriod.Monthly;
  //filter clicked
  public filter: number = 0;
  public filterByDateClicked: boolean = false

  public currentPage: number = 1;
  public pageSize: number = 10;

  @Input() accountId: any;
  public accounts: Account[] = [];

  filterReads: FilterReads = new FilterReads();

  filterReadsData: FilterReads[] = [];
  count: number = 0;
  dateFromFull: any = ''
  dateFromTo: any = ''

  channelSelected: any;
  channels: any = [];

  cid: number = 0;

  constructor(private toastr: ToastrService,
    private channelService: ChannelService,
    private renderer: Renderer2,
    private filterService: FilterReadsService) {

    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.menu.nativeElement && e.target !== this.dropDown.nativeElement) {
        this.closeAllFilters();
      }
    });
  }
  isMenuOpen: boolean = false;

  ngOnInit(): void {
    this.channelSelected = { value: 1, name: "Total forward energy" }
    this.cid = 1;
    this.initialState();
    this.fetchData();
    this.loadChannels();
  }

  private getElementAncestorsIds(element: any) {
    let ancestorsIds: any[] = [];

    if (element) {
      const childrenDepth = 1;
      for (let i = 0; i < childrenDepth; i++) {
        if (element.parentElement) {
          ancestorsIds.push(element.parentElement.id);
          element = element.parentElement;
        }
      }
    }
    return ancestorsIds;
  }

  fetchData() {
    this.reloadTable();
  }

  filterByDateClick(event: any) {
    this.filterByDateClicked = event;
    this.filter = 0;
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  loadChannels() {
    this.channelService.getAll().subscribe(response => {
      this.channels = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.isMenuOpen = !this.isMenuOpen;
  }

  DateClickEvent(e: any) {
    const filterBtnElementId = 'date-inner';

    const ancestors = this.getElementAncestorsIds(e.srcElement);

    if ((e.srcElement?.id === filterBtnElementId || ancestors.includes(filterBtnElementId)) && !this.filterByDateClicked) {
      this.filterByDateClicked = true;
      return;
    }
  }
  public dateChanged() {
    this.dateToChange.emit(this.dateTo);
    this.dateFromChange.emit(this.dateFrom);
    this.filterChange.emit(1);
    this.reloadTable();
  }

  filterClicked(value: number) {
    if (this.filter === value) {
      this.filter = 0
    }
    else {
      this.filter = value;
    }
    this.filterByDateClicked = false
  }

  public closeAllFilters() {
    this.filter = 0;
    this.filterByDateClicked = false;
  }

  SelectFilter(item: any) {
    this.channelSelected = { value: item.id, name: item.name }
    this.cid = item.id;
    this.reloadTable();
  }
  isMonthly() {
    return this.timePeriod == TimePeriod.Monthly;
  }
  reloadTable(page: any = null) {

    if (page) {
      this.currentPage = page;
    }
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      from: transformToString(this.dateFrom),
      to: transformToString(this.dateTo),
      cid: this.cid,
      accountId: this.accountId
    };

    this.filterService.GetForReads(obj).subscribe(response => {
      this.filterReadsData = response.data.data;
      this.count = response.data.count;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  initialState() {
    this.dateFromFull = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    this.dateFromTo = new Date(Date.now());
    this.dateTo = { year: this.dateFromTo.getFullYear(), month: this.dateFromTo.getMonth() + 1, day: this.dateFromTo.getDate() }
    this.dateFrom = { year: this.dateFromFull.getFullYear(), month: this.dateFromFull.getMonth() + 1, day: this.dateFromFull.getDate() }
  }

}

