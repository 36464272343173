import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import{ takeUntil } from 'rxjs/operators'
import { Ami } from 'src/app/models/ami.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { TranslationService } from 'src/app/services/translation.service';
import { Location } from '@angular/common';
import { CreateAmiComponent } from './create-ami/create-ami.component';
import { CrudDeleteModalComponent } from '../crud-delete-modal/crud-delete-modal.component';
import { CrudService } from 'src/app/services/crudService.service';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';

@Component({
  selector: 'app-ami-tab',
  templateUrl: './ami-tab.component.html',
  styleUrls: ['./ami-tab.component.scss']
})
export class AmiTabComponent implements OnInit {
  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal!: ElementRef;
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  public PermissionsEnum = PermissionsEnum;
  public selectedId: number = 0;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public searchText: string = "";
  private ngUnsubscribe = new Subject<void>();
  public searchAmis: SearchAutocomplete[] = [];

  public searchByName: string = '';

  public amis: Ami[] = [];
  public selectedAmi: Ami = new Ami();

  public checkedList: number[] = [];
  public masterSelected: boolean = false;

  @ViewChild(CreateAmiComponent) popupComponentRef!: CreateAmiComponent;

  public fileChosen: any = null;
   file: any = null;

  public amiName :string = this.translationService.getByKeyFromCache('AMI');

  @ViewChild("f") f: any;
  @Output() editTab = new EventEmitter();
  @Output() deleteComp = new EventEmitter();

  constructor(private amiService: AmiService,
    private crudService: CrudService,
    public authService: AuthService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private commonService: CommonService,
    private location: Location) { }

  ngOnInit(): void {
    // this.amiName = this.translationService.getByKeyFromCache('AMI');
    this.reloadTable();
    this.subscribeToSearch();
  }
  back(){
    this.location.back();
  }
  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null){
    if(page)
      this.currentPage = page;

    this.resetSelectedAmi();

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };

    if (!obj.filterParams.SearchContent){
      this.amiService.getAllForSelect().subscribe(response => {
        if (!obj.filterParams.SearchContent){
          this.searchAmis = []
          response.data.forEach((x : Ami) =>
          {
            let item = new SearchAutocomplete();
            item.value = x.id;
            item.name = x.name;
            this.searchAmis.push(item)
          })
        }
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
    this.amiService.getAll(obj).subscribe(response => {
      this.amis = response.data.data;
      if(obj.pageInfo.page == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      this.isAlreadyChecked();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  selectAmi(id: number){
    this.amiService.get(id).subscribe(response => {
      if(response.status === 200){
        this.selectedAmi = response.data;
        this.editTab.emit(this.selectedAmi);
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedAmi(){
    this.selectedAmi = new Ami();

    if(this.f)
      this.f.submitted = false;
  }
  openTabDelete(id:number) {
    this.amiService.get(id).subscribe(response => {
     if(response.status === 200){
       this.selectedAmi = response.data;
       this.deleteComp.emit();
     }
     else
       this.toastr.error(response.message);
   }, error => {
     this.toastr.error('An error occurred.');
   });
   this.selectedId = id;
 }
  onDeleteConfirmed( ){
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_delete))
    {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.amiService.delete(this.selectedId).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.currentPage = 1;
        this.reloadTable();
        this.resetSelectedAmi();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  onFileChosen(event: any){
    this.file = event.target.files[0];
  }

  isFileChosen(){
    if(this.fileChosen)
      return true;

    return false;
  }
  openTab() {
    this.popupComponentRef.changeState(1, this.selectedAmi);
   }

  uploadFile(){
    if(!this.isFileChosen){
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.amiService.uploadFile(formData).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
        if(response.data && response.data.length > 0)
          window.location.href = response.result.data;
          }
        this.reloadTable();
        this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  getTemplate(){
    this.amiService.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch() {
    this.crudService.globalSearch$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(searchValue => {
      if(searchValue.clicked && searchValue.componentName === ComponentNamesNew.AmiComponent) {
        this.searchText = searchValue.text;
        this.currentPage = 1;
        this.checkedList = [];
        this.reloadTable();
        return;
      }
      this.searchText = '';
    });
  }

  checkItem(item: any){
    item.checked = !item?.checked;
  }

  selectAll(){
    if(this.areAnyChecked()){
      this.amis.forEach(x => x.checked = false);
    } else {
      this.amis.forEach(x => x.checked = true);
    }
  }

  areAllCheckedFunc(){
    if(this.amis.length == 0)
      return false;

    return this.amis.every(x => x.checked);
  }

  areAnyChecked(){
    return this.amis.some(x => x.checked);
  }

  downloadItems() {
    let request = {
      ids: this.checkedList
    }
    this.amiService.download(request).subscribe(response => {
      if (response.status === 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.")
        }
        else {
          window.location.href = response.data
          this.toastr.success("Successfully downloaded Meter Manufacturers.");
        }
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  checkUncheckAll() {
    for (let i = 0; i < this.amis.length; i++) {
      this.amis[i].checked = this.masterSelected;
      this.checkForDownload(this.amis[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.amis.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.amis.every(function(item:any) {
      return item.checked == true;
    })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
