import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-field-modal-settings',
  templateUrl: './field-modal-settings.component.html',
  styleUrls: ['./field-modal-settings.component.scss']
})
export class FieldModalSettingsComponent implements OnInit {
  public SelectedItem: any = 1;
  public permissionEnum = PermissionsEnum 

  constructor(
    private toastr: ToastrService, 
    private router: Router,
    public authService: AuthService
  ) { 
    this.router.events
      .subscribe(
        (event) => {
          if(event instanceof ActivationEnd && event.snapshot.children.length == 0) {
            this.SelectedItem = event.snapshot.data['sidebarItem'];
           }
           console.log("sel",this.SelectedItem)
        });
  }

  ngOnInit(): void {
    this.SelectedItem = 1;
  }

  alert(){
    this.toastr.warning("Not yet implemented");
  }

  tabSelected(tabSelected: number){
    this.SelectedItem = tabSelected;
  }
}
