import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { ManualReadService } from 'src/app/services/manual-read.service';

@Component({
  selector: 'app-manual-read',
  templateUrl: './manual-read.component.html',
  styleUrls: ['./manual-read.component.scss']
})
export class ManualReadComponent {
  public PermissionsEnum = PermissionsEnum;

  public fileChosen: any = null;
  public file: any = null;

  constructor(private manualReadService: ManualReadService,
    private toastr: ToastrService,
    public authService: AuthService,
    private commonService: CommonService) { }

  getTemplate(){
    this.manualReadService.getTemplate().subscribe(response => {
      if (response.result.status == 200) {
        if (response.result.data && response.result.data.length > 0)
          window.location.href = response.result.data;
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getTemplateDailyConsupmtion(){
    this.manualReadService.getTemplateDailyConsupmtion().subscribe(response => {
      console.log(response)
      if(response.status === 200){
        if(response.data && response.data.length > 0)
          window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  onFileChosen(event: any){
    this.file = event.target.files[0];
  }
  isFileChosen(){
    if(this.fileChosen)
      return true;

    return false;
  }

  uploadFile(){
    if(!this.authService.isAuthorized(PermissionsEnum.networkmanagement_reports_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    if(!this.isFileChosen){
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.manualReadService.uploadFile(formData).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
        if(response.data && response.data.length > 0)
           window.location.href = response.result.data;
      }
        //this.reloadTable();
        this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  uploadFileDailyConsumption(){
  if(!this.authService.isAuthorized(PermissionsEnum.networkmanagement_reports_createupdate))
  {
    this.toastr.error("You don't have permission.");
    return;
  }

  if(!this.isFileChosen){
    this.toastr.error('You have to choose file for upload.');
    return;
  }

  const formData = new FormData();
  formData.append("uploadFile", this.file);

  this.manualReadService.uploadFileDailyConsumption(formData).subscribe(response => {
    if(response.status === 200){
      this.toastr.success(response.message);
    }else{
      this.toastr.error(response.message);
      if(response.data && response.data.length > 0)
        window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
    }
      //this.reloadTable();
      this.fileChosen = null;
  }, error => {
    this.toastr.error('An error occurred.');
    this.fileChosen = null;
  });
  }
}
