import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ToastrService } from 'ngx-toastr';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { ConcentratorService } from 'src/app/services/concentrator.service';
import { CrudService } from 'src/app/services/crudService.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { ImportDetailsPopupComponent } from '../common-new-design/import-details-popup/import-details-popup.component';
import { SearchAutocompleteComponent } from '../common-new-design/search-autocomplete/search-autocomplete.component';
import { AccountCreateStatusTabComponent } from './account-status-tab/account-create-status-tab/account-create-status-tab.component';
import { AccountStatusTabComponent } from './account-status-tab/account-status-tab.component';
import { AmiTabComponent } from './ami-tab/ami-tab.component';
import { CreateAmiComponent } from './ami-tab/create-ami/create-ami.component';
import { AreaCreateComponent } from './area-tab/area-create/area-create.component';
import { AreaTabComponent } from './area-tab/area-tab.component';
import { BusinessUnitTabComponent } from './business-unit-tab/business-unit-tab.component';
import { CreateBusinessUnitComponent } from './business-unit-tab/create-business-unit/create-business-unit.component';
import { CrudDeleteModalComponent } from './crud-delete-modal/crud-delete-modal.component';
import { DcuCreateComponent } from './dcu-tabs/dcu-create/dcu-create.component';
import { DcuTabsComponent } from './dcu-tabs/dcu-tabs.component';
import { DtCreateComponent } from './dt-tab/dt-create/dt-create.component';
import { DtTabComponent } from './dt-tab/dt-tab.component';
import { Feeder11CreateComponent } from './feeders-tab/feeder11-tab/feeder11-create/feeder11-create.component';
import { Feeder11TabComponent } from './feeders-tab/feeder11-tab/feeder11-tab.component';
import { Feeder33CreateComponent } from './feeders-tab/feeder33-tab/feeder33-create/feeder33-create.component';
import { Feeder33TabComponent } from './feeders-tab/feeder33-tab/feeder33-tab.component';
import { FeedersTabComponent } from './feeders-tab/feeders-tab.component';
import { CreateMeterModelComponent } from './meter-model-tab/create-meter-model/create-meter-model.component';
import { MeterModelTabComponent } from './meter-model-tab/meter-model-tab.component';
import { CreateSubstationComponent } from './substation/create-substation/create-substation.component';
import { SubstationTabComponent } from './substation/substation.component';
import { TransmissionStationCreateComponent } from './transmission-station-tab/transmission-station-create/transmission-station-create.component';
import { TransmissionStationTabComponent } from './transmission-station-tab/transmission-station-tab.component';
import { StaffOperatorTabComponent } from './staff-operator-tab/staff-operator-tab.component';
import { UnitTabComponent } from './unit-tab/unit-tab.component';
import { SubunitTabComponent } from './subunit-tab/subunit-tab.component';
import { DepartmentTabComponent } from './department-tab/department-tab.component';
import { CreateStaffOperatorComponent } from './staff-operator-tab/create-staff-operator/create-staff-operator.component';
import { CreateUnitComponent } from './unit-tab/create-unit/create-unit.component';
import { CreateSubunitComponent } from './subunit-tab/create-subunit/create-subunit.component';
import { CreateDepartmentComponent } from './department-tab/create-department/create-department.component';
import { StaffOperatorService } from 'src/app/services/staff-operator.service';
import { UnitService } from 'src/app/services/unit.service';
import { DepartmentService } from 'src/app/services/department.service';
import { SubunitService } from 'src/app/services/subunit.service';
import { SemanticClassificationFormat } from 'typescript';

@Component({
  selector: 'app-crud-tabs',
  templateUrl: './crud-tabs.component.html',
  styleUrls: ['./crud-tabs.component.scss']
})
export class CrudTabsComponent implements OnInit {
  @ViewChild('amiTabComponent') tabAmiComponent!: AmiTabComponent;
  @ViewChild('tabSubstationComponent') tabSubstationComponent!: SubstationTabComponent;
  @ViewChild('areaTabComponent') areaTabComponent!: AreaTabComponent;
  @ViewChild('tabBusinessUnitComponent') tabBusinessUnitComponent!: BusinessUnitTabComponent;
  @ViewChild('dcuTabsComponent') dcuTabsComponent!: DcuTabsComponent;
  @ViewChild('dtTabComponent') dtTabComponent!: DtTabComponent;
  @ViewChild('tsTabComponent') tsTabComponent!: TransmissionStationTabComponent;
  @ViewChild('feedersTabComponent') feedersTabComponent!: FeedersTabComponent;
  @ViewChild('feeders33TabComponent') feeders33TabComponent!: Feeder33TabComponent;
  @ViewChild('feeders11TabComponent') feeders11TabComponent!: Feeder11TabComponent;
  @ViewChild('accountStatusTabComponent') accountStatusTabComponent!: AccountStatusTabComponent;
  @ViewChild('meterModelTabComponent') tabMeterModelComponent!: MeterModelTabComponent;

  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  @ViewChild(CreateAmiComponent) createAmiComponent!: CreateAmiComponent;
  @ViewChild(CreateSubstationComponent) createSubstationComponent!: CreateSubstationComponent;
  @ViewChild(AreaCreateComponent) areaCreateComponent!: AreaCreateComponent;
  @ViewChild(CreateBusinessUnitComponent) createBusinessUnitComponent!: CreateBusinessUnitComponent;
  @ViewChild(DcuCreateComponent) dcuCreateComponent!: DcuCreateComponent;
  @ViewChild(DtCreateComponent) DtCreateComponent!: DtCreateComponent;
  @ViewChild(TransmissionStationCreateComponent) tsCreateComponent!: TransmissionStationCreateComponent;
  @ViewChild(Feeder33CreateComponent) feeder33CreateComponent!: Feeder33CreateComponent;
  @ViewChild(Feeder11CreateComponent) feeder11CreateComponent!: Feeder11CreateComponent;
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent
  @ViewChild(AccountCreateStatusTabComponent) accStatusCreateComponent!: AccountCreateStatusTabComponent;
  @ViewChild(CreateMeterModelComponent) createMeterModelComponent!: CreateMeterModelComponent;

  public selectedItemDelete: any;
  public selectList: any[] = [];
  public transmissionStationsSelect: any[] = [];
  public amis: any[] = [];
  public feeders33ForSelect: any[] = [];
  public busForSelect: any[] = [];
  public substationsForSelect: any[] = [];
  public utsForSelect: any[] = [];
  options: any[] = [];

  public addNewPermission: boolean = false;
  public uploadNewPermission: boolean = false;
  public isPopupOpen: boolean = false;

  public AmiAdd: any = undefined;
  public SubstationAdd: any = undefined;
  public BusinessUnitAdd: any = undefined;
  public crudSsVisible: boolean = false;
  public crudAmiVisible: boolean = false;
  public crudBUVisible: boolean = false;
  public crudAreaVisible: boolean = false;
  public crudDCUVisible: boolean = false;
  public crudDtVisible: boolean = false;
  public crudTsVisible: boolean = false;
  public crudFeedersVisible: boolean = false;
  public searchPlaceholder: string = "Search"
  public searchAutocomplete: SearchAutocomplete = new SearchAutocomplete()
  public crudAccStatusVisible: boolean = false;
  public crudStaffOperatorVisible: boolean = false;
  public crudMeterModelVisible: boolean = false;
  public crudUnitVisible: boolean = false;
  public crudSubunitVisible: boolean = false;
  public crudDepartmentVisible: boolean = false;

  currentPage = 1;
  pageSize = 10;
  count = 0;
  tabName = 'Transmission Station';
  componentName: ComponentNamesNew = ComponentNamesNew.TransmissionStationComponent;
  @ViewChild(ImportDetailsPopupComponent) importRef!: ImportDetailsPopupComponent;
  @ViewChild('searchInput') searchInput!: ElementRef;

  public fileChosen: any = null;
  public file: any = null;
  showShadow = false;

  public utName: string = this.translationService.getByKeyFromCache('UT');
  public buName: string = this.translationService.getByKeyFromCache('BU');
  searchText: string = '';

  constructor(public authService: AuthService,
    private translationService: TranslationService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public amiService: AmiService,
    public areaService: UndertakingService,
    public toastr: ToastrService,
    public substationService: SubstationService,
    public concentratorService: ConcentratorService,
    public dtService: DtService,
    private buService: BusinessUnitService,
    private location: Location,
    private tsService: TransmissionStationService,
    private feeder33Service: Feeder33Service,
    private feeder11Service: Feeder11Service,
    private staffOperatorService: StaffOperatorService,
    private unitService: UnitService,
    private subunitService: SubunitService,
    private departmentService: DepartmentService,
    private crudService: CrudService) { }

  ngOnInit(): void {
    this.AmiAdd = this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate);
    this.SubstationAdd = this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate);
  }

  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: false
    }
    switch (this.componentName) {
      case ComponentNamesNew.DtComponent:
        this.dtService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
        break;
      case ComponentNamesNew.ConcentratorComponent:
        this.options = this.dcuTabsComponent?.searchConcentrators.filter(x => x.name.toLowerCase().includes(searchText.name.toLowerCase()))
        break;
      case ComponentNamesNew.UndertakingComponent:
        this.options = this.areaTabComponent?.searchUndertakings.filter(x => x.name.toLowerCase().includes(searchText.name.toLowerCase()))
        break;
      case ComponentNamesNew.BusinessUnitComponent:
        this.options = this.tabBusinessUnitComponent?.searchBusinessUnits.filter(x => x.name.toLowerCase().includes(searchText.name.toLowerCase()))
        break;
      case ComponentNamesNew.TransmissionStationComponent:
        this.options = this.tsTabComponent?.searchTS.filter(x => x.name.toLowerCase().includes(searchText.name.toLowerCase()))
        break;
      case ComponentNamesNew.AmiComponent:
        this.options = this.tabAmiComponent?.searchAmis.filter(x => x.name.toLowerCase().includes(searchText.name.toLowerCase()))
        break;
      case ComponentNamesNew.SubstationComponent:
        this.options = this.tabSubstationComponent?.searchSs.filter(x => x.name.toLowerCase().includes(searchText.name.toLowerCase()))
        break;
      case ComponentNamesNew.Feeder11Component:
          this.options = this.feedersTabComponent?.feeders11TabComponent?.searchF11.filter(x => x.name.toLowerCase().includes(searchText.name.toLowerCase())) ?? []
        break;
      case ComponentNamesNew.Feeder33Component:
        this.options = this.feedersTabComponent?.feeders33TabComponent?.searchF33.filter(x => x.name.toLowerCase().includes(searchText.name.toLowerCase()))
        break;
      case ComponentNamesNew.MeterModelTabComponent:
        this.options = this.tabMeterModelComponent?.filteredMeterModels.filter(x => x.name.toLowerCase().includes(searchText.name.toLowerCase()))
        break;
      case ComponentNamesNew.StaffOperatorComponent:
        this.staffOperatorService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })        
          break;
      case ComponentNamesNew.UnitComponent:
        this.unitService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })        
          break;        
      case ComponentNamesNew.SubunitComponent:
        this.subunitService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })        
          break;
      case ComponentNamesNew.DepartmentComponent:
        this.departmentService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })        
          break;
    }
    if (searchText.name.length > 2) {
      this.searchComponentRef.resetOptions()
    }
  }

  inputChanged(searchText: SearchAutocomplete) {
    this.options = [];
    this.crudService.setGlobalSearch({ clicked: true, text: searchText.name, componentName: this.componentName })
  }

  changeState(id?: number) {
    this.isPopupOpen = true;
    this.showShadow = true;

  }
  openTabUpload() {
    this.importRef.changeState();
  }

  changeTab(textLabel: any) {
    this.searchAutocomplete = new SearchAutocomplete()
    this.searchComponentRef.resetOptions()
    this.crudService.cleanGlobalSearch()
    switch (textLabel) {
      case 5:
        this.tabName = 'Meter Manufacturer';
        this.componentName = ComponentNamesNew.AmiComponent
        break;
      case 1:
        this.tabName = 'Substations'
        this.componentName = ComponentNamesNew.SubstationComponent
        break;
      case 6:
        this.tabName = 'Business Units';
        this.componentName = ComponentNamesNew.BusinessUnitComponent
        break;
      case 8:
        this.tabName = 'Undertakings';
        this.componentName = ComponentNamesNew.UndertakingComponent
        break;
      case 3:
        this.tabName = 'Distribution Transformer'
        this.componentName = ComponentNamesNew.DtComponent
        break;
      case 0:
        this.tabName = 'Transmission Station'
        this.componentName = ComponentNamesNew.TransmissionStationComponent
        break;
      case 4:
        this.tabName = 'DCUs'
        this.componentName = ComponentNamesNew.ConcentratorComponent
        break;
      case 2:
        this.tabName = 'Feeders'
        this.componentName = ComponentNamesNew.Feeder33Component
        break;
      case 9:
        this.tabName = 'Meter Model'
        this.componentName = ComponentNamesNew.MeterModelTabComponent
        this.createMeterModelComponent.getAmisForSelect()
        break;
    }
  }
  tabEvent(tab: MatTabChangeEvent) {
    this.changeTab(tab.tab.textLabel);
  }

  addNew(item?: any) {
    if (this.tabName === 'Meter Manufacturer') {
      this.createAmiComponent.resetSelectedAmi();
      this.createAmiComponent.changeState(1, item);
    }
    if (this.tabName === 'Substations') {
      this.createSubstationComponent.resetSelectedSubstation();
      this.createSubstationComponent.changeState(1);
    }
    if (this.tabName === 'Transmission Station') {
      this.tsCreateComponent.resetSelectedTs();
      this.tsCreateComponent.changeState(1);
    }
    if (this.tabName === 'Business Units') {
      this.createBusinessUnitComponent.resetSelectedBu();
      this.createBusinessUnitComponent.changeState(1);
    }
    if (this.tabName === 'DCUs') {
      this.dcuCreateComponent.resetSelectedConcentrator();
      this.dcuCreateComponent.changeState(1);
    }
    if (this.tabName === 'Undertakings') {
      this.areaCreateComponent.resetSelectedUT();
      this.areaCreateComponent.changeState(1);
    }
    if (this.tabName === 'Feeders') {
      if (this.feedersTabComponent.activeTab === 1) {
        this.feeder33CreateComponent.resetSelectedFeeder33();
        this.feeder33CreateComponent.changeState(1);
      }
      else if (this.feedersTabComponent.activeTab === 2) {
        this.feedersTabComponent.feeders11TabComponent.resetSelectedFeeder11();
        this.feeder11CreateComponent.resetSelectedFeeder11();
        this.feeder11CreateComponent.changeState(1);
      }
    }
    if (this.tabName === 'Distribution Transformer') {
      this.dtTabComponent.resetSelectedDt();
      this.DtCreateComponent.resetSelectedDt();
      this.DtCreateComponent.changeState(1);
    }
    if (this.tabName === 'Account Status') {
      this.accStatusCreateComponent.resetSelectedAcc();
      this.accStatusCreateComponent.changeState(1);
    }
    if (this.tabName === 'Meter Model') {
      this.createMeterModelComponent.resetSelectedMeterModel()
      this.createMeterModelComponent.changeState(1)
    }
  }

  download() {
    switch (this.tabName) {
      case 'Meter Manufacturer':
        this.tabAmiComponent.downloadItems();
        break;
      case 'DCUs':
        this.dcuTabsComponent.downloadItems()
        break;
      case 'Undertakings':
        this.areaTabComponent.downloadItems();
        break;
      case 'Business Units':
        this.tabBusinessUnitComponent.downloadItems();
        break;
      case 'Transmission Station':
        this.tsTabComponent.downloadItems();
        break;
      case 'Substations':
        this.tabSubstationComponent.downloadItems();
        break;
      case 'Feeders':
        if (this.feedersTabComponent.activeTab === 1)
          this.feedersTabComponent.feeders33TabComponent.downloadItems();
        else
          this.feedersTabComponent.feeders11TabComponent.downloadItems();
        break;
      case 'Distribution Transformer':
        this.dtTabComponent.downloadItems();
        break;
      case 'Meter Model':
        this.tabMeterModelComponent.downloadItems();
        break;
      case 'Staff Operator':
        this.toastr.info('Not yet implemented')
        break;
      case 'Unit':
        this.toastr.info('Not yet implemented')
        break
      case 'Subunit':
        this.toastr.info('Not yet implemented')
        break
      case 'Department':
        this.toastr.info('Not yet implemented')
        break
    }
  }

  onFileChosen(event: any) {
    this.file = event.target.files[0];
  }

  isFileChosen() {
    if (this.fileChosen)
      return true;

    return false;
  }

  uploadSubFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose a file for upload.');
      return;
    }
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());
    this.substationService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.tabSubstationComponent.reloadTable();
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }
  uploadAreaFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose a file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());
    this.areaService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.areaTabComponent.reloadTable();
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }
  uploadAmiFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose a file for upload.');
      return;
    }
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());

    this.amiService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.tabAmiComponent.reloadTable();
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }
  uploadBUFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose a file for upload.');
      return;
    }
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());

    this.buService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
        this.tabBusinessUnitComponent.reloadTable()
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }
  uploadDcuFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose a file for upload.');
      return;
    }
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());

    this.concentratorService.uploadFile(formData).subscribe(response => {

      if (response.status === 200) {
        this.toastr.success(response.message);
        this.dcuTabsComponent.reloadTable();

      } else {
        this.toastr.error(response.message);

        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  uploadDtFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose a file for upload.');
      return;
    }
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());

    this.dtService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.dtTabComponent.reloadTable();
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  uploadTsFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose a file for upload.');
      return;
    }
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());

    this.tsService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
        this.tsTabComponent.reloadTable();

      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }


  uploadF33File() {
    console.log("f33")
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose a file for upload.');
      return;
    }
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());

    this.feeder33Service.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.feedersTabComponent.feeders33TabComponent.reloadTable();
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  uploadF11File() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose a file for upload.');
      return;
    }
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());

    this.feeder11Service.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.feedersTabComponent.feeders11TabComponent.reloadTable();

      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  uploadMeterModelFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose a file for upload.')
      return
    }

    this.tabMeterModelComponent.uploadFile(this.file, this.importRef.update.toString())
    this.fileChosen = false
  }

  uploadFile() {
    this.file = this.importRef.fileChosen;

    switch (this.tabName) {
      case 'Meter Manufacturer':
        this.uploadAmiFile();
        break;
      case 'Substations':
        this.uploadSubFile();
        break;
      case 'Undertakings':
        this.uploadAreaFile();
        break;
      case 'DCUs':
        this.uploadDcuFile();
        break;
      case 'Business Units':
        this.uploadBUFile();
        break;
      case 'Distribution Transformer':
        this.uploadDtFile();
        break;
      case 'Feeders':
        if (this.feedersTabComponent.activeTab === 1)
          this.uploadF33File();
        else if (this.feedersTabComponent.activeTab === 2)
          this.uploadF11File();
        break;

      case 'Transmission Station':
        this.uploadTsFile();
        break;
      case 'Meter Model':
        this.uploadMeterModelFile();
        break;
    }
  }

  back() {
    this.location.back();
  }

  downloadTemplate() {
    switch (this.tabName) {
      case 'Meter Manufacturer':
        this.tabAmiComponent.getTemplate();
        break;
      case 'Substations':
        this.tabSubstationComponent.getTemplate();
        break;
      case 'Undertakings':
        this.areaTabComponent.getTemplate();
        break;
      case 'DCUs':
        this.dcuTabsComponent.getTemplate();
        break;
      case 'Feeders':
        if (this.feedersTabComponent.activeTab === 1)
          this.feedersTabComponent.feeders33TabComponent.getTemplate();
        else if (this.feedersTabComponent.activeTab === 2)
          this.feedersTabComponent.feeders11TabComponent.getTemplate();
        break;
      case 'Distribution Transformer':
        this.dtTabComponent.getTemplate();
        break;
      case 'Transmission Station':
        this.tsTabComponent.getTemplate();
        break;
      case 'Business Units':
        this.tabBusinessUnitComponent.getTemplate();
        break;
      case 'Undertakings':
        this.areaTabComponent.getTemplate();
        break;
      case 'Meter Model':
        this.tabMeterModelComponent.getTemplate();
        break;
    }
  }

  onDeleteConfirmed() {
    switch (this.tabName) {
      case 'Transmission Station':
        this.tsTabComponent.onDeleteConfirmed();
        break;
      case 'Substations':
        this.tabSubstationComponent.onDeleteConfirmed();
        break;
      case 'Feeders':
        if (this.feedersTabComponent.activeTab === 1) {
          this.feedersTabComponent.feeders33TabComponent.onDeleteConfirmed();
          break;
        }
        else if (this.feedersTabComponent.activeTab === 2) {
          this.feedersTabComponent.feeders11TabComponent.onDeleteConfirmed();
        }
        break;
      case 'Distribution Transformer':
        this.dtTabComponent.onDeleteConfirmed();
        break;
      case 'DCUs':
        this.dcuTabsComponent.onDeleteConfirmed();
        break;
      case 'Meter Manufacturer':
        this.tabAmiComponent.onDeleteConfirmed();
        break;
      case 'Business Units':
        this.tabBusinessUnitComponent.onDeleteConfirmed();
        break;
      case 'Undertakings':
        this.areaTabComponent.onDeleteConfirmed();
        break;
      case 'Account Status':
        this.accountStatusTabComponent.onDeleteConfirmed();
        break;
      case 'Meter Model':
        this.tabMeterModelComponent.onDeleteConfirmed();
        break;
    }
  }

  openDelete(item?: any) {
    this.crudDeleteRef.changeState();
  }

  resetSelectedDelete() {
    this.selectedItemDelete = null;
  }

  openTab(item?: any) {
    switch (this.tabName) {
      case 'Transmission Station':
        this.tsCreateComponent.changeState(1, item);
        break;
      case 'Substations':
        this.createSubstationComponent.changeState(1, item);
        break;
      case 'Feeders':
        if (this.feedersTabComponent.activeTab === 1) {
          this.feeder33CreateComponent.changeState(1, item);
        }
        else if (this.feedersTabComponent.activeTab === 2) {
          this.feeder11CreateComponent.changeState(1, item);
        }
        break;
      case 'Distribution Transformer':
        this.DtCreateComponent.changeState(1, item);
        break;
      case 'DCUs':
        this.dcuCreateComponent.changeState(1, item);
        break;
      case 'Meter Manufacturer':
        this.createAmiComponent.changeState(1, item);
        break;
      case 'Business Units':
        this.createBusinessUnitComponent.changeState(1, item);
        break;
      case 'Undertakings':
        this.areaCreateComponent.changeState(1, item);
        break;
      case 'Account Status':
        this.accStatusCreateComponent.changeState(1, item);
        break;
      case 'Meter Model':
        this.createMeterModelComponent.changeState(1, item);
        break;
    }
  }

  getList(list: any[]) {
    this.transmissionStationsSelect = list;
  }

  handleAmiList(list: any) {
    this.amis = list;
  }

  handleBuList(list: any) {
    this.busForSelect = list;
  }

  handleSubstationList(list: any) {
    this.substationsForSelect = list;
  }

  handleF33List(list: any) {
    this.feeders33ForSelect = list;
  }

  handleUtList(list: any) {
    this.utsForSelect = list;
  }

  handleTabChange(value: any) {
    this.feedersTabComponent.activeTab = value
    if(value == 1)
      this.componentName = ComponentNamesNew.Feeder33Component
    else
      this.componentName = ComponentNamesNew.Feeder11Component
  }
}
