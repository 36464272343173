import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { Feeder11AssetPerfomance } from 'src/app/models/f11-asset-performance.model';
import { PopupEnum } from 'src/app/models/popupEnum';
import { AssetPerformanceF11PopupComponent } from './asset-performance-f11-popup/asset-performance-f11-popup.component';

@Component({
  selector: 'app-asset-performance-f11-new',
  templateUrl: './asset-performance-f11-new.component.html',
  styleUrls: ['./../asset-performance-new.component.scss']
})
export class AssetPerformanceF11NewComponent{
  @Input() assetPerformanceF11 : Feeder11AssetPerfomance[] = [];
  @Input() buName: string = "";
  @Input() checkedList: number[] = [];
  public masterSelected: boolean = false;

  public selectedAssetPerfomancF11: any = {};
  @ViewChild(AssetPerformanceF11PopupComponent) popupComponentF11Ref!: AssetPerformanceF11PopupComponent;

  ngOnInit(): void {
    this.isAlreadyChecked()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isAlreadyChecked()
  }
  selectAssetPerformanceF11(feederName: string){
    this.selectedAssetPerfomancF11 = this.assetPerformanceF11.find(x => x.feederName == feederName)
    this.openTab()
  }

  openTab(){
    this.popupComponentF11Ref.changeState(PopupEnum.Open, this.selectedAssetPerfomancF11);
  }

  closeTab(){
    this.popupComponentF11Ref.changeState(PopupEnum.Close, this.selectedAssetPerfomancF11);
  }

  checkUncheckAll() {
    for (let i = 0; i < this.assetPerformanceF11.length; i++) {
      this.assetPerformanceF11[i].checked = this.masterSelected;
      this.checkForDownload(this.assetPerformanceF11[i].id, true);
    }
  }


  checkForDownload(id: number, isMasterClicked: boolean = false) {
    console.log(id)
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.assetPerformanceF11.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.assetPerformanceF11.every(function(item:any) {
      return item.checked == true;
    })
  }
}
