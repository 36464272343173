import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ScrollableAutocompleteFilterComponent } from 'src/app/components/common-new-design/scrollable-autocomplete-filter/scrollable-autocomplete-filter.component';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { FunctionalGroup } from 'src/app/models/functional-group-enum';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { StaffOperator } from 'src/app/models/staff-operator.model';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { DepartmentService } from 'src/app/services/department.service';
import { StaffOperatorService } from 'src/app/services/staff-operator.service';
import { SubunitService } from 'src/app/services/subunit.service';
import { UnitService } from 'src/app/services/unit.service';

@Component({
  selector: 'app-create-staff-operator',
  templateUrl: './create-staff-operator.component.html',
  styleUrl: '../.././create-crud.scss',  
  host: {
    '(document:click)': 'handleClick($event)',
  }
})
export class CreateStaffOperatorComponent {

  public selectedEntity: StaffOperator = new StaffOperator()
  showShadow = false;
  
  @Output() saveEvent = new EventEmitter();

  @ViewChild('f') f: any;

  public departments: DictionaryItem[] = [];
  public units: DictionaryItem[] = [];
  public subunits: DictionaryItem[] = [];

  public emailValid: boolean = true;
  public phoneNumberValid: boolean = true;

  @Input() animationState = 'out';
  @ViewChild(CreateStaffOperatorComponent) popupComponentRef!: CreateStaffOperatorComponent;
  @ViewChild(ScrollableAutocompleteFilterComponent) scrollableAutocompleteComponent!: ScrollableAutocompleteFilterComponent;
  
  constructor(private toastr: ToastrService,
    private ref: ElementRef,
    private authService: AuthService,
    private departmentService: DepartmentService,
    private unitService: UnitService,
    private subunitService: SubunitService,
    private staffOperatorService: StaffOperatorService,
    private commonService: CommonService) { }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  closeTab() {
    this.popupComponentRef.changeState(2);
  }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.popup-wrapper');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }

  handleClickOutside() {
    if (this.scrollableAutocompleteComponent) {
      this.scrollableAutocompleteComponent.hideAssets()
    }
  }

  changeFunctionalGroup() {
    this.mapFunctionalGroup()
    this.getAllDepartmentsForSelect(this.selectedEntity.functionalGroup)
    this.selectedEntity.departmentId = 0
    this.selectedEntity.unitId = 0
    this.selectedEntity.subunitId = 0
    this.units = []
    this.subunits = []
  }

  mapFunctionalGroup() {
    switch(this.selectedEntity.functionalGroupString) {
      case 'AdministrativeAndHR':
        this.selectedEntity.functionalGroup = FunctionalGroup.AdministrativeAndHR
        break;
      case 'Technical':
      this.selectedEntity.functionalGroup = FunctionalGroup.Technical
      break;
      case 'General':
      this.selectedEntity.functionalGroup = FunctionalGroup.General
      break;
      case 'Commercial':
      this.selectedEntity.functionalGroup = FunctionalGroup.Commercial
    }
  }

  changeDepartment() {
    this.getAllUnitsForSelect(this.selectedEntity.departmentId)
    this.selectedEntity.unitId = 0
    this.subunits = []
  }

  changeUnit() {
    this.getAllSubunitsForSelect(this.selectedEntity.unitId)
    this.selectedEntity.subunitId = 0
  }

  changeState(number: Number, data?: any) {
    if (data) {
      this.scrollableAutocompleteComponent.getAssignedAssetsForStaffOperator(data.id)
      this.getAllDepartmentsForSelect(data.functionalGroup)
      this.getAllUnitsForSelect(data.departmentId)
      this.getAllSubunitsForSelect(data.unitId)
      this.selectedEntity = data
    }
    if (number === 1) {
      this.toggleShowDiv('open');
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv('close');
      this.showShadow = false;
      this.resetEntities()
    }
    this.resetFiltersForScrollable()
  }

  getAllDepartmentsForSelect(funcGroup: any) {
    this.departmentService.getAllForSelect(funcGroup).subscribe(response => {
      this.departments = response.data
    }, _ => {
      this.toastr.error('An error occured');
    })
  }

  getAllUnitsForSelect(deptId: number) {
    this.unitService.getAllForSelect(deptId).subscribe(response => {
      this.units = response.data
    }, _ => {
      this.toastr.error('An error occured');
    })
  }

  getAllSubunitsForSelect(unitId: number) {
    this.subunitService.getAllForSelect(unitId).subscribe(response => {
      this.subunits = response.data
    }, _ => {
      this.toastr.error('An error occured');
    })
  }
  
  save() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate)) {
      this.toastr.error("You don't have permission.");
      return;
    }

    if (this.commonService.isEmail(this.selectedEntity.email))
      this.emailValid = true;
    else
      this.emailValid = false;

     if (this.commonService.isPhoneNumber(this.selectedEntity.phoneNumber))
        this.phoneNumberValid = true;
     else
      this.phoneNumberValid = false;

    if (this.emailValid == false || this.phoneNumberValid == false)
      return;

    if (!this.selectedEntity.firstName || this.selectedEntity.unitId == 0 
          || this.selectedEntity.departmentId == 0 
          || this.selectedEntity.subunitId == 0 || !this.selectedEntity.tacticalRoleString) {
      return
    }

    if (this.selectedEntity.id == 0) {
      this.selectedEntity.assetNames = this.scrollableAutocompleteComponent.selectedAssets
    }

    this.staffOperatorService.save(this.selectedEntity).subscribe(response => {
      if (response.status === 200) {
        if (this.selectedEntity.id != 0) {
          this.scrollableAutocompleteComponent.updateAssignedAssets(this.selectedEntity.id)
        } 
        this.toastr.success(response.message);
        this.changeState(2);
        this.saveEvent.emit();
        this.resetSelectedEntity();
        this.resetEntities()
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedEntity() {
    this.selectedEntity = new StaffOperator();

    if (this.f)
      this.f.submitted = false;
  }

  resetEntities() {
    this.departments = []
    this.units = []
    this.subunits = []
  }

  resetFiltersForScrollable() {
    this.scrollableAutocompleteComponent.resetFilters()
  }

}
