import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { StaffOperator } from 'src/app/models/staff-operator.model';
import { CreateStaffOperatorComponent } from './create-staff-operator/create-staff-operator.component';
import { CrudService } from 'src/app/services/crudService.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { StaffOperatorService } from 'src/app/services/staff-operator.service';
import { TacticalRole } from 'src/app/models/tactical-role-enum';

@Component({
  selector: 'app-staff-operator-tab',
  templateUrl: './staff-operator-tab.component.html',
  styleUrl: './staff-operator-tab.component.scss'
})
export class StaffOperatorTabComponent {

  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal!: ElementRef;

  public PermissionsEnum = PermissionsEnum;
  public selectedId: number = 0;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public searchText: string = "";
  private ngUnsubscribe = new Subject<void>();

  public allEntities: StaffOperator[] = [];
  public selectedEntity: StaffOperator = new StaffOperator();

  @ViewChild(CreateStaffOperatorComponent) popupComponentRef!: CreateStaffOperatorComponent;

  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();

  constructor(private crudService: CrudService,
    public authService: AuthService,
    public staffOperatorService: StaffOperatorService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.reloadTable();
    this.subscribeToSearch();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };

    this.staffOperatorService.getAll(obj).subscribe(response => {
      this.allEntities = response.data.data;
      this.allEntities.forEach(element => {
        this.mapEnumValues(element)
      });
      if (obj.pageInfo.page == 1) {
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  selectEntity(id: number) {
    this.staffOperatorService.get(id).subscribe(response => {
      if (response.status === 200){
        this.selectedEntity = response.data;
        this.mapEnumValues(this.selectedEntity)
        this.editEvent.emit(this.selectedEntity);
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  mapEnumValues(response: StaffOperator) {
    if (response.tacticalRole == TacticalRole.Manager) {
      response.tacticalRoleString = 'Manager'
    } else if (response.tacticalRole == TacticalRole.Officer) {
      response.tacticalRoleString = 'Officer'
    } else if (response.tacticalRole == TacticalRole.Supervisor) {
      response.tacticalRoleString = 'Supervisor'
    }
  }

  openTabDelete(id: number) {
    this.staffOperatorService.get(id).subscribe(response => {
     if (response.status === 200){
       this.selectedEntity = response.data;
       this.deleteEvent.emit();
     }
     else
       this.toastr.error(response.message);
   }, error => {
     this.toastr.error('An error occurred.');
   });
   this.selectedId = id;
  }

  onDeleteConfirmed() {
    this.staffOperatorService.delete(this.selectedEntity ? this.selectedEntity.id : 0).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message)
        this.currentPage = 1
        this.reloadTable()
      }
      else
        this.toastr.error(response.message)
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  openTab() {
    this.popupComponentRef.changeState(1, this.selectedEntity);
   }

  public subscribeToSearch() {
    this.crudService.globalSearch$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(searchValue => {
      if (searchValue.clicked && searchValue.componentName === ComponentNamesNew.StaffOperatorComponent) {
        this.searchText = searchValue.text;
        this.currentPage = 1;
        this.reloadTable();
        return;
      }
      this.searchText = '';
    });
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
