
export class GraphItem{
    date: Date = new Date()
    name: string = ""
    regionName: string = ""
    received: number = 0;
    consumed: number = 0;
    lost: number = 0;
    energyReceived: number = 0
    energyConsumptionAtDts: number = 0
    energyLoss: number = 0
    meterManufacturer: string = ""
    metersCount: number = 0
    metersWithComm: number = 0
    prepaidMetersCount: number = 0
    prepaidMetersWithComm: number = 0
    postpaidMetersCount: number = 0
    postpaidMetersWithComm: number = 0

    constructor(date: Date, name:string, received:number, consumed:number){
        this.date = date;
        this.name = name;
        this.received = received
        this.consumed = consumed
    }
}