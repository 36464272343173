import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private pathApi = this.config.setting['pathApi'] + "map/";
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient, 
    private config: AppConfig) { }
  
  getTsMarkers(forDashbard: boolean = false): Observable<any> {
    if (!forDashbard)
      return this.http.get(`${this.pathApi}getTsMarkers`);
    return this.http.get(`${this.pathApi}getTsMarkersForDashboard`);
  } 

  getSsMarkers(forDashbard: boolean = false, tsId: any = null): Observable<any> {
    if (!forDashbard)
      return this.http.get(`${this.pathApi}getSsMarkers?tsId=${tsId ?? ''}`);
    return this.http.get(`${this.pathApi}getSsMarkersForDashboard?tsId=${tsId ?? ''}`);
  } 

  getDtMarkers(forDashbard: boolean = false, ssId: any = null, utId: any = null, feederId: any = null): Observable<any> {
    if (!forDashbard)
      return this.http.get(`${this.pathApi}getDtMarkers?ssId=${ssId ?? ''}&utId=${utId ?? ''}&feederId=${feederId ?? ''}`);
    return this.http.get(`${this.pathApi}getDtMarkersForDashboard?ssId=${ssId ?? ''}&utId=${utId ?? ''}&feederId=${feederId ?? ''}`);
  } 

  getBuMarkers(forDashbard: boolean = false): Observable<any> {
    if (!forDashbard)
      return this.http.get(`${this.pathApi}getBuMarkers`);
    return this.http.get(`${this.pathApi}getBuMarkersForDashboard`);
  } 

  getUtMarkers(forDashbard: boolean = false, buId: any = null): Observable<any> {
    if (!forDashbard)
      return this.http.get(`${this.pathApi}getUtMarkers?buId=${buId ?? ''}`);
    return this.http.get(`${this.pathApi}getUtMarkersForDashboard?buId=${buId ?? ''}`);
  } 

  getGlobalSearchMarkers(searchText: any): Observable<any> {
    return this.http.get(`${this.pathApi}globalSearch?search=${searchText}`);
  } 
  getChannels(channelId: number): Observable<any>{
    return this.http.get(`${this.pathApi}getDropdownFilters?channelId=${channelId}`);
 
  };

  getMapDropdownFilters(ssId: number, feederId: number, buId: number, utId: number, dtId: number): Observable<any> {
    return this.http.get(`${this.pathApi}getDropdownFilters?ssId=${ssId}&feederId=${feederId}&buId=${buId}&utId=${utId}&dtId=${dtId}`);
  } 

  getAccMarkers(obj: any) {
    return this.http.post<any>(`${this.pathApi}getAccMarkers`,obj,{headers: this.headers, observe: 'response'});
  }


  getMapData(obj: any) {
    return this.http.post<any>(`${this.pathApi}getMapData`, obj, { headers: this.headers, observe: 'response' });
  }

  GetDtMarkerById(dtId: any = null): Observable<any> {
      return this.http.get(`${this.pathApi}GetDtMarkerById?dtId=${dtId ?? ''}`);
  } 

}
