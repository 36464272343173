<main [ngClass]="{'container-fluid': !remoteDisconnection }">
  <div [ngClass]="{'container-wrapper': !remoteDisconnection }">
    <div class="meter-details-header">
    <div class="network-management-title">
      <div class="meter-details-title">
      <div class="header-back" (click)="back()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.52116 7.83342H12.8337C13.0698 7.83342 13.2677 7.75356 13.4274 7.59384C13.5871 7.43412 13.667 7.2362 13.667 7.00009C13.667 6.76398 13.5871 6.56606 13.4274 6.40634C13.2677 6.24662 13.0698 6.16676 12.8337 6.16676H3.52116L7.60449 2.08342C7.77116 1.91676 7.85102 1.72231 7.84408 1.50009C7.83713 1.27787 7.75033 1.08342 7.58366 0.916758C7.41699 0.76398 7.22255 0.684119 7.00033 0.677174C6.7781 0.67023 6.58366 0.750091 6.41699 0.916758L0.916992 6.41676C0.833659 6.50009 0.774632 6.59037 0.739909 6.68759C0.705187 6.78481 0.687826 6.88898 0.687826 7.00009C0.687826 7.1112 0.705187 7.21537 0.739909 7.31259C0.774632 7.40981 0.833659 7.50009 0.916992 7.58342L6.41699 13.0834C6.56977 13.2362 6.76074 13.3126 6.98991 13.3126C7.21908 13.3126 7.41699 13.2362 7.58366 13.0834C7.75033 12.9168 7.83366 12.7188 7.83366 12.4897C7.83366 12.2605 7.75033 12.0626 7.58366 11.8959L3.52116 7.83342Z" fill="#1C1B1F"/>
          </svg>
      </div>
      <span class="mDetails">Meter details - {{meterDetails.meterNumber}}</span>
      </div>
      <button *ngIf="currentMeterStatus=='Connected' && !remoteDisconnection" class="turnon-off" (click)="changeMeterStatus('Disconnected', meterDetails.meterNumber)">
        <span *ngIf="currentMeterStatus=='Connected' && !remoteDisconnection">Turn Off</span>
        <div>
        <label class="switch">
          <input type="checkbox" checked>
          <span class="slider round"></span>
        </label>
      </div>
      </button>
      <button *ngIf="(currentMeterStatus=='Disconnected' || currentMeterStatus=='Undefined') && !remoteDisconnection" class="turnon-off" (click)="changeMeterStatus('Connected', meterDetails.meterNumber)">
        <span *ngIf="(currentMeterStatus=='Disconnected' || currentMeterStatus=='Undefined') && !remoteDisconnection">Turn On</span>
        <div>
        <label class="switch">
          <input type="checkbox">
          <span class="slider round"></span>
        </label>
        </div>
      </button>
      <button *ngIf="currentMeterStatus=='Connected' && remoteDisconnection" class="turnon-off" (click)="deactivateMeter()">
        <span *ngIf="currentMeterStatus=='Connected' && remoteDisconnection">Deactivate Meter</span>
      </button>
    </div>
    <div class="nm-filter-wrapper">
      <app-tab-default  [firstText]="'Overview'"
        [secondText]="'Token Loading'" [thirdText]="'Tariff'"
        [forthText]="'Graph Analysis'"
        [paCss]="true" [womCss]="true" (tabChanged)="tabChanged($event)" [wom]="false"
        [tabSelected]="currentTab"></app-tab-default>

    </div>

    </div>


    <div *ngIf="currentTab == 1" class="overview-content">
      <div class="details-overview-wrapper">
        <div class="details-overview-title">
         Details Overview
        </div>
        <div class="details-overview-content">

          <div class="details-overview-row">
            <label>Meter Number</label>
            <div *ngIf="meterDetails.meterNumber === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.meterNumber !== '/'" >{{meterDetails.meterNumber}}</div>
              <svg class="copy" (click)="copyToClipboard(meterDetails.meterNumber)" width="15" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.75 12.001C4.3375 12.001 3.98438 11.8541 3.69063 11.5604C3.39688 11.2666 3.25 10.9135 3.25 10.501V1.50098C3.25 1.08848 3.39688 0.735352 3.69063 0.441602C3.98438 0.147852 4.3375 0.000976562 4.75 0.000976562H11.5C11.9125 0.000976562 12.2656 0.147852 12.5594 0.441602C12.8531 0.735352 13 1.08848 13 1.50098V10.501C13 10.9135 12.8531 11.2666 12.5594 11.5604C12.2656 11.8541 11.9125 12.001 11.5 12.001H4.75ZM4.75 10.501H11.5V1.50098H4.75V10.501ZM1.75 15.001C1.3375 15.001 0.984375 14.8541 0.690625 14.5604C0.396875 14.2666 0.25 13.9135 0.25 13.501V3.00098H1.75V13.501H10V15.001H1.75Z" fill="#7F7D82" />
              </svg>
            </div>

          <div class="details-overview-row">
            <label>Meter Type</label>
            <div *ngIf="meterDetails.meterType === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.meterType !== '/'" >{{meterDetails.meterType}}</div>
          </div>
          <div class="details-overview-row">
            <label>Meter Make</label>
            <div *ngIf="meterDetails.meterMake === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.meterMake !== '/'" >{{meterDetails.meterMake}}</div>
          </div>
          <div class="details-overview-row">
            <label>Meter Model</label>
            <div *ngIf="meterDetails.meterModel === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.meterModel !== '/'" >{{meterDetails.meterModel}}</div>
          </div>
          <div class="details-overview-row">
            <label>Connection Type</label>
            <div *ngIf="meterDetails.connectionType === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.connectionType !== '/'" >{{meterDetails.connectionType}}</div>
          </div>
          <div class="details-overview-row">
            <label>DT Name</label>
            <div *ngIf="meterDetails.dtName === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.dtName !== '/'" >{{meterDetails.dtName}}</div>
          </div>
          <div class="details-overview-row">
            <label>DT Number</label>
            <div *ngIf="meterDetails.dtNo === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.dtNo !== '/'" >{{meterDetails.dtNo}}</div>
          </div>
          <div class="details-overview-row">
            <label>Feeder</label>
            <div *ngIf="meterDetails.feederName === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.feederName !== '/'" >{{meterDetails.feederName}}</div>
          </div>
          <div class="details-overview-row">
            <label>{{buName}}</label>
            <div *ngIf="meterDetails.buName === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.buName !== '/'" >{{meterDetails.buName}}</div>
          </div>
          <div class="details-overview-row">
            <label>{{utName}}</label>
            <div *ngIf="meterDetails.utName === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.utName !== '/'" >{{meterDetails.utName}}</div>
          </div>
          <div class="details-overview-row">
            <label>DCU SN</label>
            <div *ngIf="meterDetails.dcuSN === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.dcuSN !== '/'" >{{meterDetails.dcuSN}}</div>
          </div>
          <div class="details-overview-row">
            <label>Account Number</label>
            <div *ngIf="meterDetails.accountNumber === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.accountNumber !== '/'" >{{meterDetails.accountNumber}}</div>
              <svg class="copy" (click)="copyToClipboard(meterDetails.accountNumber)" width="15" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.75 12.001C4.3375 12.001 3.98438 11.8541 3.69063 11.5604C3.39688 11.2666 3.25 10.9135 3.25 10.501V1.50098C3.25 1.08848 3.39688 0.735352 3.69063 0.441602C3.98438 0.147852 4.3375 0.000976562 4.75 0.000976562H11.5C11.9125 0.000976562 12.2656 0.147852 12.5594 0.441602C12.8531 0.735352 13 1.08848 13 1.50098V10.501C13 10.9135 12.8531 11.2666 12.5594 11.5604C12.2656 11.8541 11.9125 12.001 11.5 12.001H4.75ZM4.75 10.501H11.5V1.50098H4.75V10.501ZM1.75 15.001C1.3375 15.001 0.984375 14.8541 0.690625 14.5604C0.396875 14.2666 0.25 13.9135 0.25 13.501V3.00098H1.75V13.501H10V15.001H1.75Z" fill="#7F7D82" />
              </svg>
            </div>
          <div class="details-overview-row">
            <label>Customer Name</label>
            <div *ngIf="meterDetails.customerName === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.customerName !== '/'" >{{meterDetails.customerName}}</div>
          </div>
          <div class="details-overview-row">
            <label>Tariff</label>
            <div *ngIf="meterDetails.tariffName === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.tariffName !== '/'" >{{meterDetails.tariffName}}</div>
          </div>
          <div class="details-overview-row">
            <label>Energy (KWh)</label>
            <div *ngIf="meterDetails.energy === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.energy !== '/'" >{{meterDetails.energy | number: '1.2-2'}}</div>
          </div>
          <div class="details-overview-row">
            <label>Meter Maximum Digit</label>
            <div *ngIf="meterDetails.meterMaxDigit === 0" class="NaN">N/A</div>
            <div *ngIf="meterDetails.meterMaxDigit !== 0" >{{meterDetails.meterMaxdigit}}</div>
          </div>
          <div class="details-overview-row">
            <label>Multiplier Factor</label>
            <div *ngIf="meterDetails.multiplierFactor === 0" class="NaN">N/A</div>
            <div *ngIf="meterDetails.multiplierFactor !== 0" >{{meterDetails.multiplierFactor}}</div>
          </div>
          <div class="details-overview-row">
            <label>Comm Status (Last 24hrs)</label>
            <div *ngIf="meterDetails.lastReadSync !== '/'" class="assetForm">{{meterDetails.lastReadSync}}</div>
          </div>
          <div class="details-overview-row">
            <label>AVG Current A</label>
            <div *ngIf="meterDetails.avgCurrentA === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.avgCurrentA !== '/'" >{{meterDetails.avgCurrentA}}</div>
          </div>
          <div class="details-overview-row">
            <label>AVG Current B</label>
            <div *ngIf="meterDetails.avgCurrentB === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.avgCurrentB !== '/'" >{{meterDetails.avgCurrentB}}</div>
          </div>
          <div class="details-overview-row">
            <label>AVG Current C</label>
            <div *ngIf="meterDetails.avgCurrentC === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.avgCurrentC !== '/'" >{{meterDetails.avgCurrentC}}</div>
          </div>
          <div class="details-overview-row">
            <label>Power</label>
            <div *ngIf="meterDetails.power === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.power !== '/'" >{{meterDetails.power}}</div>
          </div>
          <div class="details-overview-row">
            <label>Voltage</label>
            <div *ngIf="meterDetails.voltage === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.voltage !== '/'" >{{meterDetails.voltage}}</div>
          </div>
          <div class="details-overview-row">
            <label>Credit Balance (KWH)</label>
            <div *ngIf="meterDetails.creditBalance === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.creditBalance !== '/'" >{{meterDetails.creditBalance}}</div>
          </div>
          <div class="details-overview-row">
            <label>Credit Balance Time</label>
            <div *ngIf="meterDetails.creditBalanceTime === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.creditBalanceTime !== '/'" >{{meterDetails.creditBalanceTime}}</div>
          </div>
          <div class="details-overview-row">
            <label>Last Vend/Payment (KWH)</label>
            <div *ngIf="meterDetails.lastPaymentKWH === '/' && meterDetails.lastVendingKWH === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.lastPaymentKWH !== '/'" >{{meterDetails.lastPaymentKWH}}</div>
            <div *ngIf="meterDetails.lastVendingKWH !== '/'" >{{meterDetails.lastVendingKWH}}</div>
          </div>
          <div class="details-overview-row">
            <label>Last Vend/Payment (NGN)</label>
            <div *ngIf="meterDetails.lastPaymentNGN === '/' && meterDetails.lastVendingNGN === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.lastPaymentNGN !== '/'" >{{meterDetails.lastPaymentNGN}}</div>
            <div *ngIf="meterDetails.lastVendingNGN !== '/'" >{{meterDetails.lastVendingNGN}}</div>
          </div>
          <div class="details-overview-row">
            <label>Last Vend/Payment Time</label>
            <div *ngIf="meterDetails.lastPaymentDate === '/' && meterDetails.lastVendDate === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.lastPaymentDate !== '/'" >{{meterDetails.lastPaymentDate}}</div>
            <div *ngIf="meterDetails.lastVendDate !== '/'" >{{meterDetails.lastVendDate}}</div>
          </div>
          <div class="details-overview-row">
            <label>Avg. Daily Availability</label>
            <div *ngIf="meterDetails.avgDailyAvailability === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.avgDailyAvailability !== '/'" >{{meterDetails.avgDailyAvailability}}</div>
          </div>


        </div>

      </div>
      <div class="status-history-overview-wrapper">
        <div class="status-overview-title"> Status/History Overview</div>
        <div class="status-history-content">
         <div class="overview-last">
           <div class="last-update">
            <div class="overview-inner-header">Last Token Loaded</div>
            <div class="dateTime" *ngIf="lastTokenLoaded != null">{{lastTokenLoaded.creationTime | date: 'MMM d, y - h:mm:ss a'}}</div>
            <div class="dateTime" *ngIf="lastTokenLoaded == null">No tokens loaded for this meter.</div>
           </div>
           <div class="history" (click)="seeHistory(2)">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7917 12.0417L11.8542 10.9792L8.75 7.875V3.5H7.25V8.5L10.7917 12.0417ZM8 16.5C6.89756 16.5 5.86153 16.2917 4.89192 15.875C3.92231 15.4583 3.07292 14.8854 2.34375 14.1562C1.61458 13.4271 1.04167 12.5767 0.625 11.605C0.208333 10.6334 0 9.59519 0 8.49046C0 7.38571 0.208333 6.34722 0.625 5.375C1.04167 4.40278 1.61458 3.55556 2.34375 2.83333C3.07292 2.11111 3.92332 1.54167 4.89496 1.125C5.86661 0.708333 6.90481 0.5 8.00954 0.5C9.11429 0.5 10.1528 0.71 11.1251 1.13C12.0974 1.55 12.9432 2.12 13.6625 2.84C14.3817 3.56 14.9511 4.40667 15.3707 5.38C15.7902 6.35333 16 7.39333 16 8.5C16 9.60244 15.7917 10.6385 15.375 11.6081C14.9583 12.5777 14.3889 13.4271 13.6667 14.1562C12.9444 14.8854 12.0963 15.4583 11.1223 15.875C10.1482 16.2917 9.10747 16.5 8 16.5ZM8.00994 15C9.80823 15 11.3395 14.3646 12.6037 13.0938C13.8679 11.8229 14.5 10.2884 14.5 8.49006C14.5 6.69177 13.8679 5.16052 12.6037 3.89631C11.3395 2.6321 9.80823 2 8.00994 2C6.21165 2 4.67708 2.6321 3.40625 3.89631C2.13542 5.16052 1.5 6.69177 1.5 8.49006C1.5 10.2884 2.13542 11.8229 3.40625 13.0938C4.67708 14.3646 6.21165 15 8.00994 15Z" fill="#727278"/>
              </svg>
            See History
           </div>
         </div>
         <div class="overview-last">
          <div class="last-update">
            <div class="overview-inner-header">Last Tariff Update</div>
            <div class="dateTime">Aug 13, 2023 - 12:00:00 AM</div>
          </div>
           <div class="history" (click)="seeHistory(3)">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7917 12.0417L11.8542 10.9792L8.75 7.875V3.5H7.25V8.5L10.7917 12.0417ZM8 16.5C6.89756 16.5 5.86153 16.2917 4.89192 15.875C3.92231 15.4583 3.07292 14.8854 2.34375 14.1562C1.61458 13.4271 1.04167 12.5767 0.625 11.605C0.208333 10.6334 0 9.59519 0 8.49046C0 7.38571 0.208333 6.34722 0.625 5.375C1.04167 4.40278 1.61458 3.55556 2.34375 2.83333C3.07292 2.11111 3.92332 1.54167 4.89496 1.125C5.86661 0.708333 6.90481 0.5 8.00954 0.5C9.11429 0.5 10.1528 0.71 11.1251 1.13C12.0974 1.55 12.9432 2.12 13.6625 2.84C14.3817 3.56 14.9511 4.40667 15.3707 5.38C15.7902 6.35333 16 7.39333 16 8.5C16 9.60244 15.7917 10.6385 15.375 11.6081C14.9583 12.5777 14.3889 13.4271 13.6667 14.1562C12.9444 14.8854 12.0963 15.4583 11.1223 15.875C10.1482 16.2917 9.10747 16.5 8 16.5ZM8.00994 15C9.80823 15 11.3395 14.3646 12.6037 13.0938C13.8679 11.8229 14.5 10.2884 14.5 8.49006C14.5 6.69177 13.8679 5.16052 12.6037 3.89631C11.3395 2.6321 9.80823 2 8.00994 2C6.21165 2 4.67708 2.6321 3.40625 3.89631C2.13542 5.16052 1.5 6.69177 1.5 8.49006C1.5 10.2884 2.13542 11.8229 3.40625 13.0938C4.67708 14.3646 6.21165 15 8.00994 15Z" fill="#727278"/>
              </svg>
            See History
           </div>
         </div>

         <div class="status-active">
           <div class="overview-last meter-status-inner ">

            <div class="overview-inner-header">Account Status</div>
            <div class="meter-status">
               <div class="status"
                  [ngClass]="{'status-red': currentAccStatus === 'Inactive', 'status': currentAccStatus != 'Inactive'}">{{currentAccStatus}}</div>
              <dropdown-meter-details class="dropdown-status" [data]="accStatuses" [header]="'Change'"
               (selectItemChange)="changeStatus($event,meterDetails.accountNumber)"></dropdown-meter-details>
            </div>
           </div>
           <div class="overview-last meter-status-inner">
            <div class="overview-inner-header">Last Active</div>
            <div *ngIf="meterDetails.lastActiveDate === '/'" class="NaN">N/A</div>
            <div *ngIf="meterDetails.lastActiveDate !== '/'" class="dateTime">{{meterDetails.lastActiveDate | date: 'MMM dd, yyyy - hh:mm:ss a'}}</div>
           </div>
          </div>



        </div>
      </div>
    </div>


    <div *ngIf="currentTab == 2">
      <app-token-loading [meterId]="meterDetails.id"></app-token-loading>
    </div>

    <div *ngIf="currentTab == 3">
      <app-meter-tariff-history [msn]="meterDetails.meterNumber" [accountId]="meterDetails.id"></app-meter-tariff-history>
    </div>

    <div *ngIf="currentTab == 4">
       <app-graph-analysis [id]="meterDetails.id"></app-graph-analysis >
    </div>

  </div>
</main>
