<app-asset-performance-f11-popup></app-asset-performance-f11-popup>
<table class="standard-table">
  <thead>
    <th><span>FEEDER 11 NAME</span></th>
    <th><span>FEEDER 11 NUMBER</span></th>
    <th><span>FEEDER TYPE</span></th>
    <th><span>{{buName | uppercase}}</span></th>
    <th><span>ENERGY RECEIVED<br />(MWH)</span></th>
    <th><span>NAMEPLATE<br />RATING</span></th>
    <th><span>AVG DAILY<br />VOLTAGE</span></th>
    <th class="small-td"><span></span></th>
  </thead>
  <tbody>
    <tr *ngFor="let item of assetPerformanceF11" class="clickable actions-click" (click)="selectAssetPerformanceF11(item.feederName)">
      <td class="td-pom"><span>{{item.feederName | hasValue}}</span></td>
      <td class="td-pom2"><span>{{item.feederNo| hasValue}}</span></td>
      <td *ngIf="item.isPublic == 'True'"><span class="public"><img alt="" class="icon" src="assets/images-new-design/globe.png">Public</span></td>
      <td *ngIf="item.isPublic == 'False'"><span class="private"><img alt="" class="icon" src="assets/images-new-design/lock.png">Private</span></td>
      <td><span>{{item.buName | hasValue}}</span></td>
      <td *ngIf="item.energyReceived"><span>{{item.energyReceived | number: "1.0-3" | hasValue}}</span></td>
      <td *ngIf="!item.energyReceived"><span class="NaN">N\A</span></td>
      <td *ngIf="item.nameplateRating"><span>{{item.nameplateRating | number: "1.0-3" | hasValue}}</span></td>
      <td *ngIf="!item.nameplateRating"><span class="NaN">N\A</span></td>
      <td *ngIf="item.avgDailyVoltage"><span>{{item.avgDailyVoltage | number: "1.0-3" | hasValue}}</span></td>
      <td *ngIf="!item.avgDailyVoltage"><span class="NaN">N\A</span></td>
      <td class="small-td" class="clickable actions-click"><span><img alt="..." src="assets/images/3-dot.svg"></span></td>
    </tr>
  </tbody>
</table>
