import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { BillsService } from 'src/app/services/bills.service';
import { CommandRequestService } from 'src/app/services/command-request.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-bills-new',
  templateUrl: './bills-new.component.html',
  styleUrls: ['./bills-new.component.scss']
})
export class BillsNewComponent implements OnChanges {
  public showPaging: boolean = false;

  @Input() bills: any[] = []
  @Input() buName: string = "";
  @Input() utName: string = "";
  @Input() buNames: any[] = []
  @Input() utNames: any[] = []
  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()

  public checkedList: number[] = [];
  public list: number[] = [];
  public masterSelected: boolean = false;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public lastPageSize: number = 1;
  public count: number = 0;

  public bu: string = this.translationService.getByKeyFromCache('BU').toUpperCase()
  public ut: string = this.translationService.getByKeyFromCache('UT').toUpperCase()
  constructor(private billsService: BillsService,
    private toastr: ToastrService,
    private commandRequestService: CommandRequestService,
    private detectionChange: ChangeDetectorRef,
    private translationService: TranslationService) { }

  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false

    if (
      changes.buIds !== undefined ||
      changes.f11Ids !== undefined ||
      changes.categoryId !== undefined ||
      changes.dateFrom !== undefined || changes.dateTo !== undefined ||
      changes.durationFrom !== undefined || changes.durationTo !== undefined ||
      changes.searchText !== undefined || changes.currentPage !== undefined
    ) {
      reloadTable = true;
    }
    if (this.searchText != null) {
      reloadTable = true
    }

    if (changes.menu !== undefined || changes.tabMenu !== undefined) {
      reloadTable = true;
      this.resetFilters();
    }

    if (reloadTable) {
      this.checkedList = [];
      this.pageChange(1)
    }

  }

  reloadTable(page: any = null) {
    if (page)
    this.currentPage = page

    let obj = this.generateObject()

    this.billsService.getAll(obj).subscribe((response) => {
      this.bills = response.data.data
      this.isAlreadyChecked()
      if(this.currentPage == 1){
        this.billsService.getBillsCount(obj).subscribe((response) => {
          this.count = response.data
          })
          this.lastPageSize = Math.ceil(this.count/this.pageSize)
        }
        this.showPaging=false;
      },
      (error) => {
        this.toastr.error('An error occurred.')
      }
    )
    this.showPaging=true;
  }

  generateObject() {
    let obj = {
      pageInfo: {
        pageSize: this.pageSize,
        page: this.currentPage,
      },
      filterParams: {
        from: transformToString(this.dateFrom),
        to: transformToString(this.dateTo),
        buNames: this.buNames,
        utNames: this.utNames,
        search: this.searchText ? this.searchText.name : this.searchText,
        billsIds: this.list
      },
      guid: crypto.randomUUID()
    }

    return obj
  }

  checkCommandRequest(guid: string) {
    // initial check
    this.commandRequestService.checkCommandRequest(guid).subscribe(response => {
      if (response.data) {
        window.location.href = response.message
      }
    })

    // check every 5s
    let intervalRef = setInterval(() => {
      this.commandRequestService.checkCommandRequest(guid).subscribe(response => {
        if (response.data) {
          window.location.href = response.message
          clearInterval(intervalRef);
        }
      })
    }, 5000)
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  download(){
    let error = this.validateDownload();

    if(error){
      this.toastr.error(error);
      return;
    }

    let obj = this.generateObject()
    obj.filterParams.billsIds = this.checkedList;
    obj.pageInfo.pageSize = 0;
    this.billsService.DownloadBillsReport(obj).subscribe(
      (response) => {
        if(response.status == 201){
          this.toastr.success(response.message)
        }
        if(response.status == 200){
          if(!response.data.includes("http")){
            this.toastr.error("An error occured.");
          }
          else{
            window.location.href = response.data;
          }
        }
      },
      (_) => {
        this.toastr.error('An error occured')
      }
    )
  }

  validateDownload(): string{
    let message: string = "";

    if(this.buNames.length === 0 && this.checkedList.length === 0){
      message = "Please select region for download, or specific bills for download.";
    }

    return message;
  }

  downloadFile(url: string) {
    console.log(url);
    const link = document.createElement('a');
    link.href = url;
    let s = url.split('/');
    let filename = s[s.length - 1];
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  resetFilters() {
    this.buNames = []
    this.utNames = []

    this.detectionChange.detectChanges()
  }

  checkUncheckAll() {
    for (let i = 0; i < this.bills.length; i++) {
      this.bills[i].checked = this.masterSelected;
      this.checkForDownload(this.bills[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.bills.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.bills.every(function(item:any) {
      return item.checked == true;
    })
  }
}
