import { Injectable } from "@angular/core";
import { AppConfig } from "../config/config";
import { AuthService } from "./auth.service";
import * as signalR from "@microsoft/signalr";

@Injectable({
    providedIn: 'root'
})
export class LogoutHubService{

    constructor(
        private config: AppConfig,
        private authService: AuthService
    ) { }

    private pathApi = this.config.setting['pathApi'].slice(0, -4);
    public connection!: signalR.HubConnection;
    token: string | null = null

    options: signalR.IHttpConnectionOptions = {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          this.token = localStorage.getItem('token') as string;
          return this.token;
        }
    };

    init(): Promise<any> {
        console.log('LogoutHubService initialized');
        console.log(this.pathApi)
        this.connection = new signalR.HubConnectionBuilder()
          .withUrl(this.pathApi + 'hub/LogoutHub', this.options)
          .build();
    
        this.connection.on('Logout', (result: any) => {
            console.log(":qdqfduiqnwoifbqnwofiqbnfq")
            this.authService.logout();
        });
    
        this.connection
          .start()
          .then(
            (con) => {
              console.log('Connection started')
            })
          .catch(err => console.log('Error while starting connection: ' + err));
    
        return Promise.resolve();
    }
    
    close() {
        return this.connection?.stop();
    }
}