<div class="dashboard-title">
    DCU
</div>
<ul class="nav nav-tabs c-tabs">
    <li class="nav-item" role="presentation">
        <div class="first">
            <div class="nav-link">Online<span class="bubble">{{online}}</span>
            </div>
            <svg width="2" height="48" viewBox="0 0 2 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.833374" y1="0.469727" x2="0.833376" y2="47.5305" stroke="#CECECE" />
            </svg>
        </div>
    </li>
 
    <li class="nav-item" role="presentation">
        <div class="nav-link">Offline<span class="bubble">{{offline}}</span></div>
    </li>
</ul>