import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessUnit } from 'src/app/models/business-unit.model';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Role } from 'src/app/models/role.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { HeaderService } from 'src/app/services/header.service';
import { RoleService } from 'src/app/services/role.service';
import { UserService } from 'src/app/services/user.service';
import { SearchAutocompleteComponent } from '../common-new-design/search-autocomplete/search-autocomplete.component';
import { CrudDeleteModalComponent } from '../crud-tabs/crud-delete-modal/crud-delete-modal.component';
import { CreateUserComponent } from './create-user/create-user.component';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  @ViewChild(CreateUserComponent) popupComponentRef!: CreateUserComponent;
  show: boolean = false;
  public selectedId: any;
  public searchPlaceholder: string = "Search"
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []

  public masterSelected: boolean = false;
  public lastPageSize: number = 1;

  public PermissionsEnum = PermissionsEnum;
  public disableEmail = false;
  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;

  public fileChosen: any = null;
  public file: any = null;

  public ngUnsubscribe = new Subject<void>();

  public searchByFirstName: string = '';
  public searchByLastName: string = '';
  public searchByUserName: string = '';
  public searchByEmail: string = '';
  public searchByPhoneNumber: string = '';
  @ViewChild('search') searchChild!: SearchAutocompleteComponent;


  public users: User[] = [];
  public roles: Role[] = [];
  public regions: BusinessUnit[] = [];
  public selectedUser: User = new User();
  public count1: number = 0;

  @ViewChild("f") f: any;
  clickObservable: any;
  constructor(private userService: UserService,
    private headerService: HeaderService,
    private roleService: RoleService,
    private toastr: ToastrService,
    public authService: AuthService,
    private location: Location,
    private buService: BusinessUnitService) { }
    public checkedList: any = [];
    usersId: any = [];

  ngOnInit(): void {
    this.reloadTable();
    this.subscribeToSearch();
    this.getRoles();
    this.getRegions();
  }

  checkUncheckAll() {
    for (let i = 0; i < this.users.length; i++) {
      this.users[i].checked = this.masterSelected;
      if(this.masterSelected) {
        this.checkForDownload(this.users[i].id, true);
      }
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.users.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.users.every(function(item:any) {
      return item.checked == true;
    })
  }

  public download() {
    let obj = {
        searchContent: this.searchText.name,
        userIds: this.checkedList
    };

    this.userService.download(obj).subscribe(response => {
      window.location.href = response.data;
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: false
    }
    if (searchText.name.length > 1) {
      this.userService.getDataForAutocomplete(obj)
            .subscribe({
              next : (response) => this.options = response.data,
              error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
            })
    }else{
      this.searchChild.resetOptions()
    }
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.masterSelected = false;
    this.reloadTable();
  }
  inputChanged(text: SearchAutocomplete){
    this.searchText = text
    this.searchText = cloneDeep(this.searchText)
    this.pageChange(1);
  }
  back() {
    this.location.back();
  }
  getRoles(): void {
    let obj = {
      pageInfo: {
        pageSize: 0
      },
      filterParams: {
      }
    };
    this.roleService.getAll(obj).subscribe(response => {
      this.roles = response.data.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

    getRegions(){
    let obj = {
      pageInfo: {
        pageSize: 0
      },
      filterParams: {
      }
    };
    this.buService.getAll(obj).subscribe(response => {
      this.regions = response.data.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;

    this.resetSelectedUser();

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchContent: this.searchText.name
      }
    };

    this.userService.getAll(obj).subscribe(response => {
      this.users = response.data.data;
      if(obj.pageInfo.page == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      this.isAlreadyChecked();

    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  closeTab(){
    this.popupComponentRef.changeState(2, this.selectedUser);
  }

  openTab(id?:number) {
    console.log("id",id)
    if(id===0){
      this.popupComponentRef.changeState(1, 0);
    }else{
      this.popupComponentRef.changeState(1, this.selectedUser);

    }
  }

  selectUser(guid: any) {
    this.resetSelectedUser();
    this.disableEmail = true;
    this.masterSelected = false;

    this.userService.getByGuid(guid).subscribe(response => {
      if (response.status === 200) {
        this.selectedUser = response.data;
        this.openTab()
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });


  }

  resetSelectedUser() {

    this.selectedUser = new User();
    this.disableEmail = false;

    if (this.f)
      this.f.submitted = false;
  }
  openTabDelete(id:any) {
    this.userService.getByGuid(id).subscribe(response => {
     if(response.status === 200){
       this.selectedUser = response.data;
     }
     else
       this.toastr.error(response.message);
   }, error => {
     this.toastr.error('An error occurred.');
   });
   this.selectedId = id;
   this.crudDeleteRef.changeState();
 }
  onDeleteConfirmed() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_user_delete)) {
      this.toastr.error("You don't have permission.");
      return;
    }

    this.userService.delete(this.selectedId).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.pageChange(1);
        // this.closeDeleteModal.nativeElement.click();
        this.resetSelectedUser();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }


  public subscribeToSearch() {
    this.headerService.globalSearch$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchValue => {
        if (searchValue.clicked && searchValue.componentName === ComponentNamesNew.UserManagementComponent) {
          this.searchText.name = searchValue.text;
          this.currentPage = 1;
          this.checkedList = [];
          this.pageChange(1);
          return;
        }
        this.searchText = new SearchAutocomplete();
      });
  }

  downloadTemplate(){
    this.userService.getTemplate().subscribe(response => {
      if (response.result.status == 200) {
        if (response.result.data && response.result.data.length > 0)
          window.location.href = response.result.data;
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  onFileChosen(event: any) {
    this.file = event.target.files[0];
  }

  isFileChosen() {
    if (this.fileChosen)
      return true;

    return false;
  }

  uploadFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.userService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
        console.log(response.data)
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.pageChange(1);
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
