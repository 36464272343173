import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FieldSettingsNew } from 'src/app/models/fieldSettingsNew.model';
import { AuthService } from 'src/app/services/auth.service';
import { DtFieldSettingsComponent } from './dt-field-settings/dt-field-settings.component';
import { Feeder11FieldSettingsComponent } from './feeder11-field-settings/feeder11-field-settings.component';
import { Feeder33FieldSettingsComponent } from './feeder33-field-settings/feeder33-field-settings.component';

@Component({
  selector: 'app-settingsField',
  templateUrl: './settingsField.component.html',
  styleUrls: ['./settingsField.component.scss']
})

export class SettingsFieldComponent{

  @ViewChild(DtFieldSettingsComponent) dtTabComponent: DtFieldSettingsComponent | any;
  @ViewChild(Feeder11FieldSettingsComponent) feeder11TabComponent: Feeder11FieldSettingsComponent | any;
  @ViewChild(Feeder33FieldSettingsComponent) feeder33TabComponent: Feeder33FieldSettingsComponent | any;

  tabName: string = 'Dt';
  firstTabPrimary: string = "Distribution Transformer";
  secondTabPrimary: string = "11Kv Feeders";
  thirdTabPrimary: string = "33KV Feeders"
  componentName: FieldSettingsNew = FieldSettingsNew.DtComponent;
  public FieldSettingsNew = FieldSettingsNew;

  public crudAreaVisible: boolean = false;
  public DtVisible: boolean = false;
  public F11Visible: boolean = false;
  public F33Visible: boolean = false;

  constructor ( public authService: AuthService,
                private location: Location) {}

  back(){
    this.location.back();
  }

  changeTab(textLabel: any) {
    switch (textLabel) {
      case 1:
        this.tabName = 'Dt'
        this.componentName = FieldSettingsNew.DtComponent
        break;
      case 2:
        this.tabName = 'Feeder11';
        this.componentName = FieldSettingsNew.Feeder11Component
        break;
      case 3:
        this.tabName = 'Feeder33';
        this.componentName = FieldSettingsNew.Feeder33Component
        break;
    }
  }

  addNew() {
    if (this.tabName === 'Dt') {
      this.dtTabComponent.openTab(1);
    }
    if (this.tabName === 'Feeder11') {
      this.feeder11TabComponent.openTab(1);
    }
    if (this.tabName === 'Feeder33') {
      this.feeder33TabComponent.openTab(1);
    }
  }
}
