import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';

export enum SettingsItems{
  UserProfileManagamentComponent = 1,
  FeederReads = 2,
  DtReads = 3
}
@Component({
  selector: 'app-network-modal-settings',
  templateUrl: './network-modal-settings.component.html',
  styleUrls: ['./network-modal-settings.component.scss']
})
export class NetworkModalSettingsComponent implements OnInit {

  public SelectedItem: any = 1;
  public permissionEnum = PermissionsEnum 

  constructor(
    private toastr: ToastrService, 
    private router: Router,
    public authService: AuthService
  ) { 
    this.router.events
      .subscribe(
        (event) => {
          if(event instanceof ActivationEnd && event.snapshot.children.length == 0) {
            this.SelectedItem = event.snapshot.data['sidebarItem'];
           }
        });
  }

  ngOnInit(): void {
    this.SelectedItem = 3;
  }

  alert(){
    this.toastr.warning("Not yet implemented");
  }

  tabSelected(tabSelected: number){
    this.SelectedItem = tabSelected;
  }
}
