import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { MultiFilterComponent } from 'src/app/components/common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { MeterBypassService } from 'src/app/services/meter-bypass.service';
import { RevenueDashboardService } from 'src/app/services/revenue-dashboard.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';

@Component({
  selector: 'app-meter-bypass-diagnostic',
  templateUrl: './meter-bypass-diagnostic.component.html',
  styleUrls: ['./meter-bypass-diagnostic.component.scss']
})
export class MeterBypassDiagnosticComponent implements OnInit, AfterViewInit {

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    interaction:{
      mode: 'nearest'
    },
    plugins:{
      legend:{
        display: false
      }
    },
    scales:{
      x:{
        max: 10000,
        grid:{
          display: false,
        },
        border:{
          display: false
        },
        ticks:{
          maxTicksLimit: 6,
          font:{
            size: 15,
            weight: 'bold',
            family: 'Inter'
          }
        }
      },
      y:{ 
        grid:{
          display: false,
        },
        border:{
          display: false
        },
        ticks:{
          crossAlign: 'far',
          padding: 15,
          font:{
            size: 15,
            weight: 'bold',
            family: 'Inter',
          }
        },
        offset: true,
      },
      backgroundData:{
        display: false,
        beginAtZero: false,
        position: 'right',
        grid:{
          display: false,
        },
        border:{
          display: false
        },
      }
    }
  };

  public CapitalLabels: string[] = [];

  public CustomersData: ChartDataset[] = [
    { data: [], label: 'Customers',
      borderRadius: 30,
      barThickness: 13,
      borderSkipped: false,
     },
     { data: [], label: 'Max',
      backgroundColor: '#D6D6D6',
      borderRadius: 30,
      barThickness: 13,
      borderSkipped: false,
      yAxisID: 'backgroundData',
      hoverBackgroundColor: '#D6D6D6',
     },
  ];

  @ViewChild('chart') canvas!: ElementRef;
  @ViewChild('chartBar') chartBar!: ElementRef;

  public currentPage: number = 1
  public pageSize: number = 5
  public count: number = 0

  public dateFrom: any = "";
  public dateTo: any = "";

  public numberOfItem: number = 0;
  public defaultHeight: number = 42 //rem
  public defaultBarHeight: number = 4 //rem
  public areaCustomerCount: any[] = []

  public buItems: any[] = [];
  public buSelectedName: string = 'Region'
  public buSelectedId: number = -1
  public totalCustomersCount: number = 0

  public searchText: SearchAutocomplete = new SearchAutocomplete();
  public searchPlaceholder: string = "Search by Area name"
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public allFilters: any = []
  public businessUnits: any = { name: this.buName, data: [], dropdown: true};
  public filterApplied: boolean = false;
  public showFilter: any = false
  public buId: number = 0
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  options = ['']

  constructor(
    private meterBypassService: MeterBypassService,
    private dashboardService: RevenueDashboardService,
    private toastr: ToastrService,
    public translationService: TranslationService,
    private buService: BusinessUnitService,
    private utService: UndertakingService
    ) { }

  ngOnInit(): void {
    this.setMainDate()
    this.getAllBusinessUnits()
    this.getPrepaidCustomersCount()
    this.getGraphData(0);
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    if(dte.getMonth() == 0)
    {
      this.dateFrom = {year: dte.getFullYear() - 1, month: 12, day: 1};
    }
    else {
      this.dateFrom = {year: dte.getFullYear(), month: dte.getMonth(), day: 1};
    }
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
  }

  updateDate(date: any){
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.getPrepaidCustomersCount()
    this.getGraphData(0)
  }

  ngAfterViewInit(): void {
    const ctx = this.canvas.nativeElement.getContext('2d')
    this.CustomersData[0].backgroundColor = '#24C373'
    if(this.chartBar){
      const chartElement = this.chartBar.nativeElement;
    }
  }

  getAllBusinessUnits(){
    this.buService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data, dropdown: true }
      this.checkFilters();
    }, _ => {
      this.toastr.error('An error occurred.')
    });
  }

  selectBu(data: any){
    this.buSelectedId = data.value
    this.buSelectedName = data.name
    this.getGraphData(this.buSelectedId)
  }

  getPrepaidCustomersCount(){
    let obj = {
      dateFrom: transformToString(this.dateFrom),
      dateTo: transformToString(this.dateTo),
    }
    this.meterBypassService.getFlaggedCustomersCount(obj).subscribe(response =>{
      this.totalCustomersCount = response.data
    },_=>{
      this.toastr.error("An error occurred")
    })
  }

  getGraphData(buId: number = 0){
    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        BuId: buId,
        SearchText: this.searchText.name
      }
    }

    this.dashboardService.getUTsAndCustomersNo(obj).subscribe(response =>{
      if(response.status == 200){      
        this.areaCustomerCount = response.data.data
        this.numberOfItem = response.data.data.length;
        if(this.currentPage == 1)
          this.count = response.data.count
        this.handleDataForGraph(response.data.data)
      }
      else{
        this.toastr.error(response.message)
      }
    })
  }

  setGraphBackgroundBars(value: number){
    for(let i = 0; i<this.CustomersData[0].data.length; i++){
      this.CustomersData[1].data.push(value);
    }

    if(this.numberOfItem >= -1){
      this.barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        interaction:{
          mode: 'index'
        },
        plugins:{
          tooltip:{
            backgroundColor: '#24C373',
            titleColor: 'white',
            callbacks:{
              label: function(context){
                return ''
              },
              title:function(context){
                let label = ''
                if(context[0].dataset.label == 'Max'){
                  label = context[0].label
                }
                else{
                  label = context[0].label + ' - Customers ' + context[0].parsed.x
                }

                return label;
              },
            }
          },
          legend:{
            display: false
          }
        },
        scales:{
          x:{
            display: false,
            max: value,
            grid:{
              display: false,
              drawOnChartArea: false
            },
            ticks:{
              maxTicksLimit: 8,
              font:{
                size: 12,
                weight: 500,
                family: 'Inter'
              }
            },
            border:{
              display: false
            },
          },
          y:{ 
            grid:{
              display: false,
            },
            border:{
              display: false
            },
            ticks:{
              crossAlign: 'far',
              padding: 15,
              font:{
                size: 12,
                weight: 500,
                family: 'Inter',
              }
            },
            offset: true,
          },
          backgroundData:{
            display: false,
            beginAtZero: false,
            position: 'right',
            grid:{
              display: false,
            },
            border:{
              display: false
            },
            ticks:{
              display:false
            }
          }
        }
        };
    }else{
      this.barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'x',
        scales:{
          x:{
            max: value,
            grid:{
              display: false,
            },
            border:{
              display: false
            },
            ticks:{
              maxTicksLimit: 7,
              color:'white',
              //maxRotation: 0,
              
              font:{
                size: 11,
                //weight: 'bold',
                family: 'Inter',
              }
            }
          },
          y:{ 
            grid:{
              display: true,
              drawTicks: true,
              drawOnChartArea: true,
              color:'#BF3A3A',
              lineWidth: 0.3,
              tickBorderDash: [4, 4],
              tickBorderDashOffset: 1,
            },
            border:{
              display: false
            },
            ticks:{
              crossAlign: 'far',
              padding: 12,
              color:'white',
              font:{
                size: 15,
                //weight: 'bold',
                family: 'Inter',
              }
            },
            offset: true,
          },
          backgroundData:{
            display: false,
            beginAtZero: false,
            position: 'right',
            grid:{
              display: false,
            },
            border:{
              display: false
            },
            ticks:{
              display:false
            }
          }
        }
      }
    }
  }

  setGraphsHight(){
    setTimeout(() => {
      if (this.chartBar) {
        const chartElement = this.chartBar.nativeElement;
        if(this.numberOfItem * this.defaultBarHeight > this.defaultHeight){
          chartElement.style.height = this.numberOfItem * this.defaultBarHeight + 'rem'
    
        }
        else{
          chartElement.style.height = this.defaultHeight + 'rem'    
        }
      } 
    }, 0);
    
  }

  pageChange(value: number){
    this.currentPage = value
    this.getGraphData()
  }

  handleDataForGraph(data: any[]){
    this.CapitalLabels = []
    this.CustomersData[0].data = []
    this.CustomersData[1].data = []
    data.forEach(element => {
      this.CapitalLabels.push(element.name)
      if(element == 0){
        this.CustomersData[0].data.push(null)
      }
      else{
        this.CustomersData[0].data.push(element.customersNo)
      }
    });

    if(data[0] != null){
      this.setGraphBackgroundBars(data[0].maxValue)
    }
    else{
      this.setGraphBackgroundBars(0)
    }
    
    this.setGraphsHight()
  }

  businessUnitSelected(item: any) {
    if(!item.item)
      this.buId = 0
    if(item.item && this.buId != item.item.value) {
      this.buId = item.item.value;
    }
    this.checkFilters()
    this.getGraphData(this.buId);
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    this.pageChange(1)
    this.getPrepaidCustomersCount()
  }

  checkFilters() {
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnits.name);

    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);

    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;

    if (!buData) {
      this.allFilters.push(this.businessUnits)

      this.filterApplied = true
    }

    this.allFilters = [...this.allFilters]
  }

  getOptions(searchText: any) { 
    let obj = {
      searchText: searchText.name, 
      msnIncluded: false
    }
    if (searchText.name.length > 2) {
      this.utService.getDataForAutocomplete(obj).subscribe({ 
        next : (response) => this.options = response.data,
        error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
      })
    } else
      this.searchComponentRef.resetOptions()
  }

  resetFilters() {
    this.searchText = new SearchAutocomplete();
    this.searchComponentRef.searchText = new SearchAutocomplete();
    this.showFilter = false
    this.buId = 0
    this.allFilters = []
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.searchComponentRef.resetOptions()
    this.setMainDate()
    this.checkFilters()
    this.pageChange(1);
  }

  inputChanged(searchText: SearchAutocomplete) {
    this.currentPage = 1
    this.searchText = searchText
    this.getGraphData(0)
  }
}
