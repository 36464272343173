import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { CommandRequestService } from 'src/app/services/command-request.service';
import { TranslationService } from 'src/app/services/translation.service';
import { VendingsService } from 'src/app/services/vendings.service';

@Component({
  selector: 'app-vendings-new',
  templateUrl: './vendings-new.component.html',
  styleUrls: ['./vendings-new.component.scss']
})
export class VendingsNewComponent implements OnChanges {
  public showPaging: boolean = false;
  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''

  @Input() vendings: any[] = []
  @Input() buName: string = "";
  @Input() utName: string = "";
  @Input() buNames: any[] = []
  @Input() utNames: any[] = []
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()

  public checkedList: number[] = [];
  public masterSelected: boolean = false;
  public vendingsIds: number[] = [];
  public idList: number[] = [];

  public currentPage: number = 1;
  public pageSize: number = 10;
  public lastPageSize: number = 1;
  public count: number = 0;

  public bu: string = this.translationService.getByKeyFromCache('BU').toUpperCase()
  public ut: string = this.translationService.getByKeyFromCache('UT').toUpperCase()
  constructor(private vendingsService: VendingsService,
    private toastr: ToastrService,
    private commandRequestService: CommandRequestService,
    private detectionChange: ChangeDetectorRef,
    private translationService: TranslationService

  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    let reloadTable = false

    if (
      changes.buIds !== undefined ||
      changes.f11Ids !== undefined ||
      changes.categoryId !== undefined ||
      changes.dateFrom !== undefined || changes.dateTo !== undefined ||
      changes.durationFrom !== undefined || changes.durationTo !== undefined ||
      changes.searchText !== undefined || changes.currentPage !== undefined
    ) {
      reloadTable = true;
    }
    if (this.searchText != null) {
      reloadTable = true
    }

    if (changes.menu !== undefined || changes.tabMenu !== undefined) {
      reloadTable = true;
      this.resetFilters();
    }

    if (reloadTable) {
      this.checkedList = [];
      this.pageChange(1);
    }
  }

  reloadTable(page: any = null) {
    if (page)
    this.currentPage = page

    let obj = this.generateObject()

    this.vendingsService.getAll(obj).subscribe((response) => {
      this.vendings = response.data.data;
      this.isAlreadyChecked();
      if(this.currentPage == 1){
        this.vendingsService.GetVendingsCount(obj).subscribe((response) => {
          this.count = response.data
          })
          this.lastPageSize = Math.ceil(this.count/this.pageSize)
        }
        this.showPaging=false;
      },
      (error) => {
        this.toastr.error('An error occurred.')
      }
    )
    this.showPaging=true;
  }

  generateObject() {
    let obj = {
      pageInfo: {
        pageSize: this.pageSize,
        page: this.currentPage,
      },
      filterParams: {
        from: transformToString(this.dateFrom),
        to: transformToString(this.dateTo),
        buNames: this.buNames,
        utNames: this.utNames,
        search: this.searchText ? this.searchText.name : this.searchText,
        vendingsIds: this.idList
      },
      guid: crypto.randomUUID()
    }

    return obj
  }

  checkCommandRequest(guid: string) {
    // initial check
    this.commandRequestService.checkCommandRequest(guid).subscribe(response => {
      if (response.data) {
        window.location.href = response.message
      }
    })

    // check every 5s
    let intervalRef = setInterval(() => {
      this.commandRequestService.checkCommandRequest(guid).subscribe(response => {
        if (response.data) {
          window.location.href = response.message
          clearInterval(intervalRef);
        }
      })
    }, 5000)
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  download(){
    let errors = this.validateDownload();

    if(errors.length > 0){
        errors.forEach(error => {
          this.toastr.error(error);
        });

      return;
    }

    let obj = this.generateObject()
    obj.pageInfo.pageSize = 0;
    obj.filterParams.vendingsIds = this.checkedList;
    this.vendingsService.DownloadVendingsReport(obj).subscribe(
      (response) => {
        if(response.status == 201){
          this.toastr.success(response.message)
        }
        if(response.status == 200){
          if(!response.data.includes("http")){
            this.toastr.error("An error occured.");
          }
          else{
            window.location.href = response.data;
          }
        }
      },
      (_) => {
        this.toastr.error('An error occured')
      }
    )
  }

  validateDownload(): string[]{
    let messages: string[] = [];

    if(this.buNames.length === 0 && this.checkedList.length === 0){
      messages.push("Please select region  for download, or specific vending for download.");
    }

    const from = new Date(this.dateFrom.year, this.dateFrom.month - 1, this.dateFrom.day);
    const to = new Date(this.dateTo.year, this.dateTo.month - 1, this.dateTo.day);

    let diff = Math.abs(to.getTime() - from.getTime());
    diff = Math.ceil(diff / (1000 * 3600 * 24));

    if(diff > 31){
      messages.push("Gap between selected dates can't be greater than 31 days for download.");
    }

    return messages;
  }

  resetFilters() {
    this.buNames = []
    this.utNames = []

    this.detectionChange.detectChanges()
  }

  checkUncheckAll() {
    for (let i = 0; i < this.vendings.length; i++) {
      this.vendings[i].checked = this.masterSelected;
      this.checkForDownload(this.vendings[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.vendings.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.vendings.every(function(item:any) {
      return item.checked == true;
    })
  }
}
