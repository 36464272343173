import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentageFormatPipe implements PipeTransform {
  transform(value: any) {
    if(!value) return;

    if(value === '/'){
      return '/';
    }

    return value + '%';
  }
}
