import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MeterManagementDashboardService } from 'src/app/services/meter-management-dashboard.service';

@Component({
  selector: 'app-meter-triggers',
  templateUrl: './meter-triggers.component.html',
  styleUrl: './meter-triggers.component.scss'
})
export class MeterTriggersComponent implements OnInit {
  openCoverTamper: number = 0;
  openTerminal: number = 0;
  magneticField: number = 0;

  ngOnInit(): void {
    this.getMeterTriggersCardData()
  }

  constructor(
    private meterDashboardService: MeterManagementDashboardService,
    private toastr: ToastrService,

  ) { }

  getMeterTriggersCardData() {
    this.meterDashboardService.getMeterTriggersCardData().subscribe(response => {
      if (response.status === 200) {
        this.openCoverTamper = response.data.openCoverTamper
        this.openTerminal = response.data.openTerminal
        this.magneticField = response.data.magneticField

      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An Error Occurred")
    })
  }
}
