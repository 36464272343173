import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Dt } from 'src/app/models/dt.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { MeterService, TypeOfConsumer } from 'src/app/services/meter.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';

@Component({
  selector: 'app-dt-create',
  templateUrl: './dt-create.component.html',
  styleUrls: ['./dt-create.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class DtCreateComponent {
  public selectedDt: Dt = new Dt()
  showShadow = false;
  msnNumberErrorMsg = '';
  @Input() animationState = 'out';
  @ViewChild(DtCreateComponent) popupComponentRef!: DtCreateComponent;
  @Output() parentComp = new EventEmitter();

  @Input() feeder33s: any[] = [];
  @Input() amis: any[] = [];
  @Input() undertakings: any[] = []

  public dts: Dt[] = [];
  public feeder11s: any[] = [];
  public substations: any[] = [];

  public isDtMetered!: boolean;
  public msnValid: boolean = false;

  public isLatitudeValid: boolean = true;
  public isLongitudeValid: boolean = true;
  public isNameplateRatingValid: boolean = true;

  public isDtOwnershipValid: boolean = false;
  public isIsMeteredValid: boolean = false;
  public isAmiValid: boolean = false;
  public isUTValid: boolean = false;
  public isF33Valid: boolean = false;

  public isSSValid: boolean = true;
  public isF11Valid: boolean = true;

  public PermissionsEnum = PermissionsEnum;

  public is33TypeSelected: boolean = true; //For showing F11 inputs
  public SsSelected: boolean = false; //For showing Select Feeder11
  @ViewChild("f") f: any;

  public fileChosen: any = null;
  public file: any = null;
  public amiName :string = this.translationService.getByKeyFromCache('AMI');
  public utName :string = this.translationService.getByKeyFromCache('UT');
  public buName :string = this.translationService.getByKeyFromCache('BU');

  public isPublicOpt:any = [{label:'Public',value:true}, {label:'Private',value:false}];
  constructor(private ref: ElementRef,private dtService: DtService,
    private toastr: ToastrService,
    public authService: AuthService,
    private f11Service: Feeder11Service,
    private f33Service: Feeder33Service,
    private meterService: MeterService,
    private substationService: SubstationService,
    private translationService: TranslationService,
    private undertakingService:UndertakingService,
    private amiService: AmiService
    ) { }

  closeTab() {
    this.popupComponentRef.changeState(2);
  }
  changeState(number: Number, data?: any) {
    if (data)
      this.selectedDt = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
    if (this.selectedDt.feederType == 1) {
      this.getSubstationsForF33()
      this.getF11sForSubstation()
    }
  }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.dt-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }


changeMeterType(){
  this.isIsMeteredValid = true;

  this.selectedDt.amiId = 0;
  this.selectedDt.amiName = '';
  this.selectedDt.meterNumber = '';

  if (this.selectedDt.dtMeterType === 1){
    this.isDtMetered = true;
    this.isAmiValid = false;
    this.msnValid = false;
  }
  else if (this.selectedDt.dtMeterType == 2){
    this.isDtMetered = false;
    this.isAmiValid = true;
    this.msnValid = true;
  }
  else{
    this.isIsMeteredValid = false;
  }
}

onChangeFeederType(source: number) {
  this.resetInputs();
  if (source === 0){
    this.selectedDt.feeder33Id = 0;
    this.isF33Valid = false;
  }

  if (this.selectedDt.feederType == 0){ // 33 KV

    this.isF11Valid = true; // If type is 33KV, SS and F11 are not required
    this.isSSValid = true;
    this.selectedDt.feeder11Id = 0;
    this.selectedDt.substationId = 0;
    this.is33TypeSelected = true;

    this.f33Service.getAllForSelect().subscribe(response => {
       this.feeder33s = response.data;
        this.selectedDt.feeder33Id = 0; //Reset initial feeder33 value
    }, error => {
        this.toastr.error('An error occurred.');
    });
  }
  else { // 11 KV
    this.is33TypeSelected = false; //For showing Select Substation

    this.isF11Valid = false; // If type is 11KV, SS and F11 are required
    this.isSSValid = false;
  }
}

onChangeFeeder33Value(source: number){
  this.isF33Valid = true;

  if (source === 0){
    this.feeder11s = [];
    this.selectedDt.feeder11Id = 0;
    this.selectedDt.substationId = 0;
    this.selectedDt.feederName11 = ''

    if(this.selectedDt.feederType === 0){
      this.isSSValid = true;
      this.isF11Valid = true;
    }
    else if(this.selectedDt.feederType === 1){
      this.isSSValid = false;
      this.isF11Valid = false;
    }
  }

  if(this.selectedDt.feederType == 1)
  {
    this.getSubstationsForF33()
    this.is33TypeSelected = false
  }
}

getSubstationsForF33() {
  this.substationService.getSSConnectedToF33(this.selectedDt.feeder33Id).subscribe(response => {
    this.substations = response.data;
  }, error => {
    this.toastr.error('An error occurred.');
});
}

onChangeSubstationValue(source: number){
  this.isSSValid = true;
  if (source === 0){
    this.selectedDt.feeder11Id = 0;
    this.isF11Valid = false;
  }

  if(this.selectedDt.feederType == 1)
  {
    this.getF11sForSubstation()
  }
}

getF11sForSubstation() {
  this.f11Service.getAllConnectedToSubstation(this.selectedDt.substationId).subscribe(response => {
    this.feeder11s = response.data;
    this.SsSelected = true;
  }, error => {
    this.toastr.error('An error occurred.');
  });
}

getAllUtsForSelect(){
  this.undertakingService.getAllForSelect().subscribe(response => {
    this.undertakings = response.data;
  }, error => {
    this.toastr.error('An error occurred.');
  });
}

setMeterType(meterType: number){
  this.isDtMetered = true;

  if (meterType == 2)
    this.isDtMetered = false;

}

selectDt(id: number){
  this.dtService.get(id).subscribe(response => {
    if(response.status === 200){
        this.selectedDt = response.data;
        this.resetInputs();
        this.setMeterType(this.selectedDt.dtMeterType);
      if (this.selectedDt.feederType == 1)
      {
        this.onChangeFeederType(1);
        this.onChangeFeeder33Value(1);
        this.onChangeSubstationValue(1);
      }
      this.validateAssets();
  
      if (this.selectedDt.dtMeterType === 2)
      {
        this.msnValid = true;
        this.isAmiValid = true;
      }
      else if (this.selectedDt.dtMeterType === 1)
        this.validateMeterNumber();
    }
    else
      this.toastr.error(response.message);
  }, error => {
    this.toastr.error('An error occurred.');
  });
}

resetAllValidations(){
  this.isDtOwnershipValid = false;
  this.isIsMeteredValid = false;
  this.isAmiValid = false;
  this.isUTValid = false;
  this.isF33Valid = false;
  this.isF11Valid = true;
  this.isSSValid = true;
}

resetSelectedDt(){
  this.resetAllValidations();
  this.selectedDt = new Dt();
  this.onChangeFeederType(0);
  this.isDtMetered = true;

  if(this.f)
    this.f.submitted = false;
}

validateAssets(){
  if (this.selectedDt.dtMeterType !== 0)
    this.isIsMeteredValid = true;
  if (this.selectedDt.isPublic !== 0)
    this.isDtOwnershipValid = true;
  if (this.selectedDt.amiId !== 0)
    this.isAmiValid = true;
  if (this.selectedDt.feeder11Id !== 0)
    this.isF11Valid = true;
  if (this.selectedDt.connectedUtId !== 0)
    this.isUTValid = true;
  if (this.selectedDt.feeder33Id !== 0)
    this.isF33Valid = true;
}

onChangeF11Value(){
  this.isF11Valid = true;
}

resetInputs(){
  this.SsSelected = false; //For showing Select Feeder11
  this.is33TypeSelected = true;
  this.substations = [];
  this.feeder11s = [];

}

resetValidations(){
  this.isLatitudeValid = true;
  this.isLongitudeValid = true;
  this.isNameplateRatingValid = true;
  this.isUTValid = false;
  this.isDtOwnershipValid = false;
  this.isUTValid = false;
}

validateInputs(){

  this.resetValidations();

  if(Number.isNaN(Number(this.selectedDt?.latitude)) || Number(this.selectedDt?.latitude) < -90 || Number(this.selectedDt?.latitude) > 90)
    this.isLatitudeValid = false;

  if(Number.isNaN(Number(this.selectedDt?.longitude)) || Number(this.selectedDt?.longitude) < -180 || Number(this.selectedDt?.longitude) > 180)
    this.isLongitudeValid = false;

  if(Number.isNaN(Number(this.selectedDt?.nameplateRating)) || Number(this.selectedDt?.nameplateRating) < 0)
    this.isNameplateRatingValid = false;

  if(this.selectedDt?.connectedUtId !== 0)
    this.isUTValid = true;

  if (this.selectedDt?.isPublic !== 0)
    this.isDtOwnershipValid = true;

  if (!this.isLatitudeValid || !this.isLongitudeValid || !this.isNameplateRatingValid || !this.isUTValid || !this.isAmiValid || !this.msnValid || !this.isDtOwnershipValid || !this.isF33Valid || !this.isF11Valid)
    return false;

  return true;
}

save(){
  if(!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate))
  {
    this.toastr.error("You don't have permission.");
    return;
  }

  if(!this.validateLongAndLat())
    return;
  
  if (!this.isF11Valid || !this.isSSValid)
    return

  if (this.selectedDt.meterNumber && this.selectedDt.meterMaxDigit != null && (this.selectedDt.meterMaxDigit ?? 0) < 1) {
    this.toastr.error('Meter Max Digit must be a positive number')
    return
  }

  if (this.selectedDt.meterNumber && this.selectedDt.multiplierFactor != null && this.selectedDt.multiplierFactor < 1) {
    this.toastr.error('Multiplier Factor must be a positive number')
    return
  }

  if (this.selectedDt.feeder11Id != 0) {
    this.selectedDt.feederName11 = this.feeder11s.find(x => x.value == this.selectedDt.feeder11Id)?.name
  }
  if(this.selectedDt.feeder33Id != 0)
    this.selectedDt.feederName33 = this.feeder33s.find(x => x.value == this.selectedDt.feeder33Id)?.name

  let ami = this.amis.find((x: { value: number; }) => x.value == this.selectedDt.amiId)
  this.selectedDt.amiName = ami ? ami.name : ''

  this.dtService.save(this.selectedDt).subscribe(response => {
    if(response.status === 200){
      this.toastr.success(response.message);
      this.resetSelectedDt();
      this.parentComp.emit();
      this.changeState(2);
    }else
      this.toastr.error(response.message);
  }, error => {
    this.toastr.error('An error occurred.');
  });
}

validateMeterNumber(){
  if (this.selectedDt.amiId !== 0)
  {
    this.isAmiValid = true;

    if (this.selectedDt.meterNumber !== '')
    {
      if (this.validateMeter(this.selectedDt?.amiId, this.selectedDt?.meterNumber) === true)
        this.msnValid = true;
      else
        this.msnValid = false;
    }
  }
  else{
    this.isAmiValid = false;
    this.msnValid = false;
  }
}


validateMeter(amiId: number, meterNumber: string){
  let ami = this.amis.find((x: { value: number; }) => x.value == amiId)?.name;
  if (ami && ami != '')
  {
    this.msnNumberErrorMsg = this.meterService.validateMeter(ami, meterNumber, TypeOfConsumer.Feeder33)
      if (this.msnNumberErrorMsg == '')
      return true
  }
  return false
}

validateLongAndLat(){
  if(Number.isNaN(Number(this.selectedDt?.longitude))){
    this.toastr.error('Longitude must be numeric value between -180 and 180.');
    return false;
  }

  if(Number(this.selectedDt?.longitude) < -180 || Number(this.selectedDt?.longitude) > 180){
    this.toastr.error('Longitude must be in -180 to 180 range.');
    return false;
  }

  if(Number.isNaN(Number(this.selectedDt?.latitude))){
    this.toastr.error('Latitude must be numeric value between -90 and 90.');
    return false;
  }

  if(Number(this.selectedDt?.latitude) < -90 || Number(this.selectedDt?.latitude) > 90){
    this.toastr.error('Latitude must be in -90 to 90 range.');
    return false;
  }

  return true;
}

}
