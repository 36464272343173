<div class="popup-overlay" *ngIf="isPopupOpen" (click)="changeState()">
    <div class="popup-content" click-stop-propagation>
        <div class="close-content">
            <div class="close-btn" (click)="closePopup()">
                <img alt="Close" src="assets/images-new-design/cancelView.png">
            </div>
        </div>
        <div class="header">
            {{selectedAssetPerfomanceF33.feederName}}</div>
        <div class="horizontal-line"></div>
        <div class="download-content">

            <div class="form">
                <label class="label-opt">Feeder 33 Name</label>
                <div class="assetForm">{{selectedAssetPerfomanceF33.feederName}}</div>
            </div>
            <div class="form">
                <label class="label-opt">Feeder 33 Number</label>
                <div class="assetForm2">{{selectedAssetPerfomanceF33.feeder33No}}</div>
                <img alt="Copy" class="icons" src="assets/images-new-design/copy.png" (click)="copyToClipboard()">

            </div>

            <div class="form">
                <label class="label-opt">Feeder 33 Type</label>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.isPublic == 'True'"><span class="public"><img alt=""
                            class="icon" src="../../../../../assets/images-new-design/globe.png">Public</span></div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.isPublic == 'False'"><span class="private"><img alt=""
                            class="icon" src="../../../../../assets/images-new-design/lock.png">Private</span></div>
            </div>
            <div class="form">
                <label class="label-opt">Transmission Station</label>
                <div class="assetForm">{{selectedAssetPerfomanceF33.tsName}}</div>
            </div>
            <div class="form">
                <label class="label-opt">Energy Received</label>
                <div *ngIf="!selectedAssetPerfomanceF33.energyReceived"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.energyReceived">
                    {{selectedAssetPerfomanceF33.energyReceived}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Nameplate Rating</label>
                <div *ngIf="!selectedAssetPerfomanceF33.nameplateRating"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.nameplateRating">
                    {{selectedAssetPerfomanceF33.nameplateRating }}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Daily Load</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyLoad"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyLoad">
                    {{selectedAssetPerfomanceF33.avgDailyLoad | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Daily Voltage 1</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyVoltage"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyVoltage">
                    {{selectedAssetPerfomanceF33.avgDailyVoltage | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Daily Voltage 2</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyVoltage2"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyVoltage2">
                    {{selectedAssetPerfomanceF33.avgDailyVoltage2 | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Daily Voltage 3</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyVoltage2"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyVoltage2">
                    {{selectedAssetPerfomanceF33.avgDailyVoltage2 | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Daily Current 1</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyCurrent"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyCurrent">
                    {{selectedAssetPerfomanceF33.avgDailyCurrent | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Daily Current 2</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyCurrent2"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyCurrent2">
                    {{selectedAssetPerfomanceF33.avgDailyCurrent2 | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Daily Current 3</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyCurrent3"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyCurrent3">
                    {{selectedAssetPerfomanceF33.avgDailyCurrent3 | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Power Factor (Total)</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyFactor"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyFactor">
                    {{selectedAssetPerfomanceF33.avgDailyFactor | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Power Factor 1</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyFactor1"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyFactor1">
                    {{selectedAssetPerfomanceF33.avgDailyFactor1 | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Power Factor 2</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyFactor2"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyFactor2">
                    {{selectedAssetPerfomanceF33.avgDailyFactor2 | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg Power Factor 3</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyFactor3"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyFactor3">
                    {{selectedAssetPerfomanceF33.avgDailyFactor3 | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">Avg AMB. Temp</label>
                <div *ngIf="!selectedAssetPerfomanceF33.avgDailyAMB"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.avgDailyAMB">
                    {{selectedAssetPerfomanceF33.avgDailyAMB | number:'0.3-3'}}
                </div>
            </div>
            <div class="form">
                <label class="label-opt">History</label>
                <div *ngIf="!selectedAssetPerfomanceF33.history"><span class="NaN">N\A</span>
                </div>
                <div class="assetForm" *ngIf="selectedAssetPerfomanceF33.history">
                    {{selectedAssetPerfomanceF33.history}}
                </div>
            </div>
        </div>
