<div>
  <div *ngIf="feeders33.length == 0" class="row justify-content-center">
    <label>Please choose at least one item from the list, or you can use the search option to find and select your desired item.</label>
  </div>
  <div *ngIf="!showTable" class="container-fluid h-100">
    <div class="row">
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-11">
            <p class="chart-title" style="padding-right: 7.8rem;">Total Energy Received into Network</p>
            <div class="row justify-content-center" style="padding-right: 3rem;">
              <img alt="" src="assets\images-new-design\Ellipse1.svg">
              <span class="chart-subtitle">Asset Energy Received</span>
              <img alt="" src="assets\images-new-design\Ellipse2.svg" style="padding-left: 2rem;">
              <span class="chart-subtitle">Asset Energy Losses</span>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-sm-1 text-right">
            <p class="chart-label-vertical">Energy(MWh)</p>
          </div>
          <div class="col-11 chart-bar">
            <canvas baseChart [datasets]="receivedData" [labels]="receivedLabels" [options]="barChartOptions"
              [plugins]="barChartPlugins" [legend]="barChartLegend" [height]="500">
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showTable">
  <app-asset-table
    [avgReceived]="avgReceived"
    [avgEnergyConsumption]="avgEnergyConsumption"
    [avgLosses]="avgLosses"
    [tableData]="tableData"
    [tabNumber]="Submenu"
  ></app-asset-table>
</div>
