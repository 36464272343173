<app-field-slider (reloadTable)="reloadTable(1)"></app-field-slider>
<main class="container-fluid field-operation-dashboard-page">
  <div class="fo-container">
    <!-- page title -->
    <div class="page-title">
      <span>Dashboard</span>
      <button class="btn-white" (click)="reportClicked()">
        Report
        <span>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5003 10.667C16.2793 10.667 16.0674 10.7548 15.9111 10.9111C15.7548 11.0674 15.667 11.2793 15.667 11.5003V14.8337C15.667 15.0547 15.5792 15.2666 15.4229 15.4229C15.2666 15.5792 15.0547 15.667 14.8337 15.667H3.16699C2.94598 15.667 2.73402 15.5792 2.57774 15.4229C2.42146 15.2666 2.33366 15.0547 2.33366 14.8337V11.5003C2.33366 11.2793 2.24586 11.0674 2.08958 10.9111C1.9333 10.7548 1.72134 10.667 1.50033 10.667C1.27931 10.667 1.06735 10.7548 0.91107 10.9111C0.75479 11.0674 0.666992 11.2793 0.666992 11.5003V14.8337C0.666992 15.4967 0.930384 16.1326 1.39923 16.6014C1.86807 17.0703 2.50395 17.3337 3.16699 17.3337H14.8337C15.4967 17.3337 16.1326 17.0703 16.6014 16.6014C17.0703 16.1326 17.3337 15.4967 17.3337 14.8337V11.5003C17.3337 11.2793 17.2459 11.0674 17.0896 10.9111C16.9333 10.7548 16.7213 10.667 16.5003 10.667ZM8.40866 12.092C8.48791 12.1679 8.58137 12.2273 8.68366 12.267C8.78341 12.3111 8.89127 12.3339 9.00033 12.3339C9.10938 12.3339 9.21724 12.3111 9.31699 12.267C9.41929 12.2273 9.51274 12.1679 9.59199 12.092L12.9253 8.75866C13.0822 8.60174 13.1704 8.38891 13.1704 8.16699C13.1704 7.94507 13.0822 7.73225 12.9253 7.57533C12.7684 7.41841 12.5556 7.33025 12.3337 7.33025C12.1117 7.33025 11.8989 7.41841 11.742 7.57533L9.83366 9.49199V1.50033C9.83366 1.27931 9.74586 1.06735 9.58958 0.91107C9.4333 0.75479 9.22134 0.666992 9.00033 0.666992C8.77931 0.666992 8.56735 0.75479 8.41107 0.91107C8.25479 1.06735 8.16699 1.27931 8.16699 1.50033V9.49199L6.25866 7.57533C6.18096 7.49763 6.08872 7.43599 5.9872 7.39394C5.88568 7.35189 5.77688 7.33025 5.66699 7.33025C5.55711 7.33025 5.4483 7.35189 5.34678 7.39394C5.24527 7.43599 5.15302 7.49763 5.07533 7.57533C4.99763 7.65302 4.93599 7.74527 4.89394 7.84678C4.85189 7.9483 4.83025 8.05711 4.83025 8.16699C4.83025 8.27688 4.85189 8.38568 4.89394 8.4872C4.93599 8.58872 4.99763 8.68096 5.07533 8.75866L8.40866 12.092Z" fill="#525252" />
          </svg>
        </span>
      </button>
    </div>
    <div class="h-line"></div>

    <!-- count box -->
    <div class="content-card">
      <div class="work-order-header">
        <span class="subtitle">Work order management</span>
      </div>
        <div class="c-header">
          <div class="count-boxes">
            <div class="box-item">
              <field-count-box-new label="Ongoing" [data]="cardData.ongoing" [isYellow]="true" [secondData]="cardData.ongoingOverdue+' Overdue'" [viewDetails]="true" (viewDetailsClickedChanged)="viewDetailsClick('ongoing')"></field-count-box-new>
            </div>
          </div>
          <div class="count-boxes">
            <div class="box-item">
              <field-count-box-new label="Overdue" [data]="cardData.unassigned" [isRed]="true" [viewDetails]="true" (viewDetailsClickedChanged)="viewDetailsClick('overdue')"></field-count-box-new>
            </div>
          </div>
          <div class="count-boxes">
            <div class="box-item">
              <field-count-box-new label="Completed" [data]="cardData.completed" [isGreen]="true" [viewDetails]="true" (viewDetailsClickedChanged)="viewDetailsClick('completed')"></field-count-box-new>
            </div>
          </div>
          <div class="count-boxes">
            <div class="box-item">
              <field-count-box-new label="Canceled" [data]="cardData.canceled" [isBlue]="true" [viewDetails]="true" (viewDetailsClickedChanged)="viewDetailsClick('canceled')"></field-count-box-new>
            </div>
          </div>
        </div>
      </div>
      <!-- tabs -->
      <div class="content-card">
        <span class="subtitle">Summary Analytics on Predictive Asset Maintenance Log</span>
        <ul class="nav nav-tabs c-tabs">
          <li class="nav-item" role="presentation">
            <button [ngClass]="{'active': activeTab === '33KV Feeders'}" (click)="activeTab = '33KV Feeders'; reloadTable(1)" class="nav-link">33KV Feeders   <span class="bubble">{{numberF33Assets}}</span></button>
          </li>
          <li class="nav-item" role="presentation">
            <button [ngClass]="{'active': activeTab === '11KV Feeders'}" (click)="activeTab = '11KV Feeders'; reloadTable(1)" class="nav-link">11KV Feeders  <span class="bubble">{{numberF11Assets}}</span></button>
          </li>
          <li class="nav-item" role="presentation">
            <button [ngClass]="{'active': activeTab === 'DTS'}" (click)="activeTab = 'DTS'; reloadTable(1)" class="nav-link">DTS   <span class="bubble">{{numberDtsAssets}}</span></button>
          </li>
        </ul>
        <!-- table -->
        <div class="field-page-table">
          <table class="">
            <thead class="table-header">
              <tr>
                <th *ngIf="this.activeTab != '33KV Feeders'" class="regions">{{buName | uppercase}} NAME</th>
                <th *ngIf="this.activeTab == '33KV Feeders'" class="regions">TRANSMISSION STATION NAME</th>
                <th><span>EXCESSIVE VOLTAGE DROP</span></th>
                <th><span>CURRENT LOSS</span></th>
                <th><span>POWER LOSS</span></th>
                <th><span>ABNORMAL OPERATING TEMP</span></th>
                <th><span>EXCESSIVE SOUND</span></th>
                <th><span>TOTAL</span></th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr *ngFor="let item of tableData" class="bodyRow ">
                <!--clickable actions-click-->
                <td *ngIf="this.activeTab != '33KV Feeders'" class="buClass"><span>{{item.bu | hasValue}}</span></td>
                <td *ngIf="this.activeTab == '33KV Feeders'" class="buClass"><span>{{item.ts | hasValue}}</span></td>
                <td><span>{{item.excessiveVoltageDrop}}</span></td>
                <td><span>{{item.currentLoss}}</span></td>
                <td><span>{{item.powerLoss}}</span></td>
                <td><span>{{item.abnormalOperatingTemp}}</span></td>
                <td><span>{{item.excessiveSound}}</span></td>
                <td><span>{{item.total}}</span></td>
              </tr>
            </tbody>
          </table>
          <div class="col-sm-12 revenue-pagination-wrapper">
            <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-fo-pagination-new>
          </div>
        </div>
      </div>
      <br>



    </div>
</main>
