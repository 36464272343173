import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Tariff } from 'src/app/models/tariff.model';
import { AuthService } from 'src/app/services/auth.service';
import { TariffService } from 'src/app/services/tariff.service';

@Component({
  selector: 'app-create-tarrif',
  templateUrl: './create-tarrif.component.html',
  styleUrls: ['./create-tarrif.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class CreateTariffComponent {
  public selectedTariff: Tariff = new Tariff()
  showShadow = false;
  @Output() parentComp = new EventEmitter();

  @Input() animationState = 'out';
  @ViewChild(CreateTariffComponent) popupComponentRef!: CreateTariffComponent;

  constructor(private toastr: ToastrService,
    public authService: AuthService,
    public tariffService: TariffService,
    private ref: ElementRef
    ) { }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isSliderClicked){
          this.changeState(2);
        }
      }
  }

  changeState(number: Number, data?: any) {
    if (data === 0) {
      this.selectedTariff = new Tariff();
    }
   if (data)
     this.selectedTariff = data;
   if (number === 1) {
     this.toggleShowDiv("open");
     this.showShadow = true;
   }
   if (number === 2) {
     this.toggleShowDiv("close");
      this.showShadow = false;
   }
 }


  save(){
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    if(Number.isNaN(Number(this.selectedTariff?.rate))){
      this.toastr.error('Rate must be numeric value.');
      return;
    }
    if((this.selectedTariff.name && this.selectedTariff.rate) !== ''){
      this.tariffService.save(this.selectedTariff).subscribe(response => {
        if(response.status === 200){
          this.toastr.success(response.message);
          this.changeState(2);
          this.parentComp.emit();
         }else
          this.toastr.error(response.message);
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }
  closeTab() {
    this.popupComponentRef.changeState(2);
  }
}
