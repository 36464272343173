import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-crud-delete-modal',
  templateUrl: './crud-delete-modal.component.html',
  styleUrls: ['./crud-delete-modal.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class CrudDeleteModalComponent {
  @Input() selectedItem: any;
  @Output() confirmDelete: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelDelete: EventEmitter<void> = new EventEmitter<void>();

  public isPopupOpen: boolean = false;
  showShadow = false;

  constructor(private elementRef: ElementRef) {}

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.isPopupOpen){
      if (this.elementRef.nativeElement.contains(event.target) || event.target === this.elementRef.nativeElement) {
        if (!isSliderClicked) {
          this.closePopup();
        }
      }
    }
  }

  onConfirmDelete() {
    this.confirmDelete.emit();
    this.isPopupOpen = false;
    this.showShadow = false;

  }

  onCancelDelete() {
    this.cancelDelete.emit();
    document.body.classList.remove('menu-open');
  }

  openTabDelete() {
    this.isPopupOpen = true;
    this.showShadow = true;
    document.body.classList.add('menu-open');
  }
  closePopup() {
    this.isPopupOpen = false;
    this.showShadow = false;
  }

  changeState(id?: number) {
    this.isPopupOpen = true;
    this.showShadow = true;
  }

}
