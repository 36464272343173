export class DtAssetPerfomance{
    id:number = 0
    checked:boolean = false
    dtName: string = ''
    address: string = ''
    dtNo: string = ''
    isPublic: string = ''
    buName: string = ''
    utName: string = ''
    energyReceived: number = 0
    nameplateRating: number = 0
    avgDailyLoad: number = 0
    avgDailyVoltage: number = 0
    avgDailyVoltage2: number = 0
    avgDailyVoltage3: number = 0
    avgDailyCurrent: number = 0
    avgDailyCurrent2: number = 0
    avgDailyCurrent3: number = 0
    avgDailyFactor: number = 0
    avgDailyAMB: number = 0
    history: string = ''
    customerAddress: string = ''
    maxDailyLoadCurrent: number = 0
    fullDailyLoadCurrent: number = 0
}
