<div class="popup-overlay" *ngIf="isPopUp" (click)="changeState(2)">
    <div class="popup-content" click-stop-propagation>
        <div class="red-line">

        </div>
        <div class="tab-header">

            <div class="overdue-bills-header">
                <div class="overdue-bills-row">
                    <div class="overdue-bills-page-header">
                        <span>Current Total Collection</span>
                    </div>

                </div>
                <div class="back-button" >
                    <img alt="Close" src="assets/images-new-design/Close.svg" (click)="changeState(2)">
                </div>
            </div>
        </div>

        <div class="tab-body-info">
            <div class="tabs-wrapper">
                <div class="tabs-button" [ngClass]="{'selected': tab == 1}" (click)="tab = 1"><span>This Week</span></div>
                <div class="tabs-button" [ngClass]="{'selected': tab == 2}" (click)="tab = 2"><span>This Month</span></div>
            </div>
            <div *ngIf="tab == 1">
                <div class="revenue-table">
                    <div class="revenue-table-page">
                        <table class="payment-collections-table">
                            <thead>
                                <tr>
                                    <th><span>{{buName}}</span></th>
                                    <th><span>AMOUNT</span></th>
                                    <th><span>CUSTOMERS</span></th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="td-padding" *ngFor="let item of weeklyCollection">
                                    <td title="{{item.bu}}"><span>{{item.bu | hasValue}}</span></td>
                                    <td title="{{item.amount}}"><span>{{item.amount | number | hasValue}}</span></td>
                                    <td title="{{item.customers}}"><span>{{item.customers | number | hasValue}}</span></td>
                                </tr>
                                <tr>
                                    <td class="overall-total">
                                        <span>OVERALL TOTAL</span>
                                    </td>
                                    <td class="overall-total">
                                        <span>{{weeklySum | number}}</span>
                                    </td>
                                    <td class="overall-total">
                                        <span>{{weeklyCustomersNo | number}} Customers</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="tab == 2">
                <div class="revenue-table">
                    <div class="revenue-table-page">
                        <table class="payment-collections-table">
                            <thead>
                                <tr>
                                    <th><span>{{buName}}</span></th>
                                    <th><span>AMOUNT</span></th>
                                    <th><span>CUSTOMERS</span></th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="td-padding" *ngFor="let item of monthlyCollection">
                                    <td title="{{item.bu}}"><span>{{item.bu | hasValue}}</span></td>
                                    <td title="{{item.amount}}"><span>{{item.amount | number | hasValue}}</span></td>
                                    <td title="{{item.customers}}"><span>{{item.customers | number | hasValue}}</span></td>
                                </tr>
                                <tr class="overall-total-wrapper">
                                    <td>
                                        <div class="overall-total"><span>OVERALL TOTAL</span></div>
                                    </td>
                                    <td>
                                        <div class="overall-column"><span>{{monthlySum | number}}</span></div>
                                    </td>
                                    <td>
                                        <div class="overall-column-last"><span>{{monthlyCustomersNo | number}} Customers</span></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="red-line red-line-bottom">

        </div>
    </div>
</div>
