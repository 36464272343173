export class Concentrator{
    id: number = 0;
    serialNumber: string = '';
    longitude: string = '';
    latitude: string = '';
    address: string = '';
    amiName: string = '';
    amiId: number = 0;
    countOfMeters: number = 0;
    status: string = '';
    simCardInfo: string = '';
    lastReadTime: any = undefined;
    checked: boolean = false;
}
