import { Component, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { AccountService } from 'src/app/services/account.service';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';
import { MultipleAmisPopUpComponent } from '../load-profile/multiple-amis-pop-up/multiple-amis-pop-up.component';

@Component({
  selector: 'app-remote-token-load',
  templateUrl: './remote-token-load.component.html',
  styleUrl: './remote-token-load.component.scss'
})
export class RemoteTokenLoadComponent{

  public accountValue: string = '';
  public tokenValue: string = '';
  public initialScreen: boolean = true
  public invalidAccount: boolean = false;
  showInvalidInputError = false;

  public accountData: any;
  amiId: number = -1;
  meterId: number = 0
  @ViewChild(MultipleAmisPopUpComponent) multipleAmisPopUp!: MultipleAmisPopUpComponent;

  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')

  public tokenTypes: DictionaryItem[] = [{"value": 1, "name": "Credit token"}, {"value": 2, "name": "Clear tamper token"}]
  public selectedTokenType: DictionaryItem = {"value": 0, "name": "Token type"}

  constructor(
    private translationService: TranslationService,
    private toastr: ToastrService,
    private meterService: MeterService,
    private accService: AccountService
  ){}

  search(){
    if (this.accountValue == '') {
      this.showInvalidInputError = true
      return
    }

    let obj = {
      MSN: this.accountValue,
      AmiId: this.amiId
    }
    this.getAccBySearch(obj)
  }

  searchAgain(){
    this.initialScreen = true;
    this.accountValue = '';
    this.tokenValue = '';
    this.selectedTokenType = {"value": 0, "name": "Token type"}
    if(this.accountData != null)
      this.invalidAccount = false
  }

  accValueChanged() {
    if (this.accountValue != '') {
      this.showInvalidInputError = false
    }
  }

  getAccBySearch(obj: any) {
    this.accService.getAccBySearch(obj).subscribe(response => {
      if (response.status === 200) {
        this.accountData = response.data
        if (response.data.multipleAmis) {
          this.multipleAmisPopUp.changeState()
          let obj = {
            searchText: this.accountValue
          }
          this.meterService.getAllAmisWithMSN(obj).subscribe(response => {
            response.data.forEach((element: any) => {
              element.checked = false
            });
            this.multipleAmisPopUp.amis = response.data
          })
        } else {
          this.accService.getMeterIdForAccountNo(this.accountData.accountNo).subscribe(response => {
            if (response.status === 200) {
              this.meterId = response.data
              this.initialScreen = false
              this.getMeter()
            } else {
              this.toastr.error(response.message)
            }
          }, _ => {
            this.toastr.error('An error occurred.')
          })        }
      }
      else if (response.status === 400 && !response.data.multipleAmis) {
        this.toastr.error(response.message)
      } else if (response.status === 404) {
        this.invalidAccount = true;
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  setMetersAmi(amiId: any) {
    this.amiId = amiId
    let obj = {
      MSN: this.accountValue,
      amiId: amiId
    }
    this.meterService.getMeterIdFromMSNAndAmi(obj).subscribe(response => {
      if (response.status === 200) {
        this.meterId = response.data
        this.getMeter(true)
      } else if (response.status === 400) {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getMeter(getAcc = false) {
    this.meterService.get(this.meterId).subscribe(response => {
      if (response.status === 200) {
        let obj = {
          MSN: this.accountValue,
          AmiId: this.amiId
        }
        if (getAcc) {
          this.getAccBySearch(obj)
        }
        this.amiId = -1
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  selectTokenType(item: DictionaryItem) {
    this.selectedTokenType = item
  }

  loadToken(){
    if(this.selectedTokenType.value == 0)
      this.toastr.error("Token type is required!");
    if(this.tokenValue == '')
      this.toastr.error("Token value must be provided!");

    if(this.selectedTokenType.value != 0 && this.tokenValue != ''){

      let obj = {
        tokenType: this.selectedTokenType.value,
        tokenValue: this.tokenValue
      }

      this.meterService.loadRemoteToken(obj).subscribe(response => {
        if(response.status === 200 || response.status === 'OK')
          this.toastr.success(response.message)
        else
          this.toastr.error(response.message)
      })
    }

  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }
}
