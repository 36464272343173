import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';



@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
	readonly DELIMITER = '/';

	parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10),
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
    if (date) {
      const day = date.day < 10 ? '0' + date.day : date.day;
      const month = date.month < 10 ? '0' + date.month : date.month;
      const year = (date.year % 100) < 10 ? '0' + (date.year % 100) : (date.year % 100);

      return day + this.DELIMITER + month + this.DELIMITER + year;
  }
  return '';
	}
}
@Component({
  selector: 'app-multi-filter-datepicker',
  templateUrl: './multi-filter-datepicker.component.html',
  styleUrls: ['./multi-filter-datepicker.component.scss'],
  providers: [
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class MultiFilterDatepickerComponent{

  @Input() isMonthly: boolean = false;
  @Input() placementPosition: string = 'bottom';
  @Input() isOnlyOneMonth: boolean = false;
  @Input() date: any = '';
  @Input() name: string = 'Created'
  @Input() placeholder: string = 'Select'


  @Output() dateChange = new EventEmitter();

  public filterByDateClicked: boolean = false;
  public isDatePickerOpened: boolean = false;


  isDisabled = (date:any, day:any) => {
    if (this.isMonthly) {
      return date.day !== 1;
    }
    return false;
  };


  public changeDate(date: NgbDate) {
    this.date = date

    this.dateChange.emit(date);
  }

  public filterClick(){
    this.isDatePickerOpened = false
    this.filterByDateClicked = !this.filterByDateClicked
  }

  public close(){
    if(!this.isDatePickerOpened){
      this.filterByDateClicked = false;
    }
    else{
      this.isDatePickerOpened=false;
    }
  }

  handleMouseDown(event: MouseEvent) {
    event.preventDefault();
  }


}
