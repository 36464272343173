<router-outlet></router-outlet>
<table class="standard-table">
    <thead>
        <tr>
            <th><span><input class="input-checkmark" type="checkbox" name="list_name" value="h1"
                        [(ngModel)]="masterSelected" (change)="checkUncheckAll()" /></span></th>
            <th><span>TEAM ID</span></th>
            <th><span>TEAM NAME</span></th>
            <th><span>MEMBERS/AGENTS</span></th>
            <th><span>TEAM LEAD</span></th>
            <th><span>NO OF ASSIGN TASK</span></th>
            <th><span>{{buName | uppercase}}</span></th>
            <th><span>{{utName | uppercase}}</span></th>

            <th class="small-td"><span></span></th>
        </tr>
    </thead>
    <tbody class="table-body">
        <tr *ngFor="let item of womTeamData" data-id="item.id" (click)="openDetails(item.id)" class="clickable actions-click">

            <td (click)="false; $event.stopPropagation();">
                <input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked"
                    (change)="checkForDownload(item.id)" />
            </td>
            <td><span>{{item.teamId}}</span></td>
            <td><span>{{item.name | hasValue}}</span></td>
            <td>
                <span>
                    <div class="header-icon">
                        <div class="pic">

                            <ng-container *ngIf="item.photoUrl && item.photoUrl.length > 0">
                                <div [style.width.rem]="(item.photoUrl.length * 1.7) + 2" class="images-container">
                                    <img alt="" [style.left.rem]="(i * 1.7)" *ngFor="let image of item.photoUrl; let i = index"
                                        [src]="image != null ? image : '/assets/images-new-design/defaultImg.png'" />
                                </div>
                            </ng-container>
                        </div>
                        <div class="text">
                            <ng-container *ngIf="item.agents && item.agents.length > 0; else noAgents">
                                <span class="text">{{ item.agents[0] }}</span>
                                <ng-container *ngIf="item.agents.length > 1">
                                    <span> +{{ item.agents.length - 1 }}</span>
                                </ng-container>
                            </ng-container>
                            <ng-template #noAgents>
                                <div class="noMembers">
                                    <span>No assign members/agents</span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </span>
            </td>
            <td>
                <span>
                    <div class="header-icon">
                        <img alt="Lead" class="image" *ngIf="item.image !== null" src="{{ item.image }}">
                        <span>{{ item.teamLeadName | hasValue }} </span>
                    </div>
                </span>
            </td>
            <td><span>{{item.noOfAssignTask}}</span></td>
            <td><span>{{item.buName}}</span></td>
            <td><span>{{item.utName}}</span></td>

            <td class="small-td clickable actions-click"><span><img alt="..."
                        src="assets/images/3-dot.svg"></span>
            </td>
        </tr>
    </tbody>
</table>
