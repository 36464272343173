export enum MenuItem {
    None = 0,
    Network = 1,
    Organisation = 2,
  }
  
export enum SubMenuItem {
    Ts = 0,
    Feeders33 = 1,
    Feeders11 = 2,
    Dt = 3,
    Bu = 4,
    Ut = 5
  }
  
export enum TimePeriod {
    Monthly = 0,
    Daily = 1
  }
  
export const menuSubmenuMap: Map<MenuItem, SubMenuItem> = new Map([[MenuItem.None, SubMenuItem.Ts], [MenuItem.Network, SubMenuItem.Ts], [MenuItem.Organisation, SubMenuItem.Bu]]);
  