export class Undertaking{
    id: number = 0;
    name: string = '';
    longitude: string = '';
    latitude: string = '';
    address: string = '';
    buName: string = '';
    buId: number = 0;
    checked: boolean = false;
}
