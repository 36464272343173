import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FilterDropdownSearchComponent } from 'src/app/components/common-new-design/filter-dropdown-search/filter-dropdown-search.component';
import { MultiFilterComponent } from 'src/app/components/common-new-design/multi-filter/multi-filter.component';
import { transformToString } from 'src/app/helpers/dateUtils';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { EnergyDistribution, NetworkPerfomanceTabs, OutageDurationAndFrequency, SystemAvailability } from 'src/app/models/energy-distribution';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-dashboard-network-preformance',
  templateUrl: './dashboard-network-preformance.component.html',
  styleUrls: ['./dashboard-network-preformance.component.scss']
})
export class DashboardNetworkPreformanceComponent implements OnInit {

  public dateFrom: any = "";
  public dateTo: any = "";
  public filterByDateClicked: boolean = false;

  public energyDistribution: EnergyDistribution = new EnergyDistribution
  public outage: OutageDurationAndFrequency = new OutageDurationAndFrequency
  public availability: SystemAvailability = new SystemAvailability

  public showFormula: boolean = false
  
  public tabsEnum = NetworkPerfomanceTabs
  public mainTab: NetworkPerfomanceTabs = NetworkPerfomanceTabs.Feeder

  public buSelectedId: number = 0;
  public buSelected: string = "";
  public utSelectedId: number = 0;
  public utSelected: string = "";
  public tsSelected: string = "";
  public tsSelectedId: number = 0
  public f33Selected: string = ""
  public f33SelectedId: number = 0
  public f11Selected: string = ""
  public f11SelectedId: number = 0
  public dtSelected: string = ""
  public dtSelectedId: number = 0
  public assetTypeSelected: number = -1
  public assetTypeSelectedName: string = ''

  public dtSearchText: string = ""
  public f11SearchText: string = ""
  public f33SearchText: string = ""

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public valute: string = this.translationService.getByKeyFromCache('Valute')

  public searchText: string = ''

  public filter: number = 0
  public buItems: any[] = [];
  public utItems: any[] = [];
  public tsItems: any[] = [];
  public f33Items: any[] = []
  public f11Items: any[] = []
  public dtItems: any[] = []
  public assetTypeItems: DictionaryItem[] = []

  @ViewChild('child1') child1!: FilterDropdownSearchComponent

  public width: string = "350px";

  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  public feeder33Name: string = "Feeder33"
  public feeder11Name: string = "Feeder11"
  public dtName: string = "Dt"
  public feeders33: any = { name: this.feeder33Name, data: [], dropdown: true };
  public feeders11: any = { name: this.feeder11Name, data: [], dropdown: true };

  public feeders: any = { name: this.feeder11Name, data: [], dropdown: true };

  public dts: any = { name: this.dtName, data: [], dropdown: true}
  public feeder11Selected: any = {value: 0, name: '', checked: false}
  public feeder33Selected: any = {value: 0, name: '', checked: false}
  public dtsSelected: any = {value: 0, name: '', checked: false}
  public f33Ids: number[] = []
  public f11Ids: number[] = []
  public dtIds: number[] = []

  typeId: number[] = [];
  public typeName: string = 'Feeder Type';
  public feederTypes: any = { name: this.typeName, data: [], dropdown: true };
  public typeSelected = {value: 1, name: '', checked: false}

  constructor(
    private toastr: ToastrService, 
    private f33Service: Feeder33Service,
    private f11Service: Feeder11Service,
    private dtService: DtService,
    private translationService: TranslationService,
    ) { }

  ngOnInit(): void {
    this.setMainDate()
    this.getAllFeeder11()
    this.getAllFeeder33()
    this.getData()
  }

  onSearch(search: string){
    this.searchText = search
  }

  mainTabChange(value: number){
    if(value == 1){
      this.mainTab = this.tabsEnum.Feeder
    }
    else{
      this.mainTab = this.tabsEnum.DT
    } 

    this.dtSelectedId = 0
    this.f11SelectedId = 0
    this.resetFilters()
  }

  updateDate(date: any){
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.getData()
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    if(dte.getMonth() == 0)
    {
      this.dateFrom = {year: dte.getFullYear() - 1, month: 12, day: 1};
    }
    else {
      this.dateFrom = {year: dte.getFullYear(), month: dte.getMonth(), day: 1};
    }
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
  }

  getAllFeeder11(){
    this.f11Service.getAllForSelect().subscribe(response => {
      if(response.status === 200){
        this.feeders11 = { name: this.feeder11Name, data: response.data, dropdown: true }
        this.feeders = { name: this.feeder11Name, data: response.data, dropdown: true }
        this.checkFilters()
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
        this.toastr.error("An Error Occurred")
    })
  }

  getAllDTs(f11Id: number){
    if (this.child1)
      this.child1.getAllAssets(f11Id)
  }

  getAllFeeder33(){
    this.f33Service.getAllForSelect().subscribe(response => {
      if(response.status === 200){
        this.feeders33 = { name: this.feeder33Name, data: response.data, dropdown: true }
        this.checkFilters()
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
        this.toastr.error("An Error Occurred")
    })
  }

  public filterTypeSelected(data: any){
    this.assetTypeSelected = data.value
    this.assetTypeSelectedName = data.name
    this.getData()
  }

  public filter11KvSelected(data: any){
    this.f11SelectedId = data
    this.getData()
  }

  public filter11KvSecondSelected(data: any){
    this.f11SelectedId = data
    this.getAllDTs(this.f11SelectedId)
  }

  public filter33KvSelected(data: any){
    this.f33SelectedId = data
    this.getData()
  }

  public filterDTSelected(data: any){
    this.dtSelectedId = data
    this.getData()
  }

  getData(){
    if(this.mainTab === NetworkPerfomanceTabs.DT){
      let obj = {
        DtId: this.dtsSelected.value,
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
      }
  
      this.getDtEnergyDistributionAndLosses(obj)
      this.getDtOutageDurationAndFrequency(obj)
      this.getDtAvailability(obj)
    }
    else if(this.mainTab === NetworkPerfomanceTabs.Feeder){
      let obj = {
        FeederId: this.typeSelected.value == 2 ? this.feeder33Selected.value : this.feeder11Selected.value,
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo)
      }
      
      if(this.typeSelected.value == 2){        
        this.getFeeder33EnergyDistributionAndLosses(obj)
        this.getFeeder33OutageDurationAndFrequency(obj)
        this.getFeeder33Availability(obj)
      }
      else {
        this.getFeeder11EnergyDistributionAndLosses(obj)
        this.getFeeder11OutageDurationAndFrequency(obj)
        this.getFeeder11Availability(obj)
      }
    }    
  }

  getDtOutageDurationAndFrequency(obj: any){
    this.dtService.getOutageDurationAndFrequency(obj).subscribe(response =>{
      if(response.status === 200){
        this.outage = response.data
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getFeeder11OutageDurationAndFrequency(obj: any){
    this.f11Service.getOutageDurationAndFrequency(obj).subscribe(response =>{
      if(response.status === 200){
        this.outage = response.data
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getFeeder33OutageDurationAndFrequency(obj: any){
    this.f33Service.getOutageDurationAndFrequency(obj).subscribe(response =>{
      if(response.status === 200){
        this.outage = response.data
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getDtEnergyDistributionAndLosses(obj: any){
    this.dtService.getEnergyDistributionAndLosses(obj).subscribe(response =>{
      if(response.status === 200){
        this.energyDistribution = response.data
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getFeeder33EnergyDistributionAndLosses(obj: any){
    this.f33Service.getEnergyDistributionAndLosses(obj).subscribe(response =>{
      if(response.status === 200){
        this.energyDistribution = response.data
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getFeeder11EnergyDistributionAndLosses(obj: any){
    this.f11Service.getEnergyDistributionAndLosses(obj).subscribe(response =>{
      if(response.status === 200){
        this.energyDistribution = response.data
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getDtAvailability(obj: any){
    this.dtService.getDtMonthlyAvailability(obj).subscribe(response =>{
      if(response.status === 200){
        this.availability = response.data
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getFeeder11Availability(obj: any){
    this.f11Service.getMonthlyAvailability(obj).subscribe(response =>{
      if(response.status === 200){
        this.availability = response.data
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getFeeder33Availability(obj: any){
    this.f33Service.getMonthlyAvailability(obj).subscribe(response =>{
      if(response.status === 200){
        this.availability = response.data
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  checkFilters() {
    if(this.mainTab == NetworkPerfomanceTabs.Feeder) {
 
     this.feederFilters();
    }
    else {
      this.dtFilters();
    }

    this.allFilters = [...this.allFilters]
  }

  feederFilters(){
    this.feederTypes.data = [{ value: 1, name: this.feeder11Name }, { value: 2, name: this.feeder33Name }]

    let typesData = this.allFilters.find((item: { name: any; }) => item.name === this.feederTypes.name);

    let typeIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feederTypes.name);

    if (typeIndex !== -1)
      this.allFilters[typeIndex].data = this.feederTypes.data;

    if (!typesData) {
      this.allFilters.push(this.feederTypes)
      this.allFilters.push(this.feeders)
      this.filterApplied = true
    }

    let f11Index = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders11.name);
    if (f11Index !== -1)
      this.allFilters[f11Index] = this.feeders;

    let f33Index = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders33.name);
    if (f33Index !== -1)
      this.allFilters[f33Index] = this.feeders;
  }

  dtFilters(){
    let f11Index = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders11.name);
      if (f11Index !== -1)
        this.allFilters[f11Index] = this.feeders;

      let dtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.dts.name);
      if (dtIndex !== -1)
        this.allFilters[dtIndex].data = this.dts.data;

      if(dtIndex === -1 && f11Index === - 1){
        this.allFilters.push(this.feeders);
        this.allFilters.push(this.dts);
        this.filterApplied = true
      }
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      if (item.name === this.feeders11.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.feeders11 = item
        this.f11Ids = filteredValues
        console.log(this.f11Ids)
      }
      if (item.name === this.feeders33.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.feeders33 = item
        this.f33Ids = filteredValues
      }
    }
    this.getData();
  }

  resetFilters() {
    this.showFilter = false
    this.filterApplied = false
    this.feeder11Selected = {value: 0, name: '', checked: false}
    this.feeder33Selected = {value: 0, name: '', checked: false}
    this.dtsSelected = {value: 0, name: '', checked: false}
    this.f11Ids = []
    this.f33Ids = []
    this.typeSelected = {value: -1, name: '', checked: false}
    this.allFilters = []
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.checkFilters()
    this.setMainDate();
    this.getData();
  }

  dropdownSelected(data: any){
    if(!data)
      return
    if(data.data.name === this.typeName)
      this.feederTypeSelected(data.item)
    if (data.data.name === this.feeder11Name)
      this.feeder11Select(data.item)
    if (data.data.name === this.feeder33Name)
      this.feederSelected(data.item)
    if(data.data.name === this.dtName)
      this.selectDt(data.item)
  }

  feederTypeSelected(item: any) {
    this.typeSelected = item;

    if (this.typeSelected.value == 0) {
      this.typeSelected = {value: 1, name: this.feeder11Name, checked: false}
      this.feeder11Selected = {value: 0, name: '', checked: false}      
      this.feeders = this.feeders11
      this.checkFilters()
      this.getData()
    }
    else if(this.typeSelected.value == 1){
      this.feeders.name = this.feeders11.name
      this.feeders.data = this.feeders11.data
      this.checkFilters()
      this.getData()
    }
    else if(this.typeSelected.value == 2){
      this.feeders.name = this.feeders33.name
      this.feeders.data = this.feeders33.data
      this.checkFilters()
      this.getData()
    }
  }

  feederSelected(data: any) {
    this.feeder33Selected = data

    if (this.feeder33Selected.value == 0) {
      this.feeder33Selected = {value: 0, name: '', checked: false}
      this.dts = { name: this.dtName, data: [], dropdown: false}
      this.dtIds = []
      this.f11Ids = []
      this.checkFilters()
      this.getData();
    }
    else
    {
      this.getData()      
    }
  }

  feeder11Select(data: any) {
    this.feeder11Selected = data

    if (this.feeder11Selected.value == 0) {
      this.feeder11Selected = {value: 0, name: '', checked: false}
      this.dtsSelected = {value: 0, name: '', checked: false}
      this.dts = { name: this.dtName, data: [], dropdown: true}
      this.dtIds = []
      this.checkFilters()
      this.getData();
    }
    else
    {
      if(this.mainTab == NetworkPerfomanceTabs.DT){
        this.dtService.getAllDtsByF11Id(this.feeder11Selected.value).subscribe(response => {
          this.dts = { name: this.dtName, data: response.data, dropdown: false}
          this.checkFilters()
          this.getData();
        }, error => {
          this.toastr.error('An error occurred.');
        })
      }
      if(this.mainTab == NetworkPerfomanceTabs.Feeder)
        this.getData();
    }
  }

  selectDt(data: any){
    this.dtsSelected = data

    if (this.dtsSelected.value == 0) {
      this.dtsSelected = {value: 0, name: '', checked: false}
      this.dts = { name: this.dtName, data: [], dropdown: false}
      this.dtIds = []
      this.f11Ids = []
      this.checkFilters()
      this.getData();
    }
    else
    {
      this.getData()      
    }
  }

}
