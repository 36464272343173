<table class="standard-table">
    <thead>
        <tr>
            <th class="checkbox-td"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" (change)="checkUncheckAll()"></th>
            <th><span>CUSTOMER ID</span></th>
            <th><span>ACCOUNT NUMBER</span></th>
            <th><span>CURRENT MONTH</span></th>
            <th><span>PREVIOUS OUTSTANDING BALANCE</span></th>
            <th><span>AMOUNT (KWH)</span></th>
            <th><span>TOTAL AMOUNT PAYABLE</span></th>
            <th><span>ACCOUNTING PERIOD</span></th>
            <th><span>{{bu}}</span></th>
            <th><span>{{ut}}</span></th>
            <th><span>SPN</span></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of bills">
            <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked"  (change)="checkForDownload(item.id)"></td>
            <td title="{{item.customerId}}">{{item.customerId | hasValue}}</td>
            <td title="{{item.accountNumber}}">{{item.accountNumber | hasValue}}</td>
            <td title="{{item.currentCharge | twoDecimals}}">{{item.currentCharge | twoDecimals}}</td>
            <td title="{{item.totalBill | twoDecimals}}">{{item.totalBill | twoDecimals}}</td>
            <td title="{{item.amountKwh | twoDecimals}}">{{item.amountKwh | twoDecimals}}</td>
            <td title="{{item.arrears | twoDecimals}}">{{item.arrears | twoDecimals}}</td>
            <td title="{{item.accountingPeriod}}">{{item.accountingPeriod | date: 'MMM yyyy'| hasValue}}</td>
            <td title="{{item.buName}}">{{item.buName | hasValue}}</td>
            <td title="{{item.utName}}">{{item.utName | hasValue}}</td>
            <td title="{{item.spn}}">{{item.spn | hasValue}}</td>
        </tr>
    </tbody>
</table>

<div class="pagination-wrapper">
    <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
        [currentPage]="currentPage" (pageChange)="pageChange($event)">
    </app-fo-pagination-new>
</div>
