import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-idle-logout-popup',
  templateUrl: './idle-logout-popup.component.html',
  styleUrl: './idle-logout-popup.component.scss',
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('0ms ease-in-out')),
      transition('out => in', animate('0ms ease-in-out'))
    ]),
  ],
})
export class IdleLogoutPopupComponent {
  @Input('openModal') openModal: number = 0;
  @Input() animationState = 'out';

  @Input() countdown : number | null = null;
  @Output() logoutResponse : EventEmitter<boolean> = new EventEmitter<boolean>();

  changeState(number: number) {
    if (number === 1) {
      this.toggleShowDiv("open")
    }
    if (number === 2)
    {
      this.toggleShowDiv("close")
    }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = this.animationState = 'in'
     }

    else if (divName === 'close')
      this.animationState = this.animationState = 'out'
  }
  stayLoggedIn(){
    this.logoutResponse.emit(false);
  }

  logout(){
    this.logoutResponse.emit(true);
  }
}
