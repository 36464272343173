<div class="details-wrapper">
    <div class="header">
        <div class="tabs-wrapper">
            <div class="filter-wrapper">
                <app-tab-default
                    [firstImg]="'assets/images-new-design/approval_delegation.svg'"
                    [firstText]="'Issued '"
                    [secondImg]="'assets/images-new-design/undo.svg'"
                    [secondText]="'Returns '"
                    [wom]="true"
                    [womCss]="false"
                    (tabChanged)="onStatusChange($event)"
                ></app-tab-default>
              </div>
        </div>
    </div>

    <div *ngIf="showInventory.length != 0" class="inventory-container">
        <div class="inventory" *ngFor="let item of showInventory">

            <div class="person">
                <img alt="Portrait" [src]="item.authorImage != null ? item.authorImage : defaultImage"/>
            </div>

            <div class="content-container">

                <div class="content-header">
                    <span class="person-name">{{item.issuedUserName}}</span>
                </div>

                <div class="content">
                    <span class="label-optional">Issued: </span>
                    <span>{{item.issuedDate | date: 'MMM d, y h:mm a'}}</span>
                    <span class="label-optional">Quantity: </span>
                    <span>{{item.issuedQuantity}}</span>
                </div>

            </div>

            <div class="content-options">
                <div class="returned-button">
                    <span class="btn-white" (click)="alert()" click-stop-propagation>
                        Mark as returned
                    </span>
                    <span>
                        <img alt="Undo" src="assets/images-new-design/undo.svg">
                    </span>
                </div>
                <span class="three-dots" (click)="alert()" click-stop-propagation>
                    <img alt="..." src="../../../../../assets/images/3-dot.svg">
                </span>
            </div>
        </div>
    </div>
</div>
