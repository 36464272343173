import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab-nm-dashboard',
  templateUrl: './tab-nm-dashboard.component.html',
  styleUrls: ['./tab-nm-dashboard.component.scss']
})
export class TabNmDashboardComponent {

  @Input() firstText: string = 'First'
  @Input() secondText: string = 'Second'

  @Output() tabChanged = new EventEmitter();

  public tabClicked = 1

  changeTab(value: number) {
    this.tabClicked = value
    this.tabChanged.emit(this.tabClicked)
  }

}
