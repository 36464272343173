import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CreateBusinessUnitComponent } from './create-business-unit/create-business-unit.component';
import { BusinessUnit } from 'src/app/models/business-unit.model';
import { Subject } from 'rxjs';
import { TranslationService } from 'src/app/services/translation.service';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { CrudDeleteModalComponent } from '../crud-delete-modal/crud-delete-modal.component';
import { CrudService } from 'src/app/services/crudService.service';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';

@Component({
  selector: 'app-business-unit-tab',
  templateUrl: './business-unit-tab.component.html',
  styleUrls: ['./business-unit-tab.component.scss']
})
export class BusinessUnitTabComponent implements OnInit {
  @ViewChild(CreateBusinessUnitComponent) popupComponentRef!: CreateBusinessUnitComponent;
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal!: ElementRef;
  @ViewChild("f") f: any;

  @Output() editTab = new EventEmitter();
  @Output() deleteComp = new EventEmitter();

  public PermissionsEnum = PermissionsEnum;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public fileChosen: any = null;
  public file: any = null;

  public businessUnits: BusinessUnit[] = [];
  public searchBusinessUnits: SearchAutocomplete[] = [];
  public selectedBU: BusinessUnit = new BusinessUnit();

  private ngUnsubscribe = new Subject<void>();
  private searchText: string = "";

  public searchByName: string = '';
  public searchByAddress: string = '';

  public selectedId: number = 0;
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public masterSelected: boolean = false;
  public checkedList: any = []

  constructor(public authService: AuthService,
    private translationService: TranslationService,
    private buService: BusinessUnitService,
    private crudService: CrudService,
    private location: Location,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.reloadTable();
    this.subscribeToSearch();
  }

  reloadTable(page: any = null){
    if(page)
      this.currentPage = page;

    this.resetSelectedBU();

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };

    if (!obj.filterParams.SearchContent){
      this.buService.getAllForSelect().subscribe(response => {
        if (!obj.filterParams.SearchContent){
          this.searchBusinessUnits = []
          response.data.forEach((x : BusinessUnit) =>
          {
            let item = new SearchAutocomplete();
            item.value = x.id;
            item.name = x.name;
            this.searchBusinessUnits.push(item)
          })
        }
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }


    this.buService.getAll(obj).subscribe(response => {
      this.businessUnits = response.data.data;
      if(obj.pageInfo.page == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      this.isAlreadyChecked();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch(){
    this.crudService.globalSearch$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(searchValue => {
      if(searchValue.clicked && searchValue.componentName === ComponentNamesNew.BusinessUnitComponent) {
        this.searchText = searchValue.text;
        this.currentPage = 1;
        this.checkedList = [];
        this.reloadTable();
        return;
      }
      this.searchText = '';
    });
  }


  getTemplate(){
    this.buService.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  isFileChosen(){
    if(this.fileChosen)
      return true;

    return false;
  }

  uploadFile(){
    if(!this.isFileChosen){
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.buService.uploadFile(formData).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
        if(response.data && response.data.length > 0)
           window.location.href = response.result.data;
      }
      this.reloadTable();
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  openTabDelete(id:number) {
    console.log(id)
    this.buService.get(id).subscribe(response => {
      if(response.status === 200){
        this.selectedBU = response.data;
        this.deleteComp.emit();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.selectedId = id;
  }

  onFileChosen(event: any){
    this.file = event.target.files[0];
  }

  selectBU(id: number){
    this.buService.get(id).subscribe(response => {
      if(response.status === 200){
        this.selectedBU = response.data;
        this.editTab.emit(this.selectedBU);
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedBU(){
    this.selectedBU = new BusinessUnit();

    if(this.f)
      this.f.submitted = false;
  }

  onDeleteConfirmed() {
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_delete)){
      this.toastr.error("You don't have permission.");
      return;
    }

    if(this.selectedBU){
      this.buService.delete(this.selectedId).subscribe(response => {
        if(response.status === 200){
          this.toastr.success(response.message);
          this.currentPage = 1;
          this.reloadTable();
          this.resetSelectedBU();
        } else {
          this.toastr.error(response.message);
        }
      }, error => {
        this.toastr.error('An error occurred');
      })
    }
  }

  deleteBU(e: any, id: number){
    e.stopPropagation();

    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_delete))
    {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.buService.delete(id).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.reloadTable();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkUncheckAll() {
    for (let i = 0; i < this.businessUnits.length; i++) {
      this.businessUnits[i].checked = this.masterSelected;
      this.checkForDownload(this.businessUnits[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.businessUnits.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.businessUnits.every(function(item:any) {
      return item.checked == true;
    })
  }

  downloadItems(){
    let obj = {
      Ids: this.checkedList,
      activate: false
    };
    this.buService.download(obj).subscribe(response => {
      if (response.status === 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.")
        }
        else {
          window.location.href = response.data
          this.toastr.success("Successfully downloaded regions.");
         }
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
