import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SwitchModulePopupComponent } from 'src/app/components/common-new-design/switch-module-popup/switch-module-popup.component';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';

export enum MeterManagementItems{
  Dashboard = 1,
  Meters = 2,
  MeterTriggers = 3,
  DeviceManagement=4,
  Dcu=5,
  ModalSettings = 6,
  GeneralSettings = 7,
  UnmeteredComponent = 8,
  AssetMeters = 9,

}

@Component({
  selector: 'app-meter-management-sidenav',
  templateUrl: './meter-management-sidenav.component.html',
  styleUrls: ['./meter-management-sidenav.component.scss']
})
export class MeterManagementSidenavComponent {
  public SelectedItem: any = 1;
  public PermissionsEnum = PermissionsEnum;

  public customerWatchlistMenu : boolean = false;
  public debtManagementMenu : boolean = false;

  public allowCustomerWatchlistMenu : boolean = true;
  public allowDebtManagementMenu : boolean = true;

  public metersMenu: boolean = false;
  public allowMetersMenu: boolean = true;
  public settingsMenu: boolean = false;
  public allowSettingsMenu: boolean = true;

  @Input() expandSidebar: boolean = false;
  @Output() expandSidebarChange = new EventEmitter();

  @ViewChild(SwitchModulePopupComponent) popupComponentRef!: SwitchModulePopupComponent;

  constructor(public authService: AuthService,
    private toastr:ToastrService,
    private router: Router) {
      this.router.events
        .subscribe(
          (event) => {
            if(event instanceof ActivationEnd && event.snapshot.children.length == 0) {
              this.SelectedItem = event.snapshot.data['sidebarItem'];
            }
          });
  }
  logout(){
    this.authService.logout();
  }

  switchModule(){
    this.popupComponentRef.changeState(1);
  }
  alert() {
    this.toastr.warning("Not yet implemented");
  }
  expand(){
    this.expandSidebar = !this.expandSidebar;
    this.expandSidebarChange.emit(this.expandSidebar);
  }

  handleDropdownMenuClick(name: string, event: any) {
    console.log(name)
    console.log(event)
    // If sidebar is open
    if(this.expandSidebar) {
      if (name === 'Meters') {
        this.metersMenu = !this.metersMenu;
      } else if(name === 'settings') {
        this.settingsMenu = !this.settingsMenu;
      }
      else {
      return;
      }
    } else {
      // this.expandSidebar = true;
      this.expand()

      if (this.metersMenu) {
        this.allowMetersMenu = false;
        event.stopPropagation();
        return;
      } else {
        this.metersMenu = !this.metersMenu;
        this.allowMetersMenu = true;
      }
    }
  }
}
