import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() currentPage: number = 1;
  @Input() pageSize: number = 0;
  @Input() count: number = 0;
  @Input() lastPageSize: number = 1;
  @Input() isSmallerSize: boolean = false;

  public previousPage : number = this.currentPage-1;

  public visiblePages: any;
  @Output() pageChange = new EventEmitter();

  public isLastPage : boolean = false;

  ngOnChanges(changes: SimpleChanges) { // Checking if number of pages is 1, if true -> disable next button
    if(changes.currentPage !== undefined)
    {
        this.previousPage = this.currentPage - 1
    }
    if (changes.lastPageSize?.currentValue !== undefined) // First check if there is change on lastPageSize prop
    {
      if (changes.lastPageSize.currentValue === 1)
        this.isLastPage = true;
      else
        this.isLastPage = false;
    }
    if(changes.count !== undefined){
      this.lastPageSize = Math.ceil(this.count / this.pageSize);
      if(this.lastPageSize === 1){
        this.isLastPage = true;
      }
    }
  }

  changePage(pagenum: number) {
    if (this.lastPageSize === 0)
      this.lastPageSize = 1;
    if (pagenum == 0)
      pagenum = 1;
    if (pagenum >= this.lastPageSize)
    {
      this.currentPage = this.lastPageSize;
      this.previousPage = this.currentPage - 1;
      this.isLastPage = true;
    }
    else{
      this.currentPage = pagenum;
      this.previousPage = this.currentPage - 1;

      this.isLastPage = false;
    }
    this.pageChange.emit(this.currentPage);
  }

  getPaginationWidth(): string {
    const numberOfDigits = Math.floor(Math.log10(this.currentPage)) + 1;

    if (numberOfDigits <= 3) {
      return '4rem';
    } else if(numberOfDigits == 4){
      return '5rem'
    } else if (numberOfDigits <= 5) {
      return '6rem';
    } else {
      return '7rem';
    }
  }
}
