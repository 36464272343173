import { Component, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-dt-map-dialog',
  templateUrl: './dt-map-dialog.component.html',
  styleUrls: ['./dt-map-dialog.component.scss', '.././map.component.scss']
})
export class DtMapDialogComponent {

  @Input()
  public dtData!: any;

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT')

  constructor(private translationService: TranslationService) { }
}
