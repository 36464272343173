import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';
import { GraphItem } from 'src/app/models/graph-item.model';
import { ToastrService } from 'ngx-toastr';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { transformToString } from 'src/app/helpers/dateUtils';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { DtService } from 'src/app/services/dt.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-trend-analyses',
  templateUrl: './trend-analyses.component.html',
  styleUrls: ['./trend-analyses.component.scss'],
})
export class TrendAnalysesComponent implements OnInit {
  public menu: number = 1;

  //Table
  public avgReceived: number = 0;
  public avgConsumed: number = 0;
  public avgLosses: number = 0;
  public avgLossesTo11Kv: number = 0;

  //multi filter
  public allFilters: any = [];
  public showFilter: any = false;
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  public feeder33Name: string = 'Feeder33';
  public feeder11Name: string = 'Feeder11';
  public dtName: string = 'Dt';
  public feeders33: any = {
    name: this.feeder33Name,
    data: [],
    dropdown: false,
  };
  public feeders11: any = {
    name: this.feeder11Name,
    data: [],
    dropdown: false,
  };
  public dts: any = { name: this.dtName, data: [], dropdown: false };
  public feeder11Selected: any = { value: 0, name: '', checked: false };
  public feeder33Selected: any = { value: 0, name: '', checked: false };
  public f33Ids: number[] = [];
  public f11Ids: number[] = [];
  public dtIds: number[] = [];

  // Search Autocomplete
  @ViewChild('search') searchChild!: SearchAutocompleteComponent;
  public options = [''];
  public searchText: SearchAutocomplete = new SearchAutocomplete();
  public searchPlaceholder: string = 'Search by Feeder33 name or number';

  //datepicker
  public dateFrom: any = '';
  public dateTo: any = '';
  public isMonthly: boolean = true;

  // Graph, Chart
  public isGraph = true;
  public chartHeight: number = 450;
  public barChartLabels: string[] = [];
  public barChartTableLabels: string[] = [];
  public receivedAndConsLabels: string[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public EnergyLabels: string[] = [];

  // rgba(192, 52, 56, 1)
  public receivedAndConsumedData: ChartDataset[] = [
    {
      data: [],
      label: 'Received',
      backgroundColor: 'rgba(41, 200, 123, 1)',
      borderRadius: 45,
      barThickness: 12,
    },
    {
      data: [],
      label: 'Consumed',
      backgroundColor: 'rgba(66, 173, 230, 1)',
      borderRadius: 45,
      barThickness: 12,
    },
    {
      data: [],
      label: 'Losses',
      backgroundColor: 'rgba(192, 52, 56, 1)',
      borderRadius: 45,
      barThickness: 12,
    },
  ];

  public barChartData: ChartDataset[] = [
    {
      data: [],
      label: 'Received',
      backgroundColor: 'rgba(41, 200, 123, 1)',
      borderRadius: 45,
      barThickness: 12,
    },
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',

    scales: {
      x: {
        grid: {
          display: false,
        },
        border:{
          display: false
        },
        ticks: {
          font: {
            size: 14,
          },
          autoSkip: false,
          maxRotation: 75,
          minRotation: 75,
        },
      },
      y: {
        grid: {
          color: '#E8E8E8',
        },
        border:{
          display: false
        },
        ticks: {
          padding: 30,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(context.parsed.y);
            }
            return label;
          },
          afterLabel: function (context) {
            const dataset = context.dataset;
            if (dataset.label === 'Consumed') {
              const previousDatasets = context.chart.data.datasets.slice(0, 2);
              const prevReceivedEnergy =
                Number(previousDatasets[0].data[context.dataIndex]) || 0;
              const prevConsumedEnergy =
                Number(previousDatasets[1].data[context.dataIndex]) || 0;
              const difference = prevReceivedEnergy - prevConsumedEnergy;

              if (prevReceivedEnergy > 0) {
                if (difference >= 0) {
                  const percentage = (
                    (prevConsumedEnergy / prevReceivedEnergy) *
                    100
                  ).toFixed(2);
                  return 'Percentage: ' + percentage + '%';
                } else {
                  const negativePercentage = (
                    (difference / prevReceivedEnergy) *
                    100
                  ).toFixed(2);
                  return 'Percentage: ' + negativePercentage + '%';
                }
              } else {
                return 'Percentage: /';
              }
            } else {
              return '';
            }
          },
        },
      },
    },
  };

  constructor(
    private chartService: ChartService,
    private _chartService: ChartService,
    private toastr: ToastrService,
    private feeder33Service: Feeder33Service,
    private feeder11Service: Feeder11Service,
    private dtService: DtService
  ) {}

  ngOnInit(): void {
    this.setMainDate();
    this.fetchData();
    this.getAllFeeders33();
  }

  setMainDate() {
    let date = new Date();
    date.setDate(1);

    this.dateFrom = {
      year: date.getFullYear() - 1,
      month: date.getMonth() + 1,
      day: date.getDay(),
    };
    this.dateTo = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDay(),
    };
  }

  // Search Autocomplete
  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: false,
    };
    if (searchText.name.length > 2) {
      if (this.menu == 1) {
        this.feeder33Service
          .getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          });
      } else if (this.menu == 2) {
        this.feeder11Service
          .getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          });
      } else if (this.menu == 3) {
        this.dtService.getDataForAutocomplete(obj).subscribe({
          next : (response) => this.options = response.data,
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        });
      }
    } else {
      this.searchChild.resetOptions();
    }
  }

  // Search Autocomplete
  searchChanged(searchText: SearchAutocomplete) {
    console.log('searchg', searchText);
    this.searchText = searchText;
    this.fetchData();
  }

  private getAllFeeders33() {
    this.feeder33Service.getAllForSelect().subscribe(
      (response) => {
        if (response.status === 200) {
          this.feeders33 = {
            name: this.feeder33Name,
            data: response.data,
            dropdown: false,
          };
          this.checkFilters();
        } else {
          this.toastr.error(response.message);
        }
      },
      (_) => {
        this.toastr.error('An Error Occurred');
      }
    );
  }

  feederSelected(data: any) {
    this.feeder33Selected = data;

    if (this.feeder33Selected.value == 0) {
      this.feeder33Selected = { value: 0, name: '', checked: false };
      this.feeder11Selected = { value: 0, name: '', checked: false };
      this.feeders11 = { name: this.feeder11Name, data: [], dropdown: false };
      this.dts = { name: this.dtName, data: [], dropdown: false };
      this.dtIds = [];
      this.f11Ids = [];
      this.checkFilters();
    } else {
      this.feeder11Service
        .GetAllConnectedToF33(this.feeder33Selected.value)
        .subscribe(
          (response) => {
            this.feeders11 = {
              name: this.feeder11Name,
              data: response.data,
              dropdown: false,
            };
            this.checkFilters();
          },
          (_) => {
            this.toastr.error('An error occurred.');
          }
        );
    }
    this.fetchData();
  }

  feeder11Select(data: any) {
    this.feeder11Selected = data;

    if (this.feeder11Selected.value == 0) {
      this.feeder11Selected = { value: 0, name: '', checked: false };
      this.dts = { name: this.dtName, data: [], dropdown: false };
      this.dtIds = [];
      this.checkFilters();
    } else {
      this.dtService.getAllDtsByF11Id(this.feeder11Selected.value).subscribe(
        (response) => {
          this.dts = {
            name: this.dtName,
            data: response.data,
            dropdown: false,
          };
          this.checkFilters();
        },
        (_) => {
          this.toastr.error('An error occurred.');
        }
      );
    }
    this.fetchData();
  }

  public switchMenu(newMenu: number) {
    this.menu = newMenu;
    this.resetFilters();
  }

  public fetchData() {
    let obj = this.generateObj();

    if (this.menu === 1) this.getReceivedAndConsumedF33ByMonths(obj);
    else if (this.menu === 2) this.getReceivedAndConsumedF11ByMonths(obj);
    else this.getReceivedAndConsumedDtByMonths(obj);
  }

  private generateObj(): any {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      FeederId: this.feeder11Selected.value,
      Feeder33Id: this.feeder33Selected.value,
      FeederIds: this.f11Ids,
      Feeder33Ids: this.f33Ids,
      DtId: this.dtIds,
      Search: this.searchText.name,
    };
    return obj;
  }

  public getReceivedAndConsumedF33ByMonths(obj: any) {
    this.chartService.getReceivedAndConsumedF33ByMonths(obj).subscribe(
      (response: any) => {
        this.avgConsumed = response.avgEnergyConsumption;
        this.avgReceived = response.avgReceived;
        this.avgLosses = response.avgLosses;
        this.avgLossesTo11Kv = response.avgLossesTo11Kv;
        this.setValuesReceivedAndConsumed(response.graphItems);
        this.setValuesForEnergyReceived(response.graphItems);
      },
      (_) => {
        this.toastr.error('An error ocured');
      }
    );
  }

  public getReceivedAndConsumedF11ByMonths(obj: any) {
    this.chartService.getReceivedAndConsumedF11ByMonths(obj).subscribe(
      (response: any) => {
        this.avgConsumed = response.avgEnergyConsumption;
        this.avgReceived = response.avgReceived;
        this.avgLosses = response.avgLosses;
        this.setValuesReceivedAndConsumed(response.graphItems);
        this.setValuesForEnergyReceived(response.graphItems);
      },
      (_) => {
        this.toastr.error('An error ocured');
      }
    );
  }

  public getReceivedAndConsumedDtByMonths(obj: any) {
    this.chartService.getReceivedAndConsumedDtByMonths(obj).subscribe(
      (response: any) => {
        this.avgConsumed = response.avgEnergyConsumption;
        this.avgReceived = response.avgReceived;
        this.avgLosses = response.avgLosses;
        this.setValuesReceivedAndConsumed(response.graphItems);
        this.setValuesForEnergyReceived(response.graphItems);
      },
      (_) => {
        this.toastr.error('An error ocured');
      }
    );
  }

  private setValuesForEnergyReceived(response: GraphItem[]) {
    this.barChartData[0].data = [];
    this.barChartLabels = [];
    let labels = [''];
    labels = [];
    let tableLabels = [''];
    tableLabels = [];
    response.forEach((element) => {
      let date = new Date(element.date);
      let month = date.getMonth();
      this.barChartData[0].data?.push(element.received);
      labels.push(this.getMonth(month, true));
      tableLabels.push(this.getMonth(month, false));
    });
    this.barChartLabels = labels;
    this.barChartTableLabels = tableLabels;
  }

  private setValuesReceivedAndConsumed(response: GraphItem[]) {
    this.receivedAndConsumedData[0].data = [];
    this.receivedAndConsumedData[1].data = [];
    this.receivedAndConsumedData[2].data = [];
    this.receivedAndConsLabels = [];
    let labels = [''];
    labels = [];
    response.forEach((element) => {
      let date = new Date(element.date);
      let month = date.getMonth();
      this.receivedAndConsumedData[0].data?.push(element.received);
      this.receivedAndConsumedData[2].data?.push(element.lost);
      this.receivedAndConsumedData[1].data?.push(element.consumed);
      labels.push(this.getMonth(month, true));
    });
    this.receivedAndConsLabels = labels;
  }

  private getMonth(month: number, short: boolean): string {
    let monthName = '';
    switch (month % 12) {
      case 0:
        monthName = 'January';
        break;
      case 1:
        monthName = 'February';
        break;
      case 2:
        monthName = 'March';
        break;
      case 3:
        monthName = 'April';
        break;
      case 4:
        monthName = 'May';
        break;
      case 5:
        monthName = 'June';
        break;
      case 6:
        monthName = 'July';
        break;
      case 7:
        monthName = 'August';
        break;
      case 8:
        monthName = 'September';
        break;
      case 9:
        monthName = 'October';
        break;
      case 10:
        monthName = 'November';
        break;
      case 11:
        monthName = 'December';
        break;
      default:
        return '';
    }

    return short ? monthName.substring(0, 3) : monthName;
  }

  public DateClickEvent(date: any) {
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.fetchData();
  }

  public downloadReport() {
    let obj = this.generateObj();

    if (this.menu == 1) {
      this._chartService
        .downloadMonthlyF33EnergyReceivedAndConsumed(obj)
        .subscribe((response: any) => {
          // window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
          if (response.status == 200) {
            window.location.href = response.data;
          } else if (response.status == 404) {
            this.toastr.info(response.message);
          }
        });
    } else if (this.menu == 2) {
      this._chartService
        .downloadMonthlyF11EnergyReceivedAndConsumed(obj)
        .subscribe((response: any) => {
          // window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
          if (response.status == 200) {
            window.location.href = response.data;
          } else if (response.status == 404) {
            this.toastr.info(response.message);
          }
        });
    } else {
      this._chartService
        .downloadMonthlyDtEnergyReceivedAndConsumed(obj)
        .subscribe((response: any) => {
          // window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
          if (response.status == 200) {
            window.location.href = response.data;
          } else if (response.status == 404) {
            this.toastr.info(response.message);
          }
        });
    }
  }

  //Multi Filter
  dropdownSelected(data: any) {
    console.log(data);
    if (!data) return;
    if (data.data.name === this.feeder33Name) this.feederSelected(data.item);
    if (data.data.name === this.feeder11Name) this.feeder11Select(data.item);
  }

  // Checking which filters should be added to the Multi Filter component
  checkFilters() {
    let f33Data = this.allFilters.find(
      (item: { name: any }) => item.name === this.feeders33.name
    );

    let f33Index = this.allFilters.findIndex(
      (item: { name: any }) => item.name === this.feeders33.name
    );

    if (f33Index !== -1) this.allFilters[f33Index].data = this.feeders33.data;

    if (!f33Data) {
      this.allFilters.push(this.feeders33);
      if (this.menu == 1) {
        this.feeders33.dropdown = false;
      }
      if (this.menu == 2) {
        this.feeders33.dropdown = true;
        this.feeders11.dropdown = false;
        this.allFilters.push(this.feeders11);
      }

      if (this.menu == 3) {
        this.feeders33.dropdown = true;
        this.feeders11.dropdown = true;
        this.allFilters.push(this.feeders11);
        this.allFilters.push(this.dts);
      }

      this.filterApplied = true;
    }

    let f11Index = this.allFilters.findIndex(
      (item: { name: any }) => item.name === this.feeders11.name
    );
    if (f11Index !== -1) this.allFilters[f11Index].data = this.feeders11.data;

    let dtIndex = this.allFilters.findIndex(
      (item: { name: any }) => item.name === this.dts.name
    );
    if (dtIndex !== -1) this.allFilters[dtIndex].data = this.dts.data;

    this.allFilters = [...this.allFilters];
  }

  // apply filter and reload table
  setFilters(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter(
        (item2: { checked: any }) => item2.checked
      );
      const filteredValues = checkedData.map(
        (item2: { value: any }) => item2.value
      );

      if (item.name === this.feeders33.name) {
        this.f33Ids = filteredValues;
      } else if (item.name === this.feeders11.name) {
        this.f11Ids = filteredValues;
      } else if (item.name === this.dts.name) {
        this.dtIds = filteredValues;
      }
    }
    this.fetchData();
  }

  resetFilters() {
    if (this.menu == 1)
      this.searchPlaceholder = 'Search by Feeder 33 name or number';
    else if (this.menu == 2)
      this.searchPlaceholder = 'Search by Feeder 11 name or number';
    else if (this.menu == 3)
      this.searchPlaceholder = 'Search by Dt name or number';

    this.searchText = new SearchAutocomplete();
    if (this.searchChild) {
      this.searchChild.searchText = new SearchAutocomplete();
      this.searchChild.resetOptions();
    }

    this.showFilter = false;
    this.filterApplied = false;
    this.feeder11Selected = { value: 0, name: '', checked: false };
    this.feeder33Selected = { value: 0, name: '', checked: false };
    this.f33Ids = [];
    this.f11Ids = [];
    this.dtIds = [];
    this.dts.data = [];
    this.feeders11.data = [];
    this.allFilters = [];
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null;
      this.multiFilterRef.resetShowFilter();
      this.multiFilterRef.uncheckData();
    }
    this.checkFilters();
    this.fetchData();
  }

  graphChanged(value: boolean) {
    this.isGraph = value;
  }
}
