<main class="container-fluid">
  <div class="container-wrapper">
    <div class="network-management-title">
      <span>Trend Analysis</span>
      <img alt=">" src="assets\images-new-design\arrow_forward_ios.svg">
      <span>Asset Comparison</span>
    </div>

    <div class="menu-tab-filter">
      <app-tab-default
          [firstText]="'Network'"
          [secondText]="'Organization'"
          (tabChanged)="organizationChanged($event)"
      ></app-tab-default>
    </div>

    <ng-container *ngIf="menu === MenuItemEnum.Network">
      <div class="nm-filter-wrapper">
        <app-tab-default
          [firstText]="'33KV Feeders'"
          [secondText]="'11KV Feeders'"
          [thirdText]="'Distribution Transformer'"
          (tabChanged)="switchSubmenu($event)"
          [paCss]="true"
          [womCss]="true"
        >
        </app-tab-default>
        <app-graph-table-button
          [isGraph]="isGraph"
          (filterClicked)="graphChanged($event)"
        ></app-graph-table-button>
      </div>
    </ng-container>

    <ng-container *ngIf="menu === MenuItemEnum.Organisation">
      <div class="nm-filter-wrapper">
        <app-tab-default
          [firstText]="buName"
          [secondText]="utName"
          (tabChanged)="switchSubmenu($event)"
          [paCss]="true"
          [womCss]="true"
        >
        </app-tab-default>
        <app-graph-table-button
          [isGraph]="isGraph"
          (filterClicked)="graphChanged($event)"
        ></app-graph-table-button>
      </div>
    </ng-container>

    <div class="filters-container">
      <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
          <app-multi-filter *ngIf="filterApplied"
              [data]="allFilters"
              (dataChange)="setFilters($event)"
              [(showFilter)]="showFilter"
              (dropdownChange)="dropdownSelected($event)"
          >
          </app-multi-filter>

          <app-range-datepicker
          [name]="'Period'"
          [isMonthly]="isMonthly"
          [fromDate]="dateFrom"
          [toDate]="dateTo"
          (dateRangeSelected)="DateClickEvent($event)"
          >
          </app-range-datepicker>

          <app-search-autocomplete
          #search
          [placeholder]="searchPlaceholder"
          [searchText]="searchText"
          [options]="options"
          (searchChange)="searchChanged($event)"
          (searchAutocompleteChange)="getOptions($event)"
        ></app-search-autocomplete>
      </div>
      <div class="btn-wrapper">
          <app-download-button-new
              [isOnlyDownload]="true"
              (downloadButtonClickedChange)="downloadReport()">
          </app-download-button-new>
      </div>
    </div>

    <div class="filters-container">
      <app-aeg-dt-new style="width: 100%" *ngIf="Submenu === SubMenuItemEnum.Dt"
        #child
        [showTable]="!isGraph"
        [menu]="menu"
        [dtIds]="dtIds"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        [searchText]="searchText"
        (redirect)="switchSubmenu($event)">
      </app-aeg-dt-new>

      <app-aeg-feeder11-new style="width: 100%" *ngIf="Submenu === SubMenuItemEnum.Feeders11"
        #child
        [menu]="menu"
        [showTable]="!isGraph"
        [f11Ids]="f11Ids"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        [searchText]="searchText"
        (redirect)="switchSubmenu($event)">
      </app-aeg-feeder11-new>

      <app-aeg-f33-new style="width: 100%" *ngIf="Submenu === SubMenuItemEnum.Feeders33"
        #child
        [menu]="menu"
        [f33Ids]="f33Ids"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        [showTable]="!isGraph"
        [searchText]="searchText"
        (redirect)="switchSubmenu($event)">
      </app-aeg-f33-new>

      <app-aeg-bu-new style="width: 100%" *ngIf="Submenu === SubMenuItemEnum.Bu"
        #child
        [menu]="menu"
        [buIds]="buIds"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        [searchText]="searchText"
        [showTable]="!isGraph"
        (redirect)="switchSubmenu($event)">
      </app-aeg-bu-new>

      <app-aeg-ut-new style="width: 100%" *ngIf="Submenu === SubMenuItemEnum.Ut"
        #child
        [menu]="menu"
        [utIds]="utIds"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        [showTable]="!isGraph"
        [searchText]="searchText"
        (redirect)="switchSubmenu($event)">
      </app-aeg-ut-new>

    </div>
  </div>
</main>
