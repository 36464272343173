import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountStatus } from 'src/app/models/accountStatus.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AccountStatusService } from 'src/app/services/accountStatus.service';
import { AccountCreateStatusTabComponent } from './account-create-status-tab/account-create-status-tab.component';

@Component({
  selector: 'app-account-status-tab',
  templateUrl: './account-status-tab.component.html',
  styleUrls: ['./account-status-tab.component.scss']
})
export class AccountStatusTabComponent implements OnInit {
  public accounts: AccountStatus[] = [];
  public PermissionsEnum = PermissionsEnum;
  public selectedAccountStatus: AccountStatus = new AccountStatus()
 
  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public selectedId: number = 0;

  @Output() deleteComp = new EventEmitter();

  @ViewChild(AccountCreateStatusTabComponent) popupComponentRef!: AccountCreateStatusTabComponent;
  @Output() editTab = new EventEmitter();
  @ViewChild('closeDeleteModal') closeDeleteModal!: ElementRef;

  constructor(private location: Location, 
    private accountStatusService:AccountStatusService, 
    private toastr:ToastrService) { }

  ngOnInit(): void {
    this.reloadTable()
  }
  back() {
    this.location.back();
  }
  reloadTable(page: any = null){
    if(page)
      this.currentPage = page; 

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: { 
      }
    };

    this.accountStatusService.getAllAccountStatus(obj).subscribe(response => {
      this.accounts = response.data.data;  
      if(obj.pageInfo.page == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  onDeleteConfirmed( ) {   
    this.accountStatusService.deleteAccStatus(this.selectedId).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.currentPage = 1;
        this.reloadTable();
        this.closeDeleteModal.nativeElement.click();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetselectedAccountStatus() {
    // this.showTab = 1;
    // this.currentTab = 1;
    // this.currentTabCollorBlue = 1;

    // this.amiValid = true;
    // this.tariffValid = true;
    // this.tariffClassValid = true;
    // this.dtValid = true;
    // this.longValid = true;
    // this.latValid = true;
    // this.accountNoValid = true;
    // this.emailValid = true;
    // this.phoneNumberValid = true;

    // this.tempDtName = '';
    // this.selectedAccount = new Account();
    // this.selectedAccount.tariffClass = "";
    // this.selectedAccount.tariffName = "";
    // this.isEdit = false;
  } 
  openTabDelete(id: number) { 
    this.accountStatusService.getAccountStatus(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedAccountStatus = response.data;
        this.deleteComp.emit();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.selectedId = id;
  } 
  selectAccStatus(id: number) { 
    this.accountStatusService.getAccountStatus(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedAccountStatus = response.data;
        this.editTab.emit(this.selectedAccountStatus);
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  openTab(id?: number) {
    if (id === 0) {
      this.popupComponentRef.changeState(1, 0);
    } else {
      this.popupComponentRef.changeState(1, this.selectedAccountStatus);
    }
  }
}
