<div class="container-fluid">
    <app-create-staff-operator (saveEvent)="staffOperatorTabComponent.reloadTable($event)"></app-create-staff-operator>
    <app-create-unit (saveEvent)="unitTabComponent.reloadTable($event)"></app-create-unit>
    <app-create-subunit (saveEvent)="subunitTabComponent.reloadTable($event)"></app-create-subunit>
    <app-create-department (saveEvent)="departmentTabComponent.reloadTable($event)"></app-create-department>

    <app-crud-delete-modal
        [selectedItem]="selectedItemDelete"
        (confirmDelete)="onDeleteConfirmed()"
        (cancelDelete)="resetSelectedDelete()">
    </app-crud-delete-modal>

    <div class="main-container">

        <div class="header">
            <div>
                <div class="titleCrud">CRUD</div>
                <div class="secondTitleCrud">Create, read, update or delete {{tabName}}</div>
            </div>
            <div class="btns">
                <ng-container regularButtons>
                    <button class="btn" (click)="addNew()">
                        <span class="text">Add New</span>
                    </button>                        
                </ng-container>
            </div>
        </div>
        <div class="titleHeader">

            <div class="input-wrapper">
                <app-search-autocomplete
                    #search
                    [placeholder]="searchPlaceholder" 
                    [width]="25"
                    [backgroundColor]="'transparent'"
                    [borderColor]="'#E8E8E8'"
                    [(searchText)]="searchAutocomplete"   
                    [options]="options"       
                    (searchAutocompleteChange)="getOptions($event)"
                    (searchChange)="inputChanged($event)"
                >     
                </app-search-autocomplete>
            </div>
        </div>
        <div screenSizeDetector [addClasses]="false" [componentId]="'-crudSettings'">
            <mat-drawer-container autosize>
                <app-page-tabs (selectedTabChange)="changeTab($event.index)" [tabs]="[ 
                { label: 'Department', tabContentReference: departmentTab, isVisible: crudDepartmentVisible, isDisabled: false },
                { label: 'Unit', tabContentReference: unitTab, isVisible: crudUnitVisible, isDisabled: false },
                { label: 'Subunit', tabContentReference: subunitTab, isVisible: crudSubunitVisible, isDisabled: false },
                { label: 'Staff operator', tabContentReference: staffOperatorTab, isVisible: crudStaffOperatorVisible, isDisabled: false },
                ]">                  
                    <ng-template #staffOperatorTab>
                        <app-staff-operator-tab
                            *ngIf="!crudStaffOperatorVisible"
                            #staffOperatorTabComponent
                            (editEvent)="openTab($event)"
                            (deleteEvent)="openDelete()" 
                        ></app-staff-operator-tab>
                    </ng-template>
                         
                    <ng-template #unitTab>
                        <app-unit-tab 
                            *ngIf="!crudUnitVisible"
                            #unitTabComponent
                            (editEvent)="openTab($event)"
                            (deleteEvent)="openDelete()" 
                        ></app-unit-tab>
                    </ng-template>
                       
                    <ng-template #subunitTab>
                        <app-subunit-tab
                            *ngIf="!crudSubunitVisible"
                            #subunitTabComponent
                            (editEvent)="openTab($event)"
                            (deleteEvent)="openDelete()" 
                        ></app-subunit-tab>
                    </ng-template>
                         
                    <ng-template #departmentTab>
                        <app-department-tab 
                            *ngIf="!crudDepartmentVisible"
                            #departmentTabComponent
                            (editEvent)="openTab($event)"
                            (deleteEvent)="openDelete()" 
                        ></app-department-tab>
                    </ng-template>
                </app-page-tabs>
            </mat-drawer-container>
        </div>
    </div>
</div>
