import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class CommandRequestService {

  public pathApi = this.config.setting['pathApi'] + "commandRequest/";

  constructor(private http: HttpClient, private config: AppConfig) { }
  
  checkCommandRequest(guid: string): Observable<any> {
    return this.http.get<any>(`${this.pathApi}check/${guid}`)
  }
}
