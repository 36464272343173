<app-new-header [border]="border"></app-new-header>
<div class="global-container">  <div class="menu">
    <div class="global">
        <img alt="Logo" *ngIf="logo !== '/'" class="logo" src="{{logo}}">
        <img alt="Logo" *ngIf="logo === '/'" class="logo" src="assets/images-new-design/AEDC-logo.png">
        <img alt="Adora" class="text" src="assets/images-new-design/global-text-blue.png">

    </div>
    <div class="title">What would you like to do today?</div>

        <div class="container-land">

            <div class="menu-items">
                <div class="items">
                    <div class="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center" (mouseenter)="onClick(1)"
                        (mouseleave)="onMouseLeave()"
                        [ngClass]="{'disabled': !authService.isGroupAuthorized('networkmanagement_') || !availableModules.includes('Network Management')}">
                        <div class="menu-card" [routerLink]="['/network-management/dashboard']">

                            <div class="menu-text">
                                <div class="header">
                                    <div class="icons">
                                        <img alt="" src="assets/images-new-design/network-icons.png">
                                    </div>

                                    <div *ngIf="isFirstBlockVisible" class="select-text">
                                        <div class="select">{{ displayText }}
                                            <img alt="" src="assets/images-new-design/Arrow.png">
                                        </div>
                                    </div>
                                </div>

                                <div class="menu-title">
                                    Network Management
                                </div>
                                <div class="details">
                                    Real-time network tracking, outage reports, and energy supply visibility for
                                    efficient
                                    grid
                                    management
                                </div>


                            </div>
                            <div class="img-container"><img alt="" src="assets/images/network-management-menu-item.png">
                                <div class="img-vector" *ngIf="isFirstBlockVisible">
                                    <img alt="" src="assets/images-new-design/Vector.svg">
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center" (mouseenter)="onClick(2)"
                        (mouseleave)="onMouseLeave()"
                        [ngClass]="{'disabled': !authService.isGroupAuthorized('commercial_') && !authService.isGroupAuthorized('revenueprotection_') && !authService.isGroupAuthorized('billing_') || !availableModules.includes('Commercial')}">
                        <div class="menu-card" [routerLink]="['/commercial']">

                            <div class="menu-text">
                                <div class="header">
                                    <div class="icons">
                                        <img alt="" src="assets/images-new-design/icons-commercial.png">
                                    </div>

                                    <div class="select-text" *ngIf="isSecondBlockVisible">
                                        <div class="select">{{ displayText }}
                                            <img alt="" src="assets/images-new-design/Arrow.png">
                                        </div>

                                    </div>
                                </div>

                                <div class="menu-title">Commercial

                                </div>
                                <div class="details">
                                    Simplify revenue management, customer interactions, and billing with our
                                    comprehensive
                                    commercial module.
                                </div>


                            </div>
                            <div class="img-container">
                                <img alt="" src="assets/images/revenue-protection-menu-item.png">
                                <div class="img-vector" *ngIf="isSecondBlockVisible">
                                    <img alt="" src="assets/images-new-design/Vector.svg">
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center" (mouseenter)="onClick(3)"
                        [ngClass]="{'disabled': !authService.isGroupAuthorized('metermanagement_') || !availableModules.includes('Meter Management')}">
                        <div class="menu-card" [routerLink]="['/meter-management/dashboard']">

                            <div class="menu-text">
                                <div class="header">
                                    <div class="icons">
                                        <img alt="" src="assets/images-new-design/icons-meter.png">
                                    </div>

                                    <div class="select-text" *ngIf="isThirdBlockVisible">
                                        <div class="select">{{ displayText }}
                                            <img alt="" src="assets/images-new-design/Arrow.png">
                                        </div>

                                    </div>
                                </div>

                                <div class="menu-title">Meter Management</div>
                                <div class="details">
                                    Aggregates and analyzes meter data for asset and customer insights.
                                </div>

                            </div>

                            <div class="img-container"><img alt="" src="assets/images/meter-management-menu-item.png">
                                <div class="img-vector" *ngIf="isThirdBlockVisible">
                                    <img alt="" src="assets/images-new-design/Vector.svg">
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center" (mouseenter)="onClick(4)"
                        (mouseleave)="onMouseLeave()"
                        [ngClass]="{'disabled': !authService.isGroupAuthorized('fieldoperations_') && availableModules || !availableModules.includes('Field Operations')}">
                        <div class="menu-card" [routerLink]="['/field-operations/dashboard']">

                            <div class="menu-text">
                                <div class="header">
                                    <div class="icons">
                                        <img alt="" src="assets/images-new-design/icons-transmission.png">
                                    </div>
                                    <div class="select-text" *ngIf="isFourthBlockVisible">

                                        <div class="select">{{ displayText }}
                                            <img alt="" src="assets/images-new-design/Arrow.png">
                                        </div>

                                    </div>
                                </div>
                                <div class="menu-title">Field Operations</div>
                                <div class="details">
                                    Streamlined fault logging, real-time tracking & optimized asset maintenance.
                                </div>
                            </div>
                            <div class="img-container"><img alt="" src="assets/images/field-operations-menu-item.png">
                                <div class="img-vector" *ngIf="isFourthBlockVisible">
                                    <img alt="" src="assets/images-new-design/Vector.svg">
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<footer class="footer">
    Powered by&nbsp;
    <div class="red">Beacon Power Services</div>
</footer>
