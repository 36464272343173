import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskCategoryService {

  private pathApi = this.config.setting['pathApi'] + "taskcategory/";

  constructor(private http: HttpClient,
              private config: AppConfig) { }

  getAll(obj: any) : Observable<any>{
    return this.http.post(`${this.pathApi}getAll`, obj);
  }

  getById(id: any) : Observable<any>{
    return this.http.get(`${this.pathApi}${id}`);
  }

  delete(id: any) : Observable<any>{
    return this.http.delete(`${this.pathApi}delete/${id}`);
  }

  save(obj: any) : Observable<any>{
    return this.http.post(`${this.pathApi}save`, obj);
  }
}
