import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: 'dayHoursTime'
})

export class DayHoursTimePipe implements PipeTransform {

    transform(value: number): string {
        const days = Math.floor(value/24);
        const hours = Math.floor(value % 24);
        let result = '';
    
        if (days > 0) {
          result += `${days}d `;
        }
    
        if (hours > 0) {
          result += `${hours}h`;
        }
    
        return result.trim();
    }
}