import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { Account } from '../models/account.model';
import { PaymentDetails } from '../models/paymentDetails';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private pathApi = this.config.setting['pathApi'] + "account/";

  constructor(private http: HttpClient,
    private config: AppConfig) { }

  get(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}${id}`);
  } 
  getAccBySearch(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAccBySearch`, obj);
  } 
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.pathApi}delete/${id}`);
  }   
  save(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}save`, obj);
  }

  updateStatus(obj:Account): Observable<any> {
    return this.http.post(`${this.pathApi}updateAccountStatus`, obj);
  }

  updateSPN(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}updateSPN`, obj);
  }

  updateStatusBulk(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}updateAccountStatusBulk`, obj);
  }

  getAll(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, obj);
  }

  getPaginationData(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getPaginationData`, obj);
  }

  getAllAccountsWithoutDts(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllAccountsWithoutDts`, obj);
  }
   
  getDataForAutocomplete(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
  }
  getAllAccounts(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllAccounts`, obj);
  }

  downloadAccounts(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadAccounts`, obj);
  }

  downloadAccountsForMeterManagement(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadAccountsForMeterManagement`, obj);
  }

  downloadAccountsCrudPage(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadAccountsCrudPage`, obj);
  }

  uploadFile(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile`, obj)
  }

  getTemplate(): Observable<any> {
    return this.http.get(`${this.pathApi}getTemplate`);
  }

  getAllAccountsByDtId(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}getAllAccountsByDtId/${id}`);
  }
  GetPaymentProp(obj: PaymentDetails): Observable<any> {
    return this.http.post(`${this.pathApi}GetPaymentProp`, obj);
  }


  uploadFileAccountDt(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}UploadFileAccountDt`, obj);
  }

  getAccountDtTemplate(): Observable<any> {
    return this.http.get(`${this.pathApi}GetAccountDtTemplate`);
  }
  
  getCustomersWithoutConsumption(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getCustomersWithoutConsumption`, obj);
  }
     
  getCustomerNoConsumptionDataForAutocomplete(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getCustomerNoConsumptionDataForAutocomplete`, obj)
  }
  getSystemHierarchy(obj: any):Observable<any>{
    return this.http.post(`${this.pathApi}getSystemHierarchy`, obj)
  }

  getMeterIdForAccountNo(accountNo: string): Observable<any> {
    return this.http.get(`${this.pathApi}getMeterIdForAccountNo/${accountNo}`)
  }
}
