    <div class="container-fluid">
        <app-transmission-station-create (parentComp)="tsTabComponent.reloadTable($event)"></app-transmission-station-create>
        <app-create-substation [feeders33]="feeders33ForSelect" (parentComp)="tabSubstationComponent.reloadTable($event)"></app-create-substation>
        <app-feeder33-create [transmissionStations]="transmissionStationsSelect" [amis]="amis" (parentComp)="feedersTabComponent.feeders33TabComponent.reloadTable($event)"></app-feeder33-create>
        <app-feeder11-create [amis]="amis" [substations]="substationsForSelect" [buForSelect]="busForSelect" [feeders33]="feeders33ForSelect" (parentComp)="feedersTabComponent.feeders11TabComponent.reloadTable($event)"></app-feeder11-create>
        <app-dt-create [amis]="amis" [feeder33s]="feeders33ForSelect" [undertakings]="utsForSelect" (parentComp)="dtTabComponent.reloadTable($event)"></app-dt-create>
        <app-dcu-create [amis]="amis" (parentComp)="dcuTabsComponent.reloadTable($event)"></app-dcu-create>
        <app-create-ami (parentComp)="tabAmiComponent.reloadTable($event)"></app-create-ami>
        <app-create-business-unit (parentComp)="tabBusinessUnitComponent.reloadTable($event)"></app-create-business-unit>
        <app-area-create [businessUnits]="busForSelect" (parentComp)="areaTabComponent.reloadTable($event)"></app-area-create>
        <app-account-create-status-tab (parentComp)="accountStatusTabComponent.reloadTable($event)"></app-account-create-status-tab>
        <app-create-meter-model (saveEvent)="tabMeterModelComponent.reloadTable($event)"></app-create-meter-model>

        <app-crud-delete-modal
            [selectedItem]="selectedItemDelete"
            (confirmDelete)="onDeleteConfirmed()"
            (cancelDelete)="resetSelectedDelete()">
        </app-crud-delete-modal>

        <app-import-details-popup
         (confirmUpload)="uploadFile()"
         (confirmGetTemplate)="downloadTemplate()"
         [placeholder]="'Import multiple customers data (Supported format XLSX).'"
        ></app-import-details-popup>
        <div class="main-container">

            <div class="header">
                <div>
                    <div class="titleCrud">CRUD</div>
                    <div class="secondTitleCrud">Create, read, update or delete {{tabName}}</div>
                </div>
                <div class="btns">
                    <ng-container regularButtons>
                        <button class="btn btn-outline btn-upload" (click)="openTabUpload()">
                            <span class="text">Upload File</span>
                        </button> 
                        <button class="btn" (click)="addNew()">
                            <span class="text">Add New</span>
                        </button>                        
                    </ng-container>
                </div>
            </div>
            <div class="titleHeader">

                <div class="input-wrapper">
                    <app-search-autocomplete
                        #search
                        [placeholder]="searchPlaceholder" 
                        [width]="25"
                        [backgroundColor]="'transparent'"
                        [borderColor]="'#E8E8E8'"
                        [(searchText)]="searchAutocomplete"   
                        [options]="options"       
                        (searchAutocompleteChange)="getOptions($event)"
                        (searchChange)="inputChanged($event)"
                    >     
                    </app-search-autocomplete>
                </div>
                <div class="download-wrapper">
                    <app-download-button-new
                    [isOnlyDownload]="true"
                    (downloadButtonClickedChange)="download()">
                    </app-download-button-new>  
                </div>
            </div>
            <div screenSizeDetector [addClasses]="false" [componentId]="'-crudSettings'">
                <mat-drawer-container autosize>
                    <app-page-tabs (selectedTabChange)="changeTab($event.index)" [tabs]="[ 
                    { label: 'Transmission Station', tabContentReference: firstTab, isVisible: crudTsVisible, isDisabled: false }, 
                    { label: 'Substations', tabContentReference: secondTab, isVisible: crudSsVisible, isDisabled: false },
                    { label: 'Feeders', tabContentReference: thirdTab, isVisible: crudFeedersVisible, isDisabled: false },
                    { label: 'Distribution Transformer', tabContentReference: fourthTab, isVisible: crudDtVisible, isDisabled: false },
                    { label: 'DCUs', tabContentReference: fifthTab, isVisible: crudDCUVisible, isDisabled: false },
                    { label: 'Meter Manufacturer', tabContentReference: sixthTab, isVisible: crudAmiVisible, isDisabled: false },                  
                    { label: buName, tabContentReference: seventhTab, isVisible: crudBUVisible, isDisabled: false },
                    { label: 'Tasks', tabContentReference: eighthTab, isVisible: crudAreaVisible, isDisabled: true },  
                    { label: utName, tabContentReference: ninthTab, isVisible: crudAreaVisible, isDisabled: false },
                    { label: 'Meter Model', tabContentReference: meterModelTab, isVisible: crudMeterModelVisible, isDisabled: false },
                    ]">
                        <ng-template #firstTab>
                            <app-transmission-station-tab 
                                *ngIf="!crudTsVisible" 
                                #tsTabComponent 
                                (parentComp)="openTab($event)" 
                                (deleteComp)="openDelete($event)"
                            ></app-transmission-station-tab>
                        </ng-template>

                        <ng-template #secondTab>
                            <app-substation-tab 
                                *ngIf="!crudSsVisible" 
                                #tabSubstationComponent 
                                (editTab)="openTab($event)" 
                                (selectList)="handleF33List($event)" 
                                (deleteComp)="openDelete($event)"
                            ></app-substation-tab>
                        </ng-template>

                        <ng-template #thirdTab>
                            <app-feeders-tab 
                                *ngIf="!crudFeedersVisible"
                                (editTab)="openTab($event)"
                                (deleteComp)="openDelete()"
                                (tsSelect)="getList($event)"
                                (amisSelect)="handleAmiList($event)"
                                (f33Select)="handleF33List($event)"
                                (busSelect)="handleBuList($event)"
                                (subSelect)="handleSubstationList($event)"
                                (tabChange)="handleTabChange($event)"
                                #feedersTabComponent
                            ></app-feeders-tab>
                        </ng-template>

                        <ng-template #fourthTab>
                          <app-dt-tab
                                      *ngIf="!crudDtVisible"
                                      (editTab)="openTab($event)"
                                      (deleteComp)="openDelete()"
                                      (amisSelect)="handleAmiList($event)"
                                      (f33Select)="handleF33List($event)"
                                      (utsSelect)="handleUtList($event)"
                                      #dtTabComponent
                          ></app-dt-tab>
                        </ng-template>

                        <ng-template #fifthTab>
                            <app-dcu-tabs 
                                *ngIf="!crudDCUVisible" 
                                #dcuTabsComponent
                                (editTab)="openTab($event)"
                                (deleteComp)="openDelete()"
                                (amisSelect)="handleAmiList($event)"
                            ></app-dcu-tabs>
                        </ng-template>

                        <ng-template #sixthTab>
                            <app-ami-tab 
                                *ngIf="!crudAmiVisible"
                                (editTab)="openTab($event)"
                                (deleteComp)="openDelete()"
                                #amiTabComponent
                            ></app-ami-tab>
                        </ng-template>

                        <ng-template #seventhTab>
                            <app-business-unit-tab 
                                *ngIf="!crudBUVisible" 
                                #tabBusinessUnitComponent
                                (editTab)="openTab($event)"
                                (deleteComp)="openDelete()"
                            ></app-business-unit-tab>
                        </ng-template>

                        <ng-template #eighthTab>
                        </ng-template>

                        <ng-template #ninthTab>
                            <app-area-tab 
                                *ngIf="!crudAreaVisible"
                                #areaTabComponent
                                (editTab)="openTab($event)"
                                (deleteComp)="openDelete()"
                                (busSelect)="handleBuList($event)"
                            ></app-area-tab>
                        </ng-template>

                        <ng-template #tenthTab>
                            <app-account-status-tab 
                                *ngIf="!crudAccStatusVisible"
                                #accountStatusTabComponent
                                (editTab)="openTab($event)"
                                (deleteComp)="openDelete()" 
                            ></app-account-status-tab>
                        </ng-template>
     
                        <ng-template #meterModelTab>
                            <app-meter-model-tab 
                                *ngIf="!crudMeterModelVisible"
                                #meterModelTabComponent
                                (amisFetchedEvent)="handleAmiList($event)"
                                (editEvent)="openTab($event)"
                                (deleteEvent)="openDelete()" 
                            ></app-meter-model-tab>
                        </ng-template> 
                    </app-page-tabs>


                </mat-drawer-container>
            </div>
        </div>
    </div>
