import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PopupEnum } from 'src/app/models/popupEnum';

@Component({
  selector: 'app-add-team-agent-multiselect',
  templateUrl: './add-team-agent-multiselect.html',
  styleUrls: ['./add-team-agent-multiselect.scss']
})
export class AddAgentPopupForFieldOperationComponent implements OnInit {

  isDialogOpen: boolean = false;

  @Input() data: any[] = [];
  @Input() searchText: string = '';

  @Output() selectData: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<void> = new EventEmitter<void>();
  @Output() isDialogOpenChange = new EventEmitter<number>();

  teamLeadsData: any[] = [];
  optionSelected : boolean = false
  public popupEnum = PopupEnum
  public dataNew: any[] = []

  public selectedItems: any[] = []

  ngOnInit(): void {
    this.reset()
    this.teamLeadsData=[...this.data]


  }
  ngOnChanges(changes: SimpleChanges) {
    this.data = changes.data.currentValue
    this.dataNew = [...this.data]
  }

  add(){
    this.selectData.emit([...this.selectedItems])
    this.changeState(PopupEnum.Close)
  }

  reset(){
    this.selectedItems = []
  }

  changeState(value: PopupEnum) {
    if(value == PopupEnum.Open){
      this.searchText = ''
      this.isDialogOpen = true;
    }

    if(value == PopupEnum.Close)
      this.isDialogOpen = false;
  }

  select(item: any){
    if(this.isChecked(item)){
      this.selectedItems = this.selectedItems.filter(x => x.id != item.id)
    }
    else{
      this.selectedItems.push(item)
    }
  }

  searchChanged(){
    this.dataNew = [...this.data]
     this.dataNew = this.data.filter((team) => {
      const fullName = (team.firstName ? team.firstName + ' ' : '') + (team.lastName ? team.lastName : '');
      return fullName.toLowerCase().includes(this.searchText.toLowerCase());
    });

  }

  isChecked(item: any){
    let items = this.selectedItems.filter(x => x.id == item.id)
    if(items != null){
      if(items.length == 0)
        return false
    }
    return true
  }
}
