<main class="">
    <div class="user-profile">
        <div class="container-wrapper">
            <div class="revenue-protection-header">
                <div class="title-container">
                    <div class="title-u">
                        Job Status History
                    </div>
                    <div class="subtitle">
                        Read or update job statuses
                    </div>
                </div>
            </div>

            <div class="filters-container">
                <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                    <app-multi-filter
                        *ngIf="filterApplied" [data]="allFilters" (dataChange)="applyFilter($event)"
                        [(showFilter)]="showFilter">
                    </app-multi-filter>

                    <app-range-datepicker
                        [fromDate]="dateFrom"
                        [toDate]="dateTo"
                        [name]="'Period'"
                        [maxDate]="maxDate"
                        (dateRangeSelected)="updateDate($event)">
                    </app-range-datepicker>
                </div>

                <div class="download-button">
                    <app-download-button-new
                      [isOnlyDownload]="true"
                      (downloadButtonClickedChange)="downloadReport()">
                    </app-download-button-new>
                </div>
            </div>
            <table class="standard-table">
                <thead>
                    <tr>
                        <th class="checkbox-td"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" (change)="checkUncheckAll()"></th>
                        <th><span>START DATE</span></th>
                        <th><span>END DATE</span></th>
                        <th><span>TYPE</span></th>
                        <th><span>STATUS</span></th>
                        <th><span>ACTION</span></th>
                        <th><span>DOWNLOAD</span></th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr *ngFor="let item of jobStatuses" class="bodyRow clickable actions-click">
                        <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" ></td>
                        <td (click)="showDetails(item)">{{item.startDate | dateTimeNoConvert}} </td>
                        <td (click)="showDetails(item)">{{item.endDate | dateTimeNoConvert}} </td>
                        <td (click)="showDetails(item)">{{item.integrationTypeValue | hasValue}} </td>
                        <td (click)="showDetails(item)">
                            <span [ngClass]="['status-job-status', getStatusValue(item.currentStatusValue?.toLowerCase() || '')]">
                                <span>{{item.currentStatusValue | hasValue}}</span>
                            </span>
                        </td>
                        <td>
                            <div class="last-child action" (click)="showDetails(item)" click-stop-propagation>
                                <img alt="Create" class="" src="assets/images-new-design/new_settings_pen.svg" data-toggle="modal"
                                data-target="#createModal"
                                />
                            </div>
                        </td>
                        <td>
                            <a href={{item.file}} download *ngIf="item.file != '' && item.file != null">
                                <img alt="Download" src="assets/images/ic_download_file.svg"/>
                            </a>
                            <span *ngIf="item.file == '' || item.file == null" class="NaN">N/A</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="col-sm-12 pagination-wrapper">
                <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
                    [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
            </div>
        </div>

    </div>
</main>

<div class="popup-overlay" [hidden]="!isDetails" (click)="isDetails = false">
    <div class="popup-content" [ngClass]="{'extended': selectedJob?.currentStatusValue !== 'Completed'}"
        click-stop-propagation>
        <div class="red-line"></div>
        <div class="top-wrapper" (click)="isDetails = false">
            <span>Job status details</span>
            <span class="red-text">CLOSE</span>
        </div>
        <table class="standard-table">
            <tbody>
                <tr>
                    <th>Type:</th>
                    <td>{{selectedJob?.integrationTypeValue}}</td>
                </tr>
                <tr>
                    <th>Start date:</th>
                    <td>{{selectedJob?.startDate | date:'medium':'GMT'}}</td>
                </tr>
                <tr>
                    <th>End date:</th>
                    <td>{{selectedJob?.endDate | date:'medium':'GMT'}}</td>
                </tr>
                <tr>
                    <th>Run mode:</th>
                    <td>{{selectedJob?.runModeValue}}</td>
                </tr>
                <tr>
                    <th>Status:</th>
                    <td>{{selectedJob?.currentStatusValue}}</td>
                </tr>
                <tr *ngIf="selectedJob?.currentStatusValue !== 'Completed'">
                    <th>Error description:</th>
                    <td>{{selectedJob?.errorDescription}}</td>
                </tr>
            </tbody>
        </table>

        <div class="red-line red-line-bottom">
        </div>
    </div>
