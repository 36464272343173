import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { HeaderService } from 'src/app/services/header.service';
import { RoleService } from 'src/app/services/role.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal!: ElementRef;


  public PermissionsEnum = PermissionsEnum;
  public disableEmail = false;
  public currentPage: number = 1;
  public pageSize: number = 20;
  public count: number = 0;

  public fileChosen: any = null;
  public file: any = null;

  private ngUnsubscribe = new Subject<void>();
  private searchText: string = "";

  public searchByFirstName: string = '';
  public searchByLastName: string = '';
  public searchByUserName: string = '';
  public searchByEmail: string = '';
  public searchByPhoneNumber: string = '';

  public emailValid: boolean = true;
  public phoneNumberValid: boolean = true;
  public passwordValid: boolean = true;
 
  public users: User[] = [];
  public roles: Role[] = [];
  public selectedUser: User = new User();

  @ViewChild("f") f: any;
  constructor(private userService: UserService,
    private commonService: CommonService,
    private headerService: HeaderService,
    private roleService: RoleService,
    private toastr: ToastrService,
    public authService: AuthService,
    private location: Location) { }

  ngOnInit(): void {
    this.reloadTable();
    this.subscribeToSearch();
    this.getRoles();
    console.log(this.selectedUser)
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }
  back() {
    this.location.back();
  }
  getRoles(): void {
    let obj = {
      pageInfo: {
        pageSize: 0
      },
      filterParams: {
      }
    };
    this.roleService.getAll(obj).subscribe(response => {
      this.roles = response.data.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  reloadTable(page: any = null) {

    console.log("TEst")
    if (page)
      this.currentPage = page;

    this.resetSelectedUser();

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchContent: this.searchText
      }
    };

    this.userService.getAll(obj).subscribe(response => {
      this.users = response.data.data;
      this.count = response.data.count;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  selectUser(guid: string) {
    this.resetSelectedUser();
    this.disableEmail = true;
    this.userService.getByGuid(guid).subscribe(response => {
      if (response.status === 200)
        this.selectedUser = response.data;
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });


  }

  resetSelectedUser() {

    this.selectedUser = new User();
    this.disableEmail = false;

    if (this.f)
      this.f.submitted = false;
  }


  deleteUser(e: any, guid: string) {
    e.stopPropagation();

    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_user_delete)) {
      this.toastr.error("You don't have permission.");
      return;
    }

    this.userService.delete(this.selectedUser.userGuid).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.reloadTable();
        this.closeDeleteModal.nativeElement.click();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  save() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_user_createupdate)) {
      this.toastr.error("You don't have permission.");
      return;
    }

    if (this.commonService.isEmail(this.selectedUser.email))
      this.emailValid = true;
    else
      this.emailValid = false;

    if (this.commonService.isPhoneNumber(this.selectedUser.phoneNumber))
      this.phoneNumberValid = true;
    else
      this.phoneNumberValid = false;

    if (this.emailValid == false || this.phoneNumberValid == false || this.selectedUser.roleId == 0)
      return;

    this.userService.save(this.selectedUser).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.reloadTable();
        this.closeModal.nativeElement.click();
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch() {
    this.headerService.globalSearch$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchValue => {
        if (searchValue.clicked && searchValue.componentName === ComponentNamesNew.UserManagementComponent) {
          console.log("Test")
          this.searchText = searchValue.text;
          this.currentPage = 1;
          this.reloadTable();
          return;
        }
        this.searchText = '';
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
