export enum WomStatus {
    Unassigned = 1,
    Ongoing = 2,
    Completed = 3,
    Cancelled = 4
}

export enum PriorityTypes{
    Low = 0,
    Medium = 1,
    High = 2
}