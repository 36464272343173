import { Component, Input } from '@angular/core';
import { PaymentCollectionModalData } from 'src/app/models/revenue-modal-data';
import { PaymentCollectionService } from 'src/app/services/payment-collection.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-payment-collections-popup',
  templateUrl: './payment-collections-popup.component.html',
  styleUrls: ['./payment-collections-popup.component.scss']
})
export class PaymentCollectionsPopupComponent {
  // @Input('openModal') openModal: number = 0;
  @Input() isPopupOpen: boolean = false;

  selectedPayment = new PaymentCollectionModalData;

  @Input() animationState = 'out';

  public payment: any=[];
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');

  public tab: number = 1

  constructor(private translationService: TranslationService, private paymentCollectionService: PaymentCollectionService) { }


  changeState(number: Number, payment: PaymentCollectionModalData){
    this.selectedPayment = payment

    if (number === 1){
      this.getPayment(parseInt(this.selectedPayment.accountId));
      this.isPopupOpen = true;
    }
    if (number === 2)
    {
      this.isPopupOpen = false;
    }
  }
  getPayment(id: number){
    let obj={
      AccId:this.selectedPayment.accountId,
      DateOfPayment:this.selectedPayment.paymentDate
    }
    id=parseInt(this.selectedPayment.accountId);
    this.paymentCollectionService.get(obj).subscribe(response => {
      if(response.status === 200)
        this.payment = response.data;
      });
  }


}
