<h2 class="h2-pom">Total Energy into Network</h2>
<div class="table">
  <table class="standard-table">
    <thead>
      <tr>
        <th *ngIf="tabNumber == 1">FEEDER NAME</th>
        <th *ngIf="tabNumber == 2">FEEDER NAME</th>
        <th *ngIf="tabNumber == 3">DT NAME</th>
        <th *ngIf="tabNumber == 5">{{utName}} NAME</th>
        <th *ngIf="tabNumber == 4 || tabNumber == 5">{{buName}} NAME</th>
        <th>ENERGY RECEIVED (MWH)</th>
        <th *ngIf="tabNumber == 1 || tabNumber == 2">ENERGY TRANSMITTED (MWH)</th>
        <th *ngIf="tabNumber == 3 || tabNumber == 4 || tabNumber == 5">ENERGY CONSUMED (MWH)</th>
        <th>ENERGY LOSSES (MWH)</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr *ngFor="let item of tableData; let i = index">
        <td class="bold">{{item.name}} </td>
        <td *ngIf="tabNumber == 5" class="bold">{{item.regionName}}</td>
        <td class="bold">{{item.received | number: '1.2-2' || "/" }}</td>
        <td class="bold">{{item.consumed | number: '1.2-2' || "/"}}</td>
        <td class="bold">{{item.lost | number: '1.2-2' || "/" }}</td>
      </tr>
      <tr>
              <td class="average">AVERAGE</td>
              <td *ngIf="tabNumber == 5" class="average"></td>
              <td class="average" style="padding-left: 1rem !important;">{{avgReceived | number: '1.2-2' || "/" }}</td>
              <td class="average" style="padding-left: 1rem !important;">{{avgEnergyConsumption | number: '1.2-2' || "/" }}</td>
              <td class="average" style="padding-left: 1rem !important;">{{avgLosses | number: '1.2-2' || "/" }}</td>
      </tr>
    </tbody>
  </table>
</div>