import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { Substation } from '../models/substation.model';

@Injectable({
  providedIn: 'root'
})
export class SubstationService {

  private pathApi = this.config.setting['pathApi'] + "substation/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    } 

    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    } 

    save(obj: Substation): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }

    getAll(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, obj);
    } 

    getCount(): Observable<any> {
      return this.http.get(`${this.pathApi}getCount`);
    }
    
    uploadFile(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}uploadFile`, obj)
    }
 
    getAllForSelect(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSelect`);
    }

    getTemplate(): Observable<any>{
      return this.http.get(`${this.pathApi}getTemplate`);
    }

    getAllSsNames(): Observable<any>{
      return this.http.get(`${this.pathApi}GetAllSsNames`);
    }

    getAllSsDictionaryItem(): Observable<any>{
      return this.http.get(`${this.pathApi}getAllSsDictionaryItem`);
    }

    getSSConnectedToF33(id: number): Observable<any>{
      return this.http.get(`${this.pathApi}getAllConnectedToF33/${id}`);
    }

    getInfoForMapDialog(id: number): Observable<any>{
      return this.http.get(`${this.pathApi}GetInfoForMapDialog/${id}`)
    }

    getConsumptionReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}consumptionReport`, obj);
    }

    download(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}download`, obj);
    }
    getSystemHierarchy(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getSystemHierarchy`, obj)
    }
}
