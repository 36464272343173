import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AttachmentsPopupComponent } from 'src/app/components/common-new-design/attachments-popup/attachments-popup.component';
import { CaimsOperator } from 'src/app/models/caimsOperator.model';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { PopupEnum } from 'src/app/models/popupEnum';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';
import { PriorityTypes, WomStatus } from 'src/app/models/womStatus.model';
import { WorkOrder } from 'src/app/models/workOrder.model';
import { CommonService } from 'src/app/services/common-service.service';
import { FieldAgentsService } from 'src/app/services/field-agents.service';
import { UserService } from 'src/app/services/user.service';
import { WorkOrderManagement } from 'src/app/services/work-order-management.service';
import { ReasonPopupComponent } from '../reason-popup/reason-popup.component';
import { TranslationService } from 'src/app/services/translation.service';
import { ChangeDatePopupComponent } from './change-date-popup/change-date-popup.component';

@Component({
  selector: 'app-wom-details-main',
  templateUrl: './wom-details-main.component.html',
  styleUrls: ['./wom-details-main.component.scss']
})
export class WomDetailsMainComponent implements OnInit, OnChanges {

  @ViewChild(AttachmentsPopupComponent) attachmentsPopupRef!: AttachmentsPopupComponent
  @ViewChild(ReasonPopupComponent) popupRef!: ReasonPopupComponent;
  @ViewChild(ChangeDatePopupComponent) datePopupRef!: ChangeDatePopupComponent

  public defaultImage: string = 'assets/images-new-design/defaultImg.png'

  @Output() changeView: EventEmitter<any> = new EventEmitter();
  @Output() setTaskId: EventEmitter<any> = new EventEmitter();
  @Output() reasonPopup: EventEmitter<any> = new EventEmitter();
  @Output() changeDatePopup: EventEmitter<any> = new EventEmitter();

  @Input() task = new WorkOrder()
  public showTask: WorkOrder = new WorkOrder()

  public agents: any[] = []
  public statuses: any[] = []
  public taskAgent: CaimsOperator = new CaimsOperator()

  public selectedAgent: CaimsOperator = new CaimsOperator()
  public selectedStatus: DictionaryItem = new DictionaryItem()
  public agentName: string = ''

  public womStatus = WomStatus
  public priorityType = PriorityTypes

  public isReadMore: boolean = false
  public selectedAgentHeader = 'Assign to'
  public selectedAgentHeaderOld: string = ''

  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')

  @Input() teamId: number[] = []
  constructor(
    private commonService: CommonService,
    private toastr: ToastrService,
    private userService: UserService,
    private caimsOperatorsService: FieldAgentsService,
    private workOrderService: WorkOrderManagement,
    private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    if (this.task.taskId !== '') {
      this.showTask = this.task
      this.fetchWorkOrder();
    }

    this.getAllWorkOrderStatuses()
    this.selectedAgent.userName = 'Assign to'
    this.selectedStatus.name = 'Mark as'
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.task != null && changes.task.currentValue != undefined) {
      this.showTask = changes.task.currentValue
      this.setStartValues()
    }
  }

  setStartValues() {
    this.setAgentName()
    this.getAllAgentsForSelect()
    this.selectedStatus.value = this.showTask.statusId
    this.selectedStatus.name = this.showTask.statusName
    if (this.showTask.assignedUser != '' && this.showTask.assignedUser != null) {
      this.getAgentById(this.showTask.assignedUser)
    }

    if (this.showTask.assignedUser != '' && this.showTask.assignedUser != null) {
      this.statuses = this.statuses.filter(x => x.value != WomStatus.Unassigned)
    }
  }

  back() {
    this.changeView.emit(ViewsEnum.View)
  }

  selectAgent(item: any) {
    this.selectedAgent = this.agents.find(x => x.id == item)
    this.selectedAgentHeaderOld = this.selectedAgentHeader
    this.selectedAgentHeader = this.selectedAgent.userName
    this.showTask.assignedUserId = item
    this.datePopupRef.changeState(this.showTask);
  }

  selectStatus(item: DictionaryItem) {
    this.selectedStatus.value = item.value
    this.getAllAgentsForSelect()
    if (this.selectedStatus.value == 4){
      this.openReasonPopup();
      // this.updateWorkOrderStatus()
    }
    else{
      this.updateWorkOrderStatus();
    }
  }

  updateWorkOrderStatus(reason?: string) {
    let obj = {
      Id: this.showTask.id,
      Status: this.selectedStatus.value,
      ReasonForCancellation: reason
    }

    this.workOrderService.updateWorkOrderStatus(obj).subscribe(response => {
      if (response.status != 200) {
        this.toastr.error(response.message)
        this.fetchWorkOrder()
      }
      else {
        this.toastr.success('Successfuly updated status')
        this.fetchWorkOrder()
      }
    }, _ => {
      this.toastr.error("An error occurred")
    })
  }

  getAllWorkOrderStatuses() {
    this.commonService.getAllWorkOrderStatuses().subscribe(response => {
      this.statuses = response.data
    }, _ => {
      this.toastr.error("An error occurred")
    })
  }

  getAllAgentsForSelect() {
    if(this.task.assignedTeamId != null)
    this.userService.getAllAgentsByTeamId(this.task.assignedTeamId).subscribe(response => {
      this.agents = response.data
      this.selectDefaultAssignedAgent(this.agents)
    }, _ => {
      this.toastr.error("An error occurred")
    })
  }

  selectDefaultAssignedAgent(agents: any) {
    if(this.selectedStatus.value == WomStatus.Completed || this.selectedStatus.value == WomStatus.Cancelled){
      this.agents = this.agents?.filter(x => x.email === this.showTask.assignedUser)
    }
    
    if (this.agents != null) {
      let users = this.agents.find(x => x.email === this.showTask.assignedUser)
      if (users != undefined && users != null) {
        this.taskAgent = users
      }
    }
  }

  viewAttachments() {
    this.attachmentsPopupRef.changeState(PopupEnum.Open)
  }

  openEdit() {
    this.changeView.emit(ViewsEnum.Edit)
  }

  fetchWorkOrder() {
    this.workOrderService.getOne(this.showTask.id).subscribe(response => {
      this.showTask = response.data
      // this.showTask.attachments = this.showTask.images;
      this.updateDropdowns(this.showTask)
    }, _ => {
      this.toastr.error("An error occurred")
    })
    this.selectedStatus.name = ''
  }

  setAgentName() {
    this.agentName = this.showTask.agentUserName == null ? '' : this.showTask.agentUserName
  }

  updateDropdowns(task: WorkOrder) {
    this.selectedStatus.value = task.statusId
    this.selectedStatus.name = task.statusName
    this.setAgentName()
    this.getAgentById(task.assignedUser)
  }

  getAgentById(email: string) {
    this.caimsOperatorsService.getByEmail(email).subscribe(response => {
      this.selectedAgent = response.data
      if (this.selectedAgent != null) {
        this.selectedAgentHeader = this.selectedAgent.userName
      }
      else {
        this.selectedAgentHeader = 'Assign to'
      }
    }, _ => {
      this.toastr.error("An error occurred")
    })
  }

  copyToClipboard(text: any) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }

  isOverdue(date: Date) {
    let temp = new Date(date)
    let now = new Date()
    now.setHours(0, 0, 0, 0)
    if (date != null)
      return temp < now
    return false
  }

  openReasonPopup() {
    this.popupRef.changeState();
  }

  getWorkOrder(){
    this.workOrderService.getOne(this.showTask.id).subscribe(response =>{
      this.showTask = response.data
      this.updateDropdowns(this.showTask)
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  cancelReassign() {
    this.selectedAgentHeader = this.selectedAgentHeaderOld
  }
}
