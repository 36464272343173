<div class="filters" appClickOutside (clickOutside)="close()">
    <div class="filter-wrapper" >
        <div class="multi-filter" (click)="filterClick()">
            <img [ngClass]="{'pressed': filterClicked}" alt="" src="assets/images-new-design/choose_filter.png">
            <span>Add Filter</span>
            <img click-stop-propagation (click)="showFilters(); close()" alt="" src="assets/images-new-design/show_filter.png">
        </div>
    </div>
    <div class="show-all-filters" *ngIf="showFilter" (click)="close()">
        <div class="filter" *ngFor="let item of checkedList">
            <span class="name-filter">{{item.name}}:</span>
            <span title="{{item.data[0].name}}" class="name-filter second-name-filter">{{item.data[0].name}}</span>
            <div (mouseover)="showTooltip(item)" (mouseout)="hideTooltip()" *ngIf="item.data.length > 1" class="plus-circle"><span>+{{item.data.length-1}}</span></div>
            <svg class="svg-close" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="resetFilter(item)">
                <path d="M1.39953 13.6538L0.345703 12.6L5.9457 7.00002L0.345703 1.40002L1.39953 0.346191L6.99953 5.94619L12.5995 0.346191L13.6534 1.40002L8.05335 7.00002L13.6534 12.6L12.5995 13.6538L6.99953 8.05384L1.39953 13.6538Z" fill="#2E3336"/>
            </svg>
            <div class="custom-tooltip"  [ngClass]="{'selected': item === selectedItem}" (mouseover)="showTooltip(item)" (mouseout)="hideTooltip()">
                <div class="tooltip-item-wrapper">
                    <div class="tooltip-item" *ngFor="let item2 of item.data" title="{{item2.name}}">
                    <span>{{item2.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="multi-filter-dropdown" [ngClass]="{'selected': filterClicked}" [style.height]="height" >
        <app-multi-filter-dropdown
            [data]="tempData"
            [name]="categorySelected?.name ?? '/' "
            [(filterClicked)]="filterCategoryClicked"
            (dataChange)="categorySelect($event)">
        </app-multi-filter-dropdown>
            <app-multi-filter-multiselect *ngFor="let item of tempData" [ngClass]="{'selected': item.name === categorySelected.name}" class="multiselect"
            [data]="item"
            [dropdownSelected]="dropdownSelected"
            [search]="search"
            [(searchText)]="searchText"
            (dataChange)="dropdownSelect(item,$event)">
            </app-multi-filter-multiselect>
        <div *ngIf="!categorySelected?.dropdown" class="button-wrapper">
            <div class="apply-button" (click)="apply(tempData)">
                <span>Filter</span>
              </div>
        </div>
    </div>
</div>
