<div class="dialog-content-root">
    <div class="header">
        <img alt="" src="assets/map/ic_ss_dialog.svg"/>
        <div>Substation</div>
    </div>
    <div class="title">{{ssData.name}}</div>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-sm-6 table-col">
                <div class="label">Address</div>
                <div class="value">{{ssData.address}}</div>
            </div>
            <div class="col-sm-6 table-col">
                <div class="label">Injection ID</div>
                <div class="value">{{ssData.id}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 table-col">
                <div class="label">Geo coordinate</div>
                <div class="value">{{ssData.latitude}}, {{ssData.longitude}}</div>
            </div>
            <div class="col-sm-6 table-col">
                <div class="label">Conn. 33KV Feeders</div>
                <div class="value">{{ssData.feeder33Name}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 table-col">
                <div class="label">Losses (MWh)</div>
                <div class="value">{{ssData.lossesMwh}}</div>
            </div>
            <div class="col-sm-6 table-col">
                <div class="label">Connected DTs</div>
                <div class="value">{{ssData.dtsCount}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 table-col">
                <div class="label">Outgoing feeder</div>
                <div class="value">{{ssData.feeders11Count}}</div>
            </div>
        </div>
    </div>
</div>
