import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MultiFilterComponent } from 'src/app/components/common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { transformToString } from 'src/app/helpers/dateUtils';
import { CurrentBillCollection } from 'src/app/models/current-bill-collection';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { RevenueDashboardService } from 'src/app/services/revenue-dashboard.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';

@Component({
  selector: 'app-current-total-collections',
  templateUrl: './current-total-collections.component.html',
  styleUrls: ['./current-total-collections.component.scss']
})
export class CurrentTotalCollectionsComponent implements OnInit {

  public weeklyCollection: CurrentBillCollection[] = []
  public weeklySum: number = 0
  public weeklyCustomersNo: number = 0
  public monthlyCollection: CurrentBillCollection[] = []
  public monthlySum: number = 0
  public monthlyCustomersNo: number = 0
  public searchText: SearchAutocomplete = new SearchAutocomplete();

  public filterSelected = 1
  public buName: string = this.translationService.getByKeyFromCache('BU');

  public count = 33;
  public page = 1;
  public pageSize = 5;
  public buItems: any[] = [];
  public buSelectedName: string = 'Region'
  public buSelectedId: number = -1
  public totalAmount: number = 0
  public totalCustomers: number = 0

  public dateFrom: any = "";
  public dateTo: any = "";

  public allFilters: any = []
  public businessUnits: any = { name: this.buName, data: [], dropdown: true};
  public filterApplied: boolean = false;
  public showFilter: any = false
  public buId: number = 0
  public searchPlaceholder: string = "Search by Area name"
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  options = ['']

  constructor(
    private dashboardService: RevenueDashboardService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private buService: BusinessUnitService,
    private utService: UndertakingService
    ) { }

  ngOnInit(): void {
    this.setMainDate()
    this.getAllBusinessUnits()
    this.getCurrentTotalCollection()
  }

  pageChange(page: any){
    this.page = page
    this.getCurrentTotalCollection()
  }

  updateDate(date: any){
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.getCurrentTotalCollection()
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    if(dte.getMonth() == 0)
    {
      this.dateFrom = {year: dte.getFullYear() - 1, month: 12, day: 1};
    }
    else {
      this.dateFrom = {year: dte.getFullYear(), month: dte.getMonth(), day: 1};
    }
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
  }

  filterClicked(value: number){
    this.filterSelected = value
  }

  getCurrentTotalCollection(){
    let obj = {
      PageInfo:{
        Page: this.page,
        PageSize: this.pageSize
      },
      FilterParams: {
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        BuId: this.buId,
        SearchText: this.searchText.name
      }
    };

    this.dashboardService.getCurrentTotalCollection(obj).subscribe(response =>{
      if(response.status === 200){
        if(this.page == 1)
          this.count = response.data.data.count
        this.totalCustomers = response.data.totalCustomers
        this.totalAmount = response.data.totalAmount
        this.handleMonthlyCollection(response.data.data.data)
      }
      else{
        this.toastr.error(response.message)
      }
    })
  }

  handleMonthlyCollection(data: any[]){
    console.log(data)
    this.monthlyCollection = data;
    this.monthlySum = this.monthlyCollection.reduce((sum, current) => sum + current.amount, 0)
    this.monthlyCustomersNo = this.monthlyCollection.reduce((sum, current) => sum + current.customers, 0)
  }

  getAllBusinessUnits(){
      this.buService.getAllForSelect().subscribe(response => {
        this.businessUnits = { name: this.buName, data: response.data, dropdown: true }
        this.checkFilters();
      }, _ => {
        this.toastr.error('An error occurred.')
      });
  }

  selectBu(data: any){
    this.buSelectedId = data.value
    this.buSelectedName = data.name
    this.page = 1
    this.getCurrentTotalCollection()
  }

  searchChange(value: SearchAutocomplete){
    this.searchText = value
    this.getCurrentTotalCollection()
  }

  checkFilters() {
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnits.name);

    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);

    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;

    if (!buData) {
      this.allFilters.push(this.businessUnits)

      this.filterApplied = true
    }

    this.allFilters = [...this.allFilters]
  }

  businessUnitSelected(item: any) {
    if(!item.item)
      this.buId = 0
    if(item.item && this.buId != item.item.value) {
      this.buId = item.item.value;
    }
    this.checkFilters()
    this.pageChange(1);
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    this.pageChange(1)
  }

  getOptions(searchText: SearchAutocomplete) { 
    let obj = {
      searchText: searchText.name, 
      msnIncluded: false
    }
    if (searchText.name.length > 2) {
      this.utService.getDataForAutocomplete(obj).subscribe({ 
        next : (response) => this.options = response.data,
        error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
      })
    } else
      this.searchComponentRef.resetOptions()
  }

  resetFilters() {
    this.searchText = new SearchAutocomplete();
    this.searchComponentRef.searchText = new SearchAutocomplete();
    this.showFilter = false
    this.buId = 0
    this.allFilters = []
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.searchComponentRef.resetOptions()
    this.setMainDate()
    this.checkFilters()
    this.pageChange(1);
  }

  inputChanged(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.pageChange(1)
    this.getCurrentTotalCollection()
  }

}
