import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class JobstatusService {

  private pathApi = this.config.setting['pathApi'] + "JobStatus/";

  constructor(private http: HttpClient, 
    private config: AppConfig) { }

  getHistory(obj: any): Observable<any[]>{
    return this.http.post<any[]>(`${this.pathApi}GetHistory`, obj)
  }

  getAllForSelect(): Observable<any> {
    return this.http.get(`${this.pathApi}getAllForSelect`);
  }

  downloadReport(obj: any):Observable<any>{
    return this.http.post<any[]>(`${this.pathApi}download`, obj)
  }
  
  downloadFile(obj: any):Observable<any>{
    return this.http.post<any[]>(`${this.pathApi}downloadFile`, obj)
  }
}
