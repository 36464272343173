import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlannedOutageService {
  private pathApi = this.config.setting['pathApi'] + 'PlannedOutage/';
  constructor(private http: HttpClient, private config: AppConfig) {}

  uploadFile(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile`, obj);
  }

  createPlannedOutage(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}createPlannedOutage`, obj);
  }
  updatePlannedOutage(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}updatePlannedOutage`, obj);
  }
  getAll(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllPaginated`, obj);
  }
  getById(id: any): Observable<any> {
    return this.http.get(`${this.pathApi}getById/${id}`, id);
  }
  getTemplate(): Observable<any> {
    return this.http.get(`${this.pathApi}getTemplate`, {
      responseType: 'blob',
    });
  }
}
