import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-multi-filter-dropdown',
  templateUrl: './multi-filter-dropdown.component.html',
  styleUrls: ['./multi-filter-dropdown.component.scss']
})
export class MultiFilterDropdownComponent {
  @Input() filterClicked: boolean = false;
  @Output() filterClickedChange = new EventEmitter();

  @Input() name: any;
  @Input() data: any;

  @Output() dataChange = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.name !== undefined){
      this.name = changes.name.currentValue;
    }
  }

  changeData(item:any){
    let dataChange = item

    this.dataChange.emit(dataChange);
  }
  public filterClick() {
    this.filterClicked = !this.filterClicked;
    this.filterClickedChange.emit(this.filterClicked);
  }

  public close(){
    this.filterClicked = false;
    this.filterClickedChange.emit(this.filterClicked);
  }
}
