import { Component, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WorkOrderManagement } from 'src/app/services/work-order-management.service';

@Component({
  selector: 'app-reason-popup',
  templateUrl: './reason-popup.component.html',
  styleUrls: ['./reason-popup.component.scss']
})
export class ReasonPopupComponent {

  public isPopupOpen: boolean = false;

  reason: string = "";

  @Output() statusChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private toastr: ToastrService,
    private workOrderService: WorkOrderManagement
  ) { }

  close() {
    this.isPopupOpen = false;
  }

  changeState() {
    this.isPopupOpen= !this.isPopupOpen;
  }

  save() {
    if(this.reason != ""){
      this.statusChanged.emit(this.reason);
      this.changeState();
    }
    else
      this.toastr.error("Reason is required!")
  }
}
