import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { LogoutHubService } from 'src/app/services/logout-hub.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-global-landing-page-new',
  templateUrl: './global-landing-page-new.component.html',
  styleUrls: ['./global-landing-page-new.component.scss']
})
export class GlobalLandingPageNewComponent implements OnInit {
  @ViewChild('thenBlock') thenBlock!: TemplateRef<any>;
  @ViewChild('secondBlock') secondBlock!: TemplateRef<any>;
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  @ViewChild('container2', { read: ViewContainerRef }) container2!: ViewContainerRef;


  public PermissionsEnum = PermissionsEnum;
  public billingUserId: boolean = false
  public havePermissions: boolean = false 
  displayText: string = 'Tekst';

  availableModules: string[] = []
 
  isFirstBlockVisible = false;
  isSecondBlockVisible=false;
  isThirdBlockVisible = false;
  isFourthBlockVisible = false;
  isFifthBlockVisible = false;
  isSixthBlockVisible = false; 
  public border: string = '1px solid rgba(133, 133, 133, 1)'

  logo: string = '';
  
  constructor(private toastr: ToastrService, public authService: AuthService,
    private userService: UserService, private logoutHub: LogoutHubService) {}

  ngOnInit(): void {
    //this.getUserBillingId()
    const user: any = this.authService.decodeJWTToken();
    document.documentElement.style.setProperty('--user-color', user?.primaryColorAdora );  
    document.documentElement.style.setProperty('--user-secondary-color', user?.secondaryColorAdora);
    document.documentElement.style.setProperty('--user-fontFamily', user?.fontStyleAdora);

    this.logo = user?.logo;

    this.logoutHub.init();

    this.getPermissions();
    this.getAvailableModules();
  }

  getAvailableModules() {
    this.availableModules = this.authService.getCurrentUser().availableModules ?? []; 
    // this.availableModules=['Commercial,Field Operations,Meter Management,Network Management']
    // this.availableModules[2]='Field Operations'
    // this.availableModules[3]='Meter Management'
    // this.availableModules[0]='Network Management' 
    console.log("availabilty",this.availableModules)
  }

  onMouseLeave() {
    this.isFirstBlockVisible = false;
    this.isSecondBlockVisible = false;
    this.isThirdBlockVisible = false;
    this.isFourthBlockVisible = false;
    this.isFifthBlockVisible = false;
    this.isSixthBlockVisible = false; 
  }
  onClick(tab: number) { 
    this.displayText='Select ';
      if(tab == 1){
        this.isFirstBlockVisible = !this.isFirstBlockVisible;
        this.isSecondBlockVisible = false;
        this.isThirdBlockVisible = false;
        this.isFourthBlockVisible = false;
        this.isFifthBlockVisible = false;
        this.isSixthBlockVisible = false; 
      }
      else if(tab == 2){
        this.isFirstBlockVisible = false;
        this.isSecondBlockVisible = !this.isSecondBlockVisible;
        this.isThirdBlockVisible = false;
        this.isFourthBlockVisible = false;
        this.isFifthBlockVisible = false;
        this.isSixthBlockVisible = false; 
      }
      else if(tab == 3){
        this.isFirstBlockVisible = false;
        this.isSecondBlockVisible = false;        
        this.isThirdBlockVisible = !this.isThirdBlockVisible;
        this.isFourthBlockVisible = false;
        this.isFifthBlockVisible = false;
        this.isSixthBlockVisible = false; 
      }
      else if(tab == 4){
        this.isFirstBlockVisible = false;
        this.isSecondBlockVisible = false; 
        this.isThirdBlockVisible = false;
        this.isFourthBlockVisible = !this.isFourthBlockVisible;
        this.isFifthBlockVisible = false;
        this.isSixthBlockVisible = false; 
      }
      else if(tab == 5){
        this.isFirstBlockVisible = false;
        this.isSecondBlockVisible = false; 
        this.isThirdBlockVisible = false;
        this.isFourthBlockVisible = false;
        this.isFifthBlockVisible = !this.isFifthBlockVisible;
        this.isSixthBlockVisible = false; 
      }
      else if(tab == 6){
        this.isFirstBlockVisible = false;
        this.isSecondBlockVisible = false; 
        this.isThirdBlockVisible = false;
        this.isFourthBlockVisible = false;
        this.isFifthBlockVisible = false;
        this.isSixthBlockVisible = !this.isSixthBlockVisible; 
      }
  }
 
  getPermissions(){
    if(localStorage.getItem('rolePermissionAndSystemComponents') == ''){
      this.havePermissions = false
    }
    else{
      this.havePermissions = true
    }
  }

  alert(){
    this.toastr.warning("Not yet implemented");
  }

  logout(){
    this.authService.logout();
  }

  // getUserBillingId(){
  //   this.userService.getBillingUserId().subscribe(response =>{
  //     if(response.data != null && response.data != 0){
  //       this.billingUserId = true
  //     }
  //     else
  //       this.billingUserId = false
  //   }, _ => {
  //     this.toastr.error('An error occurred.');
  //   });
  // }
}
