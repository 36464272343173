export class WorkOrder{
  id: number = 0;
  taskId: string = ''
  name: string = '';
  description: string = '';
  images: string[] = []; 
  attachments: string[] = []; 
  agent: number = 0;
  agentUserName: string = ''
  assetType: number = 0;
  priority: number = 0
  dateCreated: Date = new Date
  dateCanceled: Date = new Date
  estimatedEndDate: Date = new Date
  buName: string = ''; 
  buId: number = 0;
  utName: string = ''; 
  utId: number = 0;
  categoryName: string = ''; 
  categoryId: number = 0;
  assetTypeName: string = ''; 
  assetTypeId: number = 0;
  agentImage: string = ''
  assignedTeamId: number = 0
  assignedUser: string = ''
  assignedUserId: number = 0
  statusId: number = 0
  statusName: string = ''
  priorityName: string = ''
  priorityId: number = 0
}