import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AddAgentPopupForFieldOperationComponent } from 'src/app/components/common-new-design/add-team-agent-multiselect/add-team-agent-multiselect';
import { transformToString } from 'src/app/helpers/dateUtils';
import { CaimsOperator } from 'src/app/models/caimsOperator.model';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { FieldModalData } from 'src/app/models/field-modal-data';
import { PopupEnum } from 'src/app/models/popupEnum';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';
import { Womteam } from 'src/app/models/womteam.model';
import { WorkOrder } from 'src/app/models/workOrder.model';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommonService } from 'src/app/services/common-service.service';
import { FieldAgentsService } from 'src/app/services/field-agents.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { UserService } from 'src/app/services/user.service';
import { WomteamService } from 'src/app/services/womteam.service';
import { WorkOrderManagement } from 'src/app/services/work-order-management.service';


@Component({
  selector: 'app-wom-create-task-page',
  templateUrl: './wom-create-task-page.component.html',
  styleUrls: ['./wom-create-task-page.component.scss']
})
export class WomCreateTaskPageComponent implements OnInit, OnChanges {

  @Output() changeView: EventEmitter<any> = new EventEmitter()

  @ViewChild(AddAgentPopupForFieldOperationComponent) addAgentsRef!: AddAgentPopupForFieldOperationComponent

  @Input() isPopupOpen: boolean = false;
  @Input() animationState = 'out';
  @Input() taskId = 0

  @Output() reloadTable = new EventEmitter<any>();

  selectedWorkOrder = new FieldModalData;
  public selectedAgent: CaimsOperator = new CaimsOperator()

  public popupEnum = PopupEnum

  public uploadFile!: File | null;
  public selectedFiles!: FileList
  public attachments: string[] = []
  message : string = '';
  readyFiles :any[] = [];

  //Create Work Order input fields
  workOrderId:string|number = '';
  summary: string = '';
  description: string = '';
  reporter: string = '';

  public userGuid: string = ''
  public agents: CaimsOperator[] = []
  public bussinesUnits: DictionaryItem[] = []
  public undertakings: DictionaryItem[] = []
  public categories: DictionaryItem[] = []
  public assetTypes: DictionaryItem[] = []
  public priorities: DictionaryItem[] = []
  public workOrder: WorkOrder = new WorkOrder()
  public teams: Womteam[] = []
  public estimatedEndDate: any = ''

  public selectedRegion: DictionaryItem = new DictionaryItem()
  public selectedArea: DictionaryItem = new DictionaryItem()
  public selectedCategory: DictionaryItem = new DictionaryItem()
  public selectedAssetType: DictionaryItem = new DictionaryItem()
  public selectedPriority: DictionaryItem = new DictionaryItem()
  public selectedTeam: Womteam = new Womteam()

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');

  constructor(
    public workOrderManagement: WorkOrderManagement,
    public toastr: ToastrService,
    public buService: BusinessUnitService,
    private womTeamService : WomteamService,
    private fieldAgentService : FieldAgentsService,
    private commonService: CommonService,
    private userService: UserService,
    private utService: UndertakingService,
    private workOrderService: WorkOrderManagement,
    private authService:AuthService,
    private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.userGuid = this.authService.getCurrentUser()?.userGuid;
    this.selectedPriority.value = -1
    this.getAllBUsForSelect()
    this.getAllCategoriesForSelect()
    this.getAllAssetTypes()
    this.getAllPriorities()
    this.setDefaultHeaders()
      
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.taskId != null && changes.taskId.currentValue != undefined){
      this.taskId = changes.taskId.currentValue
      if(this.taskId != 0){
        this.getWorkOrder()
      }
    }
  }

  setDefaultHeaders(){
    this.selectedRegion.name = "Choose region"
    this.selectedArea.name = "Choose area"
    this.selectedCategory.name = "Choose task category"
    this.selectedAssetType.name = "Choose asset type"
    this.selectedPriority.name = "Choose priority"
    this.selectedTeam.name = "Select team"
    this.selectedAgent.userName = "Select agent"

    let date = new Date()
    this.estimatedEndDate = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}
  }

  preview(files:any) {
    if (files.length === 0)
      return;

    for(let i = 0; i < files.length; i++) {
      const mimeType = files[i].type;
      if (mimeType.match(/image\/*/) == null) {
        this.message = "Only images are supported.";
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(files[i]); 
      reader.onload = (e) => { 
        this.readyFiles.push({id: i, imgURL: e.target?.result});
      }
    }
  }

  onFileChanged(event: any) {
    let files = event.target.files
    this.selectedFiles = files
    for(let i = 0; i< files.length; i++){
      if(files[i]){
        this.attachments.push(files[i].name)
        // const reader = new FileReader()
        // reader.onload = (e: any) =>{
        //   this.preview = e.target.result
        // }
  
        // reader.readAsDataURL(files[i]);
      }  
      
    }
  }

  handleRemove(event:any, id:number) {
    event.preventDefault();
    this.readyFiles = this.readyFiles.filter(file => file.id !== id);
  }

  handleNewOrder() {
    this.reloadTable.emit();
  }

  handleClickOutside(event: Event): void {
    const target = event.target as HTMLElement;
    const sliderElement = document.querySelector('.slider') as HTMLElement;
    
    if(!sliderElement.contains(target)){
      this.isPopupOpen = false;
    }
  }

  
  changeState(value: PopupEnum, data: FieldModalData){
    this.selectedWorkOrder = data;
    switch (value) {
      case PopupEnum.Open :
        this.isPopupOpen = true;       
        break;

      case PopupEnum.Close :
        this.isPopupOpen = false;
        break;

      default:
        break;
    }
  }

  getAllBUsForSelect(){

    this.buService.GetByUserGuid(this.userGuid).subscribe(response => {
      this.bussinesUnits = response.data;
    }, _ => {
      this.toastr.error('An error occured');
    });
  }

  getAllCategoriesForSelect(){
    this.commonService.getAllTaskCategories().subscribe(response => {
      this.categories = response.data;
    }, _ => {
      this.toastr.error('An error occured');
    });
  }

  public validate(){
    let isValid = true
    if(this.selectedCategory.value <= 0)
      isValid = false;
    if(this.selectedAssetType.value <= 0)
      isValid = false
    if(this.selectedRegion.value <= 0)
      isValid = false
    if(this.selectedArea.value <= 0)
      isValid = false
    if(this.selectedPriority.value < 0)
      isValid = false
    if(this.selectedTeam.id <= 0)
      isValid = false
    if(this.selectedAgent.id <= 0)
      isValid = false

    if(!isValid){
      this.toastr.warning('Some required fields are empty')
    }
    return isValid
  }

  public create(){
    if(!this.validate())
      return

    let formData: FormData = new FormData();
    if (this.selectedFiles) {
      for(let i = 0; i<this.selectedFiles.length; i++){
        formData.append('files', this.selectedFiles[i], this.selectedFiles[i].name);
      }
      
    } 

    formData.append('id', this.workOrder.id.toString()); 
    formData.append('name', this.workOrder.name); 
    formData.append('description', this.workOrder.description); 
    formData.append('categoryId', this.workOrder.categoryId.toString()); 
    formData.append('buId', this.workOrder.buId.toString()); 
    formData.append('utId', this.workOrder.utId.toString()); 
    formData.append('agentId', this.selectedAgent.id.toString());
    formData.append('teamId', this.selectedTeam.id.toString());
    formData.append('assetType', this.workOrder.assetTypeId.toString());
    formData.append('priority', this.workOrder.priorityId.toString()); 
    formData.append('estimatedEndDate', transformToString(this.estimatedEndDate)); 

    this.workOrderManagement.save(formData).subscribe(response => {
      if(response.status === 200 || response.status === 201) {
        this.isPopupOpen = false;
        this.toastr.success(response.message);
        this.handleNewOrder();
        this.changeView.emit(ViewsEnum.View)
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  selectCategory(item: DictionaryItem){
    this.selectedCategory = item
    this.workOrder.categoryId = item.value
    this.workOrder.categoryName = item.name
  }

  selectRegion(item: DictionaryItem){
    this.selectedRegion = item
    this.workOrder.buId = item.value
    this.workOrder.buName = item.name
    this.undertakings = []
    this.teams = []
    this.agents = []
    this.resetSelectedAgent()
    this.resetSelectedTeam()
    this.resetSelectedUt()
    this.getAllUtsByBu(this.selectedRegion.value)
  }

  resetSelectedUt(){
    this.selectedArea = new DictionaryItem()
    this.selectedArea.name = "Select area"
  }

  resetSelectedTeam(){
    this.selectedTeam = new Womteam()
    this.selectedTeam.name = "Select team"
  }

  resetSelectedAgent(){
    this.selectedAgent = new CaimsOperator()
    this.selectedAgent.userName = "Select agent"
  }

  selectArea(item: DictionaryItem){
    this.selectedArea = item
    this.workOrder.utId = item.value
    this.workOrder.utName = item.name

    this.teams = []
    this.agents = []
    this.resetSelectedAgent()
    this.resetSelectedTeam()

    this.getAllWomTeams()
  }

  selectAssetType(item: DictionaryItem){
    this.selectedAssetType = item
    this.workOrder.assetTypeId = item.value
  }

  selectPriorities(item: DictionaryItem){
    this.selectedPriority = item
    this.workOrder.priority = item.value
    this.workOrder.priorityId = item.value
  }

  selectTeam(item: any){
    this.selectedTeam = this.teams.find(x => x.id == item) ?? new Womteam()

    this.agents = []
    this.resetSelectedAgent()

    this.getAllAgentsByTeam()
  }

  selectAgent(item: any){
    this.selectedAgent = this.agents.find(x => x.id == item) ?? new CaimsOperator()
    this.workOrder.agent = this.selectedAgent.id
  }

  openAddAgents(){
    this.addAgentsRef.changeState(PopupEnum.Open)
  }

  getAllAgentsByTeam(){
    this.userService.getAllAgentsByTeamId(this.selectedTeam.id).subscribe(response =>{
      this.agents = response.data
      if(this.agents != null && this.workOrder.assignedUser != '' && this.workOrder.assignedUser != null){
        let agent = this.agents.find(x => x.email == this.workOrder.assignedUser)
        if(agent != null)
          this.selectedAgent = agent
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    });
  }


  back(){
    if(this.taskId == 0)
      this.changeView.emit(ViewsEnum.View)
    else
      this.changeView.emit(ViewsEnum.ShowDetails)
  }

  getAllUtsByBu(buId: number = 0){
    if(buId != 0 && buId != null)
      this.utService.getAllUTsByBuId(buId).subscribe(response =>{
        this.undertakings = response.data
      }, _=>{
        this.toastr.error("An error occurred")
      })
  }

  getAllAssetTypes(){
    this.commonService.getAllAssetTypes().subscribe(response =>{
      this.assetTypes = response.data
      var undefinedIndex = this.assetTypes.findIndex(e => e.name = "Undefined");
      this.assetTypes.splice(undefinedIndex, 1)
    }, _=>{
      this.toastr.error("An error occurred")
    })


  }

  getAllPriorities(){
    this.commonService.getAllPriorities().subscribe(response =>{
      this.priorities = response.data
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  getAllWomTeams(){
    let obj = {
      PageInfo: {
        Page: -1,
        PageSize: -1
      },
      FilterParams:{
        BuIds: [this.selectedRegion.value],
        UtIds: [this.selectedArea.value]
      }
    }
    this.womTeamService.getAll(obj).subscribe(response =>{
      this.teams = response.data.data
      if(this.workOrder.assignedTeamId != 0 && this.workOrder.assignedTeamId != null){
        let team = this.teams.find(x => x.id == this.workOrder.assignedTeamId)
        if(team != null)
          this.selectedTeam = team
        this.getAllAgentsByTeam()
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  getWorkOrder(){
    this.workOrderService.getOne(this.taskId).subscribe(response =>{
      this.setTaskFields(response.data)
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  setTaskFields(task: WorkOrder){
    this.workOrder = task
    this.selectedCategory.value = task.categoryId
    this.selectedCategory.name = task.categoryName
    this.selectedAssetType.value = task.assetTypeId
    this.selectedAssetType.name = task.assetTypeName
    this.selectedRegion.value = task.buId
    this.selectedRegion.name = task.buName
    this.selectedArea.value = task.utId
    this.selectedArea.name = task.utName ?? "Choose area"
    this.selectedPriority.value = task.priorityId
    this.selectedPriority.name = task.priorityName
    if(task.estimatedEndDate != null){
      let date = new Date(task.estimatedEndDate)
      this.estimatedEndDate = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}
    }

    this.getAllUtsByBu(this.selectedRegion.value)
    this.getAllWomTeams()
  }

  dateChange(date: any){
    this.estimatedEndDate = date
  }
}
