<main>
    <div class="dts-profile">
        <div class="container-wrapper">

            <div class="dts-header">
                <div class="title-container">
                    <div class="title-u">Distribution Transformer</div>
                    <div class="subtitle">Read distribution transformer reads</div>
                </div>
            </div>

            <div class="filters-container">
                <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                    <app-multi-filter
                        *ngIf="filterApplied"
                        [data]="allFilters"
                        (dataChange)="applyFilter($event)"
                        [(showFilter)]="showFilter"
                    ></app-multi-filter>

                    <app-range-datepicker
                        [fromDate]="dateFrom"
                        [toDate]="dateTo"
                        [name]="'Period'"
                        [maxDate]="maxDate"
                        (dateRangeSelected)="updateDate($event)">
                    </app-range-datepicker>

                    <app-search-autocomplete
                        #search
                        [width]="30"
                        [placeholder]="searchPlaceholder"
                        [searchText]="searchText"
                        [options]="options"
                        (searchChange)="searchChange($event)"
                        (searchAutocompleteChange)="getOptions($event)"
                    ></app-search-autocomplete>
                </div>
            </div>

            <table class="standard-table">
                <thead>
                    <th class="large-td"><span title="Dt Name">DT NAME</span></th>
                    <th><span title="Meter S/N">METER S/N</span></th>
                    <th><span title="{{utName}}">{{utName | uppercase}}</span></th>
                    <th><span title="Received Energy (MWh)">RECEIVED ENERGY(MWH)</span></th>
                    <th><span title="Accounted Energy (MWh)">ACCOUNTED ENERGY(MWH)</span></th>
                    <th><span title="% Losses">% LOSSES</span></th>
                </thead>
                <tbody class="table-body" >
                    <tr *ngFor="let item of dtReadsData">
                        <td class="large-td">
                            <span *ngIf="item.dtName !== '/'" title="{{item.dtName}}">{{item.dtName | hasValue}}</span>
                            <span *ngIf="item.dtName === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.msn !== '/'" title="{{item.msn}}">{{item.msn | hasValue}}
                                <img alt="Copy" class="icons" src="assets/images-new-design/copy.png"(click)="copyToClipboard(item.msn)">
                            </span>
                            <span *ngIf="item.msn === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.area !== '/'" title="{{item.area}}">{{item.area | hasValue}}</span>
                            <span *ngIf="item.area === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.energyReceived !== '/'" title="{{item.energyReceived}}">{{item.energyReceived | hasValue}}</span>
                            <span *ngIf="item.energyReceived === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.energyAccounted !== '/'" title="{{item.energyAccounted}}">{{item.energyAccounted | hasValue}}</span>
                            <span *ngIf="item.energyAccounted === '/'" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span *ngIf="item.lossesPercentage !== '/'" title="{{item.lossesPercentage}}">{{item.lossesPercentage | hasValue}}</span>
                            <span *ngIf="item.lossesPercentage === '/'" class="NaN">N/A</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="pagination-wrapper">
                <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [currentPage]="currentPage" [count]="count" (pageChange)="pageChange($event)">
                </app-pagination>
            </div>
        </div>
    </div>
</main>
