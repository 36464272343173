import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { HeaderService } from 'src/app/services/header.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { MultiFilterComponent } from '../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../common-new-design/search-autocomplete/search-autocomplete.component';
import { MenuItem, SubMenuItem } from '../consumption/model/model';
import { AegBuNewComponent } from './aeg-bu-new/aeg-bu-new.component';


@Component({
  selector: 'app-asset-energy-graph-new',
  templateUrl: './asset-energy-graph-new.component.html',
  styleUrls: ['./asset-energy-graph-new.component.scss']
})
export class AssetEnergyGraphNewComponent implements OnInit {

  public MenuItemEnum = MenuItem;
  public menu: MenuItem = MenuItem.Network

  public isGraph: boolean = true;

  public SubMenuItemEnum = SubMenuItem;
  public Submenu: SubMenuItem = SubMenuItem.Feeders33;

  //Multi Filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;

  public feeder33Name: string = "Feeder33"
  public feeder11Name: string = "Feeder11"
  public feedersName: string = "Feeders"

  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')
  public dtName: string = this.translationService.getByKeyFromCache('DT')  
  public feeders33: any = { name: this.feeder33Name, data: [], dropdown: false, maxSelected: 15 };
  public feeders11: any = { name: this.feeder11Name, data: [], dropdown: false, maxSelected: 15 };
  public feeders: any = { name: this.feedersName, data: [], dropdown: true };
  public dts: any = { name: this.dtName, data: [], dropdown: false, maxSelected: 15 }
  public businessUnits: any = { name: this.buName, data: [], dropdown: false, maxSelected: 15 };
  public undertakings: any = { name: this.utName, data: [], dropdown: false, maxSelected: 15 };

  public f33Ids: number[] = []
  public f11Ids: number[] = []
  public dtIds: number[] = []
  public buIds: number[] = []
  public utIds: number[] = []

  public f33Id: number = 0;
  public f11Id: number = 0;
  public dtId: number = 0;

  public feederSelected: any = {value: 0, name: '', checked: false}
  public feeder33Selected: any = {value: 0, name: '', checked: false}


  // Datepicker
  public dateFrom: any = ''
  public dateTo: any = ''
  public isMonthly: boolean = false;

  //For download
  @ViewChild('child') child!: AegBuNewComponent;

  // Search Autocomplete
  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public options = ['']
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public searchPlaceholder: string = "Search by Feeder33 name or number"

  constructor(
    private headerService: HeaderService,
    private dtService: DtService,
    private toastr: ToastrService,
    private businessUnitService: BusinessUnitService,
    private undertakingService: UndertakingService,
    private translationService: TranslationService,
    public feederService: Feeder11Service,
    private feeder33Service: Feeder33Service
    ) {}

  ngOnInit(): void {
    this.setMainDate();
    this.getAllFeeder33();
  }

  // Search Autocomplete
  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name, 
      msnIncluded: false
    }
    if (searchText.name.length > 2) {
      if (this.menu == this.MenuItemEnum.Network && this.Submenu == this.SubMenuItemEnum.Feeders33) {
        this.feeder33Service.getDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      } else if (this.menu == this.MenuItemEnum.Network && this.Submenu == this.SubMenuItemEnum.Feeders11) {
        this.feederService.getDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      } else if (this.menu == this.MenuItemEnum.Network && this.Submenu == this.SubMenuItemEnum.Dt) {
        this.dtService.getDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
      else if (this.Submenu == this.SubMenuItemEnum.Bu) {
        this.businessUnitService.getDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
      else if (this.Submenu == this.SubMenuItemEnum.Ut) {
        this.undertakingService.getDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
    } else {
      this.searchChild.resetOptions()
    }
  }
  
  // Search Autocomplete
  searchChanged(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.searchText = cloneDeep(this.searchText)
  }

  organizationChanged(value: number) {
    if (value == 1) {
      this.menu = this.MenuItemEnum.Network
      this.Submenu = this.SubMenuItemEnum.Feeders33
    }
    else {
      this.menu = MenuItem.Organisation
      this.Submenu = this.SubMenuItemEnum.Bu
      if(this.businessUnits.data.length == 0)
        this.getAllBUs()
    }
    this.resetFilters()
  }


  public switchSubmenu(newSubmenu: number) {
    if(this.menu == this.MenuItemEnum.Organisation)
    {
      this.checkSubmenuOrganisation(newSubmenu);
    }
    else{
      this.checkSubmenuNetwork(newSubmenu);
    }
    
    this.headerService.cleanGlobalSearch()
  }

  graphChanged(value: boolean) {
    this.isGraph = value
  }

  checkSubmenuOrganisation(newSubmenu:number){
    if (newSubmenu == 1) {
      this.Submenu = this.SubMenuItemEnum.Bu
      this.resetFilters()
      if(this.businessUnits.data.length == 0)
        this.getAllBUs()
    }
    if (newSubmenu == 2) {
      this.Submenu = this.SubMenuItemEnum.Ut
      this.resetFilters()
      if(this.undertakings.data.length == 0)
        this.getAllUts()
    }
  }

  checkSubmenuNetwork(newSubmenu:number){
    if (newSubmenu == 1) {
      this.Submenu = this.SubMenuItemEnum.Feeders33
      this.resetFilters()
    }
    if (newSubmenu == 2) {
      this.Submenu = this.SubMenuItemEnum.Feeders11
      this.resetFilters()
      if(this.feeders11.data.length == 0)
        this.getAllFeeder11()
    }
    if (newSubmenu == 3) {
      this.Submenu = this.SubMenuItemEnum.Dt
      this.resetFilters()
      if(this.feeders.data.length == 0)
        this.getAllFeeders()
    }
  }

  //multiFilter 
  dropdownSelected(data: any){
    if(!data)
      return
    if(data.data.name === this.feedersName)
      this.feederSelect(data.item)
  }

  public DateClickEvent(date: any) {
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
  }

  checkFilters() {
    if(this.menu == this.MenuItemEnum.Network){
     this.checkNetworkFilters();
    }
    else
    {
      this.checkOrganizationFilters();
    }
    if(this.allFilters.length != 0)
      this.filterApplied = true

    this.allFilters = [...this.allFilters]
  }

  checkOrganizationFilters(){
    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);
      if (buIndex !== -1)
        this.allFilters[buIndex].data = this.businessUnits.data;

      let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakings.name);
      if (utIndex !== -1)
        this.allFilters[utIndex].data = this.undertakings.data;

      if (this.Submenu == this.SubMenuItemEnum.Bu && buIndex === -1) {
        this.allFilters.push(this.businessUnits)
      }
      else if (this.Submenu == this.SubMenuItemEnum.Ut && utIndex === -1) {
        this.allFilters.push(this.undertakings)
      }
  }

  checkNetworkFilters(){
    let f33Index = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders33.name);

    if (f33Index !== -1)
      this.allFilters[f33Index].data = this.feeders33.data;

    let f11Index = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders11.name);
    if (f11Index !== -1)
      this.allFilters[f11Index].data = this.feeders11.data;

    let feedersIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders.name);
    if (feedersIndex !== -1)
      this.allFilters[feedersIndex].data = this.feeders.data;

    let dtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.dts.name);
    if (dtIndex !== -1)
      this.allFilters[dtIndex].data = this.dts.data;
  
    if (this.Submenu == this.SubMenuItemEnum.Feeders33 && f33Index === -1) {
      this.allFilters.push(this.feeders33)
      this.feeders33.dropdown = false
    }
    else if (this.Submenu == this.SubMenuItemEnum.Feeders11 && f11Index === -1) {
      this.feeders11.dropdown = false
      this.allFilters.push(this.feeders11)
    }
    else if (this.Submenu == this.SubMenuItemEnum.Dt && dtIndex === -1 && f11Index === - 1) {
      this.allFilters.push(this.feeders)
      this.allFilters.push(this.dts)
    }
  }

  setFilters(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);
      const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);

      if (item.name === this.feeders33.name) {
        this.f33Ids = filteredValues
      }
      else if (item.name === this.feeders11.name) {
        this.f11Ids = filteredValues
      }
      else if (item.name === this.dts.name) {
        this.dtIds = filteredValues
      }
      else if (item.name === this.businessUnits.name) {
        this.buIds = filteredValues
      }
      else if (item.name === this.undertakings.name) {
        this.utIds = filteredValues
      }
    }
  }

  resetFilters() {
    if (this.Submenu == this.SubMenuItemEnum.Feeders33)
      this.searchPlaceholder = "Search by Feeder 33 name or number";
    else if (this.Submenu == this.SubMenuItemEnum.Feeders11)
      this.searchPlaceholder = "Search by Feeder 11 name or number";
    else if (this.Submenu == this.SubMenuItemEnum.Dt)
      this.searchPlaceholder = "Search by Dt name or number";
    else if (this.Submenu == this.SubMenuItemEnum.Bu)
      this.searchPlaceholder = "Search by " + this.buName +  " name";
    else if (this.Submenu == this.SubMenuItemEnum.Ut)
      this.searchPlaceholder = "Search by " + this.utName +  " name";

    this.searchText = new SearchAutocomplete()
    if(this.searchChild){
      this.searchChild.searchText = new SearchAutocomplete()
      this.searchChild.resetOptions()
    }

    this.showFilter = false
    this.filterApplied = false
    this.feederSelected = {value: 0, name: '', checked: false}
    this.f33Ids = []
    this.f11Ids = []
    this.dtIds = []
    this.buIds = []
    this.utIds = []
    this.allFilters = []
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.setMainDate()
    this.checkFilters()
  }

  feederSelect(data: any) { 
    this.feederSelected = data
    this.dts = { name: this.dtName, data: [], dropdown: false}

    if (this.feederSelected.value == 0) {
      this.feederSelected = {value: 0, name: '', checked: false}
      this.dtIds = []
      this.checkFilters()
    }
    else
    {
      let obj = {
        feederId: this.feederSelected.value, 
        feederType: this.feederSelected.type
      }
      this.dtService.GetAllDtsConnectedToFeeder(obj).subscribe(response => {
        this.dts = { name: this.dtName, data: response.data, dropdown: false, maxSelected: 15 }
        this.checkFilters()
      }, error => {
        this.toastr.error('An error occurred.');
      })
    }
  }


  downloadReport(){
    this.child.download()
  }

  setMainDate(){
    let date = new Date()
    this.dateTo = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}
    date.setMonth(date.getMonth() - 1)
    this.dateFrom = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}

    if(this.dateTo["day"] != this.dateFrom["day"]){
      this.dateFrom['day'] = 1
    }
  }

  getAllBUs() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data, dropdown: false, maxSelected: 15 }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllUts() {
    this.undertakingService.getAllForSelect().subscribe(response => {
      this.undertakings = { name: this.utName, data: response.data,dropdown: false,maxSelected: 15 }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllFeeder11(){
    this.feederService.getAllForSelect().subscribe(response =>{
      if(response.status === 200){
        this.feeders11 = { name: this.feeder11Name, data: response.data, dropdown: false, maxSelected: 15  };
        this.checkFilters()
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getAllFeeders(){
    this.feederService.getAllFeedersAllFeedersList().subscribe(response =>{
      this.feeders = { name: this.feedersName, data: response, dropdown: true  };

      this.checkFilters()   
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getAllFeeder33(){
    this.feeder33Service.getAllForSelect().subscribe(response =>{
      if(response.status === 200){
        this.feeders33 = { name: this.feeder33Name, data: response.data, dropdown: false, maxSelected: 15 };
        this.checkFilters()
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

}
