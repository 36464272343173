import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';

@Component({
  selector: 'app-f11-outage-popup',
  templateUrl: './f11-outage-popup.component.html',
  styleUrls: ['/../../outage-feeder33/f33-outage-popup/f33-outage-popup.component.scss']
})
export class F11OutagePopupComponent{
  public isPopupOpen: boolean = false;
  public selectedF11: any = {};
  public dts: any = [];
  public data: any = null;
  public currentPage: number = 1;
  public count: number = 0;
  public pageSize: number = 8;
  public lastPageSize: number = 1;
  public dataFeeder: any = {};
  public feederTopology:string="feeder11";
  date: string = '';

  public tabMenu: number = 1;
  constructor(
    private dtService: DtService,
    private f11Service: Feeder11Service,
    private toastr: ToastrService
  ) { }

  dateFormat() {
    const date = new Date(this.selectedF11.outageStart);
    const all = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"];
    const month = all[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    this.date = `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds}`;
    }

  getF11Info() {
    this.dtService.countOfPrivateDTsConnectedDirectly(this.selectedF11.feederId,).subscribe(response => {
      this.dataFeeder = response.data;
      this.dataFeeder.info = this.selectedF11;
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }
  tabChanged(value: number) {
    this.tabMenu = value
  }
  close() {
    this.isPopupOpen = false;
  }

  public isPlanned(plan: string) {
    if (plan === 'Planned') {
      return true;
    }
    else return false;
  }
  private getDtsByF11() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        Feeder11Id: this.selectedF11.feederId
      }
    };
    this.dtService.getAllDtsByF11IdWithPagination(obj).subscribe(response => {
      this.dts = response.data.data;
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  private GetPopupInfo() {
    this.f11Service.getDataForTopologyPopup(this.selectedF11.name, this.feederTopology).subscribe(response => {
      if (response.status == 200) {
        this.data = response.data
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An error occurred")
    });
  }
  back() {
    this.tabChanged(2)
  }

  pageChangeAccounts(value: any) {
    this.currentPage = value;
    this.getDtsByF11();
  }

  changeState(data?: any, menu?: any) {
    if (data)
      this.selectedF11 = data;
    this.dateFormat()
    this.getDtsByF11()
    this.getF11Info()
    this.GetPopupInfo()
    if (menu === 1){
      this.isPopupOpen=true;
      this.tabMenu=1;
     }
    else
      this.isPopupOpen = false;

  }
}
