import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[click-stop-propagation]"
})
export class ClickStopPropagation {
  @Input() useStopPropagation: boolean = true;

  @HostListener("click", ["$event"])
  public onClick(event: any): void {
    if(this.useStopPropagation)
      event.stopPropagation();
  }

  @HostListener("mousedown", ["$event"])
  public onMousedown(event: any): void {
    if(this.useStopPropagation)
      event.stopPropagation();
  }
}
