import { ChangeDetectorRef, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { TranslationService } from 'src/app/services/translation.service';
import { F11CurrentActivePopupComponent } from './f11-current-active-popup/f11-current-active-popup.component';

@Component({
  selector: 'app-current-active-f11',
  templateUrl: './current-active-f11.component.html',
  styleUrl: './current-active-f11.component.scss'
})
export class CurrentActiveF11Component {

  @Input() categoryId: number = -1
  @Input() buIds: number[] = []
  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''
  public pageSize: number = 10
  public currentPage: number = 1
  public lastPageSize: number = 1;
  public avgAvailabilityOpened: boolean = false
  public avgAvailability: number = 0
  isMonthlyClicked: boolean = false;

  public data: any[] = []
  count: number = 0
  public selectedF11: any = [];

  public checkedList: number[] = [];
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()
  @ViewChild(F11CurrentActivePopupComponent) popupRef!: F11CurrentActivePopupComponent;
  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase();

  public masterSelected: boolean = false;
  constructor(
    private f11Service: Feeder11Service,
    private toastr: ToastrService,
    private translationService:TranslationService,
    private detectionChange: ChangeDetectorRef
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false

    if (
      changes.ssIds !== undefined ||
      changes.categoryId !== undefined ||
      changes.searchText !== undefined || changes.currentPage !== undefined
    ) {
      reloadTable = true;
    }
    if (this.searchText != null) {
      reloadTable = true
    }

    if (changes.menu !== undefined || changes.tabMenu !== undefined) {
      reloadTable = true;
      this.resetFilters();
    }

    if (reloadTable) {
      this.checkedList = [];
      this.pageChange(1)
    }

  }

  public avgSelected(value: number) {
    this.currentPage = 1
    this.avgAvailability = value
    this.GetData()
    this.avgAvailabilityOpened = false;
  }

  public avgAvailabilityClicked(open: number) {
    if (open == 0)
      this.avgAvailabilityOpened = false
    else {
      if (this.avgAvailabilityOpened) {
        this.avgAvailabilityOpened = false
      }
      else {
        this.avgAvailabilityOpened = true
      }

    }
  }
  resetFilters() {
    this.categoryId = 0;
    this.buIds = []

    this.detectionChange.detectChanges()
  }
  viewMore(f11: any) {
    this.openTab(f11)
  }
  openTab(selectedF11: any) {
    this.popupRef.changeState(selectedF11, 1);
  }
  closeTab() {
    this.popupRef.changeState(this.selectedF11, 2);
  }

  download() {
    let obj = {
      pageInfo: {
        page: 0,
        pageSize: this.pageSize
      },
      filterParams: {
        BuIds: this.buIds,
        OutageCategory: this.categoryId,
        Search: this.searchText.name,
        outageIds: this.checkedList,
        avgAvailability: this.avgAvailability,

      }
    };
    this.f11Service.DownloadCurrentActiveReport(obj).subscribe(response => {
      if (response.status === 200) {
        window.location.href = response.data;
      } else if (response.data === null) {
        this.toastr.info(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }
  public GetData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        BuIds: this.buIds,
        OutageCategory: this.categoryId,
        Search: this.searchText.name,
      }
    };

    this.GetCurrentActiveForF11(obj);
  }
  public pageChange(page: number) {
    this.currentPage = page;
    this.GetData()
  }  //For Checkboxes on table

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
      this.checkForDownload(this.data[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.data.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item:any) {
      return item.checked == true;
    })
  }

  private GetCurrentActiveForF11(obj: any) {
    this.f11Service.getCurrentActiveF11(obj).subscribe(response => {
      if (response.status == 200) {
        this.data = response.data.data
        this.isAlreadyChecked();
        if (obj.pageInfo.page == 1)
          this.count = response.data.count
        if (this.count == 0)
          this.count++;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An error occurred")
    });
  }
}
