export class Account{
    id: number = 0;
    accountNo: string = '';
    street: string = '';
    firstName: string = '';
    middleName: string = '';
    lastName: string = '';
    phone: string = '';
    phone2: string = '';
    email: string = '';
    buName: string = '';
    utName: string = '';
    tariffName: string = '';
    paymentType: string = '';
    consumption: string = '';
    lastSeen: string = '';
    longitude: string = '';
    latitude: string = '';
    msn: string = '';
    substation: string = '';
    amiName: string = '';
    ami: string = '';
    meterType: string = 'Undefined';
    meterStatus: string = 'Undefined';
    dtId: number = 0;
    amiId: number = 0;
    dtName: string = '';
    dtNumber: number = 0;
    accountConsumptionType: number = 0;
    accountType: number = 0;
    dcuId: number = 0;
    accountMeterType: number = 0;
    total: number = 0;
    feeder: string = '';
    customerId:string='';
    meterLastReadSync:string='';
    
    checked: boolean = false;
    accStatusId: number = 0; 
    accStatusName: string = '';
    statusId: number = 0; 
    status:string='';
    loggedUserName: string = '';
    accountNumberHistory: any;
    meterVoltage: number = 0;
    meterMaxDigit?: number;
    multiplierFactor?: number;
}

export enum Tabs {
    FirstTab = 1,
    SecondTab = 2,
    ThirdTab = 3,
    FourthTab = 4
}
