<main class="container-fluid">
  <div class="container-wrapper">
    <div class="network-management-title">
      <span>Asset Meter</span>
    </div>
    <div class="menu-tab-filter">

      <app-tab-default 
          [firstText]="firstTabPrimary" [secondText]="secondTabPrimary" [thirdText]="thirdTabPrimary"
          (tabChanged)="primaryTabChange($event)">
      </app-tab-default>
    </div>
    <div class="filters-container">
      <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

          <app-multi-filter  *ngIf="filterApplied"
              [data]="allFilters"
              (dataChange)="applyFilter($event)"
              [(showFilter)]="showFilter">
          </app-multi-filter>

        <app-search-autocomplete
            #search
            [placeholder]="'Search by ' + activeTabPrimaryString + ' name or number'"
            [width]="25"
            [backgroundColor]="'transparent'"
            [borderColor]="'#E8E8E8'"
            [(searchText)]="searchText"
            [options]="options"
            (searchChange)="searchChange($event)"
            (searchAutocompleteChange)="getOptions($event)">
        </app-search-autocomplete>
      </div>
    </div>
      <app-asset-f33-meters
          *ngIf="activeTabPrimary === ActiveTabPrimaryEnum.Feeder33"
          [tsIds]="tsIds" [amiIds]="amiIds" [search]="searchText"
          >
      </app-asset-f33-meters>
      <app-asset-f11-meters
          *ngIf="activeTabPrimary === ActiveTabPrimaryEnum.Feeder11"
          [buIds]="buIds" [amiIds]="amiIds" [utIds]="utIds" [search]="searchText"
      >
      </app-asset-f11-meters>
      <app-asset-dt-meters
        *ngIf="activeTabPrimary === ActiveTabPrimaryEnum.DT"
        [buIds]="buIds" [amiIds]="amiIds" [utIds]="utIds" [search]="searchText"
      ></app-asset-dt-meters>
  </div>
</main>
