import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class MeterModelService {

  private pathApi = this.config.setting['pathApi'] + "meterModel/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    } 

    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    } 

    save(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }

    getAll(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, obj);
    } 
    
    getAllForSelect(amiId: any): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSelect/${amiId}`);
    }
    
    getAllForSearch(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSearch`);
    } 

    uploadFile(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}uploadFile`, obj)
    }

    getTemplate(): Observable<any>{
      return this.http.get(`${this.pathApi}getTemplate`);
    }

    download(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}download`, obj);
    }
}
