import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-set-password-new',
  templateUrl: './set-password-new.component.html',
  styleUrls: ['./set-password-new.component.scss']
})
export class SetPasswordNewComponent {
  public eyeImage : any = "assets/images-new-design/eye-closed.svg";
  public inputType: any = "password";
  public eyeImage2 : any = "assets/images-new-design/eye-closed.svg";
  public inputType2: any = "password";

  public email: string= '';

  public newPasswordForm: boolean = true;
  public newPassword: string = '';
  public confirmNewPassword: string = '';

  constructor(private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService,
    ) {

    }
  isFormValid(): boolean {
      return this.newPassword !='' && this.confirmNewPassword   !== '';
  }
  savePassword() {
    console.log("newPassword", this.newPassword.length)

    if (this.newPassword?.length < 8) {
      this.toastr.error("Password must be at least 8 characters");
      return;
    }
    if (this.newPassword?.search(/[0-9]/) < 0) {
      this.toastr.error("Password must contain at least 1 number..");
      return;
    }
    if ( this.newPassword?.search(/[A-Z]/) <0) {
        this.toastr.error("Password must contain at least one Uppercase letter.");
        return;
      }
    if (this.newPassword?.search(/[$&+,:;=?@#|'<>.^*()%!-]/) < 0) {
      this.toastr.error("Password must contain at least one special character ($&+,:;=?@#|'<>.^*()%!-).");
      return;
    }
    if (this.newPassword != this.confirmNewPassword) {
      this.toastr.error("Passwords must match");
      return;
    }

    let obj = {
      Password: this.newPassword,
      Email: this.email
    }
     this.userService.setPasswordForNewUser(obj).subscribe(x => {
      if (x?.status == 200) {
        this.toastr.success(x.message);
        localStorage.removeItem('tempPassword');
        setTimeout(() => {
          this.router.navigate(['global-landing-page']);
        }, 1000);
      }
      else {
        this.toastr.error(x.message);
        console.log("ERROR");
        this.authService.logout();
        setTimeout(() => {
          this.router.navigate(['login']);
        }, 1000);
      }
    }, error => {
      this.toastr.error(error?.error?.title);
    });
  }

  changeEye(){
    if(this.eyeImage === "assets/images-new-design/eye-open.svg") {
      this.eyeImage = "assets/images-new-design/eye-closed.svg";
      this.inputType = "password";
    }
    else {
      this.eyeImage = "assets/images-new-design/eye-open.svg";
      this.inputType = "text"
    }
  }
  changeEye2(){
    if(this.eyeImage2 === "assets/images-new-design/eye-open.svg") {
      this.eyeImage2 = "assets/images-new-design/eye-closed.svg";
      this.inputType2 = "password";
    }
    else {
      this.eyeImage2 = "assets/images-new-design/eye-open.svg";
      this.inputType2 = "text"
    }
  }


}
