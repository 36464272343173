import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Role } from 'src/app/models/role.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AuthService } from 'src/app/services/auth.service';
import { RoleService } from 'src/app/services/role.service';
import { SearchAutocompleteComponent } from '../common-new-design/search-autocomplete/search-autocomplete.component';
import { CrudDeleteModalComponent } from '../crud-tabs/crud-delete-modal/crud-delete-modal.component';
import { RoleSliderComponent } from './role-slider/role-slider.component';

@Component({
  selector: 'app-role-management-new',
  templateUrl: './role-management-new.component.html',
  styleUrls: ['./role-management-new.component.scss']
})
export class RoleManagementNewComponent implements OnInit {
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  @ViewChild(RoleSliderComponent) popupComponentRef!: RoleSliderComponent;
  @ViewChild('closeModal') closeModal!: ElementRef;
  public selectedId: number = 0;

  public PermissionsEnum = PermissionsEnum;

  public roles: any = []
  public selectedRole: Role = new Role();
  public currentPage: number = 1
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1

  public searchByName: string = '';
  public searchPlaceholder: string = "Search"
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []
  @ViewChild('search') searchChild!: SearchAutocompleteComponent;

  constructor(
    private roleService: RoleService,
    private toastr: ToastrService,
    public authService: AuthService,
  ) { }

  getOptions(searchText: any) { 
    let obj = {
    searchText: searchText.name, 
      msnIncluded: false
    }
    if (searchText.name.length > 1) {
      this.roleService.getDataForAutocomplete(obj)
            .subscribe({ 
              next : (response) => this.options = response.data,
              error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
            })
    }else{
      this.searchChild.resetOptions()
    }
  }

  ngOnInit(): void {
    this.pageChange(1);
  }
  inputChanged(text: SearchAutocomplete){
    this.searchText = text 
    this.searchText = cloneDeep(this.searchText) 
    this.pageChange(1);
  }
  openTab(id:number, item?: any) { 
    if(id===0){
      this.popupComponentRef.changeState(1); 
    }else{
      this.popupComponentRef.changeState(1, item); 
    }
  }
  openTabDelete(id:number) {
    this.roleService.get(id).subscribe(response => {
     if(response.status === 200){
       this.selectedRole = response.data;
     }
     else
       this.toastr.error(response.message);
   }, error => {
     this.toastr.error('An error occurred.');
   });
   this.selectedId = id;
   this.crudDeleteRef.changeState();
 }
  deleteRole(){   
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_roles_delete))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    if(this.selectedRole.id === 0){
      this.toastr.error('You must select Role for delete.');
      return
    }

    this.roleService.delete(this.selectedRole.id).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.pageChange(1);
      }        
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });    
  }

  public reloadTable(page: any = null){
    if(page)
    this.currentPage = page;
      
    //this.resetSelectedRole();

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        Name: this.searchText.name
      }
    };
    
    this.roleService.getAllWithAuditLog(obj).subscribe(response => {
      this.roles = response.data.data;
      this.count = response.data.count;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  pageChange(value: any) {
    this.currentPage = value
    this.reloadTable()
  }

  selectRole(role: Role){
    this.selectedRole = role
  }


}
