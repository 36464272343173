import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Chart } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { TabDefaultComponent } from 'src/app/components/common-new-design/tab-default/tab-default.component';
import { FOFlaggingValues } from 'src/app/models/fo-flagging-values.model';
import { PredictiveAssetAnalyticsGraphData, PredictiveDtAssetAnalytics } from 'src/app/models/predictive-dt-analytics.model';
import { AssetPerformanceService } from 'src/app/services/asset-performance.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-pa-dt-slider-new',
  templateUrl: './pa-dt-slider-new.component.html',
  styleUrls: ['./pa-dt-slider-new.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('0ms ease-in-out')),
      transition('out => in', animate('0ms ease-in-out'))
    ]),
  ],
  host: {
    '(document:click)': 'handleClick($event)',
  }
})
export class PaDtSliderNewComponent {

  @Input() animationState = 'out'

  public xAxisLabels: Date[] = []
  public xAxisLabelsForGraph: any[] = []

  public xAxisVoltage: any[] = []
  public xAxisCurrent: any[] = []
  public xAxisPowerFactor: any[] = []
  public flagedDate: Date  = new Date()
  public graphDataList : PredictiveAssetAnalyticsGraphData[] = []
  public chart: any;
  public showGraph: boolean = false;
  public isGraphRendered: boolean = false
  public dateFrom: any
  public dateTo: any

  public selectedData: PredictiveDtAssetAnalytics = new PredictiveDtAssetAnalytics()
  public flaggs: FOFlaggingValues = new FOFlaggingValues()

  @ViewChild(TabDefaultComponent) tabComponent!: TabDefaultComponent
  activeTab: ActiveTab = ActiveTab.Overview

  constructor(
    public translationService: TranslationService,
    public assetPerformanceService : AssetPerformanceService,
    public toastr : ToastrService) { }


  flaggedDateFetched : boolean = false;
  private getNow() {
    this.assetPerformanceService.GetDtDateOfFlaggedMeter(this.selectedData.dtId).subscribe(response => {
      if (response.status == 200) {
        this.dateFrom = new NgbDate(Number(response.data.split("-")[0]), Number(response.data.split("-")[1]), Number(response.data.split("-")[2].split("T")[0]))
        this.dateTo = new NgbDate(this.dateFrom.year, this.dateFrom.month, this.dateFrom.day + 1)
        this.flaggedDateFetched = true;
      }
      else {
        this.toastr.error(response.message)
        this.flaggedDateFetched = false;
      }
    })
  }

  tabChanged(e: any) {
    if ((e == 1 && this.activeTab == ActiveTab.Overview) || (e == 2 && this.activeTab == ActiveTab.Graph)) {
      return
    }
    if (e == 1) {
      this.activeTab = ActiveTab.Overview
      this.hideGraph()
    } else {
      this.showGraph = true
      if(this.flaggedDateFetched){
        this.getDataForGraph()
      }
      this.activeTab = ActiveTab.Graph
    }
  }

  copyDtNo() {
    navigator.clipboard.writeText(this.selectedData.dtNo)
    this.toastr.success('Copied to clipboard')
  }

  transformDataForGraph() {
    let dates = this.graphDataList.map(obj => obj.ts)
    let num1 : any[] =[]
    let num2 : any[] =[]
    let num3 : any[] =[]
    if (this.graphDataList.length !=0 ) {
      this.xAxisLabels.forEach(element  => {
        if (dates.includes(element)) {
          num1.push(this.graphDataList.find(x=>x.ts === element)?.current)
          num2.push(this.graphDataList.find(x=>x.ts === element)?.voltage)
          num3.push(this.graphDataList.find(x=>x.ts === element)?.powerFactor)
        } else {
          num1.push(undefined)
          num2.push(undefined)
          num3.push(undefined)
        }
      })
    }
    this.xAxisLabelsForGraph = []
    this.xAxisLabels.forEach(element  => {
      this.xAxisLabelsForGraph.push(new Date(element).getHours() + ':00h')
    })
    return [num1,num2,num3]
  }

  public changeFromDate(date: any) {
    this.dateFrom = date
    this.getDataForGraph()
  }

  public changeToDate(date: any) {
    this.dateTo = date
    this.getDataForGraph()
  }

  convertDateToAnotherFormat(date: any): string {
    return `${date.year}-${date.month}-${date.day}`
  }

  hideGraph() {
    this.showGraph = false
    this.isGraphRendered = false
  }

  getDataForGraph() {
    let obj = {
      dtId: this.selectedData.dtId,
      dateFromSearch: this.convertDateToAnotherFormat(this.dateFrom),
      dateToSearch: this.convertDateToAnotherFormat(this.dateTo)
    }

    this.assetPerformanceService.getPredictiveDtAssetAnalyticsGraphData(obj).subscribe(response =>{
      if (response.status == 200) {
        this.xAxisLabels = response.data.times;
        this.graphDataList = response.data.data
        this.isGraphRendered ? this.updateChart() : this.createChart()
      }
      else {
        this.toastr.error(response.message)
      }
    })
    this.showGraph = true
  }

  changeState(number: Number, data?: any, flaggs?: FOFlaggingValues) {
    this.showGraph = false
    this.isGraphRendered = false

    if (flaggs)
      this.flaggs = flaggs
    if (data)
      this.selectedData = data
    if (number === 1) {
      this.toggleShowDiv("open")
      this.tabComponent.tabSelected = 1
    }
    if (number === 2)
    {
      this.activeTab = ActiveTab.Overview
      this.toggleShowDiv("close")
    }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = this.animationState = 'in'
      this.getNow()
    }
    else if (divName === 'close') {
      this.animationState = this.animationState = 'out'
    }
  }

  updateChart() {
    let ret = this.transformDataForGraph()
    this.chart.data.labels = this.xAxisLabelsForGraph
    this.chart.data.datasets[0].data = ret[0]
    this.chart.data.datasets[1].data = ret[1]
    this.chart.data.datasets[2].data = ret[2]
    this.chart.update()
  }

  createChart() {
    this.isGraphRendered = true

    let ret = this.transformDataForGraph()
    this.chart = new Chart("MyChart", {
      // this denotes tha type of chart
      type: 'line',

      // values on X-Axis
      data: {
        labels: this.xAxisLabelsForGraph,
	       datasets: [
          {
            label: "Current",
            data: ret[0],
            borderColor: '#188D52',
            pointBorderColor: '#188D52',
            pointBackgroundColor: '#188D52'
          },
          {
            label: "Voltage",
            data: ret[1],
            borderColor: '#42ADE6',
            pointBorderColor: '#42ADE6',
            pointBackgroundColor: '#42ADE6'
          },
          {
            label: "Power Factor",
            data: ret[2],
            borderColor: '#D24D3E',
            pointBorderColor: '#D24D3E',
            pointBackgroundColor: '#D24D3E'
          }
        ]
      },
      options: {
        aspectRatio: 2.5,
        spanGaps: true,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle'
           }
          }
        }
      }
    })
  }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider')
    const isDateFilterClicked = event.target.closest('.btn-light.ng-star-inserted')
    if (!isSliderClicked && !isDateFilterClicked) {
      this.changeState(2)
    }
  }

}

export enum ActiveTab {
  Overview = 1,
  Graph = 2
}
