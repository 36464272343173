<span class="dashboard-title">
  Meter Bypass Diagnostic Analytics
</span>

<div class="content-card">  

  <div class="title-wrapper">

    <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

      <app-multi-filter *ngIf="filterApplied"
        [data]="allFilters"
        [(showFilter)]="showFilter"       
        (dropdownChange)="businessUnitSelected($event)"
      ></app-multi-filter>

      <app-range-datepicker
        [fromDate]="dateFrom" 
        [toDate]="dateTo"
        [name]="'Period'"
        (dateRangeSelected)="dateChange($event)">
      </app-range-datepicker>

      <app-search-autocomplete
        #search
        [placeholder]="searchPlaceholder" 
        [width]="25"
        [backgroundColor]="'transparent'"
        [borderColor]="'#E8E8E8'"
        [(searchText)]="searchText"
        [options]="options"       
        (searchChange)="inputChanged($event)"
        (searchAutocompleteChange)="getOptions($event)"
      ></app-search-autocomplete>

    </div>
    <div class="total-customer-wrapper">
        <span class="title-span">Total Flagged Customers</span>
        <span class="total-number">{{totalCustomersCount | number}}</span>
    </div>
  </div>

  <div class="bypass-wrapper">
    <div class="bypass-labels">
      <div class="bypass-area">
        <span>AREAS</span>
      </div>
      <div class="grid-first">
        <span class="lowest-highest">LOWEST NUMBER OF CUSTOMERS</span>
      </div>
      <div class="grid-second">
        <span class="lowest-highest">HIGHEST NUMBER OF CUSTOMERS</span>
      </div>
    </div>
    <div class="chart-wrapper">
      <div class="chart-bar" #chartBar>
          <canvas #chart baseChart
          [datasets]="CustomersData"
          [labels]="CapitalLabels"
          [options]="barChartOptions">
          </canvas>
      </div>
      <div class="chart-numbers">
        <span *ngFor="let customer of areaCustomerCount">{{ customer.customersNo }}</span>
      </div>
    </div>
    <div class="pagination-wrapper">
      <app-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [count]="count"
      (pageChange)="pageChange($event)"
    ></app-pagination>
    </div>
  </div>
  
</div>
