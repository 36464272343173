import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-adora-datepicker',
  templateUrl: './adora-datepicker.component.html',
  styleUrls: ['./adora-datepicker.component.scss']
})
export class AdoraDatepickerComponent implements OnChanges {

  @Input() useClickStopPropagation: boolean = true;
  @Input() isMonthly: boolean = false;
  @Input() placementPosition: string = 'bottom-end';

  @Input() filterByDateClicked: boolean = false;
  @Output() filterByDateClickedChange = new EventEmitter();

  @Input() dateFrom!: any;
  @Output() dateFromChange = new EventEmitter();

  @Input() dateTo!: any;
  @Output() dateToChange = new EventEmitter();
  @Output() change = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.filterByDateClicked){
      this.filterByDateClicked = changes.filterByDateClicked.currentValue;
    }

    setTimeout(()=>{
      if (changes.isMonthly && !changes.isMonthly.firstChange) {
        this.setMainDate();
      }
    })

  }

  private setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()
    dte.setHours(2,0,0);

    if(this.isMonthly)
    {
      dte.setDate(1)
      dteFrom.setDate(1)
      dteFrom.setMonth(dteFrom.getMonth() - 1)
    }
    else{
      dteFrom.setDate(dteFrom.getDate() - 1)
    }

    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate()};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate()};
    this.dateFromChange.emit(this.dateFrom);
    this.dateToChange.emit(this.dateTo);
    this.change.emit();
  }

  isDisabled = (date:any, day:any) => {
    if (this.isMonthly) {
      return date.day !== 1;
    }
    return false;
  };

  public changeFromDate(date: NgbDate, d:any) {
    this.dateFromChange.emit(date);
    this.change.emit();
  }

  public changeToDate(date: NgbDate) {
    this.dateToChange.emit(date);
    this.change.emit();
  }

  public filterClick(value: boolean) {
    this.filterByDateClickedChange.emit(value);
  }

}
