import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AccountService } from 'src/app/services/account.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { MapService } from 'src/app/services/map.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { MultiFilterComponent } from '../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../common-new-design/search-autocomplete/search-autocomplete.component';
import { MenuItem } from '../consumption/model/model';
import { GepDtComponentNew } from './gep-dt-new/gep-dt.component';

@Component({
  selector: 'app-grid-energy-profile-new',
  templateUrl: './grid-energy-profile-new.component.html',
  styleUrls: ['./grid-energy-profile-new.component.scss']
})
export class GridEnergyProfileNewComponent implements OnInit {

  @ViewChild('child') child!: GepDtComponentNew
  @ViewChild('search') searchChild!: SearchAutocompleteComponent

  public Data: any = []
  public isMap: boolean = false;
  public MenuItemEnum = MenuItem;
  public menu: MenuItem = MenuItem.Network
  public tabMenu: number = 1;

  public totalEnergyConsumption: number = 0
  public totalEnergyConsumptionTwoMonthsAgo: number = 0

  // Datepicker
  public dateFrom: any = ''
  public dateTo: any = ''
  public monthFrom: any = ''
  public monthTo: any = ''

  public isDownload: boolean = false
  public maxData: boolean = true

  @Input() markers: any[] = []
  @Input() ssMarkers: any[] = []
  @Input() tsMarkers: any[] = []
  @Input() dtMarkers: any[] = []
  @Input() tempDtMarkers: any[] = []
  @Input() buMarkers: any[] = []
  @Input() utMarkers: any[] = []

  //multi filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;

  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')
  public dtName: string = this.translationService.getByKeyFromCache('DT')
  public ssName: string = "Substation"
  public f11Name: string = "Feeder 11"
  public tsName: string = "Transmission Station"
  public feederName: string = "Feeders"
  public customerName: string = "Customer"

  public buSelected: any = {value: 0, name: '', checked: false}
  public utSelected: any = {value: 0, name: '', checked: false}
  public dtSelected: any = {value: 0, name: '', checked: false}
  public substationSelected: any = {value: 0, name: '', checked: false}
  public feeder11Selected: any = {value: 0, name: '', checked: false}
  public tsSelected: any = {value: 0, name: '', checked: false}
  public undertakingsNew: any = { name: this.utName, data: [] };

  public businessUnitsNew: any = { name: this.buName, data: [] };
  public substations: any = { name: this.ssName, data: [] };
  public transmissionStations: any = { name: this.tsName, data: [] };
  public feeders11New: any = { name: this.f11Name, data: [] };
  // public allFeederNames: any = { name: this.feederName, data: [] };
  public ssIds: number[] = []
  public f11Ids: number[] = []
  // public feederNames: any = [];
  public tsIds: number[] = []
  public buIds: any = [];
  public utIds: any = [];
  public dtIds: any = [];

  public searchPlaceholder: string = "Search"

  public filterOrSearchChanged: boolean = false;

  //MAP Filter
  public businessUnitsMap: any = { name: this.buName, data: [], dropdown: true };
  public undertakingsMap: any = { name: this.utName, data: [], dropdown: true  };
  public dtsMap: any = { name: this.dtName, data: [], dropdown: true  };
  public customersMap: any = { name: this.customerName, data: [], dropdown: true  };
  public substationsMap: any = { name: this.ssName, data: [], dropdown: true   };
  public feeders11Map: any = { name: this.f11Name, data: [], dropdown: true   };

  public showOnlyTs:boolean = false;
  public showOnlySs:boolean = false;
  public showOnlyDt:boolean = false;
  public showOnlyBu:boolean = false;
  public showOnlyUt:boolean = false;
  public organizationData:boolean = false;
  public searchTextForMap:string='';

  // Search Autocomplete
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []
  public searchLength: number = 1
  // public optionsNew = [{id: 0, name: '', type: ''}]

  constructor(
    public toastr: ToastrService,
    private dashboardService: DashboardService,
    private businessUnitService: BusinessUnitService,
    private undertakingService: UndertakingService,
    private dtService: DtService,
    private translationService: TranslationService,
    public substationService: SubstationService,
    private mapService: MapService,
    public feederService: Feeder11Service,
    public accountService: AccountService,
    public feeder33Service: Feeder33Service,
    private tsService: TransmissionStationService,
    ) {}

  ngOnInit(): void {
    this.setMainDate()
    this.getAllBUs()
    this.getAllSSs()
    this.getAllUTs()
    // this.getAllFeeders()
    this.getAllTSs()
    this.getAllFeeder11()
    this.getTotalEnergyConsumption()
  }

  // Get Data for count box
  getTotalEnergyConsumption() {
    this.dashboardService.getTotalEnergyConsumption().subscribe(x => {
      this.totalEnergyConsumption = x.data.previousMonthF33
      this.totalEnergyConsumptionTwoMonthsAgo = x.data.twoMonthsAgo
    })
  }


  // set intital date for datepicker
  setMainDate(){
    let dteFrom = new Date()
    let dte = new Date()

    dteFrom.setHours(2, 0, 0);
    dte.setHours(2, 0, 0);

    dteFrom.setDate(1)
    dteFrom.setMonth(dteFrom.getMonth() - 1);
    dte.setDate(1)

    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate()};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate()};
  }

  //get data for filter multiselect or dropdown
  getAllTSs(){
    this.tsService.getAllForSelect().subscribe(response =>{
      if(response.status === 200){
        this.transmissionStations ={ name: this.tsName, data: response.data }
        this.checkFilters()
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getAllBUs() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnitsMap = { name: this.buName, data: response.data, dropdown: true }
      this.businessUnitsNew = { name: this.buName, data: response.data }
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllUTs() {
    this.undertakingService.getAllForSelect().subscribe(response => {
      this.undertakingsMap = { name: this.utName, data: response.data, dropdown: true }
      this.undertakingsNew = { name: this.utName, data: response.data }
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllSSs() {
    this.substationService.getAllForSelect().subscribe(response => {
      this.substationsMap = { name: this.ssName, data: response.data, dropdown: true }
      this.substations = { name: this.ssName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllFeeder11(){
    this.feederService.getAllForSelect().subscribe(response =>{
      if(response.status === 200){
        this.feeders11New = { name: this.f11Name, data: response.data };
        this.checkFilters()
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  // private getAllFeeders() {
  //   this.feederService.getAllFeedersAllFeedersList().subscribe(response => {
  //     this.allFeederNames = { name: this.feederName, data: response };
  //     this.checkFilters();
  //   }, error => {
  //     this.toastr.error('An error occurred.')
  //   })
  // }

  //On map reset filter by network/organisation
  resetFilter() {
    this.menu = MenuItem.None
    //this.getMapData(MenuItem.None)
  }

  download() {
    this.isDownload = !this.isDownload    //Just change value so ngOnChange can be triggered
    this.child.download()
  }

  // change tab Network or Organisation
  organizationChanged(value: number) {
    this.tabMenu = 1
    if (value == 1){
      this.organizationData = false;
      this.menu = MenuItem.Network
    }
    else if (value == 2){
      this.menu = MenuItem.Organisation
      this.organizationData = true;
    }

    if (value == 1 && this.isMap) {
      //this.getMapData(MenuItem.Network)
      this.handleMaps(this.menu, 2)
      this.substationSelected = {value: 0, name: '', checked: false}
      this.feeder11Selected = {value: 0, name: '', checked: false}
      this.dtSelected = {value: 0, name: '', checked: false}
    }
    else if (value == 2 && this.isMap) {
      //this.getMapData(MenuItem.Organisation)
      this.handleMaps(this.menu, 1)
      this.buSelected = {value: 0, name: '', checked: false}
      this.utSelected = {value: 0, name: '', checked: false}
      this.dtSelected = {value: 0, name: '', checked: false}
    }
    this.resetFilters()
  }

  handleMaps(menu:any, tab:any){
    this.showOnlyBu =false;
    this.showOnlyUt =false;
    this.showOnlySs =false;
    this.showOnlyTs =false;
    this.showOnlyDt = false;
    if(menu == 1){
      if(tab == 2){
        this.showOnlyTs=true;
      }
      else if(tab == 3){
        this.showOnlySs=true;
      }
      else if(tab == 4){
        this.showOnlyDt = true;
      }
    }
    else if(menu  == 2){
      if(tab == 1){
        this.showOnlyBu = true;
      }
      else if (tab == 2){
        this.showOnlyUt = true;
      }
    }

  }

  // Change tab Feeder33/Feeder11/Dt, Region/Area
  tabChanged(value: number) {
    this.tabMenu = value
    if (this.tabMenu == 1) {
      this.isMap = false
    }
    this.handleMaps(this.menu, this.tabMenu)
    this.resetFilters()
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom;
    this.dateTo = data.dateTo;
  }

  mapChanged(value: boolean) {
    this.isMap = value
    if (this.isMap) {
      this.handleMaps(this.menu, this.tabMenu)
    }
    else if (!this.isMap && this.menu == 0)
      this.menu = MenuItem.Network

    this.resetFilters()
  }

  // Multi Filter

  //Check Filter for multi filter component
  checkFilters() {
    if(this.menu == this.MenuItemEnum.Network)
    {
      if(this.tabMenu == 1)
      {
        this.filterApplied = false
        return
      }

      let ssIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.substations.name);
      if (ssIndex !== -1)
        this.allFilters[ssIndex].data = this.substations.data;

      // let feedersIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.allFeederNames.name);
      // if (feedersIndex !== -1){
      //   this.allFilters[feedersIndex].data = this.allFeederNames.data;
      // }

      let feeders11Index = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders11New.name);
      if (feeders11Index !== -1){
        this.allFilters[feeders11Index].data = this.feeders11New.data;
      }

      let tsIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.transmissionStations.name);
      if (tsIndex !== -1){
        this.allFilters[tsIndex].data = this.transmissionStations.data;
      }

      let dtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.dtsMap.name);
      if (dtIndex !== -1){
        this.allFilters[dtIndex].data = this.dtsMap.data;
      }

      if ((this.tabMenu == 4 || this.tabMenu == 3) && ssIndex === -1 && feeders11Index === -1) {
          if(this.isMap && this.tabMenu == 4){
            this.allFilters.push(this.dtsMap);
            this.filterApplied = true
          }
          else if(this.isMap && this.tabMenu ==3 ){
            this.allFilters.push(this.substations)
            this.filterApplied = true
          }
          else{
          this.allFilters.push(this.substations)
          this.allFilters.push(this.feeders11New)
          this.filterApplied = true}
        }
      else if (this.tabMenu == 2 && tsIndex === -1) {
          this.allFilters.push(this.transmissionStations)
          this.filterApplied = true
        }
    }
    else if (this.menu == this.MenuItemEnum.Organisation) {
      let feeders11Index = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders11New.name);
      if (feeders11Index !== -1){
        this.allFilters[feeders11Index].data = this.feeders11New.data;
      }

      let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnitsNew.name);
      if (buIndex !== -1){
        this.allFilters[buIndex].data = this.businessUnitsNew.data;
      }
      let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakingsNew.name);
      if (utIndex !== -1){
        this.allFilters[utIndex].data = this.undertakingsNew.data;
      }

      if(this.tabMenu == 1 && !this.isMap)
      {
        this.allFilters.push(this.businessUnitsNew)

        this.allFilters.push(this.feeders11New)
      }
      else if(this.tabMenu == 1 && this.isMap)
        {
          this.allFilters.push(this.businessUnitsNew)
  
        }
      else if(this.tabMenu == 2 && !this.isMap)
      {
        this.allFilters.push(this.businessUnitsNew)
        this.allFilters.push(this.undertakingsNew)

      }
      else if(this.tabMenu == 2 && this.isMap)
        {
          this.allFilters.push(this.undertakingsNew)
  
        }
    }
    this.filterApplied = true


    this.allFilters = [...this.allFilters]
  }

  // Apply Filter from Multi Filter
  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)
      if (item.name === this.substations.name ) {
        this.ssIds = filteredValues
      }
      // else if (item.name === this.allFeederNames.name) {
      //   const filteredNames = checkedData.map((item2: { name: any; }) => item2.name);
      //   this.allFeederNames = item
      //   this.feederNames = filteredNames
      // }
      else if (item.name === this.transmissionStations.name) {
        this.tsIds = filteredValues
      }
      else if (item.name === this.undertakingsNew.name) {
        this.utIds = filteredValues
      }
      else if (item.name === this.businessUnitsNew.name) {
        this.buIds = filteredValues
      }
      else if (item.name === this.feeders11New.name) {
        this.f11Ids = filteredValues
      }
      else if (item.name === this.dtsMap.name) {
        this.dtIds = filteredValues
      }
    }
  }

  resetFilters() {
    if(!this.isMap){
      if (this.tabMenu == 4 && this.menu == this.MenuItemEnum.Network)
      this.searchPlaceholder = "Search by DT name or number";
      else if (this.tabMenu == 3 && this.menu == this.MenuItemEnum.Network)
        this.searchPlaceholder = "Search by Feeder 11 name or number";
      else if (this.tabMenu == 2 && this.menu == this.MenuItemEnum.Network)
        this.searchPlaceholder = "Search by Feeder 33 name or number";
      else if (this.tabMenu == 1 && this.menu == this.MenuItemEnum.Network)
        this.searchPlaceholder = "Search by TS name";
      else if (this.tabMenu == 1 && this.menu == this.MenuItemEnum.Organisation)
        this.searchPlaceholder = "Search by Feeder 11 name or number";
      else if (this.tabMenu == 2 && this.menu == this.MenuItemEnum.Organisation)
        this.searchPlaceholder = "Search by Dt name or number";
    }
    else{
      if(this.menu == this.MenuItemEnum.Network){
        if(this.tabMenu==2){
          this.searchPlaceholder = "Search by Ts name";
        }
        if(this.tabMenu==3){
          this.searchPlaceholder = "Search by Ss name";
        }
        if(this.tabMenu==4){
          this.searchPlaceholder = "Search by Dt name";
        }
      }
      if(this.menu == this.MenuItemEnum.Organisation){
        if(this.tabMenu==1){
          this.searchPlaceholder = "Search by Bu name";
        }
        else if(this.tabMenu==2){
          this.searchPlaceholder = "Search by Ut name";
        }
      }
    }

    this.searchText = new SearchAutocomplete()
    if (this.searchChild)
    {
      this.searchChild.searchText = new SearchAutocomplete()
      this.searchChild.resetOptions()
    }
    this.setMainDate()

    //multiFilter
    this.tsIds = []
    this.ssIds = []
    this.f11Ids = []
    this.buIds = []
    this.utIds = []
    this.dtIds = []

    this.allFilters = []
    this.showFilter = false
    this.filterApplied = false
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.checkFilters()

  }

  // Search Autocomplete
  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    this.searchChild.searchLength = this.searchLength;
    if (searchText.name.length > 1) {
      if (this.menu == MenuItem.Network && this.tabMenu == 2 && !this.isMap) {
        this.feeder33Service.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An Error Occurred"),
          })
      } else if (this.menu == MenuItem.Network && this.tabMenu == 3 && !this.isMap
        || this.menu == MenuItem.Organisation && this.tabMenu == 1 && !this.isMap) {
        this.feederService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An Error Occurred"),
          })
      } else if (this.menu == MenuItem.Network && this.tabMenu == 4 && !this.isMap
        || this.menu == MenuItem.Organisation && this.tabMenu == 2 && !this.isMap) {
        this.dtService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An Error Occurred"),
          })
      }
      else if (this.menu == MenuItem.Network && this.isMap) {
        this.dtService.getDataForNetworkMapAutocomplete(obj)
          .subscribe(response => {
            this.options = response.data
          })
      }
      else if (this.menu == MenuItem.Organisation && this.isMap) {
        this.dtService.getDataForOrganisationMapAutocomplete(obj)
          .subscribe(response => {
            this.options = response.data
          })
      }
      else if (this.menu == MenuItem.Network && this.tabMenu == 1 && !this.isMap) {
        this.tsService.getDataForAutocomplete(obj)
          .subscribe(response => {
            this.options = response.data
          })
      }
    } else {
      this.searchChild.resetOptions()
    }
  }

  searchChange(text: SearchAutocomplete) {
    this.searchText = text
    this.searchText = cloneDeep(this.searchText)
    this.searchTextForMap= text.name
  }
}
