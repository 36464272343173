import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';
import { Unit } from '../models/unit.model';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  private pathApi = this.config.setting['pathApi'] + "unit/";

  constructor(private http: HttpClient, 
    private config: AppConfig
  ) {  }

  get(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}${id}`);
  } 
  
  getAll(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, obj)
  }

  uploadFile(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile`, obj)
  }

  getTemplate(): Observable<any>{
    return this.http.get(`${this.pathApi}getTemplate`);
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}download`, obj);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.pathApi}delete/${id}`);
  } 

  save(obj: Unit): Observable<any> {
    return this.http.post(`${this.pathApi}save`, obj);
  }
  
  getDataForAutocomplete(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
  }
       
  getAllForSelect(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}getAllForSelect/${id}`);
  }
}
