import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr'; 
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public email: any;
  public username: any;
  public pass: any;
  public code:any;

  public newPassword: any = '';
  public confirmNewPassword: any = ''
  public eyeImage : any = "assets/images-new-design/eye-closed.svg";
  public eyeImage2: any = "assets/images-new-design/eye-closed.svg";

  public inputType: any = "password";
  public inputType2: any = "password";

  constructor(private userService: UserService,
    private toastr: ToastrService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(JSON.stringify(params) + "paramsmsmsmadmasmdmsm");
        this.email = params['email'];
        this.code = params['code'];
      });
  }
  savePassword() {
    if (this.newPassword?.length < 8) {
      this.toastr.error("Password must be at least 8 characters");
      return;
    }
    if (this.newPassword?.search(/[$&+,:;=?@#|'<>.^*()%!-]/) < 0) {
      this.toastr.error("Password must contain at least one special character ($&+,:;=?@#|'<>.^*()%!-).");
      return;
    } 
    if ( this.newPassword.search(/[A-Z]/) <0) {
      this.toastr.error("Password must contain at least one Uppercase letter.");
      return;
    }  
        
    if (this.newPassword?.search(/[0-9]/) < 0) {
      this.toastr.error("Password must contain at least 1 number.");
      return;
    }
    if (this.newPassword != this.confirmNewPassword) {
      this.toastr.error("Passwords must match");
      return;
    } 

    let obj = {
      Password: this.newPassword,
      Email: this.email,
      Code:this.code
    }
  console.log(obj)
    this.userService.savePassword(obj).subscribe(x => {
      if (x?.status == 200) {
        this.toastr.success('Password changed successfully.')
        setTimeout(() => {
          window.location.href = '/login';
        }, 1000);
      }
      else 
        this.toastr.error(x.message);
        setTimeout(() => {
          window.location.href = '/login';
        }, 1000);
     
    }, error => {
      this.toastr.error(error?.error?.title);
    });
  }

  changeEye(){
    console.log("cchangeEye")

    if(this.eyeImage === "assets/images-new-design/eye-open.svg") {
      this.eyeImage = "assets/images-new-design/eye-closed.svg";
      this.inputType = "password";
    }
    else {
      this.eyeImage = "assets/images-new-design/eye-open.svg";
      this.inputType = "text"
    }
  }
  changeEye2() {
  
    if (this.eyeImage2 === "assets/images-new-design/eye-open.svg") {
      this.eyeImage2 = "assets/images-new-design/eye-closed.svg";
      this.inputType2 = "password"; 
    }
    else {
      this.eyeImage2 = "assets/images-new-design/eye-open.svg";
      this.inputType2 = "text"
 
    }
  }
}
