import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PopupEnum } from 'src/app/models/popupEnum';

@Component({
  selector: 'app-attachments-popup',
  templateUrl: './attachments-popup.component.html',
  styleUrls: ['./attachments-popup.component.scss']
})
export class AttachmentsPopupComponent implements OnInit {

  isDialogOpen: boolean = false;

  @Input() data: any = [];
  @Input() searchText: string = '';

  @Output() selectData: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<void> = new EventEmitter<void>();
  @Output() isDialogOpenChange = new EventEmitter<number>();
  allowedImageExtensions = ["jpg", "jpeg", "png", "gif","svg"];

  public defaultImage: string = 'assets/images-new-design/defaultImg.png'

  teamLeadsData: any[] = [];
  optionSelected : boolean = false
  public popupEnum = PopupEnum
  public dataNew: any[] = []

  fileExtension:string=''
  fileName:string=''

  public selectedItems: any[] = []

  ngOnInit(): void {
    this.reset()
    if (Array.isArray(this.data))
      this.teamLeadsData=[...this.data]
  }
  isImageExtension(extension: string): boolean {
    return this.allowedImageExtensions.includes(extension.toLowerCase());
  }
  ngOnChanges(changes: SimpleChanges) {
    this.data = changes.data.currentValue
    this.dataNew = this.data.attachments?.map((attachment: { url: string, fileName: string }) => ({
      url: attachment?.url,
      fileName: attachment?.fileName
    }));
    this.dataNew?.forEach(item => {
      this.fileExtension = item.fileName?.split('.').pop();
    });
  }
  getFileName(url: any): string {
    const parts = url.split("/");
    const fileNameWithExtension = parts[parts.length - 1];
    return fileNameWithExtension;
  }

  reset(){
    this.selectedItems = []
  }

  changeState(value: PopupEnum) {
    if(value == PopupEnum.Open){
      this.searchText = ''
      this.isDialogOpen = true;
    }

    if(value == PopupEnum.Close)
      this.isDialogOpen = false;
  }

  onImgError(event: any){
    // event.target.hidden = true
    event.target.src = this.defaultImage
  }

  download(url: string){
    window.location.href = url;
  }
}
