import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FieldOperationsDashboardService } from 'src/app/services/field-operations-dashboard.service';
import {FOWorkOrderCardData} from 'src/app/models/fo-work-order-card-data.model'
import { TranslationService } from 'src/app/services/translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-field-operations-new',
  templateUrl: './dashboard-field-operations-new.component.html',
  styleUrls: ['./dashboard-field-operations-new.component.scss']
})
export class DashboardFieldOperationsNewComponent implements OnInit {

  public activeTab:string = '33KV Feeders';
  public activeTabCurrent:string = '11KV Feeders';
  openWorkOrder: number = 0;
  closedWorkOrder: number = 0;
  cardData:FOWorkOrderCardData = new FOWorkOrderCardData() ;
  inventoryDataItemsLow: any;
  inventoryDataTopItems:any;
  inventoryDataItemsLowCount: any;


  public tableData: any[] = [];
  public gridData: any[] = [];
  public filterData: any[]=[];
  filterWidth:string="140px";
  filterTextColor: string = "#ffffff"
  fontSizeText: string = '16px';
  fontWeightText: string = '500';
  lineHeightText: string = '19px';

  numberDtsAssets: number = 0;
  numberF11Assets: number = 0;
  numberF33Assets: number = 0;


  //Pagination
  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;
  public buName: string = this.translationService.getByKeyFromCache('BU');

  constructor(private fieldOperationDashboardService: FieldOperationsDashboardService, public toastr: ToastrService, private translationService: TranslationService, private router:Router) { }

  ngOnInit(): void {
      this.loadData()
  }

  loadData()
  {
    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize,
        isMonthlySelected: true
      }
    }
    //predictive
    this.fieldOperationDashboardService.getAssetPerformanceAnalysisDT(obj).subscribe(response => {
      if(this.currentPage == 1)
        this.numberDtsAssets = response.data.count;
      this.openWorkOrder = this.numberDtsAssets + this.numberF11Assets + this.numberF33Assets;

    }, error => {
      this.numberDtsAssets = 0;
      this.toastr.error('An error occurred.');
    });
    this.fieldOperationDashboardService.getCardData(obj).subscribe(response => {
      this.cardData = response.data;

    }, error => {
      this.toastr.error('An error occurred.');
    });
    console.log(this.cardData)
    this.fieldOperationDashboardService.getInventoryData(obj).subscribe(response => {

      this.inventoryDataTopItems = response.data.topIssuedItems;
      this.inventoryDataItemsLow = response.data.itemsRunningLow;
      this.inventoryDataItemsLowCount = this.inventoryDataItemsLow.length
    }, error => {
      this.toastr.error('An error occurred.');
    });



    this.fieldOperationDashboardService.getAssetPerformanceAnalysisFeeders11(obj).subscribe(response => {
      this.numberF11Assets = response.data.count;
      this.openWorkOrder = this.numberDtsAssets + this.numberF11Assets + this.numberF33Assets;

      if(this.activeTab == '11KV Feeders')
        this.tableData = response.data.data.data;
      if(this.currentPage == 1){
        this.count = response.data.data.count;
        this.lastPageSize = Math.ceil(this.count/this.pageSize);
      }

    }, error => {
      this.numberF11Assets = 0;
      this.toastr.error('An error occurred.');
    });
    //current
    this.fieldOperationDashboardService.getAssetPerformanceAnalysisFeeders33(obj).subscribe(response => {
      if(this.activeTab == '33KV Feeders')
        this.tableData = response.data.data.data;
      this.numberF33Assets = response.data.count;
      if(this.currentPage == 1){
        this.count = response.data.data.count;
        this.lastPageSize = Math.ceil(this.count/this.pageSize);
      }
      this.openWorkOrder = this.numberDtsAssets + this.numberF11Assets + this.numberF33Assets;
    }, error => {
      this.count = 0;
      this.numberF33Assets = 0;
      this.tableData = [];
      this.toastr.error('An error occurred.');
    });
    this.reloadGrid()
  }

  reloadTable(page: any = null){
    if(page != null){
      this.currentPage = page;
      this.lastPageSize = 1;
    }
    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize,
        isMonthlySelected: true
      }
    }

    if(this.activeTab == '33KV Feeders')
    {
      this.fieldOperationDashboardService.getAssetPerformanceAnalysisFeeders33(obj).subscribe(response => {
        this.tableData = response.data.data;
        if(this.currentPage == 1){
          this.count = response.data.data.count;
          this.lastPageSize = Math.ceil(this.count/this.pageSize);
        }
        this.numberF33Assets = response.data.count;
        this.tableData = response.data.data.data;
        this.openWorkOrder = this.numberDtsAssets + this.numberF11Assets + this.numberF33Assets;
      }, error => {
        this.count = 0;
        this.tableData = [];
        this.numberF33Assets = 0;
        this.toastr.error('An error occurred.');
      });
    }
    if(this.activeTab == '11KV Feeders')
    {
      this.fieldOperationDashboardService.getAssetPerformanceAnalysisFeeders11(obj).subscribe(response => {
        this.tableData = response.data.data;
        if(this.currentPage == 1){
          this.count = response.data.data.count;
          this.lastPageSize = Math.ceil(this.count/this.pageSize);
        }
        this.numberF11Assets = response.data.count;
        this.tableData = response.data.data.data;
        this.openWorkOrder = this.numberDtsAssets + this.numberF11Assets + this.numberF33Assets;
      }, error => {
        this.count = 0;
        this.tableData = [];
        this.numberF11Assets = 0;
        this.toastr.error('An error occurred.');
      });
    }
    if(this.activeTab == 'DTS')
    {
        this.fieldOperationDashboardService.getAssetPerformanceAnalysisDT(obj).subscribe(response => {
          this.tableData = response.data.data;
          if(this.currentPage == 1){
            this.count = response.data.data.count;
            this.lastPageSize = Math.ceil(this.count/this.pageSize);
          }
          this.numberDtsAssets = response.data.count;
          this.tableData = response.data.data.data;
          this.openWorkOrder = this.numberDtsAssets + this.numberF11Assets + this.numberF33Assets;
        }, error => {
          this.count = 0;
          this.tableData = [];
          this.numberDtsAssets = 0;
          this.toastr.error('An error occurred.');
        });
    }
  }

  reportClicked(){
    this.toastr.warning('Not implemented yet!');
  }

  lastMonthOnClick() {
    this.toastr.warning('Not implemented yet!');
  }


  reloadGrid(){
    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize,
        isMonthlySelected: true
      }
    }
    if(this.activeTabCurrent == '33KV Feeders')
    {
      this.fieldOperationDashboardService.getF33CurrentFaultsAnalytics().subscribe(response => {
        this.gridData = response.data;
      }, error => {
        this.gridData = [];
        this.toastr.error('An error occurred.');
      });
    }
    if(this.activeTabCurrent == '11KV Feeders')
    {
      this.fieldOperationDashboardService.getF11CurrentFaultsAnalytics().subscribe(response => {
        this.gridData = response.data;
      }, error => {
        this.gridData = [];
        this.toastr.error('An error occurred.');
      });
    }
    if(this.activeTabCurrent == 'DTs')
    {
      this.fieldOperationDashboardService.getDtsCurrentFaultsAnalytics().subscribe(response => {
        this.gridData = response.data;
      }, error => {
        this.gridData = [];
        this.toastr.error('An error occurred.');
      });
    }

  }


  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  alert(){
    this.toastr.warning("Not yet implemented");
  }

  viewDetailsClick(details:any){
    if(details =='canceled'){
      this.router.navigate(['/field-operations/work-order-management-new'], { queryParams: { tab: '3' }});
    }
    /*else if(details =='overdue'){
      window.location.href= '';
    }*/
    else if(details =='completed'){
      this.router.navigate(['/field-operations/work-order-management-new'], { queryParams: { tab: '2' }})
    }
    else if(details =='ongoing'){
      this.router.navigate(['/field-operations/work-order-management-new'])
    }
  }

}
