import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { Undertaking } from '../models/undertaking.model';

@Injectable({
  providedIn: 'root'
})
export class UndertakingService {

  private pathApi = this.config.setting['pathApi'] + "undertaking/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    } 

    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    } 

    save(obj: Undertaking): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }

    getAll(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, obj);
    } 
    
    getAllForSelect(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSelect`);
    }
    
    getCount(): Observable<any> {
      return this.http.get(`${this.pathApi}getCount`);
    }
    
    uploadFile(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}uploadFile`, obj);
    }
    
    getTemplate(): Observable<any> {
      return this.http.get(`${this.pathApi}getTemplate`);
    }

    getInfoForMapDialog(id: number): Observable<any>{
      return this.http.get(`${this.pathApi}getInfoForMapDialog/${id}`);
    }

    getConsumptionReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}consumptionReport`, obj);
    }

    getAllMonthlyUTs(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}GetAllMonthlyUTs`,obj);
    }

    getAllDailyUTs(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}GetAllDailyUTs`,obj);
    }

    getAllByBuId(id: any): Observable<any>{
      return this.http.get(`${this.pathApi}GetAllByBuId/${id}`);
    }

    getAllByBuIds(id: any): Observable<any>{
      return this.http.post(`${this.pathApi}GetAllByBuIds`, id);
    }

    downloadMonthlyUTsReport(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}DownloadMonthlyUTsReport`,obj);
    }

    downloadDailyUTsReport(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}DownloadDailyUTsReport`,obj);
    }

    getAllUTsByBuId(id: number): Observable<any>{
      return this.http.get(`${this.pathApi}GetAllUTsByBuId/${id}`);
    }

    getUtsWorstEnergyLosses(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}GetUtsWorstEnergyLosses`, obj)
    }

    downloadWorstRecordedAssets(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadWorstAssetsUts`, obj);
    }

    getAllUtsDictionaryItem(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllUtsDictionaryItem`);
    }

    getUtReceivedData(obj: any): Observable<any[]> {
      return this.http.post<any[]>(`${this.pathApi}Received`, obj)
    }

    downloadAssetEnergy(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadUtAssetEnergy`, obj);
    }

    getDataForAutocomplete(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
    }

    download(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}download`, obj);
    }
}
