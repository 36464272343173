import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class ManualReadService {

  
  private pathApi = this.config.setting['pathApi'] + "manualRead/";


  constructor(private http: HttpClient,
    private config: AppConfig) { }
    
    uploadFile(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}uploadFile`, obj);
    }
    getTemplate(): Observable<any> {
      return this.http.get(`${this.pathApi}getTemplate`);
    }
    getTemplateDailyConsupmtion(): Observable<any> {
      return this.http.get(`${this.pathApi}getTemplateDailyConsupmtion`);
    }
    uploadFileDailyConsumption(obj: any): Observable<any> {
      console.log("dadadadaad")
      return this.http.post(`${this.pathApi}uploadFileDailyConsumption`, obj);
    }
}
