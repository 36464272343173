import { Component, OnInit, ViewChild } from '@angular/core';
import { Account } from 'src/app/models/account.model';
import { AccountService } from 'src/app/services/account.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountReadsNewComponent } from '../account-read-new/account-reads.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-account-list-popup',
  templateUrl: './account-list-popup.component.html',
  styleUrls: ['./account-list-popup.component.scss']
})
export class AccountListPopupComponent implements OnInit {
  tabNumber: number = 1;
  account = new Account;
  @ViewChild('accountReads') accountReads: AccountReadsNewComponent | any;

  constructor(
    private accountService:AccountService,
    public route: ActivatedRoute,
    private location:Location,
    private toastr:ToastrService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let id = params['accId']  
      this.getAcc(id);
    });
  }
  back() {
this.location.back();
  }
  getAcc(id:number){
    this.accountService.get(id).subscribe(response => {
      this.account = response.data; 
    console.log("u",this.account )

     }, error => {
      this.toastr.error('An error occurred.');
    })
  }
  tabChanged(value: number) {
    this.tabNumber = value
  }
}
