import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-multiple-amis-pop-up',
  templateUrl: './multiple-amis-pop-up.component.html',
  styleUrl: './multiple-amis-pop-up.component.scss',
  host: {
    '(document:click)': 'handleClick($event)',
  }
})
export class MultipleAmisPopUpComponent {

  public isPopupOpen = false

  @Input() amis: [] | any = []
  @Input() MSN = ''
  @Output() amiSelectedEvent = new EventEmitter<number>()

  constructor(private elementRef: ElementRef, private toastr: ToastrService) { }

  handleClick(event: any) {
    if (this.isPopupOpen) {
      if (this.elementRef.nativeElement.contains(event.target) || event.target === this.elementRef.nativeElement) {
        this.closePopup()
      }
    }
  }

  getMeter() {
    let selectedAmi = this.amis.find((x: { checked: boolean }) => x.checked == true)
    if (selectedAmi == null) {
      this.toastr.error('You have to choose Ami for the meter.')
      return
    }
    this.amiSelectedEvent.emit(selectedAmi.value)
    this.uncheckAllAmis()
    this.closePopup()
  }

  onChanged(ami: any) {
    if (ami.checked == false) {
      this.uncheckAllAmis()
    }
    ami.checked = !ami.checked
  }

  uncheckAllAmis() {
    this.amis.forEach((element: { checked: boolean }) => {
      element.checked = false
    })
  }

  closePopup() {
    this.isPopupOpen = false
  }

  changeState() {
    this.isPopupOpen = true
  }
  
}
