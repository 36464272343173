import { Component, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { InvalidTfeReadService } from 'src/app/services/invalid-tfe-read.service';
import { TypeOfConsumer } from 'src/app/services/meter.service';

@Component({
  selector: 'app-invalid-tfe-reads',
  templateUrl: './invalid-tfe-reads.component.html',
  styleUrl: './invalid-tfe-reads.component.scss'
})
export class InvalidTfeReadsComponent {

  public dateFrom: any = ''
  public dateTo: any = ''
  public monthFrom: any = ''
  public monthTo: any = ''

  @ViewChild('search') searchComponent!: SearchAutocompleteComponent
  public searchPlaceholder: string = "Search by MSN"
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []
  public searchLength: number = 1
  
  public data: any[] = []

  public consumerType = TypeOfConsumer.Feeder33

  public count: number = 0
  public pageSize: number = 10
  public currentPage: number = 1
  public lastPageSize: number = 1

  public tabMenu: number = 1

  constructor(public invalidTfeReadService: InvalidTfeReadService,
    public toastr: ToastrService) {}

  ngOnInit(): void {
    this.setInitialDate()
    this.getData()
  }

  generateObject(): any {
    return {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        ConsumerType: this.consumerType,
        SearchText: this.searchText.name
      }
    }
  }

  getData() {
    let obj = this.generateObject()

    this.invalidTfeReadService.getForPage(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data
        if (obj.pageInfo.page == 1) {
          this.count = response.data.count
        }
        if (this.data.length == 0) {
          this.count = 0
        }
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      } else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name,
      consumerType: this.consumerType
    }
    this.searchComponent.searchLength = this.searchLength;
    if (searchText.name.length > 1) {
      this.invalidTfeReadService.getDataForAutocomplete(obj)
        .subscribe(response => {
          this.options = response.data
        })
    }
  }

  pageChange(value: number) {
    this.currentPage = value
    this.getData()
  }

  tabChange(value: number) {
    this.tabMenu = value
    this.setConsumerType(value)
    this.resetFilters()

    this.pageChange(1)
  }

  resetFilters() {
    this.resetSearch()
    this.setInitialDate()
  }

  resetSearch() {
    this.searchText = new SearchAutocomplete()
    if (this.searchComponent)
    {
      this.searchComponent.searchText = new SearchAutocomplete()
      this.searchComponent.resetOptions()
    }
  }
  
  searchChange(text: SearchAutocomplete) {
    this.searchText = text
    this.searchText = cloneDeep(this.searchText)

    this.pageChange(1)
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo

    this.pageChange(1)
  }

  setConsumerType(value: number): void {
    switch(value) {
        case 3:
            this.consumerType = TypeOfConsumer.Dt
            break
        case 2:
            this.consumerType = TypeOfConsumer.Feeder11
            break
        case 1:
            this.consumerType = TypeOfConsumer.Feeder33
            break
        default:
            this.toastr.error('An error occurred.')
    }
  }

  private setInitialDate() {
    let to = new Date()
    let from = new Date()

    from.setHours(2, 0, 0)
    to.setHours(2, 0, 0)
    from.setDate(from.getDate() - 7)

    this.dateFrom = {year: from.getFullYear(), month: from.getMonth() + 1, day: from.getDate()}
    this.dateTo = {year: to.getFullYear(), month: to.getMonth() + 1, day: to.getDate()}
  }
}
