<div [hidden]="!showShadow" class="popup-overlay">
  <div class="area-popup" [ngClass]="{'shadow' : showShadow === true}" >
      <div class="tab-header">
          <div class="title-popup">
          <span *ngIf="selectedUT.id === 0">Create {{utName}}</span>
          <span *ngIf="selectedUT.id !== 0">Edit {{utName}}</span>
          </div>
          <div class="btn-close-position" (click)="changeState(2,selectedUT)">
              <div class="btn-close">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 12.9436L16.3282 17.2718C16.453 17.3966 16.606 17.4632 16.7872 17.4718C16.9684 17.4804 17.1299 17.4137 17.2718 17.2718C17.4137 17.1299 17.4846 16.9726 17.4846 16.8C17.4846 16.6274 17.4137 16.4701 17.2718 16.3282L12.9436 12L17.2718 7.6718C17.3966 7.54702 17.4632 7.39403 17.4718 7.21283C17.4804 7.03163 17.4137 6.87009 17.2718 6.7282C17.1299 6.58631 16.9726 6.51537 16.8 6.51537C16.6274 6.51537 16.4701 6.58631 16.3282 6.7282L12 11.0564L7.6718 6.7282C7.54702 6.60342 7.39403 6.53676 7.21283 6.5282C7.03163 6.51964 6.87009 6.58631 6.7282 6.7282C6.58631 6.87009 6.51537 7.02736 6.51537 7.2C6.51537 7.37264 6.58631 7.52991 6.7282 7.6718L11.0564 12L6.7282 16.3282C6.60342 16.453 6.53676 16.606 6.5282 16.7872C6.51964 16.9684 6.58631 17.1299 6.7282 17.2718C6.87009 17.4137 7.02736 17.4846 7.2 17.4846C7.37264 17.4846 7.52991 17.4137 7.6718 17.2718L12 12.9436ZM12.0045 24C10.3451 24 8.78496 23.6851 7.32413 23.0554C5.86331 22.4256 4.59259 21.5709 3.51197 20.4913C2.43137 19.4117 1.57589 18.1422 0.945533 16.6827C0.315178 15.2233 0 13.6638 0 12.0045C0 10.3451 0.314878 8.78496 0.944633 7.32413C1.57441 5.86331 2.4291 4.59259 3.5087 3.51197C4.5883 2.43137 5.85782 1.57589 7.31727 0.945534C8.77673 0.315179 10.3362 0 11.9955 0C13.6549 0 15.215 0.314877 16.6759 0.944633C18.1367 1.57441 19.4074 2.4291 20.488 3.5087C21.5686 4.5883 22.4241 5.85782 23.0545 7.31727C23.6848 8.77673 24 10.3362 24 11.9955C24 13.6549 23.6851 15.215 23.0554 16.6759C22.4256 18.1367 21.5709 19.4074 20.4913 20.488C19.4117 21.5686 18.1422 22.4241 16.6827 23.0545C15.2233 23.6848 13.6638 24 12.0045 24ZM12 22.6667C14.9778 22.6667 17.5 21.6333 19.5667 19.5667C21.6333 17.5 22.6667 14.9778 22.6667 12C22.6667 9.02222 21.6333 6.5 19.5667 4.43333C17.5 2.36667 14.9778 1.33333 12 1.33333C9.02222 1.33333 6.5 2.36667 4.43333 4.43333C2.36667 6.5 1.33333 9.02222 1.33333 12C1.33333 14.9778 2.36667 17.5 4.43333 19.5667C6.5 21.6333 9.02222 22.6667 12 22.6667Z" fill="#7F7D82" />
                  </svg>                 
              </div>
          </div>

      </div>
      <form (submit)="f.valid" class="form col-sm-12" #f="ngForm">
        <div class="form-inner">
          <div class="col-sm-12">
            <label class="labelPopup">Name *</label>
            <input class="form-control" name="name" [(ngModel)]="selectedUT.name" #name="ngModel" required autocomplete="off" placeholder="Name" pattern="^[a-zA-Z0-9]+(?:[a-zA-Z0-9 ]*[a-zA-Z0-9]+)*$" />
            <div *ngIf="f.submitted && name.invalid" class="form-control-error">Name is required</div>
          </div>

          <div class="col-sm-12">
            <label class="labelPopup">Latitude *</label>
            <input class="form-control" name="latitude" [(ngModel)]="selectedUT.latitude" #latitude="ngModel" required autocomplete="off" placeholder="Latitude" pattern="^[0-9]+(?:[0-9\.?]*[0-9]+)*$" />
            <div *ngIf="f.submitted && latitude.invalid" class="form-control-error">Latitude is required</div>
          </div>

          <div class="col-sm-12">
            <label class="labelPopup">Address</label>
            <input class="form-control" name="address" [(ngModel)]="selectedUT.address" #address="ngModel" autocomplete="off" placeholder="Address" />
          </div>

          <div class="col-sm-12">
            <label class="labelPopup">Longitude *</label>
            <input class="form-control" name="longitude" [(ngModel)]="selectedUT.longitude" #longitude="ngModel" required autocomplete="off" placeholder="Longitude" pattern="^[0-9]+(?:[0-9\.]*[0-9]+)*$" />
            <div *ngIf="f.submitted && longitude.invalid" class="form-control-error">Longitude is required</div>
          </div>

          <div class="col-sm-12">
            <label class="labelPopup">Connected {{buName}} *</label>
            <select class="form-control clickable actions-click" name="buId" [(ngModel)]="selectedUT.buId" #buId="ngModel" id="buSelect" required autocomplete="off">
              <option selected disabled [ngValue]='0'>Select {{buName}}</option>
              <option *ngFor="let bu of businessUnits" value="{{bu.value}}">{{bu.name}}</option>
            </select>
          </div>
        </div>
        <div class="user-btn">
          <button class="submit col-xs-6" (click)="save()">Save</button>
        </div>
      </form>
      
  </div>
</div>
