import { Component } from '@angular/core';
import { ChartDataset } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { GraphItem } from 'src/app/models/graph-item.model';
import { MeterMakesData } from 'src/app/models/meterMakesData.model';
import { MeterManagementDashboardService } from 'src/app/services/meter-management-dashboard.service';

@Component({
  selector: 'app-meter-dashboard',
  templateUrl: './meter-dashboard.component.html',
  styleUrl: './meter-dashboard.component.scss'
})
export class MeterDashboardComponent {

  //prepaid/postpaid cards
  public countOfPrepaidMeters: number = 0;
  public countOfPostpaidMeters: number = 0;
  public countOfPrepaidMetersOffline: number = 0;
  public countOfPrepaidMetersOnline: number = 0;
  public countOfPrepaidMetersVended : number = 0;
  public countOfPostpaidMetersOnline: number = 0;
  public countOfPostpaidMetersOffline: number = 0;

  //customer stats cards
  public prepaidCustomers: number = 0;
  public prepaidNMDCustomers: number = 0;
  public prepaidMDCustomers: number = 0;
  public postpaidCustomers: number = 0;
  public postpaidNMDCustomers: number = 0;
  public postpaidMDCustomers: number = 0;
  public unmeteredCustomers: number = 0;
  public unmeteredNMDCustomers: number = 0;
  public unmeteredMDCustomers: number = 0;

  public mainTab: number = 1;

  //meter triggers data
  public MeterManufacturers: ChartDataset[] = [
    {
      data: [],
      label: 'Total Meters: ',
      backgroundColor: 'rgb(192, 52, 56)',
      borderRadius: 8,
      barThickness: 124
    },
    {
      data: [],
      label: 'Comm (Last 30 Days): ',
      backgroundColor: 'rgb(192, 52, 56)',
      borderRadius: 45,
      barThickness: 12,
      hidden: true
    }
  ];
  public graphData: GraphItem[] = []
  public MeterManufacturerLabels: string[] = [];


  //dcu cards
  public online: number = 0;
  public offline: number = 0;

  public meterMakesResponse: any[] = [];
  public meterMakesData: MeterMakesData[] = [];

  constructor(
    private meterManagementService: MeterManagementDashboardService,
    public toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.reloadTable()
  }

  refreshData(){
    this.meterManagementService.refreshData().subscribe(response => {
      if(response.status == 200)
        {
        this.reloadTable()
        this.toastr.success(response.message);
        }
      else
        this.toastr.error(response.message);
    })
  }
  reloadTable(){
    this.GetAccMeters();
    this.getDcuCardsData();
    this.getCustomerStatsCardData();
    this.getMeterMakes();
  }

  GetAccMeters(){
    this.meterManagementService.GetAccMeters().subscribe(response =>{
      if(response.status === 200){
        this.countOfPostpaidMeters = response.data.countOfPostpaid
        this.countOfPrepaidMeters = response.data.countOfPrepaid
        this.countOfPrepaidMetersOffline = response.data.countOfPrepaidMetersOffline,
        this.countOfPrepaidMetersOnline = response.data.countOfPrepaidMetersOnline,
        this.countOfPrepaidMetersVended =response.data.countOfPrepaidMetersVended
        this.countOfPostpaidMetersOnline =response.data.countOfPostpaidMetersOnline,
        this.countOfPostpaidMetersOffline=response.data.countOfPostpaidMetersOffline

      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }


  getCustomerStatsCardData() {
    this.meterManagementService.getCustomerStatsCardData().subscribe(response => {
      if (response.status === 200) {
        this.prepaidCustomers = response.data.prepaidCustomers
        this.prepaidNMDCustomers = response.data.prepaidNMDCustomers
        this.prepaidMDCustomers = response.data.prepaidMDCustomers
        this.postpaidCustomers = response.data.postpaidCustomers
        this.postpaidNMDCustomers = response.data.postpaidNMDCustomers
        this.postpaidMDCustomers = response.data.postpaidMDCustomers
        this.unmeteredCustomers = response.data.unmeteredCustomers
        this.unmeteredNMDCustomers = response.data.unmeteredNMDCustomers
        this.unmeteredMDCustomers = response.data.unmeteredMDCustomers
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An Error Occurred")
    })
  }

  getMeterMakes(){
    this.meterManagementService.getAccMeterMakes().subscribe(response => {
      this.meterMakesResponse = response.data;
      for (const item of this.meterMakesResponse) {
        const data = new MeterMakesData();
        data.meterManufacturer = item.meterManufacturer;
        data.metersCount = item.metersCount;
        data.metersWithComm = item.metersWithComm;
        this.meterMakesData.push(data);
      }
      this.setValuesForMeterMakes(response)
    })
  }

  private setValuesForMeterMakes(response: any) {
    this.MeterManufacturers[0].data = []
    this.MeterManufacturers[1].data = []
    this.MeterManufacturerLabels = []
    let labels = ['']
    labels = []
    if (response.data != null && response.data.length > 0) {
      this.graphData = response.data.sort((ami1 : any, ami2 : any) => ami2.metersCount - ami1.metersCount);
      let indexOfUnbranded = this.graphData.findIndex( item => item.meterManufacturer === "OTHER");
      if(indexOfUnbranded !== -1){
        let unbranded = this.graphData[indexOfUnbranded];
        this.graphData.splice(indexOfUnbranded,1);
        this.graphData.push(unbranded);
      }
      this.graphData.forEach(element => {
        this.MeterManufacturers[0].data?.push(element.metersCount)
        this.MeterManufacturers[1].data?.push(element.metersWithComm)
        labels.push(element.meterManufacturer)
      })
    }
    this.MeterManufacturerLabels = labels
  }

  getDcuCardsData() {
    this.meterManagementService.getOnlineAndOfflineNumber().subscribe(response => {
      this.online = response.data.onlineNumber;
      this.offline = response.data.offlineNumber;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  mainTabChange(value: number){
    if(value == 1){
      this.mainTab = 1
      this.meterMakesData = []
      for (const item of this.meterMakesResponse) {
        const data = new MeterMakesData();
        data.meterManufacturer = item.meterManufacturer;
        data.metersCount = item.metersCount;
        data.metersWithComm = item.metersWithComm;
        this.meterMakesData.push(data);
      }
     }
    else if(value == 2){
      this.mainTab = 2
      this.meterMakesData = []
      for (const item of this.meterMakesResponse) {
        const data = new MeterMakesData();
        data.meterManufacturer = item.meterManufacturer;
        data.metersCount = item.prepaidMetersCount;
        data.metersWithComm = item.prepaidMetersWithComm;
        this.meterMakesData.push(data);
      }
    }
    else {
      this.mainTab = 3
      this.meterMakesData = []
      for (const item of this.meterMakesResponse) {
        const data = new MeterMakesData();
        data.meterManufacturer = item.meterManufacturer;
        data.metersCount = item.postpaidMetersCount;
        data.metersWithComm = item.postpaidMetersWithComm;
        this.meterMakesData.push(data);
      }
    }
  }
}
