import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MeterMakesData } from 'src/app/models/meterMakesData.model';

@Component({
  selector: 'field-count-box-new',
  templateUrl: './count-box-new.component.html',
  styleUrls: ['./count-box-new.component.scss']
})
export class FieldCountBoxNewComponent {
@Input() label: any;
  @Input() data: any;
  @Input() secondData: any = null;
  @Input() lastMonth: any = null;
  @Input() lastMonthBg: boolean = true;
  @Input() viewDetails: boolean = false
  @Input() meterManagementDashboard: boolean = false
  @Input() customerWidth: boolean=false
  @Input() isGreen: boolean = false;
  @Input() isRed: boolean= false;
  @Input() isYellow: boolean = false;
  @Input() isBlue: boolean = false;
  @Input() isBlack: boolean = false;
  @Input() isPurple: boolean = false;
  @Input() width: string = "330px";
  @Input() filter: boolean = false;
  @Input() outage: boolean = false;

  @Output() viewDetailsClickedChanged = new EventEmitter();
  @Output() filterChanged = new EventEmitter();
  
  selectedFilter: string = '33'
  @Input() height: string = "15rem";
  @Input() borderRadius: string="1.6rem";

  @Input() meterMakesData: MeterMakesData[] = [];
  
  hoveredSVG: string | null = null;
  hoverTimer: any;


  detailsClicked(){
    this.viewDetailsClickedChanged.emit()
  }

  filterClicked(){
    this.filterChanged.emit(this.selectedFilter);
  }

  @HostListener('mouseenter')
  onMouseEnter(svgName: string){
    this.hoverTimer = setTimeout(() => {
      this.hoveredSVG = svgName;
    }, 500);
  }

  @HostListener('mouseenter')
  onMouseLeave(){
    this.hoveredSVG = null;
    clearTimeout(this.hoverTimer);
  }

  isSVGHovered(svgName: string): boolean {
    return this.hoveredSVG === svgName;
  }

}
