import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Account } from 'src/app/models/account.model';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AccountService } from 'src/app/services/account.service';
import { AccountStatusService } from 'src/app/services/accountStatus.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { HeaderService } from 'src/app/services/header.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';
import { ImportDetailsPopupComponent } from '../../common-new-design/import-details-popup/import-details-popup.component';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { CrudDeleteModalComponent } from '../crud-delete-modal/crud-delete-modal.component';
import { BusinessUnitService } from './../../../services/business-unit.service';
import { UndertakingService } from './../../../services/undertaking.service';
import { AccountCreateComponent } from './account-create/account-create.component';
@Component({
  selector: 'app-account-tab',
  templateUrl: './account-tab.component.html',
  styleUrls: ['./account-tab.component.scss']
})
export class AccountTabComponent implements OnInit {
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  @ViewChild(AccountCreateComponent) popupComponentRef!: AccountCreateComponent;

  public PermissionsEnum = PermissionsEnum;

  public amiName: string = this.translationService.getByKeyFromCache('AMI');
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public MD: string = this.translationService.getByKeyFromCache('MD');
  public NMD: string = this.translationService.getByKeyFromCache('NMD');
  public currentTabCollorBlue: number = 1;
  public tempDtName: string = '';
  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;

  public fileChosen: any = null;
  public file: any = null;
  public isEdit: boolean = false;
  public ngUnsubscribe = new Subject<void>();
  // public searchText: string = "";

  public accounts: Account[] = [];
  public selectedAccount: Account = new Account();

  public dts: DictionaryItem[] = [];
  public data: DictionaryItem[] = [];
  public data1: string[] = [];

  // public businessUnits: DictionaryItem[] = [];
  // public undertakings: DictionaryItem[] = [];
  public businessUnit = 0;
  public undertaking = 0;
  public selectedId: number = 0;

  public accStatuses: DictionaryItem[] = []
  public undefinedId: number | undefined = 0
  public activeId: number | undefined = 0
  public inactiveId: number | undefined = 0
  public finalisedId: number | undefined = 0
  public suspendedId: number | undefined = 0
  public pendingId: number | undefined = 0
  public disconnectedId: number | undefined = 0

  public showTab: number = 1;
  public currentTab: number = 1;

  // public buSelected: DictionaryItem = new DictionaryItem(0, this.buName)
  // public utSelected: DictionaryItem = new DictionaryItem(0, this.utName)
  filterBuClicked: boolean = false;
  filterUtClicked: boolean = false;

  public amiValid: boolean = true;
  public tariffValid: boolean = true;
  public additionalInfoValid: boolean = false;
  public importPopupOpen: boolean = false;

  public dtValid: boolean = true;
  public longValid: boolean = true;
  public latValid: boolean = true;
  public msnValid: boolean = true;
  public accountNoValid: boolean = true;
  public accountMetered!: boolean;
  public emailValid: boolean = true;
  public phoneNumberValid: boolean = true;
  public meterTypeValid: boolean = true;
  public enteredFromEdit: boolean = false;
  public lastPageSize: number = 1;

  public masterSelected: boolean = false;
  public checkedList: number[] = [];

  @ViewChild(ImportDetailsPopupComponent) importRef!: ImportDetailsPopupComponent;

  @ViewChild("f1") f1: any;
  @ViewChild("f2") f2: any;
  @ViewChild("f3") f3: any;
  @ViewChild("f4") f4: any;

  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  public allFilters: any = []
  public showFilter: any = false;
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  public businessUnits: any = { name: this.buName, data: [], dropdown: true };
  public undertakings: any = { name: this.utName, data: [], dropdown: true };
  public buSelected = {value: 0, name: '', checked: false}
  public utSelected = {value: 0, name: '', checked: false}
  public buIds: number[] = []
  public utIds: number[] = []

  public searchText:SearchAutocomplete = new SearchAutocomplete()
  options = ['']

  public paginationFlag: boolean = false;

  hoverTimer: any;
  popupDataMap: Map<number, boolean> = new Map<number, boolean>();

  constructor(private accountService: AccountService,
    private accStatusService: AccountStatusService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private translationService: TranslationService,
    private tariffService: TariffService,
    public authService: AuthService,
    private buService: BusinessUnitService,
    private utService: UndertakingService,
    private headerService: HeaderService,
    private location: Location,
    private cdref: ChangeDetectorRef,
    private elementRef: ElementRef) { }

  ngAfterContentChecked() {
    this.cdref.detectChanges()
  }

  ngOnInit(): void {
    this.paginationFlag = true;
    this.reloadTable();
    this.getAllBUsForSelect();
    this.subscribeToSearch();
  }

  resetPage() {
    this.currentPage = 1
  }
  inputChanged(searchText: SearchAutocomplete){
    this.currentPage = 1
    this.searchText = searchText
    this.paginationFlag = true;
    this.reloadTable()
  }
  businessUnitSelected(item: any) {
    if (item.value === 0)
      this.buSelected.value = 0
    if (item.value !== 0) {
      this.buSelected.value = item.value;
      this.utService.getAllByBuId(this.buSelected.value).subscribe(response => {
        this.undertakings = { name: this.utName, data: response.data, dropdown: true };
        this.paginationFlag = true;
        this.pageChange(1);
        this.checkFilters()
      }, error => {
        this.toastr.error('An error occurred.');
      })
    }
    if (this.buSelected.value == 0) {
      this.utSelected.value = 0;
      this.undertakings.data.splice(0);
      this.paginationFlag = true;
      this.pageChange(1);
      this.checkFilters()
    }
  }
  back() {
    this.location.back();
  }

  getAllBUsForSelect() {
    this.buService.getAllForSelect().subscribe(response => {
      if(response.status === 200){
        this.businessUnits = { name: this.buName, data: response.data, dropdown: true }
        this.checkFilters()
      }
      else{
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occured');
    });
  }

  download() {
    let error = this.validateDownload()

    if (error != '') {
      this.toastr.error(error)
      return
    }

    let obj = this.generateObject();
    obj.pageInfo.page = -1;
    obj.pageInfo.pageSize = 5000000;
    obj.isForDownload = 1;
    this.accountService.downloadAccountsCrudPage(obj).subscribe(response => {
      if (response.status == 201) {
        this.toastr.success(response.message)
      }
      if (response.status == 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.")
        }
        else {
          window.location.href = response.data
        }
      }
    }, _ => {
      this.toastr.error('An error occured')
    })
  }

  validateDownload(): string {
    let error: string = ''

    if (this.buSelected && this.buSelected.value === 0) {
      error = 'Please select region for download.'
    } else if (this.accounts.length == 0) {
      error = 'There are no accounts for download.'
    }

    return error;
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  getPaginationData(){
    let obj = this.generateObject();

    this.accountService.getPaginationData(obj).subscribe(response => {
      console.log(response)
      this.count = response.data;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;

    this.resetSelectedAccount();
    this.getAllAccounts();
  }
  generateObject() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText ? this.searchText.name : this.searchText,
        BusinessUnit: this.buSelected.value,
        Undertaking: this.utSelected.value
      },
      isForDownload: 0
    };
    return obj
  }

  openTab(id?:number) {
     if(id===0){
      this.popupComponentRef.changeState(1, 0);
    }else{
    this.popupComponentRef.changeState(1, this.selectedAccount);
  }
}

  getAllAccounts() {
    let obj = this.generateObject();

    console.log(obj)

    this.accountService.getAll(obj).subscribe(response => {
      this.accounts = response.data;
      console.log(this.accounts)
      if(this.paginationFlag){
        this.getPaginationData();
        this.popupComponentRef.getAllAmisForSelect();
        this.popupComponentRef.getAllTariffsForSelect();
        this.popupComponentRef.getAllDCUsForSelect();
        this.popupComponentRef.getAllAccStatuses();
      }
      this.isAlreadyChecked();
      this.paginationFlag = false;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  onFileChosen(event: any) {
    this.file = event.target.files[0];
  }

  isFileChosen() {
    if (this.fileChosen)
      return true;

    return false;
  }
  changeImportPopupState() {
    this.importPopupOpen = !this.importPopupOpen;
  }
  openUploadTab() {
     this.importRef.changeState();
  }
  uploadFile() {
    this.file = this.importRef.fileChosen;

    if (!this.isFileChosen) {
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());
    this.accountService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      } else {
        this.toastr.error(response.message);
      }
        this.reloadTable();
        this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  selectAccount(id: number) {
    this.resetSelectedAccount();
    this.accountService.get(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedAccount = response.data;
        this.getDtObject(this.selectedAccount.dtName);
        this.openTab();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedAccount() {
    this.showTab = 1;
    this.currentTab = 1;
    this.currentTabCollorBlue = 1;

    this.amiValid = true;
    this.tariffValid = true;
    this.dtValid = true;
    this.longValid = true;
    this.latValid = true;
    this.accountNoValid = true;
    this.emailValid = true;
    this.phoneNumberValid = true;

    this.tempDtName = '';
    this.selectedAccount = new Account();
    this.selectedAccount.tariffName = "";
    this.isEdit = false;
  }

  openTabDelete(id:number) {
     this.accountService.get(id).subscribe(response => {
      if(response.status === 200){
        this.selectedAccount = response.data;
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.selectedId = id;
    this.crudDeleteRef.changeState();
  }

  onDeleteConfirmed() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_delete)) {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.accountService.delete(this.selectedId).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.reloadTable();
        this.resetSelectedAccount();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }


  stringify(f1: any) {
    console.log(f1)
  }

  getDtObject(dtName: any) {
    console.log("DT", dtName)

    this.tempDtName = '';
    const temp = this.dts.filter((x: any) => x.name == dtName);

    if (temp.length > 0) {
      this.tempDtName = temp[0].name;
    }
  }

  getTemplate() {
    this.accountService.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch() {
    this.headerService.globalSearch$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchValue => {
        if (searchValue.clicked && searchValue.componentName === ComponentNamesNew.AccountComponent) {
          // this.searchText = searchValue.text;
          this.currentPage = 1;
          this.checkedList = [];
          this.reloadTable();
          return;
        }
        // this.searchText = '';
      });
  }

  accMeterTypeChanged() {
    this.selectedAccount.msn = "";
    this.selectedAccount.meterType = '';
    this.selectedAccount.amiId = 0;
    this.f3.submitted = false;
  }

  changeConsumptionType() {
    if (this.selectedAccount.accountConsumptionType == 2) {
      this.accountMetered = true;
      this.selectedAccount.accountMeterType = 0;
    }
    if (this.selectedAccount.accountConsumptionType == 3) {
      this.accountMetered = false;
      this.selectedAccount.accountMeterType = 0;
    }
  }

  checkFilters() {
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnits.name);

    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);

    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;

    if (!buData) {
      this.allFilters.push(this.businessUnits)
      this.allFilters.push(this.undertakings)

      this.filterApplied = true
    }
    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakings.name);
    if (utIndex !== -1)
      this.allFilters[utIndex].data = this.undertakings.data;

    this.allFilters = [...this.allFilters]
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      if (item.name === this.businessUnits.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.businessUnits = item
        this.buIds = filteredValues
        console.log(this.buIds)
      }
      if (item.name === this.undertakings.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.undertakings = item
        this.utIds = filteredValues
      }
      this.reloadTable(1);
    }
  }

  dropdownSelected(data: any){
    if(!data)
      return
    if(data.data.name === this.buName)
      this.businessUnitSelected(data.item)
    if (data.data.name === this.utName)
      this.utSelected.value = data.item.value
    this.checkFilters()
    this.checkedList = [];
  }

  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    if(searchText.name.length > 2){
      this.accountService.getDataForAutocomplete(obj)
      .subscribe({
        next : (response) => this.options = response.data,
        error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
      })
    } else
      this.searchComponentRef.resetOptions();
  }

  checkUncheckAll() {
    for (let i = 0; i < this.accounts.length; i++) {
      this.accounts[i].checked = this.masterSelected;
      this.checkForDownload(this.accounts[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.accounts.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.accounts.every(function(item:any) {
      return item.checked == true;
    })
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent, itemId: number){
    this.hoverTimer = setTimeout(() => {
      this.popupDataMap.set(itemId, true)
    }, 500);
  }

  @HostListener('mouseenter', ['$event'])
  onMouseLeave(event: MouseEvent, itemId: number){
    clearTimeout(this.hoverTimer);
    this.popupDataMap.delete(itemId)
  }
}
