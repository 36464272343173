export class Womteam {
    id: number = 0;
    buId: number = 0;
    utId: number = 0;

    name: string = '';
    key: string = '';
    value: string = '';
    teamLeadName: string = '';
    teamLeadId: number = 0;
    teamId: string = ''
    profileImage: string = ''
    agents: any[] = [];
    agentsIds: number[] = [];

    buName: string = '';
    utName: string = '';
    photoUrl: string = '';
    image: string = '';
    ids: any[] = [];
    isSelected: boolean = true
    userName: string = '';
}