import { Component, OnInit, ViewChild } from '@angular/core';
import { TeamDetailsComponent } from '../teams/team-details/team-details.component';
import { CreateAgentComponent } from '../agents/create-agent/create-agent.component';
import { AgentManagementComponent } from '../agents/agent-management/agent-management.component';
import { TeamManagementComponent } from '../teams/team-management/team-management.component';
import { WomteamService } from 'src/app/services/womteam.service';
import { FieldAgentsService } from 'src/app/services/field-agents.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { TranslationService } from 'src/app/services/translation.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-teams-and-agents',
  templateUrl: './teams-and-agents.component.html',
  styleUrls: ['./teams-and-agents.component.scss']
})
export class TeamsAndAgentsComponent implements OnInit {
  agentsCount: number = 0;
  // count: number = 0;
  activeTab: string = 'Agents';
  email: string = ''
  engagedFieldAgents: any[] = [];
  inactiveFieldAgents: any[] = [];
  unengagedFieldAgents: any[] = [];
  womTeamData: any[] = [];

  buId: number[] = [];
  userGuid:string=''
  tabMenu: number = 1
  public currentPage: number = 1;
  public pageSize: number = 10;
  public lastPageSize: number = 1;
  public count: number = 0;
  public teamCount: number = 0;

  public engagedCount: number = 0;
  public unengagedCount: number = 0;
  public inactiveCount: number = 0;
  public searchText:SearchAutocomplete = new SearchAutocomplete()

  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  @ViewChild(TeamDetailsComponent) detailsPopupComponentRef!: TeamDetailsComponent;
  @ViewChild('agentManagementComponent') agentManagementComponent: AgentManagementComponent | any;
  @ViewChild('teamManagementComponent') teamManagementComponent: TeamManagementComponent | any;
  @ViewChild(CreateAgentComponent) createAgentPopUpRef!: CreateAgentComponent;

  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  //Multi Filter
  public filterApplied: boolean = false;
  public allFilters: any = []
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public businessUnits: any = { name: this.buName, data: [], dropdown: true };
  public undertakings: any = { name: this.utName, data: [] };
  public buSelected = {value: 0, name: '', checked: false}


  // public buIds: number = 0
  public utIds: any = []
  public showFilter: any = false
  public searchPlaceholder: any = "Search by agent's name, agent id, phone or email";

  options = ['']

  constructor(private womTeamService: WomteamService,
    private translationService: TranslationService,
    private fieldAgentService: FieldAgentsService,
    private toastr: ToastrService,
    private userService: UserService,
    private authService: AuthService,
    private businessUnitService: BusinessUnitService,
    private undertakingService: UndertakingService

  ) { }

  ngOnInit(): void {
    this.email = this.authService.getCurrentUser()?.email;
    this.getLoggedUser()
  }
  deactivate() {
    this.agentManagementComponent.deactivate();
  }
  activate() {
    this.agentManagementComponent.activate();
  }

  getBusForSelect() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data, dropdown: true  };

      if(this.buId.length != 0)
        this.businessUnits.data = this.businessUnits.data.filter((item: { value: number; }) => this.buId.includes(item.value));

      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getTeam(obj: any) {
    this.womTeamService.getAll(obj).subscribe(response => {
      this.womTeamData = response.data.data;
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  deleteTeam() {
    this.teamManagementComponent.deleteTeam();
  }

  getCount() {
    let obj = {
      BusinessUnitIds: this.buId
  };
    this.womTeamService.getCount(obj).subscribe(response => {
      this.teamCount = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getOptions(searchText: SearchAutocomplete) {
    let agentType = '';
    if (searchText.name.length > 2) {
      if (this.activeTab === 'Agents') {
        if (this.tabMenu === 1) {
          agentType = 'Unengaged'
        } else if (this.tabMenu === 2) {
          agentType = 'Engaged'
        } else
          agentType = 'Inactive'

        let obj = {
          search: searchText.name,
          agentType: agentType
        }
        this.fieldAgentService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      } else {
        this.womTeamService.getDataForAutocomplete(searchText.name)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
    } else
      this.searchComponentRef.resetOptions()
  }

  getFieldAgentCount() {
    let obj = {
        BusinessUnitIds: this.buId,
        UserGuid : this.userGuid
    };
    this.fieldAgentService.getFieldAgentCount(obj).subscribe(response => {
      if (response.status === 200) {
        let allCount = response.data;
        this.engagedCount = allCount.engagedCount;
        this.unengagedCount = allCount.unengagedCount;
        this.inactiveCount = allCount.inactiveCount;
        this.agentsCount=this.inactiveCount+this.unengagedCount+this.engagedCount;
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getAllInactiveFieldAgents(obj: any) {
    this.fieldAgentService.getAllInactiveFieldAgents(obj).subscribe(response => {
      this.inactiveFieldAgents = response.data.data;
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getLoggedUser() {
    this.userService.getByEmail(this.email).subscribe(response => {
      if (response.status === 200) {
        this.buId = response.data.buId;
        this.userGuid = response.data.userGuid;
        this.getBusForSelect()

        this.reloadTable();
        this.getCount()
        this.getFieldAgentCount()
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getAllEngagedFieldAgents(obj: any) {
    this.fieldAgentService.getAllEngagedFieldAgents(obj).subscribe(response => {
      this.engagedFieldAgents = response.data.data;
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(value?: any, page: any = null) {
    if (value !== undefined)
      this.tabMenu = value;
    if (page)
      this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        Id: 0,
        BusinessUnitIds: this.buId,
        BuId: this.buSelected.value,
        UtIds: this.utIds,
        SearchText: this.searchText.name
      }
    };

    if (this.activeTab === 'Agents') {
      if (this.tabMenu === 1)
        this.getAllUnengaged(obj);
      else if (this.tabMenu === 2)
        this.getAllEngagedFieldAgents(obj);
      else
        this.getAllInactiveFieldAgents(obj);
    }
    else
      this.getTeam(obj)
  }

  onStatusChange(value: number) {
    this.tabMenu = value;
    this.resetFilter()
  }

  getAllUnengaged(obj: any) {
    this.fieldAgentService.getAllUnengaged(obj).subscribe(response => {
      this.unengagedFieldAgents = response.data.data;
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  mainTabChange(value: number) {
    if (value === 1) {
      this.activeTab = 'Agents';
      this.tabMenu=1;
      this.searchPlaceholder = "Search by agent's name, agent id, phone or email";
    }
    else {
      this.activeTab = 'Teams';
      this.searchPlaceholder = "Search by team id or team name";
    }
    this.resetFilter()
  }
  //Multi Filter
  checkFilters() {
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnits.name);

    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);

    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;

    if (!buData) {
      this.allFilters.push(this.businessUnits)
      this.allFilters.push(this.undertakings)

      this.filterApplied = true
    }
    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakings.name);
    if (utIndex !== -1)
      this.allFilters[utIndex].data = this.undertakings.data;

    this.allFilters = [...this.allFilters]
  }

  dropdownSelected(data: any){
    if(!data)
      return
    if(data.data.name === this.buName)
      this.businessUnitSelected(data.item)
  }

  businessUnitSelected(item: any) {
    this.buSelected = item;

    if (this.buSelected.value == 0) {
      this.buSelected = {value: 0, name: '', checked: false}
      this.utIds = []
      this.undertakings.data.splice(0);
      this.checkFilters()
    }
    else {
      this.undertakingService.getAllByBuId(this.buSelected.value).subscribe(response => {
        this.undertakings = { name: this.utName, data: response.data };
        this.checkFilters()
        // this.pageChange(1);
      }, error => {
        this.toastr.error('An error occurred.');
      })
    }
    this.pageChange(1)
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)

      if (item.name === this.undertakings.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.undertakings = item
        this.utIds = filteredValues
      }
    }
    this.reloadTable(1)
  }

  searchChange(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.reloadTable()
  }

  resetFilter() {
    this.buSelected = {value: 0, name: '', checked: false}
    this.utIds = []
    this.searchText = new SearchAutocomplete()
    this.searchComponentRef.searchText = new SearchAutocomplete()
    this.showFilter = false;
    if (this.multiFilterRef) {
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.pageChange(1);
  }

}
