import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TaskCategory } from 'src/app/models/task-category.model';
import { TaskCategoryService } from 'src/app/services/task-category.service';

@Component({
  selector: 'app-category-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(143%, 0, 0)'
      })),
      transition('in => out', animate('900ms ease-in-out')),
      transition('out => in', animate('900ms ease-in-out'))
    ]),
  ],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class CategoryCreateComponent {

  showShadow = false;
  @Input() animationState = 'out';
  @Output() parentComp = new EventEmitter();


  public selectedCategory : TaskCategory = new TaskCategory();

  constructor(private taskCategoryService: TaskCategoryService,
              private toastr: ToastrService,
              private _eref: ElementRef) { }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.animationState === 'in')
      if (this._eref.nativeElement.contains(event.target) || event.target === this._eref.nativeElement) {
        if (!isSliderClicked)
          this.changeState(2);
      }
  }

  save() {
    this.taskCategoryService.save(this.selectedCategory).subscribe(response => {
      if(response.status === 200 || response.status === 'OK'){
        this.toastr.success(response.message);
        this.resetSelectedCategory();
        this.changeState(2);
        this.parentComp.emit();
      }else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedCategory(){
    this.selectedCategory = new TaskCategory();
  }

  closeTab() {
    this.resetSelectedCategory();
    this.changeState(2);
  }

  changeState(number: Number, data?: any) {
    if (data)
      this.selectedCategory = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

}
