<main class="container-fluid">
    <div class="container-wrapper">
        <app-wom-main *ngIf="activeView == viewsEnum.View"
            (changeView)="changeView($event)"
            (setTaskId)="setTaskId($event)"
        ></app-wom-main>
        <app-wom-create-task-page *ngIf="activeView == viewsEnum.Create"
            (changeView)="changeView($event)"
        ></app-wom-create-task-page>
        <app-wom-create-task-page *ngIf="activeView == viewsEnum.Edit"
            (changeView)="changeView($event)"
            [taskId]="taskId"
        ></app-wom-create-task-page>
        <app-wom-details *ngIf="activeView == viewsEnum.ShowDetails"
            (changeView)="changeView($event)"
            [taskId]="taskId"
        ></app-wom-details>
    </div>
</main>