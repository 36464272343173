import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgChartsModule } from 'ng2-charts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule, POSITION } from 'ngx-ui-loader';
import { HttpInterceptorService } from './helpers/interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AdoraPaginationComponent } from './components/common/adora-pagination/adora-pagination.component';
import { MapClusterizedComponent } from './components/common/map/map-clusterized/map-clusterized.component';
import { MapComponent } from './components/common/map/map.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { LoginComponent } from './components/login/login.component';
import { AppConfig } from './config/config';
import { AppComponent } from './layout/app.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { HasValuePipe } from './pipes/has-value.pipe';

import { HeaderComponent } from './components/common/header/header.component';
import { ConsumptionGraphComponent } from './components/trend-analysis/trend-analysis.component';
import { UserManagementComponent } from './components/user-management/user-management.component';

import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AccountComponent } from './components/account/account/account.component';
import { AdoraDatepickerComponent } from './components/common/adora-datepicker/adora-datepicker.component';
import { CarouselComponent } from './components/common/carousel/carousel.component';
import { BuMapDialogComponent } from './components/common/map/bu-map-dialog/bu-map-dialog.component';
import { DtMapDialogComponent } from './components/common/map/dt-map-dialog/dt-map-dialog.component';
import { SsMapDialogComponent } from './components/common/map/ss-map-dialog/ss-map-dialog.component';
import { UtMapDialogComponent } from './components/common/map/ut-map-dialog/ut-map-dialog.component';
import { ConsumptionDtComponent } from './components/consumption/consumption-dt/consumption-dt.component';
import { ConsumptionFeeder11Component } from './components/consumption/consumption-feeder11/consumption-feeder11.component';
import { ConsumptionFeeder33Component } from './components/consumption/consumption-feeder33/consumption-feeder33.component';
import { ConsumptionTsComponent } from './components/consumption/consumption-ts/consumption-ts.component';
import { ConsumptionUtComponent } from './components/consumption/consumption-ut/consumption-ut.component';
import { ConsumptionComponent } from './components/consumption/consumption.component';
import { FieldCountBoxNewComponent } from './components/field-operations-modules/common/count-box-new/count-box-new.component';
import { FieldCountBoxComponent } from './components/field-operations-modules/common/count-box/count-box.component';
import { FieldFilterComponent } from './components/field-operations-modules/common/field-filter/field-filter.component';
import { FieldPaginationComponent } from './components/field-operations-modules/common/field-pagination/field-pagination.component';
import { FieldSliderComponent } from './components/field-operations-modules/common/field-slider/field-slider.component';
import { DashboardFieldOperationsNewComponent } from './components/field-operations-modules/dashboard-field-operations-new/dashboard-field-operations-new.component';
import { IMItemsLowComponent } from './components/field-operations-modules/dashboard-field-operations-new/fo-dashboard-items-low/items-low.component';
import { IMTopItemsComponent } from './components/field-operations-modules/dashboard-field-operations-new/fo-dashboard-top-items/top-items.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ResolutionTestComponent } from './components/resolution-test/resolution-test.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TranslationPageComponent } from './components/translation-page/translation-page.component';
import { WatchlistGraphComponent } from './components/watchlist-graph/watchlist-graph.component';
import { ClickStopPropagation } from './directives/click-stop-propagation.directive';
import { FieldOperationsLayoutComponent } from './layout/field-operations-layout/field-operations-layout.component';
import { NetworkManagemenetLayoutComponent } from './layout/network-managemenet-layout/network-managemenet-layout.component';
import { RevenueProtectionLayoutComponent } from './layout/revenue-protection-layout/revenue-protection-layout.component';
import { DateTimeFormatPipe } from './pipes/datetime-format.pipe';
import { PercentTimePipe } from './pipes/percent-time.pipe';
import { PercentageFormatPipe } from './pipes/percentage-format.pipe';
import { ReadTypeFormatPipe } from './pipes/read-type.pipe';

import { DropdownMenuComponent } from './components/field-operations-modules/common/dropdown-menu/dropdown-menu.component';
import { FieldDatepickerComponent } from './components/field-operations-modules/common/field-datepicker/field-datepicker.component';
import { OverdueBillConfigurationComponent } from './components/revenue-protection/settings/overdue-bill-configuration/overdue-bill-configuration.component';
import { TwoDecimalsPipe } from './pipes/two-decimals.pipe';

import { Chart } from 'chart.js';
import { AssetPerformanceDtComponent } from './components/field-operations-modules/asset-performance/asset-performance-dt/asset-performance-dt.component';
import { AssetDatepickerComponent } from './components/field-operations-modules/common/asset-datepicker/asset-datepicker.component';
import { DownloadButtonFieldComponent } from './components/field-operations-modules/common/download-button-field/download-button-field.component';
import { SettingsFieldComponent } from './components/field-operations-modules/settingsField/settingsField.component';
import { ManualReadComponent } from './components/manual-read/manual-read.component';
import { AccountListComponent } from './components/meter-management/account-list/account-list.component';
import { AccountReadsComponent } from './components/meter-management/account-list/account-reads/account-reads.component';
import { AccountSliderComponent } from './components/meter-management/account-list/account-slider/account-slider.component';
import { AccountsDownloadComponent } from './components/meter-management/account-list/accounts-download/accounts-download.component';
import { MeterManagementDatepickerComponent } from './components/meter-management/common/meter-management-datepicker/meter-management-datepicker.component';
import { MeterManagementHeaderComponent } from './components/meter-management/common/meter-management-header/meter-management-header.component';
import { MeterManagementPaginationComponent } from './components/meter-management/common/meter-management-pagination/meter-management-pagination.component';
import { MeterManagementSidenavComponent } from './components/meter-management/common/meter-management-sidenav/meter-management-sidenav.component';
import { MeterManagementDashboardComponent } from './components/meter-management/meter-management-dashboard/meter-management-dashboard.component';
import { SettingsRevenueComponent } from './components/revenue-protection/settings/settings-revenue/settings.component';
import { ConsumptionTableComponent } from './components/trend-analysis/consumption-table/consumption-table.component';
import { NetWatchComponent } from './components/watchlist-graph/net-watch/net-watch.component';
import { OrgWatchComponent } from './components/watchlist-graph/org-watch/org-watch.component';
import { MeterManagementLayoutComponent } from './layout/meter-management-layout/meter-management-layout.component';
import { BuNamePipe } from './pipes/bu-name.pipe';
import { FilterBuPipe } from './pipes/filter-bu.pipe';
import { UtNamePipe } from './pipes/ut-name.pipe';

import * as Sentry from "@sentry/angular-ivy";
import { environment } from 'src/environments/environment';
import { AssetPerfomanceSliderComponent } from './components/field-operations-modules/asset-performance/asset-performance-dt/asset-perfomance-slider/asset-perfomance-slider.component';
import { AssetPerformanceF11SliderComponent } from './components/field-operations-modules/asset-performance/asset-performance-f11/asset-performance-f11-slider/asset-performance-f11-slider.component';
import { AssetPerformanceF11Component } from './components/field-operations-modules/asset-performance/asset-performance-f11/asset-performance-f11.component';
import { PaDtComponent } from './components/field-operations-modules/asset-performance/predictive-analytics/pa-dt/pa-dt.component';
import { PaFeeders11Component } from './components/field-operations-modules/asset-performance/predictive-analytics/pa-feeders11/pa-feeders11.component';
import { PaFeeders33Component } from './components/field-operations-modules/asset-performance/predictive-analytics/pa-feeders33/pa-feeders33.component';
import { PaSliderComponent } from './components/field-operations-modules/asset-performance/predictive-analytics/pa-slider/pa-slider.component';
import { WomTeamsComponent } from './components/field-operations-modules/wom-teams/wom-teams.component';
import { GlobalSettingsComponent } from './components/global-landing-page/global-settings/global-settings.component';
import { GepDtComponentNew } from './components/grid-energy-profile-new/gep-dt-new/gep-dt.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { BillsComponent } from './components/revenue-protection-new/bills/bills.component';
import { ThanksgivingComponent } from './components/thanksgiving/thanksgiving.component';
import { GlobalLayoutComponent } from './layout/global-layout/global-layout.component';
import { CustomNumberPipe } from './pipes/custom-number.pipe';

import { ClusterizedMapComponent } from './components/common/clusterized-map/clusterized-map.component';
import { PredictiveAssetDatepickerComponent } from './components/common/predictive-asset-datepicker/predictive-asset-datepicker.component';
import { AssetPerformanceF33SliderComponent } from './components/field-operations-modules/asset-performance/asset-performance-f33/asset-performance-f33-slider/asset-performance-f33-slider.component';
import { AssetPerformanceF33Component } from './components/field-operations-modules/asset-performance/asset-performance-f33/asset-performance-f33.component';
import { PaF11SliderComponent } from './components/field-operations-modules/asset-performance/predictive-analytics/pa-f11-slider/pa-f11-slider.component';
import { PasswordForNewUserComponent } from './components/login/password-for-new-user/password-for-new-user.component';
import { CustomersMapComponent } from './components/meter-management/customers-map/customers-map.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { NotFoundPageComponent } from './layout/not-found-page/not-found-page.component';

/***********************************************NEW DESIGN******************************************** */

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { CommercialComponent } from './components/commercial/commercial.component';
import { GlobalLandingPageNewComponent } from './components/global-landing-page-new/global-landing-page-new.component';
import { LoginHomeDetailsComponent } from './components/login/login-home-details/login-home-details.component';
import { ResetPasswordComponent } from './components/login/reset-password-popup/reset-password.component';
import { SupportFormComponent } from './components/login/support-form/support-form.component';
import { MeterBypassComponent } from './components/revenue-protection-new/customer-watchlist/meter-bypass/meter-bypass.component';
import { OverdueBillArearsComponent } from './components/revenue-protection-new/customer-watchlist/overdue-bill-arears/overdue-bill-arears.component';

import { NewCountBoxComponent } from './components/common-new-design/count-box/count-box.component';
import { DatepickerComponent } from './components/common-new-design/datepicker/datepicker.component';
import { DownloadButtonComponent } from './components/common-new-design/download-button/download-button.component';
import { ExportButtonComponent } from './components/common-new-design/export-button/export-button.component';
import { FilterDropdownComponent } from './components/common-new-design/filter-dropdown/filter-dropdown.component';
import { NewHeaderComponent } from './components/common-new-design/header/header.component';
import { NetworkManagementFiltersComponent } from './components/common-new-design/network-management-filters/network-management-filters.component';
import { NetworkManagementSidemenuComponent } from './components/common-new-design/network-management-sidemenu/network-management-sidemenu.component';
import { PaginationComponent } from './components/common-new-design/pagination/pagination.component';
import { SearchComponent } from './components/common-new-design/search/search.component';
import { SidemenuComponent } from './components/common-new-design/sidemenu/sidemenu.component';
import { TabDefaultComponent } from './components/common-new-design/tab-default/tab-default.component';
import { TabNmDashboardComponent } from './components/common-new-design/tab-nm-dashboard/tab-nm-dashboard.component';
import { GepFeeder11NewComponent } from './components/grid-energy-profile-new/gep-feeder11-new/gep-feeder11-new.component';
import { GepFeeder33NewComponent } from './components/grid-energy-profile-new/gep-feeder33-new/gep-feeder33-new.component';
import { GridEnergyProfileNewComponent } from './components/grid-energy-profile-new/grid-energy-profile-new.component';
import { GridLossesAnalysisComponent } from './components/network-management-dashboard-new/grid-losses-analysis/grid-losses-analysis.component';
import { NetworkManagementDashboardNewComponent } from './components/network-management-dashboard-new/network-management-dashboard-new.component';
import { OutageAnalysisComponent } from './components/network-management-dashboard-new/outage-analysis/outage-analysis.component';
import { MeterBypassPopupComponent } from './components/revenue-protection-new/customer-watchlist/meter-bypass/meter-bypass-popup/meter-bypass-popup.component';
import { OverdueBillsPopupComponent } from './components/revenue-protection-new/customer-watchlist/overdue-bills-popup/overdue-bills-popup.component';
import { CurrentTotalCollectionsComponent } from './components/revenue-protection-new/dashboard-revenue-protection/current-total-collections/current-total-collections.component';
import { DashboardNetworkPreformanceComponent } from './components/revenue-protection-new/dashboard-revenue-protection/dashboard-network-preformance/dashboard-network-preformance.component';
import { DashboardRevenueProtectionComponent } from './components/revenue-protection-new/dashboard-revenue-protection/dashboard-revenue-protection.component';
import { MeterBypassDiagnosticComponent } from './components/revenue-protection-new/dashboard-revenue-protection/meter-bypass-diagnostic/meter-bypass-diagnostic.component';
import { OverduePostpaidBillDebtsComponent } from './components/revenue-protection-new/dashboard-revenue-protection/overdue-postpaid-bill-debts/overdue-postpaid-bill-debts.component';
import { PaymentCollectionsPopupComponent } from './components/revenue-protection-new/debt-management/payment-collections-popup/payment-collections-popup.component';
import { PaymentCollectionsComponent } from './components/revenue-protection-new/debt-management/payment-collections/payment-collections.component';
import { ViewDetailsPageComponent } from './components/revenue-protection-new/debt-management/view-details-page/view-details-page.component';
import { SettingsSidebarComponent } from './components/settings-sidebar/settings-sidebar.component';
import { UserProfileManagamentComponent } from './components/user-profile-managament/user-profile-managament.component';

import { AegBuNewComponent } from './components/asset-energy-graph-new/aeg-bu-new/aeg-bu-new.component';
import { AegDtNewComponent } from './components/asset-energy-graph-new/aeg-dt-new/aeg-dt-new.component';
import { AegF33NewComponent } from './components/asset-energy-graph-new/aeg-f33-new/aeg-f33-new.component';
import { AegFeeder11NewComponent } from './components/asset-energy-graph-new/aeg-feeder11-new/aeg-feeder11-new.component';
import { AegUtNewComponent } from './components/asset-energy-graph-new/aeg-ut-new/aeg-ut-new.component';
import { AssetEnergyGraphNewComponent } from './components/asset-energy-graph-new/asset-energy-graph-new.component';
import { FilterMultiselectComponent } from './components/common-new-design/filter-multiselect/filter-multiselect.component';
import { GraphTableButtonComponent } from './components/common-new-design/graph-table-button/graph-table-button.component';
import { NotificationsComponent } from './components/common-new-design/header/notifications/notifications.component';
import { UnreadIndicatorComponent } from './components/common-new-design/header/notifications/unread-indicator/unread-indicator.component';
import { MapTableButtonComponent } from './components/common-new-design/map-table-button/map-table-button.component';
import { GlobalSidemenuComponent } from './components/global-landing-page-new/global-sidemenu/global-sidemenu.component';
import { GepBuNewComponent } from './components/grid-energy-profile-new/gep-bu-new/gep-bu-new.component';
import { GepUtNewComponent } from './components/grid-energy-profile-new/gep-ut-new/gep-ut-new.component';
import { NetWatchNewComponent } from './components/network-management-new/net-watch/net-watch.component';
import { NetworkManagementGridLossesComponent } from './components/network-management-new/network-management-grid-losses/network-management-grid-losses.component';
import { NetworkManagementOutageAnalysisNewComponent } from './components/network-management-new/network-management-outage-analysis-new/network-management-outage-analysis-new.component';
import { OrgWatchNewComponent } from './components/network-management-new/org-watch-new/org-watch-new.component';
import { WatchlistComponent } from './components/network-management-new/watchlist/watchlist.component';
import { OutageDtNewComponent } from './components/outage-new/outage-dt/outage-dt.component';
import { OutageFeeder11NewComponent } from './components/outage-new/outage-feeder11/outage-feeder11.component';
import { OutageFeeder33NewComponent } from './components/outage-new/outage-feeder33/outage-feeder33.component';
import { OutageNewComponent } from './components/outage-new/outage-new.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { OutageDurationFilterComponent } from './components/outage-new/outage-duration-filter/outage-duration-filter.component';

import { AgentDetailsComponent } from './components/agents-teams/agents/agent-details/agent-details.component';
import { AgentManagementComponent } from './components/agents-teams/agents/agent-management/agent-management.component';
import { CreateAgentComponent } from './components/agents-teams/agents/create-agent/create-agent.component';
import { CreateTeamManagementComponent } from './components/agents-teams/teams/create-team-management/create-team-management.component';
import { TeamDetailsComponent } from './components/agents-teams/teams/team-details/team-details.component';
import { TeamManagementComponent } from './components/agents-teams/teams/team-management/team-management.component';
import { AuditLogNewComponent } from './components/audit-log-new/audit-log-new.component';
import { FieldOperationsSidemenuComponent } from './components/common-new-design/field-operations-sidemenu/field-operations-sidemenu.component';
import { FilterDropdownSearchComponent } from './components/common-new-design/filter-dropdown-search/filter-dropdown-search.component';
import { FoPaginationNewComponent } from './components/common-new-design/fo-pagination-new/fo-pagination-new.component';
import { GreenCheckboxComponent } from './components/common-new-design/green-checkbox/green-checkbox.component';
import { ImportDetailsPopupComponent } from './components/common-new-design/import-details-popup/import-details-popup.component';
import { MultiFilterDropdownComponent } from './components/common-new-design/multi-filter/multi-filter-dropdown/multi-filter-dropdown.component';
import { MultiFilterMultiselectComponent } from './components/common-new-design/multi-filter/multi-filter-multiselect/multi-filter-multiselect.component';
import { MultiFilterComponent } from './components/common-new-design/multi-filter/multi-filter.component';
import { SwitchModulePopupComponent } from './components/common-new-design/switch-module-popup/switch-module-popup.component';
import { AccountCreateComponent } from './components/crud-tabs/account-tab/account-create/account-create.component';
import { AccountTabComponent } from './components/crud-tabs/account-tab/account-tab.component';
import { AmiTabComponent } from './components/crud-tabs/ami-tab/ami-tab.component';
import { CreateAmiComponent } from './components/crud-tabs/ami-tab/create-ami/create-ami.component';
import { AreaCreateComponent } from './components/crud-tabs/area-tab/area-create/area-create.component';
import { AreaTabComponent } from './components/crud-tabs/area-tab/area-tab.component';
import { BusinessUnitTabComponent } from './components/crud-tabs/business-unit-tab/business-unit-tab.component';
import { CreateBusinessUnitComponent } from './components/crud-tabs/business-unit-tab/create-business-unit/create-business-unit.component';
import { CrudDeleteModalComponent } from './components/crud-tabs/crud-delete-modal/crud-delete-modal.component';
import { CrudTabsComponent } from './components/crud-tabs/crud-tabs.component';
import { DcuCreateComponent } from './components/crud-tabs/dcu-tabs/dcu-create/dcu-create.component';
import { DcuTabsComponent } from './components/crud-tabs/dcu-tabs/dcu-tabs.component';
import { DtCreateComponent } from './components/crud-tabs/dt-tab/dt-create/dt-create.component';
import { DtTabComponent } from './components/crud-tabs/dt-tab/dt-tab.component';
import { Feeder11CreateComponent } from './components/crud-tabs/feeders-tab/feeder11-tab/feeder11-create/feeder11-create.component';
import { Feeder11TabComponent } from './components/crud-tabs/feeders-tab/feeder11-tab/feeder11-tab.component';
import { Feeder33CreateComponent } from './components/crud-tabs/feeders-tab/feeder33-tab/feeder33-create/feeder33-create.component';
import { Feeder33TabComponent } from './components/crud-tabs/feeders-tab/feeder33-tab/feeder33-tab.component';
import { FeedersTabComponent } from './components/crud-tabs/feeders-tab/feeders-tab.component';
import { PageTabsComponent } from './components/crud-tabs/page-tabs/page-tabs.component';
import { CreateSubstationComponent } from './components/crud-tabs/substation/create-substation/create-substation.component';
import { SubstationTabComponent } from './components/crud-tabs/substation/substation.component';
import { TransmissionStationCreateComponent } from './components/crud-tabs/transmission-station-tab/transmission-station-create/transmission-station-create.component';
import { TransmissionStationTabComponent } from './components/crud-tabs/transmission-station-tab/transmission-station-tab.component';
import { DropdownMeterDetailsComponent } from './components/field-operations-modules/common/dropdown-meter-details/dropdown-meter-details.component';
import { SimpleDropdownComponent } from './components/field-operations-modules/common/simple-dropdown/simple-dropdown.component';
import { WomCreateNewComponent } from './components/field-operations-modules/work-order-management-new/wom-create-task/wom-create-task.component';
import { WorkOrderManagementNewComponent } from './components/field-operations-modules/work-order-management-new/work-order-management-new.component';
import { JobStatusHistoryNewComponent } from './components/job-status-history-new/job-status-history-new.component';
import { SetPasswordNewComponent } from './components/login/set-password-new/set-password-new.component';
import { NMReportsComponent } from './components/network-management-new/reports/reports.component';
import { NewConsumptionTableComponent } from './components/network-management-new/trend-analyses/consumption-table/consumption-table.component';
import { TrendAnalysesComponent } from './components/network-management-new/trend-analyses/trend-analyses.component';
import { ConfigurationSettingsCreateComponent } from './components/revenue-protection-new/configuration-settings/configuration-settings-create/configuration-settings-create.component';
import { ConfigurationSettingsComponent } from './components/revenue-protection-new/configuration-settings/configuration-settings.component';
import { StatementAccountDetailsComponent } from './components/revenue-protection-new/debt-management/statement-of-account/statement-account-details/statement-account-details.component';
import { StatementBalanceComponent } from './components/revenue-protection-new/debt-management/statement-of-account/statement-balance/statement-balance.component';
import { StatementHistoryComponent } from './components/revenue-protection-new/debt-management/statement-of-account/statement-history/statement-history.component';
import { StatementOfAccountComponent } from './components/revenue-protection-new/debt-management/statement-of-account/statement-of-account.component';
import { PaymentChannelCreateComponent } from './components/revenue-protection-new/payment-channel/payment-channel-create/payment-channel-create.component';
import { PaymentChannelComponent } from './components/revenue-protection-new/payment-channel/payment-channel.component';
import { PaymentsNewComponent } from './components/revenue-protection-new/payments-new/payments-new.component';
import { NewRevenueReportsComponent } from './components/revenue-protection-new/reports/reports.component';
import { VendingsComponent } from './components/revenue-protection-new/vendings/vendings.component';
import { RoleManagementNewComponent } from './components/role-management-new/role-management-new.component';
import { RoleSliderComponent } from './components/role-management-new/role-slider/role-slider.component';
import { CreateTariffComponent } from './components/tariff-settings/create-tariff/create-tarrif.component';
import { TariffSettingsComponent } from './components/tariff-settings/tarrif-settings.component';
import { CreateUserComponent } from './components/user-settings/create-user/create-user.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { ScreenSizeDetector } from './directives/screen-size-detector.directive';
import { DateTimeNoConvertPipe } from './pipes/dateTime-noConvert.pipe';

import { DeactivatePopupComponent } from './components/agents-teams/agents/agent-details/deactivate-popup/deactivate-popup.component';
import { ReassignTaskPopupComponent } from './components/agents-teams/agents/reassign-task-popup/reassign-task-popup.component';
import { TeamsAndAgentsComponent } from './components/agents-teams/teams-and-agents/teams-and-agents.component';
import { AddAgentPopupComponent } from './components/agents-teams/teams/add-agent-popup/add-agent-popup.component';
import { DeletePopupComponent } from './components/agents-teams/teams/team-details/delete-popup/delete-popup.component';
import { AssetTableComponent } from './components/asset-energy-graph-new/asset-table/asset-table.component';
import { AddPopupForFieldOperationComponent } from './components/common-new-design/add-popup-for-field-operation-team/add-popup-for-field-operation.component';
import { AddAgentPopupForFieldOperationComponent } from './components/common-new-design/add-team-agent-multiselect/add-team-agent-multiselect';
import { AttachmentsPopupComponent } from './components/common-new-design/attachments-popup/attachments-popup.component';
import { DownloadButtonNewComponent } from './components/common-new-design/download-button-new/download-button-new.component';
import { MapNewComponent } from './components/common-new-design/map-new/map-new.component';
import { MapTableButtonNewComponent } from './components/common-new-design/map-table-button-new/map-table-button-new.component';
import { MultiFilterDatepickerComponent } from './components/common-new-design/multi-filter/multi-filter-datepicker/multi-filter-datepicker.component';
import { RangeDatePickerComponent } from './components/common-new-design/multi-filter/range-date-picker/range-date-picker.component';
import { SearchAutocompleteComponent } from './components/common-new-design/search-autocomplete/search-autocomplete.component';
import { TabDefaultSecondComponent } from './components/common-new-design/tab-default-second/tab-default-second.component';
import { AccountCreateStatusTabComponent } from './components/crud-tabs/account-status-tab/account-create-status-tab/account-create-status-tab.component';
import { AccountStatusTabComponent } from './components/crud-tabs/account-status-tab/account-status-tab.component';
import { AssetPerformanceDtPopupComponent } from './components/field-operations-modules/asset-performance-new/asset-performance-dt/asset-performance-dt-popup/asset-performance-dt-popup.component';
import { AssetPerformanceDtNewComponent } from './components/field-operations-modules/asset-performance-new/asset-performance-dt/asset-performance-dt/asset-performance-dt.component';
import { AssetPerformanceF11NewComponent } from './components/field-operations-modules/asset-performance-new/asset-performance-f11-new/asset-performance-f11-new.component';
import { AssetPerformanceF11PopupComponent } from './components/field-operations-modules/asset-performance-new/asset-performance-f11-new/asset-performance-f11-popup/asset-performance-f11-popup.component';
import { AssetPerformanceF33NewComponent } from './components/field-operations-modules/asset-performance-new/asset-performance-f33-new/asset-performance-f33-new.component';
import { AssetPerformanceF33PopupComponent } from './components/field-operations-modules/asset-performance-new/asset-performance-f33-new/asset-performance-f33-popup/asset-performance-f33-popup.component';
import { AssetPerformanceNewComponent } from './components/field-operations-modules/asset-performance-new/asset-performance-new.component';
import { PaDtNewComponent } from './components/field-operations-modules/asset-performance-new/predictive-analytics-new/pa-dt-new/pa-dt-new.component';
import { PaDtSliderNewComponent } from './components/field-operations-modules/asset-performance-new/predictive-analytics-new/pa-dt-new/pa-dt-slider-new/pa-dt-slider-new.component';
import { PaF11NewComponent } from './components/field-operations-modules/asset-performance-new/predictive-analytics-new/pa-f11-new/pa-f11-new.component';
import { PaF11SliderNewComponent } from './components/field-operations-modules/asset-performance-new/predictive-analytics-new/pa-f11-new/pa-f11-slider-new/pa-f11-slider-new.component';
import { PaF33NewComponent } from './components/field-operations-modules/asset-performance-new/predictive-analytics-new/pa-f33-new/pa-f33-new.component';
import { PredictiveAnalyticsNewComponent } from './components/field-operations-modules/asset-performance-new/predictive-analytics-new/predictive-analytics-new.component';
import { DropdownTeamAgentsComponent } from './components/field-operations-modules/common/dropdown-team-agents/dropdown-team-agents.component';
import { FieldModalSettingsComponent } from './components/field-operations-modules/field-modal-settings/field-modal-settings.component';
import { FieldSettingsNewComponent } from './components/field-operations-modules/field-settings-new/field-settings-new.component';
import { InventoryManagementComponent } from './components/field-operations-modules/inventory-management/inventory-management.component';
import { ItemIssuanceComponent } from './components/field-operations-modules/inventory-management/item-issuance/item-issuance.component';
import { StockManagementCreateComponent } from './components/field-operations-modules/inventory-management/stock-management-create/stock-management-create.component';
import { IssuedItemDetailsComponent } from './components/field-operations-modules/inventory-management/stock-management-details/issued-item-details/issued-item-details.component';
import { StockItemDetailsComponent } from './components/field-operations-modules/inventory-management/stock-management-details/stock-item-details/stock-item-details.component';
import { StockManagementDetailsComponent } from './components/field-operations-modules/inventory-management/stock-management-details/stock-management-details.component';
import { StockManagementMainComponent } from './components/field-operations-modules/inventory-management/stock-management-main/stock-management-main.component';
import { DtFieldSettingsEditComponent } from './components/field-operations-modules/settingsField/dt-field-settings/dt-field-settings-edit/dt-field-settings-edit.component';
import { DtFieldSettingsComponent } from './components/field-operations-modules/settingsField/dt-field-settings/dt-field-settings.component';
import { Feeder11FieldSettingsEditComponent } from './components/field-operations-modules/settingsField/feeder11-field-settings/feeder11-field-settings-edit/feeder11-field-settings-edit.component';
import { Feeder11FieldSettingsComponent } from './components/field-operations-modules/settingsField/feeder11-field-settings/feeder11-field-settings.component';
import { Feeder33FieldSettingsEditComponent } from './components/field-operations-modules/settingsField/feeder33-field-settings/feeder33-field-settings-edit/feeder33-field-settings-edit.component';
import { Feeder33FieldSettingsComponent } from './components/field-operations-modules/settingsField/feeder33-field-settings/feeder33-field-settings.component';
import { CategoryCreateComponent } from './components/field-operations-modules/wom-crud/category-create/category-create.component';
import { WomCrudComponent } from './components/field-operations-modules/wom-crud/wom-crud.component';
import { WomCreateTaskPageComponent } from './components/field-operations-modules/work-order-management-new/wom-create-task-page/wom-create-task-page.component';
import { ReasonPopupComponent } from './components/field-operations-modules/work-order-management-new/wom-details/reason-popup/reason-popup.component';
import { WomDetailsCommentsComponent } from './components/field-operations-modules/work-order-management-new/wom-details/wom-details-comments/wom-details-comments.component';
import { WomDetailsMainComponent } from './components/field-operations-modules/work-order-management-new/wom-details/wom-details-main/wom-details-main.component';
import { WomDetailsComponent } from './components/field-operations-modules/work-order-management-new/wom-details/wom-details.component';
import { WomMainComponent } from './components/field-operations-modules/work-order-management-new/wom-main/wom-main.component';
import { DtPopupComponent } from './components/grid-energy-profile-new/gep-dt-new/dt-popup/dt-popup.component';
import { F11PopupComponent } from './components/grid-energy-profile-new/gep-feeder11-new/f11-popup/f11-popup.component';
import { F33PopupComponent } from './components/grid-energy-profile-new/gep-feeder33-new/f33-popup/f33-popup.component';
import { IdentityLoginComponent } from './components/identity-login/identity-login.component';
import { AccountListNewComponent } from './components/meter-management-new/account-list-new/account-list-new.component';
import { AccountListPopupComponent } from './components/meter-management-new/account-list-popup/account-list-popup.component';
import { AccountReadsNewComponent } from './components/meter-management-new/account-read-new/account-reads.component';
import { MeterManagementNewComponent } from './components/meter-management-new/meter-management-new.component';
import { MetersComponent } from './components/meter-management-new/meters/meters.component';
import { MeterDetailsComponent } from './components/meter-management/meter-details/meter-details.component';
import { TokenLoadingComponent } from './components/meter-management/meter-details/token-loading/token-loading.component';
import { NetworkModalSettingsComponent } from './components/network-management-new/network-modal-settings/network-modal-settings.component';
import { DtOutagePopupComponent } from './components/outage-new/outage-dt/dt-outage-popup/dt-outage-popup.component';
import { F11OutagePopupComponent } from './components/outage-new/outage-feeder11/f11-outage-popup/f11-outage-popup.component';
import { F33OutagePopupComponent } from './components/outage-new/outage-feeder33/f33-outage-popup/f33-outage-popup.component';
import { ProcessLoginComponent } from './components/process-login/process-login.component';
import { MeterBypassPostpaidDetailsComponent } from './components/revenue-protection-new/customer-watchlist/meter-bypass-postpaid/meter-bypass-postpaid-details/meter-bypass-postpaid-details.component';
import { MeterBypassPostpaidComponent } from './components/revenue-protection-new/customer-watchlist/meter-bypass-postpaid/meter-bypass-postpaid.component';
import { MeterBypassDetailsComponent } from './components/revenue-protection-new/customer-watchlist/meter-bypass/meter-bypass-details/meter-bypass-details.component';
import { BillsNewComponent } from './components/revenue-protection-new/reports-new/bills-new/bills-new.component';
import { PaymentReportsNewComponent } from './components/revenue-protection-new/reports-new/payment-reports-new/payment-reports-new.component';
import { ReportsNewComponent } from './components/revenue-protection-new/reports-new/reports-new.component';
import { VendingsNewComponent } from './components/revenue-protection-new/reports-new/vendings-new/vendings-new.component';
import { RevenueModalSettingsComponent } from './components/revenue-protection-new/revenue-modal-settings/revenue-modal-settings.component';
import { TranslationCreateComponent } from './components/translation-page/translation-create/translation-create.component';
import { MeterBypassStatusPipePipe } from './pipes/meter-bypass-status-pipe.pipe';
import { SortMonthPipe } from './pipes/sortMonth.pipe';

import { DatePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { NgIdleModule } from '@ng-idle/core';
import { DcuNewComponent } from './components/meter-management-new/dcu-new/dcu-new.component';
import { MeterAnalysisComponent } from './components/meter-management-new/meter-analysis/meter-analysis.component';
import { MeterDashboardComponent } from './components/meter-management-new/meter-dashboard/meter-dashboard.component';
import { MeterGridComponent } from './components/meter-management/common/grids/mm-meter-grid/mm-meter-grid.component';
import { CurrentActiveAssetComponent } from './components/outage-availability/Current-active/current-active-asset/current-active-asset.component';
import { CurrentActiveDTComponent } from './components/outage-availability/Current-active/current-active-asset/current-active-dt/current-active-dt.component';
import { DtCurrentActivePopupComponent } from './components/outage-availability/Current-active/current-active-asset/current-active-dt/dt-active-current-popup/dt-active-current-popup.component';
import { CurrentActiveF11Component } from './components/outage-availability/Current-active/current-active-asset/current-active-f11/current-active-f11.component';
import { F11CurrentActivePopupComponent } from './components/outage-availability/Current-active/current-active-asset/current-active-f11/f11-current-active-popup/f11-current-active-popup.component';
import { CurrentActiveF33Component } from './components/outage-availability/Current-active/current-active-asset/current-active-f33/current-active-f33.component';
import { F33CurrentActivePopupComponent } from './components/outage-availability/Current-active/current-active-asset/current-active-f33/f33-current-active-popup/f33-current-active-popup.component';

import { IdleLogoutPopupComponent } from './components/common/idle-logout-popup/idle-logout-popup.component';
import { ImageCropperDialogComponent } from './components/common/image-cropper-dialog/image-cropper-dialog.component';
import { ConsumptionsSettingsComponent } from './components/consumptions-settings/consumptions-settings.component';
import { CreateMeterModelComponent } from './components/crud-tabs/meter-model-tab/create-meter-model/create-meter-model.component';
import { MeterModelTabComponent } from './components/crud-tabs/meter-model-tab/meter-model-tab.component';
import { PaF33SliderNewComponent } from './components/field-operations-modules/asset-performance-new/predictive-analytics-new/pa-f33-new/pa-f33-slider-new/pa-f33-slider-new.component';
import { GepTsNewComponent } from './components/grid-energy-profile-new/gep-ts-new/gep-ts-new.component';
import { TsPopupComponent } from './components/grid-energy-profile-new/gep-ts-new/ts-popup/ts-popup.component';
import { CustomerStatsComponent } from './components/meter-management-new/customer-stats/customer-stats.component';
import { CustomerVendsComponent } from './components/meter-management-new/customer-vends/customer-vends.component';
import { DcuCardsComponent } from './components/meter-management-new/dcu-cards/dcu-cards.component';
import { AlarmEventMgtComponent } from './components/meter-management-new/device-management/alarm-event-mgt/alarm-event-mgt.component';
import { DeviceManagementRemoteDisconnectedComponent } from './components/meter-management-new/device-management/device-management-remote-disconnected/device-management-remote-disconnected.component';
import { RemoteDisconnectedDetailsComponent } from './components/meter-management-new/device-management/device-management-remote-disconnected/remote-disconnected-details/remote-disconnected-details.component';
import { DeviceManagementComponent } from './components/meter-management-new/device-management/device-management.component';
import { DeviceParametersComponent } from './components/meter-management-new/device-management/device-parameters/device-parameters.component';
import { LoadProfileComponent } from './components/meter-management-new/device-management/load-profile/load-profile.component';
import { MultipleAmisPopUpComponent } from './components/meter-management-new/device-management/load-profile/multiple-amis-pop-up/multiple-amis-pop-up.component';
import { RemoteDisconnectionActionComponent } from './components/meter-management-new/device-management/remote-disconnection-action/remote-disconnection-action.component';
import { RemoteTokenLoadComponent } from './components/meter-management-new/device-management/remote-token-load/remote-token-load.component';
import { GraphAnalysisComponent } from './components/meter-management-new/graph-analysis/graph-analysis.component';
import { MeterMakesComponent } from './components/meter-management-new/meter-makes/meter-makes.component';
import { MeterTriggersComponent } from './components/meter-management-new/meter-triggers/meter-triggers.component';
import { AssetMeterComponent } from './components/meter-management-new/meters/asset-meter/asset-meter.component';
import { UnmeteredComponent } from './components/meter-management-new/unmetered/unmetered.component';
import { AssetDtMetersComponent } from './components/meter-management/common/grids/asset-dt-meters/asset-dt-meters.component';
import { AssetF11MetersComponent } from './components/meter-management/common/grids/asset-f11-meters/asset-f11-meters.component';
import { AssetF33MetersComponent } from './components/meter-management/common/grids/asset-f33-meters/asset-f33-meters.component';
import { DtReadsSettingsComponent } from './components/meter-management/dt-reads-settings/dt-reads-settings.component';
import { FeederReadsSettingsComponent } from './components/meter-management/feeder-reads-settings/feeder-reads-settings.component';
import { MeterTariffHistoryComponent } from './components/meter-management/meter-details/meter-tariff-history/meter-tariff-history.component';
import { MeterTriggersGrid } from './components/meter-management/meter-triggers-page/meter-triggers-grid/meter-triggers-grid.component';
import { MeterTriggersPageComponent } from './components/meter-management/meter-triggers-page/meter-triggers-page.component';
import { CustomersWithoutDtComponent } from './components/network-management-new/network-modal-settings/customers-without-dt/customers-without-dt.component';
import { NoConsumptionCustomersComponent } from './components/network-management-new/network-modal-settings/no-consumption-customers/no-consumption-customers.component';
import { HierarchyItemComponent } from './components/network-management-new/network-modal-settings/system-hierarchy/hierarchy-item/hierarchy-item.component';
import { SystemHierarchyComponent } from './components/network-management-new/network-modal-settings/system-hierarchy/system-hierarchy.component';
import { InvalidTfeReadsComponent } from './components/network-management-new/reports/invalid-tfe-reads/invalid-tfe-reads.component';
import { NonCommunicatingDTComponent } from './components/outage-availability/non-communicating/non-communicating/non-comm-dt/non-communicating-dt.component';
import { NonCommunicatingF11Component } from './components/outage-availability/non-communicating/non-communicating/non-communicating-f11/non-communicating-f11.component';
import { NonCommunicatingF33Component } from './components/outage-availability/non-communicating/non-communicating/non-communicating-f33/non-communicating-f33.component';
import { NonCommunicatingComponent } from './components/outage-availability/non-communicating/non-communicating/non-communicating.component';
import { DayHoursTimePipe } from './pipes/day-hours-format.pipe';
import { ShortDatetimePipe } from './pipes/short-datetime-format.pipe';
import { PlannedOutageComponent } from './components/outage-new/planned-outage/planned-outage/planned-outage.component';
import { UploadPlannedOutagePopupComponent } from './components/outage-new/planned-outage/upload-planned-outage-popup/upload-planned-outage-popup.component';
import { PlannedOutageTableComponent } from './components/outage-new/planned-outage/planned-outage-table/planned-outage-table.component';
import { PlannedOutageCreateNewComponent } from './components/outage-new/planned-outage/planned-outage-create-new/planned-outage-create-new.component';
import { DatetimePickerComponent } from './components/common-new-design/multi-filter/datetime-picker/datetime-picker.component';
import { PlannedOutageUpdateComponent } from './components/outage-new/planned-outage/planned-outage-update/planned-outage-update.component';
import { StaffOperatorTabComponent } from './components/crud-tabs/staff-operator-tab/staff-operator-tab.component';
import { UnitTabComponent } from './components/crud-tabs/unit-tab/unit-tab.component';
import { SubunitTabComponent } from './components/crud-tabs/subunit-tab/subunit-tab.component';
import { DepartmentTabComponent } from './components/crud-tabs/department-tab/department-tab.component';
import { CreateStaffOperatorComponent } from './components/crud-tabs/staff-operator-tab/create-staff-operator/create-staff-operator.component';
import { CreateUnitComponent } from './components/crud-tabs/unit-tab/create-unit/create-unit.component';
import { CreateSubunitComponent } from './components/crud-tabs/subunit-tab/create-subunit/create-subunit.component';
import { CreateDepartmentComponent } from './components/crud-tabs/department-tab/create-department/create-department.component';
import { StaffOperatorCrudPagesComponent } from './components/field-operations-modules/staff-operator-crud-pages/staff-operator-crud-pages.component';
import { ScrollableAutocompleteFilterComponent } from './components/common-new-design/scrollable-autocomplete-filter/scrollable-autocomplete-filter.component';
import { ChangeDatePopupComponent } from './components/field-operations-modules/work-order-management-new/wom-details/wom-details-main/change-date-popup/change-date-popup.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsPosition: POSITION.centerCenter,
  bgsColor: 'var(--user-color)',
  fgsColor: 'var(--user-color)',
  bgsSize: 70,
  fgsSize: 70
}

Chart.register({
  id: 'zoomCSS',
  beforeEvent(chart: any, ctx: any) {
    const event = ctx.event;
    const zoom = window.devicePixelRatio;
    if (zoom !== 1) {
      chart.chartArea.left = chart.chartArea.left / zoom;
      event.x = event.x * zoom;
      event.y = event.y * zoom;
    }
  }
});

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HasValuePipe,
    AdoraPaginationComponent,
    DateFormatPipe,
    DateTimeFormatPipe,
    DateTimeNoConvertPipe,
    SidebarComponent,
    MapComponent,
    HeaderComponent,
    UserManagementComponent,
    SettingsComponent,
    ReportsComponent,
    ConsumptionGraphComponent,
    WatchlistGraphComponent,
    ConsumptionComponent,
    ConsumptionTsComponent,
    ConsumptionFeeder33Component,
    PercentageFormatPipe,
    ReadTypeFormatPipe,
    ConsumptionFeeder11Component,
    ConsumptionDtComponent,
    TranslationPageComponent,
    CarouselComponent,
    NetworkManagemenetLayoutComponent,
    ClickStopPropagation,
    NetworkManagemenetLayoutComponent,
    ResolutionTestComponent,
    DashboardRevenueProtectionComponent,
    ReportsComponent,
    AccountComponent,
    SsMapDialogComponent,
    DtMapDialogComponent,
    BuMapDialogComponent,
    UtMapDialogComponent,
    ConsumptionUtComponent,
    AdoraDatepickerComponent,
    RevenueProtectionLayoutComponent,
    MeterBypassComponent,
    DownloadButtonComponent,
    PercentTimePipe,
    OverdueBillArearsComponent,
    GlobalSettingsComponent,
    ReportsComponent,
    AccountComponent,
    SsMapDialogComponent,
    DtMapDialogComponent,
    BuMapDialogComponent,
    UtMapDialogComponent,
    ConsumptionUtComponent,
    AdoraDatepickerComponent,
    RevenueProtectionLayoutComponent,
    MeterBypassComponent,
    DownloadButtonComponent,
    PercentTimePipe,
    TwoDecimalsPipe,
    StatementOfAccountComponent,
    StatementHistoryComponent,
    PaymentCollectionsComponent,
    OverdueBillConfigurationComponent,

    ManualReadComponent,
    NetWatchComponent,
    OrgWatchComponent,
    AccountListComponent,
    MeterManagementSidenavComponent,
    MeterManagementLayoutComponent,
    MeterManagementHeaderComponent,
    MeterManagementPaginationComponent,
    AccountsDownloadComponent,
    FieldFilterComponent,
    FieldPaginationComponent,
    FieldSliderComponent,
    ReportsComponent,
    AccountComponent,
    SsMapDialogComponent,
    DtMapDialogComponent,
    BuMapDialogComponent,
    UtMapDialogComponent,
    ConsumptionUtComponent,
    AdoraDatepickerComponent,
    RevenueProtectionLayoutComponent,
    MeterBypassComponent,
    FieldCountBoxComponent,
    FieldCountBoxNewComponent,
    DownloadButtonComponent,
    PercentTimePipe,
    FieldOperationsLayoutComponent,
    DashboardFieldOperationsNewComponent,
    IMItemsLowComponent,
    IMTopItemsComponent,
    DropdownMenuComponent,
    FieldDatepickerComponent,
    AccountsDownloadComponent,
    MeterManagementDatepickerComponent,
    AccountsDownloadComponent,
    FieldOperationsLayoutComponent,
    AssetPerformanceDtComponent,
    DownloadButtonFieldComponent,
    FieldDatepickerComponent,
    AssetDatepickerComponent,
    MeterManagementDashboardComponent,
    MeterManagementLayoutComponent,
    MeterManagementDatepickerComponent,
    SettingsFieldComponent,
    AccountSliderComponent,
    ConsumptionTableComponent,
    AccountReadsComponent,
    AccountSliderComponent,
    BuNamePipe,
    UtNamePipe,
    FilterBuPipe,
    SettingsRevenueComponent,
    ShortDatetimePipe,
    FilterBuPipe,
    AssetPerfomanceSliderComponent,
    PaDtComponent,
    PaFeeders11Component,
    PaFeeders33Component,
    PaFeeders33Component,
    AssetPerformanceF11Component,
    AssetPerformanceF11SliderComponent,
    BillsComponent,
    PaSliderComponent,
    AssetPerformanceF33SliderComponent,
    CustomNumberPipe,
    GlobalLayoutComponent,
    ForgotPasswordComponent,
    ThanksgivingComponent,
    WomTeamsComponent,
    AssetPerformanceF33Component,
    AssetPerformanceF33SliderComponent,
    ClickOutsideDirective,
    CustomersMapComponent,
    ClusterizedMapComponent,
    NotFoundPageComponent,
    PaF11SliderComponent,
    PredictiveAssetDatepickerComponent,
    PasswordForNewUserComponent,
    GepDtComponentNew,


    /***********************************************NEW DESIGN******************************************** */

    SupportFormComponent,
    LoginHomeDetailsComponent,
    ResetPasswordComponent,
    GlobalLandingPageNewComponent,
    CommercialComponent,
    DashboardNetworkPreformanceComponent,
    SearchComponent,
    MeterBypassDiagnosticComponent,
    OverduePostpaidBillDebtsComponent,
    CurrentTotalCollectionsComponent,
    FilterDropdownComponent,
    MeterBypassPopupComponent,
    TabDefaultComponent,
    NetworkManagementOutageAnalysisNewComponent,
    NetworkManagementGridLossesComponent,
    PaymentCollectionsPopupComponent,
    ExportButtonComponent,
    OverdueBillArearsComponent,
    ViewDetailsPageComponent,
    SidemenuComponent,
    NetworkManagementSidemenuComponent,
    OverdueBillsPopupComponent,
    NewHeaderComponent,
    NewCountBoxComponent,
    PaginationComponent,
    SettingsSidebarComponent,
    UserProfileManagamentComponent,
    DatepickerComponent,
    GridEnergyProfileNewComponent,
    GepFeeder11NewComponent,
    GepFeeder33NewComponent,
    NetworkManagementFiltersComponent,
    MapTableButtonComponent,
    GraphTableButtonComponent,
    FilterMultiselectComponent,
    DatepickerComponent,
    WatchlistComponent,
    NetWatchNewComponent,
    DatepickerComponent,
    SettingsSidebarComponent,
    UserProfileManagamentComponent,
    NetworkManagementDashboardNewComponent,
    GridLossesAnalysisComponent,
    OutageAnalysisComponent,
    TabNmDashboardComponent,
    OrgWatchNewComponent,
    AssetEnergyGraphNewComponent,
    AegBuNewComponent,
    AegDtNewComponent,
    AegF33NewComponent,
    AegFeeder11NewComponent,
    AegUtNewComponent,
    TrendAnalysesComponent,
    NewConsumptionTableComponent,
    GepBuNewComponent,
    GepUtNewComponent,
    NotificationsComponent,
    UnreadIndicatorComponent,
    DateAgoPipe,
    MapClusterizedComponent,
    GlobalSidemenuComponent,
    StatementAccountDetailsComponent,
    StatementBalanceComponent,
    NewConsumptionTableComponent,
    OutageNewComponent,
    OutageDtNewComponent,
    OutageFeeder11NewComponent,
    OutageFeeder33NewComponent,
    OutageDurationFilterComponent,
    SetPasswordNewComponent,
    PaymentsNewComponent,
    UserSettingsComponent,
    CreateUserComponent,
    NewRevenueReportsComponent,
    NMReportsComponent,
    VendingsComponent,
    TariffSettingsComponent,
    CreateTariffComponent,
    CrudTabsComponent,
    AmiTabComponent,
    CreateAmiComponent,
    SubstationTabComponent,
    CreateSubstationComponent,
    BusinessUnitTabComponent,
    CreateBusinessUnitComponent,
    CrudDeleteModalComponent,
    PageTabsComponent,
    AreaTabComponent,
    AreaCreateComponent,
    DcuTabsComponent,
    DcuCreateComponent,
    AuditLogNewComponent,
    DtTabComponent,
    DtCreateComponent,
    TransmissionStationTabComponent,
    AccountTabComponent,
    AccountCreateComponent,
    TransmissionStationCreateComponent,
    ScreenSizeDetector,
    JobStatusHistoryNewComponent,
    AuditLogNewComponent,
    FeedersTabComponent,
    Feeder11TabComponent,
    Feeder33TabComponent,
    Feeder33CreateComponent,
    Feeder11CreateComponent,
    AccountTabComponent,
    AccountCreateComponent,
    RoleManagementNewComponent,
    AccountCreateComponent,
    ConfigurationSettingsComponent,
    ConfigurationSettingsCreateComponent,
    PaymentChannelComponent,
    PaymentChannelCreateComponent,
    ConfigurationSettingsCreateComponent,
    RoleSliderComponent,
    WorkOrderManagementNewComponent,
    WomCreateNewComponent,
    GreenCheckboxComponent,
    SwitchModulePopupComponent,
    ImportDetailsPopupComponent,
    RoleSliderComponent,
    TeamManagementComponent,
    CreateTeamManagementComponent,
    AgentManagementComponent,
    MultiFilterComponent,
    MultiFilterDropdownComponent,
    MultiFilterMultiselectComponent,
    MultiFilterDatepickerComponent,
    FoPaginationNewComponent,
    ImportDetailsPopupComponent,
    FieldOperationsSidemenuComponent,
    CreateAgentComponent,
    SimpleDropdownComponent,
    DropdownMeterDetailsComponent,
    FieldOperationsSidemenuComponent,
    FilterDropdownSearchComponent,
    TeamDetailsComponent,
    AgentDetailsComponent,
    WomCrudComponent,
    FieldSettingsNewComponent,
    AgentDetailsComponent,
    PredictiveAnalyticsNewComponent,
    AddPopupForFieldOperationComponent,
    CategoryCreateComponent,
    TeamsAndAgentsComponent,
    AddAgentPopupForFieldOperationComponent,
    CategoryCreateComponent,
    TeamsAndAgentsComponent,
    TranslationCreateComponent,
    AssetPerformanceNewComponent,
    AssetPerformanceF11NewComponent,
    AssetPerformanceF33NewComponent,
    AssetPerformanceDtPopupComponent,
    AssetPerformanceF11PopupComponent,
    AssetPerformanceF33PopupComponent,
    AssetPerformanceDtNewComponent,
    PaF11SliderNewComponent,
    PaDtSliderNewComponent,
    DtFieldSettingsComponent,
    Feeder11FieldSettingsComponent,
    Feeder33FieldSettingsComponent,
    DtFieldSettingsEditComponent,
    Feeder11FieldSettingsEditComponent,
    Feeder33FieldSettingsEditComponent,
    PaDtSliderNewComponent,
    PaDtNewComponent,
    PaF11NewComponent,
    PaF33NewComponent,
    InventoryManagementComponent,
    PaF33NewComponent,
    AddAgentPopupForFieldOperationComponent,
    WomCreateTaskPageComponent,
    WomMainComponent,
    DropdownTeamAgentsComponent,
    WomDetailsComponent,
    WomDetailsMainComponent,
    AttachmentsPopupComponent,
    WomDetailsCommentsComponent,
    RangeDatePickerComponent,
    StockManagementMainComponent,
    StockManagementCreateComponent,
    StockManagementDetailsComponent,
    AddAgentPopupComponent,
    StockItemDetailsComponent,
    IssuedItemDetailsComponent,
    ItemIssuanceComponent,
    ReassignTaskPopupComponent,
    F11PopupComponent,
    F33PopupComponent,
    DtPopupComponent,
    MapNewComponent,
    DownloadButtonNewComponent,
    MapTableButtonNewComponent,
    TabDefaultSecondComponent,
    SearchAutocompleteComponent,
    TabDefaultSecondComponent,
    SortMonthPipe,
    DtOutagePopupComponent,
    F11OutagePopupComponent,
    F33OutagePopupComponent,
    MeterBypassDetailsComponent,
    ReasonPopupComponent,
    DeactivatePopupComponent,
    MeterBypassStatusPipePipe,
    ReportsNewComponent,
    BillsNewComponent,
    VendingsNewComponent,
    PaymentReportsNewComponent,
    AssetTableComponent,
    RevenueModalSettingsComponent,
    FieldModalSettingsComponent,
    NetworkModalSettingsComponent,
    MeterManagementNewComponent,
    MetersComponent,
    MeterDetailsComponent,
    MeterGridComponent,
    AccountListNewComponent,
    AccountListPopupComponent,
    DeletePopupComponent,
    AccountReadsNewComponent,
    AccountStatusTabComponent,
    AccountCreateStatusTabComponent,
    AccountReadsNewComponent,
    MeterBypassPostpaidComponent,
    MeterBypassPostpaidDetailsComponent,
    AssetTableComponent,
    ProcessLoginComponent,
    IdentityLoginComponent,
    DcuNewComponent,
    IdentityLoginComponent,
    MeterAnalysisComponent,
    CurrentActiveAssetComponent,
    MeterDashboardComponent,
    CurrentActiveDTComponent,
    CurrentActiveF11Component,
    CurrentActiveF33Component,
    DtCurrentActivePopupComponent,
    F11CurrentActivePopupComponent,
    F33CurrentActivePopupComponent,
    NonCommunicatingDTComponent,
    NonCommunicatingF11Component,
    NonCommunicatingComponent,
    MeterDashboardComponent,
    MeterMakesComponent,
    ConsumptionsSettingsComponent,
    MeterTriggersComponent,
    CustomerVendsComponent,
    CustomerStatsComponent,
        DcuCardsComponent,
        IdentityLoginComponent,
        GraphAnalysisComponent,
    TokenLoadingComponent,
    MeterTriggersPageComponent,
    MeterTriggersGrid,
    NoConsumptionCustomersComponent,
    FeederReadsSettingsComponent,
    DtReadsSettingsComponent,
    CustomersWithoutDtComponent,
    SystemHierarchyComponent,
    HierarchyItemComponent,
    DeviceManagementComponent,
    DeviceManagementRemoteDisconnectedComponent,
    UnmeteredComponent,
    AlarmEventMgtComponent,
    LoadProfileComponent,
    AlarmEventMgtComponent,
    MultipleAmisPopUpComponent,
    RemoteDisconnectedDetailsComponent,
    DayHoursTimePipe,
    MeterModelTabComponent,
    CreateMeterModelComponent,
    RemoteTokenLoadComponent,
    MeterTariffHistoryComponent,
    DeviceParametersComponent,
    NonCommunicatingF33Component,
    PaF33SliderNewComponent,
    IdleLogoutPopupComponent,
    ImageCropperDialogComponent,
    InvalidTfeReadsComponent,

    RemoteDisconnectionActionComponent,
    AssetF33MetersComponent,
    AssetMeterComponent,
    AssetF11MetersComponent,
    AssetDtMetersComponent,
    GepTsNewComponent,
    TsPopupComponent,
    PlannedOutageComponent,
    UploadPlannedOutagePopupComponent,
    PlannedOutageTableComponent,
    PlannedOutageCreateNewComponent,
    DatetimePickerComponent,
    PlannedOutageUpdateComponent,
    StaffOperatorTabComponent,
    UnitTabComponent,
    SubunitTabComponent,
    DepartmentTabComponent,
    CreateStaffOperatorComponent,
    CreateUnitComponent,
    CreateSubunitComponent,
    CreateDepartmentComponent,
    StaffOperatorCrudPagesComponent,
    ScrollableAutocompleteFilterComponent,
    ChangeDatePopupComponent,
  ],
  imports: [
    BrowserModule,
    GoogleMapsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    AutocompleteLibModule,
    MatDialogModule,
    InfiniteScrollModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule.forRoot({ showForeground: false }),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: false,
      exclude: [environment["pathApi"] + "paymentCollection/getPaymentCollectionCount",
      environment["pathApi"] + "AllPayments/getPaymentsCount",
      environment["pathApi"] + "paymentCollection/getCumulativePayments",
      ]
    }),
    ToastrModule.forRoot(),
    NgbModule,
    NgbTooltipModule,
    MatTabsModule,
    NgChartsModule,
    MatSidenavModule,
    NgIdleModule.forRoot(),
    ImageCropperModule,
  ],
  providers: [
    AppConfig,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    DatePipe,
    TwoDecimalsPipe,
    ShortDatetimePipe,
    DayHoursTimePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      })
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
