import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { FieldAgentsService } from 'src/app/services/field-agents.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-agent-management',
  templateUrl: './agent-management.component.html',
  styleUrls: ['./agent-management.component.scss']
})
export class AgentManagementComponent implements OnChanges {

  public isCreateUserOpen: boolean = false;
  public title: string = 'Create agent'
  public btnTitle: string = 'Create'
  public isPopupOpen: boolean = false

  filteredUnassignedFieldAgents: any[] = [];
  unassignedFieldAgents: any[] = [];

  fieldAgents: any[] = [];
  @Input() unengagedFieldAgents: any[] = [];
  @Input() inactiveFieldAgents: any[] = [];
  @Input() engagedFieldAgents: any[] = [];
  @Input() tab: number = 1;

  currentTab: any[] = [];

  selectedfieldAgent: any = {};
  menuTab: number = 1;

  teamId: number = 0;
  ids: any[] = [];
  checkedIds: any[] = [];
  public buId: number = 0;
  @Input() businessUnits: any;
  public buIds: any = [];

  public searchText: string = "";
  currentPage: number = 1;
  pageSize: number = 10;

  count: number = 0;
  unassignedCount: number = 0;

  isFirstBlockVisible = false;
  isSecondBlockVisible = false;
  isThirdBlockVisible = false;
  isFourthBlockVisible: boolean = false;

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');

  @Output() dataEmitter = new EventEmitter<number>();

  agentsCount: number = 0;
  unengagedAgentsCount = 0;
  engagedAgentsCount = 0;
  inactiveAgentsCount = 0;

  public masterSelected: boolean = false;
  public masterInactiveSelected: boolean = false;

  public checkedList: any = []
  public checkedInactiveList: any = []

  activeTab: 'Agents' | 'Teams' = 'Agents';

  selectAll: boolean = false;
  email: string = '';
  public showFilter: any = false;
  @Output() parentComp = new EventEmitter<number>();

  constructor(private el: ElementRef,
    private toastr: ToastrService,
    private translationService: TranslationService,
    public userService: UserService,
    public businessUnitService: BusinessUnitService,
    private router: Router,
    private fieldAgentService: FieldAgentsService,
    private detectionChange: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.tab === 1){
      this.isAlreadyChecked(this.unengagedFieldAgents);
    }
    else if(this.tab === 2){
      this.isAlreadyChecked(this.engagedFieldAgents);
    }
    else {
      this.isAlreadyChecked(this.inactiveFieldAgents);
    }
  }

  openDetails(id: number) {
    this.router.navigate(['/field-operations/agent-details', id]);
  }
  inputChanged(searchText: string) {
    this.searchText = searchText
  }

  setFilters(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);
      const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
      if (item.name === this.businessUnits.name) {
        this.buIds = filteredValues
      }
    }
  }
  deactivate() {
    let obj = {
      Ids: this.checkedList,
      activate: false
    };

    this.fieldAgentService.activateFieldAgent(obj).subscribe(response => {
      if (response.status === 200) {
        this.selectedfieldAgent = response.data
        const value = this.tab
        this.parentComp.emit(value)
        this.toastr.success(response.message);
        this.checkedList = [];
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  activate() {
    let obj = {
      Ids: this.checkedList,
      activate: true
    };
    this.fieldAgentService.activateFieldAgent(obj).subscribe(response => {
      if (response.status === 200) {
        this.selectedfieldAgent = response.data
        const value = this.tab
        this.parentComp.emit(value)
        this.toastr.success(response.message);
        this.checkedList = [];
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  activateUser() {
    const value = this.selectedfieldAgent.id;
    const list: number[] = [value];

    let obj = {
      Ids: list,
      activate: true
    };
    this.fieldAgentService.activateFieldAgent(obj).subscribe(response => {
      if (response.status === 200) {
        this.selectedfieldAgent = response.data
        this.parentComp.emit();
        this.toastr.success(response.message);
      }
      else {
        this.toastr.error(response.message);

      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  deactivateUser() {
    const value = this.selectedfieldAgent.id;
    const list: number[] = [value];

    let obj = {
      Ids: list,
      activate: false
    };
    this.fieldAgentService.activateFieldAgent(obj).subscribe(response => {
      if (response.status === 200) {
        this.selectedfieldAgent = response.data
        const value = this.tab
        this.parentComp.emit(value);
        this.toastr.success(response.message);
      }
      else {
        this.toastr.error(response.message);

      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  checkUncheckAll(list: any) {
    for (let item of list) {
      item.checked = this.masterSelected;
      this.checkForDownload(list, item.id, true);
    }

  }

  checkForDownload(list: any, id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked(list);
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked(list: any) {
    list.forEach((x: { id: any; checked: boolean; }) => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked(list);
  }

  isMasterChecked(list: any) {
    this.masterSelected = list.every(function(item:any) {
      return item.checked;
    })
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }
}
