import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleGuardClass  {
  constructor(private authService: AuthService,
    private router: Router, private toastr: ToastrService) {  }

    
  canActivate(route: ActivatedRouteSnapshot) {
    if (!this.authService.isLoggedIn()) 
    {
      this.router.navigate(['']);
      return false;
    }

    if(this.authService.isPasswordTemp()){
      this.router.navigate(['password-new-user']);
      return false;
    }

    let moduleString = route.data['module'];

    if(this.authService.isGroupAuthorized(moduleString))
    {   
      return true;
    }
    else
    {
      this.toastr.error("You don't have access to the this module.");
      this.router.navigate(['']);
      return false;
    }    
  }
}

export const ModuleGuard: CanActivateFn = (next: ActivatedRouteSnapshot): boolean => {
  return inject(ModuleGuardClass).canActivate(next);
}
