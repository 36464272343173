<div class="search-container"
  [style.background-color]="backgroundColor"
  [style.border]="'1px solid ' + borderColor"
  [style.margin-left]="marginLeft">
  <svg (click)="onEnter()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M18.0918 16.9083L15.0002 13.8417C16.2002 12.3453 16.7814 10.4461 16.6242 8.53441C16.4669 6.62275 15.5832 4.84398 14.1548 3.56387C12.7263 2.28375 10.8617 1.59959 8.94427 1.65206C7.02686 1.70452 5.20243 2.48963 3.84612 3.84595C2.4898 5.20227 1.70469 7.0267 1.65222 8.9441C1.59976 10.8615 2.28392 12.7261 3.56403 14.1546C4.84415 15.5831 6.62292 16.4668 8.53458 16.624C10.4462 16.7812 12.3455 16.2001 13.8418 15L16.9085 18.0667C16.986 18.1448 17.0781 18.2068 17.1797 18.2491C17.2812 18.2914 17.3902 18.3132 17.5002 18.3132C17.6102 18.3132 17.7191 18.2914 17.8206 18.2491C17.9222 18.2068 18.0144 18.1448 18.0918 18.0667C18.242 17.9113 18.326 17.7036 18.326 17.4875C18.326 17.2714 18.242 17.0637 18.0918 16.9083ZM9.16683 15C8.01311 15 6.88529 14.6579 5.926 14.0169C4.96672 13.3759 4.21904 12.4649 3.77753 11.399C3.33602 10.3331 3.2205 9.16019 3.44558 8.02864C3.67066 6.89708 4.22624 5.85768 5.04204 5.04187C5.85785 4.22607 6.89725 3.6705 8.0288 3.44542C9.16036 3.22034 10.3332 3.33585 11.3992 3.77737C12.4651 4.21888 13.3761 4.96655 14.0171 5.92584C14.658 6.88512 15.0002 8.01294 15.0002 9.16666C15.0002 10.7138 14.3856 12.1975 13.2916 13.2915C12.1977 14.3854 10.7139 15 9.16683 15Z" fill="#878787"/>
    </svg>
  <input #searchInput class="search-input" type="text" name="searchText"
   [(ngModel)]="searchText" 
   [style.width.rem]="width" (keyup.enter)="onEnter()"
   [style.background-color]="backgroundColor"
   placeholder="{{placeholder}}"
   (ngModelChange)="onChange()">
</div>
