<main class="container-fluid container-crud">
    <div class="data-table">
        <div class="reads-title">
            Manual Readings Upload
        </div>
        <div class="row table-row table-pom">
            <div class="col-sm-12 col-pom">
                <div class="col-sm-6 col-pom2">
                    <div class="crud-title">
                        TFE Reads for 33KV Feeders :
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class=" col-sm-3 action-buttons" *ngIf="authService.isAuthorized(PermissionsEnum.networkmanagement_reports_createupdate)">    
                            <button class="btn btn-pom" (click)="chooseFile.click()">Upload File</button>
                    </div>
                    <div class=" col-sm-3 action-buttons">  
                            <button class="btn" (click)='getTemplate()'>Download Template</button>
                    </div>
                        <input #chooseFile type="file" accept=".xlsx" style="display: none;" (change)="onFileChosen($event);uploadFile();" [(ngModel)]="fileChosen">
                </div>
            </div>
        </div>
        <div class="row table-row table-pom">
            <div class="col-sm-12 col-pom">
                <div class="col-sm-6 col-pom2">
                    <div class="crud-title">
                            Daily Consumption For Feeder 33 KV :
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class=" col-sm-3 action-buttons">    
                            <button class="btn btn-pom" (click)="chooseFileDailyConsumption.click()"  *ngIf="authService.isAuthorized(PermissionsEnum.networkmanagement_reports_createupdate)">Upload File</button>
                    </div>
                    <div class=" col-sm-3 action-buttons">  
                            <button class="btn" (click)='getTemplateDailyConsupmtion()'>Download Template</button>
                    </div>
                        <input #chooseFileDailyConsumption type="file" accept=".xlsx" style="display: none;" (change)="onFileChosen($event);uploadFileDailyConsumption();" [(ngModel)]="fileChosen">
                </div>
            </div>
        </div>
    </div>
</main>
     
