import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { SearchAutocomplete } from '../../../models/searchAutocomplete.model';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-gep-ut-new',
  templateUrl: './gep-ut-new.component.html',
  styleUrls: ['./gep-ut-new.component.scss', '../grid-energy-profile-new.component.scss']
})
export class GepUtNewComponent implements OnChanges {

  public count: number = 0
  public pageSize: number = 8
  public currentPage: number = 1

  public commStatus: number = 0
  public commStatusOpened: boolean = false
  public readType: number = 0
  public readTypeOpened: boolean = false
  public masterSelected: boolean = false;

  public checkedList: number[] = [];
  public data: any = [];

  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase()
  public utName: string = this.translationService.getByKeyFromCache('UT').toUpperCase()
  @Input() buIds: number[] = []
  @Input() utIds: number[] = []

  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''
  @Input() search: SearchAutocomplete = new SearchAutocomplete()

  constructor(
    private _utService: UndertakingService,
    private toastr: ToastrService,
    private translationService:TranslationService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false
    if (changes.buIds != null) {
      reloadTable = true
    }
    if (changes.utIds != null) {
      reloadTable = true
    }
    if (changes.dateFrom != null || changes.dateTo != null) {
      reloadTable = true
    }
    if (this.search?.name == '')
      this.search = changes.search?.previousValue

    if (this.search != null) {
      reloadTable = true
    }

    if (reloadTable) {
      if (this.dateFrom == '' || this.dateTo == '')
        this.setMainDate()

      this.checkedList = [];
      this.resetPagination()
      this.GetData()
    }

  }

  private resetPagination() {
    this.currentPage = 1
  }

  private setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()

    dteFrom.setHours(2, 0, 0);
    dte.setHours(2, 0, 0);
    dteFrom.setDate(dteFrom.getDate() - 1)

    this.dateFrom = { year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate() };
    this.dateTo = { year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate() };
  }

  pageChange(value: number) {
    this.currentPage = value
    this.GetData()
  }

  public GetData() {
    let obj = this.getSearchParameters();

    this.data = [];

    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.fetchMonthlyUts(obj)
    }
    else {
      this.fetchDailyUts(obj);
    }
  }

  fetchDailyUts(obj: any) {
    this._utService.getAllDailyUTs(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data;
        this.isAlreadyChecked();
        if (obj.pageInfo.page == 1)
          this.count = response.data.count;
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  fetchMonthlyUts(obj: any) {
    this._utService.getAllMonthlyUTs(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data;
        this.isAlreadyChecked();
        if (obj.pageInfo.page == 1)
          this.count = response.data.count;
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getSearchParameters() {
    return {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        SubstationId: 0,
        FeederId: 0,
        BuIds: this.buIds,
        UtIds: this.utIds,
        DtType: 0,
        CommStatus: this.commStatus,
        ReadType: this.readType,
        Search: this.search ? this.search.name : this.search
      }
    };
  }

  public commStatusClicked(open: number) {
    if (open == 0)
      this.commStatusOpened = false
    else {
      if (this.commStatusOpened) {
        this.commStatusOpened = false
      }
      else {
        this.commStatusOpened = true
      }

    }
  }

  public readTypeClicked(open: number) {
    if (open == 0)
      this.readTypeOpened = false
    else {
      if (this.readTypeOpened) {
        this.readTypeOpened = false
      }
      else {
        this.readTypeOpened = true
      }

    }
  }

  public commStatusSelected(value: number) {
    this.commStatus = value
    this.GetData()
  }

  public readTypeSelected(value: number) {
    this.readType = value
    this.GetData()
  }

  public download() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      SubstationId: 0,
      FeederId: 0,
      BuIds: this.buIds,
      DtType: 0,
      CommStatus: this.commStatus,
      ReadType: this.readType,
      Search: this.search ? this.search.name : this.search,
      UtIds: this.utIds,
      CheckedList: this.checkedList
    };

    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.downloadMonthlyReport(obj)
    }
    else {
      this.downloadDailyReport(obj)
    }
  }

  private downloadMonthlyReport(obj: any) {
    this._utService.downloadMonthlyUTsReport(obj).subscribe(response => {
      window.location.href = response.data;
    }, _ => {
      this.toastr.error('An error occurred.');
    })
    this.checkedList = [];
  }

  private downloadDailyReport(obj: any) {
    this._utService.downloadDailyUTsReport(obj).subscribe(response => {
      window.location.href = response.data;
    }, _ => {
      this.toastr.error('An error occurred.');
    })
    this.checkedList = [];
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
      this.checkForDownload(this.data[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.data.forEach((x: { id: number; checked: boolean; }) => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item:any) {
      return item.checked == true;
    })
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }
}
