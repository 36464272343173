
<main class="container-fluid">
<div class="user-profile">
    <div class="user-profile-content">
        <div class="user-profile-main">
            <ng-template [ngIf]="mainForm">

                <div class="user-container">
                    <span class="user-name">{{(loggedInUser.firstName ?? '/')+ ' ' + (loggedInUser.lastName ?? '/')}}</span>
                    <div class="profile-button-wrapper">
                        <div class="profile">
                            <div *ngIf="loggedInUser.image; else defaultImage"> <img class="image"
                                    src="{{ loggedInUser.image }}" alt="Slika korisnika"></div>
                            <ng-template #defaultImage>
                                <div class="image" >{{initial}} </div>
                            </ng-template>

                        </div>
                        <div class="button">
                            <button class="red-btn" (click)="onClick(2)">Change Picture</button>
                            <button class="btn-dlt" (click)="deletePicture()">Delete Picture</button>
                        </div>
                    </div>
                    <div class="text-update col-sm-12">
                        <a class="click-link" (click)="onClick(1)">Update password</a>
                    </div>
                    <form class="user-form" (submit)="f.form.valid " #f="ngForm">

                        <div class="form">
                            <div class="">
                                <div  #input class="input">First Name</div>
                                <input class="form-control " placeholder="{{loggedInUser.firstName}}" name="firstName"
                                    disabled [(ngModel)]="user.firstName">
                            </div>
                            <div class="">
                                <div class="input">Last Name</div>
                                <input disabled class="form-control" placeholder="{{loggedInUser.lastName}}"
                                    name="lastName" [(ngModel)]="user.lastName" />
                            </div>
                        </div>
                        <div></div>

                        <div class="form">
                            <div class="form-email ">
                                <div class="input-email">Email</div>
                                <input disabled class="form-control-email" placeholder="{{loggedInUser.email}}"
                                    name="email" [(ngModel)]="user.email" />
                            </div>

                        </div>


                        <div class="form2">
                            <div class="">
                                <div class="input">Organization</div>
                                <input class="form-control" name="organisation"
                                    disabled placeholder="{{loggedInUser.organisation}}" [(ngModel)]="user.organisation" />
                            </div>

                            <div>
                                <div class="input" >User's Designation
                                </div>
                                <input  placeholder="{{loggedInUser.designationName}}" disabled class="form-control" name="usersDesignation"
                                    [(ngModel)]="user.designationName" />
                            </div>

                        </div>
                        <div class="input-role">
                                <div class="input">
                                    <div class="input">User's Role</div>
                                    <input class="form-control" placeholder="{{loggedInUser.roleName}}"
                                        name="email" [(ngModel)]="user.email" />
                                </div>

                            </div>
                            <div class="user-btn">
                                <button class="cancel-btn col-xs-6" (click)="onClick(3)">Cancel</button>
                                <button class="submit col-xs-6" (click)="save();">Save</button>
                            </div>
                    </form>
                </div>
            </ng-template>

            <ng-template [ngIf]="updateProfile">
                <div class="user-container">
                    <div class="password-header">
                        <div class="password-title"> Update password</div>

                    </div>
                    <div class="password-body">
                        <form (submit)="f.form.valid " #f="ngForm">
                            <div class=" password-form">
                                <div class="col-sm-12 input-fields">
                                    <input class="pass-input" [type]="inputType" placeholder="Current password"
                                        class="form-pass" name="password" [(ngModel)]="user.oldPassword" required
                                        autocomplete="off" />
                                    <button class="input-button" type="button">
                                        <img alt="Show password" class="eye" [src]="eyeImage"
                                            (click)="changeEye1()">
                                    </button>
                                    <div *ngIf="f.submitted && password.invalid" class="form-control-error">
                                        Password is required</div>
                                </div>
                                <div class="col-sm-12 input-fields">
                                    <input class="pass-input" [type]="inputType2" placeholder="Set new password"
                                        class="form-pass" name="password" [(ngModel)]="newPassword" #password="ngModel"
                                        required autocomplete="off" />
                                    <button class="input-button" type="button">
                                        <img alt="Show new password" class="eye" [src]="eyeImage2"
                                            (click)="changeEye2()">
                                    </button>
                                    <div *ngIf="f.submitted && password.invalid" class="form-control-error">
                                        Password is required</div>
                                </div>
                                <div class="col-sm-12 input-fields">
                                    <input class="pass-input" [type]="inputType3" class="form-pass" name="password"
                                        [(ngModel)]="confirmNewPassword" #password="ngModel"
                                        placeholder="Confirm new password" autocomplete="off" />
                                    <button class="input-button" type="button">
                                        <img alt="Show confirm" class="eye" [src]="eyeImage3"
                                            (click)="changeEye3()">
                                    </button>
                                </div>
                                <div class="button-form">
                                    <button class="cancel-btn col-xs-6" (click)="onClick(3)">Cancel</button>
                                    <button class="submit col-xs-6" (click)="save('changePassword'); ">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ng-template>

            <ng-template [ngIf]="userForm">
                <div class="user-container">
                    <div class="password-header">
                        <div class="password-title"> Update profile</div>

                    </div>
                    <div class="upload-root">
                        <form *ngIf="uploadFile == null" ngNativeValidate class="form-wrapper-upload clickable" enctype="multipart/form-data"
                            id="usersForm">
                            <div class="drag-drop" appDragAndDrop  >
                                <input type="file" id="fileDropRef" name="userImage" #inputField (click)="inputField.value = ''"
                                    (change)="savePic($event)" />
                                <div class="upload-img">
                                    <img src="assets/images-new-design/importFile.png" />
                                </div>
                                <label for="fileDropRef">Drag and drop file here or <span class="browse">Browse</span>
                                    to
                                    upload</label>
                            </div>
                        </form>
                        <img class="preview" *ngIf="imageSource() && croppedImageFlag" [src]="imageSource()">
                    </div>

                    <div class="button-form">
                        <button class="submit col-xs-6" (click)="save('image');">Save</button>
                        <button class="cancel-btn col-xs-6" (click)="onClick(3)">Cancel</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
</main>
