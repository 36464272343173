import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-outage-duration-filter',
  templateUrl: './outage-duration-filter.component.html',
  styleUrls: ['./outage-duration-filter.component.scss']
})
export class OutageDurationFilterComponent {

  @Input() durationFrom: number = 0
  @Input() durationTo?: number = undefined
  @Output() durationChange = new EventEmitter();

  public filterByDurationClicked: boolean = false;
  public isDropdownOpened: boolean = false;

  public durationChanged(durationFrom:number, durationTo?: number){
    let durationChange = {
      durationFrom: durationFrom,
      durationTo: durationTo
    }
    this.durationChange.emit(durationChange);
  }
  public filterClick(){
    console.log(this.filterByDurationClicked)
    this.isDropdownOpened=false;
    this.filterByDurationClicked = !this.filterByDurationClicked
  }

  public close(){
    if(!this.isDropdownOpened){
      this.filterByDurationClicked = false;
    }
    else{
      this.isDropdownOpened=false;
    }
  }


}
