 <div tabindex="1" class="duration-container" id="outage-duration" (focusout)="close()" >
        <div class="duration-inner-container" (click)="filterClick()" #durationInner id="duration-inner" [ngClass]="{'pressed': filterByDurationClicked}" click-stop-propagation>
            <span class="text">Outage Duration</span>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="10.536" height="6.506" viewBox="0 0 10.536 6.506">
                    <path id="ic_keyboard_arrow_left_24px" d="M6.506,1.238,2.485,5.268,6.506,9.3,5.268,10.536,0,5.268,5.268,0Z" transform="translate(0 6.506) rotate(-90)" fill="#000829"/>
                </svg>
            </span>
        </div>
        <div class="duration-dropbox-expanded" *ngIf="filterByDurationClicked">
            <div class="duration-dropbox-item">
                <span class="date-span">From </span>
                <span><input id="durationFrom" type="number" min="0" [(ngModel)]="durationFrom" (mousedown)="isDropdownOpened=true"/></span>
                <span class="h-span">h</span>
            </div>
            <div class="duration-dropbox-item">
                <span class="date-span">To </span>
                <span><input id="durationTo" type="number" min="1" [(ngModel)]="durationTo" (mousedown)="isDropdownOpened=true" /></span>
                <span class="h-span">h</span>
            </div>
            <div class="datepicker-button" (mousedown)="isDropdownOpened=true" (click)="durationChanged(durationFrom,durationTo); filterClick()">
                <span>Apply</span>
            </div>
        </div>
    </div>
