<div class="popup-wrapper">
    <div class="header">
        <div class="header-back" (click)="back()">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.52116 7.83342H12.8337C13.0698 7.83342 13.2677 7.75356 13.4274 7.59384C13.5871 7.43412 13.667 7.2362 13.667 7.00009C13.667 6.76398 13.5871 6.56606 13.4274 6.40634C13.2677 6.24662 13.0698 6.16676 12.8337 6.16676H3.52116L7.60449 2.08342C7.77116 1.91676 7.85102 1.72231 7.84408 1.50009C7.83713 1.27787 7.75033 1.08342 7.58366 0.916758C7.41699 0.76398 7.22255 0.684119 7.00033 0.677174C6.7781 0.67023 6.58366 0.750091 6.41699 0.916758L0.916992 6.41676C0.833659 6.50009 0.774632 6.59037 0.739909 6.68759C0.705187 6.78481 0.687826 6.88898 0.687826 7.00009C0.687826 7.1112 0.705187 7.21537 0.739909 7.31259C0.774632 7.40981 0.833659 7.50009 0.916992 7.58342L6.41699 13.0834C6.56977 13.2362 6.76074 13.3126 6.98991 13.3126C7.21908 13.3126 7.41699 13.2362 7.58366 13.0834C7.75033 12.9168 7.83366 12.7188 7.83366 12.4897C7.83366 12.2605 7.75033 12.0626 7.58366 11.8959L3.52116 7.83342Z"
                    fill="#1C1B1F" />
            </svg>
        </div>
        <div class="header-title">
            <span>Issue item</span>
        </div>
        <div class="add-wrapper">
            <label for="submitForm" tabindex="0" class="add-agent">Issue item</label>
        </div>
    </div>
    
    <div class="popup-content">
    
        <div class="create-form">
            <form class="form" (submit)="f.form.valid && issue()" #f="ngForm">
                <div class="col-sm-12 crud-input-container">
                    <label class="labelPopup">Item Name</label>
                    <app-simple-dropdown 
                            [header]="selectedItem.name"
                            [dropdownHeader]="'Items'"
                            [data]="items"
                            (selectItemChange)="selectItem($event)"
                            [selectedItemId]="selectedItem.value"
                    ></app-simple-dropdown>
                    <div *ngIf="f.submitted && selectedItem.value <= 0" class="form-control-error">Item is required</div>
                </div>

                <div class="col-sm-12 input crud-input-container">
                    <label class="labelPopup">Item Quantity</label>
                    <input type="number" class="form-control" name="issuedQuantity" [(ngModel)]="stockItem.issuedQuantity"  #issuedQuantity="ngModel"
                        required autocomplete="off" />
                    <div *ngIf="f.submitted && issuedQuantity.value <= 0" class="form-control-error">Quantity is required and and must be positive value</div>
                </div>

                <div class="col-sm-12 crud-input-container">
                    <label class="labelPopup">Select Task</label>
                    <app-simple-dropdown 
                            [header]="selectedTask.name"
                            [dropdownHeader]="'Tasks'"
                            [data]="tasks"
                            (selectItemChange)="selectTask($event)"
                            [selectedItemId]="selectedTask.value"
                    ></app-simple-dropdown>
                    <div *ngIf="f.submitted && selectedTask.value <= 0" class="form-control-error">Task is required</div>
                </div>
                
                <button type="submit" id="submitForm" [hidden]="true"></button>
            </form>
        </div>
    
    </div>
</div>