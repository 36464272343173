import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';

@Component({
  selector: 'app-f33-outage-popup',
  templateUrl: './f33-outage-popup.component.html',
  styleUrls: ['./f33-outage-popup.component.scss']
})
export class F33OutagePopupComponent {
  public isPopupOpen: boolean = false;
  public selectedF33: any = {};
  public dts: any = [];
  public feeder11:any=[];
  public data: any = null;
  public currentPage: number = 1;
  public count: number = 0;
  public pageSize: number = 8;
  public lastPageSize: number = 1;
  public dataFeeder: any = {};
  date: string = '';
  public feederTopology:string ="feeder33";

  public tabMenu: number = 1;
  constructor( private dtService: DtService,
    private f11Service: Feeder11Service,
    private toastr: ToastrService) { }

  dateFormat() {
    const date = new Date(this.selectedF33.outageStart);
    const all = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"];
    const month = all[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    this.date = `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds}`;
    }

  getF33Info() {
    this.dtService.countOfDTsConnectedToF33(this.selectedF33.feederId).subscribe(response => {
      this.dataFeeder = response.data;
      this.dataFeeder.info = this.selectedF33;
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }
  tabChanged(value: number) {
    this.tabMenu = value
  }
  close() {
    this.isPopupOpen = false;
  }

  public isPlanned(plan: string) {
    if (plan === 'Planned') {
      return true;
    }
    else return false;
  }
  private getDtsByF33() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        Feeder33Id: this.selectedF33.feederId
      }
    };
    this.dtService.getAllDtsByF33IdWithPagination(obj).subscribe(response => {
      this.dts = response.data.data;
      console.log(this.dts)
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }, _ => {
      this.toastr.error('An error occurred.');
    })


  }

  back() {
    this.tabChanged(2)
  }

  pageChangeAccounts(value: any) {
    this.currentPage = value;
    this.getDtsByF33();
  }

  changeState(data?: any, menu?: any) {
    if (data)
      this.selectedF33 = data;
    this.dateFormat()
    this.getDtsByF33()
    this.getF33Info()
    if (menu === 1){
      this.isPopupOpen=true;
      this.tabMenu=1;
     }
    else
      this.isPopupOpen = false;

  }
}
