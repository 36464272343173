<main class="container-fluid">
    <div class="container-wrapper">
        <div class="commercial-title">
            <div (click)="back()" class="back-button">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_7669_8469" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20"
                        height="20">
                        <rect width="20" height="20" transform="matrix(-1 0 0 1 20 0)" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_7669_8469)">
                        <path
                            d="M6.52067 10.8334H15.8332C16.0693 10.8334 16.2672 10.7536 16.4269 10.5938C16.5866 10.4341 16.6665 10.2362 16.6665 10.0001C16.6665 9.76398 16.5866 9.56606 16.4269 9.40634C16.2672 9.24662 16.0693 9.16676 15.8332 9.16676H6.52067L10.604 5.08342C10.7707 4.91676 10.8505 4.72231 10.8436 4.50009C10.8366 4.27787 10.7498 4.08342 10.5832 3.91676C10.4165 3.76398 10.2221 3.68412 9.99984 3.67717C9.77762 3.67023 9.58317 3.75009 9.4165 3.91676L3.9165 9.41676C3.83317 9.50009 3.77414 9.59037 3.73942 9.68759C3.7047 9.78481 3.68734 9.88898 3.68734 10.0001C3.68734 10.1112 3.7047 10.2154 3.73942 10.3126C3.77414 10.4098 3.83317 10.5001 3.9165 10.5834L9.4165 16.0834C9.56928 16.2362 9.76025 16.3126 9.98942 16.3126C10.2186 16.3126 10.4165 16.2362 10.5832 16.0834C10.7498 15.9168 10.8332 15.7188 10.8332 15.4897C10.8332 15.2605 10.7498 15.0626 10.5832 14.8959L6.52067 10.8334Z"
                            fill="#1C1B1F" />
                    </g>
                </svg>
            </div>
            <span class="commercial-page-title">Account Details</span>
        </div>
        <app-tab-default-second [secondText]="'Reads'" [firstText]="'Basic Information'" [thirdText]="'Events'"
            [forthText]="'Bills'" (tabChanged)="tabChanged($event)" [marginLeft]="'3.2rem'" [marginBottom]="'1.5rem'"
            [marginTop]="'1.5rem'">
        </app-tab-default-second>
        <div *ngIf="tabNumber == 1">
            <div class="tab-wrapper">
                <div class="details-row-wrapper">
                    <div class="details-row-wrapper">
                        <div class="row-title">
                            <span>Name</span>
                        </div>
                        <div class="row-data">
                            <span>{{account.firstName | hasValue}} {{account.lastName | hasValue}}</span>
                        </div>
                    </div>
                </div>

                <div class="tab-body-row">
                    <div class="details-row-wrapper">
                        <div class="row-title">
                            <span>Address</span>
                        </div>
                        <div class="row-data">
                            <span>{{account.street | hasValue}}</span>
                        </div>
                    </div>
                </div>

                <div class="tab-body-row">
                    <div class="details-row-wrapper">
                        <div class="row-title">

                            <span>Account No</span>
                        </div>
                        <div class="row-data">
                            <span>{{account.accountNo | hasValue}}</span>
                        </div>
                    </div>
                </div>

                <div class="tab-body-row">
                    <div class="details-row-wrapper">
                        <div class="row-title">

                            <span>Meter No</span>
                        </div>
                        <div class="row-data">
                            <span>{{account.msn | hasValue}}</span>
                        </div>
                    </div>
                </div>

                <div class="tab-body-row">
                    <div class="details-row-wrapper">
                        <div class="row-title">

                            <span>Last seen:</span>
                        </div>
                        <div class="row-data"> 
                            <span *ngIf="account.lastSeen !== ''" title="{{account.lastSeen}}">{{account.lastSeen |
                                hasValue}}</span>
                            <span *ngIf="account.lastSeen === ''" class="NaN">N/A</span>
                        </div>
                    </div>
                </div>

                <div class="tab-body-row">
                    <div class="details-row-wrapper">
                        <div class="row-title">

                            <span>Total</span>
                        </div>
                        <div class="row-data">   
                             <span *ngIf="account.total !== 0" title="{{account.total}}">{{account.total |
                                hasValue}}</span>
                            <span *ngIf="account.total === 0" class="NaN">N/A</span>
                        </div>
                    </div>
                </div>

                <div class="tab-body-row">
                    <div class="details-row-wrapper">
                        <div class="row-title">

                            <span>Consumption</span>
                        </div>
                        <div class="row-data"> 
                            <span *ngIf="account.consumption !== '/'" title="{{account.consumption}}">{{account.consumption |
                                hasValue}}</span>
                            <span *ngIf="account.consumption === '/'" class="NaN">N/A</span>
                        </div>
                    </div>
                </div>

                <div class="tab-body-row">
                    <div class="details-row-wrapper">
                        <div class="row-title">

                            <span>Tariff</span>
                        </div>
                        <div class="row-data">
                            <span>{{account.tariffName | hasValue}}</span>
                        </div>
                    </div>
                </div>

                <div class="tab-body-row">
                    <div class="details-row-wrapper">
                        <div class="row-title">

                            <span>Ami</span>
                        </div>
                        <div class="row-data">
                            <span>{{account.amiName | hasValue}}</span>
                        </div>
                    </div>
                </div>

                <div class="tab-body-row">
                    <div class="details-row-wrapper">
                        <div class="row-title">

                            <span>Organization</span>
                        </div>
                        <div class="row-data">
                            <span>{{account.buName | hasValue}} > {{account.utName | hasValue}} > {{account.dtName |
                                hasValue}}</span>
                        </div>
                    </div>
                </div>

                <div class="tab-body-row">
                    <div class="details-row-wrapper">
                        <div class="row-title">

                            <span>Network</span>
                        </div>
                        <div class="row-data">
                            <span>{{account.substation | hasValue}} > {{account.feeder | hasValue}} > {{account.dtName |
                                hasValue}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="tabNumber==2">
            <app-account-reads-new #accountReads [accountId]="account.id"></app-account-reads-new>
        </div>
    </div>
</main>