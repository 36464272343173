import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Undertaking } from 'src/app/models/undertaking.model';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';

@Component({
  selector: 'app-area-create',
  templateUrl: './area-create.component.html',
  styleUrls: ['./area-create.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class AreaCreateComponent {
  public selectedUT: Undertaking = new Undertaking();
  showShadow = false;
  public PermissionsEnum = PermissionsEnum;
  @ViewChild("f") f: any;
  @Output() parentComp = new EventEmitter();
  @Input() businessUnits: any = [];

  public utName :string = this.translationService.getByKeyFromCache('UT');
  public buName :string = this.translationService.getByKeyFromCache('BU');
  @Input() animationState = 'out';
  @ViewChild(AreaCreateComponent) popupComponentRef!: AreaCreateComponent;
  constructor(public authService: AuthService,
    public toastr: ToastrService,
    public utService: UndertakingService,
    public buService: BusinessUnitService,
    public translationService: TranslationService,
    public ref: ElementRef) { }

  resetSelectedUT(){
    this.selectedUT = new Undertaking();

    if(this.f)
      this.f.submitted = false;
  }
  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.area-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }
  changeState(number: Number, data?: any) {
    if (data)
      this.selectedUT = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
  }


  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }
  save(){
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }
    if(!this.selectedUT?.latitude.trim()){
      this.toastr.error('Latitude value cannot be whitespace.');
      return;
    }

    if(!this.selectedUT?.longitude.trim()){
      this.toastr.error('Longitude value cannot be whitespace.');
      return;
    }

    if(!this.selectedUT?.name.trim()){
      this.toastr.error('Name value cannot be whitespace.');
      return;
    }

    if(Number.isNaN(Number(this.selectedUT?.latitude))){
      this.toastr.error('Latitude must be numeric value between -90 and 90.');
      return;
    }

    if(Number(this.selectedUT?.latitude) < -90 || Number(this.selectedUT?.latitude) > 90){
      this.toastr.error('Latitude must be in -90 to 90 range.');
      return;
    }

    if(Number.isNaN(Number(this.selectedUT?.longitude))){
      this.toastr.error('Longitude must be numeric value between -180 and 180.');
      return;
    }

    if(Number(this.selectedUT?.longitude) < -180 || Number(this.selectedUT?.longitude) > 180){
      this.toastr.error('Longitude must be in -180 to 180 range.');
      return;
    }

    if(this.selectedUT?.buId === 0){
      this.toastr.error('BU must be provided.');
      return;
    }

    console.log(this.selectedUT)
    if((this.selectedUT.name && this.selectedUT.latitude && this.selectedUT.longitude && this.selectedUT.buId) != ''){
      this.utService.save(this.selectedUT).subscribe(response => {
        if(response.status === 200){
          this.toastr.success(response.message);
          this.changeState(2);
          this.resetSelectedUT();
          this.parentComp.emit();
         }else
          this.toastr.error(response.message);
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }

  }
}
