<div class="popup-overlay" *ngIf="isPopupOpen" (click)="changeState()">
  <div class="popup-content" click-stop-propagation>
      <div class="close-content"  (click)="closePopup()">
          <div class="upload-title">
              <span >Upload</span>
          </div>
          <div class="close-btn">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 12.9436L16.3282 17.2718C16.453 17.3966 16.606 17.4632 16.7872 17.4718C16.9684 17.4804 17.1299 17.4137 17.2718 17.2718C17.4137 17.1299 17.4846 16.9726 17.4846 16.8C17.4846 16.6274 17.4137 16.4701 17.2718 16.3282L12.9436 12L17.2718 7.6718C17.3966 7.54702 17.4632 7.39403 17.4718 7.21283C17.4804 7.03163 17.4137 6.87009 17.2718 6.7282C17.1299 6.58631 16.9726 6.51537 16.8 6.51537C16.6274 6.51537 16.4701 6.58631 16.3282 6.7282L12 11.0564L7.6718 6.7282C7.54702 6.60342 7.39403 6.53676 7.21283 6.5282C7.03163 6.51964 6.87009 6.58631 6.7282 6.7282C6.58631 6.87009 6.51537 7.02736 6.51537 7.2C6.51537 7.37264 6.58631 7.52991 6.7282 7.6718L11.0564 12L6.7282 16.3282C6.60342 16.453 6.53676 16.606 6.5282 16.7872C6.51964 16.9684 6.58631 17.1299 6.7282 17.2718C6.87009 17.4137 7.02736 17.4846 7.2 17.4846C7.37264 17.4846 7.52991 17.4137 7.6718 17.2718L12 12.9436ZM12.0045 24C10.3451 24 8.78496 23.6851 7.32413 23.0554C5.86331 22.4256 4.59259 21.5709 3.51197 20.4913C2.43137 19.4117 1.57589 18.1422 0.945533 16.6827C0.315178 15.2233 0 13.6638 0 12.0045C0 10.3451 0.314878 8.78496 0.944633 7.32413C1.57441 5.86331 2.4291 4.59259 3.5087 3.51197C4.5883 2.43137 5.85782 1.57589 7.31727 0.945534C8.77673 0.315179 10.3362 0 11.9955 0C13.6549 0 15.215 0.314877 16.6759 0.944633C18.1367 1.57441 19.4074 2.4291 20.488 3.5087C21.5686 4.5883 22.4241 5.85782 23.0545 7.31727C23.6848 8.77673 24 10.3362 24 11.9955C24 13.6549 23.6851 15.215 23.0554 16.6759C22.4256 18.1367 21.5709 19.4074 20.4913 20.488C19.4117 21.5686 18.1422 22.4241 16.6827 23.0545C15.2233 23.6848 13.6638 24 12.0045 24ZM12 22.6667C14.9778 22.6667 17.5 21.6333 19.5667 19.5667C21.6333 17.5 22.6667 14.9778 22.6667 12C22.6667 9.02222 21.6333 6.5 19.5667 4.43333C17.5 2.36667 14.9778 1.33333 12 1.33333C9.02222 1.33333 6.5 2.36667 4.43333 4.43333C2.36667 6.5 1.33333 9.02222 1.33333 12C1.33333 14.9778 2.36667 17.5 4.43333 19.5667C6.5 21.6333 9.02222 22.6667 12 22.6667Z" fill="#7F7D82" />
              </svg>
          </div>
      </div>
      <div class="download-content">
          <div class="download-template-wrapper">
              <span >Download Template</span>
                  <div class="sample-template-wrapper" >
                      <div class="sample-template">
                      <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.3077 19C1.80257 19 1.375 18.825 1.025 18.475C0.675 18.125 0.5 17.6974 0.5 17.1923V1.8077C0.5 1.30257 0.675 0.875 1.025 0.525C1.375 0.175 1.80257 0 2.3077 0H10.8653L15.5 4.6346V17.1923C15.5 17.6974 15.325 18.125 14.975 18.475C14.625 18.825 14.1974 19 13.6922 19H2.3077ZM2.3077 17.5H13.6922C13.7692 17.5 13.8397 17.4679 13.9038 17.4038C13.9679 17.3397 14 17.2692 14 17.1923V5.38458H10.1154V1.49998H2.3077C2.23077 1.49998 2.16024 1.53203 2.09612 1.59613C2.03202 1.66024 1.99997 1.73077 1.99997 1.8077V17.1923C1.99997 17.2692 2.03202 17.3397 2.09612 17.4038C2.16024 17.4679 2.23077 17.5 2.3077 17.5ZM7.99997 16.1057C9.01407 16.1057 9.87016 15.7429 10.5682 15.0173C11.2663 14.2916 11.6153 13.4205 11.6153 12.4038V8.11533H10.1154V12.4038C10.1154 13.0051 9.91248 13.5224 9.5067 13.9558C9.10093 14.3891 8.59869 14.6058 7.99997 14.6058C7.41151 14.6058 6.91182 14.3891 6.50092 13.9558C6.09002 13.5224 5.88457 13.0051 5.88457 12.4038V6.92305C5.88457 6.71535 5.9506 6.54003 6.08265 6.39708C6.21472 6.25413 6.37627 6.18265 6.5673 6.18265C6.76858 6.18265 6.93268 6.25413 7.0596 6.39708C7.18653 6.54003 7.25 6.71535 7.25 6.92305V12.4038H8.74995V6.92305C8.74995 6.30638 8.54034 5.77883 8.12113 5.34038C7.70189 4.90191 7.18395 4.68268 6.5673 4.68268C5.95063 4.68268 5.43268 4.90191 5.01345 5.34038C4.59423 5.77883 4.38462 6.30638 4.38462 6.92305V12.4038C4.38462 13.4205 4.73623 14.2916 5.43942 15.0173C6.14262 15.7429 6.99614 16.1057 7.99997 16.1057Z" fill="#2E3336"/>
                          </svg>
                      <span>Sample Template</span>
                      </div>

                      <div class="download-icon">
                      <svg (click)="getTemplate()" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 12.075C7.86667 12.075 7.74167 12.0542 7.625 12.0125C7.50833 11.9708 7.4 11.9 7.3 11.8L3.7 8.2C3.5 8 3.40417 7.76667 3.4125 7.5C3.42083 7.23333 3.51667 7 3.7 6.8C3.9 6.6 4.1375 6.49583 4.4125 6.4875C4.6875 6.47917 4.925 6.575 5.125 6.775L7 8.65V1.5C7 1.21667 7.09583 0.979167 7.2875 0.7875C7.47917 0.595833 7.71667 0.5 8 0.5C8.28333 0.5 8.52083 0.595833 8.7125 0.7875C8.90417 0.979167 9 1.21667 9 1.5V8.65L10.875 6.775C11.075 6.575 11.3125 6.47917 11.5875 6.4875C11.8625 6.49583 12.1 6.6 12.3 6.8C12.4833 7 12.5792 7.23333 12.5875 7.5C12.5958 7.76667 12.5 8 12.3 8.2L8.7 11.8C8.6 11.9 8.49167 11.9708 8.375 12.0125C8.25833 12.0542 8.13333 12.075 8 12.075ZM2 16.5C1.45 16.5 0.979167 16.3042 0.5875 15.9125C0.195833 15.5208 0 15.05 0 14.5V12.5C0 12.2167 0.0958333 11.9792 0.2875 11.7875C0.479167 11.5958 0.716667 11.5 1 11.5C1.28333 11.5 1.52083 11.5958 1.7125 11.7875C1.90417 11.9792 2 12.2167 2 12.5V14.5H14V12.5C14 12.2167 14.0958 11.9792 14.2875 11.7875C14.4792 11.5958 14.7167 11.5 15 11.5C15.2833 11.5 15.5208 11.5958 15.7125 11.7875C15.9042 11.9792 16 12.2167 16 12.5V14.5C16 15.05 15.8042 15.5208 15.4125 15.9125C15.0208 16.3042 14.55 16.5 14 16.5H2Z" fill="#2E3336"/>
                          </svg>
                      </div>
                  </div>
          </div>
          <div class="attachments-wrapper">
          <div class="attachments-inner">
              <div class="attachment-titles">
               <span class="attachments">Attachments</span>
               <span>Upload multiple planned outage data</span>
               </div>
              <div class="select-file" >
                  <button class="select-file-button" (click)="chooseFile.click()">Select File</button>

                  <input #chooseFile type="file" accept=".xlsx" style="display: none;"
                      (change)="onFileChosen($event)">
                      <div class="file-name" *ngIf="fileName!=''">
                          <div>
                          <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.95997 7.95691L11.9328 0.984094C12.1227 0.794177 12.348 0.699219 12.6087 0.699219C12.8695 0.699219 13.0948 0.794177 13.2847 0.984094C13.4746 1.17401 13.5696 1.39933 13.5696 1.66005C13.5696 1.92076 13.4746 2.14607 13.2847 2.33599L5.628 9.9927C5.43808 10.1826 5.2154 10.2776 4.95997 10.2776C4.70454 10.2776 4.48187 10.1826 4.29195 9.9927L0.718578 6.41932C0.528662 6.22941 0.436343 6.00409 0.44162 5.74339C0.446912 5.48266 0.544516 5.25734 0.734433 5.06743C0.924349 4.87751 1.14967 4.78255 1.41039 4.78255C1.6711 4.78255 1.89641 4.87751 2.08633 5.06743L4.95997 7.95691Z" fill="#188D52"/>
                          </svg>
                      </div>
                      <div class="no-wrap">
                          <span>{{fileName}}</span>
                       </div>
                      </div>
              </div>
             <span>XLSX,CSV, 24mb max file</span>
          </div>
      </div>
      </div>
      <div class="upload-wrapper">
          <button class="upload-btn" (click)="upload()"><span>Upload</span></button>
      </div>
  </div>
</div>
