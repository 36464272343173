import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TimePeriod } from 'src/app/components/consumption/model/model';
import { transformToString } from 'src/app/helpers/dateUtils';
import { DtService } from 'src/app/services/dt.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-dt-outage-popup',
  templateUrl: './dt-outage-popup.component.html',
  styleUrls: ['/../../outage-feeder33/f33-outage-popup/f33-outage-popup.component.scss']
})
export class DtOutagePopupComponent {

  public isPopupOpen: boolean = false;
  public selectedDt: any = null;
  public data: any = null;

  public tabMenu: number = 1;
  public account:any = [];

  public currentPageAccounts: number = 1;
  public count: number = 0;
  public accountsPageSize: number = 8;
  public lastPageSize: number = 1;
  date: string = '';
  public buName: string = this.translationService.getByKeyFromCache('BU')

  @Input() timePeriod: TimePeriod = TimePeriod.Monthly;
  @Input() dateFrom: any = '';
  @Input() dateTo: any = '';
  constructor(
    private dtService: DtService,
    private toastr: ToastrService,
    private translationService: TranslationService) { }

  close() {
    this.isPopupOpen = false;
  }
  private GetPopupInfo() {
    this.dtService.getDataForTopologyPopup(this.selectedDt.number).subscribe(response => {
      if (response.status == 200) {
        this.data = response.data
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An error occurred")
    });
  }

  tabChanged(value: number) {
    this.tabMenu = value
  }

  public isPlanned(plan: string){
    if(plan === 'Planned'){
      return true;
    }
    else return false;
  }

  dateFormat() {
    const date = new Date(this.selectedDt.outageStart);
    const all = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = all[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    this.date = `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds}`;
    }
  pageChangeAccounts(value: any) {
    this.currentPageAccounts = value;
    this.selectDt();
  }
  selectDt() {
    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.selectDtMonthly()
    } else
      this.selectDtMonthly()
  }
  selectDtMonthly() {
    let obj = {
      pageInfo: {
        page: this.currentPageAccounts,
        pageSize: this.accountsPageSize,
        isMonthlySelected: this.timePeriod == TimePeriod.Monthly
      },
      filterParams: {
        DtName: this.selectedDt.name,
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
      }
    };
    this.dtService.getDtViewMore(obj).subscribe(response => {
      if (response.status === 200) {
        this.account = response.data.data;
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.accountsPageSize)

      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  selectDtDaily() {
    let obj = {
      pageInfo: {
        page: this.currentPageAccounts,
        pageSize: this.accountsPageSize,
        isMonthlySelected: this.timePeriod == TimePeriod.Monthly
      },
      filterParams: {
        DtName: this.selectedDt.name,
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
      }
    };
    this.dtService.getDtViewMore(obj).subscribe(response => {
      if (response.status === 200) {
        this.account = response.data.data;
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.accountsPageSize)

      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  back(){
    this.tabChanged(2)
  }

  changeState(data?: any, menu?: any) {
    if (data)
      this.selectedDt = data;
    this.GetPopupInfo()
    this.selectDt();
    this.dateFormat()
    if (menu === 1){
      this.isPopupOpen=true;
      this.tabMenu=1;
     }
    else {
      this.isPopupOpen = false;
    }
  }
}
