import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MultipleAmisPopUpComponent } from '../load-profile/multiple-amis-pop-up/multiple-amis-pop-up.component';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';
import { DeviceManagementPages } from 'src/app/models/meter-enums.model';
import { MeterModel } from 'src/app/models/meterModel.model';
import { MeterModelService } from 'src/app/services/meterModel.service';

@Component({
  selector: 'app-device-parameters',
  templateUrl: './device-parameters.component.html',
  styleUrl: './device-parameters.component.scss'
})
export class DeviceParametersComponent {

  public accountOrMSNValue: string = ''
  public invalidAccount: boolean = false
  showInvalidInputError = false
  spnChanged = false

  // when amiId != -1 it is an indication that there are multiple meters with particular MSN
  amiId: number = -1
  data: any
  meter: any
  meterId: number = 0
  meterModels: MeterModel[] = []
  public selectedMeterModel: MeterModel = new MeterModel()

  @ViewChild(MultipleAmisPopUpComponent) multipleAmisPopUp!: MultipleAmisPopUpComponent

  public initialScreen: boolean = true

  public utName: string = this.translationService.getByKeyFromCache('UT')
  public buName: string = this.translationService.getByKeyFromCache('BU')
  public amiName: string = this.translationService.getByKeyFromCache('AMI')

  @Output() confirmUpload: EventEmitter<void> = new EventEmitter<void>()
  public file: any = null
  fileChosen: File | null = null
  fileName: string = ''

  constructor(private accService: AccountService,
    private toastr: ToastrService,
    private meterService: MeterService,
    private meterModelService: MeterModelService,
    private translationService: TranslationService) { }

  search() {
    if (this.accountOrMSNValue == '') {
      this.showInvalidInputError = true
      return
    }

    let obj = {
      MSN: this.accountOrMSNValue,
      AmiId: this.amiId
    }
    this.getAccBySearch(obj)
  }

  accValueChanged() {
    if (this.accountOrMSNValue != '') {
      this.showInvalidInputError = false
    }
  }

  spnValueChanged() {
    this.spnChanged = true
  }

  save() {
    let meterObj = {
      meterId: this.meter.id,
      meterModel: this.meter.meterModelId
    }

    let accObj = {
      id: this.data.id,
      spn: this.data.spn
    }

    if (meterObj.meterModel) {
      this.meterService.save(meterObj).subscribe(response => {
        this.meter.meterModelId = 0
        if (response.status === 200) {
          this.toastr.success('Meter Model has been successfully updated.')
        } else
          this.toastr.error(response.message)
      }, error => {
        this.toastr.error('An error occurred.')
      })
    }

    if (accObj.spn && this.spnChanged) {
      this.accService.updateSPN(accObj).subscribe(response => {
        this.spnChanged = false
        if (response.status === 200) {
          this.toastr.success(response.message)
        } else
          this.toastr.error(response.message)
      }, error => {
        this.toastr.error('An error occurred.')
      })
    }

    if (!accObj.spn && !meterObj.meterModel) {
      this.toastr.error('Enter SPN or select Meter Model.')
    }
  }

  getTemplate() {
    this.meterService.getTemplate(DeviceManagementPages.DeviceParameters).subscribe(response => {
      if (response.status == 200){
        if (response.data && response.data.length > 0)
          window.location.href = response.data
      } else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  setMetersAmi(amiId: any) {
    this.amiId = amiId
    let obj = {
      MSN: this.accountOrMSNValue,
      amiId: amiId
    }
    this.meterService.getMeterIdFromMSNAndAmi(obj).subscribe(response => {
      if (response.status === 200) {
        this.meterId = response.data
        this.getMeter(true)
      } else if (response.status === 400) {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  getMeter(getAcc = false) {
    this.meterService.get(this.meterId).subscribe(response => {
      if (response.status === 200) {
        this.meter = response.data
        if (this.meter.meterModel && this.meter.meterModel != '/') {
          this.selectedMeterModel.name = this.meter.meterModel
        }
        let obj = {
          MSN: this.accountOrMSNValue,
          AmiId: this.amiId
        }
        this.getAllForSelect(this.meter.meterMakeId)
        if (getAcc) {
          this.getAccBySearch(obj)
        } else {
          this.initialScreen = false
        }
        this.amiId = -1
      } else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  getAccBySearch(obj: any) {
    this.accService.getAccBySearch(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data
        if (response.data.multipleAmis) {
          this.multipleAmisPopUp.changeState()
          let obj = {
            searchText: this.accountOrMSNValue
          }
          this.meterService.getAllAmisWithMSN(obj).subscribe(response => {
            response.data.forEach((element: any) => {
              element.checked = false
            })
            this.multipleAmisPopUp.amis = response.data
          })
        } else {
          this.accService.getMeterIdForAccountNo(this.data.accountNo).subscribe(response => {
            if (response.status === 200) {
              this.meterId = response.data
              if (obj.AmiId == -1) {
                this.getMeter()
              } else {
                this.initialScreen = false
              }
            } else {
              this.toastr.error(response.message)
            }
          }, _ => {
            this.toastr.error('An error occurred.')
          })
        }
      }
      else if (response.status === 400 && !response.data.multipleAmis) {
        this.toastr.error(response.message)
      } else if (response.status === 404) {
        this.invalidAccount = true
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllForSelect(amiId: number) {
    this.meterModelService.getAllForSelect(amiId).subscribe(response => {
      this.meterModels = response.data
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  public selectMeterModel(meterModel: any) {
    this.selectedMeterModel = meterModel
    if (this.selectedMeterModel) {
      this.meter.meterModelId = meterModel.value
    }
  }

  onFileChosen(event: any){
    this.file = event.target.files[0]
    this.fileName = this.file.name
  }

  isFileChosen() {
    if (this.fileChosen)
      return true

    return false
  }

  uploadFile() {
    if(!this.file) {
      this.toastr.error('You have to choose file for upload.')
      return
    }
    const formData = new FormData()
    formData.append("uploadFile", this.file)
    formData.append("uploadFileType", DeviceManagementPages.DeviceParameters.toString())

    this.meterService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message)
        if (response.data && response.data.length > 0)
          window.location.href = response.data
        this.fileName = ''
        this.file = null
      } else {
        this.toastr.error(response.message)
      }
        this.fileChosen = null
    }, error => {
      this.toastr.error('An error occurred.')
      this.fileChosen = null
    })
  }

  export() {
    this.meterService.download(this.meterId).subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data
      } else {
        this.toastr.error(response.message)
      }
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }

  searchAgain() {
    this.initialScreen = true
    this.accountOrMSNValue = ''
    this.spnChanged = false
    this.selectedMeterModel.id = 0
    this.selectedMeterModel.name = ''
    if (this.data != null)
      this.invalidAccount = false
  }

}
