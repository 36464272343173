<main class="container-fluid">
    <div class="container-wrapper">
        <button class="back" (click)="back()">
            <img alt="Back" class="imgBack" src="assets/images-new-design/back-arrow.png">Back
        </button>
        <div class="revenue-protection-header">
            <div class="revenue-protection-page-header">
                <span>PAYMENTS</span>
            </div>
        </div>

        <div class="revenue-customers-download">
            <div class="search-count-wrapper">
                <app-new-count-box [isGreen]="true" label = "TOTAL CUSTOMERS"
                    [data]="allPaymentsCount | twoDecimals"
                    [secondData]="'(' + (allPaymentsCountPrevMonth | twoDecimals) + ' last month)'"></app-new-count-box>
            </div>
            <div class="search-count-wrapper">
                <app-new-count-box [isGreen]="true" label = "TOTAL COLLECTIONS"
                    [data] = "allPaymentsAmount | twoDecimals"
                    [secondData]="'(' + (allPaymentsAmountPrevMonth | twoDecimals) + ' last month)'"></app-new-count-box>
            </div>
        </div>

        <div class="download-wrapper">
            <button class="btn-red" (click)="download()">
                Download
                <img alt="" src="assets/images/download-pic.svg">
            </button>
        </div>

        <div class="revenue-protection-filter-wrapper">

            <div class="dropdown-filter">
                <label class="filter-by">Filter</label>

                <app-filter-dropdown [data]="businessUnits" [name]="buSelected.name" [dropdownName]="'All '+ buName + 's'"
                        [dataId]="buSelected.value" (dataChange)="businessUnitSelected($event)">
                </app-filter-dropdown>
                <app-filter-dropdown [data]="undertakings" [name]="utSelected.name" [dropdownName]="'All '+ utName + 's'"
                        [dataId]="utSelected.value" (dataChange)="undertakingSelected($event)">
                </app-filter-dropdown>

                <app-datepicker
                    [dateFrom]="dateFrom"
                    [dateTo]="dateTo"
                    [isMonthly]="false"
                    [placementPosition]="'bottom-right'"
                    (dateChange)="updateDate($event)">
                </app-datepicker>
            </div>

            <div class="right-wrapper">
                <div class="search-wrapper">
                    <app-search
                        [placeholder]="'Search by Acc. No or SPN'"
                        [width]="23"
                        [borderColor]="'#6C757D'"
                        [(searchText)]="searchText"
                        (searchChange)="inputChanged($event)">
                    </app-search>
                </div>
            </div>

        </div>

        <table class="standard-table">
            <thead>
                <tr>
                    <th><span>Account Number</span></th>
                    <th><span>Date of Payment</span></th>
                    <th><span>Payment Amount</span></th>
                    <th><span>Receipt_NR</span></th>
                    <th><span>SPN</span></th>
                    <th><span>{{buName}}</span></th>
                    <th><span>{{utName}}</span></th>
                    <th><span>Payment Source</span></th>
                </tr>
            </thead>
            <tbody class="table-body">
                <tr *ngFor="let item of payments" class="bodyRow clickable actions-click">
                    <td title="{{ item.accountNo }}">{{ item.accountNo | hasValue }}</td>
                    <td title="{{ item.paymentDate }}">{{ item.paymentDate | date : "dd/MM/yyyy" | hasValue }}</td>
                    <td title="{{ item.paymentAmount }}">{{ item.paymentAmount | number | hasValue }}</td>
                    <td title="{{ item.receipt_NR }}">{{ item.receipt_NR | hasValue }}</td>
                    <td title="{{ item.spn }}">{{ item.spn | hasValue }}</td>
                    <td title="{{ item.buName }}">{{ item.buName | hasValue }}</td>
                    <td title="{{ item.utName }}">{{ item.utName | hasValue }}</td>
                    <td title="{{ item.paymentSource }}">{{ item.paymentSource| hasValue }}</td>
                </tr>
            </tbody>
        </table>

        <div class="col-sm-12 pagination-wrapper" *ngIf="!showPaging">
            <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
        </div>
    </div>
</main>
