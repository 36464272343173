import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { SwitchModulePopupComponent } from '../switch-module-popup/switch-module-popup.component';

export enum FieldSidebarItemsNew{
  Dashboard = 1,
  TeamsAndAgentsComponent = 2,
  WorkOrderManagement = 3,
  AssetPerformanceNewComponent = 4,
  PredictiveAnalyticsNewComponent = 5,
  ModalSettings = 6,
  GeneralSettings = 7,
  Reports = 8,
  InventoryManagement = 9
}
@Component({
  selector: 'app-field-operations-sidemenu',
  templateUrl: './field-operations-sidemenu.component.html',
  styleUrls: ['./field-operations-sidemenu.component.scss']
})
export class FieldOperationsSidemenuComponent{

  public settingsMenu: boolean = false;
  public allowSettingsMenu: boolean = true;

  public PermissionsEnum = PermissionsEnum;

  public SelectedItem: any = 1;
  @Input() expandSidebar: boolean = false;
  @Output() expandSidebarChange = new EventEmitter();

  @ViewChild(SwitchModulePopupComponent) popupComponentRef!: SwitchModulePopupComponent;

  constructor(public authService: AuthService, public toastr: ToastrService,
    private router: Router) {
    this.router.events
      .subscribe(
        (event) => {
          if (event instanceof ActivationEnd && event.snapshot.children.length == 0) {
            this.SelectedItem = event.snapshot.data['sidebarItem'];
          }
        });
        console.log("sele",this.SelectedItem)
  }

  switchModule(){
    this.popupComponentRef.changeState(1);
  }
  logout(){
    this.authService.logout();
  }

  alert(){
    this.toastr.warning("Not yet implemented");
  }

  expand(){
    this.expandSidebar = !this.expandSidebar;
    this.expandSidebarChange.emit(this.expandSidebar);
  }

  handleDropdownMenuClick(name: string, event: any) {
    console.log(name)
    console.log(event)
    // If sidebar is open
    if(this.expandSidebar) {
      if (name = 'settings') {
        this.settingsMenu = !this.settingsMenu;
      }
      else {
      return;
      }
    } else {
      // this.expandSidebar = true;
      this.expand()

      if(name === 'Settings') {
        if(this.settingsMenu){
          this.allowSettingsMenu = false;
          event.stopPropagation();
          return
        }else{
          this.settingsMenu = !this.settingsMenu;
          this.allowSettingsMenu = true;
        }
      }
    }
  }

  redesign(){
    this.toastr.warning("Under redesign process");
  }

}
