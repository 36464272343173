import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Dt } from 'src/app/models/dt.model';
import { AmiService } from 'src/app/services/ami.service';
import { CommonService } from 'src/app/services/common-service.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { UndertakingService } from 'src/app/services/undertaking.service';

import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { CrudService } from 'src/app/services/crudService.service';
import { MeterService } from 'src/app/services/meter.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { CrudDeleteModalComponent } from '../crud-delete-modal/crud-delete-modal.component';
import { DtCreateComponent } from './dt-create/dt-create.component';

@Component({
  selector: 'app-dt-tab',
  templateUrl: './dt-tab.component.html',
  styleUrls: ['./dt-tab.component.scss']
})
export class DtTabComponent implements OnInit {
@ViewChild(DtCreateComponent) popupComponentRef!: DtCreateComponent;
@ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

public PermissionsEnum = PermissionsEnum;
public selectedId: number = 0;

public currentPage: number = 1;
public pageSize: number = 10;
public count: number = 0;
public lastPageSize: number = 1;

private ngUnsubscribe = new Subject<void>();
private searchText: string = "";

public dts: Dt[] = [];
public selectedDt: Dt = new Dt();
public amis: any = [];
public feeder11s: any[] = [];
public feeder33s: any[] = [];
public substations: any[] = [];
public undertakings: any[] = [];

public isDtMetered!: boolean;
public msnValid: boolean = false;

public isLatitudeValid: boolean = true;
public isLongitudeValid: boolean = true;
public isNameplateRatingValid: boolean = true;

public isDtOwnershipValid: boolean = false;
public isIsMeteredValid: boolean = false;
public isAmiValid: boolean = false;
public isUTValid: boolean = false;
public isF33Valid: boolean = false;

public isSSValid: boolean = true;
public isF11Valid: boolean = true;


public f33TypeSelected: boolean = true; //For showing Select Substation
public is33TypeSelected: boolean = true; //For showing F11 inputs
public SsSelected: boolean = false; //For showing Select Feeder11

public fileChosen: any = null;
public file: any = null;
public amiName :string = this.translationService.getByKeyFromCache('AMI');
public utName :string = this.translationService.getByKeyFromCache('UT');
public buName :string = this.translationService.getByKeyFromCache('BU');

public isPublicOpt:any = [{label:'Public',value:true}, {label:'Private',value:false}];

public masterSelected: boolean = false;
public checkedList: any = [];

@ViewChild("f") f: any;

@Output() editTab = new EventEmitter();
@Output() deleteComp = new EventEmitter();
@Output() amisSelect = new EventEmitter();
@Output() f33Select = new EventEmitter();
@Output() utsSelect = new EventEmitter();

constructor(private dtService: DtService,
  private toastr: ToastrService,
  private commonService: CommonService,
  private crudService: CrudService,
  public authService: AuthService,
  private f11Service: Feeder11Service,
  private f33Service: Feeder33Service,
  private amiService: AmiService,
  private meterService: MeterService,
  private substationService: SubstationService,
  private translationService: TranslationService,
  private undertakingService:UndertakingService, private location: Location) { }

ngOnInit(): void {
  this.reloadTable();
  this.getAllF33ForSelect();
  this.getAllUtsForSelect();
  this.getAllForSelect();
  this.subscribeToSearch();
}
back(){
  this.location.back();
}
getAllF33ForSelect(){
  this.f33Service.getAllForSelect().subscribe(response => {
    this.feeder33s = response.data;
    this.f33Select.emit(this.feeder33s);
  }, error => {
    this.toastr.error('An error occurred.');
  });
}

getAllForSelect(){
  this.amiService.getAllForSelect().subscribe(response => {
    this.amis = response.data;
    this.amisSelect.emit(this.amis);
  }, error => {
    this.toastr.error('An error occurred.');
  });
}

getAllUtsForSelect(){
  this.undertakingService.getAllForSelect().subscribe(response => {
    this.undertakings = response.data;
    this.utsSelect.emit(this.undertakings);
  }, error => {
    this.toastr.error('An error occurred.');
  });
}

selectDt(id: number){
  this.dtService.get(id).subscribe(response => {
    if(response.status === 200){
        this.selectedDt = response.data;
 
      this.validateAssets()
     
      if (this.selectedDt.dtMeterType === 2)
      {
        this.msnValid = true;
        this.isAmiValid = true;
      }
      this.editTab.emit(this.selectedDt);

      // else if (this.selectedDt.dtMeterType === 1)
      //   this.validateMeterNumber();
    }
    else
      this.toastr.error(response.message);
  }, error => {
    this.toastr.error('An error occurred.');
  });
}

validateAssets(){
  if (this.selectedDt.dtMeterType !== 0)
    this.isIsMeteredValid = true;
  if (this.selectedDt.isPublic !== 0)
    this.isDtOwnershipValid = true;
  if (this.selectedDt.amiId !== 0)
    this.isAmiValid = true;
  if (this.selectedDt.feeder11Id !== 0)
    this.isF11Valid = true;
  if (this.selectedDt.connectedUtId !== 0)
    this.isUTValid = true;
  if (this.selectedDt.feeder33Id !== 0)
    this.isF33Valid = true;
}

getTemplate(){
  this.dtService.getTemplate().subscribe(response => {
    if (response.status == 200) {
      if (response.data && response.data.length > 0)
        window.location.href = response.data;
    }else{
      this.toastr.error(response.message);
    }
  }, error => {
    this.toastr.error('An error occurred.');
  });
}

pageChange(value: any) {
  this.currentPage = value;
  this.reloadTable();
}

getFeederTypeValue(numValue: number){
  if(numValue == 1){
    return "11KV";
  }
  else if(numValue == 2){
    return "33KV";
  }
  else{
    return "";
  }
}
resetSelectedDt(){
  this.selectedDt = new Dt();

  if(this.f)
    this.f.submitted = false;
}

reloadTable(page: any = null){
  if(page)
    this.currentPage = page;
  // this.resetSelectedDt();
  let obj = {
    pageInfo:{
      page: this.currentPage,
      pageSize: this.pageSize
    },
    filterParams: {
      SearchContent: this.searchText
    }
  };

  this.dtService.getAll(obj).subscribe(response => {
    this.dts = response.data.data;
    if(obj.pageInfo.page == 1){
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }
    this.isAlreadyChecked();
  }, error => {
    this.toastr.error('An error occurred.');
  });
}

openTabDelete(id:number) {
  this.dtService.get(id).subscribe(response => {
   if(response.status === 200){
     this.selectedDt = response.data;
     this.deleteComp.emit();
   }
   else
     this.toastr.error(response.message);
 }, error => {
   this.toastr.error('An error occurred.');
 });
 this.selectedId = id;
}


onDeleteConfirmed( ){
  if(!this.authService.isAuthorized(PermissionsEnum.global_settings_delete))
  {
    this.toastr.error("You don't have permission.");
    return;
  };

  this.dtService.delete(this.selectedId).subscribe(response => {
    if(response.status === 200){
      this.toastr.success(response.message);
      this.currentPage = 1;
      this.reloadTable();
     }
    else
      this.toastr.error(response.message);
  }, error => {
    this.toastr.error('An error occurred.');
  });
}


onFileChosen(event: any){
  this.file = event.target.files[0];
}

isFileChosen(){
  if(this.fileChosen)
    return true;

  return false;
}

public subscribeToSearch() {
  this.crudService.globalSearch$
  .pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(searchValue => {
    if(searchValue.clicked && searchValue.componentName === ComponentNamesNew.DtComponent) {
      this.searchText = searchValue.text;
      this.currentPage = 1;
      this.checkedList = [];
      this.reloadTable();
      return;
    }
    this.searchText = '';
  });
}

ngOnDestroy(): void {
  this.ngUnsubscribe.next();
  this.ngUnsubscribe.complete();
}

checkUncheckAll() {
  for (let i = 0; i < this.dts.length; i++) {
    this.dts[i].checked = this.masterSelected;
    this.checkForDownload(this.dts[i].id, true);
  }
}

checkForDownload(id: number, isMasterClicked: boolean = false) {
  if(!isMasterClicked)
    this.isMasterChecked();
  let index = this.checkedList.indexOf(id);
  if(index !== -1){
    if(!isMasterClicked)
      this.checkedList.splice(index, 1);
    else if(isMasterClicked && !this.masterSelected)
      this.checkedList.splice(index, 1);
  }
  else {
    this.checkedList.push(id);
  }
}

isAlreadyChecked() {
  this.dts.forEach(x => {
    let index = this.checkedList.indexOf(x.id)
    if(index !== -1) {
      x.checked = true;
    }
  })

  this.isMasterChecked();
}

isMasterChecked() {
  this.masterSelected = this.dts.every(function(item:any) {
    return item.checked == true;
  })
}

downloadItems(){
  let obj = {
    Ids: this.checkedList,
    activate: false
  };
  this.dtService.downloadCrud(obj).subscribe(response => {
    if (response.status === 200) {
      if (!response.data.includes("http")) {
        this.toastr.error("An error occured.")
      }
      else {
        window.location.href = response.data
        this.toastr.success("Successfully downloaded DTs.");
      }
    }
    else
      this.toastr.error(response.message);
  }, error => {
    this.toastr.error('An error occurred.');
  });
}
}
