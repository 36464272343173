<div class="main-container">
    <div class="title-and-desc">
        <div class="title-first">
            <div class="first">
                <div class="title-u">
                    Field
                </div>
                <div class="channel-description">
                    Create, read, update or delete Fields on the field operations
                </div>
            </div>
            <div class="second">
                <button class="btn btn-white" (click)="addNew()">
                    <span class="text">Edit All</span>
                </button> 
            </div>
        </div>   
        <div class="app-page-tabs" screenSizeDetector [addClasses]="false" [componentId]="'-crudSettings'">
            <mat-drawer-container>
                <div class="title-second">
                <app-tab-default (tabChanged)="changeTab($event)" 
                [firstText]="firstTabPrimary" 
                [secondText]="secondTabPrimary"
                [thirdText]="thirdTabPrimary"
                [womCss] = "true">                
                </app-tab-default>
                </div>
                    <app-dt-field-settings  [hidden]="!(!crudAreaVisible && componentName == FieldSettingsNew.DtComponent)"></app-dt-field-settings>                   
                    <app-feeder11-field-settings [hidden]="!(!crudAreaVisible && componentName == FieldSettingsNew.Feeder11Component)"></app-feeder11-field-settings>
                    <app-feeder33-field-settings [hidden]="!(!crudAreaVisible && componentName == FieldSettingsNew.Feeder33Component)"></app-feeder33-field-settings>
            </mat-drawer-container>
        </div>
    </div>         
</div>

            