<div class="current-total-collections">
  <div class="title">Current Total Collection</div>

  <div class="current-total-collections-wrapper">

    <div class="header-wrapper">
      <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

        <app-multi-filter *ngIf="filterApplied"
          [data]="allFilters"
          [(showFilter)]="showFilter"      
          (dropdownChange)="businessUnitSelected($event)"
        ></app-multi-filter>

        <app-range-datepicker
            [fromDate]="dateFrom" 
            [toDate]="dateTo"
            [name]="'Period'"
            (dateRangeSelected)="dateChange($event)">
        </app-range-datepicker>

        <app-search-autocomplete
          #search
          [placeholder]="searchPlaceholder" 
          [width]="25"
          [backgroundColor]="'transparent'"
          [borderColor]="'#E8E8E8'"
          [(searchText)]="searchText"
          [options]="options"       
          (searchChange)="inputChanged($event)"
          (searchAutocompleteChange)="getOptions($event)"
          ></app-search-autocomplete>
      </div>
    </div>
    
    <table class="standard-table">
      <thead>
        <tr>
          <th class="small-td">SN</th>
          <th>AREAS</th>
          <th>AMOUNT</th>
          <th>CUSTOMERS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of monthlyCollection; let i = index">
          <td class="small-td">{{pageSize * (page - 1) + i + 1}}</td>
          <td>{{item.bu | uppercase}}</td>
          <td>{{item.amount  | twoDecimals}}</td>
          <td>{{item.customers | number}}</td>
        </tr>
        <tr class="overal-total">
          <td class="average"></td>
          <td class="average">OVERALL TOTAL</td>
          <td class="average">{{totalAmount | twoDecimals}}</td>
          <td class="total-num average">{{totalCustomers | number}} Customers</td>
        </tr>
      </tbody>
    </table>

    <div class="pagination-wrapper">
      <app-pagination
      [count]="count"
      [pageSize]="pageSize"
      [currentPage]="page"
      (pageChange)="pageChange($event)"
      ></app-pagination>
    </div>

  </div>
</div>
