<div class="dropdown-container" >
    <div tabindex="1" class="dropdown-btn" id="filter-inner" (click)="filterClick()" 
        [style.width]="width" [style.background-color]="backgroundColor" [style.border-radius]="borderRadius" 
        [style.border]="border" (blur)="close()" >
        <div class="left-container">
            <div *ngIf="circleColor" class="circle" [style.background-color]="circleColor"></div>
            <span class="text" title="{{name}}" [style.color]="textColor" [style.font-size]="fontSizeText" [style.font-weight]="fontWeightText" [style.line-height]="lineHeightText">{{name}}</span>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="10.536" height="6.506" viewBox="0 0 10.536 6.506">
            <path id="ic_keyboard_arrow_left_24px" d="M6.506,1.238,2.485,5.268,6.506,9.3,5.268,10.536,0,5.268,5.268,0Z" transform="translate(0 6.506) rotate(-90)" fill="#000829"/>
        </svg>
    </div>
    <div class="dropbox-expanded" *ngIf="filterClicked" click-stop-propagation [style.width]="widthExpanded">
        <div class="dropbox-header">
            <span>{{headerName}}</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z" fill="#8F92A1" fill-opacity="0.4"/>
            </svg>                
        </div>
        <div class="dropdown-item-wrapper">
            <div class="dropdown-item" *ngIf="dropdownName != ''" [ngClass]="{'selected': dataId === 0}" (mousedown)="changeData(0, dropdownName)"><span>{{dropdownName}}</span></div>
            <div class="dropdown-item" [ngClass]="{'selected': dataId === item?.value}" (mousedown)="changeData(item.value, item.name, item.type)" *ngFor="let item of data" title="{{item.name}}"><span>{{item.name}}</span></div>
        </div>
    </div>
</div>
