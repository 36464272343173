import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AttachmentsPopupComponent } from 'src/app/components/common-new-design/attachments-popup/attachments-popup.component';
import { CustomComment } from 'src/app/models/comment.model';
import { PopupEnum } from 'src/app/models/popupEnum';
import { User } from 'src/app/models/user.model';
import { WorkOrder } from 'src/app/models/workOrder.model';
import { AuthService } from 'src/app/services/auth.service';
import { CommentService } from 'src/app/services/comment.service';
import { UserService } from 'src/app/services/user.service';
import { WorkOrderManagement } from 'src/app/services/work-order-management.service';

@Component({
  selector: 'app-wom-details-comments',
  templateUrl: './wom-details-comments.component.html',
  styleUrls: ['./wom-details-comments.component.scss']
})
export class WomDetailsCommentsComponent implements OnInit, OnChanges {

  @ViewChild(AttachmentsPopupComponent) attachmentsPopupRef!: AttachmentsPopupComponent
  @ViewChild('comment') commentInputRef!: ElementRef<HTMLInputElement>

  @Input() task = new WorkOrder()

  public defaultImage: string = 'assets/images-new-design/defaultImg.png'

  public comments: CustomComment[] | null = null
  public attachmentsData: string[] = []
  public commentText: string = ''
  public sort: number = 1
  public currentUser: User = new User
  public selectedComment: CustomComment = new CustomComment
  
  public selectedFiles!: FileList | null
  public attachments: string[] = []

  constructor(
    private workOrderService: WorkOrderManagement,
    private toastr: ToastrService,
    private commentService: CommentService,
    private userService: UserService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getCurrentUser()
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.task != null && changes.task.currentValue != undefined){
      if(changes.task.currentValue.id != 0)
        this.getAllComments()
    }
  }

  viewAttachments(data: any){
    this.attachmentsData = data 
    this.attachmentsPopupRef.changeState(PopupEnum.Open)
  }

  getAllComments(){
    this.workOrderService.getAllCommentsByWorkOrderId(this.task.id).subscribe(response =>{
      if(response.status == 200){
        this.comments = response.data 
      }
    })
  }

  submit(){
    this.commentText = this.commentText.replace(/(\r\n|\n|\r)/gm, "");    /// Removes new line
    let formData: FormData = new FormData();

    if (this.selectedFiles) {
      for(let i = 0; i<this.selectedFiles.length; i++){
        formData.append('attachments', this.selectedFiles[i], this.selectedFiles[i].name);
      }
    } 

    formData.append('id', this.selectedComment.id.toString()); 
    formData.append('workOrderId', this.task.id.toString()); 
    formData.append('authorEmail', this.currentUser.email); 
    formData.append('text', this.commentText); 
    formData.append('sort', this.sort.toString()); 

    this.commentText = ''
    this.resetAttachments()
    this.workOrderService.addCommentToWorkOrder(formData).subscribe(response =>{
      if(response.status == 200){
        this.selectedComment = new CustomComment()
        this.toastr.success('Succeessfuly added comment')
        this.getAllComments()
      }
    }, _=>{
      this.toastr.error('An error occurred')
    })
  }

  resetAttachments(){
    this.attachments = []
    this.selectedFiles = null
  }

  changeSort(){
    if(this.sort == 1){
      this.sort = 2
      this.comments?.sort((a, b) => {
        return new Date(a.lastUpdateTime).getTime() - new Date(b.lastUpdateTime).getTime();
      });
    }
    else{
      this.sort = 1
      this.comments?.sort((b, a) => {
        return new Date(a.lastUpdateTime).getTime() - new Date(b.lastUpdateTime).getTime();
      });
    }
  }

  deleteComment(id: number){
    this.commentService.delete(id, this.task.taskId).subscribe(response =>{
      if(response.status == 200){
        this.toastr.success('Successfuly deleted comment')
        this.getAllComments()
      }
    }, _=>{
      this.toastr.error('An error occurred')
    })
  }

  getCurrentUser(){
    this.currentUser.userGuid = this.authService.getCurrentUser().userGuid
    this.userService.getByGuid(this.currentUser.userGuid).subscribe(response =>{
      if(response.status == 200){
        this.currentUser = response.data
      }
    })
  }

  editComment(item: CustomComment){
    this.selectedComment = item
    this.commentText = item.text
    this.commentInputRef.nativeElement.focus()
  }

  onFileChanged(event: any) {
    let files = event.target.files
    this.selectedFiles = files 
    for(let i = 0; i< files.length; i++){
      if(files[i]){
        this.attachments.push(files[i].name)
        // const reader = new FileReader()
        // reader.onload = (e: any) =>{
        //   this.preview = e.target.result
        // }
  
        // reader.readAsDataURL(files[i]);
      }  
      
    }
  }
}
