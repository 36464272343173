<main class="container-fluid consumption-wrapper">
    <div class="filter-header grid-energy">
        <div class="container-fluid">
            <div class="row filter-row">
                <div class="filter-left-side">
                    
                </div>
                
                <div class="filter-right-side">
                    
                    <div class="d-flex justify-content-center switch-toggle map">
                        <div class="col-6 grouped-toggle-item" [ngClass]="{'pressed': isMap }" (click)="toggleMap(true)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.235" height="19.693" viewBox="0 0 19.235 19.693">
                                <path id="Map" d="M187.372,1509.928l-4.58-1.832a1.4,1.4,0,0,0-.51-.1,1.351,1.351,0,0,1-1.374,0,1.4,1.4,0,0,0-.51.1l-4.58,1.832c-.062.027-.12.055-.177.087-.056-.032-.115-.059-.177-.087l-4.58-1.832a1.4,1.4,0,0,0-.51-.1,1.344,1.344,0,0,0-.77.238,1.363,1.363,0,0,0-.6,1.136v15.114a1.377,1.377,0,0,0,.864,1.278l4.58,1.832a1.4,1.4,0,0,0,.51.1,1.351,1.351,0,0,1,1.374,0,1.4,1.4,0,0,0,.51-.1l4.58-1.832c.062-.027.12-.055.177-.087a1.965,1.965,0,0,0,.177.087l4.58,1.832a1.4,1.4,0,0,0,.51.1,1.344,1.344,0,0,0,.77-.238,1.364,1.364,0,0,0,.6-1.136v-15.114a1.377,1.377,0,0,0-.864-1.278Zm-17,14.559v-15.114l4.58,1.832v15.114Zm5.954,1.832v-15.114l4.58-1.832v15.114Zm10.534,0-4.58-1.832v-15.114l4.58,1.832Z" transform="translate(-169 -1508)" fill="#b4b4b4" fill-rule="evenodd"/>
                            </svg>                              
                        </div>
                        <div class="col-6 grouped-toggle-item" [ngClass]="{'pressed': !isMap }" (click)="toggleMap(false)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.896" height="16.56" viewBox="0 0 18.896 16.56">
                                <path id="XMLID_1308_" d="M7.011,8.486A1.051,1.051,0,0,1,8.072,7.425H19.834a1.062,1.062,0,0,1,0,2.123H8.072A1.051,1.051,0,0,1,7.011,8.486Zm12.824,5.732H8.072a1.062,1.062,0,1,0,0,2.123H19.834a1.062,1.062,0,0,0,0-2.123Zm0,6.794H8.072a1.062,1.062,0,1,0,0,2.123H19.834a1.062,1.062,0,0,0,0-2.123ZM3.486,13.879a1.508,1.508,0,1,0,1.486,1.507A1.5,1.5,0,0,0,3.486,13.879ZM3.486,7A1.508,1.508,0,1,0,4.972,8.507,1.5,1.5,0,0,0,3.486,7Zm0,13.545a1.508,1.508,0,1,0,1.486,1.507A1.5,1.5,0,0,0,3.486,20.545Z" transform="translate(-2 -7)" fill="#b4b4b4"/>
                            </svg>                              
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<app-search [setInterval]="true" [style.width]="searchWidth" searchContainerWidth="'100%'"
                            #search (searchChange)="searchChange($event)" [searchText]="searchText"
                            [borderColor]="'#8F92A1'" [marginLeft]="'0'" [placeholder]="'Search by accountNo'"></app-search>
<adora-map
    [searchValue]="searchText"
    [customersMap]="true"
           [zoom]="11"></adora-map>                  


<div *ngIf="mapLoaded" id="tooltip-div" class="map-tooltip" [hidden]="clusterizedMap.tooltipHidden">
    <div class="close-tooltip" (click)="clusterizedMap.hideTooltip()">x</div>
        <div class="modal-type">Customer </div>
    <div class="modal-title">{{clusterizedMap.additionalInfo?.name}}</div>
    <div class="modal-content">
        <span class="modal-label">Account number </span>
        <span class="modal-value">{{clusterizedMap.additionalInfo?.accountNo | hasValue}}</span>
    </div>
    <div class="modal-content">
        <span class="modal-label">MSN </span>
        <span class="modal-value">{{clusterizedMap.additionalInfo?.msn | hasValue}} </span>
    </div>
    <div class="modal-content">
        <span class="modal-label">Tariff </span>
        <span class="modal-value">{{clusterizedMap.additionalInfo?.tariffName | hasValue}}</span>
    </div>
</div>
