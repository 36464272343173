import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { GraphItem } from 'src/app/models/graph-item.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { DtService } from 'src/app/services/dt.service';
import { MenuItem, SubMenuItem } from '../../consumption/model/model';

@Component({
  selector: 'app-aeg-dt-new',
  templateUrl: './aeg-dt-new.component.html',
  styleUrls: ['./aeg-dt-new.component.scss']
})
export class AegDtNewComponent {
  public SubMenuItemEnum = SubMenuItem;
  public Submenu: SubMenuItem = SubMenuItem.Dt;
  public menuItemEnum = MenuItem;
  @Input() menu!: MenuItem;
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()
  @Output() redirect: EventEmitter<any> = new EventEmitter()
  @Input() selectedFeederId: number = 0;

  //data
  public dts: [] = []

  //MultiFilter
  @Input() dtIds: number[] = []
  @Input() dateFrom: any = '';
  @Input() dateTo: any = '';


  // public dtId = []
  @Input() showTable = false;

  avgReceived: number = 0;
  avgLosses: number = 0;
  avgEnergyConsumption: number = 0;

  private graphData: GraphItem[] = []
  public receivedTableData: any = []
  public receivedTable: any = []
  public lossesTableData: any = []

  public LostLabels: string[] = [];
  public receivedLabels: string[] = [];

  public receivedAndLossesTableData: any[] = []

  public tableData: any[] = [];

  public receivedData: ChartDataset[] = [
    {
      data: [], label: 'Received',
      backgroundColor: '#29C87B',
      borderRadius: 45,
      barThickness: 12,
    },
    { data: [], label: 'Losses',
      backgroundColor: '#C03438',
      borderRadius: 45,
      barThickness: 12,
    },
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        grid: {
          display: false
        },
        border:{
          display: false
        },
        ticks: {
          font: {
            size: 14
          },
        },
      },
      y: {
        grid: {
          color: '#E8E8E8',
        },
        border:{
          display: false
        },
        ticks: {
          padding: 30,
        }
      },
    },
    plugins:{
      tooltip:{
        callbacks: {
          label: function(context) {
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(context.parsed.y);
              }
              return label;
          }
      }
    }
  }
  }
  public barChartLegend = false;
  public barChartPlugins = [];

  constructor(private dtService: DtService,
              private toastr: ToastrService,
    ) { }

  ngOnChanges(changes: SimpleChanges): void {
    let reloadTable = false
    if(changes.dtIds != null){
      reloadTable = true
    }
    if(changes.dateFrom != null || changes.dateTo != null){
      reloadTable = true
    }
    if(changes.searchText != null){
      reloadTable = true
    }

    if(reloadTable){

      this.GetData()
    }
  }

  public GetData(){
    let obj = {
      start: transformToString(this.dateFrom),
      end: transformToString(this.dateTo),
      dtId: this.dtIds,
      getAll: false,
      feederId: this.selectedFeederId,
      Search: this.searchText.name
    }
    this.getDtLossesAndReceived(obj)
  }

  onSelectedFeeder(feederId: number){
    this.selectedFeederId = feederId;
    this.setValuesForDtLosses(0);
    this.setValuesForDtReceived(0);
  }

  private getDtLossesAndReceived(obj: any){
    this.dtService.getDtReceivedData(obj).subscribe((response: any) => {
      this.dts = response.data
      this.setValuesForDtLosses(response)
      this.setValuesForDtReceived(response)
      this.setValuesForTable(response.data)
    }, _ =>{
      this.toastr.error('An error ocured');
    })
  }

  private setValuesForTable(data: any){
    this.avgReceived = data.avgReceived;
    this.avgEnergyConsumption = data.avgEnergyConsumption;
    this.avgLosses = data.avgLosses;

    this.tableData = [];
    this.graphData = data.graphItems;
    this.graphData.forEach((element: any) => {
      this.tableData.push(element);
    })
  }

  private setValuesForDtReceived(response: any){
    this.receivedData[0].data = []
    this.receivedLabels = []
    let labels = ['']
    labels = []
    this.receivedTableData = []
    if(response.data.graphItems != null && response.data.graphItems.length > 0){
      this.graphData = response.data.graphItems
      this.graphData.forEach(element =>{
        this.receivedData[0].data?.push(element.received)
        labels.push(this.sliceLabel(element.name))
        this.receivedTableData.push(element)
      })
      this.receivedAndLossesTableData[0] = this.receivedTableData;
    }
    else
      this.receivedAndLossesTableData[0] = [];
    this.receivedLabels = labels
  }

  private setValuesForDtLosses(response: any){
    this.receivedData[1].data = []
    this.lossesTableData = []
    if(response.data.graphItems != null && response.data.graphItems.length > 0){
      this.graphData = response.data.graphItems
      this.graphData.forEach(element =>{
        this.receivedData[1].data?.push(element.lost)
        this.lossesTableData.push(element)
      })
      this.receivedAndLossesTableData[1] = this.lossesTableData;
    }
    else
      this.receivedAndLossesTableData[1] = [];

  }

  private sliceLabel(label: string){
    if(label.length > 30)
      return label.slice(0, 30) + '...'
    return label
  }

  public ChangeSubmenu(submenu:any){
    this.redirect.emit(submenu)
  }
  public download(){
    let obj = {
        Start: transformToString(this.dateFrom),
        End: transformToString(this.dateTo),
        DtId: this.dtIds,
        GetAll : false,
        Search: this.searchText.name
    };
    this.downloadReport(obj);
  }
  downloadReport(obj: any){
    if (this.dts.length == 0) {
      this.toastr.warning('Please select DT for download report.');
      return;
    }
    else{
      this.dtService.downloadAssetEnergy(obj).subscribe(response =>{
        if(response.status == 200){
          window.location.href = response.data;
        }
        else{
          this.toastr.error(response.message)
        }
      }, _ =>{
        this.toastr.error('An error occurred.');
      })
    }
  }


}
