<nav aria-label="Page navigation example" [hidden]="count == 0">
    <ul class="pagination">
        <div class="page-count">Page {{currentPage}} of {{lastPageSize}}</div>

        <div [ngClass]="[isSmallerSize ? 'next-page-buttons-smaller' : 'next-page-buttons']">
            <li class="page-item pl-1" [ngClass] = "{'disabled': currentPage == 1}" (click)="changePage(1)"><a class="page-link">
                <img alt="<<" class="vector" src="assets/images-new-design/fo_start_3.svg">
            </a></li>
            <li class="page-item pl-1" [ngClass] = "{'disabled': currentPage == 1}" (click)="changePage(currentPage - 1)"><a class="page-link">
                <img alt="<" class="vector" src="assets/images-new-design/fo_back_2.svg">
            </a></li>
            <li class="page-item pl-1 pagination-border" [style.width]="getPaginationWidth()" *ngIf="currentPage > 2" (click)="changePage(currentPage - 2)"><a class="page-link">
                 <span>  {{currentPage - 2}} </span>
            </a></li>
            <li class="page-item pl-1 pagination-border" [style.width]="getPaginationWidth()" *ngIf="currentPage > 1" (click)="changePage(currentPage - 1)"><a class="page-link">
                <span>  {{currentPage - 1}} </span>
           </a></li>
           <li class="page-item pl-1 pagination-border" [style.width]="getPaginationWidth()" (click)="changePage(currentPage)" [ngClass] = "{'selected' : currentPage}"><a class="page-link">
                <span>  {{currentPage}} </span>
           </a></li>
           <li *ngIf="currentPage < lastPageSize" [style.width]="getPaginationWidth()" class="page-item pl-1 pagination-border" (click)="changePage(currentPage + 1)"><a class="page-link">
            <span>  {{currentPage + 1}} </span>
           </a></li>
           <li *ngIf="currentPage < lastPageSize - 1" [style.width]="getPaginationWidth()" class="page-item pl-1 pagination-border" (click)="changePage(currentPage + 2)"><a class="page-link">
                <span>  {{currentPage + 2}} </span>
           </a></li>
            <li class="page-item item-last pr-1" [ngClass] = "{'disabled': isLastPage}" (click)="changePage(currentPage + 1)"><a class="page-link">
                <img alt=">" class="vector" src="assets/images-new-design/fo_next_2.svg">
            </a></li>
            <li class="page-item item-last pr-1" [ngClass] = "{'disabled': isLastPage}" (click)="changePage(lastPageSize)"><a class="page-link">
                <img alt=">>" class="vector" src="assets/images-new-design/fo_end_3.svg">
            </a></li>
        </div>
    </ul>
</nav>
