<div class="popup-back" [hidden]="!showShadow">

</div>
<div id="delete" tabindex="-1" role="dialog" aria-labelledby="delete" aria-hidden="true" *ngIf="isPopupOpen" (click)="changeState()">
    <div class="custom-popup">
        <div class="custom-popup-content">
            <div class="custom-popup-header">
                <span class="custom-popup-title">Are you sure <br/> you want to delete?</span>
            </div>
            <div class="custom-popup-buttons">
                <button type="button" class="custom-popup-button custom-popup-button-yes" (click)="onConfirmDelete()">Yes</button>
                <button type="button" class="custom-popup-button custom-popup-button-no" (click)="closePopup()">No</button>
            </div>
        </div>
    </div>
</div>

