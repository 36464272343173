export enum PermissionsEnum {
    global_settings_user_view = 101,
    global_settings_user_createupdate = 102,
    global_settings_user_delete = 103,
    global_settings_roles_view = 201,
    global_settings_roles_createupdate = 202,
    global_settings_roles_delete = 203,
    global_settings_auditlog_view = 301,
    global_settings_jobstatus_view = 401,
    global_settings_upgradeplan_allow = 501,

    networkmanagement_gridenergyprofile_view = 10101,
    networkmanagement_outage_view = 10201,
    networkmanagement_historical_trend_view = 10301,
    networkmanagement_asset_comparison_view = 10401,
    networkmanagement_watchlistgraph_view = 10501,
    networkmanagement_planned_outage_view = 11101,
    networkmanagement_planned_outage_create = 11102,
    networkmanagement_planned_outage_update = 11103,
    global_settings_view = 10601,
    global_settings_createupdate = 10602,
    global_settings_delete = 10603,
    global_settings_updateAccountNumer = 10604,
    networkmanagement_reports_view = 10701,
    networkmanagement_reports_createupdate = 10702,
    networkmanagement_reports_delete = 10703,

    revenueprotection_meterbypass_view = 20101,
    revenueprotection_meterbypass_postpaid_view = 20102,
    revenueprotection_overduebillarears_view = 20201,
    revenueprotection_overduebillarears_createupdate = 20202,
    revenueprotection_overduebillarears_sendsms = 20203,
    revenueprotection_paymentcollections_view = 20301,
    revenueprotection_statementofacc_view = 20401,
    revenueprotection_settings_view = 20501,
    revenueprotection_settings_createupdate = 20502,
    revenueprotection_settings_delete = 20503,
    revenueprotection_reports_view = 20601,



    fieldoperations_teamsandagents_view = 30101,
    fieldoperations_teamsandagents_createupdate = 30103,
    fieldoperations_teamsandagents_deactivate = 30102,
    fieldoperations_workordermanagement_view = 30201,
    fieldoperations_workordermanagement_createupdate = 30202,
    fieldoperations_inventorymanagement_view = 30301,
    fieldoperations_inventorymanagement_delete = 30302,
    fieldoperations_inventorymanagement_issue = 30303,
    fieldoperations_inventorymanagement_stock = 30304,
    fieldoperations_assetperformance_view = 30401,
    fieldoperations_assetperformance_predictiveanalytics_view = 30501,
    fieldoperations_settings_view = 30601,
    fieldoperations_settings_createupdate = 30602,
    fieldoperations_settings_delete = 30603,

    metermanagement_meters_view = 40101,
    metermanagement_meters_changestatus = 40102,
    metermanagement_meters_assetmeter = 40103,
    metermanagement_alarmandeventmanagement_view = 40201,
    metermanagement_devicemanagement_view = 40301,
    metermanagement_unmeteredaccounts_view = 40401,
    metermanagement_dcu_view = 40501,
    metermanagement_settings_view = 40601,
}
