import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Department } from 'src/app/models/department.model';
import { FunctionalGroup } from 'src/app/models/functional-group-enum';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { DepartmentService } from 'src/app/services/department.service';

@Component({
  selector: 'app-create-department',
  templateUrl: './create-department.component.html',
  styleUrl: '../.././create-crud.scss',  
  host: {
    '(document:click)': 'handleClick($event)',
  }
})
export class CreateDepartmentComponent {

  public selectedEntity: Department = new Department()
  showShadow = false;
  
  @Output() saveEvent = new EventEmitter();

  @ViewChild('f') f: any;

  @Input() animationState = 'out';
  @ViewChild(CreateDepartmentComponent) popupComponentRef!: CreateDepartmentComponent;
  
  constructor(private toastr: ToastrService,
    private ref: ElementRef,
    private authService: AuthService,
    private departmentService: DepartmentService) { }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  closeTab() {
    this.popupComponentRef.changeState(2);
  }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.popup-wrapper');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }

  changeState(number: Number, data?: any) {
    if (data)
      this.selectedEntity = data;
    if (number === 1) {
      this.toggleShowDiv('open');
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv('close');
      this.showShadow = false;
    }
  }
  
  save() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate)) {
      this.toastr.error("You don't have permission.");
      return;
    }

    if (!this.selectedEntity.name || !this.selectedEntity.functionalGroupString) {
      return
    }

    this.departmentService.save(this.selectedEntity).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.changeState(2);
        this.saveEvent.emit();
        this.resetSelectedEntity();
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedEntity(){
    this.selectedEntity = new Department();

    if (this.f)
      this.f.submitted = false;
  }
}
