<div class="dashboard-title">
    Customer Stats
</div>
<ul class="nav nav-tabs c-tabs" *ngIf="meterType == 1">
    <li class="nav-item" role="presentation">
        <div class="first">
            <div class="nav-link">Total Customers
                <div class="count-row">
                    <span class="bubble">{{prepaidCustomers + postpaidCustomers}}</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        (mouseenter)="onMouseEnter('totalCustomers')" 
                        (mouseleave)="onMouseLeave()">
                        <path d="M10.75 11H12V16.25M21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 7.375C11.6548 7.375 11.375 7.65482 11.375 8C11.375 8.34518 11.6548 8.625 12 8.625C12.3452 8.625 12.625 8.34518 12.625 8C12.625 7.65482 12.3452 7.375 12 7.375Z" fill="black" stroke="black" stroke-width="0.25"/>
                    </svg>
                    <div *ngIf="isSVGHovered('totalCustomers')" class="popup total-customers">
                        <div class="popup-row popup-header">
                            <span class="popup-headers">Prepaid</span>
                            <span class="popup-headers">Postpaid</span>
                            <span class="popup-headers">Unmetered</span>
                        </div>
                        <div class="popup-row">
                            <span>{{prepaidCustomers}}</span>
                            <span>{{postpaidCustomers}}</span>
                            <span>{{unmeteredCustomers}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <svg width="2" height="48" viewBox="0 0 2 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.833374" y1="0.469727" x2="0.833376" y2="47.5305" stroke="#CECECE" />
            </svg>
        </div>
    </li>
    <li class="nav-item" role="presentation">
        <div class="first">
            <div class="nav-link">NMD Customers
                <div class="count-row">
                    <span class="bubble">{{prepaidNMDCustomers + postpaidNMDCustomers}}</span> 
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" 
                        (mouseenter)="onMouseEnter('nmdCustomers')" 
                        (mouseleave)="onMouseLeave()">
                        <path d="M10.75 11H12V16.25M21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 7.375C11.6548 7.375 11.375 7.65482 11.375 8C11.375 8.34518 11.6548 8.625 12 8.625C12.3452 8.625 12.625 8.34518 12.625 8C12.625 7.65482 12.3452 7.375 12 7.375Z" fill="black" stroke="black" stroke-width="0.25"/>
                    </svg>
                    <div *ngIf="isSVGHovered('nmdCustomers')" class="popup nmd-customers">
                        <div class="popup-row popup-header">
                            <span class="popup-headers">Prepaid</span>
                            <span class="popup-headers">Postpaid</span>
                            <span class="popup-headers">Unmetered</span>
                        </div>
                        <div class="popup-row">
                            <span>{{prepaidNMDCustomers}}</span>
                            <span>{{postpaidNMDCustomers}}</span>
                            <span>{{unmeteredNMDCustomers}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <svg width="2" height="48" viewBox="0 0 2 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.833374" y1="0.469727" x2="0.833376" y2="47.5305" stroke="#CECECE" />
            </svg>
        </div>
    </li>
    <li class="nav-item" role="presentation">
        <div class="nav-link">MD Customers
            <div class="count-row">
                <span class="bubble">{{prepaidMDCustomers + postpaidMDCustomers}}</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" 
                    (mouseenter)="onMouseEnter('mdCustomers')" 
                    (mouseleave)="onMouseLeave()">
                    <path d="M10.75 11H12V16.25M21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 7.375C11.6548 7.375 11.375 7.65482 11.375 8C11.375 8.34518 11.6548 8.625 12 8.625C12.3452 8.625 12.625 8.34518 12.625 8C12.625 7.65482 12.3452 7.375 12 7.375Z" fill="black" stroke="black" stroke-width="0.25"/>
                </svg>
                <div *ngIf="isSVGHovered('mdCustomers')" class="popup md-customers">
                    <div class="popup-row popup-header">
                        <span class="popup-headers">Prepaid</span>
                        <span class="popup-headers">Postpaid</span>
                        <span class="popup-headers">Unmetered</span>
                    </div>
                    <div class="popup-row">
                        <span>{{prepaidMDCustomers}}</span>
                        <span>{{postpaidMDCustomers}}</span>
                        <span>{{unmeteredMDCustomers}}</span>
                    </div>
                </div>
            </div>
        </div>
    </li>
</ul>
<ul class="nav nav-tabs c-tabs" *ngIf="meterType == 2">
    <li class="nav-item" role="presentation">
        <div class="first">
            <div class="nav-link">Prepaid Customers<span class="bubble">{{prepaidCustomers}}</span>
            </div>
            <svg width="2" height="48" viewBox="0 0 2 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.833374" y1="0.469727" x2="0.833376" y2="47.5305" stroke="#CECECE" />
            </svg>
        </div>
    </li>
    <li class="nav-item" role="presentation">
        <div class="first">
            <div class="nav-link">Prepaid NMD Customers<span class="bubble">{{prepaidNMDCustomers}}</span> 
        </div>
        <svg width="2" height="48" viewBox="0 0 2 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.833374" y1="0.469727" x2="0.833376" y2="47.5305" stroke="#CECECE" />
        </svg>
        </div>
    </li>
    <li class="nav-item" role="presentation">
        <div class="nav-link">Prepaid MD Customers<span class="bubble">{{prepaidMDCustomers}}</span></div>
    </li>
</ul>
<ul class="nav nav-tabs c-tabs" *ngIf="meterType == 3">
    <li class="nav-item" role="presentation">
        <div class="first">
            <div class="nav-link">Postpaid Customers<span class="bubble">{{postpaidCustomers}}</span>
            </div>
            <svg width="2" height="48" viewBox="0 0 2 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.833374" y1="0.469727" x2="0.833376" y2="47.5305" stroke="#CECECE" />
            </svg>
        </div>
    </li>
    <li class="nav-item" role="presentation">
        <div class="first">
            <div class="nav-link">Postpaid NMD Customers<span class="bubble">{{postpaidNMDCustomers}}</span> 
        </div>
        <svg width="2" height="48" viewBox="0 0 2 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.833374" y1="0.469727" x2="0.833376" y2="47.5305" stroke="#CECECE" />
        </svg>
        </div>
    </li>
    <li class="nav-item" role="presentation">
        <div class="nav-link">Postpaid MD Customers<span class="bubble">{{postpaidMDCustomers}}</span></div>
    </li>
</ul>