<div class="channel-data">
    <app-feeder33-field-settings-edit (parentComp)="reloadTable()"></app-feeder33-field-settings-edit>
    <div *ngFor="let item of configs" class="channel-item bodyRow clickable actions-click">
        <div class="channel-first-part">
            <div class="channel-info">
                <div class="channel-name"><span>{{item.name}}</span></div>
                <div class="channel-name" *ngIf="!item.name.includes('Deviation') && !item.name.includes('Nameplate')">
                    <span class="channel-id">Value: </span><span>{{item.value | number}}</span>
                </div>
                <div class="channel-name" *ngIf="item.name.includes('Deviation') || item.name.includes('Nameplate')">
                    <span class="channel-id">Value: </span><span>{{item.value | number}}%</span>
                </div>
            </div>
        </div>
    </div>
</div>