<div tabindex="1" class="date-container" id="date-picker" (blur)="close()" >
    <div class="date-inner-container" id="date-inner" (click)="filterClick()" [ngClass]="{'pressed': filterByDateClicked}" >
        <span class="text">Date</span>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="10.536" height="6.506" viewBox="0 0 10.536 6.506">
                <path id="ic_keyboard_arrow_left_24px" d="M6.506,1.238,2.485,5.268,6.506,9.3,5.268,10.536,0,5.268,5.268,0Z" transform="translate(0 6.506) rotate(-90)" fill="#000829"/>
            </svg>
        </span>

    </div>

    <div class="date-dropbox-expanded" *ngIf="filterByDateClicked">
        <div class="date-header">
            <div>
                <span>Date</span>
            </div>
        </div>
        <div class="date-dropbox-item">
            <span class="date-span">From</span>
            <span>
                <input 
                    class="input-datepicker"
                    id="dateFrom" 
                    [(ngModel)]="dateFrom" 
                    (dateSelect)="changeFromDate($event)"
                    name="dp"
                    [markDisabled]="isDisabled"
                    ngbDatepicker
                    placeholder="From"
                    #d="ngbDatepicker"
                    [placement]="placementPosition"
                    autocomplete="off"
                    navigation="navigation"
                    (click)="d.toggle(); d1.close()"
                    (mousedown)="handleMouseDown($event)"
                    
                />
                <svg (click)="d.toggle(); d1.close(); isDatePickerOpened=true" width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.9999 9.16994C16.8126 8.98369 16.5591 8.87915 16.2949 8.87915C16.0308 8.87915 15.7773 8.98369 15.5899 9.16994L11.9999 12.7099L8.45995 9.16994C8.27259 8.98369 8.01913 8.87915 7.75495 8.87915C7.49076 8.87915 7.23731 8.98369 7.04995 9.16994C6.95622 9.26291 6.88183 9.37351 6.83106 9.49537C6.78029 9.61723 6.75415 9.74793 6.75415 9.87994C6.75415 10.012 6.78029 10.1427 6.83106 10.2645C6.88183 10.3864 6.95622 10.497 7.04995 10.5899L11.2899 14.8299C11.3829 14.9237 11.4935 14.9981 11.6154 15.0488C11.7372 15.0996 11.8679 15.1257 11.9999 15.1257C12.132 15.1257 12.2627 15.0996 12.3845 15.0488C12.5064 14.9981 12.617 14.9237 12.7099 14.8299L16.9999 10.5899C17.0937 10.497 17.1681 10.3864 17.2188 10.2645C17.2696 10.1427 17.2957 10.012 17.2957 9.87994C17.2957 9.74793 17.2696 9.61723 17.2188 9.49537C17.1681 9.37351 17.0937 9.26291 16.9999 9.16994Z" fill="#575757"/>
                </svg> 
            </span>
        </div>
        <div class="date-dropbox-item">
            <span class="date-span">To</span>
            <span>
                <input 
                    class="input-datepicker"
                    #d1="ngbDatepicker"
                    id="dateTo" 
                    [(ngModel)]="dateTo" 
                    (dateSelect)="changeToDate($event)"
                    name="dp2"
                    [markDisabled]="isDisabled"
                    ngbDatepicker
                    placeholder="To"
                    [placement]="placementPosition"
                    autocomplete="off"
                    navigation="navigation"
                    (click)="d1.toggle(); d.close()"
                    (mousedown)="handleMouseDown($event)"

                />
                <svg (click)="d1.toggle(); d.close(); isDatePickerOpened=true" width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.9999 9.16994C16.8126 8.98369 16.5591 8.87915 16.2949 8.87915C16.0308 8.87915 15.7773 8.98369 15.5899 9.16994L11.9999 12.7099L8.45995 9.16994C8.27259 8.98369 8.01913 8.87915 7.75495 8.87915C7.49076 8.87915 7.23731 8.98369 7.04995 9.16994C6.95622 9.26291 6.88183 9.37351 6.83106 9.49537C6.78029 9.61723 6.75415 9.74793 6.75415 9.87994C6.75415 10.012 6.78029 10.1427 6.83106 10.2645C6.88183 10.3864 6.95622 10.497 7.04995 10.5899L11.2899 14.8299C11.3829 14.9237 11.4935 14.9981 11.6154 15.0488C11.7372 15.0996 11.8679 15.1257 11.9999 15.1257C12.132 15.1257 12.2627 15.0996 12.3845 15.0488C12.5064 14.9981 12.617 14.9237 12.7099 14.8299L16.9999 10.5899C17.0937 10.497 17.1681 10.3864 17.2188 10.2645C17.2696 10.1427 17.2957 10.012 17.2957 9.87994C17.2957 9.74793 17.2696 9.61723 17.2188 9.49537C17.1681 9.37351 17.0937 9.26291 16.9999 9.16994Z" fill="#575757"/>
                </svg>      
            </span>
        </div>
        <div class="datepicker-button" (click)="changeDates(dateFrom,dateTo); filterClick()">
            <span>Apply</span>
        </div>
    </div>
</div>



<ng-template #c let-dp>
	<div class="p-2">
		<div class="btn-group d-flex justify-content-end mb-2" role="group">
		</div>
		<div class="custom-month-grid">
			<div *ngFor="let month of dp.state.months">
				<div class="text-primary p-1 fw-bold">{{ dp.i18n.getMonthShortName(month.month) }} {{ month.year }}</div>
				<ngb-datepicker-month class="border rounded" [month]="month"></ngb-datepicker-month>
			</div>
		</div>
	</div>
</ng-template>
