<app-attachments-popup [data]="attachmentsData"></app-attachments-popup>

<div class="details-wrapper">
  <div class="header-wrapper">
    <div class="comments-header">
      <img alt="" src="assets/images/mode_comment.svg" />
      <span>Comments</span>
    </div>
  </div>
  <div class="inputs-wrapper">
    <img alt="Portrait" [src]="currentUser.image != null ? currentUser.image : defaultImage" />
    <div class="comment-input">
      <textarea name="comment" #comment (keyup.enter)="submit()" [(ngModel)]="commentText" type="text"
        placeholder="Drop a comment"></textarea>
      <div class="attachment-icon">
        <input class="file-input" placeholder="Select File" #file name="image" type="file" accept='image/*' multiple
          (change)="onFileChanged($event)" />
        <img alt="Attachment" src="assets/images/attachment.svg" />
      </div>
    </div>
    <button class="btn-white" (click)="submit()">
      <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>ic_send</title>
        <defs></defs>
        <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
          stroke-linejoin="round">
          <g id="24-px-Icons" transform="translate(-219.000000, -122.000000)" stroke="#000000">
            <g id="ic_send" transform="translate(216.000000, 120.000000)">
              <g id="send">
                <g transform="translate(4.000000, 3.000000)" stroke-width="2">
                  <polygon id="Path-32" points="0 0 17 9 0 18 5 9"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>

  </div>
  <div *ngIf="comments == null" class="comments-empty">
    <div class="empty">
      <img alt="Empty" src="assets/images/mark_chat_unread.svg" />
    </div>
    <span>No comment yet, start a conversation by adding a comment</span>
  </div>
  <div *ngIf="comments != null" class="comments-wrapper">
    <div class="comments-filter" (click)="changeSort()">
      <img alt="Sort" src="assets/images/format_letter_spacing.svg" />
      <span *ngIf="sort == 1">Newest First</span>
      <span *ngIf="sort == 2">Oldest First</span>
    </div>
    <div class="comments-container">

      <div class="comment" *ngFor="let item of comments">
        <div class="person">
          <img alt="Portrait" [src]="item.authorImage != null ? item.authorImage : defaultImage" />
        </div>
        <div class="content-container">
          <div class="content-header">
            <span class="person-name">{{item.authorFirstName}} {{item.authorLastName}}</span>
            <span class="time">{{item.lastUpdateTime | dateTimeFormat | hasValue}}</span>
            <span *ngIf="item.isEdited" class="time">Edited</span>
          </div>
          <div class="content">
            <span>{{item.text}}</span>
          </div>
          <div *ngIf="item.attachments.length != 0" class="attachment-wrapper" (click)="viewAttachments(item)">
            <img alt="" src="assets/images/attachment.svg" />
            <span>View attachment</span>
            <div class="attachment-no">
              <span>+</span>
              <span>{{item.attachments.length}}</span>
            </div>
          </div>
          <div *ngIf="item.authorGuid == currentUser.userGuid" class="crud-container">
            <span (click)="editComment(item)">Edit</span>
            <div class="dot"></div>
            <span (click)="deleteComment(item.id)">Delete</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>