import { Component, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-bu-map-dialog',
  templateUrl: './bu-map-dialog.component.html',
  styleUrls: ['./bu-map-dialog.component.scss', '.././map.component.scss']
})
export class BuMapDialogComponent {

  @Input()
  public buData!: any;

  public utName: string = this.translationService.getByKeyFromCache('UT');
  public buName: string = this.translationService.getByKeyFromCache('BU');

  constructor(private translationService: TranslationService) { }

}
