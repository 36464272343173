import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-green-checkbox',
  templateUrl: './green-checkbox.component.html',
  styleUrls: ['./green-checkbox.component.scss']
})
export class GreenCheckboxComponent {

  @Input() IsChecked: boolean = false
  @Output() checkboxChange: EventEmitter<any> = new EventEmitter()

  public checkAll(){
   this.check();
  }
  public check(){
    this.IsChecked = !this.IsChecked
    this.checkboxChange.emit(this.IsChecked)
  }
}
