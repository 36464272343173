<table class="standard-table">
    <thead>
        <tr>
            <th class="checkbox-td"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" (change)="checkUncheckAll()"></th>
            <th><span>ACCOUNT NUMBER</span></th>
            <th><span>VEND DATE</span></th>
            <th><span>VEND AMOUNT</span></th>
            <th><span>VEND UNITS</span></th>
            <th><span>{{bu}}</span></th>
            <th><span>{{ut}}</span></th>
            <th><span>SPN</span></th> 
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of vendings">
            <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" ></td>
            <td title="{{item.accountNumber}}">{{item.accountNumber | hasValue}}</td>
            <td title="{{item.vendDate}}">{{item.vendDate | date: 'MMM dd,yyyy hh:mm:ss a'| hasValue}}</td>
            <td title="{{item.vendAmount}}">{{item.vendAmount | number | hasValue}}</td>
            <td title="{{item.vendsUnits}}">{{item.vendsUnits | number | hasValue}}</td>
            <td title="{{item.buName}}">{{item.buName | hasValue}}</td>
            <td title="{{item.utName}}">{{item.utName | hasValue}}</td>
            <td title="{{item.spn}}">{{item.spn | hasValue}}</td>
        </tr>
    </tbody>
</table>

<div class="pagination-wrapper">
    <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
        [currentPage]="currentPage" (pageChange)="pageChange($event)">
    </app-fo-pagination-new>
</div>