import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { TransmissionStation } from '../models/transmission-station.model';

@Injectable({
  providedIn: 'root'
})
export class TransmissionStationService {

  private pathApi = this.config.setting['pathApi'] + "transmissionStation/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    } 

    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    } 

    save(obj: TransmissionStation): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }

    getAll(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, obj);
    } 

    getAllForSelect(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSelect`);
    }
    
    getCount(): Observable<any> {
      return this.http.get(`${this.pathApi}getCount`);
    }

    uploadFile(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}uploadFile`, obj)
    }

    getTemplate(): Observable<any>{
      return this.http.get(`${this.pathApi}getTemplate`);
    }

    getAllTsNames():Observable<any>{
      return this.http.get(`${this.pathApi}GetAllTsNames`)
    }

    getAllTsDictionaryItem():Observable<any>{
      return this.http.get(`${this.pathApi}getAllTsDictionaryItem`)
    }

    download(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}download`, obj)
    }

    getSystemHierarchy(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getSystemHierarchy`, obj)
    }

    getAllTsEnergyProfilesMonthly(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}getAllTsEnergyProfilesMonthly`, obj)
    } 

    getAllTsEnergyProfilesDaily(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}getAllTsEnergyProfilesDaily`, obj)
    } 
    
    downloadMonthlyTsReport(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}downloadMonthlyTsReport`, obj)
    } 

    downloadDailyTsReport(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}downloadDailyTsReport`, obj)
    } 

    getDataForAutocomplete(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
    }
    
    getTsViewMore(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}getTsViewMore`, obj)
    } 
}
