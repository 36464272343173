<div class="dropdown-menu-wrapper">
  <div ngbDropdown class="d-inline-block" id="{{dropdownName}}-container" #dropDownInst="ngbDropdown">
    <button tabindex="0" 
            class="menu {{dropdownName}}" 
            id="{{dropdownName}}"  
            ngbDropdownToggle  
            (keydown.Tab)="dropDownInst.close();">
        <span class="selected-item" >
          {{selectedOption !== '' ? selectedOption : label}}
        </span>
        <div class="icon" [ngClass]="dropDownInst.isOpen() ? 'icon-up' : 'icon-dwn'">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9997 13.6717L16.9497 8.72168L18.3637 10.1357L11.9997 16.4997L5.63574 10.1357L7.04974 8.72168L11.9997 13.6717Z" fill="#4E6883"/>
          </svg>            
        </div>
        <div *ngIf="selectedOption !== ''" class="clear-selection" (click)="clearSelection($event)">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0748 11.0006L17.7448 5.33956C17.9143 5.17008 18.0095 4.94023 18.0095 4.70056C18.0095 4.46089 17.9143 4.23103 17.7448 4.06156C17.5753 3.89208 17.3455 3.79687 17.1058 3.79688C16.8661 3.79688 16.6363 3.89208 16.4668 4.06156L10.8058 9.73156L5.1448 4.06156C4.97533 3.89208 4.74548 3.79688 4.5058 3.79688C4.26613 3.79688 4.03628 3.89208 3.8668 4.06156C3.69733 4.23103 3.60212 4.46089 3.60212 4.70056C3.60212 4.94023 3.69733 5.17008 3.8668 5.33956L9.5368 11.0006L3.8668 16.6616C3.78245 16.7452 3.71549 16.8448 3.6698 16.9544C3.62411 17.0641 3.60059 17.1817 3.60059 17.3006C3.60059 17.4194 3.62411 17.537 3.6698 17.6467C3.71549 17.7564 3.78245 17.8559 3.8668 17.9396C3.95047 18.0239 4.05001 18.0909 4.15969 18.1366C4.26936 18.1823 4.38699 18.2058 4.5058 18.2058C4.62462 18.2058 4.74225 18.1823 4.85192 18.1366C4.9616 18.0909 5.06114 18.0239 5.1448 17.9396L10.8058 12.2696L16.4668 17.9396C16.5505 18.0239 16.65 18.0909 16.7597 18.1366C16.8694 18.1823 16.987 18.2058 17.1058 18.2058C17.2246 18.2058 17.3423 18.1823 17.4519 18.1366C17.5616 18.0909 17.6611 18.0239 17.7448 17.9396C17.8292 17.8559 17.8961 17.7564 17.9418 17.6467C17.9875 17.537 18.011 17.4194 18.011 17.3006C18.011 17.1817 17.9875 17.0641 17.9418 16.9544C17.8961 16.8448 17.8292 16.7452 17.7448 16.6616L12.0748 11.0006Z" fill="#1D295E"/>
          </svg>
        </div>
    </button>
    
    <div ngbDropdownMenu id="{{dropdownName}}-menu" 
                        class="menu-container {{dropdownName}}-menu" 
                        attr.aria-labelledby="{{dropdownName}}">
        <button class="menu-item dropdown-item {{dropdownName}}-item"
            id="{{dropdownName}}-item"
            *ngFor="let option of options" 
            ngbDropdownItem
            (keydown.Tab)="dropDownInst.close();" 
            (keydown.enter)="onOptionClick(option)"
            (click)="onOptionClick(option)">
              {{option.name}}
        </button>
    </div>
  </div>
</div>