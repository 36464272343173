<div class="popup-overlay" [@slideInOut]="animationState">
  <div class="slider">
      <div class="tab-header">

          <div class="btn-close-wrapper">
              <div (click)="changeState(2, null)">
                  <img alt="Close" src="../../../../../assets/images-new-design/cancel.png">
              </div>
          </div>

          <div class="revenue-protection-page-header">
              {{selectedData.feederName}}
          </div>

          <div class="filter-wrapper">
              <app-tab-default
                  [firstText]="'Overview'" [secondText]="'Profile Graph'"
                  [firstImg]="'../../../../assets/images-new-design/sort.png'"
                  [secondImg]="'../../../../assets/images-new-design/mode_comment.png'"
                  (tabChanged)="tabChanged($event)">
              </app-tab-default>
          </div>

          <div *ngIf="!showGraph">
              <div class="main-info-wrapper">
                  <div class="data-wrapper">
                      <div class="name-wrapper">
                          Status
                      </div>

                      <div class="status-wrapper warning" *ngIf="selectedData.status.name == 'Warning'">
                          <span>
                              <img alt="" src="../../../../../../assets/images-new-design/warning.png">
                              {{selectedData.status.name}}
                          </span>
                      </div>
                      <div class="status-wrapper maintance" *ngIf="selectedData.status.name == 'Maintenance'">
                          <span>
                              <img alt="" src="../../../../../../assets/images-new-design/error.png">
                              {{selectedData.status.name}}
                          </span>
                      </div>
                      <div class="status-wrapper info" *ngIf="selectedData.status.name == 'Info'">
                          <span>
                              <img alt="" src="../../../../../../assets/images-new-design/warning.png">
                              {{selectedData.status.name}}
                          </span>
                      </div>
                  </div>

                  <div class="data-wrapper">
                      <div class="name-wrapper">
                          Feeder Name
                      </div>
                      <div class="value-wrapper">
                          {{selectedData.feederName}}
                      </div>
                  </div>

                  <div class="data-wrapper">
                      <div class="name-wrapper">
                          Feeder Number
                      </div>
                      <div class="value-wrapper">
                          {{selectedData.feederNo}}
                          <img alt="Copy" src="../../../../../assets/images-new-design/content_copy.png" (click)="copyFeederNo()">
                      </div>
                  </div>

                  <div class="data-wrapper">
                      <div class="name-wrapper">
                          Transmission Station
                      </div>
                      <div class="value-wrapper">
                          {{selectedData.tsName}}
                      </div>
                  </div>
              </div>

              <ng-template #Nan>
                  <td><span class="NaN">N/A</span></td>
              </ng-template>

              <div class="info-wrapper">
                  <div class="title-wrapper">
                      Average Daily Amb. Temp
                  </div>
                  <div class="border-wrapper" [ngClass]="{'red-border': selectedData.ambTemp, 'green-border': !selectedData.ambTemp}">
                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Normal Range
                          </div>
                          <div class="value-wrapper">
                              {{flaggs.minAmbTemp | hasValue}}-{{flaggs.maxAmbTemp | hasValue}}
                          </div>
                      </div>

                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Reference Value
                          </div>
                          <div class="value-wrapper">
                              {{flaggs.normalAmbTemp | hasValue}} (&#177;{{flaggs.ambTempFlag}}%)
                          </div>
                      </div>

                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Abnormal Value
                          </div>
                          <div class="value-wrapper" *ngIf="selectedData.ambTemp; else Nan">
                              {{selectedData.ambTemp | hasValue}}
                              ({{selectedData.ambTempTs | date: 'dd/MM/yyyy hh:mm a' | hasValue}})
                          </div>
                      </div>

                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Deviation (%)
                          </div>
                          <div class="value-wrapper" *ngIf="selectedData.ambTempDeviation; else Nan">
                              {{selectedData.ambTempDeviation}}
                          </div>
                      </div>
                  </div>
              </div>

              <div class="info-wrapper">
                  <div class="title-wrapper">
                      Avg. Daily Voltage
                  </div>
                  <div class="border-wrapper" [ngClass]="{'red-border': selectedData.voltage, 'green-border': !selectedData.voltage}">
                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Normal Range
                          </div>
                          <div class="value-wrapper">
                              {{flaggs.minVoltage | hasValue}}-{{flaggs.maxVoltage | hasValue}}
                          </div>
                      </div>

                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Reference Value
                          </div>
                          <div class="value-wrapper">
                              {{flaggs.normalVoltage | hasValue}} (&#177;{{flaggs.voltageFlag}}%)
                          </div>
                      </div>

                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Abnormal Value
                          </div>
                          <div class="value-wrapper" *ngIf="selectedData.voltage; else Nan">
                              {{selectedData.voltage | hasValue}}
                              ({{selectedData.voltageTs | date: 'dd/MM/yyyy hh:mm a' | hasValue}})
                          </div>
                      </div>

                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Deviation (%)
                          </div>
                          <div class="value-wrapper" *ngIf="selectedData.voltageDeviation; else Nan">
                              {{selectedData.voltageDeviation}}
                          </div>
                      </div>
                  </div>
              </div>

              <div class="info-wrapper">
                  <div class="title-wrapper">
                      Avg. Daily Current
                  </div>
                  <div class="border-wrapper" [ngClass]="{'red-border': selectedData.current, 'green-border': !selectedData.current}">
                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Max Value
                          </div>
                          <div class="value-wrapper">
                              {{selectedData.maxCurrent | hasValue}}
                          </div>
                      </div>

                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Abnormal Value
                          </div>
                          <div class="value-wrapper" *ngIf="selectedData.current; else Nan">
                              {{selectedData.current | hasValue}}
                              ({{selectedData.currentTs| date: 'dd/MM/yyyy hh:mm a' | hasValue}})
                          </div>
                      </div>
                  </div>
              </div>

              <div class="info-wrapper">
                  <div class="title-wrapper">
                      Power factor
                  </div>
                  <div class="border-wrapper" [ngClass]="{'red-border': selectedData.factor, 'green-border': !selectedData.factor}">
                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Normal Range
                          </div>
                          <div class="value-wrapper">
                              <span>{{flaggs.normalFactor | hasValue}}-1</span>
                          </div>
                      </div>

                      <div class="data-wrapper">
                          <div class="name-wrapper">
                              Abnormal Value
                          </div>
                          <div class="value-wrapper" *ngIf="selectedData.factor; else Nan">
                              {{selectedData.factor | hasValue}}
                              ({{selectedData.factorTs | date: 'dd/MM/yyyy hh:mm a' | hasValue}})
                          </div>
                      </div>
                  </div>
              </div>

            <div class="info-wrapper">
                <div class="title-wrapper">
                    Phase A Voltage Imbalance
                </div>
                <div class="border-wrapper" [ngClass]="{'red-border': selectedData.phaseAVoltageImbalance < (0-selectedData.phaseVoltageImbalanceEdge) || selectedData.phaseAVoltageImbalance > selectedData.phaseVoltageImbalanceEdge
                                    , 'green-border': selectedData.phaseAVoltageImbalance >= (0-selectedData.phaseVoltageImbalanceEdge) || selectedData.phaseAVoltageImbalance <= selectedData.phaseVoltageImbalanceEdge}">
                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Normal Range
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseVoltageImbalanceEdge; else Nan">
                            {{0-selectedData.phaseVoltageImbalanceEdge}}-{{selectedData.phaseVoltageImbalanceEdge | hasValue}}
                        </div>
                    </div>

                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Abnormal Value
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseAVoltageImbalance < (0-selectedData.phaseVoltageImbalanceEdge) || selectedData.phaseAVoltageImbalance > selectedData.phaseVoltageImbalanceEdge; else Nan">
                            {{selectedData.phaseAVoltageImbalance | twoDecimals | hasValue}}
                            ({{selectedData.voltageImbalanceTs | date: 'dd/MM/yyyy hh:mm a' | hasValue}})
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-wrapper">
                <div class="title-wrapper">
                    Phase B Voltage Imbalance
                </div>
                <div class="border-wrapper" [ngClass]="{'red-border': selectedData.phaseBVoltageImbalance < (0-selectedData.phaseVoltageImbalanceEdge) || selectedData.phaseBVoltageImbalance > selectedData.phaseVoltageImbalanceEdge
                                        , 'green-border': selectedData.phaseBVoltageImbalance >= (0-selectedData.phaseVoltageImbalanceEdge) || selectedData.phaseBVoltageImbalance <= selectedData.phaseVoltageImbalanceEdge}">
                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Normal Range
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseVoltageImbalanceEdge; else Nan">
                            {{0-selectedData.phaseVoltageImbalanceEdge}}-{{selectedData.phaseVoltageImbalanceEdge | hasValue}}
                        </div>
                    </div>

                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Abnormal Value
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseBVoltageImbalance < (0-selectedData.phaseVoltageImbalanceEdge) || selectedData.phaseBVoltageImbalance > selectedData.phaseVoltageImbalanceEdge; else Nan">
                            {{selectedData.phaseBVoltageImbalance | twoDecimals | hasValue}}
                            ({{selectedData.voltageImbalanceTs | date: 'dd/MM/yyyy hh:mm a' | hasValue}})
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-wrapper">
                <div class="title-wrapper">
                    Phase C Voltage Imbalance
                </div>
                <div class="border-wrapper" [ngClass]="{'red-border': selectedData.phaseCVoltageImbalance < (0-selectedData.phaseVoltageImbalanceEdge) || selectedData.phaseCVoltageImbalance > selectedData.phaseVoltageImbalanceEdge,
                                            'green-border': selectedData.phaseCVoltageImbalance >= (0-selectedData.phaseVoltageImbalanceEdge) || selectedData.phaseCVoltageImbalance <= selectedData.phaseVoltageImbalanceEdge}">
                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Normal Range
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseVoltageImbalanceEdge; else Nan">
                            {{0-selectedData.phaseVoltageImbalanceEdge}}-{{selectedData.phaseVoltageImbalanceEdge | hasValue}}
                        </div>
                    </div>

                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Abnormal Value
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseCVoltageImbalance < (0-selectedData.phaseVoltageImbalanceEdge) || selectedData.phaseCVoltageImbalance > selectedData.phaseVoltageImbalanceEdge; else Nan">
                            {{selectedData.phaseCVoltageImbalance | twoDecimals | hasValue}}
                            ({{selectedData.voltageImbalanceTs | date: 'dd/MM/yyyy hh:mm a' | hasValue}})
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-wrapper">
                <div class="title-wrapper">
                    Phase A Current Imbalance
                </div>
                <div class="border-wrapper" [ngClass]="{'red-border': selectedData.phaseACurrentImbalance < (0-selectedData.phaseACurrentImbalance) || selectedData.phaseACurrentImbalance > selectedData.phaseCurrentImbalanceEdge
                                        , 'green-border': selectedData.phaseACurrentImbalance >= (0-selectedData.phaseACurrentImbalance) || selectedData.phaseACurrentImbalance <= selectedData.phaseCurrentImbalanceEdge}">
                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Normal Range
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseCurrentImbalanceEdge; else Nan">
                            {{0-selectedData.phaseCurrentImbalanceEdge}}-{{selectedData.phaseCurrentImbalanceEdge | hasValue}}
                        </div>
                    </div>

                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Abnormal Value
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseACurrentImbalance < (0-selectedData.phaseACurrentImbalance) || selectedData.phaseACurrentImbalance > selectedData.phaseCurrentImbalanceEdge; else Nan">
                            {{selectedData.phaseACurrentImbalance | twoDecimals | hasValue}}
                            ({{selectedData.currentImbalanceTs | date: 'dd/MM/yyyy hh:mm a' | hasValue}})
                        </div>
                    </div>
                </div>
            </div>



            <div class="info-wrapper">
                <div class="title-wrapper">
                    Phase B Current Imbalance
                </div>
                <div class="border-wrapper" [ngClass]="{'red-border': selectedData.phaseBCurrentImbalance < (0-selectedData.phaseBCurrentImbalance) || selectedData.phaseBCurrentImbalance > selectedData.phaseCurrentImbalanceEdge
                                            , 'green-border': selectedData.phaseBCurrentImbalance >= (0-selectedData.phaseBCurrentImbalance) || selectedData.phaseBCurrentImbalance <= selectedData.phaseCurrentImbalanceEdge}">
                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Normal Range
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseCurrentImbalanceEdge; else Nan">
                            {{0-selectedData.phaseCurrentImbalanceEdge}}-{{selectedData.phaseCurrentImbalanceEdge | hasValue}}
                        </div>
                    </div>

                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Abnormal Value
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseBCurrentImbalance < (0-selectedData.phaseBCurrentImbalance) || selectedData.phaseBCurrentImbalance > selectedData.phaseCurrentImbalanceEdge; else Nan">
                            {{selectedData.phaseBCurrentImbalance | twoDecimals | hasValue}}
                            ({{selectedData.currentImbalanceTs | date: 'dd/MM/yyyy hh:mm a' | hasValue}})
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-wrapper">
                <div class="title-wrapper">
                    Phase C Current Imbalance
                </div>
                <div class="border-wrapper" [ngClass]="{'red-border': selectedData.phaseCCurrentImbalance < (0-selectedData.phaseCCurrentImbalance) || selectedData.phaseCCurrentImbalance > selectedData.phaseCurrentImbalanceEdge,
                                            'green-border': selectedData.phaseCCurrentImbalance >= (0-selectedData.phaseCCurrentImbalance) || selectedData.phaseCCurrentImbalance <= selectedData.phaseCurrentImbalanceEdge}">
                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Normal Range
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseCurrentImbalanceEdge; else Nan">
                            {{0-selectedData.phaseCurrentImbalanceEdge}}-{{selectedData.phaseCurrentImbalanceEdge | hasValue}}
                        </div>
                    </div>

                    <div class="data-wrapper">
                        <div class="name-wrapper">
                            Abnormal Value
                        </div>
                        <div class="value-wrapper" *ngIf="selectedData.phaseCCurrentImbalance < (0-selectedData.phaseCCurrentImbalance) || selectedData.phaseCCurrentImbalance > selectedData.phaseCurrentImbalanceEdge; else Nan">
                            {{selectedData.phaseCCurrentImbalance | twoDecimals | hasValue}}
                            ({{selectedData.currentImbalanceTs | date: 'dd/MM/yyyy hh:mm a' | hasValue}})
                        </div>
                    </div>
                </div>
            </div>

          </div>

          <div *ngIf="showGraph" class="chart-container">
            <p *ngIf="!flaggedDateFetched" class="graph-error">
              Unable to fetch graph data
            </p>
              <div *ngIf="flaggedDateFetched" class="date-wrapper">
                  <app-multi-filter-datepicker
                      [name]="'Start'"
                      [date]="dateFrom"
                      (dateChange)="changeFromDate($event)">
                  </app-multi-filter-datepicker>

                  <app-multi-filter-datepicker
                      [name]="'End'"
                      [date]="dateTo"
                      (dateChange)="changeToDate($event)">
                  </app-multi-filter-datepicker>
              </div>

              <canvas id="MyChart">{{ chart }}</canvas>
          </div>

      </div>
  </div>
</div>
