import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/models/config';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigRevenueProtectionService } from 'src/app/services/config-revenue-protection.service';
import { Location } from '@angular/common';  

@Component({
  selector: 'app-overdue-bill-configuration',
  templateUrl: './overdue-bill-configuration.component.html',
  styleUrls: ['./overdue-bill-configuration.component.scss']
})
export class OverdueBillConfigurationComponent implements OnInit {
  @ViewChild('closeModal') closeModal!: ElementRef;

  public PermissionsEnum = PermissionsEnum;

  public flags: Config[] = []
  public flag1: Config = new Config()
  public flag2: Config = new Config()
  public flag3: Config = new Config()

  public configBills: Config[] = []

  @ViewChild("f") f: any;

  constructor(
    private toastr: ToastrService,
    public authService: AuthService,
    private configService: ConfigRevenueProtectionService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.getBillsConfiguration()

  }
  back(){
    this.location.back();
  } 
  getBillsConfiguration(){
    this.configService.getAll().subscribe(response =>{
      if(response.status == 200){
        this.configBills = response.data
        this.flag1.key = this.configBills[0].key
        this.flag2.key = this.configBills[1].key
        this.flag3.key = this.configBills[2].key

        this.flag1.value = this.configBills[0].value
        this.flag2.value = this.configBills[1].value
        this.flag3.value = this.configBills[2].value

        this.flag1.name = this.configBills[0].name
        this.flag2.name = this.configBills[1].name
        this.flag3.name = this.configBills[2].name
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  updateSMSFlag(value: string){
    this.flag3.value = value
  }

  validateFlag1(){
    if(this.flag1.value == null){
      return false
    }
    else{
      return true
    }
  }

  validateFlag2(){
    if(this.flag2.value == null){
      return false
    }
    else{
      return true
    }
  }
  save(){
    if(!this.authService.isAuthorized(PermissionsEnum.revenueprotection_settings_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    this.flags = [this.flag1, this.flag2, this.flag3]
    this.configService.saveConfiguration(this.flags).subscribe(response =>{
      if(response.status == 200){
        this.getBillsConfiguration();
        this.closeModal.nativeElement.click();
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  reset(){
    console.log("flag1",this.configBills[0].value)

    this.flag1.value = this.configBills[0].value
    console.log("flag1",this.flag1.value)

    this.flag2.value = this.configBills[1].value    
    this.flag3.value = this.configBills[2].value   

    if(this.f)
      this.f.submitted = false;
  }

}
