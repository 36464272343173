<div class="gep-table-wrapper">
    <div class="table-wrapper">
        <table class="standard-table gep-table">
            <thead>
                <th class="checkbox-td"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" (change)="checkUncheckAll()"></th>
                <th><span title="Account number">ACCOUNT NUMBER</span></th>
                <th><span title="Account name">ACCOUNT NAME</span></th>
                <th><span title="Customer type">CUSTOMER TYPE</span></th>
                <th><span title="Meter number">METER NUMBER</span></th>
                <th><span title="Meter make">METER MAKE</span></th>
                <th><span title="Connection type">CONNECTION TYPE</span></th>
                <th><span title="Meter voltage">METER VOLTAGE</span></th>
                <th><span title="Dt name">DT NAME</span></th>
                <th><span title="Dt number">DT NUMBER</span></th>
                <th><span title="{{buName}}">{{buName | uppercase}}</span></th>
                <th><span title="{{utName}}">{{utName | uppercase}}</span></th>
                <th><span title="Feeder">FEEDER</span></th>
                <th>
                  <div class="dropdown">
                    <div tabindex="3" class="dropdown-btn" (click)="accStatusClicked(1)" (blur)="accStatusClicked(0)">
                    <span title="Contract Status">CONTRACT STATUS</span>
                    <svg *ngIf="accStatusOpened" width="24" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z"
                        fill="#8F92A1" fill-opacity="0.4" />
                    </svg>
                    <svg *ngIf="!accStatusOpened" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z"
                        fill="#8F92A1" fill-opacity="0.4" />
                    </svg>
                    </div>
                    <div class="dropdown-expanded" *ngIf="accStatusOpened">
                      <span (mousedown)="accStatusSelected(null)">Deselect</span>
                      <span *ngFor="let item of accountStatuses" (mousedown)="accStatusSelected(item.value)">{{item.name}}</span>
                    </div>
                  </div>
                </th>
                <th><span title="Last vend">LAST VEND</span></th>
                <th><div class="dropdown">
                  <div tabindex="2" class="dropdown-btn" (click)="alarmConditionClicked(1)" (blur)="alarmConditionClicked(0)">
                    <span>ALARM CONDITION</span>
                    <svg *ngIf="alarmConditionOpened" width="24" height="16" viewBox="0 0 16 16" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z"
                        fill="#8F92A1" fill-opacity="0.4" />
                    </svg>
                    <svg *ngIf="!alarmConditionOpened" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z"
                        fill="#8F92A1" fill-opacity="0.4" />
                    </svg>
                  </div>
                  <div class="dropdown-expanded" *ngIf="alarmConditionOpened">
                    <span (mousedown)="alarmConditionSelected(0)">Deselect</span>
                    <span (mousedown)="alarmConditionSelected(2)">Constant Vend</span>
                    <span (mousedown)="alarmConditionSelected(3)">Decreased Vend</span>
                    <span (mousedown)="alarmConditionSelected(4)">No Vending</span>
                    <span (mousedown)="alarmConditionSelected(1)">Undefined</span>
                  </div>
                </div></th>
                <th><span title="Age">AGE</span></th>
                <th><span title="Period">PERIOD</span></th>
                <th>
                    <div class="dropdown">
                        <div tabindex="3" class="dropdown-btn" (click)="commStatusClicked(1)" (blur)="commStatusClicked(0)">
                        <span title="Comm Status(last 24hrs)">COMM STATUS(LAST 24HRS)</span>
                        <svg *ngIf="commStatusOpened" width="24" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z"
                            fill="#8F92A1" fill-opacity="0.4" />
                        </svg>
                        <svg *ngIf="!commStatusOpened" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z"
                            fill="#8F92A1" fill-opacity="0.4" />
                        </svg>
                        </div>
                        <div class="dropdown-expanded" *ngIf="commStatusOpened">
                        <span (mousedown)="commStatusSelected(0)">Deselect</span>
                        <span (mousedown)="commStatusSelected(1)">Yes</span>
                        <span (mousedown)="commStatusSelected(2)">No</span>
                        </div>
                    </div>
                </th>
                <th><div class="dropdown">
                  <div tabindex="2" class="dropdown-btn" (click)="bypassStatusClicked(1)" (blur)="bypassStatusClicked(0)">
                    <span>STATUS</span>
                    <svg *ngIf="bypassStatusOpened" width="24" height="16" viewBox="0 0 16 16" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z"
                        fill="#8F92A1" fill-opacity="0.4" />
                    </svg>
                    <svg *ngIf="!bypassStatusOpened" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z"
                        fill="#8F92A1" fill-opacity="0.4" />
                    </svg>
                  </div>
                  <div class="dropdown-expanded" *ngIf="bypassStatusOpened">
                    <span (mousedown)="bypassStatusSelected(null)">Deselect</span>
                    <span (mousedown)="bypassStatusSelected(0)">Unknown</span>
                    <span (mousedown)="bypassStatusSelected(1)">Confirmed</span>
                    <span (mousedown)="bypassStatusSelected(2)">No Bypass</span>
                    <span (mousedown)="bypassStatusSelected(3)">Pending</span>
                  </div>
                </div></th>
                <th><span></span></th>
            </thead>
            <tbody>
                <tr *ngFor="let item of meterTriggersData; let i = index">
                    <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" ></td>
                    <td>
                        <span *ngIf="item.accountNumber !== '/'" title="{{item.accountNumber}}">{{item.accountNumber | hasValue}}
                            <img alt="Copy" class="icons" src="assets/images-new-design/copy.png"(click)="copyToClipboard(item.accountNumber)">
                        </span>
                        <span *ngIf="item.accountNumber === '/'" class="NaN">N/A</span>
                    </td>
                    <td class="large-td">
                        <span *ngIf="item.accountName !== '/'" title="{{item.accountName}}">{{item.accountName | hasValue}}</span>
                        <span *ngIf="item.accountName === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.customerType !== '/'" title="{{item.customerType}}">{{item.customerType | hasValue}}</span>
                        <span *ngIf="item.customerType === '/'" class="NaN">N/A</span>
                    </td>
                    <td >
                        <span *ngIf="item.meterNumber !== '/'" title="{{item.meterNumber}}">{{item.meterNumber | hasValue}}</span>
                        <span *ngIf="item.meterNumber === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.meterMake !== '/'" title="{{item.meterMake}}">{{item.meterMake | hasValue}}</span>
                        <span *ngIf="item.meterMake === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.connectionType !== '/'" title="{{item.connectionType}}">{{item.connectionType | hasValue}}</span>
                        <span *ngIf="item.connectionType === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.meterVoltage !== '/'" title="{{item.meterVoltage}}">{{item.meterVoltage | hasValue}}</span>
                        <span *ngIf="item.meterVoltage === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.dtName !== '/'" title="{{item.dtName}}">{{item.dtName | hasValue}}</span>
                        <span *ngIf="item.dtName === '/'" class="NaN">N/A</span>
                    </td>
                    <td class="large-td">
                        <span *ngIf="item.dtNo !== '/'" title="{{item.dtNo}}">{{item.dtNo | hasValue}}</span>
                        <span *ngIf="item.dtNo === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.buName !== '/'" title="{{item.buName}}">{{item.buName | hasValue}}</span>
                        <span *ngIf="item.buName === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.utName !== '/'" title="{{item.utName}}">{{item.utName | hasValue}}</span>
                        <span *ngIf="item.utName === '/'" class="NaN">N/A</span>
                    </td>
                    <td class="large-td">
                        <span *ngIf="item.feederName !== '/'" title="{{item.feederName}}">{{item.feederName | hasValue}}</span>
                        <span *ngIf="item.feederName === '/'" class="NaN">N/A</span>
                    </td>
                    <td *ngIf="item.contractStatus !== '/' && item.contractStatus == 'Disconnected'" class="red-table-pill" title="{{item.contractStatus}}">
                        <span>Disconnected</span>
                    </td>
                    <td *ngIf="item.contractStatus !== '/' && item.contractStatus == 'Active'" class="green-table-pill" title="{{item.contractStatus}}">
                        <span>Active</span>
                    </td>
                    <td *ngIf="item.contractStatus !== '/' && item.contractStatus == 'Inactive'" class="gray-table-pill" title="{{item.contractStatus}}">
                        <span>Inactive</span>
                    </td>
                    <td *ngIf="item.contractStatus !== '/' && item.contractStatus == 'Undefined'" class="gray-table-pill" title="{{item.contractStatus}}">
                        <span>Undefined</span>
                    </td>
                    <td *ngIf="item.contractStatus !== '/' && item.contractStatus == 'Pending'" class="yellow-table-pill" title="{{item.contractStatus}}">
                        <span>Pending</span>
                    </td>
                    <td *ngIf="item.contractStatus !== '/' && item.contractStatus == 'Finalized'" class="blue-table-pill" title="{{item.contractStatus}}">
                        <span>Finalized</span>
                    </td>
                    <td>
                        <span *ngIf="item.lastVendDate !== '/'" title="{{item.lastVendDate}}">{{item.lastVendDate | date: 'MMM, y.'}}</span>
                        <span *ngIf="item.lastVendDate === '/'" class="NaN">N/A</span>
                    </td>
                    <td *ngIf="item.alarmCondition !== '/' && item.alarmCondition == 'ConstantVend' && item.customerType !== 'MD'" class="large-td red-table-pill" title="{{item.alarmCondition}}">
                        <span>Constant Vend</span>
                    </td>
                    <td *ngIf="item.alarmCondition !== '/' && item.alarmCondition == 'NoVending' && item.customerType !== 'MD'" class="large-td green-table-pill" title="{{item.alarmCondition}}">
                        <span>No Vending</span>
                    </td>
                    <td *ngIf="(item.alarmCondition !== '/' && item.alarmCondition == 'DecreasedVend') || item.customerType === 'MD' " class="large-td yellow-table-pill" title="{{item.alarmCondition}}">
                        <span>Decreased Vend</span>
                    </td>
                    <td *ngIf="item.alarmCondition !== '/' && item.alarmCondition == 'Undefined' && item.customerType !== 'MD'" class="large-td blue-table-pill" title="{{item.alarmCondition}}">
                        <span>Undefined</span>
                    </td>
                    <td title="{{item.age}}">{{(item.age ? item.age : "0") | dayHoursTime}} </td>
                    <td>
                        <span *ngIf="item.period !== '/'" title="{{item.period}}">{{item.period | date: 'MMM, y.'}}</span>
                        <span *ngIf="item.period === '/'" class="NaN">N/A</span>
                    </td>
                    <td class="sync-div">
                        <span title="{{item.lastReadSync}}">{{item.lastReadSync | hasValue}}</span>
                        <div *ngIf="item.lastReadSyncDate !== '/'" title="{{item.lastReadSyncDate}}">
                          <svg class="info-circle" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round"
                            stroke-miterlimit="2" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 6.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                              fill-rule="nonzero" />
                          </svg>
                        </div>
                    </td>
                    <td *ngIf="item.status !== '/' && item.status == 'CONFIRMED'" class="large-td green-table-pill" title="{{item.status}}">
                        <span>Confirmed</span>
                    </td>
                    <td *ngIf="item.status !== '/' && item.status == 'NO_BYPASS'" class="large-td gray-table-pill" title="{{item.status}}">
                        <span>No Bypass</span>
                    </td>
                    <td *ngIf="item.status !== '/' && item.status == 'PENDING'" class="large-td yellow-table-pill" title="{{item.status}}">
                        <span>Pending</span>
                    </td>
                    <td *ngIf="item.status !== '/' && item.status == 'UNKNOWN'" class="large-td gray-table-pill" title="{{item.status}}">
                        <span>Unknown</span>
                    </td>

                    <td tabindex="1" (click)="statusPopup = i" click-stop-propagation (blur)="closeStatusPopup()">
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white"/>
                            <rect x="0.5" y="0.5" width="43" height="43" rx="7.5" stroke="#E8E8E8"/>
                            <path d="M22 17.8333C22.3296 17.8333 22.6518 17.7356 22.9259 17.5524C23.2 17.3693 23.4136 17.109 23.5398 16.8045C23.6659 16.4999 23.6989 16.1648 23.6346 15.8415C23.5703 15.5182 23.4116 15.2212 23.1785 14.9882C22.9454 14.7551 22.6484 14.5963 22.3251 14.532C22.0018 14.4677 21.6667 14.5007 21.3622 14.6269C21.0576 14.753 20.7973 14.9666 20.6142 15.2407C20.4311 15.5148 20.3333 15.837 20.3333 16.1667C20.3333 16.6087 20.5089 17.0326 20.8215 17.3452C21.134 17.6577 21.558 17.8333 22 17.8333ZM22 26.1667C21.6703 26.1667 21.3481 26.2644 21.074 26.4476C20.7999 26.6307 20.5863 26.891 20.4602 27.1955C20.334 27.5001 20.301 27.8352 20.3653 28.1585C20.4296 28.4818 20.5884 28.7788 20.8215 29.0118C21.0546 29.2449 21.3515 29.4037 21.6748 29.468C21.9981 29.5323 22.3332 29.4993 22.6378 29.3731C22.9423 29.247 23.2026 29.0334 23.3858 28.7593C23.5689 28.4852 23.6666 28.163 23.6666 27.8333C23.6666 27.3913 23.4911 26.9674 23.1785 26.6548C22.8659 26.3423 22.442 26.1667 22 26.1667ZM22 20.3333C21.6703 20.3333 21.3481 20.4311 21.074 20.6142C20.7999 20.7974 20.5863 21.0577 20.4602 21.3622C20.334 21.6667 20.301 22.0018 20.3653 22.3252C20.4296 22.6485 20.5884 22.9454 20.8215 23.1785C21.0546 23.4116 21.3515 23.5703 21.6748 23.6346C21.9981 23.699 22.3332 23.6659 22.6378 23.5398C22.9423 23.4137 23.2026 23.2 23.3858 22.926C23.5689 22.6519 23.6666 22.3296 23.6666 22C23.6666 21.558 23.4911 21.1341 23.1785 20.8215C22.8659 20.5089 22.442 20.3333 22 20.3333Z" fill="#474747"/>
                        </svg>
                        <div *ngIf="statusPopup == i" class="status-popup" click-stop-propagation>

                            <div *ngIf="item.status !=='CONFIRMED'" class="status-row">
                                <span (mousedown)="updateStatus(item, 1)">Confirmed</span>
                            </div>
                            <div *ngIf="item.status !=='NO_BYPASS'" class="status-row">
                                <span (mousedown)="updateStatus(item, 2)">No Bypass</span>
                            </div>
                            <div *ngIf="item.status !=='PENDING'" class="status-row">
                                <span (mousedown)="updateStatus(item, 3)">Pending</span>
                            </div>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="pagination-wrapper">
  <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
</div>
