import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { JobStatus } from 'src/app/models/jobstatus.model';
import { JobstatusService } from 'src/app/services/jobstatus.service';
import { MultiFilterComponent } from '../common-new-design/multi-filter/multi-filter.component';

@Component({
  selector: 'app-job-status-history-new',
  templateUrl: './job-status-history-new.component.html',
  styleUrls: ['./job-status-history-new.component.scss']
})
export class JobStatusHistoryNewComponent implements OnInit {

  public currentPage: number = 1
  public pageSize: number = 10
  public count: number = 0;
  public lastPageSize: number = 1

  public dateFrom: any = '';
  public dateTo: any = '';
  public dtFrom: Date = new Date();
  public dtTo: Date = new Date();
  public maxDate: Date = new Date();

  public jobStatuses: JobStatus[] = []

  public integrationTypes: string[] = []
  public userInput = ''

  public isFocus: boolean = false
  public isDetails: boolean = false
  public selectedJob?: JobStatus
  public dropdownFilter: number = 0
  public filterApplied: boolean = false;
  public allFilters: any = []
  public showFilter: any = false

  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;

  public statusName: string = 'Status'
  public statusValue: number[] = [];
  public statusOptions: { name: string; data: { value: number; name: string }[], dropdown: boolean } = {name: this.statusName, data:[{value: 1, name: 'In Progress'}, {value: 2, name: 'Partial'}, {value: 3, name: 'Completed'}, {value: 4, name: 'Failed'}], dropdown: false}

  public integrationTypeName: string = 'Integration Type'
  public integrationTypeNames: string[] = [];
  public integrationTypesNew: any = { name: this.integrationTypeName, data: [] };


  public masterSelected: boolean = false;
  public checkedList: any = [];

  constructor(
    private toastr: ToastrService,
    private jobStatusService: JobstatusService,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    this.maxDate.setDate(this.maxDate.getDate() + 1);

    this.getDropdownList()
    this.setMainDate()
    this.reloadTable()
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    this.dtFrom.setDate(dte.getDate() - 7);
    this.dtTo.setDate(dte.getDate() + 1);
    this.dateFrom = {year: this.dtFrom.getFullYear(), month: this.dtFrom.getMonth() + 1, day: this.dtFrom.getDate()};
    this.dateTo = {year: this.dtTo.getFullYear(), month: this.dtTo.getMonth() + 1, day: this.dtTo.getDate()};
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page

    let obj = this.generateObject()

    this.jobStatusService.getHistory(obj).subscribe(
      (response: any) => {
        if(response.status == 200) {
          this.jobStatuses = response.data.data
          console.log(this.jobStatuses)
          this.count = response.data.count
          this.lastPageSize = Math.ceil(this.count/this.pageSize)
          this.isAlreadyChecked();
        }
        else{
          this.toastr.error(response.message);
        }
      },
      (error) => {
        this.toastr.error('An error occurred.')
      }
    )
  }

  public getDropdownList(){
    this.jobStatusService.getAllForSelect().subscribe((response: any) => {
      if(response.status == 200){
        this.integrationTypesNew = { name: this.integrationTypeName, data: response.data }
        this.checkFilters()
      }
      else {
        this.toastr.error(response.message);
      }
    })
  }

  checkFilters() {
    this.filterApplied = true;

    let integrationTypeIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.integrationTypesNew.name);
    if (integrationTypeIndex !== -1) {
      this.allFilters[integrationTypeIndex].data = this.integrationTypesNew.data;
    }
    else
    this.allFilters.push(this.integrationTypesNew)

  let statusData = this.allFilters.find((item: { name: any; }) => item.name === this.statusOptions.name);
    let statusIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.statusOptions.name);
    if (statusIndex !== -1)
      this.allFilters[statusIndex].data = this.statusOptions.data;

    console.log(this.statusOptions)

    if (!statusData)
      this.allFilters.push(this.statusOptions)

    this.allFilters = [...this.allFilters]
  }

  generateObject() {
    let obj = {
      pageInfo: {
        pageSize: this.pageSize,
        page: this.currentPage,
      },
      filterParams: {
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        statusTypes: this.statusValue,
        types: this.integrationTypeNames,
        checkedJobs: []
      }
    }

    return obj
  }

  pageChange(value: any) {
    this.currentPage = value
    this.reloadTable()
  }

  updateDate(date: any) {
    this.dateFrom = date.dateFrom
    this.dateTo = date.dateTo
    this.reloadTable()
  }

  public downloadReport() {
    let obj = {
      pageInfo:{
        page: -1,
        pageSize: -1
      },
      filterParams:{
        statusTypes: this.statusValue,
        types: this.integrationTypeNames,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        checkedJobs: this.checkedList
      }
    }

    this.jobStatusService.downloadReport(obj).subscribe(response =>{
      if (response.status == 200) {
        window.location.href = response.data
      }
      else {
        this.toastr.error(response.message)
      }
    }, _=> {
      this.toastr.error("An error occurred")
    })
  }

  public showDetails(status: JobStatus) {
    this.isDetails = true
    this.selectedJob = status
  }

  checkUncheckAll() {
    for (let i = 0; i < this.jobStatuses.length; i++) {
      this.jobStatuses[i].checked = this.masterSelected;
      this.checkForDownload(this.jobStatuses[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.jobStatuses.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.jobStatuses.every(function(item:any) {
      return item.checked == true;
    })
  }

  getStatusValue(status: string) {
    const safeClass = this.sanitizer.sanitize(
      SecurityContext.STYLE,
      status.replace(/\s+/g,'-')
    );
    return safeClass || '';
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)

      if (item.name === this.integrationTypesNew.name) {
        this.integrationTypeNames = filteredValues
      }
      else if (item.name === this.statusOptions.name) {
        this.statusValue = filteredValues
      }
    }
    this.pageChange(1)
  }

}
