import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';
import { WorkOrder } from 'src/app/models/workOrder.model';
import { WorkOrderManagement } from 'src/app/services/work-order-management.service';

@Component({
  selector: 'app-wom-details',
  templateUrl: './wom-details.component.html',
  styleUrls: ['./wom-details.component.scss']
})
export class WomDetailsComponent implements OnInit {

  @Output() changeView: EventEmitter<any> = new EventEmitter()

  @Input() taskId: number = 0

  public task: WorkOrder = new WorkOrder
  public viewsEnum = ViewsEnum
  public activeView: ViewsEnum = ViewsEnum.View


  constructor(
    private workOrderService: WorkOrderManagement,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getWorkOrder()
  }


  public changeViewFromChild(value: ViewsEnum){
    this.changeView.emit(value)
  }


  getWorkOrder(){
    this.workOrderService.getOne(this.taskId).subscribe(response =>{
      this.task = response.data
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }
}
