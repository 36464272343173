import { DictionaryItem } from "./dictionary-item.model";

export class PredictiveF33Analytics{
  feederId : number = 0;
  feederName : string = '';
  feederNo : string = '';
  tsName : string = '';
  status : DictionaryItem = new DictionaryItem();
  voltageDeviation : number | null = 0;
  currentDeviation : number | null = 0;
  factorDeviation : number | null = 0;
  ambTempDeviation : number | null = 0;
  startTime : Date | null = null;
  lastReadSync : Date | null = null;
  ambTemp : number | null = 0;
  voltage : number | null = 0;
  current : number | null = 0;
  factor : number | null = 0;
  ambTempTs : Date | null = null;
  voltageTs : Date | null = null;
  currentTs : Date | null = null;
  factorTs : Date | null = null;
  maxCurrent : number | null = null;
  description : string = '';
  msn : string = '';
  phaseAVoltageImbalance: number = 0
  phaseACurrentImbalance: number = 0
  phaseBVoltageImbalance: number = 0
  phaseBCurrentImbalance: number = 0
  phaseCVoltageImbalance: number = 0
  phaseCCurrentImbalance: number = 0
  phaseVoltageImbalanceEdge: number = 0
  phaseCurrentImbalanceEdge: number = 0
  voltageImbalanceTs : Date | null = null;
  currentImbalanceTs : Date | null = null;
}
