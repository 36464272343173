<div class="details-wrapper">
  <div class="details-main">
    <app-wom-details-main
      (changeView)="changeViewFromChild($event)"
      [task]="task"
    ></app-wom-details-main>
  </div>
  <div class="details-comments">
    <app-wom-details-comments
      [task]="task"
    ></app-wom-details-comments>
  </div>
</div>