import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, fromEvent } from 'rxjs';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommonService } from 'src/app/services/common-service.service';
import { OverdueBillArearsService } from 'src/app/services/overdue-bill-arears.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { OverdueBillsPopupComponent } from '../overdue-bills-popup/overdue-bills-popup.component';
import { OverdueBillArearsModalData } from 'src/app/models/revenue-modal-data';
import { transformToString } from 'src/app/helpers/dateUtils';
import { PaginationComponent } from 'src/app/components/common-new-design/pagination/pagination.component';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';

@Component({
  selector: 'app-overdue-bill-arears',
  templateUrl: './overdue-bill-arears.component.html',
  styleUrls: ['./overdue-bill-arears.component.scss']
})
export class OverdueBillArearsComponent implements OnInit {

  public PermissionsEnum = PermissionsEnum;
  public showPaging: boolean = false;

  clickObservable: Observable<Event> = fromEvent(document,'click');
  private ngUnsubscribe = new Subject<void>();

  @ViewChild(OverdueBillsPopupComponent) popupComponentRef!: OverdueBillsPopupComponent;
  public importPopupOpen: boolean = false;

  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild(PaginationComponent) paginationComponentRef!: PaginationComponent;
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent


  public fileChosen: any = null;
  public file: any = null;
  public dropdownIsOpen = false;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public count1: number = 0;
  public lastPageSize: number = 1;

  public masterSelected: boolean = false;
  public checkedList: number[] = [];

  public debtId: number = 0;
  public phoneId: number = 0;

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');

  public debtRangeName: string = 'Debt Range';
  public phoneName: string = 'Phone';

  public buSelected: any = {value: 0, name: '', checked: false}
  public utIds: any = [];

  public businessUnits: any = { name: this.buName, data: [], dropdown: true};
  public undertakings: any = { name: this.utName, data: [] };
  public debtRangeOptions: { name: string; data: { value: number; name: string }[], dropdown: boolean } = {name: this.debtRangeName, data:[{value: 1, name: '20K-100K'}, {value: 2, name: '100K-200K'}, {value: 3, name: '200K-Above'}], dropdown: true}
  public phoneDropdownOptions: { name: string; data: { value: number; name: string }[], dropdown: boolean} = {name:this.phoneName, data: [{value: 1, name: 'All'}, {value: 2, name: 'Yes'}, {value: 3, name: 'No'}], dropdown: true}

  public phoneDropdownSelected:number= 1;
  public phoneDropdownMsg:string="Phone";
  public searchText: SearchAutocomplete = new SearchAutocomplete();
  public debtRange!: number;

  public firstCheckbox: boolean = false;
  public secondCheckbox: boolean = false;
  public thirdCheckbox: boolean = false;

  public fromDebt: number = 0;
  public toDebt: number = 0;

  public currentDebtCount: number = 0;
  public countOfDebtors: number = 0;

  filterBuClicked: boolean = false;
  filterUtClicked: boolean = false;
  filterDebtClicked: boolean = false;
  filterPhoneClicked: boolean = false;

  public filter: number = 0;

  public overdueBillArears: any;
  public overdueBillArear: OverdueBillArearsModalData = new OverdueBillArearsModalData;
  options = ['']

  //multiFilter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;

  public dateFrom: any = ''
  public dateTo: any = ''

  constructor(private translationService: TranslationService, private toastr: ToastrService, public authService: AuthService, private businessUnitService: BusinessUnitService,
    private undertakingService: UndertakingService, private accountService: AccountService,
    private overdueBillArearsService: OverdueBillArearsService, private commonService: CommonService, private location: Location
) { }

  setFilters(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);

      if (item.name === this.undertakings.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.undertakings = item
        this.utIds = filteredValues
        this.pageChange(1);
      }
    }
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    this.reloadTable(1);
    this.checkedList = [];
  }

  setMainDate() {
    let date = new Date()

    date.setHours(2, 0, 0)

    this.dateTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: 1 };
    date.setMonth(date.getMonth() - 1);
    this.dateFrom = { year: date.getFullYear(), month: date.getMonth() + 1, day: 1 };
  }

  checkFilters() {
    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);

    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;

    if (buIndex === -1) {
      this.allFilters.push(this.businessUnits)

      this.allFilters.push(this.undertakings)

      this.filterApplied = true
    }
    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakings.name);
    if (utIndex !== -1)
      this.allFilters[utIndex].data = this.undertakings.data;

    let debtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.debtRangeOptions.name);
    if (debtIndex !== -1)
      this.allFilters[debtIndex].data = this.debtRangeOptions.data;
    else
      this.allFilters.push(this.debtRangeOptions)

    let phoneIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.phoneDropdownOptions.name);
    if (phoneIndex !== -1)
      this.allFilters[phoneIndex].data = this.phoneDropdownOptions.data;
    else
      this.allFilters.push(this.phoneDropdownOptions)

    this.allFilters = [...this.allFilters]
  }

  getOptions(searchText: SearchAutocomplete) {
    if (searchText.name.length > 2) {
        this.overdueBillArearsService.getOverduesForAutocomplete(searchText.name)
          .subscribe(response => {
            this.options = response.data
          })
    } else
      this.searchComponentRef.resetOptions()
  }

  ngOnInit(): void {
    this.setMainDate();
    this.getBusForSelect();
    this.reloadTable();
    this.subscribeToClickEvent();
    this.showPaging=true;

  }

  selectPrepaidCustomer(id: number, e: Event){
    e.preventDefault();
    this.overdueBillArearsService.get(id).subscribe(response => {
    this.overdueBillArear = response.data;
    this.openTab()
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  openTab(){
    this.popupComponentRef.changeState(1, this.overdueBillArear);
  }
  closeTab(){
    this.popupComponentRef.changeState(2, this.overdueBillArear);
  }

  getCurrentTotalDebt(obj:any){
    this.overdueBillArearsService.getCurrentTotalDebt(obj).subscribe(response => {
      this.currentDebtCount = response.data
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  getBusForSelect(){
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data, dropdown: true }
      this.checkFilters();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  dropdownSelected(data: any){
    if(!data)
      return
    if(data.data.name === this.buName)
      this.businessUnitSelected(data.item)
    if(data.data.name === this.debtRangeOptions.name)
      this.debtSelected(data.item)
    if(data.data.name === this.phoneDropdownOptions.name)
      this.phoneSelected(data.item)
  }

  businessUnitSelected(bu: any){
    this.buSelected = bu;
    if (this.buSelected.value == 0){
      this.undertakings.data.splice(0);
      this.checkFilters();
    }
    this.undertakingService.getAllByBuId(this.buSelected.value).subscribe(response => {
      this.undertakings = { name: this.utName, data: response.data };
      this.pageChange(1);
      this.checkFilters();
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  debtSelected(data: any){
    this.dropdownIsOpen = false;

    if (data.value == 1) {
      this.firstCheckbox = true;
      this.fromDebt = 20000;
      this.toDebt = 100000;
    }
    else
      this.firstCheckbox = false;

    if (data.value == 2) {
      this.secondCheckbox = true;
      this.fromDebt = 100000;
      this.toDebt = 200000;
    }
    else
      this.secondCheckbox = false;

    if (data.value == 3) {
      this.thirdCheckbox = true;
      this.fromDebt = 200000;
    }
    else
      this.thirdCheckbox = false;

      this.debtRange = data.value;
      this.debtId = data.value;
      this.debtRangeName = this.debtRangeOptions.data.find(option => option.value === data.value)?.name || 'Debt Range';

    this.reloadTable();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null){
    if(page)
      this.currentPage = page;

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      FilterParams:{
        BusinessUnitId: this.buSelected.value,
        UtIds: this.utIds,
        FirstCheckbox: this.firstCheckbox,
        SecondCheckbox: this.secondCheckbox,
        ThirdCheckbox: this.thirdCheckbox,
        FromDebt: this.fromDebt,
        ToDebt: this.toDebt,
        Search: this.searchText.name,
        Phone:this.phoneDropdownSelected,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo)
      }
    };
    this.getCurrentTotalDebt(obj.FilterParams);
    this.count1 = 0;

    this.overdueBillArearsService.getAll(obj).subscribe(response => {
      this.overdueBillArears = response.data.data;
      this.isAlreadyChecked();
      if(this.currentPage == 1){
        this.count = response.data.count;
        this.countOfDebtors = response.data.count;
      }
      this.showPaging=false;

      this.lastPageSize = Math.ceil(this.count/this.pageSize)
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.showPaging=true;

  }

  filterClicked(value: number){
    if(this.filter === value){
      this.filter = 0
    }
    else{
      this.filter = value
    }
  }

  inputChanged(searchText: SearchAutocomplete){
    this.currentPage = 1
    this.searchText = searchText
    this.reloadTable();
    this.checkedList = [];
  }

  selectFilter(e: Event){
    this.dropdownIsOpen = false;

    const firstCheckbox = document.getElementById('firstCheckbox',) as HTMLInputElement | null;
    if (firstCheckbox?.checked == true)
      this.firstCheckbox = true;
    else
      this.firstCheckbox = false;

    const secondCheckbox = document.getElementById('secondCheckbox',) as HTMLInputElement | null;
    if (secondCheckbox?.checked == true)
      this.secondCheckbox = true;
    else
      this.secondCheckbox = false;

    const thirdCheckbox = document.getElementById('thirdCheckbox',) as HTMLInputElement | null;
    if (thirdCheckbox?.checked == true)
      this.thirdCheckbox = true;
    else
      this.thirdCheckbox = false;

    this.reloadTable();
    this.checkedList = [];
  }

  onFileChosen(event: any){
    console.log(event)
    this.file = event.target.files[0];
  }

  isFileChosen(){
    if(this.fileChosen)
      return true;

    return false;
  }

  uploadFile(){
    if(!this.isFileChosen){
      this.toastr.error('You have to choose file for upload.');
      return;
    }
    // this.modalService.dismissAll();

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.overdueBillArearsService.uploadFile(formData).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.closeModal.nativeElement.click() // Closing modal after success
      }else{
        this.toastr.error(response.message);
        if(response.data && response.data.length > 0)
           window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
      }
        this.reloadTable();
        this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  getTemplate(){
    this.overdueBillArearsService.getTemplate().subscribe(response => {

      console.log("response",response)

      if (response.status == 200) {
        if (response.data && response.data.length > 0)
            window.location.href = response.data;
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  downloadReport(){
    let obj = {
      businessUnitId: this.buSelected.value,
      UtIds: this.utIds,
      FirstCheckbox: this.firstCheckbox,
      SecondCheckbox: this.secondCheckbox,
      ThirdCheckbox: this.thirdCheckbox,
      FromDebt: this.fromDebt,
      ToDebt: this.toDebt,
      search: this.searchText.name,
      phone: this.phoneDropdownSelected,
      checkedList: this.checkedList,
      dateFrom: transformToString(this.dateFrom),
      dateTo: transformToString(this.dateTo)
    };
    this.downloadMonthlyReport(obj);
  }
  downloadAndSendReport(email: string){
    let obj = {
      businessUnitId: this.buSelected.value,
      UtIds: this.utIds,
      FirstCheckbox: this.firstCheckbox,
      SecondCheckbox: this.secondCheckbox,
      ThirdCheckbox: this.thirdCheckbox,
      FromDebt: this.fromDebt,
      ToDebt: this.toDebt,
      search: this.searchText.name,
      phone: this.phoneDropdownSelected,
      checkedList: this.checkedList,
      email: email,
      dateFrom: transformToString(this.dateFrom),
      dateTo: transformToString(this.dateTo),
    };
    this.downloadAndSendMonthlyReport(obj);
  }

  downloadMonthlyReport(obj: any){
    this.overdueBillArearsService.downloadOverdueBillArearsReport(obj).subscribe(response => {
      window.location.href = response.data
    }, error => {
      this.toastr.error('An error occured');
    })
  }

  downloadAndSendMonthlyReport(obj: any){
    this.overdueBillArearsService.downloadAndSendOverdueBillArearsReport(obj).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occured');
    })
  }

  private subscribeToClickEvent() {
    this.clickObservable
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((e: any) => {
     this.closeAllFilters(e)
      this.closeTab()
    })
  }

  closeAllFilters(e: any){
    if(e.srcElement.id !== 'filter-inner'){
      this.filterBuClicked = false;
      this.filterUtClicked = false;
      this.filterDebtClicked = false;
      this.filterPhoneClicked = false;
    }
  }

  sendSms(){
    if(!this.authService.isAuthorized(PermissionsEnum.revenueprotection_overduebillarears_sendsms))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    this.overdueBillArearsService.sendSms(this.checkedList).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message)
        this.masterSelected= false;
        this.count1 = 0;
        if(response.data && response.data.length > 0) //download file
          window.location.href = response.data
      }
      else
      {
        this.toastr.error(response.message);
        if(response.data && response.data.length > 0) //download file
          window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
      }
      this.reloadTable();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  sendMail(){
    let obj = {
      businessUnitId: this.buSelected.value,
      UtIds: this.utIds,
      FirstCheckbox: this.firstCheckbox,
      SecondCheckbox: this.secondCheckbox,
      ThirdCheckbox: this.thirdCheckbox,
      FromDebt: this.fromDebt,
      ToDebt: this.toDebt,
      search: this.searchText.name,
      phone: this.phoneDropdownSelected,
      checkedList: this.checkedList,
      // email: this.email
    };
    this.sendMailOverdueBillArears(obj);
  }

  sendMailOverdueBillArears(obj: any){
    this.overdueBillArearsService.sendMailOverdueBillArears(obj).subscribe(response => {
      if(response.status == 200){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occured');
    })
  }

  phoneSelected(data: any){
    this.dropdownIsOpen = false;

    if(data.value == 2){
      this.phoneDropdownMsg = 'Yes';
    }
    else if(data.value == 3){
      this.phoneDropdownMsg = 'No';
    }
    else{
      this.phoneDropdownMsg = '';
    }
    this.phoneDropdownSelected = data.value;
    this.phoneId = data.value;
    this.phoneDropdownMsg = this.phoneDropdownOptions.data.find(option => option.value === data.value)?.name || 'Phone';
    this.pageChange(1);
  }

  changed() {
    this.count1 = 0;
    this.overdueBillArears.forEach((item: { [x: string]: any; }) => {
    if (item['checked']) {
      this.count1 = this.count1 + 1;
    }
  })
  }
  changeImportPopupState(){
    this.importPopupOpen = !this.importPopupOpen;
  }

  public copyAccountNumber(accountNumber : any){
    navigator.clipboard.writeText(accountNumber)
    this.toastr.success('Copied to clipboard')
  }

  checkUncheckAll() {
    for (let i = 0; i < this.overdueBillArears.length; i++) {
      this.overdueBillArears[i].checked = this.masterSelected;
      this.checkForDownload(this.overdueBillArears[i].accountId, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.overdueBillArears.forEach((x: { accountId: number; checked: boolean; }) => {
      let index = this.checkedList.indexOf(x.accountId)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.overdueBillArears.every(function(item:any) {
      return item.checked == true;
    })
  }
}
