import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AuthService } from 'src/app/services/auth.service';
import { MeterService } from 'src/app/services/meter.service';

@Component({
  selector: 'app-token-loading',
  templateUrl: './token-loading.component.html',
  styleUrl: './token-loading.component.scss'
})
export class TokenLoadingComponent implements OnInit {
  @Input() meterId: number = 0;

  userGuid: string = '';
  // meterId: number = 30;

  public searchPlaceholder: any = "Search by Token";
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent
  options = ['']

  public dateFrom: any = ''
  public dateTo: any = ''
  public dtFrom: Date = new Date();
  public dtTo: Date = new Date();
  public maxDate: Date = new Date();

  public tokenValue: string = '';
  public previousTokens: any;

  fileChosen: File | null = null;
  fileName:string="";
  constructor(private authService: AuthService, 
    private meterService: MeterService,
    private toastr: ToastrService,
    ){}

  ngOnInit(): void {
    this.userGuid = this.authService.getCurrentUser()?.userGuid;
    this.maxDate.setDate(this.maxDate.getDate());
    this.setMainDate();
    this.getHistory();
  }

  getHistory(){
    let obj = {
      meterId: this.meterId,
      dateFrom: transformToString(this.dateFrom),
      dateTo: transformToString(this.dateTo),
      search: this.searchText.name
    };
    this.meterService.getMeterTokenHistory(obj).subscribe(response => {
      console.log(response)
      this.previousTokens = response.data
      console.log(this.previousTokens)
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    this.dtFrom.setDate(dte.getDate() - 7);
    this.dtTo.setDate(dte.getDate());
    this.dateFrom = {year: this.dtFrom.getFullYear(), month: this.dtFrom.getMonth() + 1, day: this.dtFrom.getDate()};
    this.dateTo = {year: this.dtTo.getFullYear(), month: this.dtTo.getMonth() + 1, day: this.dtTo.getDate()};
  }

  searchChange(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.getHistory()
  }

  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
    }
    if (searchText.name.length > 2) {
      this.meterService.getTokenDataForAutocomplete(obj)
        .subscribe(response => {
          this.options = response.data
        })
  } else
    this.searchComponentRef.resetOptions()
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    this.getHistory()
  }

  generateObject(){
    let obj = {
      userGuid: this.userGuid,
      tokenValue: this.tokenValue,
      meterId: this.meterId
    }
    console.log(obj)
    return obj
  }

  loadToken(){
    let obj = this.generateObject();
    this.meterService.loadToken(obj).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.getHistory();
        this.tokenValue = '';
      }else
        this.toastr.error(response.message);
      }, error => {
        this.toastr.error('An error occurred.');
    });
  }

  onFileChosen(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.fileChosen = inputElement.files[0];
      this.fileName=this.fileChosen.name;
    }
  }

  copyToClipboard(obj: any){
    navigator.clipboard.writeText(obj)
    this.toastr.success('Copied to clipboard')
  }
}
