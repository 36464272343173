import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from 'src/app/components/common-new-design/pagination/pagination.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { DtService } from 'src/app/services/dt.service';
import { TranslationService } from 'src/app/services/translation.service';
import { DtCurrentActivePopupComponent } from './dt-active-current-popup/dt-active-current-popup.component';

@Component({
  selector: 'app-current-active-dt',
  templateUrl: './current-active-dt.component.html',
  styleUrl: './current-active-dt.component.scss'
})
export class CurrentActiveDTComponent {
  @ViewChild(PaginationComponent) paginationComponentRef!: PaginationComponent;

  @Input() f11Ids: number[] = []
  @Input() categoryId: number = -1
  @Input() buId: number= 0
  @Input() utIds: number[] = []

  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''

  public pageSize: number = 10
  @Input() currentPage: number = 1
  public lastPageSize: number = 1;
  public selectedDt: any = null;

  public data: any[] = []
  count: number = 0
  public checkedList: number[] = [];

  public masterSelected: boolean = false;
  public avgAvailabilityOpened: boolean = false
  public avgAvailability: number = 0
  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase()
  public utName: string = this.translationService.getByKeyFromCache('UT').toUpperCase()

  @Input() tabMenu!: number;
  @Output() redirect: EventEmitter<any> = new EventEmitter()
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()
  @ViewChild(DtCurrentActivePopupComponent) popupRef!: DtCurrentActivePopupComponent;

  constructor(
    private dtService: DtService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private detectionChange: ChangeDetectorRef
  ) { }

  viewMore(dt: any) {
    this.openTab(dt)
  }
  openTab(selectedDt: any) {
    this.popupRef.changeState(selectedDt, 1);
  }
  closeTab() {
    this.popupRef.changeState(this.selectedDt, 2);
  }

  public avgSelected(value: number) {
    this.currentPage = 1
    this.avgAvailability = value
    this.GetData()
    this.avgAvailabilityOpened = false;
  }

  public avgAvailabilityClicked(open: number) {
    if (open == 0)
      this.avgAvailabilityOpened = false
    else {
      if (this.avgAvailabilityOpened) {
        this.avgAvailabilityOpened = false
      }
      else {
        this.avgAvailabilityOpened = true
      }

    }
  }
  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false

    if (
      changes.buId !== undefined || changes.utIds !== undefined ||
            changes.f11Ids !== undefined ||
      changes.categoryId !== undefined ||
      changes.dateFrom !== undefined || changes.dateTo !== undefined ||
      changes.durationFrom !== undefined || changes.durationTo !== undefined ||
      changes.searchText !== undefined || changes.currentPage !== undefined
    ) {
      reloadTable = true;
    }
    if (this.searchText != null) {
      reloadTable = true
    }

    if (changes.menu !== undefined || changes.tabMenu !== undefined) {
      reloadTable = true;
      this.resetFilters();
      this.checkedList = [];
    }

    if (reloadTable) {
      this.checkedList = [];
      this.pageChange(1)
    }

  }
  resetFilters() {
    this.categoryId = 0;
     this.f11Ids = []
    this.buId = 0

    this.detectionChange.detectChanges()
  }

  public GetData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        OutageCategory: this.categoryId,
        BuId: this.buId,
        Feeder11Ids: this.f11Ids,
        UtIds: this.utIds,
        Search: this.searchText ? this.searchText.name : this.searchText
      }
    };

    this.GetCurrentActiveForDt(obj);
  }

  download() {
    let obj = {
      pageInfo: {
        page: 0,
        pageSize: this.pageSize
      },
      filterParams: {
        OutageCategory: this.categoryId,
        BuId: this.buId,
        Feeder11Ids: this.f11Ids,
        UtIds: this.utIds,
        outageIds: this.checkedList,
        Search: this.searchText ? this.searchText.name : this.searchText,
        avgAvailability: this.avgAvailability,

      }
    };
    console.log("check",this.checkedList)
    this.dtService.downloadCurrentActiveReport(obj).subscribe(response => {
      if(response.status === 200){
        window.location.href = response.data;
      }else if(response.data===null){
        this.toastr.info(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
      this.checkForDownload(this.data[i].id, true);
    }
  }
  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.data.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item:any) {
      return item.checked == true;
    })
  }

  public pageChange(page: number) {
    this.currentPage = page;
    this.GetData()
  }
  private GetCurrentActiveForDt(obj: any) {
    this.dtService.GetCurrentActive(obj).subscribe(response => {
      if (response.status == 200) {
        this.data = response.data.data
        if (obj.pageInfo.page == 1)
          this.count = response.data.count
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
        this.isAlreadyChecked();
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An error occurred")
    });
  }
}
