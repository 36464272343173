import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardData } from 'src/app/models/dashboard-data.model';
import { DashboardService } from 'src/app/services/dashboard.service';
import { PaginationComponent } from '../../common-new-design/pagination/pagination.component';

@Component({
  selector: 'app-grid-losses-analysis',
  templateUrl: './grid-losses-analysis.component.html',
  styleUrls: ['./grid-losses-analysis.component.scss']
})
export class GridLossesAnalysisComponent implements OnInit {

  public currentPage = 1
  public pageSize = 4
  public count = 0
  public lastPageSize: number = 1;

  @ViewChild('pagination') paginationComponent!: PaginationComponent;

  public slicedItems: any[] = []
  public toggleLosses: number = 1

  public tab1: string =  '11KV Feeder : DT Losses'     
  public tab2: string = 'DT : Customer Losses' 

  public tab1Active: boolean = true;

  public lossesArray: DashboardData[] = []

  public feeder11KVLosses: DashboardData[] = []
  public dtLosses: DashboardData[] = []

  constructor(
    private dashboardService: DashboardService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.fetchGridLossesAnalysis11KVFeeder()
  }

  fetchGridLossesAnalysis11KVFeeder() {  
    this.dashboardService.getGridLossesAnalysis11KVFeeder().subscribe(x => {
      if (x.status !== 200) {
        this.toastr.error(x.message)
        return
      }
      this.feeder11KVLosses = x.data
      if (this.toggleLosses == 1)
      {
        this.lossesArray = this.feeder11KVLosses
        this.slicedItems = this.customSlice(this.lossesArray, 4, this.currentPage - 1)
        if(this.currentPage == 1){
          this.count = this.feeder11KVLosses.length
          this.lastPageSize = Math.ceil(this.count / this.pageSize);
        }
      }
    })
  }

  fetchGridLossesAnalysisDt(){
    this.dashboardService.getGridLossesAnalysisDt().subscribe(x => {
      if (x.status !== 200) {
        this.toastr.error(x.message)
        return
      }
      this.dtLosses = x.data;
      if (this.toggleLosses == 2)
      {
        this.lossesArray = this.dtLosses;
        this.slicedItems = this.customSlice(this.lossesArray, 4, this.currentPage - 1)
        if(this.currentPage == 1){
          this.count = this.dtLosses.length
          this.lastPageSize = Math.ceil(this.count / this.pageSize);
        }
      }
    })
  }

  customSlice(items: any[], pageSize: number, currentPage: number): any[] {
    const startIndex = (currentPage) * pageSize
    const endIndex = startIndex + pageSize
    return items.slice(startIndex, endIndex)
  }

  pageChanged(page: any) {
    this.currentPage = page

    if (this.toggleLosses == 1)
      this.fetchGridLossesAnalysis11KVFeeder()
    else
      this.fetchGridLossesAnalysisDt()

    return page
  }

  filterClicked(value: number) {
    if (this.toggleLosses == value)
      return

    this.toggleLosses = value

    if(value == 1)
      this.tab1Active = true;
    else
      this.tab1Active = false;

    // this.pageChanged(1)
    this.paginationComponent?.changePage(1)
  }

}
