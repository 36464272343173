import { Feeder11 } from 'src/app/models/feeder11.model';
import { Feeder33 } from 'src/app/models/feeder33.model';

export class Dt{
    id: number = 0;
    name: string = '';
    number: string = '';
    address: string = '';
    substationId: number = 0;
    amiId: number = 0;
    amiName:string = '';
    meterNumber:  string = '';
    nameplateRating: string = '';
    feederType : number = 0;
    feederName11 : string = '';
    feeder11Id? : number;
    feeder33Id : number = 0;
    feederName33 : string = '';
    dtMeters : DtMeter[] = [];
    dtFeeders : DtFeeder[] = [];
    latitude : string = '';
    longitude : string = '';
    dtMeterType: number = 0;

    isPublic: number = 0;
    connectedBu: string = '';
    connectedUt: string = '';
    connectedSs : string = '';
    connectedUtId: number = 0;
    dssNo: string = '';
    dssId: string = '';
    dssCap: string = '';
    cisDtNo: string = '';
    dssName: string = '';
    instlPosition: string = '';
    secMaxCurr: string = '';
    checked: boolean = false;
    closestLandmark: string = '';
    meterVoltage: number = 0;
    meterMaxDigit?: number;
    multiplierFactor?: number;
}

export class DtMeter{
    meterNumber:  string = '';
    fromDate : Date = new Date();
    toDate : Date = new Date();
}

export class DtFeeder{
    feeder?:  Feeder11 = new Feeder11();
    feeder33?:  Feeder33 = new Feeder33();
    fromDate : Date = new Date();
    toDate : Date = new Date();
}

