import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Concentrator } from 'src/app/models/concentrator.model';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { ConcentratorService } from 'src/app/services/concentrator.service';
import { CrudService } from 'src/app/services/crudService.service';
import { TranslationService } from 'src/app/services/translation.service';
import { CrudDeleteModalComponent } from '../crud-delete-modal/crud-delete-modal.component';
import { DcuCreateComponent } from './dcu-create/dcu-create.component';

@Component({
  selector: 'app-dcu-tabs',
  templateUrl: './dcu-tabs.component.html',
  styleUrls: ['./dcu-tabs.component.scss']
})
export class DcuTabsComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public masterSelected: boolean = false;

  public searchBySerialNumber: string = '';
  private ngUnsubscribe = new Subject<void>();
  public selectedId: number = 0;

  private searchText: string = "";
  public searchByAddress: string = '';
  public searchByAmi: number = 0;
  public isOnline: any = null;

  public concentrators: Concentrator[] = [];
  public searchConcentrators: SearchAutocomplete[] = [];
  public selectedConcentrator: Concentrator = new Concentrator();
  public amis: any = [];
  public checkedList: any = []

  public fileChosen: any = null;
  public file: any = null;
  public concentratorName: string = this.translationService.getByKeyFromCache('Concentrator');
  public amiName: string = this.translationService.getByKeyFromCache('AMI');
  public lastPageSize: number = 1;
  @ViewChild(DcuCreateComponent) popupComponentRef!: DcuCreateComponent;

  @ViewChild("f") f: any;

  @Output() amisSelect = new EventEmitter();
  @Output() editTab = new EventEmitter();
  @Output() deleteComp = new EventEmitter();

  constructor(private concentratorService: ConcentratorService,
    private toastr: ToastrService,
    private crudService: CrudService,
    private translationService: TranslationService,
    public authService: AuthService,
    private commonService: CommonService,
    private amiService: AmiService, private location: Location) { }

  ngOnInit(): void {
    this.reloadTable();
    this.getAllForSelect();
    this.subscribeToSearch();

  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }
  back() {
    this.location.back();
  }
  getAllForSelect() {
    this.amiService.getAllForSelect().subscribe(response => {
      this.amis = response.data;
      this.amisSelect.emit(this.amis);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  reloadTable(page: any = null) {
    console.log("SGSgs")
    if (page)
      this.currentPage = page;

    this.resetSelectedConcentrator();

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };

    if (!obj.filterParams.SearchContent){
      this.concentratorService.getAllForSelect().subscribe(response => {
        this.searchConcentrators = []
        response.data.forEach((x : DictionaryItem) =>
        {
          let item = new SearchAutocomplete();
          item.value = x.value;
          item.name = x.name;
          this.searchConcentrators.push(item)
          })
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }

    this.concentratorService.getAll(obj).subscribe(response => {
      this.concentrators = response.data.data;
      if(obj.pageInfo.page == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      this.isAlreadyChecked();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  selectConcentrator(id: number) {
    this.concentratorService.get(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedConcentrator = response.data;
        this.editTab.emit(this.selectedConcentrator);
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedConcentrator() {
    this.selectedConcentrator = new Concentrator();

    if (this.f)
      this.f.submitted = false;
  }

  openTabDelete(id:number) {
    this.concentratorService.get(id).subscribe(response => {
     if(response.status === 200){
       this.selectedConcentrator = response.data;
       this.deleteComp.emit();
     }
     else
       this.toastr.error(response.message);
   }, error => {
     this.toastr.error('An error occurred.');
   });
   this.selectedId = id;
 }

 onDeleteConfirmed( ) {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_delete)) {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.concentratorService.delete(this.selectedId).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.currentPage = 1;
        this.reloadTable();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  onFileChosen(event: any) {
    this.file = event.target.files[0];
  }

  isFileChosen() {
    if (this.fileChosen)
      return true;

    return false;
  }

  uploadFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.concentratorService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.result.data;

      }
      this.reloadTable();
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  getTemplate() {
    this.concentratorService.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch() {
    this.crudService.globalSearch$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchValue => {
        if (searchValue.clicked && searchValue.componentName === ComponentNamesNew.ConcentratorComponent) {
          this.searchText = searchValue.text;
          this.currentPage = 1;
          this.checkedList = [];
          this.reloadTable();
          return;
        }
        this.searchText = '';
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkUncheckAll() {
    for (let i = 0; i < this.concentrators.length; i++) {
      this.concentrators[i].checked = this.masterSelected;
      this.checkForDownload(this.concentrators[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.concentrators.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.concentrators.every(function(item:any) {
      return item.checked == true;
    })
  }

  downloadItems(){
    let obj = {
      Ids: this.checkedList,
      activate: false
    };
    this.concentratorService.download(obj).subscribe(response => {
      if (response.status === 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.")
        }
        else {
          window.location.href = response.data
          this.toastr.success("Successfully downloaded DCUs.");
        }
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
