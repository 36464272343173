import { Location } from '@angular/common';
import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { UserService } from 'src/app/services/user.service';
import { CropperDialogResult, ImageCropperDialogComponent } from '../common/image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'app-user-profile-managament',
  templateUrl: './user-profile-managament.component.html',
  styleUrls: ['./user-profile-managament.component.scss']
})
export class UserProfileManagamentComponent implements OnInit {
  public userForm: boolean = false;

  uploadFile!: File | null;
  uploadFileUrl :string = ''
  file!: string | ArrayBuffer;

  public updateProfile: boolean = false;
  mainForm: boolean = true;

  name: string = '';
  lastName: string = '';
  expandSidebar: boolean = true;


  id: number = 0;

  public user: User = new User();
  public loggedInUser: User = new User();

  public email:any='';
  public eyeImage: any = "assets/images-new-design/eye-closed.svg";
  public eyeImage2: any = "assets/images-new-design/eye-closed.svg";
  public eyeImage3: any = "assets/images-new-design/eye-closed.svg";

  public avatar: any = "assets/images-new-design/Avatar.png";

  public inputType: any = "password";
  public inputType2: any = "password";

  public inputType3: any = "password";
  public newPassword: any = '';
  public initial: string = '';
  confirmNewPassword: any = '';
  previewImage: string  = '';

  public croppedImageFlag: boolean = false;

  public emailValid: boolean = true;
  dialog: MatDialog = inject(MatDialog);
  croppedImage = signal<CropperDialogResult | undefined>(undefined);
  imageSource = computed(() => {
    if (this.croppedImage()) {
        this.croppedImageFlag = true;
        return this.croppedImage()?.imageUrl;
    }
    return '';
  });
  fileName: string = '';

  constructor(private authService: AuthService, private toastr: ToastrService, private commonService: CommonService,private location:Location,
    private sanitizer: DomSanitizer,
    private userService: UserService) { }

  ngOnInit(): void {
    this.name = this.authService.getCurrentUser()?.firstName;
    this.email = this.authService.getCurrentUser()?.email;
    this.id = this.authService.getCurrentUser()?.id;

    this.get();
  }
  onFileDropped($event: any) {

  }

  onFileChosen(event: any) {
    this.file = event.target.files[0];

  }
  handleDragOver(event: Event) {
    event.preventDefault();
    // Add styling to indicate that the area is droppable
  }

  handleDragLeave(event: Event) {
    event.preventDefault();
    // Remove any styling applied during dragover
  }

  handleDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;

    if (files && files.length > 0) {
      // Handle dropped files (upload, preview, etc.)
      console.log('Dropped files:', files);
    }
  }
  handleFileInput(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      this.handleImageFile(files[0]);
    }
  } private handleImageFile(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      // this.previewImage = reader.result;
    };
    reader.readAsDataURL(file);
  }

  handleUploadForm() {
    let data = new FormData();
    data.append('file', this.uploadFile!, this.uploadFile?.name);
    this.toastr.success("Pic uploaded successfuly.")
    //this.savePic(data)

  }
  changeEye1() {
    if (this.eyeImage === "assets/images-new-design/eye-open.svg") {
      this.eyeImage = "assets/images-new-design/eye-closed.svg";
      this.inputType = "password";
    }
    else {
      this.eyeImage = "assets/images-new-design/eye-open.svg";
      this.inputType = "text"
    }
  }
  changeEye2() {
    if (this.eyeImage2 === "assets/images-new-design/eye-open.svg") {
      this.eyeImage2 = "assets/images-new-design/eye-closed.svg";
      this.inputType2 = "password";
    }
    else {
      this.eyeImage2 = "assets/images-new-design/eye-open.svg";
      this.inputType2 = "text"
    }
  }
  changeEye3() {
    if (this.eyeImage3 === "assets/images-new-design/eye-open.svg") {
      this.eyeImage3 = "assets/images-new-design/eye-closed.svg";
      this.inputType3 = "password";
    }
    else {
      this.eyeImage3 = "assets/images-new-design/eye-open.svg";
      this.inputType3 = "text"
    }
  }
  get() {
    this.userService.getByEmail(this.email).subscribe(response => {
      if (response.status === 200) {
        this.loggedInUser = response.data;
        let name = this.loggedInUser.firstName ? this.loggedInUser.firstName[0] : '/';
        let lastName = this.loggedInUser.lastName ? this.loggedInUser.lastName[0] : '/';

        this.initial = name + lastName;
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  onClick(tab: number) {
    this.updateProfile = false;
    this.userForm = false;
    this.mainForm = false;

    if (tab == 1) {
      this.updateProfile = true;
    } else if (tab == 2) {
      this.userForm = true;
    } else {
      this.uploadFile = null
      this.mainForm = true;
      this.get();
      this.user.oldPassword = '';
      this.newPassword = '';
      this.confirmNewPassword = '';
      this.croppedImageFlag = true;
    }
  }
  fileBrowserHandler($event: any) {
    this.uploadFile = $event.target.files.item(0);
    const reader = new FileReader();
    this.file = this.uploadFile!.name;
    reader.readAsDataURL(this.uploadFile!);
  }
  deletePicture() {
    this.userService.deletePicture(this.loggedInUser.userGuid).subscribe(response => {
      if (response.status === 200) {
        let pom = response.data;
        this.toastr.success(response.message);
        window.location.reload();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  savePic($event?: any) {
    const file: File = $event.target.files[0];
    this.fileName = '';

    if (file) {
        // Open the image crop dialog
        const dialog = this.dialog.open(ImageCropperDialogComponent, {
            data: {
                image: file,
                dialogTitle: 'Crop the profile photo',
                isRoundImage: true
            }
         });

          dialog.afterClosed().subscribe((result) => {

            if (result !== undefined) {
                this.croppedImage.set(result);
                this.uploadFile = result.blob;
                this.fileName =  file.name;
            }
        });
    }
  }

getSafeImageUrl(url: string): SafeUrl {
  return this.sanitizer.bypassSecurityTrustUrl(url);
}
  save($event?: any) {
    this.user.image = this.uploadFile;
    this.user.email = this.loggedInUser.email;
    if($event=='changePassword'){
    if(this.newPassword == this.user.oldPassword)
    {
      this.toastr.error("Current password and new password cannot be the same.");
      return;
    }
    if (this.newPassword != this.confirmNewPassword) {
      this.toastr.error("Passwords must match");
      return;
    }
    if((this.user.oldPassword == null || this.user.oldPassword  == '')){
      this.toastr.error("Current password field cannot be empty.");
      return;
    }
    this.validateNewPassword();
    this.user.password = this.newPassword;
  }
    this.userService.updateProfile(this.user, this.uploadFile, this.fileName).subscribe(response => {
      if (response.body.status === 200) {
        this.toastr.success(response.body.message);
        if($event!='image'){
          this.authService.logout();}
          else{
            this.location.back()
          }
      } else
        this.toastr.error(response.body.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  validateNewPassword(){
    if((this.newPassword == null || this.newPassword == '') && !this.uploadFile){
      this.toastr.error("New password field cannot be empty.");
      return;
    }
    if (this.newPassword != null && this.newPassword !== '') {
      if (this.newPassword?.length < 8) {
        this.toastr.error("Password must be at least 8 characters.");
        return;

      }
      if (this.newPassword?.search(/[$&+,:;=?@#|'<>.^*()%!-]/) < 0) {
        this.toastr.error("Password must contain at least one special character ($&+,:;=?@#|'<>.^*()%!-).");
        return;
      }
      if (this.newPassword.search(/[A-Z]/) < 0) {
        this.toastr.error("Password must contain at least one Uppercase letter.");
        return;
      }
    }
  }

}
