import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Ami } from 'src/app/models/ami.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-create-ami',
  templateUrl: './create-ami.component.html',
  styleUrls: ['./create-ami.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class CreateAmiComponent {
  public selectedAmi: Ami = new Ami()
  showShadow = false;
  amiTitle: string = this.translationService.getByKeyFromCache('AMI');

  @Input() animationState = 'out';
  @ViewChild(CreateAmiComponent) popupComponentRef!: CreateAmiComponent;
  @Output() parentComp = new EventEmitter();

  constructor(private toastr: ToastrService,
    private ref: ElementRef,
    private authService: AuthService,
    private amiService: AmiService,
    private translationService: TranslationService,) { }

  save() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate)) {
      this.toastr.error("You don't have permission.");
      return;
    }

    if((this.selectedAmi.name && this.selectedAmi.formatAccount && this.selectedAmi.formatDt && this.selectedAmi.formatFeeder && this.selectedAmi.meterPhase) != ''){
      this.amiService.save(this.selectedAmi).subscribe(response => {
        if (response.status === 200) {
          this.toastr.success(response.message);
          this.changeState(2);
          this.resetSelectedAmi();
          this.parentComp.emit();
          } else
          this.toastr.error(response.message);
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }

  }

  resetSelectedAmi() {
    this.selectedAmi = new Ami();
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }
  closeTab() {
    this.popupComponentRef.changeState(2);
  }
  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.ami-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }

  changeState(number: Number, data?: any) {
    if (data)
      this.selectedAmi = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
  }

}
