import { BusinessUnit } from "./business-unit.model";

export class BoundaryMeterDto{
    bu: BusinessUnit = new BusinessUnit
    buId: number = 0;
    buName: string = ''
    meterNumber: string = '';
    amiId: number = 0;
    amiName: string = ''

}

