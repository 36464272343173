<main class="container-fluid" [ngClass]="{'table-hidden': showDetails}">
    <div class="container-wrapper">
        <div class="network-management-title">
            <span>Device Management</span>
        </div>

        <div class="menu-tab-filter">
            <app-tab-default
                [firstText]="remoteDisconnectedType" [firstTabNum]="remoteDisconnectedCount"
                [secondText]="alarmEventMgtType"
                [thirdText]="deviceParametersType"
                [forthText]="RemoteTokenLoadType"
                [fifthText]="loadProfileType"
                [sixthText]="remoteDisconnectionAction"
                (tabChanged)="menuChanged($event)" [wom]="true"
                [tabSelected]="tabMenu"
            ></app-tab-default>
        </div>

        <div class="filters-container" *ngIf="this.deviceManagementType === 'Remote Disconnected' || this.deviceManagementType === 'Alarm & Event Mgt'">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                <app-multi-filter *ngIf="filterApplied" [data]="allFilters" (dataChange)="applyFilter($event)"
                    [(showFilter)]="showFilter" >
                </app-multi-filter>
                   <app-range-datepicker *ngIf="this.deviceManagementType == 'Alarm & Event Mgt'"
                    [fromDate]="dateFrom"
                    [toDate]="dateTo"
                    [name]="'Period'"
                    (dateRangeSelected)="dateChange($event)">
                    </app-range-datepicker>

                <app-search-autocomplete #search [placeholder]="searchPlaceholder" [searchText]="searchText" [options]="options"
                    (searchChange)="searchChange($event)" [width]="33"
                    (searchAutocompleteChange)="getOptions($event)">
                </app-search-autocomplete>

            </div>
        </div>
                    <app-device-management-remote-disconnected #child *ngIf="this.deviceManagementType == 'Remote Disconnected'"
                    [buName]="buName"
                    [utName]="utName"
                    [buIds]="buIds"
                    [utIds]="utIds"
                    [searchText]="searchText"
                    [dateFrom]="dateFrom"
                    [dateTo]="dateTo"
                    (countChange)="handleCountChange($event)"
                    >
                </app-device-management-remote-disconnected>

                <app-alarm-event-mgt class="tbl" #child *ngIf="this.deviceManagementType == 'Alarm & Event Mgt'"
                [eventIds]="eventIds"
                [searchText]="searchText"
                [dateFrom]="dateFrom"
                [dateTo]="dateTo"
                >
                </app-alarm-event-mgt>

                <app-remote-token-load #child *ngIf="this.deviceManagementType == 'Remote Token Load'">
                </app-remote-token-load>

                <app-load-profile  class="tbl" #child *ngIf="this.deviceManagementType == 'Load Profile'">
                </app-load-profile>
                
                <app-device-parameters class="tbl" *ngIf="this.deviceManagementType == deviceParametersType">
                </app-device-parameters>
                
                <app-remote-disconnection-action class="tbl" *ngIf="this.deviceManagementType == remoteDisconnectionAction">
                </app-remote-disconnection-action>
    </div>
</main>

