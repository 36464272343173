<div class="user-profile">
    <div style="width: 100%;">
        <div class="header">
            <div class="title-container">
                <div class="title-u">
                    Customers Without DT
                </div>
                <div class="subtitle">
                    Upload Ultimate Customer Information
                </div>
            </div>
            <div class="btns">
                <app-import-details-popup
                (confirmUpload)="uploadFile()"
                (confirmGetTemplate)="getTemplate()"
                [placeholder]="'Import multiple customers data (Supported format XLSX).'"
                ></app-import-details-popup>

                <button class="btn" (click)="bulkUpload()">
                    <span class="text">Bulk Upload</span>
                </button>
            </div>
        </div>

        <div class="filters-container">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                <app-search-autocomplete
                    #search
                    [width]="45"
                    [placeholder]="searchPlaceholder"
                    [searchText]="searchText"
                    [options]="options"       
                    (searchAutocompleteChange)="getOptions($event)"
                    (searchChange)="searchChange($event)"
                ></app-search-autocomplete>
            </div>
        </div>
    
    <table class="standard-table" style="width: 100%;">
        <thead>
            <tr>
                <th class="large-td"><span title="Meter Numebr">METER NUMBER</span></th>
                <th><span title="Account number">ACCOUNT NUMBER</span></th>
                <th><span title="Customer Name">CUSTOMER NAME</span></th>
                <th><span title="Address">ADDRESS</span></th>
                <th><span title="Communication Status">COMMUNICATION STATUS</span></th>
            </tr>
        </thead>
        <tbody class="table-body">
            <tr *ngFor="let item of accounts" class="bodyRow clickable actions-click">
                <td>{{(item.msn) | hasValue}}</td>
                <td>{{(item.accountNo) | hasValue}}</td>
                <td>{{(item.firstName + ' ' + item.lastName) | hasValue}}</td>
                <td>{{(item.street) | hasValue}}</td>
                <td>{{(item.meterLastReadSync) | hasValue}}</td>
            </tr>
        </tbody>
    </table>

    <div class="pagination-wrapper">
        <app-pagination [pageSize]="pageSize" [currentPage]="currentPage" [count]="count" (pageChange)="pageChange($event)">
        </app-pagination>
    </div>
    </div>
</div>