import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Undertaking } from 'src/app/models/undertaking.model';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommonService } from 'src/app/services/common-service.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { Location } from '@angular/common';
import { AreaCreateComponent } from './area-create/area-create.component';
import { CrudDeleteModalComponent } from '../crud-delete-modal/crud-delete-modal.component';
import { CrudService } from 'src/app/services/crudService.service';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';

@Component({
  selector: 'app-area-tab',
  templateUrl: './area-tab.component.html',
  styleUrls: ['./area-tab.component.scss']
})
export class AreaTabComponent implements OnInit {
  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal!: ElementRef;


  public PermissionsEnum = PermissionsEnum;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;

  public searchByName: string = '';
  public searchByAddress: string = '';
  public searchByBU: number = 0;

  private ngUnsubscribe = new Subject<void>();
  private searchText: string = "";

  public undertakings: Undertaking[] = [];
  public searchUndertakings: SearchAutocomplete[] = [];
  public selectedUT: Undertaking = new Undertaking();
  public businessUnits: any = [];
  public selectedId: number = 0;
  public checkedList: any = []
  public masterSelected: boolean = false;

  public fileChosen: any = null;
  public file: any = null;
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public lastPageSize: number = 1;
  @ViewChild(AreaCreateComponent) popupComponentRef!: AreaCreateComponent;
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  @ViewChild("f") f: any;

  @Output() editTab = new EventEmitter();
  @Output() deleteComp = new EventEmitter();
  @Output() busSelect = new EventEmitter();

  constructor(private utService: UndertakingService,
    private buService: BusinessUnitService,
    private crudService: CrudService,
    private toastr: ToastrService,
    public authService: AuthService,
    private translationService: TranslationService,
    private commonService: CommonService,
    private location: Location) { }

  ngOnInit(): void {
    this.reloadTable();
    this.getAllForSelect();
    this.subscribeToSearch();
  }
  back() {
    this.location.back();
  }
  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  getAllForSelect() {
    this.buService.getAllForSelect().subscribe(response => {
      this.businessUnits = response.data;
      this.busSelect.emit(this.businessUnits);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;

    this.resetSelectedUT();

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText

      }
    };

    if(!obj.filterParams.SearchContent){
      this.utService.getAllForSelect().subscribe(response => {
          this.searchUndertakings = []
          response.data.forEach((x: Undertaking) =>
          {
            let item = new SearchAutocomplete();
            item.value = x.id;
            item.name = x.name;
            this.searchUndertakings.push(item)
          })

      }, error => {
        this.toastr.error('An error occurred.');
      });
    }

    this.utService.getAll(obj).subscribe(response => {
      this.undertakings = response.data.data;
      if(obj.pageInfo.page == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      this.isAlreadyChecked();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  selectUT(id: number) {
    this.utService.get(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedUT = response.data;
        this.editTab.emit(this.selectedUT);
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedUT() {
    this.selectedUT = new Undertaking();

    if (this.f)
      this.f.submitted = false;
  }
  openTabDelete(id: number) {
    this.utService.get(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedUT = response.data;
        this.deleteComp.emit();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.selectedId = id;
  }
  onDeleteConfirmed( ) {

    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_delete)) {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.utService.delete(this.selectedId).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.currentPage = 1;
        this.reloadTable();
        if(this.closeDeleteModal)
           this.closeDeleteModal.nativeElement.click();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }


  onFileChosen(event: any) {
    this.file = event.target.files[0];
  }

  isFileChosen() {
    if (this.fileChosen)
      return true;

    return false;
  }

  uploadFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.utService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.result.data;
      }
      this.reloadTable();
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  getTemplate() {
    this.utService.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;

      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch() {
    this.crudService.globalSearch$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchValue => {
        if (searchValue.clicked && searchValue.componentName === ComponentNamesNew.UndertakingComponent) {
          this.searchText = searchValue.text;
          this.currentPage = 1;
          this.checkedList = [];
          this.reloadTable();
          return;
        }
        this.searchText = '';
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkUncheckAll() {
    for (let i = 0; i < this.undertakings.length; i++) {
      this.undertakings[i].checked = this.masterSelected;
      this.checkForDownload(this.undertakings[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.undertakings.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.undertakings.every(function(item:any) {
      return item.checked == true;
    })
  }

  downloadItems(){
    let obj = {
      Ids: this.checkedList,
      activate: false
    };
     this.utService.download(obj).subscribe(response => {
      if (response.status === 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.")
        }
        else {
          window.location.href = response.data
          this.toastr.success("Successfully downloaded areas.");
        }
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
