import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { transformToString } from 'src/app/helpers/dateUtils';
import { MenuItem } from '../../consumption/model/model';
import { GraphItem } from 'src/app/models/graph-item.model';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { DtService } from 'src/app/services/dt.service';
import { ToastrService } from 'ngx-toastr';
import { SubstationService } from 'src/app/services/substation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-net-watch',
  templateUrl: './net-watch.component.html',
  styleUrls: ['./net-watch.component.scss']
})
export class NetWatchNewComponent implements OnInit {

  public mode: number = 1

  //multi filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  public ssName: string = "Substation"
  public feedersName: string = "Feeders"
  public dtName: string = "Dt"
  public tsName: string = "Transmission Stations"
  public transmissionStations: any = { name: this.tsName, data: [], dropdown: true };
  public substations: any = { name: this.ssName, data: [], dropdown: true };
  public feeders: any = { name: this.feedersName, data: [], dropdown: true };
  public tsSelected: any = {value: 0, name: '', checked: false}
  public ssSelected: any = {value: 0, name: '', checked: false}
  public feedersSelected: any = {value: 0, name: '', type: 0, checked: false}

  //Tab
  @Input() firstText: string = '33KV Feeders'
  @Input() secondText: string = '11KV Feeders'
  @Input() thirdText: string = 'Distribution Transformer'
  @Output() tabChanged = new EventEmitter();

  @Input() menu!: MenuItem;
  @Output() redirect: EventEmitter<any> = new EventEmitter()

  // Search Autocomplete
  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public options = ['']
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public searchPlaceholder: string = "Search by Feeder33 name or number"

  //Datepicker
  @Input() dateFrom: any = '' 
  @Output() dateFromChange = new EventEmitter();
  @Input() dateTo: any = ''
  @Output() dateToChange = new EventEmitter();
  public isMonthly: boolean = true


  //Graph
  public barChartType: ChartType = 'bar'
  private graphData: GraphItem[] = []
  public CapitalLabels: string[] = [];
  public EnergyLabels: string[] = [];
  public barChartLegend = true;
  public barChartPlugins = [];

  public CapitalLoss: ChartDataset[] = [
    {
      data: [], label: 'TOTAL ENERGY LOSSES',
      backgroundColor: 'rgb(192, 52, 56)',
      borderRadius: 45,
      barThickness: 12 
    },
  ];

  public EnergyLoss: ChartDataset[] = [
    {
      data: [], label: 'TOTAL ENERGY LOSSES',
      backgroundColor: 'rgb(192, 52, 56)',
      borderRadius: 45,     
      barThickness: 12
    }
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    devicePixelRatio: 1,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          color: '#E8E8E8',
        },
        ticks: {
          padding: 30,
        }
      }   
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
  
            const data = context.parsed.y !== null
              ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(context.parsed.y)
              : '';
            return [label, data];
          },
          afterLabel: function () {
            return '';
          },
        },
        backgroundColor: '#2E3336', 
        borderColor: 'rgba(0, 0, 0, 0.5)',
        borderWidth: 1,
        padding: 20, 
        cornerRadius: 10,
        displayColors: false,
        xAlign: 'center', 
        yAlign: 'bottom'
      } 
    } 
  };
  
  public barChartCapitalOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    devicePixelRatio: 1, 
    scales: {
      x: {
        grid: {
          display: false
        },
        border:{
          display: false
        },
      },
      y: {
        grid: {
          color: '#E8E8E8',
        },
        border:{
          display: false
        },
        ticks: {
          padding: 30,
        }
      } 
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            const data = context.parsed.y !== null
            ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(context.parsed.y)
            : '';
          return [label, data];
        },
        afterLabel: function () {
          return '';
        },
      },
      backgroundColor: '#2E3336', 
      borderColor: 'rgba(0, 0, 0, 0.5)',
      borderWidth: 1,
      padding: 20, 
      cornerRadius: 10,
      displayColors: false,
      xAlign: 'center', 
      yAlign: 'bottom'
      }
    }
  };

  constructor(
    private feeder33Service: Feeder33Service,
    private feeder11Service: Feeder11Service,
    private dtService: DtService,
    private toastr: ToastrService,
    private _ssService: SubstationService,
    private tsService: TransmissionStationService,

  ) { }

  ngOnInit(): void {
    this.setMainDate();
    this.getAllTs();
    this.fetchData() 
  }

  public download() {
    let obj = {
      Start: transformToString(this.dateFrom),
      End: transformToString(this.dateTo),
      SsId: this.ssSelected.value,
      TsId: this.tsSelected.value,
      FeederType: this.feedersSelected.type,
      FeederId: this.feedersSelected.value,
      Search: this.searchText.name,
      GetAll: false,
    }
    switch (this.mode) {
      case 1:
        this.downloadF33WorstRecordedAssets(obj);
        break;
      case 2:
        this.downloadF11WorstRecordedAssets(obj); 
        break;
      case 3:
        this.downloadDtWorstRecordedAssets(obj); 
        break;
    }

  }

  private downloadF11WorstRecordedAssets(obj: any) {
    if (this.graphData.length == 0) {
      this.toastr.info('No recorded assets for selected time period.')
    }
    else {
      this.feeder11Service.downloadWorstRecordedAssets(obj).subscribe(response => {
        if (response.status == 200) {
          window.location.href = response.data;
        }
        else {
          this.toastr.error(response.message)
        }
      }, _ => {
        this.toastr.error('An error occurred.');
      })
    }
  }

  private downloadF33WorstRecordedAssets(obj: any) {
    if (this.graphData.length == 0) {
      this.toastr.info('No recorded assets for selected time period.')
    }
    else {
      this.feeder33Service.downloadWorstRecordedAssets(obj).subscribe(response => {
        if (response.status == 200) {
          window.location.href = response.data;
        }
        else {
          this.toastr.error(response.message)
        }
      }, _ => {
        this.toastr.error('An error occurred.');
      })
    }
  }

  private downloadDtWorstRecordedAssets(obj: any) {
    if (this.graphData.length == 0) {
      this.toastr.info('No recorded assets for selected time period.')
    }
    else {
      this.dtService.downloadWorstRecordedAssets(obj).subscribe(response => {
        if (response.status == 200) {
          window.location.href = response.data;
        }
        else {
          this.toastr.error(response.message)
        }
      }, _ => {
        this.toastr.error('An error occurred.');
      })
    }
  } 

  public fetchData() { 
    let objEnergy = {
      Start: transformToString(this.dateFrom),
      End: transformToString(this.dateTo),
      SsId: this.ssSelected.value,
      TsId: this.tsSelected.value,
      FeederId: this.feedersSelected.value,
      FeederType: this.feedersSelected.type,
      Search: this.searchText.name,
      GetAll: false,
      GetCapitalLoss: 0
    }

    switch (this.mode) {
      case 1:
        this.getFeeders33Loss(objEnergy);
        break;
      case 2:
        this.getFeeders11Loss(objEnergy);
        break;
      case 3:
        this.getDTLoss(objEnergy);      
        break;
    }
  }

  private getFeeders33Loss(obj: any) {
    this.feeder33Service.getFeedersWorstEnergyLosses(obj).subscribe((response: GraphItem[]) => {
      this.handleEnergyLoss(response);
      this.setValuesForCapitalLoss(response);
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  private getFeeders11Loss(obj: any) {
    this.feeder11Service.getFeedersWorstEnergyLosses(obj).subscribe(response => {
      this.handleEnergyLoss(response);
      this.setValuesForCapitalLoss(response);
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  private getDTLoss(obj: any) {
    this.dtService.getDTWorstEnergyLosses(obj).subscribe(response => {
      this.handleEnergyLoss(response);
      this.setValuesForCapitalLoss(response);
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  changeTab(value: number) { 
    this.mode = value 
    if (this.mode == 1){
      this.searchPlaceholder = "Search by Feeder33 name or number";
      this.resetFilters()
    }
    if (this.mode == 2){
      this.searchPlaceholder = "Search by Feeder11 name or number";
      this.resetFilters()
      if(this.substations.data.length == 0)
        this.getAllSs();
    }
    if (this.mode == 3){
      this.searchPlaceholder = "Search by Dt name or number";
      this.resetFilters()
      if(this.feeders.data.length == 0)
        this.getAllFeedersForSelect();
    }

    this.tabChanged.emit(this.mode)
    this.fetchData()
  }

  public SelectSsFilter(ss: any) {
    this.ssSelected = ss;
    this.fetchData()
  }
 
  public selectAllFeedersFilter(feed: any) {
    console.log(feed)
    this.feedersSelected = feed 
    this.fetchData()
  }

  public SelectTsFilter(ts: any) {
    this.tsSelected = ts
    this.fetchData()
  }



  private setValuesForCapitalLoss(response: any) {
    this.CapitalLoss[0].data = []
    this.CapitalLabels = []
    let labels = ['']
    labels = []
    if (response.data.dataWithCapitalLoss != null && response.data.dataWithCapitalLoss.length > 0) {
      this.graphData = response.data.dataWithCapitalLoss;
      this.graphData.forEach(element => {
        this.CapitalLoss[0].data?.push(Math.round(element.energyLoss * 1000) / 1000)
        labels.push(this.sliceLabel(element.name))
      })
    }
    this.CapitalLabels = labels
  }

  private handleEnergyLoss(response: any) {
    this.EnergyLoss[0].data = []
    this.EnergyLabels = []
    let labels = ['']
    labels = []
    if (response.data.dataWithoutCapitalLoss != null && response.data.dataWithoutCapitalLoss.length > 0) {
      this.graphData = response.data.dataWithoutCapitalLoss;
      this.graphData.forEach(element => {
        this.EnergyLoss[0].data?.push(Math.round(element.energyLoss * 100) / 100)
        labels.push(this.sliceLabel(element.name))
      })
      this.EnergyLabels = labels
    }
  }

  private sliceLabel(label: string) {
    if (label.length > 20)
      return label.slice(0, 20) + '...'
    return label
  }

  private getAllSs() {
    this._ssService.getAllForSelect().subscribe(response => {
      this.substations = { name: this.ssName, data: response.data, dropdown: true };
      this.checkFilters();
     })
  }

  private getAllTs() {
    this.tsService.getAllForSelect().subscribe(response => {
      this.transmissionStations = { name: this.tsName, data: response.data, dropdown: true };
      this.checkFilters()
    })
  }

  private getAllFeedersForSelect() {
    this.feeder11Service.getAllFeedersAllFeedersList().subscribe(response => {
      this.feeders = { name: this.feedersName, data: response, dropdown: true };
      this.checkFilters()
    })
  }

  setMainDate() {
    let date = new Date()

    date.setHours(2, 0, 0)
    date.setDate(1)

    this.dateTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: 1}
    date.setMonth(date.getMonth() - 1);
    this.dateFrom = { year: date.getFullYear(), month: date.getMonth() + 1, day: 1};
  }


  dateChange(data: any) {
    this.dateFrom = data.dateFrom;
    this.dateTo = data.dateTo;
    this.fetchData();
  }

  //multifilter
  dropdownSelected(data: any){
    if(!data)
      return
    if(data.data.name === this.tsName)
      this.SelectTsFilter(data.item)
    if(data.data.name === this.ssName)
      this.SelectSsFilter(data.item)
    if(data.data.name === this.feedersName)
      this.selectAllFeedersFilter(data.item)
  }

  checkFilters() {
    let tsIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.transmissionStations.name);
    if (tsIndex !== -1)
      this.allFilters[tsIndex].data = this.transmissionStations.data;

    let ssIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.substations.name);
    if (ssIndex !== -1)
      this.allFilters[ssIndex].data = this.substations.data;

    let feedersIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders.name);
    if (feedersIndex !== -1)
      this.allFilters[feedersIndex].data = this.feeders.data;
  
    if (this.mode == 1 && tsIndex === -1) {
      this.allFilters.push(this.transmissionStations)
    }
    if (this.mode == 2 && ssIndex === -1) {
      this.allFilters.push(this.substations)
    }
    if (this.mode == 3 && feedersIndex === -1) {
      this.allFilters.push(this.feeders)
    }

    if(this.allFilters.length != 0)
      this.filterApplied = true

    this.allFilters = [...this.allFilters]
  }

  resetFilters() {
    this.searchText = new SearchAutocomplete()
    if(this.searchChild){
      this.searchChild.searchText = new SearchAutocomplete()
      this.searchChild.resetOptions()
    }
    this.showFilter = false
    this.filterApplied = false
    this.ssSelected = {value: 0, name: '', checked: false}
    this.tsSelected = {value: 0, name: '', checked: false}
    this.feedersSelected = {value: 0, name: '', type: 0, checked: false}
    this.allFilters = []
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.setMainDate()
    this.checkFilters()
  }

    // Search Autocomplete
    getOptions(searchText: SearchAutocomplete) {
      let obj = {
        searchText: searchText.name, 
        msnIncluded: false
      }
      if (searchText.name.length > 2) {
        if (this.mode == 1) {
          this.feeder33Service.getDataForAutocomplete(obj)
            .subscribe({ 
              next : (response) => this.options = response.data,
              error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
            })
        } else if (this.mode == 2) {
          this.feeder11Service.getDataForAutocomplete(obj)
            .subscribe({ 
              next : (response) => this.options = response.data,
              error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
            })
        } else if (this.mode == 3) {
          this.dtService.getDataForAutocomplete(obj)
            .subscribe({ 
              next : (response) => this.options = response.data,
              error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
            })
        }
      } else {
        this.searchChild.resetOptions()
      }
    }
    
    // Search Autocomplete
    searchChanged(searchText: SearchAutocomplete) {
      this.searchText = searchText
      this.fetchData()
    }
}
