import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent {

  @Input() isMonthly: boolean = false;
  @Input() placementPosition: string = 'bottom-left';
  @Input() isOnlyOneMonth: boolean = false;
  @Input() dateFrom: any = "";
  @Input() dateTo: any = "";
  @Input() name: string = 'Date'


  @Output() dateChange = new EventEmitter();

  public filterByDateClicked: boolean = false;
  public isDatePickerOpened: boolean = false;


  isDisabled = (date:any, day:any) => {
    if (this.isMonthly) {
      return date.day !== 1;
    }
    return false;
  };

  public changeFromDate(dateFrom: NgbDate) {
    if(this.isOnlyOneMonth){
      this.dateTo = new NgbDate(dateFrom.month == 12 ? dateFrom.year + 1 : dateFrom.year, dateFrom.month == 12 ? 1 : dateFrom.month + 1, dateFrom.day);
    }

    this.isDatePickerOpened = false
  }

  public changeToDate(dateTo: NgbDate) {
    if(this.isOnlyOneMonth){
      this.dateFrom = new NgbDate(dateTo.month == 1 ? dateTo.year - 1 : dateTo.year, dateTo.month == 1 ? 12 : dateTo.month - 1, dateTo.day);
    }

    this.isDatePickerOpened = false
  }

  changeDates(dateFrom: NgbDate, dateTo: NgbDate){
    let dateChange = {
                dateFrom: dateFrom,
                dateTo: dateTo
              }
    this.dateChange.emit(dateChange);
  }

  public filterClick(){
    this.isDatePickerOpened = false
    this.filterByDateClicked = !this.filterByDateClicked
  }

  public close(){
    if(!this.isDatePickerOpened){
      this.filterByDateClicked = false;
    }
    else{
      this.isDatePickerOpened=false;
    }
  }

  handleMouseDown(event: MouseEvent) {
    this.isDatePickerOpened = true;
    event.preventDefault();
  }

}
