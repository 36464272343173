import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CurrentBillCollection } from 'src/app/models/current-bill-collection';
import { RevenueDashboardService } from 'src/app/services/revenue-dashboard.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-view-details-page',
  templateUrl: './view-details-page.component.html',
  styleUrls: ['./view-details-page.component.scss']
})
export class ViewDetailsPageComponent implements OnInit {

  public weeklyCollection: CurrentBillCollection[] = []
  public weeklySum: number = 0
  public weeklyCustomersNo: number = 0
  public monthlyCollection: CurrentBillCollection[] = []
  public monthlySum: number = 0
  public monthlyCustomersNo: number = 0

  public buName: string = this.translationService.getByKeyFromCache('BU');

  @Input() isPopUp: boolean = false

  public tab: number = 1

  @Output() closePopUpChange = new EventEmitter()

  constructor(private translationService: TranslationService, private dashboardService: RevenueDashboardService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.getCurrentTotalWeeklyCollection()
    this.getCurrentTotalMonthlyCollection()
  }

  changeState(number: Number){
    
    if (number === 1){   
      this.isPopUp = true;
    }
    if (number === 2)
    { 
      this.isPopUp = false;
    }
  }

  getCurrentTotalMonthlyCollection(){
    this.dashboardService.getCurrentTotalMonthlyCollection().subscribe(response =>{
      if(response.status === 200){
        this.handleMonthlyCollection(response.data)
      }
      else{
        this.toastr.error(response.message)
      }
    })
  }

  getCurrentTotalWeeklyCollection(){
    this.dashboardService.getCurrentTotalWeeklyCollection().subscribe(response =>{
      if(response.status === 200){
        this.handleWeeklyCollection(response.data)
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  handleMonthlyCollection(data: any[]){
    this.monthlyCollection = data;
    this.monthlySum = this.monthlyCollection.reduce((sum, current) => sum + current.amount, 0)
    this.monthlyCustomersNo = this.monthlyCollection.reduce((sum, current) => sum + current.customers, 0)
  }

  handleWeeklyCollection(data: any[]){
      this.weeklyCollection = data
      this.weeklySum = this.weeklyCollection.reduce((sum, current) => sum + current.amount, 0)
      this.weeklyCustomersNo = this.weeklyCollection.reduce((sum, current) => sum + current.customers, 0)
  }

}
