<div class="filters-container">
  <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
      <app-multi-filter *ngIf="filterApplied" [data]="allFilters" (dataChange)="applyFilter($event)"
          [(showFilter)]="showFilter">
      </app-multi-filter>
      <app-search-autocomplete #search [placeholder]="searchPlaceholder" [searchText]="searchText" [options]="options"
          (searchChange)="searchChange($event)" [width]="33"
          (searchAutocompleteChange)="getOptions($event)"></app-search-autocomplete>

  </div>
  <app-map-table-button-new [isMap]="isMap" (filterClicked)="mapChanged($event)">
  </app-map-table-button-new>
</div>

<div *ngIf="!isMap" class="gep-table-wrapper">
    <div class="table-wrapper">
        <table class="standard-table gep-table">
            <thead>
                <th><span title="Account name">ACCOUNT NAME</span></th>
                <th><span title="Account  number">ACCOUNT NUMBER</span></th>
                <th><span title="Customer  type">CUSTOMER TYPE</span></th>
                <th><span title="Dt name">DT NAME</span></th>
                <th><span title="Dt number">DT NUMBER</span></th>
                <th><span title="Meter type">{{buName}}</span></th>
                <th><span title="Meter type">{{utName}}</span></th>
                <th><span title="Feeder">FEEDER</span></th>
            </thead>
            <tbody>
                <tr *ngFor="let item of data">
                    <td>
                        <span *ngIf="item.customerName !== '/'" title="{{item.customerName}}">{{item.customerName +
                            " " + item.lastName |
                            hasValue}}</span>
                        <span *ngIf="item.customerName === '/'" class="NaN">N/A</span>
                    </td>

                    <td class="large-td">
                        <span *ngIf="item.accountNumber !== '/'" title="{{item.accountNumber}}">{{item.accountNumber
                            |
                            hasValue}}<img alt="Copy" class="icons" src="assets/images-new-design/copy.png"
                                (click)="copyToClipboard(item.accountNumber)"></span>
                        <span *ngIf="item.accountNumber === '/'" class="NaN">N/A</span>
                    </td>

                    <td>
                        <span *ngIf="item.connectionType !== '/'" title="{{item.connectionType}}">{{item.connectionType
                            |
                            hasValue}}</span>
                        <span *ngIf="item.connectionType === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.dtName !== '/'" title="{{item.dtName}}">{{item.dtName | hasValue}}</span>
                        <span *ngIf="item.dtName === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.dtNo !== '/'" title="{{item.dtNo}}">{{item.dtNo | hasValue}}</span>
                        <span *ngIf="item.dtNo === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.buName !== '/'" title="{{item.buName}}">{{item.buName | hasValue}}</span>
                        <span *ngIf="item.buName === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.utName !== '/'" title="{{item.utName}}">{{item.utName | hasValue}}</span>
                        <span *ngIf="item.utName === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.feederName !== '/'" title="{{item.feederName}}">{{item.feederName |
                            hasValue}}</span>
                        <span *ngIf="item.feederName === '/'" class="NaN">N/A</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
<div class="map-wrapper" *ngIf="isMap">
    <adora-map-clusterized
      [searchValue]="searchText.name"
      [customersMap]="true"
      [unmetered]="true"
      [buIds]="buIds" [utIds]="utIds"
      [meterItemType]="meterItemType"
      [zoom]="11"></adora-map-clusterized>
      </div>
<div *ngIf="!isMap" class="pagination-wrapper">
    <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
        [currentPage]="currentPage" (pageChange)="pageChange($event)">
    </app-fo-pagination-new>
</div>
