import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { GraphItem } from '../models/graph-item.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  private pathApi = this.config.setting['pathApi'] + "TrendAnalysis/";
  

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    get(): Observable<GraphItem[]> {
      return this.http.get<GraphItem[]>(`${this.pathApi}`).pipe(
        map((arr) => 
            arr.map(x =>
                new GraphItem(x.date, "", x.received, 0))));
    } 

    
    // getByMonth(start:string, end:string){
    //     return this.http.get<GraphItem[]>(`${this.pathApi}GetByMonths?start=${start}&end=${end}`).pipe(
    //         map((arr) => 
    //             arr.map(x =>
    //               new GraphItem(x.date, "", x.received, 0))));
    //   } 
    
    // getByDays(start:string, end:string){
    //   return this.http.get<GraphItem[]>(`${this.pathApiByDays}?start=${start}&end=${end}`).pipe(
    //       map((arr) => 
    //           arr.map(x =>
    //             new GraphItem(x.date, "", x.received, 0))));
    // } 

    // getReceivedAndConsumed(){
    //   return this.http.get<GraphItem[]>(`${this.pathApiReceivedAndConsumed}`).pipe(
    //     map((arr) => 
    //         arr.map(x =>
    //           new GraphItem(x.date,"", x.received, x.consumed))));
    // }

    getReceivedAndConsumedF33ByMonths(obj: any): Observable<any>{
      return this.http.post<any>(`${this.pathApi}GetFeeders33ByMonths`, obj)
    }

    getReceivedAndConsumedF11ByMonths(obj: any): Observable<any>{
      return this.http.post<any>(`${this.pathApi}GetFeeders11ByMonths`, obj)
    } 
    getReceivedAndConsumedDtByMonths(obj: any): Observable<any>{
      return this.http.post<any>(`${this.pathApi}GetDtByMonths`, obj)
    }
    downloadMonthlyF33EnergyReceivedAndConsumed(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadF33ReceivedAndConsumed`,obj)
    }
    downloadMonthlyDtEnergyReceivedAndConsumed(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadDtReceivedAndConsumed`,obj)
    }
    downloadMonthlyF11EnergyReceivedAndConsumed(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadF11ReceivedAndConsumed`,obj)
    }
}
