import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MultiFilterComponent } from 'src/app/components/common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { transformToString } from 'src/app/helpers/dateUtils';
import { FOF11FlaggingValues } from 'src/app/models/fo-f11-flagging-values.model';
import { FOF33FlaggingValues } from 'src/app/models/fo-f33-flagging-values.model';
import { FOFlaggingValues } from 'src/app/models/fo-flagging-values.model';
import { PredictiveDtAssetAnalytics } from 'src/app/models/predictive-dt-analytics.model';
import { PredictiveF11AssetAnalytics } from 'src/app/models/predictive-f11-analytics.model';
import { PredictiveF33Analytics } from 'src/app/models/predictive-f33-analytics.model';
import { PredictiveF33HistoryAnalytics } from 'src/app/models/predictive-f33-history-analytics.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AssetPerformanceService } from 'src/app/services/asset-performance.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { ConfigFieldOperationService } from 'src/app/services/config-field-operation.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';

@Component({
  selector: 'app-predictive-analytics-new',
  templateUrl: './predictive-analytics-new.component.html',
  styleUrls: ['./predictive-analytics-new.component.scss']
})
export class PredictiveAnalyticsNewComponent implements OnInit, OnDestroy {

  currentPage: number = 1
  pageSize: number = 10
  lastPageSize: number = 1
  count: number = 0


  activeTabPrimary: number = ActiveTabPrimary.Feeder33
  firstTabPrimary: string = '33KV Feeder'
  activeTabPrimaryString = this.firstTabPrimary
  secondTabPrimary: string = '11KV Feeder'
  thirdTabPrimary: string = 'DT'
  public ActiveTabPrimaryEnum = ActiveTabPrimary

  activeTabSecondary: number = ActiveTabSecondary.Current
  public ActiveTabSecondaryEnum = ActiveTabSecondary

  public buIds: any = []
  public tsIds: any = []

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public businessUnits: any = { name: this.buName, data: [] };
  public transmissionStations: any = { name: "TransmissionStation", data: [] };


  //multi filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;

  public dateFrom: any = ''
  public dateTo: any = ''

  public searchText: SearchAutocomplete = new SearchAutocomplete()

  public predictiveDtData: PredictiveDtAssetAnalytics[] = []
  public predictiveF11Data: PredictiveF11AssetAnalytics[] = []
  public predictiveF33Data: PredictiveF33Analytics[] = [];

  public predictiveDtHistoryData: PredictiveDtAssetAnalytics[] = []
  public predictiveF11HistoryData: PredictiveF11AssetAnalytics[] = []
  public predictiveF33HistoryData: PredictiveF33HistoryAnalytics[] = [];


  public flagValues: FOFlaggingValues = new FOFlaggingValues()
  public f11FlagValues: FOF11FlaggingValues = new FOF11FlaggingValues()
  public f33FlagValues: FOF33FlaggingValues = new FOF33FlaggingValues()


  @ViewChild(MultiFilterComponent) multiFilterComponentRef!: MultiFilterComponent
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  options = ['']

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    public buService: BusinessUnitService,
    public tsService: TransmissionStationService,
    public dtService: DtService,
    public f11Service: Feeder11Service,
    public f33Service: Feeder33Service,
    private toastr: ToastrService,
    private configFOService: ConfigFieldOperationService,
    public assetPerformanceService: AssetPerformanceService,
    private translationService: TranslationService,

  ) { }

  ngOnInit(): void {
    this.setInitalDates()
    this.reloadTable()
    this.getBusForSelect()
    this.getTsForSelect()
    this.getPredictiveAnalyticFlags()
    this.getPredictiveAnalyticF11Flags()
    this.getPredictiveAnalyticF33Flags()

  }

  reloadTable(page: any = null) {
    if (page != null) {
      this.currentPage = page
    }

    let obj = this.resolveTableDataFetchObject()
    if (this.activeTabSecondary == ActiveTabSecondary.Current) {
      if (this.activeTabPrimary == ActiveTabPrimary.DT) {
        this.getDtPredictiveAnalytics(obj)
      }
      else if (this.activeTabPrimary == ActiveTabPrimary.Feeder11) {
        this.getF11PredictiveAnalytics(obj)
      }
      else if( this.activeTabPrimary == ActiveTabPrimary.Feeder33){
        this.getF33PredictiveAnalytics(obj);
      }
    }
    else if (this.activeTabSecondary == ActiveTabSecondary.Historical) {
      if (this.activeTabPrimary == ActiveTabPrimary.DT) {
        this.getDtHistoryPredictiveAnalytics(obj)
      }
      else if (this.activeTabPrimary == ActiveTabPrimary.Feeder11) {
        this.getF11HistoryPredictiveAnalytics(obj)
      }
      else if( this.activeTabPrimary == ActiveTabPrimary.Feeder33){
        this.getF33HistoryPredictiveAnalytics(obj);
      }
    }

  }

  resolveTableDataFetchObject(){
    if(this.activeTabPrimary == ActiveTabPrimary.DT || this.activeTabPrimary == ActiveTabPrimary.Feeder11){
      return {
        pageInfo: {
          page: this.currentPage,
          pageSize: this.pageSize
        },
        filterParams: {
          searchText: this.searchText.name,
          businessUnitIds: this.buIds,
          dateFrom: typeof (this.dateFrom) === 'object' ? transformToString(this.dateFrom) : this.dateFrom,
          dateTo: typeof (this.dateTo) === 'object' ? transformToString(this.dateTo) : this.dateTo
        }
      }
    }
    else{
      return {
        pageInfo: {
          page: this.currentPage,
          pageSize: this.pageSize
        },
        filterParams: {
          searchText: this.searchText.name,
          transmissionStationIds: this.tsIds,
          dateFrom: typeof (this.dateFrom) === 'object' ? transformToString(this.dateFrom) : this.dateFrom,
          dateTo: typeof (this.dateTo) === 'object' ? transformToString(this.dateTo) : this.dateTo
        }
      }
    }
  }

  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    if (searchText.name.length > 2) {
      if (this.activeTabPrimary == ActiveTabPrimary.DT) {
        this.dtService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      } else if (this.activeTabPrimary == ActiveTabPrimary.Feeder11) {
        this.f11Service.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
      else if (this.activeTabPrimary == ActiveTabPrimary.Feeder33) {
        this.f33Service.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
    } else
      this.searchComponentRef.resetOptions()

  }

  pageChange(value: any) {
    this.currentPage = value
    this.reloadTable()
  }

  getBusForSelect() {
    this.buService.getAllForSelect()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.businessUnits = { name: 'Region', data: response.data }
        this.checkFilters()
      }, _ => {
        this.toastr.error('An error occurred.')
      });
  }

  getTsForSelect() {
    this.tsService.getAllForSelect()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.transmissionStations = { name: 'TransmissionStation', data: response.data }
        // this.checkFilters()
      }, _ => {
        this.toastr.error('An error occurred.')
      });
  }

  setInitalDates() {
    let date = new Date()
    date.setHours(2, 0, 0)
    this.dateTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() + 1 }
    date.setMonth(date.getMonth() - 12);
    this.dateFrom = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
  }

  private getDtPredictiveAnalytics(obj: any) {
    this.assetPerformanceService.getPredictiveDtAssetAnalytics(obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        if (response.status == 200) {
          this.predictiveDtData = response.data.data
          if(obj.pageInfo.page == 1){
            this.count = response.data.count
            this.lastPageSize = Math.ceil(this.count / this.pageSize)
          }
        }
        else {
          this.toastr.error(response.message)
        }
      }, (_: any) => {
        this.toastr.error("An error occurred")
      })
  }

  private getF11PredictiveAnalytics(obj: any) {
    this.assetPerformanceService.getPredictiveF11AssetAnalytics(obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        if (response.status == 200) {
          this.predictiveF11Data = response.data.data
          if(obj.pageInfo.page == 1){
            this.count = response.data.count
            this.lastPageSize = Math.ceil(this.count / this.pageSize)
          }
        }
        else {
          this.toastr.error(response.message)
        }
      }, (_: any) => {
        this.toastr.error("An error occurred")
      })
  }

  private getF33PredictiveAnalytics(obj: any) {
    this.assetPerformanceService.getPredictiveF33AssetAnalytics(obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        if (response.status == 200) {
          this.predictiveF33Data = response.data.data
          if(obj.pageInfo.page == 1){
            this.count = response.data.count
            this.lastPageSize = Math.ceil(this.count / this.pageSize)
          }
        }
        else {
          this.toastr.error(response.message)
        }
      }, (_: any) => {
        this.toastr.error("An error occurred")
      })
  }

  private getDtHistoryPredictiveAnalytics(obj: any) {
    this.assetPerformanceService.getPredictiveDtHistoryAssetAnalytics(obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        if (response.status == 200) {
          this.predictiveDtHistoryData = response.data.data
          if(obj.pageInfo.page == 1){
            this.count = response.data.count
            this.lastPageSize = Math.ceil(this.count / this.pageSize)
          }
        }
        else {
          this.toastr.error(response.message)
        }
      }, (_: any) => {
        this.toastr.error("An error occurred")
      })
  }

  private getF11HistoryPredictiveAnalytics(obj: any) {
    this.assetPerformanceService.getPredictiveF11HistoryAssetAnalytics(obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        if (response.status == 200) {
          this.predictiveF11HistoryData = response.data.data
          if(obj.pageInfo.page == 1){
            this.count = response.data.count
            this.lastPageSize = Math.ceil(this.count / this.pageSize)
          }
        }
        else {
          this.toastr.error(response.message)
        }
      }, (_: any) => {
        this.toastr.error("An error occurred")
      })
  }

  private getF33HistoryPredictiveAnalytics(obj: any) {
    this.assetPerformanceService.getPredictiveF33HistoryAssetAnalytics(obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        if (response.status == 200) {
          this.predictiveF33HistoryData = response.data.data
          if(obj.pageInfo.page == 1){
            this.count = response.data.count
            this.lastPageSize = Math.ceil(this.count / this.pageSize)
          }
        }
        else {
          this.toastr.error(response.message)
        }
      }, (_: any) => {
        this.toastr.error("An error occurred")
      })
  }

  private getPredictiveAnalyticFlags() {
    this.configFOService.getPredictiveFlaggs()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.flagValues = response.data
      }, _ => {
        this.toastr.error('An error occurred.')
      })
  }


  private getPredictiveAnalyticF11Flags() {
    this.configFOService.getPredictiveF11Flaggs()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.f11FlagValues = response.data
      }, _ => {
        this.toastr.error('An error occurred.')
      })
  }

  private getPredictiveAnalyticF33Flags() {
    this.configFOService.getPredictiveF33Flaggs()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.f33FlagValues = response.data
      }, _ => {
        this.toastr.error('An error occurred.')
      })
  }

  checkFilters() {
    if(this.activeTabPrimary != ActiveTabPrimary.Feeder33){
      let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnits.name)

      if (!buData) {
        this.allFilters.push(this.businessUnits)
        this.filterApplied = true
      }
    }
    else{
      let tsData = this.allFilters.find((item: { name: any; }) => item.name === this.transmissionStations.name)

      if(!tsData){
        this.allFilters.push(this.transmissionStations)
        this.filterApplied = true
      }
    }
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)
      if (item.name === 'Region' && filteredValues.length > 0) {
        this.buIds = filteredValues
      } else if (item.name === 'Region' && filteredValues.length == 0) {
        this.buIds = []
      }
      if(item.name === 'TransmissionStation' && filteredValues.length > 0){
        this.tsIds = filteredValues
      }
      else if(item.name === 'TransmissionStation' && filteredValues.length == 0){
        this.tsIds = []
      }
    }
    this.reloadTable(1)
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    this.reloadTable(1)
  }

  searchChange(value: SearchAutocomplete) {
    this.searchText = value
    this.reloadTable(1)
  }

  primaryTabChange(value: number) {
    let lastPrimaryTabState = this.activeTabPrimary;
    if ((value === 3 && this.activeTabPrimary == ActiveTabPrimary.DT) ||
      (value === 2 && this.activeTabPrimary == ActiveTabPrimary.Feeder11) ||
      (value === 1 && this.activeTabPrimary == ActiveTabPrimary.Feeder33)) {
      return
    }
    if (value === 3) {
      this.activeTabPrimary = ActiveTabPrimary.DT
      this.activeTabPrimaryString = this.thirdTabPrimary
    }
    else if (value === 2) {
      this.activeTabPrimary = ActiveTabPrimary.Feeder11
      this.activeTabPrimaryString = this.secondTabPrimary
    }
    else if (value === 1) {
      // this.toastr.warning("Not yet implemented")
      // return
      this.activeTabPrimary = ActiveTabPrimary.Feeder33
      this.activeTabPrimaryString = this.firstTabPrimary
    }
    this.handleFilterEntitySwap(value, lastPrimaryTabState);
    this.resetFilters()
  }

  handleFilterEntitySwap(value: number, lastPrimaryTabState: number){
    if( (lastPrimaryTabState ==  ActiveTabPrimary.DT || lastPrimaryTabState ==  ActiveTabPrimary.Feeder11)
      && value === 1){
      // changing from BU based tab to TS based tab
      this.allFilters = [];
      this.checkFilters()
    }
    if(lastPrimaryTabState ==  ActiveTabPrimary.Feeder33 && (value === 3 || value == 2)){
      //changing from TS based tab to BU based tab
      this.allFilters = [];
      this.checkFilters()
    }
  }

  secondaryTabChange(value: number) {
    if ((value === 1 && this.activeTabSecondary == ActiveTabSecondary.Current) ||
      (value === 2 && this.activeTabSecondary == ActiveTabSecondary.Historical)) {
      return
    }

    if (value === 1)
      this.activeTabSecondary = ActiveTabSecondary.Current
    else if (value === 2)
      this.activeTabSecondary = ActiveTabSecondary.Historical
    this.resetFilters()
  }

  resetFilters() {
    this.multiFilterComponentRef?.uncheckData()
    this.multiFilterComponentRef?.resetShowFilter()
    this.searchComponentRef.resetOptions()
    this.searchText = new SearchAutocomplete()
    if (this.searchComponentRef)
      this.searchComponentRef.searchText = new SearchAutocomplete()
    this.buIds = []
    this.tsIds = []
    this.showFilter = false
    this.setInitalDates()
    this.reloadTable(1)
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}

export enum ActiveTabPrimary {
  DT = 3,
  Feeder11 = 2,
  Feeder33 = 1
}

export enum ActiveTabSecondary {
  Current = 0,
  Historical = 1
}
