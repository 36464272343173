import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-multi-filter-multiselect',
  templateUrl: './multi-filter-multiselect.component.html',
  styleUrls: ['./multi-filter-multiselect.component.scss']
})
export class MultiFilterMultiselectComponent {

  @Input() data: any;
  @Input() search: boolean = true;
  @Input() dropdownSelected: any;

  @Output() dataChange = new EventEmitter();
  public disableCheckbox: boolean = false;
  public checkedList: any = []

  public border: string = '1px solid #2E3336';
  public background: string = '#FFFFF';
  public stroke: string = '#2E3336';

  @Input() searchText: string = ''
  @Output() searchTextChange: EventEmitter<any> = new EventEmitter()
  @Input() placeholder: string = 'Search'
  public showData: any;

  constructor(private toastr: ToastrService,) { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.data)
    {
      this.searchChanged()
    }
    if(changes.searchText != undefined)
    {
      this.searchChanged()
    }
  }

  changeDropdownData(name: any, item:any){
    this.dropdownSelected = {data: {name: name} ,item: item }

    let dataChange = item

    this.dataChange.emit(dataChange);
  }

  changeData(data: any, isChecked: any) {
    if (data.value === 0) {
      this.data.forEach((dt: any) => {
        dt.checked = false;
      });
    }
    if(this.showData.maxSelected)
    {
      if (this.disableCheckbox && !isChecked){
        this.toastr.info("Maximum limit of selected is "+this.showData.maxSelected)
        return
      }
      else
      {
        data.checked = !isChecked
        this.disableCheckbox = this.showData.data.filter((item: any) => item.checked === true).length >= this.showData.maxSelected;
      }
    }
    else
      data.checked = !isChecked

  }

  searchChanged(){
    this.showData = { ...this.data }
    this.showData.data = this.showData.data.filter((x: { name: string; }) => x.name.toLowerCase().includes(this.searchText.toLowerCase()))
    this.searchTextChange.emit(this.searchText)
  }
}
