import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { TariffService } from 'src/app/services/tariff.service';

@Component({
  selector: 'app-meter-tariff-history',
  templateUrl: './meter-tariff-history.component.html',
  styleUrl: './meter-tariff-history.component.scss'
})
export class MeterTariffHistoryComponent implements OnInit {

  @Input() msn: string = '';
  @Input() accountId: number = 0;

  public dateFrom: any = ''
  public dateTo: any = ''
  public dtFrom: Date = new Date();
  public dtTo: Date = new Date();
  public maxDate: Date = new Date();

  public count: number = 0
  public pageSize: number = 10
  public currentPage: number = 1
  public currentMeterId: number = 1
  public lastPageSize: number = 1;

  public searchPlaceholder: any = "Search by Tariff";
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent
  options = ['']

  public previousTariffs: any;

  constructor(
    private tariffService: TariffService,
    private toastr: ToastrService
  ){}

  ngOnInit(): void {
    this.maxDate.setDate(this.maxDate.getDate());
    this.setMainDate();
    this.getHistory();
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    this.dtFrom.setDate(dte.getDate() - 7);
    this.dtTo.setDate(dte.getDate() + 1);
    this.dateFrom = {year: this.dtFrom.getFullYear(), month: this.dtFrom.getMonth() + 1, day: this.dtFrom.getDate()};
    this.dateTo = {year: this.dtTo.getFullYear(), month: this.dtTo.getMonth() + 1, day: this.dtTo.getDate()};
  }


  searchChange(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.getHistory()
  }

  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
    }
    if (searchText.name.length > 2) {
      this.tariffService.getDataForAutocomplete(obj)
        .subscribe(response => {
          this.options = response.data
        })
  } else
    this.searchComponentRef.resetOptions()
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    this.getHistory()
  }

  pageChange(value: number) {
    this.currentPage = value
    this.getHistory()
  }

  getHistory(){
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        msn: this.msn,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        searchContent: this.searchText.name,
        accountId: this.accountId
      }
    }
    this.tariffService.getTariffHistory(obj).subscribe(response => {
      this.previousTariffs = response.data.data
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize);
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

}
