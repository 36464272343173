<div class="dashboard-title">
  Network Perfomance
</div>

<div class="content-card">  
  <app-tab-default
    [firstText]="'Feeder'"
    [secondText]="'DT Customer Losses'"
    [nmDashCss]="true"
    (tabChanged)="mainTabChange($event)"
  ></app-tab-default>

  <div class="filter-wrapper">
    <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

      <app-multi-filter *ngIf="filterApplied"
          [data]="allFilters"
          (dataChange)="applyFilter($event)"
          [(showFilter)]="showFilter"
          (dropdownChange)="dropdownSelected($event)"
        ></app-multi-filter>

        <app-range-datepicker
          [name]="'Period'" 
          [isMonthly]="true" 
          [fromDate]="dateFrom" 
          [toDate]="dateTo" 
          (dateRangeSelected)="updateDate($event)">
        </app-range-datepicker>

    </div>
  </div>

  <div class="card-items-wrapper">

    <div class="card-item">
      <div class="box-item">
        <field-count-box-new label="Feeder Energy" 
          [width]="width" 
          [data]="(energyDistribution.energy | twoDecimals)+'MWh'"
          [isGreen]="true"
          [lastMonth]="valute + ' ' + (energyDistribution.energyInNGN | hasValue | twoDecimals)"
          [viewDetails]="false">
        </field-count-box-new>
      </div>
    </div>

    <div class="card-item">
      <div class="box-item">
        <field-count-box-new label="Prepaid" 
          [width]="width" 
          [data]="(energyDistribution.prepaid | twoDecimals)+'MWh'"
          [isYellow]="true"
          [lastMonth]="valute + ' ' + (energyDistribution.prepaidInNGN | hasValue | twoDecimals)"
          [viewDetails]="false">
        </field-count-box-new>
      </div>
    </div>

    <div class="card-item">
      <div class="box-item">
        <field-count-box-new label="Postpaid" 
          [width]="width" 
          [data]="(energyDistribution.postpaid | twoDecimals)+'MWh'"
          [isBlue]="true"
          [lastMonth]="valute + ' ' + (energyDistribution.postpaidInNGN | hasValue | twoDecimals)"
          [viewDetails]="false">
        </field-count-box-new>
      </div>
    </div>

    <div class="card-item">
      <div class="box-item">
        <field-count-box-new label="Losses" 
          [width]="width" 
          [data]="(energyDistribution.losses | twoDecimals)+'MWh'"
          [isRed]="true"
          [lastMonth]="valute + ' ' + (energyDistribution.lossesInNGN | hasValue | twoDecimals)"
          [viewDetails]="false">
        </field-count-box-new>
      </div>
    </div>

  </div>

  <div class="reliability-wrapper">

      <div class="reliability-items-wrapper">
          <div class="item">
            <span class="item-title">Monthly Availability</span>
            <span class="item-value">{{availability.monthlyAvailability | twoDecimals}} Hrs</span>
          </div>

          <div class="vertical-line"></div>

          <div class="item">
            <span class="item-title">Avg Daily Availability</span>
            <span class="item-value green">{{availability.avgDailyAvailability  | twoDecimals}} Hrs</span>
          </div>

          <div class="vertical-line"></div>
          
          <div class="item">
            <span class="item-title">Outage Duration</span>
            <span class="item-value red">{{outage.outageDuration  | twoDecimals}} Hrs</span>
          </div>

          <div class="vertical-line"></div>

          <div class="item">
            <span class="item-title">Frequency of Outage</span>
            <span class="item-value">{{outage.outageFrequency | twoDecimals}}</span>
          </div>

      </div>
  </div>
</div>
