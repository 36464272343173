import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BusinessUnit } from 'src/app/models/business-unit.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommonService } from 'src/app/services/common-service.service';
import { RoleService } from 'src/app/services/role.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})

export class CreateUserComponent {
  @Input() animationState = 'out';
  @Input('openModal') openModal: number = 0;
  // @ViewChild('slider')
  @ViewChild("f") f: any;
  public selectedUser: User = new User()

  @Input() regions: BusinessUnit[] = [];
  @Input() roles: Role[] = [];

  public emailValid: boolean = true;
  public phoneNumberValid: boolean = true;
  public passwordValid: boolean = true;
  @Output() parentComp = new EventEmitter();

  public buName: string = this.translationService.getByKeyFromCache('BU')

  showShadow = false;
  @ViewChild(CreateUserComponent) popupComponentRef!: CreateUserComponent;

  constructor(private toastr: ToastrService,
    private roleService: RoleService,
    private userService: UserService,
    public authService: AuthService,
    private commonService: CommonService,
    private buService: BusinessUnitService,
    private ref: ElementRef,
    private cdref: ChangeDetectorRef,
    private translationService: TranslationService
  ) { }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.user-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isSliderClicked) {
          this.changeState(2);
        }
      }
  }
  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }
  closeTab() {
    this.popupComponentRef.changeState(2);
  }
  save() {
    //we are ignoring this on backend, nothing changed
    //setting this just because backend want to get IFormFile?
    this.selectedUser.image = null;
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_user_createupdate)) {
      this.toastr.error("You don't have permission.");
      return;
    }
    // if (this.selectedUser.lastName.trim() === '') {
    //   this.toastr.error("Last Name is required.");
    //   return;
    // }
    // if (this.selectedUser.firstName.trim() == '') {
    //   this.toastr.error("First Name is required.");
    //   return;
    // }

    if (this.commonService.isEmail(this.selectedUser.email))
      this.emailValid = true;
    else
      this.emailValid = false;

     if (this.commonService.isPhoneNumber(this.selectedUser.phoneNumber))
        this.phoneNumberValid = true;
     else
      this.phoneNumberValid = false;

    if (this.emailValid == false || this.phoneNumberValid == false || this.selectedUser.roleId == 0)
      return;

    this.userService.save(this.selectedUser).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.changeState(2);
        this.resetSelectedUser();
        this.parentComp.emit();
        this.f.submitted = false;
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedUser() {
    this.selectedUser = new User();
  }

  changeState(number: Number, data?: any) {
    if (data === 0) {
      this.selectedUser = new User();
    }
    if (data)
      this.selectedUser = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.animationState = 'out';
      if(this.f)
        this.f.submitted = false;

      this.showShadow = false;
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
 }
}
