<div class="center">

    <div class="center-container">

        <div class="profile-box">
            <div class="ttl">Load Profile</div>

            <div class="bottom-wrapper" *ngIf="initialScreen">
                <div class="search-container">
                    <input #searchInput class="search-input" type="text" [(ngModel)]="accountOrMSNValue"
                        placeholder="Account number, SPN or meter number." (ngModelChange)="accValueChanged()">
                </div>
                <button (click)="search()">Search</button>
            </div>
            <div class="error-wrapper" *ngIf="invalidAccount && accountOrMSNValue != null && initialScreen || showInvalidInputError">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 15C10.2833 15 10.5208 14.9042 10.7125 14.7125C10.9042 14.5208 11 14.2833 11 14C11 13.7167 10.9042 13.4792 10.7125 13.2875C10.5208 13.0958 10.2833 13 10 13C9.71667 13 9.47917 13.0958 9.2875 13.2875C9.09583 13.4792 9 13.7167 9 14C9 14.2833 9.09583 14.5208 9.2875 14.7125C9.47917 14.9042 9.71667 15 10 15ZM9 11H11V5H9V11ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#C03438"/>
                </svg>
                <span>Number not found or invalid, please check and try again</span>
            </div>

            <div class="main-container" *ngIf="!initialScreen">
                <div class="data-main-wrapper">
                    <div class="data-wrapper">
                        <div class="data-header">
                            <span>Account Details</span>
                            <div class="search-again" (click)="searchAgain()">
                                <span>Search Again</span>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.25 13.5H3C2.58579 13.5 2.25 13.1642 2.25 12.75V3.75C2.25 3.33579 2.58579 3 3 3H15C15.4142 3 15.75 3.33579 15.75 3.75V12.75C15.75 13.1642 15.4142 13.5 15 13.5H9.75M11.25 11.25L9 13.5L11.25 15.75" stroke="#C03438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>

                        <div class="data-body">
                            <div class="account-details-wrapper">
                                <div class="details-header">Meter Number</div>
                                <div class="details-data" *ngIf="meter && meter.meterNumber && meter.meterNumber !== '/'">
                                    {{meter.meterNumber}}
                                    <img alt="Copy" class="icons" src="assets/images-new-design/copy.png" (click)="copyToClipboard(meter.meterNumber)">
                                </div>
                                <div class="NaN" *ngIf="!meter || !meter.meterNumber || meter.meterNumber === '/'">N/A</div>
                            </div>
                            <div class="account-details-wrapper">
                                <div class="details-header">Account Number</div>
                                <div class="details-data" *ngIf="data.accountNo !== '/'">
                                    {{data.accountNo}}
                                    <img alt="Copy" class="icons" src="assets/images-new-design/copy.png" (click)="copyToClipboard(data.accountNo)">
                                </div>
                                <div class="NaN" *ngIf="data.accountNo === '/'">N/A</div>
                            </div>
                            <div class="account-details-wrapper">
                                <div class="details-header">Customer Name</div>
                                <div class="details-data" *ngIf="data.firstName !== '/'">
                                    {{data.firstName}} {{data.lastName}}
                                </div>
                                <div class="NaN" *ngIf="data.firstName === '/'">N/A</div>
                            </div>
                            <div class="account-details-wrapper">
                                <div class="details-header">Customer Address</div>
                                <div class="details-data" *ngIf="data.address !== '/'">{{data.address}}</div>
                                <div class="NaN" *ngIf="data.address === '/'">N/A</div>
                            </div>
                            <div class="account-details-wrapper">
                                <div class="details-header">Tarrif</div>
                                <div class="details-data" *ngIf="data.tarrifName !== '/'">{{data.tarrifName}}</div>
                                <div class="NaN" *ngIf="data.tarrifName === '/'">N/A</div>
                            </div>
                            <div class="account-details-wrapper">
                                <div class="details-header">{{utName}}</div>
                                <div class="details-data" *ngIf="data.utName !== '/'">{{data.utName}}</div>
                                <div class="NaN" *ngIf="data.utName === '/'">N/A</div>
                            </div>
                            <div class="account-details-wrapper">
                                <div class="details-header">{{buName}}</div>
                                <div class="details-data" *ngIf="data.buName !== '/'">{{data.buName}}</div>
                                <div class="NaN" *ngIf="data.buName === '/'">N/A</div>
                            </div>
                            <div class="account-details-wrapper">
                                <div class="details-header">Feeder name</div>
                                <div class="details-data" *ngIf="data.feederName !== '/'">{{data.feederName}}</div>
                                <div class="NaN" *ngIf="data.feederName === '/'">N/A</div>
                            </div>
                            <div class="account-details-wrapper">
                                <div class="details-header">DT Name</div>
                                <div class="details-data" *ngIf="data.dtName !== '/'">{{data.dtName}}</div>
                                <div class="NaN" *ngIf="data.dtName === '/'">N/A</div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="form-wrapper">
                    <div class="formMgt">
                        <div class="formVol">
                            <label class="label">Voltage Min Load Limit </label>
                            <input class="form-control" type="number" [ngModel]="meter?.voltageMinLoadLimit" (ngModelChange)="meter.voltageMinLoadLimit = $event"
                                placeholder="Enter Voltage Min Load Limit"/>
                        </div>
                        <div class="formVol">
                            <label class="label">Voltage Max Load Limit </label>
                            <input class="form-control" type="number" [ngModel]="meter?.voltageMaxLoadLimit" (ngModelChange)="meter.voltageMaxLoadLimit = $event"
                                placeholder="Enter Voltage Max Load Limit"/>
                        </div>
                        <button class="black-btn" (click)="save()">Save changes</button>
                    </div>

                    <div *ngIf="meter && meter.voltageMinLoadLimit != null && meter.voltageMaxLoadLimit != null">
                      <div *ngIf="meter?.voltageMinLoadLimit >= meter.voltageMaxLoadLimit && meter.voltageMaxLoadLimit && meter.voltageMaxLoadLimit != 0"
                          class="form-control-error">Voltage Min Load Limit must be less than Voltage Max Load Limit</div>
                      <div *ngIf="meter.voltageMaxLoadLimit == 0 || meter.voltageMaxLoadLimit >= 1000"
                          class="form-control-error">Voltage Max Load Limit must be greater than 0 and less than 1000</div>
                      <div *ngIf="meter.voltageMinLoadLimit == 0"
                          class="form-control-error">Voltage Min Load Limit must be greater than 0</div>
                    </div>

                    <div class="formMgt">
                        <div class="formVol">
                            <label class="label">Current Min Load Limit </label>
                            <input class="form-control" type="number"  [ngModel]="meter?.currentMinLoadLimit" (ngModelChange)="meter.currentMinLoadLimit = $event"
                                placeholder="Enter Current Min Load Limit"/>

                        </div>
                        <div class="formVol">
                            <label class="label">Current Max Load Limit </label>
                            <input class="form-control" type="number"  [ngModel]="meter?.currentMaxLoadLimit" (ngModelChange)="meter.currentMaxLoadLimit = $event"
                                placeholder="Enter Current Max Load Limit"/>
                        </div>
                        <button class="black-btn" (click)="save()">Save changes</button>
                    </div>

                    <div *ngIf="meter && meter.currentMinLoadLimit != null && meter.currentMaxLoadLimit != null">
                      <div *ngIf="meter?.currentMinLoadLimit >= meter.currentMaxLoadLimit && meter.currentMaxLoadLimit && meter.currentMaxLoadLimit != 0"
                          class="form-control-error">Current Min Load Limit must be less than Current Max Load Limit</div>
                      <div *ngIf="meter.currentMaxLoadLimit == 0 || meter.currentMaxLoadLimit >= 1000"
                          class="form-control-error">Current Max Load Limit must be greater than 0 and less than 1000</div>
                      <div *ngIf="meter.currentMinLoadLimit == 0"
                          class="form-control-error">Current Min Load Limit must be greater than 0</div>
                    </div>

                </div>

                <div class="export-wrapper">
                    <button class="black-btn no-margin" (click)="export()">Export</button>
                </div>

            </div>
        </div>

    </div>

    <div class="profile-box5"  *ngIf="initialScreen">
        <div class="main">
            <div class="ttl">Bulk Upload</div>

            <div class="templ" (click)="getTemplate()">Download Template <svg width="15" height="20"
                    viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.24995 11.65V7.99998C8.24995 7.78716 8.17816 7.60896 8.03458 7.46538C7.89099 7.32179 7.71279 7.25 7.49997 7.25C7.28716 7.25 7.10896 7.32179 6.96537 7.46538C6.82179 7.60896 6.75 7.78716 6.75 7.99998V11.65L5.42687 10.3615C5.28201 10.2231 5.10894 10.1522 4.90768 10.149C4.70639 10.1458 4.52819 10.2218 4.37308 10.3769C4.22819 10.5218 4.15575 10.6974 4.15575 10.9038C4.15575 11.1102 4.22819 11.2859 4.37308 11.4307L6.8673 13.925C7.04807 14.1057 7.25896 14.1961 7.49997 14.1961C7.74099 14.1961 7.95188 14.1057 8.13265 13.925L10.6269 11.4307C10.7653 11.2923 10.8387 11.1208 10.8471 10.9163C10.8554 10.7118 10.782 10.532 10.6269 10.3769C10.4884 10.2385 10.317 10.1667 10.1125 10.1615C9.90799 10.1564 9.72819 10.2231 9.57308 10.3615L8.24995 11.65ZM1.8077 19.5C1.30257 19.5 0.875 19.325 0.525 18.975C0.175 18.625 0 18.1974 0 17.6923V6.9596C0 6.71858 0.0467999 6.48686 0.1404 6.26443C0.233983 6.04198 0.362825 5.8487 0.526925 5.6846L5.1846 1.02693C5.3487 0.862826 5.54198 0.733984 5.76442 0.640401C5.98686 0.546801 6.21858 0.5 6.4596 0.5H13.1922C13.6974 0.5 14.125 0.675 14.475 1.025C14.825 1.375 15 1.80257 15 2.3077V17.6923C15 18.1974 14.825 18.625 14.475 18.975C14.125 19.325 13.6974 19.5 13.1922 19.5H1.8077ZM1.8077 18H13.1922C13.2692 18 13.3397 17.9679 13.4038 17.9038C13.4679 17.8397 13.5 17.7692 13.5 17.6923V2.3077C13.5 2.23077 13.4679 2.16024 13.4038 2.09613C13.3397 2.03203 13.2692 1.99998 13.1922 1.99998H6.34998L1.49997 6.84998V17.6923C1.49997 17.7692 1.53202 17.8397 1.59612 17.9038C1.66024 17.9679 1.73077 18 1.8077 18ZM1.8077 18H1.49997H13.5H1.8077Z"
                        fill="#1C1B1F" />
                </svg>
            </div>
        </div>

        <div class="attachments-inner">
            <div class="attachment-titles">
            </div>

            <div class="select-file">
                <div class="box">
                    <button class="btn-upload">
                        <span class="text" (click)="chooseFile.click()">Upload File</span>
                    </button>
                </div>

                <input #chooseFile type="file" accept=".xlsx" style="display: none;"
                    (change)="onFileChosen($event); chooseFile.value = ''">

                <div class="file-name" *ngIf="fileName!=''">
                    <div>
                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.95997 7.95691L11.9328 0.984094C12.1227 0.794177 12.348 0.699219 12.6087 0.699219C12.8695 0.699219 13.0948 0.794177 13.2847 0.984094C13.4746 1.17401 13.5696 1.39933 13.5696 1.66005C13.5696 1.92076 13.4746 2.14607 13.2847 2.33599L5.628 9.9927C5.43808 10.1826 5.2154 10.2776 4.95997 10.2776C4.70454 10.2776 4.48187 10.1826 4.29195 9.9927L0.718578 6.41932C0.528662 6.22941 0.436343 6.00409 0.44162 5.74339C0.446912 5.48266 0.544516 5.25734 0.734433 5.06743C0.924349 4.87751 1.14967 4.78255 1.41039 4.78255C1.6711 4.78255 1.89641 4.87751 2.08633 5.06743L4.95997 7.95691Z"
                                fill="#188D52" />
                        </svg>
                    </div>
                    <span>{{fileName}}</span>
                </div>

            </div>
            <div class="fileTxt">XLSX, CSV, 24mb max file</div>

            <button class="upload-btn" (click)="uploadFile()">Save changes</button>
        </div>

    </div>
</div>

<app-multiple-amis-pop-up (amiSelectedEvent)="setMetersAmi($event)">
</app-multiple-amis-pop-up>
