import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';

@Component({
  selector: 'app-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.scss']
})
export class SearchAutocompleteComponent implements OnInit, OnDestroy {

  @Input() placeholder!: string
  @Input() width: number = 23
  @Input() borderColor: string = '#E8E8E8'
  @Input() backgroundColor: string = '#FFFFFF'
  @Input() searchContainerWidth: string = 'fit-content';

  @Output() result: EventEmitter<any> = new EventEmitter()

  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()
  @Output() searchTextChange: EventEmitter<any> = new EventEmitter()

  @Output() searchChange: EventEmitter<any> = new EventEmitter()
  @Output() searchAutocompleteChange: EventEmitter<any> = new EventEmitter()


  @ViewChild('searchInput') searchInput!: ElementRef;

  interval = new Subject<void>()
  @Input() setInterval: boolean = false

  @Input() options: any = []
  @Input() optionSelected: any = ''
  @Input() searchLength: number = 2

  currentKey: string | null = null;

  filteredOptions: any = []
  selectedOptionIndex: number = -1;
  visibleOptions: number = 4;
  delay = 300;
  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;

  selectedOption: string = '';
  control: UntypedFormControl;
  subscription!: Subscription;

  constructor() {
    this.control = new UntypedFormControl(this.searchText.name);
  }

  ngOnInit(): void {
    this.subscription = this.control.valueChanges
      .pipe(
        debounceTime(this.delay),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.searchText.name = value
        if (this.searchText.name == '') {
              this.searchText.value = 0
              this.searchText.type = 0
              this.onEnter()
              this.filteredOptions = []
              this.selectedOption = '';
            }
            else{
              this.onEnterAutocomplete();
            }
      });
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (this.selectedOptionIndex === 0) {
        this.optionSelected = this.filteredOptions[this.selectedOptionIndex + 1];
        this.selectedOptionIndex++;
        this.dropdownMenu.nativeElement.scrollTop += 4;
      }
      else if (this.selectedOptionIndex < this.filteredOptions.length - 1) {
        this.optionSelected = this.filteredOptions[this.selectedOptionIndex + 1];
        this.selectedOptionIndex++;
        this.dropdownMenu.nativeElement.scrollTop += 40;
      }
    }
    else if (event.key === 'ArrowUp') {
      event.preventDefault();

      if (this.selectedOptionIndex > 0) {
        this.optionSelected = this.filteredOptions[this.selectedOptionIndex - 1];
        this.selectedOptionIndex--;
        this.dropdownMenu.nativeElement.scrollTop -= 44;
      }
    }
  else if (event.key === 'Enter') {
    this.searchChange.emit(this.searchText)

    event.preventDefault();
    if (this.optionSelected) {
      this.onEnter(this.optionSelected);

      this.selectedOptionIndex = -1
    }
  }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options !== undefined) {
      this.filterData()
    }
    if(changes.searchText && (changes.searchText.currentValue.name != null || changes.searchText.currentValue.name != undefined)){
      this.control.setValue(changes.searchText.currentValue.name, {emitEvent: false});
    }
  }

  filterData() {
    if (this.searchText.name.length > this.searchLength) {
      this.filteredOptions = this.options
    }
  }

  onEnterAutocomplete() {
    this.searchAutocompleteChange.emit(this.searchText)
    this.filteredOptions = []

  }
  search(search: SearchAutocomplete) {
    this.searchChange.emit(search)
    this.filteredOptions = []
  }

  onEnter(clickedItem?: any) {
    if (!clickedItem) {
      this.searchChange.emit(new SearchAutocomplete())
    }
    if (clickedItem) {
      this.control.setValue(clickedItem.name, {emitEvent: false})
      this.searchText.type = clickedItem.type;
      this.searchText.value = clickedItem.value;
      this.searchChange.emit(clickedItem)
      this.filteredOptions = []
    }
    else if (this.searchText.name.length > 0) {
      return
    }
  }
  resetOptions() {
    this.filteredOptions = []
  }

  ngOnDestroy() {
    this.interval.next()
    this.interval.complete()
  }

}
