import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AuthService } from '../services/auth.service';
import { NotificationHubService } from '../services/notification-hub.service';
import { IdleLogoutPopupComponent } from '../components/common/idle-logout-popup/idle-logout-popup.component';
import { PopupEnum } from '../models/popupEnum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'AdoraNg';
  userColor = "#BF3A3A";
  secondsUntilIdle = 5*60; // after this many seconds user is considered Idle and gets a popup
  secondsUntilTimeout = 30; // after idle + this many seconds user is automaticaly logged out
  constructor(private router: Router, private idle: Idle,  private authService: AuthService, private notificationHub: NotificationHubService) {
    idle.setIdle(this.secondsUntilIdle); // 5 seconds for the user to be set in idle
    idle.setTimeout(this.secondsUntilTimeout); // 15 minutes for the user to be logged out

    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleStart.subscribe( () =>{
      if(authService.isLoggedIn()){
        this.openPopUp();
      }

    })
    idle.onTimeoutWarning.subscribe(() =>{ // event listener that fires an event every ~1 second after user is idle
      if(this.idleStartTime != null){
        this.idlePopup.countdown = Math.round(this.secondsUntilTimeout - (Date.now() - this.idleStartTime) / 1000);
      }
    })

    idle.onTimeout.subscribe(() => {
      if(authService.isLoggedIn()){
        this.idlePopup.changeState(2);
        this.notificationHub.close();
        this.authService.logout();
        this.router.navigate(['/login']);
      }
      this.resetIdleCheck();
    });

    this.resetIdleCheck();
  }
  ngAfterViewInit(): void {
    this.idlePopup.logoutResponse.subscribe((response : boolean) =>{
      this.idlePopup.changeState(2);
      if(response){
        if(this.authService.isLoggedIn()){
          this.notificationHub.close();
          this.authService.logout();
          this.router.navigate(['/login']);
        }
        else{
          this.resetIdleCheck();
        }
      }
    })
  }

  @ViewChild(IdleLogoutPopupComponent) idlePopup!: IdleLogoutPopupComponent
  idleStartTime : number | null = null;
  openPopUp() {
    this.idleStartTime = Date.now();
     this.idlePopup.changeState(PopupEnum.Open)
  }

ngOnInit(): void {
  const user: any = this.authService.decodeJWTToken();
  document.documentElement.style.setProperty('--user-color', user?.primaryColorAdora ?? this.userColor);
  document.documentElement.style.setProperty('--user-secondary-color', user?.secondaryColorAdora);
  document.documentElement.style.setProperty('--user-fontFamily', user?.fontStyleAdora);
}
  resetIdleCheck() {
    this.idle.watch();
  }

  public urlContains(part: any) {
    return this.router.url.includes(part);
  }
}
