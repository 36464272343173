import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DtService } from 'src/app/services/dt.service';
import { MapService } from 'src/app/services/map.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { DashboardCardDataNM } from '../../models/dashboard-data.model';

@Component({
  selector: 'app-network-management-dashboard-new',
  templateUrl: './network-management-dashboard-new.component.html',
  styleUrls: ['./network-management-dashboard-new.component.scss']
})
export class NetworkManagementDashboardNewComponent implements OnInit {

  // public totalEnergyDistributed: number = 0
  // public totalEnergyDistributedPreviousMonth: number = 0
  // public totalEnergyReceived: number = 0
  // public totalEnergyReceivedPreviousMonth: number = 0
  // public gridOutageAnalysis: number = 0
  // public gridOutageAnalysisPreviousMonth: number = 0
  // public totalActiveFeeders: number = 0
  // public totalActiveFeedersPreviousMonth: number = 0

  public onModelChangeSubject = new Subject<string>();  


  public ssCount: number = 0
  public dtCount: number = 0
  public tsCount: number = 0
  public buCount: number = 0
  public utCount: number = 0

  public buName: string = ''
  public utName: string = ''

  public totalEnergyConsumption: number = 0
  public totalEnergyConsumptionF11: number = 0
  public totalEnergyConsumptionF33: number = 0
  public totalEnergyConsumptionTwoMonthsAgo: number = 0
  public cardData: DashboardCardDataNM = new DashboardCardDataNM();
  public width: string = "35rem";
  public height: string = "17rem";
  public searchWidth: string ="412px"
  public searchWidth2: string ="412px"

  public newtworkTabSelected: boolean = true
  public feederTypeSelected: string = '33'

  public searchText: string = ''
  public organizationData:boolean=false;

  @Input() markers: any[] = []
  @Input() markersTemp: any[] = []
  @Input() ssMarkers: any[] = []
  @Input() tsMarkers: any[] = []
  @Input() dtMarkers: any[] = []
  @Input() buMarkers: any[] = []
  @Input() utMarkers: any[] = []
  
  constructor(
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private substationService: SubstationService,
    private dtService: DtService,
    private tsService: TransmissionStationService,
    private buService: BusinessUnitService,
    private utService: UndertakingService,
    private translationService: TranslationService,
    private mapService: MapService
  ) { }
  
  ngOnInit(): void {
    // this.getDashboardDataCards()
    this.getCardData()
    this.getAssetsCount()
    this.getTranslations()
    this.getTotalEnergyConsumption()
    this.onModelChangeSubject.pipe(debounceTime(1000)).subscribe(x=> {
      this.searchText = x;
    });
    
  }


  getAssetsCount() {
    this.dtService.getCount().subscribe(x => {
      if (x.status !== 200) {
        this.toastr.error(x.message)
        return
      }
      this.dtCount = x.data
    })

    this.substationService.getCount().subscribe(x => {
      if (x.status !== 200) {
        this.toastr.error(x.message)
        return
      }
      this.ssCount = x.data
    })

    this.tsService.getCount().subscribe(x => {
      if (x.status !== 200) {
        this.toastr.error(x.message)
        return
      }
      this.tsCount = x.data
    })

    this.buService.getCount().subscribe(x => {
      if (x.status !== 200) {
        this.toastr.error(x.message)
        return
      }
      this.buCount = x.data
    })

    this.utService.getCount().subscribe(x => {
      if (x.status !== 200) {
        this.toastr.error(x.message)
        return
      }
      this.utCount = x.data
    })
  }

  getTranslations() {
    this.utName = this.translationService.getByKeyFromCache('UT')
    this.buName = this.translationService.getByKeyFromCache('BU')
  }

  getTotalEnergyConsumption(){
    this.dashboardService.getTotalEnergyConsumption().subscribe(x => {
      this.totalEnergyConsumptionF11 = x.data.previousMonthF11
      this.totalEnergyConsumptionF33 = x.data.previousMonthF33
      this.totalEnergyConsumption = this.totalEnergyConsumptionF33
      this.totalEnergyConsumptionTwoMonthsAgo = x.data.twoMonthsAgo
    })
  }

  getCardData() {
    this.dashboardService.getDashboardDataCards().subscribe
      (x => {
        this.cardData = x.data;
      })
  }

  tabClicked() {
    this.newtworkTabSelected = !this.newtworkTabSelected
    if (this.newtworkTabSelected) {
      this.searchChange('')
      this.organizationData=false;
    }
    else {
      this.searchChange('')
      this.organizationData=true;
    }
  }

  searchChange(text: string) {
    this.searchText = text
    if (!text) {
      this.markersTemp = [...this.markers]
      return
    }
    this.markersTemp = this.markers.filter(x => 
      x.title.toLowerCase().includes(this.searchText.toLowerCase()) ||
      x.assetNo?.toLowerCase().includes(this.searchText.toLowerCase()) ||
      x.meterNo?.toLowerCase().includes(this.searchText.toLowerCase())
    )
  }

  // getDashboardDataCards() {  
  //   this.dashboardService.getDashboardDataCards().subscribe(x => {
  //     if (x.status !== 200) {
  //       this.toastr.error(x.message)
  //       return
  //     }
  //     this.totalEnergyDistributed = x.data.totalEnergyDistributed
  //     this.totalEnergyDistributedPreviousMonth = x.data.totalEnergyDistributedPreviousMonth
  //     this.totalEnergyReceived = x.data.totalEnergyReceived
  //     this.totalEnergyReceivedPreviousMonth = x.data.totalEnergyReceivedPreviousMonth
  //     this.gridOutageAnalysis = x.data.gridOutageAnalysis
  //     this.gridOutageAnalysisPreviousMonth = x.data.gridOutageAnalysisPreviousMonth
  //     this.totalActiveFeeders = x.data.totalActiveFeeders
  //     this.totalActiveFeedersPreviousMonth = x.data.totalActiveFeedersPreviousMonth
  //   })
  // }

  filterClicked(filter: string){
    this.feederTypeSelected = filter;
    if(this.feederTypeSelected == '11'){
      this.totalEnergyConsumption = this.totalEnergyConsumptionF11;
      console.log('F11', this.totalEnergyConsumptionF11)
    }
    else
      this.totalEnergyConsumption = this.totalEnergyConsumptionF33;
  }
}
