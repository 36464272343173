<div class="slider-back" [hidden]="!showShadow">

</div>

<div [hidden]="!showShadow">
    <div class="slider" [ngClass]="{'shadow' : showShadow === true}" [@slideInOut]="animationState">
        <div class="tab-header">
            <span>Edit Configuration</span>
            <div class="btn-close-position" (click)="changeState(2)">
                <div class="btn-close">
                    <img alt="Close" src="../../../../../../assets/images-new-design/Close.png">
                </div>
            </div>

        </div>
        <form (submit)="f.valid" class="form col-sm-12" #f="ngForm">
                <div class="col-sm-12 crud-input-container" *ngFor="let item of configs">
                    <label class="labelPopup">{{ item.name }}</label>
                    <input class="form-control" name="{{item.name}}" [(ngModel)]="item.value" #name="ngModel"
                        required autocomplete="off" placeholder="Translation" />
                    <div *ngIf="f.submitted && name.invalid" class="form-control-error">{{item.name}} is required</div>
                </div>
            <div class="user-btn">
                <button class="submit col-xs-6" (click)="save()">Save</button>
                <button class="cancel-btn col-xs-6" (click)="closeTab()">Cancel</button>
            </div>
        </form>

    </div>
</div>
