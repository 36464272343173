import { Component, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

export type CropperDialogData  = {
    image: File;
    dialogTitle: string;
    isRoundImage: boolean;
}

export type CropperDialogResult  = {
    blob: Blob;
    imageUrl: string;
}

@Component({
  selector: 'image-cropper-dialog',
  template: `
    <div class="image-cropper-container">
        <h1 class="cropper-title" mat-dialog-title>{{ data.dialogTitle }}</h1>

        <div mat-dialog-content>
            <image-cropper
                [roundCropper]="data.isRoundImage"
                [imageFile]="data.image"
                [containWithinAspectRatio]="true"
                [maintainAspectRatio]="false"
                (imageCropped)="imageCropped($event)"
            ></image-cropper>
        </div>

        <div class="buttons-container align-center">
            <button mat-raised-button class="button regular--outline" [mat-dialog-close]="false" >Cancel</button>
            <button mat-raised-button class="button regular" [mat-dialog-close]="result()">Save</button>
        </div>
    </div>
  `,
  styles: `
    .cropper-title {
        font-size: 16px;
        font-weight: 600;
    }
    .buttons-container {
        padding: 2.5rem 0;
        gap: 10px;
    }

    .ratio {
        display: grid;
        grid-template-columns: max-content auto;
        font-size: 14px;
        padding: 2rem;

        .text {
            margin-right: 8px;
            font-weight: 500;
            align-content: center
        }

        .inputs {
            display: grid;
            grid-template-columns: 70px 60px;
            grid-gap: 2px;

            .label {
                color: var(--color-gray);
            }

            input {
                border: 1px solid var(--color-light-gray);
                border-radius: 0.8rem;
                padding: 0.5rem 1rem;
                color: var(--bg-black-color);
                max-width: 60px
            }

            .separator {
                color: var(--color-gray);

            }
        }
    }
  `,
})
export class ImageCropperDialogComponent {
    data: CropperDialogData = inject(MAT_DIALOG_DATA);
    result = signal<CropperDialogResult | undefined>(undefined);

    imageCropped(event: ImageCroppedEvent) {
        const { blob, objectUrl } = event;
        // console.log('imageCropped', blob, objectUrl);

        if (blob && objectUrl) {
            this.result.set({ blob, imageUrl: objectUrl });
        }
    }
}
