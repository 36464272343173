<main class="container-fluid">
    <div class="container-wrapper">
        <div class="network-management-title">
            <span>Trend Analysis</span>
            <img alt=">" src="assets\images-new-design\arrow_forward_ios.svg">
            <span>Historical Trend</span>
        </div>

        <div class="nm-filter-wrapper">
            <app-tab-default
                [firstText]="'33KV Feeders'"
                [secondText]="'11KV Feeders'"
                [thirdText]="'Distribution Transformer'"
                (tabChanged)="switchMenu($event)"
                [paCss]="true"
                [womCss]="true"
            >
            </app-tab-default>
            <app-graph-table-button
            [isGraph]="isGraph"
            (filterClicked)="graphChanged($event)"
          ></app-graph-table-button>
        </div>

        <div class="filters-container">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                <app-multi-filter *ngIf="filterApplied"
                    [data]="allFilters"
                    (dataChange)="setFilters($event)"
                    [(showFilter)]="showFilter"
                    (dropdownChange)="dropdownSelected($event)"
                >
                </app-multi-filter>

                <app-range-datepicker
                [name]="'Period'"
                [isMonthly]="isMonthly"
                [fromDate]="dateFrom"
                [toDate]="dateTo"
                (dateRangeSelected)="DateClickEvent($event)">
                </app-range-datepicker>

                <app-search-autocomplete
                #search
                [placeholder]="searchPlaceholder"
                [searchText]="searchText"
                [options]="options"
                (searchChange)="searchChanged($event)"
                (searchAutocompleteChange)="getOptions($event)"
              ></app-search-autocomplete>

            </div>
            <div class="btn-wrapper">
                <app-download-button-new
                    [isOnlyDownload]="true"
                    (downloadButtonClickedChange)="downloadReport()">
                </app-download-button-new>
            </div>
        </div>

        <div>
            <div class="row" *ngIf="isGraph else table">

                <div class="col-12">
                    <div class="row justify-content-end">
                        <div class="col-11 text-center">
                            <p class="chart-title">Total Energy Received vs Consumption vs Losses</p>
                            <div>
                                <img alt="" src="assets\images-new-design\Ellipse1.svg">
                                <span class="chart-subtitle">Total energy received into network</span>
                                <img alt="" src="assets\images-new-design\Ellipse3.svg">
                                <span class="chart-subtitle">Total energy transmitted</span>
                                <img alt="" src="assets\images-new-design\Ellipse2.svg">
                                <span class="chart-subtitle" *ngIf="this.menu !== 3">Total energy losses</span>
                                <span class="chart-subtitle" *ngIf="this.menu === 3">Total energy consumption</span>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-sm-1 text-right">
                            <p class="chart-label">Energy (MWh)</p>
                        </div>
                        <div class="col-11 chart-bar">
                            <canvas baseChart [datasets]="receivedAndConsumedData" [labels]="barChartLabels"
                                [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="false"
                                [height]="chartHeight">
                            </canvas>
                        </div>

                    </div>
                    <div class="row align-items-center">
                        <div class="col-sm-2">

                        </div>
                        <div class="col text-center">
                            <p class="chart-labels">Month</p>
                        </div>
                    </div>
                </div>

            </div>

            <ng-template #table>
                <app-new-consumption-table [barChartData]="barChartData" [EnergyLabels]="barChartTableLabels"
                    [avgConsumed]="avgConsumed" [avgReceived]="avgReceived" [avgLosses]="avgLosses" [avgLossesTo11Kv]="avgLossesTo11Kv"
                    [receivedAndConsumedData]="receivedAndConsumedData" [tab]="menu"></app-new-consumption-table>
            </ng-template>
        </div>
    </div>
</main>
