import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { MenuItem } from '../model/model';
import { F33OutagePopupComponent } from './f33-outage-popup/f33-outage-popup.component';

@Component({
  selector: 'app-outage-feeder33-new',
  templateUrl: './outage-feeder33.component.html',
  styleUrls: ['./outage-feeder33.component.scss']
})
export class OutageFeeder33NewComponent{

  @Input() categoryId: number = -1
  @Input() tsIds: number[] = []
  @Input() durationFrom: number = 0
  @Input() durationTo?: number = undefined;
  @Input() menu!: MenuItem;
  @Input() tabMenu: number=0;

  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''

  public menuItemEnum = MenuItem
  public pageSize: number = 10
  public currentPage: number = 1
  public lastPageSize: number = 1;
  public lastMenu: MenuItem = MenuItem.CurrentOutage;

  public data: any[] = []
  count: number = 0
  public selectedF33: any = [];

  public checkedList: number[] = []



  @Output() redirect: EventEmitter<any> = new EventEmitter()
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()
  @ViewChild(F33OutagePopupComponent) popupRef!: F33OutagePopupComponent;

  public masterSelected: boolean = false;
  constructor(
    private f33Service: Feeder33Service,
    private toastr: ToastrService,
    private detectionChange: ChangeDetectorRef
  ) { }

  viewMore(feeder: any) {
    this.selectedF33 = this.data.find(x => x.feederId == feeder.feederId)
    this.openTab(this.selectedF33)
  }

  openTab(selectedF33: any) {
    this.popupRef.changeState(selectedF33, 1);
  }
  closeTab() {
    this.popupRef.changeState(this.selectedF33, 2);
  }
  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false

    if (
      changes.ssIds !== undefined ||
      changes.categoryId !== undefined ||
      changes.dateFrom !== undefined || changes.dateTo !== undefined ||
      changes.durationFrom !== undefined || changes.durationTo !== undefined ||
      changes.searchText !== undefined
    ) {
      reloadTable = true;
    }
    if (this.searchText != null) {
      reloadTable = true
    }
    if (changes.menu !== undefined || changes.tabMenu !== undefined) {
      reloadTable = true;
      this.resetFilters();
    }

    if (reloadTable) {
      this.checkedList = [];
      this.pageChange(1)
    }

  }

  public GetData() {
    let isCurrentOutage = false
    if (this.menu === MenuItem.CurrentOutage) {
      isCurrentOutage = true;
    }
    let obj=null;
    if(isCurrentOutage){

        obj = {
        pageInfo: {
          page: this.currentPage,
          pageSize: this.pageSize
        },
        filterParams: {
          //From: transformToString(this.dateFrom),
         // To: transformToString(this.dateTo),
          TsIds: this.tsIds,
          MinOutageDuration: this.durationFrom,
          MaxOutageDuration: this.durationTo,
          OutageCategory: this.categoryId,
          Search: this.searchText.name,
          IsCurrentOutage: isCurrentOutage
        }
      };

    }
    else{

      obj = {
        pageInfo: {
          page: this.currentPage,
          pageSize: this.pageSize
        },
        filterParams: {
          From: transformToString(this.dateFrom),
          To: transformToString(this.dateTo),
          SsIds: this.tsIds,
          MinOutageDuration: this.durationFrom,
          MaxOutageDuration: this.durationTo,
          OutageCategory: this.categoryId,
          Search: this.searchText.name,
          IsCurrentOutage: isCurrentOutage
        }
      };


    }



    this.GetOutagesForF33(obj);

  }

  private GetOutagesForF33(obj: any) {
    this.f33Service.getFeedersOutages(obj).subscribe(response => {
      if (response.status == 200) {
        this.data = response.data.data;
        this.isAlreadyChecked();
        if (obj.pageInfo.page == 1)
          this.count = response.data.count
        if (this.count == 0)
          this.count++;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An error occurred")
    });
  }

  public download() {
    let isCurrentOutage = false
    if (this.menu === MenuItem.CurrentOutage) {
      isCurrentOutage = true;
    }

    let obj = {
      pageInfo: {
        page: 0,
        pageSize: this.pageSize
      },
      filterParams: {
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        TsIds: this.tsIds,
        MinOutageDuration: this.durationFrom,
        MaxOutageDuration: this.durationTo,
        OutageCategory: this.categoryId,
        Search: this.searchText.name,
        outageIds: this.checkedList,
        IsCurrentOutage: isCurrentOutage
      }
    };

    this.downloadOutageReport(obj);

  }

  private downloadOutageReport(obj: any) {
    if(this.menu === MenuItem.CurrentOutage){
      this.f33Service.downloadCurrentOutages(obj).subscribe(response => {
        if(response.status === 200){
          window.location.href = response.data;
        }else if(response.data===null){
          this.toastr.info(response.message)
        }
      }, _ => {
        this.toastr.error('An error occurred.');
      })

    }
    else{
      this.f33Service.downloadHistoryOfOutages(obj).subscribe(response => {
        if(response.status === 200){
          window.location.href = response.data;
        }else if(response.data===null){
          this.toastr.info(response.message)
        }
      }, _ => {
        this.toastr.error('An error occurred.');
      })

    }

  }

  resetFilters() {
    this.categoryId = 0;
    this.durationFrom = 0
    this.durationTo = undefined
    this.tsIds = []

    this.detectionChange.detectChanges()
  }

  public pageChange(page: number) {
    this.currentPage = page;
    this.GetData()
  }

  public isPlanned(plan: string) {
    if (plan === 'Planned') {
      return true;
    }
    else return false;
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
      this.checkForDownload(this.data[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.data.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item:any) {
      return item.checked == true;
    })
  }

}
