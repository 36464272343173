import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { CrudService } from 'src/app/services/crudService.service';
import { DepartmentService } from 'src/app/services/department.service';
import { StaffOperatorService } from 'src/app/services/staff-operator.service';
import { SubunitService } from 'src/app/services/subunit.service';
import { UnitService } from 'src/app/services/unit.service';
import { ImportDetailsPopupComponent } from '../../common-new-design/import-details-popup/import-details-popup.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { StaffOperatorTabComponent } from '../../crud-tabs/staff-operator-tab/staff-operator-tab.component';
import { UnitTabComponent } from '../../crud-tabs/unit-tab/unit-tab.component';
import { SubunitTabComponent } from '../../crud-tabs/subunit-tab/subunit-tab.component';
import { DepartmentTabComponent } from '../../crud-tabs/department-tab/department-tab.component';
import { CrudDeleteModalComponent } from '../../crud-tabs/crud-delete-modal/crud-delete-modal.component';
import { CreateStaffOperatorComponent } from '../../crud-tabs/staff-operator-tab/create-staff-operator/create-staff-operator.component';
import { CreateUnitComponent } from '../../crud-tabs/unit-tab/create-unit/create-unit.component';
import { CreateSubunitComponent } from '../../crud-tabs/subunit-tab/create-subunit/create-subunit.component';
import { CreateDepartmentComponent } from '../../crud-tabs/department-tab/create-department/create-department.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ScrollableAutocompleteFilterComponent } from '../../common-new-design/scrollable-autocomplete-filter/scrollable-autocomplete-filter.component';

@Component({
  selector: 'app-staff-operator-crud-pages',
  templateUrl: './staff-operator-crud-pages.component.html',
  styleUrl: './staff-operator-crud-pages.component.scss'
})
export class StaffOperatorCrudPagesComponent {
  @ViewChild('staffOperatorTabComponent') staffOperatorTabComponent!: StaffOperatorTabComponent;
  @ViewChild('unitTabComponent') unitTabComponent!: UnitTabComponent;
  @ViewChild('subunitTabComponent') subunitTabComponent!: SubunitTabComponent;
  @ViewChild('departmentTabComponent') departmentTabComponent!: DepartmentTabComponent;

  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  @ViewChild(CreateStaffOperatorComponent) createStaffOperatorComponent!: CreateStaffOperatorComponent;
  @ViewChild(CreateUnitComponent) createUnitComponent!: CreateUnitComponent;
  @ViewChild(CreateSubunitComponent) createSubunitComponent!: CreateSubunitComponent;
  @ViewChild(CreateDepartmentComponent) createDepartmentComponent!: CreateDepartmentComponent;

  public selectedItemDelete: any;

  public addNewPermission: boolean = false;
  public isPopupOpen: boolean = false;

  public searchPlaceholder: string = "Search"
  public searchAutocomplete: SearchAutocomplete = new SearchAutocomplete()
  public crudStaffOperatorVisible: boolean = false;
  public crudUnitVisible: boolean = false;
  public crudSubunitVisible: boolean = false;
  public crudDepartmentVisible: boolean = false;

  currentPage = 1;
  pageSize = 10;
  count = 0;
  tabName = 'Department';
  componentName: ComponentNamesNew = ComponentNamesNew.DepartmentComponent;
  @ViewChild(ImportDetailsPopupComponent) importRef!: ImportDetailsPopupComponent;
  @ViewChild('searchInput') searchInput!: ElementRef;
  uploadAndDownloadDisabled: boolean = false
  showShadow = false;

  searchText: string = '';
  options: any[] = [];
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  constructor(public authService: AuthService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public amiService: AmiService,
    public toastr: ToastrService,
    private staffOperatorService: StaffOperatorService,
    private unitService: UnitService,
    private subunitService: SubunitService,
    private departmentService: DepartmentService,
    private crudService: CrudService) { }

  ngOnInit(): void {
  }

  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: false
    }
    switch (this.componentName) {
      case ComponentNamesNew.StaffOperatorComponent:
        this.staffOperatorService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })        
          break;
      case ComponentNamesNew.UnitComponent:
        this.unitService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })        
          break;        
      case ComponentNamesNew.SubunitComponent:
        this.subunitService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })        
          break;
      case ComponentNamesNew.DepartmentComponent:
        this.departmentService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })        
          break;
    }
    if (searchText.name.length > 2) {
      this.searchComponentRef.resetOptions()
    }
  }

  inputChanged(searchText: SearchAutocomplete) {
    this.options = [];
    this.crudService.setGlobalSearch({ clicked: true, text: searchText.name, componentName: this.componentName })
  }

  changeState(id?: number) {
    this.isPopupOpen = true;
    this.showShadow = true;

  }

  changeTab(textLabel: any) {
    this.searchAutocomplete = new SearchAutocomplete()
    this.searchComponentRef.resetOptions()
    this.crudService.cleanGlobalSearch()
    switch (textLabel) {
      case 3:
        this.tabName = 'Staff Operator'
        this.componentName = ComponentNamesNew.StaffOperatorComponent
        this.uploadAndDownloadDisabled = true
        break;
      case 1:
        this.tabName = 'Unit'
        this.componentName = ComponentNamesNew.UnitComponent
        this.uploadAndDownloadDisabled = true
        this.createUnitComponent.getAllDepartmentsForSelect()
        break;
      case 2:
        this.tabName = 'Subunit'
        this.componentName = ComponentNamesNew.SubunitComponent
        this.uploadAndDownloadDisabled = true
        this.createSubunitComponent.getAllUnitsForSelect()
        break;
      case 0:
        this.tabName = 'Department'
        this.componentName = ComponentNamesNew.DepartmentComponent
        this.uploadAndDownloadDisabled = true
        break;
    }
  }
  
  tabEvent(tab: MatTabChangeEvent) {
    this.changeTab(tab.tab.textLabel);
  }

  addNew() {  
    if (this.tabName === 'Staff Operator') {
      this.createStaffOperatorComponent.resetSelectedEntity()
      this.createStaffOperatorComponent.changeState(1)
    }
    if (this.tabName === 'Unit') {
      this.createUnitComponent.resetSelectedEntity()
      this.createUnitComponent.changeState(1)
    }
    if (this.tabName === 'Subunit') {
      this.createSubunitComponent.resetSelectedEntity()
      this.createSubunitComponent.changeState(1)
    }
    if (this.tabName === 'Department') {
      this.createDepartmentComponent.resetSelectedEntity()
      this.createDepartmentComponent.changeState(1)
    }
  }

  onDeleteConfirmed() {
    switch (this.tabName) {
      case 'Staff Operator':
        this.staffOperatorTabComponent.onDeleteConfirmed();
        break;
      case 'Unit':
        this.unitTabComponent.onDeleteConfirmed();
        break;
      case 'Subunit':
        this.subunitTabComponent.onDeleteConfirmed();
        break;
      case 'Department':
        this.departmentTabComponent.onDeleteConfirmed();
        break;
    }
  }

  openDelete(item?: any) {
    this.crudDeleteRef.changeState();
  }

  resetSelectedDelete() {
    this.selectedItemDelete = null;
  }

  openTab(item?: any) {
    switch (this.tabName) {
      case 'Staff Operator':
        this.createStaffOperatorComponent.changeState(1, item);
        break;
      case 'Unit':
        this.createUnitComponent.changeState(1, item);
        break;
      case 'Subunit':
        this.createSubunitComponent.changeState(1, item);
        break;
      case 'Department':
        this.createDepartmentComponent.changeState(1, item);
        break;
    }
  }
}
