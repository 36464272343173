import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CaimsOperator } from 'src/app/models/caimsOperator.model';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { StockItem } from 'src/app/models/stock-item.model';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';
import { CommonService } from 'src/app/services/common-service.service';
import { InventoryManagementService } from 'src/app/services/inventory-management.service';

@Component({
  selector: 'app-item-issuance',
  templateUrl: './item-issuance.component.html',
  styleUrls: ['./item-issuance.component.scss']
})
export class ItemIssuanceComponent implements OnInit {
  // , OnChanges

  @Output() changeView: EventEmitter<any> = new EventEmitter()
  @Output() reloadTable = new EventEmitter<any>();

  public items: DictionaryItem[] = []
  public selectedItem: DictionaryItem = new DictionaryItem()

  public tasks: DictionaryItem[] = []
  public selectedTask: DictionaryItem = new DictionaryItem()

  public agents: CaimsOperator[] = []
  public selectedAgent: CaimsOperator = new CaimsOperator()

  @Input() itemId = 0
  public stockItem: StockItem = new StockItem()


  constructor(private toastr: ToastrService,
    private commonService: CommonService,
    private inventoryService: InventoryManagementService,) { }

  ngOnInit(): void {
    this.getAllItems()
    this.getAllTasks()
    this.setDefaultHeaders()
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.itemId != null && changes.itemId.currentValue != undefined){
      this.itemId = changes.itemId.currentValue
      if(this.itemId != 0){
        this.getStockItem()
      }
    }
  }

  getStockItem(){
    this.inventoryService.getSingleStockItem(this.itemId).subscribe(response =>{
      this.setTaskFields(response.data)
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  getAllItems(){
    this.commonService.getAllStockItems().subscribe(response => {
      this.items = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getAllTasks(){
    this.commonService.getAllTasks().subscribe(response => {
      this.tasks = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  
  setTaskFields(item: StockItem){
    this.selectedItem.name = item.name
  }


  setDefaultHeaders(){
    this.selectedItem.name = "Select item"
    this.selectedTask.name = "Select task"
    this.selectedAgent.userName = "Select agent"
  }

  selectItem(item: DictionaryItem){
    this.selectedItem = item
    this.stockItem.id = item.value
  }

  selectTask(item: DictionaryItem){
    this.selectedTask = item
    this.stockItem.relatedTaskId = item.value
  }

  selectAgent(item: any){
    this.selectedAgent = this.agents.find(x => x.id == item) ?? new CaimsOperator()
  }

  resetSelectedAgent(){
    this.selectedAgent = new CaimsOperator()
    this.selectedAgent.userName = "Select agent"
  }


  public issue(){
    // this.getStockItem();
    let obj = {
      stockItemId: this.selectedItem.value,
      issuedQuantity: this.stockItem.issuedQuantity,
      taskId: this.selectedTask.value
    };

    if(!this.validate())
      return

    this.inventoryService.issueItem(obj).subscribe(response => {
      if(response.status === 200 || response.status === 201) {
        this.toastr.success(response.message);
        this.handleIssueItem();
        this.changeView.emit(ViewsEnum.View)
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  handleIssueItem() {
    this.reloadTable.emit();
  }

  validate() {
    let isValid = true
    if(this.stockItem.id <= 0)
      isValid = false;

    if(this.stockItem.issuedQuantity <= 0)
      isValid = false;

    if(this.stockItem.relatedTaskId <= 0)
      isValid = false;

    if(!isValid){
      this.toastr.warning('Some required fields are empty')
    }
    return isValid
  }

  back(){
    this.changeView.emit(ViewsEnum.View)
  }

  alert() {
    this.toastr.warning("Not yet implemented.");
  }

}
