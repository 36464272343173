import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { HeaderService } from 'src/app/services/header.service';
import { UserService } from 'src/app/services/user.service';
import { SwitchModulePopupComponent } from '../switch-module-popup/switch-module-popup.component';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';

@Component({
  selector: 'app-new-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class NewHeaderComponent implements OnInit {

  @Input() expandSidebar: boolean = false;
  @Output() expandSidebarChange = new EventEmitter();
  @ViewChild(SwitchModulePopupComponent) popupComponentRef!: SwitchModulePopupComponent;

  name: string = '';
  lastName: string = '';

  @Input() border: string = 'none';

  showIcon: boolean=true;
  @Output() showNotification: boolean = false;

  logo: string = '';

  showSwitchModule: boolean = true;
  currentModule: string = '';

  id: number = 0;
  email: string = '';

  searchText: string = '';
  showSearchInput: boolean = true;
  componentName: ComponentNamesNew = ComponentNamesNew.NoComponent;
  private ngUnsubscribe = new Subject<void>();
  public initial: string = '';

  public loggedInUser: User = new User();

  constructor(private authService: AuthService,
    private userService: UserService, private toastr: ToastrService, private headerService: HeaderService, private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(
      (event) => {
        this.currentModule = this.route.snapshot.data['currentModule'];
        if (event instanceof ActivationEnd && event.snapshot.children.length == 0){
          this.componentName = event.snapshot.data['componentName'];
          this.showIcon=event.snapshot.data['showIcon'];
          this.showSwitchModule=event.snapshot.data['showSwitchModule'];
          //this.currentModule = event.snapshot.data['currentModule'];
        }
      });
   }

  logout(){
    this.authService.logout();
  }

  onActivate() {
  }

  ngOnInit(): void {
    this.email = this.authService.getCurrentUser()?.email;
    this.name = this.authService.getCurrentUser()?.firstName;
    this.lastName = this.authService.getCurrentUser()?.lastName;

    let initialName = this.name == '' || this.name == undefined ? '' : this.name[0];
    let initialLastName = this.lastName == '' || this.name == undefined ? '' : this.lastName[0];
    this.initial = initialName + initialLastName;
    this.id = this.authService.getCurrentUser()?.id;

    let decodedToken = this.authService.decodeJWTToken();
    if(decodedToken?.logo){
      this.logo = decodedToken.logo;
    }

    this.selectUser(this.id);
  }

  selectUser(email: any) {
    this.userService.getByEmail(this.email).subscribe(response => {
      if (response.status === 200) {
        this.loggedInUser = response.data;
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });


  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  expand(){
    this.expandSidebar = !this.expandSidebar;
    this.expandSidebarChange.emit(this.expandSidebar);
  }

  alert(){
    this.toastr.warning("Not yet implemented");
  }

  showNotifications(){
    this.showNotification = !this.showNotification;
  }

  closeNotification() {
    if(this.showNotification){
      this.showNotification = false;
    }
  }

  switchModule(){
    this.popupComponentRef.changeState(1);
  }
}
