import { PaymentChannel } from 'src/app/models/paymentChannel.model';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PlannedOutageService } from 'src/app/services/planned-outage.service';
import { setThrowInvalidWriteToSignalError } from '@angular/core/primitives/signals';
import { DatetimePickerComponent } from 'src/app/components/common-new-design/multi-filter/datetime-picker/datetime-picker.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-planned-outage-update',
  templateUrl: './planned-outage-update.component.html',
  styleUrl: './planned-outage-update.component.scss'
})
export class PlannedOutageUpdateComponent{
  public selectedId : number = 0;
  public selectedOutage : any;
  constructor(private plannedOutageService : PlannedOutageService, private toastr : ToastrService){}
  getSelectedOutage(){
    this.plannedOutageService.getById(this.selectedId).subscribe({
      next: (response : any) => {
        if(response.status == 200){
          this.patchFormValues(response.data);
        }
        else{
          this.toastr.error(response.message)
        }
      },
      error: (err : any) => {
        this.toastr.error(err.message)
      }
    })
  }
  @ViewChild(DatetimePickerComponent) endTimePickerRef!: DatetimePickerComponent
  @Input() animationState = 'out';
  @Output() updated : EventEmitter<any> = new EventEmitter<any>();
  changeState(number: Number, selectedId? : number | null) {
    if (number === 1) {
      this.toggleShowDiv("open");
      this.selectedId = selectedId!;
      this.getSelectedOutage();
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      // if(this.f)
      //   this.f.submitted = false;
      this.showShadow = false;
    }
  }
  showShadow = false;
  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  public plannedOutageForm = new FormGroup({
    assetType: new FormControl({value: '', disabled: true}),
    assetName: new FormControl({value: '', disabled: true}),
    outageStartTime: new FormControl({value: '', disabled: true}),
    outageEndTime: new FormControl<Date | null>(null, [Validators.required,(control) => {
      const today: Date = new Date();
      return control.value < today ? { "LessThanToday": true } : null;
    }])
  })
  selectedEndDate : any = ''
  selectedEndTime : any = ''
  public selectedStartDateTime : Date | null = null;
  public selectedAssetType : string = "";
  patchFormValues(data : any){
    this.selectedAssetType = data.assetType;
    switch(data.assetType){
      case "f11":
        this.plannedOutageForm.controls.assetType.patchValue("Feeder 11");
        break;
      case "f33":
        this.plannedOutageForm.controls.assetType.patchValue("Feeder 33");
        break;
      case "dt":
        this.plannedOutageForm.controls.assetType.patchValue("Dt");
        break;
    }
    this.plannedOutageForm.controls.assetName.patchValue(data.assetName);
    this.plannedOutageForm.controls.outageStartTime.patchValue(formatDate(data.outageStart, "dd/MM/yyyy HH:mm", 'en'));
    this.plannedOutageForm.controls.outageEndTime.patchValue(data.outageEnd);
    this.selectedStartDateTime = data.outageStart;
    let dataOutageEndDate = new Date(data.outageEnd);
    this.selectedEndDate = {year: dataOutageEndDate.getFullYear(), month: dataOutageEndDate.getMonth()+1, day: dataOutageEndDate.getDate()};
    this.selectedEndTime = {hour: dataOutageEndDate.getHours(), minute: dataOutageEndDate.getMinutes()};
    this.plannedOutageForm.controls['outageEndTime'].patchValue(dataOutageEndDate);
    this.endTimePickerRef.setInitDateTime(this.selectedEndDate,this.selectedEndTime);
  }
  endDateTimeChange(endDateTime : any){
    this.selectedEndDate = endDateTime.date;
    this.selectedEndTime = endDateTime.time;
    this.plannedOutageForm.controls['outageEndTime'].patchValue(new Date(endDateTime.date.year, endDateTime.date.month-1, endDateTime.date.day, endDateTime.time.hour, endDateTime.time.minute));
  }
  public submittedForm = false;
  save(){
    this.submittedForm = true;
    if(this.plannedOutageForm.valid){
      let obj = {
        Id : this.selectedId,
        AssetName: this.plannedOutageForm.controls['assetName'].value,
        AssetType: this.selectedAssetType,
        OutageStart: this.selectedStartDateTime,
        OutageEnd: formatDate(this.plannedOutageForm.controls['outageEndTime'].value!, "MM/dd/yyyy HH:mm:ss", "en"),
      };
      this.plannedOutageService.updatePlannedOutage(obj).subscribe({
        next : (response) =>{
          if (response.status == 200) {
            this.toastr.success(response.message);
            this.handleSuccessfullUpdate();
          }
          else{
            this.toastr.error(response.message);
          }
        },
        error: (err) =>{
          this.toastr.error('An error occurred.');
        }
      });
    }
    else{
      this.toastr.info("Form is invalid");
    }
  }

  public resetStartDatePicker : boolean = false;

  handleSuccessfullUpdate(){
    this.updated.emit(true);
    this.plannedOutageForm.reset();
    this.changeState(2); //close popup\
    this.selectedEndDate = null;
    this.resetStartDatePicker = true;
  }
}
