import { Component, OnInit, ViewChild } from '@angular/core';
import { PaymentChannelService } from 'src/app/services/paymentChannel.service';
import { PaymentChannel } from 'src/app/models/paymentChannel.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Location } from '@angular/common';
import { PaymentChannelCreateComponent } from './payment-channel-create/payment-channel-create.component';
import { CrudDeleteModalComponent } from '../../crud-tabs/crud-delete-modal/crud-delete-modal.component';

@Component({
  selector: 'app-payment-channel',
  templateUrl: './payment-channel.component.html',
  styleUrls: ['./payment-channel.component.scss']
})
export class PaymentChannelComponent implements OnInit {
  @ViewChild(PaymentChannelCreateComponent) popupComponentRef!: PaymentChannelCreateComponent;
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  public PermissionsEnum = PermissionsEnum;

  public currentPage: number = 1;
  public pageSize: number = 20;
  public count: number = 0;
  public lastPageSize: number = 1;
  public selectedId: number = 0;

  public paymentChannels: PaymentChannel[] = [];

  public selectedChannel: PaymentChannel = new PaymentChannel();

  private searchText: string = "";

  constructor(private paymentChannelservice: PaymentChannelService,
    private toastr: ToastrService, public authService: AuthService, private location: Location) { }

  @ViewChild("f") f: any;

  ngOnInit(): void {
    this.reloadTable();
  }

  back() {
    this.location.back();
  }

  openTab(data?: any) {
    if (data) {
      this.popupComponentRef.changeState(1, data);
    }
    else {
      this.popupComponentRef.changeState(1);
    }
  }

  selectChannel(id: number) {
    this.paymentChannelservice.GetById(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedChannel = response.data;
        this.openTab(response.data);
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedChannel() {
    this.selectedChannel = new PaymentChannel();

    if(this.f)
      this.f.submitted = false;
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;
    this.resetSelectedChannel();
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };
    this.paymentChannelservice.getAllPayment(obj).subscribe(response => {
      this.paymentChannels = response.data.data;
      this.count = response.data.count;

      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  openTabDelete(id: number) { 
    console.log("ada",id)
    this.paymentChannelservice.GetById(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedChannel = response.data;
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.selectedId = id;
    this.crudDeleteRef.changeState();
  }
  onDeleteConfirmed() {
    if (!this.authService.isAuthorized(PermissionsEnum.revenueprotection_settings_delete)) {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.paymentChannelservice.delete(this.selectedId).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.reloadTable();
        this.resetSelectedChannel();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
