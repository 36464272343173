import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DateRangeSelection } from 'src/app/interfaces/dateRangeSelection';

@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss']
})
export class RangeDatePickerComponent implements OnInit {
  /**
   * Start date in the callendar
   */
  @Input() fromDate!: NgbDate | null;
  /**
   * End date in the callendar
   */
  @Input() toDate!: NgbDate | null;

    /**
   * select only 1st on datepicker
   */
  @Input() isMonthly: boolean = false;
  @Input() isOnlyOneMonth: boolean = false;
  @Input() gridEnergyProfileDatepicker: boolean = false;
      /**
   * disable future dates
   */
    @Input() maxData: boolean = false;

    /**
   * name for datepicker
   */
  @Input() name: string = "Period";

  /**
   * Used to show or hide custom calendar section on the left (Today, Yesterday, Last 7 days..)
   */
  isCalendarVisible: boolean = false;
  /**
   * Initial 'from' and 'to' dates that are used to reset date input fields in case calendar
   * is closed but "Apply" btn wasn't clicked
   */
  initialFromDate!: NgbDate | null;
  initialToDate!: NgbDate | null;

  /**
   * Values used to display readable date string in input field like: '29/10/2023 - 30/10/2023'
   */
  dateFromLabel: string = "";
  dateToLabel: string = "";
  /**
   * Initial 'from' and 'to' input values that are used to reset date input fields in case calendar
   * is closed but "Apply" btn wasn't clicked
   */
  initialDateFromLabel: string = "";
  initialDateToLabel: string = "";

  /**
   * Used to style hovered dates
   */
  hoveredDate: NgbDate | null = null;
  @Input() maxDate: Date = new Date();
  public maxDateNgb: NgbDate = new NgbDate(this.maxDate.getFullYear(), this.maxDate.getMonth() + 1, this.maxDate.getDate());

  /**
   * It will inform parent component when date range is selected and emit a DateRangeSelection object
   */
  @Output() dateRangeSelected = new EventEmitter<DateRangeSelection>();

  /**
   * Date picker reference used to manipulate with date picker
   */
  @ViewChild('datepicker') datePicker!: NgbInputDatepicker;
  /**
   * Reference to the current component container, used to detect outside clicks
   */
  @ViewChild('dateContainer') dateContainer!: ElementRef;

  /**
   * It should detect all clicks outside the component and close the calendar.
   * Needed to do this way because the current 'appClickOutside' directive will detect 
   * click on the custom calendar section (Last month) like an outside click
   */
  @HostListener('document:mousedown', ['$event'])
  onOutsideClick(event: any): void {
     if (!this.dateContainer?.nativeElement.contains(event.target)) {
      this.closeDatePicker();
      this.resetInputValues();
     }
  }

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {}

  ngOnInit(): void {
    this.setInputValues();
    // this.maxDate.setDate(this.maxDate.getDate() + 1);
    this.maxDateNgb = new NgbDate(this.maxDate.getFullYear(), this.maxDate.getMonth() + 1, this.maxDate.getDate());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.fromDate !== undefined || changes.toDate){
      this.setInputValues()
    }
  }

  setInputValues() {
    this.initialFromDate = this.fromDate;
    this.initialToDate = this.toDate;

    this.dateFromLabel = this.fromDate ? `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}` : '';
    this.dateToLabel = this.toDate ? `${this.toDate.day.toString().padStart(2, '0')}/${this.toDate.month.toString().padStart(2, '0')}/${(this.toDate.year % 100).toString().padStart(2, '0')}` : '';
    this.initialDateFromLabel = this.dateFromLabel;
    this.initialDateToLabel = this.dateToLabel;
  }

  isDisabled = (date:any) => {
    if (this.isMonthly) {
      return date.day !== 1;
    }
    if (this.maxData) {
      return date.after(this.calendar.getToday());
    }
    return false;
  };

  resetInputValues() {
    this.dateFromLabel = this.initialDateFromLabel;
    this.dateToLabel = this.initialDateToLabel;

    this.fromDate = this.initialFromDate;
    this.toDate = this.initialToDate;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
      this.dateToLabel = '';
      if(this.isOnlyOneMonth)
        this.maxDateNgb = this.fromDate.month != 12 ? new NgbDate(this.fromDate.year, this.fromDate.month + 1, 1) : new NgbDate(this.fromDate.year + 1, 1, 1);
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.dateToLabel = `${this.toDate.day.toString().padStart(2, '0')}/${this.toDate.month.toString().padStart(2, '0')}/${(this.toDate.year % 100).toString().padStart(2, '0')}`;
      this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.dateToLabel = '';
      this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
      if(this.isOnlyOneMonth)
        this.maxDateNgb = this.fromDate.month != 12 ? new NgbDate(this.fromDate.year, this.fromDate.month + 1, 1) : new NgbDate(this.fromDate.year + 1, 1, 1);
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  selectToday() {
    this.fromDate = this.calendar.getToday();
    this.toDate = null;
    this.dateToLabel = '';
    this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
    this.datePicker.navigateTo({ year: this.calendar.getToday().year, month: this.calendar.getToday().month});
  }

  selectYesterday() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 1);
    this.toDate = this.calendar.getToday();
    this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
    this.dateToLabel = `${this.toDate.day.toString().padStart(2, '0')}/${this.toDate.month.toString().padStart(2, '0')}/${(this.toDate.year % 100).toString().padStart(2, '0')}`;
    if(this.calendar.getToday().day == 1)
    {
      const currentMonth = this.calendar.getToday().month;
      const currentYear = this.calendar.getToday().year;

      const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
      
      this.datePicker.navigateTo({ year: previousYear, month: previousMonth});
    }
    else
      this.datePicker.navigateTo({ year: this.calendar.getToday().year, month: this.calendar.getToday().month});
  }

  selectLast7Days() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 7);
    this.toDate = this.calendar.getToday();
    this.dateToLabel = `${this.toDate.day.toString().padStart(2, '0')}/${this.toDate.month.toString().padStart(2, '0')}/${(this.toDate.year % 100).toString().padStart(2, '0')}`;
    this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
    if(this.calendar.getToday().day < 7)
    {
      const currentMonth = this.calendar.getToday().month;
      const currentYear = this.calendar.getToday().year;

      const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
      
      this.datePicker.navigateTo({ year: previousYear, month: previousMonth});
    }
    else
      this.datePicker.navigateTo({ year: this.calendar.getToday().year, month: this.calendar.getToday().month});
  }

  selectLast30Days() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 29);
    this.toDate = this.calendar.getToday();
    this.dateToLabel = `${this.toDate.day.toString().padStart(2, '0')}/${this.toDate.month.toString().padStart(2, '0')}/${(this.toDate.year % 100).toString().padStart(2, '0')}`;
    this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
    if(this.calendar.getToday().day < 30)
    {
      const currentMonth = this.calendar.getToday().month;
      const currentYear = this.calendar.getToday().year;

      const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;

      this.datePicker.navigateTo({ year: previousYear, month: previousMonth});
    }
    else
      this.datePicker.navigateTo({ year: this.calendar.getToday().year, month: this.calendar.getToday().month});
  }

  selectThisMonth() {
    const currentMonth = this.calendar.getToday().month;
    const currentYear = this.calendar.getToday().year;

    const nextMonth = (currentMonth % 12) + 1;
    const nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;
    
    this.fromDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month, 1);
    this.toDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month, this.calendar.getToday().day);

    // this.toDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month, getDaysInMonth(new Date().getMonth()));
    this.dateToLabel = `${this.toDate.day.toString().padStart(2, '0')}/${this.toDate.month.toString().padStart(2, '0')}/${(this.toDate.year % 100).toString().padStart(2, '0')}`;
    this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
    this.datePicker.navigateTo({ year: this.calendar.getToday().year, month: this.calendar.getToday().month});
  }

  selectLastMonth() {
    const currentMonth = this.calendar.getToday().month;
    const currentYear = this.calendar.getToday().year;

    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
    
    this.fromDate = new NgbDate(previousYear, previousMonth, 1);
    this.toDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month, 1);
    // this.toDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month - 1, getDaysInMonth(new Date().getMonth() - 1));
    this.dateToLabel = `${this.toDate.day.toString().padStart(2, '0')}/${this.toDate.month.toString().padStart(2, '0')}/${(this.toDate.year % 100).toString().padStart(2, '0')}`;
    this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
    this.datePicker.navigateTo({ year: this.calendar.getToday().year, month: this.calendar.getToday().month - 1});
  }

  selectLastTwoMonths() {
    const currentMonth = this.calendar.getToday().month;
    const currentYear = this.calendar.getToday().year;

    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 2;
    const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
    
    this.fromDate = new NgbDate(previousYear, previousMonth, 1);
    this.toDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month, 1);
    // this.toDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month - 1, getDaysInMonth(new Date().getMonth() - 1));
    this.dateToLabel = `${this.toDate.day.toString().padStart(2, '0')}/${this.toDate.month.toString().padStart(2, '0')}/${(this.toDate.year % 100).toString().padStart(2, '0')}`;
    this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
    this.datePicker.navigateTo({ year: this.calendar.getToday().year, month: this.calendar.getToday().month - 1});
  }

  selectLastThreeMonths() {
    const currentMonth = this.calendar.getToday().month;
    const currentYear = this.calendar.getToday().year;

    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 3;
    const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
    
    this.fromDate = new NgbDate(previousYear, previousMonth, 1);
    this.toDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month, 1);
    // this.toDate = new NgbDate(this.calendar.getToday().year, this.calendar.getToday().month - 1, getDaysInMonth(new Date().getMonth() - 1));
    this.dateToLabel = `${this.toDate.day.toString().padStart(2, '0')}/${this.toDate.month.toString().padStart(2, '0')}/${(this.toDate.year % 100).toString().padStart(2, '0')}`;
    this.dateFromLabel = `${this.fromDate.day.toString().padStart(2, '0')}/${this.fromDate.month.toString().padStart(2, '0')}/${(this.fromDate.year % 100).toString().padStart(2, '0')}`;
    this.datePicker.navigateTo({ year: this.calendar.getToday().year, month: this.calendar.getToday().month - 1});
  }

  closeDatePicker() {
    this.datePicker.close();
    this.isCalendarVisible = false;
  }

  toggle() {
    this.datePicker.toggle();
    this.isCalendarVisible = !this.isCalendarVisible
  }

  applySelection() {
    if (this.fromDate && this.toDate) {
      this.setInputValues();
      this.dateRangeSelected.emit({
        dateFrom: this.fromDate,
        dateTo: this.toDate
      });
      this.closeDatePicker();
    }
  }
}
