import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FOFlaggingValues } from 'src/app/models/fo-flagging-values.model';
import { PopupEnum } from 'src/app/models/popupEnum';
import { PredictiveDtAssetAnalytics } from 'src/app/models/predictive-dt-analytics.model';
import { TranslationService } from 'src/app/services/translation.service';
import { PaDtSliderNewComponent } from './pa-dt-slider-new/pa-dt-slider-new.component';

@Component({
  selector: 'app-pa-dt-new',
  templateUrl: './pa-dt-new.component.html',
  styleUrls: ['./pa-dt-new.component.scss']
})
export class PaDtNewComponent {

  @Input() data: PredictiveDtAssetAnalytics[] = []
  @Input() historyData: PredictiveDtAssetAnalytics[] = []

  @Input() menuTab: any

  @Input() flags: FOFlaggingValues = new FOFlaggingValues()

  @Output() popUpOpen: EventEmitter<any> = new EventEmitter()
  @Output() popUpClose: EventEmitter<any> = new EventEmitter()

  buName = this.translationService.getByKeyFromCache('BU')

  @ViewChild(PaDtSliderNewComponent) popupComponentRef!: PaDtSliderNewComponent

  constructor(
    public translationService: TranslationService
  ) { }


  openPopUp(item: any) {
    this.popupComponentRef.changeState(PopupEnum.Open, item, this.flags)
  }

}
