import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AccountStatusService } from 'src/app/services/accountStatus.service';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-meter-triggers-grid',
  templateUrl: './meter-triggers-grid.component.html',
  styleUrls: ['./meter-triggers-grid.component.scss', '../../common/grids/grid.component.scss']
})
export class MeterTriggersGrid implements OnInit, OnChanges {

  @Input() meterTriggerType: string = '';
  @Input() meterIds: number[] = []
  @Input() buIds: any = [];
  @Input() utIds: any = [];
  @Input() dtIds: any = [];
  @Input() amiIds: any = [];
  @Input() contractStatusValue: any = [];
  @Input() customerTypeValue: any = [];
  @Input() voltageValue: any = [];
  @Input() dateFrom: any = '';
  @Input() dateTo: any = '';
  @Input() search: SearchAutocomplete = new SearchAutocomplete()
  @Input() alarmCondition: number = 0
  @Input() commStatus: number = 0
  @Input() accStatus: number | null = null;
  @Input() bypassStatus: number | null = null;
  @Output() alarmConditionChange = new EventEmitter();
  @Output() commStatusChange = new EventEmitter();
  @Output() accStatusChange = new EventEmitter();
  @Output() bypassStatusChange = new EventEmitter();

  changeAlarmCond (newValue : any){
    this.alarmCondition = newValue;
    this.alarmConditionChange.emit(this.alarmCondition);
  }
  changeCommStatus (newValue : any){
    this.commStatus = newValue;
    this.commStatusChange.emit(this.commStatus);
  }
  changeAccStatus (newValue : any){
    this.accStatus = newValue;
    this.accStatusChange.emit(this.accStatus);
  }
  changeBypassStatus (newValue : any){
    this.bypassStatus = newValue;
    this.bypassStatusChange.emit(this.bypassStatus);
  }


  @Output() countChange = new EventEmitter<number>();

  public masterSelected: boolean = false;
  public metersId: any = [];
  public checkedList: any = [];
  public data: any[] = [];
  public meterTriggersData: any[] = [];

  public count: number = 0
  public pageSize: number = 10
  public currentPage: number = 1
  public currentMeterId: number = 1
  public lastPageSize: number = 1;

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');

  public statusPopup: number = -1

  public commStatusOpened: boolean = false
  public accountStatuses : any = [];

  constructor(
    private translationService: TranslationService,
    private meterService: MeterService,
    private toastr: ToastrService,
    private accStatusService : AccountStatusService,
    ){}

  ngOnInit(): void {
    this.accStatusService.getAllForSelect().subscribe(response => {
      if(response.status == 200 && response.data){
        this.accountStatuses = response.data;
      }
      else{
      }
    }, _=>{
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false
    if (changes.meterIds != null) {
      reloadTable = true
    }
    if (changes.buIds != null) {
      reloadTable = true
    }
    if (changes.dtIds != null) {
      reloadTable = true
    }
    if (changes.utIds != null) {
      reloadTable = true
    }
    if (changes.amiIds != null) {
      reloadTable = true
    }
    if (changes.customerTypeValue != null) {
      reloadTable = true
    }
    if (changes.contractStatusValue != null) {
      reloadTable = true
    }
    if (changes.voltageValue != null) {
      reloadTable = true
    }
    if(changes.alarmCondition != null){
      reloadTable = true
    }
    if(changes.commStatus != null){
      reloadTable = true
    }
    if(changes.accStatus != null){
      reloadTable = true
    }
    if(changes.bypassStatus != null){
      reloadTable = true
    }
    if (this.search?.name == '') {
      this.search = changes.search?.previousValue
    }
    if (changes.search != null) {
      reloadTable = true
    }
    if (changes.meterTriggerType != null) {
      this.search= new SearchAutocomplete()
      reloadTable = true
    }
    if(changes.dateFrom != null || changes.dateTo != null){
      reloadTable = true
    }
    if (reloadTable) {
      this.pageChange(1)
    }
  }

  GetData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        Search: this.search ? this.search.name : this.search,
        UtIds: this.utIds,
        BuIds: this.buIds,
        DtIds: this.dtIds,
        AmiIds: this.amiIds,
        ContractStatusValue: this.contractStatusValue,
        CustomerTypeValue: this.customerTypeValue,
        VoltageValue: this.voltageValue,
        MeterTriggerType: this.meterTriggerType,
        CommStatus: this.commStatus,
        AlarmCondition: this.alarmCondition,
        BypassStatus: this.bypassStatus,
        AccountStatus : this.accStatus,
      }
    };
    console.log(obj)
    this.meterService.getAllTriggers(obj).subscribe(response => {
      if (response.status === 200) {
        this.meterTriggersData = response.data.data;
        console.log(this.meterTriggersData)
        if (obj.pageInfo.page == 1) {
          this.count = response.data.count;
          this.countChange.emit(this.count);
          this.lastPageSize = Math.ceil(this.count / this.pageSize);
        }
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  checkUncheckAll() {
    for (let i = 0; i < this.meterTriggersData.length; i++) {
      this.meterTriggersData[i].checked = this.masterSelected;
      this.checkForDownload(this.meterTriggersData[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.meterTriggersData.forEach((x: { id: any; checked: boolean; }) => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.meterTriggersData.every(function(item:any) {
      return item.checked == true;
    })
  }

  pageChange(value: number) {
    this.currentPage = value
    this.GetData()
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }

  closeStatusPopup(){
    this.statusPopup = -1;
  }

  updateStatus(item: any, value: number){
    let oldValue = value;
    item.status = value
    console.log(item)
    console.log(oldValue)
    this.meterService.updateTriggerStatus(item).subscribe(response => {
      if(response.status == 200){
        this.toastr.success("Success")
        this.GetData()
      }
      else{
        this.toastr.error(response.message)
        item.status = oldValue
      }
    }, _=>{
      this.toastr.error('An Error Occurred')
      item.status = oldValue
    })
  }

  public download() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      Search: this.search ? this.search.name : this.search,
      CheckedList: this.checkedList,
      UtIds: this.utIds,
      BuIds: this.buIds,
      DtIds: this.dtIds,
      AmiIds: this.amiIds,
      ContractStatusValue: this.contractStatusValue,
      CustomerTypeValue: this.customerTypeValue,
      VoltageValue: this.voltageValue,
      MeterTriggerType: this.meterTriggerType,
      CommStatus: this.commStatus,
      AlarmCondition: this.alarmCondition,
      BypassStatus: this.bypassStatus,
      AccountStatus: this.accStatus,
    };
    console.log(obj)
    this.meterService.downloadMeterTriggers(obj).subscribe({
      next : (response) => window.location.href = response.data,
      error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
    })
    this.checkedList = [];
  }

  public commStatusClicked(open: number) {
    if (open == 0)
      this.commStatusOpened = false
    else {
      if (this.commStatusOpened) {
        this.commStatusOpened = false
      }
      else {
        this.commStatusOpened = true
      }

    }
  }

  public commStatusSelected(value: number) {
    this.currentPage = 1;
    this.changeCommStatus(value);
    this.GetData()
  }

  public alarmConditionOpened: boolean = false

  public alarmConditionClicked(open: number) {
    if (open == 0) {
      this.alarmConditionOpened = false
    }
    else {
      if (this.alarmConditionOpened) {
        this.alarmConditionOpened = false
      }
      else {
        this.alarmConditionOpened = true
      }
    }

  }
  public alarmConditionSelected(value: number) {
    this.changeAlarmCond(value);
    this.GetData()
  }

  public bypassStatusOpened: boolean = false;

  public bypassStatusClicked(open: number) {
    if (open == 0) {
      this.bypassStatusOpened = false
    }
    else {
      if (this.bypassStatusOpened) {
        this.bypassStatusOpened = false
      }
      else {
        this.bypassStatusOpened = true
      }
    }

  }
  public bypassStatusSelected(value: number | null) {
    this.changeBypassStatus(value);
    this.GetData()
  }

  public accStatusOpened: boolean = false;

  public accStatusClicked(open: number) {
    if (open == 0) {
      this.accStatusOpened = false
    }
    else {
      if (this.accStatusOpened) {
        this.accStatusOpened = false
      }
      else {
        this.accStatusOpened = true
      }
    }

  }
  public accStatusSelected(value: number | null) {
    this.changeAccStatus(value);
    this.GetData()
  }
}



