import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { F11ViewMore } from 'src/app/models/f11ViewMore.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TimePeriod } from '../../consumption/model/model';
import { F11PopupComponent } from './f11-popup/f11-popup.component';

@Component({
  selector: 'app-gep-feeder11-new',
  templateUrl: './gep-feeder11-new.component.html',
  styleUrls: ['./gep-feeder11-new.component.scss', '../grid-energy-profile-new.component.scss']
})
export class GepFeeder11NewComponent implements OnChanges {

  public count: number = 0
  public pageSize: number = 8
  public currentPage: number = 1
  public currentPageDts: number = 1;
  public dtsCount: number = 0;
  public dtsPageSize: number = 4;
  public lastPageSize: number = 1;

  public currentPageAccounts: number = 1;
  public accountsCount: number = 0;
  public accountsPageSize: number = 8;

  public commStatus: number = 0
  public commStatusOpened: boolean = false
  public readType: number = 0
  public readTypeOpened: boolean = false
  public dataFeeder: F11ViewMore[] = [];

  @Input() ssIds: number[] = []
  @Input() f11Ids: number[] = []
  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''
  @Input() search: SearchAutocomplete = new SearchAutocomplete()

  public searchViewMore: any = null;
  public currentFeeder : any = null;
  public currentFeederId : number = 0;

  public masterSelected: boolean = false;
  @ViewChild(F11PopupComponent) popupRef!: F11PopupComponent;

  @Input() timePeriod: TimePeriod = TimePeriod.Monthly;
  public checkedList: any = [];
  public data: F11ViewMore[] = [];
  public selectedF11: any = [];
  public isViewMore: boolean = false;
  public dtName :string = this.translationService.getByKeyFromCache('DT');

  public bandNumber: number = 1;

  allFeeders: number = 0;
  bandACount: number = 0;
  bandBCount: number = 0;
  bandCCount: number = 0;
  bandDCount: number = 0;
  bandECount: number = 0;

  constructor(
    private _feederService: Feeder11Service,
    private toastr: ToastrService,
    private translationService:TranslationService
  ) { }

  close() {
    this.isViewMore = false;
  }
  pageChangeDts(value: any) {
    this.currentPageDts = value;
    this.selectF11();
  }
  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false
    if(changes.ssIds != null){
      reloadTable = true
    }
    if(changes.f11Ids != null){
      reloadTable = true
    }
    if(changes.dateFrom != null || changes.dateTo != null){
      reloadTable = true
    }
    if(changes.search != null){
      reloadTable = true
    }

    if(reloadTable){
      if(this.dateFrom == '' || this.dateTo == '')
        this.setMainDate()

      this.checkedList = [];
      this.resetPagination()
      this.GetData()
    }
  }

  private resetPagination(){
    this.currentPage = 1
  }

  private setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()

    dteFrom.setHours(2,0,0);
    dte.setHours(2,0,0);
    dteFrom.setMonth(dteFrom.getMonth() - 1)

    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: 1};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
  }

  pageChange(value: number){
    this.currentPage = value
    this.GetData()
  }

  public GetData(){
    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        SsIds: this.ssIds,
        FeederIds: this.f11Ids,
        Search: this.search ? this.search.name : this.search,
        CommStatus: this.commStatus,
        ReadType: this.readType,
        Band: this.bandNumber
      }
    };

    // this.data = [];
    if(this.dateFrom.day == 1 && this.dateTo.day == 1){
      this.getMonthlyBandsCount();
      this.fetchMonthly11KvFeeders(obj)
    }
    else{
      this.getDailyBandsCount();
      this.fetchDaily11KvFeeders(obj);
    }
    this.getCommunicatingAssetsCounts();
  }

  getDailyBandsCount() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      SsIds: this.ssIds,
      FeederIds: this.f11Ids,
      Search: this.search ? this.search.name : this.search,
      CommStatus: this.commStatus,
      ReadType: this.readType,
    };
    this._feederService.getDailyBandsCount(obj).subscribe(response => {
      if(response.status === 200) {
        this.bandACount = response.data.bandACount;
        this.bandBCount = response.data.bandBCount;
        this.bandCCount = response.data.bandCCount;
        this.bandDCount = response.data.bandDCount;
        this.bandECount = response.data.bandECount;
        this.allFeeders = this.bandACount + this.bandBCount + this.bandCCount + this.bandDCount + this.bandECount
      } else {
        this.toastr.error(response.message);
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

  getMonthlyBandsCount() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      SsIds: this.ssIds,
      FeederIds: this.f11Ids,
      Search: this.search ? this.search.name : this.search,
      CommStatus: this.commStatus,
      ReadType: this.readType,
    };
    this._feederService.getMonthlyBandsCount(obj).subscribe(response => {
      if(response.status === 200) {
        this.bandACount = response.data.bandACount;
        this.bandBCount = response.data.bandBCount;
        this.bandCCount = response.data.bandCCount;
        this.bandDCount = response.data.bandDCount;
        this.bandECount = response.data.bandECount;
        this.allFeeders = this.bandACount + this.bandBCount + this.bandCCount + this.bandDCount + this.bandECount
      } else {
        this.toastr.error(response.message);
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

  fetchDaily11KvFeeders(obj:any){
    this._feederService.getAllDaily11KvFeeders(obj).subscribe(response => {
      if(response.status === 200) {
        this.data = response.data.data;
        this.isAlreadyChecked();
        if(obj.pageInfo.page == 1) {
          this.count = response.data.count;
          this.lastPageSize = Math.ceil(this.count / this.pageSize)
        }
      } else {
        this.toastr.error(response.message);
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

  fetchMonthly11KvFeeders(obj:any){
    this._feederService.getAllMonthly11KvFeeders(obj).subscribe(response => {
      if(response.status === 200) {
        this.data = response.data.data;
        this.isAlreadyChecked();
        if(obj.pageInfo.page == 1) {
          this.count = response.data.count;
          this.lastPageSize = Math.ceil(this.count / this.pageSize)
        }
      } else {
        this.toastr.error(response.message);
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

  public commStatusClicked(open: number){
    if(open == 0)
      this.commStatusOpened = false
    else{
      if(this.commStatusOpened){
        this.commStatusOpened = false
      }
      else{
        this.commStatusOpened = true
      }

    }
  }

  public readTypeClicked(open: number){
    if(open == 0)
      this.readTypeOpened = false
    else{
      if(this.readTypeOpened){
        this.readTypeOpened = false
      }
      else{
        this.readTypeOpened = true
      }

    }
  }

  public commStatusSelected(value: number){
    this.currentPage = 1
    this.commStatus = value
    this.GetData()
  }

  public readTypeSelected(value: number){
    this.currentPage = 1
    this.readType = value
    this.GetData()
  }
selectF11(feeder?:any,page: any = null){
  this.isViewMore = true
  if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
    this.selectF11Monthly(feeder)
  }
  else {
    this.selectF11Daily(feeder)
  }
 }
selectF11Monthly(feeder?:any,page: any = null){
  if(page)
    this.currentPageDts = page;

    let obj = {
      pageInfo:{
        page: this.currentPageDts,
        pageSize: this.dtsPageSize,
        isMonthlySelected: this.timePeriod == TimePeriod.Monthly
      },
      filterParams: {
        Id: this.selectedF11.id,
        From: this.dateFrom != '' ? transformToString(this.dateFrom) : null,
        To: this.dateTo != '' ? transformToString(this.dateTo) : null,
        Search:this.searchViewMore
      }
    };  this._feederService.getFeederViewMore(obj).subscribe(response => {
      if(response.status === 200) {
        let data = response.data.data;
        this.dtsCount = response.data.count;
        this.lastPageSize = Math.ceil(this.dtsCount / this.dtsPageSize)
       } else {
        this.toastr.error(response.message);
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  };
  selectF11Daily(feeder?:any,page: any = null){
    if(page)
      this.currentPageDts = page;

    let obj = {
      pageInfo:{
        page: this.currentPageDts,
        pageSize: this.dtsPageSize,
        isMonthlySelected: this.timePeriod == TimePeriod.Daily
      },
      filterParams: {
        Id: this.selectedF11.id,
        From: this.dateFrom != '' ? transformToString(this.dateFrom) : null,
        To: this.dateTo != '' ? transformToString(this.dateTo) : null,
        Search:this.searchViewMore
      }
    };  this._feederService.getFeederViewMore(obj).subscribe(response => {
      if(response.status === 200) {
        let data = response.data.data;
        this.lastPageSize = Math.ceil(this.dtsCount / this.dtsPageSize)
       } else {
        this.toastr.error(response.message);
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  };
  viewMore(feeder: any){
    this.currentFeeder = feeder;
    this.currentFeederId = feeder.id;
    this.searchViewMore = '';
    this.isViewMore = true;

    this.selectF11(this.currentFeeder);
    this.selectedF11 = this.data.find(x => x.feederNumber == feeder.feederNumber)

    this.openTab(this.selectedF11);

  }
  openTab(selectedF11:any){
    this.selectedF11 =selectedF11;
    this.popupRef.changeState(1, selectedF11);
  }
  closeTab(){
    this.popupRef.changeState(1, this.selectedF11);
  }
  public download(){
    let obj = {
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        SsIds: this.ssIds,
        FeederIds: this.f11Ids,
        Search: this.search ? this.search.name : this.search,
        CommStatus: this.commStatus,
        ReadType: this.readType,
        checkedList: this.checkedList,
        Band: this.bandNumber
    };

    if(this.dateFrom.day == 1 && this.dateTo.day == 1){
      this.downloadMonthlyReport(obj)
    }
    else{
      this.downloadDailyReport(obj);
    }
  }
  private downloadMonthlyReport(obj: any) {
    this._feederService.downloadMonthly11KvFeedersReport(obj).subscribe(response => {
      if(response != null)
         window.location.href = response.data;
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }
  private downloadDailyReport(obj: any) {
    this._feederService.downloadDaily11KvFeedersReport(obj).subscribe(response => {
      if(response != null)
      window.location.href = response.data;
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
      this.checkForDownload(this.data[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.data.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item:any) {
      return item.checked == true;
    })
  }

  bandChanged(value: number) {
    this.bandNumber = value;
    this.currentPage = 1
    this.GetData();
  }

  public communicatingAssetsCounter : number = 0;
  public nonCommunicatingAssetsCounter : number = 0;

  getCommunicatingAssetsCounts(){
    let obj = {
      SsIds: this.ssIds,
      FeederIds : this.f11Ids,
      Search: this.search ? this.search.name : this.search,
      CommStatus: this.commStatus,
      ReadType: this.readType,
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
    };
    this._feederService.getCommunicatingAssetsCounts(obj).subscribe({
      next: (response) =>{
        if(response && response.status == 200){
          this.communicatingAssetsCounter = response.data?.comunicatingAssetsCount ?? 0;
          this.nonCommunicatingAssetsCounter = response.data?.nonComunicatingAssetsCount ?? 0;
        }
      },
      error: (err) =>{
        this.toastr.error('An error occurred.');
      },
    });
  }
}
