import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config'; 
import { AccountStatus } from '../models/accountStatus.model';

@Injectable({
  providedIn: 'root'
})
export class AccountStatusService {

  private pathApi = this.config.setting['pathApi'] + "accountStatus/";

  constructor(private http: HttpClient,
    private config: AppConfig) { } 
  getAccountStatus(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}getAccountStatus/${id}`);
  }  
  deleteAccStatus(id: number): Observable<any> {
    return this.http.delete(`${this.pathApi}deleteAccStatus/${id}`);
  }
  saveAccountStatus(obj: AccountStatus): Observable<any> {
    return this.http.post(`${this.pathApi}saveAccStatus`, obj);
  } 
  getAllAccountStatus(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllAccountStatus`, obj);
  } 

  getAllForSelect(): Observable<any> {
    return this.http.get(`${this.pathApi}getAllForSelect`);
  } 
}
