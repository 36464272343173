<div class="slider-back" [hidden]="!showShadow">

</div>

<div [hidden]="!showShadow">
    <div class="slider" [ngClass]="{'shadow' : showShadow === true}" [@slideInOut]="animationState">
        <div class="tab-header">Create/Edit Payment Channel
            <div class="btn-close-position" (click)="changeState(2)">
                <div class="btn-close">
                    <img alt="Close" src="assets/images-new-design/Close.png">
                </div>
            </div>

        </div>
        <form (submit)="f.valid" class="form col-sm-12" #f="ngForm">
            <label class="labelPopup">Name</label>
            <input class="form-control" name="name" [(ngModel)]="selectedChannel.name"
                #name="ngModel" pattern="^[a-zA-Z0-9 ]+$" required autocomplete="off" placeholder="Name"/>
            <div *ngIf="f.submitted && name.invalid" class="form-control-error">Name is required</div>
        </form>
        <div class="user-btn">
            <button class="submit col-xs-6" [disabled]="!f.valid" (click)="save()">Save</button>
            <button class="cancel-btn col-xs-6" (click)="changeState(2)">Cancel</button>
        </div>
    </div>
</div>
