import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap/tooltip/tooltip.module';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DatetimePickerComponent } from 'src/app/components/common-new-design/multi-filter/datetime-picker/datetime-picker.component';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { PlannedOutageService } from 'src/app/services/planned-outage.service';
import { OutageStartEndTimeValidator } from '../start-end-time-validator/outage-start-end-time-validator';

@Component({
  selector: 'app-planned-outage-create-new',
  templateUrl: './planned-outage-create-new.component.html',
  styleUrl: './planned-outage-create-new.component.scss',
})
export class PlannedOutageCreateNewComponent implements OnInit {
  constructor(private ref: ElementRef,
     public dtService: DtService,
    public f11Service: Feeder11Service,
    public f33Service: Feeder33Service,
    private toastr: ToastrService,
    private plannedOutageService : PlannedOutageService){}
  ngOnInit(): void {
    let currentDate = new Date();
    this.startMinDate = {year: currentDate.getFullYear(), month: currentDate.getMonth(), day: currentDate.getDate()}
    this.endMinDate = {year: currentDate.getFullYear(), month: currentDate.getMonth(), day: currentDate.getDate()}
  }

  submittedForm = false;
  showShadow = false;
  selectedStartDate : any = ''
  selectedStartTime : any = ''
  selectedEndDate : any = ''
  selectedEndTime : any = ''
  startMinDate : any = '';
  endMinDate : any = '';

  @Input() animationState = 'out';
  @Output() addedNew : EventEmitter<any> = new EventEmitter<any>();
  changeState(number: Number) {
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      // if(this.f)
      //   this.f.submitted = false;
      this.clearForm();
      this.showShadow = false;
    }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }
  // handleClick(event: any) {
  //   const isPopupClicked = event.target.closest('.account-popup');
  //   if (this.animationState === 'in')
  //     if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
  //       if (!isPopupClicked) {
  //         this.changeState(2);
  //       }
  //     }
  // }
  setMainDate(){
    let date = new Date()
    this.selectedStartDate = {year: date.getFullYear(), month: date.getMonth(), day: date.getDate()};
    this.selectedStartTime = {hour: date.getHours(), minute: date.getMinutes()};

  }

  startDateTimeChange(startDateTime : any){
    this.selectedStartDate = startDateTime.date;
    this.selectedStartTime = startDateTime.time;
    this.plannedOutageForm.controls['outageStartTime'].patchValue(new Date(startDateTime.date.year, startDateTime.date.month-1, startDateTime.date.day, startDateTime.time.hour, startDateTime.time.minute));
  }
  endDateTimeChange(endDateTime : any){
    this.selectedEndDate = endDateTime.date;
    this.selectedEndTime = endDateTime.time;
    this.plannedOutageForm.controls['outageEndTime'].patchValue(new Date(endDateTime.date.year, endDateTime.date.month-1, endDateTime.date.day, endDateTime.time.hour, endDateTime.time.minute));
  }

  save(){
    this.submittedForm = true;
    if(this.plannedOutageForm.valid){
      let obj = {
        AssetName: this.plannedOutageForm.controls['assetName'].value,
        AssetType: this.plannedOutageForm.controls['assetType'].value,
        OutageStart: formatDate(this.plannedOutageForm.controls['outageStartTime'].value!, "MM/dd/yyyy HH:mm:ss", "en"),
        OutageEnd: formatDate(this.plannedOutageForm.controls['outageEndTime'].value!, "MM/dd/yyyy HH:mm:ss", "en"),
      };
      
      this.plannedOutageService.createPlannedOutage(obj).subscribe({
        next : (response) =>{
          if (response.status == 200) {
            this.toastr.success(response.message);
            this.handleSuccessfullAdding();
          }
          else{
            this.toastr.error(response.message);
          }
        },
        error: (err) =>{
          this.toastr.error('An error occurred.');
        }
      });
    }
    else{
      this.toastr.info("Form is invalid");
    }
  }

  public resetStartDatePicker : boolean = false;
  public resetEndDatePicker : boolean = false;

  handleSuccessfullAdding(){
    this.addedNew.emit(true);
    this.changeState(2); //close popup\
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.resetStartDatePicker = true;
    this.resetEndDatePicker = true;
  }

  public plannedOutageForm = new FormGroup({
    assetType: new FormControl('', Validators.required),
    assetName: new FormControl('', Validators.required),
    outageStartTime: new FormControl<Date | null>(null,  [Validators.required, (control) => {
      const today: Date = new Date();
      return control.value < today ? { "LessThanToday": true } : null;
    }]),
    outageEndTime: new FormControl<Date | null>(null,  [Validators.required,(control) => {
      const today: Date = new Date();
      return control.value < today ? { "LessThanToday": true } : null;
    }])
  },{
    validators : OutageStartEndTimeValidator('outageStartTime', 'outageEndTime')
  })

  public searchPlaceholder: string = "Search"
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent
  @ViewChild("startDatePicker") startDatePickerRef!: DatetimePickerComponent
  @ViewChild("endDatePicker") endDatePickerRef!: DatetimePickerComponent


  searchChange(text: SearchAutocomplete) {
    this.searchText = text
    this.searchText = cloneDeep(this.searchText)
    this.plannedOutageForm.controls['assetName'].patchValue(text.name);
  }

  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    if (searchText.name.length > 2) {
      let assetType = this.plannedOutageForm.get("assetType")?.value;
      if ( assetType && assetType == "dt") {
        this.dtService.getDataForAutocompleteNameExclusive(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      } else if (assetType && assetType == "f11") {
        this.f11Service.getDataForAutocompleteNameExclusive(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
      else if (assetType && assetType == "f33") {
        this.f33Service.getDataForAutocompleteNameExclusive(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
    }
    else
      this.searchComponentRef.resetOptions()
  }
  clearForm(){
    this.searchComponentRef.resetOptions()
    this.searchText = new SearchAutocomplete()
    if (this.searchComponentRef)
      this.searchComponentRef.searchText = new SearchAutocomplete()
    this.plannedOutageForm.reset();
    this.startDatePickerRef.resetPicker();
    this.endDatePickerRef.resetPicker();
    this.submittedForm = false;
  }


}

