import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';

@Component({
  selector: 'app-issued-item-details',
  templateUrl: './issued-item-details.component.html',
  styleUrls: ['./issued-item-details.component.scss']
})
export class IssuedItemDetailsComponent implements OnChanges {

  @Output() changeView: EventEmitter<any> = new EventEmitter()

  @Input() inventory: any[] = []
  public showInventory: any[] = []

  public defaultImage: string = 'assets/images-new-design/defaultImg.png'

  constructor(private toastr: ToastrService) { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.inventory != null && changes.inventory.currentValue != undefined){
      this.showInventory = changes.inventory.currentValue
      // this.setStartValues()
    }
  }

  back(){
    this.changeView.emit(ViewsEnum.View)
  }

  onStatusChange(value: number) {
  }

  alert(){
    this.toastr.warning("Not yet implemented.");
  }
}
