<google-map class="map-anti-zoom" #map id="map"
            width="100%"
            height="100%"
            [zoom]="zoom"
            (boundsChanged)="firstBoundChange()"
            (mapDragend)="onMapBoundsChange()"
            (zoomChanged)="onMapZoomChange()"
            (mapInitialized)="onMapInitialized()"
            (mapClick)="markerHoverFinish()"
            [center]="center"
            [options]="mapProperties">
  <map-info-window></map-info-window>

  <div #markerModal class="marker-modal">
    <div class="modal-type">{{modalType}}</div>
    <div class="modal-inner">
      <div *ngFor="let data of modalData">
        <div class="modal-title">{{data.name}}</div>
        <div *ngIf="modalType !== 'ACC' " class="modal-content">
          <span class="modal-label">Energy received</span>
          <span *ngIf="modalType === 'BU'" class="modal-value">/</span>
          <span *ngIf="modalType !== 'BU'" class="modal-value">{{data.consumptionLastMonth | hasValue}}</span>
        </div>
        <div *ngIf="modalType === 'DT'" class="modal-content">
          <span class="modal-label">DT to customer losses:</span>
          <span *ngIf="losses == null" class="modal-value">/</span>
          <span *ngIf="losses != null" class="modal-value">{{data.lossesMVH | hasValue }} ({{data.losses | hasValue | percentage}})</span>
        </div>
        <div *ngIf="modalType === 'UT' || modalType === 'BU'" class="modal-content">
          <span class="modal-label">Energy losses:</span>
          <span *ngIf="modalType === 'BU'" class="modal-value">/</span>
          <span *ngIf="modalType !== 'BU'" class="modal-value">{{data.lossesMVH | hasValue }} ({{data.losses | hasValue | percentage}})</span>
        </div>
        <div *ngIf="modalType === 'TS' || modalType === 'SS'" class="modal-content">
          <span class="modal-label">Grid to DT losses</span>
          <span *ngIf="losses == null" class="modal-value">/</span>
          <span *ngIf="losses != null" class="modal-value">{{data.lossesMVH | hasValue }} ({{data.losses | hasValue | percentage}})</span>
        </div>
        <div *ngIf="modalType === 'ACC'" class="modal-content">
          <span class="modal-label">Account No</span>
          <span class="modal-value">{{data.accountNo | hasValue }}</span>
        </div>
        <div *ngIf="modalType === 'ACC'" class="modal-content">
          <span class="modal-label">MSN</span>
          <span class="modal-value">{{data.msn | hasValue }}</span>
        </div>
        <div *ngIf="modalType === 'ACC'" class="modal-content">
          <span class="modal-label">Tariff Name</span>
          <span class="modal-value">{{data.tariffName | hasValue }}</span>
        </div>
      </div>
    </div>
    <div class="modal-period">
      <span>Period {{ monthBefore | date: 'MMMM' }}</span>
    </div>
  </div>
  <div *ngIf="nmDashboard || customersMap">
    <map-marker #somemarker="mapMarker"
                *ngFor="let marker of markers"
                [position]="getPosition(marker.longitude, marker.latitude)"
                [icon]="marker.icon"
                [label]="getMarkerLabel(marker)"
                [options]="markerProperties"
                (mapClick)="markerHoverNM(somemarker, marker, $event)">
    </map-marker>
  </div>
</google-map>


