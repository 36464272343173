import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { TaskCategory } from 'src/app/models/task-category.model';
import { AuthService } from 'src/app/services/auth.service';
import { TaskCategoryService } from 'src/app/services/task-category.service';
import { CrudDeleteModalComponent } from '../../crud-tabs/crud-delete-modal/crud-delete-modal.component';
import { CategoryCreateComponent } from './category-create/category-create.component';
import { CrudService } from 'src/app/services/crudService.service';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-wom-crud',
  templateUrl: './wom-crud.component.html',
  styleUrls: ['./wom-crud.component.scss']
})
export class WomCrudComponent implements OnInit {

  public PermissionsEnum = PermissionsEnum;

  public currentPage: number = 1;
  public pageSize: number = 8;
  public count: number = 0;
  public lastPageSize: number = 1;

  public selectedId : number = 0;

  public categories: TaskCategory[] = [];
  public selectedCategory: TaskCategory = new TaskCategory();
  private ngUnsubscribe = new Subject<void>();


  searchText: string = '';

  @ViewChild("f") f: any;
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;
  @ViewChild(CategoryCreateComponent) popupComponentRef!: CategoryCreateComponent;
  componentName: ComponentNamesNew = ComponentNamesNew.TaskCategoryComponent;


  constructor(public authService: AuthService,
              private toastr: ToastrService,
              private location: Location,
              private crudService: CrudService,
              private taskCategoryService: TaskCategoryService) { }

  ngOnInit(): void {
    this.reloadTable();
    this.subscribeToSearch();
  }

  back() {
    this.location.back();
  }

  openTab(data?: any) {
    if (data) {
      this.popupComponentRef.changeState(1, data);
    }
    else {
      this.popupComponentRef.changeState(1);
    }
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  selectCategory(id: number) {
    this.taskCategoryService.getById(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedCategory = response.data;
        this.openTab(response.data);
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedCategory() {
    this.selectedCategory = new TaskCategory();

    if(this.f)
      this.f.submitted = false;
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;
    this.resetSelectedCategory();
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };
    this.taskCategoryService.getAll(obj).subscribe(response => {
      this.categories = response.data.data;

      if(this.currentPage == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  openTabDelete(id: number) { 
    this.taskCategoryService.getById(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedCategory = response.data;
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.selectedId = id;
    this.crudDeleteRef.changeState();
  }

  onDeleteConfirmed() {
    this.taskCategoryService.delete(this.selectedId).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.reloadTable();
        this.resetSelectedCategory();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  textChanged(): void {
    if (this.searchText.trim().length === 0) {
      this.search();
    }
  }

  search(): void {
    this.crudService.setGlobalSearch({ clicked: true, text: this.searchText, componentName: this.componentName });
  }

  public subscribeToSearch() {
    this.crudService.globalSearch$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(searchValue => {
      if(searchValue.clicked && searchValue.componentName === ComponentNamesNew.TaskCategoryComponent) {
        this.searchText = searchValue.text;
        this.currentPage = 1;
        this.reloadTable();
        return;
      }
      this.searchText = '';
    });
  }
}
