import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationStorage } from 'src/share-storage/notification-storage';
import { AppConfig } from '../config/config';
import { NotificationAdora } from '../models/notification.model';
import { PermissionsEnum } from '../models/permissionsEnum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private pathApi = this.config.setting['pathApi'] + "auth/";
  private jwtService = new JwtHelperService();

  public notifications:NotificationAdora[] = this.notificationStorage.notifications;

  constructor(private http: HttpClient, private toastr: ToastrService,
    private config: AppConfig,
    private router: Router,
    private notificationStorage: NotificationStorage,) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post(this.pathApi + "login", { Email: email, Password: password });
  }

  refreshToken(token: string): Observable<any> {
    return this.http.post(this.pathApi + "refresh", { RefreshToken: token });
  }
  public decodeJWTToken() {
    let token = localStorage.getItem('token');

    if (!token)
      return null;

    return this.jwtService.decodeToken(token);
  }
  logout() {
    if(!!localStorage.getItem("refreshToken")){
      this.http.post(this.pathApi + "logout", { RefreshToken: localStorage.getItem("refreshToken") }).subscribe(
        (res) => {},
      );
    }

    if(environment.useOpenIdDict)
    {
      this.logoutIdentity().subscribe(res => { // logout from identity
        this.logoutNoApi(); // logout from app
      }, err => {
        this.toastr.error('An error ocurred.');
      });    }
    else
    {
      this.logoutNoApi();
    }

    this.notificationStorage.firstLoad = true;
    this.notificationStorage.notifications = [];
    this.notificationStorage.totalPages = 1;
  }

  logoutNoApi() {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('isPasswordTemp');
    localStorage.removeItem('translations');
    localStorage.removeItem('refreshToken');
    window.sessionStorage.clear();
    
    if(localStorage.getItem('is_from_home')){
      localStorage.removeItem('is_from_home');
      window.location.href = environment.homeUrl + 'logout';
    }
    else{
      this.router.navigate(['']);
    }
  }

  updateUserInLocalStorageNoApi(user: any) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  isLoggedIn() {
    let token = localStorage.getItem('token');

    return !!token;
  }

  hasRefreshToken() {
    let token = localStorage.getItem('refreshToken');

    return !!token;
  }

  isPasswordTemp() {
    return localStorage.getItem('tempPassword');
  }

  public getCurrentUser() {
    let token = localStorage.getItem('token');

    if (!token)
      return null;

    return this.jwtService.decodeToken(token);
  }

  public isAuthorized(permission: PermissionsEnum) {
    let permissionCrypto = localStorage.getItem('permissions') ?? '';
    let rolePermissions = this.jwtService.urlBase64Decode(permissionCrypto)

    if (!rolePermissions)
      return false;

    let currentUserPermissions = JSON.parse(rolePermissions); 
    let filteredPerms = currentUserPermissions.filter((x: PermissionsEnum) => x == permission);

    if (filteredPerms)
      return filteredPerms[0];

    return null;
  }

  public isGroupAuthorized(permission: string) {
    let permissionCrypto = localStorage.getItem('permissions') ?? '';
    let rolePermissions = this.jwtService.urlBase64Decode(permissionCrypto)

    if (!rolePermissions)
      return false;

    let currentUserPermissions = JSON.parse(rolePermissions);
    let filteredPerms = currentUserPermissions?.filter((x: PermissionsEnum) => PermissionsEnum[x]?.includes(permission)) 
    if (filteredPerms)
      return filteredPerms[0];

    return null;
  }

  logoutIdentity(): Observable<any> {
    return this.http.post(this.pathApi + "logoutIdentity",null, {withCredentials: true});
  }

  loginIdentity() : Observable<string> {
    this.logoutIdentity().subscribe(
      (x)=>{
        return this.http.get(this.pathApi + 'identityLogin', { responseType: 'text' });
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
      );
    return this.http.get(this.pathApi + 'identityLogin', { responseType: 'text' });
  }
}
