<main class="container-fluid">
    <div class="container-wrapper">
        <div class="network-management-title">
            <span>Watch List</span>
        </div>
        <div class="menu-tab-filter">
            <app-tab-default [firstText]="'Network'" [secondText]="'Organisation'"
                (tabChanged)="mainTabChange($event)"></app-tab-default>
        </div>
        <app-net-watch *ngIf="menu === MenuItemEnum.Network" [menu]="menu"
             (redirect)="switchMenu(MenuItemEnum.Network)">
        </app-net-watch>
        <app-org-watch-new *ngIf="menu === MenuItemEnum.Organisation" [menu]="menu"
            (redirect)="switchMenu(MenuItemEnum.Organisation)">
        </app-org-watch-new>
    </div>
</main>


    
