import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { Feeder11 } from 'src/app/models/feeder11.model';
import { BoundaryMeterDto } from 'src/app/models/feeder11Bu.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { MeterService, TypeOfConsumer } from 'src/app/services/meter.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-feeder11-create',
  templateUrl: './feeder11-create.component.html',
  styleUrls: ['./feeder11-create.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class Feeder11CreateComponent {
  @Input() animationState = 'out';
  public PermissionsEnum = PermissionsEnum;

  showShadow = false;

  public msnValid: boolean = false;

  public powerMsnValid: boolean = true;
  public regionMsnValid: boolean = true;

  public feeders11: Feeder11[] = [];
  public selectedFeeder11: Feeder11 = new Feeder11();
  public buSelected: number = 0;
  public meterNumber: string = '';
  public amiSelected: number = 0;
  public buNotSharedId: number = 0;
  @Output() parentComp = new EventEmitter();

  @Input() substations: any = [];
  @Input() amis: any = [];
  @Input() buForSelect: DictionaryItem[] = [];
  @Input() feeders33: any = [];


  public isNameplateRatingValid: boolean = true;

  public isEnergyShared: boolean = false;

  public amiName: string = this.translationService.getByKeyFromCache('AMI');
  public buName: string = this.translationService.getByKeyFromCache('BU');

  constructor(private ref: ElementRef,
    private translationService: TranslationService,
    private feeder33Service: Feeder33Service,
    private toastr: ToastrService,
    private meterService: MeterService,
    public authService: AuthService,
    private feeder11Service: Feeder11Service,
    private ssService: SubstationService,
    private amiService: AmiService,
    private buService: BusinessUnitService
    ) { }

  @ViewChild("f") f: any;
  // @ViewChild(Feeder11CreateComponent) popupComponentRef!: Feeder11CreateComponent;

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  closeTab() {
    this.changeState(2);
  }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.feeder11-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }

  changeState(number: number, data?: any) {
    if (data) {
      this.selectedFeeder11 = data;
      this.isEnergyShared = this.selectedFeeder11.boundaryMeters.length > 0 
    }
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
  }

  save() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_delete)) {
      this.toastr.error("You don't have permission.");
      return;
    }
    if(!this.isNameplateRatingValid) return;

    if (!this.validateConnectedSS()) return;
    if (!this.validateConnectedF33()) return;

    if(!this.validateRegion()) return;
    
    if(!this.validateMaxDigitAndMultiplierFactor()) return;

    if (this.isEnergyShared && this.selectedFeeder11.boundaryMeters.length == 0)
      return;

    if (!this.validateMeter(this.selectedFeeder11.amiId, this.selectedFeeder11.meterNumber) && this.selectedFeeder11.meterType !== 2) {
      this.msnValid = false;
      return
    }
    
    if (!this.isEnergyShared) {
      this.selectedFeeder11.boundaryMeters = []
    }
    else {
      this.selectedFeeder11.buId = 0
    }

    this.feeder11Service.save(this.selectedFeeder11).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.resetSelectedFeeder11();
        this.changeState(2);
        this.parentComp.emit();
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  validateNameplate(){
    if (Number.isNaN(Number(this.selectedFeeder11?.nameplateRating)) || Number(this.selectedFeeder11?.nameplateRating) < 0) {
      this.isNameplateRatingValid = false;
    } else
      this.isNameplateRatingValid = true;
  }

  validateMaxDigitAndMultiplierFactor(){
    if (this.selectedFeeder11.meterNumber && this.selectedFeeder11.meterMaxDigit != null && (this.selectedFeeder11.meterMaxDigit ?? 0) < 1) {
      this.toastr.error('Meter Max Digit must be a positive number')
      return false
    }

    if (this.selectedFeeder11.meterNumber && this.selectedFeeder11.multiplierFactor != null && this.selectedFeeder11.multiplierFactor < 1) {
      this.toastr.error('Multiplier Factor must be a positive number')
      return false
    }
    return true
  }

  validateRegion(){
    if (!this.isEnergyShared && this.selectedFeeder11.buId == 0){
      this.toastr.error('Region must be provided');
      return false;
    }
    return true;
  }

  isMeteredValidate() {
    if (this.selectedFeeder11.meterType === 2) {
      this.selectedFeeder11.meterNumber = '';
      this.selectedFeeder11.amiId = 0;
    }

  }

  validatePowerMSN() {
    if (!this.validateMeter(this.amiSelected, this.meterNumber)) {
      this.powerMsnValid = false;
      return;
    }
    this.powerMsnValid = true;
  }

  validateMSN() {
    if (!this.validateMeter(this.selectedFeeder11.amiId, this.selectedFeeder11.meterNumber)) {
      this.msnValid = false;
      return;
    }
    this.msnValid = true;
  }
  msnNumberErrorMsg = '';

  validateMeter(amiId: number, meterNumber: string) {
    let ami = this.amis.find((x: { value: number; }) => x.value == amiId)?.name;
    if (ami && ami != '') {
      this.msnNumberErrorMsg = this.meterService.validateMeter(ami, meterNumber, TypeOfConsumer.Feeder33)
      if (this.msnNumberErrorMsg == '')
        return true
    }
    return false
  }

  resetSelectedFeeder11() {
    this.selectedFeeder11 = new Feeder11();
    this.buNotSharedId = 0;
    if (this.f)
      this.f.submitted = false;
  }

  validateConnectedSS() {
    if (this.selectedFeeder11.ssId == 0) {
      return false;
    }
    return true;
  }

  validateConnectedF33() {
    if (this.selectedFeeder11.feeder33Id == 0) {
      return false;
    }
    return true;
  }

  deleteFeederBu(buId: number) {
    this.selectedFeeder11.boundaryMeters.splice(this.selectedFeeder11.boundaryMeters.findIndex(x => x.buId == buId), 1)
  }

  addBuPower() {

    if (this.meterNumber === "") {
      return
    }
    this.validatePowerMSN();
    if (!this.powerMsnValid) {
      return
    }

    if (this.buSelected !== 0 && this.amiSelected !== 0 && this.meterNumber != '') {
      let temp = new BoundaryMeterDto
      temp.buName = this.buForSelect.find(x => x.value == this.buSelected)?.name ?? '';
      temp.buId = this.buSelected
      temp.meterNumber = this.meterNumber
      temp.amiName = this.amis.find((x: { value: number; }) => x.value == this.amiSelected)?.name;
      temp.amiId = this.amiSelected

      if (this.selectedFeeder11.boundaryMeters.find(x => x.buName == temp.buName) == null) {
        this.selectedFeeder11.boundaryMeters.push(temp)

        this.amiSelected = 0;
        this.buSelected = 0;
        this.meterNumber = '';
        this.powerMsnValid = true;
        this.regionMsnValid = true
      }
      else {
        this.regionMsnValid = false
      }
    }

  }

  checkboxChanged() {
    //
  }

  buChanged() {
    this.amiSelected = 0;
    this.meterNumber = "";
  }

  public onChangeSS() {
    this.feeder33Service.getAllFeeder33BySsId(this.selectedFeeder11.ssId).subscribe(response => {
      this.feeders33 = response.data;
      this.selectedFeeder11.feeder33Id = 0;
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }
}
