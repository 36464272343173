
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartDataset, ChartOptions, ChartTypeRegistry } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { DtService } from 'src/app/services/dt.service';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';

@Component({
  selector: 'app-graph-analysis',
  templateUrl: './graph-analysis.component.html',
  styleUrl: './graph-analysis.component.scss'
})
export class GraphAnalysisComponent implements OnInit {
  public lineChartLabels: any[] = []
  public powFacLineChartLabels: any[] = []
  public currentLineChartLabels: any[] = []
  public volLineChartLabels: any[] = []
  public statusOnOffLabels: any[] = []
  public demandLabels: any[] = []
  public tfeLineChartLabels: any[] = []

  public receivedLabels: string[] = [];
  public EnergyLabels: string[] = [];

  public CapitalLabels: string[] = [];
  private graphData: any[] = []

  public data: any;
  public barChartLegend = false;
  public barChartPlugins = [];
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public searchPlaceholder: string = "Search by Dt name or number"
  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public options = ['']

  public dateFrom: any = ''
  public dateTo: any = ''

  @Input() id: number = 0;
  //Multi Filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;

  public buName: string = this.translationService.getByKeyFromCache('BU')
  public buIds: number[] = []
  public businessUnits: any = { name: this.buName, data: [], dropdown: false, maxSelected: 15 };

  public lineChartData: ChartDataset[] = [
    {
      type: 'line',
      borderWidth: 2,
      fill: true,
      data: [],
      pointBackgroundColor:"white",
      pointBorderColor:"#C03438",
      pointRadius:6,
      pointBorderWidth:2,
      borderColor:"#C03438",
    }
  ];
  public powFacLineChartData: ChartDataset[] = [
    {
      type: 'line',
      borderWidth: 2,
      fill: true,
      data: [],
      pointBackgroundColor:"white",
      pointBorderColor:"#E18D16",
      pointRadius:6,
      pointBorderWidth:2,
      borderColor:"#E18D16",
    }
  ];
  currentLineChartData: ChartDataset[] = [
    {
      type: 'line',
      borderWidth: 2,
      fill: true,
      data: [],
      pointBackgroundColor:"white",
      pointBorderColor:"#188D52",
      pointRadius:6,
      pointBorderWidth:2,
      borderColor:"#188D52",
    }
  ];
  volLineChartData: ChartDataset[] = [
    {
      type: 'line',
      borderWidth: 2,
      fill: true,
      data: [],
      pointBackgroundColor:"white",
      pointBorderColor:"#2E3336",
      pointRadius:6,
      pointBorderWidth:2,
      borderColor:"#2E3336",
    }
  ];

  statusOnOffChartData: ChartDataset[] = [
    {
      type: 'line',
      borderWidth: 2,
      fill: false,
      data: [],
      pointBackgroundColor:"white",
      pointBorderColor:"#537CE5",
      borderColor:"#537CE5"

    }
  ];

  demandChartData: ChartDataset[] = [
    {
      type: 'line',
      borderWidth: 2,
      fill: false,
      data: [],
      pointBackgroundColor:"white",
      pointBorderColor:"#9C34C0",
    borderColor:"#9C34C0"   }
  ];

  public tfeLineChartData: ChartDataset[] = [
    {
      type: 'line',
      borderWidth: 2,
      fill: true,
      data: [],
      pointBackgroundColor:"white",
      pointBorderColor:"#C03438",
      pointRadius:6,
      pointBorderWidth:2,
      borderColor:"#C03438",
    }
  ];

  public lineChartKwhOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    elements: {
      point: {
        hoverRadius: 6,
        radius:6,
        borderWidth:2
      },
      line: {
        tension: 0
      }
    },
    plugins: {
      legend: {
        display: false
      }

    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks:{
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          //stepSize: 0.2,
          callback: function (value, index, values) {
            return Number(value).toFixed(2) + " kwh";
          }
        }
      }
    }
  };

  public lineChartVAOptions: ChartOptions = JSON.parse(JSON.stringify(this.lineChartKwhOptions));
  public lineChartAOptions: ChartOptions =JSON.parse(JSON.stringify(this.lineChartKwhOptions));
  public lineChartVOptions: ChartOptions =JSON.parse(JSON.stringify(this.lineChartKwhOptions));
  public lineChartKWOptions: ChartOptions =JSON.parse(JSON.stringify(this.lineChartKwhOptions));

  public lineChartType: keyof ChartTypeRegistry = 'line';
  constructor(private meterService: MeterService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private businessUnitService: BusinessUnitService,
    private route: ActivatedRoute,
    private dtService: DtService) {
      this.route.params.subscribe(params => {
        this.id = params['id'];
      })
  }
  ngOnInit(): void {
    this.initializeScales()
    this.setMainDate();
    this.getMetersConsumption()
    this.getMeters()
    this.getAllBUs()
    this.getCurrentMeters()
    this.getVoltageMeters()
    this.getStatusOnOff()
    this.getDemandMeters()
    this.getMetersTfe()
  }

  getAllBUs() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data, dropdown: false, maxSelected: 15 }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  initializeScales(){

    this.lineChartVAOptions.scales={
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks:{
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 0.2,
          callback: function (value, index, values) {
            return value + " VA";
          }
        }
      }
    }

    this.lineChartAOptions.scales={
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks:{
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 0.2,
          callback: function (value, index, values) {
            return value + " A";
          }
        }
      }
    }

    this.lineChartKWOptions.scales={
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks:{
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 0.2,
          callback: function (value, index, values) {
            return value + " KW";
          }
        }
      }
    }

    this.lineChartVOptions.scales={
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks:{
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 0.2,
          callback: function (value, index, values) {
            return value + " V";
          }
        }
      }
    }


  }

  setMainDate() {
    let date = new Date()
    this.dateTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
    date.setMonth(date.getMonth() - 1)
    this.dateFrom = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }

    if (this.dateTo["day"] != this.dateFrom["day"]) {
      this.dateFrom['day'] = 1
    }
  }


  dropdownSelected(data: any) {
    if (!data)
      return
    if (data.data.name === this.buName)
      this.buIds = data.data.value
  }

  checkFilters() {
    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);
    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;
    this.allFilters.push(this.businessUnits)
    if(this.allFilters.length != 0)
    this.filterApplied = true

  }
  setFilters(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);
      const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);

      if (item.name === this.businessUnits.name) {
        this.buIds = filteredValues
      }
    }
  }
  public DateClickEvent(date: any) {
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.getCurrentMeters()
    this.getVoltageMeters()
    this.getDemandMeters()
    this.getMetersConsumption()
    this.getStatusOnOff()
    this.getMeters()
    this.getMetersTfe()
  }
  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: false
    }
    if (searchText.name.length > 2) {
      this.dtService.getDataForAutocomplete(obj)
        .subscribe(response => {
          this.options = response.data
        })
    } else {
      this.searchChild.resetOptions()
    }
  }

  // Search Autocomplete
  searchChanged(searchText: SearchAutocomplete) {
    this.searchText = searchText
  }
  setValues(response: any) {
    this.lineChartData[0].data = []
    let labels = ['']
    labels = []
    if (response != null) {
      this.graphData = response
      this.graphData.forEach(element => {
        this.lineChartData[0].data?.push(Math.round(element.energy) / 1000)
        labels.push(this.sliceLabel(element.ts))
      })
    }
    this.lineChartLabels = labels
  }

  setValuesForPowerFactor(response: any) {
    this.powFacLineChartData[0].data = []
    let labels = ['']
    labels = []
    if (response != null) {
      this.graphData = response
      this.graphData.forEach(element => {
        this.powFacLineChartData[0].data?.push(Math.round(element.energy))
        labels.push(this.sliceLabel(element.ts))
      })
    }
    this.powFacLineChartLabels = labels
  }
  setValuesForVoltage(response: any) {
    this.volLineChartData[0].data = []
    let labels = ['']
    labels = []
    if (response != null) {
      this.graphData = response
      this.graphData.forEach(element => {
        this.volLineChartData[0].data?.push(Math.round(element.energy))
        labels.push(this.sliceLabel(element.ts))
      })
    }
    this.volLineChartLabels = labels
  }

  setValuesForDemand(response: any) {
    console.log(this.demandChartData)
    this.demandChartData[0].data = []
    let labels = ['']
    labels = []
    if (response != null) {
      this.graphData = response
      this.graphData.forEach(element => {
        this.demandChartData[0].data?.push(Math.round(element.energy))

        labels.push(this.sliceLabel(element.ts))
      })
    }
    this.demandLabels = labels
  }

  setValuesForStatusOnOff(response: any) {
    this.statusOnOffChartData[0].data = []
    let labels = ['']
    labels = []
    if (response != null) {
      this.graphData = response
      this.graphData.forEach(element => {
        this.statusOnOffChartData[0].data?.push(Math.round(element.energy))

        labels.push(this.sliceLabel(element.ts))
      })
    }

    this.statusOnOffLabels = labels
  }

  setValuesForCurrent(response: any) {
    this.currentLineChartData[0].data = []
    let labels = ['']
    labels = []
    if (response != null) {
      this.graphData = response
      this.graphData.forEach(element => {
        this.currentLineChartData[0].data?.push(Math.round(element.energy))
        labels.push(this.sliceLabel(element.ts))
      })
    }
    this.currentLineChartLabels = labels
   }

   setValuesForTfe(response: any) {
    this.tfeLineChartData[0].data = []
    let labels = ['']
    labels = []
    if (response != null) {
      this.graphData = response
      this.graphData.forEach(element => {
        this.tfeLineChartData[0].data?.push(element.energy)
        labels.push(this.sliceLabel(element.ts))
      })
    }
    this.tfeLineChartLabels = labels
  }

  private sliceLabel(label: string) {
    if (label.length > 20)
      return label.slice(0, 20) + '...'
    return label
  }

  private getMeters() {
    let obj = {
      Search: this.searchText ? this.searchText.name : this.searchText,
      From :  transformToString(this.dateFrom),
      To:  transformToString(this.dateTo),
      Mid: this.id,
      Code: "Pow_fac"
    };
    this.meterService.getMeterForGraph(obj).subscribe((response: any) => {
      //this.powFacLineChartData = response.data
      this.setValuesForPowerFactor(response.data)
     }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  private getCurrentMeters() {
    let obj = {
      Search: this.searchText ? this.searchText.name : this.searchText,
      From :  transformToString(this.dateFrom),
      To:  transformToString(this.dateTo),
      Mid: this.id,
      Code: "Pha_A_Cur"
    };
    this.meterService.getMeterForGraph(obj).subscribe((response: any) => {
      //this.currentLineChartData = response.data
      this.setValuesForCurrent(response.data)

    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  private getVoltageMeters() {
    let obj = {
      Search: this.searchText ? this.searchText.name : this.searchText,
      From :  transformToString(this.dateFrom),
      To:  transformToString(this.dateTo),
      Mid: this.id,
      Code: "Pha_A_Vol"
    };
    this.meterService.getMeterForGraph(obj).subscribe((response: any) => {
      //this.volLineChartData = response.data
      this.setValuesForVoltage(response.data)
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  private getDemandMeters(){
    let obj = {
      Search: this.searchText ? this.searchText.name : this.searchText,
      From :  transformToString(this.dateFrom),
      To:  transformToString(this.dateTo),
      Mid: this.id,
      Code: "Demand"
    };
    this.meterService.getMeterForGraph(obj).subscribe((response: any) => {
      //this.demandChartData[0].data = response.data
      this.setValuesForDemand(response.data)
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  private getStatusOnOff(){
    let obj = {
      Search: this.searchText ? this.searchText.name : this.searchText,
      From :  transformToString(this.dateFrom),
      To:  transformToString(this.dateTo),
      Mid: this.id,
      Code: "StatusOnOff"
    };
    this.meterService.getMeterForGraph(obj).subscribe((response: any) => {
      this.statusOnOffChartData[0].data = response.data
      this.setValuesForStatusOnOff(response.data)
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  private getMetersConsumption() {
    let obj = {
      Search: this.searchText ? this.searchText.name : this.searchText,
      From :  transformToString(this.dateFrom),
      To:  transformToString(this.dateTo),
      Mid: this.id
    };
    this.meterService.getMeterConsumption(obj).subscribe((response: any) => {
      //this.lineChartData = response.data
      this.setValues(response.data)
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  private getMetersTfe(){
    let obj = {
      Search: this.searchText ? this.searchText.name : this.searchText,
      From :  transformToString(this.dateFrom),
      To:  transformToString(this.dateTo),
      Mid: this.id,
    };
    this.meterService.getMeterTfeForGraph(obj).subscribe((response: any) => {
      this.setValuesForTfe(response.data)
    }, _ => {
      this.toastr.error('An error ocured');
    })
  }

  download(){
     this.meterService.getMeterTfeReport(this.id).subscribe( response => {
      if(response.status == 404){
        this.toastr.info(response.message);
      }
      else if(response.status == 200){
        window.open(response.data, '_blank');
      }
      else{
        this.toastr.info("Unknown error occurred");
      }
     });
  }
}
