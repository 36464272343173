import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BusinessUnit } from 'src/app/models/business-unit.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-create-business-unit',
  templateUrl: './create-business-unit.component.html',
  styleUrls: ['./create-business-unit.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class CreateBusinessUnitComponent {
  public selectedBu: BusinessUnit = new BusinessUnit();
  showShadow = false;
  @Output() parentComp = new EventEmitter();

  @Input() animationState = 'out';
  @ViewChild(CreateBusinessUnitComponent) popupComponentRef!: CreateBusinessUnitComponent;
  public buName: string = this.translationService.getByKeyFromCache('BU');

  constructor(private authService: AuthService, private buService: BusinessUnitService, private toastr: ToastrService, private ref: ElementRef,
    private translationService: TranslationService) { }


  save(){
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    if(Number.isNaN(Number(this.selectedBu?.longitude))){
      this.toastr.error('Longitude must be numeric value between -180 and 180.');
      return;
    }

    if(Number(this.selectedBu?.longitude) < -180 || Number(this.selectedBu?.longitude) > 180){
      this.toastr.error('Longitude must be in -180 to 180 range.');
      return;
    }

    if(Number.isNaN(Number(this.selectedBu?.latitude))){
      this.toastr.error('Latitude must be numeric value between -90 and 90.');
      return;
    }

    if(Number(this.selectedBu?.latitude) < -90 || Number(this.selectedBu?.latitude) > 90){
      this.toastr.error('Latitude must be in -90 to 90 range.');
      return;
    }

    if((this.selectedBu.name && this.selectedBu.latitude && this.selectedBu.longitude) !== ''){
      this.buService.save(this.selectedBu).subscribe(response => {
        if(response.status === 200){
          this.toastr.success(response.message);
          this.changeState(2);
          this.resetSelectedBu();
          this.parentComp.emit();
          // this.closeModal.nativeElement.click();
        }else
          this.toastr.error(response.message);
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
  }

  resetSelectedBu(){
    this.selectedBu = new BusinessUnit();
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  closeTab() {
    this.popupComponentRef.changeState(2);
  }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.bu-popup');
    if (this.animationState === 'in'){
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
    }
  }

  changeState(number: Number, data?: any) {
    if (data)
      this.selectedBu = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
  }

}
