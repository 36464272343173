import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AssetHierarchy, AssetsHierarchyType } from 'src/app/models/assetHierarchy.model';
import { SearchAutocomplete, Type } from 'src/app/models/searchAutocomplete.model';
import { AccountService } from 'src/app/services/account.service';
import { CommonService } from 'src/app/services/common-service.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';

@Component({
  selector: 'app-hierarchy-item',
  templateUrl: './hierarchy-item.component.html',
  styleUrl: './hierarchy-item.component.scss'
})
export class HierarchyItemComponent {
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()

  public TSs: AssetHierarchy[] = [];
  public F33s: AssetHierarchy[] = [];
  public SSs: AssetHierarchy[] = [];
  public F11s: AssetHierarchy[] = [];
  public DTs: AssetHierarchy[] = [];
  public ACCs: AssetHierarchy[] = [];

  public tsShowed: number[] = []
  public f33Showed: number[] = []
  public ssShowed: any[][] = []
  public f11Showed: number[] = []
  public dtShowed: number[] = []

  constructor(
    private toastr: ToastrService,
    private commonService: CommonService,
    private tsService: TransmissionStationService,
    private f33Service: Feeder33Service,
    private ssService: SubstationService,
    private f11Service: Feeder11Service,
    private DtService: DtService,
    private accService: AccountService
  ) {}

  ngOnInit(): void {
    this.getTsAssetsHierarchy()
  }

  getTsAssetsHierarchy(obj: any = ''){
    if(obj == ''){
      obj = {
        
       }
    }
    this.tsService.getSystemHierarchy(obj).subscribe(response =>{
      if(response.status == 200){
        this.TSs = response.data
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  getF33AssetsHierarchy(ts: AssetHierarchy, obj: any = ''){
    if(obj == ''){
      obj = {
        Id: ts.id
       }
    }
    
    this.f33Service.getSystemHierarchy(obj).subscribe(response =>{
      if(response.status == 200){
        if(ts.id == 0){
          this.parseHierarchyData(response.data)
        }
        else{
          ts.children = response.data
        }
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  parseHierarchyData(data: AssetHierarchy[]){
    this.TSs = data.filter(x => x.type == AssetsHierarchyType.TS)
    this.TSs.forEach(ts => {
      this.populateChildrens(ts, this.tsShowed, data, AssetsHierarchyType.Feeder33KV)

      if(ts.children != null && ts.children != undefined){
        ts.children.forEach(f33 => {
          this.populateF33DtChildren(f33, this.f33Showed, data)
          
          if(f33.children != null && f33.children != undefined){
            f33.children.forEach(ss => {
              if(ss.type == AssetsHierarchyType.SS){
                this.populateSsChildrens(ss, this.ssShowed, data, AssetsHierarchyType.Feeder11KV)
              }
              else{
                this.populateChildrens(ss, this.dtShowed, data, AssetsHierarchyType.ACC)
              }

              if(ss.children != null && ss.children != undefined){
                ss.children.forEach(f11 => {
                  this.populateChildrens(f11, this.f11Showed, data, AssetsHierarchyType.DTs)
                  
                  if(f11.children != null && f11.children != undefined){
                    f11.children.forEach(dt => {
                      this.populateChildrens(dt, this.dtShowed, data, AssetsHierarchyType.ACC)
                    });
                  }
                });
              }
            });
          }
        });
      }
      
    });
  }

  populateChildrens(parent: AssetHierarchy, childShowed: number[], data: AssetHierarchy[], type: AssetsHierarchyType){
    let childData = data.filter(x => x.type == type && x.parentId == parent.id && x.parentType == AssetsHierarchyType.Undefined)
    if(childData.length != 0){
      parent.children = childData
      if(!childShowed.includes(parent.id))
        childShowed.push(parent.id)
    }
  }

  populateSsChildrens(parent: AssetHierarchy, childShowed: number[][], data: AssetHierarchy[], type: AssetsHierarchyType){
    let childData = data.filter(x => x.type == type && x.parentId == parent.id && x.parentType == AssetsHierarchyType.Undefined)
    if(childData.length != 0){
      parent.children = childData
      if(!this.includesTuple(childShowed, [parent.id, parent.parentId]))
        childShowed.push([parent.id, parent.parentId])
    }
  }

  populateF33DtChildren(parent: AssetHierarchy, childShowed: number[], data: AssetHierarchy[]){
    let childData = data.filter(x => x.type == AssetsHierarchyType.SS && x.parentId == parent.id)
    let dtChild = data.filter(x => x.type == AssetsHierarchyType.DTs && x.parentId == parent.id && x.parentType == AssetsHierarchyType.Feeder33KV)
    childData.push(...dtChild)
    if(childData.length != 0){
      parent.children = childData
      if(!childShowed.includes(parent.id))
        childShowed.push(parent.id)
    }
  }

  getSsAssetsHierarchy(f33: AssetHierarchy, obj: any = ''){
    if(obj == ''){
      obj = {
        Id: f33.id
       }
    }
    this.ssService.getSystemHierarchy(obj).subscribe(response =>{
      if(response.status == 200){
        if(f33.id == 0){
          this.parseHierarchyData(response.data)
        }
        else{
          f33.children = response.data
        }
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  getF11AssetsHierarchy(f11: AssetHierarchy, obj: any = ''){
    if(obj == ''){
      obj = {
        Id: f11.id
       }
    }
    this.f11Service.getSystemHierarchy(obj).subscribe(response =>{
      if(response.status == 200){
        if(f11.id == 0){
          this.parseHierarchyData(response.data)
        }
        else{
          f11.children = response.data
        }
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  getDtAssetsHierarchy(dt: AssetHierarchy, obj: any = ''){
    if(obj == ''){
      obj = {
        Id: dt.id
       }
    }
    this.DtService.getSystemHierarchy(obj).subscribe(response =>{
      if(response.status == 200){
        if(dt.id == 0){
          this.parseHierarchyData(response.data)
        }
        else{
          dt.children = response.data
        }
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  getAccAssetsHierarchy(dt: AssetHierarchy, obj: any = ''){
    if(obj == ''){
      obj = {
        Id: dt.id
       }
    }
    this.accService.getSystemHierarchy(obj).subscribe(response =>{
      if(response.status == 200){
        if(dt.id == 0){
          this.parseHierarchyData(response.data)
        }
        else{
          dt.children = response.data
        }
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  tsClicked(ts: AssetHierarchy){
    if(this.tsShowed.includes(ts.id)){
      let index = this.tsShowed.indexOf(ts.id)
      this.tsShowed.splice(index, 1)
    }
    else{
      this.tsShowed.push(ts.id)
      this.getF33AssetsHierarchy(ts)
    }
  }

  f33Clicked(f33: AssetHierarchy){
    if(this.f33Showed.includes(f33.id)){
      let index = this.f33Showed.indexOf(f33.id)
      this.f33Showed.splice(index, 1)
    }
    else{
      this.f33Showed.push(f33.id)
      this.getSsAssetsHierarchy(f33)
    }
  }



  ssClicked(ss: AssetHierarchy){
    if(this.includesTuple(this.ssShowed, [ss.id, ss.parentId])){
      console.log('hell')
      let index = this.indexOfTuple(this.ssShowed, [ss.id, ss.parentId])
      this.ssShowed.splice(index, 1)
    }
    else{
      console.log('push')
      this.ssShowed.push([ss.id, ss.parentId])
      this.getF11AssetsHierarchy(ss)
    }

    console.log(this.ssShowed)
  }

  f11Clicked(f11: AssetHierarchy){
    if(this.f11Showed.includes(f11.id)){
      let index = this.f11Showed.indexOf(f11.id)
      this.f11Showed.splice(index, 1)
    }
    else{
      this.f11Showed.push(f11.id)
      this.getDtAssetsHierarchy(f11)
    }
  }

  dtClicked(dt: AssetHierarchy){
    if(this.dtShowed.includes(dt.id)){
      let index = this.dtShowed.indexOf(dt.id)
      this.dtShowed.splice(index, 1)
    }
    else{
      this.dtShowed.push(dt.id)
      this.getAccAssetsHierarchy(dt)
    }
  }

  compareTuples(t1: any[], t2: any[]){
    let value = t1[0] == t2[0] && t1[1] == t2[1]
    return value
  }

  includesTuple(array: any[][], tuple: any[]){
    let includes = false

    array.forEach(element => {
      if(this.compareTuples(element, tuple)){
        includes = true
      }
    });
    
    return includes
  }

  indexOfTuple(array: any[][], tuple: any[]){
    for(let i = 0; i < array.length; i++){
      if(this.compareTuples(array[i], tuple)){
        return i
      }
    }

    return -1
  }

  resetShowedAssets(){
    this.tsShowed = []
    this.f33Showed = []
    this.ssShowed = []
    this.f11Showed = []
    this.dtShowed = []
  }

  applySearch(searchText: SearchAutocomplete){
    this.resetShowedAssets()

    if(searchText.name == ''){      
      let obj = {
        SearchText: searchText.name
      }
      this.getTsAssetsHierarchy(obj)
    }
    if(searchText.type == Type.TransmissionStation){
      let obj = {
        SearchText: searchText.name
      }
      this.getTsAssetsHierarchy(obj)
    }
    if(searchText.type == Type.Feeder33KV){
      let obj = {
        SearchText: searchText.name
      }
      this.getF33AssetsHierarchy(new AssetHierarchy(), obj)
    }
    if(searchText.type == Type.Substation){
      let obj = {
        SearchText: searchText.name
      }
      this.getSsAssetsHierarchy(new AssetHierarchy(), obj)
    }
    if(searchText.type == Type.Feeder11KV){
      let obj = {
        SearchText: searchText.name
      }
      this.getF11AssetsHierarchy(new AssetHierarchy(), obj)
    }
    if(searchText.type == Type.Dt){
      let obj = {
        SearchText: searchText.name
      }
      this.getDtAssetsHierarchy(new AssetHierarchy(), obj)
    }
    if(searchText.type == Type.Account){
      let obj = {
        SearchText: searchText.name
      }
      this.getAccAssetsHierarchy(new AssetHierarchy(), obj)
    }
  }
}
