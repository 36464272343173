import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { Ami } from '../models/ami.model';

@Injectable({
  providedIn: 'root'
})
export class AmiService {

  private pathApi = this.config.setting['pathApi'] + "ami/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    } 
    getByMeterId(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    } 

    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    } 

    save(obj: Ami): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }

    getAll(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, obj);
    } 
    
    getAllForSelect(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSelect`);
    }
    
    uploadFile(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}uploadFile`, obj)
    }

    getTemplate(): Observable<any>{
      return this.http.get(`${this.pathApi}getTemplate`);
    }

    download(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}download`, obj);
    }
}
