<div><app-create-tarrif (parentComp)="reloadTable($event)"></app-create-tarrif></div>
<app-import-details-popup
(confirmUpload)="uploadFile()"
(confirmGetTemplate)="getTemplate()"
></app-import-details-popup>
<main class="">
    <div class="user-profile">

        <div class="user-settings-content">

            <div class="first">
                <div class="title-container">
                    <div class="title-u">
                        Tariff's Settings
                    </div>
                    <div class="subtitle">
                        Create, read, update or delete tariffs
                    </div>
                </div>
                <div class="btn">
                    <button class="red-btn" (click)="openUploadTab()">
                        Upload File
                    </button>

                    <button class="red-btn" (click)="openTab(0)">
                        Add New <img alt="+" src="assets/images-new-design/plus.png">
                    </button>
                </div>
            </div>
            <div class="header">
                <div class="search-wrapper">
                    <app-search-autocomplete
                    #search
                    [placeholder]="'Search'"
                    [searchText]="searchText"
                    [options]="options"
                    [width]="20"
                    (searchChange)="inputChanged($event)"
                    (searchAutocompleteChange)="getOptions($event)"
                ></app-search-autocomplete>
                </div>
                <div class="download-button">
                    <app-download-button-new
                      [isOnlyDownload]="true"
                      (downloadButtonClickedChange)="download()">
                    </app-download-button-new>
                  </div>
            </div>
            <table class="standard-table">
                <thead>
                    <tr>
                        <th class="checkbox-td checkbox-th"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" (change)="checkUncheckAll()"></th>
                        <th><span>Name</span></th>
                        <th><span>Rate</span></th>
                        <th class="actions-th"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tariffs" class="bodyRow clickable actions-click"
                        [ngClass]="{'selected': selectedTariff.id===item.id}">
                        <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" ></td>
                        <td><span>{{item.name | hasValue}} </span></td>
                        <td><span>{{item.rate | hasValue}}</span> </td>
                        <td>
                            <span class="settings-actions"><img class="" alt="Edit" src="assets/images-new-design/new_settings_pen.svg" data-toggle="modal"
                                    data-target="#createModal"
                                    *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_createupdate)"
                                    (click)="selectTariff(item.id)" /> </span>
                            <span class="settings-actions"><img class="" alt="Delete" src="assets/images-new-design/new_settings_delete.svg" data-toggle="modal"
                                    data-target="#deleteModal" (click)="openTabDelete(item.id)"
                                    *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_delete)" />
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-wrapper">
                <app-pagination [pageSize]="pageSize" [currentPage]="currentPage" [count]="count" (pageChange)="pageChange($event)">
                </app-pagination>
            </div>
        </div>
    </div>

</main>
<app-crud-delete-modal [selectedItem]="selectedTariff" (confirmDelete)="onDeleteConfirmed()"
    (cancelDelete)="resetSelectedTariff()">
</app-crud-delete-modal>
