<div [ngClass]="{'disabled': isInvalidAccount}">
     <div class="table-wrapper" *ngIf="data.length !== 0">
        <table>
            <thead>
                <td><span>DATE</span></td>
                <td><span>AMOUNT PAID</span></td>
                <td><span>CURRENT TOTAL DEBT OWED</span></td>
            </thead>
            <tbody>
                <tr *ngFor="let item of data">
                    <td><span>{{item.date|date : 'MMM dd, yyyy'}}</span></td>
                    <td><span>{{item.amountPaid|twoDecimals}}</span></td>
                    <td><span>{{item.outstandingDebt|twoDecimals}}</span></td>
                </tr>
                <tr>
                    <td colspan="3" class="pagination-wrapper">
                        <app-pagination [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
                    </td>
                </tr>
            </tbody>
        </table>
     </div>
</div>
