<div class="dropdown-container" id="filter-container" appClickOutside (clickOutside)="close()">
    <div tabindex="1" class="dropdown-btn" id="filter-inner" (click)="filterClick()"  >
        <div class="left-container">
            <span class="text" title="{{name}}">{{name}}</span>
        </div>
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99953 7.03843L0.345703 1.38463L1.39953 0.330803L5.99953 4.9308L10.5995 0.330803L11.6534 1.38463L5.99953 7.03843Z" fill="#2E3336"/>
        </svg>
    </div>
    <div class="dropbox-expanded" *ngIf="filterClicked" >
        <div class="dropdown-item-wrapper">
            <div class="dropdown-item" [ngClass]="{'selected': name === item?.name}" (mousedown)="changeData(item)" (click)="close()" click-stop-propagation
            *ngFor="let item of data" title="{{item.name}}">
            <span>{{item.name}}</span>
            <img alt="Check" *ngIf="name === item?.name" src="assets/images-new-design/check.png">
            </div>
        </div>
    </div>
</div>
