export class MapFilter {
    constructor(
        public SearchValue: string = "",
        
        public NorthEastLat: number = 0,
        public NorthEastLng: number = 0,
        public SouthWestLat: number = 0,
        public SouthWestLng: number = 0,
        public OrganizationTab: boolean = false,
        public Zoom: number = 0,
        public AccountConsumptionType:string='',
        public Unmetered:boolean = false,
        public Uts:any=null,
        public Bus:any=null,
        public Dts:any=null,
        public Ss:any=null,
        public Ts:any=null,
        public ShowOnlyDts: boolean = false,
        public ShowOnlySs: boolean = false,
        public ShowOnlyTs: boolean = false,
        public ShowOnlyUts: boolean = false,
        public ShowOnlyBu: boolean = false,

    ){}
}
