<main>
    <div class="my-container">
      <div class="page-title-wrapper">
        <span class="network-management-title">Dashboard</span>
       </div>
      <div class="horizontal-line"></div>

      <div class="dashboard-title">
        Meters Analysis
      </div>

      <div class="content-card">
        <app-tab-default [firstText]="'All'" [secondText]="'Prepaid'" [thirdText]="'Postpaid'" [paCss]="true" [womCss]="true"
        (tabChanged)="mainTabChange($event)"></app-tab-default>
        <div class="card-items-wrapper" *ngIf="mainTab==1">
          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Total Meters in the Network" [width]="'350px'" [data]="countOfPrepaidMeters+countOfPostpaidMeters"
                [isGreen]="true" [viewDetails]="false" [meterManagementDashboard]="true" [meterMakesData]="meterMakesData">
              </field-count-box-new>
            </div>
          </div>

          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Total Meters Online (Last 24 Hrs)" [width]="'350px'"
                [data]="countOfPrepaidMetersOnline+countOfPostpaidMetersOnline" [isYellow]="true" [viewDetails]="false" [meterManagementDashboard]="true" [meterMakesData]="meterMakesData">
              </field-count-box-new>
            </div>
          </div>

          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Total Meters Offline (Last 24 Hrs)" [width]="'350px'"
                [data]="countOfPrepaidMetersOffline+countOfPostpaidMetersOffline" [isBlue]="true" [viewDetails]="false" [meterManagementDashboard]="true" [meterMakesData]="meterMakesData">
              </field-count-box-new>
            </div>
          </div>

        </div>

        <div class="card-items-wrapper" *ngIf="mainTab==2">
          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Total Prepaid Meters in the Network" [width]="'350px'" [data]="countOfPrepaidMeters"
                [isGreen]="true" [viewDetails]="false" [meterManagementDashboard]="true" [meterMakesData]="meterMakesData">
              </field-count-box-new>
            </div>
          </div>

          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Meters Online in the Last 24 Hrs" [width]="'350px'"
                [data]="countOfPrepaidMetersOnline" [isYellow]="true" [viewDetails]="false" [meterManagementDashboard]="true" [meterMakesData]="meterMakesData">
              </field-count-box-new>
            </div>
          </div>

          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Meters Offline in the Last 24 Hrs" [width]="'350px'"
                [data]="countOfPrepaidMetersOffline" [isBlue]="true" [viewDetails]="false" [meterManagementDashboard]="true" [meterMakesData]="meterMakesData">
              </field-count-box-new>
            </div>
          </div>

          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Meters Vended in the Last 30 Days" [width]="'350px'"
                [data]="countOfPrepaidMetersVended" [isRed]="true" [viewDetails]="false" [meterManagementDashboard]="true" [meterMakesData]="meterMakesData">
              </field-count-box-new>
            </div>
          </div>

        </div>
        <div class="card-items-wrapper" *ngIf="mainTab==3">

          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Total Postpaid Meters in the Network" [width]="'350px'" [data]="countOfPostpaidMeters"
                [isGreen]="true" [viewDetails]="false" [meterManagementDashboard]="true" [meterMakesData]="meterMakesData">
              </field-count-box-new>
            </div>
          </div>

          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Communication in the Last 24 Hrs" [width]="'350px'" [isYellow]="true"
              [data]="countOfPostpaidMetersOnline" [viewDetails]="false" [meterManagementDashboard]="true" [meterMakesData]="meterMakesData">
              </field-count-box-new>
            </div>
          </div>

          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="No Communication in the Last 24 Hrs" [width]="'350px'" [isYellow]="true"
              [data]="countOfPostpaidMetersOffline"
                [viewDetails]="false" [meterManagementDashboard]="true" [meterMakesData]="meterMakesData">
              </field-count-box-new>
            </div>
          </div>
        </div>
      </div>


      <app-customer-stats
      [prepaidCustomers]="prepaidCustomers"
      [prepaidNMDCustomers]="prepaidNMDCustomers"
      [prepaidMDCustomers]="prepaidMDCustomers"
      [postpaidCustomers]="postpaidCustomers"
      [postpaidNMDCustomers]="postpaidNMDCustomers"
      [postpaidMDCustomers]="postpaidMDCustomers"
      [unmeteredCustomers]="unmeteredCustomers"
      [unmeteredNMDCustomers]="unmeteredNMDCustomers"
      [unmeteredMDCustomers]="unmeteredMDCustomers"
      [meterType]="mainTab"
      ></app-customer-stats>

      <app-meter-triggers
      ></app-meter-triggers>

      <app-dcu-cards
      [online]="online"
      [offline]="offline"
      ></app-dcu-cards>

      <app-meter-makes
      [graphData]="graphData"
      [MeterManufacturerLabels]="MeterManufacturerLabels"
      [MeterManufacturers]="MeterManufacturers"
      ></app-meter-makes>

      <div class="filters-container">

        <button class="btn-red" (click)="refreshData()">
          <label class="label-click clickable" type="button">Refresh</label>
          <img alt="" id="download-img" src="assets/images-new-design/ic_refresh.svg">
        </button>
      </div>
    </div>
  </main>
