import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-non-communicating-f33',
  templateUrl: './non-communicating-f33.component.html',
  styleUrl: './non-communicating-f33.component.scss'
})
export class NonCommunicatingF33Component implements OnInit, OnChanges {

  @Input() tsIds: number[] = []

  @Input() categoryId: number = -1
  public pageSize: number = 10
  public currentPage: number = 1
  public lastPageSize: number = 1;
  public count: number = 0

  public data: any[] = []

  public checkedList: any[] = []
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()

  public masterSelected: boolean = false;

  constructor(
    private translationService: TranslationService,
    private detectionChange: ChangeDetectorRef,
    private toastr: ToastrService,
    private f33Service: Feeder33Service
  ){}

  ngOnInit(): void {
    // this.GetData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    let reloadTable = false

    if (
      changes.tsIds !== undefined ||
      changes.f33Ids !== undefined ||
      changes.categoryId !== undefined ||
      changes.searchText !== undefined || changes.currentPage !== undefined
    ) {
      reloadTable = true;
    }
    if (this.searchText != null) {
      reloadTable = true
    }

    if (changes.menu !== undefined || changes.tabMenu !== undefined) {
      reloadTable = true;
      this.resetFilters();
    }

    if (reloadTable) {
      this.pageChange(1)
    }
  }


  resetFilters() {
    this.categoryId = 0;
    this.tsIds = []

    this.detectionChange.detectChanges()
  }

  public pageChange(page: number) {
    this.currentPage = page;
    this.GetData()
  }

  isAlreadyChecked() {
    this.data.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
      this.checkForDownload(this.data[i].id, true);
    }
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].checked)
        this.checkedList.push(this.data[i]);
    }
  }

  isAllSelected() {
    this.masterSelected = this.data.every(function (item: any) {
      return item.checked == true;
    })
    this.getCheckedItemList();
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item:any) {
      return item.checked == true;
    })
  }

  download() {
    let obj = {
      pageInfo: {
        page: 0,
        pageSize: this.pageSize
      },
      filterParams: {
        TsIds: this.tsIds,
        Search: this.searchText.name,
        outageIds: this.checkedList,
      }
    };

    this.f33Service.downloadNonCommunicatingReport(obj).subscribe(response => {
      if(response.status === 200){
        window.location.href = response.data;
      }else if(response.data===null){
        this.toastr.info(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  public GetData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        TsIds: this.tsIds,
        Search: this.searchText.name,
      }
    };

    this.getNonComunicatingF33(obj);
  }

  private getNonComunicatingF33(obj: any) {
    this.f33Service.getNonCommunicatingF33(obj).subscribe(response => {
      if (response.status == 200) {
        this.data = response.data.data
        this.isAlreadyChecked()
        if (obj.pageInfo.page == 1)
          this.count = response.data.count
        if (this.count == 0)
          this.count++;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An error occurred")
    });
  }

}
