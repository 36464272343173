import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public pathApi = this.config.setting['pathApi'];
  public pathApiCommon = this.config.setting['pathApi'] + "Common/";

  constructor(private http: HttpClient, private config: AppConfig) { }

  replaceAll(data: string, searchText: string, replaceText: string): string {
    while (data.includes(searchText)) {
      data = data.replace(searchText, replaceText);
    }
    return data;
  }

  combinePaths(path1: string, path2: string): string {
    path1 = this.replaceAll(path1, '\\', '/');
    path2 = this.replaceAll(path2, '\\', '/');
    if (path1[path1.length - 1] === '/' && path2[0] === '/') path1 = path1.slice(0, -1);

    return path1 + path2;
  }

  getIntegrationTypes(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}GetJobIntegrationTypes`)
  }

  getAllFeederTypes(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}getAllFeederTypes`)
  }

  getAllJobStatusTypes(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}GetAllJobStatusTypes`)
  }

  getAllPredictiveAnalyticsAlerts(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}GetAllPredictiveAnalyticsAlerts`)
  }

  getAllDesignations(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}GetDesignationsForSelect`)
  }

  getAllTaskCategories(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}GetTaskCategoriesForSelect`)
  }

  getAllAssetTypes(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}getAssetTypesForSelect`)
  }

  getAllPriorities(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}getAllPrioritiesForSelect`)
  }

  getAllWorkOrderStatuses(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}getAllWorkOrderStatuses`)
  }

  getAllItemCategories(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}getAllItemCategories`)
  }

  getAllStockItems(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}getAllStockItemsForSelect`)
  }

  getAllTasks(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}getAllTasksForSelect`)
  }

  getAssetsHierarhy(): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}GetSystemHierarchy`)
  }

  isNumber(value: any){
    let retVal: boolean = false;
    retVal = /^\d+$/.test(value);
    return retVal
  }

  isEmail(search:string):boolean{
      let retVal: boolean = false;
      let regexp  = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      retVal = regexp.test(search);
      return retVal
  }

  isLettersOnly(input: string): boolean {
    const regex = new RegExp(/^[a-zA-Z\s]+$/)
    return regex.test(input)
  }

  isPhoneNumber(search: string): boolean{
      let retVal: boolean = false;
      let regexp  = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im);
      retVal = regexp.test(search);
      return retVal
  }
}
