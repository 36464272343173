import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AttachmentsPopupComponent } from 'src/app/components/common-new-design/attachments-popup/attachments-popup.component';
import { PopupEnum } from 'src/app/models/popupEnum';
import { ItemCategories, StockItem } from 'src/app/models/stock-item.model';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';
import { InventoryManagementService } from 'src/app/services/inventory-management.service';

@Component({
  selector: 'app-stock-item-details',
  templateUrl: './stock-item-details.component.html',
  styleUrls: ['./stock-item-details.component.scss']
})
export class StockItemDetailsComponent implements OnChanges {

  @Output() changeView: EventEmitter<any> = new EventEmitter()
  @Output() setItemId: EventEmitter<any> = new EventEmitter()
  @Output() reloadTable = new EventEmitter<any>();

  @ViewChild(AttachmentsPopupComponent) attachmentsPopupRef!: AttachmentsPopupComponent

  @Input() item = new StockItem()
  @Input() itemId = 0
  public showItem: StockItem = new StockItem()
  public itemIds: number[] = []

  public itemCategory = ItemCategories

  public isReadMore: boolean = false

  constructor(private toastr: ToastrService,
    private inventoryService: InventoryManagementService,) { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.item != null && changes.item.currentValue != undefined){
      this.showItem = changes.item.currentValue
      // this.setStartValues()
    }
  }

  copyToClipboard(text: any) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }

  viewAttachments(){
    this.attachmentsPopupRef.changeState(PopupEnum.Open)
  }

  back(){
    this.changeView.emit(ViewsEnum.View)
  }

  openEdit(){
    this.changeView.emit(ViewsEnum.Edit)
  }

  deleteItem(){
    this.itemIds = []
    this.itemIds.push(this.showItem.id)
    let obj = {
      ItemIds: this.itemIds
    };
    this.inventoryService.deleteStockItems(obj).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message)
        this.handleDeleteItem();
        this.changeView.emit(ViewsEnum.View)
      } else {
        this.toastr.error(response.message);
      }
    })
  }

  handleDeleteItem(){
    this.reloadTable.emit();
  }
}
