<main class="">
    <div class="user-profile">

        <div class="container-wrapper">
            <div class="auditheader">
                <div class="title-container">
                    <div class="title-u">
                        Audit Log History
                    </div>
                    <div class="subtitle">
                        Read audit logs
                    </div>
                </div>
            </div>

            <div class="filters-container">
                <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                    <app-multi-filter *ngIf="filterApplied"
                        [data]="allFilters"
                        [(showFilter)]="showFilter"
                        (dropdownChange)="dropdownSelected($event)"
                    >
                    </app-multi-filter>
        
                    <app-range-datepicker
                        [fromDate]="dateFrom" 
                        [toDate]="dateTo"
                        [name]="'Period'"
                        [maxDate]="maxDate"
                        (dateRangeSelected)="updateDate($event)">
                    </app-range-datepicker>

                    <app-search-autocomplete
                        #search
                        [width]="30"
                        [placeholder]="searchPlaceholder"
                        [searchText]="searchText"
                        [options]="options"
                        (searchChange)="searchChange($event)"
                        (searchAutocompleteChange)="getOptions($event)"
                    ></app-search-autocomplete>
                </div>

                <div class="download-button">
                    <app-download-button-new
                      [isOnlyDownload]="true"
                      (downloadButtonClickedChange)="download()">
                    </app-download-button-new>   
                </div>

            </div>
        
        <table class="standard-table">
            <thead>
                <tr>
                    <th><span><input class="input-checkmark" type="checkbox" name="list_name" value="h1" [(ngModel)]="masterSelected" (change)="checkUncheckAll()" /></span></th>
                    <th class="large-td"><span title="Username">USERNAME</span></th>
                    <th><span title="Email Address">EMAIL ADDRESS</span></th>
                    <th><span title="Page">PAGE</span></th>
                    <th><span title="Action Time">ACTION</span></th>
                    <th><span title="IP Address">IP ADDRESS</span></th>
                    <th><span title="Action Time">ACTION TIME</span></th>
                    <th><span title="Duration on page">DURATION ON PAGE</span></th>
                </tr>
            </thead>
            <tbody class="table-body">
                <tr *ngFor="let item of auditLogs" class="bodyRow clickable actions-click">
                    <td (click)="false; $event.stopPropagation();"><span> <input class="input-checkmark" type="checkbox" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" /></span> </td>
                    <td>{{(item.username) | hasValue}}</td>
                    <td>{{(item.email) | hasValue}}</td>
                    <td>{{(item.page) | hasValue}}</td>
                    <td>{{(item.action) | hasValue}}</td>
                    <td>{{(item.ipAddress) | hasValue}}</td>
                    <td>{{item.time | dateTimeNoConvert}} </td>
                    <td>{{item.durationOnPage | dateTimeNoConvert :'shortTime'}} </td>
                </tr>
            </tbody>
        </table>

        <div class="pagination-wrapper">
            <app-pagination [pageSize]="pageSize" [currentPage]="currentPage" [count]="count" (pageChange)="pageChange($event)">
            </app-pagination>
        </div>
        </div>
    </div>

</main>