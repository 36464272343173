export class FOF11FlaggingValues{
    maxAllowedLoad : number = 0
    minAmbTemp : number = 0
    maxAmbTemp : number = 0
    normalVoltage:number = 0
    normalCurrent : number = 0
    nameplateFlag : number = 0
    normalFactor : number = 0
    currentFlag : number = 0
    factorFlag : number = 0
    loadFlag : number = 0
    ambTempFlag : number = 0
    voltageFlag : number = 0 
    normalAmbTemp: number = 0 
    minVoltage : number = 0
    maxVoltage : number = 0
    minFactor : number = 0
    maxFactor : number = 0 
}