import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { F33ViewMore } from 'src/app/models/f33ViewMore.model';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { TranslationService } from 'src/app/services/translation.service';
import { SearchAutocomplete } from '../../../models/searchAutocomplete.model';
import { TimePeriod } from '../../consumption/model/model';
import { F33PopupComponent } from './f33-popup/f33-popup.component';

@Component({
  selector: 'app-gep-feeder33-new',
  templateUrl: './gep-feeder33-new.component.html',
  styleUrls: ['./gep-feeder33-new.component.scss', '../grid-energy-profile-new.component.scss']
})
export class GepFeeder33NewComponent implements OnChanges {
  public isViewMore: boolean = false;

  public count: number = 0
  public pageSize: number = 8
  public currentPage: number = 1
  public currentFeederId: number = 1
  public commStatus: number = 0
  public commStatusOpened: boolean = false
  public readType: number = 0
  public readTypeOpened: boolean = false
  public currentPageDts: number = 1;
  public dtsCount: number = 0;
  public dtsPageSize: number = 4;
  public lastPageSize: number = 1;
  public dtName: string = this.translationService.getByKeyFromCache('DT');
  @ViewChild(F33PopupComponent) popupRef!: F33PopupComponent;

  @Input() tsIds: number[] = []
  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''
  @Input() search: SearchAutocomplete = new SearchAutocomplete()
  @Input() timePeriod: TimePeriod = TimePeriod.Monthly;

  public masterSelected: boolean = false;

  feeder33Id: any = [];
  public selectedF33: any = [];
  public checkedList: any = [];
  dataFeeder: any = []
  public data: F33ViewMore[] = [];
  currentFeeder33: any = []
  public searchViewMore: any = null;

  public bandNumber: number = 1;

  allFeeders: number = 0;
  bandACount: number = 0;
  bandBCount: number = 0;
  bandCCount: number = 0;
  bandDCount: number = 0;
  bandECount: number = 0;

  constructor(
    private feeder33Service: Feeder33Service,
    private toastr: ToastrService,
    private translationService: TranslationService,
  ) { }
  pageChangeDts(value: any) {
    this.currentPageDts = value;
    this.selectF33();
  }

  viewMore(feeder: any) {
    this.isViewMore = true
    this.currentFeeder33 = feeder;
    this.currentFeederId = feeder.id;

    this.searchViewMore = '';
    this.isViewMore = true;
    this.selectF33(feeder);

    this.selectedF33 = this.data.find(x => x.feederNumber == feeder.feederNumber)
    this.openTab(this.selectedF33)
  }
  close() {
    this.isViewMore = false;
  }
  selectF33(feeder?: any, page: any = null) {
    this.isViewMore = true

    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.selectF33Monthly()
    }
    else {
      this.selectF33Daily()
    }
  }
  selectF33Monthly() {
    let obj = {
      pageInfo: {
        page: this.currentPageDts,
        pageSize: this.dtsPageSize,
        isMonthlySelected: this.timePeriod == TimePeriod.Monthly
      },
      filterParams: {
        Id: this.currentFeederId,
        From: this.dateFrom != '' ? transformToString(this.dateFrom) : null,
        To: this.dateTo != '' ? transformToString(this.dateTo) : null,
        Search: this.searchViewMore
      }
    }; this.feeder33Service.getFeederViewMore(obj).subscribe(response => {
      if (response.status === 200) {
        this.selectedF33 = response.data.data;
        this.dtsCount = response.data.count;
        this.lastPageSize = Math.ceil(this.dtsCount / this.dtsPageSize)

      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  };

  openTab(selectedF33:any){
    this.popupRef.changeState(1, selectedF33);
  }
  closeTab(){
    this.popupRef.changeState(1, this.selectedF33);
  }
  selectF33Daily() {
    let obj = {
      pageInfo: {
        page: this.currentPageDts,
        pageSize: this.dtsPageSize,
        isMonthlySelected: this.timePeriod == TimePeriod.Daily
      },
      filterParams: {
        Id: this.currentFeederId,
        From: this.dateFrom != '' ? transformToString(this.dateFrom) : null,
        To: this.dateTo != '' ? transformToString(this.dateTo) : null,
        Search: this.searchViewMore
      }
    }; this.feeder33Service.getFeederViewMore(obj).subscribe(response => {
      if (response.status === 200) {
        this.selectedF33 = response.data.data;
        this.dtsCount = response.data.count;
        this.lastPageSize = Math.ceil(this.dtsCount / this.dtsPageSize)

      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  };

  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false
    if (changes.tsIds != null) {
      reloadTable = true
    }
    if (changes.dateFrom != null || changes.dateTo != null) {
      reloadTable = true
    }
    if (this.search?.name == '')
      this.search = changes.search?.previousValue

    if (changes.search != null) {
      reloadTable = true
    }

    if (reloadTable) {
      if (this.dateFrom == '' || this.dateTo == '')
        this.setMainDate()

      this.resetPagination()
      this.checkedList = [];
      this.GetData()
    }
  }

  private resetPagination() {
    this.currentPage = 1
  }

  private setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()

    dteFrom.setHours(2,0,0);
    dte.setHours(2,0,0);
    dteFrom.setMonth(dteFrom.getMonth() - 1)

    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: 1};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
  }

  pageChange(value: number) {
    this.currentPage = value
    this.GetData()
  }

  public GetData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        TsIds: this.tsIds,
        Search: this.search ? this.search.name : this.search,
        CommStatus: this.commStatus,
        ReadType: this.readType,
        Band: this.bandNumber
      }
    };

    // this.data = [];
    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.getMonthlyBandsCount()
      this.fetchMonthly33KvFeeders(obj)
    }
    else {
      this.getDailyBandsCount()
      this.fetchDaily33KvFeeders(obj);
    }
    this.getCommunicatingAssetsCounts();
  }

  getMonthlyBandsCount() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      TsIds: this.tsIds,
      Search: this.search ? this.search.name : this.search,
      CommStatus: this.commStatus,
      ReadType: this.readType,
      Band: this.bandNumber
    };
    this.feeder33Service.getMonthlyBandsCount(obj).subscribe(response => {
      if(response.status === 200) {
        this.bandACount = response.data.bandACount;
        this.bandBCount = response.data.bandBCount;
        this.bandCCount = response.data.bandCCount;
        this.bandDCount = response.data.bandDCount;
        this.bandECount = response.data.bandECount;
        this.allFeeders = this.bandACount + this.bandBCount + this.bandCCount + this.bandDCount + this.bandECount
      } else {
        this.toastr.error(response.message);
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

  public communicatingAssetsCounter : number = 0;
  public nonCommunicatingAssetsCounter : number = 0;

  getCommunicatingAssetsCounts(){
    let obj = {
      TsIds: this.tsIds,
      Search: this.search ? this.search.name : this.search,
      CommStatus: this.commStatus,
      ReadType: this.readType,
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
    };
    this.feeder33Service.getCommunicatingAssetsCounts(obj).subscribe({
      next: (response) =>{
        if(response && response.status == 200){
          this.communicatingAssetsCounter = response.data?.comunicatingAssetsCount ?? 0;
          this.nonCommunicatingAssetsCounter = response.data?.nonComunicatingAssetsCount ?? 0;
        }
      },
      error: (err) =>{
        this.toastr.error('An error occurred.');
      },
    });
  }

  getDailyBandsCount() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      TsIds: this.tsIds,
      Search: this.search ? this.search.name : this.search,
      CommStatus: this.commStatus,
      ReadType: this.readType,
      Band: this.bandNumber
    };
    this.feeder33Service.getDailyBandsCount(obj).subscribe(response => {
      if(response.status === 200) {
        this.bandACount = response.data.bandACount;
        this.bandBCount = response.data.bandBCount;
        this.bandCCount = response.data.bandCCount;
        this.bandDCount = response.data.bandDCount;
        this.bandECount = response.data.bandECount;
        this.allFeeders = this.bandACount + this.bandBCount + this.bandCCount + this.bandDCount + this.bandECount
      } else {
        this.toastr.error(response.message);
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

  fetchDaily33KvFeeders(obj: any) {
    this.feeder33Service.getAllDaily33KvFeeders(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data;
        this.isAlreadyChecked();
        if(obj.pageInfo.page == 1){
          this.count = response.data.count;
          this.lastPageSize = Math.ceil(this.count / this.pageSize)
        }

      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  fetchMonthly33KvFeeders(obj: any) {
    this.feeder33Service.getAllMonthly33KvFeeders(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data;
        this.isAlreadyChecked();
        if(obj.pageInfo.page == 1) {
          this.count = response.data.count;
          this.lastPageSize = Math.ceil(this.count / this.pageSize)
        }
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  public commStatusClicked(open: number) {
    if (open == 0)
      this.commStatusOpened = false
    else {
      if (this.commStatusOpened) {
        this.commStatusOpened = false
      }
      else {
        this.commStatusOpened = true
      }

    }
  }

  public readTypeClicked(open: number) {
    if (open == 0)
      this.readTypeOpened = false
    else {
      if (this.readTypeOpened) {
        this.readTypeOpened = false
      }
      else {
        this.readTypeOpened = true
      }

    }
  }

  public commStatusSelected(value: number) {
    this.currentPage = 1
    this.commStatus = value
    this.GetData()
  }

  public readTypeSelected(value: number) {
    this.currentPage = 1
    this.readType = value
    this.GetData()
  }

  public download() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      TsIds: this.tsIds,
      Search: this.search ? this.search.name : this.search,
      CommStatus: this.commStatus,
      ReadType: this.readType,
      CheckedList: this.checkedList,
      Band: this.bandNumber
    };

    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.downloadMonthlyReport(obj)
    }
    else {
      this.downloadDailyReport(obj);
    }
  }

  private downloadMonthlyReport(obj: any) {
    this.feeder33Service.downloadMonthly33KvFeedersReport(obj).subscribe(response => {
      window.location.href = response.data;

    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  private downloadDailyReport(obj: any) {
    this.feeder33Service.downloadDaily33KvFeedersReport(obj).subscribe(response => {
      window.location.href = response.data;

    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
      this.checkForDownload(this.data[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.data.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item:any) {
      return item.checked;
    })
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }
  bandChanged(value: number) {
    this.bandNumber = value;
    this.currentPage = 1
    this.GetData();
  }
}
