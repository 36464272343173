<div class="nm-filter-wrapper">
    <app-tab-default [firstText]="'33KV Feeders'" [secondText]="'11KV Feeders'" [paCss]="true" [womCss]="true"
        (tabChanged)="tabChanged($event)" [tabSelected]="activeTab">
    </app-tab-default>
</div>
<app-feeder11-tab #feeders11TabComponent *ngIf="activeTab === 2" (editTab)="edit($event)"
    (amisSelect)="amisForSelect($event)" (f33Select)="f33sForSelect($event)" (busSelect)="busForSelect($event)"
    (subSelect)="subsForSelect($event)" (deleteComp)="delete()">
</app-feeder11-tab>
<app-feeder33-tab #feeders33TabComponent *ngIf="activeTab === 1" (editTab)="edit($event)" (tsSelect)="tsForSelect($event)"
    (amisSelect)="amisForSelect($event)" (deleteComp)="delete()">
</app-feeder33-tab>