<div class="block-details">
    <div class="details-title">
        <span class="title-first-part">ADORA </span> <span class="title-second-part">- Your Key to Efficient Grid Management</span> 
    </div>
    <div class="details-text">
        <ul class="details-list">
            <li class="details">
                <span class="mini-title">Real-Time Data Monitoring:</span> Track energy usage in real time with detailed metrics and
                visualizations.
            </li>
            <li class="details">
                <span class="mini-title">Historical Trends Analysis:</span> Analyze patterns and identify opportunities for energy optimization
                based on
                historical consumption data.
            </li>
            <li class="details">
                <span class="mini-title">Equipment Performance Monitoring:</span> Identify energy-intensive appliances and monitor their
                performance for
                potential upgrades or replacements.
            </li>
            <li class="details">
                <span class="mini-title">Sustainability Awareness:</span> Gain insights into your environmental impact and discover ways to
                promote
                sustainability through energy conservation.
            </li>
        </ul>
    </div>
</div>