<div>
  <app-configuration-settings-create></app-configuration-settings-create>
</div>
<main>
  <div class="user-profile">
    <div class="user-settings-content">
      <div class="title-and-desc">
        <div class="first">
          <div class="title-u">Configuration</div>
          <div class="config-description">Read or update Configurations</div>
        </div>
        <div class="second">
          <div class="btn">
            <button class="config-btn" (click)="openTab()">
              Edit Configuration
            </button>
          </div>
        </div>
      </div>

      <div class="config-data">
        <div
          *ngFor="let item of configBills"
          class="config-item clickable actions-click"
        >
          <div class="config">
            <div class="config-info">
              <div class="config-name">
                <span>{{ item.name | hasValue }}</span>
              </div>
            </div>
            <div class="config-value config-light">
              <span *ngIf="item.key === 'SMSFlag'" class="value-span">
                {{ item.value.split(":")[1] }}</span
              >
              <span *ngIf="item.key != 'SMSFlag'" class="value-span">
                <span class="value-text">Value</span>

                {{ item.value | number }}</span
              >
            </div>
          </div>
        </div>
        <div class="edit-config-row" (click)="openTab()">
            <span class="edit-config-span"
              ><svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.09618 16.9638C0.778248 17.0343 0.504532 16.9548 0.275032 16.7253C0.0455486 16.4958 -0.0339349 16.2221 0.0365817 15.9042L0.873107 11.8889L5.11151 16.1273L1.09618 16.9638ZM5.11151 16.1273L0.873107 11.8889L12.0923 0.669643C12.4372 0.324776 12.8641 0.152344 13.3731 0.152344C13.882 0.152344 14.309 0.324776 14.6538 0.669643L16.3307 2.34654C16.6756 2.69141 16.848 3.11833 16.848 3.62729C16.848 4.13626 16.6756 4.56318 16.3307 4.90804L5.11151 16.1273ZM13.1615 1.72347L2.93653 11.9388L5.06153 14.0638L15.2769 3.83884C15.3346 3.78114 15.3635 3.70903 15.3635 3.62249C15.3635 3.53594 15.3346 3.46382 15.2769 3.40612L13.5943 1.72347C13.5366 1.66577 13.4644 1.63692 13.3779 1.63692C13.2913 1.63692 13.2192 1.66577 13.1615 1.72347Z"
                fill="#1C1B1F"
              />
            </svg>
              <span class="config-light"
                >Edit config</span
              ></span
            >
          </div>
      </div>
    </div>
  </div>
</main>
