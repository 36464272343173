<div class="tariff-history-container">
    <div class="tariff-body">
        <div class="tariff-history-header">
            <div class="history-header">History</div>
            
            <div class="filters-container">
                <div class="multi-filter-wrapper">

                    <app-range-datepicker
                        [fromDate]="dateFrom" 
                        [toDate]="dateTo"
                        [name]="'Period'"
                        (dateRangeSelected)="dateChange($event)"
                    ></app-range-datepicker>

                    <app-search-autocomplete
                        #search
                        [placeholder]="searchPlaceholder"
                        [searchText]="searchText"
                        [options]="options"        
                        (searchChange)="searchChange($event)"
                        (searchAutocompleteChange)="getOptions($event)"
                    ></app-search-autocomplete>
                    
                </div>
            </div>
            
        </div>


        <div class="gep-table-wrapper">
            <div class="table-wrapper">
                <table class="standard-table">
                    <thead>
                        <th><span title="Tariff">TARIFF</span></th>
                        <th><span title="Rate">RATE</span></th>
                        <th><span title="Updated By">UPDATED BY</span></th>
                        <th><span title="Active Period">ACTIVE PERIOD</span></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of previousTariffs">
                            <td class="large-td">
                                <span>{{item.name}}</span>
                            </td>
                            <td class="large-td">
                                <span>{{item.rate}}</span>
                            </td>
                            <td class="large-td">
                                <span>{{item.updatedBy}}</span>
                            </td>
                            <td *ngIf="item.endDate === null && item.startDate !== null" class="till-date large-td" >
                                <span>{{item.startDate | date}} - Till Date</span>
                            </td>
                            <td *ngIf="item.endDate !== null && item.startDate !== null" class="large-td">
                                <span>{{item.startDate | date}} - {{item.endDate | date}}</span>
                            </td>
                            <td *ngIf="item.endDate !== null && item.startDate === null" class="large-td">
                                <span>Unknown - {{item.endDate | date}}</span>
                            </td>
                            <td *ngIf="item.endDate === null && item.startDate === null" class="till-date large-td">
                                <span>Unknown - Till Date</span>
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>
        </div>
        <div class="pagination-wrapper">
            <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
        </div>                  
    </div>
</div>