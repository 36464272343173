import { FunctionalGroup } from "./functional-group-enum";
import { TacticalRole } from "./tactical-role-enum";

export class StaffOperator {
    id: number = 0;
    firstName: string = '';
    lastName: string = '';
    phoneNumber: string = '';
    email: string = '';
    functionalGroup: FunctionalGroup = FunctionalGroup.Undefined;
    functionalGroupString: string = '';
    departmentId: number = 0;
    departmentName: string = '';
    unitId: number = 0;
    unitName: string = '';
    subunitId: number = 0;
    subunitName: string = '';
    tacticalRole: TacticalRole = TacticalRole.Undefined;
    tacticalRoleString: string = '';
    assetNames: string[] = [];
    checked: boolean = false;
}
