import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-graph-table-button',
  templateUrl: './graph-table-button.component.html',
  styleUrls: ['./graph-table-button.component.scss']
})
export class GraphTableButtonComponent{

  @Input() isGraph: boolean = true

  @Output() filterClicked: any = new EventEmitter()

  public buttonClick(value: boolean){
    this.isGraph = value
    this.filterClicked.emit(this.isGraph)
  }
}
