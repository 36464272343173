export class Feeder33{
    id: number = 0;
    name: string = '';
    tsName: string = '';
    tsId: number = 0;
    feeder33No: string = '';
    nameplateRating: string = '';
    amiId: number = 0;
    amiName: string = '';
    meterNumber: string = '';
    meterType: number = 0;
    checked: boolean = false;
    meterVoltage: number = 0;
    meterMaxDigit?: number;
    multiplierFactor?: number;
}
