import { AdditionalInfo } from "./additionalInfo.model";

export class MapFilterDataOut {
    constructor(
        public id: number = 0,
        public latitude: number = 0,
        public longitude: number = 0,
        public additionalInfo: AdditionalInfo[],
        public icon:string="",
        public count: number = 0,
        public type:MapEntityType = 0
    ){}
}


export enum MapEntityType
{   UN = 0,
    TS = 1,
    SS = 2,
    DT = 3,
    BU = 4,
    UT = 5,
    ACC = 6,
}