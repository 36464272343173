<app-asset-performance-f33-popup></app-asset-performance-f33-popup>
<div class="gep-table-wrapper">
  <div class="table-wrapper">
    <table class="standard-table gep-table">
      <thead>
        <th><span><input class="input-checkmark" type="checkbox" name="list_name" value="h1" [(ngModel)]="masterSelected" (change)="checkUncheckAll()" /></span></th>
        <th><span>FEEDER 33 NAME</span></th>
        <th><span>FEEDER 33 NUMBER</span></th>
        <th><span>FEEDER 33 TYPE</span></th>
        <th><span>TRANSMISSION STATION</span></th>
        <th><span>ENERGY RECEIVED<br />(MWH)</span></th>
        <th><span>NAMEPLATE RATING</span></th>
        <th><span>AVG DAILY VOLTAGE</span></th>
        <th><span>AVG DAILY LOAD</span></th>
        <th><span></span></th>
      </thead>

      <tbody>
        <tr *ngFor="let item of assetPerformanceF33" class="clickable actions-click"  (click)="selectAssetPerformanceF33(item.feederName)">
          <td (click)="false; $event.stopPropagation();"><span> <input class="input-checkmark" type="checkbox" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" /></span> </td>
          <td class="td-pom"><span>{{item.feederName | hasValue}}</span></td>
          <td class="td-pom2"><span>{{item.feeder33No| hasValue}}</span></td>
          <td *ngIf="item.isPublic == 'True'">
            <span class="public"><img alt="" class="icon" src="assets/images-new-design/globe.png">Public</span>
          </td>
          <td *ngIf="item.isPublic == 'False'">
            <span class="private"><img alt="" class="icon" src="assets/images-new-design/lock.png">Private</span>
          </td>
          <td><span>{{item.tsName | hasValue}}</span></td>
          <td *ngIf="item.energyReceived"><span>{{item.energyReceived | number: "1.0-3" | hasValue}}</span></td>
          <td *ngIf="!item.energyReceived"><span class="NaN">N\A</span></td>
          <td *ngIf="item.nameplateRating"><span>{{item.nameplateRating | number: "1.0-3" | hasValue}}</span></td>
          <td *ngIf="!item.nameplateRating"><span class="NaN">N\A</span></td>
          <td *ngIf="item.avgDailyVoltage"><span>{{item.avgDailyVoltage | number: "1.0-3" | hasValue}}</span></td>
          <td *ngIf="!item.avgDailyVoltage"><span class="NaN">N\A</span></td>
          <td *ngIf="item.avgDailyLoad"><span>{{item.avgDailyLoad | number: "1.0-3" | hasValue}}</span></td>
          <td *ngIf="!item.avgDailyLoad"><span class="NaN">N\A</span></td>
          <td><span><img alt="..." src="assets/images/3-dot.svg"></span></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
