<div class="main-wrapper" tabindex="1" appClickOutside (clickOutside)="isSelected = false">
  <div class="simple-wrapper" (click)="isSelected = !isSelected">
    <div class="simple-header-closed">
      <span>{{header}}</span>
      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.99953 7.03844L0.345703 1.38464L1.39953 0.330811L5.99953 4.93081L10.5995 0.330811L11.6534 1.38464L5.99953 7.03844Z" fill="#2E3336"/>
      </svg>
    </div>
  </div>
  <div class="simple-wrapper selected-wrapper" *ngIf="isSelected">
    <div class="simple-header" (click)="isSelected = false">
      <span>{{dropdownHeader}}</span>
      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.99953 7.03844L0.345703 1.38464L1.39953 0.330811L5.99953 4.93081L10.5995 0.330811L11.6534 1.38464L5.99953 7.03844Z" fill="#2E3336"/>
      </svg>
    </div>
    <div class="simple-search" tabindex="1">
      <div class="search-btn">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.99452 12.5C8.51206 12.5 9.80903 11.9669 10.8854 10.9007C11.9618 9.8345 12.5 8.53609 12.5 7.00548C12.5 5.48794 11.9631 4.19097 10.8893 3.11458C9.81547 2.03819 8.51904 1.5 7 1.5C5.46786 1.5 4.16816 2.0369 3.1009 3.11071C2.03363 4.18453 1.5 5.48096 1.5 7C1.5 8.53214 2.0331 9.83184 3.09929 10.8991C4.1655 11.9664 5.46391 12.5 6.99452 12.5ZM7 14C6.02913 14 5.11974 13.816 4.27185 13.4479C3.42395 13.0799 2.68056 12.5799 2.04167 11.9479C1.40278 11.316 0.902778 10.576 0.541667 9.72802C0.180556 8.88002 0 7.97053 0 6.99956C0 6.04152 0.184028 5.13542 0.552083 4.28125C0.920139 3.42708 1.41864 2.68387 2.04758 2.0516C2.67653 1.41935 3.41569 0.919354 4.26508 0.551604C5.11449 0.183868 6.02548 0 6.99806 0C7.95769 0 8.86458 0.184028 9.71875 0.552083C10.5729 0.920139 11.316 1.42014 11.9479 2.05208C12.5799 2.68403 13.0799 3.42612 13.4479 4.27835C13.816 5.13058 14 6.0381 14 7.00094C14 7.83365 13.8615 8.62125 13.5846 9.36375C13.3078 10.1062 12.9254 10.7836 12.4375 11.3958L16 14.9583L14.9375 16L11.375 12.4583C10.7668 12.9459 10.0939 13.3247 9.35638 13.5948C8.61879 13.8649 7.83333 14 7 14Z" fill="#727278"/>
        </svg>
      </div>
      <input type="text" [(ngModel)]="search" (ngModelChange)="searchChanged()" placeholder="Search"/>
    </div>
    <div class="simple-dropdown" *ngIf="isAgents === true">
      <div class="simple-item" *ngFor="let item of agents" (click)="selectAgent(item)" [ngClass]="{'selected': item.id == selectedAgentId}">
        <img alt="" *ngIf="item.image != null" [src]="item.image"/>
        <img alt="" *ngIf="item.image == null" [src]="defaultImage"/>
        <span>{{item.userName}}</span>
      </div>
    </div>
    <div class="simple-dropdown" *ngIf="isAgents === false">
      <div class="simple-item" *ngFor="let item of teams" (click)="selectTeam(item)" [ngClass]="{'selected': item.id == selectedTeamId}">
        <img alt="" *ngIf="item.image != null" [src]="item.image"/>
        <img alt="" *ngIf="item.image == null" [src]="defaultImage"/>
        <span>{{item.name}}</span>
      </div>
    </div>
  </div>

</div>
