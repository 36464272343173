<div><app-support-form></app-support-form></div>
<div class="login-container">
    <div class="row login-card">
        <div class="col-sm-12 logo-img"><img src="assets/images-new-design/AEDC-logo.png" alt="">
        </div>
        <ng-template [ngIf]="!resetPasswordForm">
            <div class="col-sm-12 login-form">
                <div class="login-title">Log in.</div>
                <div class="login-text">
                    Log in with your username and password.
                </div>
                <form class="col-sm-12" ngNativeValidate (submit)="login()" >
                    <div class="form-group index-search-form">
                        <input name="email" type="email" class="form-control input-w-button input-email"
                        placeholder="Email Address" autocomplete="off" [(ngModel)]="email" #Email="ngModel" pattern="^[a-zA-Z0-9_.+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+$" required>
                        <button class="input-button check-icon" type="button" disabled>
                            <img alt="" *ngIf="Email.errors?.pattern && Email.touched" src="assets/images-new-design/login-x-icon.svg" style="width: 50%;">
                            <img alt="" *ngIf="!Email.errors?.pattern && Email.touched && Email.value" src="assets/images-new-design/login-check.svg" style="width: 50%;">
                        </button>
                    </div>
                    <div class="form-group index-search-form">
                        <input name="password" [type]="inputType" class="form-control input-w-button"
                            placeholder="Password" [(ngModel)]="pass" required>
                        <button class="input-button" type="button" disabled><img alt="Show Password" [src]="eyeImage" (click)="changeEye()"
                                style="width: 75%;"></button>
                    </div>
                    <div class="col-sm-12 link-container">
                        <a class="forgot-link" (click)="resetPasswordForm = true;">Forgot password?</a>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary regular-btn login-button" [disabled]="Email.errors?.pattern || !Email.value">
                            <span class="button-text" *ngIf="!isLogClicked">Log in</span>
                            <img src="assets/images-new-design/loading_spinner.gif" alt="" width=30 height=auto *ngIf="isLogClicked">
                        </button>
                    </div>
                </form>
                <div class="text-help col-sm-12">
                    Do you need help? <a class="click-link" (click)="openTab()">Click here</a>
                </div>
            </div>
        </ng-template>

        <ng-template [ngIf]="resetPasswordForm">
            <div class="col-sm-12 login-form">
                <div class="login-title">Reset password</div>
                <div class="login-text">
                    Please provide your email address to initiate the process of resetting your credentials.
                </div>
                <form id="myForm" class="col-sm-12" ngNativeValidate (submit)="forgotPaswordRequest()">
                    <div class="form-group">
                        <input class="form-control username-icon login-input" name="email" type="text" #Email2="ngModel"
                            autocomplete="off" [(ngModel)]="email" required pattern="^[a-zA-Z0-9_.+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+$">
                    </div>
                    <div class="col-sm-12 link-container">
                        <a class="forgot-link" (click)="resetPasswordForm = false;">Back to Login</a>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary regular-btn login-button" [disabled]="Email2.errors?.pattern || !Email2.value"
                            (submit)="forgotPaswordRequest()">
                            <span class="button-text" *ngIf="!isLogClicked">Reset password</span>
                            <img src="assets/images-new-design/loading_spinner.gif" alt="" width=30 height=auto *ngIf="isLogClicked">
                        </button>
                    </div>
                </form>
                <div class="login-help">
                    Do you need help? <a class="click-link" (click)="openTab()">Click here</a>
                </div>
            </div>
        </ng-template>
    </div>
    <div style="width: 50%; float: right;">
        <app-login-home-details></app-login-home-details>
    </div>
</div>
