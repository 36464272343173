<div class="slider-back" [hidden]="!showShadow">

</div>

<div [hidden]="!showShadow">
    <div class="slider" [ngClass]="{'shadow' : showShadow === true}" [@slideInOut]="animationState">
        <div class="tab-header">
            <span>Edit Translation</span>
            <div class="btn-close-position" (click)="changeState(2)">
                <div class="btn-close">
                    <img alt="Close" src="../../../../../../assets/images-new-design/Close.png">
                </div>
            </div>

        </div>
        <form (submit)="f.valid" class="form col-sm-12" #f="ngForm">
                <div class="col-sm-12 crud-input-container">
                    <label class="labelPopup">{{ translation1.key }}</label>
                    <input class="form-control" name="translation1" [(ngModel)]="translation1.value" #name="ngModel"
                        required autocomplete="off" placeholder="Translation" />
                    <div *ngIf="f.submitted && name.invalid" class="form-control-error">Translation is required</div>
                </div>
                <div class="col-sm-12 crud-input-container">
                    <label class="labelPopup">{{ translation2.key }}</label>
                    <input class="form-control" name="translation2" [(ngModel)]="translation2.value" #name="ngModel"
                        required autocomplete="off" placeholder="Translation" />
                    <div *ngIf="f.submitted && name.invalid" class="form-control-error">Translation is required</div>
                </div>
                <div class="col-sm-12 crud-input-container">
                    <label class="labelPopup">{{ translation3.key }}</label>
                    <input class="form-control" name="translation3" [(ngModel)]="translation3.value" #name="ngModel"
                        required autocomplete="off" placeholder="Translation" />
                    <div *ngIf="f.submitted && name.invalid" class="form-control-error">Translation is required</div>
                </div>
                <div class="col-sm-12 crud-input-container">
                    <label class="labelPopup">{{ translation4.key }}</label>
                    <input class="form-control" name="translation4" [(ngModel)]="translation4.value" #name="ngModel"
                        required autocomplete="off" placeholder="Translation" />
                    <div *ngIf="f.submitted && name.invalid" class="form-control-error">Translation is required</div>
                </div>
                <div class="col-sm-12 crud-input-container">
                    <label class="labelPopup">{{ translation5.key }}</label>
                    <input class="form-control" name="translation5" [(ngModel)]="translation5.value" #name="ngModel"
                        required autocomplete="off" placeholder="Translation" />
                    <div *ngIf="f.submitted && name.invalid" class="form-control-error">Translation is required</div>
                </div>
                <div class="col-sm-12 crud-input-container">
                    <label class="labelPopup">{{ translation6.key }}</label>
                    <input class="form-control" name="translation6" [(ngModel)]="translation6.value" #name="ngModel"
                        required autocomplete="off" placeholder="Translation" />
                    <div *ngIf="f.submitted && name.invalid" class="form-control-error">Translation is required</div>
                </div>
                <div class="col-sm-12 crud-input-container">
                    <label class="labelPopup">{{ translation7.key }}</label>
                    <input class="form-control" name="translation7" [(ngModel)]="translation7.value" #name="ngModel"
                        required autocomplete="off" placeholder="Translation" />
                    <div *ngIf="f.submitted && name.invalid" class="form-control-error">Translation is required</div>
                </div>

            <div class="user-btn">

                <button class="submit col-xs-6" (click)="save()">Save</button>
                <button class="cancel-btn col-xs-6" (click)="closeTab()">Cancel</button>
            </div>
        </form>

    </div>
</div>
