<main class="">
    <div class="user-profile">

        <div class="container-wrapper">
            <div class="auditheader">
                <div class="title-container">
                    <div class="title-u">
                        Customer without Consumption
                    </div>
                    <div class="subtitle">
                        Read customer without consumption
                    </div>
                </div>
            </div>

            <div class="filters-container">
                <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                    <app-multi-filter *ngIf="filterApplied"
                        [data]="allFilters"
                        [(showFilter)]="showFilter"
                        (dropdownChange)="dropdownSelected($event)"
                        (dataChange)="setFilters($event)"

                    >
                    </app-multi-filter>
        
                    <app-range-datepicker
                        [fromDate]="dateFrom" 
                        [toDate]="dateTo"
                        [name]="'Period'"
                        [isMonthly]="isMonthly"
                        [isOnlyOneMonth]="isOnlyOneMonth"
                        (dateRangeSelected)="updateDate($event)">
                    </app-range-datepicker>

                    <app-search-autocomplete
                        #search
                        [width]="30"
                        [placeholder]="searchPlaceholder"
                        [searchText]="searchText"
                        [options]="options"
                        (searchChange)="searchChange($event)"
                        (searchAutocompleteChange)="getOptions($event)"
                    ></app-search-autocomplete>
                </div>
            </div>
        
        <table class="standard-table">
            <thead>
                <tr>
                    <th class="large-td"><span title="METER S/N">METER S/N</span></th>
                    <th><span title="ACCOUNT NUMBER">ACCOUNT NUMBER</span></th>
                    <th><span title="CUSTOMER NAME">CUSTOMER NAME</span></th>
                    <th><span title="{{buName | uppercase}}">{{buName | uppercase}}</span></th>
                    <th><span title="{{utName | uppercase}}">{{utName | uppercase}}</span></th>
                    <th><span title="DT NAME">DT NAME</span></th>
                    <th><span title="DATE">DATE</span></th>
                </tr>
            </thead>
            <tbody class="table-body">
                <tr *ngFor="let item of customersNoConsumption" class="bodyRow clickable actions-click">
                    <td>{{(item.msn) | hasValue}}
                        <svg (click)="copy(item.msn)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="content_copy">
                            <mask id="mask0_7446_16312" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_7446_16312)">
                            <path id="content_copy_2" d="M9.05771 17.5C8.55258 17.5 8.12502 17.325 7.77504 16.975C7.42504 16.625 7.25004 16.1974 7.25004 15.6923V4.3077C7.25004 3.80257 7.42504 3.375 7.77504 3.025C8.12502 2.675 8.55258 2.5 9.05771 2.5H17.4423C17.9474 2.5 18.375 2.675 18.725 3.025C19.075 3.375 19.25 3.80257 19.25 4.3077V15.6923C19.25 16.1974 19.075 16.625 18.725 16.975C18.375 17.325 17.9474 17.5 17.4423 17.5H9.05771ZM9.05771 16H17.4423C17.5192 16 17.5897 15.9679 17.6538 15.9038C17.7179 15.8397 17.75 15.7692 17.75 15.6923V4.3077C17.75 4.23077 17.7179 4.16024 17.6538 4.09613C17.5897 4.03203 17.5192 3.99998 17.4423 3.99998H9.05771C8.98078 3.99998 8.91026 4.03203 8.84616 4.09613C8.78204 4.16024 8.74999 4.23077 8.74999 4.3077V15.6923C8.74999 15.7692 8.78204 15.8397 8.84616 15.9038C8.91026 15.9679 8.98078 16 9.05771 16ZM5.55774 20.9999C5.05262 20.9999 4.62506 20.8249 4.27506 20.4749C3.92506 20.1249 3.75006 19.6973 3.75006 19.1922V6.3077H5.25004V19.1922C5.25004 19.2692 5.28209 19.3397 5.34619 19.4038C5.4103 19.4679 5.48082 19.5 5.55774 19.5H15.4423V20.9999H5.55774Z" fill="#1C1B1F"/>
                            </g>
                            </g>
                        </svg> 
                    </td>
                    <td>{{(item.accountNo) | hasValue}}
                        <svg (click)="copy(item.accountNo)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="content_copy">
                            <mask id="mask0_7446_16312" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_7446_16312)">
                            <path id="content_copy_2" d="M9.05771 17.5C8.55258 17.5 8.12502 17.325 7.77504 16.975C7.42504 16.625 7.25004 16.1974 7.25004 15.6923V4.3077C7.25004 3.80257 7.42504 3.375 7.77504 3.025C8.12502 2.675 8.55258 2.5 9.05771 2.5H17.4423C17.9474 2.5 18.375 2.675 18.725 3.025C19.075 3.375 19.25 3.80257 19.25 4.3077V15.6923C19.25 16.1974 19.075 16.625 18.725 16.975C18.375 17.325 17.9474 17.5 17.4423 17.5H9.05771ZM9.05771 16H17.4423C17.5192 16 17.5897 15.9679 17.6538 15.9038C17.7179 15.8397 17.75 15.7692 17.75 15.6923V4.3077C17.75 4.23077 17.7179 4.16024 17.6538 4.09613C17.5897 4.03203 17.5192 3.99998 17.4423 3.99998H9.05771C8.98078 3.99998 8.91026 4.03203 8.84616 4.09613C8.78204 4.16024 8.74999 4.23077 8.74999 4.3077V15.6923C8.74999 15.7692 8.78204 15.8397 8.84616 15.9038C8.91026 15.9679 8.98078 16 9.05771 16ZM5.55774 20.9999C5.05262 20.9999 4.62506 20.8249 4.27506 20.4749C3.92506 20.1249 3.75006 19.6973 3.75006 19.1922V6.3077H5.25004V19.1922C5.25004 19.2692 5.28209 19.3397 5.34619 19.4038C5.4103 19.4679 5.48082 19.5 5.55774 19.5H15.4423V20.9999H5.55774Z" fill="#1C1B1F"/>
                            </g>
                            </g>
                        </svg> 

                    </td>
                    <td>{{(item.firstName) | hasValue}} {{(item.lastName)}}</td>
                    <td>{{(item.buName) | hasValue}}</td>
                    <td>{{(item.utName) | hasValue}}</td>
                    <td>{{item.dtName | hasValue}} </td>
                    <td>{{item.period | date:'MMM y'}} </td>
                </tr>
            </tbody>
        </table>

        <div class="pagination-wrapper">
            <app-pagination [pageSize]="pageSize" [currentPage]="currentPage" [count]="count" (pageChange)="pageChange($event)">
            </app-pagination>
        </div>
        </div>
    </div>

</main>