import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class DeletePopupComponent {
  isDeactivatePopupOpen: boolean = false;

  @Input() count: number = 0;
  @Output() parentComp = new EventEmitter();

  constructor(private elementRef: ElementRef
  ) { }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.isDeactivatePopupOpen) {
      if (this.elementRef.nativeElement.contains(event.target) || event.target === this.elementRef.nativeElement) {
        if (!isSliderClicked) {
          this.close();
        }
      }
    }
  }
  delete(){
    this.parentComp.emit()
    this.close()
  }
  open() {
    this.isDeactivatePopupOpen = true;
  }
  close() {
    this.isDeactivatePopupOpen = false;
  }

}
