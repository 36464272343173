import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  public pathApi = this.config.setting['pathApi'];
  public pathApiCommon = this.config.setting['pathApi'] + "Comments/";

  constructor(private http: HttpClient, private config: AppConfig) { }

  delete(id: number, taskId: string): Observable<any>{
    return this.http.get<any>(`${this.pathApiCommon}delete/${id}/${taskId}`)
  }
}
