<main class="container-fluid">
    <div class="container-wrapper">
        <div class="network-management-title">
            <span>Meter Events</span>
        </div>

        <div class="menu-tab-filter">
            <app-tab-default
                [firstText]="'Meter Cover Tamper'" [firstTabNum]="openCoverTamperCount"
                [secondText]="'Open Terminal'" [secondTabNum]="openTerminalCount"
                [thirdText]="'Magnetic Tamper'" [thirdTabNum]="openMagneticCount"
                [forthText]="'Low Credit'" [fourthTabNum]="lowCreditCount"
                (tabChanged)="menuChanged($event)" [wom]="true"
                [tabSelected]="tabMenu"
            ></app-tab-default>
        </div>

        <div class="filters-container">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                <app-multi-filter *ngIf="filterApplied" [data]="allFilters" (dataChange)="applyFilter($event)"
                    [(showFilter)]="showFilter" >
                </app-multi-filter>

                <app-range-datepicker
                    [fromDate]="dateFrom"
                    [toDate]="dateTo"
                    [name]="'Period'"
                    [isMonthly]="true"
                    (dateRangeSelected)="dateChange($event)">
                </app-range-datepicker>

                <app-search-autocomplete #search [placeholder]="searchPlaceholder" [searchText]="searchText" [options]="options"
                    (searchChange)="searchChange($event)" [width]="33"
                    (searchAutocompleteChange)="getOptions($event)">
                </app-search-autocomplete>
            </div>
            <div class="download-button">
                <app-download-button-new
                  [isOnlyDownload]="true"
                  (downloadButtonClickedChange)="download()">
                </app-download-button-new>
            </div>
        </div>

        <app-meter-triggers-grid #child
            [meterTriggerType]="meterTriggerType"
            [meterIds]="meterIds"
            [search]="searchText"
            [buIds]="buIds"
            [utIds]="utIds"
            [dtIds]="dtIds"
            [amiIds]="amiIds"
            [customerTypeValue]="customerTypeValue"
            [contractStatusValue]="contractStatusValue"
            [dateFrom]="dateFrom"
            [dateTo]="dateTo"
            [(accStatus)]="accStatus"
            [(alarmCondition)]="alarmCondition"
            [(bypassStatus)]="bypassStatus"
            [(commStatus)]="commStatus"
            (countChange)="handleCountChange($event)">

        </app-meter-triggers-grid>
    </div>
</main>
