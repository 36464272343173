import { Component } from '@angular/core';

@Component({
  selector: 'app-meter-management-layout',
  templateUrl: './meter-management-layout.component.html',
  styleUrls: ['./meter-management-layout.component.scss']
})
export class MeterManagementLayoutComponent {

  expandSidebar: boolean = true;
}
