import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
    providedIn: 'root'
})

export class InvalidTfeReadService {

    private pathApi = this.config.setting['pathApi'] + "invalidTfeRead/";

    constructor(private http: HttpClient,
        private config: AppConfig) { }
        
    getForPage(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getInvalidTfeReads`, obj);
    }

    getDataForAutocomplete(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
    }
}