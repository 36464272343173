import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { RoleService } from 'src/app/services/role.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-process-login',
  templateUrl: './process-login.component.html',
  styleUrls: ['./process-login.component.scss']
})
export class ProcessLoginComponent implements OnInit {

  constructor(private activeRouter: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private roleService: RoleService,
    private translationService: TranslationService) { }

  ngOnInit(): void {

    const accessToken = this.activeRouter.snapshot.queryParamMap.get('access_token');
    const identityToken = this.activeRouter.snapshot.queryParamMap.get('identity_token');

    if(accessToken) {
      localStorage.setItem('token', accessToken);
    }

    if(identityToken) {
      localStorage.setItem('identityToken', identityToken);
    }

    const isFromHome = this.activeRouter.snapshot.queryParamMap.get('is_from_home');
    if(isFromHome)
      localStorage.setItem('is_from_home', isFromHome);

    let currentUser = this.authService.getCurrentUser()?.email;

    this.roleService.getUserPermissions(currentUser).subscribe(x => {
      if(x.status === 200) {
        localStorage.setItem('permissions', x.data.permissions);

        if(x.data.isPasswordTemp){
          localStorage.setItem('tempPassword', x.data.isPasswordTemp);
        }
      }
    }, error => {
      this.toastr.error('An error ocurred.');
    });
    // localStorage.setItem('rolePermissionAndSystemComponents', x.data.permissions);

    this.translationService.getAll().subscribe(x => {
      if (x.status === 200){
        localStorage.setItem('translations',JSON.stringify(x.data));
        this.translationService.getByKeyFromCache('UT');

        if(this.authService.isPasswordTemp()){
          this.router.navigate(['password-new-user'])
        }
        else{
          this.router.navigate(['global-landing-page']);
        }
      }
      else
      {
        this.toastr.error(x.message);
      }
    }, error => {
      this.toastr.error('An error ocurred.');

    });
  }

}
