export class FieldModalData{
  assetType: string = "";
  assetTypeId: string = "";
  assignedTeam: string = "";
  assignedTeamId: string = "";
  assignedUser: string = "";
  assignedUserId: string = "";
  buId: number | null = null;
  buName: string = "";
  category: string = "";
  dateAssigned: string = ""; 
  dateCreated: string = "";
  dateRemoved: string = "";
  description: string = "";
  id: number | null = 0;
  images: [] = [];
  reporter: string = "";
  status: string = "";
  summary: string = "";
  workOrderId: string | number = "";
}