import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Directive, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';


export enum ScreenSize {
  XSmall = 'XSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
}

/**
 * Directive that will detect screen size changes and add a coresponding classes to the
 * host html element. Also it can emit screen size values that can be used in the typescript.
 */
@Directive({
  selector: '[screenSizeDetector]'
})
export class ScreenSizeDetector {
  /**
   * Id used to add uniqe class names to the element
   */
  @Input() componentId: string = '';
  /**
   * Boolean that will disable adding class names to the host element in case it is false
   */
  @Input() addClasses: boolean = true;
  /**
   * It will emit screen size values
   */
  @Output() screenSizeChanged = new EventEmitter<ScreenSize>();

  constructor(private element: ElementRef, private responsive: BreakpointObserver, private renderer: Renderer2) {
  }

  private layoutClasses = ['x-small-screen', 'small-screen', 'medium-screen', 'large-screen', 'x-large-screen'];

  private removeClasses() {
    for (let index = 0; index < this.layoutClasses.length; index++) {
      const className = this.layoutClasses[index];
      this.renderer.removeClass(this.element.nativeElement, `${className}${this.componentId}`);
    }
  }

  // BreakpointObserver reference https://material.angular.io/cdk/layout/overview
  ngOnInit(): void {
    this.responsive.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ])
      .subscribe(result => {

        const breakpoints = result.breakpoints;

        if (breakpoints[Breakpoints.XSmall]) {
          this.screenSizeChanged.emit(ScreenSize.XSmall);
          if (!this.addClasses) {
            return;
          }
          this.removeClasses();
          this.renderer.addClass(this.element.nativeElement, `x-small-screen${this.componentId}`);
        } else if (breakpoints[Breakpoints.Small]) {
          this.screenSizeChanged.emit(ScreenSize.Small);
          if (!this.addClasses) {
            return;
          }
          this.removeClasses();
          this.renderer.addClass(this.element.nativeElement, `small-screen${this.componentId}`);
        } else if (breakpoints[Breakpoints.Medium]) {
          this.screenSizeChanged.emit(ScreenSize.Medium);
          if (!this.addClasses) {
            return;
          }
          this.removeClasses();
          this.renderer.addClass(this.element.nativeElement, `medium-screen${this.componentId}`);
        } else if (breakpoints[Breakpoints.Large]) {
          this.screenSizeChanged.emit(ScreenSize.Large);
          if (!this.addClasses) {
            return;
          }
          this.removeClasses();
          this.renderer.addClass(this.element.nativeElement, `large-screen${this.componentId}`);
        } else if (breakpoints[Breakpoints.XLarge]) {
          this.screenSizeChanged.emit(ScreenSize.XLarge);
          if (!this.addClasses) {
            return;
          }
          this.removeClasses();
          this.renderer.addClass(this.element.nativeElement, `x-large-screen${this.componentId}`);
        }
      }
    );
  }
}
