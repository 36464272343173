import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-deactivate-popup',
  templateUrl: './deactivate-popup.component.html',
  styleUrls: ['./deactivate-popup.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class DeactivatePopupComponent {
  isDeactivatePopupOpen: boolean = false;

  @Input() count: number = 0;
  @Output() parentComp = new EventEmitter();

  constructor(private elementRef: ElementRef
  ) { }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.isDeactivatePopupOpen) {
      if (this.elementRef.nativeElement.contains(event.target) || event.target === this.elementRef.nativeElement) {
        if (!isSliderClicked) {
          this.close();
        }
      }
    }
  }
  activate(){
    this.parentComp.emit()
    this.close()
  }
  open() {
    this.isDeactivatePopupOpen = true;
  }
  close() {
    this.isDeactivatePopupOpen = false;
  }
}
