<div class="container-fluid">
    <div class="container-wrapper">
        <div class="fo-container">
            <div class="network-management-title">
                <span>DCU</span>
            </div>
            <div class="menu-tab-filter">
                <app-tab-default [firstText]="'Online'" [secondText]="'Offline'" [firstTabNum]="onlineNumber"
                    [secondTabNum]="offlineNumber" [wom]="true" (tabChanged)="mainTabChange($event)"></app-tab-default>
            </div>
            <div class="nm-filter-wrapper filter-scroll">
                <app-tab-default class="wide-tab" [womCss]="true" [paCss]="true" [isConst]="false" [tabs]="tabs"
                    [wom]="true" (tabChanged)="secondTabChange($event)"></app-tab-default>
            </div>
        </div>
        <div class="filters-container">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                <app-multi-filter *ngIf="filterApplied" [data]="allFilters" (dataChange)="applyFilter($event)"
                    [(showFilter)]="showFilter">
                </app-multi-filter>
                <app-search-autocomplete #search [width]="30" [placeholder]="searchPlaceholder"
                    [searchText]="searchText" [options]="options" (searchChange)="searchChange($event)"
                    (searchAutocompleteChange)="getOptions($event)"></app-search-autocomplete>
                </div>
            </div>
            <div class="gep-table-wrapper">
                <table class="standard-table">
                    <thead>
                        <tr>
                            <th class="checkbox-td"><input type="checkbox" class="input-checkmark"
                                    [(ngModel)]="masterSelected" (change)="checkUncheckAll()"></th>
                            <th class="large-td"><span>{{concentratorName}} SN</span></th>
                            <th class="large-td"><span>{{buName}}</span></th>
                            <th class="large-td"><span>{{utName}}</span></th>
                            <th class="large-td"><span>ADDRESS</span></th>
                            <th><span>LONGITUDE</span></th>
                            <th><span>LATITUDE</span></th>
                            <th><span>CONNECTED METERS</span></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of dcus" class="bodyRow clickable"
                            [ngClass]="{'selected': selectedDcu.id===item.id}">
                            <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox"
                                    class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked"
                                    (change)="checkForDownload(item.id)"></td>
                            <td class="large-td">
                                <span class="label">{{item.serialNumber | hasValue}}</span>
                                <svg style="margin-left: 1rem;" (click)="copySerialNumber(item.serialNumber)" width="17"
                                    height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.05765 15.5C5.55252 15.5 5.12496 15.325 4.77498 14.975C4.42498 14.625 4.24998 14.1974 4.24998 13.6923V2.3077C4.24998 1.80257 4.42498 1.375 4.77498 1.025C5.12496 0.675 5.55252 0.5 6.05765 0.5H14.4422C14.9473 0.5 15.3749 0.675 15.7249 1.025C16.0749 1.375 16.2499 1.80257 16.2499 2.3077V13.6923C16.2499 14.1974 16.0749 14.625 15.7249 14.975C15.3749 15.325 14.9473 15.5 14.4422 15.5H6.05765ZM6.05765 14H14.4422C14.5191 14 14.5897 13.9679 14.6538 13.9038C14.7179 13.8397 14.7499 13.7692 14.7499 13.6923V2.3077C14.7499 2.23077 14.7179 2.16024 14.6538 2.09613C14.5897 2.03203 14.5191 1.99998 14.4422 1.99998H6.05765C5.98072 1.99998 5.9102 2.03203 5.8461 2.09613C5.78198 2.16024 5.74993 2.23077 5.74993 2.3077V13.6923C5.74993 13.7692 5.78198 13.8397 5.8461 13.9038C5.9102 13.9679 5.98072 14 6.05765 14ZM2.55768 18.9999C2.05256 18.9999 1.625 18.8249 1.275 18.4749C0.925 18.1249 0.75 17.6973 0.75 17.1922V4.3077H2.24998V17.1922C2.24998 17.2692 2.28202 17.3397 2.34613 17.4038C2.41024 17.4679 2.48076 17.5 2.55768 17.5H12.4422V18.9999H2.55768Z"
                                        fill="#1C1B1F" />
                                </svg>
                            </td>
                            <td class="large-td">
                                <span *ngIf="item.buName != '/'" class="label">{{item.buName}}</span>
                                <span *ngIf="!item.buName || item.buName == '/'" class="NaN">N/A</span>
                            </td>
                            <td class="large-td">
                                <span *ngIf="item.utName != '/'" class="label">{{item.utName}}</span>
                                <span *ngIf="!item.utName || item.utName == '/'" class="NaN">N/A</span>
                            </td>
                            <td class="large-td">
                                <span *ngIf="item.address != '/'" class="label">{{item.address}}</span>
                                <span *ngIf="!item.address || item.address == '/'" class="NaN">N/A</span>
                            </td>
                            <td>
                                <span *ngIf="item.longitude" class="label">{{item.longitude}}</span>
                                <span *ngIf="!item.longitude" class="NaN">N/A</span>
                            </td>
                            <td>
                                <span *ngIf="item.latitude" class="label">{{item.latitude}}</span>
                                <span *ngIf="!item.latitude" class="NaN">N/A</span>
                            </td>
                            <td>
                                <span>{{item.connectedMeters}}</span>
                            </td>
                            <td class="actions">
                                <button class="more-button" (click)="alert()">
                                    <svg width="4" height="16" viewBox="0 0 4 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.99998 3.83333C2.32962 3.83333 2.65185 3.73559 2.92593 3.55245C3.20001 3.36931 3.41363 3.10902 3.53978 2.80447C3.66593 2.49993 3.69893 2.16482 3.63462 1.84152C3.57031 1.51822 3.41158 1.22124 3.17849 0.988156C2.9454 0.755068 2.64843 0.596334 2.32513 0.532025C2.00183 0.467717 1.66672 0.500722 1.36218 0.626868C1.05763 0.753014 0.797334 0.966635 0.614198 1.24072C0.431062 1.5148 0.333314 1.83703 0.333314 2.16667C0.333314 2.60869 0.508909 3.03262 0.82147 3.34518C1.13403 3.65774 1.55795 3.83333 1.99998 3.83333ZM1.99998 12.1667C1.67035 12.1667 1.34811 12.2644 1.07403 12.4476C0.799949 12.6307 0.586327 12.891 0.460181 13.1955C0.334035 13.5001 0.30103 13.8352 0.365338 14.1585C0.429647 14.4818 0.588382 14.7788 0.82147 15.0118C1.05456 15.2449 1.35153 15.4037 1.67483 15.468C1.99813 15.5323 2.33324 15.4993 2.63779 15.3731C2.94233 15.247 3.20263 15.0334 3.38576 14.7593C3.5689 14.4852 3.66665 14.163 3.66665 13.8333C3.66665 13.3913 3.49105 12.9674 3.17849 12.6548C2.86593 12.3423 2.44201 12.1667 1.99998 12.1667ZM1.99998 6.33333C1.67035 6.33333 1.34811 6.43108 1.07403 6.61422C0.799949 6.79735 0.586327 7.05765 0.460181 7.3622C0.334035 7.66674 0.30103 8.00185 0.365338 8.32515C0.429647 8.64845 0.588382 8.94542 0.82147 9.17851C1.05456 9.4116 1.35153 9.57033 1.67483 9.63464C1.99813 9.69895 2.33324 9.66595 2.63779 9.5398C2.94233 9.41365 3.20263 9.20003 3.38576 8.92595C3.5689 8.65187 3.66665 8.32964 3.66665 8C3.66665 7.55797 3.49105 7.13405 3.17849 6.82149C2.86593 6.50893 2.44201 6.33333 1.99998 6.33333Z"
                                            fill="#474747" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pagination-wrapper">
                <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
                    [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
            </div>
        </div>
    </div>