import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TypeOfConsumer } from 'src/app/services/meter.service';
import { StaffOperatorService } from 'src/app/services/staff-operator.service';

@Component({
  selector: 'app-scrollable-autocomplete-filter',
  templateUrl: './scrollable-autocomplete-filter.component.html',
  styleUrl: './scrollable-autocomplete-filter.component.scss'
})
export class ScrollableAutocompleteFilterComponent implements OnInit {

  searchQuery: string = ''
  selectedAssets: any[] = []
  usassignedAssets: any[] = []

  isF11FilterSelected: boolean = false
  isF33FilterSelected: boolean = false
  isDtFilterSelected: boolean = false
  assetTypesSelected: TypeOfConsumer[] = []

  @ViewChild('list') listDiv: ElementRef<HTMLDivElement> | undefined

  constructor(private staffOperatorService: StaffOperatorService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
  }

  searchChange() {
    if (this.searchQuery.length > 2) {
      if (this.listDiv != undefined) {
        this.listDiv!.nativeElement.style.display = 'block'        
      }
      this.getUnassignedAssets()
    }
    if (this.searchQuery.length == 0) {
      this.usassignedAssets = []
    }
  }

  getUnassignedAssets() {
    let obj = {
      searchText: this.searchQuery,
      consumerTypes: this.assetTypesSelected
    }

    this.staffOperatorService.getUnassignedAssetsForAutocomplete(obj).subscribe(response => {
      if (response.status === 200) {
        this.usassignedAssets = response.data
      } else
        this.toastr.error(response.message)
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  getAssignedAssetsForStaffOperator(staffOperatorId: number) {
    this.staffOperatorService.getAssignedAssetsForSO(staffOperatorId).subscribe(response => {
      if (response.status === 200) {
        this.selectedAssets = response.data
      } else
        this.toastr.error(response.message)
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  updateAssignedAssets(staffOperatorId: number) {
    let obj = {
      id: staffOperatorId,
      assetNames: this.selectedAssets
    }
    
    this.staffOperatorService.updateAssignedAssetsForStaffOperator(obj).subscribe(response => {
      if (response.status !== 200) {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  resetFilters() {
    this.selectedAssets = []
    this.usassignedAssets = []
    this.isDtFilterSelected = false
    this.isF11FilterSelected = false
    this.isF33FilterSelected = false
    this.assetTypesSelected = []
    this.searchQuery = ''
  }

  selectAsset(asset: string) {
    if (!this.selectedAssets.includes(asset)) {
      this.selectedAssets.push(asset)
    }
  }

  removeAsset(asset: string) {
    this.selectedAssets = this.selectedAssets.filter(a => a !== asset)
  }

  changeFilterAssets() {
    this.assetTypesSelected = []
    if (this.isDtFilterSelected) {
      this.assetTypesSelected.push(TypeOfConsumer.Dt)
    }
    if (this.isF11FilterSelected) {
      this.assetTypesSelected.push(TypeOfConsumer.Feeder11)
    }
    if (this.isF33FilterSelected) {
      this.assetTypesSelected.push(TypeOfConsumer.Feeder33)
    }
    if (this.searchQuery.length > 2) {
      this.getUnassignedAssets()
    }
  }

  hideAssets() {
    if (this.listDiv != undefined) {
      this.listDiv.nativeElement.style.display = 'none'
    }
  }

}
