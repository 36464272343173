import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(value: any, args?: any) {
    if (!value)
      return '/';

    if (value === '')
      return '/';

    return formatDate(value, "dd/M/yy h:mm a", "en");
  }
}
