import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AccountService } from 'src/app/services/account.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';

@Component({
  selector: 'app-no-consumption-customers',
  templateUrl: './no-consumption-customers.component.html',
  styleUrl: './no-consumption-customers.component.scss'
})
export class NoConsumptionCustomersComponent {

  public currentPage: number = 1
  public pageSize: number = 10
  public count: number = 0;
  public lastPageSize: number = 1

  public dateFrom: any = '';
  public dateTo: any = '';
  public dtFrom: Date = new Date();
  public dtTo: Date = new Date();
  public isMonthly: boolean = true;
  public isOnlyOneMonth: boolean = true;

  public search: string = '';

  public height: string = '21rem'
  public showFilter: any = false
  public allFilters: any = []
  public filterApplied: boolean = false;

  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public masterSelected: boolean = false;
  public checkedList: any = [];
  public searchPlaceholder: string = "Search by Meter Number, Account Number & SPN";
  public searchText: SearchAutocomplete = new SearchAutocomplete();
  public searchLength: number = 1
  public options = [];

  public customersNoConsumption: any;

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public businessUnits: any = { name: this.buName, data: [], dropdown: true};
  public undertakings: any = { name: this.utName, data: [] };
  public buSelected: any = {value: 0, name: '', checked: false}
  public utIds: any = [];
  public buId: any = 0

  constructor(    
    private toastr: ToastrService,
    private translationService: TranslationService,
    private businessUnitService: BusinessUnitService,
    private undertakingService: UndertakingService,
    private accountService: AccountService
    ) { }

    // dropdownSelected(item: any) {
    //   this.checkedList = []
    //   this.reloadTable(1);
    // }

  ngOnInit(): void {
    this.setMainDate()
    this.reloadTable()
    this.getBusForSelect();
  }

  // public download() {
  //   let obj = {
  //       dateFrom: transformToString(this.dateFrom),
  //       dateTo: transformToString(this.dateTo),
  //       search: this.searchText.name ? this.searchText.name : this.search,
  //       id: 0,
  //       userId: this.userSelected.value,
  //       isDownload: true,
  //       logIds: this.checkedList
  //   }

  //   this.customersNoConsumptionervice.downloadReport(obj).subscribe(response => {
  //     window.location.href = response.data;
  //   }, _ => {
  //     this.toastr.error('An error occurred.');
  //   })
  // }

  searchChange(text: SearchAutocomplete) { 
    this.searchText = text;
    this.searchText = cloneDeep(this.searchText);

    this.checkedList = [];
    this.pageChange(1)
  }

  // Search Autocomplete
  getOptions(searchText: any) { 
    let obj = {
      searchText: searchText.name, 
      msnIncluded: false
    }
    this.searchChild.searchLength = this.searchLength;
    if (searchText.name.length > 1) {
      this.accountService.getCustomerNoConsumptionDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) =>this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
    } else {
      this.searchChild.resetOptions()
    }
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    this.dtFrom.setMonth(dte.getMonth() - 1);
    this.dtTo.setDate(dte.getDate() + 1);
    this.dateFrom = {year: this.dtFrom.getFullYear(), month: this.dtFrom.getMonth() + 1, day: 1};
    this.dateTo = {year: this.dtTo.getFullYear(), month: this.dtTo.getMonth() + 1, day: 1};
  }

  inputChanged(searchText: string){
    this.search = searchText
    this.pageChange(1)
  }
  reloadTable(page: any = null) {
    if (page) 
      this.currentPage = page

    let obj = this.generateObject()

    this.accountService.getCustomersWithoutConsumption(obj).subscribe({
      next : (response) => {
        if(response.status == 200)
        {
          this.customersNoConsumption = response.data.data
          if(this.currentPage == 1)
          {
            this.count = response.data.count,
            this.lastPageSize = Math.ceil(this.count/this.pageSize)
          }
        }
        else
        {
          this.toastr.error(response.message)
        }
      },
      error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
    }
    )
  }

  generateObject() {
    let obj = {
      pageInfo: {
        pageSize: this.pageSize,
        page: this.currentPage,
      },
      filterParams: {
        from: transformToString(this.dateFrom),
        to: transformToString(this.dateTo),
        searchText: this.searchText.name ? this.searchText.name : "",
        buId: this.buId,
        utIds: this.utIds
      }
    }
    
    return obj
  }

  checkFilters() {
    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);

    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;

    if (buIndex === -1) {
      this.allFilters.push(this.businessUnits)

      this.allFilters.push(this.undertakings)

      this.filterApplied = true
    }
    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakings.name);
    if (utIndex !== -1)
      this.allFilters[utIndex].data = this.undertakings.data;

    this.allFilters = [...this.allFilters]
  }

  setFilters(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);

      if (item.name === this.undertakings.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.undertakings = item
        this.utIds = filteredValues
        this.pageChange(1);
      }
    }
  }

  
  getBusForSelect(){
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data, dropdown: true }
      this.checkFilters();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  dropdownSelected(data: any){
    if(!data)
      return
    if(data.data.name === this.buName)
      this.businessUnitSelected(data.item)
  }

  businessUnitSelected(item: any) { 
    if (!item.value)
      this.buId = 0
    if (item.value !== 0) {
      this.buId = item.value;
      this.undertakingService.getAllByBuId(this.buId).subscribe(response => {
        this.undertakings = { name: this.utName, data: response.data, dropdown: true }; 
        this.checkFilters()
        this.pageChange(1);
      }, error => {
        this.toastr.error('An error occurred.');
      })
    } 
    if (this.buId == 0) {
      this.undertakings.data.splice(0);
      this.pageChange(1);
      this.checkFilters()
    } 
  } 

  pageChange(value: any) {
    this.currentPage = value
    this.reloadTable()
  }

  updateDate(date: any) {
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.pageChange(1)
  }

  public copy(copyText : any){
    navigator.clipboard.writeText(copyText)
    this.toastr.success('Copied to clipboard')
  }
}
