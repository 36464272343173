import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private pathApi = this.config.setting['pathApi'] + "dashboard/";
  private pathApiSubstation = this.config.setting['pathApi'] + "Substation/";

  constructor(private http: HttpClient, 
    private config: AppConfig) { }

  getDashboardData(): Observable<any> {
    return this.http.get(`${this.pathApi}`);
  } 

  getDashboardDataCards(): Observable<any> {
    return this.http.get(`${this.pathApi}getDashboardDataCards`);
  }

  getGridLossesAnalysis11KVFeeder(): Observable<any> {
    return this.http.get(`${this.pathApi}getGridLossesAnalysis11KVFeeder`);
  } 

  getGridLossesAnalysisDt(): Observable<any> {
    return this.http.get(`${this.pathApi}getGridLossesAnalysisDt`);
  } 

  getTotalEnergyConsumption(): Observable<any> {
    return this.http.get(`${this.pathApi}getTotalEnergyConsumptionForNetworkDashboard`);
  } 

  getOutageAnalysis11KV(): Observable<any> {
    return this.http.get(`${this.pathApi}getOutageAnalysis11KV`);
  } 

  getOutageAnalysis33KV(): Observable<any> {
    return this.http.get(`${this.pathApi}getOutageAnalysis33KV`);
  } 

  getOutageAnalysis11KVFeeders(): Observable<any> {
    return this.http.get(`${this.pathApi}getOutageAnalysis11KVFeeders`);
  } 
  getOutageAnalysis33KVFeeders(): Observable<any> {
    return this.http.get(`${this.pathApi}getOutageAnalysis33KVFeeders`);
  } 
  getMeterManagementAnalysis(): Observable<any> {
    return this.http.get(`${this.pathApi}getMeterManagementAnalysis`);
  } 

  getOutageAnalysisDT(): Observable<any> {
    return this.http.get(`${this.pathApi}getOutageAnalysisDT`);
  } 

  getGridLossesPopupData(obj:any): Observable<any> {
    return this.http.post(`${this.pathApiSubstation}lossesAnalyses`, obj);
  } 

  getDashboardPopUpOutages(obj:any): Observable<any> {
    return this.http.post(`${this.pathApiSubstation}GetOutagePopUAnalysis`, obj);
  } 

}
