<div *ngIf="isPopupOpen" class="popup-overlay" (click)="changeState(2, selectedPayment)">
    <div class="popup-content" click-stop-propagation>
        <div class="red-line">

        </div>
        <div class="back-button">
            <img alt="Close" src="assets/images-new-design/Close.svg" (click)="changeState(2, selectedPayment)">
        </div>
        <div class="information-wrapper">
            <div class="user-information">
                <div class="user-data">
                    <span class="tab-field-header" title="{{selectedPayment.accountName}}">{{selectedPayment.accountName}}</span>
                    <span class="tab-field-body">Account Name</span>
                </div>
                <div class="vertical-line"></div>
                <div class="user-data">
                    <span class="tab-field-header" title="{{selectedPayment.accountNo}}">{{selectedPayment.accountNo}}</span>
                    <span class="tab-field-body">Account Number</span>
                </div>
            </div>
        </div>

        <div class="tab-body-info">
            <div class="tabs-wrapper">
                <div class="tabs-button" [ngClass]="{'selected': tab == 1}" (click)="tab = 1"><span>Debt Balance</span>
                </div>
                <div class="tabs-button" [ngClass]="{'selected': tab == 2}" (click)="tab = 2"><span>Payment history</span>
                </div>
                <div class="tabs-button" [ngClass]="{'selected': tab == 3}" (click)="tab = 3"><span>Account Details</span>
                </div>
            </div>
            <div *ngIf="tab == 1">
                <table class="standard-table">
                    <thead>
                        <tr>
                            <th><span>ORIGINAL DEBT OWED</span></th>
                            <th><span>DEBT BALANCE FROM LAST PAYMENT</span></th>
                            <th><span>AMOUNT PAID</span></th>
                            <th><span>CURRENT TOTAL DEBT OWED</span></th>
                            <th><span>PAYMENT SOURCE</span></th>
                        </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr class="td-padding">
                            <td title="{{selectedPayment.actualDebtOwed}}"><span>{{selectedPayment.actualDebtOwed |
                                    twoDecimals | hasValue}}</span></td>
                            <td title="{{selectedPayment.debtBalanceFromLastPayment}}">
                                    <span>{{selectedPayment.debtBalanceFromLastPayment | twoDecimals | hasValue}}</span></td>
                            <td title="{{selectedPayment.amountPaid}}"><span>{{selectedPayment.amountPaid | twoDecimals |
                                    hasValue}}</span></td>
                            <td title="{{selectedPayment.currentDebtBalance}}">
                                    <span>{{selectedPayment.currentDebtBalance | twoDecimals | hasValue}}</span></td>
                            <td title="{{selectedPayment.paymentSource}}"><span>{{selectedPayment.paymentSource |
                                    hasValue }}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="tab == 2">
                <table class="standard-table">
                    <thead>
                        <tr>
                            <th><span>PAYMENT DATE</span></th>
                            <th><span>AMOUNT PAID</span></th>
                            <th><span>PAYMENT SOURCE</span></th>
                        </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr class="td-padding">
                            <td title="{{selectedPayment.paymentDate}}"><span>{{selectedPayment.paymentDate| hasValue |
                                date: 'dd/MM/yyyy'}}</span></td>
                            <td title="{{selectedPayment.amountPaid}}"><span>{{selectedPayment.amountPaid |
                                hasValue | twoDecimals}}</span></td>
                            <td title="{{selectedPayment.paymentSource}}"><span>{{selectedPayment.paymentSource |
                                hasValue}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="tab == 3">
                <table class="standard-table">
                    <thead>
                        <tr>
                            <th><span>{{utName}}</span></th>
                            <th><span>DT</span></th>
                            <th><span>{{buName}}</span></th>
                            <th><span>CUSTOMER ID</span></th>
                        </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr class="td-padding">
                            <td title="{{selectedPayment.undertaking}}"><span>{{selectedPayment.undertaking|
                                    hasValue}}</span></td>
                            <td title="{{selectedPayment.dt}}"><span>{{selectedPayment.dt | hasValue}}</span></td>
                            <td title="{{selectedPayment.businessUnit}}"><span>{{selectedPayment.businessUnit |
                                    hasValue}}</span></td>
                            <td title="{{selectedPayment.customerId}}"><span>{{selectedPayment.customerId |
                                    hasValue}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="red-line red-line-bottom">

        </div>
    </div>
</div>
