<div *ngFor="let ts of TSs; let tsi = index" class="item-container">
  <div class="item-header">
    <div class="header-dropdown">
      <div class="header-text ts-color" (click)="tsClicked(ts)" [ngClass]="{'rotate' : !tsShowed.includes(ts.id)}">
        <span>TS</span>
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.75535 5.18698C3.67535 5.18698 3.60035 5.17309 3.53035 5.14531C3.46035 5.11753 3.39757 5.07587 3.34202 5.02031L0.180986 1.85928C0.0550077 1.7333 -0.00520358 1.58976 0.000351973 1.42865C0.00590753 1.26753 0.0697964 1.12587 0.192019 1.00365C0.314241 0.881424 0.455908 0.820312 0.617019 0.820312C0.77813 0.820312 0.919796 0.881424 1.04202 1.00365L3.75869 3.73698L6.49202 1.00365C6.61424 0.881424 6.75591 0.82309 6.91702 0.828646C7.07813 0.834201 7.2198 0.89809 7.34202 1.02031C7.46424 1.14253 7.52535 1.2842 7.52535 1.44531C7.52535 1.60642 7.46236 1.74966 7.33639 1.87501L4.17535 5.02031C4.11535 5.07587 4.05035 5.11753 3.98035 5.14531C3.91035 5.17309 3.83535 5.18698 3.75535 5.18698Z" fill="white"/>
          </svg>        
      </div>
      
    </div>
    <div class="header-title">
      <span>{{ts.name}}</span>
    </div>
  </div>

  <div class="children-container">
      <div class="items" [hidden]="!tsShowed.includes(ts.id)">
        
        <div *ngFor="let f33 of ts.children; let f33i = index" class="item-container">
          <div class="vertical-line ts-color"></div>
          <div class="horizontal-line ts-color"></div>
          <div *ngIf="f33i < ts.children.length - 1" class="vertical-item-line ts-color"></div>
          <div class="item-header">
            <div class="header-dropdown">
              <div class="header-text f33-color"  (click)="f33Clicked(f33)" [ngClass]="{'rotate' : !f33Showed.includes(f33.id)}">
                <span>F33</span>
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.75535 5.18698C3.67535 5.18698 3.60035 5.17309 3.53035 5.14531C3.46035 5.11753 3.39757 5.07587 3.34202 5.02031L0.180986 1.85928C0.0550077 1.7333 -0.00520358 1.58976 0.000351973 1.42865C0.00590753 1.26753 0.0697964 1.12587 0.192019 1.00365C0.314241 0.881424 0.455908 0.820312 0.617019 0.820312C0.77813 0.820312 0.919796 0.881424 1.04202 1.00365L3.75869 3.73698L6.49202 1.00365C6.61424 0.881424 6.75591 0.82309 6.91702 0.828646C7.07813 0.834201 7.2198 0.89809 7.34202 1.02031C7.46424 1.14253 7.52535 1.2842 7.52535 1.44531C7.52535 1.60642 7.46236 1.74966 7.33639 1.87501L4.17535 5.02031C4.11535 5.07587 4.05035 5.11753 3.98035 5.14531C3.91035 5.17309 3.83535 5.18698 3.75535 5.18698Z" fill="white"/>
                  </svg>        
              </div>
            </div>
            <div class="header-title">
              <span>{{f33.name}}</span>
            </div>
          </div>

          <div class="children-container">
              <div class="items" [hidden]="!f33Showed.includes(f33.id)">
                
                <div class="item-container">
                  <div class="relative" *ngFor="let ss of f33.children; let ssi = index">
                    <div class="vertical-line f33-color" [ngClass]="{'dt-f33-vertical-line': ss.type == 5}"></div>
                    <div class="horizontal-line f33-color" [ngClass]="{'dt-f33-horizontal-line': ss.type == 5}"></div>
                    <div *ngIf="ssi < f33.children.length - 1" class="vertical-item-line f33-color" [ngClass]="{'dt-f33-vertical-item-line': ss.type == 5}"></div>
                    <div class="item-header" [ngClass]="{'dt-f33-header': ss.type == 5}">
                      <div class="header-dropdown">
                        <div *ngIf="ss.type == 3" class="header-text ss-color"  (click)="ssClicked(ss)" [ngClass]="{'rotate' : !includesTuple(ssShowed, [ss.id, ss.parentId])}">
                          <span>SS</span>
                          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.75535 5.18698C3.67535 5.18698 3.60035 5.17309 3.53035 5.14531C3.46035 5.11753 3.39757 5.07587 3.34202 5.02031L0.180986 1.85928C0.0550077 1.7333 -0.00520358 1.58976 0.000351973 1.42865C0.00590753 1.26753 0.0697964 1.12587 0.192019 1.00365C0.314241 0.881424 0.455908 0.820312 0.617019 0.820312C0.77813 0.820312 0.919796 0.881424 1.04202 1.00365L3.75869 3.73698L6.49202 1.00365C6.61424 0.881424 6.75591 0.82309 6.91702 0.828646C7.07813 0.834201 7.2198 0.89809 7.34202 1.02031C7.46424 1.14253 7.52535 1.2842 7.52535 1.44531C7.52535 1.60642 7.46236 1.74966 7.33639 1.87501L4.17535 5.02031C4.11535 5.07587 4.05035 5.11753 3.98035 5.14531C3.91035 5.17309 3.83535 5.18698 3.75535 5.18698Z" fill="white"/>
                            </svg>        
                        </div>
                        <div *ngIf="ss.type == 5" class="header-text dt-color"  (click)="dtClicked(ss)" [ngClass]="{'rotate' : !dtShowed.includes(ss.id)}">
                          <span>DT</span>
                          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.75535 5.18698C3.67535 5.18698 3.60035 5.17309 3.53035 5.14531C3.46035 5.11753 3.39757 5.07587 3.34202 5.02031L0.180986 1.85928C0.0550077 1.7333 -0.00520358 1.58976 0.000351973 1.42865C0.00590753 1.26753 0.0697964 1.12587 0.192019 1.00365C0.314241 0.881424 0.455908 0.820312 0.617019 0.820312C0.77813 0.820312 0.919796 0.881424 1.04202 1.00365L3.75869 3.73698L6.49202 1.00365C6.61424 0.881424 6.75591 0.82309 6.91702 0.828646C7.07813 0.834201 7.2198 0.89809 7.34202 1.02031C7.46424 1.14253 7.52535 1.2842 7.52535 1.44531C7.52535 1.60642 7.46236 1.74966 7.33639 1.87501L4.17535 5.02031C4.11535 5.07587 4.05035 5.11753 3.98035 5.14531C3.91035 5.17309 3.83535 5.18698 3.75535 5.18698Z" fill="white"/>
                            </svg>        
                        </div>
                      </div>
                      <div class="header-title">
                        <span>{{ss.name}}</span>
                      </div>
                    </div>
      
                    <div *ngIf="ss.type == 3" class="children-container">
                        <div class="items" [hidden]="!includesTuple(ssShowed, [ss.id, ss.parentId])">
                          
                          <div *ngFor="let f11 of ss.children; let f11i = index" class="item-container">
                            <div class="vertical-line ss-color"></div>
                            <div class="horizontal-line ss-color"></div>
                            <div *ngIf="f11i < ss.children.length - 1" class="vertical-item-line ss-color"></div>
                            <div class="item-header">
                              <div class="header-dropdown">
                                <div class="header-text f11-color"  (click)="f11Clicked(f11)" [ngClass]="{'rotate' : !f11Showed.includes(f11.id)}">
                                  <span>F11</span>
                                  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75535 5.18698C3.67535 5.18698 3.60035 5.17309 3.53035 5.14531C3.46035 5.11753 3.39757 5.07587 3.34202 5.02031L0.180986 1.85928C0.0550077 1.7333 -0.00520358 1.58976 0.000351973 1.42865C0.00590753 1.26753 0.0697964 1.12587 0.192019 1.00365C0.314241 0.881424 0.455908 0.820312 0.617019 0.820312C0.77813 0.820312 0.919796 0.881424 1.04202 1.00365L3.75869 3.73698L6.49202 1.00365C6.61424 0.881424 6.75591 0.82309 6.91702 0.828646C7.07813 0.834201 7.2198 0.89809 7.34202 1.02031C7.46424 1.14253 7.52535 1.2842 7.52535 1.44531C7.52535 1.60642 7.46236 1.74966 7.33639 1.87501L4.17535 5.02031C4.11535 5.07587 4.05035 5.11753 3.98035 5.14531C3.91035 5.17309 3.83535 5.18698 3.75535 5.18698Z" fill="white"/>
                                    </svg>        
                                </div>
                              </div>
                              <div class="header-title">
                                <span>{{f11.name}}</span>
                              </div>
                            </div>
              
                            <div class="children-container">
                                <div class="items" [hidden]="!f11Showed.includes(f11.id)">
                                  
                                  <div *ngFor="let dt of f11.children; let dti = index" class="item-container">
                                    <div class="vertical-line f11-color"></div>
                                    <div class="horizontal-line f11-color"></div>
                                    <div *ngIf="dti < f11.children.length - 1" class="vertical-item-line f11-color"></div>
                                    <div class="item-header">
                                      <div class="header-dropdown">
                                        <div class="header-text dt-color"  (click)="dtClicked(dt)" [ngClass]="{'rotate' : !dtShowed.includes(dt.id)}">
                                          <span>DT</span>
                                          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.75535 5.18698C3.67535 5.18698 3.60035 5.17309 3.53035 5.14531C3.46035 5.11753 3.39757 5.07587 3.34202 5.02031L0.180986 1.85928C0.0550077 1.7333 -0.00520358 1.58976 0.000351973 1.42865C0.00590753 1.26753 0.0697964 1.12587 0.192019 1.00365C0.314241 0.881424 0.455908 0.820312 0.617019 0.820312C0.77813 0.820312 0.919796 0.881424 1.04202 1.00365L3.75869 3.73698L6.49202 1.00365C6.61424 0.881424 6.75591 0.82309 6.91702 0.828646C7.07813 0.834201 7.2198 0.89809 7.34202 1.02031C7.46424 1.14253 7.52535 1.2842 7.52535 1.44531C7.52535 1.60642 7.46236 1.74966 7.33639 1.87501L4.17535 5.02031C4.11535 5.07587 4.05035 5.11753 3.98035 5.14531C3.91035 5.17309 3.83535 5.18698 3.75535 5.18698Z" fill="white"/>
                                            </svg>        
                                        </div>
                                      </div>
                                      <div class="header-title">
                                        <span>{{dt.name}}</span>
                                      </div>
                                    </div>
                      
                                    <div class="children-container">
                                        <div class="items" [hidden]="!dtShowed.includes(dt.id)">
                                          
                                          <div *ngFor="let acc of dt.children; let acci = index" class="item-container">
                                            <div class="vertical-line dt-color"></div>
                                            <div class="horizontal-line dt-color"></div>
                                            <div *ngIf="acci < dt.children.length - 1" class="vertical-item-line dt-color"></div>
                                            <div class="item-header">
                                              <div class="header-dropdown">
                                                <div class="header-text acc-color">
                                                  <span>ACC</span>
                                                </div>
                                              </div>
                                              <div class="header-title">
                                                <span>{{acc.name}}</span>
                                              </div>
                                            </div>
                              
                                            <div class="children-container">
                                                <div class="items">
                                                  
                                                  
                              
                                                </div>
                                            </div>
                                          </div>
                      
                                        </div>
                                    </div>
                                  </div>
              
                                </div>
                            </div>
                          </div>
      
                        </div>
                    </div>

                    <div *ngIf="ss.type == 5" class="children-container">
                      <div class="items" [hidden]="!dtShowed.includes(ss.id)">
                        
                        <div *ngFor="let acc of ss.children; let acci = index" class="item-container">
                          <div class="vertical-line dt-color dt-f33-margin-vertical"></div>
                          <div class="horizontal-line dt-color dt-f33-margin"></div>
                          <div *ngIf="acci < ss.children.length - 1" class="vertical-item-line dt-color dt-f33-margin"></div>
                          <div class="item-header dt-f33-header">
                            <div class="header-dropdown">
                              <div class="header-text acc-color">
                                <span>ACC</span>
                              </div>
                            </div>
                            <div class="header-title">
                              <span>{{acc.name}}</span>
                            </div>
                          </div>
            
                          <div class="children-container">
                              <div class="items">
                                
                                
            
                              </div>
                          </div>
                        </div>
    
                      </div>
                  </div>
                  </div>
                 
                </div>
              </div>
          </div>
        </div>
      
    </div>
  </div>
</div>