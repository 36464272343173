<main class="">
    <div class="user-profile">

        <div class="user-settings-content">

            <div class="first">
                <div class="title-container">
                    <div class="title-u">Customer Vending</div>
                    <div class="subtitle">Read Customer Vending</div>
                </div>
            </div>
        </div>
    </div>

    <div class="filters-container">
        <div class="multi-filter-wrapper">
            <app-range-datepicker [fromDate]="dateFrom" [toDate]="dateTo" [isMonthly]="true" [name]="'Period'"
                (dateRangeSelected)="dateChange($event)">
            </app-range-datepicker>
            <app-search-autocomplete #search [placeholder]="'Search by Meter Number, Account Number & SPN'" [backgroundColor]="'transparent'"
                [borderColor]="'#E8E8E8'" [(searchText)]="searchText" [searchLength]="2" [options]="options"
                (searchChange)="inputChanged($event)"
                (searchAutocompleteChange)="getOptions($event)"></app-search-autocomplete>
        </div>

    </div>

    <table class="standard-table gep-table">
        <thead>
            <tr>
                <th><span>METER S/N</span></th>
                <th><span>ACCOUNT NUMBER</span></th>
                <th><span>CUSTOMER NAME</span></th>
                <th><span>AMOUNT (KWH)</span></th>
                <th><span>AMOUNT (NGN)</span></th>
                <th><span>DATE</span></th>
                <th><span>TOKEN</span></th> 
            </tr>
        </thead>
        <tbody class="table-body">
            <tr *ngFor="let item of vends">
                <td class="large-td">
                    <span *ngIf="item.msn !== '/'" title="{{item.msn}}">{{item.msn | hasValue}}</span>
                    <span *ngIf="item.msn === '/'" class="NaN">N/A</span>
                </td>
                <td class="large-td">
                    <span *ngIf="item.accountNumber !== '/'" title="{{item.accountNumber}}">{{item.accountNumber |
                        hasValue}}</span>
                    <span *ngIf="item.accountNumber === '/'" class="NaN">N/A</span>
                </td>
                <td class="large-td">
                    <span *ngIf="item.customerName !== '/'" title="{{item.customerName}}">{{item.customerName |
                        hasValue}}</span>
                    <span *ngIf="item.customerName === '/'" class="NaN">N/A</span>
                </td>
                <td class="large-td">
                    <span *ngIf="item.vendsUnits !== '/'" title="{{item.vendsUnits}}">{{item.vendsUnits |
                        hasValue}}</span>
                    <span *ngIf="item.vendsUnits === '/'" class="NaN">N/A</span>
                </td>
                <td class="large-td">
                    <span *ngIf="item.vendAmount !== '/'" title="{{item.vendAmount}}">{{item.vendAmount |
                        hasValue}}</span>
                    <span *ngIf="item.vendAmount === '/'" class="NaN">N/A</span>
                </td>
                <td class="large-td">
                    <span *ngIf="item.vendDate !== '/'" title="{{item.vendDate}}">{{item.vendDate | date}}</span>
                    <span *ngIf="item.vendDate === '/'" class="NaN">N/A</span>
                </td>
                <td class="large-td">
                    <span *ngIf="item.token !== '/'" title="{{item.token}}">{{item.token | hasValue}}</span>
                    <span *ngIf="item.token === '/'" class="NaN">N/A</span>
                </td>

            </tr>
        </tbody>
    </table>
    <div class="pagination-wrapper">
        <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
            (pageChange)="pageChange($event)"></app-pagination>
    </div>