import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-commercial',
  templateUrl: './commercial.component.html',
  styleUrls: ['./commercial.component.scss']
})
export class CommercialComponent implements OnInit {

  public PermissionsEnum = PermissionsEnum;
  public billingUserId: boolean = false
  public havePermissions: boolean = false
  displayText: string = 'Tekst';
  public border: string = '1px solid rgba(133, 133, 133, 1)'

  isFirstBlockVisible = false;
  isSecondBlockVisible = false;
  constructor(private toastr: ToastrService, public authService: AuthService, private location: Location,
    private userService: UserService) { }

  ngOnInit(): void {
    //this.getUserBillingId()
    this.getPermissions()
  }

  navigateTo(url: string) {
    window.location.href = url
  }

  isBillingUser() {
    let perm = localStorage.getItem('rolePermissionAndSystemComponents')
    if (perm == undefined || perm == null || perm == '')
      return false

    return true
  }

  onClick(tab: number) {
    this.displayText = 'Select ';
    if (tab == 1) {
      this.isFirstBlockVisible = !this.isFirstBlockVisible;
      this.isSecondBlockVisible = false;
    }
    else if (tab == 2) {
      this.isFirstBlockVisible = false;
      this.isSecondBlockVisible = !this.isSecondBlockVisible;
    }
  }
  onMouseLeave() {
    this.isFirstBlockVisible = false;
    this.isSecondBlockVisible = false;

  }
  back() {
    this.location.back();
  }
  getPermissions() {
    if (localStorage.getItem('rolePermissionAndSystemComponents') == '') {
      this.havePermissions = false
    }
    else {
      this.havePermissions = true
    }
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }

  logout() {
    this.authService.logout();
  }

  // getUserBillingId(){
  //   this.userService.getBillingUserId().subscribe(response =>{
  //     if(response.data != null && response.data != 0){
  //       this.billingUserId = true
  //     }
  //     else
  //       this.billingUserId = false
  //   }, _ => {
  //     this.toastr.error('An error occurred.');
  //   });
  // }
}
