import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaymentChannel } from 'src/app/models/paymentChannel.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentChannelService } from 'src/app/services/paymentChannel.service';

@Component({
  selector: 'app-payment-channel-create',
  templateUrl: './payment-channel-create.component.html',
  styleUrls: ['./payment-channel-create.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(143%, 0, 0)'
      })),
      transition('in => out', animate('900ms ease-in-out')),
      transition('out => in', animate('900ms ease-in-out'))
    ]),
  ], host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class PaymentChannelCreateComponent {

  showShadow = false;
  @Input() animationState = 'out';
  public PermissionsEnum = PermissionsEnum;
  selectedChannel: any = {};

  @Output() parentComp = new EventEmitter();
  @ViewChild("f") f: any;

  constructor(private ref: ElementRef,
    private toastr: ToastrService,
    public authService: AuthService,
    private paymentChannelservice: PaymentChannelService
    ) { }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isSliderClicked){
          this.changeState(2);
        }
      }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  changeState(number: Number, data?: any) {
    if(data){
      this.selectedChannel = data;
    }
    else{
      this.selectedChannel = new PaymentChannel();
    }

    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
        this.showShadow = false;
        this.f.submitted = false;
    }
 }

 save(){
  if(!this.authService.isAuthorized(PermissionsEnum.revenueprotection_settings_createupdate))
  {
    this.toastr.error("You don't have permission.");
    return;
  }
  this.paymentChannelservice.save(this.selectedChannel).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.parentComp.emit()
        this.changeState(2)
      }else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });

  }
}



