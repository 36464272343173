import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { CaimsOperator } from 'src/app/models/caimsOperator.model';
import { Womteam } from 'src/app/models/womteam.model';

@Component({
  selector: 'app-dropdown-team-agents',
  templateUrl: './dropdown-team-agents.component.html',
  styleUrls: ['./dropdown-team-agents.component.scss']
})
export class DropdownTeamAgentsComponent {

  public search: string = ''
  public defaultImage: string = 'assets/images-new-design/defaultImg.png'

  @Input() isSelected: boolean = false;
  @Input() header: string = 'Select'
  @Input() dropdownHeader: string = 'Teams'
  @Input() data: any[] = []
  @Input() width: string = '31'
  @Input() selectedAgentId: number = 0
  @Input() selectedTeamId: number = 0
  @Input() isAgents: boolean = false

  @Output() selectItemChange: EventEmitter<any> = new EventEmitter()

  public agents: CaimsOperator[] = []
  public teams: Womteam[] = []

  public showData: any[] = []

  public searchOpened: boolean = false;
  private wasInside: boolean = false;

  constructor(
    private eRef: ElementRef,
    private ref: ChangeDetectorRef
    ) {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.data)
    {
      if(this.isAgents){
        this.agents = changes.data.currentValue
      }
      else{
        this.teams = changes.data.currentValue
      }

    }
    if(changes.selectedItem){
      if(changes.selectedItem.currentValue.name != null && changes.selectedItem.currentValue.name != undefined && changes.selectedItem.currentValue.name != '')
        this.header = changes.selectedItem.currentValue.name
      else{
        this.header = 'Select'
      }
    }

    if(changes.selectedAgent != undefined && changes.selectAgent != null){
      if(changes.selectAgent.currentValue != undefined){
        this.selectedAgentId = changes.selectAgent.currentValue.userGuid
        this.header = changes.selectAgent.currentValue.firstName
      }
      else{
        this.selectedAgentId = 0
      }
    }

  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any){
    const clickedInside = this.eRef.nativeElement.contains(target);
  }

  close(){
    this.isSelected = false
  }

  selectAgent(item: CaimsOperator){
    this.header = item.userName
    this.isSelected = false
    this.selectedAgentId = item.id
    this.selectItemChange.emit(this.selectedAgentId)
  }

  selectTeam(item: Womteam){
    this.header = item.name
    this.isSelected = false
    this.selectedTeamId = item.id
    this.selectItemChange.emit(this.selectedTeamId)
  }

  searchChanged(){
    if(this.isAgents){
      this.agents = this.data
      this.agents = this.agents.filter(x => x.userName.toLowerCase().includes(this.search.toLowerCase()))
    }
    else{
      this.teams = this.data
      this.teams = this.teams.filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()))
    }

  }
}
