<main class="container-fluid">
    <div class="container-wrapper">

        <app-stock-management-main *ngIf="activeView == viewsEnum.View"
            (changeView)="changeView($event)"
            (setItemId)="setItemId($event)"
        ></app-stock-management-main>
        
        <app-stock-management-create *ngIf="activeView == viewsEnum.Create"
            (changeView)="changeView($event)">
        </app-stock-management-create>

        <app-stock-management-create *ngIf="activeView == viewsEnum.Edit"
            (changeView)="changeView($event)"
            [itemId]="itemId"
        ></app-stock-management-create>
    
        <app-stock-management-details *ngIf="activeView == viewsEnum.ShowDetails"
            (changeView)="changeView($event)"
            [itemId]="itemId"
        ></app-stock-management-details>

        <app-item-issuance *ngIf="activeView == viewsEnum.IssueItem"
            (changeView)="changeView($event)"
            [itemId]="itemId"
        ></app-item-issuance>

    </div>
</main>