import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Translation } from 'src/app/models/translation.model';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-translation-create',
  templateUrl: './translation-create.component.html',
  styleUrls: ['./translation-create.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(143%, 0, 0)'
      })),
      transition('in => out', animate('900ms ease-in-out')),
      transition('out => in', animate('900ms ease-in-out'))
    ]),
  ], 
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class TranslationCreateComponent implements OnInit {

  showShadow = false;
  @Input() animationState = 'out';
  @Output() parentComp = new EventEmitter();

  translations : Translation[] = [];
  public translation1 : Translation = new Translation();
  public translation2 : Translation = new Translation();
  public translation3 : Translation = new Translation();
  public translation4 : Translation = new Translation();
  public translation5 : Translation = new Translation();
  public translation6 : Translation = new Translation();
  public translation7 : Translation = new Translation();

  constructor(private toastr: ToastrService,
              private translationService: TranslationService,
              private _eref: ElementRef) { }

  ngOnInit(): void {
    this.loadTranslations();
  }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.animationState === 'in')
      if (this._eref.nativeElement.contains(event.target) || event.target === this._eref.nativeElement) {
        if (!isSliderClicked)
          this.changeState(2);
      }
  }

  closeTab() {
    this.changeState(2);
  }

  changeState(number: Number) {
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  save() {
    this.translations = [this.translation1, this.translation2, this.translation3, this.translation4, this.translation5, this.translation6, this.translation7];

    this.translationService.save(this.translations).subscribe(response => {
      this.changeState(2);
      this.parentComp.emit();
    })
  }

  loadTranslations(){
    this.translationService.getAll().subscribe(response => {
      this.translation1 = response.data[0];
      this.translation2 = response.data[1];
      this.translation3 = response.data[2];
      this.translation4 = response.data[3];
      this.translation5 = response.data[4];
      this.translation6 = response.data[5];
      this.translation7 = response.data[6];
    }, error => {
      this.toastr.error('An error occured');
    });
  }
}
