<div class="filters-container">
   <div class="multi-filter-wrapper">

      <app-range-datepicker [name]="'Period'" [isMonthly]="false" [fromDate]="dateFrom" [toDate]="dateTo"
         (dateRangeSelected)="DateClickEvent($event)">
      </app-range-datepicker>

   </div>
   <app-download-button-new
        [isOnlyDownload]="true"
        (downloadButtonClickedChange)="download()"
        [onlyDownloadText] = "'Download Total Forwarded Energy'"
        >
    </app-download-button-new>
</div>
<main class="container-fluid consumption-wrapper">
   <div class="row">
      <div class="col-6">
         <div class="row justify-content-end">
            <div class="col-12 text-center">
               <p class="chart-title">Consumption</p>
            </div>
         </div>
         <div class="row align-items-center">
            <div class="col-10 chart-bar">
               <canvas baseChart #chart [datasets]="lineChartData" [labels]="lineChartLabels"
                  [options]="lineChartKwhOptions" [type]="lineChartType" height="450">
               </canvas>
            </div>
         </div>
      </div>
      <div class="col-6">
         <div class="row justify-content-end">
            <div class="col-12 text-center">
               <p class="chart-title">Status on/off</p>
            </div>
         </div>
         <div class="row align-items-center">
            <div class="col-10 chart-bar">
               <canvas baseChart #chart [datasets]="statusOnOffChartData" [labels]="statusOnOffLabels"
                  [options]="lineChartVAOptions" [type]="lineChartType" height="450">
               </canvas>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-6">
         <div class="row justify-content-end">
            <div class="col-12 text-center">
               <p class="chart-title">Current</p>
            </div>
         </div>
         <div class="row align-items-center">
            <div class="col-10 chart-bar">
               <canvas baseChart #chart [datasets]="currentLineChartData" [labels]="currentLineChartLabels" [options]="lineChartAOptions"
               [type]="lineChartType" height="450">
            </canvas>
            </div>
         </div>
      </div>
      <div class="col-6">
         <div class="row justify-content-end">
            <div class="col-12 text-center">
               <p class="chart-title">Voltage</p>
            </div>
         </div>
         <div class="row align-items-center">
            <div class="col-10 chart-bar">
               <canvas baseChart #chart [datasets]="volLineChartData" [labels]="volLineChartLabels" [options]="lineChartVOptions"
               [type]="lineChartType" height="450">
            </canvas>
            </div>
         </div>
      </div>
   </div>

   <div class="row">
      <div class="col-6">
         <div class="row justify-content-end">
            <div class="col-12 text-center">
               <p class="chart-title">Power Factor</p>
            </div>
         </div>
         <div class="row align-items-center">
            <div class="col-10 chart-bar">
               <canvas baseChart #chart [datasets]="powFacLineChartData" [labels]="powFacLineChartLabels"
               [options]="lineChartKWOptions" [type]="lineChartType" height="450">
            </canvas>
            </div>
         </div>
      </div>
      <div class="col-6">
         <div class="row justify-content-end">
            <div class="col-12 text-center">
               <p class="chart-title">Demand</p>
            </div>
         </div>
         <div class="row align-items-center">
            <div class="col-10 chart-bar">
               <canvas baseChart #chart [datasets]="demandChartData" [labels]="demandLabels"
               [options]="lineChartKWOptions" [type]="lineChartType" height="450">
            </canvas>
            </div>
         </div>
      </div>
   </div>

   <div class="row">
      <div class="col-6">
         <div class="row justify-content-end">
            <div class="col-12 text-center">
               <p class="chart-title">Total forward energy</p>
            </div>
         </div>
         <div class="row align-items-center">
            <div class="col-10 chart-bar">
               <canvas baseChart #chart [datasets]="tfeLineChartData" [labels]="tfeLineChartLabels"
               [options]="lineChartKwhOptions" [type]="lineChartType" height="450">
            </canvas>
            </div>
         </div>
      </div>
   </div>
</main>
