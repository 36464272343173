import { Component, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AssetHierarchy } from 'src/app/models/assetHierarchy.model';
import { SearchAutocomplete, Type } from 'src/app/models/searchAutocomplete.model';
import { AmiService } from 'src/app/services/ami.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommonService } from 'src/app/services/common-service.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { HierarchyItemComponent } from './hierarchy-item/hierarchy-item.component';

@Component({
  selector: 'app-system-hierarchy',
  templateUrl: './system-hierarchy.component.html',
  styleUrl: './system-hierarchy.component.scss'
})
export class SystemHierarchyComponent {
  @ViewChild('hierarchyItem') hierarchyItem!: HierarchyItemComponent

  public searchType: Type = Type.TransmissionStation

  public assets: AssetHierarchy[] = []
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false

  public searchPlaceholder: string = "Search by TS, F33, SS, F11 or DT"
  public searchText: SearchAutocomplete = new SearchAutocomplete()

  public dtName: string = this.translationService.getByKeyFromCache('DT')
  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')
  public f11Name: string = '11KV Feeders'
  public f33Name: string = '33KV Feeders'
  public ssName: string = 'Substations'
  public tsName: string = 'Transmission Stations'

  public dtIds: number[] = [];
  public f11Ids: number[] = [];
  public f33Ids: number[] = [];
  public tsIds: number[] = [];
  public ssIds: number[] = [];

  public bus: any = { name: this.buName, data: [], dropdown: true };
  public uts: any = { name: this.utName, data: [], dropdown: true };
  public transmissionStations: any = { name: this.tsName, data: [], dropdown: true };
  public substations: any = { name: this.ssName, data: [], dropdown: true };
  public feeders11: any = { name: this.f11Name, data: [], dropdown: true };
  public feeders33: any = { name: this.f33Name, data: [], dropdown: true };
  public dts: any = { name: this.dtName, data: [], dropdown: true };

  public tsSelected: any = {value: 0, name: '', checked: false}
  public ssSelected: any = {value: 0, name: '', checked: false}
  public f11Selected: any = {value: 0, name: '', checked: false}
  public f33Selected: any = {value: 0, name: '', checked: false}
  public dtSelected: any = {value: 0, name: '', checked: false}
  public buSelected: any = {value: 0, name: '', checked: false}
  public utSelected: any = {value: 0, name: '', checked: false}

  constructor(
    private toastr: ToastrService,
    private commonService: CommonService,
    private buService: BusinessUnitService,
    private utService: UndertakingService,
    private dtService: DtService,
    private amiService: AmiService,
    private translationService: TranslationService,
    private f11Service: Feeder11Service,
    private f33Service: Feeder33Service,
    private tsService: TransmissionStationService,
    private ssService: SubstationService
  ) {}

  ngOnInit(): void {
    // this.getAllDTs()
    // this.getAllF11()
    // this.getAllF33()
    // this.getAllTs()
    // this.getAllSs()
    this.getAllBus()
    this.getAllUts()
    this.searchText.type = Type.TransmissionStation
  }

  //multifilter
  dropdownSelected(data: any){
    if(!data)
      return
    if(data.data.name === this.tsName)
      this.SelectTsFilter(data.item)
    if(data.data.name === this.ssName)
      this.SelectSsFilter(data.item)
    if(data.data.name === this.f11Name)
      this.selectAllFeeders11Filter(data.item)
    if(data.data.name === this.f33Name)
      this.selectAllFeeders33Filter(data.item)
    if(data.data.name === this.dtName)
      this.selectAllDtsFilter(data.item)
    if(data.data.name === this.buName)
      this.selectAllBuFilter(data.item)
    if(data.data.name === this.utName)
      this.selectAllUtFilter(data.item)
  }

  public selectAllBuFilter(bu: any) {
    this.buSelected = bu;
  }

  public selectAllUtFilter(ut: any) {
    this.utSelected = ut;
  }

  public selectAllDtsFilter(dt: any) {
    this.dtSelected = dt;
  }

  public SelectSsFilter(ss: any) {
    this.ssSelected = ss;
  }

  public selectAllFeeders11Filter(feed: any) {
    this.f11Selected = feed
  }

  public selectAllFeeders33Filter(feed: any) {
    this.f33Selected = feed
  }

  public SelectTsFilter(ts: any) {
    this.tsSelected = ts
  }

  getAllSs() {
    this.ssService.getAllForSelect().subscribe(response => {
      this.substations = { name: this.ssName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllBus() {
    this.buService.getAllForSelect().subscribe(response => {
      this.bus = { name: this.buName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllUts() {
    this.utService.getAllForSelect().subscribe(response => {
      this.uts = { name: this.utName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllTs() {
    this.tsService.getAllForSelect().subscribe(response => {
      this.transmissionStations = { name: this.tsName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllF33() {
    this.f33Service.getAllForSelect().subscribe(response => {
      this.feeders33 = { name: this.f33Name, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllF11() {
    this.f11Service.getAllForSelect().subscribe(response => {
      this.feeders11 = { name: this.f11Name, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllDTs() {
    this.dtService.getAllForSelect().subscribe(response => {
      this.dts = { name: this.dtName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  checkFilters() {
    this.filterApplied = true;

    // let dtData = this.checkData(this.dts)
    // let f11Data = this.checkData(this.feeders11)
    // let f33Data = this.checkData(this.feeders33)
    // let tsData = this.checkData(this.transmissionStations)
    // let ssData = this.checkData(this.substations)
    let buData = this.checkData(this.bus)
    let utData = this.checkData(this.uts)


    // if (!tsData)
    //   this.allFilters.push(this.transmissionStations)
    // if (!f33Data)
    //   this.allFilters.push(this.feeders33)
    // if (!ssData)
    //   this.allFilters.push(this.substations)
    // if (!f11Data)
    //   this.allFilters.push(this.feeders11)
    // if (!dtData)
    //   this.allFilters.push(this.dts)
    if (!buData)
      this.allFilters.push(this.bus)
    if (!utData)
      this.allFilters.push(this.uts)


    this.allFilters = [...this.allFilters]
  }

  checkData(data: any) : any{
    let resultData = this.allFilters.find((item: { name: any; }) => item.name === data.name);
    let index = this.allFilters.findIndex((item: { name: any; }) => item.name === data.name);
    if (index !== -1) {
      this.allFilters[index].data = data.data;
    }

    return resultData
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)

      if (item.name === this.tsName) {
        this.tsIds = filteredValues
      }
      else if (item.name === this.f33Name) {
        this.f33Ids = filteredValues
      }
      else if (item.name === this.ssName) {
        this.ssIds = filteredValues
      }
      else if (item.name === this.f11Name) {
        this.f11Ids = filteredValues
      }
      else if (item.name === this.dtName) {
        this.dtIds = filteredValues
      }
    }
  }

  searchChange(text: SearchAutocomplete) {
    this.searchText.type = this.searchType
    this.searchText.name = text.name
    if (this.searchText.name == ''){
      this.searchText.type = Type.TransmissionStation
      this.hierarchyItem.applySearch(this.searchText)
    }
    else if(this.searchText.name.trim().length >= 3 || this.searchText.type > Type.Dt){
      this.hierarchyItem.applySearch(this.searchText)
    }
    else{
      this.toastr.warning('Minimum number of caracters in search is 3')
    }
  }

  searchTypeSelected(type: any){
    this.searchType = type.target.value;
    // switch(this.searchType){
    //   case Type.TransmissionStation:
    //     this.searchPlaceholder = 'Search by TS'
    //     break
    //   case Type.Feeder33KV:
    //     this.searchPlaceholder = 'Search by F33'
    //     break
    //   case Type.Substation:
    //     this.searchPlaceholder = 'Search by SS'
    //     break
    //   case Type.Feeder11KV:
    //     this.searchPlaceholder = 'Search by F11'
    //     break
    //   case Type.Dt:
    //     this.searchPlaceholder = 'Search by DT'
    //     break
    // }
   }
}
