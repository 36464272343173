<div class="overdue-postpaid-bills">
    <div class="title">Overdue Postpaid Bill Debts</div>

    <div class="overdue-postpaid-bills-wrapper">
        <div class="tabs-padding">
            <app-tab-default
            [firstText]="NMD"
            [secondText]="MD"
            [nmDashCss]="true"
            (tabChanged)="filterClicked($event)"
            ></app-tab-default>
        </div>
        
        <table class="standard-table">
            <thead>
                <tr class="second-header">
                    <th class="header">{{buName | uppercase}}</th>
                    <th class="wide">CUSTOMERS > 20K BUT &le; 100K</th>
                    <th>CUSTOMERS > 100K BUT &le; 200K</th>
                    <th class="narrow">CUSTOMERS > 200K</th>
                    <th>TOTAL CUSTOMERS</th>
                </tr>
            </thead>
            <tbody class="customers-items">
                <tr *ngFor="let item of data">
                    <td class="table-item">{{item.buName}}</td>
                    <td class="table-item">{{item.range1 | number}}</td>
                    <td class="table-item">{{item.range2 | number}}</td>
                    <td class="table-item">{{item.range3 | number}}</td>
                    <td class="table-item">{{item.total | number}}</td>
                </tr>
                <tr>
                    <td class="total average"><span>GRAND TOTAL</span></td>
                    <td class="average"></td>
                    <td class="average"></td>
                    <td class="average"></td>
                    <td class="total-num average"><span>{{grandTotal | number}}</span></td>
                </tr>     
            </tbody>
        </table>
        
        <div class="pagination-wrapper">
            <app-pagination 
               [count]="count" 
                [currentPage]="currentPage" 
                [pageSize]="pageSize"
                (pageChange)="pageChanged($event)">
            </app-pagination>
        </div>
        
    </div>
</div>