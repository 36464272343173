import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { FieldAgentTeamDTO } from '../models/field-agent';

@Injectable({
  providedIn: 'root'
})
export class FieldAgentsService {

  private pathApi = this.config.setting['pathApi'] + "caimsOperators/";

  constructor(private http: HttpClient,
    private config: AppConfig) { }

  getAllByTeam(teamId: number): Observable<any> {
    return this.http.get(`${this.pathApi}getByTeam/${teamId}`);
  }
  activateFieldAgent(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}activateFieldAgent`, obj);
  }
  getAllFieldAgents(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllFieldAgents`, obj);
  }
  getCount(): Observable<any> {
    return this.http.get(`${this.pathApi}getCount`);
  } 
  getFieldAgentCount(obj:any): Observable<any> {
    return this.http.post(`${this.pathApi}getFieldAgentCount`,obj);
  } 
  getAllEngagedFieldAgents(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllEngagedFieldAgents`, obj);
  } 
  createOrEditAgent(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}createOrEditAgent`, obj)
  } 
  getAllInactiveFieldAgents(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllInactiveFieldAgents`, obj);
  }
  getAllUnengaged(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllUnengaged`, obj);
  }
  getAll(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, obj);
  }

  get(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}getById/${id}`);
  }

  getAllUnassigned(): Observable<any> {
    return this.http.get(`${this.pathApi}getAllUnassigned`);
  }

  unassign(agentId: number): Observable<any> {
    return this.http.post(`${this.pathApi}unassign/${agentId}`, null);
  }

  assign(obj: FieldAgentTeamDTO): Observable<any> {
    return this.http.post(`${this.pathApi}assignToTeam`, obj);
  }
  getDataForAutocomplete(obj:any): Observable<any> {
    return this.http.post(`${this.pathApi}getDataForAutocomplete`,obj)
  }

  getByEmail(email: any): Observable<any> {
    return this.http.get(`${this.pathApi}getAgent/${email}`);
  }
}