import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { Substation } from 'src/app/models/substation.model';
import { AuthService } from 'src/app/services/auth.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { SubstationService } from 'src/app/services/substation.service';

@Component({
  selector: 'app-create-substation',
  templateUrl: './create-substation.component.html',
  styleUrls: ['./create-substation.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class CreateSubstationComponent {
  public selectedSubstation: Substation = new Substation()
  showShadow = false;
  selectedFeeder: string = 'Select Feeder33';
  public filterBySsSelected: string = '';
  @Output() parentComp = new EventEmitter();
  @ViewChild("f") f: any;

  @Input() feeders33: any = [];

  @Input() animationState = 'out';
  @ViewChild(CreateSubstationComponent) popupComponentRef!: CreateSubstationComponent;

  constructor(private toastr: ToastrService,
    private ref: ElementRef,
    private substationService: SubstationService,
    private authService: AuthService,
    private feederService: Feeder33Service) { }

  public selectF33Filter(feed: any) {
    this.selectedFeeder=feed.name
    this.filterBySsSelected=feed.value
    this.selectedSubstation.feeders33.push({id:this.filterBySsSelected, name:this.selectedFeeder});

  }
  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }
  // addItem() {
  //   const feeder = this.selectedSubstation.feeders33.find((obj) => {
  //     return obj.id === this.selectedFeeder.value;
  //   });

  //   if(feeder){
  //     this.toastr.error(`Feeder33 ${this.selectedFeeder.name} already exists in the list.`);
  //     return;
  //   }

  //   this.selectedSubstation.feeders33.push({id:this.selectedFeeder.value, name:this.selectedFeeder.name});
  //   this.selectedFeeder = {}
  // }

  closeTab() {
    this.popupComponentRef.changeState(2);
    this.selectedFeeder = 'Select Feeder33';
  }
  deleteItem(id: any){
    let index = this.selectedSubstation.feeders33.findIndex(d => d.id === id);
    this.selectedSubstation.feeders33.splice(index, 1);
  }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.substation-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }

  save(){
    console.log("ss",this.selectedSubstation)
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    if(Number.isNaN(Number(this.selectedSubstation?.latitude))){
      this.toastr.error('Latitude must be numeric value between -90 and 90.');
      return;
    }

    if(Number(this.selectedSubstation?.latitude) < -90 || Number(this.selectedSubstation?.latitude) > 90){
      this.toastr.error('Latitude must be in -90 to 90 range.');
      return;
    }

    if(Number.isNaN(Number(this.selectedSubstation?.longitude))){
      this.toastr.error('Longitude must be numeric value between -180 and 180.');
      return;
    }

    if(Number(this.selectedSubstation?.longitude) < -180 || Number(this.selectedSubstation?.longitude) > 180){
      this.toastr.error('Longitude must be in -180 to 180 range.');
      return;
    }

    // if(this.selectedSubstation?.feeders33.length == 0) {
    //   this.toastr.error('Feeder33 must be provided.');
    //   return;
    // }

    if((this.selectedSubstation.name && this.selectedSubstation.latitude && this.selectedSubstation.longitude) != "" && this.selectedSubstation.feeders33.length != 0){
      this.substationService.save(this.selectedSubstation).subscribe(response => {
        if(response.status === 200){
          this.toastr.success(response.message);
          this.changeState(2);
          this.resetSelectedSubstation();
          this.parentComp.emit();
        }else
          this.toastr.error(response.message);
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }

  }

  changeState(number: Number, data?: any) {
    if (data)
      this.selectedSubstation = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
      if(this.f)
        this.f.submitted = false;
      this.selectedFeeder = 'Select Feeder33';
    }
  }

  resetSelectedSubstation() {
    this.selectedSubstation = new Substation();
  }
}
