import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/models/config';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigRevenueProtectionService } from 'src/app/services/config-revenue-protection.service';
import { Location } from '@angular/common';
import { ConfigurationSettingsCreateComponent } from './configuration-settings-create/configuration-settings-create.component';

@Component({
  selector: 'app-configuration-settings',
  templateUrl: './configuration-settings.component.html',
  styleUrls: ['./configuration-settings.component.scss']
})
export class ConfigurationSettingsComponent implements OnInit {
  @ViewChild('closeModal') closeModal!: ElementRef;

  public PermissionsEnum = PermissionsEnum;

  public flags: Config[] = []
  public flag1: Config = new Config()
  public flag2: Config = new Config()
  public flag3: Config = new Config()

  public configBills: Config[] = []
  @ViewChild(ConfigurationSettingsCreateComponent) popupComponentRef!: ConfigurationSettingsCreateComponent;

  @ViewChild("f") f: any;
 
  constructor(
    private toastr: ToastrService,
    public authService: AuthService,
    private configService: ConfigRevenueProtectionService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.getBillsConfiguration()

  }
  back(){
    this.location.back();
  } 
  closeTab() {
    this.popupComponentRef.changeState(2);
  }

  openTab(id?:number) { 
    console.log("id",id)
    if(id===0){
      this.popupComponentRef.changeState(1); 
    }else{
    this.popupComponentRef.changeState(1);
   }
  }
  getBillsConfiguration(){
    this.configService.getAll().subscribe(response =>{
      if(response.status == 200){
        this.configBills = response.data
        this.flag1.key = this.configBills[0].key
        this.flag2.key = this.configBills[1].key
        this.flag3.key = this.configBills[2].key

        this.flag1.value = this.configBills[0].value
        this.flag2.value = this.configBills[1].value
        this.flag3.value = this.configBills[2].value

        this.flag1.name = this.configBills[0].name
        this.flag2.name = this.configBills[1].name
        this.flag3.name = this.configBills[2].name
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  updateSMSFlag(value: string){
    this.flag3.value = value
  }



  reset(){
    this.flag1.value = this.configBills[0].value
    this.flag2.value = this.configBills[1].value    
    this.flag3.value = this.configBills[2].value   

    if(this.f)
      this.f.submitted = false;
  }

}
