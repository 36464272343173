<div class="grid-losses">
  
 <div class="title">Grid Losses Analysis</div>
 <div class="grid-losses-wrapper">
   <div class="tabs-padding">
   <app-tab-default [firstText]="tab1"
                    [secondText]="tab2"
                    [nmDashCss]="true"
                    (tabChanged)="filterClicked($event)">
   </app-tab-default>
 </div>
   <table class="standard-table">
     <thead>
       <tr>
         <td class="asset-name">
           <span *ngIf="tab1Active">Sub-Station</span>
           <span *ngIf="!tab1Active">Feeder</span>
         </td>
         <td class="gray">0 - 5%</td>
         <td class="gray">5 - 10%</td>
         <td class="gray">10 - 15%</td>
         <td class="gray">ABOVE 15%</td>
       </tr>
     </thead>
     <tbody>
       <tr *ngFor="let item of slicedItems">
         <td class="black">
           {{item.ssName}}
         </td>
         <td>
           <span>{{item.a}}</span>
         </td>
         <td>
           <span>{{item.b}}</span>
         </td>
         <td>
           <span>{{item.c}}</span>
         </td>
         <td>
           <span>{{item.d}}</span>
         </td>

       </tr>
     </tbody>
   </table>

   <div class="pagination-wrapper">
     <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChanged($event)"></app-fo-pagination-new>

   </div>
 </div>
</div>
