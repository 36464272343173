import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Feeder11TabComponent } from './feeder11-tab/feeder11-tab.component';
import { Feeder33TabComponent } from './feeder33-tab/feeder33-tab.component';

@Component({
  selector: 'app-feeders-tab',
  templateUrl: './feeders-tab.component.html',
  styleUrls: ['./feeders-tab.component.scss']
})
export class FeedersTabComponent{

  public crudF33Visible: boolean = true;
  public crudF11Visible: boolean = true;

  @ViewChild('feeders33TabComponent') feeders33TabComponent!: Feeder33TabComponent;
  @ViewChild('feeders11TabComponent') feeders11TabComponent!: Feeder11TabComponent ;


  @Output() tabChange = new EventEmitter<number>();

  activeTab: number = 1;

  public tabMenu: number = 1;


  @Output() editTab = new EventEmitter();
  @Output() tsSelect = new EventEmitter();
  @Output() amisSelect = new EventEmitter();
  @Output() deleteComp = new EventEmitter();
  @Output() f33Select = new EventEmitter();
  @Output() busSelect = new EventEmitter();
  @Output() subSelect = new EventEmitter();

  changeTab(textLabel: any) {
    this.activeTab = textLabel.index;
  }

  edit(item: any){
    this.editTab.emit(item);
  }

  tsForSelect(item: any) {
    this.tsSelect.emit(item);
  }

  amisForSelect(item : any){
    this.amisSelect.emit(item);
  }

  delete() {
    this.deleteComp.emit();
  }

  f33sForSelect(item: any){
    this.f33Select.emit(item);
  }

  busForSelect(item: any){
    this.busSelect.emit(item);
  }

  subsForSelect(item: any){
    this.subSelect.emit(item);
  }

  tabChanged(value: number){
    this.activeTab = value
    this.tabChange.emit(value)
  }
}
