import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/models/config';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigFieldOperationService } from 'src/app/services/config-field-operation.service';

@Component({
  selector: 'app-dt-field-settings-edit',
  templateUrl: './dt-field-settings-edit.component.html',
  styleUrls: ['./dt-field-settings-edit.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(143%, 0, 0)'
      })),
      transition('in => out', animate('900ms ease-in-out')),
      transition('out => in', animate('900ms ease-in-out'))
    ]),
  ], 
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class DtFieldSettingsEditComponent implements OnInit {

  @ViewChild("f") f: any;

  showShadow = false;
  configs: Config[] = [];
  tempConfigs: Config[] = [];
  @Input() animationState = 'out';
  @Output() parentComp = new EventEmitter();

  constructor(private toastr: ToastrService,
              private configService: ConfigFieldOperationService,
              private authService: AuthService,
              private _eref: ElementRef) { }

  ngOnInit(): void {
    // this.loadConfigs();
  }

  loadConfigs(){
    this.configService.getAll().subscribe(response => {
      if (response.status == 200) {
        this.configs = response.data
        this.tempConfigs = this.configs;
      }
      else 
        this.toastr.error(response.message)
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  openTab(){
    this.changeState(1);
    this.loadConfigs();
  }

  changeState(number: Number) {
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  save() {
    if(!this.authService.isAuthorized(PermissionsEnum.fieldoperations_settings_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }
    
    if (this.f.valid) {
      this.configService.saveConfiguration(this.configs).subscribe(response =>{
        if (response.status == 200){
          this.parentComp.emit();
          this.changeState(2);
          this.toastr.success('Successfully saved.');
        }
        else {
          this.toastr.error(response.message)
        }
      }, _ => {
        this.toastr.error("An error occurred")
      })
    }
  }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.animationState === 'in')
      if (this._eref.nativeElement.contains(event.target) || event.target === this._eref.nativeElement) {
        if (!isSliderClicked)
          this.changeState(2);
      }
  }

  closeTab() {
    this.changeState(2);
  }
}
