import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { SearchAutocompleteComponent } from '../common-new-design/search-autocomplete/search-autocomplete.component';

@Component({
  selector: 'app-consumptions-settings',
  templateUrl: './consumptions-settings.component.html',
  styleUrl: './consumptions-settings.component.scss'
})
export class ConsumptionsSettingsComponent implements OnInit {

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public data: any[] = [];

  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase();
  public utName: string = this.translationService.getByKeyFromCache('UT').toUpperCase();
  public search: any = '';

  // Search Autocomplete
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []
  public searchLength: number = 1

  public showFilter: any = false
  public allFilters: any = []
  public filterApplied: boolean = false;
  @ViewChild('search') searchChild!: SearchAutocompleteComponent

  public undertakingsNew: any = { name: this.utName, data: [] };
  public businessUnitsNew: any = { name: this.buName, data: [], dropdown: true };

  public buId: number = 0;
  public utIds: any = [];

  //datepicker
  public dateFrom: any = ''
  public dateTo: any = ''
  constructor(
    private meterService: MeterService,
    private translationService: TranslationService,
    private toastr: ToastrService,
    private buService: BusinessUnitService,
    private utService: UndertakingService
  ) { }
  ngOnInit(): void {
    this.getAllBUs()
    this.setMainDate()
    this.reloadTable()
  }

  setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()
    
    dteFrom.setHours(2,0,0);
    dte.setHours(2,0,0);
    dteFrom.setDate(dteFrom.getDate() - 1)
    
    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() , day: 1};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
  }
  pageChange(value: any) {
    this.currentPage = value;
    // this.masterSelected = false;
    this.reloadTable();
  }
  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    this.reloadTable()
  }

  copyToClipboard(text: any) {
    const textToCopy = text;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    this.toastr.success('Copied to clipboard')

  }
  
  setFilters(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);

      if (item.name === this.undertakingsNew.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.undertakingsNew = item
        this.utIds = filteredValues
      }
    }
    this.pageChange(1);
  }
  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        BuId: this.buId,
        UtIds: this.utIds,
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        Search: this.search.name
      }
    };

    this.meterService.getConsumption(obj).subscribe(response => {
      if (response.data) {
      this.data = response.data?.data;
      if (obj.pageInfo.page == 1) {
        this.count = response.data?.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
        };
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getAllBUs() {
    this.buService.getAllForSelect().subscribe(response => {
      this.businessUnitsNew = { name: this.buName, data: response.data, dropdown: true }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  businessUnitSelected(item: any) {
    if (!item.item)
      this.buId = 0
    if (item.item && this.buId != item.item.value) {
      this.buId = item.item.value;
      this.utService.getAllByBuId(this.buId).subscribe(response => {
        this.undertakingsNew = { name: this.utName, data: response.data };
        this.checkFilters()
        this.pageChange(1);
      }, error => {
        this.toastr.error('An error occurred.');
      })
    }
    if (this.buId == 0) {
      this.utIds = [];
      this.undertakingsNew.data.splice(0);
      this.pageChange(1);
      this.checkFilters()
    }
  }

  //Check Filter for multi filter component
  checkFilters() {
    this.filterApplied = true;
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnitsNew.name);

    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnitsNew.name);
    if (buIndex !== -1) {
      this.allFilters[buIndex].data = this.businessUnitsNew.data;
    }
    let utData = this.allFilters.find((item: { name: any; }) => item.name === this.undertakingsNew.name);

    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakingsNew.name);
    if (utIndex !== -1) {
      this.allFilters[utIndex].data = this.undertakingsNew.data;
    }

    if (!buData)
      this.allFilters.push(this.businessUnitsNew)
    if (!utData)
      this.allFilters.push(this.undertakingsNew)

    this.allFilters = [...this.allFilters]
  }

  inputChanged(searchText: string) {
    this.search = searchText
    this.reloadTable()
  }
  // Search Autocomplete
  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    if (searchText.name.length > 2) {
      this.meterService.getDataForAutocomplete(obj)
        .subscribe({
          next: (response) => this.options = response.data,
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
    }
  }
} 
