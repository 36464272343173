import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-unread-indicator',
  templateUrl: './unread-indicator.component.html',
  styleUrls: ['./unread-indicator.component.scss']
})
export class UnreadIndicatorComponent {
  @Input() unreadCount : number = 0
}
