import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StockItem } from 'src/app/models/stock-item.model';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';
import { InventoryManagementService } from 'src/app/services/inventory-management.service';

@Component({
  selector: 'app-stock-management-details',
  templateUrl: './stock-management-details.component.html',
  styleUrls: ['./stock-management-details.component.scss']
})
export class StockManagementDetailsComponent implements OnInit {

  @Output() changeView: EventEmitter<any> = new EventEmitter()
  @Input() itemId: number = 0

  public item: StockItem = new StockItem
  public inventory: any[] = []
  public viewsEnum = ViewsEnum
  public activeView: ViewsEnum = ViewsEnum.View

  constructor(private toastr: ToastrService,
    private inventoryService: InventoryManagementService) { }
  
  ngOnInit(): void {
    this.getSingleStockItem()
    this.getInventoryDetails()
  }

  public changeViewFromChild(value: ViewsEnum){
    this.changeView.emit(value)
  }
  
  getSingleStockItem(){
    this.inventoryService.getSingleStockItem(this.itemId).subscribe(response => {
      this.item = response.data;
    },  _=>{
      this.toastr.error("An error occurred")
    })
  }

  getInventoryDetails(){
    this.inventoryService.getInventoryDetails(this.itemId).subscribe(response => {
      this.inventory = response.data;
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

}
