import { NgbDate } from "@ng-bootstrap/ng-bootstrap"

export const transformToString = (date: NgbDate): string => {
    if (date === null) return '';
    
    let month = date.month >= 10 ? date.month : '0' + date.month;
    let day = date.day >= 10 ? date.day : '0' + date.day;

    let tempDate = `${date.year}-${month}-${day}`;
    if(tempDate == 'undefined-0undefined-0undefined')
        return ''
        
    return `${date.year}-${month}-${day}`;
}

export const getDaysInMonth = (monthNumber: number): number => {
    const date = new Date();

    return new Date(
      date.getFullYear(),
      monthNumber + 1,
      0,
    ).getDate(); 
}
