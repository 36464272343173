import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function OutageStartEndTimeValidator(startTimeControl: string, endTimeControl: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startTime : Date | null = control.get(startTimeControl)?.value;
    const endTime : Date | null = control.get(endTimeControl)?.value;

    if(startTime == null || endTime == null){
      return { notNull: true }
    }
    if(startTime >= endTime){
      return { endBeforeStart: true }
    }
    return null;
};
}
