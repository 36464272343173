<div class="date-container" click-stop-propagation [useStopPropagation]="useClickStopPropagation" (click)="filterClick(!filterByDateClicked)">
    <div class="date-inner-container" id="date-inner" [ngClass]="{'pressed': filterByDateClicked}" >
        <span>
            <svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                <path id="Path_16" data-name="Path 16" d="M15.042,1.583H14.25V.792a.792.792,0,1,0-1.583,0v.792H6.333V.792a.792.792,0,0,0-1.583,0v.792H3.958A3.963,3.963,0,0,0,0,5.542v9.5A3.963,3.963,0,0,0,3.958,19H15.042A3.963,3.963,0,0,0,19,15.042v-9.5A3.963,3.963,0,0,0,15.042,1.583ZM1.583,5.542A2.375,2.375,0,0,1,3.958,3.167H15.042a2.375,2.375,0,0,1,2.375,2.375v.792H1.583ZM15.042,17.417H3.958a2.375,2.375,0,0,1-2.375-2.375V7.917H17.417v7.125A2.375,2.375,0,0,1,15.042,17.417Z" fill="#000829"/>
                <circle id="Ellipse_23" data-name="Ellipse 23" cx="1.313" cy="1.313" r="1.313" transform="translate(8.188 10.527)" fill="#000829"/>
                <circle id="Ellipse_24" data-name="Ellipse 24" cx="1.313" cy="1.313" r="1.313" transform="translate(4.289 10.527)" fill="#000829"/>
                <circle id="Ellipse_25" data-name="Ellipse 25" cx="1.313" cy="1.313" r="1.313" transform="translate(12.086 10.527)" fill="#000829"/>
            </svg>
        </span>
        <span class="text">Filter By Date</span>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="10.536" height="6.506" viewBox="0 0 10.536 6.506">
                <path id="ic_keyboard_arrow_left_24px" d="M6.506,1.238,2.485,5.268,6.506,9.3,5.268,10.536,0,5.268,5.268,0Z" transform="translate(0 6.506) rotate(-90)" fill="#000829"/>
            </svg>
        </span>
    </div>

    <div class="date-dropbox-expanded" id="date-dropbox" *ngIf="filterByDateClicked"  click-stop-propagation>
        <div class="date-dropbox-item">
            <span>From: </span>
            <span>
                <input 
                    class="input-datepicker"
                    id="dateFrom" 
                    [(ngModel)]="dateFrom" 
                    (dateSelect)="changeFromDate($event, d)"
                    name="dp"
                    [markDisabled]="isDisabled"
                    ngbDatepicker
                    (click)="d.toggle(); d1.close();"
                    #d="ngbDatepicker"
                    [placement]="placementPosition"
                    autocomplete="off"
                    
                />
                <svg xmlns="http://www.w3.org/2000/svg" width="10.536" height="6.506" viewBox="0 0 10.536 6.506">
                    <path id="ic_keyboard_arrow_left_24px" d="M6.506,1.238,2.485,5.268,6.506,9.3,5.268,10.536,0,5.268,5.268,0Z" transform="translate(0 6.506) rotate(-90)"/>
                </svg>
            </span>
        </div>
        <div class="date-dropbox-item">
            <span>To: </span>
            <span>
                <input 
                    class="input-datepicker"
                    #d1="ngbDatepicker"
                    id="dateTo" 
                    [(ngModel)]="dateTo" 
                    (dateSelect)="changeToDate($event)"
                    name="dp2"
                    [markDisabled]="isDisabled"
                    ngbDatepicker
                    (click)="d1.toggle(); d.close();"
                    [placement]="placementPosition"
                    autocomplete="off"
                />
                <svg xmlns="http://www.w3.org/2000/svg" width="10.536" height="6.506" viewBox="0 0 10.536 6.506">
                    <path id="ic_keyboard_arrow_left_24px" d="M6.506,1.238,2.485,5.268,6.506,9.3,5.268,10.536,0,5.268,5.268,0Z" transform="translate(0 6.506) rotate(-90)"/>
                </svg>
            </span>
        </div>
    </div>
</div>