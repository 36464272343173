<app-planned-outage-create-new (addedNew) = "addedNewRefresh($event)"></app-planned-outage-create-new>
<app-upload-planned-outage-popup
    (confirmUpload)="uploadFile()"
    (confirmGetTemplate)="getTemplate()"
></app-upload-planned-outage-popup>

<main class="container-fluid">
  <div class="container-wrapper">
    <div class="header">
      <span class="network-management-title">Planned Outage Schedule</span>
      <div class="crud-title">
        <div class="btns">
          <button *ngIf="authService.isAuthorized(PermissionsEnum.networkmanagement_planned_outage_create)" class="white-btn" (click)="openUploadTab()">Upload File</button>
          <button *ngIf="authService.isAuthorized(PermissionsEnum.networkmanagement_planned_outage_create)" class="black-btn" (click)="openTab(0)">Add New</button>
        </div>
      </div>
    </div>
    <div class="filters-container">
      <app-range-datepicker
                [fromDate]="dateFrom"
                [toDate]="dateTo"
                [name]="'Period'"
                [maxDate]="maxDate"
                (dateRangeSelected)="dateChange($event)"
                [isMonthly]="false"
            ></app-range-datepicker>
      <app-search-autocomplete
        #search
        [placeholder]="searchPlaceholder"
        [searchText]="searchText"
        [options]="options"
        (searchChange)="searchChanged($event)"
        (searchAutocompleteChange)="getOptions($event)"
      ></app-search-autocomplete>
    </div>
    <app-planned-outage-table
    [searchText]="searchText"
    [dateFrom]="dateFrom"
    [dateTo]="dateTo"
    ></app-planned-outage-table>
  </div>
</main>
