import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { Dt } from '../models/dt.model';

@Injectable({
  providedIn: 'root'
})
export class DtService {

  private pathApi = this.config.setting['pathApi'] + "dt/";
  private pathApiDTFeeders = this.config.setting['pathApi'] + "AngularChartData/GetDTFeeders";
  private pathApiDTWatchlistFeeders = this.config.setting['pathApi'] + "AngularChartData/GetDT";

  constructor(private http: HttpClient,
    private config: AppConfig,) {  }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    }

    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    }

    save(obj: Dt): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }

    getAll(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAll`, obj);
    }

    getAllDtsByF11IdWithPagination(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAllDtsByF11IdWithPagination`, obj);
    }

    getAllDtsByF33IdWithPagination(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAllDtsAndFeeder11ByF33IdWithPagination`, obj);
    }

    getAllForSelect(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSelect`);
    }

    getCount(): Observable<any> {
      return this.http.get(`${this.pathApi}getCount`);
    }

    uploadFile(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}uploadFile`, obj);
    }

    getTemplate(): Observable<any> {
      return this.http.get(`${this.pathApi}getTemplate`);
    }

    getInfoForMapDialog(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}getInfoForMapDialog/${id}`);
    }

    getConsumptionReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}consumptionReport`, obj);
    }
    getAllDtEnergyProfilesMonthly(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}GetAllDtEnergyProfilesMonthly`,obj);
    }

    getAllDtEnergyProfilesDaily(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}GetAllDtEnergyProfilesDaily`,obj);
    }

    getDtViewMore(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}GetDtViewMore`,obj);
    }

    downloadMonthlyDTReport(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}DownloadMonthlyDTReport`,obj);
    }

    downloadDailyDTReport(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}DownloadDailyDTReport`,obj);
    }

    getDTReceivedData(start: string, end: string): Observable<any[]> {
      if(start ==  "" || end == ""){
        return this.http.get<any[]>(`${this.pathApiDTFeeders}/Received`)
      }
      else{
        return this.http.get<any[]>(`${this.pathApiDTFeeders}/Received?start=${start}&end=${end}`)
      }
    }

    getDTLostData(start: string, end: string): Observable<any[]> {
      if(start ==  "" || end == ""){
        return this.http.get<any[]>(`${this.pathApiDTFeeders}/Lost`)
      }
      else{
        return this.http.get<any[]>(`${this.pathApiDTFeeders}/Lost?start=${start}&end=${end}`)
      }
    }

    getDTWorstEnergyLosses(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}GetWorst10Assets`, obj)
    }
    getDTWorstCapitalLosses(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}GetWorst10Assets`, obj)
    }

    downloadWorstRecordedAssets(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadWorst10Assets`, obj)
    }

    getFeedersOutages(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}GetOutages`, obj)
    }

    GetCurrentActive(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}GetCurrentActive`, obj)
    }
    GetNonCommunicatingDt(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}GetNonCommunicatingDt`, obj)
    }
    getFeederNames():Observable<any[]>{
      return this.http.get<any[]>(`${this.pathApi}GetFeederNames`);
    }

    downloadOutages(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadOutagesReport`,obj)
    }

    downloadCurrentActiveReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadCurrentActiveReport`,obj)
    }

    DownloadNonCommunicatingReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadNonCommunicatingReport`,obj)
    }

    countOfCustomersConnectedOnDt(id: number): Observable<any> {
      return this.http.post(`${this.pathApi}countOfCustomersConnectedOnDt/${id}`, id);
    }

    countOfPrivateDTsConnectedDirectly(id: number): Observable<any> {
      return this.http.post(`${this.pathApi}countOfDTsConnectedDirectly/${id}`, id);
    }
    countOfDTsConnectedToF33(id: number): Observable<any> {
      return this.http.post(`${this.pathApi}countOfDTsConnectedToF33/${id}`, id);
    }

    getAllDtsByUtId(id: number): Observable<any> {
        return this.http.get(`${this.pathApi}GetAllDtsByUtId/${id}`);
    }

    getAllDtsByF11Id(id: number): Observable<any> {
        return this.http.get(`${this.pathApi}GetAllDtsByF11Id/${id}`);
    }
    GetAllDtsConnectedToFeeder(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}GetAllDtsConnectedToFeeder`, obj);
    }

    getAllDtsConnectedToFeederById(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}GetAllDtsConnectedToFeederById/${id}`);
    }

    getEnergyDistributionAndLosses(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getEnergyDistributionAndLosses`, obj);
    }

    getOutageDurationAndFrequency(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getOutageDurationAndFrequency`, obj);
    }

    getDtMonthlyAvailability(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getAvailability`, obj);
    }

    getAllForSelectByParams(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getAllForSelectByParams`, obj);
    }

    getAllDtsDictionaryItem(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllDtsDictionaryItem`);
    }
    downloadAssetEnergy(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadDtAssetEnergy`, obj);
    }
    getDtReceivedData(obj: any): Observable<any[]> {
      return this.http.post<any[]>(`${this.pathApi}Received`, obj)
    }

    getDataForAutocomplete(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
    }

    getDataForAutocompleteNameExclusive(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForAutocompleteNameExclusive`, obj)
    }

    getDataForNetworkMapAutocomplete(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForNetworkMapAutocomplete`, obj)
    }

    getDataForOrganisationMapAutocomplete(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForOrganisationMapAutocomplete`, obj)
    }

    getDataForTopologyPopup(dtnumber: string): Observable<any> {
      return this.http.get(`${this.pathApi}getDataForTopologyPopup/${dtnumber}`);
    }

    downloadCrud(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadCrud`, obj);
    }

    getDtReads(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}GetDtReads`, obj);
    }
    getSystemHierarchy(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getSystemHierarchy`, obj)
    }
    getDailyBandsCount(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getDailyBandsCount`, obj);
    }
    getMonthlyBandsCount(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getMonthlyBandsCount`, obj);
    }
    getCommunicatingAssetsCounts(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getCommunicatingAssetsCounts`, obj);
    }
}
