<app-asset-performance-dt-popup></app-asset-performance-dt-popup>

<table class="standard-table">
    <thead>
        <tr>
            <th><span>DT NAME</span></th>
            <th><span>DT NUMBER</span></th>
            <th><span>DT TYPE</span></th>
            <th><span>{{buName | uppercase}}</span></th>
            <th><span>{{utName | uppercase}}</span></th>
            <th><span>ENERGY RECEIVED (MWH)</span></th>
            <th><span>NAMEPLATE RATING</span></th>
            <th class="small-td"><span></span></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of assetPerformanceDts" class="clickable actions-click" (click)="selectAssetPerformanceDt(item.dtNo)">
            <td class="td-pom"><span style="text-align: left !important;">{{item.dtName | hasValue}}</span></td>
            <td class="td-pom2"><span>{{item.dtNo| hasValue}}</span></td>
            <td *ngIf="item.isPublic == 'True'"><span class="public"><img alt="" class="icon" src="assets/images-new-design/globe.png">Public</span></td>
            <td *ngIf="item.isPublic == 'False'"><span class="private"><img alt="" class="icon" src="assets/images-new-design/lock.png">Private</span></td>
            <td><span>{{item.buName | hasValue}}</span></td>
            <td><span>{{item.utName | hasValue}}</span></td>
            <td *ngIf="item.energyReceived"><span>{{item.energyReceived | number: "1.0-3" | hasValue}}</span></td>
            <td *ngIf="!item.energyReceived"><span class="NaN">N\A</span></td>
            <td *ngIf="item.nameplateRating"><span>{{item.nameplateRating | number: "1.0-3" | hasValue}}</span></td>
            <td *ngIf="!item.nameplateRating"><span class="NaN">N\A</span></td>
            <td class="small-td" class="clickable actions-click"><span><img alt="..." src="assets/images/3-dot.svg"></span></td>
        </tr>
    </tbody>
</table>
