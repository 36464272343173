import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';

export enum GlobalSidebarItems{
  Settings = 1,
  Reports = 2,
}

@Component({
  selector: 'app-global-sidemenu',
  templateUrl: './global-sidemenu.component.html',
  styleUrls: ['./global-sidemenu.component.scss']
})
export class GlobalSidemenuComponent{

  public SelectedItem: any = 1;
  @Input() expandSidebar: boolean = false;
  @Output() expandSidebarChange = new EventEmitter();

  public PermissionsEnum = PermissionsEnum;

  constructor(public authService: AuthService) { }

  logout(){
    this.authService.logout();
  }
}
