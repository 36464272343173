export enum MenuItem {
  None = 0,
  CurrentOutage = 1,
  HistoricalOutage = 2,
}

export enum SubMenuItem {
  Feeders33 = 0,
  Feeders11 = 1,
  DT = 2,
}

