<main class="container-fluid">
    <div class="container-wrapper">
        <span class="network-management-title">Non Communicating</span>
        
        <div class="nm-filter-wrapper">
            <app-tab-default
            [firstText]="'33KV Feeders'"
            [secondText]="'11KV Feeders'"
            [thirdText]="'Distribution Transformer'"
            [paCss]="true"
            [womCss]="true"
            (tabChanged)="tabChanged($event)"
            [tabSelected]="tabMenu"            >
        </app-tab-default>
        </div>

        <div class="filters-container">
                <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

                    <app-multi-filter  *ngIf="filterApplied" 
                        [data]="allFilters"
                        (dataChange)="applyFilter($event)"
                        [(showFilter)]="showFilter"
                        (dropdownChange)="dropdownSelect($event)"
                        >
                    </app-multi-filter> 

                    <app-search-autocomplete
                    #search
                    [placeholder]="searchPlaceholder"
                    [searchText]="searchText"
                    [options]="options"        
                    (searchChange)="searchChanged($event)"
                    (searchAutocompleteChange)="getOptions($event)"
                    [width]="30"
                    ></app-search-autocomplete>
                </div>
                <div class="download-button">
                    <app-download-button-new
                    [isOnlyDownload]="true"
                    (downloadButtonClickedChange)="download()">
                    </app-download-button-new>   
                </div>
        </div>

        <app-non-communicating-f33
        #child
        *ngIf="tabMenu == 1"
        [tsIds]="tsIds"
        [categoryId]="categorySelected.value"
        [searchText]="searchText" 
        ></app-non-communicating-f33>


        <app-non-communicating-dt
        #child
        *ngIf="tabMenu == 3"
        [buId]="buId"
        [utIds]="utIds"
        [categoryId]="categorySelected.value"
        [searchText]="searchText"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
 
        [tabMenu]="tabMenu"
        ></app-non-communicating-dt>

        <app-non-communicating-f11
        #child
        *ngIf="tabMenu == 2"
        [buIds]="buIds"
        [categoryId]="categorySelected.value"
        [searchText]="searchText" 
        ></app-non-communicating-f11>
    </div>
</main>
