<div class="button-wrapper">
    <div class="button" [ngClass]="{'selected-table': !isMap}" (click)="buttonClick(false)">
      <span>Table View</span>
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 14H18V11.325H7V14ZM2 4.675H5V2H2V4.675ZM2 9.35H5V6.675H2V9.35ZM2 14H5V11.325H2V14ZM7 9.35H18V6.675H7V9.35ZM7 4.675H18V2H7V4.675ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2Z" fill="white"/>
      </svg>      
    </div>
    <div class="button" [ngClass]="{'selected-map': isMap}" (click)="buttonClick(true)"
      [ngClass]="{ 'disabled': mapBtnDisabled }">
      <span>Map View</span>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 18L6 15.9L1.35 17.7C1.01667 17.8333 0.708333 17.7958 0.425 17.5875C0.141667 17.3792 0 17.1 0 16.75V2.75C0 2.53333 0.0625 2.34167 0.1875 2.175C0.3125 2.00833 0.483333 1.88333 0.7 1.8L6 0L12 2.1L16.65 0.3C16.9833 0.166667 17.2917 0.204167 17.575 0.4125C17.8583 0.620833 18 0.9 18 1.25V15.25C18 15.4667 17.9375 15.6583 17.8125 15.825C17.6875 15.9917 17.5167 16.1167 17.3 16.2L12 18ZM11 15.55V3.85L7 2.45V14.15L11 15.55ZM13 15.55L16 14.55V2.7L13 3.85V15.55ZM2 15.3L5 14.15V2.45L2 3.45V15.3Z" fill="#2E3336"/>
      </svg>      
    </div>
  </div>