<div *ngIf="isPopupOpen" id="isViewMore" class="popup-overlay" (click)="close()">
  <div class="popup-content" click-stop-propagation>
    <div class="close-content">
      <svg (click)="close()" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.75 12.9436L17.0782 17.2718C17.203 17.3966 17.356 17.4632 17.5372 17.4718C17.7184 17.4804 17.8799 17.4137 18.0218 17.2718C18.1637 17.1299 18.2346 16.9726 18.2346 16.8C18.2346 16.6274 18.1637 16.4701 18.0218 16.3282L13.6936 12L18.0218 7.6718C18.1466 7.54702 18.2132 7.39403 18.2218 7.21283C18.2304 7.03163 18.1637 6.87009 18.0218 6.7282C17.8799 6.58631 17.7226 6.51537 17.55 6.51537C17.3774 6.51537 17.2201 6.58631 17.0782 6.7282L12.75 11.0564L8.4218 6.7282C8.29702 6.60342 8.14403 6.53676 7.96283 6.5282C7.78163 6.51964 7.62009 6.58631 7.4782 6.7282C7.33631 6.87009 7.26537 7.02736 7.26537 7.2C7.26537 7.37264 7.33631 7.52991 7.4782 7.6718L11.8064 12L7.4782 16.3282C7.35342 16.453 7.28676 16.606 7.2782 16.7872C7.26964 16.9684 7.33631 17.1299 7.4782 17.2718C7.62009 17.4137 7.77736 17.4846 7.95 17.4846C8.12264 17.4846 8.27991 17.4137 8.4218 17.2718L12.75 12.9436ZM12.7545 24C11.0951 24 9.53496 23.6851 8.07413 23.0554C6.61331 22.4256 5.34259 21.5709 4.26197 20.4913C3.18137 19.4117 2.32589 18.1422 1.69553 16.6827C1.06518 15.2233 0.75 13.6638 0.75 12.0045C0.75 10.3451 1.06488 8.78496 1.69463 7.32413C2.32441 5.86331 3.1791 4.59259 4.2587 3.51197C5.3383 2.43137 6.60782 1.57589 8.06727 0.945534C9.52673 0.315179 11.0862 0 12.7455 0C14.4049 0 15.965 0.314877 17.4259 0.944633C18.8867 1.57441 20.1574 2.4291 21.238 3.5087C22.3186 4.5883 23.1741 5.85782 23.8045 7.31727C24.4348 8.77673 24.75 10.3362 24.75 11.9955C24.75 13.6549 24.4351 15.215 23.8054 16.6759C23.1756 18.1367 22.3209 19.4074 21.2413 20.488C20.1617 21.5686 18.8922 22.4241 17.4327 23.0545C15.9733 23.6848 14.4138 24 12.7545 24ZM12.75 22.6667C15.7278 22.6667 18.25 21.6333 20.3167 19.5667C22.3833 17.5 23.4167 14.9778 23.4167 12C23.4167 9.02222 22.3833 6.5 20.3167 4.43333C18.25 2.36667 15.7278 1.33333 12.75 1.33333C9.77222 1.33333 7.25 2.36667 5.18333 4.43333C3.11667 6.5 2.08333 9.02222 2.08333 12C2.08333 14.9778 3.11667 17.5 5.18333 19.5667C7.25 21.6333 9.77222 22.6667 12.75 22.6667Z"
          fill="#7F7D82" />
      </svg>
    </div>
    <div class="tabs">
      <div class="formTitle">{{selectedDt.name | hasValue }}</div>
      <app-tab-default *ngIf="tabMenu!==3" [firstText]="'Overview'" [secondText]="'Topology'"
        (tabChanged)="tabChanged($event)" [tabSelected]="tabMenu"
        [firstImg]="'../../../../assets/images-new-design/sort.png'"
        [secondImg]="'../../../../assets/images-new-design/commit.png'"></app-tab-default>
    </div>
    <div class="horizontal-line hr"></div>
    <div class="download-content" *ngIf="tabMenu===1">
      <div class="form">
        <label class="label-opt">DT Name</label>

        <div class="form-input" *ngIf="selectedDt.name && selectedDt.name != '/'">{{selectedDt.name}}</div>
        <div *ngIf="!selectedDt.name || selectedDt.name == '/'" class="NaN2">N/A</div>
      </div>
      <div class="form">
        <label class="label-opt">DT Number</label>

        <div *ngIf="selectedDt.number && selectedDt.number != '/'">{{selectedDt.number}}</div>
        <div *ngIf="!selectedDt.number || selectedDt.number == '/'" class="NaN2">N/A</div>
      </div>
      <div class="form">
        <label class="label-opt">Meter Number</label>
        <div *ngIf="selectedDt.msn && selectedDt.msn != '/'">{{selectedDt.msn}}</div>
        <div *ngIf="!selectedDt.msn || selectedDt.msn == '/'" class="NaN2">N/A</div>
      </div>
      <div class="form">
        <label class="label-opt">Connected Feeder</label>

        <span *ngIf="selectedDt.feederName && selectedDt.feederName != '/'">{{selectedDt.feederName}}</span>
        <span
          *ngIf="!selectedDt.feederName || selectedDt.feederName == '/'"
          class="NaN2">N/A</span>

      </div>
      <div class="form">
        <label class="label-opt">Feeder Type</label>
        <div *ngIf="selectedDt.feederType && selectedDt.feederType != '/'">{{selectedDt.feederType}}</div>
      </div>
      <div class="form">
        <label class="label-opt">{{buName}}</label>
        <div *ngIf="selectedDt.parentName && selectedDt.parentName != '/'">{{selectedDt.parentName}}</div>
        <div *ngIf="!selectedDt.parentName || selectedDt.parentName == '/'" class="NaN2">N/A</div>
      </div>
      <div class="form">
        <label class="label-opt">Availability (Last 1 day)</label>
        <div *ngIf="selectedDt.dailyAvailability && selectedDt.dailyAvailability != '/'">{{selectedDt.dailyAvailability}}</div>
        <div *ngIf="!selectedDt.dailyAvailability || selectedDt.dailyAvailability == '/'" class="NaN2">N/A</div>
      </div>
      <div class="form">
        <label class="label-opt">Availability (Last 3 day)</label>
        <div *ngIf="selectedDt.last3dayavailability && selectedDt.last3dayavailability != '/'">{{selectedDt.last3dayavailability}}</div>
        <div *ngIf="!selectedDt.last3dayavailability || selectedDt.last3dayavailability == '/'" class="NaN2">N/A</div>
      </div>
      <div class="form">
        <label class="label-opt">Availability (Last 1 week)</label>
        <div *ngIf="selectedDt.last7dayavailability && selectedDt.last7dayavailability != '/'">{{selectedDt.last7dayavailability}}</div>
        <div *ngIf="!selectedDt.last7dayavailability || selectedDt.last7dayavailability == '/'" class="NaN2">N/A</div>
      </div> 
      <div class="form">
        <label class="label-opt">Availability (Last 1 month)</label>
        <div *ngIf="selectedDt.monthlyAvailability && selectedDt.monthlyAvailability != '/'">{{selectedDt.monthlyAvailability}}</div>
        <div *ngIf="!selectedDt.monthlyAvailability || selectedDt.monthlyAvailability == '/'" class="NaN2">N/A</div>
      </div>  
      <div class="form">
        <label class="label-opt">Start Time (date and time)</label>
        <div *ngIf="date && date != '/'">{{date}}</div>
        <div *ngIf="!date || date == '/'" class="NaN2">N/A</div>
      </div>
     
    </div>
    <div class="download-content" *ngIf="tabMenu===2">
      <div class="second-tab">
        <div class="topology">
          <div class="blackBtn">
            Ts Point
          </div>
          <div class="topology-text-first">

            {{data.ts}}
          </div>
          <div class="topology-icon">
            <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.89922 17.7992V15.3992H12.2992C12.2992 12.3992 11.1437 9.91589 8.83255 7.94922C6.52144 5.98255 3.81033 4.99922 0.699219 4.99922V2.59922C3.41033 2.59922 5.927 3.26033 8.24922 4.58255C10.5714 5.90477 12.3214 7.777 13.4992 10.1992C14.277 8.53255 15.3437 7.09366 16.6992 5.88255C18.0548 4.67144 19.5103 3.577 21.0659 2.59922H15.8992V0.199219H24.6992V8.99922H22.2992V4.63255C20.2548 5.877 18.477 7.39366 16.9659 9.18255C15.4548 10.9714 14.6992 13.0437 14.6992 15.3992H17.0992V17.7992H9.89922Z"
                fill="#2E3336" />
            </svg>
          </div>
        </div>
        <div class="line">
          <svg width="15" height="52" viewBox="0 0 15 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.50117 29.1736L0.828125 22.5005L1.87232 21.4563L6.7512 26.3198V0.164062H8.25115V26.3102L13.13 21.4313L14.1742 22.5005L7.50117 29.1736Z"
              fill="#2E3336" />
            <path d="M6.75 51.3277V28.1719H8.24995V51.318L6.75 51.3277Z" fill="#2E3336" />
          </svg>

        </div>
        <div class="topology">
          <div class="blackBtnF33">
            33KV Feeder
          </div>
          <div class="topology-text">
            {{data.feeder33}}
          </div>
          <div class="topology-icon">
            <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.90156 29.5656V22.9656L0.101562 20.1656V7.16562H2.90156V0.765625H5.30156V7.16562H8.10156V20.1656L5.30156 22.9656V29.5656H2.90156ZM13.3016 29.5656V22.9656L10.5016 20.1656V7.16562H13.3016V0.765625H15.7016V7.16562H18.5016V20.1656L15.7016 22.9656V29.5656H13.3016ZM23.7016 29.5656V22.9656L20.9016 20.1656V7.16562H23.7016V0.765625H26.1016V7.16562H28.9016V20.1656L26.1016 22.9656V29.5656H23.7016ZM2.50156 9.56562V15.1656H5.70156V9.56562H2.50156ZM12.9016 9.56562V15.1656H16.1016V9.56562H12.9016ZM23.3016 9.56562V15.1656H26.5016V9.56562H23.3016ZM4.10156 20.8656L5.70156 19.1656V17.5656H2.50156V19.1656L4.10156 20.8656ZM14.5349 20.8656L16.1016 19.1656V17.5656H12.9016V19.1656L14.5349 20.8656ZM24.8682 20.8656L26.5016 19.1656V17.5656H23.3016V19.1656L24.8682 20.8656Z"
                fill="#2E3336" />
            </svg>

          </div>
        </div>
        <div class="line">
          <svg width="15" height="52" viewBox="0 0 15 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.50117 29.1736L0.828125 22.5005L1.87232 21.4563L6.7512 26.3198V0.164062H8.25115V26.3102L13.13 21.4313L14.1742 22.5005L7.50117 29.1736Z"
              fill="#2E3336" />
            <path d="M6.75 51.3277V28.1719H8.24995V51.318L6.75 51.3277Z" fill="#2E3336" />
          </svg>

        </div>
        <div class="topology" *ngIf="data.feeder11">
          <div class="blackBtnF33" >
            11KV Feeder
          </div>
          <div class="topology-text">
            {{data.feeder11}}
          </div>
          <div class="topology-icon">
            <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.90156 29.5656V22.9656L0.101562 20.1656V7.16562H2.90156V0.765625H5.30156V7.16562H8.10156V20.1656L5.30156 22.9656V29.5656H2.90156ZM13.3016 29.5656V22.9656L10.5016 20.1656V7.16562H13.3016V0.765625H15.7016V7.16562H18.5016V20.1656L15.7016 22.9656V29.5656H13.3016ZM23.7016 29.5656V22.9656L20.9016 20.1656V7.16562H23.7016V0.765625H26.1016V7.16562H28.9016V20.1656L26.1016 22.9656V29.5656H23.7016ZM2.50156 9.56562V15.1656H5.70156V9.56562H2.50156ZM12.9016 9.56562V15.1656H16.1016V9.56562H12.9016ZM23.3016 9.56562V15.1656H26.5016V9.56562H23.3016ZM4.10156 20.8656L5.70156 19.1656V17.5656H2.50156V19.1656L4.10156 20.8656ZM14.5349 20.8656L16.1016 19.1656V17.5656H12.9016V19.1656L14.5349 20.8656ZM24.8682 20.8656L26.5016 19.1656V17.5656H23.3016V19.1656L24.8682 20.8656Z"
                fill="#2E3336" />
            </svg>
          </div>
        </div>
        <div class="line" *ngIf="data.feeder11">
          <svg width="15" height="52" viewBox="0 0 15 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.50117 29.1736L0.828125 22.5005L1.87232 21.4563L6.7512 26.3198V0.164062H8.25115V26.3102L13.13 21.4313L14.1742 22.5005L7.50117 29.1736Z"
              fill="#2E3336" />
            <path d="M6.75 51.3277V28.1719H8.24995V51.318L6.75 51.3277Z" fill="#2E3336" />
          </svg>
        </div>
        <div class="topology-second">
          <div class="red-Btn">
            Dts
          </div>
          <div class="topology-text-second">

            {{selectedDt.name}}
          </div>
          <div class="topology-icon-second">
            <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.90156 29.7336V23.1336L0.101562 20.3336V7.33359H2.90156V0.933594H5.30156V7.33359H8.10156V20.3336L5.30156 23.1336V29.7336H2.90156ZM13.3016 29.7336V23.1336L10.5016 20.3336V7.33359H13.3016V0.933594H15.7016V7.33359H18.5016V20.3336L15.7016 23.1336V29.7336H13.3016ZM23.7016 29.7336V23.1336L20.9016 20.3336V7.33359H23.7016V0.933594H26.1016V7.33359H28.9016V20.3336L26.1016 23.1336V29.7336H23.7016ZM2.50156 9.73359V15.3336H5.70156V9.73359H2.50156ZM12.9016 9.73359V15.3336H16.1016V9.73359H12.9016ZM23.3016 9.73359V15.3336H26.5016V9.73359H23.3016ZM4.10156 21.0336L5.70156 19.3336V17.7336H2.50156V19.3336L4.10156 21.0336ZM14.5349 21.0336L16.1016 19.3336V17.7336H12.9016V19.3336L14.5349 21.0336ZM24.8682 21.0336L26.5016 19.3336V17.7336H23.3016V19.3336L24.8682 21.0336Z"
                fill="#C03438" />
            </svg>

          </div>
        </div>
        <div class="line">
          <svg width="15" height="52" viewBox="0 0 15 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.50117 29.1736L0.828125 22.5005L1.87232 21.4563L6.7512 26.3198V0.164062H8.25115V26.3102L13.13 21.4313L14.1742 22.5005L7.50117 29.1736Z"
              fill="#2E3336" />
            <path d="M6.75 51.3277V28.1719H8.24995V51.318L6.75 51.3277Z" fill="#2E3336" />
          </svg>
        </div>
        <div class="topology">
          <div class="text-item">
            Customers
          </div>
          <div class="topology-details" (click)="tabChanged(3)">
            See All  <svg  class ="svg" width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.44766 6.49758L0.847656 1.89758L1.90148 0.84375L7.55531 6.49758L1.90148 12.1514L0.847656 11.0976L5.44766 6.49758Z" fill="#2E3336"/>
              </svg>
          </div>
          <div class="topology-icon">
            <div class="line">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.300781 24.6953V15.0953H15.5008V10.2953H17.9008V15.0953H22.7008V24.6953H0.300781ZM2.70078 22.2953H20.3008V17.4953H2.70078V22.2953ZM14.8674 8.46198L13.1674 6.76198C13.6341 6.31753 14.1786 5.99531 14.8008 5.79531C15.423 5.59531 16.0563 5.49531 16.7008 5.49531C17.3452 5.49531 17.9786 5.59531 18.6008 5.79531C19.223 5.99531 19.7674 6.31753 20.2341 6.76198L18.5341 8.46198C18.2897 8.21754 18.0063 8.06198 17.6841 7.99531C17.3619 7.92865 17.0341 7.89531 16.7008 7.89531C16.3674 7.89531 16.0397 7.92865 15.7174 7.99531C15.3952 8.06198 15.1119 8.21754 14.8674 8.46198ZM11.5008 5.09531L9.76745 3.36198C10.6786 2.42865 11.7397 1.75087 12.9508 1.32865C14.1619 0.906424 15.4119 0.695312 16.7008 0.695312C17.9897 0.695312 19.2397 0.906424 20.4508 1.32865C21.6619 1.75087 22.723 2.42865 23.6341 3.36198L21.9008 5.09531C21.2119 4.40642 20.4174 3.90087 19.5174 3.57865C18.6174 3.25642 17.6786 3.09531 16.7008 3.09531C15.723 3.09531 14.7841 3.25642 13.8841 3.57865C12.9841 3.90087 12.1897 4.40642 11.5008 5.09531ZM4.30078 21.0953H6.70078V18.6953H4.30078V21.0953ZM8.70078 21.0953H11.1008V18.6953H8.70078V21.0953ZM13.1008 21.0953H15.5008V18.6953H13.1008V21.0953Z"
                  fill="#1C1B1F" />
              </svg>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="download-content" *ngIf="tabMenu===3">
      <div class="back-container" (click)="back()"><svg class="img" width="21" height="15" viewBox="0 0 21 15"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 14.5L0.5 7.5L7.5 0.5L8.9 1.9L4.325 6.5H20.5V8.5H4.325L8.925 13.1L7.5 14.5Z" fill="#2E3336" />
        </svg>
        <button class="back-button title-third ">Customers</button>
      </div>
      <div *ngIf="account.length > 0; else noAccountMessage">
        <div class="customer-content">
          <div class="formAcc" *ngFor="let selectedDt of account">
            <div class="form-customer">
              <label class="label">Account Number</label>
              <div class="formselectedDt">{{selectedDt.accountNumber | hasValue }}</div>
            </div>
            <div class="form-customer">
              <label class="label">MSN</label>
              <div class="formselectedDt">{{selectedDt.msn | hasValue }}</div>
            </div>
            <div class="form-customer">
              <label class="label">Consumption</label>
              <div class="formselectedDt">{{selectedDt.consumption | hasValue }} MWh</div>
            </div>
          </div>
        </div>


      </div>

      <ng-template #noAccountMessage>
        <p class="message">There are no customers connected to this Dt!</p>
      </ng-template>
      <div class="paginationAcc" *ngIf="account.length > 0"><app-fo-pagination-new [lastPageSize]="lastPageSize"
          [pageSize]="accountsPageSize" [count]="count" [currentPage]="currentPageAccounts"
          (pageChange)="pageChangeAccounts($event)">
        </app-fo-pagination-new></div>

    </div>
    <div class="horizontal-line hr"></div>


  </div>

  <div class="horizontal-line hr"></div>

</div>