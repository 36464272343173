import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MeterService } from 'src/app/services/meter.service';

@Component({
  selector: 'app-remote-disconnection-action',
  templateUrl: './remote-disconnection-action.component.html',
  styleUrl: './remote-disconnection-action.component.scss'
})
export class RemoteDisconnectionActionComponent{

  public meterValue: string = '';
  public tokenValue: string = '';
  public initialScreen: boolean = true
  public invalidMeter: boolean = false;
  public meterDetailsData: any;
  showInvalidInputError = false;

  public showDetails: boolean = false;

  constructor(
    private meterService: MeterService,
    private toastr: ToastrService
  ){}


  meterValueChanged() {
    if (this.meterValue != '') {
      this.showInvalidInputError = false
    }
  }

  search(){
    if (this.meterValue == '') {
      this.showInvalidInputError = true
      return
    }
    this.getMeterBySearch(this.meterValue)
  }

  getMeterBySearch(msn: string) {
    this.meterService.getMeterDetailsByMSN(msn).subscribe(response => {
      if (response.status === 200) {
        this.meterDetailsData = response.data
        this.showDetails = true;
        console.log(this.meterDetailsData)
      }
      else if (response.status === 400) {
        this.toastr.error(response.message)
      } else if (response.status === 404) {
        this.invalidMeter = true;
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

}
