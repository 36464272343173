<main class="">
  <div class="user-profile">
    <div class="container-wrapper">
      <div class="auditheader">
        <div class="title-container">
          <div class="title-u">System Hierarchy</div>
          <div class="subtitle">View system components in hierarchy</div>
        </div>
      </div>

      <div class="filters-container">
        <div class="multi-filter-wrapper">
          <select (change)="searchTypeSelected($event)" class="searchType">
            <option value="8">TS</option>
            <option value="3">F33</option>
            <option value="7">SS</option>
            <option value="4">F11</option>
            <option value="2">DT</option>
            <option value="1">ACC</option>
          </select>

          <app-search-autocomplete
            #search
            [placeholder]="searchPlaceholder"
            [searchText]="searchText"
            (searchChange)="searchChange($event)"
            [width]="33"
          >
          </app-search-autocomplete>
        </div>
      </div>

      <div class="hierarchy-wrapper">
        <app-hierarchy-item
          #hierarchyItem
          [searchText]="searchText"gsd
        ></app-hierarchy-item>
      </div>
    </div>
  </div>
</main>
