import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { MeterBypassService } from 'src/app/services/meter-bypass.service';

@Component({
  selector: 'app-meter-bypass-details',
  templateUrl: './meter-bypass-details.component.html',
  styleUrls: ['./meter-bypass-details.component.scss']
})
export class MeterBypassDetailsComponent implements OnInit {
  @Input() data: any;
  @Output() backClicked = new EventEmitter<void>();

  constructor(public route: ActivatedRoute,public meterBypassService : MeterBypassService, public toastr: ToastrService, public router: Router, public accountService : AccountService)
  {
    let currentDate = new Date();
    let lastMonthDate = new Date();
    currentDate.setMonth(lastMonthDate.getMonth() - 1);
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 2);
    this.currentMonthString = this.monthNames[currentDate.getMonth()];
    this.lastMonthString = this.monthNames[lastMonthDate.getMonth()];
  }
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  currentMonthString : string = '';
  lastMonthString : string = '';
  currentMonthAvgVend : number | null = null;
  lastMonthAvgVend : number | null = null;
  currentAlarmCondition : number = 0;

  ngOnInit(): void {
    this.getCustomerDetails(this.data);
  }

  saveComment(){
    this.meterBypassService.updateBypass(this.selectedMeterBypass).subscribe(response =>{
      if(response.status == 200){
         this.toastr.success("Success")
       }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error('An Error Occurred')
    })
  }

  tabChanged(e: any) {
    if ((e == 1 && this.activeTab == ActiveTab.Table) || (e == 2 && this.activeTab == ActiveTab.Details)) {
      return
    }
    if (e == 1) {
      this.activeTab = ActiveTab.Table
    } else  if (e == 2){
      this.activeTab = ActiveTab.Details
    }else{
      this.activeTab = ActiveTab.Remark
    }
  }
  activeTab: ActiveTab = ActiveTab.Table
  accountDetails : any;

  getCustomerDetails(obj : any){
    this.meterBypassService.get(obj).subscribe(response => {
      this.selectedMeterBypass = response.data;
      this.initCardData();
       this.getAccountDetails(this.selectedMeterBypass.accountId);
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  initCardData(){
    if(this.selectedMeterBypass?.modalMonthlyTables){
      this.selectedMeterBypass.modalMonthlyTables.forEach((element : any) => {
        if(element.monthName == this.currentMonthString){
          this.currentMonthAvgVend = element.avgMonthlyVend;
          console.log(element.monthlyAlarmCondition)
          this.currentAlarmCondition = element.monthlyAlarmCondition;
        }
        if(element.monthName == this.lastMonthString){
          this.lastMonthAvgVend = element.avgMonthlyVend;
        }
      });
    }
  }

  getAccountDetails(id: any){
    this.accountService.get(id).subscribe(response =>{
      this.accountDetails = response.data;
    })
  }

  public selectedMeterBypass: any;
  ActiveTab: any = ActiveTab;

  public copyAccountNumber(accountNumber : any){
    navigator.clipboard.writeText(accountNumber)
    this.toastr.success('Copied to clipboard')
  }
  public back(){
    this.backClicked.emit();
  }
}
export enum ActiveTab {
  Details = 1,
  Table = 2,
  Remark = 3
}
