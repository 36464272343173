import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateTimeNoConvert'
})
export class DateTimeNoConvertPipe implements PipeTransform {
  transform(value: any, args?: any) {
    console.log(args)
    if (!value)
      return '/';

    if (value === '')
      return '/';

    if(args == 'shortTime')
        return formatDate(value, "hh:mm a", "en", "UTC");

    return formatDate(value, "dd/M/yy hh:mm:ss a", "en", "UTC");
  }
}