<app-role-slider (reloadTable)="reloadTable()"></app-role-slider>
<main class="">
  <div class="user-profile">

    <div class="container-wrapper">
      <div class="auditheader">

          <div class="btn-wrapper">
            <div class="title-container">
              <div class="title-u">
                  Roles Management
              </div>
              <div class="subtitle">
                  Create, read, update or delete Roles
              </div>
            </div>
              <div>
              <button class="red-btn" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_roles_createupdate)" (click)="openTab(0)">
                <span>Create Role</span>
                <img alt="+" src="assets/images-new-design/plus.png">
              </button>
            </div>
          </div>
          <div class="filters-container">
            <div>
              <app-search-autocomplete
              #search
              [placeholder]="searchPlaceholder"
              [searchText]="searchText"
              [options]="options"
              [width]="20"
              (searchChange)="inputChanged($event)"
              (searchAutocompleteChange)="getOptions($event)"
          ></app-search-autocomplete>
          </div>
          </div>
      </div>

        <table class="one-column-table">
          <tbody class="standard-rows">
              <tr *ngFor="let item of roles" class="">
                <td class="table-name">{{item.name | hasValue}} </td>
                <td></td>
                <td class="table-icons">
                  <span (click)="openTab(1, item)" class="clickable settings-actions-edit"
                        *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_roles_createupdate)">
                        <img alt="" class="" src="assets/images-new-design/new_settings_pen.svg" data-toggle="modal"
                        data-target="#createModal"
                        /> Edit
                  </span>
                  <span (click)="openTabDelete(item.id)"  data-toggle="modal" data-target="#deleteModal" class="clickable settings-actions-delete"
                    *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_roles_delete)">
                    <img class="" alt="" src="assets/images-new-design/new_settings_delete.svg" data-toggle="modal"
                    data-target="#deleteModal"/>
                  </span>
                </td>
              </tr>
              <tr class="last-row clickable" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_roles_createupdate)" (click)="openTab(0)">
                <td>+ Create New Role</td>
              </tr>
          </tbody>
      </table>

      <div class="pagination-wrapper">
        <app-pagination [pageSize]="pageSize" [currentPage]="currentPage" [count]="count" (pageChange)="pageChange($event)">
        </app-pagination>
    </div>

    </div>



  </div>
</main>
<app-crud-delete-modal
    [selectedItem]="selectedRole"
    (confirmDelete)="deleteRole()"
 >
</app-crud-delete-modal>
