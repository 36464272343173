import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-revenue-protection',
  templateUrl: './dashboard-revenue-protection.component.html',
  styleUrls: ['./dashboard-revenue-protection.component.scss']
})
export class DashboardRevenueProtectionComponent {

}
