
export class EnergyDistribution{
    energy: number = 0
    energyInNGN: number = 0
    prepaid: number = 0
    prepaidInNGN: number = 0
    postpaid: number = 0
    postpaidInNGN: number = 0
    losses: number = 0
    lossesInNGN: number = 0
}

export class OutageDurationAndFrequency{
    outageDuration: number = 0
    outageFrequency: number = 0
}

export class SystemAvailability{
    monthlyAvailability: number = 0
    avgDailyAvailability: number = 0
}

export enum NetworkPerfomanceTabs{
    DT = 1,
    Feeder = 2
}

export enum FieldConfigurationTabs {
    DT = 1,
    Feeder11 = 2,
    Feeder33 = 3
}