export class TsViewMore {
    tsId: number = 0;
    tsName: string = '';
    numberOfConnectedFeeders33: number = 0;
    energyReceived: number = 0;
    energyReceivedInNGN: number = 0;
    energyConsumptionAtDts: number = 0;
    energyConsumptionAtDtsInNGN: number = 0;
    losses: number = 0;
    lossesInPercentage: number = 0;
    lossesInNGN: number = 0;
    checked: boolean = false;
}