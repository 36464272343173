<main class="container-fluid container-crud">
    <div class="back-container" ><img alt="back" src="assets/images/ic_arrow_left.svg" />
        <div class="back-button" (click)="backPage()">Back</div>
    </div>
    <div class="data-table">
        <div class="row table-row">
            <div class="crud-title">
               Account's Settings
            </div>
            <div class="crud-title">
                <div class="accounts-filter-button">
                    <select style="cursor: pointer;" [(ngModel)]="businessUnit" (change)="selectUT()">
                        <option [value]=0>Select {{buName}}</option>
                        <option *ngFor="let bu of businessUnits" [value]="bu.value">{{bu.name}}</option>
                    </select>
                </div>
                <div class="accounts-filter-button">
                    <select style="cursor: pointer;" [(ngModel)]="undertaking" (change)="getAllAccounts()" (change)="resetPage()">
                        <option [value]=0>Select {{utName}}</option>
                        <option *ngFor="let ut of undertakings" [value]="ut.value">{{ut.name}}</option>
                    </select>
                </div>
                <div class="accounts-download-button">
                    <button data-toggle="modal" data-target="#createModal">Create Account</button>
                    <button (click)="download()">
                        Download
                    </button>
                    <button (click)="chooseFile.click()">Upload File</button>
                    <button (click)='getTemplate()'>Download Template</button>
                    <input #chooseFile type="file" accept=".xlsx" style="display: none;" (change)="onFileChosen($event); uploadFile();" [(ngModel)]="fileChosen">
                </div>
            </div>
            <div class=" col-sm-12 table-details">
                <table>
                    <thead>
                        <tr class="header-columns">
                            <td></td>
                            <td><span>Account number</span></td>
                            <td><span>Consumer name</span></td>
                            <td><span>MSN</span></td>
                            <td><span>Customer Id</span></td>
                            <td><span>Actions</span></td>
                        </tr>
                    </thead>
                    <!-- Put <thead>, <tbody>, and <tr>'s here! -->
                    <tbody>
                        <tr *ngFor="let item of accounts" class="bodyRow clickable actions-click" [ngClass]="{'selected': selectedAccount.id===item.id}" (click)="selectAccount(item.id)">
                            <td></td>
                            <td><span>{{item.accountNo | hasValue}}</span></td>
                            <td><span>{{item.firstName | hasValue}} {{item.middleName}} {{item.lastName}}</span></td>
                            <td><span>{{item.msn | hasValue}}</span></td>
                            <td><span>{{item.customerId | hasValue}}</span></td>
                            <td>
                                <span><img alt="edit" class="" src="assets/images/crud-edit.png" data-toggle="modal" data-target="#createModal" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_createupdate)" (click)="selectAccount(item.id)"/> </span>
                                <span><img alt="delete" class="" src="assets/images/crud-delete.png" data-toggle="modal" data-target="#deleteModal" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_delete)" /> </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div class="col-sm-12">
                        <app-adora-pagination [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-adora-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="create" aria-hidden="true" appClickOutside (clickOutside)="resetSelectedAccount()">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content create-modal crud-card">
            <div class="modal-header">
                <div class="modal-title"><img alt="Edit" src="/assets/pencil.svg"> Create/Edit Account</div>
            </div>
            <div class="modal-body">
                <div class="tab">
                    <div [ngClass]="currentTabCollorBlue == 1 ? 'btn-blue' : 'tab-green'" [ngStyle]="{'cursor' : (isEdit) ? 'pointer' : 'default'}" class="btn" (click)="swithTab(1)">Tenant</div>
                    <div [ngClass]="currentTabCollorBlue == 2 ? 'btn-blue' : 'tab-green'" [ngStyle]="{'cursor' : (isEdit) ? 'pointer' : 'default'}" class="btn" (click)="swithTab(2)">Service point</div>
                    <div [ngClass]="currentTabCollorBlue == 3 ? 'btn-blue' : 'tab-green'" [ngStyle]="{'cursor' : (isEdit) ? 'pointer' : 'default'}" class="btn" (click)="swithTab(3)">Meter</div>
                    <div [ngClass]="currentTabCollorBlue == 4 ? 'btn-blue' : 'tab-green'" [ngStyle]="{'cursor' : (isEdit) ? 'pointer' : 'default'}" class="btn" (click)="swithTab(4)">Additional info</div>
                </div>
                <form *ngIf="showTab == 1" (submit)="!isEdit && next()" #f1="ngForm" >
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Account number</label>
                            <input class="form-control" name="accountNo" [(ngModel)]="selectedAccount.accountNo" #accountNo="ngModel" required autocomplete="off" placeholder="Account number"/>
                            <div *ngIf=" f1?.submitted && accountNo.errors?.['required']" class="error-message">Account number is required {{stringify(f1)}}</div>
                            <div *ngIf="f1.submitted && !accountNoValid" class="error-message">Account number is not valid</div>
                        </div>

                        <div class="col-sm-6">
                            <label>First name</label>
                            <input class="form-control" name="firstName" [(ngModel)]="selectedAccount.firstName" #firstName="ngModel" required autocomplete="off" placeholder="First name" />
                            <div *ngIf="f1.submitted && firstName.invalid" class="error-message">First name is required</div>
                        </div>

                        <div class="col-sm-6">
                            <label>Email</label>
                            <input type="email" class="form-control" name="email" [(ngModel)]="selectedAccount.email" #email="ngModel" autocomplete="off" placeholder="Email" />
                            <div *ngIf="f1.submitted && !emailValid" class="error-message">Email is not valid</div>
                        </div>

                        <div class="col-sm-6">
                            <label>Middle name</label>
                            <input class="form-control" name="middleName" [(ngModel)]="selectedAccount.middleName" #middleName="ngModel" autocomplete="off" placeholder="Middle name" />
                        </div>

                        <div class="col-sm-6">
                            <label>Last name</label>
                            <input class="form-control" name="lastName" [(ngModel)]="selectedAccount.lastName" #lastName="ngModel" autocomplete="off" placeholder="Last name"/>
                        </div>

                        <div class="col-sm-6">
                            <label>Account Type</label>
                            <select style="cursor: pointer;" class="form-control" name="accountType" [(ngModel)]="selectedAccount.accountType" #accountType="ngModel" pattern="[2-3]" autocomplete="off">
                                <option selected disabled [ngValue]='0'>Select Account Type</option>
                                <option [ngValue]=2>MD</option>
                                <option [ngValue]=3>NMD</option>
                            </select>
                            <div *ngIf="f1.submitted && accountType.invalid" class="error-message">Account Type is required</div>
                        </div>
                        <div class="col-sm-6">
                            <label>Customer Id</label>
                            <input class="form-control" name="Customer Id" [(ngModel)]="selectedAccount.customerId" #street="ngModel" autocomplete="off" placeholder="Customer Id"/>
                        </div>
                        <div class="col-sm-6">
                            <label>Phone</label>
                            <input class="form-control" name="phone" [(ngModel)]="selectedAccount.phone" #phone="ngModel" autocomplete="off" placeholder="Phone"/>
                            <div *ngIf="f1.submitted && !phoneNumberValid" class="error-message">Phone number is not valid</div>
                        </div>

                        <div class="col-sm-12 form-buttons">
                            <button *ngIf="!isEdit" type="submit" class="btn green float-right" style="cursor: pointer;" [disabled]="selectedAccount.accountNo == '' || selectedAccount.firstName == '' || selectedAccount.accountType == 0">Next</button>
                            <div *ngIf="isEdit">
                                <button *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_createupdate)"  type="submit" class="btn green" style="cursor: pointer;" data-toggle="modal" data-target="#saveChangesModal">
                                    Save</button>
                            </div>
                            <button #closeModal class="btn green clear-btn" style="cursor: pointer;"  data-toggle="modal" data-target="#createModal" (click)="resetSelectedAccount()" class="btn btn-default" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </form>

                <form *ngIf="showTab == 2" (submit)="!isEdit &&  handleTabSwitching()" #f2="ngForm" >
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Longitude</label>
                            <input class="form-control" name="longitude" [(ngModel)]="selectedAccount.longitude" #longitude="ngModel" (ngModelChange)="validateLongitude()" autocomplete="off" placeholder="Longitude" />
                            <div *ngIf="!longValid" class="error-message">Longitude must be in -180 to 180 range.</div>
                        </div>

                        <div class="col-sm-6">
                            <label>Latitude</label>
                            <input class="form-control" name="latitude" [(ngModel)]="selectedAccount.latitude" #latitude="ngModel" (ngModelChange)="validateLatitude()" autocomplete="off" placeholder="Latitude" />
                            <div *ngIf="!latValid" class="error-message">Latitude must be in -90 to 90 range.</div>
                        </div>
                        <div class="col-sm-6">
                            <label>Street</label>
                            <input class="form-control" name="street" [(ngModel)]="selectedAccount.street" #street="ngModel" autocomplete="off" placeholder="Street"/>
                        </div>
                        <div class="col-sm-12 form-buttons">
                            <button *ngIf="!isEdit" type="submit" class="btn green float-right" style="cursor: pointer;" #backButton>Back</button>
                            <button *ngIf="!isEdit" type="submit" class="btn green float-right"style="cursor: pointer;" #nextButton [disabled]="selectedAccount.longitude == '' || selectedAccount.latitude == ''">Next</button>
                            <button type="submit" class="btn green" style="cursor: pointer;" data-toggle="modal" data-target="#saveChangesModal"
                                *ngIf="isEdit && authService.isAuthorized(PermissionsEnum.global_settings_createupdate)">
                                Save</button>
                            <button #closeModal class="btn green clear-btn" style="cursor: pointer;"  data-toggle="modal" data-target="#createModal" (click)="resetSelectedAccount()" class="btn btn-default" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </form>

                <form *ngIf="showTab == 3" (submit)="!isEdit && handleTabSwitching()" #f3="ngForm" >
                    <div class="row">

                        <div class="col-sm-6">
                            <label>Account Consumption Type</label>
                            <select style="cursor: pointer;" class="form-control" (change)="changeConsumptionType()" name="accountConsumptionType"
                            [(ngModel)]="selectedAccount.accountConsumptionType" #accountConsumptionType="ngModel" pattern="[2-3]" autocomplete="off">
                                <option [ngValue]='0'>Select Meter Type</option>
                                <option [ngValue]='2'>Prepaid</option>
                                <option [ngValue]='3'>Postpaid</option>
                            </select>
                            <div *ngIf="f3.submitted && accountConsumptionType.invalid" class="error-message">Account Consumption Type is required</div>
                        </div>

                        <div class="col-sm-6">
                            <label>Is Metered</label>
                            <select style="cursor: pointer;" (ngModelChange)="accMeterTypeChanged()" class="form-control" name="accountMeterType"
                            [(ngModel)]="selectedAccount.accountMeterType" #accountMeterType="ngModel" pattern="[1-2]" required autocomplete="off">
                                <div *ngIf="accountMetered == true; then thenBlock else elseBlock"></div>
                                    <ng-template #thenBlock>
                                        <option [ngValue]='0'>Select Meter Type</option>
                                        <option [ngValue]='1'>Metered</option>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        <option [ngValue]='0'>Select Meter Type</option>
                                        <option [ngValue]='1'>Metered</option>
                                        <option [ngValue]='2'>Unmetered</option>
                                    </ng-template>
                            </select>
                            <div *ngIf="f3.submitted && accountMeterType.invalid" class="error-message">Is Metered is required</div>
                        </div>

                        <div class="col-sm-6">
                            <label>MSN</label>
                            <input class="form-control" name="msn" [(ngModel)]="selectedAccount.msn" #msn="ngModel" required autocomplete="off" placeholder="MSN"/>
                            <div *ngIf="f3.submitted && !msnValid" class="error-message">MSN not valid</div>
                        </div>

                        <div class="col-sm-6">
                            <label>Meter type</label>
                            <select style="cursor: pointer;" class="form-control" name="meterType" [(ngModel)]="selectedAccount.meterType" #meterType="ngModel" autocomplete="off">
                                <option ngvalue="">Undefined</option>
                                <option selected ngValue="SinglePhase">Single Phase</option>
                                <option ngValue="ThreePhase">Three Phase</option>
                            </select></div>

                        <div class="col-sm-6">
                            <label>{{amiName}}</label>
                            <select style="cursor: pointer;" class="form-control" name="amiId" [(ngModel)]="selectedAccount.amiId" #amiId="ngModel" (click)="validateAmi()" autocomplete="off">
                                <option selected disabled value="0">Select Meter Manufacturer</option>
                                <option *ngFor="let ami of amis" value="{{ami.value}}">{{ami.name}}</option>
                            </select>
                            <div *ngIf="f3.submitted && !amiValid" class="error-message">{{amiName}} is required</div>
                        </div>

                        <div class="col-sm-12 form-buttons">
                            <button *ngIf="!isEdit" type="submit" class="btn green float-right" style="cursor: pointer;" #backButton>Back</button>
                            <button *ngIf="!isEdit" type="submit" class="btn green float-right" style="cursor: pointer;" #nextButton [disabled]="selectedAccount.accountConsumptionType == 0 || selectedAccount.accountMeterType == 0">Next</button>
                            <button type="submit" class="btn green" style="cursor: pointer;" data-toggle="modal" data-target="#saveChangesModal"
                                *ngIf="isEdit && authService.isAuthorized(PermissionsEnum.global_settings_createupdate)">
                                Save</button>
                            <button #closeModal class="btn green clear-btn" style="cursor: pointer;"  data-toggle="modal" data-target="#createModal" (click)="resetSelectedAccount()" class="btn btn-default" data-dismiss="modal">Cancel</button>

                        </div>
                    </div>
                </form>

                <form *ngIf="showTab == 4" (submit)="!isEdit && handleTabSwitching()" #f4="ngForm">

                    <div class="row">

                        <div class="col-sm-6 margin-top-acc">
                            <div class="autocomplete-label">DT name</div>
                            <div class="ng-content-width">
                                <ng-autocomplete click-stop-propagation
                                [data]="data1"
                                [searchKeyword]="keyword"
                                placeholder="Select dt name"
                                (selected)='selectEvent($event)'
                                (inputChanged)='onChange($event)'
                                (inputCleared)='inputCleared()'
                                [initialValue] = 'tempDtName'
                                [itemTemplate]="itemTemplate">
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a class="font-size-acc" [innerHTML]="item"></a>
                                </ng-template>
                            </div>
                            <div *ngIf="!dtValid" class="error-message">DT name is required</div>
                        </div>

                        <div class="col-sm-6">
                            <label>Tariff Name</label>
                            <select (change)="validateTariff()" class="form-control" style="cursor: pointer;" name="tariffName" [(ngModel)]="selectedAccount.tariffName" #tariffName="ngModel" autocomplete="off">
                                <option disabled value="">Select Tariff</option>
                                <option *ngIf="tariffNames.length == 0 && selectedAccount.tariffName" value="{{selectedAccount.tariffName}}">{{selectedAccount.tariffName}}</option>
                                <option *ngFor="let tariff of tariffNames" value="{{tariff.name}}">{{tariff.name}}</option>
                            </select>
                            <div *ngIf="!tariffValid" class="error-message">Tariff name is required</div>
                        </div>

                        <div class="col-sm-6">
                            <label>DCU SN</label>
                            <select class="form-control" name="dcuId" style="cursor: pointer;" [(ngModel)]="selectedAccount.dcuId" #dcuId="ngModel" autocomplete="off">
                                <option selected disabled value="0">Select DCU SN</option>
                                <option *ngFor="let dcu of dcus" value="{{dcu.value}}">{{dcu.name}}</option>
                            </select>
                        </div>
                        <div class="col-sm-6">
                            <label>Street</label>
                            <input class="form-control" name="street" [(ngModel)]="selectedAccount.street" #street="ngModel" autocomplete="off" placeholder="Street"/>
                        </div>
                        <div class="col-sm-12 form-buttons">
                            <button *ngIf="!isEdit" type="submit" class="btn green float-right" style="cursor: pointer;" #backButton>Back</button>

                            <button type="submit" class="btn green" style="cursor: pointer;" data-toggle="modal" data-target="#saveChangesModal"
                                *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_createupdate)" [disabled]="selectedAccount.dtName == '' || selectedAccount.tariffName =='' ">
                                Save</button>
                            <button #closeModal class="btn green clear-btn" style="cursor: pointer;"  data-toggle="modal" data-target="#createModal" (click)="resetSelectedAccount()" class="btn btn-default" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="saveChangesModal" tabindex="-1" role="dialog" aria-labelledby="saveChanges" aria-hidden="true" click-stop-propagation (clickOutside)="resetSelectedAccount()">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content save-changes-modal">
            <div class="modal-header">
                <div class="title-wrapper">
                    <span class="modal-title upload-modal-title" id="exampleModalLabel">Are you sure you want to save changes?</span>
                    <div class="modal-button-wrapper">
                        <button type="button" class="btn send-btn-green modal-button-wrapper" (click)="save()" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn send-btn-red modal-button-wrapper" data-dismiss="modal">No</button>
                    </div>
                    <button #closeModal [hidden]="true" data-toggle="modal" data-target="#saveChangesModal" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="delete" aria-hidden="true" (clickOutside)="resetSelectedAccount()">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content delete-modal">
            <div class="modal-header">
                <div class="title-wrapper" *ngIf="selectedAccount">
                    <span class="modal-title upload-modal-title" id="exampleModalLabel">Are you sure you want to delete?</span>
                    <div class="modal-button-wrapper">
                        <button type="button" class="btn send-btn-green modal-button-wrapper" data-dismiss="modal" (click)="deleteAccount($event, selectedAccount.id)">Yes</button>
                        <button type="button" class="btn send-btn-red modal-button-wrapper" data-dismiss="modal">No</button>
                    </div>
                    <button #closeModal [hidden]="true" data-toggle="modal" data-target="#deleteModal" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
