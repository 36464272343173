import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { Substation } from 'src/app/models/substation.model';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { CrudService } from 'src/app/services/crudService.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { SubstationService } from 'src/app/services/substation.service';
import { CrudDeleteModalComponent } from '../crud-delete-modal/crud-delete-modal.component';
import { CreateSubstationComponent } from './create-substation/create-substation.component';

@Component({
  selector: 'app-substation-tab',
  templateUrl: './substation.component.html',
  styleUrls: ['./substation.component.scss']
})
export class SubstationTabComponent implements OnInit {
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  public selectedId: number = 0;

  public PermissionsEnum = PermissionsEnum;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;


  public searchByName: string = '';
  public searchByAddress: string = '';
  public searchSs: SearchAutocomplete[] = [];

  private ngUnsubscribe = new Subject<void>();
  private searchText: string = "";


  public substations: Substation[] = [];
  public selectedSubstation: Substation = new Substation();
  public feeders33: any = [];

  public fileChosen: any = null;
  public file: any = null;

  public masterSelected: boolean = false;
  public checkedList: any = [];

  selectedFeeder: any = {};
  @ViewChild(CreateSubstationComponent) popupComponentRef!: CreateSubstationComponent;
  @Output() selectList = new EventEmitter();
  @Output() editTab = new EventEmitter();
  @Output() deleteComp = new EventEmitter();

  @ViewChild("f") f: any;
  constructor(private substationService: SubstationService,
    private toastr: ToastrService,
    private crudService: CrudService,
    public authService: AuthService,
    private commonService: CommonService,
    private feederService: Feeder33Service,
    private location: Location) { }

  ngOnInit(): void {
    this.reloadTable();
    this.getAllForSelect();
    this.subscribeToSearch();
  }
  back(){
    this.location.back();
  }
  getAllForSelect(){
    this.feederService.getAllForSelect().subscribe(response => {
      this.feeders33 = response.data;
      this.selectList.emit(this.feeders33);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null){
    if(page)
      this.currentPage = page;

    this.resetSelectedSubstation();

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };
    if (!obj.filterParams.SearchContent ){
       this.substationService.getAllForSelect().subscribe(response => {
        if (!obj.filterParams.SearchContent){
          this.searchSs = []
          response.data.forEach((x : Substation) =>
          {
            let item = new SearchAutocomplete();
            item.value = x.id;
            item.name = x.name;
            this.searchSs.push(item)
          })
        }
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
    this.substationService.getAll(obj).subscribe(response => {
      this.substations = response.data.data;
      if(obj.pageInfo.page == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      this.isAlreadyChecked();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  selectSubstation(id: number){
    this.substationService.get(id).subscribe(response => {
      if(response.status === 200)
      {
          this.selectedSubstation = response.data;
          this.editTab.emit(this.selectedSubstation);
          // this.openTab();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  openTabDelete(id:number) {
    this.substationService.get(id).subscribe(response => {
     if(response.status === 200){
       this.selectedSubstation = response.data;
       this.deleteComp.emit(this.selectedSubstation);
     }
     else
       this.toastr.error(response.message);
   }, error => {
     this.toastr.error('An error occurred.');
   });
   this.selectedId = id;
 }

  resetSelectedSubstation(){
    this.selectedSubstation = new Substation();
    this.selectedFeeder = 0;

    if(this.f)
      this.f.submitted = false;
  }

  onDeleteConfirmed(){
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_delete))
    {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.substationService.delete(this.selectedId).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.currentPage = 1;
        this.reloadTable();
        this.resetSelectedSubstation();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  onFileChosen(event: any){
    this.file = event.target.files[0];
  }

  isFileChosen(){
    if(this.fileChosen)
      return true;

    return false;
  }

  uploadFile(){
    console.log("Sfsfs", this.file)
    if(!this.isFileChosen){
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.substationService.uploadFile(formData).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.reloadTable();
      }else{
        this.toastr.error(response.message);
        if(response.data && response.data.length > 0)
          window.location.href = response.result.data;
      }
        this.reloadTable();
        this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  addItem() {
    const feeder = this.selectedSubstation.feeders33.find((obj) => {
      return obj.id === this.selectedFeeder.value;
    });

    if(feeder){
      this.toastr.error(`Feeder33 ${this.selectedFeeder.name} already exists in the list.`);
      return;
    }

    this.selectedSubstation.feeders33.push({id:this.selectedFeeder.value, name:this.selectedFeeder.name});
    this.selectedFeeder = {}
  }

  deleteItem(id: any){
    let index = this.selectedSubstation.feeders33.findIndex(d => d.id === id);
    this.selectedSubstation.feeders33.splice(index, 1);
  }

  getTemplate(){
    this.substationService.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;

          //window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch() {
    this.crudService.globalSearch$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(searchValue => {
      if(searchValue.clicked && searchValue.componentName === ComponentNamesNew.SubstationComponent) {
        this.searchText = searchValue.text;
        this.currentPage = 1;
        this.checkedList = [];
        this.reloadTable();
        return;
      }
      this.searchText = '';
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkUncheckAll() {
    for (let i = 0; i < this.substations.length; i++) {
      this.substations[i].checked = this.masterSelected;
      this.checkForDownload(this.substations[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.substations.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.substations.every(function(item:any) {
      return item.checked == true;
    })
  }

  downloadItems(){
    let obj = {
      Ids: this.checkedList,
      activate: false
    };
    this.substationService.download(obj).subscribe(response => {
      if (response.status === 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.")
        }
        else {
          window.location.href = response.data
          this.toastr.success("Successfully downloaded substations.");

        }
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
