import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { OverdueBillArearsModalData } from 'src/app/models/revenue-modal-data';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-overdue-bills-popup',
  templateUrl: './overdue-bills-popup.component.html',
  styleUrls: ['./overdue-bills-popup.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class OverdueBillsPopupComponent{

  @Input('openModal') openModal: number = 0;
  overdueBillArears = new OverdueBillArearsModalData;

  @Input() animationState = 'out';
  showShadow = false;

  @Input() isPopupOpen: boolean = false;

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');

  tabNumber = 1;

  constructor(public translationService: TranslationService) { }

  changeState(number: Number, data?: OverdueBillArearsModalData){
    if (data)
      this.overdueBillArears = data;
    if (number === 1){
      this.toggleShowDiv("open");
      this.showShadow = true;
      this.isPopupOpen = true;
    }
    if (number === 2)
    {
      this.toggleShowDiv("close");
      this.showShadow = false;
      this.isPopupOpen = false;
    }
  }

  open(type: string) {
    if (type == "customerDetails") {
      this.tabNumber = 1;
    }
    else if (type == "parentsDetails") {
      this.tabNumber = 2;
    }
  }
  toggleShowDiv(divName: string) {
    if (divName === 'open'){
      this.animationState = this.animationState = 'in';
      this.tabNumber = 1;
    }else if (divName === 'close')
      this.animationState = this.animationState = 'out';
  }

}
