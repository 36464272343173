import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { Account } from 'src/app/models/account.model';
import { MeterService } from 'src/app/services/meter.service';

@Component({
  selector: 'app-meter-details',
  templateUrl: './meter-details.component.html',
  styleUrls: ['./meter-details.component.scss']
})
export class MeterDetailsComponent implements OnInit {
  @Input() meterDetails:any;
  @Input() currentAccStatus:any;
  @Input() currentMeterStatus:any;
  @Input() remoteDisconnection: boolean = false;
  @Output() backClicked = new EventEmitter<void>();
  @Output() parentComp = new EventEmitter();
  previousMeterStatus:any;

  currentTab:number=1;
  change:string='Change';
  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase();
  public utName: string = this.translationService.getByKeyFromCache('UT').toUpperCase();
  accStatuses: string[]=[ "Active","Inactive","Finalised"];

  lastTokenLoaded: any;

  constructor(
    public toastr: ToastrService,
    private translationService: TranslationService,
    private accountService: AccountService,
    private meterService: MeterService
  ) {}

  ngOnInit(): void {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
      this.getLastTokenLoaded(this.meterDetails.id);
      this.previousMeterStatus=this.currentMeterStatus;
  }

 back() {
  this.backClicked.emit();
 }

  changeStatus(status:any,accNo:any){
  let acc= new Account();
  acc.accountNo=accNo;
  acc.status=status;
  this.accountService.updateStatus(acc).subscribe(response => {
    if (response.status === 200) {
      this.toastr.success(response.message);
      this.currentAccStatus=status;
      this.parentComp.emit()
    } else
      this.toastr.error(response.message);
  }, error => {
    this.toastr.error('An error occurred.');
  });

  }

  changeMeterStatus(status:any, meterNumber:any){
    console.log(status);
    console.log(meterNumber);
    this.currentMeterStatus=status;
    let meterStatus={
      "meterNumber":meterNumber,
      "status":this.currentMeterStatus
    }
    this.meterService.updateMeterStatus(meterStatus).subscribe(response => {
      if(response.status == 200){
        this.toastr.success("Meter Status updated successfully")
        this.previousMeterStatus = this.currentMeterStatus;
      }
      else{
        this.toastr.error(response.message)
        this.currentMeterStatus = this.previousMeterStatus;
      }
    }, _=>{
      this.toastr.error('An Error Occurred')
      this.currentMeterStatus = this.previousMeterStatus;

    })

  }

  tabChanged(value:number){
  this.currentTab=value;
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }

seeHistory(tab:number){
  this.currentTab=tab;
}

getLastTokenLoaded(id: any) {
  let obj = {
    meterId: id
  }
  this.meterService.getLastTokenLoaded(obj).subscribe(response => {
    this.lastTokenLoaded = response;
  })
}

  deactivateMeter(){
    this.toastr.warning('Not yet implemented.');
  }

}
