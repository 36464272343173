import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationStorage } from 'src/share-storage/notification-storage';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class NotificationHubService {
  constructor(
    private config: AppConfig,
    private notificationStorage: NotificationStorage,
    private notificationService: NotificationService,
    private authService: AuthService) { }

  private pathApi = this.config.setting['pathApi'].slice(0, -4);
  public connection!: signalR.HubConnection;
  token: string | null = null

  updateNotificationCountFunc: any;

  options: signalR.IHttpConnectionOptions = {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
    accessTokenFactory: () => {
      this.token = localStorage.getItem('token') as string;
      return this.token;
    }
  };

  init(notificationCountUpdateFunc: any = null): Promise<any> {
    if(!!notificationCountUpdateFunc){
      this.updateNotificationCountFunc = notificationCountUpdateFunc;
    }

    console.log('LogoutHubService initialized');
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.pathApi + 'hub/NotificationHub', this.options)
      .build();

    this.connection.on('ReciveNotification', (result: any) => {
      this.notificationStorage.notifications.splice(0, 0, result);
      this.notificationService.getUnreadCount(this.authService.getCurrentUser()?.userGuid).subscribe(response => {
        this.updateNotificationCountFunc(response.data);
      });
    });

    this.connection
      .start()
      .then(
        (con) => {
          console.log('Connection started')
        })
      .catch(err => console.log('Error while starting connection: ' + err));

    return Promise.resolve();
  }

  close() {
    return this.connection?.stop();
  }
}
