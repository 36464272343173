import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { SwitchModulePopupComponent } from '../switch-module-popup/switch-module-popup.component';


export enum SidebarItemsNew{
  Dashboard = 1,
  GridEnergyProfile = 2,
  Outage = 3,
  HistoricalTrend = 4,
  AssetComparison = 5,
  WatchList = 6,
  ModalSettings = 7,
  GeneralSettings = 8,
  ConsumptionGraph = 9,
  Reports = 10,
  CurrentActiveAssetComponent = 11,
  NonCommunicatingComponent = 12,
  PlannedOutage = 13,
}

@Component({
  selector: 'app-network-management-sidemenu',
  templateUrl: './network-management-sidemenu.component.html',
  styleUrls: ['./network-management-sidemenu.component.scss']
})
export class NetworkManagementSidemenuComponent {

  public PermissionsEnum = PermissionsEnum;

  public SelectedItem: any = 1;
  @Input() expandSidebar: boolean = false;
  @Output() expandSidebarChange = new EventEmitter();

  @ViewChild(SwitchModulePopupComponent) popupComponentRef!: SwitchModulePopupComponent;

  public trendAnalysisMenu: boolean = false;
  public outageMenu: boolean = false;
  public settingsMenu: boolean = false;




  constructor(public authService: AuthService, public toastr: ToastrService,
    private router: Router) {
      this.router.events
        .subscribe(
          (event) => {
            if(event instanceof ActivationEnd && event.snapshot.children.length == 0) {
              this.SelectedItem = event.snapshot.data['sidebarItem'];
            }
          });
    }

  logout(){
    this.authService.logout();
  }

  alert(){
    this.toastr.warning("Not yet implemented");
  }

  redesign(){
    this.toastr.warning("Page under redesign");
  }

  expand(){
    this.expandSidebar = !this.expandSidebar;
    this.expandSidebarChange.emit(this.expandSidebar);
  }

  handleDropdownMenuClick(name: string, event: any) {
    // If sidebar is open
    if(this.expandSidebar) {
      if (name === 'settings') {
        this.settingsMenu = !this.settingsMenu;
      }
      else if (name === 'TrendAnalysis') {
        this.trendAnalysisMenu = !this.trendAnalysisMenu;
      }else if(name=== 'Outage'){
        this.outageMenu = !this.outageMenu;
      }
      else {
      return;
      }
    } else {
      this.expand()
    }
  }

  switchModule(){
    this.popupComponentRef.changeState(1);
  }
}
