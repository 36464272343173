import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { MultiFilterComponent } from 'src/app/components/common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { AmiService } from './../../../../services/ami.service';

@Component({
  selector: 'app-asset-meter',
  templateUrl: './asset-meter.component.html',
  styleUrl: './asset-meter.component.scss'
})
export class AssetMeterComponent {

  constructor(
    public buService: BusinessUnitService,
    public tsService: TransmissionStationService,
    public AmiService: AmiService,
    public utService: UndertakingService,
    public dtService: DtService,
    public f11Service: Feeder11Service,
    public f33Service: Feeder33Service,
    public meterService: MeterService,
    private toastr: ToastrService,
    private translationService: TranslationService,

  ) { }

  ngOnInit(): void {
    forkJoin([ // RXJS helper method that bundles multiple Observables into one
      this.tsService.getAllForSelect(),
      this.buService.getAllForSelect(),
      this.AmiService.getAllForSelect(),
      this.utService.getAllForSelect(),

    ]).subscribe(([tsData, busData, amisData,utsData]) => {
      this.businessUnits = { name: this.buName, data: busData.data }
      this.amis = { name: this.amiName, data: amisData.data }
      this.transmissionStations = { name: "TransmissionStation", data: tsData.data }
      this.uts = { name: this.utName, data: utsData.data }
      this.checkFilters();
    }, error => {
      this.toastr.error('An error occurred.')
    });
    this.setInitalDates()
  }

  currentPage: number = 1
  pageSize: number = 10
  lastPageSize: number = 1
  count: number = 0

  firstTabPrimary: string = '33KV Feeder'
  secondTabPrimary: string = '11KV Feeder'
  thirdTabPrimary: string = 'DT'
  activeTabPrimary: number = ActiveTabPrimary.Feeder33
  activeTabPrimaryString = this.firstTabPrimary
  public ActiveTabPrimaryEnum = ActiveTabPrimary

  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  public buIds: any = []
  public tsIds: any = []
  public amiIds: any = []
  public utIds : any = []

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public amiName :string = this.translationService.getByKeyFromCache('AMI');

  public amis: any = {name: this.amiName, data: []}
  public businessUnits: any = { name: this.buName, data: []};
  public transmissionStations: any = { name: "TransmissionStation", data: []};
  public uts: any = { name: this.utName, data: []};


  public dateFrom: any = ''
  public dateTo: any = ''
  options = ['']

  public f33metersData = [];

  public searchText: SearchAutocomplete = new SearchAutocomplete()
  @ViewChild(MultiFilterComponent) multiFilterComponentRef!: MultiFilterComponent
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  getBusForSelect() {
    this.buService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getAmisForSelect() {
    this.AmiService.getAllForSelect().subscribe(response => {
      this.amis = { name: this.amiName, data: response.data }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getTsForSelect() {
    this.tsService.getAllForSelect()
      .subscribe(response => {
        this.transmissionStations = { name: 'TransmissionStation', data: response.data }
        this.checkFilters()
      }, _ => {
        this.toastr.error('An error occurred.')
      });
  }

  primaryTabChange(value: number) {
    let lastPrimaryTabState = this.activeTabPrimary;
    if ((value === 3 && this.activeTabPrimary == ActiveTabPrimary.DT) ||
      (value === 2 && this.activeTabPrimary == ActiveTabPrimary.Feeder11) ||
      (value === 1 && this.activeTabPrimary == ActiveTabPrimary.Feeder33)) {
      return
    }
    if (value === 3) {
      this.activeTabPrimary = ActiveTabPrimary.DT
      this.activeTabPrimaryString = this.thirdTabPrimary
    }
    else if (value === 2) {
      this.activeTabPrimary = ActiveTabPrimary.Feeder11
      this.activeTabPrimaryString = this.secondTabPrimary
    }
    else if (value === 1) {
      this.activeTabPrimary = ActiveTabPrimary.Feeder33
      this.activeTabPrimaryString = this.firstTabPrimary
    }
    this.handleFilterEntitySwap(value, lastPrimaryTabState);
    this.resetFilters()
  }

  handleFilterEntitySwap(value: number, lastPrimaryTabState: number){
    if( (lastPrimaryTabState ==  ActiveTabPrimary.DT || lastPrimaryTabState ==  ActiveTabPrimary.Feeder11)
      && value === 1){
      // changing from BU based tab to TS based tab
      this.allFilters = [];
      this.checkFilters()
    }
    if(lastPrimaryTabState ==  ActiveTabPrimary.Feeder33 && (value === 2 || value == 3)){
      //changing from TS based tab to BU based tab
      this.allFilters = [];
      this.checkFilters()
    }
  }
  setInitalDates() {
    let date = new Date()
    date.setHours(2, 0, 0)
    this.dateTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
    date.setMonth(date.getMonth() - 6);
    this.dateFrom = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
  }

  checkFilters() {
    this.filterApplied = true;
    let buData;
    let tsData;
    let utData;
    if(this.activeTabPrimary != ActiveTabPrimary.Feeder33){
      buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnits.name)
      let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);
      if (buIndex !== -1) {
        this.allFilters.splice(buIndex, 1);
        this.allFilters.push(this.businessUnits)
      }
      if(!buData){
        this.allFilters.push(this.businessUnits)
      }
      utData = this.allFilters.find((item: { name: any; }) => item.name === this.uts.name);
      let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.uts.name);
      if (utIndex !== -1) {
        this.allFilters.splice(utIndex, 1);
        this.allFilters.push(this.uts)
      }
      if(!utData){
        this.allFilters.push(this.uts)
      }
    }
    else{
      tsData = this.allFilters.find((item: { name: any; }) => item.name === this.transmissionStations.name)
      let tsIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.transmissionStations.name);
      if (tsIndex !== -1) {
        this.allFilters[tsIndex].data = this.transmissionStations.data;
        this.allFilters.splice(tsIndex, 1);
        this.allFilters.push(this.transmissionStations)
      }
      if(!tsData){
        this.allFilters.push(this.transmissionStations)
      }
    }
    let amiData = this.allFilters.find((item: { name: any; }) => item.name === this.amiName)
    let amiIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.amis.name);
    if (amiIndex !== -1) {
      this.allFilters[amiIndex].data = this.amis.data;
      this.allFilters.splice(amiIndex, 1);
      this.allFilters.push(this.amis)

    }
    if(!amiData){
      this.allFilters.push(this.amis)
    }
  }
  resetFilters() {
    this.multiFilterComponentRef?.uncheckData()
    this.multiFilterComponentRef?.resetShowFilter()
    this.searchComponentRef.resetOptions()
    this.searchText = new SearchAutocomplete()
    if (this.searchComponentRef)
      this.searchComponentRef.searchText = new SearchAutocomplete()
    this.buIds = []
    this.tsIds = []
    this.amiIds =[]
    this.utIds = []
    this.showFilter = false
    this.setInitalDates()
  }
  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)
      if (item.name === this.buName && filteredValues.length > 0) {
        this.buIds = filteredValues
      }
      else if (item.name === this.buName && filteredValues.length == 0) {
        this.buIds = []
      }
      if(item.name === 'TransmissionStation' && filteredValues.length > 0){
        this.tsIds = filteredValues
      }
      else if(item.name === 'TransmissionStation' && filteredValues.length == 0){
        this.tsIds = []
      }
      if(item.name === this.amiName && filteredValues.length > 0){
        this.amiIds = filteredValues
      }
      else if(item.name === this.amiName && filteredValues.length == 0){
        this.amiIds = []
      }
      if(item.name === this.utName && filteredValues.length > 0){
        this.utIds = filteredValues
      }
      else if(item.name === this.utName && filteredValues.length == 0){
        this.utIds = []
      }
    }
  }
  searchChange(text: SearchAutocomplete) {
    this.searchText = text
    this.searchText = cloneDeep(this.searchText)
  }
  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    if (searchText.name.length > 2) {
      if (this.activeTabPrimary == ActiveTabPrimary.DT) {
        this.dtService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      } else if (this.activeTabPrimary == ActiveTabPrimary.Feeder11) {
        this.f11Service.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
      else if (this.activeTabPrimary == ActiveTabPrimary.Feeder33) {
        this.f33Service.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
    }
    else
      this.searchComponentRef.resetOptions()
  }
}

export enum ActiveTabPrimary {
  DT = 3,
  Feeder11 = 2,
  Feeder33 = 1
}
