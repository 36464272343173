export class SearchAutocomplete{
    value: number = 0;
    name: string = '';
    type: Type = Type.Undefined;
}

//must be in sync with Enum Type on backend
export enum Type {
    Undefined = 0,
    Dt = 1,
    Feeder33KV = 2,
    Feeder11KV = 3,
    BusinessUnit = 4,
    Undertaking = 5,
    Substation = 6,
    TransmissionStation = 7,
    Account = 8,
    Bill = 9,
    Payment = 10,
    Concentrator = 11,
    Meter = 12,
    MeterToken = 13,
    AdoraUtility = 14,
    AdoraUser = 15
}
