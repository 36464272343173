import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TimePeriod } from 'src/app/components/consumption/model/model';
import { transformToString } from 'src/app/helpers/dateUtils';
import { DtService } from 'src/app/services/dt.service';

@Component({
  selector: 'app-dt-popup',
  templateUrl: './dt-popup.component.html',
  styleUrls: ['./dt-popup.component.scss']
})
export class DtPopupComponent{
  public isPopupOpen: boolean = false;
  public selectedDt: any = null;
  daily: boolean = false
  public account:any = [];
  public currentPageAccounts: number = 1;
  public count: number = 0;
  public accountsPageSize: number = 8;
  public lastPageSize: number = 1;
  public customersLoadingFlag : boolean = true;
  @Input() timePeriod: TimePeriod = TimePeriod.Monthly;

  public tabMenu: number = 1;
  public dataDt: any = {};
  @Input() dateFrom: any = '';
  @Input() dateTo: any = '';
  constructor(private _dtService: DtService,
    private toastr: ToastrService) { }

  close() {
    this.account = [];
    this.selectedDt = []
    this.isPopupOpen = false;
    this.currentPageAccounts = 1;
  }
  getPopupInfo(obj: any) {
    this._dtService.countOfCustomersConnectedOnDt(obj.id).subscribe(response => {
      this.dataDt = response.data;
      this.dataDt.info = this.selectedDt;
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }
  tabChanged(value: number) {
    this.tabMenu = value
  }

  selectDtMonthly(name: any) {
    let obj = {
      pageInfo: {
        page: this.currentPageAccounts,
        pageSize: this.accountsPageSize,
        isMonthlySelected: this.timePeriod == TimePeriod.Monthly
      },
      filterParams: {
        DtName: this.selectedDt.dtName,
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
      }
    };
    this.customersLoadingFlag = true;
    this._dtService.getDtViewMore(obj).subscribe(response => {
      if (response.status === 200) {
        this.account = response.data.data;
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.accountsPageSize)
        this.customersLoadingFlag = false;

      } else {
        this.toastr.error(response.message);
        this.customersLoadingFlag = false;
      }
    }, _ => {
      this.toastr.error('An error occurred.');
      this.customersLoadingFlag = false;
    })
  }
  pageChangeAccounts(value: any) {
    this.currentPageAccounts = value;
    this.selectDt();
  }
  selectDt() {
    if (this.daily == true) {
      this.selectDtDaily(this.selectedDt.dtName)
    } else
      this.selectDtMonthly(this.selectedDt.dtName)
  }
  selectDtDaily(name: string = '') {
    let obj = {
      pageInfo: {
        page: this.currentPageAccounts,
        pageSize: this.accountsPageSize,
        isMonthlySelected: this.timePeriod == TimePeriod.Daily
      },
      filterParams: {
        DtName: name,
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
      }
    };
    this._dtService.getDtViewMore(obj).subscribe(response => {
      if (response.status === 200) {
        this.account = response.data.data;
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.accountsPageSize)
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }


  changeState(daily: boolean, data?: any, menu?: any) {
    if (data)
     this.selectedDt = data;
   this.daily = daily;
   if (daily === true)
     this.selectDtDaily(this.selectedDt.dtName)
   else
     this.selectDtMonthly(this.selectedDt.dtName)
   this.getPopupInfo(this.selectedDt);
   if (menu === 1){
    this.isPopupOpen=true;
    this.tabMenu=1;
   }
   else {
     this.isPopupOpen = false;
     this.account = [];
     this.selectedDt = []
   }
  }
  //    this.selectedDt = data;
  // console.log("Dy",this.selectedDt)
  // this.getPopupInfo();
  //  if (menu === 1)
  //    this.isPopupOpen=true;
  //  else
  //    this.isPopupOpen=false;

 }
