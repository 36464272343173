<app-f33-popup></app-f33-popup>
<div class="gep-table-wrapper">
  <div class="table-wrapper">
    <table class="standard-table gep-table">
      <thead>
        <th class="checkbox-td"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected"
            (change)="checkUncheckAll()"></th>

        <th><span title="Account name">ACCOUNT NAME</span></th>
        <th><span title="Account  number">ACCOUNT NUMBER</span></th>
        <th><span title="Customer  type">CUSTOMER TYPE</span></th>
        <th *ngIf="meterItemSubType!='Unmetered'"><span title="Meter number">METER NUMBER</span></th>
        <th *ngIf="meterItemSubType!='Unmetered'"><span title="Meter voltage">METER VOLTAGE</span></th>
        <th *ngIf="meterItemSubType!='Unmetered'"><span title="Meter make">METER MAKE</span></th>
        <th *ngIf="meterItemSubType!='Unmetered'"><span title="Meter model">METER MODEL</span></th>
        <th *ngIf="meterItemSubType!='Unmetered'"><span title="Connection type">CONNECTION TYPE</span></th>
        <th><span title="Dt name">DT NAME</span></th>
        <th><span title="Dt number">DT NUMBER</span></th>
        <th><span title="Meter type">{{buName}}</span></th>
        <th><span title="Meter type">{{utName}}</span></th>
        <th><span title="Feeder">FEEDER</span></th>
        <th><span title="Dcu sn">DCU SN</span></th>
        <th><span title="Tariff">TARIFF</span></th>
        <th><span title="Energy">ENERGY</span></th>
        <th><span title="Meter max digit">METER MAX. DIGIT</span></th>
        <th><span title="Multiplier factor">MULTIPLIER FACTOR</span></th>
        <th>
          <div class="dropdown">
              <div tabindex="3" class="dropdown-btn" (click)="commStatusClicked(1)" (blur)="commStatusClicked(0)">
              <span title="Comm Status(last 24hrs)">COMMUNICATION STATUS</span>
              <svg *ngIf="commStatusOpened" width="24" height="16" viewBox="0 0 16 16" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z"
                  fill="#8F92A1" fill-opacity="0.4" />
              </svg>
              <svg *ngIf="!commStatusOpened" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z"
                  fill="#8F92A1" fill-opacity="0.4" />
              </svg>
              </div>
              <div class="dropdown-expanded" *ngIf="commStatusOpened">
              <span (mousedown)="commStatusSelected(0)">Deselect</span>
              <span (mousedown)="commStatusSelected(1)">Yes</span>
              <span (mousedown)="commStatusSelected(2)">No</span>
              </div>
          </div>
      </th>
      </thead>
      <tbody>
        <tr *ngFor="let item of data" (click)="openTab(item.id)" class="clickable actions-click">
          <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox"
              class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked" (change)="isAllSelected()"></td>
          <td>
            <span *ngIf="item.customerName !== '/'" title="{{item.customerName}}">{{item.customerName + " " + item.lastName |
              hasValue}}</span>
            <span *ngIf="item.customerName === '/'" class="NaN">N/A</span>
          </td>

          <td class="large-td">
            <span *ngIf="item.accountNumber !== '/'" title="{{item.accountNumber}}">{{item.accountNumber |
              hasValue}}<img alt="Copy" class="icons" src="assets/images-new-design/copy.png"
                (click)="copyToClipboard(item.accountNumber)" click-stop-propagation></span>
            <span *ngIf="item.accountNumber === '/'" class="NaN">N/A</span>
          </td>

          <td>
            <span *ngIf="item.connectionType !== '/'" title="{{item.connectionType}}">{{item.connectionType |
              hasValue}}</span>
            <span *ngIf="item.connectionType === '/'" class="NaN">N/A</span>
          </td>

          <td *ngIf="meterItemSubType!='Unmetered'">
            <span *ngIf="item.meterNumber !== '/'" title="{{item.meterNumber}}">{{item.meterNumber | hasValue}}<img
                class="icons" alt="Copy" src="assets/images-new-design/copy.png"
                (click)="copyToClipboard(item.meterNumber)" click-stop-propagation></span>
            <span *ngIf="item.meterNumber === '/'" class="NaN">N/A</span>
          </td>
          <td *ngIf="meterItemSubType!='Unmetered'">
            <span *ngIf="item.meterVol !== '/'" title="{{item.meterVol}}">{{item.meterVol | hasValue}}</span>
            <span *ngIf="item.meterVol === '/'" class="NaN">N/A</span>
          </td>
          <td *ngIf="meterItemSubType!='Unmetered'">
            <span *ngIf="item.meterMake !== '/'" title="{{item.meterMake}}">{{item.meterMake | hasValue}}</span>
            <span *ngIf="item.meterMake === '/'" class="NaN">N/A</span>
          </td>
          <td *ngIf="meterItemSubType!='Unmetered'">
            <span *ngIf="item.meterModel !== '/'" title="{{item.meterModel}}">{{item.meterModel | hasValue}}</span>
            <span *ngIf="item.meterModel === '/'" class="NaN">N/A</span>
          </td>
          <td *ngIf="meterItemSubType!='Unmetered'">
            <span *ngIf="item.meterType !== '/'" title="{{item.meterType}}">{{item.meterType | hasValue}}</span>
            <span *ngIf="item.meterType === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.dtName !== '/'" title="{{item.dtName}}">{{item.dtName | hasValue}}</span>
            <span *ngIf="item.dtName === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.dtNo !== '/'" title="{{item.dtNo}}">{{item.dtNo | hasValue}}</span>
            <span *ngIf="item.dtNo === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.buName !== '/'" title="{{item.buName}}">{{item.buName | hasValue}}</span>
            <span *ngIf="item.buName === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.utName !== '/'" title="{{item.utName}}">{{item.utName | hasValue}}</span>
            <span *ngIf="item.utName === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.feederName !== '/'" title="{{item.feederName}}">{{item.feederName | hasValue}}</span>
            <span *ngIf="item.feederName === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.dcuSN !== '/'" title="{{item.dcuSN}}">{{item.dcuSN | hasValue}}</span>
            <span *ngIf="item.dcuSN === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.tariffName !== '/'" title="{{item.tariffName}}">{{item.tariffName | hasValue}}</span>
            <span *ngIf="item.tariffName === '/'" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.energy !== 0" title="{{item.energy}}">{{item.energy | hasValue}}</span>
            <span *ngIf="item.energy === 0" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.meterMaxDigit !== 0" title="{{item.meterMaxDigit}}">{{item.meterMaxDigit |
              hasValue}}</span>
            <span *ngIf="item.meterMaxDigit === 0" class="NaN">N/A</span>
          </td>
          <td>
            <span *ngIf="item.multiplierFactor !== 0" title="{{item.multiplierFactor}}">{{item.multiplierFactor |
              hasValue}}</span>
            <span *ngIf="item.multiplierFactor === 0" class="NaN">N/A</span>
          </td>
          <td>
            <div *ngIf="item.lastReadSync !== '/'" class="assetForm">{{item.lastReadSync}}</div>
          </td>
          <td><button type="button" id="openPopup" class="btn btn-more">See More</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
<div class="pagination-wrapper">
  <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
    (pageChange)="pageChange($event)">
  </app-fo-pagination-new>
</div>
