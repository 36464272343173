<div class="count-box"> 
    <div class="meter-bypass-rectangle" [ngClass]="{'green-bar': isGreen}">
    </div>
    <div class="box-info">
        <div>
            <span>{{label}}</span>
        </div>
        <div class="box-data">
            <span class="count">{{data}}</span>
            <span class="view-details" *ngIf="viewDetails" (click)="detailsClicked()">VIEW DETAILS</span>
        </div>
        <div>       
            <span class="last-month">{{secondData}}</span>
        </div>
    </div>
</div>