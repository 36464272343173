<div class="gep-table-wrapper">
    <div class="table-wrapper">
        <table class="standard-table gep-table">
            <thead>
                <th class="large-td"><span title="Meter number">METER NUMBER</span></th>
                <th><span title="Meter make">ACCOUNT NAME</span></th>
                <th><span title="Meter make">ADDRESS</span></th>
                <th><span title="Meter make">EVENT NAME</span></th>
                <th><span title="Meter make">EVENT DATE</span></th>
                <th><span title="Meter make">EVENT AGE</span></th>

            </thead>
            <tbody>
                <tr *ngFor="let item of data; let i = index">
                    <td>
                        <span *ngIf="item.msn !== '/'" title="{{item.msn}}">{{item.msn | hasValue}}
                            <img alt="Copy" class="icons" src="assets/images-new-design/copy.png"(click)="copyToClipboard(item.msn)">
                        </span>
                        <span *ngIf="item.msn === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.firstName !== '/'" title="{{item.firstName}}">{{item.firstName  }}</span>
                        <span *ngIf="item.firstName === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.street !== '/'" title="{{item.street}}">{{item.street | hasValue}}</span>
                        <span *ngIf="item.street === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.name !== '/'" title="{{item.name}}">{{item.name | hasValue}}</span>
                        <span *ngIf="item.name === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.eventDate !== '/'" title="{{item.eventDate}}">{{(item.eventDate | date:'MMM dd,yyyy')}}</span>
                        <span *ngIf="item.eventDate === '/'" class="NaN">N/A</span>
                    </td>
                    <td>
                        <span *ngIf="item.eventAge !== '/'" title="{{item.eventAge}}">{{item.eventAge | hasValue}} days</span>
                        <span *ngIf="item.eventAge === '/'" class="NaN">N/A</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="pagination-wrapper">
  <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
</div>
