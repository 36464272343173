<div class="statement-of-account-box-header">
    <span>Customer Account Details</span>
</div>

<div>
    <div class="row-fluid account-details-wrapper">
        <div class="details-header">
            <span>{{headers[0]|hasValue}}</span>
        </div>
        <div class="details-data">
            <span *ngIf="!data[0]" class="NaN">N/A</span>
            <span *ngIf="data[0]">{{data[0]|hasValue}}</span>
        </div>
    </div>
    <div class="row-fluid account-details-wrapper">
        <div class="details-header">
            <span>{{headers[1]|hasValue}}</span>
        </div>
        <div class="details-data">
            <span *ngIf="!data[1]" class="NaN">N/A</span>
            <span *ngIf="data[1]">
                {{data[1]|hasValue}}
                <img class="icons" src="assets/images-new-design/copy.png" alt="Copy"
                    (click)="copyToClipboard(data[1])">
            </span>
        </div>
    </div>
    <div class="row-fluid account-details-wrapper">
        <div class="details-header">
            <span>{{headers[2]|hasValue}}</span>
        </div>
        <div class="details-data">
            <span *ngIf="!data[2]" class="NaN">N/A</span>
            <span *ngIf="data[2]">{{data[2]|hasValue}}</span>
        </div>
    </div>
    <div class="row-fluid account-details-wrapper">
        <div class="details-header">
            <span>{{headers[3]|hasValue}}</span>
        </div>
        <div class="details-data">
            <span *ngIf="!data[3]" class="NaN">N/A</span>
            <span *ngIf="data[3]">{{data[3]|hasValue}}</span>
        </div>
    </div>
    <div class="row-fluid account-details-wrapper">
        <div class="details-header">
            <span>{{headers[4]|hasValue}}</span>
        </div>
        <div class="details-data">
            <span *ngIf="!data[4]" class="NaN">N/A</span>
            <span *ngIf="data[4]">{{data[4]|hasValue}}</span>
        </div>
    </div>
    <div class="row-fluid account-details-wrapper">
        <div class="details-header">
            <span>{{headers[5]|hasValue}}</span>
        </div>
        <div class="details-data">
            <span *ngIf="!data[5]" class="NaN">N/A</span>
            <span *ngIf="data[5]">{{data[5]|hasValue}}</span>
        </div>
    </div>
    <div class="row-fluid account-details-wrapper">
        <div class="details-header">
            <span>{{headers[6]|hasValue}}</span>
        </div>
        <div class="details-data">
            <span *ngIf="!data[6]" class="NaN">N/A</span>
            <span *ngIf="data[6]">{{data[6]|hasValue}}</span>
        </div>
    </div>
    <div class="row-fluid account-details-wrapper">
        <div class="details-header">
            <span>{{headers[7]|hasValue}}</span>
        </div>
        <div class="details-data">
            <span *ngIf="!data[7]" class="NaN">N/A</span>
            <span *ngIf="data[7]">{{data[7]|hasValue}}</span>
        </div>
    </div>
</div>
