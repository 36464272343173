import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Dcu } from 'src/app/models/dcu.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { ConcentratorService } from 'src/app/services/concentrator.service';
import { TranslationService } from 'src/app/services/translation.service';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { cloneDeep } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { UndertakingService } from 'src/app/services/undertaking.service';

@Component({
  selector: 'app-dcu-new',
  templateUrl: './dcu-new.component.html',
  styleUrl: './dcu-new.component.scss'
})
export class DcuNewComponent implements OnInit {

  @ViewChild('search') searchChild!: SearchAutocompleteComponent;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public masterSelected: boolean = false;
  public checkedList: any = [];

  public dcus: Dcu[] = [];
  public selectedDcu: Dcu = new Dcu();

  public concentratorName: string = '';

  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase()
  public utName: string = this.translationService.getByKeyFromCache('UT').toUpperCase()
  public searchText: SearchAutocomplete = new SearchAutocomplete();
  public searchPlaceholder: string = 'Search by concentrator serial number';
  public searchLength: number = 1;
  public options = [];

  public tabName: string = 'Online';
  public tabNumber: number = 0;
  public filterTabId: number = 4;
  public tabs: any = [];

  public onlineNumber: number = 0;
  public offlineNumber: number = 0;
  //multi filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  public buIds: number[] = [];
  public utIds: any = [];

  public undertakingsNew: any = { name: this.utName, data: [] };
  public businessUnitsNew: any = { name: this.buName, data: [] };
  constructor(private dcuService: ConcentratorService,
    private toastr: ToastrService,
    private buService: BusinessUnitService,
    private utService: UndertakingService,
    private translationService: TranslationService) {
  }

  ngOnInit(): void {
    this.getData();
    this.getAllBUs()
    this.getAllUTs()
    this.reloadTable();
  }
  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)

      if (item.name === this.undertakingsNew.name) {
        this.utIds = filteredValues
      }
      else if (item.name === this.businessUnitsNew.name) {
        this.buIds = filteredValues
      }
    }
    this.getData()
  }
  checkFilters() {
    this.filterApplied = true;
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnitsNew.name);

    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnitsNew.name);
    if (buIndex !== -1) {
      this.allFilters[buIndex].data = this.businessUnitsNew.data;
    }
    let utData = this.allFilters.find((item: { name: any; }) => item.name === this.undertakingsNew.name);

    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakingsNew.name);
    if (utIndex !== -1) {
      this.allFilters[utIndex].data = this.undertakingsNew.data;
    }

    if (!buData)
      this.allFilters.push(this.businessUnitsNew)
    if (!utData)
      this.allFilters.push(this.undertakingsNew)


    this.allFilters = [...this.allFilters]
  }

  getAllUTs() {
    this.utService.getAllForSelect().subscribe(response => {
      this.undertakingsNew = { name: this.utName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getAllBUs() {
    this.buService.getAllForSelect().subscribe(response => {
      this.businessUnitsNew = { name: this.buName, data: response.data }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getTabFilters() {
    this.dcuService.getTabFilters(this.tabNumber).subscribe(response => {
      this.tabs = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getData() {
    this.concentratorName = this.translationService.getByKeyFromCache('Concentrator');

    this.getTabFilters();

    this.dcuService.getOnlineAndOfflineNumber().subscribe(response => {
      this.onlineNumber = response.data.onlineNumber;
      this.offlineNumber = response.data.offlineNumber;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  searchChange(text: SearchAutocomplete) {
    this.searchText = text;
    this.searchText = cloneDeep(this.searchText);
    this.reloadTable();
  }

  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name
    }

    this.searchChild.searchLength = this.searchLength;
    if (searchText.name.length > 1) {
      this.dcuService.getDataForAutocomplete(obj).subscribe({
        next: (response) => this.options = response.data,
        error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
      })
    }
  }

  mainTabChange(value: number) {
    if (value == 1) {
      this.tabName = 'Online';
    }
    else if (value == 2) {
      this.tabName = 'Offline';
    }
    this.tabNumber = value;
    this.getTabFilters();
    this.checkedList = [];
    this.reloadTable();
  }

  secondTabChange(value: number) {
    this.filterTabId = value;
    this.checkedList = [];
    this.reloadTable();
  }

  public copySerialNumber(serialNumber: any) {
    navigator.clipboard.writeText(serialNumber)
    this.toastr.success('Copied to clipboard')
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null) {
    let isOnline = 0;
    if (this.tabNumber == 1)
      isOnline = 1;
    if (page)
      this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText.name,
        tabName: this.tabName,
        amiId: this.filterTabId,
        isMM: true,
        isOnline: isOnline
      }
    };

    this.dcuService.getAllForMeterManagement(obj).subscribe(response => {
      this.dcus = response.data.data;
      if (obj.pageInfo.page == 1) {
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  checkUncheckAll() {
    for (let i = 0; i < this.dcus.length; i++) {
      this.dcus[i].checked = this.masterSelected;
      this.checkForDownload(this.dcus[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if (!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if (index !== -1) {
      if (!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if (isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.dcus.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if (index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.dcus.every(function (item: any) {
      return item.checked == true;
    })
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }
}
