import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class WomteamService {

  private pathApi = this.config.setting['pathApi'] + "womteam/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    getAllForSelect(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSelect`);
    }

    getAll(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAll`, obj);
    } 
    getAllUnengaged(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAllUnengaged`, obj);
    } 
    getAllEngaged(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAllEngaged`, obj);
    } 
    save(obj: any): Observable<any>{
      let data = { 'name' : obj}
      return this.http.post(`${this.pathApi}save`, data);
    }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    } 
    getCount(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getCount`,obj);
    } 

    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    } 
    deleteTeam(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}deleteTeam`,obj);
    } 
    createOrEditWOMTeam(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}createOrEditWOMTeam`,obj)
    }
    deleteAgent(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}deleteAgent`,obj)
    }
    getDataForAutocomplete(searchText:string): Observable<any> {
      return this.http.get(`${this.pathApi}getDataForAutocomplete/${searchText}`)
    }
}