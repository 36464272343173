<div class="tabs-wrapper" [style.margin-left]="marginLeft" [style.margin-bottom]="marginBottom" [style.margin-top]="marginTop">
    <div class="tab-container" (click)="changeTab(1)" [ngClass]="{'selected' : tabSelected == 1 && !isInvalidAccount, 'wom-selected': womCss, 'pa-selected': paCss}">
      <img alt="" [src]="firstImg" *ngIf="firstImg">
      <span>{{firstText}}</span>
      <span class="tab-num" *ngIf="wom">{{firstTabNum}}</span>
    </div>
    <div class="tab-container" (click)="changeTab(2)" [ngClass]="{'selected' : tabSelected == 2 && !isInvalidAccount, 'wom-selected': womCss, 'pa-selected': paCss}">
      <img alt="" [src]="secondImg" *ngIf="secondImg">
      <span>{{secondText}}</span>
      <span class="tab-num" *ngIf="wom">{{secondTabNum}}</span>
    </div>
    <div class="tab-container" *ngIf="thirdText !== ''" (click)="changeTab(3)" [ngClass]="{'selected' : tabSelected == 3 && !isInvalidAccount, 'wom-selected': womCss}">
      <span>{{thirdText}}</span>
      <span class="tab-num" *ngIf="wom">{{thirdTabNum}}</span>
    </div>
    <div class="tab-container" *ngIf="forthText !== ''" (click)="changeTab(4)" [ngClass]="{'selected' : tabSelected == 4 && !isInvalidAccount, 'wom-selected': womCss}">
      <span>{{forthText}}</span>
      <span class="tab-num" *ngIf="wom">{{fourthTabNum}}</span>
    </div>
    <div class="tab-container" *ngIf="fifthText !== ''" (click)="changeTab(5)" [ngClass]="{'selected' : tabSelected == 5 && !isInvalidAccount, 'wom-selected': womCss}">
      <span>{{fifthText}}></span>
      <span class="tab-num" *ngIf="wom">{{fifthTabNum}}</span>
    </div>
  </div>
