import { TranslationService } from 'src/app/services/translation.service';
import { ToastrService } from 'ngx-toastr';
import { Translation } from './../../models/translation.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { TranslationCreateComponent } from './translation-create/translation-create.component';

@Component({
  selector: 'app-translation-page',
  templateUrl: './translation-page.component.html',
  styleUrls: ['./translation-page.component.scss']
})
export class TranslationPageComponent implements OnInit {

  public translations: Translation[] = [];
  public fieldInvalid: boolean = false;

  @ViewChild(TranslationCreateComponent) popupComponentRef!: TranslationCreateComponent;

  constructor(private toastr: ToastrService,
              private translationService: TranslationService,
              private location: Location) { }

  ngOnInit(): void {
    this.getAllTranslations();
  }

  back(){
    this.location.back();
  } 

  getAllTranslations(){
    this.translationService.getAll().subscribe(response => {
      this.translations = response.data;
    }, error => {
      this.toastr.error('An error occured');
    });
  }

  reloadTable(){
    this.getAllTranslations();
  }

  save(){
      if (!this.dropBoxValid())
        return;

      this.translationService.save(this.translations).subscribe(response => {
        this.toastr.success("Successfully saved");
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  dropBoxValid(): boolean{
    this.fieldInvalid = false;

    this.translations.forEach(obj => {
      if (obj.value === "")
        this.fieldInvalid = true;
    })
    if (this.fieldInvalid)
      return false;
      
    return true;
  }

  openTab(){
    this.popupComponentRef.changeState(1);
  }
}