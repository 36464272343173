<main class="container-fluid">
    <div class="container-wrapper">
        <span class="network-management-title">Invalid TFE Reads</span>

        <div class="nm-filter-wrapper">
            <app-tab-default
                [firstText]="'33KV Feeders'"
                [secondText]="'11KV Feeders'"
                [thirdText]="'Distribution Transformer'"
                (tabChanged)="tabChange($event)"
                [tabSelected]="tabMenu"
                [paCss]="true"
                [womCss]="true">
            </app-tab-default>
        </div>

        <div class="filters-container">
            <div class="multi-filter-wrapper">
                <app-range-datepicker
                    [fromDate]="dateFrom"
                    [toDate]="dateTo"
                    [name]="'Period'"
                    [maxData]="true"
                    (dateRangeSelected)="dateChange($event)">
                </app-range-datepicker>

                <app-search-autocomplete
                    #search
                    [width]="30"
                    [placeholder]="searchPlaceholder"
                    [searchText]="searchText"
                    [options]="options"
                    (searchChange)="searchChange($event)"
                    (searchAutocompleteChange)="getOptions($event)"
                ></app-search-autocomplete>
            </div>
        </div>
    
        <table class="standard-table gep-table">
            <thead>
                <tr>
                    <th title="MSN" class="large-td"><span>MSN</span></th>
                    <th title="Value" class="large-td"><span>VALUE (MWH)</span></th>
                    <th title="Read Date Time" class="large-td"><span>READ DATE TIME</span></th>
                </tr>
            </thead>
            
            <tbody>
                <tr *ngFor="let item of data">
                    <td title="{{item.msn}}" class="large-td">
                        <span *ngIf="item.msn && item.msn != '/'">{{item.msn}}</span>
                        <span *ngIf="!item.msn || item.msn == '/'" class="NaN">N/A</span>
                    </td>
                    <td title="{{item.val}}" class="large-td">
                        <span *ngIf="item.val || item.val == 0">{{item.val | number:'0.3-3'}}</span>
                        <span *ngIf="!item.val && item.val != 0" class="NaN">N/A</span>
                    </td>
                    <td title="{{item.ts}}" class="large-td">
                        <span *ngIf="item.ts">{{item.ts | date: 'MMM dd, yyyy, HH:mm:ss'}}</span>
                        <span *ngIf="!item.ts" class="NaN">N/A</span>
                    </td>                
                </tr>
            </tbody>
        </table>

    </div>
</main>

<div class="pagination-wrapper">
    <app-pagination [pageSize]="pageSize" [currentPage]="currentPage" 
        [lastPageSize]="lastPageSize" [count]="count" (pageChange)="pageChange($event)">
    </app-pagination>
</div>
    