import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private pathApi = this.config.setting['pathApi'] + "user/";


  constructor(private http: HttpClient, private config: AppConfig) {

  }

  getByEmail(email: any): Observable<any> {
    return this.http.get(`${this.pathApi}getUser/${email}`);
  }
  getByGuid(guid: any): Observable<any> {
    return this.http.get(`${this.pathApi}getByGuid/${guid}`);
  }
  getDataForAutocomplete(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
  }
  deletePicture(guid: any): Observable<any> {
    return this.http.delete(`${this.pathApi}deletePicture/${guid}`);
  }
  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}download`,obj);
  }
  forgotPasswordRequest(email: any): Observable<any> {
    return this.http.post(`${this.pathApi}forgotPasswordRequest/${email}`, email)
  }

  savePassword(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}savePassword/${obj}`, obj);
  }
  delete(guid: string): Observable<any> {
    return this.http.delete(`${this.pathApi}delete/${guid}`);
  }

  save(obj: User): Observable<any> {
    obj.roleId = +obj.roleId;
    obj.buId = +obj.buId;
    obj.designationId = +obj.designationId;
    return this.http.post(`${this.pathApi}save`, obj);
  }
  
  updateProfile(obj: User, image: File | null, fileName: string | null): Observable<any> {
    let formData: FormData = new FormData();
    if (image && fileName) {
      formData.append('image', image, fileName);
    }
    formData.append('email', obj.email);
    formData.append('firstName', obj.firstName);
    formData.append('lastName', obj.lastName);
    formData.append('password', obj.password);
    formData.append('oldPassword', obj.oldPassword);
    formData.append('organisation', obj.organisation);
    formData.append('usersDesignation', obj.designationId.toString());

    return this.http.post(`${this.pathApi}updateProfile`, formData, { observe: 'response' });
  }

  setPasswordForNewUser(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}setPasswordForNewUser`, obj);
  }

  getAll(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, obj);
  }  
  getAllUnengaged(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllUnengaged`, obj);
  }
  getAllInactiveFieldAgents(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllInactiveFieldAgents`, obj);
  }

  getAllForSelect(): Observable<any> {
    return this.http.get(`${this.pathApi}getAllForSelect`);
  }
  uploadFile(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile`, obj);
  }
  getAllCaimsOperatorsForSelect(): Observable<any> {
    return this.http.get(`${this.pathApi}getAllCaimsOperatorsForSelect`);
  }

  sendMail(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}sendMail/${obj}`, obj)
  }

  getTemplate(): Observable<any> {
    return this.http.get(`${this.pathApi}getTemplate`);
  }

  sendUpgradePlanMail(module: string): Observable<any> {
    return this.http.get(`${this.pathApi}sendUpgradePlanMail/${module}`)
  }

  getAllAgentsByTeamId(teamId: number): Observable<any> {
    return this.http.get(`${this.pathApi}getAllFieldAgentsByTeamId/${teamId}`)
  }
}
