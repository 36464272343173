<main>
  <div class="my-container">
    <div class="page-title-wrapper">
      <span class="network-management-title">Dashboard</span>
    </div>
    <div class="horizontal-line"></div>

  
    <app-dashboard-network-preformance></app-dashboard-network-preformance>

    <app-meter-bypass-diagnostic></app-meter-bypass-diagnostic>
    <div class="horizontal-line"></div>

    <app-overdue-postpaid-bill-debts></app-overdue-postpaid-bill-debts>

    <app-current-total-collections></app-current-total-collections>
  </div>
</main>
