<div class="container-fluid">
    <div class="top-container">

        <div class="statement-of-account-header" *ngIf="initialScreen">
            <div class="statement-of-account-row">
                <div class="statement-of-account-page-header">
                    <span>Debt Management</span>
                </div>
                <div class="arrow-wrapper">
                    <img alt=">" src="assets/images-new-design/arrow_forward_ios.svg">
                </div>
                <div class="statement-of-account-box-header">
                    <span>Statement of Account</span>
                </div>
            </div>
        </div>

        <div class="generate-statement-wrapper" *ngIf="initialScreen">
            <div class="box-wrapper">
                <div class="top-wrapper">
                    Generate Statement of account
                </div>
                <div class="bottom-wrapper">
                    <div class="search-container">
                        <input #searchInput class="search-input" type="text" name="searchText"
                        [(ngModel)]="accNumber"
                        placeholder="Enter account number to generate statement">
                    </div>
                    <button (click)="accountSearch(true)">
                        Generate
                    </button>
                </div>
                <div class="error-wrapper" *ngIf="invalidAccount">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 15C10.2833 15 10.5208 14.9042 10.7125 14.7125C10.9042 14.5208 11 14.2833 11 14C11 13.7167 10.9042 13.4792 10.7125 13.2875C10.5208 13.0958 10.2833 13 10 13C9.71667 13 9.47917 13.0958 9.2875 13.2875C9.09583 13.4792 9 13.7167 9 14C9 14.2833 9.09583 14.5208 9.2875 14.7125C9.47917 14.9042 9.71667 15 10 15ZM9 11H11V5H9V11ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#C03438"/>
                    </svg>
                    <span>Please enter a valid account number</span>
                </div>
            </div>
        </div>

        <div class="statement-of-account-header" *ngIf="!initialScreen">
            <div class="statement-of-account-row second-screen">
                <div class="left-wrapper">
                    <div class="back-arrow-wrapper" (click)="backToInitalScreen()">
                        <img alt=">" src="assets/images-new-design/arrow_forward.png">
                    </div>
                    <div class="statement-of-account-box-header">
                        <span>Statement of Account Details - {{accNumber}}</span>
                    </div>
                </div>
                <div class="right-wrapper">
                    <app-download-button-new
                    [isOnlyDownload]="false"
                    (downloadButtonClickedChange)="downloadReport()"
                    (downloadAndSendButtonClickedChange)="downloadAndSendReport($event)"
                    [onlyDownloadText]="'Download Statement'">
                    </app-download-button-new>
                </div>
            </div>
        </div>

        <div class="tab-wrapper" *ngIf="!initialScreen" >
            <app-tab-nm-dashboard
                [firstText]="'Debt Balance & History'"
                [secondText]="'Account Details'"
                (tabChanged)="tabChanged($event)">
            </app-tab-nm-dashboard>
        </div>
        <div class="date-picker" *ngIf="tabEnum == 1 && !initialScreen">
            <app-range-datepicker [fromDate]="dateFrom" [toDate]="dateTo" [name]="'Date'"
                (dateRangeSelected)="updateDate($event)">
            </app-range-datepicker>
        </div>
        <div class="main-wrapper" *ngIf="!initialScreen">
            <div class="first-tab" *ngIf="tabEnum == 1">
                <div class="debt-balance">
                    <div class="header-wrapper">
                        Debt Balance
                    </div>
                    <div class="body-wrapper">
                        <field-count-box-new
                            [label]="'Original Debt Owed'"
                            [data]="debtBalance.actualDebt | number | hasValue"
                            [isYellow]="true"
                            [height]="'12rem'">
                        </field-count-box-new>

                        <field-count-box-new
                            [label]="'Total Debt After Adjustment/Discount'"
                            [data]="debtBalance.debtAfterAdjustment | number | hasValue"
                            [isGreen]="true"
                            [height]="'12rem'">
                        </field-count-box-new>

                        <field-count-box-new
                            [label]="'Current Total Debt Owed'"
                            [data]="debtBalance.currentTotalDebt | number | hasValue"
                            [isBlue]="true"
                            [height]="'12rem'">
                        </field-count-box-new>
                    </div>
                </div>
                <div class="debt-history">
                    <div class="header-wrapper">
                        Payment History
                    </div>
                    <app-statement-history
                        [data]="paymentHistory"
                        [dateFrom]="dateFrom"
                        [dateTo]="dateTo"
                        [count]="count"
                        [currentPage]="page"
                        [pageSize]="pageSize"
                        [accNumber]="accNumber"
                        [cumulativePayment]="cumulativePayment"
                    ></app-statement-history>
                </div>
            </div>
            <div class="second-tab" *ngIf="tabEnum == 2">
                <app-statement-account-details
                    [data]="accData" [headers]="accHeaders">
                </app-statement-account-details>
            </div>
        </div>

    </div>
</div>
