
<div [hidden]="!showShadow" class="popup-overlay">
  <div class="feeder11-popup" [ngClass]="{'shadow' : showShadow === true}">
      <div class="tab-header">
          <div  class="title-popup">
          <span *ngIf="selectedFeeder11.id === 0">Create Feeder11</span>
          <span *ngIf="selectedFeeder11.id !== 0">Edit Feeder11</span>
          </div>
          <div class="btn-close-position" (click)="changeState(2, selectedFeeder11)">
              <div class="btn-close">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 12.9436L16.3282 17.2718C16.453 17.3966 16.606 17.4632 16.7872 17.4718C16.9684 17.4804 17.1299 17.4137 17.2718 17.2718C17.4137 17.1299 17.4846 16.9726 17.4846 16.8C17.4846 16.6274 17.4137 16.4701 17.2718 16.3282L12.9436 12L17.2718 7.6718C17.3966 7.54702 17.4632 7.39403 17.4718 7.21283C17.4804 7.03163 17.4137 6.87009 17.2718 6.7282C17.1299 6.58631 16.9726 6.51537 16.8 6.51537C16.6274 6.51537 16.4701 6.58631 16.3282 6.7282L12 11.0564L7.6718 6.7282C7.54702 6.60342 7.39403 6.53676 7.21283 6.5282C7.03163 6.51964 6.87009 6.58631 6.7282 6.7282C6.58631 6.87009 6.51537 7.02736 6.51537 7.2C6.51537 7.37264 6.58631 7.52991 6.7282 7.6718L11.0564 12L6.7282 16.3282C6.60342 16.453 6.53676 16.606 6.5282 16.7872C6.51964 16.9684 6.58631 17.1299 6.7282 17.2718C6.87009 17.4137 7.02736 17.4846 7.2 17.4846C7.37264 17.4846 7.52991 17.4137 7.6718 17.2718L12 12.9436ZM12.0045 24C10.3451 24 8.78496 23.6851 7.32413 23.0554C5.86331 22.4256 4.59259 21.5709 3.51197 20.4913C2.43137 19.4117 1.57589 18.1422 0.945533 16.6827C0.315178 15.2233 0 13.6638 0 12.0045C0 10.3451 0.314878 8.78496 0.944633 7.32413C1.57441 5.86331 2.4291 4.59259 3.5087 3.51197C4.5883 2.43137 5.85782 1.57589 7.31727 0.945534C8.77673 0.315179 10.3362 0 11.9955 0C13.6549 0 15.215 0.314877 16.6759 0.944633C18.1367 1.57441 19.4074 2.4291 20.488 3.5087C21.5686 4.5883 22.4241 5.85782 23.0545 7.31727C23.6848 8.77673 24 10.3362 24 11.9955C24 13.6549 23.6851 15.215 23.0554 16.6759C22.4256 18.1367 21.5709 19.4074 20.4913 20.488C19.4117 21.5686 18.1422 22.4241 16.6827 23.0545C15.2233 23.6848 13.6638 24 12.0045 24ZM12 22.6667C14.9778 22.6667 17.5 21.6333 19.5667 19.5667C21.6333 17.5 22.6667 14.9778 22.6667 12C22.6667 9.02222 21.6333 6.5 19.5667 4.43333C17.5 2.36667 14.9778 1.33333 12 1.33333C9.02222 1.33333 6.5 2.36667 4.43333 4.43333C2.36667 6.5 1.33333 9.02222 1.33333 12C1.33333 14.9778 2.36667 17.5 4.43333 19.5667C6.5 21.6333 9.02222 22.6667 12 22.6667Z" fill="#7F7D82" />
                    </svg>
              </div>
          </div>

      </div>
      <form (submit)="f.valid" class="form col-sm-12" #f="ngForm">
        <div class="form-inner">
          <div class="col-sm-12 crud-input-container">
            <label class="labelPopup">Name *</label>
            <input class="form-control" name="name" [(ngModel)]="selectedFeeder11.name" #name="ngModel"
                   required autocomplete="off" placeholder="Enter First Name" pattern="^[a-zA-Z0-9]+(?:[a-zA-Z0-9 ]*[a-zA-Z0-9]+)*$" />
            <div *ngIf="f.submitted && name.invalid" class="form-control-error">Name is required</div>
          </div>

          <div class="col-sm-12 crud-input-container">
            <label class="labelPopup">Feeder Number  *</label>
            <input class="form-control" name="feederNo" [(ngModel)]="selectedFeeder11.feederNumber"
                   #feederNo="ngModel" required autocomplete="off" placeholder="Feeder Number" pattern="^[a-zA-Z0-9]+(?:[a-zA-Z0-9 ]*[a-zA-Z0-9]+)*$"/>
            <div *ngIf="f.submitted && feederNo.invalid" class="form-control-error">
              Feeder Number is
              required
            </div>
          </div>

          <div class="col-sm-12 crud-input-container">
            <label class="labelPopup">Is Metered  *</label>
            <select class="form-control clickable actions-click" (click)="isMeteredValidate()"
                    name="meterType" [(ngModel)]="selectedFeeder11.meterType" #meterType="ngModel"
                    id="meterType" required autocomplete="off">
              <option selected disabled [ngValue]='0'>Select Metered Status</option>
              <option [ngValue]='1'>Metered</option>
              <option [ngValue]='2'>Unmetered</option>
            </select>
            <div *ngIf="f.submitted && (selectedFeeder11.meterType === 0)" class="form-control-error">
              Is
              Metered is required
            </div>
          </div>

          <div class="col-sm-12 crud-input-container">
            <label class="labelPopup" *ngIf="selectedFeeder11.meterType == 1">Connected {{amiName}} *</label>
            <label class="labelPopup" *ngIf="selectedFeeder11.meterType !== 1">Connected {{amiName}}</label>
            <select class="form-control clickable actions-click" name="amiId"
                    [disabled]="selectedFeeder11.meterType === 2" [(ngModel)]="selectedFeeder11.amiId"
                    #tsId="ngModel" id="amiSelect" autocomplete="off">
              <option selected disabled [ngValue]='0'>Select {{amiName}}</option>
              <option *ngFor="let a of amis" value="{{a.value}}">{{a.name}}</option>
            </select>
            <div *ngIf="f.submitted && (selectedFeeder11.amiId === 0 && selectedFeeder11.meterType !== 2)"
                 class="form-control-error">Connected Meter Manufacturer is required</div>
          </div>

          <div class="col-sm-12 crud-input-container">
            <label class="labelPopup">Connected SS  *</label>
            <select class="form-control clickable actions-click" name="ssId"
                    [(ngModel)]="selectedFeeder11.ssId" #ssId="ngModel" id="ssSelect" required
                    autocomplete="off" (change)="onChangeSS()">
              <option selected disabled [ngValue]='0'>Select SS</option>
              <option *ngFor="let ss of substations" value="{{ss.value}}">{{ss.name}}</option>
            </select>
            <div *ngIf="f.submitted && selectedFeeder11.ssId == 0" class="form-control-error">
              Connected
              SS is required
            </div>
          </div>

          <div class="col-sm-12 crud-input-container">
            <label class="labelPopup" *ngIf="selectedFeeder11.meterType == 1">Primary Meter Number *</label>
            <label class="labelPopup" *ngIf="selectedFeeder11.meterType !== 1">Primary Meter Number</label>
            <input class="form-control" (input)="validateMSN()" name="number"
                   [disabled]="selectedFeeder11.meterType === 2" [(ngModel)]="selectedFeeder11.meterNumber"
                   #number="ngModel" autocomplete="off" placeholder="Enter Meter Number" />
            <div *ngIf="f.submitted && !(msnValid || selectedFeeder11.meterType === 2)"
                 class="form-control-error">{{msnNumberErrorMsg}}</div>
          </div>
          <div class="col-sm-12 crud-input-container">
            <label class="labelPopup">Connected Feeder33  *</label>
            <select class="form-control clickable actions-click" name="feeder33Id"
                    [(ngModel)]="selectedFeeder11.feeder33Id" #feeder33Id="ngModel" id="f33Select" required
                    autocomplete="off">
              <option selected disabled value="0">Select Feeder33</option>
              <option *ngFor="let f33 of feeders33" value="{{f33.value}}">{{f33.name}}</option>
            </select>
            <div *ngIf="f.submitted && selectedFeeder11.feeder33Id == 0" class="form-control-error">
              Connected Feeder33 is required
            </div>
          </div>
          <div class="col-sm-12 crud-input-container">
            <label class="labelPopup">Nameplate Rating / kVA</label>
            <input class="form-control" name="nameplateRating"
                   [(ngModel)]="selectedFeeder11.nameplateRating" #nameplateRating="ngModel"
                   autocomplete="off" placeholder="Nameplate Rating / kVA" />
            <div *ngIf="f.submitted && !isNameplateRatingValid" class="form-control-error">
              Nameplate
              Rating is invalid
            </div>
          </div>

          <div class="col-sm-12">
            <label class="labelPopup">Meter Voltage</label>
            <select style="cursor: pointer;" class="form-control" name="meterVoltage"
                    [(ngModel)]="selectedFeeder11.meterVoltage" #meterVoltage="ngModel" pattern="[0-2]" autocomplete="off" [disabled]="selectedFeeder11.meterType!= 1">
              <option [ngValue]='0'>Undefined</option>
              <option [ngValue]='2'>Low</option>
              <option [ngValue]='1'>High</option>
            </select>
          </div>

          <div class="col-sm-12">
            <label class="labelPopup">Meter Max Digit</label>
            <input type="number" class="form-control" name="meterMaxDigit" [(ngModel)]="selectedFeeder11.meterMaxDigit" #meterMaxDigit="ngModel"
              autocomplete="off" placeholder="Meter Max Digit" [disabled]="selectedFeeder11.meterType!= 1" />
          </div>

          <div class="col-sm-12">
            <label class="labelPopup">Multiplier Factor</label>
            <input type="number" class="form-control" name="multiplierFactor" [(ngModel)]="selectedFeeder11.multiplierFactor" #multiplierFactor="ngModel"
              autocomplete="off" placeholder="Multiplier Factor" [disabled]="selectedFeeder11.meterType!= 1" />
          </div>

          <div *ngIf="!isEnergyShared" class="power-table">
            <label class="labelPopup">{{buName}} *</label>
            <select class="form-control power-select-bu clickable actions-click"
                    [(ngModel)]="selectedFeeder11.buId" #buSelectId="ngModel" name="buSelect">
              <option selected disabled [ngValue]='0'>Select {{buName}}</option>
              <option *ngFor="let item of buForSelect" value="{{item.value}}">
                {{item.name}}
              </option>
            </select>
            <div *ngIf="f.submitted && (this.selectedFeeder11.buId == 0) && !isEnergyShared"
                 class="form-control-error">{{buName}} is required.</div>
          </div>

          <div class="col-sm-12 crud-input-container power-input-container">
            <div class="power-container">
              <div class="power-sharing">
                <label class="power-title">Boundary Information</label>
                <br>
                <br>
                <div class="energy-shared-checkbox">
                  <label class="labelPopup">Energy Shared:</label>
                  <div>
                    <input type="checkbox" name="shared" [(ngModel)]="isEnergyShared"
                           (change)="checkboxChanged()">
                  </div>

                </div>

                <div class="col-sm-12 crud-input-container" *ngIf="isEnergyShared">
                  <label class="labelPopup">{{buName}} *</label>
                  <select class="form-control power-select clickable actions-click"
                          (change)="buChanged()" [(ngModel)]="buSelected"
                          #buSelectId="ngModel" name="buSelect">
                    <option selected disabled [ngValue]='0'>Select {{buName}} *</option>
                    <option *ngFor="let item of buForSelect" value="{{item.value}}">{{item.name}}</option>
                  </select>
                </div>

                <br>
                <div class="col-sm-12 crud-input-container" *ngIf="isEnergyShared">
                  <label class="labelPopup">Meter Manufacturer</label>
                  <select class="form-control power-select meter-manufacturer clickable actions-click"
                          [(ngModel)]="amiSelected" #manufacturerSelectId="ngModel"
                          name="manufacturerSelect">
                    <option selected disabled [ngValue]='0'>Select Meter Manufacturer</option>
                    <option *ngFor="let a of amis" value="{{a.value}}">{{a.name}}</option>
                  </select>
                </div>

                <br>
                <div class="col-sm-12 crud-input-container" *ngIf="isEnergyShared">
                  <label class="labelPopup">Meter Number</label>
                  <input *ngIf="powerMsnValid" class="form-control power-input valid"
                         (input)="validatePowerMSN()" [(ngModel)]="meterNumber"
                         type="text" name="meterNumberPower" #powerMsn="ngModel">
                  <input *ngIf="!powerMsnValid" class="form-control power-input valid"
                         (input)="validatePowerMSN()" [(ngModel)]="meterNumber"
                         type="text" name="meterNumberPower" #powerMsn="ngModel">
                </div>

                <!-- </td> -->
                <div class="power-add" (click)="addBuPower()" *ngIf="isEnergyShared">
                  <span class="power-btn">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_2_8363)">
                        <path d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z"
                              fill="#4E6883" />
                      </g>
                      <defs>
                        <clipPath id="clip0_2_8363">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </div>

                <div *ngIf="!regionMsnValid" class="form-control-error power-error">That {{buName}} already has a meter</div>

                <div *ngIf="!powerMsnValid" class="form-control-error power-error"></div>

                <div *ngIf="!powerMsnValid" class="form-control-error power-error">Meter Number is invalid</div>

                <tr class="power-items"
                    *ngFor="let item of selectedFeeder11.boundaryMeters">
                  <td>{{item.buName}}</td>
                  <td>{{item.amiName}}</td>
                  <td>{{item.meterNumber}}</td>
                  <td>
                    <span><img alt="Delete" class="power-delete" (click)="deleteFeederBu(item.buId)" src="assets/images/crud-delete.png" /></span>
                  </td>
                </tr>

                <div *ngIf="f.submitted && (selectedFeeder11.boundaryMeters.length == 0) && isEnergyShared"
                     class="form-control-error">Boundary Meters is required.</div>
              </div>
            </div>

          </div>


          <div *ngIf="selectedFeeder11.meterHistory && selectedFeeder11.meterHistory.length > 0" class=" col-sm-12 table-details">
            <div class="title mb-2">Meter History</div>
            <table class="standard-table">
              <thead>
                <tr class="header-columns">
                  <td></td>
                  <td><span>Meter number</span></td>
                  <td><span>From</span></td>
                  <td><span>To</span></td>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of selectedFeeder11.meterHistory" class="bodyRow clickable">
                  <td></td>
                  <td>{{item.meterNumber | hasValue }} </td>
                  <td>{{item.fromDate | date:'dd/MM/yyyy' | hasValue }} </td>
                  <td>{{item.toDate | date:'dd/MM/yyyy' | hasValue }} </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
        <div class="user-btn">
          <button class="submit col-xs-6" (click)="save()">Save</button>
        </div>
      </form>


  </div>
</div>
