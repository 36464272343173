export class CaimsOperator {
  id: number = 0
  userName: string = ''
  email: string = ''
  phoneNumber: string = ''
  agentId: string = ''
  buId: number = 0
  utId: number = 0
  buName: string = ''
  usersDesignation: string = ''
  utName: string = ''
  image: string = ''
  status: string = ''
  noOfAssignTask: number = 0
  dateCreated: Date = new Date()
  userActivation: string = ''
}