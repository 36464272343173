<div>
    <table class="standard-table" *ngIf="allEntities.length > 0">
        <thead>
            <tr>
                <th><span>NAME</span></th>
                <th><span>PHONE</span></th>
                <th><span>EMAIL</span></th>
                <th><span>SUBUNIT</span></th>
                <th><span>TACTITAL ROLE</span></th>
                <th class="actions-th"><span>ACTION</span></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of allEntities" class="bodyRow clickable"
                [ngClass]="{'selected': selectedEntity.id === item.id}">
                <td>
                    <span *ngIf="item.firstName">{{item.firstName | hasValue}} {{item.lastName | hasValue}} </span>
                    <span *ngIf="!item.firstName" class="NaN">N/A</span>
                </td>
                <td>
                    <span *ngIf="item.phoneNumber">{{item.phoneNumber | hasValue}}</span>
                    <span *ngIf="!item.phoneNumber" class="NaN">N/A</span>
                </td>
                <td>
                    <span *ngIf="item.email">{{item.email | hasValue}}</span>
                    <span *ngIf="!item.email" class="NaN">N/A</span>
                </td>
                <td>
                    <span *ngIf="item.subunitName">{{item.subunitName | hasValue}}</span>
                    <span *ngIf="!item.subunitName" class="NaN">N/A</span>
                </td>
                <td>
                    <span *ngIf="item.tacticalRoleString">{{item.tacticalRoleString | hasValue}}</span>
                    <span *ngIf="!item.tacticalRoleString" class="NaN">N/A</span>
                </td>
                <td>
                    <div class="d-flex align-items-center action-table">
                        <span class="table-span actions-click" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_createupdate)"
                        (click)="selectEntity(item.id)">
                            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white"/>
                                <rect x="0.5" y="0.5" width="43" height="43" rx="7.5" stroke="#E8E8E8"/>
                                <mask id="mask0_8505_132517" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="12" y="12" width="20" height="20">
                                <rect x="12" y="12" width="20" height="20" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_8505_132517)">
                                <path d="M15.8334 29.0525C15.5685 29.1113 15.3404 29.045 15.1491 28.8538C14.9579 28.6626 14.8916 28.4345 14.9504 28.1695L15.6475 24.8234L19.1795 28.3554L15.8334 29.0525ZM19.1795 28.3554L15.6475 24.8234L24.9969 15.4741C25.2843 15.1867 25.64 15.043 26.0642 15.043C26.4883 15.043 26.8441 15.1867 27.1314 15.4741L28.5289 16.8715C28.8163 17.1589 28.9599 17.5146 28.9599 17.9388C28.9599 18.3629 28.8163 18.7187 28.5289 19.0061L19.1795 28.3554ZM25.8879 16.3522L17.367 24.8651L19.1379 26.6359L27.6507 18.1151C27.6988 18.067 27.7228 18.0069 27.7228 17.9348C27.7228 17.8626 27.6988 17.8025 27.6507 17.7544L26.2485 16.3522C26.2004 16.3042 26.1403 16.2801 26.0682 16.2801C25.996 16.2801 25.9359 16.3042 25.8879 16.3522Z" fill="#1C1B1F"/>
                                </g>
                            </svg>
                        </span>
                        <span class="table-span actions-click" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_delete)"
                        (click)="openTabDelete(item.id)">
                            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white"/>
                                <rect x="0.5" y="0.5" width="43" height="43" rx="7.5" stroke="#E8E8E8"/>
                                <mask id="mask0_8505_132523" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="12" y="12" width="20" height="20">
                                <rect x="12" y="12" width="20" height="20" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_8505_132523)">
                                <path d="M18.5 29C18.0875 29 17.7344 28.8531 17.4406 28.5594C17.1469 28.2656 17 27.9125 17 27.5V17.5H16V16H20V15H24V16H28V17.5H27V27.491C27 27.9137 26.8531 28.2708 26.5594 28.5625C26.2656 28.8542 25.9125 29 25.5 29H18.5ZM25.5 17.5H18.5V27.5H25.5V17.5ZM20 26H21.5V19H20V26ZM22.5 26H24V19H22.5V26Z" fill="#1C1B1F"/>
                                </g>
                            </svg>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="pagination-wrapper">
        <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
            [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
    </div>
</div>