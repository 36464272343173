<h2 class="ttl">Total Energy into network</h2>
<div class="table"> 
    <table class="standard-table">
        <thead>
            <tr>      
                <th>MONTH</th>                        
                <th>ENERGY RECEIVED (MWH)</th>
                <th *ngIf="tab === 1 || tab === 2">ENERGY TRANSMITTED (MWH)</th>
                <th *ngIf="tab === 3">ENERGY CONSUMED (MWH)</th>
                <th *ngIf="tab === 1">LOSSES (MWH)</th>
                <th *ngIf="tab === 2">LOSSES (MWH) - 11KV TO DT</th>
                <th *ngIf="tab === 3">LOSSES (MWH) - DT TO CUSTOMER</th>
            </tr>
        </thead>
        <tbody class="table-body">    
            <tr *ngFor="let item of EnergyLabels; let i = index">
                <td class="bold">{{item}}</td>    
                <td class="bold">{{receivedAndConsumedData[0].data[i] | number: '1.2-2' || "/"  }}</td>
                <td class="bold">{{receivedAndConsumedData[1].data[i] | number: '1.2-2' || "/"  }}</td>      
                <td class="bold">{{receivedAndConsumedData[2].data[i] | number: '1.2-2' || "/"  }}</td>     
            </tr>  
            <tr>
                <td class="average">AVERAGE</td>
                <td class="average" style="padding-left: 1rem !important;">{{avgReceived | number: '1.2-2' || "/" }}</td>
                <td class="average" style="padding-left: 1rem !important;">{{avgConsumed | number: '1.2-2' || "/" }}</td>
                <td class="average" style="padding-left: 1rem !important;">{{avgLosses | number: '1.2-2' || "/" }}</td>
            </tr>
        </tbody>
    </table>       
</div>