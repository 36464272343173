import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent {
  constructor(private elRef: ElementRef) {
    this.label = 'Select'
  }

  @Input() options : any;
  @Input() dropdownName: any;
  @Input() selectedOption: any;
  @Input() label: string;

  @Output() optionSelected = new EventEmitter<any>();
  @Output() clearSelected = new EventEmitter<any>();


  onOptionClick(option:any) {
    this.optionSelected.emit({ option });
  }

  clearSelection(e:any) {
    e.stopPropagation();
    this.clearSelected.emit();
  }

}
