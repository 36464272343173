import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { StockItem } from 'src/app/models/stock-item.model';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';
import { CommonService } from 'src/app/services/common-service.service';
import { InventoryManagementService } from 'src/app/services/inventory-management.service';

@Component({
  selector: 'app-stock-management-create',
  templateUrl: './stock-management-create.component.html',
  styleUrls: ['./stock-management-create.component.scss']
})
export class StockManagementCreateComponent implements OnInit, OnChanges {

  @Output() changeView: EventEmitter<any> = new EventEmitter()
  @Output() reloadTable = new EventEmitter<any>();

  public categories: DictionaryItem[] = []

  public selectedCategory: DictionaryItem = new DictionaryItem()

  @Input() itemId = 0
  public stockItem: StockItem = new StockItem()

  public selectedFiles!: FileList
  public attachments: string[] = []
  message : string = '';

  constructor(private toastr: ToastrService,
    private commonService: CommonService,
    private inventoryService: InventoryManagementService) { }

  ngOnInit(): void {
    this.selectedCategory.value = -1
    this.getAllCategories()
    this.setDefaultHeaders()
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.itemId != null && changes.itemId.currentValue != undefined){
      this.itemId = changes.itemId.currentValue
      if(this.itemId != 0){
        this.getStockItem()
      }
    }
  }

  getStockItem(){
    this.inventoryService.getSingleStockItem(this.itemId).subscribe(response =>{
      this.setTaskFields(response.data)
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  
  setTaskFields(item: StockItem){
    this.stockItem = item
    this.selectedCategory.value = item.categoryId
    this.selectedCategory.name = item.categoryName
  }


  setDefaultHeaders(){
    this.selectedCategory.name = "Select category"
  }

  selectCategories(item: DictionaryItem){
    this.selectedCategory = item
    this.stockItem.categoryId = item.value
  }

  getAllCategories(){
    this.commonService.getAllItemCategories().subscribe(response =>{
      this.categories = response.data
    }, _=>{
      this.toastr.error("An error occurred")
    })
  }

  onFileChanged(event: any) {
    let files = event.target.files
    this.selectedFiles = files
    for(let i = 0; i< files.length; i++){
      if(files[i]){
        this.attachments.push(files[i].name)
      }  
      
    }
  }

  public create(){
    if(!this.validate())
      return

    let formData: FormData = new FormData();
    if (this.selectedFiles) {
      for(let i = 0; i<this.selectedFiles.length; i++){
        formData.append('files', this.selectedFiles[i], this.selectedFiles[i].name);
      }
      
    } 

    formData.append('id', this.stockItem.id.toString()); 
    formData.append('name', this.stockItem.name); 
    formData.append('description', this.stockItem.description); 
    formData.append('categoryId', this.stockItem.categoryId.toString()); 
    formData.append('totalQuantity', this.stockItem.totalQuantity.toString());
    formData.append('faultQuantity', this.stockItem.faultQuantity.toString());
    formData.append('threshold', this.stockItem.threshold.toString());

    this.inventoryService.saveStockItem(formData).subscribe(response => {
      if(response.status === 200 || response.status === 201) {
        this.toastr.success(response.message);
        this.handleNewItem();
        this.changeView.emit(ViewsEnum.View)
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  handleNewItem() {
    this.reloadTable.emit();
  }

  validate() {
    let isValid = true
    if(this.stockItem.categoryId < 0)
      isValid = false;

    if(!isValid){
      this.toastr.warning('Some required fields are empty')
    }
    return isValid
  }

  back(){
    if(this.itemId == 0)
      this.changeView.emit(ViewsEnum.View)
    else
      this.changeView.emit(ViewsEnum.ShowDetails)
  }

  alert() {
    this.toastr.warning("Not yet implemented.");
  }

}
