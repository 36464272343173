import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, args?: any) {
    if (!value)
      return '/';

    if (value === '')
      return '/';

    return formatDate(value, "yyyy-MM-dd", "en");
  }
}
