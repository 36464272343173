<main class="container-fluid">
    <div class="container-wrapper">
        <div class="filters-container">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                <app-multi-filter *ngIf="filterApplied" [data]="allFilters" (dataChange)="applyFilter($event)"
                    [(showFilter)]="showFilter" (dropdownChange)="dropdownSelected($event)">

                </app-multi-filter>
                <app-range-datepicker
                [fromDate]="dateFrom" 
                [toDate]="dateTo"
                [name]="'Period'"
                (dateRangeSelected)="updateDate($event)">
            </app-range-datepicker>
                <app-search-autocomplete #search 
                [placeholder]="searchPlaceholder"
                [searchText]="searchText" [options]="options" (searchChange)="inputChanged($event)"
                    (searchAutocompleteChange)="getOptions($event)"></app-search-autocomplete>
            </div>


            <div style="display: flex; gap: 5px;">
                <app-map-table-button-new [isMap]="isMap" (filterClicked)="mapChanged($event)"> 
                </app-map-table-button-new>
                <div class="download-button">
                    <app-download-button-new [isOnlyDownload]="true" (downloadButtonClickedChange)="downloadAccounts()">
                    </app-download-button-new>
                </div>
            </div>
        </div>
        <div class="gep-table-wrapper">
            <div class="table-wrapper">
                <table class="standard-table gep-table">
                    <thead>
                        <th class="large-td"><span title="Account Name">ACCOUNT NAME</span></th>
                        <th class="large-td"><span title="Account Number">ACCOUNT NUMBER</span></th>
                        <th><span title="Dt Number">DT NUMBER</span></th>
                        <th class="large-td"><span title="Dt Name">DT NAME</span></th>
                        <th class="large-td"><span title="Bu Name">BU NAME</span></th>
                        <th class="large-td"><span title="Ut Name">UT NAME</span></th>
                        <th class="large-td"><span title="Feeder">FEEDER</span></th>
                    </thead>

                    <tbody>
                        <tr *ngFor="let item of accounts">
                            <td>
                                <span *ngIf="item.firstName !== '/'" title="{{item.firstName}}">{{item.firstName |
                                    hasValue}}</span>
                                <span *ngIf="item.firstName === '/'" class="NaN">N/A</span>
                            </td>
                            <td>
                                <span *ngIf="item.accountNo !== '/'" title="{{item.accountNo}}">{{item.accountNo |
                                    hasValue}}</span>
                                <span *ngIf="item.accountNo === '/'" class="NaN">N/A</span>
                            </td>
                            <td>
                                <span>{{item.dtName}}</span>
                            </td>
                            <td>
                                <span *ngIf="item.dtName !== '/'" title="{{item.dtName}}">{{item.dtName }}</span>
                                <span *ngIf="item.dtName === '/'" class="NaN">N/A</span>
                            </td>
                            <td>
                                <span *ngIf="item.buName !== '/'" title="{{item.buName}}">{{item.buName}}</span>
                                <span *ngIf="item.buName === '/'" class="NaN">N/A</span>
                            </td>
                            <td>
                                <span *ngIf="item.utName !== '/'" title="{{item.utName}}">{{item.utName }}</span>
                                <span *ngIf="item.utName === '/'" class="NaN">N/A</span>
                            </td>
                            <td>
                                <span *ngIf="item.feeder !== '/'" title="{{item.feeder}}">{{item.feeder}}</span>
                                <span *ngIf="item.feeder === '/'" class="NaN">N/A</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="pagination-wrapper">
     
            <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
            [currentPage]="currentPage" (pageChange)="pageChange($event)">
        </app-fo-pagination-new>
        </div>
    </div>
</main>