import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { Account } from 'src/app/models/account.model';
import { AccountService } from 'src/app/services/account.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { TranslationService } from 'src/app/services/translation.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { DtService } from 'src/app/services/dt.service';
import { SubstationService } from 'src/app/services/substation.service';
import { MapService } from 'src/app/services/map.service';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { CommonService } from 'src/app/services/common-service.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-account-list-new',
  templateUrl: './account-list-new.component.html',
  styleUrls: ['./account-list-new.component.scss']
})
export class AccountListNewComponent implements OnInit {
  public accounts: Account[] = [];
  //paging
  public currentPage: number = 1;
  public pageSize: number = 10;
  public lastPageSize: number = 1;
  public count: number = 0;

  //search and date filter
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public dateFrom: any = "";
  public dateTo: any = "";
  options = ['']
  public searchPlaceholder: any = "Search by name or task ID";

  //list for filters   
  public customerSelected: any = {value: 0, name: '', checked: false} 
  public filterSS: number = 0;
  public ssSelected: any = { value: 0, name: '', checked: false }
  public f11Selected: any = { value: 0, name: '', checked: false }
  public dtsSelected: any = { value: 0, name: '', checked: false }

  public feeder11s: any = [];

  //multi filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  public dtName: string = "Dt"
  public ssName: string = "Substation"
  customerName: string = "Customer"
  public f11Name: string = "Feeder 11"
  public ssIds: number[] = []
  public f11Ids: number[] = []
  public dtIds: number[] = []
  public customerIds: number[] = []

  public dtsMap: any = { name: this.dtName, data: [], dropdown: true };
  public substations: any = { name: this.ssName, data: [], dropdown: true };
  public feeders11New: any = { name: this.f11Name, data: [], dropdown: true };
  public customers: any = { name: this.customerName, data: [], dropdown: true };
  public isMap: boolean = false;
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  constructor(private accountService: AccountService,
    private toastr: ToastrService,
    private feederService: Feeder11Service,
    private dtService: DtService,
    private mapService: MapService,
    public router: Router,
    private ssService: SubstationService,
    private translationService: TranslationService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    // this.customerSelected = {value: 0, name:''} 
    this.getAllDts()
    this.getAllSSs()
    this.setMainDate()
    this.getAllFeeder11()
    this.reloadTable()
  }
  inputChanged(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.pageChange(1)
  }
  selectAcc(id: number) {
    this.router.navigate(['meter-management', 'account-list-popup', id])
  }

  downloadAccounts() {
    let obj = {
      pageInfo: {
        page: 1,
        pageSize: 100000
      },
      filterParams: {
        SearchContent: this.searchText.name,
        DateFrom: transformToString(this.dateFrom),
        DateTo: transformToString(this.dateTo),
        SsId: this.ssSelected.value,
        F11Id: this.f11Selected.value,
        DtId: this.dtsSelected.value,
        AmiId: this.customerSelected.value
      },
      IsForDownload:1
    };
    this.accountService.downloadAccountsForMeterManagement(obj).subscribe(response => {
      window.location.href = response.data; 
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2, 0, 0);
    if (dte.getMonth() == 0) {
      this.dateFrom = { year: dte.getFullYear() - 1, month: 12, day: 1 };
    }
    else {
      this.dateFrom = { year: dte.getFullYear(), month: dte.getMonth(), day: 1 };
    }
    this.dateTo = { year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1 };
  }
  updateDate(date: any) {
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.pageChange(1)
  }

  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    if (searchText.name.length > 2) {
      this.accountService.getDataForAutocomplete(obj)
        .subscribe({ 
          next : (response) => this.options = response.data,
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
    } else
      this.searchComponentRef.resetOptions()
  }
  dropdownSelected(data: any) {
    if (!data)
      return
    if (data.data.name === this.dtName)
      this.dtSelected(data.item)
    if (data.data.name === this.f11Name)
      this.f11Select(data.item)
    if (data.data.name === this.ssName)
      this.ssSelect(data.item)
    if (data.data.name === this.customerName)
      this.customerSelect(data.item)
  }
  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }
  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)
      if (item.name === this.substations.name) {
        this.ssIds = filteredValues
      } else if (item.name === this.feeders11New.name) {
        this.f11Ids = filteredValues
      } else if (item.name === this.dtsMap.name) {
        this.dtIds = filteredValues
      } else if (item.name === this.customers.name) {
        this.customerIds = filteredValues
      }
      this.pageChange(1);

    }
  }
  getAllDts() {
    this.dtService.getAllForSelect().subscribe(response => {
      this.dtsMap = { name: this.dtName, data: response.data, dropdown: true }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  resetFilters(){
    this.ssIds= []
    this.customerIds= []
    this.dtIds= []
    this.f11Ids = []
    this.allFilters = []
    this.showFilter = false
    this.filterApplied = false
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.checkFilters()
  }
  dtSelected(dt: any) {
    this.dtsSelected = dt;
    if (this.dtsSelected.value == 0) {
      this.checkFilters();
    }
    this.pageChange(1)
    this.getCustomer()

  }
  f11Select(data: any) {
    this.f11Selected = data;
    if (this.f11Selected.value == 0) {
      this.checkFilters();
    }
    this.pageChange(1)
  }
  ssSelect(data: any) {
    this.ssSelected = data;
    if (this.ssSelected.value == 0) {
      this.checkFilters();
    }
    this.pageChange(1)
  } 
  customerSelect(data: any) {
    this.customerSelected = data;
    if (this.customerSelected.value == 0) {
      this.checkFilters();
    }
    this.pageChange(1)
  }
  getAllSSs() {
    this.ssService.getAllForSelect().subscribe(response => {
      this.substations = { name: this.ssName, data: response.data, dropdown: true }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getCustomer() { 
    this.mapService.getMapDropdownFilters(this.ssSelected.value, this.f11Selected.value, 0, 0, this.dtsSelected.value).subscribe(response => {
      this.customers = { name: this.customerName, data: response.data.customers, dropdown: true }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  } 
  getAllFeeder11() {
    this.feederService.getAllForSelect().subscribe(response => {
      if (response.status === 200) {
        this.feeders11New = { name: this.f11Name, data: response.data, dropdown: true };
        this.checkFilters()
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An Error Occurred")
    })
  }

  //Check Filter for multi filter component
  checkFilters() {
    let ssIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.substations.name);
    if (ssIndex !== -1)
      this.allFilters[ssIndex].data = this.substations.data;
    else {
      this.allFilters.push(this.substations)
      this.filterApplied = true
    }

    let dtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.dtsMap.name);
    if (dtIndex !== -1) {
      this.allFilters[dtIndex].data = this.dtsMap.data;
    } else {
      this.allFilters.push(this.dtsMap)
      this.allFilters.push(this.feeders11New)
    }
    let f11Index = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders11New.name);
    if (f11Index !== -1) {
      this.allFilters[f11Index].data = this.feeders11New.data;
    } else {
      this.allFilters.push(this.feeders11New)
    }
    let customerIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.customers.name);
    if (customerIndex !== -1) {
      this.allFilters[customerIndex].data = this.customers.data;
    } else {
      this.allFilters.push(this.customers)
    }
    this.allFilters = [...this.allFilters]

  }
  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText.name,
        DateFrom: transformToString(this.dateFrom),
        DateTo: transformToString(this.dateTo),
        AccWithoutMeters: 1
      }
    };  
    this.accountService.getAllAccounts(obj).subscribe({
      next: (response) => {
        this.accounts = response.data.data;
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      },
      error: (error) => {
        this.toastr.error('An error occurred.');
      },
    });
  }


  mapChanged(value: boolean) {
    console.log(value)
    this.isMap = value
    if (this.isMap) {
      this.alert()
      //this.searchPlaceholder = "Search by meter number"

    }
    else if (!this.isMap) {
      // this.meterItemType = 'Prepaid'
      // this.subMenu = 'Active'
    }
    this.resetFilters()
  }

  alert() {
    this.toastr.warning('Not implemented yet!');
  }
}
