<div>
    <app-payment-collections-popup click-stop-propagation></app-payment-collections-popup>
</div>

<div class="view-details-container" *ngIf="detailsShowed">
    <app-view-details-page [isPopUp]="true" (closePopUpChange)="detailsShowed = false"></app-view-details-page>
</div>

<main class="container-fluid">
    <div class="container-wrapper">
        <div class="payment-collections-row">
            <div class="payment-collections-page-header">
                <span>Debt Management</span>
            </div>
            <div class="arrow-wrapper">
                <img alt=">" src="assets\images-new-design\arrow_forward_ios.svg">
            </div>
            <div class="payment-collections-box-header">
                <span>Payment collections</span>
            </div>
        </div>
        <div class="horizontal-line hr"></div>
        <div class="revenue-customers-download">
            <div class="card-wrapper">
            <div class="search-count-wrapper">
                <field-count-box-new [height]="'102px'" [borderRadius]="'8px'" [isYellow]="true" label="Cumulative Debt Amount"
                    [data]="cumulativeDebt | twoDecimals"></field-count-box-new>
            </div>
            <div class="search-count-wrapper">
                <field-count-box-new [height]="'102px'" [borderRadius]="'8px'" [isGreen]="true" [customerWidth]="true" l label="Current Total Collection"
                    [data]="currentTotalCollection | twoDecimals"></field-count-box-new>
            </div>
            <div class="search-count-wrapper">
                <field-count-box-new [height]="'102px'" [borderRadius]="'8px'" [isBlue]="true" label="Customer Count"
                    [data]="customerCount | twoDecimals"></field-count-box-new>
            </div>
        </div>
        </div>

        <div class="filter-wrapper">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                <app-multi-filter *ngIf="filterApplied" [data]="allFilters" (dataChange)="setFilters($event)"
                    [(showFilter)]="showFilter"
                    (dropdownChange)="dropdownSelected($event)">
                </app-multi-filter>

                <app-range-datepicker [fromDate]="dateFrom" [toDate]="dateTo" [name]="'Date'"
                    (dateRangeSelected)="updateDate($event)">
                </app-range-datepicker>

                <app-search-autocomplete [placeholder]="'Search by Meter Number, Account Number & SPN'" [width]="23" [borderColor]="'#6C757D'"
                    [(searchText)]="searchText" (searchChange)="inputChanged($event)" [options]="options"
                    (searchAutocompleteChange)="getOptions($event)"
                    >
                </app-search-autocomplete>
            </div>
            <div class="download-button">
                <app-download-button-new
                    [isOnlyDownload]="false"
                    (downloadButtonClickedChange)="downloadReport()"
                    (downloadAndSendButtonClickedChange)="downloadAndSendReport($event)">
                </app-download-button-new>
            </div>
        </div>
        <table class="standard-table">
            <thead>
                <tr>
                    <th><span><input type="checkbox" class="input-checkmark" name="list_name" value="h1"
                                [(ngModel)]="masterSelected" (change)="checkUncheckAll()" /></span></th>
                    <th><span title="Account Number">ACCOUNT NUMBER</span></th>
                    <th><span title="Account Name">ACCOUNT NAME</span></th>
                    <th><span title="Recept Number">RECEPT NUMBER</span></th>
                    <th><span title="Amount Paid">AMOUNT PAID</span></th>
                    <th><span title="Date of Payment">DATE OF PAYMENT</span></th>
                    <th><span title="Customer type">CUSTOMER TYPE</span></th>
                    <th><span title="{{dtName}}">{{dtName | uppercase}}</span></th>
                    <th><span></span></th>
                </tr>
            </thead>
            <tbody class="table-body">
                <tr *ngFor="let item of paymentCollections" data-toggle="modal"
                    data-target="#exampleModal" (click)="selectPayment(item)" class="bodyRow clickable actions-click">
                    <td (click)="false; $event.stopPropagation();"><span> <input class="input-checkmark" type="checkbox"
                                value="{{item.paymentId}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.paymentId)" /></span></td>
                    <td title="{{item.accountNo}}" class="acc-no-td">
                        <span>{{item.accountNo | hasValue}}</span>
                        <img alt="Copy" class="icons" src="assets/images-new-design/copy.png"
                            (click)="copyToClipboard(item.accountNo)" click-stop-propagation>
                    </td>
                    <td title="{{item.accountName}}"><span>{{item.accountName | hasValue}}</span></td>
                    <td *ngIf="item.receptNo" title="{{item.receptNo}}"><span>{{item.receptNo |
                            hasValue}}</span></td>
                    <td *ngIf="!item.receptNo"><span class="NaN">N\A</span></td>
                    <td *ngIf="item.amountPaid" title="{{item.amountPaid}}"><span>{{item.amountPaid | hasValue}}</span>
                    </td>
                    <td *ngIf="!item.amountPaid"><span class="NaN">N\A</span></td>
                    <td title="{{item.dateOfPayment}}"><span>{{item.dateOfPayment | date : "MMM dd, yyyy" |
                            hasValue}}</span></td>
                    <td title="{{item.customerType}}"><span>{{item.customerType | hasValue}}</span></td>
                    <td title="{{item.dtName}}"><span>{{item.dtName | hasValue}}</span></td>
                    <td>
                        <button class="see-more-btn" id="openPopup">See More</button>
                    </td>
            </tbody>
        </table>
        <div class="col-sm-12 pagination-wrapper" >
            <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
                [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
        </div>
    </div>
</main>
