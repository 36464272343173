import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class StatementOfAccountService {

  private pathApi = this.config.setting['pathApi'] + "StatementOfAccount/";

    constructor(
      private http: HttpClient, 
      private config: AppConfig) { }

    getAccountPaymentHistory(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getAccountPaymentHistory`, obj)
    }

    getAccountDetailsByAccNumber(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getDetailsByAccNo`, obj);
    }

    downloadStatementOfAccount(accNo: string): Observable<any>{
      return this.http.get(`${this.pathApi}downloadStatementOfAccount/${accNo}`)
    }

    downloadAndSendStatementOfAccount(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadAndSendStatementOfAccount`, obj);
    }

    downloadPdf(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadPdf`, obj);
    }

    downloadAndSendPdf(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadAndSendPdf`, obj);
    }
}
