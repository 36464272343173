import { Injectable } from '@angular/core';
import { NotificationAdora } from 'src/app/models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationStorage {

  public notifications: NotificationAdora[] = [];
  public totalPages: number = 1;
  public firstLoad : boolean = true;

}
