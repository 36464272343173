import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class AssetPerformanceService {

  private pathApi = this.config.setting['pathApi'] + "assetPerformance/";

  constructor(private http: HttpClient,
    private config: AppConfig) { }


    getDtAssetPerformance(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDtAssetPerformance`, obj);
    }

    downloadDtAssetPerformance(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadDtAssetPerformance`, obj);
    }

    getPredictiveDtAssetAnalytics(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getPredictiveDtAssetAnalytics`, obj);
    }


    getPredictiveF11AssetAnalytics(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getPredictiveF11AssetAnalytics`, obj);
    }
    getPredictiveF33AssetAnalytics(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getPredictiveF33AssetAnalytics`, obj);
    }
    GetDateOfFlaggedMeter(feederId: number): Observable<any>{
      return this.http.get(`${this.pathApi}GetDateOfFlaggedMeter/${feederId}`);
    }
    GetDateOfFlaggedF33Meter(feederId: number): Observable<any>{
      return this.http.get(`${this.pathApi}getDateOfFlaggedF33Meter/${feederId}`);
    }
    GetDtDateOfFlaggedMeter(dtId: number): Observable<any>{
      return this.http.get(`${this.pathApi}getDateOfDtFlaggedMeter/${dtId}`);
    }
    getPredictiveDtAssetAnalyticsGraphData(obj: any) :  Observable<any>{
      return this.http.post(`${this.pathApi}getPredictiveDtAssetAnalyticsGraphData`, obj)
    }

    getPredictiveF11AssetAnalyticsGraphData(obj: any) :  Observable<any>{
      return this.http.post(`${this.pathApi}getPredictiveF11AssetAnalyticsGraphData`, obj)
    }

    getPredictiveF33AssetAnalyticsGraphData(obj: any) :  Observable<any>{
      return this.http.post(`${this.pathApi}getPredictiveF33AssetAnalyticsGraphData`, obj)
    }

    downloadF11PredictiveAnalytics(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadF11PredictiveAnalytics`, obj);
    }

    downloadDtPredictiveAnalytics(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadDtPredictiveAnalytics`, obj);
    }

    getF11AssetPerformance(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getF11AssetPerformance`, obj);
    }

    downloadF11AssetPerformance(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadF11AssetPerformance`, obj);
    }

    getF33AssetPerformance(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getF33AssetPerformance`, obj);
    }

    downloadF33AssetPerformance(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadF33AssetPerformance`, obj);
    }

    getPredictiveDtHistoryAssetAnalytics(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getPredictiveDtHistoryAssetAnalytics`, obj);
    }

    getPredictiveF11HistoryAssetAnalytics(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getPredictiveF11HistoryAssetAnalytics`, obj);
    }

    getPredictiveF33HistoryAssetAnalytics(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getPredictiveF33HistoryAssetAnalytics`, obj);
    }

    downloadDtHistoryPredictiveAnalytics(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadDtHistoryPredictiveAnalytics`, obj);
    }

    downloadF11HistoryPredictiveAnalytics(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadF11HistoryPredictiveAnalytics`, obj);
    }

}
