import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardClass {
  constructor(private authService: AuthService,
    private router: Router, private toastr: ToastrService) {  }

    canActivate(route: ActivatedRouteSnapshot) {
      if (!this.authService.isLoggedIn()) 
      {
        this.router.navigate(['']);
        return false;
      }

      if(this.authService.isPasswordTemp()){
        this.router.navigate(['password-new-user']);
        return false;
      }

      let result = false;
      let currentUserPermissions = route.data['permissions'];
      let currentUtilityModules = route.data['module']?.trim().toLowerCase()

      let availableModules = this.authService.getCurrentUser().availableModules?.trim().toLowerCase();

      if(availableModules && currentUtilityModules){
        let isModuleAvailable = availableModules.includes(currentUtilityModules)

        if(!isModuleAvailable){
          this.toastr.error('Module is inactive.');
          this.router.navigate(['']);
        }
        if(currentUserPermissions){    
          currentUserPermissions.forEach((x: any) => {
            if(this.authService.isAuthorized(x))
              result = true;
          });
  
          if(!result)
          {
            this.toastr.error("You don't have permission.");
            this.router.navigate(['']);
          }
          return result;
        }
      }
      return true;
    }

}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot): boolean => {
  return inject(AuthGuardClass).canActivate(next);
}
