import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WomteamService } from 'src/app/services/womteam.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DeletePopupComponent } from './delete-popup/delete-popup.component';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss']
})

export class TeamDetailsComponent implements OnInit {

  public isPopupOpen: boolean = false;
  showShadow = false;
  @Output() confirmUpload: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmGetTemplate: EventEmitter<void> = new EventEmitter<void>();
  @Output() parentComp = new EventEmitter();
  @Output() createTeamPopupChange = new EventEmitter();
  @ViewChild(DeletePopupComponent) deletePopupComponentRef!: DeletePopupComponent;

  id: number = 0;
  searchText: string = ''
  @Input() update: boolean = false;
  @Input() file: any = null;
  fileChosen: File | null = null;
  public selectedWomTeam: any = {};
  public agents: any[] = [];
  public newAgents: any[] = [];

  date: string = '';

  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')

  constructor(private elementRef: ElementRef,
    private toastr: ToastrService,
    private teamService: WomteamService,
    private womTeamService: WomteamService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private translationService: TranslationService) {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    })
  }

  ngOnInit(): void {
    this.selectWomTeam(this.id)
  }

  back() {
    this.location.back()
  }

  searchChange(text: string) {
    this.searchText = text

    this.newAgents = this.agents.filter(x => x.toLowerCase().includes(this.searchText.toLowerCase()))

  }

  public create(id: any) {
    if (id === 0)
      this.selectedWomTeam.agents = this.selectedWomTeam.agents.filter((name: string) => name == this.selectedWomTeam.teamLeadName);
    else
      this.selectedWomTeam.agents = this.selectedWomTeam.agents.filter((user: string) => user !== id);

    let obj = {
      Id: this.selectedWomTeam.id,
      Agents: this.selectedWomTeam.agents
    }
    this.womTeamService.deleteAgent(obj).subscribe(response => {
      if (response.status == 200) {
        this.toastr.success('Successfully deleted agent from WOM team')
        this.selectWomTeam(this.id)
      }
      else {
        this.toastr.error(response.message)
        this.selectWomTeam(this.id)
      }
    }, _ => {
      this.toastr.error('An error occurred')
    })
  }

  selectWomTeam(id: number) {
    this.womTeamService.get(id).subscribe(response => {
      this.selectedWomTeam = response.data;
      this.agents = this.selectedWomTeam.agents
      this.newAgents = this.agents
      this.dateFormat();
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }

  dateFormat() {
    const date = new Date(this.selectedWomTeam.dateCreated);
    const all = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"];
    const month = all[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    this.date = `${month} ${day}, ${year}`;
  }

  onFileChosen(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.fileChosen = inputElement.files[0];
    }
  }

  getTemplate() {
    this.confirmGetTemplate.emit();
    this.isPopupOpen = false;
    this.showShadow = false;
  }

  upload() {
    this.confirmUpload.emit();
    this.isPopupOpen = false;
    this.showShadow = false;
    this.update = false;
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.selectedWomTeam.id)
    this.toastr.success('Copied to clipboard')
  }

  openEditTeam() {
    this.createTeamPopupChange.emit(this.selectedWomTeam)
  }

  sendObjectToAnotherComponent() {
    this.router.navigate(['/field-operations/team-create'], {
      queryParams: { data: JSON.stringify(this.selectedWomTeam.id) }
    });
  }
  openDeletePopup() {
    this.deletePopupComponentRef.open()
  }
  deleteTeam() {
    this.teamService.delete(this.selectedWomTeam.id).subscribe(response => {
      if (response.status === 200) {
        this.parentComp.emit();
        this.toastr.success('Successfully deleted.');
        this.location.back()
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
