import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ModalData } from 'src/app/models/revenue-modal-data';
import { MeterBypassService } from 'src/app/services/meter-bypass.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-meter-bypass-popup',
  templateUrl: './meter-bypass-popup.component.html',
  styleUrls: ['./meter-bypass-popup.component.scss']
})
export class MeterBypassPopupComponent {
  selectedMeterBypass = new ModalData;

  @Input() isPopupOpen: boolean = false;

  @Output() parentComp = new EventEmitter();

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');

  constructor(
    private translationService: TranslationService,
    public meterBypassService: MeterBypassService,
    public toastr: ToastrService
    ) { }

  changeState(number: Number, data?: ModalData){
    if (data)
      this.selectedMeterBypass = data;
     if (number === 1){
      this.isPopupOpen = true;
    }
    if (number === 2)
    {
      this.isPopupOpen = false;
      this.selectedMeterBypass=new ModalData;
    }
  }

  saveComment(){
    this.meterBypassService.updateBypass(this.selectedMeterBypass).subscribe(response =>{
      if(response.status == 200){
        this.parentComp.emit();
        this.toastr.success("Success")
        this.isPopupOpen = false;
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error('An Error Occurred')
    })
  }

  cancel(){
    this.isPopupOpen = false;
  }

}
