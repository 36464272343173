import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountStatus } from 'src/app/models/accountStatus.model';
import { AccountStatusService } from 'src/app/services/accountStatus.service';

@Component({
  selector: 'app-account-create-status-tab',
  templateUrl: './account-create-status-tab.component.html',
  styleUrls: ['./account-create-status-tab.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class AccountCreateStatusTabComponent {
  showShadow = false;
  @Input() animationState = 'out';

  @ViewChild("f") f: any;
  @Output() parentComp = new EventEmitter();

  public selectedAccountStatus: AccountStatus = new AccountStatus()

  constructor(
    private accountStatusService:AccountStatusService,
    private toastr: ToastrService,
    public ref: ElementRef) { }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.transmission-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }
  save() {
    this.accountStatusService.saveAccountStatus(this.selectedAccountStatus).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.changeState(2);

        this.parentComp.emit()
        this.f = null;
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  changeState(number: Number, data?: any) {
    if (data)
      this.selectedAccountStatus = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
  }
  resetSelectedAcc(){
    this.selectedAccountStatus = new AccountStatus();

    if(this.f)
      this.f.submitted = false;
  }
  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }
}
