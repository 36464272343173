import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { GraphItem } from 'src/app/models/graph-item.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { CommonService } from 'src/app/services/common-service.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { MenuItem, SubMenuItem } from '../../consumption/model/model';

@Component({
  selector: 'app-aeg-ut-new',
  templateUrl: './aeg-ut-new.component.html',
  styleUrls: ['./aeg-ut-new.component.scss']
})
export class AegUtNewComponent {

  public SubMenuItemEnum = SubMenuItem;
  public Submenu: SubMenuItem = SubMenuItem.Ut;
  public menuItemEnum = MenuItem;
  @Input() menu!: MenuItem;
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()
  @Output() redirect: EventEmitter<any> = new EventEmitter()

  //data
  public undertakings: [] = []

  avgReceived: number = 0;
  avgLosses: number = 0;
  avgEnergyConsumption: number = 0;

  public tableData: any[] = [];

  //multiFilter
  @Input() utIds: number[] = []
  @Input() dateFrom: any = '';
  @Input() dateTo: any = '';

  @Input() showTable = false;

  // Graph
  private graphData: GraphItem[] = []
  public receivedTableData: any = []
  public lossesTableData: any = []

  public LostLabels: string[] = [];
  public receivedLabels: string[] = [];

  public receivedAndLossesData: any[] = [];

  public LostData: ChartDataset[] = [
    { data: [], label: 'Value',
      backgroundColor: '#C03438',
      borderRadius: 45,
      barThickness: 12,
     },
  ];

  public receivedData: ChartDataset[] = [
    { data: [], label: 'Received',
      backgroundColor: '#29C87B',
      borderRadius: 45,
      barThickness: 12,
    },
    { data: [], label: 'Losses',
      backgroundColor: '#C03438',
      borderRadius: 45,
      barThickness: 12,
     }
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        grid: {
          display: false
        },
        border:{
          display: false
        },
        ticks: {
          font: {
            size: 14
          },

        },
      },
      y: {
        grid: {
          color: '#E8E8E8',
        },
        border:{
          display: false
        },
        ticks: {
          padding: 30,
        }
      },
    },
    plugins:{
      tooltip:{
        callbacks: {
          label: function(context) {
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(context.parsed.y);
              }
              return label;
          }
      }
    }
  }
  }
  public barChartLegend = false;
  public barChartPlugins = [];


  constructor(private utService: UndertakingService,
              private toastr: ToastrService,
              private commonService: CommonService) { }


  ngOnChanges(changes: SimpleChanges): void {
    let reloadTable = false
    if(changes.utIds != null){
      reloadTable = true
    }
    if(changes.dateFrom != null || changes.dateTo != null){
      reloadTable = true
    }
    if(changes.searchText != null){
      reloadTable = true
    }

    if(reloadTable){

      this.GetData()
    }
  }

  public GetData(){
    let obj = {
      Start: transformToString(this.dateFrom),
      End: transformToString(this.dateTo),
      UtId: this.utIds,
      GetAll: false,
      Search: this.searchText.name
    }
    this.getUtEnergyReceivedAndLosses(obj)
    // this.getUtLosses(obj)
  }

  private getUtEnergyReceivedAndLosses(obj: any){
    this.utService.getUtReceivedData(obj).subscribe((response: any) => {
      this.undertakings = response.data
      this.setValuesForUtLosses(response)
      this.setValuesForUtReceived(response)
      this.setValuesForTable(response.data)
    }, _ =>{
      this.toastr.error('An error ocured');
    })
  }

  private setValuesForTable(data: any){
    this.avgReceived = data.avgReceived;
    this.avgEnergyConsumption = data.avgEnergyConsumption;
    this.avgLosses = data.avgLosses;

    this.tableData = [];
    this.graphData = data.graphItems;
    this.graphData.forEach((element: any) => {
      this.tableData.push(element);
    })
  }

  // private getUtReceived(obj:any){
  //   this.utService.getUtReceivedData(obj).subscribe((response: GraphItem[]) => {
  //     this.setValuesForUtReceived(response)
  //   }, _ =>{
  //     this.toastr.error('An error ocured');
  //   })
  // }

  private setValuesForUtReceived(response: any){
    this.receivedData[0].data = []
    this.receivedLabels = []
    let labels = ['']
    labels = []
    this.receivedTableData = []
    if(response.data.graphItems != null && response.data.graphItems.length > 0){
      this.graphData = response.data.graphItems
      this.graphData.forEach(element =>{
      this.receivedData[0].data?.push(element.received)
      labels.push(this.sliceLabel(element.name))
      this.receivedTableData.push(element)
      })
      this.receivedAndLossesData[0] = this.receivedTableData;
    }
    else
      this.receivedAndLossesData[0] = [];
    this.receivedLabels = labels
  }

  private setValuesForUtLosses(response: any){
    this.receivedData[1].data = []
    this.lossesTableData = [];
    if(response.data.graphItems != null && response.data.graphItems.length > 0){
      this.graphData = response.data.graphItems
      this.graphData.forEach(element =>{
        this.receivedData[1].data?.push(element.lost)
        this.lossesTableData.push(element)
      })

      this.receivedAndLossesData[1] = this.lossesTableData;
    }
    else
      this.receivedAndLossesData[1] = [];
  }

  private sliceLabel(label: string){
    if(label.length > 30)
      return label.slice(0, 30) + '...'
    return label
  }


  public ChangeSubmenu(submenu:any){
    this.redirect.emit(submenu)
  }

  public download(){
    let obj = {
        Start: transformToString(this.dateFrom),
        End: transformToString(this.dateTo),
        UtId: this.utIds,
        GetAll : false,
        Search: this.searchText.name
    };
    this.downloadReport(obj);
  }

  downloadReport(obj: any){
    if(this.undertakings.length == 0){
      this.toastr.warning("Please select Area for download report.");
      return;
    }
    else{
      this.utService.downloadAssetEnergy(obj).subscribe(response =>{
        if(response.status == 200){
          // window.location.href = this.commonService.combinePaths(this.commonService.pathApi.replace('/api', ''), response.data);
          window.location.href = response.data;
        }
        else{
          this.toastr.error(response.message)
        }
      }, _ =>{
        this.toastr.error('An error occurred.');
      })
    }
  }
}
