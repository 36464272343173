<main class="container-fluid">
    <div class="container-wrapper">

        <div class="network-management-title">
            <span>Dashboard</span>
          </div>

        <div class="horizontal-l"></div>

        <div class="card-items-wrapper">
          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Total Energy Received into the Grid" [width]="width" [height]="height" [data]="(totalEnergyConsumption | twoDecimals)+'MWh'" [isGreen]="true" [lastMonth]="'Last 30 days'" [viewDetails]="false" [filter]="true" (filterChanged)="filterClicked($event)"></field-count-box-new>
            </div>
          </div>
          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Total Energy Consumption at DTs" [width]="width" [height]="height" [data]="(cardData.totalEnergyConsumptionDt | twoDecimals)+'K'" [isYellow]="true" [lastMonth]="'Last 30 days'" [viewDetails]="false"></field-count-box-new>
            </div>
          </div>
          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Total Grid to DT losses" [width]="width" [height]="height" [data]="(cardData.totalGridToDtLosses | twoDecimals)+'K'" [lastMonth]="'Last 30 days'" [isRed]="true" [viewDetails]="false"></field-count-box-new>
            </div>
          </div>
          <div class="card-item">
            <div class="box-item">
              <field-count-box-new label="Total Active Outage in The Network" [width]="width" [height]="height" [data]="(cardData.totalActiveOutage | number)" [lastMonth]="(cardData.customersImpacted | number) + ' customers impacted'" [isBlue]="true" [viewDetails]="false" [outage]="true"></field-count-box-new>
            </div>
          </div>

          </div>
          <div class="grid-network-wrapper">

            <div class="title">
              Grid Network
            </div>

            <div class="bottom-wrapper">
              <div class="map-wrapper">
                <adora-map-clusterized 
                           [searchValue]="searchText"
                           [nmDashboard]="true"
                           [organizationTab]="organizationData">
                </adora-map-clusterized>
              </div>

              <div class="filters-wrapper">
                <div style="width: 100%;">
                  <app-tab-nm-dashboard [firstText]="'Network'"
                                        [secondText]="'Organization'"
                                        (tabChanged)="tabClicked()">
                  </app-tab-nm-dashboard>
                </div>
                <div style="width: 100%;">
                <app-search [setInterval]="true" [style.width]="searchWidth" searchContainerWidth="'100%'"
                            #search (searchChange)="searchChange($event)" [searchText]="searchText"
                            [borderColor]="'#8F92A1'" [marginLeft]="'0'" [placeholder]="'Search by DT name or number'"></app-search>
                </div>
                <div *ngIf="newtworkTabSelected" style="width: 100%;">
                  <div class="asset-wrapper-ts">
                    <div class="left-container">
                      <div class="circle" [style.background-color]="'#FF991F'"></div>
                      <span class="text">Transmission Station</span>
                    </div>
                    <div>
                      {{tsCount | number}}
                    </div>
                  </div>

                  <div class="asset-wrapper-ss">
                    <div class="left-container">
                      <div class="circle" [style.background-color]="'#171717'"></div>
                      <span class="text">Sub-Station</span>
                    </div>
                    <div>
                      {{ssCount | number}}
                    </div>
                  </div>

                  <div class="asset-wrapper-dt">
                    <div class="left-container">
                      <div class="circle" [style.background-color]="'#FF0A0A'"></div>
                      <span class="text">DT</span>
                    </div>
                    <div>
                      {{dtCount | number}}
                    </div>
                  </div>
                </div>

                <div *ngIf="!newtworkTabSelected" style="width: 100%;">
                  <div class="asset-wrapper-ss">
                    <div class="left-container">
                      <div class="circle" [style.background-color]="'#171717'"></div>
                      <span class="text">{{buName}}</span>
                    </div>
                    <div>
                      {{buCount | number}}
                    </div>
                  </div>

                  <div class="asset-wrapper-ts">
                    <div class="left-container">
                      <div class="circle" [style.background-color]="'#FF991F'"></div>
                      <span class="text">{{utName}}</span>
                    </div>
                    <div>
                      {{utCount | number}}
                    </div>
                  </div>

                  <div class="asset-wrapper-dt">
                    <div class="left-container">
                      <div class="circle" [style.background-color]="'#FF0A0A'"></div>
                      <span class="text">DT</span>
                    </div>
                    <div>
                      {{dtCount | number}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <app-grid-losses-analysis></app-grid-losses-analysis>
          <app-outage-analysis></app-outage-analysis>
        </div>
</main>
