import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'dropdown-meter-details',
  templateUrl: './dropdown-meter-details.component.html',
  styleUrls: ['./dropdown-meter-details.component.scss']
})
export class DropdownMeterDetailsComponent implements OnChanges {

  @Input() isSelected: boolean = false;
  @Input() header: string = 'Change'
  @Input() data: string[] = []
  @Input() width: string = '31'
  selectedItemId:any;

  @Output() selectItemChange: EventEmitter<any> = new EventEmitter()

  public showData: string[] = []
  constructor(private eRef: ElementRef) {
  }


  ngOnChanges(changes: SimpleChanges){
    if(changes.data)
    {
      this.showData = changes.data.currentValue
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any){
    const clickedInside = this.eRef.nativeElement.contains(target);
  }

  close(){
    this.isSelected = false
  }

  select(item: any){

    this.isSelected = false
    this.selectedItemId = item;
    this.selectItemChange.emit(item)
  }
}
