import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { Feeder33 } from '../models/feeder33.model';

@Injectable({
  providedIn: 'root'
})
export class Feeder33Service {

  private pathApi = this.config.setting['pathApi'] + "feeder33/";
  private pathApiLargeFeeders = this.config.setting['pathApi'] + "AngularChartData/GetLargeFeeders";

  constructor(private http: HttpClient,
    private config: AppConfig,) {  }

    getFeederFullInfo(id: number): Observable<any[]> {
      return forkJoin([this.get(id), this.getFeederMeterHistory(id)]);
    }

    get(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    }

    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    }

    save(obj: Feeder33): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }

    getAll(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, obj);
    }

    getAllForSelect(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSelect`);
    }

    getAllForSelectByTsId(tsId: number): Observable<any> {
      return this.http.get(`${this.pathApi}getAllForSelectByTsId/${tsId}`);
    }

    uploadFile(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}uploadFile`, obj)
    }

    getFeederMeterHistory(id: number): Observable<any>{
      return this.http.get(`${this.pathApi}getFeederMeterHistory/${id}`)
    }

    getFeedersReceivedData(start: string, end: string): Observable<any[]> {
      if(start ==  "" || end == ""){
        return this.http.get<any[]>(`${this.pathApiLargeFeeders}/Received`)
      }
      else{
        return this.http.get<any[]>(`${this.pathApiLargeFeeders}/Received?start=${start}&end=${end}`)
      }
    }

    getFeedersLostData(start: string, end: string): Observable<any[]> {
      if(start ==  "" || end == ""){
        return this.http.get<any[]>(`${this.pathApiLargeFeeders}/Lost`)
      }
      else{
        return this.http.get<any[]>(`${this.pathApiLargeFeeders}/Lost?start=${start}&end=${end}`)
      }
    }

    getAllFeeder33BySsId(ssId: number): Observable<any>{
      return this.http.get(`${this.pathApi}getAllForSelectBySSId/${ssId}`);
    }

    getFeedersWorstEnergyLosses(obj: any): Observable<any[]>{
      return this.http.post<any[]>(`${this.pathApi}GetF33Worst10EnergyLosses`, obj)
    }
    downloadWorstRecordedAssets(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadWorstAssets`, obj);
    }

    getAllMonthly33KvFeeders(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}GetAllMonthly33KvFeeders`,obj);
    }

    getAllDaily33KvFeeders(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}GetAllDaily33KvFeeders`,obj);
    }

    downloadMonthly33KvFeedersReport(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}DownloadMonthly33KvFeedersReport`,obj);
    }

    downloadDaily33KvFeedersReport(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}DownloadDaily33KvFeedersReport`,obj);
    }

    getTemplate(): Observable<any>{
      return this.http.get(`${this.pathApi}getTemplate`);
    }

    getFeedersOutages(obj: any): Observable<any>{
      if (obj.filterParams.From == 'MM/DD/YYYY' && obj.filterParams.To == 'MM/DD/YYYY')
      {
        obj.filterParams.From = null
        obj.filterParams.To = null
      }
      return this.http.post(`${this.pathApi}GetFeeder33Outages`, obj)
    }
    getF33Reads(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}GetF33Reads`, obj);
    }
    downloadCurrentOutages(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadFeeder33CurrentOutagesReport`,obj)
    }

    downloadHistoryOfOutages(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadFeeder33HistoryOfOutagesReport`,obj)
    }

    getAllForSelectByParams(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getAllForSelectByParams`, obj);
    }

    getEnergyDistributionAndLosses(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getEnergyDistributionAndLosses`, obj);
    }

    getOutageDurationAndFrequency(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getOutageDurationAndFrequency`, obj);
    }

    getMonthlyAvailability(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getAvailability`, obj);
    }

    getFeederViewMore(obj: object): Observable<any> {
      return this.http.post(`${this.pathApi}GetFeeder33ViewMore`,obj);
    }
    getAllFeeders33DictionaryItem(): Observable<any> {
      return this.http.get(`${this.pathApi}getAllFeeders33DictionaryItem`);
    }
    getFeeder33ReceivedData(obj: any): Observable<any[]> {
      return this.http.post<any[]>(`${this.pathApi}Received`, obj)
    }
    downloadAssetEnergy(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadF33AssetEnergy`, obj);
    }
    getDataForAutocomplete(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForAutocomplete`, obj)
    }
    getDataForAutocompleteNameExclusive(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getDataForAutocompleteNameExclusive`, obj)
    }
    downloadCrud(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadCrud`, obj);
    }
    getSystemHierarchy(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getSystemHierarchy`, obj)
    }
    getCurrentActiveF33(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getCurrentActiveF33`, obj)
    }
    DownloadCurrentActiveReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}DownloadCurrentActiveReport`,obj)
    }
    getNonCommunicatingF33(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}getNonCommunicatingF33`, obj)
    }
    downloadNonCommunicatingReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadNonCommunicatingReport`,obj)
    }
    getDailyBandsCount(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getDailyBandsCount`, obj);
    }
    getMonthlyBandsCount(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}getMonthlyBandsCount`, obj);
    }
    getCommunicatingAssetsCounts(obj: any):Observable<any>{
      return this.http.post(`${this.pathApi}GetCommunicatingAssetsCounts`, obj);
    }

}
