<main class="container-fluid">
    <div class="container-wrapper">
        <span class="network-management-title">Reports</span>
        
        <div class="menu-tab-filter">
            <app-tab-default
            [firstText]="'Customer Bills'"
            [secondText]="'Customer Vendings'"
            [thirdText]="'Customer Payments'"
            (tabChanged)="tabChanged($event)"
            ></app-tab-default>
        </div>

        <div class="filters-container">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
    
                <app-multi-filter *ngIf="filterApplied"
                    [data]="allFilters"
                    (dataChange)="applyFilter($event)"
                    [(showFilter)]="showFilter"        
                >
                </app-multi-filter>

                <app-range-datepicker
                    [fromDate]="dateFrom" 
                    [toDate]="dateTo"
                    [isMonthly]="tabMenu==1"
                    [name]="'Period'"
                    (dateRangeSelected)="dateChange($event)">
                </app-range-datepicker>

                <app-search-autocomplete
                    #search
                    [placeholder]="searchPlaceholder" 
                    [searchText]="searchText"
                    [options]="options"       
                    (searchChange)="inputChanged($event)"
                    (searchAutocompleteChange)="getOptions($event)"
                >     
                </app-search-autocomplete>
            </div>

            <div class="download-button">
                <app-download-button-new
                    [isOnlyDownload]="true"
                    (downloadButtonClickedChange)="download()">
                </app-download-button-new>   
            </div>
        </div>

        <div *ngIf="tabMenu==1">
            <app-bills-new
                #child
                [buNames]="buNames"
                [utNames]="utNames"
                [searchText]="searchText"
                [buName]="buName"
                [utName]="utName"
                [dateFrom]="dateFrom"
                [dateTo]="dateTo"
            ></app-bills-new>

        </div>
        <div *ngIf="tabMenu==2">
            <app-vendings-new
                #child
                [buNames]="buNames"
                [utNames]="utNames"
                [searchText]="searchText"
                [buName]="buName"
                [utName]="utName"
                [dateFrom]="dateFrom"
                [dateTo]="dateTo"
            ></app-vendings-new>

        </div>
        <div *ngIf="tabMenu==3">
            <app-payment-reports-new
                #child
                [buNames]="buNames"
                [utNames]="utNames"
                [searchText]="searchText"
                [buName]="buName"
                [utName]="utName"
                [dateFrom]="dateFrom"
                [dateTo]="dateTo"
            ></app-payment-reports-new>

        </div>
    </div>
</main>
