import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasValue'
})
export class HasValuePipe implements PipeTransform {
  transform(value: any, args?: any) {   
    if(value === 0){
      return value;      
    }
    if (!value)
      return '/';

    if (value === '')
      return '/'; 

    return value;
  }
}
