<main class="container-fluid">
    <div class="container-wrapper">
        <span class="field-operation-title">Predictive Analytics</span>
        <div class="menu-tab-filter">

            <app-tab-default
                [firstText]="firstTabPrimary" [secondText]="secondTabPrimary" [thirdText]="thirdTabPrimary"
                (tabChanged)="primaryTabChange($event)">
            </app-tab-default>
        </div>

        <div class="filter-wrapper center">
            <app-tab-default
                [firstText]="'Current Predictive'"
                [secondText]="'Historical Predictive'"
                [paCss]="true"
                [womCss]="true"
                (tabChanged)="secondaryTabChange($event)">
            </app-tab-default>
        </div>

        <div class="filters-container">
            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

                <app-multi-filter  *ngIf="filterApplied"
                    [data]="allFilters"
                    (dataChange)="applyFilter($event)"
                    [(showFilter)]="showFilter">
                </app-multi-filter>

                <app-range-datepicker
                    [fromDate]="dateFrom"
                    [toDate]="dateTo"
                    [name]="'Period'"
                    (dateRangeSelected)="dateChange($event)">
                </app-range-datepicker>

            <app-search-autocomplete
                #search
                [placeholder]="'Search by ' + activeTabPrimaryString + ' name or number'"
                [width]="25"
                [backgroundColor]="'transparent'"
                [borderColor]="'#E8E8E8'"
                [(searchText)]="searchText"
                [options]="options"
                (searchChange)="searchChange($event)"
                (searchAutocompleteChange)="getOptions($event)">
            </app-search-autocomplete>
        </div>
     </div>
        <!-- CURRENT -->
        <div class="standard-table" *ngIf="activeTabSecondary === ActiveTabSecondaryEnum.Current">
            <app-pa-dt-new
                *ngIf="activeTabPrimary === ActiveTabPrimaryEnum.DT"
                [data]="predictiveDtData"
                [menuTab] ="activeTabSecondary"
                [flags]="flagValues">
            </app-pa-dt-new>

            <app-pa-f11-new
                *ngIf="activeTabPrimary === ActiveTabPrimaryEnum.Feeder11"
                [data]="predictiveF11Data"
                [menuTab] ="activeTabSecondary"
                [flags]="f11FlagValues">
            </app-pa-f11-new>

            <app-pa-f33-new *ngIf="activeTabPrimary === ActiveTabPrimaryEnum.Feeder33"
                [data]="predictiveF33Data"
                [menuTab] ="activeTabSecondary"
                [flags]="f33FlagValues"
            >
            </app-pa-f33-new>
        </div>

        <!-- HISTORICAL -->
        <div class="standard-table" *ngIf="activeTabSecondary === ActiveTabSecondaryEnum.Historical">
            <app-pa-dt-new
                *ngIf="activeTabPrimary === ActiveTabPrimaryEnum.DT"
                [historyData]="predictiveDtHistoryData"
                [menuTab] ="activeTabSecondary"
                [flags]="flagValues">
            </app-pa-dt-new>

            <app-pa-f11-new
                *ngIf="activeTabPrimary === ActiveTabPrimaryEnum.Feeder11"
                [historyData]="predictiveF11HistoryData"
                [menuTab] ="activeTabSecondary"
                [flags]="f11FlagValues">
            </app-pa-f11-new>

            <app-pa-f33-new
                *ngIf="activeTabPrimary === ActiveTabPrimaryEnum.Feeder33"
                [historyData]="predictiveF33HistoryData"
                [menuTab] ="activeTabSecondary"
                [flags]="f33FlagValues"
                >
            </app-pa-f33-new>
        </div>

        <div class="pagination-wrapper">
            <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
                [currentPage]="currentPage" (pageChange)="pageChange($event)">
            </app-fo-pagination-new>
        </div>

    </div>
</main>
