import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-upload-planned-outage-popup',
  templateUrl: './upload-planned-outage-popup.component.html',
  styleUrl: './upload-planned-outage-popup.component.scss'
})
export class UploadPlannedOutagePopupComponent {
  public isPopupOpen: boolean = false;
  showShadow = false;
  @Output() confirmUpload: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmGetTemplate: EventEmitter<void> = new EventEmitter<void>();

  @Input() update: boolean = false;
  @Input() file: any = null;
  @Input() placeholder: any = 'Import multiple planned outage data (Supported format XLSX).';
  fileChosen: File | null = null;
  fileName:string="";

  constructor(private elementRef: ElementRef) { }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.isPopupOpen) {
      if (this.elementRef.nativeElement.contains(event.target) || event.target === this.elementRef.nativeElement) {
        if (!isSliderClicked) {
          this.closePopup();
        }
      }
    }
  }
  onFileChosen(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.fileChosen = inputElement.files[0];
      this.fileName=this.fileChosen.name;
    }
  }

  getTemplate() {
    this.confirmGetTemplate.emit();
    this.isPopupOpen = false;
    this.showShadow = false;
    this.fileName="";
  }
  upload() {
    this.confirmUpload.emit();
    this.isPopupOpen = false;
    this.showShadow = false;
    this.update=false;
    this.fileName="";
  }
  closePopup() {
    this.isPopupOpen = false;
    this.showShadow = false;
    this.fileName="";
  }
  changeState(id?: number) {
    this.isPopupOpen = true;
    this.showShadow = true;

  }
}
