import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { Feeder11 } from 'src/app/models/feeder11.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommonService } from 'src/app/services/common-service.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { MeterService } from 'src/app/services/meter.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Feeder11CreateComponent } from './feeder11-create/feeder11-create.component';
import { CrudDeleteModalComponent } from '../../crud-delete-modal/crud-delete-modal.component';
import { CrudService } from 'src/app/services/crudService.service';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';

@Component({
  selector: 'app-feeder11-tab',
  templateUrl: './feeder11-tab.component.html',
  styleUrls: ['./feeder11-tab.component.scss']
})
export class Feeder11TabComponent implements OnInit {
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  public PermissionsEnum = PermissionsEnum;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public searchByName: string = '';
  public searchBySS: number = 0;
  public searchByAmi: number = 0;
  public searchByMeterNumber: string = '';
  public searchF11: SearchAutocomplete[] = [];

  private ngUnsubscribe = new Subject<void>();
  private searchText: string = "";
  public msnValid: boolean = false;
  public powerMsnValid: boolean = true;
  public regionMsnValid: boolean = true;

  public feeders11: Feeder11[] = [];
  public selectedFeeder11: Feeder11 = new Feeder11();
  public substations: any = [];
  public feeders33: any = [];
  public amis: any = [];
  public buSelected: number = 0;
  public meterNumber: string = '';
  public amiSelected: number = 0;
  public buNotSharedId: number = 0
  public isEnergyShared: boolean = false;

  public buForSelect: DictionaryItem[] = []
  public selectedId: number = 0;

  public isNameplateRatingValid: boolean = true;

  public fileChosen: any = null;
  public file: any = null;

  public amiName :string = this.translationService.getByKeyFromCache('AMI');
  public buName: string = this.translationService.getByKeyFromCache('BU');

  public masterSelected: boolean = false;
  public checkedList: any = [];

  @ViewChild(Feeder11CreateComponent) popupComponentRef!: Feeder11CreateComponent;
  @ViewChild("f") f: any;

  @Output() editTab = new EventEmitter();
  @Output() subSelect = new EventEmitter();
  @Output() amisSelect = new EventEmitter();
  @Output() busSelect = new EventEmitter();
  @Output() f33Select = new EventEmitter();
  @Output() deleteComp = new EventEmitter();

  constructor(private feeder11Service: Feeder11Service,
    private feeder33Service: Feeder33Service,
    private toastr: ToastrService,
    private commonService: CommonService,
    private meterService: MeterService,
    public authService: AuthService,
    private ssService: SubstationService,
    private crudService: CrudService,
    private amiService: AmiService,
    private translationService: TranslationService,
    private buService: BusinessUnitService,
    private location: Location
    ) { }

  ngOnInit(): void {
    this.reloadTable();
    this.getAllForSelect();
    this.subscribeToSearch();
  }

  openTab() {
    this.popupComponentRef.changeState(1, this.selectedFeeder11);
   }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  getAllForSelect(){
    this.ssService.getAllForSelect().subscribe(response => {
      this.substations = response.data;
      this.subSelect.emit(this.substations);
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.amiService.getAllForSelect().subscribe(response => {
      this.amis = response.data;
      this.amisSelect.emit(this.amis);
    }, error => {
      this.toastr.error('An error occurred.');
    });

  this.buService.getAllForSelect().subscribe(response => {
    this.buForSelect = response.data
    this.busSelect.emit(this.buForSelect);
  })
}

  reloadTable(page: any = null) {
    this.msnValid = false;
    this.powerMsnValid = true;
    this.buSelected = 0
    this.amiSelected = 0;
    this.meterNumber = '';
    if (page)
      this.currentPage = page;

    this.resetSelectedFeeder11();
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };

    if (!obj.filterParams.SearchContent){
      this.feeder11Service.getAllForSelect().subscribe(response => {

        if (!obj.filterParams.SearchContent){
          this.searchF11 = []
          response.data.forEach((x : Feeder11) =>
          {
            let item = new SearchAutocomplete();
            item.value = x.id;
            item.name = x.name;
            this.searchF11.push(item)
          })
        }
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
    this.feeder11Service.getAll(obj).subscribe(response => {
      this.feeders11 = response.data.data;
      if(obj.pageInfo.page == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      this.isAlreadyChecked();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  buChanged() {
    this.amiSelected = 0;
    this.meterNumber = "";
  }

  selectFeeder11(id: number) {
    this.feeder11Service.get(id).subscribe(response => {
      if (response.status === 200) {

        this.selectedFeeder11 = response.data;
        this.feeder33Service.getAllFeeder33BySsId(this.selectedFeeder11.ssId).subscribe(response => {
          this.feeders33 = response.data;
          this.f33Select.emit(this.feeders33);

        }, error => {
          this.toastr.error('An error occurred.');
        })
        if (this.selectedFeeder11.buId != 0) {
          this.isEnergyShared = false
          this.buNotSharedId = this.selectedFeeder11.buId
        }
        else {
          this.buNotSharedId = 0
          this.isEnergyShared = true
        }
        this.editTab.emit(this.selectedFeeder11);
        // this.openTab();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedFeeder11() {
    this.selectedFeeder11 = new Feeder11();
    this.buNotSharedId = 0;
    if (this.f)
      this.f.submitted = false;
  }

  openTabDelete(id:number) {
    this.feeder11Service.get(id).subscribe(response => {
     if(response.status === 200){
       this.selectedFeeder11 = response.data;
        this.deleteComp.emit();
     }
     else
       this.toastr.error(response.message);
   }, error => {
     this.toastr.error('An error occurred.');
   });
   this.selectedId = id;
  //  this.crudDeleteRef.changeState();
 }
 onDeleteConfirmed() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_delete)) {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.feeder11Service.delete(this.selectedId).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.currentPage = 1;
        this.reloadTable();
        this.resetSelectedFeeder11();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  validateConnectedSS() {
    if (this.selectedFeeder11.ssId == 0) {
      return false;
    }
    return true;
  }
  validateConnectedF33() {
    if (this.selectedFeeder11.feeder33Id == 0) {
      return false;
    }
    return true;
  }

  deleteFeederBu(buId: number) {
    this.selectedFeeder11.boundaryMeters.splice(this.selectedFeeder11.boundaryMeters.findIndex(x => x.buId == buId), 1)
  }

  isMeteredValidate() {
    if (this.selectedFeeder11.meterType === 2) {
      this.selectedFeeder11.meterNumber = '';
      this.selectedFeeder11.amiId = 0;
    }

  }

  onFileChosen(event: any) {
    this.file = event.target.files[0];
  }

  isFileChosen() {
    if (this.fileChosen)
      return true;

    return false;
  }

  uploadFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.feeder11Service.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
           window.location.href = response.result.data;
      }
      this.reloadTable();
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  getTemplate() {
    this.feeder11Service.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch() {
    this.crudService.globalSearch$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchValue => {
        if (searchValue.clicked && searchValue.componentName === ComponentNamesNew.Feeder11Component) {
          this.searchText = searchValue.text;
          this.currentPage = 1;
          this.checkedList = [];
          this.reloadTable();
          return;
        }
        this.searchText = '';
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onChangeSS() {
    this.feeder33Service.getAllFeeder33BySsId(this.selectedFeeder11.ssId).subscribe(response => {
      this.feeders33 = response.data;
      this.selectedFeeder11.feeder33Id = 0;
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  checkUncheckAll() {
    for (let i = 0; i < this.feeders11.length; i++) {
      this.feeders11[i].checked = this.masterSelected;
      this.checkForDownload(this.feeders11[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.feeders11.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.feeders11.every(function(item:any) {
      return item.checked == true;
    })
  }

  downloadItems(){
    let obj = {
      Ids: this.checkedList,
      activate: false
    };
    this.feeder11Service.downloadCrud(obj).subscribe(response => {
      if (response.status === 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.")
        }
        else {
          window.location.href = response.data
          this.toastr.success("Successfully downloaded feeders.");

        }
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
