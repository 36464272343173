import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class PaymentCollectionService {

  private pathApi = this.config.setting['pathApi'] + "paymentCollection/";

  constructor(private http: HttpClient,
    private config: AppConfig) {  }

  getAll(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, obj);
  }
  getPaymentCollectionCount(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getPaymentCollectionCount`, obj);
  }
  get(obj: any): Observable<any> { 
    return this.http.post(`${this.pathApi}GetByAccId`, obj);
  }

  getPaymentPopUpData(obj: any): Observable<any>{
    return this.http.post(`${this.pathApi}getPaymentCollectionPopUp`, obj);
  }

  downloadPaymentCollectionsReport(obj: any): Observable<any>{
    return this.http.post(`${this.pathApi}downloadPaymentCollectionsReport`, obj);
  }

  downloadAndSendPaymentCollectionsReport(obj: any): Observable<any>{
    return this.http.post(`${this.pathApi}downloadAndSendPaymentCollectionsReport`, obj);
  }

  getCumulativePayments(obj:any): Observable<any>{
    return this.http.post(`${this.pathApi}getCumulativePayments`,obj);
  }

  getNoOfAccounts(obj:any): Observable<any> {
    return this.http.post(`${this.pathApi}GetNoOfAccounts`,obj);
  }
}
