import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-customer-stats',
  templateUrl: './customer-stats.component.html',
  styleUrl: './customer-stats.component.scss'
})
export class CustomerStatsComponent{

  @Input() meterType: number = 0;

  @Input() prepaidCustomers: number = 0;
  @Input() prepaidNMDCustomers: number = 0;
  @Input() prepaidMDCustomers: number = 0;

  @Input() postpaidCustomers: number = 0;
  @Input() postpaidNMDCustomers: number = 0;
  @Input() postpaidMDCustomers: number = 0;

  @Input() unmeteredCustomers: number = 0;
  @Input() unmeteredNMDCustomers: number = 0;
  @Input() unmeteredMDCustomers: number = 0;

  hoveredSVG: string | null = null;

  hoverTimer: any;

  @HostListener('mouseenter')
  onMouseEnter(svgName: string){
    this.hoverTimer = setTimeout(() => {
      this.hoveredSVG = svgName;
    }, 500);
  }

  @HostListener('mouseleave')
  onMouseLeave(){
    this.hoveredSVG = null;
    clearTimeout(this.hoverTimer);
  }

  isSVGHovered(svgName: string): boolean {
    return this.hoveredSVG === svgName;
  }
}
