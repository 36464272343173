import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MapInfoWindow } from '@angular/google-maps';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MapFilter } from 'src/app/models/mapFilter.model';
import { MapEntityType, MapFilterDataOut } from 'src/app/models/mapFilterDataOut.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommonService } from 'src/app/services/common-service.service';
import { DtService } from 'src/app/services/dt.service';
import { MapService } from 'src/app/services/map.service';
import { SubstationService } from 'src/app/services/substation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { environment } from 'src/environments/environment';
import { stylesClusterized as mapStyle } from '../model/styles';

@Component({
  selector: 'adora-map-clusterized',
  templateUrl: './map-clusterized.component.html',
  styleUrls: ['./map-clusterized.component.scss']
})
export class MapClusterizedComponent implements OnInit, OnDestroy {
  @ViewChild('map', { static: true }) mapElement!: google.maps.Map;
  @ViewChild('markerModal') markerModal!: ElementRef;
  @ViewChild('markerDialog') markerDialog!: ElementRef;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow
  ts_icon: any = '/assets/map-new/ic_ts.png';
  ss_icon: any = "/assets/map-new/ic_ss.png";
  bu_icon = "/assets/map-new/ic_bu.png";
  ut_icon = "/assets/map-new/ic_ut.png";
  dt_icon = "/assets/map-new/ic_dt.png";
  ac_icon = "/assets/map-new/ic_acc.png"
  ac_act_icon = "/assets/map-new/ic_acc_act.png"
  ac_fin_icon = "/assets/map-new/ic_acc_fin.png"
  ac_dis_icon = "/assets/map-new/ic_acc_dis.png"
  ac_ina_icon = "/assets/map-new/ic_acc_ina.png"
  ac_und_icon = "/assets/map-new/ic_acc_und.png"


  content: any = '';

  @Input() lat = environment.mapCenterLat;
  @Input() lng = environment.mapCenterLong;
  @Input() zoom = environment.mapZoom;
  center: any;
  @Input() markers: any[] = [];
  markersTransformed: any[] = [];
  @Input() xOffsetModal: number = 0;
  @Input() yOffsetModal: number = 0;
  @Input() hasDialog: boolean = false;
  @Input() clickable: boolean = false;
  @Input() searchValue:string="";
  @Input() nmDashboard:boolean = false;
  @Input() organizationTab:boolean = false;
  @Input() customersMap: boolean = false;
  @Input() unmetered: boolean = false;
  @Input() buIds: number[] = [];
  @Input() utIds: any = [];
  @Input() tsIds: number[] = [];
  @Input() ssIds: number[] = [];
  @Input() dtIds: number[] = [];
  @Input() meterItemType: string = '';
  @Input() showOnlyDts:boolean = false;
  @Input() showOnlySs:boolean = false;
  @Input() showOnlyTs:boolean = false;
  @Input() showOnlyUts:boolean = false;
  @Input() showOnlyBu:boolean = false;



  modalType: string = '';
  modalTitle: string = '';
  modalConsumption: number = 0;
  modalConsumptionPercentage: number = 0;
  modalData:any[]=[];
  showModal:boolean=false;
  //zoomTriggered:boolean = false;
  zoomTimeTriggered:number=0;
  zoomTimeDelay:number=500;
  zoomTimeout:any;

  energyReceivedPreviousMonth?: number = 0;
  losses?: number = 0;
  lossesMVH?: number = 0;

  filterByDateClicked: boolean = false;
  public dateFrom: any = '';
  public dateTo: any = '';
  clickObservable: Observable<Event> = fromEvent(document, 'click');

  northEastLat: number = 0;
  northEastLng: number = 0;
  southWestLat: number = 0;
  southWestLng: number = 0;
  mapData: MapFilterDataOut[] = [];




  private ngUnsubscribe = new Subject<void>();

  selectedMarker: any = {};

  public ssData: any = {};
  public dtData: any = {};
  public buData: any = {};
  public utData: any = {};

  shouldCloseDialog: boolean = true;
  public monthBefore: any = '';

  //ICON CLICKS
  @Output() tsClick = new EventEmitter();
  @Output() ssClick = new EventEmitter();
  @Output() buClick = new EventEmitter();
  @Output() utClick = new EventEmitter();
  @Output() dtClick = new EventEmitter();



  ngOnChanges(changes: SimpleChanges) {
    this.closeDialog();

    if(changes?.markers && this.markers.length > 0){
      this.markers.forEach(function (value) {
        if(!value.icon?.scaledSize){
          value.icon = {
            url: value.icon,
            scaledSize:{
              width: 50 / window.devicePixelRatio,
              height: 50 / window.devicePixelRatio,
              widthUnit: 'px',
              heightUnit: 'px'
          }}
        }
      });
    }
    this.checkChanges(changes);
    
  }

  checkChanges(changes: SimpleChanges){
    if(this.nmDashboard || this.customersMap){
      if(changes.searchValue){
    if(changes.searchValue.previousValue!=changes.searchValue.currentValue){
      this.searchValue=changes.searchValue.currentValue;
      if(this.searchValue.length>=2 || this.searchValue.length == 0)
         this.getMapData()
    }
  }
        if (changes.utIds || changes.buIds || changes.tsIds || changes.ssIds || changes.dtIds || changes.organizationTab || changes.meterItemType ){
    this.getMapData();
  }

  }
  }


  mapProperties = {
    center: new google.maps.LatLng(this.lat, this.lng),
    // zoom: this.zoom,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: mapStyle,
    disableDefaultUI: true,
    strictBounds: false
  };

  markerProperties: google.maps.MarkerOptions = {
    //zIndex: 100
  };


  constructor(private commonService: CommonService, private ssService: SubstationService,
              private dtService: DtService, private toastr: ToastrService, private buService: BusinessUnitService,
              private utService: UndertakingService, private mapService:MapService) {}

  ngOnInit(): void {
    this.center = new google.maps.LatLng(this.lat, this.lng);
    this.subscribeToClickEvent();
    this.setDate();

  }


  onMapBoundsChange() {
    let bounds = this.mapElement.getBounds();

    if (bounds != undefined) {
      this.northEastLat = bounds.getNorthEast().lat();
      this.northEastLng = bounds.getNorthEast().lng();
      this.southWestLat = bounds.getSouthWest().lat();
      this.southWestLng = bounds.getSouthWest().lng();
    }
    if(this.nmDashboard || this.customersMap)
       this.getMapData();

  }


  firstBoundChange(){
    if(this.southWestLng==0 && this.southWestLat==0 && this.northEastLat==0 && this.northEastLng==0 ){
      this.onMapBoundsChange()
    }

  }


  onMapInitialized(){
   if(this.nmDashboard || this.customersMap){
      this.onMapBoundsChange()
      this.getMapData();
   }

  }


  onMapZoomChange() {
    if (this.zoomTimeout) clearTimeout(this.zoomTimeout);

    this.zoom=this.mapElement.getZoom() || 10;
    let bounds = this.mapElement.getBounds();
    if (bounds != undefined) {
      this.northEastLat = bounds.getNorthEast().lat();
      this.northEastLng = bounds.getNorthEast().lng();
      this.southWestLat = bounds.getSouthWest().lat();
      this.southWestLng = bounds.getSouthWest().lng();
    }
    let that=this;
    this.zoomTimeout=setTimeout(function(){
    if(that.nmDashboard || that.customersMap)
       that.getMapData();
      },500)
  }



  getMapData(){
    let mapFilter = new MapFilter();
    mapFilter.SearchValue = this.searchValue;
    mapFilter.NorthEastLat = this.northEastLat;
    mapFilter.NorthEastLng = this.northEastLng;
    mapFilter.SouthWestLat = this.southWestLat;
    mapFilter.SouthWestLng = this.southWestLng;
    mapFilter.OrganizationTab=this.organizationTab;
    mapFilter.Zoom = this.zoom;
    mapFilter.Bus = this.buIds;
    mapFilter.Uts = this.utIds;
    mapFilter.Dts = this.dtIds;
    mapFilter.Ts = this.tsIds;
    mapFilter.Ss = this.ssIds;
    mapFilter.ShowOnlyDts = this.showOnlyDts;
    mapFilter.ShowOnlyBu = this.showOnlyBu;
    mapFilter.ShowOnlySs = this.showOnlySs;
    mapFilter.ShowOnlyTs = this.showOnlyTs;
    mapFilter.ShowOnlyUts = this.showOnlyUts;
    if(this.nmDashboard ){
    if(this.northEastLat != 0 || this.northEastLng!= 0 || this.southWestLat!=0 || this.southWestLng!=0 ){
    this.mapService.getMapData(mapFilter).subscribe({
      next: (data) => {
        this.markers = data.body.data;
        this.markers.forEach(function (value) {
          if(!value.icon?.scaledSize){
            value.icon = {
              url: value.icon,
              scaledSize:{
                width: 50 / window.devicePixelRatio,
                height: 50 / window.devicePixelRatio,
                widthUnit: 'px',
                heightUnit: 'px'
            }}
          }
        });
      },
      error: (error) => console.log(error.statusText)
    });
  }


  }
  if(this.customersMap){
    mapFilter.AccountConsumptionType=this.meterItemType;
    mapFilter.Bus=this.buIds;
    mapFilter.Uts=this.utIds;
    if(this.unmetered){
      mapFilter.Unmetered=true;
    }
    if(this.northEastLat != 0 || this.northEastLng!= 0 || this.southWestLat!=0 || this.southWestLng!=0 ){
    this.mapService.getAccMarkers(mapFilter).subscribe({
      next: (data) => {
        this.markers = data.body.data;
        this.markers.forEach(function (value) {
          if(!value.icon?.scaledSize){
            value.icon = {
              url: value.icon,
              scaledSize:{
                width: 50 / window.devicePixelRatio,
                height: 50 / window.devicePixelRatio,
                widthUnit: 'px',
                heightUnit: 'px'
            }}
          }
        });
      },
      error: (error) => console.log(error.statusText)
    });
  }
  }
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  private setDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    if(dte.getMonth() == 0)
    {
      this.dateFrom = {year: dte.getFullYear() - 1, month: 12, day: 1};
    }
    else {
      this.dateFrom = {year: dte.getFullYear(), month: dte.getMonth(), day: 1};
    }
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
    this.monthBefore = new Date(dte.getFullYear(), dte.getMonth() - 1, 1);
  }

  markerHoverNM(marker: any, data: any, event: any){
    this.markerModal.nativeElement.scrollTop = 0;
    if(data.additionalInfo.length!=0){
      this.modalData=data.additionalInfo;
      this.markerModal.nativeElement.style.display='block';
      if(data.type == MapEntityType.TS)
        this.modalType = 'TS';
      else if(data.type==MapEntityType.SS)
        this.modalType = 'SS';
      else if(data.type==MapEntityType.DT)
        this.modalType = 'DT';
      else if(data.type==MapEntityType.BU)
        this.modalType = 'BU';
      else if(data.type==MapEntityType.UT)
        this.modalType = 'UT';
      else
        this.modalType = 'ACC';



    let pixelOffset = this.getMarkerPixelPosition(marker, event.domEvent.target.width ?? 58);
    this.markerModal.nativeElement.style.left = (pixelOffset.x - this.markerModal.nativeElement.clientWidth / 2 - this.xOffsetModal) + 'px';
    this.markerModal.nativeElement.style.top = (pixelOffset.y - this.yOffsetModal) + 'px';

    this.markerModal.nativeElement.style.opacity = '1';
  }
  else{
    this.markerModal.nativeElement.style.display='none';

  }
  }

  checkModal(icon:string){
    if(icon.includes('ic_ts'))
       this.modalType = 'TS';
    else if(icon.includes('ic_ss'))
       this.modalType = 'SS';
    else if(icon.includes('ic_dt'))
      this.modalType = 'DT';
    else if(icon.includes('ic_bu'))
      this.modalType = 'BU';
    else if(icon.includes('ic_ut'))
      this.modalType = 'UT';
    else if(icon.includes('ic_acc'))
      this.modalType = 'ACC';
 }

  getMarkerPixelPosition(marker: any, offset: any){
    let scale = Math.pow(2, this.mapElement?.getZoom()!);                /// Bilo je bez ||0.
    let tempLat = this.mapElement?.getBounds()?.getNorthEast().lat() ?? 0;
      let tempLng = this.mapElement?.getBounds()?.getSouthWest().lng() ?? 0;
      let nw = new google.maps.LatLng(
        tempLat,
        tempLng
    );
    let worldCoordinateNW = this.mapElement?.getProjection()?.fromLatLngToPoint(nw);
    let worldCoordinate = this.mapElement?.getProjection()?.fromLatLngToPoint(marker.getPosition());
    if(worldCoordinate && worldCoordinateNW){
      let pixelOffset = new google.maps.Point(
        Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
        Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
      );

      pixelOffset.y -= (offset / 4);
      pixelOffset.x += (offset / 3)
      return pixelOffset;
    }

    return new google.maps.Point(0, 0);
  }

  getMarkerLabel(marker:any){
    return {text: marker.count.toString(), color:'white' , fontSize:'8px' }
  }

  getIconData(ic:any){
      const icon ={
        url: ic, // url
        scaledSize: new google.maps.Size(50, 50)

      }
      return icon

  }


  markerHoverFinish(){
    this.markerModal.nativeElement.style.opacity = '0';
    this.markerModal.nativeElement.style.top = '0';
  }

  closeDialog(){
    if(this.markerDialog && this.shouldCloseDialog)
      this.markerDialog.nativeElement.style.transform = 'translate(100%)';

    this.shouldCloseDialog = true;
  }

  getPosition(longitude:number, latitude:number){

    return {lat: latitude, lng:longitude }
  }

  private subscribeToClickEvent() {
    this.clickObservable
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((e: any) => {
      this.filterByDateClicked = false;
    })
  }


}
