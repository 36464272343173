import { Component, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-asset-table',
  templateUrl: './asset-table.component.html',
  styleUrls: ['./asset-table.component.scss']
})
export class AssetTableComponent {

  @Input() avgReceived: number = 0;
  @Input() avgLosses: number = 0;
  @Input() avgEnergyConsumption: number = 0;
  @Input() tableData: any[] = [];
  @Input() tabNumber: number = 0;

  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')

  constructor(private translationService: TranslationService) { }
}
