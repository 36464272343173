import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { CreateSubunitComponent } from './create-subunit/create-subunit.component';
import { CrudService } from 'src/app/services/crudService.service';
import { AuthService } from 'src/app/services/auth.service';
import { SubunitService } from 'src/app/services/subunit.service';
import { ToastrService } from 'ngx-toastr';
import { Subunit } from 'src/app/models/subunit.model';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';

@Component({
  selector: 'app-subunit-tab',
  templateUrl: './subunit-tab.component.html',
  styleUrl: './subunit-tab.component.scss'
})
export class SubunitTabComponent {

  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal!: ElementRef;

  public PermissionsEnum = PermissionsEnum;
  public selectedId: number = 0;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public searchText: string = "";
  private ngUnsubscribe = new Subject<void>();

  public allEntities: Subunit[] = [];
  public selectedEntity: Subunit = new Subunit();

  @ViewChild(CreateSubunitComponent) popupComponentRef!: CreateSubunitComponent;

  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();

  constructor(private crudService: CrudService,
    public authService: AuthService,
    public subunitService: SubunitService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    console.log('ng on init')
    this.reloadTable();
    this.subscribeToSearch();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };

    this.subunitService.getAll(obj).subscribe(response => {
      this.allEntities = response.data.data;
      if (obj.pageInfo.page == 1) {
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  selectEntity(id: number) {
    this.subunitService.get(id).subscribe(response => {
      if (response.status === 200){
        this.selectedEntity = response.data;
        console.log(this.selectedEntity)
        this.editEvent.emit(this.selectedEntity);
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  openTabDelete(id: number) {
    this.subunitService.get(id).subscribe(response => {
     if (response.status === 200){
       this.selectedEntity = response.data;
       this.deleteEvent.emit();
     }
     else
       this.toastr.error(response.message);
   }, error => {
     this.toastr.error('An error occurred.');
   });
   this.selectedId = id;
  }

  onDeleteConfirmed() {
    this.subunitService.delete(this.selectedEntity ? this.selectedEntity.id : 0).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message)
        this.currentPage = 1
        this.reloadTable()
      }
      else
        this.toastr.error(response.message)
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  openTab() {
    this.popupComponentRef.changeState(1, this.selectedEntity);
   }

  public subscribeToSearch() {
    this.crudService.globalSearch$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(searchValue => {
      if (searchValue.clicked && searchValue.componentName === ComponentNamesNew.SubunitComponent) {
        this.searchText = searchValue.text;
        this.currentPage = 1;
        this.reloadTable();
        return;
      }
      this.searchText = '';
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
