import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FOF33FlaggingValues } from 'src/app/models/fo-f33-flagging-values.model';
import { PopupEnum } from 'src/app/models/popupEnum';
import { PredictiveF33HistoryAnalytics } from 'src/app/models/predictive-f33-history-analytics.model';
import { PredictiveF33Analytics } from './../../../../../models/predictive-f33-analytics.model';
import { PaF33SliderNewComponent } from './pa-f33-slider-new/pa-f33-slider-new.component';
@Component({
  selector: 'app-pa-f33-new',
  templateUrl: './pa-f33-new.component.html',
  styleUrls: ['./pa-f33-new.component.scss']
})
export class PaF33NewComponent {

  @Input() data: PredictiveF33Analytics[] = []
  @Input() historyData: PredictiveF33HistoryAnalytics[] = []

  @Input() menuTab: any

  @Input() flags: FOF33FlaggingValues = new FOF33FlaggingValues()

  @Output() popUpOpen: EventEmitter<any> = new EventEmitter()
  @Output() popUpClose: EventEmitter<any> = new EventEmitter()
  @ViewChild(PaF33SliderNewComponent) popupF33ComponentRef!: PaF33SliderNewComponent

  openPopUp(item: any) {
     this.popupF33ComponentRef.changeState(PopupEnum.Open, item, this.flags)
  }
}
