import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-new-count-box',
  templateUrl: './count-box.component.html',
  styleUrls: ['./count-box.component.scss']
})
export class NewCountBoxComponent {

  @Input() label: any;
  @Input() data: any;
  @Input() secondData: any;
  @Input() viewDetails: boolean = false
  @Input() customerWidth: boolean=false
  @Input() isGreen: boolean = false;

  @Output() viewDetailsClickedChanged = new EventEmitter()

  detailsClicked(){
    this.viewDetailsClickedChanged.emit()
  }

}
