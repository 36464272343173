import { TimePeriod } from 'src/app/components/consumption/model/model';
import { Component, Input, ViewChild, OnChanges, SimpleChanges, EventEmitter, Output, HostListener, ElementRef } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbTime } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrl: './datetime-picker.component.scss'
})
export class DatetimePickerComponent  implements OnChanges {

  public currentDate : any;
  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private toastr : ToastrService)
  {
    let currentDate = new Date();
    this.currentDate = {year: currentDate.getFullYear(), month: currentDate.getMonth(), day: currentDate.getDate()}
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.reset?.currentValue){
      this.date = null;
      this.dateLabel = "";
      this.time = null;
      this.timeLabel = "";
      this.reset = false;
    }
    if(changes.date){
      this.onDateSelection(changes.date.currentValue);
    }
    if(changes.time){
      this.onTimeSelection(changes.time.currentValue);
    }
  }
  /**
   * name for datepicker
   */
  @Input() name: string = "Period";
  /**
 * disable past dates
 */
  @Input() minDate: boolean = false;
  @Input() reset: boolean = false;

  @ViewChild('datepicker') datePicker!: NgbInputDatepicker;

  /**
   * Used to style hovered dates
   */
  hoveredDate: NgbDate | null = null;
  /**
   * Date in the calendar
   */
  @Input() date!: NgbDate | null;

    /**
   * Time in the calendar
   */
    @Input() time!: NgbTime | null;


  @Output() dateTimeSelected = new EventEmitter<{
    date: NgbDate;
    time: NgbTime;
  }>()

  isPopupShown : boolean = false;
  toggle() {
    this.isPopupShown = !this.isPopupShown;
  }

    /**
   * Values used to display readable date string in input field like: '29/10/2023 - 30/10/2023'
   */
    dateLabel: string = "";
    timeLabel: string = "";

  isDisabled = (date:any) => {
    if (this.minDate) {
      return date.before(this.calendar.getToday());
    }
    return false;
  };
  onDateSelection(date: NgbDate) {
    if(date){
      this.date = date;
    }
  }
  onTimeSelection(time: NgbTime){
    if(time){
      this.time = time;
    }
  }
  applySelection() {
    if (this.date && this.time) {
      this.timeLabel = `${this.time.hour.toString().padStart(2, '0')}:${this.time.minute.toString().padStart(2, '0')}`;
      this.dateLabel = `${this.date.day.toString().padStart(2, '0')}/${this.date.month.toString().padStart(2, '0')}/${(this.date.year % 100).toString().padStart(2, '0')}`;
      this.dateTimeSelected.emit({
        date: this.date,
        time: this.time
      });
      this.closeDatePicker();
    }
    else{
      this.toastr.info("Both date and time values must be filled");
    }
  }
  closeDatePicker() {
    this.isPopupShown = false;
  }
  @ViewChild('dateContainer') dateContainer!: ElementRef;

  @HostListener('document:mousedown', ['$event'])
  onOutsideClick(event: any): void {
     if (!this.dateContainer?.nativeElement.contains(event.target)) {
      this.closeDatePicker();
     }
  }
  setInitDateTime(date : NgbDate, time : NgbTime){
    this.date = date;
    this.time = time;
    this.timeLabel = `${this.time.hour.toString().padStart(2, '0')}:${this.time.minute.toString().padStart(2, '0')}`;
    this.dateLabel = `${this.date.day.toString().padStart(2, '0')}/${this.date.month.toString().padStart(2, '0')}/${(this.date.year % 100).toString().padStart(2, '0')}`;
  }

  public resetPicker(){
      this.date = null;
      this.dateLabel = "";
      this.time = null;
      this.timeLabel = "";
      this.reset = false;
  }
}
