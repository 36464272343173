import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TimePeriod } from 'src/app/components/consumption/model/model';
import { DtService } from 'src/app/services/dt.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-f33-current-active-popup',
  templateUrl: './f33-current-active-popup.component.html',
  styleUrls: ['./f33-current-active-popup.component.scss']
})
export class F33CurrentActivePopupComponent{
  public isPopupOpen: boolean = false;
  public selectedF33: any = {};
  public dts: any = [];
  public data: any = null;
  public currentPage: number = 1;
  public count: number = 0;
  public pageSize: number = 8;
  public lastPageSize: number = 1;
  public dataFeeder: any = {};
  date: string = '';
  public feederTopology:string='feeder11';
  @Input() timePeriod: TimePeriod = TimePeriod.Monthly;
  @Input() dateFrom: any = '';
  @Input() dateTo: any = '';

  public buName: string = this.translationService.getByKeyFromCache('BU');

  public tabMenu: number = 1;
  constructor(
    private dtService: DtService,
    private toastr: ToastrService,
    private translationService:TranslationService
  ) { }

  dateFormat() {
    const date = new Date(this.selectedF33.outageStart);
    const all = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"];
    const month = all[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    this.date = `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds}`;
    }

    tabChanged(value: number) {
      this.tabMenu = value
    }
    close() {
      this.isPopupOpen = false;
    }

  public isPlanned(plan: string) {
    if (plan === 'Planned') {
      return true;
    }
    else return false;
  }
  private getDtsByF33() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        Feeder33Id: this.selectedF33.feederId
      }
    };
    this.dtService.getAllDtsByF33IdWithPagination(obj).subscribe(response => {
      this.dts = response.data.data;
      this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  pageChangeAccounts(value: any) {
    this.currentPage = value;
    this.getDtsByF33();
  }
  back() {
    this.tabChanged(2)
  }

  changeState(data?: any, menu?: any) {
    if (data)
      this.selectedF33 = data;
    this.dateFormat()
    this.getDtsByF33()
    if (menu === 1){
      this.isPopupOpen=true;
      this.tabMenu=1;
     }
    else
      this.isPopupOpen = false;

  }
}
