export class Ami{
    id: number = 0;
    name: string = '';
    formatAccount: string = '';
    formatDt: string = '';
    formatFeeder: string = '';
    meterModel: string = '';
    meterPhase: string = 'Unknown';
    checked: boolean = false;
}
