import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DtService } from 'src/app/services/dt.service';

@Component({
  selector: 'app-f11-popup',
  templateUrl: './f11-popup.component.html',
  styleUrls: ['./f11-popup.component.scss']
})
export class F11PopupComponent {
  public isPopupOpen: boolean = false;
  public selectedF11: any = {};
  public tabMenu: number = 1;
  public dataFeeder: any = {};

  constructor(private _dtService: DtService,
    private toastr: ToastrService) { }

  tabChanged(value: number) {
    this.tabMenu = value
  }
  close() {
    this.isPopupOpen = false;
  }
  getPopupInfo() {
    this._dtService.countOfPrivateDTsConnectedDirectly(this.selectedF11.id).subscribe(response => {
      this.dataFeeder = response.data;
      this.dataFeeder.info = this.selectedF11;
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }
  changeState(menu?: any, data?: any) {
    if (data)
      this.selectedF11 = data;
    this.getPopupInfo();
    if (menu === 1) {
      this.isPopupOpen = true;
      this.tabMenu = 1;
    }
    else
      this.isPopupOpen = false;

  }
}
