import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { transformToString } from 'src/app/helpers/dateUtils';
import { FieldAgentsService } from 'src/app/services/field-agents.service';
import { UserService } from 'src/app/services/user.service';
import { WorkOrderManagement } from 'src/app/services/work-order-management.service';
import { ReassignTaskPopupComponent } from '../reassign-task-popup/reassign-task-popup.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { DeactivatePopupComponent } from './deactivate-popup/deactivate-popup.component';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';
import { TranslationService } from 'src/app/services/translation.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class AgentDetailsComponent implements OnInit {
  public isPopupOpen: boolean = false;
  showShadow = false;
  showTaskDetails: ViewsEnum = ViewsEnum.View;
  public viewsEnum = ViewsEnum
  showAgentPopup: boolean = false
  selectedData: any;

  date: string = '';
  date2: string = '';

  @Output() confirmUpload: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmGetTemplate: EventEmitter<void> = new EventEmitter<void>();
  @Output() parentComp = new EventEmitter();
  @Output() createAgentPopupChange = new EventEmitter();
  guid: number = 0;
  @Input() update: boolean = false;
  @Input() file: any = null;
  fileChosen: File | null = null;
  public selectedfieldAgent: any = {};
  public tasks: any[] = [];
  public selectedTask: any = []
  public masterSelected: boolean = false;
  public checkedList: any = []
  teamId: number[] = []

  public activeTab: boolean = false
  public status: string = '';
  public isCreateUserOpen: boolean = false
  @Output() changeView: EventEmitter<any> = new EventEmitter()
  @ViewChild(ReassignTaskPopupComponent) popupComponentRef!: ReassignTaskPopupComponent;
  @ViewChild(DeactivatePopupComponent) deactivatePopupComponentRef!: DeactivatePopupComponent;

  //MULTI FILTER
  public searchPlaceholder: any = "Search by Acc name or Acc No";
  public filterApplied: boolean = false;
  public allFilters: any = []
  public statusName = 'Status'
  public statuses: any = { name: this.statusName, data: [], dropdown: true };
  public statusId: number = 0;
  public showFilter: any = false
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public dateFrom: any = ''
  public dateTo: any = ''
  public height: string = '21rem'

  //pagination
  public currentPage: number = 1;
  public pageSize: number = 10;
  public lastPageSize: number = 1;
  @Input() count: number = 0;

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  options = ['']


  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  constructor(private elementRef: ElementRef,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private workOrderService: WorkOrderManagement,
    private fieldAgentService: FieldAgentsService,
    private translationService:TranslationService,
    private location: Location) {
    this.route.params.subscribe(params => {
      this.guid = params['id'];
    })

  }

  public changeViewFromChild() {
    this.location.back()
  }

  ngOnInit(): void {
    this.setMainDate();
    this.selectFieldAgent(this.guid);
    this.checkFilters();
  }
  open() {
    this.popupComponentRef.open();
  }
  showTask(item: any) {
    this.showTaskDetails = ViewsEnum.ShowDetails;
    this.teamId = this.selectedfieldAgent.teamId
    this.selectedTask = item;
    this.selectedTask.name = item.taskName
  }
  editTask(){
    this.showTaskDetails = ViewsEnum.Edit
  }

  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.isPopupOpen) {
      if (this.elementRef.nativeElement.contains(event.target) || event.target === this.elementRef.nativeElement) {
        if (!isSliderClicked) {
          this.closePopup();
        }
      }
    }
  }
  onFileChosen(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.fileChosen = inputElement.files[0];
    }
  }

  getTemplate() {
    this.confirmGetTemplate.emit();
    this.isPopupOpen = false;
    this.showShadow = false;
  }
  upload() {
    this.confirmUpload.emit();
    this.isPopupOpen = false;
    this.showShadow = false;
    this.update = false;
  }

  closePopup() {
    this.isPopupOpen = false;
    this.showShadow = false;
  }

  copyToClipboard(text: any) {
    const textToCopy = text;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    this.toastr.success('Copied to clipboard')

  }

  public openPopup() {
    if(this.activeTab===true)
      this.deactivatePopupComponentRef.open();
    else
     this.activateUser(this.activeTab);

  }

  public activate() {
     this.activateUser(this.activeTab);
  }

  activateUser(tab: any) {
        this.activeTab = !this.activeTab
    const value = this.selectedfieldAgent.id;
    const list: number[] = [value];
    let obj = {
      Ids: list,
      activate: this.activeTab
    };
    this.fieldAgentService.activateFieldAgent(obj).subscribe(response => {
      if (response.status == 200) {
        this.selectedfieldAgent.userActivation = response.data
        this.toastr.success(response.message)

        this.parentComp.emit();
      }
      else {
        this.toastr.error(response.message)
      }

    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  deactivateUser() {
    const value = this.selectedfieldAgent.id;
    const list: number[] = [value];

    let obj = {
      Ids: list,
      activate: false
    };
    this.fieldAgentService.activateFieldAgent(obj).subscribe(response => {
      this.selectedfieldAgent.userActivation = response.data
      this.parentComp.emit();

    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  isActive(tab: number) {
    return this.activeTab
  }

  initIsActive() {
    if (this.selectedfieldAgent.userActivation === 'Active')
      this.activeTab = true
    else
      this.activeTab = false
  }
  alert() {
    this.toastr.warning("Not yet implemented");
  }
  changeState(data?: number, menu?: any) {
    if (data) {
      this.selectedfieldAgent = data;
      if (menu === 1) {
        this.status = "Unengaged ";
      } else if (menu === 2) {
        this.status = "Engaged";
      } else
        this.status = "Inactive ";

      this.initIsActive()
    }

    this.dateFormat();
    this.isPopupOpen = true;
    this.showShadow = true;

  }
  dateFormat() {
    const date = new Date(this.selectedfieldAgent.dateCreated);
    const all = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"];
    const month = all[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    this.date = `${month} ${day}, ${year}`;
    for (const item1 of this.tasks) {

      const date2 = new Date(item1.dateCreated);
      const month = all[date2.getMonth()];
      const day = date2.getDate();
      const year = date2.getFullYear();
      this.date2 = `${month} ${day}, ${year}`;
    }
  }
  deleteAgent() {
    this.userService.delete(this.selectedfieldAgent.userGuid).subscribe(response => {
      this.parentComp.emit();
      this.closePopup();
      this.toastr.success('Successfully deleted.');
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  sendObjectToAnotherComponent() {
    this.router.navigate(['/field-operations/agent-create'], {
      queryParams: { data: JSON.stringify(this.selectedfieldAgent.id) }
    });
  }
  openEditAgent() {
    this.closePopup()
    this.createAgentPopupChange.emit(this.selectedfieldAgent)
  }
  back() {
    this.location.back()
  }
  getCheckedItemList() {
    this.checkedList = [];
    for (let value of this.tasks) {
      if (value.checked)
        this.checkedList.push(value);
    }
  }
  isAllSelected() {
    this.masterSelected = this.tasks.every(function (item: any) {
      return item.checked;
    })
    this.getCheckedItemList();
  }
  reassignTask() {
    //
  }
  selectFieldAgent(id: any) {
    this.fieldAgentService.get(id).subscribe(response => {
      this.selectedfieldAgent = response.data;
      this.teamId = this.selectedfieldAgent.teamId
      this.showAgentPopup = true
      this.reloadTable();
      this.dateFormat();
      if (this.selectedfieldAgent.userActivation === 'Active')
        this.activeTab = true
      else
        this.activeTab = false;

    }, error => {
      this.toastr.error('An error occurred.');
    })
  }
  //
  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        userId: this.selectedfieldAgent.id,
        status: this.statusId,
        Search: this.searchText.name,
        dateCreatedFrom: transformToString(this.dateFrom),
        dateCreatedTo: transformToString(this.dateTo)
      }
    };

    this.workOrderService.getAll(obj).subscribe(response => {
      this.tasks = response.data.data;
      this.count = response.data.count;
      this.dateFormat();
      this.lastPageSize = Math.ceil(this.count / this.pageSize);
    }, error => {
      this.tasks = [];
      this.toastr.error('An error occurred.');
    });
  }

  setMainDate() {
    let date = new Date()
    let dteFrom = new Date()

    date.setHours(2, 0, 0)
    date.setDate(date.getDate() + 1)


    this.dateFrom = { year: dteFrom.getFullYear(), month: dteFrom.getMonth(), day: dteFrom.getDate() };
    this.dateTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
  }

  //multi filter

  searchChange(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.reloadTable()
  }

  checkFilters() {
    let statusData = this.allFilters.find((item: { name: any; }) => item.name === this.statusName)

    if (!statusData) {
      this.statuses.data = [{ value: 2, name: 'Ongoing' }, { value: 3, name: 'Completed' }, { value: 4, name: 'Canceled' }]
      this.allFilters.push(this.statuses)
      this.filterApplied = true
    }
  }

  getOptions(searchText: SearchAutocomplete) {
    if (searchText.name.length > 2) {
      this.workOrderService.getDataForAutocomplete(searchText.name)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next : (response) => this.options = response.data,
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
    } else
      this.searchComponentRef.resetOptions()
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    this.reloadTable(1)
  }

  dropdownSelected(item: any) {
    if(item.data.name == this.statusName)
    {
      if (this.statusId == item.item.value)
        return
      if (!item.item)
        this.statusId = 0
      else
        this.statusId = item.item.value
    }
    this.reloadTable(1)
  }

  public changeTaskView(value: ViewsEnum){
    this.showTaskDetails = value
  }
}

