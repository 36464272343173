<div class="dialog-content-root">
    <div class="header">
        <img alt="" src="assets/map/ic_bu_dialog.svg"/>
        <div>{{buName}}</div>
    </div>
    <div class="title">{{buData.name}}</div>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-sm-6 table-col">
                <div class="label">Address</div>
                <div class="value">{{buData.address}}</div>
            </div>
            <div class="col-sm-6 table-col">
                <div class="label">{{utName}} count</div>
                <div class="value">{{buData.utCount}}</div>
            </div>
        </div>
    </div>
</div>
