<div *ngIf="isPopupOpen" class="popup-overlay" (click)="changeState(2)">
    <div class="popup-content" click-stop-propagation>
        <div class="red-line">
        </div>
      <!-- Your popup content here -->
      <div class="back-button" (click)="changeState(2)">
        <span>CLOSE</span>
      </div>

      <div class="information-wrapper">
        <div class="user-information">
            <div class="user-data">
                <span class="tab-field-header">
                    Account Name
                </span>
                <span class="tab-field-body" title="{{selectedMeterBypass.accountName}}">
                    {{selectedMeterBypass.accountName}}
                </span>
            </div>
            <div class="user-data">
                <span class="tab-field-header">
                    Account Number
                </span>
                <span class="tab-field-body" title="{{selectedMeterBypass.accountNumber}}">
                    {{selectedMeterBypass.accountNumber}}
                </span>
            </div>
            <div class="user-data">
                <span class="tab-field-header">
                    Meter Serial No
                </span>
                <span class="tab-field-body" title="{{selectedMeterBypass.meterSerialNumber}}">
                    {{selectedMeterBypass.meterSerialNumber}}
                </span>
            </div>
        </div>

        <div class="user-information network-information">
            <div class="user-data">
                <span class="tab-field-header">DT Name</span>
                <span class="tab-field-body" title="{{selectedMeterBypass.meterSerialNumber}}">{{selectedMeterBypass.dtName | hasValue}}</span>
            </div>
            <div class="user-data">
                <span class="tab-field-header">Feeder Name</span>
                <span class="tab-field-body" title="{{selectedMeterBypass.feederName}}">{{selectedMeterBypass.feederName | hasValue}}</span>
            </div>
            <div class="user-data">
                <span class="tab-field-header">% Vending deviation</span>
                <span class="tab-field-body" title="{{selectedMeterBypass.vendingDeviation}}">{{selectedMeterBypass.vendingDeviation | twoDecimals}}</span>
            </div>
            <div class="user-data">
                <span class="tab-field-header">% Availability deviation</span>
                <span class="tab-field-body" title="{{selectedMeterBypass.vendingDeviation}}">{{selectedMeterBypass.availabilityDeviation | twoDecimals}}</span>
            </div>
            <div class="user-data">
                <span class="tab-field-header">{{buName}}</span>
                <span class="tab-field-body" title="{{selectedMeterBypass.businessUnit}}">{{selectedMeterBypass.businessUnit | hasValue}}</span>
            </div>
        </div>
        <div class="user-information address-information">
            <div class="address-data">
                <span class="tab-field-header">Customer Address</span>
                <span class="tab-field-body address-field-body" title="{{selectedMeterBypass.customerAddress}}">{{selectedMeterBypass.customerAddress}}</span>
            </div>
        </div>
      </div>
      <div class="table-wrapper">
            <table class="standard-table">
                <thead>

                    <tr>
                        <th><span>Month</span></th>
                        <th><span>LAR(MWH)</span></th>
                        <th><span>PAR(MWH)</span></th>
                        <th><span>CONSUMPTION</span></th>
                        <th><span>Monthly vend</span></th>
                        <th><span>Avg monthly vend</span></th>
                        <th><span>Monthly supply availability</span></th>
                        <th><span>Avg monthly availability</span></th>
                        <th><span>Avg Daily Availability</span></th>
                        <th><span>% Vending deviation</span></th>
                        <th><span>% Availability deviation</span></th>
                        <th><span>Alarm condition</span></th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr *ngFor="let item of selectedMeterBypass.modalMonthlyTables" class="td-padding">
                        <td class="td-padding" title="{{item.monthName}}"><span>{{item.monthName | hasValue}}</span></td>
                        <td class="td-padding" title="{{item.lar}}"><span>{{item.lar | hasValue}}</span></td>
                        <td class="td-padding" title="{{item.par}}"><span>{{item.par | hasValue}}</span></td>
                        <td class="td-padding" title="{{item.consumption}}"><span>{{item.consumption | hasValue}}</span></td>

                        <td title="{{item.monthlyVend}}"><span >{{item.monthlyVend | hasValue | twoDecimals}}</span></td> 
                        <td title="{{item.avgMonthlyVend}}"><span >{{item.avgMonthlyVend | hasValue | twoDecimals}}</span></td>
                        <td title="{{item.monthlySupplyAvailability}}"><span>{{item.monthlySupplyAvailability | hasValue | twoDecimals}}</span></td>
                        <td title="{{item.avgMonthlyAvailability}}"><span>{{item.avgMonthlyAvailability | hasValue | twoDecimals}}</span></td>
                        <td title="{{item.avgDailyAvailability}}"><span>{{item.avgDailyAvailability | hasValue | twoDecimals}}</span></td>
                        <td title="{{item.monthlyVendingDeviation}}"><span>{{item.monthlyVendingDeviation | hasValue | twoDecimals}}</span></td>
                        <td title="{{item.monthlyAvailabilityDeviation}}"><span>{{item.monthlyAvailabilityDeviation | hasValue | twoDecimals}}</span></td>
                        <td class="monthly-alarm-condition" title="{{item.monthlyAlarmCondition}}"><span>{{item.monthlyAlarmCondition | hasValue}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    <div class="comment-wrapper">
        <fieldset>
            <legend>Remark</legend>
            <textarea class="comment" [(ngModel)]="selectedMeterBypass.remarks" placeholder="Add a comment.."></textarea>
    </fieldset>
    </div>
    <div class="button-wrapper">
        <button class="cancel-btn" (click)="cancel()">Cancel</button> 
        <button class="cancel-btn submit" (click)="saveComment()">Save</button>
    </div>

    <div class="red-line red-line-bottom">

    </div>
  </div>
</div>