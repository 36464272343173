import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DebtBalance } from 'src/app/models/debt-balance';
import { PaymentHistory } from 'src/app/models/payment-history';
import { StatementOfAccountTabs } from 'src/app/models/statement-of-account-tabs.model';
import { StatementOfAccountService } from 'src/app/services/statement-of-account.service';
import { transformToString } from 'src/app/helpers/dateUtils';

@Component({
  selector: 'app-statement-of-account',
  templateUrl: './statement-of-account.component.html',
  styleUrls: ['./statement-of-account.component.scss']
})
export class StatementOfAccountComponent implements OnInit {

  public accNumber: string = ''
  public accHeaders: string[] = []
  public accData: string[] = []
  
  public paymentHistory: PaymentHistory[] = []
  public page: number = 1
  public pageSize: number = 10
  public count: number = 0
  public cumulativePayment: number = 0
  public debtBalance: DebtBalance = new DebtBalance()

  public debtError: boolean = false
  public historyError: boolean = false

  public dateFrom: any = ''
  public dateTo: any = ''

  public invalidAccount: boolean = false;

  public tabEnum = StatementOfAccountTabs.DebtBalanceAndHistory

  public searchText: string = "";

  public initialScreen: boolean = true

  constructor(
    private statementService: StatementOfAccountService, 
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.setAccountHeaders();
  }

  accountSearch(setMainDate: boolean) {
    if (!this.accNumber) {
      this.invalidAccount = true
      return
    }
    if (setMainDate) {
      this.setMainDate()
    }
    this.paymentHistory = []
    this.cumulativePayment = 0
    this.debtBalance = new DebtBalance()
    this.accData = []

    let obj = {
      pageInfo:{
        page: this.page,
        pageSize: this.pageSize
      },
      filterParams: {
        accountNo: this.accNumber,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo)
      }
    };
    if(this.accNumber != null) {
      this.getCustomerDetails(obj)
    }
    else {
      this.tabEnum = StatementOfAccountTabs.DebtBalanceAndHistory
      this.invalidAccount = true
    }
  }

  getCustomerDetails(obj: any){
    this.tabEnum = StatementOfAccountTabs.DebtBalanceAndHistory
    this.statementService.getAccountDetailsByAccNumber(obj).subscribe(response =>{
      if(response.status == 200){
        if(response.data !== null){
          this.handleAccountDetails(response.data)
          this.invalidAccount = false
          this.initialScreen = false
        }
        else{
          // this.toastr.info(`Account with account number ${this.accNumber} does not exist`)
          this.invalidAccount = true
        }

      }
      else{
        this.toastr.error(response.message)
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

  handleAccountDetails(response: any){
    let data = response.details
    this.paymentHistory = response.history

    this.accData = []
    this.accData.push(data.name)
    this.accData.push(data.accNumber)
    this.accData.push(data.street)
    this.accData.push(data.tarrif)
    this.accData.push(data.utName)
    this.accData.push(data.buName)
    this.accData.push(data.feederName)
    this.accData.push(data.dtName)

    this.paymentHistory = response.history.data
    this.count = response.history.count
    this.cumulativePayment = response.cumulativePayment
    this.debtBalance = response.debtBallance

    if(response.history.count === 0){
      this.historyError = true
    }
    else{
      this.debtError = false
    }

    if(this.debtBalance.actualDebt === null &&
      this.debtBalance.debtAfterAdjustment === null &&
      this.debtBalance.outstandingBalance === null){
        this.debtError = true;
      }
    else{
      this.debtError = false
    }
  }

  setAccountHeaders(){
    this.accHeaders = [
      'Customer Name',
      'Account Number',
      'Customer Address',
      'Tarrif',
      'Undertaking',
      'Business Unit',
      'Feeder name',
      'DT Name'
    ]
  }

  downloadReport(){  
    let obj = {
      pageInfo:{
        page: this.page,
        pageSize: this.pageSize
      },
      filterParams: {
        accountNo: this.accNumber,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo)
      }
    };
    if(this.accNumber != null) {
      this.statementService.downloadPdf(obj).subscribe(response => {
        if(response.status == 200){
          response.data.forEach((_x: string) => {
            window.open(_x, '_blank');
          })
        }else
          this.toastr.error(response.message);
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
    
  }

  downloadAndSendReport(email: string){
    let obj = {
      pageInfo:{
        page: this.page,
        pageSize: this.pageSize
      },
      filterParams: {
        accountNo: this.accNumber,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        email: email
      }
    };
    this.downloadAndSend(obj);
  }

  downloadAndSend(obj: any){  
    this.statementService.downloadAndSendPdf(obj).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);      
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  inputChanged(searchText: string) {
    this.accNumber = searchText
    this.invalidAccount = false;
  }

  updateDate(date: any) {
    this.dateFrom = date.dateFrom
    this.dateTo = date.dateTo
    this.accountSearch(false)
  }

  setMainDate() {
    let date = new Date()
    this.dateTo = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}
    date.setMonth(date.getMonth() - 1)
    this.dateFrom = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}

    if (this.dateTo["day"] != this.dateFrom["day"]) {
      this.dateFrom['day'] = 1
    }
  }

  backToInitalScreen() {
    this.initialScreen = true
    this.accNumber = ''
  }

  tabChanged(tab: number) {
    if (tab == 1) {
      this.tabEnum = StatementOfAccountTabs.DebtBalanceAndHistory
    } else {
      this.tabEnum = StatementOfAccountTabs.AccountDetails
    }
  }

}
