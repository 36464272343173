<div class="dialog-content-root">
    <div class="header">
        <img alt="" src="assets/map/ic_dt_dialog.png"/>
        <div>Distribution Transformer</div>
    </div>
    <div class="title" title="{{dtData.name}}">{{dtData.name}}</div>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-sm-6 table-col">
                <div class="label">Address</div>
                <div class="value">{{dtData.address}}</div>
            </div>
            <div class="col-sm-6 table-col">
                <div class="label">DT ID</div>
                <div class="value">{{dtData.id}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 table-col">
                <div class="label">{{utName}}</div>
                <div class="value">{{dtData.area}}</div>
            </div>
            <div class="col-sm-6 table-col">
                <div class="label">Last seen</div>
                <div class="value">{{dtData.lastSeen | dateTimeFormat}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 table-col">
                <div class="label">Losses</div>
                <div class="value">{{dtData.losses}}</div>
            </div>
            <div class="col-sm-6 table-col">
                <div class="label">Conn. 11KV Feeder</div>
                <div class="value">{{dtData.feeder11Name}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 table-col">
                <div class="label">{{buName}}</div>
                <div class="value">{{dtData.region}}</div>
            </div>
            <div class="col-sm-6 table-col">
                <div class="label">Conn. customers</div>
                <div class="value">{{dtData.customersCount}}</div>
            </div>
        </div>
    </div>
</div>
