<div class="popup-overlay" [@slideInOut]="animationState">
  <div class="slider">
    <div class="btn-close-wrapper">
      <div (click)="stayLoggedIn()">
          <img alt="Cancel" src="../../../../assets/images-new-design/cancel.png">
      </div>
    </div>
    <div class="content-wrapper">
      <div class="header">
        You have been dormant for too long
      </div>
      <div class="logout-timer">
        You will be logged out in : {{countdown ?? 30}}
      </div>
    </div>
    <div class="buttons-holder">
      <button class="btn btn-outline" (click)="stayLoggedIn()">Stay logged in</button>
      <button class="btn" (click)="logout()">Log out</button>
    </div>
  </div>
</div>
