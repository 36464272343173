import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-non-communicating-f11',
  templateUrl: './non-communicating-f11.component.html',
  styleUrl: './non-communicating-f11.component.scss'
})
export class NonCommunicatingF11Component implements OnInit{

  @Input() categoryId: number = -1
  @Input() buIds: number[] = []
  public pageSize: number = 10
  public currentPage: number = 1
  public lastPageSize: number = 1;

  public data: any[] = []
  count: number = 0
  public selectedF11: any = [];

  public checkedList: any[] = []
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()
  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase();

  public masterSelected: boolean = false;
  constructor(
    private f11Service: Feeder11Service,
    private toastr: ToastrService,
    private translationService:TranslationService,
    private detectionChange: ChangeDetectorRef
  ) { }
ngOnInit(): void {
    // this.GetData()
}

ngOnChanges(changes: SimpleChanges) {
  let reloadTable = false

  if (
    changes.buIds !== undefined ||
    changes.f11Ids !== undefined ||
    changes.categoryId !== undefined ||
    changes.searchText !== undefined || changes.currentPage !== undefined
  ) {
    reloadTable = true;
  }
  if (this.searchText != null) {
    reloadTable = true
  }

  if (changes.menu !== undefined || changes.tabMenu !== undefined) {
    reloadTable = true;
    this.resetFilters();
  }

  if (reloadTable) {
    this.pageChange(1)
  }

}

resetFilters() {
  this.categoryId = 0;
  this.buIds = []

  this.detectionChange.detectChanges()
}
download() {
  let obj = {
    pageInfo: {
      page: 0,
      pageSize: this.pageSize
    },
    filterParams: {
      BuIds: this.buIds,
      Search: this.searchText.name,
      outageIds: this.checkedList,
    }
  };

  this.f11Service.downloadNonCommunicatingReport(obj).subscribe(response => {
    if(response.status === 200){
      window.location.href = response.data;
    }else if(response.data===null){
      this.toastr.info(response.message)
    }
  }, _ => {
    this.toastr.error('An error occurred.');
  })
}
public GetData() {
  let obj = {
    pageInfo: {
      page: this.currentPage,
      pageSize: this.pageSize
    },
    filterParams: {
      BuIds: this.buIds,
      Search: this.searchText.name,
    }
  };

  this.getNonCommunicatingF11(obj);
}
public pageChange(page: number) {
  this.currentPage = page;
  this.GetData()
}  //For Checkboxes on table

isAlreadyChecked() {
  this.data.forEach(x => {
    let index = this.checkedList.indexOf(x.id)
    if(index !== -1) {
      x.checked = true;
    }
  })

  this.isMasterChecked();
}
checkUncheckAll() {
  for (let i = 0; i < this.data.length; i++) {
    this.data[i].checked = this.masterSelected;
    this.checkForDownload(this.data[i].id, true);
  }
}
getCheckedItemList() {
  this.checkedList = [];
  for (let i = 0; i < this.data.length; i++) {
    if (this.data[i].checked)
      this.checkedList.push(this.data[i]);
  }
}

isAllSelected() {
  this.masterSelected = this.data.every(function (item: any) {
    return item.checked == true;
  })
  this.getCheckedItemList();
}
checkForDownload(id: number, isMasterClicked: boolean = false) {
  if(!isMasterClicked)
    this.isMasterChecked();
  let index = this.checkedList.indexOf(id);
  if(index !== -1){
    if(!isMasterClicked)
      this.checkedList.splice(index, 1);
    else if(isMasterClicked && !this.masterSelected)
      this.checkedList.splice(index, 1);
  }
  else {
    this.checkedList.push(id);
  }
}

isMasterChecked() {
  this.masterSelected = this.data.every(function(item:any) {
    return item.checked == true;
  })
}
private getNonCommunicatingF11(obj: any) {
  this.f11Service.getNonCommunicatingF11(obj).subscribe(response => {
    if (response.status == 200) {
      this.data = response.data.data
      this.isAlreadyChecked()
      if (obj.pageInfo.page == 1)
        this.count = response.data.count
      if (this.count == 0)
        this.count++;
      this.lastPageSize = Math.ceil(this.count / this.pageSize)
    }
    else {
      this.toastr.error(response.message)
    }
  }, _ => {
    this.toastr.error("An error occurred")
  });
}
}
