import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { DtService } from 'src/app/services/dt.service';
import { TranslationService } from 'src/app/services/translation.service';
import { PaginationComponent } from '../../common-new-design/pagination/pagination.component';
import { MenuItem } from '../model/model';
import { DtOutagePopupComponent } from './dt-outage-popup/dt-outage-popup.component';

@Component({
  selector: 'app-outage-dt-new',
  templateUrl: './outage-dt.component.html',
  styleUrls: ['./outage-dt.component.scss']
})
export class OutageDtNewComponent implements OnChanges {

  @ViewChild(PaginationComponent) paginationComponentRef!: PaginationComponent;


  @Input() buIds: number[] = []
  @Input() f11Ids: number[] = []
  @Input() categoryId: number = -1
  @Input() durationFrom: number = 0
  @Input() durationTo?: number = undefined

  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''

  public menuItemEnum = MenuItem
  public pageSize: number = 10
  @Input() currentPage: number = 1
  public lastPageSize: number = 1;
  public lastMenu: MenuItem = MenuItem.CurrentOutage;
  public selectedDt: any = null;

  public data: any[] = []
  count: number = 0

  public checkedList: number[] = []
  public accountIds: number[] = []

  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase()
  @Input() menu!: MenuItem;
  @Input() tabMenu!: number;
  @Output() redirect: EventEmitter<any> = new EventEmitter()
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()
  @ViewChild(DtOutagePopupComponent) popupRef!: DtOutagePopupComponent;

  public masterSelected: boolean = false;
  constructor(
    private dtService: DtService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private detectionChange: ChangeDetectorRef
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false

    if (
      changes.buIds !== undefined ||
      changes.f11Ids !== undefined ||
      changes.categoryId !== undefined ||
      changes.dateFrom !== undefined || changes.dateTo !== undefined ||
      changes.durationFrom !== undefined || changes.durationTo !== undefined ||
      changes.searchText !== undefined || changes.currentPage !== undefined
    ) {
      reloadTable = true;
    }
    if (this.searchText != null) {
      reloadTable = true
    }

    if (changes.menu !== undefined || changes.tabMenu !== undefined) {
      reloadTable = true;
      this.resetFilters();
    }

    if (reloadTable) {
      this.checkedList = [];
      this.pageChange(1)
    }

  }

  public GetData() {
    let isCurrentOutage = false
    if (this.menu === MenuItem.CurrentOutage) {
      isCurrentOutage = true;
    }
    let obj=null;
    if(isCurrentOutage){
      obj = {
        pageInfo: {
          page: this.currentPage,
          pageSize: this.pageSize
        },

        filterParams: {
          //From: transformToString(this.dateFrom),
          //To: transformToString(this.dateTo),
          MinOutageDuration: this.durationFrom,
          MaxOutageDuration: this.durationTo,
          OutageCategory: this.categoryId,
          BuIds: this.buIds,
          Feeder11Ids: this.f11Ids,
          Search: this.searchText ? this.searchText.name : this.searchText,
          IsCurrentOutage: isCurrentOutage
        }

    }


    }
    else{

      obj = {
        pageInfo: {
          page: this.currentPage,
          pageSize: this.pageSize
        },

        filterParams: {
          From: transformToString(this.dateFrom),
          To: transformToString(this.dateTo),
          MinOutageDuration: this.durationFrom,
          MaxOutageDuration: this.durationTo,
          OutageCategory: this.categoryId,
          BuIds: this.buIds,
          Feeder11Ids: this.f11Ids,
          Search: this.searchText ? this.searchText.name : this.searchText,
          IsCurrentOutage: isCurrentOutage
        }

    }




    }

    this.GetOutagesForDt(obj);

  }

  private GetOutagesForDt(obj: any) {
    this.dtService.getFeedersOutages(obj).subscribe(response => {
      if (response.status == 200) {
        this.data = response.data.data
        this.isAlreadyChecked();
        if (obj.pageInfo.page == 1)
          this.count = response.data.count
        this.lastPageSize = Math.ceil(this.count / this.pageSize)
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An error occurred")
    });
  }

  public download() {
    let isCurrentOutage = false
    if (this.menu === MenuItem.CurrentOutage) {
      isCurrentOutage = true;
    }

    this.accountIds = []
    let obj = {
      pageInfo: {
        page: 0,
        pageSize: this.pageSize
      },
      filterParams: {
        From: transformToString(this.dateFrom),
        To: transformToString(this.dateTo),
        MinOutageDuration: this.durationFrom,
        MaxOutageDuration: this.durationTo,
        OutageCategory: this.categoryId,
        BuIds: this.buIds,
        Feeder11Ids: this.f11Ids,
        Search: this.searchText ? this.searchText.name : this.searchText,
        outageIds: this.checkedList,
        IsCurrentOutage: isCurrentOutage
      }
    };

    this.downloadOutageReport(obj);

  }

  private downloadOutageReport(obj: any) {
    this.dtService.downloadOutages(obj).subscribe(response => {
      if(response == null){
        this.toastr.error('An error occurred.');
      }
      else if(response.status === 200){
        window.location.href = response.data;
      }else if(response.data===null){
        this.toastr.info(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  resetFilters() {
    this.categoryId = 0;
    this.durationFrom = 0
    this.durationTo = undefined
    this.buIds = []
    this.f11Ids = []

    this.detectionChange.detectChanges()
  }

  public pageChange(page: number) {
    this.currentPage = page;
    this.GetData()
  }

  public isPlanned(plan: string) {
    if (plan === 'Planned') {
      return true;
    }
    else return false;
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
      this.checkForDownload(this.data[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.data.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item:any) {
      return item.checked == true;
    })
  }

  viewMore(dt: any) {
    this.openTab(dt)

  }
  openTab(selectedDt: any) {
    this.popupRef.changeState(selectedDt, 1);
  }
  closeTab() {
    this.popupRef.changeState(this.selectedDt, 2);
  }

}
