import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { SwitchModulePopupComponent } from '../switch-module-popup/switch-module-popup.component';


export enum RevenueSidebarItems{
  Dashboard = 1,
  MeterBypass = 2,
  OverdueBillArears = 3,
  PaymentCollections = 4,
  StatementAccount = 5,
  Billing = 6,
  Reports=7,
  ModalSettings = 8,
  GeneralSettings = 9,
  Recovery=10,
  PaymentPlan=11,
  RecoverPaymentPlan=12,
  PostpaidMeterBypass = 13
}

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent {

  public PermissionsEnum = PermissionsEnum;

  public SelectedItem: any = 1;
  @Input() expandSidebar: boolean = false;
  @Output() expandSidebarChange = new EventEmitter();

  @ViewChild(SwitchModulePopupComponent) popupComponentRef!: SwitchModulePopupComponent;


  public customerWatchlistMenu : boolean = false;
  public debtManagementMenu : boolean = false;
  public billingMenu : boolean = false;

  public commercialMenu : boolean = false;


  public allowCustomerWatchlistMenu : boolean = true;
  public allowDebtManagementMenu : boolean = true;
  public allowbillingMenu : boolean = true;

  public allowCommercialMenu : boolean = true;

  public settingsMenu: boolean = false;
  public allowSettingsMenu: boolean = true;

  constructor(public authService: AuthService, public toastr: ToastrService,
    private router: Router) {
      this.router.events
        .subscribe(
          (event) => {
            if(event instanceof ActivationEnd && event.snapshot.children.length == 0) {
              this.SelectedItem = event.snapshot.data['sidebarItem'];
            }
          });
    }


  logout(){
    this.authService.logout();
  }


  handleDropdownMenuClick(name:string, event:any) {
    // If sidebar is open
    // if(this.expandSidebar) {
      if(name === 'customerWatchlist') {
        this.customerWatchlistMenu = !this.customerWatchlistMenu;
      } else if (name = 'debtManagementMenu') {
        this.debtManagementMenu = !this.debtManagementMenu;
      }else if (name = 'billingMenu') {
        this.billingMenu = !this.billingMenu;
      }else if(name = 'commercialMenu') {
        this.commercialMenu=!this.commercialMenu;
      }
      return;

    // } else {
    //   // this.expandSidebar = true;
    //   // this.expand()

    //   if(name === 'customerWatchlist') {
    //     if(this.customerWatchlistMenu) {
    //       this.allowCustomerWatchlistMenu = false;
    //       event.stopPropagation();
    //       return;
    //     } else {
    //       this.customerWatchlistMenu = !this.customerWatchlistMenu;
    //       this.allowCustomerWatchlistMenu = true;
    //     }
    //   } else if (name === 'debtManagement') {
    //     if(this.debtManagementMenu) {
    //       this.allowDebtManagementMenu = false;
    //       event.stopPropagation();
    //       return;
    //     } else {
    //       this.debtManagementMenu = !this.debtManagementMenu;
    //       this.allowDebtManagementMenu = true;
    //     }
    //   }else if (name === 'Billing') {
    //       if(this.billingMenu) {
    //         this.allowbillingMenu = false;
    //         event.stopPropagation();
    //         return;
    //       } else {
    //         this.billingMenu = !this.billingMenu;
    //         this.allowbillingMenu = true;
    //       }
    //   }else if(name === 'Commercial') {
    //     if(this.commercialMenu){
    //       this.allowCommercialMenu = false;
    //       event.stopPropagation();
    //       return
    //     }else{
    //       this.commercialMenu=!this.commercialMenu;
    //       this.allowCommercialMenu = true;
    //     }
    //   }
    // }
  }

  expand(){
    this.expandSidebar = !this.expandSidebar;
    this.expandSidebarChange.emit(this.expandSidebar);
  }

  alert(){
    this.toastr.warning("Not yet implemented");
  }


  redesign(){
    this.toastr.warning("Under redesign process");
  }

  navigateTo(url: string){
    window.location.href = url
  }

  isBillingUser(){
    let perm = localStorage.getItem('rolePermissionAndSystemComponents')
    if(perm == undefined || perm == null || perm == '')
      return false

    return true
  }

  switchModule(){
    this.popupComponentRef.changeState(1);
  }
}
