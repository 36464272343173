import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { PaymentHistory } from 'src/app/models/payment-history';
import { StatementOfAccountService } from 'src/app/services/statement-of-account.service';

@Component({
  selector: 'app-statement-history',
  templateUrl: './statement-history.component.html',
  styleUrls: ['./statement-history.component.scss']
})
export class StatementHistoryComponent{

  @Input() accNumber: string = ''
  @Input() data: PaymentHistory[] = []
  @Input() count: number = 0
  @Input() currentPage: number = 1
  @Input() pageSize: number = 5
  @Input() cumulativePayment: number = 0
  @Input() isInvalidAccount: boolean = false;

  @Input() dateFrom: any = '';
  @Input() dateTo: any = '';

  constructor(private statementService: StatementOfAccountService, private toastr: ToastrService,) { }

  pageChange(value: number){
    this.currentPage = value
    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        accountNo: this.accNumber,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo)
      }
    };

    this.statementService.getAccountPaymentHistory(obj).subscribe(response =>{
      if(response.status == 200){
        this.data = response.data.data
      }else{
        this.toastr.error(response.message)
      }
    }, _ =>{
      this.toastr.error('An error occurred.');
    })
  }

}
