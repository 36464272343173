import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FieldModalData } from 'src/app/models/field-modal-data';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { FieldAgentsService } from 'src/app/services/field-agents.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';
import { WomteamService } from 'src/app/services/womteam.service';
import { WorkOrderManagement } from 'src/app/services/work-order-management.service';

@Component({
  selector: 'app-field-slider',
  templateUrl: './field-slider.component.html',
  styleUrls: ['./field-slider.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class FieldSliderComponent implements OnInit, OnDestroy {

  public buName: string = this.translationService.getByKeyFromCache('BU');

  constructor(
    public workOrderManagement: WorkOrderManagement,
    public toastr: ToastrService,
    public buService: BusinessUnitService,
    public userService: UserService,
    private translationService: TranslationService,
    private womTeamService : WomteamService,
    private fieldAgentService : FieldAgentsService
  ) { }

  @Input('openModal') openModal: number = 0;
  selectedWorkOrder = new FieldModalData;
  @Input() animationState = 'out';
  @Output() reloadTable = new EventEmitter<any>();

  handleNewOrder() {
    this.reloadTable.emit();
  }

  showShadow = false;
  showMoreInfo = false;
  editWorkOrder = false;
  assignWorkOrder = false;
  createWorkOrder = false;

  //Dropdownmenu
  workOrderCategoriesForOptions:[] = [];
  assetTypesForOptions:[] = [];
  assignedTeamsForOptions:[] = [];
  buForOptions:[] = [];
  womTeams:[] = []
  womTeamAgents:[] = []

  //Selected options
  selectedworkOrderCategory:any = '';
  selectedAssetType: any = '';
  selectedAssetTypeId: any = '';
  
  selectedBusinessUnit: any = '';
  selectedBuId: any = '';
  selectedAssignedTeam: any = '';
  selectedAssignedTeamId : any = '';
  selectedAssignedStaff: any = '';
  selectedAssignedStaffId: any = '';

  //Create Work Order input fields
  workOrderId:string|number = '';
  summary: string = '';
  description: string = '';
  businessUnit: string = '';
  reporter: string = '';

  optionSelected(e:any, name:string) {
    switch (name) {
      case 'workOrderCategory':
        this.selectedworkOrderCategory = e.option.name;
        break;

      case 'assetType':
        this.selectedAssetType = e.option.name;
        this.selectedAssetTypeId = e.option.value;
        break;

      case 'businessUnit':
        this.selectedBusinessUnit = e.option.name;
        this.selectedBuId = e.option.value;
        break;

      case 'assignedTeam':
        this.selectedAssignedTeam = e.option.name;
        this.selectedAssignedTeamId = e.option.id;
        this.getAgentsByTeam(e.option.id);
        this.selectedAssignedStaff = '';
        this.selectedAssignedStaffId = '';
        break;

      case 'assignedStaff':
        this.selectedAssignedStaff = e.option.name;
        this.selectedAssignedStaffId = e.option.id;
        break;
    }
  }

  ngOnInit(): void {
    // document.body.addEventListener('click', this.handleClickOutside.bind(this));
  }
  ngOnDestroy(): void {
    // document.body.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  // handleClickOutside(event: Event): void {
  //   const target = event.target as HTMLElement;
  //   const sliderElement = document.querySelector('.slider') as HTMLElement;
    
  //   if(!sliderElement.contains(target)){
  //     this.toggleShowDiv('close');
  //     this.showShadow = false;
  //     this.showMoreInfo = false;
  //     this.editWorkOrder = false;
  //     this.assignWorkOrder = false;
  //   }
  // }

  incidentAgingCalculation(dateAssigned: string, dateRemoved: string, status: string) {
    const now = new Date();
    const assignedDate = new Date(dateAssigned);
    const removedDate = new Date(dateRemoved);

    if(status === 'Closed'){
      return(removedDate.getTime() - assignedDate.getTime()) / (1000 * 60 * 60);
    } else if(status === 'Assigned'){
      return(now.getTime() - assignedDate.getTime()) / (1000 * 60 * 60);
    }
    return 0;
  }

  getAgentsByTeam(teamId: number){
    this.fieldAgentService.getAllByTeam(teamId).subscribe(response => {
      this.womTeamAgents = response.map((obj: any) => ({
        name: obj.email,
        id: obj.id,
      }));
      
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }


  getBuForOptions() {
    this.buService.getAllForSelect().subscribe(response => {
      this.buForOptions = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }


  getAssetTypesForOptions() {
    this.workOrderManagement.getAssetTypesForOptions().subscribe(response => {
      this.assetTypesForOptions = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getWorkOrderCategoriesForOptions() {
    this.workOrderManagement.getWorkOrderCategoriesForOptions().subscribe(response => {
      this.workOrderCategoriesForOptions = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getAllWomTeamsForSelect( ){
    this.womTeamService.getAllForSelect().subscribe(response => {
      if(response.status === 200) {
        this.womTeams = response.data;        
      } else {
        this.toastr.error(response.message)
      }
    
    }, error => {
      this.toastr.error('An error occured');
    });
  }
  changeState(name: string, data: FieldModalData){
    this.selectedWorkOrder = data;
    switch (name) {
      case 'showMoreInfo' :
        this.getAgentsByTeam(Number.parseInt(this.selectedWorkOrder.assignedTeamId));
        // this.selectedAssignedStaffId = data.assignedUserId;

        this.toggleShowDiv("open");
        this.showShadow = true;
        this.showMoreInfo = true;
        break;

      case 'createWorkOrder' :
        this.toggleShowDiv("open");
        this.showShadow = true;
        this.createWorkOrder = true;
        this.getWorkOrderCategoriesForOptions();
        this.getAssetTypesForOptions();
        this.getBuForOptions();
        this.getAllWomTeamsForSelect();
        
        break;

      case 'closeModal' :
        this.toggleShowDiv("close");
        this.showShadow = false;
        this.showMoreInfo = false;
        this.editWorkOrder = false;
        this.assignWorkOrder = false;
        this.createWorkOrder = false;
        break;

      default:
        break;
    }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = this.animationState = 'in';
    } else if (divName === 'close') {
      this.animationState = this.animationState = 'out';
    }
    this.reset();
  }

  handleButtonClick(name:string) : void {
    switch (name) {
      case 'editWorkOrder' :
        
        this.showMoreInfo = false;
        this.editWorkOrder = true;
        this.getAssetTypesForOptions();
        this.getAllWomTeamsForSelect();
        this.getBuForOptions();

        this.workOrderId = this.selectedWorkOrder.workOrderId;
        this.summary = this.selectedWorkOrder.summary;
        this.reporter = this.selectedWorkOrder.reporter;
        this.description = this.selectedWorkOrder.description;
        this.selectedAssetType = this.selectedWorkOrder.assetType;
        this.selectedAssetTypeId = this.selectedWorkOrder.assetTypeId;
        this.selectedBusinessUnit = this.selectedWorkOrder.buName;
        this.selectedBuId = this.selectedWorkOrder.buId;
        this.selectedAssignedTeam =  this.selectedWorkOrder.assignedTeam;
        this.selectedAssignedTeamId =  this.selectedWorkOrder.assignedTeamId;
        this.selectedAssignedStaff = this.selectedWorkOrder.assignedUser;
        this.selectedAssignedStaffId = this.selectedWorkOrder.assignedUserId;
        this.selectedworkOrderCategory = this.selectedWorkOrder.category;
        if(this.selectedWorkOrder.images.length !== 0) {
          this.readyFiles = this.selectedWorkOrder.images.map((img, i) => ({'id': i, 'imgURL': img}))
        }
        break;
      
      case 'assignWorkOrder' :
        this.showMoreInfo = false;
        this.assignWorkOrder = true;
        this.getAllWomTeamsForSelect();
        this.getAgentsByTeam(Number.parseInt(this.selectedWorkOrder.assignedTeamId));
        this.selectedAssignedTeam =  this.selectedWorkOrder.assignedTeam;
        this.selectedAssignedTeamId =  this.selectedWorkOrder.assignedTeamId;

        this.selectedAssignedStaff = this.selectedWorkOrder.assignedUser;
        this.selectedAssignedStaffId = this.selectedWorkOrder.assignedUserId;

        break;
    }
  }

  handleCreateWorkOrder(id:any = 0):void {
    let images = [];
    if(this.readyFiles.length !== 0) {
      images = this.readyFiles.map(img => img.imgURL)
    }
    let obj = {
      workOrderId: this.workOrderId,
      category: this.selectedworkOrderCategory,
      summary: this.summary,
      description: this.description,
      assetType: this.selectedAssetTypeId,
      buId: this.selectedBuId,
      reporter: this.reporter,
      assignedTeam: this.selectedAssignedTeam,
      assignedTeamId: this.selectedAssignedTeamId,
      assignedUserId: this.selectedAssignedStaffId,
      images: images,
      id: id
    }
    if(this.selectedworkOrderCategory === ""){
      this.toastr.warning("Category is required!")
      return;
    }
    if(this.summary === ""){
      this.toastr.warning("Summary is required!")
      return;
    }
    if(this.description === ""){
      this.toastr.warning("Description is required!")
      return;
    }
    if(this.selectedAssetType === ""){
      this.toastr.warning("Asset type is required!")
      return;
    }
    if(this.selectedBusinessUnit === ""){
      this.toastr.warning("Business unit is required!")
      return;
    }
    if(this.selectedAssignedTeam === ""){
      this.toastr.warning("Assigned team is required!")
      return;
    }
    if(this.selectedAssignedStaff === ""){
      this.toastr.warning("Assigned staff is required!");
      return;
    }
    
    this.workOrderManagement.save(obj).subscribe(response => {
      if(response.status === 200 || response.status === 201) {
        this.toggleShowDiv('close');
        this.reset();
        this.toastr.success(response.message);
        this.handleNewOrder();
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
    
  }

  handleAssignWorkOrder(id:any):void {
    let obj = {
      workOrderId: id,
      assignedUserId: this.selectedAssignedStaffId,
      assignedTeam: this.selectedAssignedTeam,
      assignedTeamId: this.selectedAssignedTeamId
    }
    this.workOrderManagement.assignWorkOrder(obj).subscribe(response => {
      if(response.status === 200 || response.status === 201) {
        this.toggleShowDiv('close');
        this.reset();
        this.toastr.success("Successfully Assign Work Order");
        this.handleNewOrder();
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  //Image Upload
  message : string = '';
  readyFiles :any[] = [];
  preview(files:any) {
    if (files.length === 0)
      return;

    for(let i = 0; i < files.length; i++) {
      const mimeType = files[i].type;
      if (mimeType.match(/image\/*/) == null) {
        this.message = "Only images are supported.";
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(files[i]); 
      reader.onload = (e) => { 
        this.readyFiles.push({id: i, imgURL: e.target?.result});
      }
    }
  }
  handleRemove(event:any, id:number) {
    event.preventDefault();
    this.readyFiles = this.readyFiles.filter(file => file.id !== id);
  }


  // Clear / Reset all fields 
  reset() {
    // Files
    this.readyFiles = [];
    //Selected dropdown options
    this.selectedworkOrderCategory = '';
    this.selectedAssetType = '';
    this.selectedAssetTypeId = '';
    this.selectedBusinessUnit = '';
    this.selectedAssignedTeam = '';
    this.selectedAssignedStaff = '';
    //Inputs
    this.workOrderId = '';
    this.summary = '';
    this.description = '';
    this.businessUnit = '';
    this.reporter = '';

    this.showShadow = false;
    this.showMoreInfo = false;
    this.editWorkOrder = false;
    this.assignWorkOrder = false;
    this.createWorkOrder = false;
  }
}
