<div><app-create-user [roles]="roles" [regions]="regions" (parentComp)="reloadTable($event)"></app-create-user></div>
<main>
    <div class="user-profile">

        <div class="user-settings-content">

            <div class="first">
                <div class="title-container">
                    <div class="title-u">
                        Users Management
                    </div>
                    <div class="subtitle">
                        Create, read, update or delete Users
                    </div>
                </div>

                <div class="btn">
                    <button class="red-btn" (click)="openTab(0)">
                        Create User
                    </button>
                </div>

            </div>
            <div class="filters-container">
                <div>
                    <app-search-autocomplete
                    #search
                    [placeholder]="searchPlaceholder"
                    [searchText]="searchText"
                    [options]="options"
                    [width]="20"
                    (searchChange)="inputChanged($event)"
                    (searchAutocompleteChange)="getOptions($event)"
                ></app-search-autocomplete>
                </div>
                <div class="download-button">
                    <app-download-button-new
                      [isOnlyDownload]="true"
                      (downloadButtonClickedChange)="download()">
                    </app-download-button-new>
                  </div>
            </div>
            <table class="standard-table">
                <thead>
                    <tr>
                        <th><span>First Name</span></th>
                        <th><span>Last Name</span></th>
                        <th><span>Email</span></th>
                        <th><span>Phone Number</span></th>
                        <th><span>Sign Up Date</span></th>
                        <th><span>Created By</span></th>

                        <th class="actions-th"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of users" class="bodyRow clickable actions-click"
                        [ngClass]="{'selected': selectedUser.userGuid===item.userGuid}">

                        <td class="large-td">
                            <span *ngIf="item.firstName  !== null" title="{{item.firstName }}">{{item.firstName  | hasValue}}</span>
                            <span *ngIf="item.firstName  === null" class="NaN">N/A</span>
                        </td>
                        <td class="large-td">
                            <span *ngIf="item.lastName !== null" title="{{item.lastName}}">{{item.lastName | hasValue}}</span>
                            <span *ngIf="item.lastName === null" class="NaN">N/A</span>
                        </td>
                        <td class="large-td">
                            <span *ngIf="item.email !== null" title="{{item.email}}">{{item.email | hasValue}}</span>
                            <span *ngIf="item.email === null" class="NaN">N/A</span>
                        </td>
                        <td class="large-td">
                            <span *ngIf="item.phoneNumber !== null" title="{{item.phoneNumber}}">{{item.phoneNumber | hasValue}}</span>
                            <span *ngIf="item.phoneNumber === null" class="NaN">N/A</span>
                        </td>
                        <td class="large-td">
                          <span *ngIf="item.dateCreated !== null" title="{{item.dateCreated}}">{{item.dateCreated | dateTimeFormat}}</span>
                          <span *ngIf="item.dateCreated === null" class="NaN">N/A</span>
                        </td>
                        <td class="large-td">
                          <span *ngIf="item.createdBy !== null" title="{{item.createdBy}}">{{item.createdBy | hasValue}}</span>
                          <span *ngIf="item.createdBy === null" class="NaN">N/A</span>
                        </td>
                        <td>
                            <span class="settings-actions"><img class="" alt="Upsert" src="assets/images-new-design/new_settings_pen.svg" data-toggle="modal"
                                    data-target="#createModal"
                                    *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_user_createupdate)"
                                    (click)="selectUser(item.userGuid)" /> </span>
                            <span class="settings-actions"><img class="" alt="Delete" src="assets/images-new-design/new_settings_delete.svg" data-toggle="modal"
                                    data-target="#deleteModal"
                                    *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_user_delete)"
                                    (click)="openTabDelete(item.userGuid)" /> </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-wrapper">
                <app-pagination [pageSize]="pageSize" [currentPage]="currentPage" [count]="count" (pageChange)="pageChange($event)">
                </app-pagination>
            </div>
        </div>
    </div>

</main>
<app-crud-delete-modal [selectedItem]="selectedUser" (confirmDelete)="onDeleteConfirmed()"
    (cancelDelete)="resetSelectedUser()">
</app-crud-delete-modal>
