import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Feeder33 } from 'src/app/models/feeder33.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { TranslationService } from 'src/app/services/translation.service';
import { Feeder33CreateComponent } from './feeder33-create/feeder33-create.component';
import { MeterService } from 'src/app/services/meter.service';
import { CommonService } from 'src/app/services/common-service.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { AmiService } from 'src/app/services/ami.service';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { CrudDeleteModalComponent } from '../../crud-delete-modal/crud-delete-modal.component';
import { CrudService } from 'src/app/services/crudService.service';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';

@Component({
  selector: 'app-feeder33-tab',
  templateUrl: './feeder33-tab.component.html',
  styleUrls: ['./feeder33-tab.component.scss']
})
export class Feeder33TabComponent implements OnInit {
  @ViewChild(CrudDeleteModalComponent) crudDeleteRef!: CrudDeleteModalComponent;

  public selectedId: number = 0;

  public PermissionsEnum = PermissionsEnum;

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public searchByName: string = '';
  public searchByTS: number = 0;
  public searchByMeterNumber: string = '';
  public searchByAmi: number = 0;

  public isNameplateRatingValid: boolean = true;

  private ngUnsubscribe = new Subject<void>();
  private searchText: string = "";

  public feeders33: Feeder33[] = [];
  public selectedFeeder33: Feeder33 = new Feeder33();
  public transmissionStations: any = [];
  public amis: any = [];
  public feeder33MeterHistory: any = [];
  public msnValid: boolean = false;

  public fileChosen: any = null;
  public file: any = null;

  public tsIdInvalid: boolean = false;
  public amiIdInvalid: boolean = false;

  public masterSelected: boolean = false;
  public checkedList: any = [];
  public searchF33: SearchAutocomplete[] = [];

  public amiName: string = this.translationService.getByKeyFromCache('AMI');
  @ViewChild(Feeder33CreateComponent) popupComponentRef!: Feeder33CreateComponent;
  @ViewChild("f") f: any;

  @Output() editTab = new EventEmitter();
  @Output() tsSelect = new EventEmitter();
  @Output() amisSelect = new EventEmitter();
  @Output() deleteComp = new EventEmitter();

  constructor(private feeder33Service: Feeder33Service,
    private translationService: TranslationService,
    private toastr: ToastrService,
    public authService: AuthService,
    private meterService: MeterService,
    private crudService: CrudService,
    private commonService: CommonService,
    private tsService: TransmissionStationService,
    private amiService: AmiService,
    private location: Location,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.reloadTable();
    this.getAllTransmissionStationsForSelect();
    this.getAllAmisForSelect();
    this.subscribeToSearch();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  openTab() {
    this.popupComponentRef.changeState(1, this.selectedFeeder33);
  }

  getAllTransmissionStationsForSelect() {
    this.tsService.getAllForSelect().subscribe(response => {
      this.transmissionStations = response.data;
      this.tsSelect.emit(this.transmissionStations);
    }, _ => {
      this.toastr.error('An error occurred.');
    });
  }

  getAllAmisForSelect() {
    this.amiService.getAllForSelect().subscribe(response => {
      this.amis = response.data;
      this.amisSelect.emit(this.amis);
    }, _ => {
      this.toastr.error('An error occured');
    })
  }

  reloadTable(page: any = null) {
    console.log("reloadTable searchText",this.searchText)
    if (page)
      this.currentPage = page;

    this.resetSelectedFeeder33();

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText
      }
    };
    console.log("sear",this.searchF33)
    if (!obj.filterParams.SearchContent){
      this.feeder33Service.getAllForSelect().subscribe(response => {

        if (!obj.filterParams.SearchContent){
          this.searchF33 = []
          response.data.forEach((x : Feeder33) =>
          {
            let item = new SearchAutocomplete();
            item.value = x.id;
            item.name = x.name;
            this.searchF33.push(item)
          })
        }
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
    this.feeder33Service.getAll(obj).subscribe(response => {
      this.feeders33 = response.data.data;
      console.log(this.feeders33)
      if (obj.pageInfo.page == 1) {
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize);
      }
      this.isAlreadyChecked();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  selectFeeder33(id: number) {
    this.feeder33Service.getFeederFullInfo(id).subscribe(([feederResponse, historyResponse]) => {
      this.handleFeederResponse(feederResponse);
      this.handleFeederHistoryResponse(historyResponse);
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  isMeteredValidate() {
    if (this.selectedFeeder33.meterType === 2) {
      this.selectedFeeder33.meterNumber = '';
      this.selectedFeeder33.amiId = 0;
    }
  }

  handleFeederResponse(feederResponse: any) {
    if (feederResponse.status === 200) {
      this.selectedFeeder33 = feederResponse.data;
      this.editTab.emit(this.selectedFeeder33);
    } else {
      this.toastr.error(feederResponse.message);
    }
  }

  handleFeederHistoryResponse(historyResponse: any) {
    if (historyResponse.status === 200) {
      this.feeder33MeterHistory = historyResponse.data;
    } else {
      this.toastr.error(historyResponse.message);
    }
  }

  resetSelectedFeeder33() {
    this.selectedFeeder33 = new Feeder33();
    this.feeder33MeterHistory = [];

    if (this.f)
      this.f.submitted = false;
  }

  openTabDelete(id: number) {
    this.feeder33Service.get(id).subscribe(response => {
      if (response.status === 200) {
        this.selectedFeeder33 = response.data;
        this.deleteComp.emit();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.selectedId = id;
  }

  onDeleteConfirmed() {
    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_delete)) {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.feeder33Service.delete(this.selectedId).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.currentPage = 1;
        this.reloadTable();
        this.resetSelectedFeeder33();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  dropBoxValid(): boolean {
    this.tsIdInvalid = false;

    if (this.selectedFeeder33.tsId === 0) {
      this.tsIdInvalid = true;
    }

    if (this.tsIdInvalid) {
      return false;
    }
    return true;
  }

  onFileChosen(event: any) {
    this.file = event.target.files[0];
  }

  isFileChosen() {
    if (this.fileChosen)
      return true;

    return false;
  }

  uploadFile() {
    if (!this.isFileChosen) {
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.feeder33Service.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.result.data;
      }
      this.reloadTable();
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  getTemplate() {
    this.feeder33Service.getTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;

      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  public subscribeToSearch() {
    this.crudService.globalSearch$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(searchValue => {
        if (searchValue.clicked && searchValue.componentName === ComponentNamesNew.Feeder33Component) {
          this.searchText = searchValue.text;
          this.currentPage = 1;
          this.checkedList = [];
          this.reloadTable();
          return;
        }
        this.searchText = '';
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkUncheckAll() {
    for (let i = 0; i < this.feeders33.length; i++) {
      this.feeders33[i].checked = this.masterSelected;
      this.checkForDownload(this.feeders33[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.feeders33.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.feeders33.every(function(item:any) {
      return item.checked == true;
    })
  }

  downloadItems() {
    let obj = {
      Ids: this.checkedList,
      activate: false
    };
    this.feeder33Service.downloadCrud(obj).subscribe(response => {
      if (response.status === 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.")
        }
        else {
          window.location.href = response.data
          this.toastr.success("Successfully downloaded feeders.");

        }
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
