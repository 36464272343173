import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { DtAssetPerfomance } from 'src/app/models/dt-asset-perfomance.model';
import { Feeder11AssetPerfomance } from 'src/app/models/f11-asset-performance.model';
import { Feeder33AssetPerfomance } from 'src/app/models/f33-asset-performance.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AssetPerformanceService } from 'src/app/services/asset-performance.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';

@Component({
  selector: 'app-asset-performance-new',
  templateUrl: './asset-performance-new.component.html',
  styleUrls: ['./asset-performance-new.component.scss']
})
export class AssetPerformanceNewComponent implements OnInit {
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;

  public tabMenu: number = 1;
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public dateFrom: any = ''
  public dateTo: any = ''
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public dtName: string = this.translationService.getByKeyFromCache('DT');
  public feederName: string = "Feeders"

  public allFilters: any = []
  public businessUnits: any = { name: this.buName, data: [], dropdown: true};
  public undertakings: any = { name: this.utName, data: [] };
  public transmissionStations: any = { name: this.utName, data: [] };
  public allFeederNames: any = { name: this.feederName, data: [] };
  public filterApplied: boolean = false;
  public showFilter: any = false
  public buId: number = 0
  public utIds: any = [];
  public tsId:number=0;
  public feederNames: any = [];
  public searchPlaceholder: string = "Search by DT name or number"

  options = ['']

  public currentPage: number = 1;
  public pageSize: number = 10;
  public lastPageSize: number = 1;
  public count: number = 0;
  public checkedList: number[] = [];
  public assetPerformanceDts: DtAssetPerfomance[] = []
  public assetPerformanceF11: Feeder11AssetPerfomance[] = [];
  public assetPerformanceF33: Feeder33AssetPerfomance[] = []
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  constructor(
    private toastr: ToastrService,
    private assetPerformanceDtService: AssetPerformanceService,
    private assetPerformanceF11Service: AssetPerformanceService,
    private assetPerformanceF33Service: AssetPerformanceService,
    private buService: BusinessUnitService,
    private undertakingService: UndertakingService,
    private transmissionService: TransmissionStationService,
    private translationService: TranslationService,
    public dtService: DtService,
    public feederService: Feeder11Service,
    public feeder33Service: Feeder33Service
  ) { }

  ngOnInit(): void {
    this.setMainDate();
    this.getBusForSelect();
    this.getTSForSelect()
    this.getAllFeeders();
    this.reloadTable();
  }

  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: false
    }
    if (searchText.name.length > 2) {
      if (this.tabMenu == 3) {
        this.dtService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      } else if (this.tabMenu == 2) {
        this.feederService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }else if (this.tabMenu == 1) {
        this.feeder33Service.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
    } else
      this.searchComponentRef.resetOptions()
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchText: this.searchText.name ?? this.searchText,
        businessUnitId: this.buId,
        tsId: this.tsId,
        utIds: this.utIds,
        feederNames: this.feederNames,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo)
      }
    };
    if (this.tabMenu == 3) {
      this.assetPerformanceDtService.getDtAssetPerformance(obj).subscribe(response => {
        this.assetPerformanceDts = response?.data?.data;
        if(this.currentPage == 1){
          this.count = response?.data?.count;
          this.lastPageSize = Math.ceil(this.count / this.pageSize)
        }
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
    if (this.tabMenu == 2) {
      this.assetPerformanceF11Service.getF11AssetPerformance(obj).subscribe(response => {
        this.assetPerformanceF11 = response.data?.data;
        if(this.currentPage == 1){
          this.count = response.data?.count;
          this.lastPageSize = Math.ceil(this.count / this.pageSize)
        }
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
    if (this.tabMenu == 1) {
      this.assetPerformanceF33Service.getF33AssetPerformance(obj).subscribe(response => {
        this.assetPerformanceF33 = response?.data?.data;
        if(this.currentPage == 1){
          this.count = response?.data?.count;
          this.lastPageSize = Math.ceil(this.count / this.pageSize)
        }
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
  }

  setMainDate() {
    let date = new Date()
    let dteFrom = new Date()

    date.setHours(2, 0, 0)
    dteFrom.setDate(dteFrom.getDate() - 1)

    this.dateFrom = { year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate() };
    this.dateTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
  }

  getBusForSelect() {
    this.buService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data, dropdown: true }
      this.checkFilters();
    }, _ => {
      this.toastr.error('An error occurred.')
    });
  }

  getTSForSelect(){
    this.transmissionService.getAllForSelect().subscribe(response=>{
      this.transmissionStations ={ name: 'Transmission stations', data: response.data, dropdown: true }
      this.checkFilters();
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  private getAllFeeders() {
    this.feederService.getAllFeedersAllFeedersList().subscribe(response => {
      this.allFeederNames = { name: this.feederName, data: response };
      this.checkFilters();
    })
  }

  businessUnitSelected(item: any) {
    if(this.tabMenu==1){
      this.tsId = item.item.value;
      this.checkFilters()
      this.pageChange(1);
    }
    else{
    if(!item.item)
      this.buId = 0
    if(item.item && this.buId != item.item.value) {
      this.buId = item.item.value;
      this.undertakingService.getAllByBuId(this.buId).subscribe(response => {
        this.undertakings = { name: this.utName, data: response.data };
        this.checkFilters()
        this.pageChange(1);
      }, error => {
        this.toastr.error('An error occurred.');
      })
    }
    if (this.buId == 0) {
      this.utIds = [];
      this.undertakings.data.splice(0);
      this.pageChange(1);
      this.checkFilters()
    }
  }
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  checkFilters() {
    if(this.tabMenu == 1){
      
      this.checkTsFilter();

    }
    else{
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnits.name);
    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);

    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;

    if (!buData && this.tabMenu !== 1) {
      this.allFilters.push(this.businessUnits)

      if (this.tabMenu == 3) {
        this.allFilters.push(this.undertakings)
        this.allFilters.push(this.allFeederNames)
      }


      this.filterApplied = true
    }

   
    this.checkUtFilter();
    this.checkFeederFilter();

    this.allFilters = [...this.allFilters]
  }
  }

  inputChanged(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.reloadTable(1)
  }

  checkTsFilter(){
    let tsData = this.allFilters.find((item: { name: any; }) => item.name === this.transmissionStations.name);
      let tsIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.transmissionStations.name);

      if (tsIndex !== -1)
        this.allFilters[tsIndex].data = this.transmissionStations.data;
      if(!tsData){
        this.allFilters=[]
        this.allFilters.push(this.transmissionStations)
      }
  }

  checkUtFilter(){
    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakings.name);
    if (utIndex !== -1)
      this.allFilters[utIndex].data = this.undertakings.data;
  }

  checkFeederFilter(){
    let feedersIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.allFeederNames.name);
    if (feedersIndex !== -1)
      this.allFilters[feedersIndex].data = this.allFeederNames.data;
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    this.reloadTable(1)
  }

  setFilters(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);

      if (item.name === this.undertakings.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.undertakings = item
        this.utIds = filteredValues
      }

      if (item.name === this.allFeederNames.name) {
        const filteredNames = checkedData.map((item2: { name: any; type: any}) => {
          const { name, type } = item2;
          return { name, type };
        });
        this.allFeederNames = item
        this.feederNames = filteredNames
      }
    }
    this.pageChange(1);
  }

  tabChanged(value: number) {
    this.tabMenu = value
    this.resetFilters()
  }

  download(){

    if(this.tabMenu==1){
    let obj = {
      pageInfo: {
        page: -1,
        pageSize: this.pageSize
      },
      filterParams: {
        searchText: this.searchText.name ?? this.searchText,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        Feeder33Ids:this.checkedList

      }
    };
    this.assetPerformanceF33Service.downloadF33AssetPerformance(obj).subscribe(response => {
      if (response.status === 200) {
        window.location.href = response.data;
      } else if (response.data === null) {
        this.toastr.info(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }
  else if(this.tabMenu==3){
    let obj = {
      pageInfo: {
        page: -1,
        pageSize: this.pageSize
      },
      filterParams: {
        searchText: this.searchText.name ?? this.searchText,
        businessUnitId: this.buId,
        tsId: this.tsId,
        utIds: this.utIds,
        feederNames: this.feederNames,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        dtIds:this.checkedList

      }
    };
    this.assetPerformanceDtService.downloadDtAssetPerformance(obj).subscribe(response => {
      if (response.status === 200) {
        window.location.href = response.data;
      } else if (response.data === null) {
        this.toastr.info(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }
  else if(this.tabMenu==2){
    let obj = {
      pageInfo: {
        page: -1,
        pageSize: this.pageSize
      },
      filterParams: {
        searchText: this.searchText.name ?? this.searchText,
        businessUnitId: this.buId,
        tsId: this.tsId,
        utIds: this.utIds,
        feederNames: this.feederNames,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        feeder11Ids: this.checkedList

      }
    };
    this.assetPerformanceF11Service.downloadF11AssetPerformance(obj).subscribe(response => {
      if (response.status === 200) {
        window.location.href = response.data;
      } else if (response.data === null) {
        this.toastr.info(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  }

  resetFilters() {
    if (this.tabMenu == 3)
      this.searchPlaceholder = "Search by DT name or number";
    if (this.tabMenu == 2)
      this.searchPlaceholder = "Search by Feeder11 name or number";
    if(this.tabMenu == 1)
      this.searchPlaceholder ="Search by Feeder33 name or number"
    this.searchText = new SearchAutocomplete()
    this.searchComponentRef.searchText = new SearchAutocomplete()
    this.showFilter = false
    this.buId = 0
    this.tsId = 0
    this.checkedList = []
    this.utIds = []
    this.feederNames = []
    this.allFilters = []
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.searchComponentRef.resetOptions()
    this.setMainDate()
    this.checkFilters()
    this.pageChange(1);
  }
}

