import { BoundaryMeterDto } from "./feeder11Bu.model";

export class Feeder11{
    id: number = 0;
    name: string = '';
    feederNumber: string = '';
    ssName: string = '';
    ssId: number = 0;
    feeder33Name: string = '';
    feeder33Id: number = 0;
    amiId: number = 0;
    amiName: string = '';
    //MeterId:  string = '';
    meterNumber:  string = '';
    meterHistory : FeederMeter[] = [];
    meterType: number = 0;
    boundaryMeters: BoundaryMeterDto[] = []
    buId: number = 0
    nameplateRating: string = '';
    checked: boolean = false;
    meterVoltage: number = 0;
    meterMaxDigit?: number;
    multiplierFactor?: number;
}

export class FeederMeter{
    meterNumber:  string = '';
    fromDate : Date = new Date();
    toDate : Date = new Date();
}
