<app-unread-indicator
[unreadCount]="unreadCount"
(click)="show = !show">
</app-unread-indicator>
<div 
    *ngIf="show"
    class="notifications-wrapper"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="500"
    (scrolled)="onScroll()"
    [scrollWindow]="false">
    <span class="notification-title">Notifications</span>
    <div *ngIf="notifications.length != 0; else noNotifications">
        <div class="data-card" *ngFor="let notification of notifications">
        
            <div class="notification-text">
                <span class="title">
                    {{notification.title}}
                </span>
                <span class="description">
                    {{notification.description}}
                </span>
            </div>
            
            <div class="notification-time">
                <span class="time">
                    {{notification.create | dateAgo}}
                </span>
            </div>
            
        </div>
    </div>

    <ng-template #noNotifications>
        <div class="data-card">
        
            <div class="notification-text">
                <span class="description">
                    There's no notifications.
                </span>
            </div>
            
        </div>
    </ng-template>
    
</div>