<div class="nm-filter-wrapper">
    <app-tab-default [firstText]="this.buName" [secondText]="this.utName"
        (tabChanged)="changeTab($event)" [paCss]="true"
        [womCss]="true"></app-tab-default>
</div>
<div class="filters-container">
    <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
        <app-multi-filter *ngIf="filterApplied"
            [data]="allFilters"
            [(showFilter)]="showFilter"
            (dropdownChange)="dropdownSelected($event)"
        >
        </app-multi-filter>
        <app-range-datepicker
            [fromDate]="dateFrom" 
            [toDate]="dateTo"
            [name]="'Period'"
            [isMonthly]="isMonthly"
            (dateRangeSelected)="dateChange($event)">
            </app-range-datepicker>
        <app-search-autocomplete
        #search
        [placeholder]="searchPlaceholder"
        [searchText]="searchText"
        [options]="options"        
        (searchChange)="searchChanged($event)"
        (searchAutocompleteChange)="getOptions($event)"
        ></app-search-autocomplete>
    </div>
    <app-download-button-new [isOnlyDownload]="true" (downloadButtonClickedChange)="download()">
    </app-download-button-new>
</div>
<div class="graph row">
    <div class="col-6">
        <div class="row justify-content-end">
            <div class="col-11 text-center">
                <p class="chart-title">Worst 10 Performing Assets</p>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-sm-1 text-right" *ngIf="mode == 1">
                <p class="chart-label-rotate">Energy Loss (%)</p>
            </div>
            <div class="col-sm-1 text-right" *ngIf="mode == 2">
                <p class="chart-label-rotate">Energy Loss (%)</p>
            </div>
            <div class="col-11 chart-bar">
                <canvas [legend]="false" #chart baseChart [labels]="EnergyLabels" [datasets]="EnergyLoss"
                    [options]="barChartOptions" [plugins]="barChartPlugins" height="450">
                </canvas>
            </div>

        </div>
        <div class="row align-items-center">
            <div class="col-sm-2">

            </div>
            <div class="col text-center" *ngIf="mode == 1">
                <p class="chart-label">{{buName}}</p>
            </div>
            <div class="col text-center" *ngIf="mode == 2">
                <p class="chart-label">{{utName}}</p>
            </div>
        </div>
    </div>


    <div class="col-6">
        <div class="row justify-content-end">
            <div class="col-11 text-center">
                <p class="chart-title">Worst 10 Performing Assets</p>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-sm-1 text-right" *ngIf="mode == 1">
                <p class="chart-label-rotate">Capital Losses (in millions NGN)</p>
            </div>
            <div class="col-sm-1 text-right" *ngIf="mode == 2">
                <p class="chart-label-rotate">Capital Losses (in millions NGN)</p>
            </div>
            <div class="col-11 chart-bar">
                <canvas [legend]="false" #chart baseChart [labels]="CapitalLabels" [datasets]="CapitalLoss"
                    [options]="barChartOptions" [plugins]="barChartPlugins" height="450">
                </canvas>
            </div>

        </div>
        <div class="row align-items-center">
            <div class="col-sm-2">

            </div>
            <div class="col text-center" *ngIf="mode == 2">
                <p class="chart-label">{{utName}}</p>
            </div>
            <div class="col text-center" *ngIf="mode == 1">
                <p class="chart-label">{{buName}}</p>
            </div>
        </div>
    </div>
</div>