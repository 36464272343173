import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { DtService } from 'src/app/services/dt.service';
import { AccountService } from 'src/app/services/account.service';
import { PaymentCollectionModalData } from 'src/app/models/revenue-modal-data';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommonService } from 'src/app/services/common-service.service';
import { PaymentCollectionService } from 'src/app/services/payment-collection.service';
import { OverdueBillArearsService } from 'src/app/services/overdue-bill-arears.service';
import { transformToString } from 'src/app/helpers/dateUtils';
import { Location } from '@angular/common';
import { PaymentCollectionsPopupComponent } from '../payment-collections-popup/payment-collections-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { CommandRequestService } from 'src/app/services/command-request.service';
import { PaginationComponent } from 'src/app/components/common-new-design/pagination/pagination.component';
import { FilterDropdownSearchComponent } from 'src/app/components/common-new-design/filter-dropdown-search/filter-dropdown-search.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-payment-collections',
  templateUrl: './payment-collections.component.html',
  styleUrls: ['./payment-collections.component.scss']
})
export class PaymentCollectionsComponent implements OnInit {

  @ViewChild(PaymentCollectionsPopupComponent) popupComponentRef!: PaymentCollectionsPopupComponent;
  @ViewChild(PaginationComponent) paginationComponentRef!: PaginationComponent;
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent
  public allFilters: any = []
  public filterApplied: boolean = false;
  public showFilter: any = false

  public buId: number = 0

  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  private countForDownload: number = 0;

  public searchText: SearchAutocomplete = new SearchAutocomplete();
  public dateFrom: any = ''
  public dateTo: any = ''
  monthly = false;

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public dtName: string = this.translationService.getByKeyFromCache('DT');

  public businessUnits: any = { name: this.buName, data: [], dropdown: true };
  public undertakings: any = { name: this.utName, data: [], dropdown: true };
  public dts: any = { name: this.dtName, data: [], dropdown: true };
  public accounts: any = [];
  public utIds: any = [];

  public cumulativeDebt: number = 0;
  public currentTotalCollection: number = 0;
  public customerCount: number = 0;

  public paymentCollections: any = [];
  public paymentCollection: PaymentCollectionModalData = new PaymentCollectionModalData;
  public selectedPayment: PaymentCollectionModalData = new PaymentCollectionModalData;

  public filter: number = 0;
  public detailsShowed: boolean = false;


  public count1: number = 0;

  public masterSelected: boolean = false;
  public checkedList: number[] = [];
  public undertakingId: number = 0;
  public dtId: number = 0;
  public options = ['']

  @ViewChild('child1') child1!: FilterDropdownSearchComponent

  public showPaging: boolean = false;

  constructor(private translationService: TranslationService,
    public toastr: ToastrService,
    public businessUnitService: BusinessUnitService,
    public undertakingService: UndertakingService,
    public accountService: AccountService,
    public commonService: CommonService,
    public paymentcollectionService: PaymentCollectionService,
    public dtService: DtService,
    private overdueBillArearsService: OverdueBillArearsService,
    private authService: AuthService,
    private commandRequestService: CommandRequestService,
    private location: Location) { }

  ngOnInit(): void {
    this.setMainDate();
    this.getBusForSelect();
    this.reloadTable();
    this.showPaging = true;
  }


  setMainDate(){
    let dteFrom = new Date()
    let dte = new Date()

    dteFrom.setHours(2, 0, 0);
    dte.setHours(2, 0, 0);
    dte.setDate(dte.getDate() + 1);

    dteFrom.setMonth(dteFrom.getMonth() - 1);
    dteFrom.setDate(dteFrom.getDate() + 1);

    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate()};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate()};
  }

  setFilters(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);
      if (item.name === this.undertakings.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.undertakings = item
        this.undertakingId = filteredValues
      }
      if (item.name === this.dts.name) {
        const filteredNames = checkedData.map((item2: { value: any; }) => item2.value);
        this.dts = item
        this.dtId = filteredNames
      }
    }
    this.pageChange(1);


  }

  dropdownSelected(data: any) {
    if (!data)
      return
    if (data.data.name === this.buName)
      this.businessUnitSelected(data.item)
    if (data.data.name === this.utName)
      this.undertakingSelected(data.item)
    if (data.data.name === this.dtName) {
      this.dtId = data.item.value;
      this.checkFilters()
      this.checkedList = [];
      this.pageChange(1);
    }
  }

  businessUnitSelected(item: any) {
    if (item.value === 0)
      this.buId = 0
    if (item.value !== 0) {
      this.buId = item.value;
      this.undertakingService.getAllByBuId(this.buId).subscribe(response => {
        this.undertakings = { name: this.utName, data: response.data, dropdown: true };
        this.pageChange(1);
        this.checkFilters()
      }, error => {
        this.toastr.error('An error occurred.');
      })
    }
    if (this.buId == 0) {
      this.undertakingId = 0;
      this.undertakings.data.splice(0);
      this.pageChange(1);
      this.checkedList = [];
      this.checkFilters()
    }
  }

  undertakingSelected(item: any) {
    if (item.value === 0)
      this.undertakingId = 0
    if (item.value !== 0) {
      this.undertakingId = item.value;
      this.dtService.getAllDtsByUtId(this.undertakingId).subscribe(response => {
        this.dts = { name: this.dtName, data: response.data, dropdown: true };
        this.pageChange(1);
        this.checkedList = [];
        this.checkFilters()
      }, error => {
        this.toastr.error('An error occurred.');
      })

    }
    if (this.undertakingId == 0) {
      this.dtId = 0;
      this.dts.data.splice(0);

      this.pageChange(1);
      this.checkFilters()
    }
  }

  checkFilters() {
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnits.name);

    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);

    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;

    if (!buData) {
      this.allFilters.push(this.businessUnits)
      this.allFilters.push(this.undertakings)
      this.allFilters.push(this.dts)

      this.filterApplied = true
    }
    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakings.name);
    if (utIndex !== -1)
      this.allFilters[utIndex].data = this.undertakings.data;

    let dtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.dts.name);
    if (dtIndex !== -1)
      this.allFilters[dtIndex].data = this.dts.data;

    this.allFilters = [...this.allFilters]

  }

  getNoOfAccounts(obj: any) {
    this.paymentcollectionService.getNoOfAccounts(obj).subscribe(response => {
      this.customerCount = response.data
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getCumulativePayments(obj: any) {
    this.paymentcollectionService.getCumulativePayments(obj).subscribe(response => {
      this.currentTotalCollection = response.data
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getCumulativeDebtAmount(obj: any) {
    this.overdueBillArearsService.getCurrentTotalDebt(obj).subscribe(response => {
      this.cumulativeDebt = response.data
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getBusForSelect() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      // this.businessUnits = response.data;
      this.businessUnits = { name: this.buName, data: response.data, dropdown: true }
      this.checkFilters();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  selectPayment(payment: PaymentCollectionModalData) {
    this.getPaymentPopUp(payment)
  }

  openTab() {
    this.popupComponentRef.changeState(1, this.selectedPayment);
  }

  closeTab() {
    this.popupComponentRef.changeState(2, this.selectedPayment);
  }

  getPaymentPopUp(payment: any) {
    let obj = {
      AccountNo: payment.accountNo,
      PaymentId: payment.paymentId
    }

    this.paymentcollectionService.getPaymentPopUpData(obj).subscribe(response => {
      if (response.status == 200) {
        this.selectedPayment = response.data
        this.openTab()
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error("An Error Occurred")
    })
  }

  viewDetails() {
    this.detailsShowed = !this.detailsShowed
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
    this.showPaging = true;
  }

  resetAll() {
    this.undertakings.splice(0);
    this.allFilters = []

    this.paginationComponentRef?.changePage(1)
    this.pageChange(1)
  }

  downloadReport() {
    let errors = this.validateDownload();

    if (errors.length > 0) {
      errors.forEach(error => {
        this.toastr.error(error);
      });

      return;
    }

    let obj = {
      pageInfo: {
        page: -1,
        pageSize: this.countForDownload
      },
      FilterParams: {
        businessUnitId: this.buId,
        undertakingId: this.undertakingId,
        dtId: this.dtId,
        search: this.searchText ? this.searchText.name : this.searchText,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        checkedList: this.checkedList,
      },
      guid: crypto.randomUUID()
    };
    this.downloadMonthlyReport(obj);
  }

  downloadAndSendReport(email: string) {
    let errors = this.validateDownload();

    if (errors.length > 0) {
      errors.forEach(error => {
        this.toastr.error(error);
      });

      return;
    }

    let obj = {
      pageInfo: {
        page: -1,
        pageSize: this.countForDownload
      },
      FilterParams: {
        businessUnitId: this.buId,
        undertakingId: this.undertakingId,
        dtId: this.dtId,
        search: this.searchText ? this.searchText.name : this.searchText,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        checkedList: this.checkedList,
        email: email,
      },
      guid: crypto.randomUUID()
    };
    this.downloadAndSendMonthlyReport(obj);
  }

  downloadAndSendMonthlyReport(obj: any) {
    this.paymentcollectionService.downloadAndSendPaymentCollectionsReport(obj).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occured');
    })
  }

  private downloadMonthlyReport(obj: any) {
    this.paymentcollectionService.downloadPaymentCollectionsReport(obj).subscribe(response => {
      if (response.status == 201) {
        this.toastr.success(response.message)
      }
      if (response.status == 200) {
        if (!response.data.includes("http")) {
          this.toastr.error("An error occured.");
        }
        else {
          window.location.href = response.data;
        }
      }
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  checkCommandRequest(guid: string) {
    // initial check
    this.commandRequestService.checkCommandRequest(guid).subscribe(response => {
      if (response.data) {
        window.location.href = response.message;
      }
    })

    // check every 5s
    let intervalRef = setInterval(() => {
      this.commandRequestService.checkCommandRequest(guid).subscribe(response => {
        if (response.data) {
          window.location.href = response.message;
          clearInterval(intervalRef);
        }
      })
    }, 5000);
  }

  getOptions(searchText: SearchAutocomplete) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    if(searchText.name.length > 2){
      this.accountService.getDataForAutocomplete(obj)
      .subscribe({
        next : (response) => this.options = response.data,
        error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
      })
    } else
      this.searchComponentRef.resetOptions()
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      FilterParams: {
        BusinessUnitId: this.buId,
        UndertakingId: this.undertakingId,
        DtId: this.dtId,
        Search: this.searchText.name,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        phone: 1
      }
    };
    this.getCumulativePayments(obj.FilterParams)
    this.getNoOfAccounts(obj.FilterParams);
    this.getCumulativeDebtAmount(obj.FilterParams);
    this.paymentcollectionService.getAll(obj).subscribe(response => {
      this.paymentCollections = response.data.data;
      this.isAlreadyChecked();
        if (this.currentPage == 1) {
            this.paymentcollectionService.getPaymentCollectionCount(obj).subscribe(response => {
            this.count = response.data;
            this.showPaging = false;
            }, error => {
                this.toastr.error('An error occurred while fetching count.');
            })
        }


      this.showPaging=false;
    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.lastPageSize = Math.ceil(this.count / this.pageSize)
    this.showPaging = true;

  }


  inputChanged(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.pageChange(1);
    this.checkedList = [];

  }

  updateDate(date: any) {
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.reloadTable();
    this.checkedList = [];
  }

  changed() {
    this.count1 = 0;
    this.paymentCollections.forEach((item: { [x: string]: any; }) => {
      if (item['checked']) {
        this.count1 = this.count1 + 1;
      }
    })
  }

  validateDownload(): string[] {
    let messages: string[] = [];

    if (this.buId === 0) {
      messages.push("Please select region for download.");
    }

    const from = new Date(this.dateFrom.year, this.dateFrom.month - 1, this.dateFrom.day);
    const to = new Date(this.dateTo.year, this.dateTo.month - 1, this.dateTo.day);

    let diff = Math.abs(to.getTime() - from.getTime());
    diff = Math.ceil(diff / (1000 * 3600 * 24));

    if (diff > 31) {
      messages.push("Gap between selected dates can't be greater than 31 days for download.");
    }

    return messages;
  }

  copyToClipboard(accNo: string){
    navigator.clipboard.writeText(accNo)
    this.toastr.success('Copied to clipboard')
  }

  checkUncheckAll() {
    for (let i = 0; i < this.paymentCollections.length; i++) {
      this.paymentCollections[i].checked = this.masterSelected;
      this.checkForDownload(this.paymentCollections[i].paymentId, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.paymentCollections.forEach((x: { paymentId: number; checked: boolean; }) => {
      let index = this.checkedList.indexOf(x.paymentId)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.paymentCollections.every(function(item:any) {
      return item.checked == true;
    })
  }
}
