<div class="dialog-content-root">
    <div class="header">
        <img alt="" src="assets/map/ic_ut_dialog.png"/>
        <div>{{utName}}</div>
    </div>
    <div class="title">{{utData.name}}</div>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-sm-6 table-col">
                <div class="label">Address</div>
                <div class="value">{{utData.address}}</div>
            </div>
            <div class="col-sm-6 table-col">
                <div class="label">DT count</div>
                <div class="value">{{utData.dtCount}}</div>
            </div>
        </div>
    </div>
</div>
