import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-statement-account-details',
  templateUrl: './statement-account-details.component.html',
  styleUrls: ['./statement-account-details.component.scss']
})
export class StatementAccountDetailsComponent {

  @Input() headers: string[] = []
  @Input() data: string[] = []
  @Input() isInvalidAccount: boolean = false;

  constructor(private toastr: ToastrService) { }

  copyToClipboard(accNo: string){
    navigator.clipboard.writeText(accNo)
    this.toastr.success('Copied to clipboard')
  }

}
