import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Account } from 'src/app/models/account.model';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-thanksgiving',
  templateUrl: './thanksgiving.component.html',
  styleUrls: ['./thanksgiving.component.scss']
})
export class ThanksgivingComponent implements OnInit { 
  public account: Account= new Account();
  transactionNo : string = "";
  paymentAmount : any = "";
  isSuccessfull : boolean = true;

  constructor(public accountService: AccountService,
    private toastr: ToastrService, 
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.transactionNo = params['TransactionNo'];
      this.paymentAmount = params['PaymentAmount'];
      this.isSuccessfull = params['IsSuccessfull'] == 'true' ? true : false;
       if (this.paymentAmount) {
        this.paymentAmount = parseFloat(this.paymentAmount)
        this.paymentAmount = this.paymentAmount / 100;
       }
      this.GetPaymentProp(this.transactionNo,this.paymentAmount)
    }); 
  }

  GetPaymentProp(transactionNo:string, paymentAmount:string){ 
    const formData={transactionNo,paymentAmount }
    this.accountService.GetPaymentProp(formData).subscribe(response => {
      this.account = response.data   
      if (response.status === 200 && this.isSuccessfull) {
        this.toastr.success("Your payment has been successfully made!"); 
      } else if (response.status === 200 && !this.isSuccessfull) {
        this.toastr.error("Your payment was not processed successfully!"); 
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
