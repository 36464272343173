import { Component, Input } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { GraphItem } from 'src/app/models/graph-item.model';

@Component({
  selector: 'app-meter-makes',
  templateUrl: './meter-makes.component.html',
  styleUrl: './meter-makes.component.scss'
})
export class MeterMakesComponent{
// Graph, Chart
  public barChartType: ChartType = 'bar'
  @Input() graphData: GraphItem[] = [];
  @Input() MeterManufacturerLabels: string[] = [];
  @Input() MeterManufacturers: ChartDataset[] = [];
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',

    scales: {
      x: {
        grid: {
          display: false
        },
        border:{
          display: false
        },
      },
      y: {
        grid: {
          color: '#E8E8E8',
        },
        border:{
          display: false
        },
        ticks: {
          padding: 30,
          stepSize: 50,
        }
      }
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(context.parsed.y);
            }
            return label;
          },
          afterLabel: function (context) {
            const dataset = context.dataset;
            if (dataset.label === 'Total Meters: ') {
              const previousDatasets = context.chart.data.datasets.slice(0, 2);
              const totalMeters =
                Number(previousDatasets[0].data[context.dataIndex]) || 0;
              const metersWithComm =
                Number(previousDatasets[1].data[context.dataIndex]) || 0;
              const percentage = (metersWithComm / totalMeters) * 100;

              return 'Comm (Last 30 Days): ' + metersWithComm + ' (' + percentage + '%)';
            } else
              return '';
          },
        },
        backgroundColor: '#2E3336',
        borderColor: 'rgba(0, 0, 0, 0.5)',
        borderWidth: 1,
        padding: 20,
        cornerRadius: 10,
        displayColors: false,
        xAlign: 'center',
        yAlign: 'bottom'
      }
    }
  };

  private setValuesForMeterMakes(response: any) {
    this.MeterManufacturers[0].data = []
    this.MeterManufacturers[1].data = []
    this.MeterManufacturerLabels = []
    let labels = ['']
    labels = []
    if (response.data != null && response.data.length > 0) {
      this.graphData = response.data
      this.graphData.forEach(element => {
        this.MeterManufacturers[0].data?.push(element.metersCount)
        this.MeterManufacturers[1].data?.push(element.metersWithComm)
        labels.push(element.meterManufacturer)
      })
    }
    this.MeterManufacturerLabels = labels
  }
}
