<div class="outage-analysis">
  <div class="title">Outage Analysis</div>
  <div class="outage-analysis-wrapper">
    <div class="tabs-padding">
    <app-tab-default [firstText]="tab1"
                     [secondText]="tab2"
                     [thirdText]="tab3"
                     [nmDashCss]="true"
                     (tabChanged)="filterClicked($event)">
    </app-tab-default>
   </div>
    <table class="standard-table">
      <thead>
        <tr>
          <td class="asset-name">
            <span *ngIf="toggleLosses==1">Transmission-Station</span>
            <span *ngIf="toggleLosses==2">Sub-Station</span>
            <span *ngIf="toggleLosses==3">Feeder</span>
          </td>
          <td class="gray">0 - 5H</td>
          <td class="gray">5 - 10H</td>
          <td class="gray">10 - 15H</td>
          <td class="gray">ABOVE 15H</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of slicedItems">
          <td class="black">
            {{item.ssName}}
          </td>
          <td>
            <span>{{item.a}}</span>
          </td>
          <td>
            <span>{{item.b}}</span>
          </td>
          <td>
            <span>{{item.c}}</span>
          </td>
          <td>
            <span>{{item.d}}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination-wrapper">
      <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChanged($event)"></app-fo-pagination-new>
    </div>
  </div>
</div>
