<span class="dashboard-title">
    Meter Makes
</span>

<div class="content-card">  
    <div class="bypass-wrapper">
        <div class="chart-wrapper">
            <div class="row align-items-center">
                <div class="col-1 text-right">
                    <p class="chart-label-rotate">Total Number of Meter makes</p>
                </div>
                <div class="col-11 chart-bar">
                    <canvas 
                        [legend]="false" #chart baseChart [labels]="MeterManufacturerLabels" [datasets]="MeterManufacturers"
                        [options]="barChartOptions" [plugins]="barChartPlugins" height="500">
                    </canvas>
                </div>
            </div>
        </div>
    </div>
</div>
