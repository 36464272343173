import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Feeder33 } from 'src/app/models/feeder33.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { MeterService, TypeOfConsumer } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';

@Component({
  selector: 'app-feeder33-create',
  templateUrl: './feeder33-create.component.html',
  styleUrls: ['./feeder33-create.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class Feeder33CreateComponent{
  @Input() animationState = 'out';
  public PermissionsEnum = PermissionsEnum;
  @Output() parentComp = new EventEmitter();

  @Input() transmissionStations: any = [];
  @Input() amis: any = [];

  public selectedFeeder33: Feeder33 = new Feeder33()
  public tsIdInvalid: boolean = false;
  public amiIdInvalid: boolean = false;

  public isNameplateRatingValid: boolean = true;
  public feeder33MeterHistory: any = [];


  public msnValid: boolean = false;
  showShadow = false;


  public amiName :string = this.translationService.getByKeyFromCache('AMI');

  @ViewChild("f") f: any;
  constructor(private translationService: TranslationService,
    private ref: ElementRef,
    private meterService: MeterService,
    public authService: AuthService,
    private toastr: ToastrService,
    private feeder33Service: Feeder33Service,
    private tsService: TransmissionStationService,
    private amiService: AmiService,
    // @Inject(FeedersTabComponent) private tabComponent: FeedersTabComponent
    ) { }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }

  closeTab() {
    this.resetSelectedFeeder33();
    this.changeState(2);
  }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.feeder11-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }

  save(){
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    if (this.selectedFeeder33.amiId == 0 && this.selectedFeeder33.meterType !== 2) {
      this.toastr.error("Meter Manufacturer is required.");
      return;
    }
    
    if (this.selectedFeeder33.meterNumber && this.selectedFeeder33.meterMaxDigit != null && (this.selectedFeeder33.meterMaxDigit ?? 0) < 1) {
      this.toastr.error('Meter Max Digit must be a positive number')
      return 
    }

    if (this.selectedFeeder33.meterNumber && this.selectedFeeder33.multiplierFactor != null && this.selectedFeeder33.multiplierFactor < 1) {
      this.toastr.error('Multiplier Factor must be a positive number')
      return 
    }

    if(Number.isNaN(Number(this.selectedFeeder33?.nameplateRating)) || Number(this.selectedFeeder33?.nameplateRating) < 0){
      this.isNameplateRatingValid = false;
      return;
    }else
      this.isNameplateRatingValid = true;

    // if(!this.dropBoxValid()){
    //   this.toastr.error("Please fill out all required fields.");
    //   return;
    // }

    if (!this.validateMeter(this.selectedFeeder33.amiId,this.selectedFeeder33.meterNumber) && this.selectedFeeder33.meterType !== 2)
    {
      this.msnValid = false;
      this.toastr.error("Meter is not valid.");
      return
    }

    this.feeder33Service.save(this.selectedFeeder33).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.changeState(2);
        this.resetSelectedFeeder33();
        this.parentComp.emit();
      }else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  changeState(number: Number, data?: any) {
    if (data)
      this.selectedFeeder33 = data;

    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      this.showShadow = false;
    }
  }

  validateMSN(){
    if(!this.validateMeter(this.selectedFeeder33.amiId, this.selectedFeeder33.meterNumber)){
      this.msnValid = false;
     return;
    }
    this.msnValid = true;
  }

  msnNumberErrorMsg = '';
  validateMeter(amiId: number, meterNumber: string){
    let ami = this.amis.find((x: { value: number; }) => x.value == amiId)?.name;
    if (ami && ami != '')
    {
      this.msnNumberErrorMsg = this.meterService.validateMeter(ami, meterNumber, TypeOfConsumer.Feeder33)
      if (this.msnNumberErrorMsg == '')
        return true
    }
    return false
  }

  dropBoxValid(): boolean {
    this.tsIdInvalid = false;

    if(this.selectedFeeder33.tsId === 0) {
      this.tsIdInvalid = true;
    }

    if(this.tsIdInvalid) {
      return false;
    }
    return true;
  }

  isMeteredValidate(){
    if(this.selectedFeeder33.meterType === 2){
      this.selectedFeeder33.meterNumber = '';
      this.selectedFeeder33.amiId = 0;
    }
  }

  resetSelectedFeeder33(){
    this.selectedFeeder33 = new Feeder33();
    this.feeder33MeterHistory = [];

    if(this.f)
      this.f.submitted = false;
  }
}
