import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { MeterBypassService } from 'src/app/services/meter-bypass.service';

@Component({
  selector: 'app-meter-bypass-postpaid-details',
  templateUrl: './meter-bypass-postpaid-details.component.html',
  styleUrls: ['./meter-bypass-postpaid-details.component.scss']
})
export class MeterBypassPostpaidDetailsComponent implements OnInit {
  @Input() data: any;
  @Output() backClicked = new EventEmitter<void>();

  constructor(public route: ActivatedRoute,public meterBypassService : MeterBypassService, public toastr: ToastrService, public router: Router, public accountService : AccountService) { }

  ngOnInit(): void {
    this.getCustomerDetails(this.data);
  }
  
  saveComment(){
    this.meterBypassService.updatePostpaidBypass(this.selectedMeterBypass).subscribe(response =>{
      if(response.status == 200){
         this.toastr.success("Success")
       }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error('An Error Occurred')
    })
  }

  tabChanged(e: any) {
    if ((e == 1 && this.activeTab == ActiveTab.Table) || (e == 2 && this.activeTab == ActiveTab.Details)) {
      return
    }
    if (e == 1) {
      this.activeTab = ActiveTab.Table
    } else  if (e == 2){
      this.activeTab = ActiveTab.Details
    }else{
      this.activeTab = ActiveTab.Remark
    }
  }
  activeTab: ActiveTab = ActiveTab.Table
  accountDetails : any;

  getCustomerDetails(obj : any){
    this.meterBypassService.getPostpaid(obj).subscribe(response => {
      this.selectedMeterBypass = response.data; 
       this.getAccountDetails(this.selectedMeterBypass.accountId);
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  getAccountDetails(id: any){
    this.accountService.get(id).subscribe(response =>{
      this.accountDetails = response.data;
    })
  }
  
  public selectedMeterBypass: any;
  ActiveTab: any = ActiveTab;

  public copyAccountNumber(accountNumber : any){
    navigator.clipboard.writeText(accountNumber)
    this.toastr.success('Copied to clipboard')
  }
  public back(){
    this.backClicked.emit();
  }
}
export enum ActiveTab {
  Details = 1,
  Table = 2,
  Remark = 3
}
