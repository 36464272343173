import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MultiFilterComponent } from 'src/app/components/common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from 'src/app/components/common-new-design/search-autocomplete/search-autocomplete.component';
import { transformToString } from 'src/app/helpers/dateUtils';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { ViewsEnum } from 'src/app/models/viewsEnum.model';
import { WomTabs } from 'src/app/models/wom-tabs.model';
import { WomStatus } from 'src/app/models/womStatus.model';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { TranslationService } from 'src/app/services/translation.service';
import { WorkOrderManagement } from 'src/app/services/work-order-management.service';

@Component({
  selector: 'app-wom-main',
  templateUrl: './wom-main.component.html',
  styleUrls: ['./wom-main.component.scss']
})
export class WomMainComponent implements OnInit, OnDestroy {

  public PermissionsEnum = PermissionsEnum;

  public userGuid: string = ''

  public tabMenu: number = 1;

  public currentPage: number = 1;
  public pageSize: number = 6;
  public count: number = 0;
  public lastPageSize: number = 1;
  public defaultImage: string = 'assets/images-new-design/defaultImg.png'
  searchPlaceholder: string = 'Search by task id or task name'

  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public dateCreatedFrom: any = '';
  public dateCreatedTo: any = '';
  public dateResolvedFrom: any = '';
  public dateResolvedTo: any = '';
  filterByDateClicked: boolean = false;
  @Output() search = new EventEmitter<any>();
  @Output() dateCreatedStr = new EventEmitter<any>();
  @Output() dateResolvedStr = new EventEmitter<any>();
  @Output() changeView: EventEmitter<any> = new EventEmitter()
  @Output() setTaskId: EventEmitter<any> = new EventEmitter()
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  public showResolvedFilter: boolean = false
  options = ['']

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  openModal = 0;

  public tabsEnum = WomTabs
  public mainTab: WomTabs = WomTabs.Feeders33

  public status: number = WomStatus.Ongoing;
  public activeTab: string = '33KV Feeders';

  taskCount: number = 0;

  closedCount: number = 0;
  resetStatus: boolean = false;
  public taskCounts: any = {}

  selectedOrder: any = {};
  tableData: any[] = [];

  filterBuClicked: boolean = false;
  public businessUnits: any = [];
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public buSelected: DictionaryItem = new DictionaryItem(0, this.buName)
  public buIds: any = []
  public allFilters: any = []
  public filterApplied: boolean = false;
  public showFilter: any = false

  constructor(private translationService: TranslationService,
    public authService: AuthService,
    public workOrderManagement: WorkOrderManagement,
    public toastr: ToastrService,
    private router: Router,
    public businessUnitService: BusinessUnitService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userGuid = this.authService.getCurrentUser()?.userGuid;
    this.route.queryParams.subscribe(params => {
      if (params.tab > 1){
        this.tabMenu = parseInt(params['tab']);
        this.onStatusChange(this.tabMenu)
      }

    })
    this.setMainDate();
    this.getBusForSelect();
    this.reloadTable();
  }

  getOptions(searchText: SearchAutocomplete) {
    if (searchText.name.length > 2) {
      this.workOrderManagement.getDataForAutocomplete(searchText.name)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next : (response) => this.options = response.data,
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
    } else
      this.searchComponentRef.resetOptions()
  }

  setMainDate() {
    let date = new Date()
    let dteFrom = new Date()

    date.setHours(2, 0, 0)
    date.setDate(date.getDate() + 1)
    dteFrom.setMonth(date.getMonth() - 6);
    var temp1 = dteFrom.getFullYear();
    var temp2 = dteFrom.getMonth()
    var temp3 = dteFrom.getDate()
    this.dateCreatedFrom = { year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate()};
    this.dateCreatedTo ={ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};

    this.dateResolvedFrom = { year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate()};
    this.dateResolvedTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
  }

  removeResolvedFilter() {
    this.dateResolvedFrom = ''
    this.dateResolvedTo = ''
  }

  getBusForSelect() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      // this.businessUnits = response.data;
      this.businessUnits = { name: this.buName, data: response.data }
      this.checkFilters();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  checkFilters() {
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnits.name)

    if (!buData) {
      this.allFilters.push(this.businessUnits)
      this.filterApplied = true
    }
  }

  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        isMonthlySelected: true

      },
      filterParams: {
        businessUnitIds: this.buIds,
        search: this.searchText.name,
        assetType: this.activeTab,
        dateCreatedFrom: transformToString(this.dateCreatedFrom),
        dateCreatedTo: transformToString(this.dateCreatedTo),
        dateResolvedFrom: transformToString(this.dateResolvedFrom),
        dateResolvedTo: transformToString(this.dateResolvedTo),
        status: this.status
      }
    };

    this.workOrderManagement.getAll(obj).subscribe(response => {
      this.tableData = response.data.data;
      if(this.currentPage == 1){
        this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize);
      }
    }, error => {
      this.tableData = [];
      this.toastr.error('An error occurred.');
    });
    this.getTaskCounts()
  }


  businessUnitSelect(data: any) {
    this.buSelected = data;
    this.pageChange(1);
  }

  onStatusChange(value: number) {
    if (value === 1) {
      this.status = WomStatus.Ongoing
      this.showResolvedFilter = false
      this.removeResolvedFilter()
    }
    if (value === 2) {
      this.status = WomStatus.Completed
      this.showResolvedFilter = true
      this.setMainDate()
    }
    if (value === 3) {
      this.status = WomStatus.Cancelled
      this.showResolvedFilter = false
      this.removeResolvedFilter()
    }
    this.tabMenu = value;
    this.router.navigate([], { queryParams: { tab: value}, replaceUrl: true })
    this.currentPage = 1;
    this.reloadTable();
    this.searchText = new SearchAutocomplete()
    if(this.searchComponentRef){
      this.searchComponentRef.resetOptions()
      this.searchComponentRef.searchText = new SearchAutocomplete()
    }
  }




  getTaskCounts() {
    this.workOrderManagement.getTaskCounts(this.userGuid).subscribe(response => {
      this.taskCounts = response.data
      this.taskCount = this.taskCounts.ongoing + this.taskCounts.unassigned + this.taskCounts.completed + this.taskCounts.canceled
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }


  mainTabChange(value: number) {
    if (value == 1) {
      this.mainTab = this.tabsEnum.Feeders33
      this.activeTab = '33KV Feeders'
      this.resetAll();
    }
    else {
      // this.mainTab = this.tabsEnum.Feeders11
      // this.activeTab = '11KV Feeders'
      // this.resetAll()
      this.toastr.warning("Not yet implemented.")
    }
    this.searchComponentRef.searchText = new SearchAutocomplete()
    this.searchText = new SearchAutocomplete()
  }

  alert() {
    this.toastr.warning("Not yet implemented.");
  }

  public resetAll() {
    this.buSelected = new DictionaryItem(0, 'Region')
    // this.dateCreatedFrom = null;
    // this.dateResolved = null;
    this.buIds = []
    this.searchText = new SearchAutocomplete()
    this.searchComponentRef.searchText = new SearchAutocomplete()

    this.filterBuClicked = false;
    this.filterByDateClicked = false;
    this.pageChange(1)
  }


  searchChange(search: SearchAutocomplete) {
    this.searchText = search;
    this.reloadTable();
  }

  handleRowClick(name: string, id: number): void {
    if (name === 'showMoreInfo') {
      this.workOrderManagement.getOne(id).subscribe(response => {
        this.selectedOrder = response.data;
        // this.popupComponentRef.changeState('showMoreInfo', this.selectedOrder);
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }
    else if (name === 'createWorkOrder') {
      // this.popupComponentRef.changeState(PopupEnum.Open, this.selectedOrder);
    }
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }
  dateCreatedChange(date: any) {
    this.dateCreatedFrom = date.dateFrom;
    this.dateCreatedTo = date.dateTo;
    this.reloadTable();
  }
  dateResolvedChange(date: any) {
    this.dateResolvedFrom = date.dateFrom;
    this.dateResolvedTo = date.dateTo;
    this.reloadTable();
  }
  clearDate(name: string): void {
    if (name === 'dateResolved') {
      this.dateResolvedStr.emit('');
    } else if (name === 'dateCreated') {
      this.dateCreatedStr.emit('');
    }
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)
      if (item.name === 'Region' && filteredValues.length > 0) {
        this.buIds = filteredValues
      } else if (item.name === 'Region' && filteredValues.length == 0) {
        this.buIds = []
      }
    }
    this.reloadTable(1)
  }

  openPopup() {
    this.changeView.emit(ViewsEnum.Create)
  }

  showDetails(item: any) {
    this.setTaskId.emit(item.id)
    this.changeView.emit(ViewsEnum.ShowDetails)
  }

  isOverdue(date: Date) {
    let temp = new Date(date)
    let now = new Date()
    now.setHours(0,0,0,0)
    if (date != null)
      return temp < now
    return false
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
