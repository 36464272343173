<div class="tabs-wrapper" [style.margin-left]="marginLeft" *ngIf="isConst">
  <div class="tab-container" (click)="firstDisabled ?  notImplementedYet() : changeTab(1)" [ngClass]="{'selected' : tabSelected == 1 && !isInvalidAccount, 'wom-selected': womCss, 'pa-selected': paCss, 'nm-selected':nmDashCss,'map-tab':meterMapCss}">
    <img alt="" [src]="firstImg" *ngIf="firstImg" [ngClass]="{'map-img': meterMapCss}">
    <span>{{firstText}}</span>
    <span class="tab-num" *ngIf="wom">{{firstTabNum}}</span>
  </div>
  <div class="tab-container" (click)="secondDisabled? null : changeTab(2)" [ngClass]="{'selected' : tabSelected == 2 && !isInvalidAccount, 'wom-selected': womCss, 'pa-selected': paCss, 'nm-selected':nmDashCss, 'map-tab':meterMapCss}">
    <img alt="" [src]="secondImg" *ngIf="secondImg" [ngClass]="{'map-img': meterMapCss}">
    <span>{{secondText}}</span>
    <span class="tab-num" *ngIf="wom && secondTabNum">{{secondTabNum}}</span>
  </div>
  <div class="tab-container" *ngIf="thirdText !== ''" (click)=" thirdDisabled ? null : changeTab(3)" [ngClass]="{'selected' : tabSelected == 3 && !isInvalidAccount, 'wom-selected': womCss, 'map-tab':meterMapCss}">
    <img alt="" [src]="thirdImg" *ngIf="thirdImg" [ngClass]="{'map-img': meterMapCss}">
    <span>{{thirdText}}</span>
    <span class="tab-num" *ngIf="wom && thirdTabNum">{{thirdTabNum}}</span>
  </div>
  <div class="tab-container" *ngIf="forthText !== ''" (click)="forthDisabled ? null :  changeTab(4)" [ngClass]="{'selected' : tabSelected == 4 && !isInvalidAccount, 'wom-selected': womCss, 'map-tab':meterMapCss}">
    <img alt="" [src]="fourthImg" *ngIf="fourthImg" [ngClass]="{'map-img': meterMapCss}">
    <span>{{forthText}}</span>
    <span class="tab-num" *ngIf="wom && fourthTabNum">{{fourthTabNum}}</span>
  </div>
  <div class="tab-container" *ngIf="fifthText !== ''" (click)="fifthDisabled ? null :  changeTab(5)" [ngClass]="{'selected' : tabSelected == 5 && !isInvalidAccount, 'wom-selected': womCss, 'map-tab':meterMapCss}">
    <img alt="" [src]="fifthImg" *ngIf="fifthImg" [ngClass]="{'map-img': meterMapCss}">
    <span>{{fifthText}}</span>
    <span class="tab-num" *ngIf="wom && fifthTabNum">{{fifthTabNum}}</span>
  </div>
  <div class="tab-container" *ngIf="sixthText !== ''" (click)="sixthDisabled ? null :  changeTab(6)" [ngClass]="{'selected' : tabSelected == 6 && !isInvalidAccount, 'wom-selected': womCss, 'map-tab':meterMapCss}">
    <img alt="" [src]="sixthImg" *ngIf="sixthImg" [ngClass]="{'map-img': meterMapCss}">
    <span>{{sixthText}}</span>
    <span class="tab-num" *ngIf="wom && sixthTabNum">{{sixthTabNum}}</span>
  </div>
</div>

<div class="tabs-wrapper-more" #widgetsContent [style.margin-left]="marginLeft" *ngIf="!isConst">
  <div *ngFor="let item of tabs; let i = index" class="tab-container" (click)="changeTabMulti(i + 1,item.id)" [ngClass]="{'selected' : tabSelected == i + 1 && !isInvalidAccount, 'wom-selected': womCss, 'pa-selected': paCss, 'nm-selected':nmDashCss}">
    <img alt="" [src]="firstImg" *ngIf="firstImg">
    <span>{{item.name}}</span>
    <span class="tab-num" *ngIf="wom">{{item.number}}</span>
  </div>
</div>
