<main class="container-fluid container-crud">
    <!-- -fluid bg-white -->
        <div class="back-container"><img alt="Back" src="assets/images/ic_arrow_left.svg" />
            <div class="back-button" (click)="back()">Back</div>
        </div>
        <div class="data-table">
            <!-- row-fluid revenue-page-padding-left -->
            <div class="row table-row">
                <div class="crud-title">
                    Configuration Settings
                </div>
                <div class="crud-title">
                <div class="create-wrapper">
                    <div class="accounts-download-button">
                        <button  data-toggle="modal" data-target="#createModal" *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_user_createupdate)">Create Configuration</button>
                    </div>
                </div>
            </div>


            <div class="col-sm-12 table-details">
                <table>
                    <thead>
                        <tr class="header-columns">
                            <td>Name</td>
                            <td>Value</td>
                        </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr *ngFor="let item of configBills">
                            <td>{{item.name}}</td>
                            <td *ngIf="item.key === 'SMSFlag'" >{{item.value}}</td>
                            <td *ngIf="item.key != 'SMSFlag'">{{item.value | number}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

<div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="create" aria-hidden="true" appClickOutside  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content create-modal crud-card">
            <div class="modal-header">
                <div class="modal-title"><img alt="Upsert" src="/assets/pencil.svg"> Create/Edit Payment Configuration</div>
            </div>
            <div class="modal-body">
                <form (submit)="f.form.valid" #f="ngForm">
                    <!-- && save() -->
                    <div class="row">
                        <div class="col-sm-6 crud-input-container">
                            <label>Bill Overdue Flag 1</label>
                            <input class="form-control" type="number" name="flag1" [(ngModel)]="flag1.value" #name="ngModel" required autocomplete="off" />
                            <div *ngIf="f.submitted && !validateFlag1()" class="form-control-error">Bill overdue flag 1 is required</div>
                          </div>

                          <div class="col-sm-6 crud-input-container">
                            <label>Bill Overdue Flag 2</label>
                            <input class="form-control" type="number" name="flag2" [(ngModel)]="flag2.value" #name="ngModel" required autocomplete="off" />
                            <div *ngIf="f.submitted && !validateFlag2()" class="form-control-error">Bill overdue flag 2 is required</div>
                          </div>

                          <div class="col-sm-6 crud-input-container">
                            <label>SMS Automatic Send Flag</label>
                            <div class="download-button-wrapper" style="margin-top: 30px;">
                                <input class="form-control" type="button" name="flag3" (click)="updateSMSFlag('True')" [ngClass]="{'selected': flag3.value === 'True'}" value="True" class="btn-sms download-button-wrapper button-overdue" value="True"/>
                                <input class="form-control" type="button" name="flag3" (click)="updateSMSFlag('False')" [ngClass]="{'selected': flag3.value === 'False'}" value="False" class="btn-sms modal-button-wrapper button-overdue" value="False"/>
                            </div>
                        </div>

                        <div class="col-sm-12 form-buttons">
                            <div class="btn float-right clear-btn" (click)="reset()" data-dismiss="modal">Cancel</div>
                            <button type="submit" class="btn green float-right"
                            *ngIf="authService.isAuthorized(PermissionsEnum.revenueprotection_settings_createupdate)" data-toggle="modal" data-target="#saveChangesModal" [disabled]="!f.valid">
                            Save</button>
                        </div>
                        <button #closeModal [hidden]="true" data-toggle="modal" data-target="#myModal" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="saveChangesModal" tabindex="-1" role="dialog" aria-labelledby="saveChanges" aria-hidden="true" click-stop-propagation>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content save-changes-modal">
            <div class="modal-header">
                <div class="title-wrapper">
                    <span class="modal-title upload-modal-title" id="exampleModalLabel">Are you sure you want to save changes?</span>
                    <div class="modal-button-wrapper">
                        <button type="button" class="btn send-btn-green modal-button-wrapper" (click)="save()" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn send-btn-red modal-button-wrapper" data-dismiss="modal">No</button>
                    </div>
                    <button #closeModal [hidden]="true" data-toggle="modal" data-target="#myModal" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
