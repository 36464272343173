import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ss-map-dialog',
  templateUrl: './ss-map-dialog.component.html',
  styleUrls: ['./ss-map-dialog.component.scss', '.././map.component.scss']
})
export class SsMapDialogComponent {

  @Input()
  public ssData!: any;
}
