import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DeviceManagementPages } from 'src/app/models/meter-enums.model';
import { AccountService } from 'src/app/services/account.service';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';
import { MultipleAmisPopUpComponent } from './multiple-amis-pop-up/multiple-amis-pop-up.component';

@Component({
  selector: 'app-load-profile',
  templateUrl: './load-profile.component.html',
  styleUrl: './load-profile.component.scss'
})
export class LoadProfileComponent {

  public accountOrMSNValue: string = '';
  public invalidAccount: boolean = false;
  showInvalidInputError = false

  // when amiId != -1 it is an indication that there are multiple meters with particular MSN
  amiId: number = -1;
  data: any;
  meter: any;
  meterId:number=0;

  @ViewChild(MultipleAmisPopUpComponent) multipleAmisPopUp!: MultipleAmisPopUpComponent;

  public initialScreen: boolean = true

  public utName: string = this.translationService.getByKeyFromCache('UT');
  public buName: string = this.translationService.getByKeyFromCache('BU');

  @Output() confirmUpload: EventEmitter<void> = new EventEmitter<void>();
  public file: any = null;
  fileName:string="";

  constructor(private accService: AccountService,
    private toastr: ToastrService,
    private meterService: MeterService,
    private translationService: TranslationService) { }

  search() {
    if (this.accountOrMSNValue == '') {
      this.showInvalidInputError = true
      return
    }

    let obj = {
      MSN: this.accountOrMSNValue,
      AmiId: this.amiId
    }
    this.getAccBySearch(obj)
  }

  accValueChanged() {
    if (this.accountOrMSNValue != '') {
      this.showInvalidInputError = false
    }
  }

  save() {
    let obj = {
      MeterId: this.meterId,
      VoltageMinLoadLimit: this.meter.voltageMinLoadLimit,
      VoltageMaxLoadLimit: this.meter.voltageMaxLoadLimit,
      CurrentMinLoadLimit: this.meter.currentMinLoadLimit,
      CurrentMaxLoadLimit: this.meter.currentMaxLoadLimit
    }

    if ((obj.VoltageMinLoadLimit >= obj.VoltageMaxLoadLimit && obj.VoltageMaxLoadLimit) ||
        obj.VoltageMinLoadLimit == 0 ||
        obj.VoltageMaxLoadLimit == 0 || obj.VoltageMaxLoadLimit >= 1000) {
      return
    }
    if ((obj.CurrentMinLoadLimit >= obj.CurrentMaxLoadLimit && obj.CurrentMaxLoadLimit) ||
      obj.CurrentMinLoadLimit == 0 ||
      obj.CurrentMaxLoadLimit == 0 || obj.CurrentMaxLoadLimit >= 1000) {
      return
    }

    this.meterService.save(obj).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getTemplate() {
    this.meterService.getTemplate(DeviceManagementPages.LoadProfile).subscribe(response => {
      if (response.status == 200){
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  setMetersAmi(amiId: any) {
    this.amiId = amiId
    let obj = {
      MSN: this.accountOrMSNValue,
      amiId: amiId
    }
    this.meterService.getMeterIdFromMSNAndAmi(obj).subscribe(response => {
      if (response.status === 200) {
        this.meterId = response.data
        this.getMeter(true)
      } else if (response.status === 400) {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getMeter(getAcc = false) {
    this.meterService.get(this.meterId).subscribe(response => {
      if (response.status === 200) {
        this.meter = response.data
        let obj = {
          MSN: this.accountOrMSNValue,
          AmiId: this.amiId
        }
        if (getAcc) {
          this.getAccBySearch(obj)
        }
        this.amiId = -1
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getAccBySearch(obj: any) {
    this.accService.getAccBySearch(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data
        if (response.data.multipleAmis) {
          this.multipleAmisPopUp.changeState()
          let obj = {
            searchText: this.accountOrMSNValue
          }
          this.meterService.getAllAmisWithMSN(obj).subscribe(response => {
            response.data.forEach((element: any) => {
              element.checked = false
            });
            this.multipleAmisPopUp.amis = response.data
          })
        } else {
          this.accService.getMeterIdForAccountNo(this.data.accountNo).subscribe(response => {
            if (response.status === 200) {
              this.meterId = response.data
              this.initialScreen = false
              this.getMeter()
            } else {
              this.toastr.error(response.message)
            }
          }, _ => {
            this.toastr.error('An error occurred.')
          })
        }
      }
      else if (response.status === 400 && !response.data.multipleAmis) {
        this.toastr.error(response.message)
      } else if (response.status === 404) {
        this.invalidAccount = true;
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  onFileChosen(event: any){
    this.file = event.target.files[0];
    this.fileName = this.file.name;
  }

  uploadFile() {
    if(!this.file) {
      this.toastr.error('You have to choose file for upload.');
      return;
    }
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("uploadFileType", DeviceManagementPages.LoadProfile.toString());

    this.meterService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.fileName = "";
        this.file = null
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  export() {
    this.meterService.download(this.meterId).subscribe(response => {
      if (response.status == 200){
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }

  searchAgain() {
    this.initialScreen = true;
    this.accountOrMSNValue = '';
    if (this.data != null)
      this.invalidAccount = false
  }
}
