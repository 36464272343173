import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './components/account/account/account.component';
import { LoginComponent } from './components/login/login.component';
import { TranslationPageComponent } from './components/translation-page/translation-page.component';

import { DashboardFieldOperationsNewComponent } from './components/field-operations-modules/dashboard-field-operations-new/dashboard-field-operations-new.component';
import { SettingsFieldComponent } from './components/field-operations-modules/settingsField/settingsField.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { ManualReadComponent } from './components/manual-read/manual-read.component';
import { MeterManagementItems } from './components/meter-management/common/meter-management-sidenav/meter-management-sidenav.component';
import { CustomersMapComponent } from './components/meter-management/customers-map/customers-map.component';
import { ResolutionTestComponent } from './components/resolution-test/resolution-test.component';
import { ThanksgivingComponent } from './components/thanksgiving/thanksgiving.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { FieldOperationsLayoutComponent } from './layout/field-operations-layout/field-operations-layout.component';
import { GlobalLayoutComponent } from './layout/global-layout/global-layout.component';
import { MeterManagementLayoutComponent } from './layout/meter-management-layout/meter-management-layout.component';
import { NetworkManagemenetLayoutComponent } from './layout/network-managemenet-layout/network-managemenet-layout.component';
import { NotFoundPageComponent } from './layout/not-found-page/not-found-page.component';
import { RevenueProtectionLayoutComponent } from './layout/revenue-protection-layout/revenue-protection-layout.component';
import { PermissionsEnum } from './models/permissionsEnum';
import { AuthGuard } from './services/guards/auth-guard';
import { ModuleGuard } from './services/guards/module-guard.guard';


/********************************************Adora new design ************************************************* */
import { CommercialComponent } from './components/commercial/commercial.component';
import { GlobalLandingPageNewComponent } from './components/global-landing-page-new/global-landing-page-new.component';
import { NetworkManagementDashboardNewComponent } from './components/network-management-dashboard-new/network-management-dashboard-new.component';
import { MeterBypassComponent } from './components/revenue-protection-new/customer-watchlist/meter-bypass/meter-bypass.component';
import { OverdueBillArearsComponent } from './components/revenue-protection-new/customer-watchlist/overdue-bill-arears/overdue-bill-arears.component';
import { DashboardRevenueProtectionComponent } from './components/revenue-protection-new/dashboard-revenue-protection/dashboard-revenue-protection.component';
import { PaymentCollectionsComponent } from './components/revenue-protection-new/debt-management/payment-collections/payment-collections.component';
import { UserProfileManagamentComponent } from './components/user-profile-managament/user-profile-managament.component';

import { GridEnergyProfileNewComponent } from './components/grid-energy-profile-new/grid-energy-profile-new.component';
import { TrendAnalysesComponent } from './components/network-management-new/trend-analyses/trend-analyses.component';
import { WatchlistComponent } from './components/network-management-new/watchlist/watchlist.component';
import { StatementOfAccountComponent } from './components/revenue-protection-new/debt-management/statement-of-account/statement-of-account.component';

import { OutageNewComponent } from './components/outage-new/outage-new.component';

import { environment } from '../environments/environment';
import { AgentDetailsComponent } from './components/agents-teams/agents/agent-details/agent-details.component';
import { CreateAgentComponent } from './components/agents-teams/agents/create-agent/create-agent.component';
import { TeamsAndAgentsComponent } from './components/agents-teams/teams-and-agents/teams-and-agents.component';
import { CreateTeamManagementComponent } from './components/agents-teams/teams/create-team-management/create-team-management.component';
import { TeamDetailsComponent } from './components/agents-teams/teams/team-details/team-details.component';
import { AssetEnergyGraphNewComponent } from './components/asset-energy-graph-new/asset-energy-graph-new.component';
import { AuditLogNewComponent } from './components/audit-log-new/audit-log-new.component';
import { FieldSidebarItemsNew } from './components/common-new-design/field-operations-sidemenu/field-operations-sidemenu.component';
import { SidebarItemsNew } from './components/common-new-design/network-management-sidemenu/network-management-sidemenu.component';
import { RevenueSidebarItems } from './components/common-new-design/sidemenu/sidemenu.component';
import { AccountTabComponent } from './components/crud-tabs/account-tab/account-tab.component';
import { AmiTabComponent } from './components/crud-tabs/ami-tab/ami-tab.component';
import { BusinessUnitTabComponent } from './components/crud-tabs/business-unit-tab/business-unit-tab.component';
import { CrudTabsComponent } from './components/crud-tabs/crud-tabs.component';
import { AssetPerformanceNewComponent } from './components/field-operations-modules/asset-performance-new/asset-performance-new.component';
import { PredictiveAnalyticsNewComponent } from './components/field-operations-modules/asset-performance-new/predictive-analytics-new/predictive-analytics-new.component';
import { FieldModalSettingsComponent } from './components/field-operations-modules/field-modal-settings/field-modal-settings.component';
import { InventoryManagementComponent } from './components/field-operations-modules/inventory-management/inventory-management.component';
import { WomCrudComponent } from './components/field-operations-modules/wom-crud/wom-crud.component';
import { WorkOrderManagementNewComponent } from './components/field-operations-modules/work-order-management-new/work-order-management-new.component';
import { GlobalSidebarItems } from './components/global-landing-page-new/global-sidemenu/global-sidemenu.component';
import { GlobalSettingsComponent } from './components/global-landing-page/global-settings/global-settings.component';
import { IdentityLoginComponent } from './components/identity-login/identity-login.component';
import { JobStatusHistoryNewComponent } from './components/job-status-history-new/job-status-history-new.component';
import { SetPasswordNewComponent } from './components/login/set-password-new/set-password-new.component';
import { AccountListNewComponent } from './components/meter-management-new/account-list-new/account-list-new.component';
import { AccountListPopupComponent } from './components/meter-management-new/account-list-popup/account-list-popup.component';
import { CustomerVendsComponent } from './components/meter-management-new/customer-vends/customer-vends.component';
import { DcuNewComponent } from './components/meter-management-new/dcu-new/dcu-new.component';
import { DeviceManagementComponent } from './components/meter-management-new/device-management/device-management.component';
import { MeterDashboardComponent } from './components/meter-management-new/meter-dashboard/meter-dashboard.component';
import { AssetMeterComponent } from './components/meter-management-new/meters/asset-meter/asset-meter.component';
import { MetersComponent } from './components/meter-management-new/meters/meters.component';
import { UnmeteredComponent } from './components/meter-management-new/unmetered/unmetered.component';
import { MeterTriggersPageComponent } from './components/meter-management/meter-triggers-page/meter-triggers-page.component';
import { NetworkModalSettingsComponent } from './components/network-management-new/network-modal-settings/network-modal-settings.component';
import { InvalidTfeReadsComponent } from './components/network-management-new/reports/invalid-tfe-reads/invalid-tfe-reads.component';
import { CurrentActiveAssetComponent } from './components/outage-availability/Current-active/current-active-asset/current-active-asset.component';
import { NonCommunicatingComponent } from './components/outage-availability/non-communicating/non-communicating/non-communicating.component';
import { ProcessLoginComponent } from './components/process-login/process-login.component';
import { ReportsComponent } from './components/reports/reports.component';
import { BillsComponent } from './components/revenue-protection-new/bills/bills.component';
import { ConfigurationSettingsComponent } from './components/revenue-protection-new/configuration-settings/configuration-settings.component';
import { MeterBypassPostpaidDetailsComponent } from './components/revenue-protection-new/customer-watchlist/meter-bypass-postpaid/meter-bypass-postpaid-details/meter-bypass-postpaid-details.component';
import { MeterBypassPostpaidComponent } from './components/revenue-protection-new/customer-watchlist/meter-bypass-postpaid/meter-bypass-postpaid.component';
import { MeterBypassDetailsComponent } from './components/revenue-protection-new/customer-watchlist/meter-bypass/meter-bypass-details/meter-bypass-details.component';
import { PaymentChannelComponent } from './components/revenue-protection-new/payment-channel/payment-channel.component';
import { PaymentsNewComponent } from './components/revenue-protection-new/payments-new/payments-new.component';
import { ReportsNewComponent } from './components/revenue-protection-new/reports-new/reports-new.component';
import { RevenueModalSettingsComponent } from './components/revenue-protection-new/revenue-modal-settings/revenue-modal-settings.component';
import { VendingsComponent } from './components/revenue-protection-new/vendings/vendings.component';
import { OverdueBillConfigurationComponent } from './components/revenue-protection/settings/overdue-bill-configuration/overdue-bill-configuration.component';
import { SettingsRevenueComponent } from './components/revenue-protection/settings/settings-revenue/settings.component';
import { RoleManagementNewComponent } from './components/role-management-new/role-management-new.component';
import { SettingsItems, SettingsSidebarComponent } from './components/settings-sidebar/settings-sidebar.component';
import { TariffSettingsComponent } from './components/tariff-settings/tarrif-settings.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { ComponentNamesNew } from './models/crudsNew.model';
import { PlannedOutageComponent } from './components/outage-new/planned-outage/planned-outage/planned-outage.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component:  environment.useOpenIdDict ? IdentityLoginComponent : LoginComponent, pathMatch: 'full' },
  { path: 'process-login', component: ProcessLoginComponent },
  { path: 'resolution-test', component: ResolutionTestComponent, pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full' },
  { path: 'thanksgiving', component: ThanksgivingComponent, pathMatch: 'full' },
  { path: 'password-new-user', component: SetPasswordNewComponent, pathMatch: 'full' },
  { path: 'global-landing-page', component: GlobalLandingPageNewComponent, data: { showIcon: false, showSearchInput: false, showSwitchModule: false }, pathMatch: 'full', canActivate: [AuthGuard] },

  { path: 'commercial', component: CommercialComponent, data: { showIcon: false, showSearchInput: false, currentModule: 'Commercial' }, pathMatch: 'full' },
  {
    path: 'global', component: GlobalLayoutComponent, data: { showSwitchModule: false }, children: [
      {
        path: 'settings', component: GlobalSettingsComponent, canActivate: [ModuleGuard], data: { module: ['settings'], showSearchInput: false, showSwitchModule: false }, children: [
          //CRUDs
        ]
      },

      {
        path: 'jobstatus-history', component: JobStatusHistoryNewComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: GlobalSidebarItems.Reports, permissions: [PermissionsEnum.global_settings_jobstatus_view] }
      },
      {
        path: 'user-management', component: UserManagementComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: GlobalSidebarItems.Settings, componentName: ComponentNamesNew.UserManagementComponent, permissions: [PermissionsEnum.global_settings_user_view], showSwitchModule: false }
      },
      {
        path: 'account', component: AccountComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: GlobalSidebarItems.Settings, componentName: ComponentNamesNew.AccountComponent, permissions: [PermissionsEnum.global_settings_view], showSwitchModule: false }
      },

      {
        path: 'user-profile', component: UserProfileManagamentComponent, canActivate: [AuthGuard],
        data: { sidebarItem: SettingsItems.UserProfileManagamentComponent, showSearchInput: false, showSwitchModule: false }
      },
      {
        path: 'account-settings', component: AccountTabComponent, canActivate: [AuthGuard],
        data: { sidebarItem: SettingsItems.AccountTabComponent, showSearchInput: false, showSwitchModule: false }
      },
      {
        path: 'audit-log-new', component: AuditLogNewComponent, canActivate: [AuthGuard],
        data: { sidebarItem: SettingsItems.AuditLogNewComponent, showSearchInput: false, showSwitchModule: false }
      },
      {
        path: 'role-management-new', component: RoleManagementNewComponent, canActivate: [AuthGuard],
        data: { sidebarItem: SettingsItems.RoleManagementNewComponent, showSearchInput: false, showSwitchModule: false }
      },
      {
        path: 'user-settings', component: UserSettingsComponent, canActivate: [AuthGuard],
        data: { sidebarItem: SettingsItems.UserSettingsComponent, showSearchInput: false, showSwitchModule: false }
      },

      {
        path: 'jobstatus-history', component: JobStatusHistoryNewComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: SettingsItems.JobStatusHistoryNewComponent, permissions: [PermissionsEnum.global_settings_jobstatus_view], showSwitchModule: false }
      },

      {
        path: 'crud-settings', component: CrudTabsComponent, canActivate: [AuthGuard],
        data: { sidebarItem: SettingsItems.CrudTabsComponent, showSearchInput: false, showSwitchModule: false }
      },
      {
        path: 'tariff-settings', component: TariffSettingsComponent, canActivate: [AuthGuard],
        data: { sidebarItem: SettingsItems.TarrifSettingsComponent, showSearchInput: false, showSwitchModule: false }
      },
      {
        path: 'ami-settings', component: AmiTabComponent, canActivate: [AuthGuard],
        data: { sidebarItem: SettingsItems.UserSettingsComponent, showSearchInput: false, showSwitchModule: false }
      },
      {
        path: 'business-unit-settings', component: BusinessUnitTabComponent, canActivate: [AuthGuard],
        data: { SidebarItems: SettingsItems.UserSettingsComponent, showSearchInput: false, showSwitchModule: false }
      },
      {
        path: 'translation-page', component: TranslationPageComponent, canActivate: [AuthGuard],
        data: { SidebarItems: SettingsItems.TranslationPageComponent, showSearchInput: false, showSwitchModule: false }
      },
    ]
  },

  // network-management
  {
    path: 'network-management', component: NetworkManagemenetLayoutComponent, canActivate: [ModuleGuard], data: { module: ['networkmanagement_'], currentModule: 'Network Management' }, children: [
      {
        path: 'dashboard', component: NetworkManagementDashboardNewComponent, pathMatch: 'full',
        data: { sidebarItem: SidebarItemsNew.Dashboard, showSearchInput: false, module: 'Network Management' }, canActivate: [AuthGuard]
      },
      {
        path: 'grid-energy-profile', component: GridEnergyProfileNewComponent, pathMatch: 'full',
        data: { sidebarItem: SidebarItemsNew.GridEnergyProfile, componentName: ComponentNamesNew.ConsumptionComponent, permissions: [PermissionsEnum.networkmanagement_gridenergyprofile_view], module: 'Network Management' }, canActivate: [AuthGuard]
      },
      {
        path: 'reports', component: ReportsComponent, pathMatch: 'full', data: { module: 'Network Management'}, canActivate: [AuthGuard]
      },
      {
        path: 'invalid-tfe-reads', component: InvalidTfeReadsComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: GlobalSidebarItems.Settings, componentName: ComponentNamesNew.InvalidTfeRead, permissions: [PermissionsEnum.networkmanagement_reports_view], module: 'Network Management' }
      },
      {
        path: 'manual-read', component: ManualReadComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: GlobalSidebarItems.Settings, componentName: ComponentNamesNew.ManualRead, permissions: [PermissionsEnum.global_settings_view], module: 'Network Management' }
      },
      {
        path: 'trend', component: TrendAnalysesComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { showSearchInput: false, module: 'Network Management' }
      },


      //GRAPHS
      {
        path: 'trend-analysis', component: TrendAnalysesComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: SidebarItemsNew.HistoricalTrend, showSearchInput: false, module: 'Network Management' }
      },
      {
        path: 'asset-energy-graph', component: AssetEnergyGraphNewComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: SidebarItemsNew.AssetComparison, showSearchInput: false, permissions: [PermissionsEnum.networkmanagement_asset_comparison_view], module: 'Network Management' }
      },
      {
        path: 'watchlist-graph', component: WatchlistComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: SidebarItemsNew.WatchList, showSearchInput: false, module: 'Network Management' }
      },



      { path: 'outage', component: OutageNewComponent, pathMatch: 'full', data: { sidebarItem: SidebarItemsNew.Outage, showSearchInput: false, module: 'Network Management' }, canActivate: [AuthGuard] },
      { path: 'planned-outage', component: PlannedOutageComponent, pathMatch: 'full', data: { sidebarItem: SidebarItemsNew.PlannedOutage, showSearchInput: false, module: 'Network Management' }, canActivate: [AuthGuard] },
      {
        path: 'modal-settings', component: NetworkModalSettingsComponent, pathMatch:'full', canActivate: [AuthGuard],
        data: { sidebarItem: SidebarItemsNew.ModalSettings, showSearchInput: false, module: 'Network Management'}
      },
      {
        path: 'general-settings', component: SettingsSidebarComponent, pathMatch:'full', canActivate: [AuthGuard],
        data: { sidebarItem: SidebarItemsNew.GeneralSettings, showSearchInput: false, module: 'Network Management'}
      },
      { path: 'current-active', component: CurrentActiveAssetComponent, pathMatch: 'full', data: {sidebarItem: SidebarItemsNew.CurrentActiveAssetComponent,  showSearchInput: false, module: 'Network Management' }, canActivate: [AuthGuard] },
      { path: 'non-comm', component: NonCommunicatingComponent, pathMatch: 'full', data: { sidebarItem: SidebarItemsNew.NonCommunicatingComponent, showSearchInput: false, module: 'Network Management' }, canActivate: [AuthGuard] },

    ]
  },

   //REVENUE PROTECTION
   {
    path: 'revenue-protection', component: RevenueProtectionLayoutComponent, canActivate: [ModuleGuard], data: { module: ['revenueprotection_'], currentModule: 'Commercial' }, children: [
      {
        path: 'meter-bypass', component: MeterBypassComponent, canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.MeterBypass, showSearchInput: false, permissions: [PermissionsEnum.revenueprotection_meterbypass_view], module: 'Commercial' }
      },
      {
        path: 'postpaid-meter-bypass', component: MeterBypassPostpaidComponent, canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.PostpaidMeterBypass, showSearchInput: false, permissions: [PermissionsEnum.revenueprotection_meterbypass_postpaid_view], module: 'Commercial' }
      },
      {
        path: 'meter-bypass-details/:accId/:period', component: MeterBypassDetailsComponent, canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.MeterBypass, showSearchInput: false, permissions: [PermissionsEnum.revenueprotection_meterbypass_view], module: 'Commercial' }
      },
      {
        path: 'meter-bypass-postpaid-details/:accId/:period', component: MeterBypassPostpaidDetailsComponent, canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.MeterBypass, showSearchInput: false, permissions: [PermissionsEnum.revenueprotection_meterbypass_postpaid_view], module: 'Commercial' }
      },
      {
        path: 'overdue-bill-arears', component: OverdueBillArearsComponent, canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.OverdueBillArears, showSearchInput: false, permissions: [PermissionsEnum.revenueprotection_overduebillarears_view], module: 'Commercial' }
      },
      {
        path: 'dashboard', component: DashboardRevenueProtectionComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.Dashboard, showSearchInput: false, module: 'Commercial' }
      },
      {
        path: 'payment-collections', component: PaymentCollectionsComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.PaymentCollections, showSearchInput: false, permissions: [PermissionsEnum.revenueprotection_paymentcollections_view], module: 'Commercial' }
      },
      {
        path: 'statement-of-account', component: StatementOfAccountComponent, canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.StatementAccount, showSearchInput: false, permissions: [PermissionsEnum.revenueprotection_statementofacc_view], module: 'Commercial' }
      },
      {
        path: 'modal-settings', component: RevenueModalSettingsComponent, pathMatch:'full', canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.ModalSettings, showSearchInput: false, module: 'Commercial'}
      },
      {
        path: 'general-settings', component: SettingsSidebarComponent, pathMatch:'full', canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.GeneralSettings, showSearchInput: false, module: 'Commercial'}
      },


      //SETTINGS REVENUE PROTECTION
      {
        path: 'settingsRevenue', component: SettingsRevenueComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.ModalSettings, showSearchInput: false, permissions: [PermissionsEnum.revenueprotection_settings_view], module: 'Commercial' }
      },
      {
        path: 'channels', component: PaymentChannelComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.ModalSettings, permissions: [PermissionsEnum.revenueprotection_settings_view], module: 'Commercial' }
      },
      {
        path: 'overdue-bill-configuration', component: OverdueBillConfigurationComponent, canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.ModalSettings, permissions: [PermissionsEnum.revenueprotection_settings_view], module: 'Commercial' }
      },
      {
        path: 'configuration', component: ConfigurationSettingsComponent, canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.ModalSettings, permissions: [PermissionsEnum.revenueprotection_settings_view], module: 'Commercial' }
      },

      //REPORTS REVENUE PROTECTION
      {
        path: 'reports', component: ReportsNewComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.Reports, showSearchInput: false, permissions: [PermissionsEnum.revenueprotection_reports_view], module: 'Commercial' }
      },
      {
        path: 'vendings', component: VendingsComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.Reports, permissions: [PermissionsEnum.revenueprotection_reports_view], module: 'Commercial' }
      },
      {
        path: 'bills', component: BillsComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.Reports, permissions: [PermissionsEnum.revenueprotection_reports_view], module: 'Commercial' }
      },
      {
        path: 'payments', component: PaymentsNewComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: RevenueSidebarItems.Reports, permissions: [PermissionsEnum.revenueprotection_reports_view], module: 'Commercial' }
      },
    ]
  },

  // Meter management
  {

    path: 'meter-management', component: MeterManagementLayoutComponent, canActivate: [ModuleGuard], data: { module: ['metermanagement_'], currentModule: 'Meter management' }, children: [
      {
        path: 'dashboard', component: MeterDashboardComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.Dashboard, module: 'Meter management' }
      },


      {
        path: 'vends', component: CustomerVendsComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { module: 'Meter management'}
      },
      {
        path: 'meters', component: MetersComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.Meters, module: 'Meter management'}
      },
      {
        path: 'asset-meter', component: AssetMeterComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.AssetMeters, module: 'Meter management'}
      },
      {
        path: 'unmetered', component: UnmeteredComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.UnmeteredComponent, module: 'Meter management'}
      },
      {
        path: 'meter-triggers', component: MeterTriggersPageComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.MeterTriggers, module: 'Meter management'}
      },
      {
        path: 'account-list', component: AccountListNewComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.UnmeteredComponent, module: 'Meter management' }
      },
      {
        path: 'account-list-popup/:accId', component: AccountListPopupComponent, canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.UnmeteredComponent, showSearchInput: false, module: 'Meter management' }
      },
      {
        path: 'map', component: CustomersMapComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.UnmeteredComponent, module: 'Meter management' }
      },
      {
        path: 'device-management', component: DeviceManagementComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.DeviceManagement, module: 'Meter management' }
      },
      {
        path: 'dcu', component: DcuNewComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.Dcu, module: 'Meter management' }
      },
      {
        path: 'modal-settings', component: NetworkModalSettingsComponent, pathMatch:'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.ModalSettings, showSearchInput: false, module: 'Meter management'}
      },
      {
        path: 'general-settings', component: SettingsSidebarComponent, pathMatch:'full', canActivate: [AuthGuard],
        data: { sidebarItem: MeterManagementItems.GeneralSettings, showSearchInput: false, module: 'Meter management'}
      }
    ]
  },

  // Field Operations
  {
    path: 'field-operations', component: FieldOperationsLayoutComponent, canActivate: [ModuleGuard], data: { module: ['fieldoperations_'], currentModule: 'Field Operations' }, children: [
      {
        path: 'dashboard', component: DashboardFieldOperationsNewComponent, pathMatch: 'full',
        data: { sidebarItem: SidebarItemsNew.Dashboard, showSearchInput: false, module: 'Field Operations' }, canActivate: [AuthGuard]
      },
       {
        path: 'work-order-management-new', component: WorkOrderManagementNewComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: FieldSidebarItemsNew.WorkOrderManagement, module: 'Field Operations' }
      },
      {
        path: 'modal-settings', component: FieldModalSettingsComponent, pathMatch:'full', canActivate: [AuthGuard],
        data: { sidebarItem: FieldSidebarItemsNew.ModalSettings, showSearchInput: false, module: 'Field Operations'}
      },
      {
        path: 'general-settings', component: SettingsSidebarComponent, pathMatch:'full', canActivate: [AuthGuard],
        data: { sidebarItem: FieldSidebarItemsNew.GeneralSettings, showSearchInput: false, module: 'Field Operations'}
      },
      {
        path: 'wom-category', component: WomCrudComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: FieldSidebarItemsNew.ModalSettings, module: 'Field Operations' }
      },

      {
        path: 'settingsField', component: SettingsFieldComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: FieldSidebarItemsNew.ModalSettings, permissions: [PermissionsEnum.fieldoperations_settings_view], module: 'Field Operations' }
      },

      {
        path: 'work-order-teams', component: TeamsAndAgentsComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: {  sidebarItem: FieldSidebarItemsNew.TeamsAndAgentsComponent,  permissions: [PermissionsEnum.fieldoperations_settings_view], module: 'Field Operations' }
      },
      {
        path: 'team-details/:id', component: TeamDetailsComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { permissions: [PermissionsEnum.fieldoperations_settings_view], module: 'Field Operations' }
      },
      {
        path: 'agent-details/:id', component: AgentDetailsComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { permissions: [PermissionsEnum.fieldoperations_settings_view], module: 'Field Operations' }
      },
      {
        path: 'team-create', component: CreateTeamManagementComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { module: 'Field Operations'}
      },
      {
        path: 'agent-create', component: CreateAgentComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { module: 'Field Operations' }
      },

      {
        path: 'asset-performance-new', component: AssetPerformanceNewComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: FieldSidebarItemsNew.AssetPerformanceNewComponent, module: 'Field Operations' }
      },
      {
        path: 'predictive-analytics', component: PredictiveAnalyticsNewComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: {
          sidebarItem: FieldSidebarItemsNew.PredictiveAnalyticsNewComponent,
          permissions: [PermissionsEnum.fieldoperations_assetperformance_predictiveanalytics_view], module: 'Field Operations' }
      },
      {
        path: 'inventory-management', component: InventoryManagementComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: { sidebarItem: FieldSidebarItemsNew.InventoryManagement, module: 'Field Operations' }
      },

    ]
  },

  {
    path: '**', pathMatch: 'full',
    component: NotFoundPageComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
