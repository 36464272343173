import { Component } from '@angular/core';

@Component({
  selector: 'app-revenue-protection-layout',
  templateUrl: './revenue-protection-layout.component.html',
  styleUrls: ['./revenue-protection-layout.component.scss']
})
export class RevenueProtectionLayoutComponent {

  expandSidebar: boolean = true;

}
