import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { Translation } from '../models/translation.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private pathApi = this.config.setting['pathApi'] + "translation/";

  constructor(private http: HttpClient,
    private config: AppConfig) {  }

    getAll(): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, null);
    }

    getByKeyFromCache(key:string){
        let translations : Translation[] = JSON.parse(localStorage.getItem('translations') as string);

        if(translations != null && translations.filter(x => x.key == key).length != 0) {
          return translations.filter(x => x.key == key)[0].value;
        }
        else {
          return "";
        }
    }

    save(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}save`, obj);
    }
}
