import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash';
import { transformToString } from 'src/app/helpers/dateUtils';
import { DtService } from 'src/app/services/dt.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-dt-reads-settings',
  templateUrl: './dt-reads-settings.component.html',
  styleUrl: './dt-reads-settings.component.scss'
})
export class DtReadsSettingsComponent implements OnInit {

  public currentPage: number = 1
  public pageSize: number = 10
  public count: number = 0;
  public lastPageSize: number = 1

  public dateFrom: any = '';
  public dateTo: any = '';
  public dtFrom: Date = new Date();
  public dtTo: Date = new Date();
  public maxDate: Date = new Date();

  public search: string = '';
  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public searchPlaceholder: string = "Search by MSN or DT name";
  public searchText: SearchAutocomplete = new SearchAutocomplete();
  public searchLength: number = 1
  public options = [];
  
  public utName: string = this.translationService.getByKeyFromCache('UT');

  public showFilter: any = false
  public allFilters: any = []
  public filterApplied: boolean = false;
  public undertakings: any = { name: this.utName, data: [] };

  public utIds: any = [];

  public dtReadsData: any[] = [];

  constructor(
    private translationService: TranslationService,
    private undertakingService: UndertakingService,
    private toastr: ToastrService,
    private dtService: DtService
  ){}

  ngOnInit(): void {
    this.maxDate.setDate(this.maxDate.getDate() - 1);
    this.setMainDate()
    this.getUtsForSelect()
    this.reloadTable()
  }

  reloadTable(){
    let obj = {
      pageInfo: {
        pageSize: this.pageSize,
        page: this.currentPage,
      },
      filterParams: {
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        utIds: this.utIds,
        search: this.searchText ? this.searchText.name : this.searchText,
      }
    }
    this.dtService.getDtReads(obj).subscribe(response => {
      if (response.status === 200) {
        this.dtReadsData = response.data.data;
        if (obj.pageInfo.page == 1) {
          this.count = response.data.count;
          this.lastPageSize = Math.ceil(this.count / this.pageSize);
        }
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  getUtsForSelect(){
    this.undertakingService.getAllForSelect().subscribe(response => {
      this.undertakings = { name: this.utName, data: response.data,dropdown: false }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  checkFilters() {
    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakings.name);
    if (utIndex !== -1)
      this.allFilters[utIndex].data = this.undertakings.data;

    if(utIndex === -1)
      this.allFilters.push(this.undertakings)

    this.filterApplied = true
    this.allFilters = [...this.allFilters]
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    this.dtFrom.setDate(dte.getMonth());
    this.dtTo.setDate(dte.getDate() - 1);
    this.dateFrom = {year: this.dtFrom.getFullYear(), month: this.dtFrom.getMonth() + 1, day: 1};
    this.dateTo = {year: this.dtTo.getFullYear(), month: this.dtTo.getMonth() + 1, day: this.dtTo.getDate()};
  }

  updateDate(date: any) {
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.reloadTable()
  }

  searchChange(text: SearchAutocomplete) { 
    this.searchText = text;
    this.searchText = cloneDeep(this.searchText);
    this.currentPage = 1
    this.reloadTable();
  }

  getOptions(searchText: any) { 
    let obj = {
      searchText: searchText.name, 
      msnIncluded: true
    }
    this.searchChild.searchLength = this.searchLength;
    if (searchText.name.length > 2) {
      this.dtService.getDataForAutocomplete(obj)
      .subscribe({ 
        next : (response) => this.options = response.data,
        error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
      })
    } else {
      this.searchChild.resetOptions()
    }
  }

  pageChange(value: number) {
    this.currentPage = value
    this.reloadTable()
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)
      if (item.name === this.undertakings.name) {
        this.utIds = filteredValues
        this.currentPage = 1
      }
    }
    this.reloadTable()
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }
}
