import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { DtService } from 'src/app/services/dt.service';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';

@Component({
  selector: 'app-unmetered',
  templateUrl: './unmetered.component.html',
  styleUrl: './unmetered.component.scss'
})
export class UnmeteredComponent implements OnInit, AfterViewInit, OnChanges {

  public count: number = 0
  public pageSize: number = 10
  public currentPage: number = 1
  public lastPageSize: number = 1;
  public isMap: boolean = false;

  meterItemType: string = ''
  public meterIds: number[] = []
  public ssIds: number[] = []
  public f11Ids: number[] = []

  buIds: any = [];
  utIds: any = [];
  dtIds: any = [];

  // Search Autocomplete
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []
  public searchLength: number = 1

  @Input() search: SearchAutocomplete = new SearchAutocomplete()
  @Input() customerPrepaidFlag: boolean = true

  @ViewChild(SearchAutocompleteComponent) searchChild!: SearchAutocompleteComponent

  customerTypeValue: number = 0;

  public masterSelected: boolean = false;
  public ssName: string = "Substation"
  public f11Name: string = "Feeder 11"
  public tsName: string = "Transmission Station"
  public feederName: string = "Feeders"
  public customerName: string = "Customer"
  public metersId: any = [];
  public checkedList: any = [];
  public data: any;
  tabMenu: number = 1
  public amiName: string = this.translationService.getByKeyFromCache('AMI');
  public buName: string = this.translationService.getByKeyFromCache('BU').toUpperCase();
  public utName: string = this.translationService.getByKeyFromCache('UT').toUpperCase();
  public dtName: string = this.translationService.getByKeyFromCache('DT')

  @Input() undertakingsNew: any = { name: this.utName, data: [] };
  @Input() businessUnitsNew: any = { name: this.buName, data: [] };
  @Input() dtsMap: any = { name: this.dtName, data: [] };
  @Input() metersFilterFunction: ((param: any) => void) | null = null ;
  public substations: any = { name: this.ssName, data: [] };
  public transmissionStations: any = { name: this.tsName, data: [] };
  public feeders11New: any = { name: this.f11Name, data: [] };
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;

  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;

  public dateFrom: any = ''
  public dateTo: any = ''
  public searchPlaceholder: string = "Search by Account Number or Account Name"
  public postpaidCount: number = 0;
  public prepaidCount: number = 0;

  ngOnInit(): void {
    this.setMainDate()
    // this.getAllBUs()
    // this.getAllUTs()
    // this.getAllDTs()
  }
  constructor(private toastr: ToastrService,
    private translationService: TranslationService,
    private meterService: MeterService,
    private buService: BusinessUnitService,
    private utService: UndertakingService,
    private dtService: DtService, ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.businessUnitsNew || changes.undertakingsNew || changes.dtsMap){
      this.checkFilters()
    }
  }
  ngAfterViewInit(): void {
    if(this.customerPrepaidFlag)
      this.menuChanged(1)
    else{
      this.menuChanged(2)
    }

  }

    public GetUnmeteredCount() {
      let obj = {
        Search: this.searchText ? this.searchText.name : this.searchText,
        BuIds: this.buIds,
        DtIds: this.dtIds,
        UtIds: this.utIds,
      }
      this.meterService.GetUnmeteredCount(obj).subscribe(response => {
        this.postpaidCount = response.postpaidCount

        this.prepaidCount = response.prepaidCount

      }, _ => {
        this.toastr.error('An error occurred.');
      })

    }
  pageChange(value: number) {
    this.currentPage = value
    this.GetData()
  }

  //Check Filter for multi filter component
  checkFilters() {
    this.filterApplied = true;
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnitsNew.name);

    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnitsNew.name);
    if (buIndex !== -1) {
      this.allFilters[buIndex].data = this.businessUnitsNew.data;
    }
    let utData = this.allFilters.find((item: { name: any; }) => item.name === this.undertakingsNew.name);

    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakingsNew.name);
    if (utIndex !== -1) {
      this.allFilters[utIndex].data = this.undertakingsNew.data;
    }
    let dtData;
    dtData = this.allFilters.find((item: { name: any; }) => item.name === this.dtsMap.name);

    let dtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.dtsMap.name);
    if (dtIndex !== -1 && !this.isMap) {
      this.allFilters[dtIndex].data = this.dtsMap.data;
    }

    if (!buData)
      this.allFilters.push(this.businessUnitsNew)
    if (!utData)
      this.allFilters.push(this.undertakingsNew)
    if (!dtData && !this.isMap)
      this.allFilters.push(this.dtsMap)

    this.allFilters = [...this.allFilters]
  }

  getAllBUs() {
    this.buService.getAllForSelect().subscribe(response => {
      this.businessUnitsNew = { name: this.buName, data: response.data }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getAllUTs() {
    this.utService.getAllForSelect().subscribe(response => {
      this.undertakingsNew = { name: this.utName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getAllDTs() {
    this.dtService.getAllForSelect().subscribe(response => {
      this.dtsMap = { name: this.dtName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  menuChanged(value: number) {
    this.tabMenu = value;
    if (value == 1)
      this.meterItemType = 'Prepaid'
    else if (value == 2)
      this.meterItemType = 'Postpaid'

    this.checkFilters()
    if(!this.isMap)
       this.GetData()
  }
  // Apply Filter from Multi Filter
  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)
      if (item.name === this.substations.name) {
        this.ssIds = filteredValues
      }

      else if (item.name === this.undertakingsNew.name) {
        this.utIds = filteredValues
      }
      else if (item.name === this.businessUnitsNew.name) {
        this.buIds = filteredValues
      }
      else if (item.name === this.dtsMap.name) {
        this.dtIds = filteredValues
      }
    }
    this.pageChange(1)
    if(this.metersFilterFunction){
      this.metersFilterFunction(data)
    }
  }
  // set intital date for datepicker
  private setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()

    dteFrom.setHours(2, 0, 0);
    dte.setHours(2, 0, 0);
    dteFrom.setDate(dteFrom.getDate() - 1)

    this.dateFrom = { year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate() };
    this.dateTo = { year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate() };
  }
  resetFilters() {
    //this.searchPlaceholder = "Search by DT name or number";

    this.searchText = new SearchAutocomplete()
    if (this.searchChild) {
      this.searchChild.searchText = new SearchAutocomplete()
      this.searchChild.resetOptions()
    }
    this.setMainDate()

    //multiFilter
    this.meterIds = []
    this.ssIds = []
    this.customerTypeValue = 0
    this.f11Ids = []
    this.buIds = []
    this.utIds = []
    this.allFilters = []
    this.showFilter = false
    this.filterApplied = false
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }

    this.pageChange(1)
    this.GetUnmeteredCount()

  }
  searchChange(text: SearchAutocomplete) {
    this.searchText = text
    this.pageChange(1)
    this.GetUnmeteredCount()

  }


  downloadUnmeteredReport() {
    this.metersId = [];
    if (this.checkedList.length != 0) {
      this.metersId = this.checkedList.map((item: any) => item.id)
    }

    let obj = {
      Search: this.search ? this.search.name : this.search,
      CheckedList: this.metersId,
      BuIds: this.buIds,
      DownloadAll: this.meterIds,
      MeterItemType: this.meterItemType,
    };

    this.meterService.DownloadUnmeteredReport(obj).subscribe(response => {
      if (response.status == 200)
        window.location.href = response.data;
      else
        this.toastr.info(response.message)

    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  // Search Autocomplete
  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: false,
      MeterItemType: this.meterItemType
    }
    this.searchChild.searchLength = this.searchLength;
    if (searchText.name.length > 1) {
      this.meterService.getDataForAutocomplete(obj)
        .subscribe(response => {
          this.options = response.data
        })
    } else {
      this.searchChild.resetOptions()
    }
  }
  public GetData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        Search: this.searchText ? this.searchText.name : this.searchText,
        MeterItemType: this.meterItemType,
        CheckedList: this.metersId,
        UtIds: this.utIds,
        CustomerTypeValue: this.customerTypeValue,
        BuIds: this.buIds,
        DtIds: this.dtIds,
      }
    };
    this.meterService.getAllUnmetered(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data;
        if (this.currentPage == 1)
          this.count = response.data.count;
        this.lastPageSize = Math.ceil(this.count / this.pageSize);
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].checked)
        this.checkedList.push(this.data[i]);
    }
    return this.checkedList;
  }

  isAllSelected() {
    this.masterSelected = this.data.every(function (item: any) {
      return item.checked == true;
    })
    this.getCheckedItemList();
  }

  copyToClipboard(text: any) {
    const textToCopy = text;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    this.toastr.success('Copied to clipboard')

  }

  mapChanged(value: boolean) {
    console.log(value)
    this.isMap = value
    if (this.isMap) {
      //this.searchPlaceholder = "Search by meter number"

    }
    else if (!this.isMap) {
      // this.meterItemType = 'Prepaid'
      // this.subMenu = 'Active'
    }
    this.resetFilters()
    this.checkFilters()

  }


}
