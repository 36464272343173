import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-network-managemenet-layout',
  templateUrl: './network-managemenet-layout.component.html',
  styleUrls: ['./network-managemenet-layout.component.scss']
})
export class NetworkManagemenetLayoutComponent {

  expandSidebar: boolean = true;
  constructor(private router: Router) {
  }

  public urlContains(part: any) {
    return this.router.url.includes(part);
  }
}
