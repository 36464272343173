
 
<div class="table-wrapper">
    <table class="standard-table scroll-table">
        <thead>
            <tr>
                <th class="m-td">Time</th>
                <th class="m-td">Value</th>
                <th class="m-td">Read Type</th>
            </tr>
        </thead>
        <tbody class="table-body">
            <tr *ngFor="let item of filterReadsData" class="bodyRow clickable actions-click">
                <td title="{{item.time}}">{{item.time  }}</td>
                <td title="{{item.value}}">{{item.value}}</td>
                <td  title="{{item.readType}}">{{item.readType || "/" }}</td> 
            </tr>
        </tbody>
    </table>
    <div class="col-sm-6">
    </div>
</div>


              
