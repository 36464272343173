import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-switch-module-popup',
  templateUrl: './switch-module-popup.component.html',
  styleUrls: ['./switch-module-popup.component.scss']
})
export class SwitchModulePopupComponent implements OnInit {

  modules = [
    {
      name: 'Network Management',
      permissions: ['networkmanagement_'],
      isAuthorized: false,
      path: '/network-management/dashboard'
    }, 
    {
      name: 'Commercial',
      permissions: ['commercial_', 'revenueprotection_', 'billing_'],
      isAuthorized: false,
      path: '/commercial'
    }, 
    {
      name: 'Field Operations',
      permissions: ['fieldoperations_'],
      isAuthorized: false,
      path: '/field-operations/dashboard'
    },
    {
      name: 'Meter Management',
      permissions: ['metermanagement_'],
      isAuthorized: false,
      path: '/meter-management/dashboard'
    }
  ] 

  currentModule: string = '';
  availableModules: string[] = [];

  @Input() isPopupOpen: boolean = false;

  constructor(private route: ActivatedRoute, 
      public authService: AuthService, 
      private toastr: ToastrService,
      private userService: UserService) {
    this.currentModule = this.route.snapshot.data['currentModule'];
    this.modules = this.modules.filter(x => x.name != this.currentModule);

    this.modules.forEach(x => {
      for(let i = 0; i < x.permissions.length; i++){
        if(this.authService.isGroupAuthorized(x.permissions[i])){
          x.isAuthorized = true;
          break;
        }
      }
    });

    this.modules.sort((a, b) => {
      return (a.isAuthorized === b.isAuthorized) ? 0: a.isAuthorized ? -1 : 1
    })
  }

  ngOnInit(): void {
    this.getAvailableModules();
  }

  getAvailableModules() {
    this.availableModules = this.authService.getCurrentUser().availableModules;
  }

  changeState(number: Number){
    if (number === 1){
      this.isPopupOpen = true;
    }
    if (number === 2)
    {
      this.isPopupOpen = false;
    }
  }

  upgradePlan(module: string){
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_upgradeplan_allow)){
      this.toastr.error("You don't have permission for this request.");
    }
    else{
      this.userService.sendUpgradePlanMail(module).subscribe(response =>{
        if(response.status == 200){
          this.toastr.success(response.message)
        }
        else{
          this.toastr.error(response.message)
        }
      }, _=>{
        this.toastr.error('An error occurred.')
      })
    }
  }
}
