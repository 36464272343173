import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab-default-second',
  templateUrl: './tab-default-second.component.html',
  styleUrls: ['./tab-default-second.component.scss']
})
export class TabDefaultSecondComponent {

  @Input() firstText: string = 'First'
  @Input() secondText: string = 'Second'
  @Input() marginLeft: string = '0'
  @Input() marginBottom: string = '0'
  @Input() marginTop: string = '0'
  @Input() thirdText: string = ''
  @Input() forthText: string = ''
  @Input() fifthText: string = ''
  @Input() tabSelected = 1
  @Input() isInvalidAccount: boolean = false;

  @Input() firstImg: string = ''
  @Input() secondImg: string = ''

  @Input() firstTabNum: number = 0;
  @Input() secondTabNum: number = 0;
  @Input() thirdTabNum: number = 0;
  @Input() fourthTabNum: number = 0;
  @Input() fifthTabNum: number = 0;
  @Input() wom: boolean = false;
  @Input() womCss: boolean = false;
  @Input() paCss: boolean = false;

  @Output() tabChanged = new EventEmitter();

  changeTab(value: number) {
    this.tabSelected = value
    this.tabChanged.emit(this.tabSelected)
  }
}
