import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigFieldOperationService {

  private pathApi = this.config.setting['pathApi'] + "configurationFO/";

  constructor(private http: HttpClient,
    private config: AppConfig) {  }

    getAll(): Observable<any> {
      return this.http.get(`${this.pathApi}getConfiguration`);
    }
    getAllF11(): Observable<any> {
      return this.http.get(`${this.pathApi}getConfigurationF11`);
    }

    getAllF33(): Observable<any> {
      return this.http.get(`${this.pathApi}getConfigurationF33`);
    }
    saveConfiguration(config: any): Observable<any>{
      return this.http.post(`${this.pathApi}saveConfiguration`, config);
    }

    getPredictiveFlaggs(): Observable<any> {
      return this.http.get(`${this.pathApi}getPredictiveFlaggs`);
    }

    getPredictiveF11Flaggs(): Observable<any> {
      return this.http.get(`${this.pathApi}getPredictiveF11Flaggs`);
    }

    getPredictiveF33Flaggs(): Observable<any> {
      return this.http.get(`${this.pathApi}getPredictiveF33Flaggs`);
    }

}
