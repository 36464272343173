<app-category-create (parentComp)="reloadTable($event)"></app-category-create>

<main>
    <div class="user-profile">
        <div class="user-settings-content">
            <div class="title-and-desc">
                <div class="first">
                    <div class="title-u">
                        Task Category
                    </div>
                    <div class="task-description">
                        Create, read, update or delete Task Categories on field operations
                    </div>
                </div>
                <div class="second">
                    <div class="btn">
                        <button class="payment-btn" (click)="openTab()">
                            Create Category
                        </button>
                    </div>
                </div>
            </div>
            <div class="task-data">
                <div *ngFor="let item of categories" class="task-item bodyRow clickable actions-click"
                [ngClass]="{'selected': selectedCategory.id===item.id}">
                    <div class="task-first-part">
                        <div class="task-info">
                            <div class="task-name"><span>{{item.name | hasValue}}</span></div>
                        </div>
                    </div>
                    <div class="task-second-part">
                        <div class="delete">
                            <span *ngIf="authService.isAuthorized(PermissionsEnum.global_settings_delete)" (click)="openTabDelete(item.id)"><img alt="Delete" class="" src="assets/images-new-design/delete.svg" data-toggle="modal" data-target="#deleteModal"  /> </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pagination-wrapper">
                <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
                    [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
            </div>
        </div>
    </div>
</main>

<app-crud-delete-modal [selectedItem]="selectedCategory" (confirmDelete)="onDeleteConfirmed()"
    (cancelDelete)="resetSelectedCategory()">
</app-crud-delete-modal>
