import { Component, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-asset-performance-f33-popup',
  templateUrl: './asset-performance-f33-popup.component.html',
  styleUrls: ['./asset-performance-f33-popup.component.scss']
})
export class AssetPerformanceF33PopupComponent {
  public selectedAssetPerfomanceF33: any = {};

  public isPopupOpen: boolean = false;
  public buName: string = this.translationService.getByKeyFromCache('BU');
  constructor(private elementRef: ElementRef,
    private translationService : TranslationService,
    private toastr: ToastrService) { }

  closePopup() {
    this.isPopupOpen = false;
  }
  changeState( menu?: any,data?: any) {
     if (data)
      this.selectedAssetPerfomanceF33 = data;
    if (menu === 1)
      this.isPopupOpen=true;
    else
      this.isPopupOpen=false;

  }

  copyToClipboard() {
    const textToCopy = this.selectedAssetPerfomanceF33.f11no;
    navigator.clipboard.writeText(textToCopy)
    this.toastr.success('Copied to clipboard')
  }
  handleClick(event: any) {
    const isSliderClicked = event.target.closest('.slider');
    if (this.isPopupOpen) {
      if (this.elementRef.nativeElement.contains(event.target) || event.target === this.elementRef.nativeElement) {
        if (!isSliderClicked) {
          this.closePopup();
        }
      }
    }
  }
}
