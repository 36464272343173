import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { PaymentChannel } from '../models/paymentChannel.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentChannelService {

  private pathApi = this.config.setting['pathApi'] + "paymentChannel/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    GetById(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`);
    }  
    getAll(): Observable<any> {
      return this.http.get(`${this.pathApi}getAll`);
    }     
    getAllPayment(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAllPayment`, obj);
    }
    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`);
    } 
    save(obj: PaymentChannel): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }
}
