import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

import { TabItem } from 'src/app/models/tabItem.model';

@Component({
  selector: 'app-page-tabs',
  template: `
<mat-tab-group #tabGroup disableRipple animationDuration="0ms" [(selectedIndex)]="selectedTab" (selectedTabChange)="selectedTabChange.emit($event)">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <mat-tab [disabled]="tab.isDisabled">
      <ng-template class="tab-title-wrapper" mat-tab-label> 
        <span class="tab-title">
          {{ tab.label }}
        </span>
        <span class="tab-num" *ngIf="tab.num">
          {{ tab.num }}
        </span>
      </ng-template>
      <ng-container *ngIf="selectedTab === i">
         <ng-container *ngTemplateOutlet="tab.tabContentReference"></ng-container>
      </ng-container>
    </mat-tab>
  </ng-container>
</mat-tab-group>


  `,
  styleUrls: ['./page-tabs.component.scss']
})
export class PageTabsComponent {
  @Input() selectedTab: number = 0;
  @Input() isTabsDisabled: boolean = false;
  @Input() tabs: TabItem[] = [];
  @Output() selectedTabChange = new EventEmitter<MatTabChangeEvent>();
  @ViewChild('tabGroup') public tabGroup!: MatTabGroup;
}