<main class="container-fluid">
    <div class="container-wrapper">
        <div class="commercial-title">
            <div (click)="back()" class="back-button">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_7669_8469" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20"
                        height="20">
                        <rect width="20" height="20" transform="matrix(-1 0 0 1 20 0)" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_7669_8469)">
                        <path
                            d="M6.52067 10.8334H15.8332C16.0693 10.8334 16.2672 10.7536 16.4269 10.5938C16.5866 10.4341 16.6665 10.2362 16.6665 10.0001C16.6665 9.76398 16.5866 9.56606 16.4269 9.40634C16.2672 9.24662 16.0693 9.16676 15.8332 9.16676H6.52067L10.604 5.08342C10.7707 4.91676 10.8505 4.72231 10.8436 4.50009C10.8366 4.27787 10.7498 4.08342 10.5832 3.91676C10.4165 3.76398 10.2221 3.68412 9.99984 3.67717C9.77762 3.67023 9.58317 3.75009 9.4165 3.91676L3.9165 9.41676C3.83317 9.50009 3.77414 9.59037 3.73942 9.68759C3.7047 9.78481 3.68734 9.88898 3.68734 10.0001C3.68734 10.1112 3.7047 10.2154 3.73942 10.3126C3.77414 10.4098 3.83317 10.5001 3.9165 10.5834L9.4165 16.0834C9.56928 16.2362 9.76025 16.3126 9.98942 16.3126C10.2186 16.3126 10.4165 16.2362 10.5832 16.0834C10.7498 15.9168 10.8332 15.7188 10.8332 15.4897C10.8332 15.2605 10.7498 15.0626 10.5832 14.8959L6.52067 10.8334Z"
                            fill="#1C1B1F" />
                    </g>
                </svg>
            </div>
            <span class="commercial-page-title">Prepaid Meter Bypass Details</span>
        </div>
        <app-tab-default-second [firstText]="'Details'" [secondText]="'Customer Information'" [thirdText]="'Remark'"
            (tabChanged)="tabChanged($event)" [marginLeft]="'3.2rem'" [marginBottom]="'1.5rem'" [marginTop]="'1.5rem'">
        </app-tab-default-second>
        <div *ngIf="activeTab == ActiveTab.Table" class="bypass-info-cards-holder">
          <div class="mb-card-black">
            <div class="mb-card-data-wrapper">
              <div>Average Monthly Vend</div>
              <div class="bottom-info-wrapper">
                <div class="bottom-info-data-column">
                  <div class="bottom-info-top">
                    {{selectedMeterBypass?.avgMonthlyVend | hasValue}}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="mb-card-clear">
            <div class="mb-card-data-wrapper">
              <div>Alarm Condition</div>
              <div class="medium-div" *ngIf="currentAlarmCondition == 1" class="blue-table-pill" title="Undefined">
                <span>Undefined</span>
            </div>
            <div class="medium-div" *ngIf="currentAlarmCondition == 2" class="red-table-pill" title="Constant Vend">
                <span>Constant Vend</span>
            </div>
            <div class="medium-div" *ngIf="currentAlarmCondition == 3" class="yellow-table-pill" title="Decreased Vend">
                <span>Decreased Vend</span>
            </div>
            <div class="medium-div" *ngIf="currentAlarmCondition == 4" class="green-table-pill" title="No Vending">
                <span>No Vending</span>
            </div>
            </div>
          </div>
        </div>
        <div *ngIf="activeTab == ActiveTab.Details && selectedMeterBypass != undefined">
            <div class="tab-wrapper">
                <div class="details-row-wrapper">
                    <div class="row-title">
                        Customer Name
                    </div>
                    <div class="row-data">
                        {{selectedMeterBypass.accountName}}
                    </div>
                </div>
                <div class="details-row-wrapper">
                    <div class="row-title">
                        Account Number
                    </div>
                    <div class="row-data">
                        {{selectedMeterBypass.accountNumber}}
                        <svg (click)="copyAccountNumber(selectedMeterBypass.accountNumber)" width="20" height="20"
                            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="content_copy">
                                <mask id="mask0_7446_16312" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0"
                                    y="0" width="24" height="24">
                                    <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_7446_16312)">
                                    <path id="content_copy_2"
                                        d="M9.05771 17.5C8.55258 17.5 8.12502 17.325 7.77504 16.975C7.42504 16.625 7.25004 16.1974 7.25004 15.6923V4.3077C7.25004 3.80257 7.42504 3.375 7.77504 3.025C8.12502 2.675 8.55258 2.5 9.05771 2.5H17.4423C17.9474 2.5 18.375 2.675 18.725 3.025C19.075 3.375 19.25 3.80257 19.25 4.3077V15.6923C19.25 16.1974 19.075 16.625 18.725 16.975C18.375 17.325 17.9474 17.5 17.4423 17.5H9.05771ZM9.05771 16H17.4423C17.5192 16 17.5897 15.9679 17.6538 15.9038C17.7179 15.8397 17.75 15.7692 17.75 15.6923V4.3077C17.75 4.23077 17.7179 4.16024 17.6538 4.09613C17.5897 4.03203 17.5192 3.99998 17.4423 3.99998H9.05771C8.98078 3.99998 8.91026 4.03203 8.84616 4.09613C8.78204 4.16024 8.74999 4.23077 8.74999 4.3077V15.6923C8.74999 15.7692 8.78204 15.8397 8.84616 15.9038C8.91026 15.9679 8.98078 16 9.05771 16ZM5.55774 20.9999C5.05262 20.9999 4.62506 20.8249 4.27506 20.4749C3.92506 20.1249 3.75006 19.6973 3.75006 19.1922V6.3077H5.25004V19.1922C5.25004 19.2692 5.28209 19.3397 5.34619 19.4038C5.4103 19.4679 5.48082 19.5 5.55774 19.5H15.4423V20.9999H5.55774Z"
                                        fill="#1C1B1F" />
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="details-row-wrapper">
                    <div class="row-title">
                        Customer Address
                    </div>
                    <div class="row-data">
                        {{selectedMeterBypass.customerAddress | hasValue}}
                    </div>
                </div>
                <div class="details-row-wrapper" *ngIf="accountDetails != undefined">
                    <div class="row-title">
                        Tarrif
                    </div>
                    <div class="row-data">
                        {{accountDetails.tariffName | hasValue}}
                    </div>
                </div>
                <div class="details-row-wrapper">
                    <div class="row-title">
                        Undertaking
                    </div>
                    <div class="row-data">
                        {{selectedMeterBypass.undertaking | hasValue}}
                    </div>
                </div>
                <div class="details-row-wrapper">
                    <div class="row-title">
                        Business Unit
                    </div>
                    <div class="row-data">
                        {{selectedMeterBypass.businessUnit | hasValue}}
                    </div>
                </div>
                <div class="details-row-wrapper">
                    <div class="row-title">
                        Feeder Name
                    </div>
                    <div class="row-data">
                        {{selectedMeterBypass.feederName | hasValue}}
                    </div>
                </div>
                <div class="details-row-wrapper">
                    <div class="row-title">
                        DT Name
                    </div>
                    <div class="row-data">
                        {{selectedMeterBypass.dtName | hasValue}}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="activeTab == ActiveTab.Table">
            <div class="table-tab-wrapper">
                <div class="scroll-table-wrapper">
                    <div class="table-wrapper">
                        <table class="standard-table scroll-table">
                            <thead>
                                <tr>
                                    <th class="s-td"><span>MONTH</span></th>
                                    <th class="m-td"><span>MONTHLY VEND</span></th>
                                    <th class="m-td"><span>VENDING DEVIATION (%)</span></th>
                                    <th class="m-td"><span>AVAILABILITY(HRS)</span></th>
                                    <th class="m-td"><span>AVAILABILITY DEVIATION (%)</span></th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr *ngFor="let item of selectedMeterBypass?.modalMonthlyTables" class="td-padding">
                                    <td title="{{item.monthName}}"><span>{{item.monthName | hasValue}}</span></td>
                                    <td title="{{item.monthlyVend}}">
                                        <span *ngIf="item.monthlyVend != undefined">{{item.monthlyVend | hasValue | twoDecimals}}</span>
                                        <span *ngIf="item.monthlyVend == undefined || item.monthlyVend === ''" class="NaN">N\A</span>
                                    </td>
                                    <td title="{{item.monthlyVendingDeviation}}">
                                        <span *ngIf="item.monthlyVendingDeviation != undefined">{{item.monthlyVendingDeviation | hasValue | twoDecimals}}</span>
                                        <span *ngIf="item.monthlyVendingDeviation == undefined || item.monthlyVendingDeviation === ''" class="NaN">N\A</span>
                                    </td>
                                    <td title="{{item.dtAvailability}}">
                                      <span *ngIf="item.dtAvailability != undefined">{{item.dtAvailability | hasValue | twoDecimals}}</span>
                                      <span *ngIf="item.dtAvailability == undefined || item.dtAvailability === ''" class="NaN">N\A</span>
                                  </td>
                                    <td title="{{item.monthlyAvailabilityDeviation}}">
                                      <span
                                      *ngIf="item.monthlyAvailabilityDeviation != undefined">{{item.monthlyAvailabilityDeviation | hasValue | twoDecimals}}</span>
                                      <span *ngIf="item.monthlyAvailabilityDeviation == undefined" class="NaN">N\A</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="comment-wrapper" *ngIf="activeTab == ActiveTab.Remark">
            <div class="remark-section">
                <fieldset>
                    <legend>Add Remark</legend>
                    <textarea class="comment" [(ngModel)]="selectedMeterBypass.remarks"
                        placeholder="Add a comment.."></textarea>
                </fieldset>
                <div class="button-wrapper">
                    <button class="cancel-btn" (click)="saveComment()">Save</button>
                </div>
            </div>
        </div>


    </div>
</main>
