<div [ngClass]=" {
  'count-box-green': isGreen,
  'count-box-yellow': isYellow,
  'count-box-blue' : isBlue,
  'count-box-red': isRed,
  'count-box-black': isBlack,
  'count-box-purple': isPurple
}"
     [style.width]="width"
     [style.height]="height"
     [style.borderRadius]="borderRadius">
  
  <div class="box-info">
    <div style="display: flex;" [ngClass]="!filter? 'noFilter': ''">
      <div>{{label}}</div>
      <select *ngIf="filter" class="dropdown-select" [(ngModel)]="selectedFilter" (click)="filterClicked()">
        <option class="drop-select" value="33">33KV</option>
        <option class="drop-select" value="11">11KV</option>
      </select>
    </div>
    <div *ngIf="lastMonth!=null" class="padding-lastMonth"> </div>
    <div class="box-data">
      <span class="count" *ngIf="data">{{data}}</span>
      <span class="NaN" *ngIf="!data">N\A</span>
      <span *ngIf="outage" class="affected">Assets affected</span>
      <span *ngIf="secondData!=null" class="second-data"> &nbsp;{{secondData}}&nbsp;</span>
        
      <span class="view-details" *ngIf="viewDetails" (click)="detailsClicked()">View details ></span>
      <svg *ngIf="meterManagementDashboard && data" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        (mouseenter)="onMouseEnter('totalCustomers')" 
        (mouseleave)="onMouseLeave()"
        >
        <path d="M10.75 11H12V16.25M21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 7.375C11.6548 7.375 11.375 7.65482 11.375 8C11.375 8.34518 11.6548 8.625 12 8.625C12.3452 8.625 12.625 8.34518 12.625 8C12.625 7.65482 12.3452 7.375 12 7.375Z" fill="black" stroke="black" stroke-width="0.25"/>
      </svg>
      <div *ngIf="isSVGHovered('totalCustomers')" class="popup total-customers">
        <div class="popup-row popup-header">
            <span class="popup-headers">Meter Manufacturer</span>
            <span class="popup-headers">Meters Count</span>
        </div>
        <div class="popup-row" *ngFor="let item of meterMakesData">
            <span>{{item.meterManufacturer}}</span>
            <span>{{item.metersCount}}</span>
        </div>
    </div>
    </div>
    <div *ngIf="lastMonth!=null">
      <span  class="last-month" [ngClass]="!lastMonthBg ? 'last-month-no-bg' : '' "  > &nbsp;{{lastMonth}}&nbsp;</span>
    </div>
  </div>
</div>
