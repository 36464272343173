import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dcu-cards',
  templateUrl: './dcu-cards.component.html',
  styleUrl: './dcu-cards.component.scss'
})
export class DcuCardsComponent{
  @Input() online: number = 0;
  @Input() offline: number = 0;

}

