<main class="container-fluid">
    <div class="container-wrapper">
        <button class="back" (click)="back()">
            <img alt="Back" class="imgBack" src="assets/images-new-design/back-arrow.png">Back
        </button>
        <div class="revenue-protection-header">
            <div class="revenue-protection-page-header">
                <span>VENDINGS</span>
            </div>
        </div>

        <div class="download-wrapper">
            <button class="btn-red" (click)="download()">
                Download
                <img alt="" src="../../../../../../assets/images/download-pic.svg">
            </button>
        </div>

        <div class="revenue-protection-filter-wrapper">

            <div class="dropdown-filter">
                <label class="filter-by">Filter</label>

                <app-filter-dropdown [data]="businessUnits" [name]="buSelected.name" [dropdownName]="'All '+ buName + 's'"
                        [dataId]="buSelected.value" (dataChange)="businessUnitSelected($event)">
                </app-filter-dropdown>
                <app-filter-dropdown [data]="undertakings" [name]="utSelected.name" [dropdownName]="'All '+ utName + 's'"
                        [dataId]="utSelected.value" (dataChange)="undertakingSelected($event)">
                </app-filter-dropdown>

                <app-datepicker
                    [dateFrom]="dateFrom"
                    [dateTo]="dateTo"
                    [isMonthly]="false"
                    [placementPosition]="'bottom-right'"
                    (dateChange)="updateDate($event)">
                </app-datepicker>
            </div>

            <div class="right-wrapper">
                <div class="search-wrapper">
                    <app-search
                        [placeholder]="'Search by Acc. No'"
                        [width]="23"
                        [borderColor]="'#6C757D'"
                        [(searchText)]="searchText"
                        (searchChange)="inputChanged($event)">
                    </app-search>
                </div>
            </div>

        </div>

        <table class="standard-table">
            <thead>
                <tr>
                    <th><span>Account Number</span></th>
                    <th><span>Vend DateTime</span></th>
                    <th><span>Vend Amount</span></th>
                    <th><span>Vend Units</span></th>
                    <th><span>{{buName}}</span></th>
                    <th><span>{{utName}}</span></th>
                    <th><span>SPN</span></th>
                </tr>
            </thead>
            <tbody class="table-body">
                <tr *ngFor="let item of vendings" class="bodyRow clickable actions-click">
                    <td title="{{item.accountNumber}}">{{item.accountNumber | hasValue}}</td>
                    <td title="{{item.vendDate}}">{{item.vendDate | date: 'dd/MM/yyyy hh:mm a'| hasValue}}</td>
                    <td title="{{item.vendAmount}}">{{item.vendAmount | number | hasValue}}</td>
                    <td title="{{item.vendsUnits}}">{{item.vendsUnits | number | hasValue}}</td>
                    <td title="{{item.buName}}">{{item.buName | hasValue}}</td>
                    <td title="{{item.utName}}">{{item.utName | hasValue}}</td>
                    <td title="{{item.spn}}">{{item.spn | hasValue}}</td>
                </tr>
            </tbody>
        </table>

        <div class="col-sm-12 pagination-wrapper" *ngIf="!showPaging">
            <app-pagination [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-pagination>
        </div>
    </div>
</main>
