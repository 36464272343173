import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Account } from 'src/app/models/account.model';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AccountService } from 'src/app/services/account.service';
import { AccountStatusService } from 'src/app/services/accountStatus.service';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { ConcentratorService } from 'src/app/services/concentrator.service';
import { DtService } from 'src/app/services/dt.service';
import { MeterService, TypeOfConsumer } from 'src/app/services/meter.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-account-create',
  templateUrl: './account-create.component.html',
  styleUrls: ['./account-create.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})

export class AccountCreateComponent {
  public get authService(): AuthService {
    return this._authService;
  }
  public set authService(value: AuthService) {
    this._authService = value;
  }
  @ViewChild(AccountCreateComponent) popupComponentRef!: AccountCreateComponent;
  public selectedAccount: Account = new Account()
  showShadow = false;
  public tariffNames: DictionaryItem[] = [];
  keyword: string = "name";

  public dtValid: boolean = true;
  public longValid: boolean = true;
  public latValid: boolean = true;
  public msnValid: boolean = true;
  public accountNoValid: boolean = true;
  public accountMetered!: boolean;
  public emailValid: boolean = true;
  public phoneNumberValid: boolean = true;
  public meterTypeValid: boolean = true;
  public amiValid: boolean = true;
  public tariffValid: boolean = true;
  public statusValid: boolean = false;
  public amis: DictionaryItem[] = [];
  public tempDtName: string = '';
  public data: DictionaryItem[] = [];
  public data1: any[] = [];

  public dts: DictionaryItem[] = [];
  public dcus: DictionaryItem[] = [];
  public PermissionsEnum = PermissionsEnum;

  @ViewChild('autocompleteComponent') autocompleteComponent!: any | undefined;

  public amiName: string = this.translationService.getByKeyFromCache('AMI');
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public MD: string = this.translationService.getByKeyFromCache('MD');
  public NMD: string = this.translationService.getByKeyFromCache('NMD');
  public additionalInfoValid: boolean = false;
  @ViewChild("f") f: any;
  public tarifClasses: DictionaryItem[] = [];
  @Input() public accountStatuses: DictionaryItem[] = []
  @Input() animationState = 'out';
  @Output() parentComp = new EventEmitter();
  constructor(private toastr: ToastrService,
    private ref: ElementRef,
    private accountService: AccountService,
    private _authService: AuthService,
    private commonService: CommonService,
    private amiService: AmiService,
    private translationService: TranslationService,
    private tariffService: TariffService,
    private meterService: MeterService,
    private dtService: DtService,
    private dcuService: ConcentratorService,
    private accStatusService: AccountStatusService
  ) { }

  inputCleared() {
    this.tempDtName = '';
    this.selectedAccount.dtName = '';
    this.data1 = [];
  }

  onChange(item: any) {
    this.data = [];
    this.tempDtName = '';
    this.data1 = [];
    if (item.length < 3) {

      this.data1 = []
      return this.data1;
    }

    let search = {
      searchText: item
    }

    this.dtService.getDataForAutocomplete(search).subscribe({
      next : (response) => {
        if(response.status == 200){
          this.data1 = response.data.map((x: { name: any }) => x.name)
        }
      },
      error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
    })

    return this.data1;
  }

  accMeterTypeChanged() {
    this.selectedAccount.msn = "";
    this.selectedAccount.meterType = '';
    this.selectedAccount.amiId = 0;
    if (this.f)
      this.f.submitted = false;
  }

  resetSelectedAccount() {
    this.amiValid = true;
    this.tariffValid = true;
    this.dtValid = true;
    this.longValid = true;
    this.latValid = true;
    this.accountNoValid = true;
    this.emailValid = true;
    this.phoneNumberValid = true;
    this.statusValid = true;

    this.tempDtName = '';
    this.data1 = [];
    this.selectedAccount = new Account();
    this.selectedAccount.tariffName = "";
    this.autocompleteComponent.query = "";
  }

  changeConsumptionType() {
    if (this.selectedAccount.accountConsumptionType == 2) {
      this.accountMetered = true;
      this.selectedAccount.accountMeterType = 0;
    }
    if (this.selectedAccount.accountConsumptionType == 3) {
      this.accountMetered = false;
      this.selectedAccount.accountMeterType = 0;
    }
  }

  changeStatusValidity() {
    this.statusValid = true
  }

  validateTariff() {
    !this.selectedAccount.tariffName ? this.tariffValid = false : this.tariffValid = true;
  }

  validateAdditionalInfo() {
    this.additionalInfoValid = this.f == null ? true : this.f.form.valid;
  }

  validateDt() {
    if (this.selectedAccount.dtName == "") {
      this.dtValid = false;
    } else {
      this.dtValid = true;
    }
  }

  validateTabs() {
    this.validateAccountNo();
    this.validatePhone();
    this.validateEmail();
    if (!this.emailValid || !this.phoneNumberValid || !this.accountNoValid)
      return false;

    if (this.selectedAccount.firstName == '' || !this.commonService.isLettersOnly(this.selectedAccount.firstName))
      return false

    this.validateLatitude();
    this.validateLongitude();
    if (!this.latValid || !this.longValid) {
      return false;
    }

    if (!this.statusValid)
      return false
    if (this.selectedAccount.msn && this.selectedAccount.meterMaxDigit != null && (this.selectedAccount.meterMaxDigit ?? 0) < 1) {
      this.toastr.error('Meter Max Digit must be a positive number')
      return false
    }

    if (this.selectedAccount.msn && this.selectedAccount.multiplierFactor != null && this.selectedAccount.multiplierFactor < 1) {
      this.toastr.error('Multiplier Factor must be a positive number')
      return false
    }

    if(!this.validateMeterProperties())
      return false;

    return true;
  }
  msnNumberErrorMsg = '';

  validateMeter(amiId: number, meterNumber: string) {
    let ami = this.amis.find(x => x.value == amiId)?.name;
    if (ami && ami != '') {
      this.msnNumberErrorMsg = this.meterService.validateMeter(ami, meterNumber, TypeOfConsumer.Account)
      if (this.msnNumberErrorMsg == '')
        return true
    }
    return false
  }

  validateAccountNo(){
    if (this.selectedAccount.accountNo) {
      if (!this.commonService.isNumber(this.selectedAccount.accountNo)) // Is accountNo number
        this.accountNoValid = false;
      else
        this.accountNoValid = true;
    }
  }

  validateMeterProperties(){
   

    if (!this.selectedAccount.accountConsumptionType) // Consumption type null? Return
      return false;
    else  // Consumption type has value?
    {
      if (!this.selectedAccount.accountMeterType) //Meter type null? Return
        return false;
      if(!this.validateMsn())
        return false;
    }
    return true;
  }

  validatePhone(){
     if (this.selectedAccount.phone) {
       if (!this.commonService.isPhoneNumber(this.selectedAccount.phone))
         this.phoneNumberValid = false;
       else
         this.phoneNumberValid = true;
    }
  }

  validateMsn(){
    if (this.selectedAccount.msn != "") {

      this.validateAmi();
      if (this.selectedAccount.msn != "" && this.selectedAccount.amiId != 0) {
        if (!this.validateMeter(this.selectedAccount.amiId, this.selectedAccount.msn)) {
          this.msnValid = false;
          return false
        }
      }

      if (!this.amiValid)
        return false;
    }
    return true;
  }

  validateEmail(){
    if (this.selectedAccount.email) { // If something typed in field
      if (!this.commonService.isEmail(this.selectedAccount.email)) // Check if correct
        this.emailValid = false;
      else
        this.emailValid = true;
    }
  }

  selectEvent(item: any) {

    this.selectedAccount.dtName = item;
    this.tempDtName = item;

    this.validateDt();
    this.data = [];
  }

  validateLongitude() {
    if (Number.isNaN(Number(this.selectedAccount?.longitude)))
      this.longValid = false;
    else if (Number(this.selectedAccount?.longitude) < -180 || Number(this.selectedAccount?.longitude) > 180)
      this.longValid = false;
    else
      this.longValid = true;
  }

  validateLatitude() {
    if (Number.isNaN(Number(this.selectedAccount?.latitude)))
      this.latValid = false;
    else if (Number(this.selectedAccount?.latitude) < -90 || Number(this.selectedAccount?.latitude) > 90)
      this.latValid = false;
    else
      this.latValid = true;
  }

  validateStatus() {
    if (this.selectedAccount.accStatusId == 0) {
      this.statusValid = false
    } else {
      this.statusValid = true
    }
  }

  validateAmi() {
    let regExp = "[";
    this.amis.forEach(element => {
      regExp += element.value + "|";
    });
    regExp.slice(0, -1);
    regExp += "]";
    const reg: RegExp = new RegExp(regExp);
    this.amiValid = reg.test(this.selectedAccount.amiId.toString());
    if (this.selectedAccount.msn != "" && this.selectedAccount.amiId == 0)
      this.amiValid = false;
    }

  getAllAmisForSelect() {
    this.amiService.getAllForSelect().subscribe(response => {
      this.amis = response.data
    }, _ => {
      this.toastr.error('An error occured');
    })
  }

  getAllDTsForSelect() {
    this.dtService.getAllForSelect().subscribe(response => {
      this.dts = response.data
    }, _ => {
      this.toastr.error('An error occured');
    })
  }

  getAllTariffsForSelect() {
    this.tariffService.getAllForSelect().subscribe(response => {
      this.tariffNames = response.data
    }, _ => {
      this.toastr.error('An error occured');
    })
  }
  stringify(f1: any) {
    console.log(f1)
  }

  getAllDCUsForSelect() {
    this.dcuService.getAllForSelect().subscribe(response => {
      this.dcus = response.data

    }, _ => {
      this.toastr.error('An error occured');
    })
  }
  save() {
    this.selectedAccount.loggedUserName = this.authService.getCurrentUser().firstName;
    this.validateTariff();
    this.validateDt();
    this.validateAdditionalInfo();
    this.validateStatus()

    if (!this.selectedAccount.accountNo) {
      return;
    }

    if (!this.validateTabs()) {
      return;
    }
    if (!this.additionalInfoValid || !this.tariffValid || !this.dtValid) {
      return;
    }

    if (!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate)) {
      this.toastr.error("You don't have permission.");
      return;
    }

    if (this.selectedAccount.id == 0) //create
    {
      let dtId = 0;
      //finding id of selected dt
      for (const key in this.dts) {
        if (this.dts.hasOwnProperty(key) && this.dts[key].name === this.selectedAccount.dtName) {
          dtId = this.dts[key].value;
          break;
        }
      }
      this.selectedAccount.dtId = dtId;
    }
    let ami = this.amis.find(x => x.value == this.selectedAccount.amiId)
    this.selectedAccount.ami = ami ? ami.name : ''
    this.accountService.save(this.selectedAccount).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.changeState(2);
        this.parentComp.emit()
        this.f = null;
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.account-popup');
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2);
        }
      }
  }

  closeTab() {
    this.popupComponentRef.changeState(2);
  }

  changeState(number: Number, data?: any) {
    if (data === 0) {
      this.resetSelectedAccount()
    }
    if (data)
      this.selectedAccount = data;
    if (number === 1) {
      this.toggleShowDiv("open");
      this.showShadow = true;
    }
    if (number === 2) {
      this.toggleShowDiv("close");
      if(this.f)
        this.f.submitted = false;
      this.showShadow = false;
    }
  }

  getAllAccStatuses() {
    this.accStatusService.getAllForSelect().subscribe(response => {
      if (response.status === 200) {
        this.accountStatuses = response.data
      }
      else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occured');
    })
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in';
    } else if (divName === 'close')
      this.animationState = 'out';
  }
}
