import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { MeterService } from 'src/app/services/meter.service';

@Component({
  selector: 'app-alarm-event-mgt',
  templateUrl: './alarm-event-mgt.component.html',
  styleUrl: './alarm-event-mgt.component.scss'
})
export class AlarmEventMgtComponent implements OnInit{

  //pagination
  public count: number = 0
  public pageSize: number = 10
  public currentPage: number = 1
  public lastPageSize: number = 1;

  //checkbox
  public masterSelected: boolean = false;
  public accountsId: any = [];
  public checkedList: any = [];
  public data: any[] = [];

  @Input() eventIds: any = null;
  @Input() dateFrom: any = '';
  @Input() dateTo: any = '';
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()

  @Output() copyToClipboardChange = new EventEmitter<number>();

  ngOnInit(): void {
    this.GetAlarmEventData();
  }
  constructor(    private meterService: MeterService,
    private toastr: ToastrService,
    ){}



    ngOnChanges(changes: SimpleChanges) {

      let reloadTable = false

      if (changes.eventIds != null) {
        reloadTable = true
      }
      if(changes.dateFrom != null && changes.dateTo != null){
        reloadTable = true
      }
      if (this.searchText?.name == '')
        this.searchText = changes.search?.previousValue

      if (changes.searchText != null) {
        reloadTable = true
      }

      if (reloadTable) {
        this.pageChange(1)
      }
    }

  GetAlarmEventData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        Search: this.searchText ? this.searchText.name : this.searchText,
        From : transformToString(this.dateFrom),
        To : transformToString(this.dateTo),
        EventIds:this.eventIds

      }
    };
    console.log(obj)
    this.meterService.getAllMeterEvents(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data;
        if (obj.pageInfo.page == 1) {
          this.count = response.data.count;
          // this.countChange.emit(this.count);
          this.lastPageSize = Math.ceil(this.count / this.pageSize);
        }
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  pageChange(value: number) {
    this.currentPage = value
    this.GetAlarmEventData()
  }

  copyToClipboard(text: any) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }
}
