export class Dcu {
    id: number = 0;
    serialNumber: string = '';
    address: string = '';
    buName:string ='';
    utName:string ='';
    latitude: string = '';
    longitude: string = '';
    amiId: number = 0;
    checked: boolean = false;
    connectedMeters: number = 0;
}