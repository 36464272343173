import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderManagement {
  
  private pathApi = this.config.setting['pathApi'] + "WorkOrder/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    getAll(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAll`, obj);
    }

    getOne(id:number): Observable<any> {
      return this.http.get(`${this.pathApi}${id}`,);
    }

    save(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}save`, obj);
    }

    assignWorkOrder(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}assignWorkOrder`, obj);
    }
    reassignTask(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}reassignTask`, obj);
    }
    downloadWorkOrderReport(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}downloadWorkOrderReport`, obj);
    }
    getTaskByUserId(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}getTaskByUserId/${id}`);
    }
    getAssetTypesForOptions(): Observable<any> {
      return this.http.get(`${this.pathApi}getAssetTypesForOptions`);
    }

    getAssignedTeamsForOptions(): Observable<any> {
      return this.http.get(`${this.pathApi}getAssignedTeamsForOptions`);
    }
    
    getWorkOrderCategoriesForOptions(): Observable<any> {
      return this.http.get(`${this.pathApi}getWorkOrderCategoriesForOptions`);
    }

    getNumberOfWorkOrder(): Observable<any> {
      return this.http.get(`${this.pathApi}getNumberOfWorkOrder`);
    }

    getAssignedCount(): Observable<any> {
      return this.http.get(`${this.pathApi}getAssignedCount`);
    }

    getClosedCount(): Observable<any> {
      return this.http.get(`${this.pathApi}getClosedCount`);
    }

    getTaskCounts(userGuid: string): Observable<any> {
      return this.http.get(`${this.pathApi}getTaskCounts/${userGuid}`);
    }

    updateWorkOrderStatus(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}updateWorkOrderStatus`, obj);
    }

    getAllCommentsByWorkOrderId(id: number): Observable<any> {
      return this.http.get(`${this.pathApi}getCommentsByWorkOrderId/${id}`);
    }

    addCommentToWorkOrder(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}addCommentToWorkOrder`, obj);
    }

    getDataForAutocomplete(searchText:string): Observable<any> {
      return this.http.get(`${this.pathApi}getDataForAutocomplete/${searchText}`)
    }
}
