import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { Womteam } from 'src/app/models/womteam.model';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { WomteamService } from 'src/app/services/womteam.service';
import { AddAgentPopupComponent } from '../add-agent-popup/add-agent-popup.component';

@Component({
  selector: 'app-create-team-management',
  templateUrl: './create-team-management.component.html',
  styleUrls: ['./create-team-management.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class CreateTeamManagementComponent implements OnInit {
  public selectedTeam: Womteam = new Womteam()
  public teamAgents: Womteam = new Womteam()

  showShadow = false;
  @Input() team: Womteam = new Womteam()
  @ViewChild('myDropdown') myDropdown: any;
  @Output() clickOutside = new EventEmitter<MouseEvent>();
  @Input() animationState = 'out';
  allTeams: Womteam[] = [];
  @Input() womteam: Womteam = new Womteam()
  public bussinesUnits: DictionaryItem[] = []
  public selectedRegion: DictionaryItem = new DictionaryItem()
  selectedTeamLead: DictionaryItem = new DictionaryItem()
  fieldAgents: any[] = []
  public preview: string = ''
  private uploadFile!: File | null;
  defaultImage: string = '../../../../../assets/images-new-design/defaultImg.png'
  @Input() isPopupOpen: boolean = false;
  isAgentPopupOpen: boolean = false;
  isTeamPopupOpen: boolean = false;
  public selectedUt: DictionaryItem = new DictionaryItem()
  public uts: DictionaryItem[] = []

  @Input() maxVisibleItems = 17;
  @Input() hiddenItemsCount: number = 0;
  @Input() showHiddenNames: boolean = false;

  isSelected: boolean = false;
  public search: string = ''
  @Input() selectedItems: any[] = [];

  @Input() teamLeads: any[] = [];
  optionSelected: boolean = false;
  title: string = '';
  button: string = '';

  public masterSelected: boolean = false;
  public checkedList: any = []
  public showData: any[] = []
  isDropdownOpen: boolean = false;
  selectedTeamLeadName: string = '';
  selectedTeamLeadImg: string = '';
  receivedObject: any;
  buId: number = 0
  teamBu: number = 0

  disabled: boolean = false;
  userGuid: string = ''

  @ViewChild(AddAgentPopupComponent) popupComponentRef!: AddAgentPopupComponent;
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public buName: string = this.translationService.getByKeyFromCache('BU');

  constructor(private toastr: ToastrService,
    public authService: AuthService,
    private utService: UndertakingService,
    private womTeamService: WomteamService,
    private buService: BusinessUnitService,
    private ref: ElementRef,
    private location: Location,
    private route: ActivatedRoute,
    private translationService: TranslationService,

  ) { }

  ngOnInit(): void {
    this.userGuid = this.authService.getCurrentUser()?.userGuid;
    this.setInitial();
    this.getAllTeamsForSelect();
    this.getAllBUsForSelect();
    this.getTeam();
  }

  getHiddenNames(): string {
    const hiddenNames = this.selectedItems
      .slice(this.maxVisibleItems)
      .map(item => item.userName);
    return hiddenNames.join(', ');
  }

  setInitial() {
    this.preview = this.defaultImage
    this.route.queryParams.subscribe(params => {
      if (params['data'] !== undefined) {
        this.selectedTeam.id = JSON.parse(params['data']);
        this.womteam.id = JSON.parse(params['data'])
      } else {
        this.selectedTeam.id = 0
      }
    });
    if (this.selectedTeam.id === 0) {
      this.selectedTeam.buName = this.buName
      this.selectedTeam.utName = this.utName
      this.title = "Create Team"
      this.button = "Create Team"
      // this.isAgentPopupOpen = true  
    } else {
      this.title = "Edit Team"
      this.button = "Save Changes"
      this.selectWomTeam(this.selectedTeam.id)
    }
  }

  selectWomTeam(id: number) {
    this.womTeamService.get(id).subscribe(response => {
      this.selectedTeam = response.data;
      this.teamAgents = this.selectedTeam;
      this.buId = this.selectedTeam.buId;
      if (this.buId === 0 || this.buId === null) {
        this.selectedTeam.buName = this.buName
      }
      this.teamBu = this.selectedTeam.buId;

      if (this.selectedTeam.utName === null || this.selectedTeam.utName == '') {
        this.selectedTeam.utName = this.utName
      }
      this.selectedTeamLeadName = this.selectedTeam.teamLeadName
      this.selectedRegion.value = this.selectedTeam.buId
      this.selectedUt.value = this.selectedTeam.utId

      if (this.selectedTeam.profileImage !== null)
        this.preview = this.selectedTeam.profileImage
      this.womteam.teamLeadId = this.selectedTeam.teamLeadId
      this.selectedTeamLeadName = this.selectedTeam.teamLeadName

      for (let i = 0; i < this.selectedTeam.agentsIds.length; i++) {
        const mappedItem: Womteam = Object.assign({}, this.selectedItems[0], { id: this.selectedTeam.agentsIds[i], userName: this.selectedTeam.agents[i], image: this.selectedTeam.photoUrl[i] });
        this.selectedItems.push(mappedItem);

        if(mappedItem.userName == this.selectedTeamLeadName){
          this.selectedTeamLeadImg = mappedItem.image
        }
      }

      for (let i = 0; i < this.selectedTeam.agentsIds.length; i++) {
        const mappedItem: Womteam = Object.assign({}, this.selectedItems[0], { id: this.selectedTeam.agentsIds[i], userName: this.selectedTeam.agents[i], image: this.selectedTeam.photoUrl[i]  });
        this.teamLeads.push(mappedItem);
    }

      this.isAgentPopupOpen = true 
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  getTeam() {
    if (this.selectedTeam.id != 0) {
      this.selectedTeamLeadName = this.selectedTeam.teamLeadName
      this.selectedTeamLeadImg = this.selectedTeam.image
    }
  }

  getAllUTsForSelect() {
    this.utService.getAllByBuId(this.selectedRegion.value).subscribe(response => {
      this.uts = response.data;
    }, _ => {
      this.toastr.error('An error occured');
    });
  }

  selectUt(item: DictionaryItem) {
    this.selectedUt = item
  }

  onOptionSelect() {
    if (this.selectedTeamLead.value !== 0)
      this.optionSelected = true;
  }

  searchTeamChanged() {
    this.teamLeads = this.teamLeads.filter(x => x.firstName.toLowerCase().includes(this.search.toLowerCase()))
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fieldAgents = changes.data.currentValue

    // this.fieldAgents.find(agent => agent.)
  }

  changeStateAddTeam(number: number) {
    if (number === 1) {
      this.isTeamPopupOpen = true;
    }
    if (number === 2) {
      this.isTeamPopupOpen = false;
      this.onOptionSelect();
    }
  }

  alert() {
    this.toastr.warning("Not yet implemented");
  }

  selectRegion(item: DictionaryItem) {
    this.selectedRegion = item
    this.womteam.buName = item.name
    this.womteam.buId = item.value
    this.teamBu = item.value
    this.selectedTeam.teamLeadId=0;
    this.selectedTeam.teamLeadName='';
    this.selectedTeamLeadName='';
    this.selectedTeamLeadImg='';
    this.womteam.teamLeadId = 0;
    this.selectedItems=[];
    this.isAgentPopupOpen = true; 
    this.getAllUTsForSelect();
  }

  selectTeamLead(item: any) {
    this.isDropdownOpen = false;

    this.selectedTeamLead = item
    this.womteam.teamLeadId = item.id
    this.selectedTeamLeadImg = item.image
    this.selectedTeamLeadName = item.userName;
    this.isTeamPopupOpen = false;
  }

  getAllBUsForSelect() {
    this.buService.GetByUserGuid(this.userGuid).subscribe(response => {
      this.bussinesUnits = response.data;
    }, _ => {
      this.toastr.error('An error occured');
    });
  }

  back() {
    this.location.back()
  }

  cancelTeamLead() {
    this.optionSelected = false;
    this.womteam.teamLeadId = 0
    this.selectedTeamLeadImg = ''
    this.selectedTeamLeadName = '';
  }

  cancelImage(index: number) {
    this.selectedItems.splice(index, 1);
    this.teamLeads = this.selectedItems.map(selectedItem => selectedItem);
    var lead = this.selectedItems.find(x => x.userName == this.selectedTeamLeadName)
    if(!lead){
      this.cancelTeamLead()
    }
  }

  public create() {
    if (!this.selectedTeam.name) {
      this.toastr.error("Name is required!")
      return;
    }
    if (this.selectedItems.length === 0) {
      this.toastr.error("You must choose at least one agent!")
      return;
    }
    if (this.selectedRegion.value === 0 || this.selectedRegion === null) {
      this.toastr.error(`${this.buName} is required!`);
      return;
    }
    if (this.selectedUt.value === 0 || this.selectedUt === null) { 
      this.toastr.error(`${this.utName} is required!`);

      return;
    }
    if (this.womteam.teamLeadId === 0) {
      this.toastr.error("Team lead is required!")
      return;
    }

    let formData: FormData = new FormData();
    if (this.uploadFile) {
      formData.append('photoUrl', this.uploadFile, this.uploadFile.name);
    }
    const idStrings = this.selectedItems.map(item => item.id.toString());

    formData.append('name', this.selectedTeam.name);
    formData.append('teamLeadId', this.womteam.teamLeadId.toString());
    formData.append('id', this.selectedTeam.id.toString());
    formData.append('buId', this.selectedRegion.value.toString());
    formData.append('utId', this.selectedUt.value.toString());
    for (let i = 0; i < idStrings.length; i++) {
      formData.append('agentId', idStrings[i]);
    }

    this.womTeamService.createOrEditWOMTeam(formData).subscribe(response => {
      if (response.status == 200) {
        this.toastr.success(response.message)
        this.location.back();
        this.changeState(2);
      }
      else {
        this.toastr.error(response.message)
        this.changeState(2);

      }
    }, _ => {
      this.toastr.error('An error occurred')
    })
  }

  onFileChanged(event: any) {
    this.uploadFile = event.target.files[0]
    if (this.uploadFile) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        this.preview = e.target.result
      }
      reader.readAsDataURL(this.uploadFile);
    }
  }

  save() {
    this.womTeamService.save(this.selectedTeam.name).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.changeState(2);
        this.location.back()
      } else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getAllTeamsForSelect() {
    this.womTeamService.getAllForSelect().subscribe(response => {
      if (response.status === 200) {
        this.allTeams = response.data;
      } else {
        this.toastr.error(response.message)
      }
    }, error => {
      this.toastr.error('An error occured');
    });
  }

  receiveAutoMsgHandler(p: any) { 
    this.teamLeads = p.teamLead;
    this.selectedItems = p.selectedItems
    this.hiddenItemsCount = p.hiddenItemsCount

    var lead = this.selectedItems.find(x => x.userName == this.selectedTeamLeadName)
    if(!lead){
      this.cancelTeamLead()
    }
  }

  handleClick(event: any) {
    const openPopupButton2 = document.getElementById('openPopup2');
    const isSliderClicked = event.target.closest('.slider');
    if (!openPopupButton2 || (openPopupButton2 && event.target !== openPopupButton2)) {
      if (this.isTeamPopupOpen) {
        if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
          if (!isSliderClicked) {
            this.changeStateAddTeam(2);
          }
        }
      }
    }
  }

  open() {
    if (this.selectedRegion.value !== 0) {
      this.isAgentPopupOpen = true
      this.popupComponentRef.open(this.selectedItems);
    }else 
      this.toastr.warning(`Choose ${this.buName} first!`);

  }

  changeState(number: Number, data?: any) {
    if (data) {
      this.selectedTeam = data
      this.selectedTeamLeadName = this.selectedTeam.teamLeadName
      this.selectedItems = this.selectedItems.concat(this.selectedTeam);
    }
    if (number === 1) {
      this.isPopupOpen = true;
    }
    if (number === 2) {
      this.isPopupOpen = false;
      this.womteam.name = '';
      this.womteam.teamLeadName = '';
      this.womteam.teamLeadId = 0;
      this.selectedRegion.value = 0;
      this.selectedTeam.name = '';
      this.selectedTeam.value = '';
      this.selectedTeamLeadName = '';
      this.selectedItems.splice(0);

    }
  }
}
