import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Ami } from 'src/app/models/ami.model';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { MeterModel } from 'src/app/models/meterModel.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { MeterModelService } from 'src/app/services/meterModel.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-create-meter-model',
  templateUrl: './create-meter-model.component.html',
  styleUrl: './create-meter-model.component.scss',
  host: {
    '(document:click)': 'handleClick($event)',
  }
})
export class CreateMeterModelComponent {

  @Input() public selectedMeterModel: MeterModel = new MeterModel()

  @ViewChild("form") form: any

  showShadow = false
  @Input() animationState = 'out'

  @Output() saveEvent = new EventEmitter()

  public selectedAmi: Ami = new Ami()
  @Input() public amis: DictionaryItem[] = []
  public amiName: string = this.translationService.getByKeyFromCache('AMI')

  constructor(private meterModelService: MeterModelService,
    private amiService: AmiService,
    public authService: AuthService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    public ref: ElementRef
  ) { }


  handleClick(event: any) {
    const isPopupClicked = event.target.closest('.popup-wrapper')
    if (this.animationState === 'in')
      if (this.ref.nativeElement.contains(event.target) || event.target === this.ref.nativeElement) {
        if (!isPopupClicked) {
          this.changeState(2)
        }
      }
  }

  getAmisForSelect(){
    this.amiService.getAllForSelect().subscribe(response => {
      this.amis = response.data
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  save() {
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate)) {
      this.toastr.error("You don't have permission.")
      return
    }

    if (this.selectedMeterModel.name == '' || this.selectedMeterModel.amiId == 0) {
      return
    }

    this.meterModelService.save(this.selectedMeterModel).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message)
        this.changeState(2)
        this.resetSelectedMeterModel()
        this.saveEvent.emit()
      }else
        this.toastr.error(response.message)
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  resetSelectedMeterModel() {
    this.selectedMeterModel.id = 0
    this.selectedMeterModel.name = ''
    this.selectedMeterModel.amiId = 0

    if (this.form)
      this.form.submitted = false
  }

  public selectAmi(ami: any) {
    this.selectedAmi = ami
    if (this.selectedMeterModel) {
      this.selectedMeterModel.amiId = ami.value
    }
  }

  changeState(number: number, data?: any) {
    if (data) {
      this.selectedMeterModel = data
      this.selectedAmi.name = this.selectedMeterModel.amiName
    }
    if (number === 1) {
      this.toggleShowDiv("open")
      this.showShadow = true
    }
    if (number === 2) {
      this.toggleShowDiv("close")
      this.showShadow = false
      if (this.form) {
        this.form.submitted = false
      }
      if (this.selectedAmi) {
        this.selectedAmi.name = 'Select ' + this.amiName
      }
    }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'open') {
      this.animationState = 'in'
    } else if (divName === 'close')
      this.animationState = 'out'
  }
}
