import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class FieldOperationsDashboardService {

  private pathApi = this.config.setting['pathApi'] + "fieldOperationsDashboard/";

  constructor(private http: HttpClient, 
    private config: AppConfig) { }


    getAssetPerformanceAnalysisDT(obj : any): Observable<any> {
      return this.http.post(`${this.pathApi}getDtPredictiveAssetMaintenanceLog`, obj);
    }

     getCardData(obj : any): Observable<any> {
      return this.http.post(`${this.pathApi}getCardData`, obj);
    }

     getInventoryData(obj : any): Observable<any> {
      return this.http.post(`${this.pathApi}getInventoryManagement`, obj);
    }

    getAssetPerformanceAnalysisFeeders11(obj : any): Observable<any> {
      return this.http.post(`${this.pathApi}getFeeder11PredictiveAssetMaintenanceLog`, obj);
    }

    getAssetPerformanceAnalysisFeeders33(obj : any): Observable<any> {
      return this.http.post(`${this.pathApi}getFeeder33PredictiveAssetMaintenanceLog`, obj);
    }

    getDtsCurrentFaultsAnalytics(): Observable<any> {
      return this.http.get(`${this.pathApi}getDtsCurrentFaultsAnalytics`);
    }

    getF11CurrentFaultsAnalytics(): Observable<any> {
      return this.http.get(`${this.pathApi}getF11CurrentFaultsAnalytics`);
    }

    getF33CurrentFaultsAnalytics(): Observable<any> {
      return this.http.get(`${this.pathApi}getF33CurrentFaultsAnalytics`);
    }
}
