import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';
import { PlannedOutageService } from 'src/app/services/planned-outage.service';
import { PlannedOutageUpdateComponent } from '../planned-outage-update/planned-outage-update.component';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { transformToString } from 'src/app/helpers/dateUtils';

@Component({
  selector: 'app-planned-outage-table',
  templateUrl: './planned-outage-table.component.html',
  styleUrl: './planned-outage-table.component.scss',
})
export class PlannedOutageTableComponent implements OnChanges{
  public pageSize: number = 8;
  @Input() currentPage: number = 1;
  public lastPageSize: number = 1;
  count: number = 0;
  public plannedOutages : any;

  @ViewChild(PlannedOutageUpdateComponent)
  popupComponentRef!: PlannedOutageUpdateComponent;
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()


  constructor(private plannedOutageService: PlannedOutageService,private toastr: ToastrService){}

  ngOnChanges(changes: SimpleChanges)
  {
    let reloadTable : boolean = false;
    if (this.searchText != null) {
      reloadTable = true;
    }
    if (changes.dateFrom != null || changes.dateTo != null) {
      reloadTable = true
    }

    if (reloadTable) {
      if (this.dateFrom == '' || this.dateTo == '')
        this.setMainDate()
      this.pageChange(1)
    }
  }
  public pageChange(page: number) {
    this.currentPage = page;
    this.GetData();
  }

  private setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()

    dteFrom.setHours(0, 0, 0);
    dte.setHours(0, 0, 0);
    dteFrom.setMonth(dteFrom.getMonth() - 1)

    this.dateFrom = { year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate() };
    this.dateTo = { year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate() };
  }

  public GetData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams:{
        value: this.searchText.value,
        name: this.searchText.name,
        type: this.searchText.type,
        creationDateFrom: transformToString(this.dateFrom),
        creationDateTo: transformToString(this.dateTo),
      }
    };
    this.plannedOutageService.getAll(obj).subscribe({
      next: (response : any) => {
        if(response.status == 200){
          this.plannedOutages = response.data.data;
          if (obj.pageInfo.page == 1)
          {
            this.count = response.data.count
            this.lastPageSize = Math.ceil(this.count / this.pageSize)
          }
        }
        else{
          this.toastr.error(response.message)
        }
      },
      error: (err : any) => {
        this.toastr.error(err.message)
      }
    })

  }
  openTab(selectedId: number, id?: number) {
    this.popupComponentRef.changeState(1, selectedId);
  }
  refreshPage(shouldRefresh : any){
    if(shouldRefresh){
      this.pageChange(1)
    }
  }
  //Date Range picker
  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''
}
