<google-map  class="map-anti-zoom"  #map id="map"
            width="100%"
            height="100%"
            [zoom]="zoom"
            [center]="center"
            [options]="mapProperties">
<map-info-window></map-info-window>

<div #markerModal class="marker-modal">
  <div class="modal-type">{{modalType}}</div>
  <div class="modal-title">{{modalTitle}}</div>
  <div class="modal-content">
    <span class="modal-label">Energy received</span>
    <span *ngIf="modalType === 'BU'" class="modal-value">/</span>
    <span *ngIf="modalType !== 'BU'" class="modal-value">{{energyReceivedPreviousMonth | hasValue}}</span>
  </div>
  <div *ngIf="modalType === 'DT'" class="modal-content">
    <span class="modal-label">DT to customer losses:</span>
    <span *ngIf="losses == null" class="modal-value">/</span>
    <span *ngIf="losses != null" class="modal-value">{{lossesMVH | hasValue }} ({{losses | hasValue | percentage}})</span>
  </div>
  <div *ngIf="modalType === 'UT' || modalType === 'BU'" class="modal-content">
    <span class="modal-label">Energy losses:</span>
    <span *ngIf="modalType === 'BU'" class="modal-value">/</span>
    <span *ngIf="modalType !== 'BU'" class="modal-value">{{lossesMVH | hasValue }} ({{losses | hasValue | percentage}})</span>
  </div>
  <div *ngIf="modalType === 'TS' || modalType === 'SS'" class="modal-content">
    <span class="modal-label">Grid to DT losses</span>
    <span *ngIf="losses == null" class="modal-value">/</span>
    <span *ngIf="losses != null" class="modal-value">{{lossesMVH | hasValue }} ({{losses | hasValue | percentage}})</span>
  </div>
  <div class="modal-period">
    <span>Period {{ monthBefore | date: 'MMMM' }}</span>
  </div>
</div>

<div #markerDialog class="marker-dialog" [hidden]="!hasDialog">
  <div class="marker-dialog-content">
    <div class="close-btn"><img alt="Close" src="assets/images/ic_close.svg" (click)="closeDialog()" /></div>

    <app-ss-map-dialog *ngIf="selectedMarker?.icon?.url?.includes('ic_ss')" [ssData]="ssData"></app-ss-map-dialog>
    <app-dt-map-dialog *ngIf="selectedMarker?.icon?.url?.includes('ic_dt')" [dtData]="dtData"></app-dt-map-dialog>
    <app-bu-map-dialog *ngIf="selectedMarker?.icon?.url?.includes('ic_bu')" [buData]="buData"></app-bu-map-dialog>
    <app-ut-map-dialog *ngIf="selectedMarker?.icon?.url?.includes('ic_ut')" [utData]="utData"></app-ut-map-dialog>

    <div class="container-fluid p-0">
      <div class="row tasks-header">
        <div class="col-sm-6">
          <div>Tasks</div>
        </div>
        <div class="col-sm-6 tasks-plus">
          <img alt="+" src="assets/images/ic_plus.svg" />
        </div>
      </div>
      <div class="row tasks-content">
        <div class="col-sm-12"><div></div></div>
      </div>
      <div class="row download-report">
        <div class="col-sm-12">Download Report</div>
      </div>
      <div class="row">
        <div class="calendar-container">
          <app-adora-datepicker [(dateFrom)]="dateFrom" [(dateTo)]="dateTo"
                                [isMonthly]="true" [(filterByDateClicked)]="filterByDateClicked"
                                [placementPosition]="'top-start'">
          </app-adora-datepicker>
        </div>
        <div class="action-buttons">
          <img alt="Download" (click)="download()" src="assets/images/ic_download_green.svg" />
        </div>
      </div>
    </div>
  </div>
</div>

<map-marker #somemarker="mapMarker"
            *ngFor="let marker of markers"
            [position]="marker.position"
            [icon]="marker.icon"
            [options]="markerProperties"
            (mapMouseover)="markerHover(somemarker, marker, $event)"
            (mapMouseout)="markerHoverFinish()"
            (mapClick)="onMarkerClick(marker)">
</map-marker>
</google-map>
