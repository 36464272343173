import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, fromEvent, interval } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  @Input() placeholder!: string
  @Input() width: number = 23
  @Input() borderColor: string = '#E8E8E8'
  @Input() backgroundColor: string = '#FFFFFF'
  @Input() marginLeft: string = '0rem'

  @Output() result: EventEmitter<any> = new EventEmitter()

  @Input() searchText: string = ''
  @Output() searchTextChange: EventEmitter<any> = new EventEmitter()

  @Output() searchChange: EventEmitter<any> = new EventEmitter()

  @ViewChild('searchInput') searchInput!:ElementRef;

  interval = new Subject<void>()
  @Input() setInterval: boolean = false
  private previousSearchText = ''

  ngOnInit(): void {
    if (this.setInterval) {
      interval(3000)
      .pipe(takeUntil(this.interval))
      .subscribe(() => {
        if (this.searchText !== this.previousSearchText) {
          this.onEnter()
          this.previousSearchText = this.searchText
        }
      })
    }
  }

  ngAfterViewInit(): void{
    let source = fromEvent(this.searchInput.nativeElement, 'keyup');
    source.pipe(debounceTime(500)).subscribe(event=>{
      this.onEnter()
    })
  }
    //onKeyDown(event: KeyboardEvent): void {
    //    if (this.autocomplete) {
    //        if (event.key === 'ArrowDown') {
    //            event.preventDefault();
    //            if (this.selectedOptionIndex === 0) {
    //                this.optionSelected = this.filteredOptions[this.selectedOptionIndex + 1];
    //                this.selectedOptionIndex++;
    //                this.dropdownMenu.nativeElement.scrollTop += 4;
    //            }
    //            else if (this.selectedOptionIndex < this.filteredOptions.length - 1) {
    //                this.optionSelected = this.filteredOptions[this.selectedOptionIndex + 1];
    //                this.selectedOptionIndex++;
    //                this.dropdownMenu.nativeElement.scrollTop += 40;
    //            }
    //        } else if (event.key === 'ArrowUp') {
    //            event.preventDefault();
  //      if (this.selectedOptionIndex > 0) {
  //        this.optionSelected = this.filteredOptions[this.selectedOptionIndex - 1];
  //        this.selectedOptionIndex--;
  //        this.dropdownMenu.nativeElement.scrollTop -= 44;
  //      }
  //    } else if (event.key === 'Enter') {
  //      event.preventDefault();
  //      if (this.optionSelected) {
  //        this.searchText = this.optionSelected;
  //        this.selectedOptionIndex = -1
  //      }
  //    }
  //  }
  //}
  //ngOnChanges(changes: SimpleChanges): void {
  //  this.optionSelected = '';
  //  if (changes.options !== undefined) {
  //    this.filterData()
  //  }
  //}
  //@HostListener("keyup", ["$event"]) keyUp(e: KeyboardEvent) {
  //  console.log("auto",this.autocomplete)
  //  if (!this.autocomplete) {
  //    // let source = fromEvent(this.searchInput.nativeElement, 'keyup');
  //    // source.pipe(debounceTime(500)).subscribe(event => {
  //      this.onEnter();
  //    // });
  //  } else if (this.autocomplete) {
  //    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp' && e.key !== 'Enter') {
  //      this.onEnterAutocomplete();
  //    }
  //  }
  //  if (e.key === 'Backspace') {
  //    this.optionSelected = ''
  //  }
  //}

  onChange(){
    if(this.searchText == ''){
      this.onEnter()
    }
  }

  onEnter(){
    this.searchChange.emit(this.searchText)
  }

  ngOnDestroy() {
    this.interval.next()
    this.interval.complete()
  }

}
