import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class MeterBypassService {
  
  private pathApi = this.config.setting['pathApi'] + "meterBypass/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    getAll(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAll`, obj);
    }

    getAllPostpaid(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getAllPostpaid`, obj);
    }

    getTop5Areas(): Observable<any> {
      return this.http.get(`${this.pathApi}getTop5Areas`);
    }
    getTop5AreasAEDCPostpaid(): Observable<any> {
      return this.http.get(`${this.pathApi}getTop5AreasAEDCPostpaid`);
    }
    getStatusCount(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getStatusCount`, obj);
    }

    get(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}get`, obj);
    }

    getPostpaid(obj: any): Observable<any> {
      return this.http.post(`${this.pathApi}getPostpaid`, obj);
    }

    getFlaggedCustomersCount(obj:any): Observable<any> {
      return this.http.post(`${this.pathApi}getFlaggedCustomersCount`, obj);
    }

    downloadPrepaidCustomersReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadPrepaidCustomersReport`, obj);
    }  
    downloadPostpaidCustomersReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadPostpaidCustomersReport`, obj);
    } 
    updateBypass(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}updateBypass`, obj);
    }
    updatePostpaidBypass(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}updatePostpaidBypass`, obj);
    }
    downloadAndSendPrepaidCustomersReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadAndSendPrepaidCustomersReport`, obj);
    }
    downloadAndSendPostpaidCustomersReport(obj: any): Observable<any>{
      return this.http.post(`${this.pathApi}downloadAndSendPostpaidCustomersReport`, obj);
    }
}
