import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent{

  // public concentratorName :string = this.translationService.getByKeyFromCache('Concentrator');
  // public amiName :string = this.translationService.getByKeyFromCache('AMI');
  // public buName :string = this.translationService.getByKeyFromCache('BU');
  // public utName :string = this.translationService.getByKeyFromCache('UT');
  public dtName :string = this.translationService.getByKeyFromCache('DT');

  constructor(private toastr: ToastrService, private translationService: TranslationService) { }

  alert(){
    this.toastr.warning("Not yet implemented!");
  }

}
