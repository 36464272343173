<div *ngIf="isPopupOpen" class="popup-overlay" (click)="changeState(2)">
    <div class="popup-content" click-stop-propagation>
        <div class="cancel" (click)="changeState(2)">
            <img src="assets/images-new-design/cancel.svg" alt="Cancel">
        </div>
        <div class="current-module-wrapper">
            <img src="assets/images-new-design/{{currentModule}}.svg" alt="{{currentModule}}">
            <div class="module-name">{{currentModule}}</div>
            <div class="current-module">Current Module</div>
        </div>
        <div class="other-modules-wrapper">
            <p>Other modules</p>
            <div class="other-modules">
                <div 

                    class="module" 
                    *ngFor="let module of modules" 
                    [routerLink]="module.isAuthorized && availableModules.includes(module.name) ? [module.path] : null">
                    <div>
                        <img src="assets/images-new-design/{{module.name}}.svg" alt="{{module.name}}">
                        <span>{{module.name}}</span>
                    </div>
                    <div class="upgrade" (click)="upgradePlan(module.name)" *ngIf="availableModules && !availableModules.includes('Meter Management') && module.name === 'Meter Management'">Upgrade plan</div>
                    <div class="upgrade" (click)="upgradePlan(module.name)" *ngIf="availableModules && !availableModules.includes('Network Management') && module.name === 'Network Management'">Upgrade plan</div>
                    <div class="upgrade" (click)="upgradePlan(module.name)" *ngIf="availableModules && !availableModules.includes('Field Operations') && module.name === 'Field Operations'">Upgrade plan</div>
                    <div class="upgrade" (click)="upgradePlan(module.name)" *ngIf="availableModules && !availableModules.includes('Commercial') && module.name === 'Commercial'">Upgrade plan</div>
                </div>
            </div>
        </div>
    </div>
</div>
