<div class="container login-container">
    <div class="div-block">

        <div class="row-thanksgiving">

            <div class="col-sm-12 logo-img"><img src="assets/images/logo.svg" alt=""></div>
            <div class="transactionNum">
                Payment Reference No: {{transactionNo}}
                <div class="textField">
                    Dear {{account.firstName}} {{account.lastName}},
                    <div *ngIf="isSuccessfull">Your payment has been successfully made!
                        Your amount is: {{paymentAmount | number: '1.2-2'}}
                    </div>

                    <div *ngIf="!isSuccessfull">Your payment was not processed successfully!</div>
                </div>
            </div>

        </div>
    </div>