import { Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AmiService } from 'src/app/services/ami.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { DtService } from 'src/app/services/dt.service';
import { MeterService } from 'src/app/services/meter.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { MeterTriggersGrid } from './meter-triggers-grid/meter-triggers-grid.component';

@Component({
  selector: 'app-meter-triggers-page',
  templateUrl: './meter-triggers-page.component.html',
  styleUrl: './meter-triggers-page.component.scss'
})
export class MeterTriggersPageComponent implements OnInit {

  public tabMenu: number = 1;
  public meterTriggerType: string = 'Meter Cover Tamper';

  openCoverTamperCount: number = 0;
  openTerminalCount: number = 0;
  openMagneticCount: number = 0;
  lowCreditCount: number = 0;

  public dateFrom: any = ''
  public dateTo: any = ''
  public alarmCondition : number = 0;
  public commStatus : number = 0;
  public accStatus : number | null = null;
  public bypassStatus : number | null = null;


  @ViewChild('child') child!: MeterTriggersGrid
  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public searchPlaceholder: string = "Search by Meter Number, Account Number & SPN"
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []
  public searchLength: number = 1

  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  public meterIds: number[] = [];
  public buIds: number[] = [];
  public utIds: any = [];
  public dtIds: number[] = [];
  public amiIds: number[] = [];
  public customerTypeValue: number[] = [];
  // public voltageValue: number[] = [];
  public contractStatusValue: number[] = [];

  public customerTypeName: string = 'Customer Type'
  // public voltageName: string = 'Meter Voltage'
  public contractStatusName: string = 'Contract Status'
  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')
  public amiName: string = 'Meter Make'
  public dtName: string = this.translationService.getByKeyFromCache('DT')

  public buSelected: any = { value: 0, name: '', checked: false }
  public utSelected: any = { value: 0, name: '', checked: false }
  public dtSelected: any = { value: 0, name: '', checked: false }

  public customerTypeOptions: { name: string; data: { value: number; name: string }[], dropdown: boolean } = {name: this.customerTypeName, data:[{value: 2, name: 'MD'}, {value: 3, name: 'NMD'}], dropdown: false}
  // public voltageOptions: { name: string; data: { value: number; name: string }[], dropdown: boolean } = {name: this.voltageName, data:[{value: 1, name: 'High'}, {value: 2, name: 'Low'}], dropdown: false}
  public contractStatusOptions: { name: string; data: { value: number; name: string }[], dropdown: boolean } =
                          {name: this.contractStatusName, data:[{value: 1, name: 'Undefined'}, {value: 2, name: 'Active'}, {value: 3, name: 'Inactive'},
                          {value: 4, name: 'Suspended'}, {value: 5, name: 'Disconnected'}, {value: 6, name: 'Finalised'}], dropdown: false}

  public businessUnitsNew: any = { name: this.buName, data: [] };
  public undertakingsNew: any = { name: this.utName, data: [] };
  public amisNew: any = { name: this.amiName, data: [] };
  public dtsNew: any = { name: this.dtName, data: [] };

  public isDownload: boolean = false

  constructor(private toastr: ToastrService,
    private translationService: TranslationService,
    private meterService: MeterService,
    private buService: BusinessUnitService,
    private utService: UndertakingService,
    private dtService: DtService,
    private amiService: AmiService
    ){}

  ngOnInit(): void {
    this.setMainDate()
    this.getAllBUs()
    this.getAllUTs()
    this.getAllDTs()
    this.getAllAmis()
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
  }

  setMainDate(){
    let dteFrom = new Date()
    let dte = new Date()

    dteFrom.setHours(2, 0, 0);
    dte.setHours(2, 0, 0);

    dteFrom.setDate(1)
    dteFrom.setMonth(dteFrom.getMonth() - 1);
    dte.setDate(1)

    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate()};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate()};
  }

  menuChanged(value: number) {
    this.tabMenu = value;
    if (value == 1) {
      this.meterTriggerType = 'Meter Cover Tamper'
      this.tabChanged(1)
    }
    else if (value == 2) {
      this.meterTriggerType = 'Open Terminal'
      this.tabChanged(2)
    }
    else if (value == 3) {
      this.meterTriggerType = 'Magnetic Tamper'
      this.tabChanged(3)
    }
    else if (value == 4) {
      this.meterTriggerType = 'Low Credit'
      this.tabChanged(4)
    }
  }

  tabChanged(menu: number) {
    this.tabMenu = menu;
    this.resetFilters();
  }

  handleCountChange(count: any) {
    this.openCoverTamperCount = count;
    this.openTerminalCount = count;
    this.openMagneticCount = count;
    this.lowCreditCount = count;
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)

      if (item.name === this.undertakingsNew.name) {
        this.utIds = filteredValues
      }
      else if (item.name === this.businessUnitsNew.name) {
        this.buIds = filteredValues
      }
      else if (item.name === this.dtsNew.name) {
        this.dtIds = filteredValues
      }
      else if (item.name === this.amisNew.name) {
        this.amiIds = filteredValues
      }
      else if (item.name === this.contractStatusOptions.name) {
        this.contractStatusValue = filteredValues
        console.log(this.contractStatusValue)
      }
      // else if (item.name === this.voltageOptions.name) {
      //   this.voltageValue = filteredValues
      // }
      else if (item.name === this.customerTypeOptions.name) {
        this.customerTypeValue = filteredValues
      }
    }
  }

  checkFilters() {
    this.filterApplied = true;

    let customerTypeIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.customerTypeOptions.name);
    if (customerTypeIndex !== -1)
      this.allFilters[customerTypeIndex].data = this.customerTypeOptions.data;
    else
      this.allFilters.push(this.customerTypeOptions)

    // let voltageIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.voltageOptions.name);
    //   if (voltageIndex !== -1)
    //     this.allFilters[voltageIndex].data = this.voltageOptions.data;
    //   else
    //     this.allFilters.push(this.voltageOptions)

    let contractStatusIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.contractStatusOptions.name);
      if (contractStatusIndex !== -1)
        this.allFilters[contractStatusIndex].data = this.contractStatusOptions.data;
      else
        this.allFilters.push(this.contractStatusOptions)

    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnitsNew.name);
    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnitsNew.name);
    if (buIndex !== -1) {
      this.allFilters[buIndex].data = this.businessUnitsNew.data;
    }

    let utData = this.allFilters.find((item: { name: any; }) => item.name === this.undertakingsNew.name);
    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakingsNew.name);
    if (utIndex !== -1) {
      this.allFilters[utIndex].data = this.undertakingsNew.data;
    }

    let dtData = this.allFilters.find((item: { name: any; }) => item.name === this.dtsNew.name);
    let dtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.dtsNew.name);
    if (dtIndex !== -1) {
      this.allFilters[dtIndex].data = this.dtsNew.data;
    }

    let amiData = this.allFilters.find((item: { name: any; }) => item.name === this.amisNew.name);
    let amiIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.amisNew.name);
    if (amiIndex !== -1) {
      this.allFilters[amiIndex].data = this.amisNew.data;
    }

    if (!buData)
      this.allFilters.push(this.businessUnitsNew)
    if (!utData)
      this.allFilters.push(this.undertakingsNew)
    if (!amiData)
      this.allFilters.push(this.amisNew)
    if (!dtData)
      this.allFilters.push(this.dtsNew)


    this.allFilters = [...this.allFilters]
  }

  searchChange(text: SearchAutocomplete) {
    this.searchText = text
    this.searchText = cloneDeep(this.searchText)
  }

  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true,
      MeterItemType: this.meterTriggerType,
    }
    this.searchChild.searchLength = this.searchLength;
    if (searchText.name.length > 1) {
      this.meterService.getDataForAutocomplete(obj)
        .subscribe(response => {
          this.options = response.data
        })
    } else {
      this.searchChild.resetOptions()
    }
  }

  getAllBUs() {
    this.buService.getAllForSelect().subscribe(response => {
      this.businessUnitsNew = { name: this.buName, data: response.data }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getAllAmis() {
    this.amiService.getAllForSelect().subscribe(response => {
      this.amisNew = { name: this.amiName, data: response.data }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getAllUTs() {
    this.utService.getAllForSelect().subscribe(response => {
      this.undertakingsNew = { name: this.utName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getAllDTs() {
    this.dtService.getAllForSelect().subscribe(response => {
      this.dtsNew = { name: this.dtName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  resetFilters() {
    this.searchPlaceholder = "Search by Meter Number, Account Number & SPN";

    this.searchText = new SearchAutocomplete()
    if (this.searchChild) {
      this.searchChild.searchText = new SearchAutocomplete()
      this.searchChild.resetOptions()
    }
    this.setMainDate()

    //multiFilter
    this.meterIds = []
    this.buIds = []
    this.utIds = []
    this.amiIds = []
    this.dtIds = []
    this.customerTypeValue = []
    this.contractStatusValue = []
    this.accStatus = null;
    this.bypassStatus = null;
    this.alarmCondition = 0;
    this.commStatus = 0;
    // this.voltageValue = []
    this.allFilters = []
    this.showFilter = false
    this.filterApplied = false
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.checkFilters()

  }

  download() {
    this.isDownload = !this.isDownload    //Just change value so ngOnChange can be triggered
    this.child.download()
  }

}
