import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RevenueDashboardService } from 'src/app/services/revenue-dashboard.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-overdue-postpaid-bill-debts',
  templateUrl: './overdue-postpaid-bill-debts.component.html',
  styleUrls: ['./overdue-postpaid-bill-debts.component.scss']
})
export class OverduePostpaidBillDebtsComponent implements OnInit {

  public filterSelected = 1
  public data: any[] = []
  public grandTotal = 0
  public currentPage = 1
  public pageSize = 5
  public count = 24

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public MD: string =  this.translationService.getByKeyFromCache('MD');
  public NMD: string = this.translationService.getByKeyFromCache('NMD');

  constructor(
    private dashboardService: RevenueDashboardService,
    private toastr: ToastrService,
    private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.reloadTable();
  }

  filterClicked(value: number){
    this.filterSelected = value
    this.currentPage = 1
    this.reloadTable()
  }

  reloadTable(){
    let obj = {
      PageInfo: {
        Page: this.currentPage,
        PageSize: this.pageSize
      },
      FilterParams: {
        CustomersType: this.filterSelected,
      }
    }

   this.dashboardService.getPostpaidBillDebts(obj).subscribe(response =>{
    if(response.status === 200){
      this.data = response.data.items.data
      if(this.currentPage == 1)
        this.count = response.data.items.count
      this.grandTotal = response.data.grandTotal
    }
    else{
      this.toastr.error(response.message)
    }
   })
  }

  pageChanged(page: any){

    this.currentPage = page
    this.reloadTable()
  }

}
