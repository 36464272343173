<div class="slider-back" [hidden]="!showShadow">

</div>

<div [hidden]="!showShadow">
    <div class="slider" [ngClass]="{'shadow' : showShadow === true}" [@slideInOut]="animationState">
        <div class="tab-header">Create/Edit Payment Configuration
            <div class="btn-close-position" (click)="changeState(2)">
                <div class="btn-close">
                    <img alt="Close" src="../../../../../../assets/images-new-design/Close.png">
                </div>
            </div>

        </div>
        <form (submit)="f.valid" class="form col-sm-12" ngNativeValidate #f="ngForm">
            <div  class="col-sm-12 crud-input-container">
                <label class="detail">Bill Overdue Flag 1</label>
                <input class="form-control" type="number" name="flag1" [(ngModel)]="flag1.value" #name="ngModel" required autocomplete="off" pattern="^[0-9]+(?:[0-9\.?]*[0-9]+)*$"/>
                <div *ngIf="f.submitted && !validateFlag1()" class="form-control-error">Bill overdue flag 1 is required</div>
              </div>

              <div  class="col-sm-12 crud-input-container">
                <label class="detail">Bill Overdue Flag 2</label>
                <input class="form-control" type="number" name="flag2" [(ngModel)]="flag2.value" #name="ngModel" required autocomplete="off" pattern="^[0-9]+(?:[0-9\.?]*[0-9]+)*$"/>
                <div *ngIf="f.submitted && !validateFlag2()" class="form-control-error">Bill overdue flag 2 is required</div>
              </div>

              <div  class="col-sm-12 crud-input-container">
                <label class="detail">SMS Automatic Send Flag</label>
                <select style="cursor: pointer;" class="form-control" name="accountType" [(ngModel)]="flag3.value" #name="ngModel" (click)="updateSMSFlag($event)" >
                    <option selected disabled [ngValue]='0'>Select SMS Automatic Send Flag</option>
                    <option [ngValue]='"True"'>True</option>
                    <option  [ngValue]='"False"'>False</option>
                </select>
            </div>

        </form>
        <div class="user-btn">

            <button class="submit col-xs-6" (click)="save()">Save</button>
            <button class="cancel-btn col-xs-6" (click)="changeState(2)">Cancel</button>
        </div>
    </div>
</div>
