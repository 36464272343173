<app-delete-popup  (parentComp)="deleteTeam()"></app-delete-popup>
<main class="container-fluid">
    <div class="gep-container">
        <div class="details">
            <div class="close-content">
                <div class="header-back" (click)="back()">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.52116 7.83342H12.8337C13.0698 7.83342 13.2677 7.75356 13.4274 7.59384C13.5871 7.43412 13.667 7.2362 13.667 7.00009C13.667 6.76398 13.5871 6.56606 13.4274 6.40634C13.2677 6.24662 13.0698 6.16676 12.8337 6.16676H3.52116L7.60449 2.08342C7.77116 1.91676 7.85102 1.72231 7.84408 1.50009C7.83713 1.27787 7.75033 1.08342 7.58366 0.916758C7.41699 0.76398 7.22255 0.684119 7.00033 0.677174C6.7781 0.67023 6.58366 0.750091 6.41699 0.916758L0.916992 6.41676C0.833659 6.50009 0.774632 6.59037 0.739909 6.68759C0.705187 6.78481 0.687826 6.88898 0.687826 7.00009C0.687826 7.1112 0.705187 7.21537 0.739909 7.31259C0.774632 7.40981 0.833659 7.50009 0.916992 7.58342L6.41699 13.0834C6.56977 13.2362 6.76074 13.3126 6.98991 13.3126C7.21908 13.3126 7.41699 13.2362 7.58366 13.0834C7.75033 12.9168 7.83366 12.7188 7.83366 12.4897C7.83366 12.2605 7.75033 12.0626 7.58366 11.8959L3.52116 7.83342Z" fill="#1C1B1F"/>
                      </svg>
                  </div>
                <div class="editAgent">
                    <button class="editbtn" (click)="sendObjectToAnotherComponent()">Edit Team <img alt="Edit"
                            src="assets/images-new-design/stylus.png"></button>
                </div>
            </div>
            <div class="header">
                <img class="bin" *ngIf="selectedWomTeam.profileImage else defaultImage" [src]="selectedWomTeam.profileImage" alt="Profile">
                <ng-template #defaultImage>
                    <img class="bin" src="assets/images-new-design/defaultImg.png" alt="Default">
                  </ng-template>
                <div class="teamName">{{selectedWomTeam.name}}</div>
            </div>

            <div class="download-content">

                <div class="form">
                    <label class="label-opt">Status</label>

                    <div class="status" [ngClass]="{'blue-background': selectedWomTeam.status === 'Engaged', 'green-background': selectedWomTeam.status === 'Unengaged'
                }">{{selectedWomTeam.status}}</div>
                </div>
                <div class="form">
                    <label class="label-opt">Team ID</label>
                    <div class="flex">
                        <div class="formEmail">
                            <div>{{selectedWomTeam.id}}</div>
                        </div>
                        <div class="mail">
                            <img alt="Copy" class="icons" src="assets/images-new-design/copy.png" (click)="copyToClipboard()">
                        </div>
                    </div>
                </div>

                <div class="form">
                    <label class="label-opt">Team Lead</label>
                    <span>
                        <div class="teamLead">
                            <img alt="Team" class="header-icon" *ngIf="selectedWomTeam.image !== null"
                                src="{{ selectedWomTeam.image }}">
                            <span>{{ selectedWomTeam.teamLeadName | hasValue }} </span>
                        </div>
                    </span>
                </div>
                <div class="form">
                    <label class="label-opt">Agents</label>
                    <ng-container *ngIf="selectedWomTeam.photoUrl && selectedWomTeam.photoUrl.length > 0">
                         <div [style.width.rem]="(selectedWomTeam.photoUrl.length * 1.7) + 2" class="images-container">
                            <img alt="" [style.left.rem]="(i * 1.7)"
                                *ngFor="let image of selectedWomTeam.photoUrl; let i = index"
                                [src]="image != null ? image : '/assets/images-new-design/defaultImg.png'" />
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedWomTeam.agents && selectedWomTeam.agents.length > 0; else noAgents">
                        <ng-container *ngIf="selectedWomTeam.agents.length > 1">
                            <span class="btnPlus"> +{{ selectedWomTeam.agents.length - 1 }}</span>
                        </ng-container>
                    </ng-container>
                    <ng-template #noAgents>
                        <span  class="teamLead">No assign members/agents</span>
                    </ng-template>
                </div>

                <div class="form">
                    <label class="label-opt">{{buName}}</label>
                    <div  class="teamLead">{{selectedWomTeam.buName | hasValue }}</div>
                </div>
                <div class="form">
                    <label class="label-opt">{{utName}}</label>
                    <div  class="teamLead">{{selectedWomTeam.utName | hasValue }}</div>
                </div>
                <div class="form">
                    <label class="label-opt">Date Created</label>
                    <div  class="teamLead">{{date}}</div>
                </div>

            </div>
            <div class="horizontal-line"></div>

            <div class="bottom">

                <div class="editDlt">

                    <div class="deactiv">
                        <button class="editbtn" (click)="openDeletePopup()">
                            Dissolve Team
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="task">
            <div class="task-header">
                <div class="header">Members
                </div>
                <div class="editAgent">
                    <button class="editbtn" (click)="create(0)">Delete All</button>
                </div>
            </div>
            <div class="search-container">

                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M18.0918 16.9083L15.0002 13.8417C16.2002 12.3453 16.7814 10.4461 16.6242 8.53441C16.4669 6.62275 15.5832 4.84398 14.1548 3.56387C12.7263 2.28375 10.8617 1.59959 8.94427 1.65206C7.02686 1.70452 5.20243 2.48963 3.84612 3.84595C2.4898 5.20227 1.70469 7.0267 1.65222 8.9441C1.59976 10.8615 2.28392 12.7261 3.56403 14.1546C4.84415 15.5831 6.62292 16.4668 8.53458 16.624C10.4462 16.7812 12.3455 16.2001 13.8418 15L16.9085 18.0667C16.986 18.1448 17.0781 18.2068 17.1797 18.2491C17.2812 18.2914 17.3902 18.3132 17.5002 18.3132C17.6102 18.3132 17.7191 18.2914 17.8206 18.2491C17.9222 18.2068 18.0144 18.1448 18.0918 18.0667C18.242 17.9113 18.326 17.7036 18.326 17.4875C18.326 17.2714 18.242 17.0637 18.0918 16.9083ZM9.16683 15C8.01311 15 6.88529 14.6579 5.926 14.0169C4.96672 13.3759 4.21904 12.4649 3.77753 11.399C3.33602 10.3331 3.2205 9.16019 3.44558 8.02864C3.67066 6.89708 4.22624 5.85768 5.04204 5.04187C5.85785 4.22607 6.89725 3.6705 8.0288 3.44542C9.16036 3.22034 10.3332 3.33585 11.3992 3.77737C12.4651 4.21888 13.3761 4.96655 14.0171 5.92584C14.658 6.88512 15.0002 8.01294 15.0002 9.16666C15.0002 10.7138 14.3856 12.1975 13.2916 13.2915C12.1977 14.3854 10.7139 15 9.16683 15Z"
                        fill="#878787" />
                </svg>
                <input class="search-input" type="text" name="searchText" [(ngModel)]="searchText"
                    placeholder="Search by name" (ngModelChange)="searchChange($event)">

            </div>
            <div class="simple-dropdown">
                <div class="simple-item" *ngFor="let item of newAgents; let i = index">
                    <div class="item">
                      <div class="name">
                        <img alt="Team" class="photoUrl" *ngIf="selectedWomTeam.photoUrl[i]"
                             [src]="selectedWomTeam.photoUrl[i]" />
                        <img  alt="Default" class="photoUrl" *ngIf="!selectedWomTeam.photoUrl[i]"
                             src="assets/images-new-design/defaultImg.png">
                        <p class="first">{{ item }} <span class="teamLeadMember" *ngIf="item.includes(selectedWomTeam.teamLeadName)">Team Lead</span></p>
                      </div>
                        <div class="editAgent">
                            <button class="editbtn" (click)="create(item)" *ngIf="!item.includes(selectedWomTeam.teamLeadName)">Remove</button>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</main>
