<app-f11-outage-popup></app-f11-outage-popup>
<main class="outage-wrapper">
    <table class="standard-table outage-table" *ngIf="menu === menuItemEnum.CurrentOutage">
        <thead>
            <tr>
                <th><span><input class="input-checkmark" type="checkbox" name="list_name" value="h1" [(ngModel)]="masterSelected" (change)="checkUncheckAll()" /></span></th>
                <th title="11Kv Feeder Name"><span>11KV FEEDER NAME</span></th>
                <th title="Feeder Number"><span>FEEDER NUMBER</span></th>
                <th title="Substation"><span>CONNECTED SS</span></th>
                <th title="Meter Number"><span>METER NUMBER</span></th>
                <th title="No. of Customers Impacted"><span>NO. OF CUSTOMERS IMPACTED</span></th>
                <th title="Outage Start Time"><span>OUTAGE START TIME</span></th>
                <th title="Current Outage Length (hrs)" ><span>CURRENT OUTAGE LENGTH (HRS)</span></th>
                <th title="Outage Category"><span>OUTAGE CATEGORY</span></th>
                <th></th>
            </tr>
        </thead>
        
        <tbody>
            <tr *ngFor="let item of data" class="clickable actions-click"  (click)="viewMore(item)">
                <td (click)="false; $event.stopPropagation();"><span> <input class="input-checkmark" type="checkbox" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" /></span> </td>

                <td title="{{item.name}}">
                    <span *ngIf="item.name && item.name != '/'">{{item.name}}</span>
                    <span *ngIf="!item.name || item.name == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.number}}">
                    <span *ngIf="item.number && item.number != '/'">{{item.number}}</span>
                    <span *ngIf="!item.number || item.number == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.parentName}}">
                    <span *ngIf="item.parentName && item.parentName != '/'">{{item.parentName}}</span>
                    <span *ngIf="!item.parentName || item.parentName == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.msn}}">
                    <span *ngIf="item.msn && item.msn != '/'">{{item.msn}}</span>
                    <span *ngIf="!item.msn || item.msn == '/'" class="NaN">N/A</span>
                </td>
                <td class="center" title="{{item.noOfCustomersImpacted}}">
                    <span *ngIf="item.noOfCustomersImpacted && item.noOfCustomersImpacted != '/'">{{item.noOfCustomersImpacted | number}}</span>
                    <span *ngIf="!item.noOfCustomersImpacted || item.noOfCustomersImpacted == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.outageStart}}">
                    <span *ngIf="item.outageStart && item.outageStart != '/'">{{(item.outageStart | date:'MMM dd,yyyy, HH:mm:ss')}}</span>
                    <span *ngIf="!item.outageStart || item.outageStart == '/'" class="NaN">N/A</span>
                </td>
                <td  class="center" title="{{item.currentOutageLength}}">{{(item.currentOutageLength ? item.currentOutageLength : "0") | shortDatetime}} </td>
                <td title="{{item.category}}" class="planned" [ngClass]="{'unplanned': !isPlanned(item.category)}">
                    <span *ngIf="item.category && item.category != '/'">{{item.category}}</span>
                    <span *ngIf="!item.category || item.category == '/'" class="NaN">N/A</span>
                </td>
                 <td> <button class="see-more-btn" id="openPopup">See More</button></td>

            </tr>
        </tbody>
    </table>

    <table class="standard-table outage-table history-table" *ngIf="menu === menuItemEnum.HistoricalOutage">
        <thead>
            <tr>
                <th><span><input class="input-checkmark" type="checkbox" name="list_name" value="h1" [(ngModel)]="masterSelected" (change)="checkUncheckAll()" /></span></th>
                <th title="11Kv Feeder Name"><span>11KV FEEDER NAME</span></th>
                <th title="Feeder Number"><span>FEEDER NUMBER</span></th>
                <th title="Substation"><span>CONNECTED SS</span></th>
                <th title="Meter Number"><span>METER NUMBER</span></th>
                <th title="No. of Customers Impacted"><span>NO. OF CUSTOMERS IMPACTED</span></th>
                <th title="Outage Start Time"><span>OUTAGE START TIME</span></th>
                <th title="Outage End Time"><span>OUTAGE END TIME</span></th>
                <th title="Fault Resolution Time" ><span>FAULT RESOLUTION TIME</span></th>
                <th title="Supply Restoration Time" ><span>SUPPLY RESTORATION TIME</span></th>
                <th title="Outage Length (hrs)" ><span>OUTAGE LENGTH (HRS)</span></th>
                <th title="Outage Category"><span>OUTAGE CATEGORY</span></th>
            </tr>
        </thead>
        
        <tbody>
            <tr *ngFor="let item of data">
                <td (click)="false; $event.stopPropagation();"><span> <input class="input-checkmark" type="checkbox" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" /></span> </td>

                <td title="{{item.name}}">
                    <span *ngIf="item.name">{{item.name}}</span>
                    <span *ngIf="!item.name" class="NaN">N/A</span>
                </td>
                <td title="{{item.number}}">
                    <span *ngIf="item.number && item.number != '/'">{{item.number}}</span>
                    <span *ngIf="!item.number || item.number == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.parentName}}">
                    <span *ngIf="item.parentName && item.parentName != '/'">{{item.parentName}}</span>
                    <span *ngIf="!item.parentName || item.parentName == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.msn}}">
                    <span *ngIf="item.msn && item.msn != '/'">{{item.msn}}</span>
                    <span *ngIf="!item.msn || item.msn == '/'" class="NaN">N/A</span>
                </td>
                <td class="center"  title="{{item.noOfCustomersImpacted}}">
                    <span *ngIf="item.noOfCustomersImpacted && item.noOfCustomersImpacted != '/'">{{item.noOfCustomersImpacted | number}}</span>
                    <span *ngIf="!item.noOfCustomersImpacted || item.noOfCustomersImpacted == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.outageStart}}">
                    <span *ngIf="item.outageStart && item.outageStart != '/'">{{(item.outageStart | date:'MMM dd,yyyy, HH:mm:ss')}}</span>
                    <span *ngIf="!item.outageStart || item.outageStart == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.outageEnd}}">
                    <span *ngIf="item.outageEnd && item.outageEnd != '/'">{{(item.outageEnd | date:'MMM dd,yyyy, HH:mm:ss')}}</span>
                    <span *ngIf="!item.outageEnd || item.outageEnd == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.faultResolutionTime}}">
                    <span *ngIf="item.faultResolutionTime && item.faultResolutionTime != '/'">{{(item.faultResolutionTime | date:'MMM dd,yyyy, HH:mm:ss')}}</span>
                    <span *ngIf="!item.faultResolutionTime || item.oufaultResolutionTimetageEnd == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.supplyRestorationTime}}">
                    <span *ngIf="item.supplyRestorationTime && item.supplyRestorationTime != '/'">{{(item.supplyRestorationTime | date:'MMM dd,yyyy, HH:mm:ss')}}</span>
                    <span *ngIf="!item.supplyRestorationTime || item.supplyRestorationTime == '/'" class="NaN">N/A</span>
                </td>
                <td class="center"  title="{{item.currentOutageLength}}">{{(item.currentOutageLength ? item.currentOutageLength : "0") | shortDatetime}} </td>
                <td title="{{item.category}}" class="planned" [ngClass]="{'unplanned': !isPlanned(item.category)}">
                    <span *ngIf="item.category && item.category != '/'" >{{item.category}}</span>
                    <span *ngIf="!item.category || item.category == '/'" class="NaN">N/A</span>
                </td>
            </tr>
        </tbody>
    </table> 
</main>
<div class="pagination-wrapper">
    <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
        [currentPage]="currentPage" (pageChange)="pageChange($event)">
    </app-fo-pagination-new>
</div>
