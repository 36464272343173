<main class="container-fluid">
    <div class="gep-container">
        <div class="revenue-protection-header">
            <div class="revenue-protection-page-header">
                <span class="mainTitle">Teams and Agents</span>
                <div class="button" *ngIf="activeTab=='Teams'">
                    <button class="btn-white" (click)="deleteTeam()" click-stop-propagation>
                        Dissolve Team
                    </button>
                    <button class="red-btn" routerLink="/field-operations/team-create" click-stop-propagation>
                        Create Team <img alt="Add" src="assets/images-new-design/plus.png">
                    </button>
                </div>
                <div class="button" *ngIf="activeTab=='Agents'">
                    <button class="btn-white" *ngIf="tabMenu!==3" (click)="deactivate()" click-stop-propagation>
                        Deactivate
                    </button>
                    <button class="btn-white" *ngIf="tabMenu===3" (click)="activate()" click-stop-propagation>
                        Activate
                    </button>
                    <button class="red-btn" routerLink="/field-operations/agent-create">
                        Create Agent <img alt="Add" src="assets/images-new-design/plus.png">
                    </button>
                </div>

            </div>
        </div>

        <div class="filter-wrapper">
            <app-tab-default [firstText]="'Agents'" [secondText]="'Teams'" [firstTabNum]="agentsCount"
                [secondTabNum]="teamCount" [wom]="true" (tabChanged)="mainTabChange($event)"></app-tab-default>
        </div>

        <div *ngIf="activeTab === 'Agents'" class="horizontal-line hr"></div>

        <div class="filter-wrapper second" *ngIf="activeTab==='Agents'">
            <app-tab-default [firstText]="'Unengaged '" [firstTabNum]="unengagedCount" [secondText]="'Engaged '"
                [secondTabNum]="engagedCount" [thirdTabNum]="inactiveCount" [thirdText]="'Inactive '" [wom]="true"
                [womCss]="true" (tabChanged)="onStatusChange($event)"></app-tab-default>
        </div>

        <div class="horizontal-line horizontal-bottom"></div>
        <div class="filters-container">

            <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">
                <app-multi-filter *ngIf="filterApplied"
                    [data]="allFilters"
                    (dataChange)="applyFilter($event)"
                    (dropdownChange)="dropdownSelected($event)"
                    [(showFilter)]="showFilter"
                    >
                </app-multi-filter>

            <app-search-autocomplete
                #search
                [placeholder]="searchPlaceholder"
                [width]="35"
                [backgroundColor]="'transparent'"
                [borderColor]="'#E8E8E8'"
                [(searchText)]="searchText"
                [searchLength]="2"
                [options]="options"
                (searchChange)="searchChange($event)"
                (searchAutocompleteChange)="getOptions($event)"
            >
            </app-search-autocomplete>
        </div>
      </div>

        <ng-container *ngIf="activeTab === 'Agents'; then agentTab else teamsTab"></ng-container>

        <ng-template #agentTab>
            <app-agent-management #agentManagementComponent #agentTab *ngIf="tabMenu === 1" (parentComp)="reloadTable($event)" (parentComp)="getFieldAgentCount()"
                [tab]="tabMenu" [businessUnits]="businessUnits"
                [unengagedFieldAgents]="unengagedFieldAgents"></app-agent-management>
            <app-agent-management (parentComp)="reloadTable($event)" (parentComp)="getFieldAgentCount()"  #agentManagementComponent #agentTab *ngIf="tabMenu === 2"
                [tab]="tabMenu" [businessUnits]="businessUnits"
                [engagedFieldAgents]="engagedFieldAgents"></app-agent-management>
            <app-agent-management (parentComp)="reloadTable($event)" (parentComp)="getFieldAgentCount()"  #agentManagementComponent #agentTab *ngIf="tabMenu === 3"
                [tab]="tabMenu" [businessUnits]="businessUnits"
                [inactiveFieldAgents]="inactiveFieldAgents"></app-agent-management>
        </ng-template>

        <ng-template #teamsTab>
            <app-team-management (parentComp)="reloadTable()" (parentComp)="getFieldAgentCount()" [womTeamData]="womTeamData" #teamManagementComponent
                [businessUnits]="businessUnits"></app-team-management>
        </ng-template>

        <div class="pagination-wrapper">
            <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
                [currentPage]="currentPage" (pageChange)="pageChange($event)">
            </app-fo-pagination-new>
        </div>
    </div>
</main>
