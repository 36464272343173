import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { TranslationService } from 'src/app/services/translation.service';
import { transformToString } from 'src/app/helpers/dateUtils';
import { MeterBypassService } from 'src/app/services/meter-bypass.service';
import { ToastrService } from 'ngx-toastr';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { SubstationService } from 'src/app/services/substation.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { AccountService } from 'src/app/services/account.service';
import { OrganizationNetworkTabs } from 'src/app/models/revenue-protection-enums.model';
import { AuthService } from 'src/app/services/auth.service';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { PaginationComponent } from 'src/app/components/common-new-design/pagination/pagination.component';
import { FilterDropdownSearchComponent } from 'src/app/components/common-new-design/filter-dropdown-search/filter-dropdown-search.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MultiFilterComponent } from 'src/app/components/common-new-design/multi-filter/multi-filter.component';
import { Router } from '@angular/router';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-meter-bypass-postpaid',
  templateUrl: './meter-bypass-postpaid.component.html',
  styleUrls: ['./meter-bypass-postpaid.component.scss'],
  animations: [
    trigger('fadeInOutCheckSvg', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [
        animate('0.3s ease-in-out')
      ])
    ])
  ]
})
export class MeterBypassPostpaidComponent implements OnInit {


  @ViewChild(PaginationComponent) paginationComponentRef!: PaginationComponent;


  public currentPage: number = 1;
  public pageSize: number = 10;
  public count: number = 0;
  public lastPageSize: number = 1;

  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public dtName: string = this.translationService.getByKeyFromCache('DT');
  public ssName: string = "Substation";
  public f11Name: string = "Feeder";

  public dateFrom: any = "";
  public dateTo: any = "";
  public searchText: SearchAutocomplete = new SearchAutocomplete();

  public bypassPopup: number = -1
  public bypassCheckbox: number = 3

  public meterBypasses: any;
  public selectedMeterBypass: any;

  public areas: any;
  public areasCount: number = 0;
  public totalCount: number = 0;
  public previousTotalCount: number = 0;

  public masterSelected: boolean = false;
  public checkedList: number[] = [];

  filterByDateClicked: boolean = false;

  public substationSelected: DictionaryItem = new DictionaryItem(0, 'Choose SS')
  public tsSelected: DictionaryItem = new DictionaryItem(0, 'Choose TS')
  public feeder11Selected: DictionaryItem = new DictionaryItem(0, 'Choose Feeder11')
  public feeder33Selected: DictionaryItem = new DictionaryItem(0, 'Choose Feeder33')
  public buSelected: DictionaryItem = new DictionaryItem(0, 'Choose ' + this.buName)
  public utSelected: DictionaryItem = new DictionaryItem(0, 'Choose ' + this.utName)
  private dtSelectedId: number = 0

  @ViewChild('child1') child1!: FilterDropdownSearchComponent

  public tabsEnum = OrganizationNetworkTabs
  public mainTab: OrganizationNetworkTabs = OrganizationNetworkTabs.Organization

  public accountIds : number[] = [];
  public statusCountList : any;
  public showPaging: boolean=false;
  public showDetails: boolean = false;
  public detailsData: any;

  constructor(public meterBypassService: MeterBypassService, public toastr: ToastrService, private location: Location,
     private translationService: TranslationService, public authService: AuthService, public undertakingService: UndertakingService,
     public dtService: DtService,public feeder11Service: Feeder11Service, public accountService: AccountService,
     public businessUnitService: BusinessUnitService, public substationService: SubstationService, public router : Router, public datePipe: DatePipe) { }


  ngOnInit(): void {
    this.getBusForSelect();
    this.setMainDate();
    this.reloadTable();
    this.showPaging=true;
  }

  reloadTable(page: any = null){
    if(page)
      this.currentPage = page;

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      FilterParams:{
        businessUnitId: this.buId,
        undertakingId: this.undertakingId,
        dtId: this.dtId,
        feeder11Id: this.feeder11Id,
        substationId: this.ssId,
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        search: this.searchText ? this.searchText.name : this.searchText,
        alarmCondition : this.alarmCondition,
        bypassStatus : this.bypassStatus,
      }
    };

    this.meterBypassService.getAllPostpaid(obj).subscribe(response => {
      this.meterBypasses = response?.data?.data ?? [];
      this.isAlreadyChecked();
      if(this.currentPage == 1)
        this.count = response.data.count;
      this.lastPageSize = Math.ceil(this.count/this.pageSize)
      this.showPaging=false;

    }, error => {
      this.toastr.error('An error occurred.');
    });
    this.showPaging=true;
    this.getTop5Areas()
    this.getStatusCount();
  }

  getTop5Areas(){
    // let obj = {
    //     dateFrom: transformToString(this.dateFrom),
    //     dateTo: transformToString(this.dateTo),
    // };
    this.meterBypassService.getTop5AreasAEDCPostpaid().subscribe(response => {
      this.areas = response.data?.meterBypassAreaData;
      if(this.areas){
      this.areasCount = this.areas.length
      console.log(this.areasCount)
      this.totalCount = response.data.meterBypassAreaCount.count;
      this.previousTotalCount = response.data.meterBypassAreaCount.previousMonthCount;
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getStatusCount(){
    let obj = {
      dateFrom: transformToString(this.dateFrom),
      dateTo: transformToString(this.dateTo),
    };
    this.meterBypassService.getStatusCount(obj).subscribe(response => {
      this.statusCountList = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  closeBypassPopup(){
    this.bypassPopup = -1
  }

  updateBypass(item: any, value: number){
    let oldValue = value;
    item.status = value
    this.meterBypassService.updatePostpaidBypass(item).subscribe(response =>{
      if(response.status == 200){
        this.toastr.success("Success")
      }
      else{
        this.toastr.error(response.message)
        item.status = oldValue
      }
    }, _=>{
      this.toastr.error('An Error Occurred')
      item.status = oldValue
    })
  }

  public resetAll(){
    this.setMainDate()
    this.undertakings = { name: this.utName, data: [], dropdown: true };
    this.feeders11 = { name: this.f11Name, data: [], dropdown: true  };
    this.allFilters = [];
    this.substationSelected = new DictionaryItem(0, 'Choose Substation')
    this.tsSelected = new DictionaryItem(0, 'Choose TS')
    this.buSelected=new DictionaryItem(0, 'Choose Region')
    this.feeder11Selected= new DictionaryItem(0, 'Choose Feeder11');
    this.feeder33Selected= new DictionaryItem(0, 'Choose Feeder33');
    this.utSelected= new DictionaryItem(0, 'Choose Area')
    this.dtSelectedId = 0
    this.searchText = new SearchAutocomplete();
    this.alarmCondition = null;
    this.bypassStatus = null;

    this.buId = 0
    this.undertakingId = 0;
    this.dtId = 0;
    this.feeder11Id = 0
    this.ssId = 0;
    this.showFilter = false
    this.filterApplied = false
    this.filterByDateClicked = false;
    this.pageChange(1);
    this.checkedList = [];
    this.reloadTable();
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
  }


  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  inputChanged(searchText: SearchAutocomplete){
    this.searchText = searchText
    this.reloadTable()
  }

  mainTabChange(value: number){
    this.resetAll();
    if(value == 1){
      this.mainTab = this.tabsEnum.Organization
      this.getBusForSelect();
    }
    else{
      this.mainTab = this.tabsEnum.Network
      this.getSubstationsForSelect();
    }
  }


  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    if(dte.getMonth() == 0)
    {
      this.dateFrom = {year: dte.getFullYear() - 1, month: 12, day: 1};
    }
    else {
      this.dateFrom = {year: dte.getFullYear(), month: dte.getMonth(), day: 1};
    }
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: 1};
  }

  updateDate(date: any){
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.checkedList = [];
    this.reloadTable();
  }

  getBusForSelect() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data, dropdown: true  };
      this.checkFilters();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getSubstationsForSelect(){
    this.substationService.getAllForSelect().subscribe(response => {
      this.substations ={ name: this.ssName, data: response.data, dropdown: true  };
      this.checkFilters();
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }


  selectPostpaidCustomer(id: number, period: any){
    this.showDetails = true;
    this.detailsData = {
      id:  id,
      dateFrom: this.datePipe.transform(period,'MMM. yyyy')
    };
}

  // openTab(){
  //   this.popupComponentRef.changeState(1, this.selectedMeterBypass);
  // }
  // closeTab(){
  //   this.popupComponentRef.changeState(2, this.selectedMeterBypass);
  // }

  back(){
    this.location.back();
  }

  checkUncheckAll() {
    for (let i = 0; i < this.meterBypasses.length; i++) {
      this.meterBypasses[i].checked = this.masterSelected;
      this.checkForDownload(this.meterBypasses[i].accountId, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }
  isAlreadyChecked() {
    this.meterBypasses.forEach((x: { accountId: number; checked: boolean; }) => {
      let index = this.checkedList.indexOf(x.accountId)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.meterBypasses.every(function(item:any) {
      return item.checked == true;
    })
  }

  downloadReport(){
    let obj = {
      businessUnitId: this.buId,
      undertakingId: this.undertakingId,
      dtId: this.dtId,
      feeder11Id: this.feeder11Id,
      substationId: this.ssId,
      dateFrom: transformToString(this.dateFrom),
      dateTo: transformToString(this.dateTo),
      search: this.searchText.name,
      checkedList: this.checkedList,
      alarmCondition : this.alarmCondition,
      bypassStatus : this.bypassStatus,
    };
    this.downloadMonthlyReport(obj);
  }

  private downloadMonthlyReport(obj: any) {
    this.meterBypassService.downloadPostpaidCustomersReport(obj).subscribe(response => {
      window.location.href = response.data;
    }, error =>{
      this.toastr.error('An error occurred.');
    })
  }

  public copyAccountNumber(accountNumber : any){
    navigator.clipboard.writeText(accountNumber)
    this.toastr.success('Copied to clipboard')
  }

  downloadAndSendReport(email: string){
    let obj = {
      businessUnitId: this.buSelected.value,
      undertakingId: this.utSelected.value,
      dtId: this.dtSelectedId,
      feeder11Id: this.feeder11Selected.value,
      substationId: this.substationSelected.value,
      dateFrom: transformToString(this.dateFrom),
      dateTo: transformToString(this.dateTo),
      search: this.searchText.name,
      checkedList: this.checkedList,
      email:email
    };

    this.downloadAndSendMonthlyReport(obj);
  }

  private downloadAndSendMonthlyReport(obj: any) {
    this.meterBypassService.downloadAndSendPostpaidCustomersReport(obj).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
      }
    }, error =>{
      this.toastr.error('An error occurred.');
    })
  }

  //multi filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  public options = ['']
  public buId: number = 0
  public undertakingId: number = 0;
  public dtId: number = 0;
  public feeder11Id: number = 0
  public ssId: number = 0;
  public businessUnits: any = { name: this.buName, data: [], dropdown: true };
  public undertakings: any = { name: this.utName, data: [], dropdown: true };
  public dts: any = { name: this.dtName, data: [], dropdown: true  };
  public feeders11: any = { name: this.f11Name, data: [], dropdown: true  };
  public substations: any = { name: this.ssName, data: [], dropdown: true  };
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;



  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);
      if (item.name === this.undertakings.name) {
        const filteredValues = checkedData.map((item2: { value: any; }) => item2.value);
        this.undertakings = item
        this.undertakingId = filteredValues
      }
      if (item.name === this.dts.name) {
        const filteredNames = checkedData.map((item2: { value: any; }) => item2.value);
        this.dts = item
        this.dtId = filteredNames
      }
    }
    this.pageChange(1);
    this.checkedList = [];
  }

  //Multi Filter
  checkFilters() {
    if(this.mainTab == this.tabsEnum.Organization){
      let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnits.name);

    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);

    if (buIndex !== -1)
      this.allFilters[buIndex].data = this.businessUnits.data;

    if (!buData) {
      this.allFilters.push(this.businessUnits)
      this.allFilters.push(this.undertakings)
      this.allFilters.push(this.dts)

      this.filterApplied = true
    }
    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakings.name);
    if (utIndex !== -1)
      this.allFilters[utIndex].data = this.undertakings.data;

    let dtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.dts.name);
    if (dtIndex !== -1)
      this.allFilters[dtIndex].data = this.dts.data;

    this.allFilters = [...this.allFilters]

    }
    else{
      let ssData = this.allFilters.find((item: { name: any; }) => item.name === this.substations.name);

      let ssIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.substations.name);
      if (ssIndex !== -1)
        this.allFilters[ssIndex].data = this.substations.data;

      if (!ssData) {
        this.allFilters.push(this.substations)
        this.allFilters.push(this.feeders11)
        this.allFilters.push(this.dts)
        this.filterApplied = true
      }
      let f11Index = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders11.name);
      if (f11Index !== -1)
        this.allFilters[f11Index].data = this.feeders11.data;

      let dtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.dts.name);
      if (dtIndex !== -1)
        this.allFilters[dtIndex].data = this.dts.data;

      this.allFilters = [...this.allFilters]
    }

  }
  businessUnitSelected(item: any) {
    if (!item.value)
      this.buId = 0
    if (item.value !== 0) {
      this.buId = item.value;
      this.undertakingService.getAllByBuId(this.buId).subscribe(response => {
        this.undertakings = { name: this.utName, data: response.data, dropdown: true };
        this.checkFilters()
        this.pageChange(1);
      }, error => {
        this.toastr.error('An error occurred.');
      })
    }
    if (this.buId == 0) {
      this.undertakingId = 0;
      this.undertakings.data.splice(0);
      this.pageChange(1);
      this.checkFilters()
    }
  }

  undertakingSelected(item: any) {
    if (!item.value)
      this.undertakingId = 0
    if (item.value !== 0) {
      this.undertakingId = item.value;
      this.dtService.getAllDtsByUtId(this.undertakingId).subscribe(response => {
        this.dts = { name: this.dtName, data: response.data, dropdown: true  };
        this.checkFilters()
        this.pageChange(1);
      }, error => {
        this.toastr.error('An error occurred.');
      })

    }
    if (this.undertakingId == 0) {
      this.dtId = 0;
      this.dts.data.splice(0);
      this.pageChange(1);
      this.checkFilters()
    }
  }

  ssSelected(item : any){
    if (!item.value)
      this.ssId = 0
    if (item.value !== 0) {
      this.ssId = item.value;
      this.feeder11Service.getAllConnectedToSubstation(this.ssId).subscribe(response => {
        this.feeders11 = { name: this.f11Name, data: response.data, dropdown: true };
        this.checkFilters()
        this.pageChange(1);
      }, error => {
        this.toastr.error('An error occurred.');
      })
    }
    if (this.ssId == 0) {
      this.feeder11Id = 0;
      this.feeders11.data.splice(0);
      this.pageChange(1);
      this.checkFilters()
    }
  }

  f11Selected(item : any){
    if (!item.value)
      this.feeder11Id = 0
    if (item.value !== 0) {
      this.feeder11Id = item.value;
      this.dtService.getAllDtsByF11Id(this.feeder11Id).subscribe(response => {
        this.dts = { name: this.dtName, data: response.data, dropdown: true  };
        this.checkFilters()
        this.pageChange(1);
      }, error => {
        this.toastr.error('An error occurred.');
      })
    }
    if (this.feeder11Id == 0) {
      this.dtId = 0;
      this.dts.data.splice(0);
      this.pageChange(1);
      this.checkFilters()
    }
  }

  dropdownSelected(data: any) {
    if (!data)
      return
    if (data.data.name === this.buName)
      this.businessUnitSelected(data.item)
    if (data.data.name === this.utName)
      this.undertakingSelected(data.item)
    if (data.data.name === this.ssName)
      this.ssSelected(data.item)
    if (data.data.name === this.f11Name)
      this.f11Selected(data.item)

    if (data.data.name === this.dtName){
      this.dtId=data.item.value;
      this.checkFilters();
      this.checkedList = [];
      this.pageChange(1);
    }
  }
  //Autocomplete

  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    this.accountService.getDataForAutocomplete(obj)
    .subscribe({
      next : (response) => this.options = response.data,
      error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
    })
  }

  public alarmConditionOpened: boolean = false
  public alarmConditionClicked(open: number) {
    if (open == 0)
      this.alarmConditionOpened = false
    else {
      if (this.alarmConditionOpened) {
        this.alarmConditionOpened = false
      }
      else {
        this.alarmConditionOpened = true
      }

    }
  }
  public bypassStatus: number | null = null;
  public alarmCondition: number | null = null;

  public alarmConditionSelected(value : number | null){
    this.alarmCondition = value;
    this.checkedList = [];
    this.pageChange(1)
  }

  public bypassStatusSelected(value : number | null){
    this.bypassStatus = value;
    this.checkedList = [];
    this.pageChange(1)
  }

  public bypassStatusOpened: boolean = false
  public bypassStatusClicked(open: number) {
    if (open == 0)
      this.bypassStatusOpened = false
    else {
      if (this.bypassStatusOpened) {
        this.bypassStatusOpened = false
      }
      else {
        this.bypassStatusOpened = true
      }

    }
  }
}
