import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { Feeder33Service } from 'src/app/services/feeder33.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { MultiFilterComponent } from '../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../common-new-design/search-autocomplete/search-autocomplete.component';
import { MenuItem } from './model/model';
import { OutageDtNewComponent } from './outage-dt/outage-dt.component';

@Component({
  selector: 'app-outage-new',
  templateUrl: './outage-new.component.html',
  styleUrls: ['./outage-new.component.scss']
})
export class OutageNewComponent implements OnInit {

  public menu: MenuItem = MenuItem.CurrentOutage
  public menuItemEnum = MenuItem;

  @ViewChild('child') child!: OutageDtNewComponent;  
  public tabMenu: number = 1;

  public ssId: number = 0
  public tsId: number = 0
  public regionId: number = 0
  public feeder11Id: number = 0

  public categorySelected: DictionaryItem = new DictionaryItem(0, 'Category')
  public filterCategoryClicked: boolean = false

  //datepicker
  public dateFrom: any = ''
  public dateTo: any = ''
  public maxDate: Date = new Date

  //outage filter
  public durationFrom: number = 0
  public durationTo?: number = undefined

  // Search Autocomplete
  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public options = []
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public searchPlaceholder: string = "Search by Dt name or number"

  //Multi Filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;

  public buName: string = this.translationService.getByKeyFromCache('BU')
  public ssName: string = "Substation"
  public f11Name: string = "Feeder 11"
  public tsName: string = "Transmission Station"
  public categoryName: string = "Category"
  public businessUnits: any = { name: this.buName, data: [], dropdown: false   };
  public substations: any = { name: this.ssName, data: [], dropdown: false   };
  public transmissionStations: any = { name: this.tsName, data: [], dropdown: false   };
  public feeders11: any = { name: this.f11Name, data: [], dropdown: false  };
  public allCategory: any = {name: this.categoryName, data: [{ value:1, name:'Planned'},{ value:2, name:'Unplanned'}], dropdown: true }
  public ssIds: number[] = []
  public f11Ids: number[] = []
  public tsIds: number[] = []
  public buIds: any = [];

  constructor(
    private location: Location,
    public dtService: DtService,
    public feederService: Feeder11Service,
    private translationService: TranslationService,
    private businessUnitService: BusinessUnitService,
    public substationService: SubstationService,
    private tsService: TransmissionStationService,
    public toastr: ToastrService,
    public feeder33Service: Feeder33Service
  ) { }
  ngOnInit(): void {
    this.setMainDate()
    this.getAllBUs()
    this.getAllSSs()
    this.getAllTSs()
    this.getAllFeeder11()
  }

  setMainDate() {

    let dteFrom = new Date()
    let dte = new Date()

    dteFrom.setHours(2, 0, 0);
    dte.setHours(2, 0, 0);

    dteFrom.setDate(1)
    dteFrom.setMonth(dteFrom.getMonth() - 1);
    dte.setDate(dte.getDate() + 1)

    if(this.menu === MenuItem.CurrentOutage){
      this.dateFrom = {year: 2022, month: 6, day: 1};
      this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate()};
    }
    else{
      this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate()};
      this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate()};
    }

    this.maxDate = new Date(dte.getFullYear(), dte.getMonth(), dte.getDate())
  }

  // Search Autocomplete
  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name, 
      msnIncluded: true
    }
    if (searchText.name.length > 2) {
      if (this.tabMenu == 1) {
        this.feeder33Service.getDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      } else if (this.tabMenu == 2) {
        this.feederService.getDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      } else if (this.tabMenu == 3) {
        this.dtService.getDataForAutocomplete(obj)
          .subscribe({ 
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
      }
    } else {
      this.searchChild.resetOptions()
    }
  }

  // Search Autocomplete
  searchChanged(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.searchText = cloneDeep(this.searchText) 

    // if(this.searchText.type == Type.Undefined)
    //   this.searchText = new SearchAutocomplete()

  }

  public back(): void {
    this.location.back();
  }

  menuChanged(value: number){
    if(value == 1){
      this.menu = MenuItem.CurrentOutage
    }
    else{
      this.menu = MenuItem.HistoricalOutage
    }
    this.resetFilters()
    this.setMainDate()
  }

  tabChanged(value: number){
    this.tabMenu = value
    this.resetFilters()
  }

  ssSelect(value: number){
    this.ssId = value
  }

  tsSelect(value: number){
    this.tsId = value
  }

  regionSelect(value: number){
    this.regionId = value
  }

  feeder11Select(value: number){
    this.feeder11Id = value
  }

  dateChange(data: any){
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
    }
  
  dropdownSelect(data: any){
    if(!data)
      return
    if(data.data.name === this.categoryName)
      this.categorySelect(data.item)
  }

  categorySelect(category: any){
    this.categorySelected = category
  }

  durationChange(data: any){
    this.durationFrom = data.durationFrom
    this.durationTo = data.durationTo
  }

  download(){
    this.child.download()    
  }

  resetFilters(){
    if (this.tabMenu == 1)
      this.searchPlaceholder = "Search by Feeder 33 name or number";
    else if (this.tabMenu == 2)
      this.searchPlaceholder = "Search by Feeder 11 name or number";
    else if (this.tabMenu == 3)
      this.searchPlaceholder = "Search by Dt name or number";

    this.searchText = new SearchAutocomplete()
    if(this.searchChild){
      this.searchChild.searchText = new SearchAutocomplete()
      this.searchChild.resetOptions()
    }
    this.durationFrom = 0
    this.durationTo = undefined

    //multi filter
    this.ssIds= []
    this.tsIds= []
    this.buIds= []
    this.f11Ids = []
    this.allFilters = []
    this.showFilter = false
    this.filterApplied = false
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.checkFilters()

  }


  //multi filter
  getAllBUs() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllSSs() {
    this.substationService.getAllForSelect().subscribe(response => {
      this.substations = { name: this.ssName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllFeeder11(){
    this.feederService.getAllForSelect().subscribe(response =>{
      if(response.status === 200){
        // this.feeders11 = response.data 
        this.feeders11 = { name: this.f11Name, data: response.data };
        this.checkFilters()
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  getAllTSs(){
    this.tsService.getAllForSelect().subscribe(response =>{
      if(response.status === 200){
        this.transmissionStations ={ name: this.tsName, data: response.data }
        this.checkFilters()
      }
      else{
        this.toastr.error(response.message)
      }
    }, _=>{
      this.toastr.error("An Error Occurred")
    })
  }

  checkFilters() {

    if(this.tabMenu == 1)
    {
      let tsIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.transmissionStations.name);

      if (tsIndex !== -1)
        this.allFilters[tsIndex].data = this.transmissionStations.data;
      else
      {
        this.allFilters.push(this.transmissionStations)
        if(this.menu == this.menuItemEnum.HistoricalOutage)
        {
          this.allFilters.push(this.allCategory)
        }
      }
      this.filterApplied = true
    }
    else if(this.tabMenu == 2)
    {
      let ssIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.substations.name);

      if (ssIndex !== -1)
        this.allFilters[ssIndex].data = this.substations.data;
      else
      {
        this.allFilters.push(this.substations)
        if(this.menu == this.menuItemEnum.HistoricalOutage)
        {
          this.allFilters.push(this.allCategory)
        }
      }
      this.filterApplied = true
    }
    else if(this.tabMenu == 3)
    {
      let feeders11Index = this.allFilters.findIndex((item: { name: any; }) => item.name === this.feeders11.name);
      if (feeders11Index !== -1){
        this.allFilters[feeders11Index].data = this.feeders11.data;
      }

      let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);
      if (buIndex !== -1)
        this.allFilters[buIndex].data = this.businessUnits.data;

      if(feeders11Index === -1 && buIndex === -1)
      {
        this.allFilters.push(this.businessUnits)
        this.allFilters.push(this.feeders11)
        if(this.menu == this.menuItemEnum.HistoricalOutage)
        {
          this.allFilters.push(this.allCategory)
        }
      }

      this.filterApplied = true
    }

    this.allFilters = [...this.allFilters]
  }


  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)
      if (item.name === this.substations.name ) {
        this.ssIds = filteredValues
      }
      // else if (item.name === this.allFeederNames.name) {
      //   const filteredNames = checkedData.map((item2: { name: any; }) => item2.name);
      //   this.allFeederNames = item
      //   this.feederNames = filteredNames
      // }
      else if (item.name === this.transmissionStations.name) {
        this.tsIds = filteredValues
      }
      else if (item.name === this.businessUnits.name) {
        this.buIds = filteredValues
      }
      else if (item.name === this.feeders11.name) {
        this.f11Ids = filteredValues
      }
    }
  }
}
