import { Component, OnInit, ViewChild } from '@angular/core';
import { PaginationComponent } from '../../common-new-design/pagination/pagination.component';
import { DashboardData } from 'src/app/models/dashboard-data.model';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-outage-analysis',
  templateUrl: './outage-analysis.component.html',
  styleUrls: ['./outage-analysis.component.scss']
})
export class OutageAnalysisComponent implements OnInit {
  
  public currentPage = 1
  public pageSize = 4
  public count = 0
  public lastPageSize: number = 1;

  @ViewChild('pagination') paginationComponent!: PaginationComponent;

  public slicedItems: any[] = []
  public toggleLosses: number = 1

  public tab1: string =  '33KV Feeders'     
  public tab2: string = '11KV Feeders' 
  public tab3: string = 'DT'


  public lossesArray: DashboardData[] = []

  public outage11KVFeeders: DashboardData[] = []
  public outageDT: DashboardData[] = []
  public outage33KVFeeders: DashboardData[] = []


  constructor(
    private dashboardService: DashboardService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.fetchOutageAnalysis33KVFeeders()
  }

  fetchOutageAnalysis11KVFeeders() {  
    this.dashboardService.getOutageAnalysis11KVFeeders().subscribe(x => {
      if (x.status !== 200) {
        this.toastr.error(x.message)
        return
      }
      this.outage11KVFeeders = x.data
      if (this.toggleLosses == 2)
      {
        this.lossesArray = this.outage11KVFeeders
        this.slicedItems = this.customSlice(this.lossesArray, 4, this.currentPage - 1)
        if(this.currentPage == 1){
          this.count = this.outage11KVFeeders.length
          this.lastPageSize = Math.ceil(this.count / this.pageSize);
        }
      }
    })
  }

  fetchOutageAnalysis33KVFeeders() {  
    this.dashboardService.getOutageAnalysis33KVFeeders().subscribe(x => {
      if (x.status !== 200) {
        this.toastr.error(x.message)
        return
      }
      this.outage33KVFeeders = x.data
      if (this.toggleLosses == 1)
      {
        this.lossesArray = this.outage33KVFeeders
        this.slicedItems = this.customSlice(this.lossesArray, 4, this.currentPage - 1)
        if(this.currentPage == 1){
          this.count = this.outage33KVFeeders.length
          this.lastPageSize = Math.ceil(this.count / this.pageSize);
        }
      }
    })
  }

  fetchOutageAnalysisDT(){
    this.dashboardService.getOutageAnalysisDT().subscribe(x => {
      if (x.status !== 200) {
        this.toastr.error(x.message)
        return
      }
      this.outageDT = x.data;
      if (this.toggleLosses == 3)
      {
        this.lossesArray = this.outageDT;
        this.slicedItems = this.customSlice(this.lossesArray, 4, this.currentPage - 1)
        if(this.currentPage == 1){
          this.count = this.outageDT.length
          this.lastPageSize = Math.ceil(this.count / this.pageSize);
        }
      }
    })
  }

  customSlice(items: any[], pageSize: number, currentPage: number): any[] {
    const startIndex = (currentPage) * pageSize
    const endIndex = startIndex + pageSize
    return items.slice(startIndex, endIndex)
  }

  pageChanged(page: any) {
    this.currentPage = page

    if (this.toggleLosses == 1)
      this.fetchOutageAnalysis33KVFeeders()
    if(this.toggleLosses == 2)
      this.fetchOutageAnalysis11KVFeeders()
    if(this.toggleLosses == 3)
      this.fetchOutageAnalysisDT()
    

    return page
  }

  filterClicked(value: number) {
    if (this.toggleLosses == value)
      return

    this.toggleLosses = value

    this.pageChanged(1)
    this.paginationComponent?.changePage(1)
  }

}
