<main class="container-fluid">
<div class="container-wrapper">
    <span class="network-management-title">Grid Energy Profile</span>
    <div class="menu-tab-filter">
      <div class="left-wrapper">
        <div class="tab-wrapper">
          <app-tab-default
            [firstText]="'Network'"
            [secondText]="'Organization'"
            (tabChanged)="organizationChanged($event)"
            [tabSelected]="menu"
          ></app-tab-default>
        </div>
      </div>

    </div>

      <div *ngIf="menu == MenuItemEnum.Network">
        <div class="nm-filter-wrapper">
          <app-tab-default
              [firstText]="'All TS Points'"
              [secondText]="'33KV Feeders'"
              [thirdText]="'11KV Feeders'"
              [forthText]="'Distribution Transformer'"
              (tabChanged)="tabChanged($event)"
              [tabSelected]="tabMenu"
              [paCss]="true"
              [womCss]="true">
          </app-tab-default>

          <app-map-table-button-new
            [isMap]="isMap"
            [mapBtnDisabled]="tabMenu == 1"
            (filterClicked)="mapChanged($event)">
          </app-map-table-button-new>

      </div>
      <div class="filters-container">
          <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

            <app-multi-filter  *ngIf="filterApplied"
                [data]="allFilters"
                (dataChange)="applyFilter($event)"
                [(showFilter)]="showFilter">
            </app-multi-filter>

            <app-range-datepicker *ngIf="!isMap"
                [fromDate]="dateFrom"
                [toDate]="dateTo"
                [name]="'Period'"
                [maxData]="maxData"
                (dateRangeSelected)="dateChange($event)"
                [isMonthly]="false"
                [gridEnergyProfileDatepicker]="true"
            ></app-range-datepicker>
          <app-search-autocomplete
            #search
            [width]="30"
            [placeholder]="searchPlaceholder"
            [searchText]="searchText"
            [options]="options"
            (searchChange)="searchChange($event)"
            (searchAutocompleteChange)="getOptions($event)"
          ></app-search-autocomplete>
        </div>

          <div *ngIf="!isMap" class="download-button">
            <app-download-button-new
              [isOnlyDownload]="true"
              (downloadButtonClickedChange)="download()">
            </app-download-button-new>
          </div>

        </div>
      </div>

          <div *ngIf="menu == MenuItemEnum.Organisation">
            <div class="nm-filter-wrapper">
              <app-tab-default
                  [firstText]="buName"
                  [secondText]="utName"
                  (tabChanged)="tabChanged($event)"
                  [tabSelected]="tabMenu"
                  [paCss]="true"
                  [womCss]="true"
              >
              </app-tab-default>

              <div class="download-button">
                <app-map-table-button-new
                      [isMap]="isMap"
                      (filterClicked)="mapChanged($event)">
                </app-map-table-button-new>
              </div>
            </div>

          <div class="filters-container">
              <div class="multi-filter-wrapper" [ngClass]="{'show-filter' : showFilter}">

                <app-multi-filter  *ngIf="filterApplied"
                    [data]="allFilters"
                    (dataChange)="applyFilter($event)"
                    [(showFilter)]="showFilter">
                </app-multi-filter>

                <app-range-datepicker *ngIf="!isMap"
                    [fromDate]="dateFrom"
                    [toDate]="dateTo"
                    [name]="'Period'"
                    [maxData]="maxData"
                    (dateRangeSelected)="dateChange($event)">
                </app-range-datepicker>

                <app-search-autocomplete
                #search
                [width]="30"
                [placeholder]="searchPlaceholder"
                [searchText]="searchText"
                [options]="options"
                (searchChange)="searchChange($event)"
                (searchAutocompleteChange)="getOptions($event)"
              ></app-search-autocomplete>
              </div>


              <div *ngIf="!isMap" class="download-button">
                <app-download-button-new
                [isOnlyDownload]="true"
                (downloadButtonClickedChange)="download()"
                ></app-download-button-new>
              </div>
          </div>
        </div>

    <div *ngIf="menu == MenuItemEnum.Network && !isMap">
        <app-gep-dt
          #child
          *ngIf="tabMenu == 4"
          [ssIds]="ssIds"
          [f11Ids]="f11Ids"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
          [search]="searchText"
        ></app-gep-dt>
        <app-gep-feeder11-new
          #child
          *ngIf="tabMenu == 3"
          [ssIds]="ssIds"
          [f11Ids]="f11Ids"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
          [search]="searchText"
        ></app-gep-feeder11-new>
       <app-gep-feeder33-new
          #child
          *ngIf="tabMenu == 2"
          [tsIds]="tsIds"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
          [search]="searchText"
        ></app-gep-feeder33-new>
        <app-gep-ts-new
          #child
          *ngIf="tabMenu == 1"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
          [search]="searchText"
        ></app-gep-ts-new>
    </div>

    <div *ngIf="menu == MenuItemEnum.Organisation && !isMap">
        <app-gep-bu-new
          #child
          *ngIf="tabMenu == 1"
          [feeder11Ids]="f11Ids"
          [buIds]="buIds"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
          [search]="searchText"
        ></app-gep-bu-new>
        <app-gep-ut-new
          #child
          *ngIf="tabMenu == 2"
          [buIds]="buIds"
          [utIds]="utIds"
          [dateFrom]="dateFrom"
          [dateTo]="dateTo"
          [search]="searchText"
        ></app-gep-ut-new>
    </div>

    <div class="card-item-wrapper"*ngIf="isMap">
      <div class="card-item">
        <div class="card-item-values">
            <span class="item-title">Total energy received in last 30 days</span>
            <div class="value-wrapper">
              <span class="value">{{totalEnergyConsumption | twoDecimals}}MWh</span>
              <div class="value-last-month-wrapper">
                <span class="value-last-month">{{totalEnergyConsumptionTwoMonthsAgo}}k last month</span>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="map-wrapper" *ngIf="isMap" [ngClass]="{'subfilter-active': menu !== MenuItemEnum.None}">
      <adora-map-clusterized 
      [searchValue]="searchTextForMap"
      [nmDashboard]="true"
      [buIds]="buIds"
      [utIds]="utIds"
      [dtIds]="dtIds"
      [tsIds]="tsIds"
      [ssIds]="ssIds"
      [showOnlyDts]="showOnlyDt"
      [showOnlyBu]="showOnlyBu"
      [showOnlySs]="showOnlySs"
      [showOnlyTs]="showOnlyTs"
      [showOnlyUts]="showOnlyUt"
      [organizationTab]="organizationData">
</adora-map-clusterized>
    </div>
  </div>
</main>
