<app-dt-active-current-popup [dateFrom]="dateFrom" [dateTo]="dateTo"></app-dt-active-current-popup>

<main class="outage-wrapper">
    <div class="gep-table-wrapper">
        <div class="table-wrapper">
            <table class="standard-table gep-table">
                        <thead>
            <tr>
                <th><span><input class="input-checkmark" type="checkbox" name="list_name" value="h1" [(ngModel)]="masterSelected" (change)="checkUncheckAll()" /></span></th>
                <th title="DT Name"><span>DT NAME</span></th>
                <th title="DT Number"><span>DT NUMBER</span></th>
                <th title="Meter Number"><span>METER NUMBER</span></th>
                <th title="Connected Feeder"><span>CONNECTED FEEDER</span></th>
                <th title="Feeder Type"><span>FEEDER TYPE</span></th>
                <th title="{{buName}}"><span>{{buName}}</span></th>
                <th title="{{utName}}"><span>{{utName}}</span></th>

                <th title="Customers Downstream" class="large-td"><span>CUSTOMERS DOWNSTREAM</span></th>
                <th title="Start Time"><span>START TIME</span></th>              
                <th title="Availability (Last 1 day)" class="large-td"><span>AVAILABILITY (LAST 1 DAY)</span></th>
                <th title="Availability (Last 3 day)" class="large-td"><span>AVAILABILITY (LAST 3 DAY)</span></th>
                <th title="Availability (Last week)" class="large-td"><span>AVAILABILITY (LAST WEEK)</span></th>
                <th title="Availability (one month)" class="large-td"><span>AVAILABILITY (ONE MONTH)</span></th>
                <th class="large-td" title="Availability Avg" class="large-td">
                    <div class="dropdown">
                        <div tabindex="3" class="dropdown-btn" (click)="avgAvailabilityClicked(1)"
                            (blur)="avgAvailabilityClicked(0)">
                            <span *ngIf="avgAvailability==0" >AVERAGE AVAILABILITY (MONTHLY)</span>
                            <span *ngIf="avgAvailability==1" >AVERAGE AVAILABILITY (WEEKLY)</span>
                            <span *ngIf="avgAvailability==2" >AVERAGE AVAILABILITY (DAILY)</span>

                            <svg *ngIf="avgAvailabilityOpened" width="24" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M12.291 9.29257C12.9214 9.92241 12.4754 11 11.5842 11H4.41268C3.52199 11 3.07572 9.92331 3.70525 9.29321L7.28781 5.70743C7.67815 5.31673 8.31132 5.31644 8.70202 5.70679L12.291 9.29257Z"
                                    fill="#8F92A1" fill-opacity="0.4" />
                            </svg>
                            <svg *ngIf="!avgAvailabilityOpened" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M16.291 10.7074C16.9214 10.0776 16.4754 9 15.5842 9H8.41268C7.52199 9 7.07572 10.0767 7.70525 10.7068L11.2878 14.2926C11.6782 14.6833 12.3113 14.6836 12.702 14.2932L16.291 10.7074Z"
                                    fill="#8F92A1" fill-opacity="0.4" />
                            </svg>
                        </div>
                        <div class="dropdown-expanded" *ngIf="avgAvailabilityOpened">
                            <span (mousedown)="avgSelected(0)" class="itemDropdown">Monthly
                                <div *ngIf="avgAvailability==0"> <svg width="18" height="13" viewBox="0 0 18 13"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.54961 12.5016L0.849609 6.80156L2.27461 5.37656L6.54961 9.65156L15.7246 0.476562L17.1496 1.90156L6.54961 12.5016Z"
                                            fill="#1C1B1F" />
                                    </svg></div>
                            </span>
                            <span (mousedown)="avgSelected(1)" class="itemDropdown">Weekly
                                <div *ngIf="avgAvailability==1"> <svg width="18" height="13" viewBox="0 0 18 13"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.54961 12.5016L0.849609 6.80156L2.27461 5.37656L6.54961 9.65156L15.7246 0.476562L17.1496 1.90156L6.54961 12.5016Z"
                                        fill="#1C1B1F" />
                                </svg></div></span>
                            <span (mousedown)="avgSelected(2)" class="itemDropdown">Daily
                                <div *ngIf="avgAvailability==2"> <svg width="18" height="13" viewBox="0 0 18 13"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.54961 12.5016L0.849609 6.80156L2.27461 5.37656L6.54961 9.65156L15.7246 0.476562L17.1496 1.90156L6.54961 12.5016Z"
                                        fill="#1C1B1F" />
                                </svg></div></span>
                        </div>
                    </div>
                </th>
                 <th></th>
            </tr>
        </thead>
        
        <tbody>
            <tr *ngFor="let item of data"  (click)="viewMore(item)" class="clickable actions-click">
                <td (click)="false; $event.stopPropagation();"><span> <input class="input-checkmark" type="checkbox" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" /></span> </td>

                <td title="{{item.name}}">
                    <span *ngIf="item.name && item.name != '/'">{{item.name}}</span>
                    <span *ngIf="!item.name || item.name == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.number}}">
                    <span *ngIf="item.number && item.number != '/'">{{item.number}}</span>
                    <span *ngIf="!item.number || item.number == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.msn}}">
                    <span *ngIf="item.msn && item.msn != '/'">{{item.msn}}</span>
                    <span *ngIf="!item.msn || item.msn == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.feederName}}">
                    <span *ngIf="item.feederName && item.feederName != '/'">{{item.feederName}}</span>
                    <span *ngIf="!item.feederName || item.feederName == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.feederType}}">
                   <span>{{item.feederType}}</span>
                </td>
                <td title="{{item.parentName}}">
                    <span *ngIf="item.parentName && item.parentName != '/'">{{item.parentName}}</span>
                    <span *ngIf="!item.parentName || item.parentName == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.utName}}">
                    <span *ngIf="item.utName && item.utName != '/'">{{item.utName}}</span>
                    <span *ngIf="!item.utName || item.utName == '/'" class="NaN">N/A</span>
                </td>
                <td class="center" title="{{item.noOfCustomersImpacted}}">
                    <span *ngIf="item.noOfCustomersImpacted && item.noOfCustomersImpacted != '/'">{{item.noOfCustomersImpacted | number}}</span>
                    <span *ngIf="!item.noOfCustomersImpacted || item.noOfCustomersImpacted == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.outageStart}}">
                    <span *ngIf="item.outageStart && item.outageStart != '/'">{{(item.outageStart | date:'MMM dd,yyyy, HH:mm:ss')}}</span>
                    <span *ngIf="!item.outageStart || item.outageStart == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.dailyAvailability}}">
                    <span *ngIf="item.dailyAvailability && item.dailyAvailability != '/'">{{(item.dailyAvailability | number)}}</span>
                    <span *ngIf="!item.dailyAvailability || item.dailyAvailability == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.last3dayavailability}}">
                    <span *ngIf="item.last3dayavailability && item.last3dayavailability != '/'">{{(item.last3dayavailability | number)}}</span>
                    <span *ngIf="!item.last3dayavailability || item.last3dayavailability == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.last7dayavailability}}">
                    <span *ngIf="item.last7dayavailability && item.last7dayavailability != '/'">{{(item.last7dayavailability | number)}}</span>
                    <span *ngIf="!item.last7dayavailability || item.last7dayavailability == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.monthlyAvailability}}">
                    <span *ngIf="item.monthlyAvailability && item.monthlyAvailability != '/'">{{(item.monthlyAvailability | number)}}</span>
                    <span *ngIf="!item.monthlyAvailability || item.monthlyAvailability == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.weeklyAVG}}" *ngIf="avgAvailability==1">
                    <span *ngIf="item.weeklyAVG && item.weeklyAVG != '/'">{{(item.weeklyAVG | number)}}</span>
                    <span *ngIf="!item.weeklyAVG || item.weeklyAVG == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.weeklyAmonthlyAVGVG}}" *ngIf="avgAvailability==0">
                    <span *ngIf="item.monthlyAVG && item.monthlyAVG != '/'">{{(item.monthlyAVG | number)}}</span>
                    <span *ngIf="!item.monthlyAVG || item.monthlyAVG == '/'" class="NaN">N/A</span>
                </td>
                <td title="{{item.dailyAVG}}" *ngIf="avgAvailability==2">
                    <span *ngIf="item.dailyAVG && item.dailyAVG != '/'">{{(item.dailyAVG | number)}}</span>
                    <span *ngIf="!item.dailyAVG || item.dailyAVG == '/'" class="NaN">N/A</span>
                </td>
                <td> <button class="see-more-btn" id="openPopup">See More</button></td>

            </tr>
        </tbody>
    </table>
    </div>
</div>
</main>
<div class="pagination-wrapper">
    <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
        [currentPage]="currentPage" (pageChange)="pageChange($event)">
    </app-fo-pagination-new>
</div>

