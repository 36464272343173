import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
 import { CrudSearch } from "../models/crudSearch.model";
import { ComponentNamesNew } from "../models/crudsNew.model";

@Injectable({
    providedIn: 'root'
})
export class CrudService {

    readonly crudSearchInitState: CrudSearch = {clicked: false, text: '', componentName: ComponentNamesNew.NoComponent};

    private globalSearch:  BehaviorSubject<CrudSearch> = new BehaviorSubject<CrudSearch>(this.crudSearchInitState);

    public globalSearch$: Observable<CrudSearch> = this.globalSearch.asObservable();

    setGlobalSearch(latestValue: CrudSearch) {
        return this.globalSearch.next(latestValue);
    }

    cleanGlobalSearch() {
        return this.globalSearch.next(this.crudSearchInitState);
    }
    
}