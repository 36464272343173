import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';

@Component({
  selector: 'app-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss']
})
export class SimpleDropdownComponent implements OnChanges {
  public search: string = ''

  @Input() isSelected: boolean = false;
  @Input() header: string = 'Select'
  @Input() dropdownHeader: string = 'Designation'
  @Input() data: DictionaryItem[] = []
  @Input() width: string = '31'
  @Input() selectedItemId: number = 0

  @Output() selectItemChange: EventEmitter<any> = new EventEmitter()

  public showData: DictionaryItem[] = []

  public searchOpened: boolean = false;
  private wasInside: boolean = false;

  constructor(private eRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.data)
    {
      this.showData = changes.data.currentValue
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any){
    const clickedInside = this.eRef.nativeElement.contains(target);
  }

  close(){
    this.isSelected = false
  }

  select(item: DictionaryItem){
    this.header = item.name
    this.isSelected = false
    this.selectedItemId = item.value
    this.selectItemChange.emit(item)
  }

  searchChanged(){
    this.showData = this.data
    this.showData = this.showData.filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()))
  }
}
