import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-consumption-table',
  templateUrl: './consumption-table.component.html',
  styleUrls: ['./consumption-table.component.scss']
})
export class NewConsumptionTableComponent implements OnInit {

  @Input() barChartData:any;
  @Input() EnergyLabels:any;
  @Input() receivedAndConsumedData:any;
  @Input() avgConsumed:any;
  @Input() avgReceived:number=0;
  @Input() avgLosses: number = 0;
  @Input() avgLossesTo11Kv: number = 0;
  @Input() tab: number = 0;

  ngOnInit(): void {
    console.log(this.barChartData[0].data)
  }
}
