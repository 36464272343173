import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDecimals'
})
export class TwoDecimalsPipe implements PipeTransform {

  transform(value: any, args?: any) {

    if (value != "/")
    {
      let numb: number = +value
      return commafy(numb);
    }

    return value;
  }

}

function commafy(num: number) {
  let str = num.toString().split('.');
  if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 0) {
      str[1] = str[1] + '00'
      str[1] = str[1].substring(0, 2);
  }
  return str.join('.');
}
