import { Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { UserForAudit } from 'src/app/models/userForAudit.mode';
import { UserService } from 'src/app/services/user.service';
import { SearchAutocompleteComponent } from '../../../common-new-design/search-autocomplete/search-autocomplete.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from 'src/app/services/account.service';
import { Account } from 'src/app/models/account.model';
import { ImportDetailsPopupComponent } from 'src/app/components/common-new-design/import-details-popup/import-details-popup.component';

@Component({
  selector: 'app-customers-without-dt',
  templateUrl: './customers-without-dt.component.html',
  styleUrls: ['./customers-without-dt.component.scss']
})
export class CustomersWithoutDtComponent implements OnInit {

  public currentPage: number = 1
  public pageSize: number = 10
  public count: number = 0;
  public lastPageSize: number = 1

  public dateFrom: any = '';
  public dateTo: any = '';
  public dtFrom: Date = new Date();
  public dtTo: Date = new Date();
  public maxDate: Date = new Date();

  public accounts: Account[] = []
  public search: string = '';

  public userSelected: DictionaryItem = new DictionaryItem(0, 'Username')
  public height: string = '21rem'
  public showFilter: any = false
  public allFilters: any = []
  public filterApplied: boolean = false;
  public userName = 'Search by Area or Region'
  public users: {name:String, data:UserForAudit[], dropdown:boolean} = {name:this.userName, data:[], dropdown:true}


  @ViewChild('search') searchChild!: SearchAutocompleteComponent
  public masterSelected: boolean = false;
  public checkedList: any = [];
  public searchPlaceholder: string = "Search by Meter Number, Account number or Customer Name";
  public searchText: SearchAutocomplete = new SearchAutocomplete();
  public searchLength: number = 1
  public options = [];
  public selectedLogs = [];

  public fileChosen: any = null;
  public file: any = null;
  @ViewChild(ImportDetailsPopupComponent) importRef!: ImportDetailsPopupComponent;

  constructor(
    private toastr: ToastrService,
    private accountService: AccountService,
    private userService: UserService
    ) { }

    dropdownSelected(item: any) {
      if(item.data.name == this.userName)
      {
        if (this.userSelected == item.item)
          return
        else
          this.userSelected = item.item
      }
      this.checkedList = []
      this.reloadTable(1);
    }

  ngOnInit(): void {
    this.maxDate.setDate(this.maxDate.getDate() + 1);

    this.setMainDate()
    this.reloadTable()
    this.getAllUsernamesForSelect()
  }

  bulkUpload() {
    this.importRef.changeState();
  }

  // public download() {
  //   this.selectedLogs = [];
  //   if(this.checkedList.length != 0)
  //    {
  //     this.selectedLogs = this.checkedList.map((item: any) => item.id)
  //    }
  //   let obj = {
  //     From: transformToString(this.dateFrom),
  //     To: transformToString(this.dateTo),
  //     TsIds: this.tsIds,
  //     Search: this.search ? this.search.name : this.search,
  //     CommStatus: this.commStatus,
  //     ReadType: this.readType,
  //     CheckedList: this.selectedLogs
  //   };

  //   if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
  //     this.downloadMonthlyReport(obj)
  //   }
  //   else {
  //     this.downloadDailyReport(obj);
  //   }
  // }


  // public download() {
  //   let obj = {
  //       dateFrom: transformToString(this.dateFrom),
  //       dateTo: transformToString(this.dateTo),
  //       search: this.searchText.name ? this.searchText.name : this.search,
  //       id: 0,
  //       userId: this.userSelected.value,
  //       isDownload: true,
  //       logIds: this.checkedList
  //   }

  //   this.accountService.downloadReport(obj).subscribe(response => {
  //     window.location.href = response.data;
  //   }, _ => {
  //     this.toastr.error('An error occurred.');
  //   })
  // }

  checkUncheckAll() {
    for (let i = 0; i < this.accounts.length; i++) {
      this.accounts[i].checked = this.masterSelected;
      this.checkForDownload(this.accounts[i].id, true);
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if(!isMasterClicked)
      this.isMasterChecked();
    let index = this.checkedList.indexOf(id);
    if(index !== -1){
      if(!isMasterClicked)
        this.checkedList.splice(index, 1);
      else if(isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1);
    }
    else {
      this.checkedList.push(id);
    }
  }

  isAlreadyChecked() {
    this.accounts.forEach(x => {
      let index = this.checkedList.indexOf(x.id)
      if(index !== -1) {
        x.checked = true;
      }
    })

    this.isMasterChecked();
  }

  isMasterChecked() {
    this.masterSelected = this.accounts.every(function(item:any) {
      return item.checked == true;
    })
  }

  searchChange(text: SearchAutocomplete) {
    this.searchText = text;
    this.searchText = cloneDeep(this.searchText);

    this.checkedList = [];
    this.reloadTable();
  }

  // Search Autocomplete
  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true
    }
    this.searchChild.searchLength = this.searchLength;
    if (searchText.name.length > 1) {
      this.accountService.getDataForAutocomplete(obj)
          .subscribe({
            next : (response) => this.options = response.data,
            error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
          })
    } else {
      this.searchChild.resetOptions()
    }
  }

  setMainDate() {
    let dte = new Date();
    dte.setHours(2,0,0);
    this.dtFrom.setDate(dte.getDate() - 7);
    this.dtTo.setDate(dte.getDate() + 1);
    this.dateFrom = {year: this.dtFrom.getFullYear(), month: this.dtFrom.getMonth() + 1, day: this.dtFrom.getDate()};
    this.dateTo = {year: this.dtTo.getFullYear(), month: this.dtTo.getMonth() + 1, day: this.dtTo.getDate()};
  }

  inputChanged(searchText: string){
    this.search = searchText
    this.reloadTable()
  }
  reloadTable(page: any = null) {
    if (page)
      this.currentPage = page

    let obj = this.generateObject()

    this.accountService.getAllAccountsWithoutDts(obj).subscribe(
      (response) => {
        this.accounts = response.data.data
        this.count = response.data.count
        this.lastPageSize = Math.ceil(this.count/this.pageSize)
        this.isAlreadyChecked();
      },
      (error) => {
        this.toastr.error('An error occurred.')
      }
    )
  }

  generateObject() {
    let obj = {
      pageInfo: {
        pageSize: this.pageSize,
        page: this.currentPage,
      },
      filterParams: {
        dateFrom: transformToString(this.dateFrom),
        dateTo: transformToString(this.dateTo),
        id: 0,
        userId: this.userSelected.value,
        search: this.searchText.name ? this.searchText.name : this.search,
      }
    }

    return obj
  }

  getAllUsernamesForSelect() {
    this.userService.getAllForSelect().subscribe(response => {
      this.users.data = response.data
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  checkFilters() {
    let usernamesData = this.allFilters.find((item: { name: any; }) => item.name === this.userName)

    if (!usernamesData) {
      this.allFilters.push(this.users)
      this.filterApplied = true
    }
  }

  pageChange(value: any) {
    this.currentPage = value
    this.reloadTable()
  }

  selectUser(user: any) {
    this.userSelected = user
    this.pageChange(1)
  }

  updateDate(date: any) {
    this.dateFrom = date.dateFrom;
    this.dateTo = date.dateTo;
    this.checkedList = [];
    this.reloadTable()
  }

  isFileChosen() {
    if (this.fileChosen)
      return true;

    return false;
  }

  uploadFile() {
    this.file = this.importRef.fileChosen;

    if (!this.isFileChosen) {
      this.toastr.error('You have to choose file for upload.');
      return;
    }
    const formData = new FormData();
    formData.append("uploadFile", this.file);
    formData.append("update", this.importRef.update.toString());

    this.accountService.uploadFileAccountDt(formData).subscribe(response => {
      if (response && response.status === 200) {
        this.toastr.success(response.message);
        if(response.data)
          window.location.href = response.data;
      } else {
        this.toastr.error(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }
      this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  getTemplate() {
    this.accountService.getAccountDtTemplate().subscribe(response => {
      if (response.status == 200) {
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}
