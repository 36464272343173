import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/models/config';
import { ConfigFieldOperationService } from 'src/app/services/config-field-operation.service';
import { Feeder33FieldSettingsEditComponent } from './feeder33-field-settings-edit/feeder33-field-settings-edit.component';

@Component({
  selector: 'app-feeder33-field-settings',
  templateUrl: './feeder33-field-settings.component.html',
  styleUrls: ['./feeder33-field-settings.component.scss']
})
export class Feeder33FieldSettingsComponent implements OnInit {

  @ViewChild(Feeder33FieldSettingsEditComponent) popupComponentRef : Feeder33FieldSettingsEditComponent | any;

  public configs: Config[] = []

  constructor(private configService: ConfigFieldOperationService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.configService.getAllF33().subscribe(response => {
      if (response.status == 200) {
        this.configs = response.data
      }
      else 
        this.toastr.error(response.message)
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  openTab() {
    this.popupComponentRef.openTab(1);
  }

  reloadTable(){
    this.getAll();
  }
}
