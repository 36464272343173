import { Component } from '@angular/core';

@Component({
  selector: 'app-field-operations-layout',
  templateUrl: './field-operations-layout.component.html',
  styleUrls: ['./field-operations-layout.component.scss']
})
export class FieldOperationsLayoutComponent {
  expandSidebar: boolean = true;

}
