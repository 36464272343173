import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { MeterService } from 'src/app/services/meter.service';

@Component({
  selector: 'app-device-management-remote-disconnected',
  templateUrl: './device-management-remote-disconnected.component.html',
  styleUrls: ['./device-management-remote-disconnected.component.scss', '../../../meter-management/common/grids/grid.component.scss']
})
export class DeviceManagementRemoteDisconnectedComponent {
  @Input() buName: string = '';
  @Input() utName: string = '';


  //pagination
  public count: number = 0
  public pageSize: number = 10
  public currentPage: number = 1
  public lastPageSize: number = 1;

  //checkbox
  public data: any[] = [];

  //details


  public commStatus: number = 0
  public commStatusOpened: boolean = false


  @Input() buIds: any = null;
  @Input() utIds: any = null;
  @Input() dateFrom: any = '';
  @Input() dateTo: any = '';
  @Input() searchText: SearchAutocomplete = new SearchAutocomplete()

  @Output() countChange = new EventEmitter<number>();

  constructor(private meterService: MeterService,
    private toastr: ToastrService,
    ){}

  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false

    if (changes.buIds != null) {
      reloadTable = true
    }
    if (changes.utIds != null) {
      reloadTable = true
    }
    if (this.searchText?.name == '')
      this.searchText = changes.search?.previousValue
    if (changes.searchText != null) {
      reloadTable = true
    }
    if (reloadTable) {
      this.pageChange(1)
    }
  }

  GetRemoteDisconnectedData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        Search: this.searchText ? this.searchText.name : this.searchText,
        UtIds: this.utIds,
        BuIds: this.buIds,
        CommStatus: this.commStatus
      }
    };
    this.meterService.getAllDeviceManagement(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data;
        console.log(this.data)
        if (obj.pageInfo.page == 1) {
          this.count = response.data.count;
          this.countChange.emit(this.count);
          this.lastPageSize = Math.ceil(this.count / this.pageSize);
        }
        // this.isAlreadyChecked();
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

  // checkUncheckAll() {
  //   for (var i = 0; i < this.data.length; i++) {
  //     this.data[i].checked = this.masterSelected;
  //     this.checkItem(this.data[i].id, true);
  //   }
  // }

  // checkItem(id: number, isMasterClicked: boolean = false) {
  //   if(!isMasterClicked)
  //     this.isMasterChecked();
  //   let index = this.checkedList.indexOf(id);
  //   if(index !== -1){
  //     if(!isMasterClicked)
  //       this.checkedList.splice(index, 1);
  //     else if(isMasterClicked && !this.masterSelected)
  //       this.checkedList.splice(index, 1);
  //   }
  //   else {
  //     this.checkedList.push(id);
  //   }
  // }

  // isAlreadyChecked() {
  //   this.data.forEach(x => {
  //     let index = this.checkedList.indexOf(x.id)
  //     if(index !== -1) {
  //       x.checked = true;
  //     }
  //   })

  //   this.isMasterChecked();
  // }

  // isMasterChecked() {
  //   this.masterSelected = this.data.every(function(item:any) {
  //     return item.checked == true;
  //   })
  // }

  public commStatusClicked(open: number) {
    if (open == 0)
      this.commStatusOpened = false
    else {
      if (this.commStatusOpened) {
        this.commStatusOpened = false
      }
      else {
        this.commStatusOpened = true
      }

    }
  }

  public commStatusSelected(value: number) {
    this.currentPage = 1
    this.commStatus = value
    this.GetRemoteDisconnectedData()
  }

  copyToClipboard(text: any) {
    navigator.clipboard.writeText(text)
    this.toastr.success('Copied to clipboard')
  }

  pageChange(value: number) {
    this.currentPage = value
    this.GetRemoteDisconnectedData()
  }

  reconnectionMeter(id: number){
    let obj =
    {
      Id: id
    };

    this.meterService.setMeterStatusConnected(obj).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        // this.checkedList = []
        this.GetRemoteDisconnectedData();
      } else {
        this.toastr.error(response.message);
      }
    }, _ => {
      this.toastr.error('An error occurred.');
    })
  }

}
