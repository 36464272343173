import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AccountService } from 'src/app/services/account.service';
import { AllPaymentsService } from 'src/app/services/allPayments.service';
import { BillsService } from 'src/app/services/bills.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CommandRequestService } from 'src/app/services/command-request.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { VendingsService } from 'src/app/services/vendings.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { BillsNewComponent } from './bills-new/bills-new.component';

@Component({
  selector: 'app-reports-new',
  templateUrl: './reports-new.component.html',
  styleUrls: ['./reports-new.component.scss']
})
export class ReportsNewComponent implements OnInit {
  public showPaging: boolean = false;

  public tabMenu: number = 1;
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public dateFrom: any = ''
  public dateTo: any = ''
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');

  public allFilters: any = []
  public businessUnits: any = { name: this.buName, data: []};
  public undertakings: any = { name: this.utName, data: [] };
  public filterApplied: boolean = false;
  public showFilter: any = false
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;

  @ViewChild('child') child!: BillsNewComponent;  

  public buNames: any = [];
  public utNames: any = [];

  public searchPlaceholder: string = "Search by Account Number & SPN"

  options = ['']

  @ViewChild(SearchAutocompleteComponent) searchComponentRef!: SearchAutocompleteComponent

  constructor(private toastr: ToastrService, 
    private translationService: TranslationService,
    private billsService: BillsService,
    private vendingsService: VendingsService,
    private paymentsService: AllPaymentsService,
    private commandRequestService: CommandRequestService,
    private businessUnitService: BusinessUnitService,
    private undertakingService: UndertakingService,
    private accountService: AccountService) { }

  ngOnInit(): void {
    this.getAllBUs()
    this.getAllUTs()
    this.setMainDate()
  }

  getAllBUs() {
    this.businessUnitService.getAllForSelect().subscribe(response => {
      this.businessUnits = { name: this.buName, data: response.data, dropdown: false }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  getAllUTs() {
    this.undertakingService.getAllForSelect().subscribe(response => {
      this.undertakings = { name: this.utName, data: response.data,dropdown: false }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  tabChanged(value: number) {
    this.tabMenu = value
    this.resetFilters();
  }

  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { name: any }) => x.name)
      if (item.name === this.businessUnits.name ) {
        this.buNames = filteredValues
      }
      else if (item.name === this.undertakings.name) {
        this.utNames = filteredValues
      }
    }
  }

  getOptions(searchText: SearchAutocomplete) {  
    let obj = {
      searchText: searchText.name, 
      msnIncluded: false
    }
    if (searchText.name.length > 2) {
      if (this.tabMenu == 1) {
        this.billsService.getDataForAutocomplete(obj).subscribe({ 
          next : (response) => this.options = response.data,
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
      } 
      else if(this.tabMenu == 2){
        this.vendingsService.getDataForAutocomplete(obj).subscribe({ 
          next : (response) => this.options = response.data,
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
      }
      else if (this.tabMenu == 3) {
        this.paymentsService.getDataForAutocomplete(obj).subscribe({ 
          next : (response) => this.options = response.data,
          error: (err: HttpErrorResponse) => this.toastr.error("An error, please contact support center"),
        })
      }
    } else
      this.searchComponentRef.resetOptions()
  }

  checkFilters() {
    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnits.name);
    if (buIndex !== -1){
      this.allFilters[buIndex].data = this.businessUnits.data;
    }

    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakings.name);
    if (utIndex !== -1)
      this.allFilters[utIndex].data = this.undertakings.data;

    if(buIndex === -1 && utIndex === -1)
    {
      this.allFilters.push(this.businessUnits)
      this.allFilters.push(this.undertakings)
    }

    this.filterApplied = true
    this.allFilters = [...this.allFilters]
  }

  inputChanged(searchText: SearchAutocomplete) {
    this.searchText = searchText
    this.searchText = cloneDeep(this.searchText) 
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
  }

  download(){
    this.child.download()
  }

  resetFilters(){
    if (this.tabMenu == 1) {
      this.searchPlaceholder = "Search by Account Number & SPN";
      this.setMainDate();
    }
    else if (this.tabMenu == 2) {
      this.searchPlaceholder = 'Search by Account Number & SPN'
      this.setVendsAndPaymentsDate();
    }
    else if (this.tabMenu == 3) {
      this.searchPlaceholder = 'Search by Account Number & SPN'
      this.setVendsAndPaymentsDate();
    }

    this.buNames = []
    this.utNames = []
    this.allFilters = []
    this.showFilter = false
    this.filterApplied = false
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.checkFilters()


    this.searchText = new SearchAutocomplete()
    if(this.searchComponentRef){
      this.searchComponentRef.searchText = new SearchAutocomplete()
      this.searchComponentRef.resetOptions()
    }
  }

  setMainDate(){
    let dteFrom = new Date()
    let dte = new Date()

    dteFrom.setHours(2, 0, 0);
    dte.setHours(2, 0, 0);

    dteFrom.setDate(1)
    dteFrom.setMonth(dteFrom.getMonth() - 1);
    dte.setDate(1)

    this.dateFrom = {year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate()};
    this.dateTo = {year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate()};
  }

  setVendsAndPaymentsDate(){
    let date = new Date()
    let dteFrom = new Date()

    dteFrom.setMonth(dteFrom.getMonth() - 1);
    date.setHours(2, 0, 0)

    this.dateFrom = { year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate() };
    this.dateTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
  }
}
