import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-map-table-button-new',
  templateUrl: './map-table-button-new.component.html',
  styleUrls: ['./map-table-button-new.component.scss']
})
export class MapTableButtonNewComponent {

  @Input() isMap: boolean = true
  @Input() mapBtnDisabled: boolean = false

  @Output() filterClicked: any = new EventEmitter()

  public buttonClick(value: boolean) {
    if (this.mapBtnDisabled)
      return
    this.isMap = value
    this.filterClicked.emit(this.isMap)
  }
}
