<div class="revenue-protection-header">
    <div class="revenue-protection-page-header">
        <span>Inventory management</span>
        <div class="button">
            <button class="btn-white" (click)="deleteItems()" click-stop-propagation>
                Delete
            </button>
            <button class="btn-white" (click)="issueItem(0)" click-stop-propagation>
                Issue Item
            </button>
            <button class="red-btn" (click)="openPopup()" click-stop-propagation>
                Create Inventory <img alt="Create" src="assets/images-new-design/plus.png">
            </button>
        </div>
    </div>
</div>
<div>
    <table class="standard-table">
        <thead>
            <tr>
                <th>
                    <span>
                        <input class="input-checkmark" type="checkbox" name="list_name" value="h1"
                            [(ngModel)]="masterSelected" (change)="checkUncheckAll()" />
                    </span>
                </th>
                <th><span>ITEM ID</span></th>
                <th><span>ITEM NAME</span></th>
                <th><span>CATEGORY</span></th>
                <th><span>IN STOCK</span></th>
                <th><span>TOTAL QUANTITY</span></th>
                <th><span>DATE ADDED</span></th>
                <th><span>LAST ISSUED</span></th>
                <th><span>LAST ISSUED TO</span></th>
                <th><span></span></th>
            </tr>
        </thead>
        <tbody class="table-body">
            <tr *ngFor="let item of inventoryData" >
                <td (click)="false; $event.stopPropagation();">
                    <input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked"
                        (change)="isAllSelected()" />
                </td>
                <td><span>{{item.itemId}}</span></td>
                <td><span>{{item.name}}</span></td>
                <td><span>{{item.categoryName}}</span></td>
                <td>
                    <span>{{item.inStockQuantity}}</span>
                    <span class="low-stock" *ngIf="item.inStockQuantity < item.threshold">Low</span>
                </td>
                <td>
                    <span>{{item.totalQuantity}}</span>
                    <span class="faulty" *ngIf="item.faultQuantity > 0">Faulty {{item.faultQuantity | hasValue}}</span>
                </td>
                <td><span>{{item.dateAdded | date: 'MMM d, y'}}</span></td>
                <td>
                    <span *ngIf="item.lastDateIssued != undefined">{{item.lastDateIssued | date: 'MMM d, y'}}</span>
                    <span *ngIf="item.lastDateIssued == undefined">/</span>
                </td>
                <td>
                    <span *ngIf="item.lastIssuedUser != null">
                        <div class="header-icon">
                            <div *ngIf="item.userImage; else defaultImage"> <img alt="Portrait" class="image" src="{{ item.userImage }}"></div>
                            <ng-template #defaultImage>
                                <div class="image"><img alt="Default" class="image" src="/assets/images-new-design/defaultImg.png">
                                </div>
                            </ng-template> {{ item.lastIssuedUser | hasValue}}
                        </div>
                    </span>
                    <span *ngIf="item.lastIssuedUser == null" class="add-agent" (click)="issueItem(item)">Issue Item</span>
                </td>
                <td (click)="showDetails(item)" class="bodyRow clickable actions-click"><span><img alt="..." src="../../../../../assets/images/3-dot.svg"></span></td>
            </tr>
        </tbody>
    </table>

    <div class="pagination-wrapper">
        <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
            [currentPage]="currentPage" (pageChange)="pageChange($event)">
        </app-fo-pagination-new>
    </div>
</div>
