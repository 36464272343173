import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { AccountService } from 'src/app/services/account.service';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { DtService } from 'src/app/services/dt.service';
import { Feeder11Service } from 'src/app/services/feeder11.service';
import { MeterService } from 'src/app/services/meter.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UndertakingService } from 'src/app/services/undertaking.service';
import { MultiFilterComponent } from '../../common-new-design/multi-filter/multi-filter.component';
import { SearchAutocompleteComponent } from '../../common-new-design/search-autocomplete/search-autocomplete.component';
import { MenuItem, SubMenuItem } from '../../consumption/model/model';
import { MeterGridComponent } from '../../meter-management/common/grids/mm-meter-grid/mm-meter-grid.component';

@Component({
  selector: 'app-meters',
  templateUrl: './meters.component.html',
  styleUrls: ['./meters.component.scss']
})
export class MetersComponent implements OnInit {

  @ViewChild('child') child!: MeterGridComponent
  @ViewChild('search') searchChild!: SearchAutocompleteComponent

  public Data: any = []
  public isMap: boolean = false;
  public MenuItemEnum = MenuItem;
  public meterItemType: string = 'Prepaid';
  public MenuSubItemEnum = SubMenuItem;
  public subMenu: string = 'Active';

  public tabMenu: number = 1;

  public tabSubMenu: number = 1;
  public prepaid: number = 17;

  public width: string = "35rem";
  public height: string = "17rem";
  public searchWidth: string = "412px"
  public searchWidth2: string = "412px"

  public totalEnergyConsumption: number = 0
  public totalEnergyConsumptionTwoMonthsAgo: number = 0

  // Datepicker
  public dateFrom: any = ''
  public dateTo: any = ''
  public monthFrom: any = ''
  public monthTo: any = ''

  public isDownload: boolean = false
  public maxData: boolean = true

  @Input() markers: any[] = []
  @Input() ssMarkers: any[] = []
  @Input() tsMarkers: any[] = []
  @Input() dtMarkers: any[] = []
  @Input() tempDtMarkers: any[] = []
  @Input() buMarkers: any[] = []
  @Input() utMarkers: any[] = []

  public accountNumbers: any = [];

  //multi filter
  public allFilters: any = []
  public showFilter: any = false
  public filterApplied: boolean = false;
  @ViewChild(MultiFilterComponent) multiFilterRef!: MultiFilterComponent;
  public amiName: string = this.translationService.getByKeyFromCache('AMI')
  public buName: string = this.translationService.getByKeyFromCache('BU')
  public utName: string = this.translationService.getByKeyFromCache('UT')
  public dtName: string = this.translationService.getByKeyFromCache('DT')
  public ssName: string = "Substation"
  public f11Name: string = "Feeder 11"
  public tsName: string = "Transmission Station"
  public feederName: string = "Feeders"
  public customerName: string = "Customer"
  postpaidActiveCount: number = 0;
  public postpaidCount: number = 0;
  postpaidDisconnectedCount: number = 0;
  postpaidFinalizedCount: number = 0;
  postpaidInactiveCount: number = 0;
  postpaidUndefinedCount: number = 0;
  prepaidActiveCount: number = 0;
  prepaidCount: number = 0;
  prepaidFinalizedCount: number = 0;
  prepaidInactiveCount: number = 0;
  prepaidUndefinedCount: number = 0;
  prepaidWithoutMeterCount: number = 0;
  postpaidWithoutMeterCount: number = 0;


  public buSelected: any = { value: 0, name: '', checked: false }
  public utSelected: any = { value: 0, name: '', checked: false }
  public dtSelected: any = { value: 0, name: '', checked: false }
  public substationSelected: any = { value: 0, name: '', checked: false }
  public feeder11Selected: any = { value: 0, name: '', checked: false }
  public undertakingsNew: any = { name: this.utName, data: [] };

  public businessUnitsNew: any = { name: this.buName, data: [] };
  public amisNew: any = { name: this.amiName, data: [] };

  public substations: any = { name: this.ssName, data: [] };
  public transmissionStations: any = { name: this.tsName, data: [] };
  public feeders11New: any = { name: this.f11Name, data: [] };
  // public allFeederNames: any = { name: this.feederName, data: [] };
  public ssIds: number[] = []
  public f11Ids: number[] = []
  // public feederNames: any = [];
  public meterIds: number[] = []
  public buIds: number[] = [];
  public utIds: any = [];
  public dtIds: number[] = [];
  public amiIds: number[] = [];
  public customerTypeValue: number = 0;
  public customerType: string = '';
  public customerPrepaidFlagUnmetered = true;

  public checkedIds: any = [];

  public searchPlaceholder: string = "Search by Meter Number, Account Number & SPN"

  //MAP Filter
  public customerTypeName: string = 'Customer Type'

  public businessUnitsMap: any = { name: this.buName, data: [], dropdown: true };
  public undertakingsMap: any = { name: this.utName, data: [], dropdown: true };
  public dtsMap: any = { name: this.dtName, data: [] };
  public customersMap: any = { name: this.customerName, data: [], dropdown: true };
  public substationsMap: any = { name: this.ssName, data: [], dropdown: true };
  public feeders11Map: any = { name: this.f11Name, data: [], dropdown: true };
  public customerTypeOptions: { name: string; data: { value: number; name: string }[], dropdown: boolean } = { name: this.customerTypeName, data: [{ value: 2, name: 'MD' }, { value: 3, name: 'NMD' }], dropdown: true }

  // Search Autocomplete
  public searchText: SearchAutocomplete = new SearchAutocomplete()
  public options = []
  public searchLength: number = 1
  // public optionsNew = [{id: 0, name: '', type: ''}]

  public showDetails: boolean = false;
  public detailsData: any;

  meterStatuses: string[] = ["Inactive", "Finalised"];

  public PermissionsEnum = PermissionsEnum;

  constructor(
    public toastr: ToastrService,
    private translationService: TranslationService,
    public substationService: SubstationService,
    public feederService: Feeder11Service,
    public accountService: AccountService,
    private meterService: MeterService,
    private buService: BusinessUnitService,
    private utService: UndertakingService,
    private dtService: DtService,
    private amiService: AmiService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.setMainDate();
    this.GetCardsCount();
    this.GetMetersCount()

    this.getAllBUs()
    this.getAllUTs()
    this.getAllDTs()
    this.getAllAmis()
  }

  mapChanged(value: boolean) {
    console.log(value)
    this.isMap = value
    if (this.isMap) {
      //this.alert()
      this.searchPlaceholder = "Search by meter number"

    }
    else if (!this.isMap) {
      this.meterItemType = 'Prepaid'
      this.subMenu = 'Active'
    }
    this.resetFilters()
  }

  menuChanged(value: number) {
    this.tabMenu = value;
    if (value == 1) {
      this.meterItemType = 'Prepaid'
      this.tabChanged(1)
    }
    else if (value == 2) {
      this.meterItemType = 'Postpaid'
      this.tabChanged(1)
    }
  }
  alert() {
    this.toastr.warning('Not implemented yet!');
  }
  tabChanged(value: number) {
    this.tabSubMenu = value;
    if (this.meterItemType == 'Prepaid') {
      if (value == 1) {
        this.subMenu = 'Active'
        this.meterStatuses = ["Inactive", "Finalised"];
      }
      else if (value == 2) {
        this.subMenu = 'Inactive'
        this.meterStatuses = ["Active", "Finalised"];
      }
      else
        if (value == 3) {
          this.subMenu = 'Finalised'
          this.meterStatuses = ["Active", "Inactive"];
        }

        else if (value == 4) {
          this.subMenu = 'Undefined'
          this.meterStatuses = ["Active", "Inactive", "Disconnected", "Finalised"]
        }
        else if (value == 5) {
          this.customerPrepaidFlagUnmetered = true;
        }
    } else {
      if (value == 1) {
        this.subMenu = 'Active'
        this.meterStatuses = ["Inactive", "Disconnected", "Finalised"]
      }
      else if (value == 2) {
        this.subMenu = 'Inactive'
        this.meterStatuses = ["Active", "Disconnected", "Finalised"]
      }
      else if (value == 3) {
        this.subMenu = 'Disconnected'
        this.meterStatuses = ["Active", "Inactive", "Finalised"]
      }
      else if (value == 4) {
        this.subMenu = 'Finalised'
        this.meterStatuses = ["Active", "Inactive", "Disconnected"]
      }
      else if (value == 5) {
        this.subMenu = 'Undefined'
        this.meterStatuses = ["Active", "Inactive", "Disconnected", "Finalised"]
      }
      else if (value == 6) {
        this.customerPrepaidFlagUnmetered = false;
      }
    }
    this.resetFilters()
  }

  // set intital date for datepicker
  private setMainDate() {
    let dte = new Date();
    let dteFrom = new Date()

    dteFrom.setHours(2, 0, 0);
    dte.setHours(2, 0, 0);
    dteFrom.setDate(dteFrom.getDate() - 1)

    this.dateFrom = { year: dteFrom.getFullYear(), month: dteFrom.getMonth() + 1, day: dteFrom.getDate() };
    this.dateTo = { year: dte.getFullYear(), month: dte.getMonth() + 1, day: dte.getDate() };
  }
  dropdownSelected(data: any) {
    if (data.data.name === this.customerTypeOptions.name) {
      this.customerTypeValue = data.item.value
      this.customerType=data.item.name
    }
    this.GetMetersCount()
    this.GetCardsCount()

  }
  // Apply Filter from Multi Filter
  applyFilter(data: any) {
    for (const item of data) {
      const checkedData = item.data.filter((x: { checked: any }) => x.checked)
      const filteredValues = checkedData.map((x: { value: any }) => x.value)
      if (item.name === this.substations.name) {
        this.ssIds = filteredValues
      }

      else if (item.name === this.undertakingsNew.name) {
        this.utIds = filteredValues
      }
      else if (item.name === this.businessUnitsNew.name) {
        this.buIds = filteredValues
      }
      else if (item.name === this.dtsMap.name) {
        this.dtIds = filteredValues
      } else if (data.name === this.customerTypeOptions.name) {
        this.customerTypeValue = filteredValues
      }
      else if (item.name === this.amisNew.name) {
        this.amiIds = filteredValues
      }
    }
    this.GetCardsCount()
    this.GetMetersCount()

  }

  resetFilters() {
    //this.searchPlaceholder = "Search by DT name or number";

    this.searchText = new SearchAutocomplete()
    if (this.searchChild) {
      this.searchChild.searchText = new SearchAutocomplete()
      this.searchChild.resetOptions()
    }
    this.setMainDate()

    //multiFilter
    this.meterIds = []
    this.ssIds = []
    this.customerTypeValue = 0
    this.f11Ids = []
    this.buIds = []
    this.utIds = []
    this.amiIds = []
    this.allFilters = []
    this.showFilter = false
    this.filterApplied = false
    if (this.multiFilterRef) {
      this.multiFilterRef.dropdownSelected = null
      this.multiFilterRef.resetShowFilter()
      this.multiFilterRef.uncheckData()
    }
    this.checkFilters()
    this.GetCardsCount()
    this.GetMetersCount()

    // this.child.pageChange(1)
  }

  //Check Filter for multi filter component
  checkFilters() {
    this.filterApplied = true;
    let buData = this.allFilters.find((item: { name: any; }) => item.name === this.businessUnitsNew.name);

    let buIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.businessUnitsNew.name);
    if (buIndex !== -1) {
      this.allFilters[buIndex].data = this.businessUnitsNew.data;
    }
    let utData = this.allFilters.find((item: { name: any; }) => item.name === this.undertakingsNew.name);

    let utIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.undertakingsNew.name);
    if (utIndex !== -1) {
      this.allFilters[utIndex].data = this.undertakingsNew.data;
    }
    let dtData;
    let amiData;
    if (!this.isMap) {
      dtData = this.allFilters.find((item: { name: any; }) => item.name === this.dtsMap.name);

      let dtIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.dtsMap.name);
      if (dtIndex !== -1) {
        this.allFilters[dtIndex].data = this.dtsMap.data;
      }
      amiData = this.allFilters.find((item: { name: any; }) => item.name === this.amisNew.name);

      let amiIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.amisNew.name);
      if (amiIndex !== -1) {
        this.allFilters[amiIndex].data = this.amisNew.data;
      }
      let customerTypeIndex = this.allFilters.findIndex((item: { name: any; }) => item.name === this.customerTypeOptions.name);
      if (this.subMenu == 'Undefined') {
        if (customerTypeIndex !== -1)
          this.allFilters[customerTypeIndex].data = this.customerTypeOptions.data;
        else
          this.allFilters.push(this.customerTypeOptions)
      }
    }
    if (!buData)
      this.allFilters.push(this.businessUnitsNew)
    if (!utData)
      this.allFilters.push(this.undertakingsNew)
    if (!this.isMap) {
      if (this.subMenu != 'Undefined') {

        if (!amiData)
          this.allFilters.push(this.amisNew)
      }
      if (!dtData)
        this.allFilters.push(this.dtsMap)
    }

    this.allFilters = [...this.allFilters]
  }

  dateChange(data: any) {
    this.dateFrom = data.dateFrom
    this.dateTo = data.dateTo
  }

  searchChange(text: SearchAutocomplete) {
    this.searchText = text
    this.searchText = cloneDeep(this.searchText)
  }

  // Search Autocomplete
  getOptions(searchText: any) {
    let obj = {
      searchText: searchText.name,
      msnIncluded: true,
      MeterItemType: this.meterItemType,
      MeterItemSubType: this.subMenu
    }
    this.searchChild.searchLength = this.searchLength;
    if (searchText.name.length > 1) {
      this.meterService.getDataForAutocomplete(obj)
        .subscribe(response => {
          this.options = response.data
        })
    } else {
      this.searchChild.resetOptions()
    }
  }
  getAllBUs() {
    this.buService.getAllForSelect().subscribe(response => {
      this.businessUnitsNew = { name: this.buName, data: response.data }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getAllAmis() {
    this.amiService.getAllForSelect().subscribe(response => {
      this.amisNew = { name: this.amiName, data: response.data }
      this.checkFilters()

    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getAllUTs() {
    this.utService.getAllForSelect().subscribe(response => {
      this.undertakingsNew = { name: this.utName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }
  getAllDTs() {
    this.dtService.getAllForSelect().subscribe(response => {
      this.dtsMap = { name: this.dtName, data: response.data }
      this.checkFilters()
    }, error => {
      this.toastr.error('An error occurred.')
    })
  }

  public download() {
    this.isDownload = !this.isDownload    //Just change value so ngOnChange can be triggered
    this.child.download();
  }

  changeStatus(status: any) {
    this.checkedIds = this.child.getCheckedItemList();
    if (this.checkedIds.length == 0) {
      this.toastr.error("Select accounts to change their status.")
      return
    }
    
    this.accountNumbers = [];
    if (this.checkedIds.length != 0) {
      this.accountNumbers = this.checkedIds.map((item: any) => item.accountNumber)
    }

    let obj = {
      AccountNumbers: this.accountNumbers,
      AccountStatus: status,
    };

    this.accountService.updateStatusBulk(obj).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.child.GetData();
        this.GetCardsCount();
      } else {
        this.toastr.error(response.message)
      }
    }, error => {
      this.toastr.error('An error occurred.');
    })
  }

  public GetMetersCount() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      Search: this.searchText ? this.searchText.name : this.searchText,
      BuIds: this.buIds,
      DtIds: this.dtIds,
      UtIds: this.utIds,
      CustomerType: this.customerType,
      AmiIds: this.amiIds,
    }
    this.meterService.GetMetersCount(obj).subscribe(response => {
      this.postpaidCount = response.postpaidCount

      this.prepaidCount = response.prepaidCount

    }, _ => {
      this.toastr.error('An error occurred.');
    })

  }
  public GetCardsCount() {
    let obj = {
      From: transformToString(this.dateFrom),
      To: transformToString(this.dateTo),
      Search: this.searchText ? this.searchText.name : this.searchText,
      BuIds: this.buIds,
      DtIds: this.dtIds,
      CustomerType: this.customerType,
      UtIds: this.utIds,
      AmiIds: this.amiIds,
    }
    this.meterService.GetCardsCount(obj).subscribe(response => {
      this.postpaidDisconnectedCount = response?.Postpaid?.Disconnected ?? 0
      this.postpaidFinalizedCount = response?.Postpaid?.Finalization ?? 0
      this.postpaidInactiveCount = response?.Postpaid?.Inactive ?? 0
      this.postpaidUndefinedCount = response?.Postpaid?.Undefined ?? 0
      this.prepaidActiveCount = response?.Prepaid?.Active ?? 0
      this.postpaidActiveCount = response?.Postpaid?.Active ?? 0
      this.postpaidWithoutMeterCount = response?.Postpaid?.WithoutMeter ?? 0

      this.prepaidFinalizedCount = response?.Prepaid?.Finalization ?? 0
      this.prepaidInactiveCount = response?.Prepaid?.Inactive ?? 0
      this.prepaidUndefinedCount = response?.Prepaid?.Undefined ?? 0
      this.prepaidWithoutMeterCount = response?.Prepaid?.WithoutMeter ?? 0
    }, _ => {
      this.toastr.error('An error occurred.');
    })

  }

  openDetails(id: any) {
    this.meterService.getMeterDetails(id).subscribe(response => {
      if (response.status === 200) {
        this.detailsData = response.data;
        this.showDetails = true;
      }
      else {
        this.toastr.error("An error occurred.");
      }
    }
    );
  }
}
