<!-- Animacija na zatvaranju se ne pojavljuje jer imamo shadow [hidden]="!showShadow", kad se stavi click-stop-propagation ne radi dropdown sa zastavicama-->

<div [hidden]="!showShadow" class="popup-overlay">
    <div tabindex="1" class="slider" id="slider" [ngClass]="{'shadow' : showShadow === true}"
        [@slideInOut]="animationState">
        <div class="btn-close-position" (click)="changeState(2)">
            <button class="back">
                <img alt="Exit" class="imgBack" src="assets/images-new-design/back-arrow.png">Exit
            </button>
        </div>
        <div class="col-sm-12 login-form">

            <div class="title">Need help?</div>
            <div class="detail">Send us a detail of what you need help with.</div>
            <form class="form col-sm-12" ngNativeValidate #f="ngForm">
                <div class="form-group">
                    <input class="form-control" [(ngModel)]="mail.fullName" name="name"
                        placeholder="Full name" type="text" autocomplete="off" required #name="ngModel">
                    <div *ngIf="f.submitted && !nameValid && !name.errors?.['required'];" class="form-control-error">
                        Please enter valid name</div>

                </div>
                <div class="form-group">
                    <input class="form-control" [(ngModel)]="mail.email" name="email"
                        placeholder="Email Address" type="email" autocomplete="off" required #email="ngModel">
                    <div *ngIf="f.submitted && email.errors?.['required']" class="form-control-error">Email
                        is required</div>

                </div>

                <div class="form-group">
                    <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [phoneValidation]="false"
                        [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National"
                        name="phoneNumber" [(ngModel)]="phoneNumberObject" #phoneNumberControl="ngModel" required>
                    </ngx-intl-tel-input>
                </div>
                <div class="form-group">
                    <input class="form-control" placeholder="Organisation" name="organisation"
                        [(ngModel)]="mail.organisation" type="text" required>
                </div>
                <div class="form-group">
                    <input class="form-control" placeholder="Subject" type="text" name="subject"
                        [(ngModel)]="mail.subject">
                </div>
                <div class="form-mess">
                    <textarea class="input-control" name="message" placeholder="Message" [(ngModel)]="mail.message"
                        type="text"> </textarea>
                </div>

                <button type="submit" class="button-new" (click)="sendMail(); " id="submitButton">Send message</button>

            </form>
        </div>
    </div>
</div>
