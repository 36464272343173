<app-attachments-popup
  [data]="showTask"
></app-attachments-popup>
<app-reason-popup
  (statusChanged)="updateWorkOrderStatus($event)"
></app-reason-popup>
<app-change-date-popup
  (dateChanged)="getWorkOrder()"
  (cancelReassign)="cancelReassign()">
</app-change-date-popup>

<div class="details-wrapper">
  <div class="header-wrapper">
    <div class="header-back" (click)="back()">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.52116 7.83342H12.8337C13.0698 7.83342 13.2677 7.75356 13.4274 7.59384C13.5871 7.43412 13.667 7.2362 13.667 7.00009C13.667 6.76398 13.5871 6.56606 13.4274 6.40634C13.2677 6.24662 13.0698 6.16676 12.8337 6.16676H3.52116L7.60449 2.08342C7.77116 1.91676 7.85102 1.72231 7.84408 1.50009C7.83713 1.27787 7.75033 1.08342 7.58366 0.916758C7.41699 0.76398 7.22255 0.684119 7.00033 0.677174C6.7781 0.67023 6.58366 0.750091 6.41699 0.916758L0.916992 6.41676C0.833659 6.50009 0.774632 6.59037 0.739909 6.68759C0.705187 6.78481 0.687826 6.88898 0.687826 7.00009C0.687826 7.1112 0.705187 7.21537 0.739909 7.31259C0.774632 7.40981 0.833659 7.50009 0.916992 7.58342L6.41699 13.0834C6.56977 13.2362 6.76074 13.3126 6.98991 13.3126C7.21908 13.3126 7.41699 13.2362 7.58366 13.0834C7.75033 12.9168 7.83366 12.7188 7.83366 12.4897C7.83366 12.2605 7.75033 12.0626 7.58366 11.8959L3.52116 7.83342Z" fill="#1C1B1F"/>
        </svg>
    </div>
    <div class="btns-wrapper">

      <div class="assign-btn">
        <app-dropdown-team-agents
            [header]="selectedAgentHeader"
            [dropdownHeader]="'Agents'"
            [data]="agents"
            (selectItemChange)="selectAgent($event)"
            [isAgents]="true"
            [selectedAgentId]="taskAgent.id"
          ></app-dropdown-team-agents>
      </div>

      <div class="edit-btn" (click)="openEdit()">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_2786_16941" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
          <rect width="20" height="20" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_2786_16941)">
          <path d="M3.83145 17.0515C3.56651 17.1103 3.33841 17.0441 3.14716 16.8528C2.95593 16.6616 2.88969 16.4335 2.94845 16.1685L3.64556 12.8224L7.17756 16.3544L3.83145 17.0515ZM7.17756 16.3544L3.64556 12.8224L12.9949 3.47307C13.2823 3.18569 13.6381 3.04199 14.0622 3.04199C14.4863 3.04199 14.8421 3.18569 15.1295 3.47307L16.5269 4.87049C16.8143 5.15788 16.958 5.51364 16.958 5.93778C16.958 6.36192 16.8143 6.71769 16.5269 7.00507L7.17756 16.3544ZM13.8859 4.35126L5.36508 12.8641L7.13591 14.6349L15.6487 6.11407C15.6968 6.06599 15.7208 6.00589 15.7208 5.93378C15.7208 5.86166 15.6968 5.80155 15.6487 5.75347L14.2465 4.35126C14.1984 4.30318 14.1383 4.27914 14.0662 4.27914C13.9941 4.27914 13.934 4.30318 13.8859 4.35126Z" fill="#1C1B1F"/>
          </g>
        </svg>
      </div>

      <div class="mark-btn">
        <app-simple-dropdown
          [header]="selectedStatus.name"
          [dropdownHeader]="'Mark as'"
          [data]="statuses"
          [selectedItemId]="selectedStatus.value"
          (selectItemChange)="selectStatus($event)"
        ></app-simple-dropdown>
      </div>
    </div>
  </div>
  <div class="title">
    <span>{{showTask.name}}</span>
  </div>

  <div class="details-table-wrapper">
    <table class="details-table">
      <tr>
        <th><span>Status</span></th>
        <td class="icons-wrapper">
          <div *ngIf="showTask.statusId == womStatus.Ongoing" class="ongoing">
            <img alt="" src="assets/images/clock_loader_40.svg"/>
            <span>Ongoing</span>
          </div>
          <div *ngIf="showTask.statusId == womStatus.Completed" class="completed">
            <img alt="" src="assets/images/check_circle.svg"/>
            <span>Completed</span>
          </div>
          <div *ngIf="showTask.statusId == womStatus.Cancelled" class="cancelled">
            <img alt="" src="assets/images/block.svg"/>
            <span>Cancelled</span>
          </div>
          <div *ngIf="showTask.statusId == womStatus.Unassigned" class="unassigned">
            <img alt="" src="assets/images/work_history.svg"/>
            <span>Unassigned</span>
          </div>
          <div *ngIf="showTask.priorityId == priorityType.High" class="high-priority">
            <span>High Priority</span>
          </div>
          <div *ngIf="showTask.priorityId == priorityType.Medium" class="medium-priority">
            <span>Medium Priority</span>
          </div>
          <div *ngIf="showTask.priorityId == priorityType.Low" class="low-priority">
            <span>Low Priority</span>
          </div>
          <div *ngIf="showTask.priorityId == null" class="none-priority">
            <span>None</span>
          </div>
          <div class="overdue" *ngIf="isOverdue(showTask.estimatedEndDate)">
            <img alt="" src="assets/images/error.svg"/>
            <span>Overdue</span>
          </div>
        </td>
      </tr>
      <tr>
        <th><span>Task ID</span></th>
        <td class="taskid-wrapper">
          <span>{{showTask.taskId | hasValue}}</span>
          <img alt="Copy" (click)="copyToClipboard(showTask.taskId)" src="assets/images/content_copy.svg"/>
        </td>
      </tr>
      <tr>
        <th><span>Assigned to</span></th>
        <td class="assigned-wrapper">
          <div *ngIf="showTask.assignedUser != null" class="images-container">
            <img alt="Portrait" [src]="showTask.agentImage != null ? showTask.agentImage : defaultImage"/>
          </div>
          <span *ngIf="showTask.assignedUser != null">{{agentName}}</span>
          <span *ngIf="showTask.assignedUser == null">Unassigned</span>
        </td>
      </tr>
      <tr>
        <th><span>Category</span></th>
        <td>
          <span>{{showTask.categoryName | hasValue}}</span>
        </td>
      </tr>
      <tr>
        <th><span>Asset Type</span></th>
        <td>
          <span>{{showTask.assetTypeName | hasValue}}</span>
        </td>
      </tr>
      <tr>
        <th><span>{{buName}}</span></th>
        <td>
          <span *ngIf="showTask.buName != null && showTask.buName != ''">{{showTask.buName}}</span>
          <span *ngIf="showTask.buName == null || showTask.buName == ''" class="NaN">N/A</span>
        </td>
      </tr>
      <tr>
        <th><span>{{utName}}</span></th>
        <td>
          <span *ngIf="showTask.utName != null && showTask.utName != ''">{{showTask.utName}}</span>
          <span *ngIf="showTask.utName == null || showTask.utName == ''" class="NaN">N/A</span>
        </td>
      </tr>
      <tr>
        <th><span>Attachments</span></th>
        <td *ngIf="showTask.attachments.length > 0" class="attachment-wrapper" (click)="viewAttachments()">
          <img alt="" src="assets/images/attachment.svg"/>
          <span>View attachment</span>
          <div class="attachment-no">
            <span>+</span>
            <span>{{showTask.attachments.length}}</span>
          </div>

        </td>
        <td *ngIf="showTask.attachments.length === 0" class="attachment-wrapper">
          <span>/</span>
        </td>
      </tr>
      <tr>
        <th><span>Date created</span></th>
        <td>
          <span>{{showTask.dateCreated | date | hasValue}}</span>
        </td>
      </tr>
      <tr>
        <th><span>Estimated end date</span></th>
        <td>
          <span>{{showTask.estimatedEndDate | date | hasValue}}</span>
        </td>
      </tr>
      <tr>
        <th><span>Description</span></th>
        <td>    
          <div class="description-wrapper">
            <div class="description">
              <p [ngClass]="{'closed': !isReadMore}">
                {{showTask.description}}
              </p>
            </div>
            <div class="read-more" (click)="isReadMore = !isReadMore">
              <span *ngIf="isReadMore">Read less</span>
              <span *ngIf="!isReadMore">Read more</span>
              <img alt="View More" [ngClass]="{'closed': isReadMore}" src="assets/images/expand_more.svg"/>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
