import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class RevenueDashboardService {

  private pathApi = this.config.setting['pathApi'] + "RevenueDashboard/";

  constructor(private http: HttpClient, 
    private config: AppConfig) {  }

    getUTsAndCustomersNo(obj: any): Observable<any>{
      return this.http.post<any>(`${this.pathApi}GetUndertakingCustomers`, obj)
    }

    getPostpaidBillDebts(obj: any): Observable<any>{
      return this.http.post<any>(`${this.pathApi}GetPostpaidBillDebts`, obj)
    }

    getTotalPostpaidNo(filter: number):Observable<any>{
      return this.http.get<any>(`${this.pathApi}GetTotalPostpaidCustomersNo/${filter}`)
    }

    getCurrentTotalWeeklyCollection():Observable<any>{
      return this.http.get<any>(`${this.pathApi}getCurrentWeeklyCollection`)
    }

    getCurrentTotalMonthlyCollection():Observable<any>{
      return this.http.get<any>(`${this.pathApi}getCurrentMonthlyCollection`)
    }
    
    getCurrentTotalCollection(obj: any):Observable<any>{
      return this.http.post<any>(`${this.pathApi}getCurrentTotalCollection`, obj)
    }
}
