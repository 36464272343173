import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { GlobalSearch } from "../models/header.model";
import { ComponentNamesNew } from "../models/crudsNew.model";

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    readonly globalSearchInitState: GlobalSearch = {clicked: false, text: '', componentName: ComponentNamesNew.NoComponent};

    private globalSearch:  BehaviorSubject<GlobalSearch> = new BehaviorSubject<GlobalSearch>(this.globalSearchInitState);

    public globalSearch$: Observable<GlobalSearch> = this.globalSearch.asObservable();

    setGlobalSearch(latestValue: GlobalSearch) {
        return this.globalSearch.next(latestValue);
    }

    cleanGlobalSearch() {
        return this.globalSearch.next(this.globalSearchInitState);
    }
    
}