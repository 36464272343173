export class StockItem {
    id: number = 0;
    name: string = '';
    itemId: string = '';
    description: string = '';
    category: number = 0;
    categoryName: string = '';
    categoryId: number = 0;
    totalQuantity: number = 0;
    goodQuantity: number = 0;
    faultQuantity: number = 0;
    inStockQuantity: number = 0;
    issuedQuantity: number = 0;
    threshold: number = 0;
    images: string[] = []; 
    dateAdded: Date = new Date();
    relatedTaskId: number = 0;
}

export enum ItemCategories {
    Tools = 0,
    Consumables = 1,
    Assets = 2
}