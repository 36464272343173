<div class="date-container" id="date-picker" #dateContainer>
  <div class="date-inner-container" id="date-inner">
    <div class="left-date" (click)="toggle()">
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M2.3077 19.5C1.80257 19.5 1.375 19.325 1.025 18.975C0.675 18.625 0.5 18.1974 0.5 17.6923V4.30772C0.5 3.80259 0.675 3.37502 1.025 3.02502C1.375 2.67502 1.80257 2.50002 2.3077 2.50002H3.69233V1.15384C3.69233 0.934627 3.76573 0.751619 3.91253 0.604819C4.05931 0.458035 4.24232 0.384644 4.46155 0.384644C4.68077 0.384644 4.86378 0.458035 5.01058 0.604819C5.15736 0.751619 5.23075 0.934627 5.23075 1.15384V2.50002H12.8077V1.13462C12.8077 0.921803 12.8794 0.743602 13.023 0.600018C13.1666 0.456435 13.3448 0.384644 13.5576 0.384644C13.7704 0.384644 13.9486 0.456435 14.0922 0.600018C14.2358 0.743602 14.3076 0.921803 14.3076 1.13462V2.50002H15.6923C16.1974 2.50002 16.625 2.67502 16.975 3.02502C17.325 3.37502 17.5 3.80259 17.5 4.30772V17.6923C17.5 18.1974 17.325 18.625 16.975 18.975C16.625 19.325 16.1974 19.5 15.6923 19.5H2.3077ZM2.3077 18H15.6923C15.7692 18 15.8397 17.9679 15.9038 17.9038C15.9679 17.8397 16 17.7692 16 17.6923V8.30772H1.99997V17.6923C1.99997 17.7692 2.03202 17.8397 2.09612 17.9038C2.16024 17.9679 2.23077 18 2.3077 18ZM1.99997 6.80774H16V4.30772C16 4.23079 15.9679 4.16026 15.9038 4.09614C15.8397 4.03204 15.7692 3.99999 15.6923 3.99999H2.3077C2.23077 3.99999 2.16024 4.03204 2.09612 4.09614C2.03202 4.16026 1.99997 4.23079 1.99997 4.30772V6.80774Z"
              fill="#2E3336" />
      </svg>
  </div>
    <div class="right-date">
      <div *ngIf="isPopupShown" class="datetime-wrapper">
          <ngb-datepicker  [(ngModel)]="date" [startDate]="date ?? currentDate" (ngModelChange)="onDateSelection($event)" class="datetime-date" ></ngb-datepicker>
          <ngb-timepicker [(ngModel)]="time" (ngModelChange)="onTimeSelection($event)" class="datetime-time" ></ngb-timepicker>
          <button class="black-btn" (click)="applySelection()"> Apply </button>
      </div>
      <input class="form-control"
      [value]="dateLabel + ' ' + timeLabel"
      [readOnly]="true"
      (click)="toggle()"
      name="datepicker"
      tabindex="-1" />
      <ng-template #customDays let-date let-focused="focused">
        <span class="custom-day"
            [class.focused]="focused"
            >
            {{ date.day }}
        </span>
    </ng-template>
    <ng-template #customMonthYearTemplate let-date>
      {{ date.monthText }} {{ date.year }}
  </ng-template>
    </div>
  </div>
</div>
