import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss']
})
export class FilterDropdownComponent{

  @Input() filterClicked: boolean = false;
  @Output() filterClickedChange = new EventEmitter();

  @Input() name: any;
  @Input() type: any;
  @Input() dropdownName: any = '';
  @Input() data: any;
  @Input() dataId: any;
  @Input() circleColor: string = '';
  @Input() headerName: string = '';
  @Input() width: string = '19rem';
  @Input() widthExpanded: string = '19rem';
  @Input() backgroundColor: string = 'white';
  @Input() borderRadius: string = '0.5rem';
  @Input() border: string = '1px solid #E8E8E8';
  @Input() textColor: string = '#575757';
  @Input() isDebtRange: boolean = false;
  @Input() fontSizeText: string = '1.4rem';
  @Input() fontWeightText: string = '400';
  @Input() lineHeightText: string = '16px';

  @Output() dataChange = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.filterClicked){
      this.filterClicked = changes.filterClicked.currentValue;
    }
  }

  changeData(value:any, name:any, type:any = ''){
    let dataChange = {
                value: value,
                name: name,
                type: type
              }

    this.dataChange.emit(dataChange);
  }

  public filterClick() {
    this.filterClicked = !this.filterClicked;
    this.filterClickedChange.emit(this.filterClicked);
  }

  public close(){
    this.filterClicked = false;
  }


}
