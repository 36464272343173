import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tab-default',
  templateUrl: './tab-default.component.html',
  styleUrls: ['./tab-default.component.scss']
})
export class TabDefaultComponent implements OnInit {

  @Input() firstText: string = 'First'
  @Input() secondText: string = 'Second'
  @Input() marginLeft: string = '0'
  @Input() thirdText: string = ''
  @Input() forthText: string = ''
  @Input() fifthText: string = ''
  @Input() sixthText: string = ''
  @Input() firstDisabled: boolean = false
  @Input() secondDisabled: boolean = false
  @Input() thirdDisabled: boolean = false
  @Input() forthDisabled: boolean = false
  @Input() fifthDisabled: boolean = false
  @Input() sixthDisabled: boolean = false
  @Input() tabSelected = 1
  @Input() isInvalidAccount: boolean = false;

  @Input() firstImg: string = ''
  @Input() secondImg: string = ''
  @Input() thirdImg: string = ''
  @Input() fourthImg: string = ''
  @Input() fifthImg: string = ''
  @Input() sixthImg: string = ''


  @Input() firstTabNum: number = 0;
  @Input() secondTabNum: number = 0;
  @Input() thirdTabNum: number = 0;
  @Input() fourthTabNum: number = 0;
  @Input() fifthTabNum: number = 0;
  @Input() sixthTabNum: number = 0;
  @Input() wom: boolean = false;

  @Input() womCss: boolean = false;
  @Input() paCss: boolean = false;
  @Input() nmDashCss: boolean = false;
  @Input() meterMapCss:boolean=false;

  @Input() isConst: boolean = true;
  @Input() tabs: any = [];

  @Output() tabChanged = new EventEmitter();
  @Output() tabIdChanged = new EventEmitter();

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
    if(this.meterMapCss)
      this.tabSelected=0
  }

  changeTab(value: number) {
    if(this.meterMapCss){
      this.tabSelected=0;
      return;
    }
    this.tabSelected = value
    this.tabChanged.emit(this.tabSelected)
  }

  changeTabMulti(tabNumber: number, tabId?: number){
    this.tabSelected = tabNumber;
    if(this.meterMapCss){
      this.tabSelected=0;
      return;
    }
    if(tabId !== undefined)
      this.tabChanged.emit(tabId);
    else
      this.tabChanged.emit(this.tabSelected);
  }

  notImplementedYet(){
    this.toastr.info("Not implemented yet");
  }
}
