import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Account } from 'src/app/models/account.model';
import { ComponentNamesNew } from 'src/app/models/crudsNew.model';
import { DictionaryItem } from 'src/app/models/dictionary-item.model';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AccountService } from 'src/app/services/account.service';
import { AmiService } from 'src/app/services/ami.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common-service.service';
import { ConcentratorService } from 'src/app/services/concentrator.service';
import { DtService } from 'src/app/services/dt.service';
import { HeaderService } from 'src/app/services/header.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';
import { Tabs } from './../../../models/account.model';
import { BusinessUnitService } from './../../../services/business-unit.service';
import { MeterService, TypeOfConsumer } from './../../../services/meter.service';
import { UndertakingService } from './../../../services/undertaking.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal!: ElementRef;
  @ViewChild('nextButton') nextButton!: ElementRef;
  @ViewChild('backButton') backButton!: ElementRef;

  public PermissionsEnum = PermissionsEnum;

  public amiName :string = this.translationService.getByKeyFromCache('AMI');
  public buName: string = this.translationService.getByKeyFromCache('BU');
  public utName: string = this.translationService.getByKeyFromCache('UT');
  public MD: string = this.translationService.getByKeyFromCache('MD');
  public NMD: string = this.translationService.getByKeyFromCache('NMD');
  public currentTabCollorBlue: number = 1;
  public tempDtName: string = '';
  public currentPage: number = 1;
  public pageSize: number = 20;
  public count: number = 0;

  public fileChosen: any = null;
  public file: any = null;
  public isEdit : boolean = false;
  private ngUnsubscribe = new Subject<void>();
  private searchText: string = "";

  public accounts: Account[] = [];
  public selectedAccount: Account = new Account();

  public amis: DictionaryItem[] = [];
  public tariffs: DictionaryItem[] = [];
  public dcus: DictionaryItem[] = [];
  public tarifClasses: DictionaryItem[] = [];
  public tariffNames: DictionaryItem[] = [];
  public dtNames: DictionaryItem[] = [];

  public dts: DictionaryItem[] = [];
  public data: DictionaryItem[] = [];
  public data1: string[] = [];
  keyword: string = "name";

  public businessUnits: DictionaryItem[] = [];
  public undertakings: DictionaryItem[] = [];
  public businessUnit = 0;
  public undertaking = 0;

  public showTab: number = 1;
  public currentTab: number = 1;

  public amiValid: boolean = true;
  public tariffValid: boolean = true;
  public additionalInfoValid: boolean = false;

  public dtValid: boolean = true;
  public longValid: boolean = true;
  public latValid: boolean = true;
  public msnValid: boolean = true;
  public accountNoValid: boolean = true;
  public accountMetered!: boolean;
  public emailValid: boolean = true;
  public phoneNumberValid: boolean = true;
  public meterTypeValid: boolean = true;
  public enteredFromEdit : boolean = false;
  @ViewChild("f1") f1: any;
  @ViewChild("f2") f2: any;
  @ViewChild("f3") f3: any;
  @ViewChild("f4") f4: any;
  constructor(private accountService: AccountService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private amiService: AmiService,
    private translationService: TranslationService,
    private tariffService: TariffService,
    public authService: AuthService,
    private meterService: MeterService,
    private dtService: DtService,
    private dcuService: ConcentratorService,
    private buService: BusinessUnitService,
    private utService: UndertakingService,
    private headerService: HeaderService,
    private location:Location,
    private elementRef : ElementRef) { }

  ngOnInit(): void {
    this.reloadTable();
    this.getAllAmisForSelect();
    this.getAllTariffsForSelect();
    this.getAllDTsForSelect();
    this.getAllDCUsForSelect();
    this.subscribeToSearch();
    this.getAllBUsForSelect();
  }

  ngAfterViewInit() {
    const createModalDiv = this.elementRef.nativeElement.querySelector('#createModal');
    const options = {
      root: null, // Use the viewport as the root element
      threshold: 0.5, // Trigger when at least 50% of the element is visible
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.setIsEdit();
          this.f1.submitted = false;
         }else{
          this.isEdit = false;
        }
      });
    }, options);
    observer.observe(createModalDiv);
  }

  resetPage(){
    this.currentPage = 1
  }
  backPage(){
    this.location.back();
  }
  selectUT(){
    this.utService.getAllUTsByBuId(this.businessUnit).subscribe({
      next: response => {
      this.undertaking = 0;
      this.resetPage();
      this.undertakings = response.data;
      this.getAllAccounts();
    }, error : _ => {
      this.toastr.error('An error occured');
    }
  });
  }

  getAllBUsForSelect(){
    this.buService.getAllForSelect().subscribe({
      next: response => {
        this.businessUnits = response.data;
      },
      error : _ =>{
        this.toastr.error('An error occurred');
      }
    });
  }
  msnNumberErrorMsg = '';

  validateMeter(amiId: number, meterNumber: string){
    let ami = this.amis.find(x => x.value == amiId)?.name;
    if (ami && ami != '')
    {
      this.msnNumberErrorMsg = this.meterService.validateMeter(ami, meterNumber, TypeOfConsumer.Feeder33)
      if (this.msnNumberErrorMsg == '')
        return true
    }
    return false
  }

  getAllAmisForSelect() {
    this.amiService.getAllForSelect().subscribe({
      next: response => {
        this.amis = response.data
      },
      error: _ => {
        this.toastr.error('An error occurred');
      }
    })
  }

  getAllDTsForSelect() {
    this.dtService.getAllForSelect().subscribe(response => {
      this.dts = response.data
    }, _ => {
      this.toastr.error('An error occured');
    })
  }

  getAllTariffsForSelect() {
    this.tariffService.getAllForSelect().subscribe(response => {
      this.tariffs = response.data
    }, _ => {
      this.toastr.error('An error occured');
    })
  }

  getAllDCUsForSelect() {
    this.dcuService.getAllForSelect().subscribe(response => {
      this.dcus = response.data
    }, _ => {
      this.toastr.error('An error occured');
    })
  }

  download(){
    let obj = this.generateObject();
    obj.pageInfo.page = -1;
    obj.pageInfo.pageSize = 20000;
    this.accountService.downloadAccountsCrudPage(obj).subscribe(response => {
      window.location.href = response.data;
      this.toastr.success("Successfully downloaded accounts");
    }, _ => {
      this.toastr.error('An error occured');
    })
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null){
    if(page)
      this.currentPage = page;

    this.resetSelectedAccount();
    this.getAllAccounts();
  }
  generateObject(){
    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchContent: this.searchText,
        BusinessUnit: this.businessUnit,
        Undertaking: this.undertaking
      }
    };
    return obj
  }

  getAllAccounts(){
    if(this.businessUnit == 0){
      this.undertaking = 0
    }
    let obj = this.generateObject();

    this.accountService.getAll(obj).subscribe(response => {
      this.accounts = response.data.data;
      this.count = response.data.count;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  onFileChosen(event: any){
    this.file = event.target.files[0];
  }

  isFileChosen(){
    if(this.fileChosen)
      return true;

    return false;
  }

  uploadFile(){
    if(!this.isFileChosen){
      this.toastr.error('You have to choose file for upload.');
      return;
    }

    const formData = new FormData();
    formData.append("uploadFile", this.file);

    this.accountService.uploadFile(formData).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      } else {
        this.toastr.error(response.message);
      }
        this.reloadTable();
        this.fileChosen = null;
    }, error => {
      this.toastr.error('An error occurred.');
      this.fileChosen = null;
    });
  }

  selectAccount(id: number){
    this.resetSelectedAccount();
    this.accountService.get(id).subscribe(response => {
      if(response.status === 200){
        this.setIsEdit()
        this.selectedAccount = response.data;
        this.getDtObject(this.selectedAccount.dtName);
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  resetSelectedAccount(){
    this.showTab = 1;
    this.currentTab = 1;
    this.currentTabCollorBlue = 1;

    this.amiValid = true;
    this.tariffValid = true;
    this.dtValid = true;
    this.longValid = true;
    this.latValid = true;
    this.accountNoValid = true;
    this.emailValid = true;
    this.phoneNumberValid = true;

    this.tempDtName = '';
    this.selectedAccount = new Account();
    this.selectedAccount.tariffName = "";
    this.isEdit = false;
  }

  deleteAccount(e: any, id: number){
    e.stopPropagation();

    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_delete))
    {
      this.toastr.error("You don't have permission.");
      return;
    }
    this.accountService.delete(id).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.reloadTable();
        this.closeDeleteModal.nativeElement.click();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  validateTariff(){
    this.tariffValid = !(!this.selectedAccount.tariffName) //check is falsely and negate it to check truthly;
  }

  validateAdditionalInfo(){
    this.additionalInfoValid = this.f4 === undefined ? true : this.f4.form.valid;
  }
  validateTabs(){

       this.validateAccountNo();
       this.validateEmail();
       this.validatePhone();
       
        if (!this.emailValid || !this.phoneNumberValid || !this.accountNoValid || this.selectedAccount.firstName=="")
          return false;

        this.validateLatitude();
        this.validateLongitude();
        if(!this.latValid || !this.longValid){
          return false;
        }
        
        this.validateConsumption();
      

      return true;
  }

  validateAccountNo(){
    if (this.selectedAccount.accountNo){
      if (!this.commonService.isNumber(this.selectedAccount.accountNo)) // Is accountNo number
        this.accountNoValid = false;
      else
        this.accountNoValid = true;
    }
  }

  validateEmail(){
    if (this.selectedAccount.email){ // If something typed in field
      if (!this.commonService.isEmail(this.selectedAccount.email)) // Check if correct
        this.emailValid = false;
      else
        this.emailValid = true;
    }
  }

  validatePhone(){
    if (this.selectedAccount.phone){
      if (!this.commonService.isPhoneNumber(this.selectedAccount.phone))
        this.phoneNumberValid = false;
      else
        this.phoneNumberValid = true;
    }
  }

  validateConsumption(){
    if(!this.selectedAccount.accountConsumptionType) // Consumption type null? Return
        return false;
    else{
    if(!this.selectedAccount.accountMeterType) //Meter type null? Return
      return false;
    if(this.selectedAccount.msn != "")
    {

      if(!this.amiValid || !this.validateMsnInConsumption())
        return false;
    
}
}
return true;
}

validateMsnInConsumption(){
  this.validateAmi();
      if (this.selectedAccount.msn != "" && this.selectedAccount.amiId != 0)
      {
        if (!this.validateMeter(this.selectedAccount.amiId,this.selectedAccount.msn))
        {
          this.msnValid = false;
          return false
        }
      }
      return true;
}

  save(){
    this.enteredFromEdit = true;
    if(!this.authService.isAuthorized(PermissionsEnum.global_settings_createupdate))
    {
      this.toastr.error("You don't have permission.");
      return;
    }

    this.validateTariff();
    this.validateDt();
    this.validateAdditionalInfo();
    if(!this.validateTabs()){
      this.toastr.warning("Please fill out the form correctly!")
      return;
    }
    if(!this.additionalInfoValid || !this.tariffValid || !this.dtValid){
      return;
    }

    if (this.selectedAccount.id == 0) //create
    {
      let dtId = 0;
      //finding id of selected dt
      for (const key in this.dts) {
        if (this.dts.hasOwnProperty(key) && this.dts[key].name === this.selectedAccount.dtName) {
          dtId = this.dts[key].value;
          break;
        }
      }

      this.selectedAccount.dtId = dtId;
    }

    let ami = this.amis.find(x => x.value == this.selectedAccount.amiId)
    this.selectedAccount.ami = ami ? ami.name : ''

    this.accountService.save(this.selectedAccount).subscribe(response => {
      if(response.status === 200){
        this.toastr.success(response.message);
        this.reloadTable();
        this.closeModal.nativeElement.click();
        this.f1 = null;
      }else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  stringify (f1 : any){
    console.log(f1)
  }

  selectEvent(item: any) {
    this.selectedAccount.dtName = item;
    this.tempDtName = item;
    this.validateDt();
    this.data = [];
  }

  onChange(item: any) {
    this.data = [];
    this.tempDtName = '';
    this.data1 = [];
    if (item.length < 3) {

      this.data1 = []
      return this.data1;
    }

    this.data = this.dts.filter(dt =>
      dt.name.toLowerCase().includes(item.toLowerCase()));

    this.data.forEach(element => {
      this.data1.push(element.name);
    });

    return this.data1;
  }

  inputCleared(){
    this.tempDtName = '';
    this.selectedAccount.dtName = '';
  }

  openTab(tab: number){
    if (this.currentTab + 1 == tab) // This means it is next
    {
      this.emailValid = true;
      this.phoneNumberValid = true;
      this.accountNoValid = true;
      if (this.currentTab == Tabs.FirstTab)
      {
        this.validateAccountNo()

        this.validateEmail();

        this.validatePhone();

        if(!this.checkFirstTabValidation())
          return

      }
      else if (this.currentTab == Tabs.SecondTab)
      {
        this.validateLatitude();
        this.validateLongitude();
        if(!this.checkSecondTabValidation()){
          return;
        }
      }
      else if (this.currentTab == Tabs.ThirdTab)
      {
        if(!this.checkThirdTabValidation()){
          return;
        }
      }
      else
        this.toastr.error("An unexpected error occured");
    }

    this.showTab = 0;

    this.checkTab(tab);
  }

  checkTab(tab:any){
    if(tab == Tabs.FirstTab){
      this.setTabs(Tabs.FirstTab)
  }else if(tab == Tabs.SecondTab){
      this.setTabs(Tabs.SecondTab)
    }else if(tab == Tabs.ThirdTab){
      this.setTabs(Tabs.ThirdTab)
    }else if(tab == Tabs.FourthTab){
      this.setTabs(Tabs.FourthTab)
    }else
    this.toastr.error("An unexpected error occured");
  }

  checkFirstTabValidation(){
    if (!this.emailValid  || !this.phoneNumberValid || !this.accountNoValid || !this.f1.form.valid)
      return false;
    return true;
  }

  checkSecondTabValidation(){
    if(!this.f2.form.valid || !this.latValid || !this.longValid){
      return false;
    }
    return true;  
  }

  checkThirdTabValidation(){
    if(!this.f3.form.valid || !this.validateConsumption()){
      return false;
    }
    return true;
  }

  setTabs(tab: number){
    this.currentTabCollorBlue = tab;
    this.showTab = tab;
    this.currentTab = tab;
  }

  handleTabSwitching(){
    if(this.nextButton != undefined && this.nextButton.nativeElement === document.activeElement){
      this.next();
    } else if(this.backButton.nativeElement === document.activeElement){
      this.back();
    }
  }

  swithTab(num : number){
    if(this.isEdit){
      this.currentTab = num;
      this.openTab(num);
    }
  }

  setIsEdit(){
    this.isEdit = JSON.stringify(this.selectedAccount) != JSON.stringify(new Account())
  }

  next(){
    if(this.currentTab == Tabs.FirstTab)
      this.openTab(this.currentTab + 1);
    else if(this.currentTab == Tabs.SecondTab)
      this.openTab(this.currentTab + 1);
    else if(this.currentTab == Tabs.ThirdTab)
      this.openTab(this.currentTab + 1);
    else
      this.toastr.error("An unexpected error occured");
  }

  back(){
    if(this.currentTab == Tabs.SecondTab)
      this.openTab(this.currentTab - 1);
    else if(this.currentTab == Tabs.ThirdTab)
      this.openTab(this.currentTab - 1);
    else if(this.currentTab == Tabs.FourthTab)
      this.openTab(this.currentTab - 1);
    else
      this.toastr.error("An unexpected error occured");
  }

  validateAmi(){
    let regExp = "[";
    this.amis.forEach(element => {
      regExp += element.value + "|";
    });
    regExp.slice(0, -1);
    regExp += "]";
    const reg: RegExp = new RegExp(regExp);
    this.amiValid = reg.test(this.selectedAccount.amiId.toString());
    if (this.selectedAccount.msn != "" && this.selectedAccount.amiId == 0)
      this.amiValid = false;
  }

  validateDt(){
    if(this.selectedAccount.dtName == ""){
      this.dtValid = false;
    }else{
      this.dtValid = true;
    }
  }

  validateLongitude(){
    if(!this.selectedAccount?.longitude)
      this.longValid = false;
    else if(Number.isNaN(Number(this.selectedAccount?.longitude)))
      this.longValid = false;
    else if(Number(this.selectedAccount?.longitude) < -180 || Number(this.selectedAccount?.longitude) > 180)
      this.longValid = false;
    else
      this.longValid = true;
  }

  validateLatitude(){
    if(!this.selectedAccount?.latitude)
      this.latValid = false;
    else if(Number.isNaN(Number(this.selectedAccount?.latitude)))
      this.latValid = false;
    else if(Number(this.selectedAccount?.latitude) < -90 || Number(this.selectedAccount?.latitude) > 90)
      this.latValid = false;
    else
      this.latValid = true;
  }

  getDtObject(dtName: any){
    this.tempDtName = '';
    const temp = this.dts.filter((x: any) => x.name == dtName);

    if(temp.length > 0){
      this.tempDtName = temp[0].name;
    }
  }

  getTemplate(){
    this.accountService.getTemplate().subscribe(response => {
      if (response.status == 200){
        if (response.data && response.data.length > 0)
          window.location.href = response.data;
      }else{
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  public subscribeToSearch() {
    this.headerService.globalSearch$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(searchValue => {
      if(searchValue.clicked && searchValue.componentName === ComponentNamesNew.AccountComponent) {
        this.searchText = searchValue.text;
        this.currentPage = 1;
        this.reloadTable();
        return;
      }
      this.searchText = '';
    });
  }

  accMeterTypeChanged() {
    this.selectedAccount.msn = "";
    this.selectedAccount.meterType = '';
    this.selectedAccount.amiId = 0;
    this.f3.submitted = false;
  }

  changeConsumptionType(){
    if (this.selectedAccount.accountConsumptionType == 2)
    {
      this.accountMetered = true;
      this.selectedAccount.accountMeterType = 0;
    }
    if (this.selectedAccount.accountConsumptionType == 3)
    {
      this.accountMetered = false;
      this.selectedAccount.accountMeterType = 0;
    }
  }
}
