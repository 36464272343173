import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-multi-filter',
  templateUrl: './multi-filter.component.html',
  styleUrls: ['./multi-filter.component.scss']
})
export class MultiFilterComponent implements OnInit {

  @Input() data: any;
  @Input() showFilter: boolean = false;
  @Input() search: boolean = true
  @Input() height: string = '35rem'

  public dropdownSelected: any = {data: {name:''} ,item: {value: 0, name: '', checked: false} }

  public tempData: any = [];
  selectedItem: any = null;

  public categorySelected: any;
  public filterCategoryClicked: boolean = false;

  public checkedList: any = []
  public searchText: string = "";
  public filterClicked: boolean = false;

  @Output() dataChange = new EventEmitter();
  @Output() showFilterChange = new EventEmitter();
  @Output() dropdownChange = new EventEmitter();

  constructor(private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.setData(this.data)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data !== undefined) {
      this.setData(changes.data.currentValue)
    }
  }

  setData(data: any) {
    if (data.length > 0) {
      for (const item of data) {
        for (const item2 of item.data) {
          item2.checked = item2['checked'] ? item2['checked'] : false;
          // item2.visible = true
        }
      }

      this.tempData = cloneDeep(this.data)
      if(!this.categorySelected)
        this.categorySelected = this.tempData[0];
    }
  }


  public showFilters() {
    if (this.checkedList.length)
      this.showFilter = !this.showFilter;
    else
      this.showFilter = false

    this.showFilterChange.emit(this.showFilter)
  }

  uncheckData() {
    for (const x of this.data) {
      for (const y of x.data) {
        y.checked = false
      }
    }
  }

  public filterClick() {
    this.filterClicked = !this.filterClicked;
    if(!this.filterClicked)
      this.categorySelected = this.tempData[0];
  }

  categorySelect(category: any) {
    let Index = this.data.findIndex((item: { name: any; }) => item.name === category.name);
    if(Index != -1){
      if(this.data[Index].data.length == 0 && this.data[Index - 1])
        this.toastr.warning("Please choose a " + this.data[Index - 1].name)
      else
      {
        this.categorySelected =  this.data[Index]
        this.searchText = ''
      }
    }
    else
      return
  }

  dropdownSelect(data: any, item: any) {
    this.dropdownSelected = {data: data ,item: item }

    let checkIndex = this.checkedList.findIndex((item2: { name: any; }) => item2.name === data.name);
    if (checkIndex !== -1){
      this.checkedList[checkIndex].data = [this.dropdownSelected.item];
      this.checkedList.splice(checkIndex + 1);
    }
    else
      this.checkedList.push({ name: this.dropdownSelected.data.name, data: [this.dropdownSelected.item], dropdown: true })

    let Index = this.data.findIndex((item: { name: any; }) => item.name === this.dropdownSelected.data.name);
    if(Index != -1 && this.data[Index + 1]){
      // if(this.data[Index + 1].data.length == 0)
      //   this.toastr.warning("Not exists " + this.data[Index + 1].name + " for " + item.name)
      // else
      this.categorySelected =  this.data[Index + 1]
    }
    else
      this.filterClicked = false

    this.searchText = ''

    let dataChange = this.dropdownSelected

    this.dropdownChange.emit(dataChange)
  }

  // changeCategory(item: any){
  //   let Index = this.data.findIndex((item: { name: any; }) => item.name === this.dropdownSelected.data.name);
  //   if(Index != -1 && this.data[Index + 1]){
  //     if(this.data[Index + 1].data.length == 0)
  //       this.toastr.warning("Not exists " + this.data[Index + 1].name + " for " + item.name)
  //     else
  //       this.categorySelected =  this.data[Index + 1]
  //   }
  // }

  public close() {
    this.filterClicked = false;
    this.filterCategoryClicked = false;
    this.categorySelected = this.tempData[0];
    this.searchText = ''

    this.tempData = cloneDeep(this.data)

    // this.tempData = []
    // this.setData(this.data)
  }

  public apply(tempData: any) {
    this.data = cloneDeep(tempData);
    // this.checkedList = []

    for (const item of this.data) {
      const checkedData = item.data.filter((item2: { checked: any; }) => item2.checked);
      const filteredValues = checkedData.map((item2: { value: any; }) => item2);
      let checkIndex = this.checkedList.findIndex((item2: { name: any; }) => item2.name === item.name);
      if (filteredValues.length > 0)
      {
        if (checkIndex !== -1){
          this.checkedList[checkIndex].data = filteredValues;
        }
        else
          this.checkedList.push({ name: item.name, data: filteredValues })
      }
      else if(filteredValues.length == 0 && checkIndex !== -1)
      {
        this.checkedList.splice(checkIndex, 1)
      }
    }


    this.categorySelected = this.data[0];
    this.filterClicked = false;
    this.filterCategoryClicked = false;

    if (!this.checkedList.length) {
      this.showFilters();
    }

    this.searchText = ''

    this.dataChange.emit(this.data)
  }

  resetFilter(data: any) {
    data.data.forEach((item: any) => {
      item.checked = false;
    });
    if (data.dropdown) {
      data.data = []
      this.dropdownSelected = {data: data ,item: {value: 0, name: '', checked: false} }

      let Index = this.checkedList.findIndex((item: { name: any; }) => item.name === data.name);

      if (this.checkedList[Index])
        this.checkedList.splice(Index);

      let dataChange = this.dropdownSelected

      this.dropdownChange.emit(dataChange)
    }
    else{
      let index = this.checkedList.indexOf(data)
      this.checkedList.splice(index, 1);
      this.dataChange.emit(this.data)
    }
    if (!this.checkedList.length) {
      this.showFilters();
    }
  }

  resetShowFilter() {
    this.checkedList.splice(0);
  }

  showTooltip(data: any) {
    this.selectedItem = data;
  }

  hideTooltip() {
    this.selectedItem = null;
  }
}

