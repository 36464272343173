export class ModalData{
        accountId: string = "";
        accountName: string = "";
        accountNumber: string = "";
        meterSerialNumber: string = "";
        customerAddress: string = "";

        dtName: string = "";
        feederName: string = "";
        businessUnit: string = "";
        undertaking: string = "";
        period: string = "";
        vendingDeviation: string = "";
        availabilityDeviation: string = "";
        availabilitySource: string = "";
        alarmCondition: string = "";
        remarks: string = ''
        id:string=''
        modalMonthlyTables: ModalTable[] = [];
}

export class ModalTable {
        monthName: string = "";
        lar: string = "";
        par: string = "";
        consumption: string = "";
        monthlyVend: string = "";
        avgMonthlyVend: string = "";
        monthlySupplyAvailability: string = "";
        avgMonthlyAvailability: string = "";
        avgDailyAvailability: string = "";
        monthlyVendingDeviation: string = "";
        monthlyAvailabilityDeviation: string = "";
        monthlyAlarmCondition: string = "";
}

export class OverdueBillArearsModalData{
        accountId: string = "";
        accountName: string = "";
        accountNumber: string = "";
        customerType: string = "";
        customerAddress: string = "";

        dtName: string = "";
        feederName: string = "";
        initialDebtAmount: string = "";
        undertaking: string = "";
        businessUnit: string = "";
        tariff: string = "";
        debtSource: string = "";
        lastPaymentAmount: string="";
        currentDebtAmount:string="";
        lastPaymentDate: Date = new Date();
        email: string="";
        longitude: string="";
        latitude: string="";
        phone: string="";
        flaggedDate:Date=new Date();
}

export class PaymentCollectionModalData{
        accountId: string = "";
        accountName: string = "";
        accountNo: string = "";
        customerType: string = "";
        customerAddress: string = "";
        customerId: string = "";
        paymentId: number = 0
        paymentSource: number=0;

        actualDebtOwed: number=0;
        debtBalanceFromLastPayment: number=0;
        currentDebtBalance: number=0;

        amountPaid: number=0;
        paymentDate: string = "";
        outstandingDebt: number=0;

        undertaking: string = "";
        businessUnit: string = "";
        dt: string = "";
}
