import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/permissionsEnum';
import { AuthService } from 'src/app/services/auth.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent{

  public PermissionsEnum = PermissionsEnum;

  public buName :string = this.translationService.getByKeyFromCache('BU');
  public utName :string = this.translationService.getByKeyFromCache('UT');
  constructor(private toastr: ToastrService, private translationService: TranslationService, private router: Router, public authService : AuthService) { }

  alert(){
    this.toastr.warning("Not yet implemented");
  }
}
