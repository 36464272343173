<table class="standard-table">
    <thead>
        <tr>
            <th class="checkbox-td"><input type="checkbox" class="input-checkmark" [(ngModel)]="masterSelected" (change)="checkUncheckAll()"></th>
            <th><span>ACCOUNT NUMBER</span></th>
            <th><span>DATE OF PAYMENT</span></th>
            <th><span>PAYMENT AMOUNT</span></th>
            <th><span>RECEIPT_NR</span></th>
            <th><span>SPN</span></th>
            <th><span>{{bu}}</span></th>
            <th><span>{{ut}}</span></th>
            <th><span>PAYMENT SOURCE</span></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of payments">
            <td (click)="false; $event.stopPropagation();" class="checkbox-td"><input type="checkbox" class="input-checkmark" value="{{item.id}}" [(ngModel)]="item.checked" (change)="checkForDownload(item.id)" ></td>
            <td title="{{ item.accountNo }}">{{ item.accountNo | hasValue }}</td>
            <td title="{{ item.paymentDate }}">{{ item.paymentDate | date : "MMM dd, yyyy" | hasValue }}</td>
            <td title="{{ item.paymentAmount }}">{{ item.paymentAmount | number | hasValue }}</td>
            <td title="{{ item.receipt_NR }}">{{ item.receipt_NR | hasValue }}</td>
            <td title="{{ item.spn }}">{{ item.spn | hasValue }}</td>
            <td title="{{ item.buName }}">{{ item.buName | hasValue }}</td>
            <td title="{{ item.utName }}">{{ item.utName | hasValue }}</td>
            <td title="{{ item.paymentSource }}">{{ item.paymentSource | hasValue }}</td>
        </tr>
    </tbody>
</table>

<div class="pagination-wrapper">
    <app-fo-pagination-new [lastPageSize]="lastPageSize" [pageSize]="pageSize" [count]="count"
        [currentPage]="currentPage" (pageChange)="pageChange($event)">
    </app-fo-pagination-new>
</div>