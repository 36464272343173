<app-new-header [border]="border"></app-new-header>
<div class="container landing-container">
    <button class="back-container" (click)="back()">Back
        <img alt="Back" src="assets/images-new-design/system-uicons_arrow-left.svg" />
    </button>
    <div class="text">Menu <img alt=">" src="assets/images-new-design/ep_arrow-left.png"> Commercial <img alt=">"
            src="assets/images-new-design/ep_arrow-left.png"></div>
    <div class="menu-items">
        <div class="row">
            <div class="commercial-item  " (mouseenter)="onClick(1)" (mouseleave)="onMouseLeave()"
                [ngClass]="{'disabled': !authService.isGroupAuthorized('revenueprotection_')}">
                <div class="menu-card" [routerLink]="['/revenue-protection/dashboard']">

                    <div class="menu-text">
                        <div class="header">
                            <div class="icons">
                                <img alt="" src="assets/images-new-design/icons-revenue.png">
                            </div>
                            <div class="select-text" *ngIf="isFirstBlockVisible">
                                <div class="select">{{ displayText }}
                                    <img alt="" src="assets/images-new-design/Arrow.png">
                                </div>
                            </div>
                        </div>
                        <div class="menu-title">
                            Revenue Protection
                        </div>
                        <div class="details">
                            Advanced machine learning algorithms identify meter tampering risks,
                            safeguarding revenue
                            integrity.
                        </div>
                    </div>

                    <div class="img-container"><img alt="" src="assets/images/network-management-menu-item.png">
                        <div class="img-vector" *ngIf="isFirstBlockVisible">
                            <img alt="" src="assets/images-new-design/Vector.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer">
    Powered by&nbsp;
    <div class="red">Beacon Power Services</div>
</footer>
