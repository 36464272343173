export enum ComponentNamesNew{
    NoComponent = -1,
    TransmissionStationComponent = 1,
    BusinessUnitComponent = 2,
    UndertakingComponent = 3,
    TariffComponent = 4,
    ConcentratorComponent = 5,
    Feeder33Component = 6,
    Feeder11Component = 7,
    SubstationComponent = 8,
    DtComponent = 9,
    ConsumptionComponent = 10,
    AccountComponent = 11,
    UserManagementComponent = 12,
    AuditLogComponent = 13,
    ManualRead = 14,
    CustomerVendingsComponent=15,
    GlobalLandingPageNewComponent=16,
    TaskCategoryComponent = 17,
    AmiComponent = 18,
    ConsumptionGraphComponent=19,
    AccountStatusTabComponent =20,
    MeterModelTabComponent = 21,
    InvalidTfeRead = 22,
    StaffOperatorComponent = 23,
    UnitComponent = 24,
    SubunitComponent = 25,
    DepartmentComponent = 26,
}
