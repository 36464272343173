import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { transformToString } from 'src/app/helpers/dateUtils';
import { SearchAutocomplete } from 'src/app/models/searchAutocomplete.model';
import { TsViewMore } from 'src/app/models/tsViewMore';
import { TransmissionStationService } from 'src/app/services/transmission-station.service';
import { TsPopupComponent } from './ts-popup/ts-popup.component';

@Component({
  selector: 'app-gep-ts-new',
  templateUrl: './gep-ts-new.component.html',
  styleUrl: './gep-ts-new.component.scss'
})
export class GepTsNewComponent implements OnChanges {

  public count: number = 0
  public pageSize: number = 8
  public currentPage: number = 1

  @Input() dateFrom: any = ''
  @Input() dateTo: any = ''
  @Input() search: SearchAutocomplete = new SearchAutocomplete()

  public masterSelected: boolean = false
  public checkedList: any = []

  public data: TsViewMore[] = []

  public selectedTs: any = null
  @ViewChild(TsPopupComponent) tsPopupRef!: TsPopupComponent
  public isDailyPeriod: boolean = false

  constructor(
    private tsService: TransmissionStationService,
    private toastr: ToastrService
  ) { }


  ngOnChanges(changes: SimpleChanges) {
    let reloadTable = false
    reloadTable = true
    if (changes.dateFrom != null || changes.dateTo != null) {
      reloadTable = true
    }
    if (this.search?.name == '')
      this.search = changes.search?.previousValue

    if (changes.search != null) {
      reloadTable = true
    }

    if (reloadTable) {
      this.currentPage = 1
      this.checkedList = []
      this.getData()
    }
  }


  pageChange(value: number) {
    this.currentPage = value
    this.getData()
  }

  public getData() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        from: transformToString(this.dateFrom),
        to: transformToString(this.dateTo),
        search: this.search ? this.search.name : this.search
      }
    }

    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.fetchMonthlyTsData(obj)
    }
    else {
      this.fetchDailyTsData(obj)
    }
  }

  fetchMonthlyTsData(obj: any) {
    this.tsService.getAllTsEnergyProfilesMonthly(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data
        this.isAlreadyChecked()
        if (obj.pageInfo.page == 1)
          this.count = response.data.count
      } else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  fetchDailyTsData(obj: any) {
    this.tsService.getAllTsEnergyProfilesDaily(obj).subscribe(response => {
      if (response.status === 200) {
        this.data = response.data.data
        this.isAlreadyChecked()
        if (obj.pageInfo.page == 1)
          this.count = response.data.count
      } else {
        this.toastr.error(response.message)
      }
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  download() {
    let obj = {
      pageInfo: {
        page: -1
      },
      filterParams: {
        from: transformToString(this.dateFrom),
        to: transformToString(this.dateTo),
        search: this.search ? this.search.name : this.search,
        checkedList: this.checkedList
      }
    }

    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.downloadMonthlyReport(obj)
    }
    else {
      this.downloadDailyReport(obj)
    }
  }

  private downloadMonthlyReport(obj: any) {
    this.tsService.downloadMonthlyTsReport(obj).subscribe(response => {
      window.location.href = response.data
    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  private downloadDailyReport(obj: any) {
    this.tsService.downloadDailyTsReport(obj).subscribe(response => {
      window.location.href = response.data

    }, _ => {
      this.toastr.error('An error occurred.')
    })
  }

  checkUncheckAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].checked = this.masterSelected
      this.checkForDownload(this.data[i].tsId, true)
    }
  }

  checkForDownload(id: number, isMasterClicked: boolean = false) {
    if (!isMasterClicked)
      this.isMasterChecked()
    let index = this.checkedList.indexOf(id)
    if (index !== -1) {
      if (!isMasterClicked)
        this.checkedList.splice(index, 1)
      else if (isMasterClicked && !this.masterSelected)
        this.checkedList.splice(index, 1)
    }
    else {
      this.checkedList.push(id)
    }
  }

  isAlreadyChecked() {
    this.data.forEach(x => {
      let index = this.checkedList.indexOf(x.tsId)
      if (index !== -1) {
        x.checked = true
      }
    })
    this.isMasterChecked()
  }

  isMasterChecked() {
    this.masterSelected = this.data.every(function(item: any) {
      return item.checked == true
    })
  }

  viewMore(ts: any) {
    this.selectedTs = this.data.find(x => x.tsId == ts.tsId)
    if (this.dateFrom.day == 1 && this.dateTo.day == 1) {
      this.isDailyPeriod = false
    }
    else {
      this.isDailyPeriod = true
    }
    this.openTab(this.selectedTs, this.isDailyPeriod)
  }

  openTab(selectedTs:any, daily:any) {
    this.tsPopupRef.changeState(daily, selectedTs)
  }

}
