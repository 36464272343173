<div class="details-wrapper">
    <div class="details-main">
        <app-stock-item-details
            (changeView)="changeViewFromChild($event)"
            [item]="item"
        ></app-stock-item-details>
    </div>
    <div class="details-issued">
        <app-issued-item-details
            (changeView)="changeViewFromChild($event)"
            [inventory]="inventory"
        ></app-issued-item-details>

    </div>
</div>