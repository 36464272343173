<app-pa-f11-slider-new></app-pa-f11-slider-new>

<table *ngIf="menuTab === 0" class="standard-table">
    <thead class="predictive-table-header">
        <tr>
            <th class="left dt-name"><span>Feeder Name</span></th>
            <th><span>Feeder Number</span></th>
            <th class="bu"><span>{{buName}}</span></th>
            <th><span>MSN</span></th>
            <th><span>Last Read Sync</span></th>
            <th class="status"><span>Status</span></th>
            <th class="description"><span>Description</span></th>
            <th><span></span></th>
        </tr>
    </thead>
    <tbody class="table-items">
        <tr class="cursor-default clickable actions-click" *ngFor="let item of data" click-stop-propagation (click)="openPopUp(item)">
            <td><span>{{item.feederName}}</span></td>
            <td><span>{{item.feederNo}}</span></td>
            <td><span>{{item.buName}}</span></td>
            <td><span>{{item.msn}}</span></td>
            <td><span>{{item.lastReadSync | date: 'MMM dd, yyyy'}}</span></td>
            <td>
                <div class="status-item warning" *ngIf="item.status.name == 'Warning'">
                    <span>
                    <img alt="" src="../../../../../../assets/images-new-design/warning.png">
                    {{item.status.name}}</span>
                </div>
                <div class="status-item maintance" *ngIf="item.status.name == 'Maintenance'">
                    <span>
                    <img alt="" src="../../../../../../assets/images-new-design/error.png">
                    {{item.status.name}}</span>
                </div>
                <div class="status-item info" *ngIf="item.status.name == 'Info'">
                    <span>
                    <img alt="" src="../../../../../../assets/images-new-design/warning.png">
                    {{item.status.name}}</span>
                </div>
            </td>
            <td title="{{item.description}}"><span>{{item.description}}</span></td>
            <td class="pointer"><span>
                <img alt="..." src="../../../../../assets/images/3-dot.svg">
            </span></td>
        </tr>
    </tbody>
</table>

<table *ngIf="menuTab === 1" class="standard-table">
    <thead class="predictive-table-history-header">
        <tr>
            <th class="left dt-name"><span>Feeder Name</span></th>
            <th><span>MSN</span></th>
            <th><span>Start Date</span></th>
            <th><span>End Date</span></th>
            <th><span>Voltage</span></th>
            <th><span>Current</span></th>
            <th><span>Amb Temp</span></th>
            <th><span>Power Factor</span></th>
            <th class="description"><span>Description</span></th>

        </tr>
    </thead>
    <tbody class="table-items">
        <tr class="cursor-default" *ngFor="let item of historyData" click-stop-propagation  >
            <td><span>{{item.feederName}}</span></td>
            <td><span>{{item.startTime | date: 'MMM dd, yyyy'}}</span></td>
            <td><span>{{item.msn}}</span></td>
            <td><span>{{item.endTime | date: 'MMM dd, yyyy'}}</span></td>
            <td *ngIf="item.voltage; else Nan"><span>{{item.voltage}}</span></td>
            <td *ngIf="item.current; else Nan"><span>{{item.current}}</span></td>
            <td *ngIf="item.ambTemp; else Nan"><span>{{item.ambTemp}}</span></td>
            <td *ngIf="item.factor; else Nan"><span>{{item.factor}}</span></td>
            <td title="{{item.description}}"><span>{{item.description | hasValue}}</span></td>
            <ng-template #Nan>
                <td><span class="NaN">N/A</span></td>
            </ng-template>
        </tr>
    </tbody>
</table>
