<app-translation-create (parentComp)="reloadTable()"></app-translation-create>
<main class="container-fluid">
    <div class="translation-page">
        <app-settings-sidebar></app-settings-sidebar>
        <div class="translation-card">
            <button class="back" (click)="back()">
                <img alt="Back" class="imgBack" src="assets/images-new-design/back-arrow.png">Back
            </button>
            <div style="display: flex; justify-content: space-between;" class="title-wrapper">
                <div class="title-u">Defined Label Names</div>
                <ng-container regularButtons>
                    <button class="red-btn" (click)="openTab()">
                        <span class="text">Edit Translation</span>
                    </button>
                </ng-container>
            </div>
            <div class="tbl">
                <table class="standard-table">
                    <thead>
                        <tr>
                            <th><span>Key</span></th>
                            <th><span>Value</span></th>
                        </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr *ngFor="let item of translations">
                            <td>{{item.key}}</td>
                            <td>{{item.value}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</main>
